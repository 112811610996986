import React, { useState } from "react";
import UpArrowIcon from "../../../icons/UpArrowIcon";
import { DownArrowIcon } from "../../../icons";
import Controls from "../../../components/controls/Controls";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  flexCenterCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  labels: {
    fontSize: "14px",
    lineHeight: "20px",
    color: "#1A1A1A",
    fontWeight: 600,
    fontFamily: "Noto Sans",
    paddingTop: "8px",
  },
}));

export default function RenderSection({
  product,
  errors,
  sectionLabel,
  fieldsConfig,
  activeFields,
  handleChange,
}) {
  const classes = useStyles();
  const [openSection, setOpenSection] = useState(false);

  const renderField = (config) => {
    switch (config.fieldType) {
      case "select":
        return (
          <Controls.Select
            key={config.name}
            name={config.name}
            label={config.label}
            labelPlacement="top"
            labelFontWeight={400}
            value={product[config.name]}  
            onChange={handleChange}
            options={config.options}
            error={errors[config.name]}
          />
        );
      case "input":
        return (
          <Controls.Input
            key={config.name}
            name={config.name}
            label={config.label}
            labelPlacement="top"
            labelFontWeight={400}
            placeholder={config.placeholder}
            value={product[config.name]}
            onChange={handleChange}
            startAdornment={config.startAdornment}
            endAdornment={config.endAdornment}
          />
        );
      case "switch":
        return (         
          <Controls.Switch
            key={config.name}
            name={config.name}
            label={config.label}
            labelFontWeight={400}
            value={product[config.name] ? false: true}
            onChange={handleChange}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div>
      {sectionLabel && (
        <div
          className={classes.flexCenterCenter}
          style={{ marginBottom: 10, cursor: "pointer" }}
          onClick={() => setOpenSection(!openSection)}
        >
          <label className={classes.labels}>{sectionLabel}</label>
          {openSection ? (
            <UpArrowIcon stroke="#666666" />
          ) : (
            <DownArrowIcon stroke="#666666" />
          )}
        </div>
      )}
      {(!sectionLabel || openSection) &&
        fieldsConfig.map((config) =>
          activeFields[config.name] ? renderField(config) : <></>
        )}
    </div>
  );
}
