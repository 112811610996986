import {
  ADD_PRODUCT,
  DELETE_PRODUCT,
  FETCH_PRODUCTS,
  UPDATE_PRODUCT,
  PRODUCT_PROGRESS,
  SET_PRODUCT_ERROR,
  CLEAR_PRODUCT,
  PRODUCT_LOADER,
  PRODUCT_QUANTITY_UPDATE,
  CLEAR_COMPLETE_PRODUCT,
  SET_FILTER_SLUGS,
  SET_SORT_CRITERIA,
  SET_BULK_IMPORT_TOAST,
  SET_FILTER_STATUS,
  SET_FILTER_QUANTITY,
  SET_PRODUCTS_PER_PAGE,
  SET_PRODUCT_ONLINE,
  UPDATE_PRODUCT_CATEGORY,
  ASSIGN_PRODUCT_CATEGORY,
  SET_FILTER_PAYMENT_MODE,
} from "./productTypes";
import { CLEAR_DATA, CLEAR_COMPLETE } from "../commom/commonTypes";

const initialState = {
  products: [],
  numberOfPages: 0,
  numberOfElements: 0,
  product: null,
  deleteSuccess: false,
  progress: true,
  loader: false,
  error: null,
  filterSlugs: [],
  sortBy: "createdAt,desc",
  bulkToast: "",
  status: null,
  mode: null,
  maxQty: null,
  minQty: null,
  productsPerPage: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      };
    case PRODUCT_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case FETCH_PRODUCTS:
      let allProducts = state.products;
      let page = action.payload.page;
      if (page > 0 && state.products.length > action.productsPerPage * page) {
        allProducts = state.products.slice(0, action.productsPerPage * page);
      }
      return {
        ...state,
        products:
          action.payload.page > 0
            ? [...allProducts, ...action.payload.records.content]
            : action.payload.records.content,
        numberOfPages: action.payload.records.totalPages,
        numberOfElements: action.payload.records.totalElements,
        progress: false,
      };
    case ADD_PRODUCT:
      let pageCnt = state.numberOfPages;
      let localPageProducts = state.products;
      let count = state.products.length;
      if (count + 1 > action.productsPerPage) {
        let fullPages = Math.floor((count + 1) / action.productsPerPage);
        localPageProducts = state.products.slice(
          0,
          fullPages * action.productsPerPage - 1
        );
      }
      return {
        ...state,
        product: action.payload,
        loader: false,
        products: [action.payload, ...localPageProducts],
        numberOfPages:
          state.numberOfElements === pageCnt * action.productsPerPage
            ? pageCnt + 1
            : pageCnt,
        numberOfElements: state.numberOfElements + 1,
      };
    case UPDATE_PRODUCT:
      let modProducts = state.products.map((prod) => {
        if (prod.sku === action.payload.sku) {
          return action.payload;
        }
        return prod;
      });
      return {
        ...state,
        product: action.payload,
        products: modProducts,
        loader: false,
      };
    case ASSIGN_PRODUCT_CATEGORY:
      let upProds = state.products.map((product) => {
        const matchingProd = action.payload.find(
          (prod) => prod.prod_sku === product.sku
        );
        if (matchingProd) {
          return {
            ...product,
            cat_slug: matchingProd.cat_slug,
            cat_name: matchingProd.cat_name,
            categories: matchingProd.categories,
          };
        }
        return product;
      });
      return {
        ...state,
        products: upProds,
      };
    case UPDATE_PRODUCT_CATEGORY:
      let updatedProducts = state.products.map((product) => {
        if (product.sku === action.payload.prod_sku) {
          return {
            ...product,
            cat_slug: "",
            cat_name: "",
            categories: action.payload.categories,
          };
        }
        return product;
      });
      return {
        ...state,
        products: updatedProducts,
      };
    case DELETE_PRODUCT:
      return {
        ...state,
        products: state.products.filter(
          (product) => !action.payload.includes(product.sku)
        ),
        numberOfPages: Math.ceil(
          (state.numberOfElements - action.payload.length) /
            action.productsPerPage
        ),
        numberOfElements: state.numberOfElements - action.payload.length,
        loader: false,
        deleteSuccess: true,
      };
    case SET_FILTER_SLUGS:
      return {
        ...state,
        filterSlugs: action.payload.filterSlugs,
      };
    case SET_FILTER_STATUS:
      return {
        ...state,
        status: action.payload.status,
      };
    case SET_FILTER_PAYMENT_MODE:
      return {
        ...state,
        mode: action.payload.mode,
      };
    case SET_FILTER_QUANTITY:
      return {
        ...state,
        minQty: action.payload.minQty,
        maxQty: action.payload.maxQty,
      };
    case SET_SORT_CRITERIA:
      return {
        ...state,
        sortBy: action.payload.sortBy,
      };
    case SET_BULK_IMPORT_TOAST:
      return {
        ...state,
        bulkToast: action.payload.data,
      };
    case CLEAR_PRODUCT:
      return {
        ...state,
        product: action.payload,
        deleteSuccess: false,
      };
    case SET_PRODUCT_ERROR:
      return {
        ...state,
        loader: false,
        error: action.payload.error,
      };
    case CLEAR_COMPLETE_PRODUCT:
      return {
        ...state,
        products: [],
        numberOfPages: 0,
        numberOfElements: 0,
        product: null,
        progress: false,
        error: "",
      };
    case CLEAR_COMPLETE:
    case CLEAR_DATA:
      return {
        ...state,
        products: [],
        numberOfPages: 0,
        numberOfElements: 0,
        product: null,
        progress: false,
        error: "",
        productsPerPage: null,
      };
    case PRODUCT_QUANTITY_UPDATE:
      let prods = state.products.map((prod) => {
        if (prod.sku === action.payload.sku) {
          return {
            ...prod,
            qty: action.payload.qty,
            quantity: action.payload.qty,
          };
        }
        return prod;
      });
      return {
        ...state,
        products: prods,
      };
    case SET_PRODUCT_ONLINE:
      let products = state.products.map((prod) => {
        if (action.payload.skus.includes(prod.sku)) {
          return {
            ...prod,
            show_online: action.payload.value,
          };
        }
        return prod;
      });
      return {
        ...state,
        products,
      };
    case SET_PRODUCTS_PER_PAGE:
      return {
        ...state,
        productsPerPage: action.payload,
      };
    default:
      return state;
  }
};
export default reducer;
