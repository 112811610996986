export const TOGGLE_LOADING = "TOGGLE_LOADING";
export const CREATE_COUPON_LOADING = "TOGGLE_CREATE_COUPON_LOADING";
export const SET_COUPONS = "SET_COUPONS";
export const COUPON_CREATE_ERROR = "COUPON_CREATE_ERROR";
export const CREATE_COUPON_SUCCESS = "CREATE_COUPON_SUCCESS";
export const CLEAR_COUPONS = "CLEAR_COUPONS";
export const SET_COUPON_BANNERS = "SET_COUPON_BANNERS";
export const DELETE_COUPON_SUCCESS = "DELETE_COUPON_SUCCESS";
export const TOGGLE_COUPON_ACTIVATION = "TOGGLE_COUPON_ACTIVATION";
export const CLEAR_COUPON = "CLEAR_COUPON";
export const COUPON_ERROR = "COUPON_ERROR";
export const SET_COUPONS_PER_PAGE = "SET_COUPONS_PER_PAGE";
