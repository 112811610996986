import React from "react";
import FilledEditIcon from "../../icons/FilledEditIcon";
import { Button, Typography } from "@material-ui/core";

const FilledEditButton = ({
  size = 16,
  fill = "#1641DB",
  onClick = () => {},
  style = {},
  textStyle = {},
}) => {
  return (
    <Button onClick={onClick} style={{ background: "#FFFFFF", ...style }}>
      <FilledEditIcon size={size} fill={fill} />
      <Typography
        color="secondary"
        style={{ fontSize: 12, fontWeight: 600, marginLeft: 4, ...textStyle }}
      >
        Edit
      </Typography>
    </Button>
  );
};

export default FilledEditButton;
