import React from "react";
import { Typography } from "@material-ui/core";

export const generateLabelProps = (props) => {
  const labelPlacement = props.labelPlacement || "start";

  return {
    label: props.label,
    labelPlacement: labelPlacement,
    labelFontWeight: props.labelFontWeight,
    mainLabelWidth: props.mainLabelWidth,
    labelWidth: props.labelWidth,
    labelStyles: props.labelStyles,
  };
};

const CustomLabel = ({
  label,
  labelPlacement,
  labelFontWeight = 600,
  mainLabelWidth,
  labelWidth,
  labelStyles = {},
}) => {
  if (!label) return null;

  return (
    <Typography
      style={{
        fontSize: 14,
        fontWeight: labelFontWeight,
        minWidth:
          labelPlacement === "start" ? mainLabelWidth || labelWidth || 110 : 0,
        maxWidth: labelPlacement === "start" ? 110 : "auto",
        marginRight: labelPlacement === "start" ? 8 : 0,
        marginTop: labelPlacement === "start" ? -18 : 0,
        marginBottom: labelPlacement === "top" ? 12 : 0,
        ...labelStyles,
      }}
    >
      {label}
    </Typography>
  );
};

export default CustomLabel;
