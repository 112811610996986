import React from "react";

const MenuIcon = () => {
  return (
    <svg
      width="14"
      height="11"
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="13.5"
        y1="0.5"
        x2="0.5"
        y2="0.499999"
        stroke="black"
        strokeLinecap="round"
      />
      <line
        x1="13.5"
        y1="5.5"
        x2="0.5"
        y2="5.5"
        stroke="black"
        strokeLinecap="round"
      />
      <line
        x1="13.5"
        y1="10.5"
        x2="0.5"
        y2="10.5"
        stroke="black"
        strokeLinecap="round"
      />
    </svg>
  );
};
export default MenuIcon;
