import React, { useState, useEffect } from "react";
import { Grid, Divider, Typography } from "@material-ui/core";
import useStyles from "../styles";
import FormModal from "../../../components/common/FormModal";
import DeliveryPartnerForm from "./DeliveryPartnerForm";
import ShiprocketForm from "./ShiprocketForm";
import DeliveryBoyForm from "./DeliveryBoyForm";
import { isWindows } from "react-device-detect";
import { useSelector } from "react-redux";
import Controls from "../../../components/controls/Controls";

const OPTIONS = {
  DELIVERY_PARTNER: {
    id: "DELIVERY_PARTNER",
    title: "Delivery Partner",
    img: "https://storage.googleapis.com/shy-pub/_static/app-img/delivery-partner.png",
  },
  DELIVERY_BOY: {
    id: "DELIVERY_BOY",
    title: "Delivery Boy",
    img: "https://storage.googleapis.com/shy-pub/_static/app-img/delivery-boy.png",
  },
  SHIPROCKET: {
    id: "SHIPROCKET",
    title: "Ship Rocket",
    img: "https://storage.googleapis.com/shy-pub/_static/app-img/shiprocket.png",
  },
  XPRESSBEES: {
    id: "XPRESSBEES",
    title: "Xpressbees Logistic",
    img: "https://storage.googleapis.com/shy-pub/_static/app-img/xpressbee.png",
  },
  ITHINK: {
    id: "ITHINK",
    title: "iThink Logistics",
    img: "https://storage.googleapis.com/shy-pub/_static/app-img/ithink.png",
  },
  DELHIVERY: {
    id: "DELHIVERY",
    title: "Delhivery Logistics",
    img: "https://storage.googleapis.com/shy-pub/_static/app-img/delhivery.png",
  },
  SHIPWAY: {
    id: "SHIPWAY",
    title: "Shipway",
    img: "https://storage.googleapis.com/shy-pub/_static/app-img/shipway.png",
  },
  ESHIPZ: {
    id: "ESHIPZ",
    title: "eshipz",
    img: "https://storage.googleapis.com/shy-pub/_static/app-img/eshipz/eshipz-no-bg.png",
  },
};
const ShippingOptionsModal = ({
  isOpen,
  order,
  bulkOrdDispatch,
  selectedItems,
  setselectedItems,
  logisticApps,
  setToast,
  setSuccessToast,
  onClose,
}) => {
  const classes = useStyles();
  const orderState = useSelector((state) => state.orders);
  const orders = orderState.orders;
  const [shippingOption, setShippingOption] = useState("");
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [scale, setScale] = useState(1);

  const [deliveryOptions, setDeliveryOptions] = useState([]);

  useEffect(() => {
    if (isWindows) {
      setScale(window.devicePixelRatio - 0.07);
    }
  }, []);

  useEffect(() => {
    let newOptions = [];
    newOptions.push(OPTIONS.DELIVERY_PARTNER);
    newOptions.push(OPTIONS.DELIVERY_BOY);
    if (logisticApps && Object.keys(logisticApps).length > 0) {
      if (logisticApps.shiprocket) {
        newOptions.push(OPTIONS.SHIPROCKET);
      }
      if (logisticApps.xpressbees) {
        newOptions.push(OPTIONS.XPRESSBEES);
      }
      if (logisticApps.ithink) {
        newOptions.push(OPTIONS.ITHINK);
      }
      if (logisticApps.delhivery) {
        newOptions.push(OPTIONS.DELHIVERY);
      }
      if (logisticApps.shipway) {
        newOptions.push(OPTIONS.SHIPWAY);
      }
      if (logisticApps.eshipz) {
        newOptions.push(OPTIONS.ESHIPZ);
      }
    }
    setDeliveryOptions(newOptions);

    let defaultShipOpt = localStorage.getItem("default_shipping_option");

    if (
      logisticApps &&
      logisticApps.shiprocket &&
      defaultShipOpt === "SHIPROCKET"
    ) {
      setShippingOption("SHIPROCKET");
    } else if (defaultShipOpt === "DELIVERY_BOY") {
      setShippingOption("DELIVERY_BOY");
    } else if (
      logisticApps &&
      logisticApps.xpressbees &&
      defaultShipOpt === "XPRESSBEES"
    ) {
      setShippingOption("XPRESSBEES");
    } else if (
      logisticApps &&
      logisticApps.ithink &&
      defaultShipOpt === "ITHINK"
    ) {
      setShippingOption("ITHINK");
    } else if (
      logisticApps &&
      logisticApps.delhivery &&
      defaultShipOpt === "DELHIVERY"
    ) {
      setShippingOption("DELHIVERY");
    } else if (
      logisticApps &&
      logisticApps.shipway &&
      defaultShipOpt === "SHIPWAY"
    ) {
      setShippingOption("SHIPWAY");
    } else if (
      logisticApps &&
      logisticApps.eshipz &&
      defaultShipOpt === "ESHIPZ"
    ) {
      setShippingOption("ESHIPZ");
    } else {
      setShippingOption("DELIVERY_PARTNER");
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (bulkOrdDispatch) {
      let selctedOrdId = Object.keys(selectedItems);
      let matchedOrders = orders.filter((order) =>
        selctedOrdId.includes(order.id.toString())
      );
      setSelectedOrders(matchedOrders);
      console.log("matchedorders", matchedOrders);
    }
    //eslint-disable-next-line
  }, [selectedItems, orders]);

  const getOrderIds = () => {
    let str = "";
    Object.values(selectedItems).forEach((item, i) => {
      str =
        str +
        item +
        `${Object.entries(selectedItems).length === i + 1 ? "" : ", "}`;
    });
    return str;
  };

  return (
    <FormModal
      open={isOpen}
      heading={"Select Shipping Option"}
      width={750}
      btnTxt={"Dispatch"}
      onClose={() => onClose()}
      hideCombinedButton={true}
      hideBottomStickyButton={true}
      headingStyle={{ fontSize: "20px" }}
    >
      <div>
        <div style={{ display: "flex" }}>
          <div
            item
            style={{ marginBottom: 25, flexBasis: "28%", marginRight: 16 }}
          >
            <div className={classes.sideOrdDetail}>
              {bulkOrdDispatch ? (
                <div style={{ marginBottom: 16 }}>
                  <Typography
                    style={{ fontSize: 14, fontWeight: 600, marginBottom: 2 }}
                  >
                    Selected Order Ids
                  </Typography>
                  <Typography style={{ fontSize: 14 }}>
                    {getOrderIds()}
                  </Typography>
                </div>
              ) : (
                <>
                  <div>
                    <Typography className={classes.orderDetailHead}>
                      Order ID
                      <Typography className={classes.orderDetailText}>
                        {order.display_id}
                      </Typography>
                    </Typography>
                    <Typography className={classes.orderDetailHead}>
                      Order Details
                      <Typography className={classes.orderDetailText}>
                        {order.payment_mode}
                      </Typography>
                      <Typography className={classes.orderDetailText}>
                        Total Amt - ₹{order.final_sale_price}
                      </Typography>
                      <Typography className={classes.orderDetailText}>
                        Due Amt - ₹{order.due_amount}
                      </Typography>
                    </Typography>
                    <Typography className={classes.orderDetailHead}>
                      Contact
                      <Typography
                        className={classes.orderDetailText}
                      >{`${order.order_address.customer_name}, ${order.order_address.mobile}`}</Typography>
                    </Typography>
                    <Typography className={classes.orderDetailHead}>
                      Address
                      <Typography
                        className={classes.orderDetailText}
                      >{`${order.order_address.street1} ${order.order_address.street2}, ${order.order_address.city}, ${order.order_address.state}, ${order.order_address.pincode}`}</Typography>
                    </Typography>
                  </div>
                </>
              )}
            </div>
          </div>
          <div style={{ flexBasis: "70%" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controls.Select
                  name="deliverymethod"
                  label="Select Method"
                  value={shippingOption}
                  options={deliveryOptions}
                  errorElm={false}
                  onChange={(e) => {
                    setShippingOption(e.target.value);
                  }}
                  mainLabelWidth={110}
                  imgClass={classes.selectimg}
                  labelStyles={{ marginTop: 0 }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                />
              </Grid>
            </Grid>
            <Divider style={{ margin: "16px 0px" }} />
            {shippingOption === "DELIVERY_PARTNER" && (
              <DeliveryPartnerForm
                order={order}
                bulkOrdDispatch={bulkOrdDispatch}
                selectedItems={selectedItems}
                setselectedItems={setselectedItems}
                selectedOrders={selectedOrders}
                setToast={setToast}
                onClose={onClose}
                scale={scale}
              />
            )}
            {(shippingOption === "SHIPROCKET" ||
              shippingOption === "XPRESSBEES" ||
              shippingOption === "DELHIVERY" ||
              shippingOption === "SHIPWAY" ||
              shippingOption === "ESHIPZ" ||
              shippingOption === "ITHINK") && (
              <ShiprocketForm
                order={order}
                bulkOrdDispatch={bulkOrdDispatch}
                selectedItems={selectedItems}
                setselectedItems={setselectedItems}
                selectedOrders={selectedOrders}
                setSuccessToast={setSuccessToast}
                onClose={onClose}
                scale={scale}
                shippingOption={shippingOption}
              />
            )}
            {shippingOption === "DELIVERY_BOY" && (
              <DeliveryBoyForm
                order={order}
                bulkOrdDispatch={bulkOrdDispatch}
                selectedItems={selectedItems}
                setselectedItems={setselectedItems}
                selectedOrders={selectedOrders}
                setSuccessToast={setSuccessToast}
                onClose={onClose}
                scale={scale}
              />
            )}
          </div>
        </div>
      </div>
    </FormModal>
  );
};

export default ShippingOptionsModal;
