import { Box, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Controls from "../../../../components/controls/Controls";
import { useSelector } from "react-redux";
import {
  getStoreAttributes,
  updateAttributes,
} from "../../../../utils/storeUtil";
import Toast from "../../../../components/Layout/Toast";
import SolidButton from "../../../../components/buttons/SolidButton";
import { DimensionField } from "../../../../components/controls/DimensionField";
import Select from "../../../../components/controls/Select";
import PageLoader from "../../../../components/Layout/PageLoader";

const OrderSyncSettings = () => {
  const store = useSelector((state) => state.store.store);
  const [pickupLocations, setPickupLocations] = useState([]);
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState("success");
  const [formState, setFormState] = useState({
    length: { value: "", touched: false, error: "" },
    breadth: { value: "", touched: false, error: "" },
    height: { value: "", touched: false, error: "" },
    weight: { value: "", touched: false, error: "" },
    auto_sync: { value: false, touched: false },
    pickup_location: { value: "none", touched: false, error: "" },
  });

  const handleInputChange = (value, field) => {
    setFormState({
      ...formState,
      [field]: { ...formState[field], value, touched: true, error: "" },
    });
  };

  const handlePickupLocationChange = (e) => {
    const selectedLocation = e.target.value;
    setFormState({
      ...formState,
      pickup_location: {
        value: selectedLocation,
        touched: true,
        error: "",
      },
    });
  };

  const handleSwitchChange = async (e) => {
    const autoSyncValue = e.target.checked;
    handleInputChange(autoSyncValue, "auto_sync");
    if (!autoSyncValue) {
      const formattedData = [
        {
          name: "auto_sync",
          value: autoSyncValue,
        },
      ];
      await updateAttributes(store.slug, "plugin-shiprocket", formattedData);
      setToastMessage("Order sync settings disabled successfully");
      setToastSeverity("success");
      setToast(true);
    }
  };

  const validateNumber = (value) => {
    return !isNaN(value) && Number(value) > 0;
  };
  const fetchPickupLocations = async () => {
    let shipOpt = "shiprocket";
    let accessToken = localStorage.getItem("access_token");
    let storeSlug = store.slug;
    try {
      let response = await fetch(
        `${process.env.REACT_APP_API}/api/v1/store/${storeSlug}/pickup-locations?app=${shipOpt}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) throw response;

      let data = await response.json();
      setPickupLocations(data.payload);
    } catch (err) {
      setPickupLocations(null);
    }
  };

  const validateForm = () => {
    let isValid = true;
    const newState = { ...formState };

    if (
      !formState.pickup_location.value.trim() ||
      formState.pickup_location.value === "none"
    ) {
      newState.pickup_location.error = "Please select a pickup location";
      isValid = false;
    }

    if (!validateNumber(formState.length.value)) {
      newState.length.error = "Length can accept only positive numeric values";
      isValid = false;
    }
    if (!validateNumber(formState.breadth.value)) {
      newState.breadth.error = "Width can accept only positive numeric values";
      isValid = false;
    }
    if (!validateNumber(formState.height.value)) {
      newState.height.error = "Height can accept only positive numeric values";
      isValid = false;
    }
    if (!validateNumber(formState.weight.value)) {
      newState.weight.error = "Please input a valid number";
      isValid = false;
    }

    setFormState(newState);
    return isValid;
  };

  const handleSave = async () => {
    if (!formState.auto_sync.value) {
      setToastMessage("Please enable auto-sync to proceed");
      setToastSeverity("error");
      setToast(true);
      return;
    }
    if (validateForm()) {
      const formattedData = Object.keys(formState).map((key) => ({
        name: key,
        value: formState[key].value,
      }));
      await updateAttributes(store.slug, "plugin-shiprocket", formattedData);
      setToastMessage("Order sync settings saved successfully");
      setToastSeverity("success");
      setToast(true);
    }
  };

  useEffect(() => {
    if (store.slug) {
      fetchPluginAttributes();
      fetchPickupLocations();
    }
  }, [store.slug]);
  const fetchPluginAttributes = async () => {
    try {
      const response = await getStoreAttributes(
        store.slug,
        "plugin-shiprocket"
      );
      if (response) {
        const initialFormData = {
          length: { value: response.length || "", touched: false, error: "" },
          breadth: { value: response.breadth || "", touched: false, error: "" },
          height: { value: response.height || "", touched: false, error: "" },
          weight: { value: response.weight || "", touched: false, error: "" },
          pickup_location: {
            value: response.pickup_location || "none",
            touched: false,
            error: "",
          },
          auto_sync: {
            value: response.auto_sync === "true",
            touched: false,
          },
        };
        setFormState(initialFormData);
      }
    } catch (error) {
      console.error("Error fetching contact information:", error);
    }
  };

  if (!store) {
    return <PageLoader />;
  }

  return (
    <div>
      <Typography style={{ fontSize: "14px", fontWeight: "600" }}>
        Order Auto Sync Settings
      </Typography>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "5px",
        }}
      >
        <div style={{ width: "80%", paddingRight: "8px", margin: "auto" }}>
          <Typography style={{ fontSize: "14px" }}>
            Auto sync order to shiprocket upon confirmation
          </Typography>
        </div>

        <div style={{ width: "20%", paddingLeft: "8px" }}>
          <Controls.Switch
            name="auto_sync"
            label=""
            color="secondary"
            value={formState.auto_sync.value}
            onChange={handleSwitchChange}
            width="100%"
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          marginTop: "16px",
        }}
      >
        <div style={{ width: "40%" }}>
          <Typography style={{ fontSize: "14px" }}>
            {" "}
            Default Pickup location
          </Typography>
        </div>
        <div style={{ width: "60%" }}>
          <Select
            name="pickup_location"
            placeholder="Select pickup location"
            value={formState.pickup_location.value}
            options={
              pickupLocations
                ? pickupLocations.map((loc) => ({
                    id: loc.location_id,
                    title: loc.pickup_location,
                  }))
                : []
            }
            onChange={handlePickupLocationChange}
            disabled={!formState.auto_sync.value}
            error={
              formState.pickup_location.error
                ? formState.pickup_location.error
                : ""
            }
            MenuProps={{
              anchorOrigin: { vertical: "bottom", horizontal: "left" },
              transformOrigin: { vertical: "top", horizontal: "left" },
              getContentAnchorEl: null,
            }}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: "8px",
        }}
      >
        <Box style={{ width: "40%" }}>
          <Typography style={{ fontSize: "14px" }}> Order Dimension</Typography>
        </Box>
        <Box display="flex" flexDirection="column" width="60%">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            <DimensionField
              value={formState.length.value}
              placeholder="Length"
              flexBasis="33%"
              hideHelperText={true}
              disabled={!formState.auto_sync.value}
              onChange={(e) => handleInputChange(e.target.value, "length")}
            />
            <Typography
              style={{
                flexBasis: "5%",
                padding: "0px 5px",
                textAlign: "center",
              }}
            >
              x
            </Typography>
            <DimensionField
              value={formState.breadth.value}
              placeholder="Width"
              hideHelperText={true}
              disabled={!formState.auto_sync.value}
              flexBasis="33%"
              onChange={(e) => handleInputChange(e.target.value, "breadth")}
            />
            <Typography
              style={{
                flexBasis: "5%",
                padding: "0px 5px",
                textAlign: "center",
              }}
            >
              x
            </Typography>
            <DimensionField
              value={formState.height.value}
              placeholder="Height"
              disabled={!formState.auto_sync.value}
              hideHelperText={true}
              flexBasis="33%"
              onChange={(e) => handleInputChange(e.target.value, "height")}
            />
          </Box>
          <Box style={{ height: "24px" }}>
            {(formState.length.error ||
              formState.breadth.error ||
              formState.height.error) && (
              <p
                style={{
                  color: "red",
                  fontSize: "12px",
                }}
              >
                {formState.length.error ||
                  formState.breadth.error ||
                  formState.height.error}
              </p>
            )}
          </Box>
        </Box>
      </div>

      <div
        style={{
          display: "flex",
          padding: "4px 0 2px",
        }}
      >
        <div style={{ width: "40%" }}>
          <Typography style={{ fontSize: "14px" }}> Order Weight</Typography>
        </div>
        <div style={{ width: "60%" }}>
          <Controls.Input
            name="weight"
            type="number"
            value={formState.weight.value}
            onChange={(e) => handleInputChange(e.target.value, "weight")}
            error={formState.weight.error ? formState.weight.error : ""}
            disabled={!formState.auto_sync.value}
            endAdornment={
              <span
                style={{
                  fontSize: "12px",
                  color: "#999999",
                  lineHeight: "16px",
                }}
              >
                grams
              </span>
            }
          />
        </div>
      </div>

      <div style={{ float: "right" }}>
        <SolidButton
          variant="contained"
          color="secondary"
          disabled={!formState.auto_sync.value}
          onClick={handleSave}
          style={{ marginTop: "8px" }}
        >
          Save
        </SolidButton>
      </div>
      <Toast
        open={toast}
        message={toastMessage}
        close={() => setToast(false)}
        severity={toastSeverity}
      />
    </div>
  );
};

export default OrderSyncSettings;
