import React, { useEffect } from "react";
import { Typography, Select, MenuItem } from "@material-ui/core";
import useStyles from "./styles";
import { fetchUserStores } from "../../../redux/userstore/userStoreActions";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { CLEAR_COMPLETE, CLEAR_DATA } from "../../../redux/commom/commonTypes";
import { fetchStore } from "../../../redux/store/storeActions";
import { fetchTaxGroups } from "../../../redux/taxGroup/taxGroupActions";
import NoPicIcon from "../../../icons/NoPicIcon";
import { DownArrowIcon } from "../../../icons";
import { customerLogout } from "../../../redux/customer/customerActions";
import { cdnUrl } from "../../../utils/cdnUtils";
import { getHostedSubPath, getStoreIdFromUrl } from "../../../utils/storeUtil";
import { isCustomDomain } from "../../../utils/utils";

const StoreSwitcher = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const storeId = getStoreIdFromUrl();
  const state = useSelector((state) => state);
  const stores = state.userstore.stores;
  const loaded = state.userstore.loaded;

  useEffect(() => {
    dispatch(fetchUserStores());
    dispatch(fetchStore());
    dispatch(fetchTaxGroups());
    // eslint-disable-next-line
  }, []);

  const handleChange = (event) => {
    if (event.target.value === "__ACTION_SIGNOUT_BUTTON") {
      dispatch(customerLogout());
      dispatch({
        type: CLEAR_COMPLETE,
        payload: {},
      });
      history.push(isCustomDomain() ? "/admin/login" : "/login");
    } else {
      dispatch({
        type: CLEAR_DATA,
        payload: {},
      });
      history.push(getHostedSubPath(event.target.value, "/dashboard"));
      dispatch(fetchStore());
    }
  };

  if (stores && stores.length === 1) {
    return (
      <>
        {stores[0].logo_url ? (
          <div>
            <img
              alt="Logo"
              style={{ width: "unset", height: 40 }}
              src={cdnUrl(stores[0].logo_url, null, 200, 1)}
            />
          </div>
        ) : (
          <div className={classes.storeitem} style={{ padding: 3 }}>
            <NoPicIcon height={20} width={20} />
          </div>
        )}
        <div style={{ marginRight: 16 }}></div>
        <Typography
          className={classes.menutxt}
          style={{ minWidth: 0, userSelect: "none" }}
        >
          {stores[0].store_name}
        </Typography>
      </>
    );
  }

  return (
    <>
      {loaded && storeId && (
        <Select
          id="store-select"
          value={storeId}
          onChange={handleChange}
          disableUnderline={true}
          classes={{
            selectMenu: classes.selectmenu,
          }}
          MenuProps={{
            classes: {
              paper: classes.dropdownMenuContainerDesk,
            },
          }}
          IconComponent={DownArrowIcon}
        >
          {stores.map((store, index) => (
            <MenuItem
              key={index + 1}
              value={store.store_id.toString()}
              className={classes.menuitem}
              classes={{ gutters: classes.menuitemGutter }}
            >
              {store.logo_url ? (
                <div>
                <img
                  alt="Logo"
                  style={{ width: "unset", height: 32 }}
                  src={cdnUrl(store.logo_url, null, 200, 1)}
                />
              </div>
              ) : (
                <div className={classes.storeitem} style={{ padding: 3 }}>
                  <NoPicIcon height={20} width={20} />
                </div>
              )}

              <div style={{ marginRight: 16 }}></div>
              <Typography
                className={classes.menuText}
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {store.store_name}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      )}
    </>
  );
};
export default withRouter(StoreSwitcher);
