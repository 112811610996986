import React, { useState, useEffect } from "react";
import SolidButton from "../../components/buttons/SolidButton";
import HeaderBreadcrumbs from "../../components/common/HeaderBreadcrumbs";
import NoRecord from "../../components/invoiceorder/NoRecord";
import {
  layoutBodyBox,
  layoutContainer,
  layoutLoaderHeader,
  layoutSubHeader,
} from "../../styles/common";
import { Grid } from "@material-ui/core";
import PageLoader from "../../components/Layout/PageLoader";
import { useHistory } from "react-router-dom";
import { AddIcon } from "../../icons";
import NoBannerFigma from "../../icons/NoBannerFigma";
import BannerCard from "./components/BannerCard";
import {
  changeBannerPosition,
  clearBannerError,
  deleteBanner,
  fetchBanners,
  setSuccessToast,
  toggleBannerActivation,
} from "../../redux/banners/bannerActions";
import { useDispatch, useSelector } from "react-redux";
import { getStoreIdFromUrl } from "../../utils/storeUtil";
import Toast from "../../components/Layout/Toast";
import ConfirmModal from "../../components/common/ConfirmModal";

const Banners = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const storeId = getStoreIdFromUrl();
  const bannerState = useSelector((state) => state.banners);
  const banners = bannerState.banners;
  const progress = bannerState.progress;
  const error = bannerState.error;

  const [deletingBanner, setDeletingBanner] = useState(null);

  useEffect(() => {
    if (banners.length === 0) {
      dispatch(fetchBanners());
    }
    // eslint-disable-next-line
  }, [storeId]);

  const onEdit = (bannerId) => {
    history.push(`./banners/${bannerId}`);
  };

  const onToogle = (bannerId, state) => {
    dispatch(toggleBannerActivation(bannerId, state));
  };

  const deleteBannerPopup = (banner) => {
    setDeletingBanner(banner);
  };

  const onPositionChange = (bannerId, positionState) => {
    dispatch(changeBannerPosition(bannerId, positionState));
  };

  if (progress) {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} className={layoutLoaderHeader}>
            <div className={layoutContainer}>
              <HeaderBreadcrumbs list={[{ label: "Banners" }]} />
              <PageLoader />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
  return (
    <div className={layoutContainer}>
      <div className={layoutSubHeader}>
        <HeaderBreadcrumbs list={[{ label: "Banners" }]} />
        <SolidButton
          startIcon={<AddIcon />}
          onClick={() => history.push("./banners/add")}
          style={{ width: 185 }}
        >
          Add Banner
        </SolidButton>
      </div>
      {banners.length === 0 ? (
        <NoRecord
          text="Start Adding Banners for your Online Store"
          image={<NoBannerFigma />}
          arrow={true}
        />
      ) : (
        <div style={{ position: "relative", width: "100%" }}>
          <div className={layoutBodyBox}>
            <div style={{ padding: "8px 32px 32px" }}>
              <Grid container spacing={3}>
                {banners.map((banner, index) => {
                  return (
                    <Grid item lg={4} md={4} sm={6} xs={12} key={index}>
                      <BannerCard
                        banner={banner}
                        index={index + 1}
                        onEdit={onEdit}
                        onToggle={onToogle}
                        onDelete={deleteBannerPopup}
                        onPositionChange={onPositionChange}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </div>
        </div>
      )}
      <ConfirmModal
        open={deletingBanner ? true : false}
        message={<span>Are you sure you want to delete this banner ?</span>}
        closeBtnText="Cancel"
        confirmBtnText="Delete"
        onClose={() => {
          setDeletingBanner(null);
        }}
        onConfirm={() => {
          dispatch(deleteBanner(deletingBanner.id));
          setDeletingBanner(null);
        }}
      />
      <Toast
        open={bannerState.successToast}
        close={() => dispatch(setSuccessToast(false))}
        message={bannerState.message}
      />
      <Toast
        open={error ? true : false}
        close={() => dispatch(clearBannerError())}
        message="Something went wrong, please try again!"
        severity="error"
      />
    </div>
  );
};

export default Banners;
