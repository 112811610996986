import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const AbandonedCartsIcon = ({ viewBox = "0 0 56 56" }) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "56px", height: "56px" }}>
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="56" height="56" rx="12" fill="#B3263F" fillOpacity="0.1" />
        <path
          d="M22 42C23.1046 42 24 41.1046 24 40C24 38.8954 23.1046 38 22 38C20.8954 38 20 38.8954 20 40C20 41.1046 20.8954 42 22 42Z"
          fill="#B3263F"
        />
        <path
          d="M36 42C37.1046 42 38 41.1046 38 40C38 38.8954 37.1046 38 36 38C34.8954 38 34 38.8954 34 40C34 41.1046 34.8954 42 36 42Z"
          fill="#B3263F"
        />
        <path
          d="M16.98 14.804C16.9347 14.5774 16.8123 14.3735 16.6337 14.2269C16.455 14.0803 16.2311 14.0001 16 14H12V16H15.18L19.02 35.196C19.0653 35.4226 19.1877 35.6265 19.3663 35.7731C19.545 35.9197 19.7689 35.9999 20 36H38V34H20.82L20.02 30H38C38.2275 30 38.4481 29.9224 38.6256 29.78C38.803 29.6377 38.9266 29.4391 38.976 29.217L41.244 19H39.197L37.198 28H19.62L16.98 14.804Z"
          fill="#B3263F"
        />
        <path
          d="M29 14C27.8133 14 26.6533 14.3519 25.6666 15.0112C24.6799 15.6705 23.9109 16.6075 23.4567 17.7039C23.0026 18.8003 22.8838 20.0067 23.1153 21.1705C23.3468 22.3344 23.9182 23.4035 24.7574 24.2426C25.5965 25.0818 26.6656 25.6532 27.8295 25.8847C28.9933 26.1162 30.1997 25.9974 31.2961 25.5433C32.3925 25.0892 33.3295 24.3201 33.9888 23.3334C34.6481 22.3467 35 21.1867 35 20C34.9982 18.4093 34.3654 16.8842 33.2406 15.7594C32.1158 14.6346 30.5907 14.0019 29 14ZM29 16C29.7118 16.0037 30.4093 16.1996 31.019 16.567L25.567 22.019C25.1997 21.4093 25.0038 20.7118 25 20C25.0013 18.9395 25.4232 17.9229 26.173 17.173C26.9229 16.4232 27.9395 16.0013 29 16ZM29 24C28.2882 23.9963 27.5907 23.8004 26.981 23.433L32.433 17.981C32.8003 18.5907 32.9962 19.2882 33 20C32.9987 21.0605 32.5768 22.0771 31.827 22.827C31.0771 23.5768 30.0605 23.9987 29 24Z"
          fill="#B3263F"
        />
      </svg>
    </SvgIcon>
  );
};

export default AbandonedCartsIcon;
