import {
  Typography,
  Button,
  makeStyles,
  TextField,
  Modal,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CloseIcon } from "../../icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import CombinedButtons from "../buttons/CombinedButtons";
import { getStoreIdFromUrl } from "../../utils/storeUtil";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontWeight: 700,
    fontSize: 24,
    lineHeight: "32px",
    color: "#1A1A1A",
  },
  dividerGrid: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "space-between",
    paddingTop: 12,
    paddingBottom: 18,
    borderBottom: "1px solid #E6E6E6",
  },
  helperText: {
    fontWeight: 400,
    fontSize: 12,
    color: "#999999",
    paddingTop: 10,
    marginBottom: -4,
  },
}));

const SEOModal = ({ open, setOpen, sku, setToast, setMessage }) => {
  const classes = useStyles();
  const store = useSelector((state) => state.store.store);
  const [values, setValues] = useState({
    title: "",
    description: "",
    keywords: [],
  });

  useEffect(() => {
    if (store.id) {
      init();
    }
    // eslint-disable-next-line
  }, [store]);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const init = async () => {
    let storeId = getStoreIdFromUrl();
    let accessToken = localStorage.getItem("access_token");
    let res = await axios.get(
      `${process.env.REACT_APP_API}/api/v2/business/store/${storeId}/product/find-by-sku/${sku}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    let payload = res.data.payload;
    setValues({
      title: payload.seo_title ? payload.seo_title : "",
      description: payload.seo_description ? payload.seo_description : "",
      keywords: payload.seo_keywords ? payload.seo_keywords.split(",") : [],
    });
  };

  const onSave = async () => {
    setOpen(false);
    let accessToken = localStorage.getItem("access_token");
    let keywordsString = "";
    if (values.keywords && values.keywords.length > 0) {
      keywordsString = values.keywords.join(",");
    }
    const data = {
      seo_title: values.title,
      seo_description: values.description,
      seo_keywords: keywordsString,
    };
    let storeId = getStoreIdFromUrl();
    try {
      await axios.patch(
        `${process.env.REACT_APP_API}/api/v2/business/store/${storeId}/product/${sku}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      setMessage("SEO Changes Saved");
      setToast(true);
    } catch (err) {
      setMessage("Failed to save SEO Changes");
      setToast(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          padding: 25,
          borderRadius: 12,
          paddingBottom: 16,
          position: "absolute",
          background: "#ffffff",
          width: 546,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 25,
          }}
        >
          <Typography className={classes.heading}>Product SEO</Typography>
          <Button onClick={() => setOpen(false)} style={{ outline: "none" }}>
            <CloseIcon />
          </Button>
        </div>
        <div className={classes.dividerGrid} style={{ display: "block" }}>
          <Typography className={classes.subHeading}>Title</Typography>
          <TextField
            name="title"
            color="secondary"
            variant="outlined"
            fullWidth
            value={values.title}
            onChange={handleChange}
            style={{ marginTop: 12 }}
            inputProps={{ style: { fontSize: 14 } }}
          />
        </div>
        <div className={classes.dividerGrid} style={{ display: "block" }}>
          <Typography className={classes.subHeading}>Description</Typography>
          <TextField
            name="description"
            color="secondary"
            variant="outlined"
            fullWidth
            value={values.description}
            onChange={handleChange}
            style={{ marginTop: 12 }}
            inputProps={{ style: { fontSize: 14 } }}
            rows="3"
            multiline
          />
        </div>
        <div className={classes.dividerGrid} style={{ display: "block" }}>
          <Typography className={classes.subHeading}>Keywords</Typography>
          <Autocomplete
            multiple
            limitTags={3}
            options={[]}
            color="secondary"
            variant="outlined"
            value={values.keywords}
            onChange={(_, value) => {
              setValues({ ...values, keywords: value });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                color="secondary"
                variant="outlined"
                style={{ marginTop: 12 }}
              />
            )}
            freeSolo
          />
          <Typography className={classes.helperText}>
            Press enter to add a keyword
          </Typography>
        </div>
        <div
          style={{
            height: "50px",
            position: "relative",
            marginTop: 50,
            marginBottom: 7,
          }}
        >
          <div style={{ position: "absolute", bottom: 0, right: 0 }}>
            <CombinedButtons
              outlinedBtnAction={() => {
                setOpen(false);
              }}
              solidBtnAction={onSave}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SEOModal;