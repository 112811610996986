// Core react imports
import React, { useState } from "react";
// Ui lib imports
import { Modal, Paper, makeStyles, Box, Button } from "@material-ui/core";
// Icon imports
import CloseIcon from "../../../icons/CloseIcon";
import WhatsappShare from "../../../icons/whatsappShare";
import Facebook from "../../../icons/facebook";
import TwitterShare from "../../../icons/twitterShare";
// Custom component imports
import Toast from "../../../components/Layout/Toast";
// Redux imports
import { useSelector } from "react-redux";
// Util imports
import { copyToClipboard } from "../../../utils/utils";
import { getStoreUrl } from "../../../utils/storeUtil";
// Style hook creation
const useStyles = makeStyles((theme) => ({
  modalBody: {
    position: "absolute",
    top: "20%",
    left: "33%",
    width: "40%",
    padding: 40,
    borderRadius: 16,
  },
  closeIcon: { padding: "4px", cursor: "pointer" },
  modalHead: {
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
  },
  infoText: {
    fontSize: "16px",
    lineHeight: "20px",
    color: "#666666",
    margin: 0,
  },
  hoverable: {
    cursor: "pointer",
  },
  horizontalDivider: {
    border: "1px solid #E5E5E5",
    margin: "24px 0px",
    width: "100%",
  },
  infoHighlight: {
    color: "#000",
    fontWeight: "bold",
  },
  platformName: {
    color: "#666666",
    fontSize: "14px",
    lineHeight: "20px",
    margin: 0,
    marginTop: "6px",
    textAlign: "center",
  },
  promotionHeader: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#1A1A1A",
    margin: 0,
    marginTop: "-10px",
  },
  promotionTeaser: {
    padding: "6px",
    fontSize: "16px",
    lineHeight: "20px",
    whiteSpace: "noWrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#666",
    border: "1px solid #999",
    height: "32px",
  },
}));
// Default component export
export default function ShareModal(props) {
  const [successOpen, setSuccessOpen] = useState(false);
  const [failOpen, setFailOpen] = useState(false);
  const classes = useStyles();
  let store = useSelector((state) => state.store);
  store = store.store;

  return (
    <>
      <Modal
        open={props.isOpen}
        onClose={props.onClose}
        disableAutoFocus
        disableEnforceFocus
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper className={classes.modalBody}>
          <div>
            <Box display="flex" justifyContent="space-between">
              <h3 className={classes.modalHead}>Share</h3>
              <span
                className={classes.closeIcon}
                onClick={() => props.onClose()}
              >
                <CloseIcon />
              </span>
            </Box>
          </div>
          <div className={classes.horizontalDivider} />
          <div>
            <p className={classes.infoText}>
              Share you coupons to social media platforms and generate the hype
              that your store needs. Choose from a wide variety of social
              platforms and make sure to use{" "}
              <span className={classes.infoHighlight}>hashtags</span> and{" "}
              <span className={classes.infoHighlight}>keywords</span> to target
              the right audience.
            </p>
          </div>
          <div className={classes.horizontalDivider} />
          <div>
            <Box display="flex" justifyContent="space-around">
              <Box display="flex" flexDirection="column">
                <div
                  onClick={() => {
                    window.open(
                      "https://web.whatsapp.com/send" +
                        objectToGetParams({
                          text: getPromotionText(
                            props.coupon,
                            store,
                            "whatsapp"
                          ),
                        })
                    );
                  }}
                  className={classes.hoverable}
                >
                  <WhatsappShare />
                </div>
                <p className={classes.platformName}>Whatsapp</p>
              </Box>
              <Box display="flex" flexDirection="column">
                <div
                  className={classes.hoverable}
                  onClick={() => {
                    window.open(
                      "https://www.facebook.com/sharer/sharer.php" +
                        objectToGetParams({
                          u: getStoreUrl(store),
                          quote: getPromotionText(
                            props.coupon,
                            store,
                            "facebook"
                          ),
                        })
                    );
                  }}
                >
                  <Facebook />
                </div>
                <p className={classes.platformName}>Facebook</p>
              </Box>
              <Box display="flex" flexDirection="column">
                <div
                  className={classes.hoverable}
                  onClick={() => {
                    window.open(
                      "https://www.twitter.com/share/" +
                        objectToGetParams({
                          url: getStoreUrl(store),
                          text: getPromotionText(
                            props.coupon,
                            store,
                            "twitter"
                          ),
                        })
                    );
                  }}
                >
                  <TwitterShare />
                </div>
                <p className={classes.platformName}>Twitter</p>
              </Box>
            </Box>
          </div>
          <div className={classes.horizontalDivider} />
          {store && props.coupon && (
            <div>
              <p className={classes.promotionHeader}> or Copy promotion text</p>
              <Box
                style={{ marginTop: "16px" }}
                display="flex"
                flexDirection="row"
              >
                <Box className={classes.promotionTeaser}>
                  {getPromotionText(props.coupon, store, "facebook")}
                </Box>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{
                    height: "32px",
                    borderRadius: 0,
                    textTransform: "none",
                  }}
                  onClick={() => {
                    let result = copyToClipboard(
                      getPromotionText(props.coupon, store, "facebook")
                    );
                    if (!result) {
                      setFailOpen(true);
                    } else {
                      setSuccessOpen(true);
                    }
                  }}
                >
                  Copy
                </Button>
              </Box>
            </div>
          )}
        </Paper>
      </Modal>
      <Toast
        open={successOpen}
        close={() => setSuccessOpen(false)}
        message="Copied to clipboard"
        savirity="success"
      />
      <Toast
        open={failOpen}
        close={() => setFailOpen(false)}
        message="Could not copy text"
        severity="error"
      />
    </>
  );
}
// Helper functions
function objectToGetParams(object) {
  const params = Object.entries(object)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
    );

  return params.length > 0 ? `?${params.join("&")}` : "";
}

function getPromotionText(coupon, store, channel) {
  return `Hey guys checkout my store ${
    channel === "twitter" ? "" : "at " + getStoreUrl(store)
  }, and use the coupon code: ${
    channel === "whatsapp" ? "*" + coupon.code + "*" : coupon.code
  } to get an amazing discount. Hurry Limited period offer only.\n`;
}
