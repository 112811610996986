import { CLEAR_DATA, CLEAR_COMPLETE } from "../commom/commonTypes";
import {
  FETCH_PURCHASES,
  PURCHASE_PROGRESS,
  UPDATE_PURCHASE,
  SET_PURCHASE_ERROR,
  SET_PURCHASE_LOADER,
  CREATE_PURCHASE,
  CLEAR_PURCHASE,
  CLEAR_COMPLETE_PURCHASE,
  DELETE_PURCHASE,
  PURCHASE_DATE_SELECTOR,
  PURCHASE_STATUS_SELECTOR,
  PURCHASE_DATE_RANGE,
  SET_PURCHASES_PER_PAGE,
  SET_PURCHASES_CURRENT_PAGE,
  SET_PURCHASES_MAX_PAGE_SO_FAR,
  SET_PURCHASES_INPUT_KEYWORD,
  SET_PURCHASES_SEARCH_KEYWORD,
  SET_PURCHASES_CUSTOMER_ID,
} from "./purchaseTypes";

const initialState = {
  purchases: [],
  purchasesMap: {},
  purchase: null,
  numberOfPages: 0,
  numberOfElements: 0,
  progress: true,
  loader: false,
  error: null,
  dateSelector: null,
  statusSelector: null,
  dateRange: {
    aoe: null,
    boe: null,
  },
  purchasesPerPage: null,
  currentPage: 0,
  maxPageSoFar: 0,
  searchKeyword: "",
  inputKeyword: "",
  customerId: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PURCHASE_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      };
    case FETCH_PURCHASES:
      let allPurchases = state.purchases;
      let page = action.payload.pageable.pageNumber;
      if (page > 0 && state.purchases.length > action.purchasesPerPage * page) {
        allPurchases = state.purchases.slice(0, action.purchasesPerPage * page);
      }
      let newPurchases =
        action.payload.pageable.pageNumber > 0
          ? [...allPurchases, ...action.payload.content]
          : action.payload.content;

      let newPurchasesMap = {};
      newPurchases.forEach((element) => {
        newPurchasesMap[element.invoice_id] = element;
      });
      return {
        ...state,
        purchases: newPurchases,
        purchasesMap: newPurchasesMap,
        numberOfPages: action.payload.totalPages,
        numberOfElements: action.payload.totalElements,
        progress: false,
      };
    case CREATE_PURCHASE:
      let pageCnt = state.numberOfPages;
      let localPagePurchases = state.purchases;
      let count = state.purchases.length;
      if (count + 1 > state.purchasesPerPage) {
        let fullPages = Math.floor((count + 1) / state.purchasesPerPage);
        localPagePurchases = state.purchases.slice(
          0,
          fullPages * state.purchasesPerPage - 1
        );
      }
      let availablePages = Math.floor(
        state.purchases.length / state.purchasesPerPage
      );
      return {
        ...state,
        purchase: action.payload,
        loader: false,
        progress: false,
        purchases: [action.payload, ...localPagePurchases],
        numberOfPages:
          state.numberOfElements === pageCnt * state.purchasesPerPage
            ? pageCnt + 1
            : pageCnt,
        numberOfElements: state.numberOfElements + 1,
        currentPage: 0,
        maxPageSoFar: availablePages > 1 ? availablePages - 1 : 0,
      };
    case UPDATE_PURCHASE:
      let modPurchases = state.purchases.map((pur) => {
        if (pur.id === action.payload.id) {
          return action.payload;
        }
        return pur;
      });

      let modPurchasesMap = {};
      modPurchases.forEach((element) => {
        modPurchasesMap[element.invoice_id] = element;
      });

      return {
        ...state,
        purchases: modPurchases,
        purchasesMap: modPurchasesMap,
        purchase: action.payload,
        loader: false,
        progress: false,
      };

    case SET_PURCHASE_ERROR:
      return {
        ...state,
        error: action.payload.error,
        progress: false,
      };
    case SET_PURCHASE_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case CLEAR_PURCHASE:
      return {
        ...state,
        purchase: action.payload,
      };
    case DELETE_PURCHASE:
      return {
        ...state,
        purchases: state.purchases.filter(
          (purchase) => purchase.id !== action.payload.purchaseId
        ),
        loader: false,
      };
    case CLEAR_COMPLETE_PURCHASE:
      return {
        ...state,
        purchases: [],
        purchasesMap: {},
        purchase: null,
        numberOfPages: 0,
        numberOfElements: 0,
        progress: false,
        loader: false,
        error: null,
      };
    case CLEAR_COMPLETE:
    case CLEAR_DATA:
      return {
        ...state,
        purchases: [],
        purchasesMap: {},
        purchase: null,
        numberOfPages: 0,
        numberOfElements: 0,
        progress: true,
        loader: false,
        error: null,
        dateSelector: null,
        statusSelector: null,
        dateRange: {
          aoe: null,
          boe: null,
        },
        purchasesPerPage: null,
        currentPage: 0,
        maxPageSoFar: 0,
        searchKeyword: "",
        inputKeyword: "",
      };
    case PURCHASE_DATE_SELECTOR:
      return {
        ...state,
        dateSelector: action.payload,
      };
    case PURCHASE_STATUS_SELECTOR:
      return {
        ...state,
        statusSelector: action.payload,
      };
    case PURCHASE_DATE_RANGE:
      return {
        ...state,
        dateRange: action.payload,
      };
    case SET_PURCHASES_PER_PAGE:
      return {
        ...state,
        purchasesPerPage: action.payload,
      };
    case SET_PURCHASES_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SET_PURCHASES_MAX_PAGE_SO_FAR:
      return {
        ...state,
        maxPageSoFar: action.payload,
      };
    case SET_PURCHASES_INPUT_KEYWORD:
      return {
        ...state,
        inputKeyword: action.payload,
      };
    case SET_PURCHASES_SEARCH_KEYWORD:
      return {
        ...state,
        searchKeyword: action.payload,
      };
    case SET_PURCHASES_CUSTOMER_ID:
      return {
        ...state,
        customerId: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
