import axios from "axios";
import { getDefaultStore } from "../../../utils/storeUtil";

export const getContainerSize = (theme, type) => {
  const height = 180;
  if (theme === "basic") {
    if (type === "Mobile") {
      return {
        height: height,
        width: (height * 16) / 9,
        aspectRatio: 16 / 9,
        recommendSize: "1024px X 576px (16:9)",
      };
    }
    if (type === "Desktop") {
      return {
        height: height,
        width: (height * 3) / 1,
        aspectRatio: 3 / 1,
        recommendSize: "1920px X 640px (3:1)",
      };
    }
  } else if (theme === "fashion") {
    if (type === "Mobile") {
      return {
        height: height,
        width: (height * 1) / 1,
        aspectRatio: 1 / 1,
        recommendSize: "800px X 800px (1:1)",
      };
    }
    if (type === "Desktop") {
      return {
        height: height,
        width: (height * 3) / 1,
        aspectRatio: 3 / 1,
        recommendSize: "1920px X 640px (3:1)",
      };
    }
  }
};

export const fetchBannerById = async (id) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API}/api/v1/org/stores/${store.store_id}/offers/${id}`,

      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (err) {
    console.log("Failure while fetching banners", err);
  }
};
