import React, { useEffect, useState } from "react";
import { NoPicIcon } from "../../../icons";
import { cdnUrl } from "../../../utils/cdnUtils";
import { Grid, makeStyles } from "@material-ui/core";
import Controls from "../../../components/controls/Controls";
import ThreeDotsIcon from "../../../icons/ThreeDotsIcon";
import ReOrderSharpIcon from "../../../icons/ReOrderSharpIcon";
import MoveToBottomIcon from "../../../icons/MoveToBottomIcon";
import MoveToUpIcon from "../../../icons/MoveToUpIcon";
import UnassignIcon from "../../../icons/UnassignIcon";
import { ThreeDots } from "react-loader-spinner";

const menuList = [
  { title: "Move to Top", icon: <MoveToUpIcon />, value: "top" },
  { title: "Move to Bottom", icon: <MoveToBottomIcon />, value: "bottom" },
  { title: "Unassign ", icon: <UnassignIcon />, value: "unassign" },
];

const useStyles = makeStyles({
  product: {
    height: "88px",
    width: "100%",
    background: "white",
    borderRadius: 8,
    border: "1px #E6E6E6 solid",
    marginTop: "16px",
    padding: "12px",
    display: "flex",
    justifyContent: "space-between",
    position: "relative",
  },
  flexBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "15px",
  },
  gridItem: {
    display: "flex",
    alignItems: "center",
  },
  productImage: {
    height: 64,
    width: 64,
    borderRadius: 4,
  },
  noProductImage: {
    border: "1px solid #E5E5E5",
    boxSizing: "border-box",
    borderRadius: "4px",
    width: 64,
    height: 64,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  unassign: {
    color: "#1641DB",
    fontSize: 14,
    fontWeight: 600,
    cursor: "pointer",
    padding: "10px",
    borderRadius: 8,
    "&:hover": {
      background: "rgba(220, 220, 220, 0.2)",
    },
  },
  threedots: {
    "&:hover": {
      background: "rgba(220, 220, 220, 0.2)",
    },
    cursor: "pointer",
    height: "30px",
    width: "30px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  itemContainer: {
    position: "absolute",
    top: 50,
    right: 25,
    display: "flex",
    borderRadius: 6,
    background: "#FFFFFF",
    overflow: "hidden",
    zIndex: 1,
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
    flexDirection: "column",
    gap: "16px",
    padding: "12px",
    cursor: "pointer",
  },
  item: {
    "&:hover": {
      background: "rgba(220, 220, 220, 0.2)",
    },
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  productName: {
    width: "270px",
    color: "#1A1A1A",
    fontSize: 14,
    fontWeight: 400,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "&:hover": {},
  },
  category: {
    color: "#999999",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  textContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: 5,
  },
  productInfo: {
    height: "64px",
    width: "270px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
});

const Product = ({
  filter,
  product,
  handleProps,
  manuallyOperation,
  assignProductsPage,
  productUnassign,
  onCheckboxChange,
  unassignSku,
}) => {
  const classes = useStyles();
  const wrapperRef = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const closeOpenMenu = (event) => {
      if (
        wrapperRef.current &&
        openMenu &&
        !wrapperRef.current.contains(event.target)
      ) {
        setOpenMenu(false);
      }
    };

    document.addEventListener("mousedown", closeOpenMenu);
    return () => {
      document.removeEventListener("mousedown", closeOpenMenu);
    };
  }, [openMenu, wrapperRef]);

  return (
    <Grid container className={classes.product}>
      <Grid item className={classes.flexBox}>
        {assignProductsPage && (
          <div>
            <Controls.Checkbox
              color="secondary"
              name=""
              label=""
              value={isChecked}
              onChange={(event) => {
                const result = onCheckboxChange({
                  ssku: product.super_prod_sku,
                  position: null,
                });
                if (result) {
                  setIsChecked(event.target.checked);
                }
              }}
              style={{ marginRight: "-5px" }}
            />
          </div>
        )}
        {filter && filter === "catManual" && !assignProductsPage && (
          <div {...handleProps}>
            <ReOrderSharpIcon />
          </div>
        )}
        <div>
          {product.thumbnail ? (
            <img
              className={classes.productImage}
              alt=""
              src={cdnUrl(product.thumbnail, 200, 200, 1)}
            />
          ) : (
            <div className={classes.noProductImage}>
              <NoPicIcon />
            </div>
          )}
        </div>
        <div className={classes.productInfo}>
          <div title={product.display_name} className={classes.productName}>
            {product.display_name}
          </div>

          <div style={{ width: "280px" }}>
            <span style={{ color: "#999999" }}>Sales Price- </span>
            <span style={{ color: "#1A1A1A" }}>₹{product.sale_price}</span>
          </div>

          {product?.categories?.length > 0 && (
            <div style={{ width: "280px" }} className={classes.category}>
              <span style={{ color: "#999999" }}>Category- </span>
              {product.categories.map((category, index) => (
                <span
                  key={index}
                  style={{
                    color: "#1A1A1A",
                  }}
                  title={category.cat_name}
                >
                  {category.cat_name}
                  {product.categories.length - 1 !== index && ", "}
                </span>
              ))}
            </div>
          )}
        </div>
      </Grid>
      {!assignProductsPage && (
        <Grid item className={classes.gridItem} ref={wrapperRef}>
          {filter !== "catManual" ? (
            <div
              className={classes.unassign}
              id={product.super_prod_sku}
              onClick={productUnassign}
              style={{
                opacity: unassignSku !== product.super_prod_sku ? 1 : 0.5,
                pointerEvents:
                  unassignSku !== product.super_prod_sku ? "auto" : "none",
              }}
            >
              {unassignSku !== product.super_prod_sku ? (
                "Unassign"
              ) : (
                <ThreeDots
                  visible={true}
                  height="10"
                  width="90"
                  color="#1641DB"
                  radius="9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              )}
            </div>
          ) : (
            <>
              <div
                className={classes.threedots}
                onClick={() => {
                  setOpenMenu(!openMenu);
                }}
              >
                <ThreeDotsIcon />
              </div>
              {openMenu && (
                <div className={classes.itemContainer}>
                  {menuList.map((item, index) => (
                    <div
                      key={index}
                      className={classes.item}
                      onClick={() => {
                        manuallyOperation(product, item);
                        setOpenMenu(false);
                      }}
                    >
                      <span>{item.icon}</span>
                      <span>{item.title}</span>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default Product;
