import { CLEAR_DATA, CLEAR_COMPLETE } from "../commom/commonTypes";
import {
  ADD_MENU,
  ADD_MENU_ITEM,
  ADD_SUB_MENU_ITEM,
  CLEAR_MENU_ERROR,
  DELETE_MENU_ITEM,
  DELETE_SUB_MENU_ITEM,
  UPDATE_MENU_ITEM,
  UPDATE_SUB_MENU_ITEM,
  FETCH_MENU,
  MENU_LOADER,
  MENU_PROGRESS,
  SET_MENU_ERROR,
  UPDATE_MENU,
  UPDATE_MENU_ITEM_LIST,
} from "./menuTypes";

const initialState = {
  menu: {},
  menuItems: [],
  menuItem: {},
  progress: true,
  error: null,
  loader: false,
  loaded: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MENU_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      };
    case MENU_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case FETCH_MENU:
      let menuObj = {};
      let menuItems = [];
      let obj = action.payload.find((e) => e.type === "header") ?? {};
      if (Object.keys(obj).length !== 0) {
        menuObj = obj;
        if (obj.menu_items?.length > 0) {
          menuItems = obj.menu_items;
        }
      }
      return {
        ...state,
        menu: menuObj,
        menuItems: menuItems,
        progress: false,
        loaded: true,
      };
    case ADD_MENU:
      return {
        ...state,
        menu: action.payload,
      };
    case UPDATE_MENU:
      let modMenu = { ...state.menu, css: action.payload };
      return {
        ...state,
        menu: modMenu,
      };
    case ADD_MENU_ITEM:
      let newMenuIts = [...state.menuItems, action.payload];
      return {
        ...state,
        menuItem: action.payload,
        menuItems: newMenuIts,
        loader: false,
      };
    case ADD_SUB_MENU_ITEM:
      let newMenuItss = state.menuItems.map((item, id) => {
        if (item.id === action.payload.parentMenuItemId) {
          let children = item?.children
            ? [...item.children, action.payload.subMenuItem]
            : [action.payload.subMenuItem];

          return { ...item, children: children };
        }
        return { ...item };
      });
      return {
        ...state,
        menuItem: action.payload.subMenuItem,
        menuItems: newMenuItss,
        loader: false,
      };
    case UPDATE_MENU_ITEM_LIST:
      return {
        ...state,
        menuItems: action.payload.menu_items,
      };
    case DELETE_MENU_ITEM:
      let modMenuIts = state.menuItems.filter(
        (item) => item.id !== action.payload.id
      );
      return {
        ...state,
        menuItems: modMenuIts,
        loader: false,
      };
    case DELETE_SUB_MENU_ITEM:
      let modMenuItss = state.menuItems.map((item) => {
        if (item.id === action.payload.menuItemId) {
          let modSubMenuItem = item.children.filter(
            (i) => i.id !== action.payload.subMenuItemId
          );
          if (modSubMenuItem.length === 0) {
            let newItem = { ...item };
            delete newItem.children;
            return newItem;
          } else {
            return { ...item, children: modSubMenuItem };
          }
        }
        return { ...item };
      });

      return {
        ...state,
        menuItems: modMenuItss,
        loader: false,
      };
    case UPDATE_MENU_ITEM:
      let editMenuIts = state.menuItems.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload;
        }
        return item;
      });
      return {
        ...state,
        menuItems: editMenuIts,
        loader: false,
      };
    case UPDATE_SUB_MENU_ITEM:
      let replaceObj = state.menuItems.map((item, index) => {
        if (item.id === action.payload.parentMenuItemId) {
          let output = item.children.map((item1, index1) => {
            if (item1.id !== action.payload.subMenuItem.id) {
              return item1;
            }
            return {
              ...item1,
              ...action.payload.subMenuItem,
            };
          });
          return {
            ...item,
            children: output,
          };
        }
        return {
          ...item,
        };
      });
      return {
        ...state,
        menuItems: replaceObj,
        loader: false,
      };
    case SET_MENU_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loader: false,
      };
    case CLEAR_MENU_ERROR:
      return {
        ...state,
        error: null,
      };
    case CLEAR_COMPLETE:
    case CLEAR_DATA:
      return {
        ...state,
        menu: {},
        menuItems: [],
        menuItem: {},
        progress: false,
        error: null,
        loader: false,
      };
    default:
      return state;
  }
};
export default reducer;
