import React from "react";
import useStyles from "./styles";
import { Typography } from "@material-ui/core";
import { isCustomDomain } from "../../../utils/utils";

const LoginBanner = () => {
  const classes = useStyles();
  const customDomain = isCustomDomain();
  let image =
    "https://img.clevup.in/static/shoopy-app/login-screen.png";
  return (
    <div className={classes.bannerRoot}>
      <img src={image} alt="App" className={classes.media} />
      <div className={classes.bannercontent}>
        <Typography className={classes.bannerheading}>Get. Set. Go</Typography>
        <Typography className={classes.bannersubheading}>
          Setup your business in no time with {customDomain ? "us" : "Shoopy"}
        </Typography>
      </div>
    </div>
  );
};

export default LoginBanner;
