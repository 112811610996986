import React from "react";
import Slider from "react-slick";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "../../../../src/pages/appstore/slick.css";
import CarouselPrevBtn from "../../../icons/CarouselPrevBtn";
import CarouselNextBtn from "../../../icons/CarouselNextBtn";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Skeleton } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { fetchOnlineStores } from "../../../redux/dashboard/dashboardActions";
import { getStoreIdFromUrl } from "../../../utils/storeUtil";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "20px",
    marginBottom: "24px",
  },
  subHeading: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16x",
    color: "#666666",
  },
  image: {
    width: "145px",
    height: "279px",
    cursor: "pointer",
    // objectFit: "contain",
  },
  arrow: {
    position: "absolute ",
    cursor: "pointer ",
    top: "calc(50% - 30px)",
    zIndex: 1,
  },
}));

const OnlineStores = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dashboardState = useSelector((state) => state.dashboard);
  const onlineStores = dashboardState.onlineStores;
  const storeId = getStoreIdFromUrl();

  useEffect(() => {
    if (onlineStores.length === 0) {
      dispatch(fetchOnlineStores());
    }
    // eslint-disable-next-line
  }, [storeId]);

  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className={classes.arrow}
        style={{
          right: -2,
        }}
        onClick={onClick}
      >
        <CarouselNextBtn />
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className={classes.arrow}
        style={{
          left: -4,
        }}
        onClick={onClick}
      >
        <CarouselPrevBtn />
      </div>
    );
  };

  const settings = {
    infinite: false,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    className: "center",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        boxShadow: "0px 8px 20px rgba(0,0,0,0.04)",
        borderRadius: 10,
        width: "100%",
        padding: "32px",
      }}
    >
      <Typography className={classes.heading}>
        Online Stores Built Using Shoopy
      </Typography>
      {onlineStores.length === 0 ? (
        <div>
          <Skeleton
            style={{ transform: "none" }}
            animation="wave"
            width="100%"
            height={320}
          />
        </div>
      ) : (
        <div>
          <Slider {...settings}>
            {onlineStores.length !== 0 &&
              onlineStores.map((store, idx) => {
                return (
                  <div key={idx}>
                    <img
                      onClick={() => {
                        window.open(store.link);
                      }}
                      className={classes.image}
                      src={store.image}
                      alt=""
                    />
                    <Typography
                      style={{
                        fontSize: 14,
                        fontWeight: "600",
                        textAlign: "center",
                      }}
                    >
                      {store.tags}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 12,
                        fontWeight: "400",
                        textAlign: "center",
                      }}
                    >
                      {store.title}
                    </Typography>
                  </div>
                );
              })}
          </Slider>
        </div>
      )}
      <style jsx="true">{`
        .slick-slide {
          margin: 0 20px;
        }
        * {
          min-width: 0;
        }
      `}</style>
    </div>
  );
};

export default OnlineStores;
