import React from "react";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  noProductGrid: {
    height: "90%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
  },
  noProductImage: {
    width: "260px",
    height: "156px",
  },
  noProduct: {
    color: "#999999",
    fontSize: 12,
    fontWeight: "400",
  },
  primaryAssignButton: {
    marginTop: "25px",
    height: "40px",
    width: "158px",
    background: "white",
    borderRadius: 4,
    border: "1px #1641DB solid",
    color: "#1641DB",
    fontSize: 14,
    fontWeight: "600",
  },
});

const NoProducts = ({ category, fetchProductsToAssigned }) => {
  const classes = useStyles();
  return (
    <div className={classes.noProductGrid}>
      <div className={classes.container}>
        <div>
          <img
            className={classes.noProductImage}
            src="https://storage.googleapis.com/shy-pub/_static/app-img/no-assigned-products.png"
            alt="noProductAssigned"
          />
        </div>

        <div className={classes.noProduct}>
          {`No products added in ${category.name} category`}
        </div>
      </div>
      <Button
        className={classes.primaryAssignButton}
        onClick={fetchProductsToAssigned}
      >
        Assign Products
      </Button>
    </div>
  );
};

export default NoProducts;
