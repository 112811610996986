import React from "react";
import SolidButton from "../../../components/buttons/SolidButton";
import CoinIcon from "../../../icons/CoinIcon";
import { Skeleton } from "@material-ui/lab";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    height: 72,
    borderRadius: 12,
    padding: 12,
    marginBottom: 32,
  },
  content: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  balanceContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.625), // 5px
  },
  balanceTextContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.625), // 5px
  },
  balanceText: {
    color: "#1A1A1A",
    fontWeight: 700,
    fontSize: 16,
  },
  note: {
    marginLeft: theme.spacing(3.5), // 28px
  },
}));

export default function WalletBalance() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { progress, walletData } = useSelector((state) => state.wallet);

  const balance = walletData?.balance;

  const handleBuyCreditsClick = () => {
    const currentPath = location.pathname;
    const basePath = currentPath.split("/").slice(0, 2).join("/");
    const action = "buyCredits";
    history.push(`${basePath}/wallet?action=${action}`);
  };

  if (progress || !balance || balance > 50) {
    return null;
  }

  return (
    <div
      className={classes.container}
      style={{ background: balance < 0 ? "#FFEFEF" : "#FFF2E2" }}
    >
      <div className={classes.content}>
        <div style={{ paddingTop: 10 }}>
          <div className={classes.balanceContainer}>
            <CoinIcon />
            <div className={classes.balanceTextContainer}>
              {progress ? (
                <Skeleton variant="text" width={100} height={24} />
              ) : (
                <>
                  <span className={classes.balanceText}>{balance}</span>
                  <span>Credits</span>
                </>
              )}
            </div>
          </div>
          <p className={classes.note}>
            {balance < 0
              ? "Customers are not receiving any SMS, including OTP."
              : "Customers won't receive order SMS when wallet balance hits 0."}
          </p>
        </div>
        <SolidButton onClick={handleBuyCreditsClick}>Buy Credits</SolidButton>
      </div>
    </div>
  );
}
