import React from "react";
import { useSelector } from "react-redux";
import Diamond from "../../icons/Diamond";
import { planCustomProps, PLANS } from "../../pages/plans/constants";

const PaidTab = ({ plan, style = {} }) => {
  const store = useSelector((state) => state.store.store);

  if (PLANS[store.plan]?.weight >= PLANS[plan].weight) return <></>;
  return (
    <span>
      <Diamond
        {...planCustomProps[plan].diamondProps}
        style={{
          width: 30,
          height: 28,
          marginBottom: 0,
          marginTop: -2,
          ...style,
        }}
      />
    </span>
  );
};

export default PaidTab;
