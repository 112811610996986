import React from "react";

const ReOrderSharpIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.53125 14.25H18.4688M5.53125 9.75H18.4688"
        stroke="#666666"
        strokeWidth="2.0625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ReOrderSharpIcon;
