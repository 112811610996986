export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const PRODUCT_PROGRESS = "PRODUCT_PROGRESS";
export const SET_PRODUCT_ERROR = "SET_PRODUCT_ERROR";
export const CLEAR_PRODUCT = "CLEAR_PRODUCT";
export const PRODUCT_LOADER = "PRODUCT_LOADER";
export const PRODUCT_QUANTITY_UPDATE = "PRODUCT_QUANTITY_UPDATE";
export const CLEAR_COMPLETE_PRODUCT = "CLEAR_COMPLETE_PRODUCT";
export const SET_FILTER_SLUGS = "SET_FILTER_SLUGS";
export const SET_FILTER_STATUS = "SET_FILTER_STATUS";
export const SET_FILTER_PAYMENT_MODE = "SET_FILTER_PAYMENT_MODE";
export const SET_SORT_CRITERIA = "SET_SORT_CRITERIA";
export const SET_FILTER_QUANTITY = "SET_FILTER_QUANTITY";
export const SET_BULK_IMPORT_TOAST = "SET_BULK_IMPORT_TOAST";
export const SET_PRODUCTS_PER_PAGE = "SET_PRODUCTS_PER_PAGE";
export const SET_PRODUCT_ONLINE = "SET_PRODUCT_ONLINE";
export const UPDATE_PRODUCT_CATEGORY = "UPDATE_PRODUCT_CATEGORY";
export const ASSIGN_PRODUCT_CATEGORY = "ASSIGN_PRODUCT_CATEGORY";
