import React from "react";
import FormModal from "./common/FormModal";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import TickCircle from "../icons/TickCircle";
import { paidPlanTopFeatures } from "../pages/plans/constants";
import { getStoreIdFromUrl } from "../utils/storeUtil";
import PaidPlanIcon from "../icons/PaidPlanIcon";
import { rowFlexJustifyBetween } from "../styles/common";

const textStyle = { color: "#1a1a1a", fontSize: "14px", lineHeight: "20px" };
export default function PaidPopUpV2({
  open,
  close,
  subtxt,
  outlinedBtnText,
  hideCloseIcon,
}) {
  const history = useHistory();
  const storeId = getStoreIdFromUrl();

  return (
    <FormModal
      btnTxt="View Plans"
      open={open}
      outlinedBtnText={outlinedBtnText}
      onClose={close}
      onSave={() => {
        history.push(`/${storeId}/billing/plans`);
      }}
      hideCloseIcon={hideCloseIcon}
      heading={
        <>
          <span style={{ marginRight: "8px" }}>Upgrade to Paid Plan </span>
          <PaidPlanIcon style={{ marginBottom: 13 }} />
        </>
      }
    >
      <Typography
        style={{
          ...textStyle,
          marginBottom: "20px",
          marginTop: "-10px",
          width: "90%",
          color: "#000000",
        }}
      >
        {subtxt}
      </Typography>
      <Typography
        style={{
          ...textStyle,
          marginBottom: "14px",
          fontWeight: 600,
          fontSize: "16px",
        }}
      >
        Top Features
      </Typography>
      <div className={rowFlexJustifyBetween}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {paidPlanTopFeatures
            .filter((feature, index) => (index + 1) % 2 !== 0)
            .map((feature, index) => {
              return (
                <div key={index} style={{ marginBottom: "12px" }}>
                  <span>
                    <TickCircle />
                  </span>
                  <span style={{ marginLeft: "12px", ...textStyle }}>
                    {feature}
                  </span>
                </div>
              );
            })}
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {paidPlanTopFeatures.map((feature, index) => {
            if ((index + 1) % 2 === 0) {
              return (
                <div key={index} style={{ marginBottom: "12px" }}>
                  <span>
                    <TickCircle />
                  </span>
                  <span style={{ marginLeft: "12px", ...textStyle }}>
                    {feature}
                  </span>
                </div>
              );
            }
          })}
        </div>
      </div>
    </FormModal>
  );
}
