import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const LowStocksIcon = ({ viewBox = "0 0 56 56" }) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "56px", height: "56px" }}>
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="56" height="56" rx="12" fill="#6D3F84" fillOpacity="0.1" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 12H14V42H44V44H12V12ZM41.634 35.774C41.7357 35.6908 41.82 35.5883 41.8821 35.4725C41.9442 35.3567 41.9828 35.2298 41.9958 35.099C42.0088 34.9683 41.9959 34.8362 41.9579 34.7104C41.9198 34.5847 41.8573 34.4676 41.774 34.366L32.774 23.366C32.6855 23.258 32.5755 23.1698 32.4508 23.1069C32.3262 23.044 32.1898 23.008 32.0504 23.001C31.911 22.994 31.7717 23.0163 31.6414 23.0664C31.5111 23.1166 31.3928 23.1934 31.294 23.292L26.12 28.466L18.808 18.412C18.648 18.2086 18.4155 18.0751 18.1592 18.0396C17.9029 18.0041 17.6428 18.0693 17.4335 18.2216C17.2243 18.3739 17.0823 18.6013 17.0372 18.8561C16.9922 19.1109 17.0477 19.3732 17.192 19.588L25.192 30.588C25.2769 30.705 25.3862 30.8021 25.5123 30.8728C25.6383 30.9435 25.7782 30.986 25.9223 30.9974C26.0664 31.0088 26.2113 30.9889 26.3469 30.9389C26.4826 30.8889 26.6057 30.8102 26.708 30.708L31.926 25.488L40.226 35.634C40.3092 35.7357 40.4117 35.82 40.5275 35.8821C40.6433 35.9442 40.7702 35.9828 40.901 35.9958C41.0317 36.0088 41.1638 35.9959 41.2896 35.9579C41.4153 35.9198 41.5324 35.8573 41.634 35.774Z"
          fill="#6D3F84"
        />
      </svg>
    </SvgIcon>
  );
};

export default LowStocksIcon;
