import React from "react";
import DomainSelection from "./DomainSelection";
import ListAddons from "./ListAddons";
import AddressForm from "./AddressForm";
export default function ModalForm({
  stage,
  domainList,
  searchKey,
  loading,
  handleDomainSearch,
  handleContinueWithoutDomain,
  handleDomainSelect,
  currentSelectedPlan,
  handleAddonSelection,
  addonList,
  selectedDomain,
  handleAddressFormFieldChange,
  addressForm,
}) {
  if (stage === 0) {
    return (
      <DomainSelection
        domainList={domainList}
        searchKey={searchKey}
        loading={loading}
        handleDomainSelect={handleDomainSelect}
        handleDomainSearch={handleDomainSearch}
        handleContinueWithoutDomain={handleContinueWithoutDomain}
        selectedDomain={selectedDomain}
      />
    );
  } else if (stage === 1) {
    return (
      <ListAddons
        currentSelectedPlan={currentSelectedPlan}
        handleAddonSelection={handleAddonSelection}
        addonList={addonList}
      />
    );
  } else if (stage === 2) {
    return (
      <AddressForm
        handleAddressFormFieldChange={handleAddressFormFieldChange}
        addressForm={addressForm}
      />
    );
  }
}
