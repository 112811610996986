import { Button, Typography } from "@material-ui/core";
import React from "react";
import ShoopyIconLatest from "../../icons/ShoopyIconLatest";
import { cdnUrl } from "../../utils/cdnUtils";
import { isCustomDomain } from "../../utils/utils";
import MobileDrawing from "./MobileDrawing";

const MobileMode = () => {
  const isApple =
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    (navigator.userAgent.includes("Mac") && "ontouchend" in document);
  const customDomain = isCustomDomain();
  const domain = window.location.hostname;
  return (
    <div
      style={{
        background: "#FFFFFF",
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingBottom: "8vh",
      }}
    >
      <div style={{ textAlign: "center", padding: 20 }}>
        {customDomain ? (
          <div>
            <img
              alt="Logo"
              style={{ width: "unset", height: 48 }}
              src={cdnUrl(`https://${domain}/shoopy.png`, null, 200, 1)}
            />
          </div>
        ) : (
          <ShoopyIconLatest />
        )}
        <Typography style={{ marginTop: 40, fontWeight: 600 }} variant="h5">
          Welcome To <br />
          {customDomain ? domain : "Shoopy"}
        </Typography>
        <Typography style={{ marginTop: 12, marginBottom: 50 }} variant="body2">
          Please access this url on a PC or a Laptop
          {!customDomain && (
            <span>, and meanwhile Download our Mobile App to get started</span>
          )}
        </Typography>
        <div style={{ width: "100%", textAlign: "center" }}>
          <MobileDrawing />
        </div>
        {!customDomain && (
          <a
            href={
              isApple
                ? "https://apps.apple.com/in/app/shoopy/id1495571808"
                : "https://play.google.com/store/apps/details?id=in.shoopy.business"
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              style={{
                background: "#1641DB",
                color: "white",
                width: 284,
                borderRadius: 6,
                marginTop: 60,
                fontWeight: 700,
                outline: "none",
              }}
            >
              Download App
            </Button>
          </a>
        )}
      </div>
    </div>
  );
};

export default MobileMode;
