import { SvgIcon } from "@material-ui/core";
import React from "react";

export default function SmallDiamond({
  primary = "#FF1D1D",
  secondary = "#2238A8",
  tertiary = "#CA6189",
  quaternary = "#F7BBD0",
  quinary = "#E789AD",
  ellipse = "#CA6189",
}) {
  return (
    <SvgIcon viewBox="0 0 40 40" style={{ width: "40px", height: "40px" }}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.2"
          d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
          fill={ellipse}
        />
        <path
          d="M30.8899 11.5083L28.6459 11.1435L28.2795 9L27.959 11.1891L25.8523 11.5083L27.959 11.8276L28.2795 14.0167L28.6459 11.8732L30.8899 11.5083Z"
          fill={primary}
        />
        <path
          d="M12.7551 9L12.6635 9.86652L11.7476 10.0033L12.6177 10.1402L12.7551 11.0066L12.9383 10.1402L13.8084 10.0033L12.8924 9.86652L12.7551 9Z"
          fill={primary}
        />
        <path
          d="M11.5193 11.7822L11.2903 10.2316L11.0613 11.7822L9.55005 12.0102L11.0613 12.2839L11.2903 13.8345L11.5193 12.2839L13.1222 12.0102L11.5193 11.7822Z"
          fill="#1641DB"
        />
        <path
          d="M23.3801 18.2127L20.5865 12.9224H19.4416L16.3274 18.2127H23.3801Z"
          fill={tertiary}
        />
        <path
          d="M26.9524 12.9224H20.5867L23.3803 18.2127L26.9524 12.9224Z"
          fill={quaternary}
        />
        <path
          d="M30.8002 18.2127L26.9991 12.9224H26.9532L23.3811 18.2127H30.8002Z"
          fill={tertiary}
        />
        <path
          d="M19.8995 30.8909L23.3801 18.2125H16.3274L19.8995 30.8909Z"
          fill={quinary}
        />
        <path
          d="M19.4421 12.6701H12.8474L16.3279 17.9604L19.4421 12.6701Z"
          fill={quaternary}
        />
        <path
          d="M12.8469 12.9224H12.8011L9 18.2127H16.3274L12.8469 12.9224Z"
          fill={tertiary}
        />
        <path
          d="M30.7992 17.9601L19.8996 30.6385L9 17.9601H16.3274L19.8996 30.6385L23.3801 17.9601H30.7992Z"
          fill={quaternary}
        />
        <path
          d="M23.3799 16.7988L23.1967 17.9845L21.9602 18.167L23.1967 18.3494L23.3799 19.6263L23.5631 18.3494L24.7996 18.167L23.5631 17.9845L23.3799 16.7988Z"
          fill="white"
        />
        <path
          d="M14.4026 14.1534L14.2194 15.3392L12.9829 15.5216L14.1736 15.704L14.4026 16.981L14.5858 15.7497L15.8223 15.5216L14.5858 15.3392L14.4026 14.1534Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
}
