import React from "react";
import {
  FormControl,
  RadioGroup as MuiRadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";

const RadioGroup = (props) => {
  const {
    disabled,
    name,
    label,
    value,
    onChange,
    items,
    labelClass,
    radioLabelClass,
  } = props;
  return (
    <FormControl>
      <p className={labelClass} autoFocus={true}>
        {label}
      </p>
      <MuiRadioGroup
        row
        name={name}
        value={value}
        onChange={disabled ? () => {} : onChange}
      >
        {items.map((item) => (
          <FormControlLabel
            key={item.id}
            value={item.id}
            control={<Radio />}
            label={item.title}
            classes={{ label: radioLabelClass }}
          />
        ))}
      </MuiRadioGroup>
    </FormControl>
  );
};
export default RadioGroup;
