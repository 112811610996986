import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const MobileIcon = ({ viewBox = "0 0 10 17", fill = "#1641DB" }) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "10px", height: "17px" }}>
      <path
        d="M1.90789 0C0.855263 0 0 0.855263 0 1.90789V14.4058C0 15.4585 0.855263 16.3137 1.90789 16.3137H8.09211C9.14474 16.3137 10 15.4585 10 14.4058V1.90789C10 0.855263 9.14474 0 8.09211 0H1.90789ZM1.90789 0.526316H8.09211C8.85421 0.526316 9.47368 1.14579 9.47368 1.90789V12.6316H0.526316V1.90789C0.526316 1.14474 1.14579 0.526316 1.90789 0.526316ZM3.93092 2.12788V2.65419H6.05263V2.12788H3.93092ZM0.526316 13.1579H9.47369V14.4079C9.47369 15.17 8.85421 15.7895 8.09211 15.7895H1.90789C1.14579 15.7895 0.526316 15.17 0.526316 14.4079V13.1579Z"
        fill={fill}
      />
    </SvgIcon>
  );
};

export default MobileIcon;
