import React from "react";
import { SvgIcon } from "@material-ui/core";

const ReportPending = ({ viewBox = "0 0 16 16" }) => {
  return (
    <SvgIcon style={{ width: "16px", height: "16px" }} viewBox={viewBox}>
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="8" fill="white" stroke="#3AC973" />
      </svg>
    </SvgIcon>
  );
};

export default ReportPending;
