import { CircularProgress } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { fetchUserStores } from "../../redux/userstore/userStoreActions";
import { circular, progressbar } from "../../styles/common";

const RenewalRedirector = () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState();
  const storeId = params.storeId;
  const isLoggedIn = localStorage.getItem("access_token") ? true : false;
  useEffect(() => {
    if (!isLoggedIn) {
      let path = "/login/redirect?callback=/subscribe/renew";
      history.push(storeId ? `${path}/${storeId}` : path);
    } else {
      setLoader(true);
      dispatch(fetchUserStores()).then((res) => {
        setLoader(false);
        if (res?.length > 0) {
          history.push(
            `/${storeId ? storeId : res[0].store_id}/billing/plans/info`
          );
        }
      });
    }
    // eslint-disable-next-line
  }, [isLoggedIn]);

  if (loader) {
    return (
      <CircularProgress
        color="secondary"
        className={progressbar}
        classes={{ colorSecondary: circular }}
      />
    );
  }
  return <></>;
};

export default RenewalRedirector;
