import React from "react";
import useStyles from "../../common/styles";
import { Done } from "@material-ui/icons";
import { Switch } from "@material-ui/core";
import Controls from "../../../../components/controls/Controls";
import SolidButton from "../../../../components/buttons/SolidButton";
import { cdnUrl } from "../../../../utils/cdnUtils";

const ConfiguredDetails = ({
  steps,
  isChecked,
  activateRazorpaySwitch,
  affordabilitySwitch,
  handleAffordBilitychange,
  handleRazorpaySwitch,
  razorpaySettingDone,
  isShowSteps,
  apiKey,
  setApiKey,
  btnDisabled,
  setBtnDisabled,
  handleSaveKey,
}) => {
  const classes = useStyles();
  const RAZOPRAY_ICON_URL =
    "https://storage.googleapis.com/shy-pub/static/shoopy-app/rzp.png";
  return (
    <div>
      <div style={{ display: "flex", marginBottom: 28 }}>
        <div className={classes.imgctn} style={{ background: "#95959533" }}>
          <img
            alt="Razorpay"
            width="40px"
            height="40px"
            src={cdnUrl(RAZOPRAY_ICON_URL)}
          />
        </div>
        <div style={{ marginLeft: 16 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 16,
            }}
          >
            <p className={classes.title}>Razorpay Connected</p>
          </div>
          <p className={classes.subtitle}>
            Your Store is successfully connected with your Razorpay Account.
          </p>
        </div>
      </div>
      <hr />
      <div>
        <p
          className={classes.description}
          style={{ fontWeight: 400, marginTop: 32 }}
        >
          <Done
            style={{
              width: "20px",
              height: "20px",
              color: "white",
              background: "green",
              borderRadius: "50%",
              marginRight: "10px",
            }}
          />
          Your Razorpay payment gateway is successfully connected
        </p>
        {isShowSteps && (
          <div>
            <div>
              <p>
                Before you can start receiving payment, please configure the
                following settings in your Razorpay account
              </p>
            </div>
            <div>
              {steps.map((step, index) => (
                <div key={index}>
                  <p style={{ marginBottom: "4px" }} variant="h6">
                    {index + 1}.{" "}
                    {step.url ? (
                      <>
                        {step.label}{" "}
                        <a
                          href={step.url}
                          style={{ color: "blue", textDecoration: "underline" }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {step.url}
                        </a>
                      </>
                    ) : (
                      step.label
                    )}
                  </p>
                </div>
              ))}
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "12px",
                }}
              >
                <input
                  type="checkbox"
                  style={{ marginRight: "10px", height: 16, width: 16 }}
                  checked={isChecked}
                  onChange={razorpaySettingDone}
                />
                <p style={{ margin: 0 }}>
                  I confirm that I have done above changes.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
      <hr />
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "10px",
            opacity: isChecked ? 1 : 0.5,
            pointerEvents: isChecked ? "auto" : "none",
          }}
        >
          <p style={{ margin: 0, flexGrow: 1 }}>
            Activate Razorpay to start receiving online payment
          </p>
          <Switch
            checked={activateRazorpaySwitch}
            onChange={handleRazorpaySwitch}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "10px",
            opacity: activateRazorpaySwitch ? 1 : 0.5,
            pointerEvents: activateRazorpaySwitch ? "auto" : "none",
          }}
        >
          <p style={{ margin: 0, flexGrow: 1 }}>
            Activate{" "}
            <span style={{ fontWeight: "600" }}>Affordability widget</span> in
            my product detail page to provide EMI information
          </p>
          <Switch
            checked={affordabilitySwitch}
            onChange={handleAffordBilitychange}
          />
        </div>
        <p
          style={{
            marginTop: 16,
            opacity: activateRazorpaySwitch ? 1 : 0.5,
            pointerEvents: activateRazorpaySwitch ? "auto" : "none",
          }}
        >
          Read more about affordability widget{" "}
          <a
            target="_blank"
            href="https://razorpay.com/docs/payments/payment-gateway/affordability/widget/"
          >
            Click here
          </a>
        </p>
        {affordabilitySwitch && (
          <>
            <div style={{ marginTop: 16 }}>
              <Controls.Input
                type="text"
                name="razorpay_live_key"
                placeholder="Enter API Key to show widget"
                label="Razorpay Live API Key"
                labelFontWeight={600}
                labelPlacement="top"
                value={apiKey}
                onChange={(e) => {
                  setBtnDisabled(false);
                  setApiKey(e.target.value);
                }}
                helperText={
                  <>
                    Steps to get Razorpay Live API Key{" "}
                    <a
                      target="_blank"
                      href="https://razorpay.com/docs/api/authentication/#generate-api-keys"
                    >
                      click here
                    </a>
                  </>
                }
              />
            </div>
            <div style={{ textAlign: "right" }}>
              <SolidButton onClick={handleSaveKey} disabled={btnDisabled}>
                Save Key
              </SolidButton>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ConfiguredDetails;
