import axios from "axios";
import { getFinalSalePriceWTax } from "./invoiceHelper";
import { getDefaultStore } from "./storeUtil";
import moment from "moment";
import { saveFileDocument } from "./documentUtil";

export const getPaymentDetails = async (orderId) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  let response = await axios.get(
    `${process.env.REACT_APP_API}/api/v1/org/store/${store.store_id}/order/${orderId}/payments`,
    {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return response.data.payload;
};

export const getDeliveryDetails = async (orderId) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  let response = await axios.get(
    `${process.env.REACT_APP_API}/api/v1/store/${store.store_id}/delivery-details/find-one?order-id=${orderId}`,
    {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return response.data.payload;
};

export const bulkExport = async (type, slug, obj) => {
  const objArr = Object.entries(obj);
  const ids = [];
  let lowest = objArr[0][1].substr(objArr[0][1].indexOf("-") + 1);
  let highest = lowest;
  for (const [key, value] of objArr) {
    let ordNo = value.substr(value.indexOf("-") + 1);
    if (parseInt(ordNo) > parseInt(highest)) highest = ordNo;
    else if (parseInt(ordNo) < parseInt(lowest)) lowest = ordNo;
    ids.push(key);
  }
  const filename = `${
    type === "invoice" ? "Invoice" : "Shipping"
  }-ORD-${lowest}-ORD-${highest}.pdf`;
  let accessToken = localStorage.getItem("access_token");
  let records = "";
  ids.forEach((id) => {
    records += `record-ids=${id}&`;
  });
  let response = await axios.get(
    `${process.env.REACT_APP_API}/api/v1/org/store/${slug}/records/${type}/document?${records}`,
    {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${accessToken}`,
      },
      responseType: "blob",
    }
  );
  saveFileDocument(response.data, filename);
  return;
};
const getTextMessage = (order) => {
  let addLine1 = addressLine1(order.order_address);
  let addLine2 = addressLine2(order.order_address);
  let detail = "";
  if (order.type === "PURCHASE_ORDER") {
    detail += `Purchase : *${order.invoice_id}*\n`;
  } else if (order.type === "ESTIMATE") {
    detail += `Estimate : *${order.invoice_id}*\n`;
  } else {
    detail += `Order   : *${order.display_id}*\n`;
  }
  detail += `Phone    : ${order.org_customer_mobile}\n`;
  detail += `Name     : ${order.org_customer_name}\n`;
  let checkoutFields = order.checkout_fields;
  if (checkoutFields && checkoutFields.length > 0) {
    let otherInfo = "";
    for (let i = 0; i < checkoutFields.length; i++) {
      otherInfo += `${checkoutFields[i].name} : ${checkoutFields[i].value}\n`;
    }
    detail += otherInfo;
  }
  let discount = order.round_value +
    order.order_discount +
    order.coupon_discount +
    order.payment_discount;
  let finalChg = order.sales_with_other_charges - discount;
  let charge = Math.round(finalChg * 100) / 100;
  detail += `Amount  : *${charge}*\n`;
  detail += `Address  : ${addLine1} ${addLine2}\n`;
  let addressLink = getMapLink(order.order_address);
  if (addressLink) {
    addressLink = addressLink.replaceAll(" ", "+");
    detail += `\n ${addressLink.slice(0, -2)}`;
  }
  detail += "\n\n";
  if (order.order_items && order.order_items.length > 0) {
    detail += "----------Items------------\n";
    let items = order.order_items;
    for (let i = 0; i < items.length; i++) {
      detail += padRight(`${items[i].qty} x `, 10);
      detail += safeSubString(items[i].display_name, 30);
      detail += padRight(` = ₹ ${getFinalSalePriceWTax(items[i], false)}`, 10);
      detail += "\n";
    }
  }
  return detail;
};

const addressLine1 = (order_address) => {
  let list = [];
  if (order_address != null) {
    if (order_address.street1) {
      list.push(order_address.street1);
    }
    if (order_address.street2) {
      list.push(order_address.street2);
    }
  }
  return list.join(", ");
};

const addressLine2 = (order_address) => {
  if (order_address != null) {
    let list = [];
    if (order_address.city) {
      list.push(order_address.city);
    }
    if (order_address.state) {
      list.push(order_address.state);
    }
    if (list.length > 0) {
      let address = list.join(", ");
      if (order_address.pincode) {
        address = `${address} - ${order_address.pincode}`;
      }
      return address;
    }
  }
  return "";
};

const getMapLink = (order_address) => {
  if (order_address) {
    if (order_address.lat != null && order_address.lng != null) {
      return `https://www.google.com/maps/search/?api=1&query=${order_address.lat},${order_address.lng}`;
    } else {
      let address = `${order_address.street1 ? order_address.street1 : ""}, ${
        order_address.street2 ? order_address.street2 : ""
      }, ${order_address.city ? order_address.city : ""}, ${
        order_address.state ? order_address.state : ""
      }, ${order_address.countrty ? order_address.country : ""}`;
      return `https://www.google.com/maps/search/?api=1&query=${address}`;
    }
  }
  return "";
};

const padRight = (originalText, maxRight) => {
  if (originalText) {
    if (originalText.length > maxRight) {
      return originalText.substring(0, maxRight);
    } else {
      return originalText.padEnd(maxRight);
    }
  }
  return "".padEnd(maxRight);
};

const safeSubString = (originalText, maxLength) => {
  if (originalText) {
    if (originalText.length > maxLength) {
      return originalText.substring(0, maxLength);
    } else {
      return originalText;
    }
  }
  return "";
};

export const getWhatsAppShareLink = async (order) => {
  let baseUrl = "https://wa.me/";
  let toNumber = order.org_customer_mobile;
  let countryCode = 91;
  if (toNumber) {
    if (toNumber.length === 10) {
      toNumber = countryCode + toNumber;
    }
    baseUrl = baseUrl + toNumber;
  }
  let text = getTextMessage(order);
  if (text) {
    let encodedText = encodeURIComponent(text);
    baseUrl = `${baseUrl}?text=${encodedText}`;
  }
  return baseUrl;
};

export const getShippingDetails = (order) => {
  let slot = order.slot;
  if (slot && order.delivery_starts_at && order.delivery_ends_at) {
    let deliveryStartsAt = order.delivery_starts_at;
    let deliveryEndsAt = order.delivery_ends_at;
    let dayOrDate = timeToDay(deliveryEndsAt);
    let startsAt = moment(deliveryStartsAt).format("hh:mm A");
    let endsAt = moment(deliveryEndsAt).format("hh:mm A");
    return dayOrDate + ", " + startsAt + " - " + endsAt;
  } else {
    let deliveryEndsAt = order.delivery_ends_at;
    let dayOrDate = timeToDay(deliveryEndsAt);
    let endsAt = moment(deliveryEndsAt).format("hh:mm A");
    return endsAt === "12:00 AM" ? dayOrDate : dayOrDate + ", " + endsAt;
  }
};

export const getColumnShippingDetails = (order) => {
  let slot = order.slot;
  if (slot && order.delivery_starts_at && order.delivery_ends_at) {
    let deliveryStartsAt = order.delivery_starts_at;
    let deliveryEndsAt = order.delivery_ends_at;
    let dayOrDate = timeToDay(deliveryEndsAt, true);
    let startsAt = moment(deliveryStartsAt).format("HH:mm");
    let endsAt = moment(deliveryEndsAt).format("HH:mm");
    return dayOrDate + ", " + startsAt + " - " + endsAt;
  } else {
    let deliveryEndsAt = order.delivery_ends_at;
    let dayOrDate = timeToDay(deliveryEndsAt, false);
    let endsAt = moment(deliveryEndsAt).format("HH:mm");
    return endsAt === "00:00" ? dayOrDate : dayOrDate + ", " + endsAt;
  }
};

export const timeToDay = (time, trimYear) => {
  let formatedTime = moment(time).format("DD-MM-YYYY");
  if (moment().format("DD-MM-YYYY") === formatedTime) {
    return "Today";
  }
  if (moment().add(1, "days").format("DD-MM-YYYY") === formatedTime) {
    return "Tomorrow";
  }
  if (moment().add(-1, "days").format("DD-MM-YYYY") === formatedTime) {
    return "Yesterday";
  }
  return trimYear
    ? moment(time).format("DD-MMM")
    : moment(time).format("DD-MMM-YYYY");
};

export const fetchOrderById = async (orderid) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API}/api/v1/org/store/${store.store_id}/order/${orderid}/order`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.payload;
  } catch (err) {
    console.log("Failure while fetching order", err);
  }
};

export const addCustomerAddress = async (addressData) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  let address = addressData;
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_API}/api/v1/org/store/${store.store_id}/customer-addresses`,
      address,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (err) {
    console.log("Failure while adding customer address", err);
  }
};

export const commonAddStoreCustomer = async (customerData) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  let data = customerData;
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_API}/api/v2/org/store/${store.store_id}/customers`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.payload;
  } catch (err) {
    console.log("Failure while adding customer", err);
  }
};

export const commonEditStoreCustomer = async (customerData) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  let data = customerData;
  try {
    let response = await axios.put(
      `${process.env.REACT_APP_API}/api/v2/org/store/${store.store_id}/customers/${data.id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.payload;
  } catch (err) {
    console.log("Failure while adding customer", err);
  }
};

export const compareBillAndShipAddress = (billingAdd, shippingAdd) => {
  let billStr = "";
  if (billingAdd) {
    billStr =
      billingAdd.customer_name +
      billingAdd.mobile +
      billingAdd.street1 +
      billingAdd.street2 +
      billingAdd.pincode +
      billingAdd.city +
      billingAdd.state;
  }
  let shipStr = "";
  if (shippingAdd) {
    shipStr =
      shippingAdd.customer_name +
      shippingAdd.mobile +
      shippingAdd.street1 +
      shippingAdd.street2 +
      shippingAdd.pincode +
      shippingAdd.city +
      shippingAdd.state;
  }
  if (billStr === "") {
    return false;
  }
  return !(billStr && shipStr && billStr !== shipStr);
};

export const getCustomerDetails = async (record) => {
  let accessToken = localStorage.getItem("access_token");
  let id = record.org_store_customer_id;
  let store = getDefaultStore();
  let response = await axios.get(
    `${process.env.REACT_APP_API}/api/v2/org/store/${store.store_id}/customers/${id}`,
    {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return response.data.payload;
};
