import React from "react";

export default function NotificationSettingsIcon({
  fill = "#666666",
  strokeWidth = 0,
}) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_22814_1850"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="28"
        height="28"
      >
        <rect width="28" height="28" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_22814_1850)">
        <path
          d="M3.76904 11.4648C3.76904 9.96897 4.06703 8.57753 4.663 7.2905C5.25917 6.00347 6.06951 4.89251 7.09404 3.95763L7.88154 4.81483C6.96921 5.64045 6.25015 6.62239 5.72438 7.76067C5.1986 8.89895 4.93571 10.1337 4.93571 11.4648H3.76904ZM23.064 11.4648C23.064 10.1337 22.8011 8.89515 22.2753 7.74929C21.7495 6.60363 21.0305 5.61799 20.1181 4.79238L20.9056 3.95763C21.9302 4.89251 22.7405 6.00347 23.3367 7.2905C23.9326 8.57753 24.2306 9.96897 24.2306 11.4648H23.064ZM5.83317 21.8975V20.7308H7.71792V11.4873C7.71792 9.96304 8.20024 8.62245 9.16488 7.4655C10.1297 6.30856 11.3525 5.58804 12.8332 5.30396V4.66667C12.8332 4.34506 12.9472 4.07021 13.1753 3.84212C13.4034 3.61404 13.6782 3.5 13.9998 3.5C14.3214 3.5 14.5963 3.61404 14.8244 3.84212C15.0525 4.07021 15.1665 4.34506 15.1665 4.66667V5.30396C16.6472 5.58804 17.87 6.30856 18.8348 7.4655C19.7994 8.62245 20.2817 9.96304 20.2817 11.4873V20.7308H22.1665V21.8975H5.83317ZM13.9998 24.9486C13.4778 24.9486 13.0332 24.765 12.666 24.3979C12.2987 24.0308 12.1151 23.5862 12.1151 23.0641H15.8846C15.8846 23.5862 15.7009 24.0308 15.3336 24.3979C14.9665 24.765 14.5219 24.9486 13.9998 24.9486ZM8.88458 20.7308H19.1151V11.4873C19.1151 10.0692 18.617 8.8621 17.6209 7.86596C16.6247 6.86982 15.4177 6.37175 13.9998 6.37175C12.5819 6.37175 11.3749 6.86982 10.3788 7.86596C9.38265 8.8621 8.88458 10.0692 8.88458 11.4873V20.7308Z"
          fill={fill}
          stroke={fill}
          strokeWidth={strokeWidth}
        />
      </g>
    </svg>
  );
}
