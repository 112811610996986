export const CREATE_ESTIMATE = "CREATE_ESTIMATE";
export const FETCH_ESTIMATES = "FETCH_ESTIMATES";
export const ESTIMATE_PROGRESS = "ESTIMATE_PROGRESS";
export const UPDATE_ESTIMATE = "UPDATE_ESTIMATE";
export const SET_ESTIMATE_ERROR = "SET_ESTIMATE_ERROR";
export const SET_ESTIMATE_LOADER = "SET_ESTIMATE_LOADER";
export const CLEAR_COMPLETE_ESTIMATE = "CLEAR_COMPLETE_ESTIMATE";
export const DELETE_ESTIMATE = "DELETE_ESTIMATE";
export const CLEAR_ESTIMATE = "CLEAR_ESTIMATE";
export const ESTIMATE_DATE_SELECTOR = "ESTIMATE_DATE_SELECTOR";
export const ESTIMATE_STATE_SELECTOR = "ESTIMATE_STATE_SELECTOR";
export const ESTIMATE_STATUS_SELECTOR = "ESTIMATE_STATUS_SELECTOR";
export const ESTIMATE_DATE_RANGE = "ESTIMATE_DATE_RANGE";
export const SET_ESTIMATES_PER_PAGE = "SET_ESTIMATES_PER_PAGE";
export const SET_ESTIMATES_CURRENT_PAGE = "SET_ESTIMATES_CURRENT_PAGE";
export const SET_ESTIMATES_MAX_PAGE_SO_FAR = "SET_ESTIMATES_MAX_PAGE_SO_FAR";
export const SET_ESTIMATES_INPUT_KEYWORD = "SET_ESTIMATES_INPUT_KEYWORD";
export const SET_ESTIMATES_SEARCH_KEYWORD = "SET_ESTIMATES_SEARCH_KEYWORD";
export const SET_ESTIMATES_CUSTOMER_ID = "SET_ESTIMATES_CUSTOMER_ID";
