import { makeStyles, Popper, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import "react-image-crop/dist/ReactCrop.css";
import ComponentLoader from "../../../components/ComponentLoader.json";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Lottie from "react-lottie";
import axios from "axios";

const useStyles = makeStyles(() => ({
  autocompleteOption: {
    borderTop: "1px solid #E5E5E5",
    padding: "14px",
    minHeight: "62px",
    '&[data-focus="true"]': {
      backgroundColor: "rgba(22, 65, 219, 0.05)",
    },
    '&[aria-selected="true"]': {
      backgroundColor: "rgba(22, 65, 219, 0.05)",
    },
  },
}));

const GoogleCategory = ({ formState, setFormState }) => {
  const classes = useStyles();
  const timeout = useRef(null);

  const [loader, setLoader] = useState(false);
  const [gmcInputValue, setGmcInputValue] = useState("");
  const [gmcOptions, setGmcOptions] = useState([]);

  useEffect(() => {
    if (gmcInputValue.trim().length >= 3) {
      let accessToken = localStorage.getItem("access_token");
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        setLoader(true);
        axios
          .get(
            `${process.env.REACT_APP_API}/api/v1/google-categories?keyword=${gmcInputValue}`,
            {
              headers: {
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${accessToken}`,
              },
            }
          )
          .then((res) => {
            setLoader(false);
            setGmcOptions(res.data.payload);
          });
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gmcInputValue.trim()]);

  const filter = createFilterOptions({
    stringify: (option) => `${option.name}`,
  });

  const CustomPopper = (props) => {
    const classes = useStyles();
    return (
      <Popper
        disablePortal={true}
        {...props}
        className={classes.root}
        placement="bottom-start"
        style={{ zIndex: 9, width: "100%" }}
      />
    );
  };

  return (
    <Autocomplete
      freeSolo
      clearOnBlur
      autoComplete
      handleHomeEndKeys
      classes={{
        option: classes.autocompleteOption,
      }}
      name="gmc_category"
      value={formState.gmc_category.value}
      onChange={(event, newValue) => {
        const cloneData = formState.gmc_category;
        cloneData.touched = true;
        cloneData.value = newValue;
        setFormState((prevState) => ({
          ...prevState,
          gmc_category: cloneData,
        }));
      }}
      inputValue={gmcInputValue}
      onInputChange={(event, newInputValue) => {
        setGmcInputValue(newInputValue);
      }}
      options={gmcOptions}
      getOptionLabel={(option) => `${option.name}`}
      renderOption={(option) => (
        <Typography
          style={{
            fontSize: 12,
          }}
        >
          {option.name}
        </Typography>
      )}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        return filtered;
      }}
      PopperComponent={CustomPopper}
      renderInput={(params) => (
        <div ref={params.InputProps.ref}>
          <TextField
            {...params}
            color="secondary"
            variant="outlined"
            placeholder="Start typing for Google Category"
            inputProps={{
              ...params.inputProps,
              style: {
                fontSize: 14,
                color: "#1A1A1A",
              },
            }}
            InputProps={{
              style: { width: "100%", paddingRight: loader ? 36 : 25 },
              endAdornment: (
                <>
                  {loader && (
                    <Lottie
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: ComponentLoader,
                      }}
                      style={{
                        position: "absolute",
                        right: 26,
                        top: 6,
                      }}
                      width={16}
                      height={20}
                    />
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            onFocus={() => {
              const cloneData = formState.gmc_category;
              cloneData.touched = true;
              cloneData.error = "";
              setFormState((prevState) => ({
                ...prevState,
                gmc_category: cloneData,
              }));
            }}
            onBlur={() => {
              const cloneData = formState.gmc_category;
              cloneData.touched = false;
              if (!cloneData.value) {
                cloneData.error = "This field is required.";
              }
              setFormState((prevState) => ({
                ...prevState,
                gmc_category: cloneData,
              }));
            }}
            helperText={
              formState.gmc_category.error ? formState.gmc_category.error : ""
            }
            error={formState.gmc_category.error}
          />
        </div>
      )}
    />
  );
};

export default GoogleCategory;
