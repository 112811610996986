import { BUSINESS_APP_PRO } from "../plans/constants";
export const configApps = [
  "checkout-fields",
  "razorpay",
  "facebook-pixel",
  "indiamart",
  "google-analytics",
  "shiprocket",
  "tawkto",
  "tidio",
  "gmc",
  "xpressbees",
  "ithink",
  "baat",
  "facebook-domain-verification",
  "facebook-conversion-api",
  "google-site-verification",
  "google-ads-pixel",
  "clarity",
  "shipway",
  "delhivery",
  "whatsapp-chat-bubble",
  "eshipz",
];

export const slugPlanMap = {
  tawkto: {
    plan: BUSINESS_APP_PRO,
    subTxt: "Enable live support by upgrading to Professional plan",
  },
  tidio: {
    plan: BUSINESS_APP_PRO,
    subTxt: "Enable live support by upgrading to Professional plan",
  },
  gmc: {
    plan: BUSINESS_APP_PRO,
    subTxt: "Enable Google Merchant Center by upgrading to Professional plan",
  },
};

export const slugPixelMap = {
  tidio: "tidio",
};
