import { FETCH_TAX_GROUPS, TAX_GROUP_PROGRESS } from './taxGroupTypes';
import axios from 'axios';

export const fetchTaxGroups = () => dispatch => {
  dispatch({
    type: TAX_GROUP_PROGRESS,
    payload: true,
  });
  let deviceToken = process.env.REACT_APP_TRUSTED_TOKEN;
  axios.get(
    `${process.env.REACT_APP_API}/api/v1/tax-group/all`, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Authorization': `Bearer ${deviceToken}`
    }
  }).then(response => {
    dispatch({
      type: FETCH_TAX_GROUPS,
      payload: response.data.payload
    });
  }
  );
};