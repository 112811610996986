import { Button, Typography } from "@material-ui/core";
import { Modal, LinearProgress } from "@material-ui/core";
import { CheckCircleRounded, InfoOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import { Fragment } from "react";
import Dropzone from "react-dropzone";
import CloseIcon from "../../../icons/CloseIcon";
import CloudUploadIcon from "../../../icons/CloudUploadIcon";
import CsvIcon from "../../../icons/CsvIcon";
import ErrorIcon from "../../../icons/ErrorIcon";
import {
  importBulkProduct,
  startJobCheck,
  validateBulkProduct,
} from "../../../utils/productUtils";
import { getDefaultStore, getStoreIdFromUrl } from "../../../utils/storeUtil";
import useStyles from "../styles";
import DownloadIcon from "../../../icons/DownloadIcon";
import { useDispatch } from "react-redux";
import { setBulkToast } from "../../../redux/product/productActions";
import OutlinedButton from "../../../components/buttons/OutlinedButton";
import SolidButton from "../../../components/buttons/SolidButton";
import { layoutHeading, transparentButton } from "../../../styles/common";
import CloseButton from "../../../components/buttons/CloseButton";
import { saveCsvDocument } from "../../../utils/documentUtil";

const BulkUpload = ({ openBulkUpload, setOpenBulkUpload }) => {
  const classes = useStyles();

  const [filename, setFilename] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [showGuide, setShowGuide] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [verified, setVerified] = useState(false);
  const [importing, setImporting] = useState(false);
  const [imported, setImported] = useState(false);
  const [error, setError] = useState("");
  const [invalidRows, setInvalidRows] = useState(null);
  const dispatch = useDispatch();

  const handleDrop = (acceptedFiles) => {
    setUploading(true);
    const file = acceptedFiles[0];
    if (file.size > 5e6) {
      setError("Max file size exceeded");
      return;
    }
    setFilename(file.name);
    setUploadedFile(file);
    setUploading(false);
    verify(file);
  };

  const reset = () => {
    setFilename("");
    setUploading(false);
    setError("");
    setInvalidRows(null);
    setShowGuide(false);
    setVerified(false);
    setVerifying(false);
    setImported(false);
    setImporting(false);
    setUploadedFile(null);
  };

  const verify = (file) => {
    setVerifying(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("org-id", getDefaultStore().org_id);
    validateBulkProduct(formData)
      .then((res) => {
        if (res.data.payload.header_error) {
          let message =
            "Missing field(s): " +
            res.data.payload.data.map((obj) => obj.comment).join(", ");
          setError(message);
          setVerifying(false);
          return;
        }
        if (res.data.payload.data_error_count > 0) {
          setError(`${res.data.payload.data_error_count} Invalid Row(s)`);
          setVerifying(false);
          setInvalidRows(res.data.payload.data);
          return;
        }
        setVerified(true);
        setVerifying(false);
      })
      .catch(() => {
        setError(
          "Something went wrong. Please try again. Contact support if issue continues"
        );
        setVerifying(false);
      });
  };

  const onImport = () => {
    setImporting(true);
    const formData = new FormData();
    formData.append("file", uploadedFile);
    importBulkProduct(formData).then((res) => {
      setImporting(false);
      setImported(true);
      startJobCheck(res.data.payload.id, getStoreIdFromUrl()).then(
        (success) => {
          if (success) {
            dispatch(
              setBulkToast({
                message: "Products imported sucessfully, please refresh.",
                severity: "success",
              })
            );
          } else {
            dispatch(
              setBulkToast({
                message:
                  "Products import failed. Please contact support for assist.",
                severity: "error",
              })
            );
          }
        }
      );
    });
  };

  const onExportErrors = () => {
    saveCsvDocument(invalidRows, "ProductErrors.csv");
  };

  if (imported) {
    return (
      <Modal
        open={openBulkUpload}
        onClose={() => {
          setOpenBulkUpload(false);
          reset();
        }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        disableAutoFocus
      >
        <div
          style={{
            padding: 25,
            borderRadius: 12,
            position: "absolute",
            background: "#ffffff",
            width: 546,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 25,
            }}
          >
            <Typography className={classes.heading} style={{ fontSize: 19 }}>
              Import Successful
            </Typography>
            <Button
              onClick={() => {
                setOpenBulkUpload(false);
                reset();
              }}
              style={{ outline: "none" }}
            >
              <CloseIcon />
            </Button>
          </div>
          <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 12 }}>
            We are uploading your products, process will take some time. Please
            refresh after sometime to see the newly added products, meanwhile
            sit back and relax! <br /> <br />
            For any issue
            <a
              href="https://wa.me/917651838757"
              target="_blank"
              rel="noopener noreferrer"
              style={{ margin: "0px 5px", cursor: "pointer" }}
            >
              contact support
            </a>
          </Typography>
          <div
            style={{
              height: "50px",
              position: "relative",
              marginTop: 28,
            }}
          >
            <div style={{ position: "absolute", bottom: 0, right: 0 }}>
              <SolidButton
                onClick={() => {
                  setOpenBulkUpload(false);
                  reset();
                }}
              >
                Continue
              </SolidButton>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  if (showGuide) {
    return (
      <Modal
        open={openBulkUpload}
        onClose={() => {
          setOpenBulkUpload(false);
          reset();
        }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        disableAutoFocus
      >
        <div
          style={{
            padding: 25,
            borderRadius: 12,
            position: "absolute",
            background: "#ffffff",
            width: 546,
            height: 660,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 25,
              }}
            >
              <Typography className={layoutHeading} style={{ fontSize: 19 }}>
                To Avoid File Rejection
              </Typography>
            </div>
            <ul style={{ marginTop: 24 }}>
              <li style={{ marginBottom: 16, lineHeight: "24px" }}>
                Please create all the categories before providing this file to
                us
              </li>
              <li style={{ marginBottom: 16, lineHeight: "24px" }}>
                If you are using any additional field apart from Size, Color,
                Weight, Please create it.
              </li>
              <li style={{ marginBottom: 16, lineHeight: "24px" }}>
                Images must be uploaded first in some place and then image url
                can be provided (Optional)
              </li>
              <li style={{ marginBottom: 16, lineHeight: "24px" }}>
                <a
                  href="https://docs.google.com/document/d/1chOjepOoxbwj92_07yfwNpkvmwzsYjpI3JFcFCpLVfw"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click Here
                </a>{" "}
                to understand how to get image url
              </li>
              <li style={{ marginBottom: 16, lineHeight: "24px" }}>
                Multiple images can be specified using "," (comma)
              </li>
              <li style={{ marginBottom: 16, lineHeight: "24px" }}>
                Product variations can be added
              </li>
              <li style={{ marginBottom: 16, lineHeight: "24px" }}>
                In all the product variations name must be same in all, only
                variation field like weight, color, size, mrp, sale_price can
                vary
              </li>
            </ul>
          </div>
          <div
            style={{
              height: "50px",
              position: "relative",
              marginTop: 28,
              marginBottom: 6,
            }}
          >
            <div style={{ position: "absolute", bottom: 0, right: 0 }}>
              <OutlinedButton
                onClick={() => {
                  setShowGuide(false);
                }}
              >
                Back
              </OutlinedButton>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      open={openBulkUpload}
      onClose={() => {
        setOpenBulkUpload(false);
        reset();
      }}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      disableAutoFocus
    >
      <div
        style={{
          padding: 25,
          borderRadius: 12,
          position: "absolute",
          background: "#ffffff",
          width: 546,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 25,
          }}
        >
          <Typography
            style={{
              fontWeight: 700,
              fontSize: 24,
              lineHeight: "32px",
              color: "#1A1A1A",
            }}
          >
            Bulk Import
          </Typography>
          <CloseButton
            onClick={() => {
              setOpenBulkUpload(false);
              reset();
            }}
            style={{ minWidth: 0, padding: 6 }}
          />
        </div>

        {filename ? (
          <div className={classes.uploadContainer}>
            <CsvIcon />
            <div style={{ width: "100%" }}>
              <div
                className={classes.flexContainer}
                style={{ marginBottom: 8 }}
              >
                <Typography style={{ fontSize: 12, fontWeight: 600 }}>
                  {filename}
                </Typography>
                <span role="button" onClick={reset}>
                  <CloseIcon width={12} height={12} />
                </span>
              </div>
              {(uploading || verifying || importing) && (
                <Fragment>
                  <LinearProgress color="secondary" />
                  <Typography
                    style={{ fontSize: 12, fontWeight: 400, marginTop: 5 }}
                  >
                    {uploading ? "Uploading" : ""}
                    {verifying ? "Verifying" : ""}
                    {importing ? "Importing" : ""}
                  </Typography>
                </Fragment>
              )}
            </div>
          </div>
        ) : (
          <Dropzone
            onDrop={handleDrop}
            multiple={false}
            maxSize={5e6}
            accept=".csv"
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} style={{ outline: "none" }}>
                <input {...getInputProps()} />
                <div className={classes.bulkContainer}>
                  <CloudUploadIcon />
                  <Typography
                    style={{ fontSize: 24, fontWeight: 600, marginTop: 24 }}
                  >
                    Drag & Drop
                  </Typography>
                  <Typography
                    style={{ fontSize: 14, fontWeight: 400, marginTop: 18 }}
                  >
                    a file here or{" "}
                    <Button
                      style={{
                        background: "#FFF",
                        border: "1px solid #999999",
                        padding: "2px 16px",
                        marginLeft: 5,
                        fontWeight: 600,
                        fontSize: 14,
                        textTransform: "none",
                      }}
                    >
                      Browse
                    </Button>
                  </Typography>
                </div>
              </div>
            )}
          </Dropzone>
        )}
        {verified && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <CheckCircleRounded style={{ color: "#72C472" }} />
            <Typography style={{ fontSize: 14, margin: "12px 8px" }}>
              Verification successfull, continue import.
            </Typography>
          </div>
        )}
        {!verified && !error && (
          <Fragment>
            <Typography style={{ fontSize: 12, fontWeight: 400 }}>
              Maximum 1MB of .csv files supported
            </Typography>
            <hr />
            <Typography
              style={{ fontSize: 14, fontWeight: 400, marginTop: 18 }}
            >
              Download{" "}
              <a
                href="https://storage.googleapis.com/shy-pub/_static/shoopy_product_sample_v6.xlsx"
                download
              >
                Sample File{" "}
              </a>
              to view template of other supported fields.
            </Typography>
            <Button
              className={transparentButton}
              onClick={(e) => {
                e.preventDefault();
                setShowGuide(true);
              }}
              style={{
                padding: 0,
                justifyContent: "flex-start",
                fontWeight: 400,
                fontSize: 14,
              }}
            >
              To avoid file rejection
            </Button>
            <div
              style={{
                border: "1px solid #E5E5E5",
                padding: 16,
                borderRadius: 12,
                marginTop: 18,
              }}
            >
              <Typography style={{ fontSize: 12, fontWeight: 600 }}>
                <InfoOutlined
                  color="secondary"
                  style={{
                    fontSize: 16,
                    marginRight: 10,
                    marginTop: -1,
                  }}
                />
                Important Note
              </Typography>
              <ul
                style={{
                  paddingLeft: 18,
                  marginTop: 13,
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
                <li style={{ marginBottom: 6 }}>
                  To create product variation of a product, make sure the
                  variant field like Color, Size, Weight etc is provided in all
                  the related product.
                </li>
                <li>Product name must be same in all the products.</li>
              </ul>
            </div>
          </Fragment>
        )}
        {error && (
          <div className={classes.errorDiv}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <ErrorIcon />
              <Typography
                style={{ marginLeft: 12, fontSize: 12, fontWeight: 600 }}
              >
                {error}
              </Typography>
            </div>
            {invalidRows && (
              <Button
                className={classes.downloadBtn}
                endIcon={<DownloadIcon />}
                onClick={onExportErrors}
              >
                Download
              </Button>
            )}
          </div>
        )}
        <div
          style={{
            height: "50px",
            position: "relative",
            marginTop: 28,
            marginBottom: 6,
          }}
        >
          <div style={{ position: "absolute", bottom: 0, right: 0 }}>
            {!importing && (
              <OutlinedButton
                onClick={() => {
                  setOpenBulkUpload(false);
                  reset();
                }}
                style={{ marginRight: 20 }}
              >
                Cancel
              </OutlinedButton>
            )}
            <SolidButton
              onClick={() => {
                if (!importing) {
                  onImport();
                }
              }}
              disabled={!verified}
              loading={importing}
            >
              Import
            </SolidButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BulkUpload;
