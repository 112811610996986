import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import { createUpdatePage } from "../../../utils/pageUtils";
import useStyles from "../styles";
import ExpandAndEdit from "./ExpandAndEdit";
import Editor from "../../products/components/Editor";

const TermsSettings = ({ tos, setTos, onClose, store, setToast }) => {
  const classes = useStyles();
  const [tc, setTc] = useState(
    tos?.find((item) => item.slug === "terms-and-conditions")?.description ?? ""
  );
  const [refundPolicy, setRefundPolicy] = useState(
    tos?.find((item) => item.slug === "refund-policy")?.description ?? ""
  );

  const [privacyPolicy, setPrivacyPolicy] = useState(
    tos?.find((item) => item.slug === "privacy-policy")?.description ?? ""
  );

  const [shippingPolicy, setShippingPolicy] = useState(
    tos?.find((item) => item.slug === "shipping-policy")?.description ?? ""
  );
  const [paymentPolicy, setPaymentPolicy] = useState(
    tos?.find((item) => item.slug === "payment-policy")?.description ?? ""
  );


  const [expand, setExpand] = useState({
    tc: false,
    refundPolicy: false,
    privacyPolicy: false,
    shippingPolicy: false,
    paymentPolicy: false
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (tos?.length > 0) {
      tos.forEach((item) => {
        if (item.slug === "terms-and-conditions") {
          setTc(item.description ? item.description : "");
        } else if (item.slug === "refund-policy") {
          setRefundPolicy(item.description ? item.description : "");
        } else if (item.slug === "privacy-policy") {
          setPrivacyPolicy(item.description ? item.description : "");
        }  else if (item.slug === "shipping-policy") {
          setShippingPolicy(item.description ? item.description : "");
        } else if (item.slug === "payment-policy") {
          setPaymentPolicy(item.description ? item.description : "");
        }
      });
    }
  }, [tos]);

  const onSave = async () => {
    setLoading(true);
    let temp = [];
    let tosPage = await createUpdatePage(tos, store, "terms-and-conditions", {
      description: tc,
      slug: "terms-and-conditions",
      title: "Terms and Conditions",
      store: null,
      store_id: store.id,
    });
    temp.push(tosPage);
    let shpPage = await createUpdatePage(tos, store, "shipping-policy", {
      description: shippingPolicy,
      slug: "shipping-policy",
      title: "Shipping Policy",
      store: null,
      store_id: store.id,
    });
    temp.push(shpPage);
    let payPage = await createUpdatePage(tos, store, "payment-policy", {
      description: paymentPolicy,
      slug: "payment-policy",
      title: "Payment Policy",
      store: null,
      store_id: store.id,
    });
    temp.push(payPage);
    let rfdPage = await createUpdatePage(tos, store, "refund-policy", {
      description: refundPolicy,
      slug: "refund-policy",
      title: "Return & Refund Policy",
      store: null,
      store_id: store.id,
    });
    temp.push(rfdPage);
    let privPage = await createUpdatePage(tos, store, "privacy-policy", {
      description: privacyPolicy,
      slug: "privacy-policy",
      title: "Privacy Policy",
      store: null,
      store_id: store.id,
    });
    temp.push(privPage);
    setTos(temp);
    setToast(true);
    setLoading(false);
  };

  if (expand.tc) {
    return (
      <ExpandAndEdit
        heading="Terms and Conditions"
        editorText={tc}
        setEditorText={setTc}
        onContinue={() => {
          setExpand({ ...expand, tc: false });
        }}
      />
    );
  }
  if (expand.shippingPolicy) {
    return (
      <ExpandAndEdit
        heading="Shipping Policy"
        editorText={shippingPolicy}
        setEditorText={setShippingPolicy}
        onContinue={() => {
          setExpand({ ...expand, shippingPolicy: false });
        }}
      />
    );
  }
  if (expand.paymentPolicy) {
    return (
      <ExpandAndEdit
        heading="Payment Policy"
        editorText={paymentPolicy}
        setEditorText={setPaymentPolicy}
        onContinue={() => {
          setExpand({ ...expand, paymentPolicy: false });
        }}
      />
    );
  }
  if (expand.refundPolicy) {
    return (
      <ExpandAndEdit
        heading="Refund Policy"
        editorText={refundPolicy}
        setEditorText={setRefundPolicy}
        onContinue={() => {
          setExpand({ ...expand, refundPolicy: false });
        }}
      />
    );
  }
  if (expand.privacyPolicy) {
    return (
      <ExpandAndEdit
        heading="Privacy Policy"
        editorText={privacyPolicy}
        setEditorText={setPrivacyPolicy}
        onContinue={() => {
          setExpand({ ...expand, privacyPolicy: false });
        }}
      />
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "100%",
      }}
    >
      <div>
        <div className={classes.dividerGrid} style={{ display: "block" }}>
          <Typography
            className={classes.subHeading}
            style={{ marginBottom: 12 }}
          >
            Terms and Conditions
          </Typography>
          <Editor editorText={tc} setEditorText={setTc} height="200px" />
          <p
            style={{
              margin: "0px",
              color: "#1641DB",
              cursor: "pointer",
              fontSize: 12,
              marginTop: 6,
              marginBottom: 4,
            }}
            onClick={() => {
              setExpand({ ...expand, tc: true });
            }}
          >
            Expand and Edit Field
          </p>
        </div>
        <div
          className={classes.dividerGrid}
          style={{ display: "block", borderBottom: "none" }}
        >
          <Typography
            className={classes.subHeading}
            style={{ marginBottom: 12 }}
          >
            Shipping Policy
          </Typography>
          <Editor
            editorText={shippingPolicy}
            setEditorText={setShippingPolicy}
            height="200px"
          />
          <p
            style={{
              margin: "0px",
              color: "#1641DB",
              cursor: "pointer",
              fontSize: 12,
              marginTop: 6,
              marginBottom: 4,
            }}
            onClick={() => {
              setExpand({ ...expand, shippingPolicy: true });
            }}
          >
            Expand and Edit Field
          </p>
        </div>
        <div
          className={classes.dividerGrid}
          style={{ display: "block", borderBottom: "none" }}
        >
          <Typography
            className={classes.subHeading}
            style={{ marginBottom: 12 }}
          >
            Payment Policy
          </Typography>
          <Editor
            editorText={paymentPolicy}
            setEditorText={setPaymentPolicy}
            height="200px"
          />
          <p
            style={{
              margin: "0px",
              color: "#1641DB",
              cursor: "pointer",
              fontSize: 12,
              marginTop: 6,
              marginBottom: 4,
            }}
            onClick={() => {
              setExpand({ ...expand, paymentPolicy: true });
            }}
          >
            Expand and Edit Field
          </p>
        </div>
        <div
          className={classes.dividerGrid}
          style={{ display: "block", borderBottom: "none" }}
        >
          <Typography
            className={classes.subHeading}
            style={{ marginBottom: 12 }}
          >
            Return and Refund Policy
          </Typography>
          <Editor
            editorText={refundPolicy}
            setEditorText={setRefundPolicy}
            height="200px"
          />
          <p
            style={{
              margin: "0px",
              color: "#1641DB",
              cursor: "pointer",
              fontSize: 12,
              marginTop: 6,
              marginBottom: 4,
            }}
            onClick={() => {
              setExpand({ ...expand, refundPolicy: true });
            }}
          >
            Expand and Edit Field
          </p>
        </div>
        <div
          className={classes.dividerGrid}
          style={{ display: "block", borderBottom: "none" }}
        >
          <Typography
            className={classes.subHeading}
            style={{ marginBottom: 12 }}
          >
            Privacy Policy
          </Typography>
          <Editor
            editorText={privacyPolicy}
            setEditorText={setPrivacyPolicy}
            height="200px"
          />
          <p
            style={{
              margin: "0px",
              color: "#1641DB",
              cursor: "pointer",
              fontSize: 12,
              marginTop: 6,
              marginBottom: 4,
            }}
            onClick={() => {
              setExpand({ ...expand, privacyPolicy: true });
            }}
          >
            Expand and Edit Field
          </p>
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "flex-end", paddingTop: 32 }}
      >
        <CombinedButtons
          outlinedBtnAction={() => {
            onClose();
          }}
          solidBtnAction={onSave}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default TermsSettings;
