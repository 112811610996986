import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const DownArrowIcon = ({ viewBox = "0 0 16 16", stroke = "#1A1A1A" }) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "16px", height: "16px" }}>
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.5 6.5L8 11L12.5 6.5"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
export default DownArrowIcon;
