import React from "react";
import { useHistory } from "react-router-dom";
import BannerForm from "./BannerForm";

const AddBanner = () => {
  const history = useHistory();

  const onClose = () => {
    history.goBack();
  };

  const onSave = () => {
    history.goBack();
  };

  return (
    <div>
      <BannerForm record={null} onSave={onSave} onClose={onClose} />
    </div>
  );
};

export default AddBanner;
