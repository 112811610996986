import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import Lottie from "react-lottie";
import { outlineButton } from "../../styles/common";
import ComponentLoader from "../ComponentLoader.json";

const OutlinedButton = ({
  onClick = () => {},
  loading = false,
  disabled = false,
  style = {},
  children,
  endIcon = <></>,
  startIcon = <></>,
  componentKey = 1,
  fullWidth = false,
}) => {
  const { disabledBtn, endIconCss, startIconCss } = makeStyles(() => ({
    disabledBtn: {
      borderColor: "#C4C4C4 !important",
      color: "#666666 !important",
    },
    endIconCss: {
      marginRight: 8,
    },
    startIconCss: {
      marginLeft: 8,
    },
  }))();

  return (
    <Button
      className={outlineButton}
      style={style}
      onClick={(e) => {
        if (!loading) onClick(e);
      }}
      disabled={disabled}
      classes={{
        disabled: disabledBtn,
        endIcon: endIconCss,
        startIcon: startIconCss,
      }}
      endIcon={endIcon}
      startIcon={startIcon}
      key={componentKey}
      fullWidth={fullWidth}
    >
      {loading ? (
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: ComponentLoader,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          height={24}
          width={24}
          style={{
            overflow: "visible",
            marginTop: -8,
          }}
        />
      ) : (
        children
      )}
    </Button>
  );
};

export default OutlinedButton;
