import React, { useState } from "react";
import { handleCustomerDataFetch } from "./formUtils";
import { NoPicIcon } from "../../../../icons";
import { Grid } from "@material-ui/core";
import Controls from "../../../../components/controls/Controls";
import SearchableSelect from "./SearchableSelect";
import SelectedItem from "./SelectedItem";
import SelectedAreaHead from "./SelectedAreaHead";
import { useSelector } from "react-redux";
export default function FormSectionFour({
  formState,
  onFormFieldChange,
  appliesTo,
  setAppliesTo,
  setIsPaidPopUpOpen,
}) {
  const [showCustomerPopUp, setShowCustomerPopUp] = useState(false);
  const storeState = useSelector((state) => state.store);
  const handleCustomerRemoval = (checked = false, updatedcustomer) => {
    let clonedObj = {
      ...formState.customers.value,
    };
    delete clonedObj[updatedcustomer.id];
    if (Object.keys(clonedObj).length === 0) {
      setAppliesTo("everyone");
    }
    onFormFieldChange("customers", clonedObj);
  };
  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Controls.Select
            options={[
              {
                id: "everyone",
                title: "Everyone",
              },
              {
                id: "specific-customers",
                title: "Specific Customers",
                plan: "BASIC",
              },
            ]}
            value={appliesTo}
            label="Customer Eligibility"
            onChange={(e) => {
              if (
                storeState.store.plan === "FREE" &&
                e.target.value !== "everyone"
              ) {
                setIsPaidPopUpOpen(true);
                return;
              }
              switch (e.target.value) {
                case "specific-customers":
                  setShowCustomerPopUp(true);
                  break;
                case "everyone":
                  onFormFieldChange("customers", {});
                  break;
                default:
              }
              setAppliesTo(e.target.value);
            }}
            labelPlacement="top"
          />
        </Grid>
        {appliesTo === "specific-customers" &&
          Object.keys(formState.customers.value).length > 0 && (
            <Grid item xs={12}>
              <SelectedAreaHead
                title="Selected Customers"
                updateAction={setShowCustomerPopUp}
              />
              <div style={{ maxHeight: "220px", overflow: "auto" }}>
                {Object.keys(formState.customers.value).map((customerKey) => {
                  let customer = formState.customers.value[customerKey];
                  return (
                    <SelectedItem
                      key={`${customer.id}-selected-item`}
                      item={customer}
                      onRemove={handleCustomerRemoval}
                    />
                  );
                })}
              </div>
            </Grid>
          )}
      </Grid>
      {showCustomerPopUp && (
        <SearchableSelect
          isOpen={showCustomerPopUp}
          heading="Select Customers"
          subHeading="Select customers to apply on the coupon"
          close={(selectedCustomers = {}) => {
            if (Object.keys(selectedCustomers).length > 0) {
              onFormFieldChange("customers", selectedCustomers);
            } else {
              setAppliesTo("everyone");
            }
            setShowCustomerPopUp(false);
          }}
          ImageFallback={NoPicIcon}
          fetchData={handleCustomerDataFetch}
          selected_items={formState.customers.value}
        />
      )}
    </>
  );
}
