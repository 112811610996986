import { Divider, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ImageHandler from "../../../components/ImageHandler";
import useStyles from "../styles";
import RemoveCircleIcon from "../../../icons/RemoveCircleIcon";
import Controls from "../../../components/controls/Controls";
import { useDispatch } from "react-redux";
import { updateStore } from "../../../redux/store/storeActions";
import { uploadImage } from "../../../utils/imageUploader";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import Input from "../../../components/controls/Input";
import { fetchUserStores } from "../../../redux/userstore/userStoreActions";
import Select from "../../../components/controls/Select";
import { ColorPicker, createColor } from "material-ui-color";
import { getNoticeColor } from "../settingUtils";
import { updateAttributes } from "../../../utils/storeUtil";
import { useHistory } from "react-router-dom";
import { colorPickerColors } from "../../../utils/colorPickerColors";

const HeaderSetting = ({
  store,
  attributes,
  setAttributes,
  onClose,
  setToast,
}) => {
  const defaultCrop = { x: 0, y: 0, width: 250, height: 250 };
  const classes = useStyles();
  const history = useHistory();
  const defNoticeColor = getNoticeColor(
    store.store_setting && store.store_setting.theme_color
  );
  const [showNoticeBar, setShowNoticeBar] = useState(false);
  const [notice, setNotice] = useState("");
  const [barColor, setBarColor] = useState(
    createColor(defNoticeColor.noticeBackgroundColor)
  );
  const [barFontColor, setBarFontColor] = useState(
    createColor(defNoticeColor.noticeColor)
  );
  const [showLogo, setShowLogo] = useState(true);
  const [cropImg, setCropImg] = useState(null);
  const [filename, setFilename] = useState("");
  const [showTitle, setShowTitle] = useState(true);
  const [title, setTitle] = useState("");
  const [punchLine, setPunchLine] = useState("");
  const [logoTitleAligment, setLogoTitleAligment] = useState("left");
  const [searchView, setSearchView] = useState("bar");
  const [showWhatsAppIcon, setShowWhatsAppIcon] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  const [showHeaderMenu, setShowHeaderMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openImageHandler, setOpenImageHandler] = useState(false);
  const [openImageHandlerIndex, setOpenImageHandlerIndex] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, []);

  const init = () => {
    if (store.logo) {
      setCropImg(store.logo);
    }
    if (store.name) {
      setTitle(store.name);
    }
    setShowNoticeBar(attributes?.header?.notice_show === "true" ? true : false);
    setNotice(attributes?.header?.notice_message ?? "");
    setBarColor(
      attributes?.header?.notice_background_color
        ? createColor(attributes.header.notice_background_color)
        : createColor(defNoticeColor.noticeBackgroundColor)
    );
    setBarFontColor(
      attributes?.header?.notice_color
        ? createColor(attributes.header.notice_color)
        : createColor(defNoticeColor.noticeColor)
    );
    setShowLogo(attributes?.header?.logo_show === "false" ? false : true);
    setShowTitle(attributes?.header?.title_show === "false" ? false : true);
    setPunchLine(attributes?.header?.title_punchline ?? "");
    setLogoTitleAligment(attributes?.header?.logo_title_align ?? "left");
    setSearchView(attributes?.header?.search_view ?? "bar");
    setShowWhatsAppIcon(
      attributes?.header?.whatsapp_icon_show === "true" ? true : false
    );
    setShowLocation(
      attributes?.header?.select_location_show === "true" ? true : false
    );
    setShowHeaderMenu(
      attributes?.header?.menu_bar_show === "true" ? true : false
    );
  };

  const onSave = async () => {
    setLoading(true);
    let imageUrl = null;
    if (cropImg) {
      if (cropImg.startsWith("https:")) {
        imageUrl = cropImg;
      } else {
        let cloudFile = await uploadImage(filename, cropImg);
        imageUrl = cloudFile.payload.location;
      }
    }

    let payload = [
      {
        name: "notice_show",
        value: showNoticeBar,
      },
      { name: "notice_message", value: notice },
      { name: "notice_background_color", value: barColor.css.backgroundColor },
      { name: "notice_color", value: barFontColor.css.backgroundColor },
      { name: "logo_show", value: showLogo },
      { name: "title_show", value: showTitle },
      { name: "title_punchline", value: punchLine },
      { name: "logo_title_align", value: logoTitleAligment },
      { name: "search_view", value: searchView },
      { name: "whatsapp_icon_show", value: showWhatsAppIcon },
      { name: "select_location_show", value: showLocation },
      { name: "menu_bar_show", value: showHeaderMenu },
    ];
    let res = await updateAttributes(store.slug, "header", payload);
    if (res) {
      setAttributes({
        ...attributes,
        header: {
          notice_show: showNoticeBar,
          notice_message: notice,
          notice_background_color: barColor.css.backgroundColor,
          notice_color: barFontColor.css.backgroundColor,
          logo_show: showLogo,
          title_show: showTitle,
          title_message: title,
          title_punchline: punchLine,
          logo_title_align: logoTitleAligment,
          search_view: searchView,
          whatsapp_icon_show: showWhatsAppIcon,
          select_location_show: showLocation,
          menu_bar_show: showHeaderMenu,
        },
      });
    }
    await dispatch(
      updateStore({
        logo: imageUrl,
        name: title,
      })
    ).then(() => {
      setLoading(false);
      setToast(true);
    });
    dispatch(fetchUserStores(true));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "100%",
      }}
    >
      <div className={classes.dividerBox} style={{ display: "block" }}>
        <Controls.Switch
          name="annBar"
          label="Announcement Bar"
          color="secondary"
          value={showNoticeBar}
          onChange={() => setShowNoticeBar(!showNoticeBar)}
          width="100%"
        />
        {showNoticeBar && (
          <>
            <Typography
              className={classes.subHeading}
              style={{ marginBottom: 12, fontWeight: 400 }}
            >
              Announcement Message
            </Typography>
            <Input
              color="secondary"
              variant="outlined"
              multiline
              rows="3"
              value={notice}
              onChange={(e) => {
                setNotice(e.target.value);
              }}
              placeholder="Write a message to convey important information to your customers"
              FormHelperTextProps={{
                style: { position: "absolute", bottom: -20, right: 0 },
              }}
            />
            <Typography
              className={classes.subHeading}
              style={{ marginBottom: 10, fontWeight: 400 }}
            >
              Bar Color
            </Typography>
            <div className={classes.colorPicker}>
              <ColorPicker
                hideTextfield
                value={barColor}
                onChange={(value) => {
                  setBarColor(value);
                }}
                palette={colorPickerColors}
              />
              <Typography style={{ fontSize: 14, marginLeft: 10 }}>
                {barColor.css.backgroundColor}
              </Typography>
            </div>
            <Typography
              className={classes.subHeading}
              style={{ marginTop: 10, marginBottom: 10, fontWeight: 400 }}
            >
              Font Color
            </Typography>
            <div className={classes.colorPicker}>
              <ColorPicker
                hideTextfield
                value={barFontColor}
                onChange={(value) => {
                  setBarFontColor(value);
                }}
                palette={colorPickerColors}
              />
              <Typography style={{ fontSize: 14, marginLeft: 10 }}>
                {barFontColor.css.backgroundColor}
              </Typography>
            </div>
          </>
        )}
      </div>
      <div className={classes.dividerBox} style={{ display: "block" }}>
        <Controls.Switch
          name="showLogo"
          label="Show Store Logo"
          color="secondary"
          value={showLogo}
          onChange={() => setShowLogo(!showLogo)}
          width="100%"
        />
        {showLogo && (
          <div
            style={{
              paddingBottom: 24,
              marginTop: 16,
            }}
          >
            <ImageHandler
              cropImg={cropImg}
              setCropImg={setCropImg}
              defaultCrop={defaultCrop}
              setFilename={setFilename}
              imgFormat="image/png"
              maxSize={600}
              openImageHandler={openImageHandler}
              setOpenImageHandler={setOpenImageHandler}
              openImageHandlerIndex={openImageHandlerIndex}
              openModal={openModal}
              setOpenModal={setOpenModal}
            />
            {cropImg && (
              <div style={{ width: 60, position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    top: -11,
                    right: -11,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setCropImg(null);
                  }}
                >
                  <RemoveCircleIcon />
                </div>
                <img
                  src={cropImg}
                  alt=""
                  style={{
                    width: 60,
                    height: 60,
                    borderRadius: 6,
                    objectFit: "contain",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setOpenImageHandler(true);
                    setOpenImageHandlerIndex(0);
                  }}
                />
              </div>
            )}
          </div>
        )}
        <Controls.Switch
          name="showName"
          label="Show Store Name"
          color="secondary"
          value={showTitle}
          onChange={() => setShowTitle(!showTitle)}
          width="100%"
        />
        {showTitle && (
          <Input
            color="secondary"
            variant="outlined"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            placeholder="Set your store name"
          />
        )}
        <Input
          color="secondary"
          variant="outlined"
          value={punchLine}
          onChange={(e) => setPunchLine(e.target.value)}
          placeholder="Add a Tag line for your business"
          label="Business Motto"
          labelPlacement="top"
          helperText={`${punchLine.length}/40`}
          maxLength={40}
          FormHelperTextProps={{
            style: { position: "absolute", bottom: -20, right: 0 },
          }}
        />
        {(showLogo || showTitle) && (
          <Select
            name="aligment"
            label="Content Alignment"
            value={logoTitleAligment}
            onChange={(e) => setLogoTitleAligment(e.target.value)}
            options={[
              { title: "Left Aligned", id: "left" },
              { title: "Center Aligned", id: "center" },
            ]}
            color="secondary"
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            labelPlacement="top"
          />
        )}
      </div>
      <div className={classes.dividerBox} style={{ display: "block" }}>
        <Select
          name="searchView"
          label="Search View"
          value={searchView}
          onChange={(e) => setSearchView(e.target.value)}
          options={[
            { title: "Input Box", id: "bar" },
            { title: "Icon", id: "icon" },
          ]}
          color="secondary"
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          labelPlacement="top"
        />
      </div>
      {store?.store_setting?.show_support_mobile &&
        store?.store_setting?.support_mobile && (
          <div className={classes.dividerBox} style={{ display: "block" }}>
            <Controls.Switch
              name="showWhatsAppIcon"
              label="Show WhatsApp Icon"
              color="secondary"
              value={showWhatsAppIcon}
              onChange={() => setShowWhatsAppIcon(!showWhatsAppIcon)}
              width="100%"
            />
          </div>
        )}
      <div className={classes.dividerBox} style={{ display: "block" }}>
        <Controls.Switch
          name="showLocation"
          label="Show GPS Icon"
          color="secondary"
          value={showLocation}
          onChange={() => setShowLocation(!showLocation)}
          width="100%"
        />
      </div>
      <div className={classes.dividerBox} style={{ display: "block" }}>
        <Controls.Switch
          name="showHedaerMenu"
          label="Show Header Menu"
          color="secondary"
          value={showHeaderMenu}
          onChange={() => setShowHeaderMenu(!showHeaderMenu)}
          width="100%"
        />
        {showHeaderMenu && (
          <>
            <Divider style={{ marginBottom: "20px", marginTop: "10px" }} />
            <span
              className={classes.clickableHeading}
              onClick={() => {
                history.push(`/${store.id}/display/menu`);
              }}
            >
              View Menu
            </span>
          </>
        )}
      </div>
      <div
        style={{ display: "flex", justifyContent: "flex-end", paddingTop: 32 }}
      >
        <CombinedButtons
          outlinedBtnAction={() => {
            onClose();
            init();
          }}
          loading={loading}
          solidBtnAction={onSave}
        />
      </div>
    </div>
  );
};

export default HeaderSetting;
