import { SvgIcon } from "@material-ui/core";
import React from "react";

const DeliveryIcon = ({
  viewBox = "0 0 16 17",
  stroke = "#999999",
  color = "#ffffff",
}) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "16px", height: "17px" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="13"
        viewBox="0 0 18 13"
        fill="none"
      >
        <path
          d="M3.58146 12.6313C2.9309 12.6313 2.37861 12.4038 1.92458 11.9488C1.47056 11.4938 1.24354 10.9414 1.24354 10.2915H0V2.2563C0 1.88116 0.129514 1.56407 0.388542 1.30505C0.647569 1.04602 0.964583 0.916504 1.33958 0.916504H12.5192V3.7563H14.7306L17.4229 7.5288V10.2915H16.0833C16.0833 10.9414 15.8556 11.4938 15.4002 11.9488C14.9449 12.4038 14.3921 12.6313 13.7417 12.6313C13.0913 12.6313 12.539 12.4038 12.0848 11.9488C11.6308 11.4938 11.4038 10.9414 11.4038 10.2915H5.92292C5.92292 10.9432 5.69528 11.496 5.24 11.95C4.78472 12.4042 4.23188 12.6313 3.58146 12.6313ZM3.58333 11.548C3.93611 11.548 4.23368 11.4267 4.47604 11.1842C4.7184 10.9419 4.83958 10.6443 4.83958 10.2915C4.83958 9.93873 4.7184 9.64109 4.47604 9.39859C4.23368 9.15623 3.93611 9.03505 3.58333 9.03505C3.23056 9.03505 2.93292 9.15623 2.69042 9.39859C2.44806 9.64109 2.32687 9.93873 2.32687 10.2915C2.32687 10.6443 2.44806 10.9419 2.69042 11.1842C2.93292 11.4267 3.23056 11.548 3.58333 11.548ZM1.08333 9.20817H1.51917C1.69653 8.82998 1.97347 8.52602 2.35 8.2963C2.72667 8.06657 3.13778 7.95171 3.58333 7.95171C4.01806 7.95171 4.42639 8.06525 4.80833 8.29234C5.19028 8.51942 5.46667 8.8247 5.6375 9.20817H11.4358V1.99984H1.33958C1.27556 1.99984 1.21681 2.02657 1.16333 2.08005C1.11 2.13338 1.08333 2.19213 1.08333 2.2563V9.20817ZM13.7435 11.548C14.0963 11.548 14.3939 11.4267 14.6363 11.1842C14.8788 10.9419 15 10.6443 15 10.2915C15 9.93873 14.8788 9.64109 14.6363 9.39859C14.3939 9.15623 14.0963 9.03505 13.7435 9.03505C13.3908 9.03505 13.0931 9.15623 12.8506 9.39859C12.6083 9.64109 12.4871 9.93873 12.4871 10.2915C12.4871 10.6443 12.6083 10.9419 12.8506 11.1842C13.0931 11.4267 13.3908 11.548 13.7435 11.548ZM12.5192 7.70817L16.2115 7.68734L14.1521 4.83963H12.5192V7.70817Z"
          fill="#1641DB"
        />
      </svg>
    </SvgIcon>
  );
};

export default DeliveryIcon;
