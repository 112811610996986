import { Button, ClickAwayListener, makeStyles } from "@material-ui/core";
import React from "react";
import { DownArrowIcon } from "../../icons";
import UpArrowIcon from "../../icons/UpArrowIcon";
import { blueButton, delButton, rowFlexCenterAll } from "../../styles/common";

const DropdownButton = ({ style = {}, isDelete = false, list = [] }) => {
  const [openDropdown, setOpenDropdown] = React.useState(false);

  const { disabledBtn, endIconCss, startIconCss, sheet, actionBtn } =
    makeStyles(() => ({
      disabledBtn: {
        background: "#C4C4C4 !important",
        color: "#666666 !important",
      },
      endIconCss: {
        marginRight: 8,
      },
      startIconCss: {
        marginLeft: 8,
      },
      sheet: {
        position: "absolute",
        top: 54,
        borderRadius: 6,
        background: "#FFFFFF",
        overflow: "hidden",
        zIndex: 1,
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
        display: "block",
        width: "100%",
        padding: 4,
      },
      actionBtn: {
        fontWeight: 400,
        fontSize: 12,
        borderRadius: 6,
        padding: 9,
        cursor: "pointer",
        "&:hover": {
          background: "#F5F7FC",
        },
        display: "flex",
        alignItems: "center",
      },
    }))();

  return (
    <ClickAwayListener
      onClickAway={() => {
        setOpenDropdown(false);
      }}
    >
      <div style={{ position: "relative" }}>
        <Button
          type="submit"
          className={isDelete ? delButton : blueButton}
          style={{ paddingRight: 0, ...style }}
          onClick={(e) => {
            list[0].onClick(e);
          }}
          classes={{
            disabled: disabledBtn,
            endIcon: endIconCss,
            startIcon: startIconCss,
          }}
          startIcon={list[0]?.icon}
        >
          {list[0]?.label}
          <span
            onClick={(e) => {
              e.stopPropagation();
              setOpenDropdown(!openDropdown);
            }}
            style={{
              paddingLeft: 14,
              marginLeft: 14,
              paddingRight: 16,
              borderLeft: "1px solid #FFFFFF",
            }}
          >
            {openDropdown ? (
              <UpArrowIcon stroke="#FFFFFF" />
            ) : (
              <DownArrowIcon stroke="#FFFFFF" />
            )}
          </span>
        </Button>
        <div
          className={sheet}
          style={{
            visibility: openDropdown ? "visible" : "hidden",
          }}
          onClick={() => setOpenDropdown(false)}
        >
          {list.slice(1).map((obj, i) => {
            if (obj.hidden) {
              return <React.Fragment key={i}></React.Fragment>;
            }
            return (
              <div key={i} className={actionBtn} onClick={obj.onClick}>
                <div className={rowFlexCenterAll} style={{ width: 16 }}>
                  {obj.icon}
                </div>
                <span style={{ marginLeft: 12 }}>{obj.label}</span>
              </div>
            );
          })}
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default DropdownButton;
