import { Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { rowFlexAlignCenter } from "../../../../styles/common";
import OutlinedButton from "../../../../components/buttons/OutlinedButton";

const useStyles = makeStyles(() => ({
  promoheading: {
    fontSize: 16,
    lineHeight: "20px",
    marginLeft: 8,
  },
  container: {
    border: "1px #E1E1E1 solid",
    borderRadius: 8,
    padding: 24,
    marginTop: 16,
  },
  subheading: {
    fontSize: 14,
    margin: "16px 0px",
    fontWeight: "bold",
  },
  message: {
    marginLeft: 8,
    marginBottom: 0,
    fontSize: 14,
    lineHeight: "20px",
  },
  list: {
    "& li": {
      marginBottom: 8,
    },
  },
}));
const PROMO_IMG_URL =
  "https://storage.googleapis.com/shy-pub/_static/app-img/promo_offer.png";
const SHIPROCKET_IMG_URL =
  "https://storage.googleapis.com/shy-pub/_static/app-img/shiprocket.png";

const ShiprocketPromo = () => {
  const classes = useStyles();
  const sendToShipRocket = () => {
    window.open(
      "https://app.shiprocket.in/register?utm_source=Channel_Partner&utm_term=SRCP0434",
      "_blank"
    );
  };
  return (
    <div>
      <div className={rowFlexAlignCenter}>
        <img width="24" height="24" alt="Promotion Offer" src={PROMO_IMG_URL} />
        <Typography variant="h4" className={classes.promoheading}>
          Promotionl Offer
        </Typography>
      </div>
      <div className={classes.container}>
        <div className={rowFlexAlignCenter}>
          <img
            width="32"
            height="32"
            alt="Shiprocket"
            src={SHIPROCKET_IMG_URL}
          />
          <Typography className={classes.message}>
            Unlock Shipping Excellence: Enjoy 6 Months of Shiprocket Advance
            Plan for FREE (Worth Rs 12,000)
          </Typography>
        </div>
        <div>
          <Typography varinat="h5" className={classes.subheading}>
            Streamline your shipping with premium features
          </Typography>
          <ul className={classes.list}>
            <li>Cheap Shipping rates</li>
            <li>Automated Order Sync</li>
            <li>Upto 10000 SKUs Management and many more</li>
          </ul>
        </div>
        <div style={{ marginTop: 16 }}>
          <b>Register now</b>&nbsp;<span>using the provided button</span>
        </div>
        <OutlinedButton onClick={sendToShipRocket} style={{ marginTop: 32 }}>
          Register Now!
        </OutlinedButton>
      </div>
    </div>
  );
};
export default ShiprocketPromo;
