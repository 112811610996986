import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { makeStyles } from "@material-ui/core/styles";
import "../slick.css";

const useStyles = makeStyles((theme) => ({
  image: {
    width: "100%",
    height: 300,
    objectFit: "contain",
    maxHeight: 480,
    [theme.breakpoints.up("md")]: {
      marginTop: 26,
    },
  },
}));
function Carousel({ media }) {
  var settings = {
    customPaging: function (i) {
      if (media[i].url.includes("youtube.com"))
        return (
          // eslint-disable-next-line
          <a>
            <img
              style={{
                height: 64,
                width: 85,
                objectFit: "cover",
                marginTop: 16,
                border: "1px solid #ECECEC",
              }}
              src={`https://img.youtube.com/vi/${getId(
                media[i].url
              )}/hqdefault.jpg`}
              alt=""
            />
          </a>
        );
      return (
        // eslint-disable-next-line
        <a>
          <img
            style={{
              height: 64,
              width: 85,
              objectFit: "cover",
              marginTop: 16,
              border: "1px solid #ECECEC",
            }}
            src={media[i].url}
            alt=""
          />
        </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const classess = useStyles();
  const [extraPadding, setExtraPadding] = useState(0);

  useEffect(() => {
    const dots = document.getElementsByClassName("slick-dots");
    if (dots.length > 0) {
      setExtraPadding(dots.item(0).offsetHeight);
    }
  }, []);
  function getId(url) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  }
  return (
    <div style={{ paddingBottom: extraPadding }}>
      <Slider {...settings}>
        {media.map((item, i) => {
          if (item.type === "image") {
            return (
              <a
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
                key={i}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  key={i}
                >
                  <img className={classess.image} src={item.url} alt="" />
                </div>
              </a>
            );
          } else {
            return (
              <iframe
                src={"https://youtube.com/embed/" + getId(item.url)}
                frameBorder="0"
                allowFullScreen
                height="300"
                title={getId(item.url)}
              />
            );
          }
        })}
      </Slider>
    </div>
  );
}

export default Carousel;
