import React, { useEffect, useState } from "react";
import { Container, CircularProgress, makeStyles } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import { getStoreIdFromUrl } from "../../../utils/storeUtil";

const useStyles = makeStyles(() => ({
  progressbar: {
    position: "fixed",
    top: "50%",
    left: "50%",
  },
}));

const RazorpayOAuth = () => {
  const classes = useStyles();
  const [loading] = useState(true);
  const storeId = getStoreIdFromUrl();
  useEffect(() => {
    let url = createOAuthUrl();
    window.location = url;
    // eslint-disable-next-line
  }, [storeId]);

  const createOAuthUrl = () => {
    let url = process.env.REACT_APP_SELF;
    let state = uuidv4();
    let hash = storeId + state;
    let callback = `${url}/apps/razorpay/auth`;
    sessionStorage.setItem('state_token', hash);
    sessionStorage.setItem('storeid', storeId);
    let clientId = process.env.REACT_APP_RAZORPAY_CLIENT_ID;
    return `https://auth.razorpay.com/authorize?client_id=${clientId}&response_type=code&redirect_uri=${callback}&scope=read_write&state=${state}`;
  };

  return (
    <Container className={classes.container}>
      {loading && (
        <CircularProgress
          color="secondary"
          className={classes.progressbar}
        />
      )}
    </Container>
  );
};
export default RazorpayOAuth;
