import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const MobileDrawing = ({
  viewBox = "0 0 320 192",
  stroke = "#999999",
  fill = "#999999",
}) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "320px", height: "192px" }}>
      <svg
        width="320"
        height="192"
        viewBox="0 0 320 192"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M320 190H0V191H320V190Z" fill="#2F2E41" />
        <path
          d="M139.99 181.989C147.067 181.989 152.805 176.246 152.805 169.163C152.805 162.08 147.067 156.337 139.99 156.337C132.912 156.337 127.174 162.08 127.174 169.163C127.174 176.246 132.912 181.989 139.99 181.989Z"
          fill="#2F2E41"
        />
        <path
          d="M130.861 184.96L127.319 183.341L130.498 175.89L134.04 177.509L130.861 184.96Z"
          fill="#2F2E41"
        />
        <path
          d="M141.122 180.747L137.581 179.128L134.682 185.478L138.223 187.097L141.122 180.747Z"
          fill="#2F2E41"
        />
        <path
          d="M138.768 189.934C139.316 189.544 138.917 188.044 137.878 186.583C136.839 185.122 135.553 184.254 135.005 184.644C134.458 185.034 134.857 186.534 135.896 187.995C136.935 189.456 138.221 190.324 138.768 189.934Z"
          fill="#2F2E41"
        />
        <path
          d="M131.553 187.865C132.1 187.475 131.702 185.974 130.663 184.513C129.624 183.052 128.337 182.184 127.79 182.574C127.243 182.964 127.641 184.465 128.68 185.926C129.719 187.387 131.006 188.255 131.553 187.865Z"
          fill="#2F2E41"
        />
        <path
          d="M141.633 170.728C144.052 170.728 146.013 168.766 146.013 166.345C146.013 163.924 144.052 161.961 141.633 161.961C139.214 161.961 137.253 163.924 137.253 166.345C137.253 168.766 139.214 170.728 141.633 170.728Z"
          fill="white"
        />
        <path
          d="M140.052 165.836C140.859 165.836 141.512 165.182 141.512 164.375C141.512 163.568 140.859 162.914 140.052 162.914C139.246 162.914 138.592 163.568 138.592 164.375C138.592 165.182 139.246 165.836 140.052 165.836Z"
          fill="#3F3D56"
        />
        <path
          d="M133.635 153.144C134.619 148.495 139.574 145.608 144.701 146.696C149.828 147.784 153.187 152.434 152.202 157.083C151.217 161.732 147.424 161.878 142.297 160.79C137.169 159.702 132.65 157.793 133.635 153.144Z"
          fill="#CFCCE0"
        />
        <path
          d="M158.438 169.977C158.465 168.866 155.619 167.895 152.081 167.81C148.543 167.724 145.653 168.556 145.627 169.668C145.6 170.779 148.446 171.75 151.984 171.835C155.522 171.921 158.411 171.089 158.438 169.977Z"
          fill="#2F2E41"
        />
        <path
          d="M133.029 167.819C133.055 166.707 130.209 165.737 126.671 165.651C123.134 165.566 120.244 166.398 120.217 167.509C120.19 168.621 123.036 169.591 126.574 169.677C130.112 169.762 133.002 168.93 133.029 167.819Z"
          fill="#2F2E41"
        />
        <path
          d="M135.332 173.191C135.01 173.896 134.981 174.7 135.252 175.426C135.522 176.153 136.07 176.742 136.774 177.064C137.479 177.386 138.282 177.415 139.008 177.144C139.733 176.873 140.322 176.325 140.644 175.62C141.314 174.152 140.264 173.304 138.797 172.634C137.33 171.963 136.002 171.723 135.332 173.191Z"
          fill="white"
        />
        <path
          d="M136.983 157.546C136.994 181.574 122.725 189.969 105.12 189.978C104.711 189.978 104.304 189.974 103.899 189.965C103.083 189.947 102.276 189.911 101.477 189.854C85.5875 188.736 73.2374 179.923 73.2266 157.577C73.2153 134.46 102.712 105.274 104.945 103.097C104.947 103.095 104.949 103.094 104.951 103.092C105.022 103.023 105.124 103.013 105.195 103.082C107.299 105.126 136.971 134.371 136.983 157.546Z"
          fill="#1641DB"
        />
        <path
          d="M103.958 186.303L115.609 169.994L103.929 188.093L103.899 189.965C103.083 189.947 102.276 189.911 101.477 189.854L102.721 165.816L102.711 165.63L102.733 165.594L102.851 163.323L91.1245 145.191L102.885 161.621L102.915 162.103L103.855 143.941L93.8148 125.203L103.977 140.749L104.947 103.094L104.95 102.969L104.951 103.092L104.801 132.787L114.779 121.01L104.759 135.345L104.503 151.607L113.822 135.996L104.467 153.998L104.324 163.04L117.849 141.31L104.273 166.195L103.958 186.303Z"
          fill="#3F3D56"
        />
        <path
          d="M214.033 100.661C214.443 100.661 214.776 100.328 214.776 99.9171C214.776 99.5066 214.443 99.1737 214.033 99.1737C213.623 99.1737 213.291 99.5066 213.291 99.9171C213.291 100.328 213.623 100.661 214.033 100.661Z"
          fill="#CFCCE0"
        />
        <path
          d="M112.743 103.456C113.153 103.456 113.485 103.123 113.485 102.713C113.485 102.302 113.153 101.969 112.743 101.969C112.332 101.969 112 102.302 112 102.713C112 103.123 112.332 103.456 112.743 103.456Z"
          fill="#CFCCE0"
        />
        <path
          d="M141.11 139.491C141.52 139.491 141.853 139.158 141.853 138.747C141.853 138.337 141.52 138.004 141.11 138.004C140.7 138.004 140.368 138.337 140.368 138.747C140.368 139.158 140.7 139.491 141.11 139.491Z"
          fill="#CFCCE0"
        />
        <path
          d="M127.743 63.4717C128.153 63.4717 128.485 63.1389 128.485 62.7283C128.485 62.3177 128.153 61.9849 127.743 61.9849C127.332 61.9849 127 62.3177 127 62.7283C127 63.1389 127.332 63.4717 127.743 63.4717Z"
          fill="#CFCCE0"
        />
        <path
          d="M144.743 108.454C145.153 108.454 145.485 108.121 145.485 107.711C145.485 107.3 145.153 106.967 144.743 106.967C144.332 106.967 144 107.3 144 107.711C144 108.121 144.332 108.454 144.743 108.454Z"
          fill="#CFCCE0"
        />
        <path
          d="M244.634 66.2547C245.536 66.2547 246.267 65.5227 246.267 64.6198C246.267 63.7168 245.536 62.9849 244.634 62.9849C243.731 62.9849 243 63.7168 243 64.6198C243 65.5227 243.731 66.2547 244.634 66.2547Z"
          fill="#CFCCE0"
        />
        <path
          d="M271.903 114.818C272.805 114.818 273.536 114.086 273.536 113.183C273.536 112.28 272.805 111.548 271.903 111.548C271 111.548 270.269 112.28 270.269 113.183C270.269 114.086 271 114.818 271.903 114.818Z"
          fill="#CFCCE0"
        />
        <path
          d="M62.5 85.9756C64.433 85.9756 66 84.4092 66 82.4769C66 80.5447 64.433 78.9783 62.5 78.9783C60.567 78.9783 59 80.5447 59 82.4769C59 84.4092 60.567 85.9756 62.5 85.9756Z"
          fill="#CFCCE0"
        />
        <path
          d="M168.634 88.2458C169.536 88.2458 170.267 87.5138 170.267 86.6109C170.267 85.7079 169.536 84.976 168.634 84.976C167.731 84.976 167 85.7079 167 86.6109C167 87.5138 167.731 88.2458 168.634 88.2458Z"
          fill="#CFCCE0"
        />
        <path
          d="M95.6351 133.06L72.9993 105.968L27.5628 106.386L0 133.395L0.556999 133.755H0.357963V189.935H95.5743V133.755L95.6351 133.06Z"
          fill="#F2F2F2"
        />
        <path
          d="M73.023 106.025L44.7446 139.38V189.935H95.5743V133.035L73.023 106.025Z"
          fill="#E6E6E6"
        />
        <path
          d="M75.5287 161.978H65.1479V171.209H75.5287V161.978Z"
          fill="#3F3D56"
        />
        <path d="M75.5287 146H65.1479V155.084H75.5287V146Z" fill="#3F3D56" />
        <path
          d="M75.5287 161.978H65.1479V171.209H75.5287V161.978Z"
          fill="white"
        />
        <path d="M75.5287 146H65.1479V155.084H75.5287V146Z" fill="white" />
        <path
          d="M29.3963 159.528H19.0156V168.759H29.3963V159.528Z"
          fill="white"
        />
        <path
          d="M29.3963 143.55H19.0156V152.635H29.3963V143.55Z"
          fill="white"
        />
        <path
          d="M93.9968 175.876H53.0879V177.204H59.468V189.816H60.7875V177.204H85.6371V189.816H86.957V177.204H93.9968V175.876Z"
          fill="#3F3D56"
        />
        <path
          d="M94.0774 171.904H53.1685V173.232H94.0774V171.904Z"
          fill="#3F3D56"
        />
        <path
          d="M94.0774 168.585H53.1685V169.913H94.0774V168.585Z"
          fill="#3F3D56"
        />
        <path
          d="M94.0774 165.266H53.1685V166.594H94.0774V165.266Z"
          fill="#3F3D56"
        />
        <path
          d="M46.915 176.716C52.4335 176.716 56.9072 167.592 56.9072 156.336C56.9072 145.081 52.4335 135.956 46.915 135.956C41.3965 135.956 36.9229 145.081 36.9229 156.336C36.9229 167.592 41.3965 176.716 46.915 176.716Z"
          fill="#3F3D56"
        />
        <path
          d="M47.8379 189.695C43.836 165.694 47.7976 141.779 47.8381 141.54L48.6166 141.672C48.5763 141.909 44.6373 165.699 48.6168 189.566L47.8379 189.695Z"
          fill="#1641DB"
        />
        <path
          d="M55.7132 151.954L46.6768 156.785L47.0493 157.481L56.0857 152.65L55.7132 151.954Z"
          fill="#1641DB"
        />
        <path
          d="M37.5129 154.374L37.1401 155.07L46.1754 159.904L46.5482 159.208L37.5129 154.374Z"
          fill="#1641DB"
        />
        <path
          d="M27.439 155.841C42.0408 155.841 53.878 131.698 53.878 101.916C53.878 72.1335 42.0408 47.9904 27.439 47.9904C12.8371 47.9904 1 72.1335 1 101.916C1 131.698 12.8371 155.841 27.439 155.841Z"
          fill="#1641DB"
        />
        <path
          d="M30.3839 190.099C19.809 126.675 30.2773 63.4816 30.3842 62.8507L31.4373 63.029C31.3306 63.6583 20.8931 126.682 31.4376 189.924L30.3839 190.099Z"
          fill="#3F3D56"
        />
        <path
          d="M50.9569 90.7698L27.0483 103.553L27.5522 104.495L51.4608 91.7114L50.9569 90.7698Z"
          fill="#3F3D56"
        />
        <path
          d="M2.32004 97.1735L1.81592 98.115L25.721 110.905L26.2251 109.963L2.32004 97.1735Z"
          fill="#3F3D56"
        />
        <path
          d="M73.1844 33.4421L80 28.0732C74.7053 27.4979 72.5298 30.3419 71.6395 32.593C67.503 30.9013 63 33.1183 63 33.1183L76.6367 37.9942C75.9487 36.1844 74.7512 34.6053 73.1844 33.4421Z"
          fill="#3F3D56"
        />
        <path
          d="M280.184 5.44402L287 0.0751875C281.705 -0.500139 279.53 2.34387 278.639 4.59493C274.503 2.90323 270 5.12028 270 5.12028L283.637 9.99612C282.949 8.18636 281.751 6.60728 280.184 5.44402Z"
          fill="#3F3D56"
        />
        <path
          d="M180.605 43.9849V168.38C180.605 169.862 181.194 171.283 182.242 172.331C183.29 173.379 184.71 173.967 186.192 173.967H236.671C238.153 173.967 239.574 173.379 240.621 172.331C241.669 171.283 242.258 169.862 242.258 168.38V43.9849C242.258 43.2509 242.114 42.524 241.833 41.8459C241.552 41.1677 241.141 40.5515 240.622 40.0325C240.103 39.5134 239.487 39.1017 238.81 38.8208C238.132 38.54 237.405 38.3954 236.671 38.3955H229.118V39.3662C229.117 40.5867 228.633 41.7571 227.77 42.6202C226.907 43.4833 225.737 43.9683 224.517 43.9686H198.845C197.625 43.9686 196.455 43.4837 195.593 42.6206C194.73 41.7575 194.245 40.5868 194.245 39.3662V38.3955H186.192C185.458 38.3954 184.732 38.54 184.054 38.8208C183.376 39.1017 182.76 39.5134 182.241 40.0325C181.722 40.5515 181.311 41.1677 181.03 41.8459C180.749 42.524 180.605 43.2509 180.605 43.9849Z"
          fill="#F2F2F2"
        />
        <path
          d="M243.341 55.5695H243.703C243.784 55.5695 243.862 55.5372 243.919 55.4797C243.977 55.4222 244.009 55.3442 244.009 55.2629V50.1685C244.009 50.0872 243.977 50.0092 243.919 49.9517C243.862 49.8942 243.784 49.8619 243.703 49.8619H243.341"
          stroke="#3F3D56"
          strokeMiterlimit="10"
        />
        <path
          d="M243.398 70.246H243.707C243.798 70.246 243.886 70.2096 243.951 70.1447C244.016 70.0799 244.052 69.992 244.052 69.9004V60.657C244.052 60.6116 244.043 60.5666 244.026 60.5247C244.008 60.4828 243.983 60.4447 243.951 60.4126C243.919 60.3805 243.881 60.3551 243.839 60.3377C243.797 60.3203 243.752 60.3114 243.707 60.3114H243.341"
          stroke="#3F3D56"
          strokeMiterlimit="10"
        />
        <path
          d="M243.398 83.7423H243.702C243.789 83.7423 243.873 83.7077 243.934 83.646C243.996 83.5843 244.031 83.5007 244.031 83.4135V74.0508C244.031 73.9635 243.996 73.8799 243.934 73.8182C243.873 73.7566 243.789 73.7219 243.702 73.7219H243.398"
          stroke="#3F3D56"
          strokeMiterlimit="10"
        />
        <path
          d="M180.275 31H236.066C240.084 31 243.341 34.2588 243.341 38.2787V166.745C243.341 170.765 240.084 174.023 236.066 174.023H180.275C176.257 174.023 173 170.765 173 166.745V38.2787C173 34.2588 176.257 31 180.275 31Z"
          stroke="#3F3D56"
          strokeMiterlimit="10"
        />
        <path
          d="M206.059 35.8828H214.845C215.132 35.8828 215.364 36.1149 215.364 36.4012V37.36C215.364 37.6462 215.132 37.8783 214.845 37.8783H206.059C205.773 37.8783 205.541 37.6462 205.541 37.36V36.4012C205.541 36.1149 205.773 35.8828 206.059 35.8828Z"
          fill="#F2F2F2"
        />
        <path
          d="M202.924 38.0125C202.299 38.0125 201.793 37.5057 201.793 36.8806C201.793 36.2555 202.299 35.7488 202.924 35.7488C203.549 35.7488 204.056 36.2555 204.056 36.8806C204.056 37.5057 203.549 38.0125 202.924 38.0125Z"
          fill="#F2F2F2"
        />
        <path
          d="M205.651 35.2711H214.438C214.724 35.2711 214.956 35.5032 214.956 35.7895V36.7483C214.956 37.0345 214.724 37.2666 214.438 37.2666H205.651C205.365 37.2666 205.133 37.0345 205.133 36.7483V35.7895C205.133 35.5032 205.365 35.2711 205.651 35.2711Z"
          stroke="#3F3D56"
          strokeMiterlimit="10"
        />
        <path
          d="M202.517 37.4007C201.892 37.4007 201.385 36.8939 201.385 36.2688C201.385 35.6437 201.892 35.137 202.517 35.137C203.141 35.137 203.648 35.6437 203.648 36.2688C203.648 36.8939 203.141 37.4007 202.517 37.4007Z"
          stroke="#3F3D56"
          strokeMiterlimit="10"
        />
        <path
          d="M177.344 40.3145V164.709C177.344 166.191 177.933 167.613 178.981 168.66C180.028 169.708 181.449 170.297 182.931 170.297H233.41C234.892 170.297 236.313 169.708 237.36 168.66C238.408 167.613 238.997 166.191 238.997 164.709V40.3145C238.997 39.5805 238.853 38.8536 238.572 38.1755C238.291 37.4973 237.88 36.8811 237.361 36.3621C236.842 35.843 236.226 35.4313 235.548 35.1504C234.87 34.8696 234.144 34.725 233.41 34.7251H225.856V35.6958C225.856 36.9163 225.371 38.0867 224.509 38.9498C223.646 39.8128 222.476 40.2979 221.256 40.2982H195.584C194.364 40.2982 193.194 39.8133 192.331 38.9502C191.469 38.087 190.984 36.9164 190.984 35.6958V34.7251H182.931C182.197 34.725 181.47 34.8696 180.793 35.1504C180.115 35.4313 179.499 35.843 178.98 36.3621C178.461 36.8811 178.05 37.4973 177.769 38.1755C177.488 38.8536 177.344 39.5805 177.344 40.3145V40.3145Z"
          stroke="#3F3D56"
          strokeMiterlimit="10"
        />
        <path
          d="M230.915 46.6135H237.234V47.4292H230.915V46.6135Z"
          stroke="#3F3D56"
          strokeMiterlimit="10"
        />
        <path
          d="M230.915 48.0409H237.234V48.8566H230.915V48.0409Z"
          stroke="#3F3D56"
          strokeMiterlimit="10"
        />
        <path
          d="M230.915 49.4683H237.234V50.2839H230.915V49.4683Z"
          stroke="#3F3D56"
          strokeMiterlimit="10"
        />
        <path
          d="M190.975 67.4153H234.371C235.783 67.4153 236.928 68.5608 236.928 69.9739V91.774C236.928 93.1871 235.783 94.3326 234.371 94.3326H190.975C189.563 94.3326 188.418 93.1871 188.418 91.774V69.9739C188.418 68.5608 189.563 67.4153 190.975 67.4153Z"
          fill="#999999"
        />
        <path
          d="M188.325 62.3173H231.721C233.133 62.3173 234.278 63.4628 234.278 64.8759V86.6759C234.278 88.089 233.133 89.2346 231.721 89.2346H188.325C186.913 89.2346 185.768 88.089 185.768 86.6759V64.8759C185.768 63.4628 186.913 62.3173 188.325 62.3173Z"
          stroke="#3F3D56"
          strokeMiterlimit="10"
        />
        <path
          d="M217.673 81.7955L213.395 86.207C213.301 86.3011 213.19 86.3752 213.067 86.4247C212.944 86.4741 212.812 86.4979 212.679 86.4945H212.667C212.534 86.4979 212.402 86.4741 212.279 86.4247C212.156 86.3752 212.045 86.3011 211.951 86.207L207.673 81.7955C207.578 81.7072 207.503 81.6003 207.452 81.4816C207.4 81.3629 207.373 81.2349 207.373 81.1055C207.373 80.9762 207.4 80.8482 207.452 80.7295C207.503 80.6108 207.578 80.5039 207.673 80.4156C207.868 80.2324 208.126 80.1305 208.394 80.1305C208.662 80.1305 208.92 80.2324 209.116 80.4156L211.651 83.0669V76.2309C211.663 75.9677 211.776 75.7192 211.966 75.5371C212.156 75.355 212.41 75.2534 212.673 75.2534C212.936 75.2534 213.189 75.355 213.38 75.5371C213.57 75.7192 213.683 75.9677 213.695 76.2309V83.0669L216.23 80.4156C216.426 80.2324 216.683 80.1305 216.951 80.1305C217.219 80.1305 217.477 80.2324 217.673 80.4156C217.768 80.5039 217.843 80.6108 217.894 80.7295C217.946 80.8482 217.972 80.9762 217.972 81.1055C217.972 81.2349 217.946 81.3629 217.894 81.4816C217.843 81.6003 217.768 81.7072 217.673 81.7955H217.673Z"
          fill="white"
        />
        <path
          d="M229.071 140.418H185.676C184.263 140.418 183.118 139.273 183.118 137.86V116.06C183.118 114.647 184.263 113.501 185.676 113.501H229.071C230.484 113.501 231.629 114.647 231.629 116.06V137.86C231.629 139.273 230.484 140.418 229.071 140.418Z"
          fill="#999999"
        />
        <path
          d="M231.721 135.32H188.325C186.913 135.32 185.768 134.175 185.768 132.762V110.962C185.768 109.548 186.913 108.403 188.325 108.403H231.721C233.133 108.403 234.278 109.548 234.278 110.962V132.762C234.278 134.175 233.133 135.32 231.721 135.32Z"
          stroke="#3F3D56"
          strokeMiterlimit="10"
        />
        <path
          d="M202.373 127.881C202.279 127.793 202.204 127.686 202.152 127.567C202.101 127.449 202.074 127.321 202.074 127.191C202.074 127.062 202.101 126.934 202.152 126.815C202.204 126.696 202.279 126.59 202.373 126.501C202.569 126.318 202.827 126.216 203.095 126.216C203.363 126.216 203.621 126.318 203.817 126.501L206.352 129.152V122.316C206.364 122.053 206.476 121.805 206.667 121.623C206.857 121.441 207.11 121.339 207.374 121.339C207.637 121.339 207.89 121.441 208.08 121.623C208.271 121.805 208.383 122.053 208.395 122.316V129.152L210.93 126.501C211.126 126.318 211.384 126.216 211.652 126.216C211.92 126.216 212.178 126.318 212.374 126.501C212.468 126.59 212.543 126.696 212.595 126.815C212.646 126.934 212.673 127.062 212.673 127.191C212.673 127.321 212.646 127.449 212.595 127.567C212.543 127.686 212.468 127.793 212.374 127.881L208.095 132.293C208.002 132.387 207.89 132.461 207.767 132.51C207.644 132.56 207.512 132.583 207.38 132.58H207.367C207.235 132.583 207.103 132.56 206.98 132.51C206.857 132.461 206.745 132.387 206.652 132.293L202.373 127.881H202.373Z"
          fill="white"
        />
        <path
          d="M263.868 183.873L263.868 183.873C263.868 183.873 263.932 183.909 264.042 183.965C263.983 183.934 263.925 183.903 263.868 183.873Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M288.177 162.503C289.707 166.902 287.922 174.042 287.094 177.229C286.266 180.417 286.011 184.241 286.011 184.241C282.761 184.751 280.403 184.113 280.403 184.113L281.741 177.548C280.785 173.659 281.741 163.268 281.741 163.268C281.741 163.268 279.575 159.252 279.384 153.132C279.196 147.108 277.712 145.161 277.665 145.101L277.664 145.1C274.605 146.694 270.718 163.779 270.718 163.779C271.037 167.922 270.654 174.297 269.38 177.739C268.608 179.926 268.283 182.245 268.425 184.56C267.979 184.937 267.36 185.001 266.691 184.893C264.871 184.598 262.69 183.03 262.69 183.03C262.69 183.03 263.391 164.416 263.901 163.715C264.41 163.014 263.901 158.998 263.901 158.998L264.729 152.496C264.729 152.496 264.601 141.467 265.812 139.554C266.857 137.903 267.098 135.001 267.145 134.239C267.151 134.118 267.154 134.051 267.154 134.051L268.998 131.842C268.998 131.842 286.839 130.503 288.177 131.842C288.506 132.17 288.709 132.917 288.829 133.858C289.197 136.75 288.776 141.467 288.776 141.467C288.776 141.467 288.24 144.973 287.476 148.543C287.016 150.686 287.016 152.902 287.476 155.045C287.224 157.553 287.463 160.086 288.177 162.503Z"
          fill="#575A89"
        />
        <path
          d="M287.062 190.457C286.266 191.509 281.583 191.062 280.785 190.734C280.299 190.533 280.335 189.617 280.467 188.673C280.55 188.073 280.673 187.461 280.722 187.014C280.849 185.867 280.34 183.54 280.34 183.54C280.34 183.54 281.391 184.656 281.646 183.54C281.757 183.052 282.149 182.802 282.555 182.675C283.101 182.51 283.688 182.547 284.209 182.779C285.037 183.138 286.418 183.904 286.17 184.939C285.819 186.403 287.062 188.48 287.062 188.48C287.158 188.602 287.237 188.737 287.294 188.881C287.404 189.14 287.44 189.424 287.399 189.701C287.358 189.979 287.241 190.241 287.062 190.457Z"
          fill="#CBCEDA"
        />
        <path
          opacity="0.1"
          d="M287.062 190.457C286.266 191.508 281.583 191.062 280.785 190.734C280.299 190.533 280.335 189.617 280.467 188.673C280.97 188.909 281.825 189.104 283.251 188.972C283.584 188.941 283.918 188.931 284.252 188.942C285.267 188.987 286.283 188.967 287.294 188.881C287.404 189.14 287.44 189.423 287.399 189.701C287.358 189.979 287.241 190.241 287.062 190.457Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M270.718 163.779C271.037 167.922 270.654 174.297 269.38 177.739C268.608 179.926 268.283 182.245 268.425 184.56C267.979 184.937 267.36 185.001 266.691 184.893C267.075 182.204 268.713 170.619 269.763 161.468C270.957 151.045 276.549 143.873 276.549 143.873L277.664 145.1C274.605 146.694 270.718 163.779 270.718 163.779Z"
          fill="black"
        />
        <path
          d="M285.15 96.7805L284.372 100.605L280.563 107.012L275.863 105.004C275.863 105.004 269.476 98.3104 273.49 97.3542C273.971 97.2513 274.425 97.049 274.823 96.7604C275.611 96.1656 275.859 95.3012 275.863 94.455C275.832 93.5063 275.61 92.5736 275.21 91.7126L283.43 89.1309C283.091 90.2112 282.893 91.331 282.84 92.4623C282.777 94.7017 283.609 95.8051 284.291 96.3311C284.545 96.5362 284.837 96.689 285.15 96.7805Z"
          fill="#FBBEBE"
        />
        <path
          opacity="0.1"
          d="M283.43 89.1309C283.091 90.2112 282.893 91.331 282.84 92.4623C282.691 92.6405 282.531 92.8103 282.362 92.971C281.514 93.7819 280.462 94.3481 279.317 94.6093C278.173 94.8706 276.98 94.8173 275.863 94.455C275.832 93.5063 275.61 92.5736 275.21 91.7126L283.43 89.1309Z"
          fill="black"
        />
        <path
          d="M284.372 88.079C284.372 89.3649 283.991 90.622 283.277 91.6912C282.563 92.7604 281.548 93.5937 280.36 94.0858C279.173 94.5779 277.866 94.7066 276.606 94.4558C275.345 94.2049 274.187 93.5856 273.278 92.6763C272.369 91.767 271.75 90.6085 271.499 89.3473C271.249 88.0861 271.377 86.7788 271.869 85.5907C272.361 84.4026 273.194 83.3872 274.263 82.6727C275.331 81.9582 276.588 81.5769 277.873 81.5768C278.728 81.5736 279.574 81.7396 280.364 82.0653C281.154 82.3909 281.872 82.8697 282.476 83.4742C283.08 84.0786 283.559 84.7966 283.884 85.587C284.21 86.3773 284.376 87.2242 284.372 88.079Z"
          fill="#FBBEBE"
        />
        <path
          opacity="0.1"
          d="M285.15 96.7805L284.372 100.605L280.563 107.012L275.863 105.004C275.863 105.004 269.476 98.3104 273.49 97.3542C273.971 97.2513 274.425 97.049 274.823 96.7604C274.098 98.7531 277.09 102.9 277.09 102.9C277.438 103.413 278.237 103.091 278.237 103.091C280.164 102.8 283.713 97.2538 284.291 96.3311C284.545 96.5362 284.837 96.689 285.15 96.7805Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M288.829 133.858C287.698 134.115 281.438 135.503 279.065 135.156C276.453 134.773 271.374 134.519 271.374 134.519C271.374 134.519 268.14 134.694 267.145 134.239C267.151 134.118 267.154 134.051 267.154 134.051L268.998 131.842C268.998 131.842 286.839 130.503 288.177 131.842C288.506 132.17 288.709 132.917 288.829 133.858Z"
          fill="black"
        />
        <path
          d="M277.09 103.091C277.09 103.091 273.728 98.4319 274.995 96.6181L269.317 98.9479C269.317 98.9479 265.899 98.5334 264.665 103.636C264.468 104.485 264.396 105.357 264.454 106.226C264.499 106.996 264.394 108.351 263.518 110.104C262.117 112.908 262.435 119.347 265.302 119.984C268.17 120.622 269.189 120.303 269.189 120.303C269.189 120.303 268.807 131.969 267.15 133.307C265.494 134.646 271.374 134.327 271.374 134.327C271.374 134.327 276.453 134.582 279.065 134.965C281.678 135.347 289.005 133.626 289.005 133.626C289.005 133.626 287.922 128.718 287.54 125.594C287.158 122.47 286.266 115.819 286.266 115.819L292.765 107.012C292.765 107.012 293.912 101.689 289.897 99.3304C285.883 96.9718 284.373 96.3918 284.373 96.3918C284.373 96.3918 280.34 102.964 278.237 103.283C278.237 103.283 277.438 103.604 277.09 103.091Z"
          fill="#3F3D56"
        />
        <path
          opacity="0.1"
          d="M269.635 104.94C269.635 104.94 269.571 107.044 269.954 107.872C270.336 108.701 271.738 113.291 271.738 113.291H268.552C268.552 113.291 270.272 111.442 270.145 110.55C270.017 109.657 269.635 104.94 269.635 104.94Z"
          fill="black"
        />
        <path
          d="M279.065 120.813C279.065 120.813 272.03 119.092 271.374 117.626C270.718 116.16 268.911 112.878 268.911 112.878L269.654 111.878L273.076 114.566C273.076 114.566 277.154 117.817 279.065 118.136C280.977 118.454 279.065 120.813 279.065 120.813Z"
          fill="#FBBEBE"
        />
        <path
          opacity="0.05"
          d="M279.065 120.813C279.065 120.813 272.03 119.092 271.374 117.626C270.718 116.16 268.911 112.878 268.911 112.878L269.654 111.878L273.076 114.566C273.076 114.566 277.154 117.817 279.065 118.136C280.977 118.454 279.065 120.813 279.065 120.813Z"
          fill="black"
        />
        <path
          d="M285.15 112.526V116.345H284.537C281.505 116.345 278.524 117.117 275.873 118.589L274.723 119.227L274.413 119.399L272.439 114.948L274.286 113.291C274.286 113.291 274.32 113.295 274.381 113.3C274.833 113.344 276.788 113.507 277.616 113.227C278.403 112.96 279.38 112.112 281.698 112.705C282.839 112.997 283.814 114.277 284.922 113.877L285.15 112.526Z"
          fill="#FBBEBE"
        />
        <path
          opacity="0.1"
          d="M291.299 105.577L292.574 107.012V110.486C292.574 110.486 291.49 121.45 288.05 122.662C284.609 123.873 278.467 121.96 278.467 121.96C278.467 121.96 276.88 117.753 277.622 117.498C278.365 117.243 284.181 115.706 284.181 115.706C284.181 115.706 284.099 109.275 285.373 107.362C285.373 107.362 284.545 101.37 287.412 101.243C290.28 101.115 291.299 105.577 291.299 105.577Z"
          fill="black"
        />
        <path
          d="M291.49 105.577L292.765 107.012V110.486C292.765 110.486 291.682 121.45 288.241 122.662C284.8 123.873 278.658 121.96 278.658 121.96C278.658 121.96 277.071 117.753 277.814 117.498C278.556 117.243 284.373 115.706 284.373 115.706C284.373 115.706 284.29 109.275 285.565 107.362C285.565 107.362 284.736 101.37 287.604 101.243C290.471 101.115 291.49 105.577 291.49 105.577Z"
          fill="#3F3D56"
        />
        <path
          opacity="0.1"
          d="M282.59 90.8425C282.645 90.4231 282.595 89.9965 282.445 89.6013C282.265 89.2207 281.844 88.9205 281.434 89.0171C280.889 89.1443 280.654 89.8547 280.119 90.0249C279.669 90.1683 279.177 89.849 278.962 89.4273C278.747 89.0066 278.741 88.5132 278.74 88.0408C278.739 87.5082 278.738 86.9756 278.736 86.443C278.735 85.8635 278.718 85.2372 278.361 84.7821C277.916 84.215 276.873 83.9674 276.176 83.9903C275.712 83.9953 275.266 84.1749 274.928 84.4933C274.555 84.8107 274.124 85.0441 273.615 84.9609C273.404 84.9287 273.205 84.8373 273.044 84.6972C272.882 84.5571 272.763 84.374 272.701 84.1691C272.699 84.1626 272.697 84.1559 272.696 84.1491C273.238 83.434 273.922 82.839 274.705 82.4015C275.489 81.9639 276.354 81.6933 277.247 81.6068C278.139 81.5202 279.041 81.6194 279.893 81.8983C280.746 82.1772 281.532 82.6297 282.201 83.2272C282.87 83.8248 283.408 84.5545 283.782 85.3704C284.156 86.1863 284.356 87.0708 284.372 87.9681C284.387 88.8654 284.216 89.7561 283.87 90.5842C283.525 91.4123 283.011 92.1599 282.362 92.7797C282.474 92.1387 282.55 91.4919 282.59 90.8425Z"
          fill="black"
        />
        <path
          d="M273.615 84.7697C273.404 84.7374 273.205 84.6459 273.043 84.5058C272.882 84.3656 272.763 84.1826 272.7 83.9777C272.647 83.7719 272.661 83.5524 272.6 83.3485C272.451 82.8477 271.917 82.5865 271.559 82.2063C270.841 81.4443 270.887 80.1906 271.396 79.275C271.806 78.5362 272.602 77.9123 273.434 78.0567C273.949 78.1461 274.395 78.5136 274.917 78.5467C275.42 78.5786 275.88 78.297 276.338 78.0878C277.443 77.5877 278.684 77.4775 279.86 77.7751C280.221 77.8471 280.556 78.0161 280.829 78.2641C281.014 78.4969 281.187 78.7396 281.346 78.9911C281.94 79.7484 283.003 79.8963 283.95 80.0682C284.897 80.2401 285.969 80.6139 286.28 81.5251C286.487 82.1292 286.287 82.7854 286.168 83.4127C286.129 83.5413 286.138 83.6798 286.193 83.8024C286.341 84.0468 286.711 83.9445 286.99 84.0032C287.528 84.116 287.666 84.8085 287.685 85.3574C287.708 85.9856 287.725 86.6139 287.737 87.2425C287.752 87.4771 287.733 87.7128 287.681 87.942C287.564 88.2694 287.379 88.5683 287.139 88.8194L285.035 91.3482C284.102 92.4694 283.111 93.7762 283.299 95.2229C282.857 95.4214 282.332 95.0395 282.187 94.5768C282.042 94.1142 282.159 93.6157 282.258 93.1409C282.427 92.32 282.538 91.4881 282.59 90.6514C282.646 90.232 282.596 89.8054 282.444 89.4104C282.265 89.0293 281.844 88.7296 281.434 88.8255C280.888 88.9532 280.654 89.6633 280.119 89.8336C279.669 89.9772 279.176 89.6573 278.962 89.2362C278.747 88.8151 278.741 88.3222 278.74 87.8494L278.736 86.2516C278.735 85.6726 278.718 85.0459 278.361 84.5903C277.917 84.0235 276.872 83.7759 276.175 83.7986C275.711 83.804 275.266 83.9836 274.929 84.3018C274.555 84.6193 274.124 84.8526 273.615 84.7697Z"
          fill="#3F3D56"
        />
        <path
          opacity="0.1"
          d="M275.083 104.781L277.078 112.981C277.078 112.981 278.651 112.972 275.083 104.781Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M281.901 103.41C281.901 103.41 279.288 107.691 280.308 112.116L280.795 112.152C280.795 112.152 279.926 110.104 281.901 103.41Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M283.557 127.634C283.557 127.634 286.765 126.572 286.998 129.036C286.998 129.036 286.393 127.347 283.557 127.634Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M274.287 113.482C274.287 113.482 273.649 117.944 274.796 119.729C274.796 119.729 272.463 120.686 269.189 120.494L268.807 113.482L274.287 113.482Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M274.723 119.227L274.413 119.399L272.439 114.948L274.286 113.291H274.382C274.382 113.294 274.381 113.297 274.381 113.3C274.352 113.505 273.837 117.338 274.723 119.227Z"
          fill="black"
        />
        <path
          d="M274.287 113.291C274.287 113.291 273.649 117.753 274.796 119.538C274.796 119.538 272.463 120.494 269.189 120.303L266.959 113.291H274.287Z"
          fill="#3F3D56"
        />
        <path
          d="M269.183 189.374C269.146 189.692 269.004 189.988 268.78 190.217C268.556 190.445 268.263 190.593 267.946 190.636C266.215 190.895 262.047 190.127 260.995 190.628C259.657 191.266 254.369 190.819 254.113 190.183C254.077 190.055 254.067 189.922 254.083 189.79C254.098 189.658 254.14 189.531 254.205 189.416C254.386 189.027 254.685 188.705 255.06 188.497C255.181 188.427 255.31 188.37 255.443 188.327C256.439 187.994 258.321 187.288 258.828 186.549C258.828 186.549 261.173 184.496 261.842 183.386C261.968 183.168 262.146 182.984 262.359 182.851C262.573 182.718 262.816 182.639 263.067 182.623C263.626 182.598 264.247 182.879 264.502 184.102C264.502 184.102 267.558 185.847 268.386 184.317C268.386 184.317 268.513 188.095 269.02 188.738C269.021 188.739 269.022 188.74 269.023 188.741C269.09 188.831 269.139 188.933 269.166 189.042C269.194 189.15 269.199 189.263 269.183 189.374Z"
          fill="#CBCEDA"
        />
        <path
          opacity="0.1"
          d="M269.183 189.374C269.146 189.691 269.004 189.988 268.78 190.217C268.556 190.445 268.263 190.593 267.946 190.636C266.215 190.895 262.047 190.127 260.995 190.628C259.657 191.266 254.369 190.819 254.113 190.182C254.077 190.055 254.067 189.921 254.083 189.79C254.098 189.658 254.14 189.531 254.205 189.416C254.386 189.027 254.685 188.705 255.06 188.497C255.922 189.069 257.836 189.629 262.046 188.971C266.062 188.344 268.05 189.156 269.02 188.737C269.021 188.738 269.022 188.74 269.023 188.741C269.09 188.831 269.139 188.933 269.166 189.041C269.194 189.15 269.199 189.263 269.183 189.374Z"
          fill="black"
        />
        <g opacity="0.1">
          <path
            opacity="0.1"
            d="M286.162 83.5023C286.164 83.4921 286.166 83.4818 286.168 83.4718C286.258 82.994 286.395 82.4996 286.364 82.0242C286.323 82.4148 286.258 82.8025 286.168 83.1849C286.141 83.2888 286.139 83.3975 286.162 83.5023Z"
            fill="black"
          />
          <path
            opacity="0.1"
            d="M282.444 89.4693C282.545 89.6991 282.598 89.9469 282.6 90.1978C282.629 89.8517 282.576 89.5038 282.444 89.1825C282.265 88.8014 281.844 88.5017 281.434 88.5976C280.888 88.7253 280.653 89.4354 280.119 89.6057C279.669 89.7493 279.176 89.4294 278.961 89.0083C278.747 88.5872 278.741 88.0943 278.74 87.6215C278.739 87.0889 278.737 86.5563 278.736 86.0237C278.735 85.4447 278.718 84.818 278.36 84.3624C277.916 83.7956 276.872 83.548 276.175 83.5707C275.711 83.5761 275.266 83.7557 274.928 84.0739C274.554 84.3915 274.124 84.6247 273.615 84.5418C273.403 84.5095 273.205 84.418 273.043 84.2779C272.881 84.1378 272.762 83.9547 272.7 83.7498C272.646 83.544 272.66 83.3245 272.6 83.1206C272.451 82.6198 271.917 82.3587 271.558 81.9785C271.224 81.6081 271.033 81.1301 271.021 80.6311C270.995 80.9258 271.029 81.2227 271.121 81.5037C271.213 81.7847 271.362 82.0439 271.558 82.2653C271.917 82.6455 272.451 82.9067 272.6 83.4075C272.66 83.6113 272.646 83.8309 272.7 84.0367C272.762 84.2416 272.881 84.4246 273.043 84.5648C273.205 84.7049 273.403 84.7964 273.615 84.8286C274.124 84.9116 274.554 84.6783 274.928 84.3608C275.266 84.0426 275.711 83.863 276.175 83.8576C276.872 83.8349 277.916 84.0825 278.36 84.6493C278.718 85.1048 278.735 85.7316 278.736 86.3105C278.737 86.8432 278.739 87.3758 278.74 87.9084C278.741 88.3811 278.747 88.874 278.961 89.2952C279.176 89.7163 279.669 90.0362 280.119 89.8926C280.653 89.7223 280.888 89.0122 281.434 88.8845C281.844 88.7886 282.265 89.0882 282.444 89.4693Z"
            fill="black"
          />
          <path
            opacity="0.1"
            d="M287.681 87.7142C287.564 88.0416 287.379 88.3405 287.139 88.5916C286.437 89.4345 285.736 90.2774 285.035 91.1204C284.149 92.1849 283.212 93.4169 283.28 94.7769C283.344 93.5302 284.212 92.3964 285.035 91.4072C285.736 90.5643 286.437 89.7214 287.139 88.8785C287.379 88.6274 287.564 88.3284 287.681 88.0011C287.733 87.7719 287.751 87.5363 287.736 87.3018C287.734 87.441 287.716 87.5795 287.681 87.7142Z"
            fill="black"
          />
          <path
            opacity="0.1"
            d="M282.187 94.349C282.153 94.2382 282.132 94.1237 282.124 94.0079C282.106 94.2193 282.127 94.4323 282.187 94.6359C282.332 95.0986 282.857 95.4805 283.299 95.282C283.287 95.1894 283.28 95.0963 283.277 95.003C282.84 95.1799 282.329 94.8042 282.187 94.349Z"
            fill="black"
          />
        </g>
        <path
          opacity="0.1"
          d="M261.422 183.963C261.422 183.963 262.088 185.135 264.502 184.102L261.422 183.963Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M281.418 184.041C281.418 184.041 283.5 186.493 286.122 184.845L281.418 184.041Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M287.476 155.045C287.476 155.045 286.005 155.964 285.957 156.49C285.91 157.016 286.717 156.107 286.717 156.107L287.476 155.045Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M288.056 162.093C287.545 162.519 286.909 162.768 286.244 162.801C285.145 162.849 285.236 164.283 285.955 163.853C286.674 163.422 288.056 162.093 288.056 162.093Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M288.336 163.013C288.336 163.013 287.63 166.482 287.057 165.813L288.336 163.013Z"
          fill="black"
        />
        <path
          d="M305.285 159.516C304.668 159.74 304.007 159.818 303.354 159.744C302.701 159.669 302.075 159.444 301.524 159.085C300.973 158.727 300.513 158.246 300.18 157.679C299.846 157.113 299.649 156.477 299.604 155.821C299.558 155.165 299.666 154.508 299.917 153.901C300.169 153.294 300.558 152.753 301.054 152.322C301.55 151.892 302.14 151.582 302.776 151.418C303.412 151.253 304.078 151.239 304.72 151.376L304.614 153.561L305.647 151.689C306.661 152.165 307.444 153.025 307.825 154.079C307.96 154.448 308.042 154.835 308.069 155.227C308.134 156.147 307.897 157.064 307.395 157.838C306.893 158.612 306.152 159.201 305.285 159.516Z"
          fill="#57B894"
        />
        <path
          d="M300.174 184.851C300.538 182.994 298.958 181.347 297.397 180.276C295.837 179.206 294.013 178.154 293.48 176.337C292.715 173.726 294.995 171.335 296.769 169.273C298.086 167.743 299.216 166.062 300.137 164.265C300.506 163.545 300.846 162.792 300.943 161.988C301.083 160.831 300.711 159.679 300.337 158.575C299.089 154.898 297.756 151.251 296.336 147.635"
          stroke="#3F3D56"
          strokeMiterlimit="10"
        />
        <path
          d="M305.11 158.953C304.493 159.178 303.832 159.256 303.179 159.181C302.526 159.107 301.9 158.881 301.349 158.523C300.798 158.165 300.338 157.683 300.005 157.117C299.672 156.55 299.475 155.914 299.429 155.259C299.384 154.603 299.491 153.946 299.743 153.338C299.994 152.731 300.384 152.191 300.88 151.76C301.376 151.329 301.965 151.019 302.601 150.855C303.237 150.691 303.903 150.677 304.546 150.814L304.439 152.998L305.472 151.126C306.486 151.603 307.269 152.463 307.65 153.517C307.785 153.886 307.867 154.272 307.894 154.665C307.959 155.585 307.723 156.502 307.22 157.276C306.718 158.049 305.977 158.638 305.11 158.953V158.953Z"
          stroke="#3F3D56"
          strokeMiterlimit="10"
        />
        <path
          d="M300.692 146.881C300.45 146.212 300.043 145.615 299.51 145.144C298.976 144.674 298.333 144.346 297.639 144.189L297.29 146.266L296.642 144.085C295.743 144.098 294.871 144.397 294.153 144.938C293.435 145.48 292.907 146.235 292.646 147.096C292.385 147.957 292.404 148.879 292.701 149.728C292.997 150.577 293.556 151.31 294.296 151.821C295.036 152.332 295.92 152.594 296.818 152.57C297.717 152.546 298.585 152.237 299.297 151.687C300.009 151.137 300.528 150.375 300.778 149.511C301.029 148.648 300.999 147.726 300.692 146.881H300.692Z"
          fill="#57B894"
        />
        <path
          d="M300.459 146.131C300.217 145.462 299.81 144.865 299.277 144.394C298.743 143.924 298.1 143.596 297.406 143.439L297.057 145.516L296.409 143.335C295.51 143.348 294.638 143.647 293.92 144.189C293.202 144.73 292.674 145.485 292.413 146.346C292.152 147.207 292.171 148.129 292.468 148.978C292.764 149.827 293.323 150.56 294.063 151.071C294.803 151.582 295.687 151.844 296.586 151.82C297.484 151.796 298.352 151.487 299.064 150.937C299.776 150.387 300.295 149.626 300.545 148.762C300.796 147.898 300.766 146.976 300.459 146.131H300.459Z"
          stroke="#3F3D56"
          strokeMiterlimit="10"
        />
        <path
          d="M297.514 169.315C296.535 169.67 295.459 169.653 294.492 169.266C293.525 168.878 292.734 168.149 292.27 167.216C291.806 166.283 291.701 165.212 291.976 164.206C292.251 163.201 292.886 162.332 293.76 161.765L294.257 163.441L294.61 161.34C294.614 161.339 294.617 161.337 294.621 161.336C295.145 161.146 295.701 161.06 296.257 161.085C296.814 161.11 297.361 161.244 297.865 161.48C298.37 161.716 298.824 162.049 299.2 162.46C299.576 162.872 299.867 163.353 300.057 163.877C300.247 164.402 300.332 164.958 300.307 165.515C300.282 166.072 300.148 166.618 299.912 167.123C299.676 167.628 299.342 168.082 298.931 168.458C298.519 168.834 298.038 169.125 297.514 169.315H297.514Z"
          fill="#57B894"
        />
        <path
          d="M297.312 168.555C296.333 168.911 295.257 168.893 294.29 168.506C293.323 168.119 292.532 167.389 292.068 166.456C291.604 165.523 291.5 164.452 291.774 163.447C292.049 162.442 292.684 161.573 293.558 161.006L294.055 162.682L294.408 160.581C294.412 160.579 294.415 160.578 294.419 160.577C294.943 160.386 295.499 160.301 296.056 160.325C296.612 160.35 297.159 160.484 297.664 160.72C298.168 160.956 298.622 161.29 298.998 161.701C299.374 162.112 299.665 162.594 299.855 163.118C300.046 163.642 300.13 164.198 300.105 164.755C300.08 165.312 299.946 165.859 299.71 166.364C299.474 166.869 299.14 167.322 298.729 167.698C298.317 168.074 297.836 168.366 297.312 168.555H297.312Z"
          stroke="#3F3D56"
          strokeMiterlimit="10"
        />
        <path
          d="M301.93 176.363C301.762 177.321 301.269 178.193 300.535 178.831C299.8 179.469 298.869 179.835 297.897 179.868C296.925 179.901 295.971 179.598 295.195 179.011C294.42 178.424 293.87 177.587 293.637 176.642C293.405 175.697 293.504 174.701 293.919 173.821C294.333 172.941 295.038 172.23 295.914 171.807C296.791 171.385 297.785 171.277 298.732 171.501C299.678 171.726 300.519 172.269 301.113 173.04L299.223 175.886L301.805 174.375C302.005 175.018 302.048 175.7 301.93 176.363Z"
          fill="#57B894"
        />
        <path
          d="M301.755 175.801C301.587 176.759 301.094 177.63 300.36 178.269C299.626 178.907 298.694 179.273 297.722 179.306C296.75 179.339 295.796 179.036 295.021 178.449C294.245 177.861 293.695 177.025 293.463 176.08C293.23 175.135 293.33 174.139 293.744 173.259C294.159 172.378 294.863 171.667 295.74 171.245C296.616 170.823 297.611 170.715 298.557 170.939C299.504 171.163 300.344 171.706 300.938 172.477L299.048 175.323L301.63 173.813C301.831 174.456 301.874 175.137 301.755 175.801V175.801Z"
          stroke="#3F3D56"
          strokeMiterlimit="10"
        />
        <path
          d="M306.063 187.368C306.063 187.368 302.32 185.9 301.192 185.096C300.064 184.293 295.434 183.333 295.154 184.622C294.874 185.911 289.528 191.033 293.754 191.067C297.981 191.101 303.574 191.76 304.7 191.074C305.826 190.388 306.063 187.368 306.063 187.368Z"
          fill="#656380"
        />
        <path
          opacity="0.2"
          d="M293.679 190.618C297.905 190.652 303.499 191.312 304.625 190.626C305.482 190.103 305.824 188.228 305.938 187.363C306.018 187.366 306.063 187.368 306.063 187.368C306.063 187.368 305.826 190.388 304.7 191.074C303.574 191.76 297.981 191.101 293.754 191.067C292.534 191.057 292.113 190.623 292.136 189.98C292.306 190.368 292.771 190.611 293.679 190.618Z"
          fill="black"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="263.955"
            y1="183.965"
            x2="263.955"
            y2="183.873"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#808080" stopOpacity="0.25" />
            <stop offset="0.53514" stopColor="#808080" stopOpacity="0.12" />
            <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default MobileDrawing;
