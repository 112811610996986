import React, { useEffect, useState } from "react";
import useStyles from "../components/ConfigStyles";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchApps,
  getPixelValue,
  postPixelValue,
} from "../../../redux/apps/appActions";
import PageLoader from "../../../components/Layout/PageLoader";
import Toast from "../../../components/Layout/Toast";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import Controls from "../../../components/controls/Controls";
import { Paper, Typography } from "@material-ui/core";
import HeaderBreadcrumbs from "../../../components/common/HeaderBreadcrumbs";
import { centerContainer, layoutCenterContainer } from "../../../styles/common";
const list = [
  {
    label: "App Store",
    link: "/apps",
  },
  {
    label: "Google Analytics",
    link: "/apps/google-analytics",
  },
  {
    label: "Configure",
  },
];

const GoogleAnalyticsConfig = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const storeData = useSelector((state) => state.store);
  const appState = useSelector((state) => state.apps);
  const apps = appState.apps;

  const initialState = {
    googleAnalytics: {
      value: "",
      touched: false,
      error: "",
    },
  };

  const [formState, setFormState] = useState(initialState);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (apps.length === 0) {
      dispatch(fetchApps());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apps.length]);

  useEffect(() => {
    if (storeData.fetched) {
      dispatch(getPixelValue("ga"));
    }
    //eslint-disable-next-line
  }, [storeData]);

  useEffect(() => {
    setFormState((prevState) => ({
      ...prevState,
      googleAnalytics: {
        ...prevState.googleAnalytics,
        value: appState.pixels.ga,
      },
    }));
    setLoading(false);
    //eslint-disable-next-line
  }, [appState.pixels]);

  const getMessage = (name) => {
    const messages = {
      googleAnalytics: "Please enter a valid GA property ID.",
    };
    return messages[name] || "";
  };

  const handleChnage = (event) => {
    let { name, value } = event.target;
    let cloneData = formState[name];
    cloneData.touched = true;
    cloneData.value = value;
    setFormState({ ...formState, [name]: cloneData });
  };

  const onBlur = (event) => {
    const { name } = event.target;
    const cloneData = formState[name];
    if (!cloneData.value) cloneData.error = getMessage(name);
    setFormState((prevState) => ({ ...prevState, [name]: cloneData }));
  };

  const onFocus = (event) => {
    const { name } = event.target;
    let cloneData = formState[name];
    cloneData.touched = true;
    cloneData.error = "";
    setFormState((prevState) => ({ ...prevState, [name]: cloneData }));
  };

  const validatation = () => {
    let errorFlag = false;
    Object.keys(formState).forEach((key) => {
      let cloneData = formState[key];
      if (!cloneData.value) {
        errorFlag = true;
        cloneData.error = getMessage(key);
      }
      setFormState({ ...formState, [key]: cloneData });
    });
    return errorFlag;
  };

  const navigateToUrl = () => {
    history.goBack();
  };

  const handleSave = async () => {
    let isError = validatation();
    if (isError) return;

    dispatch(
      postPixelValue([
        {
          name: "ga",
          value: formState.googleAnalytics.value,
        },
      ])
    ).then((response) => {
      if (response.error)
        setError(
          "Failed to save the Google Analytics Property ID. Please try again."
        );
      else
        setSuccess("Google Analytics Property ID has been successfully saved.");
    });
  };

  if (loading && !storeData.fetched) return <PageLoader />;

  return (
    <div className={layoutCenterContainer}>
      <div className={centerContainer}>
        <div className={classes.breadcrumbsContainer}>
          <div className={classes.backDiv}>
            <HeaderBreadcrumbs list={list} />
          </div>
        </div>
        <Paper elevation={0} className={classes.paper}>
          <div>
            <Typography className={classes.formHead}>
              Google Analytics Configuration Settings
            </Typography>
            <Controls.Input
              name="googleAnalytics"
              label="GA Property Id"
              labelPlacement="top"
              value={formState.googleAnalytics.value}
              onChange={handleChnage}
              onFocus={onFocus}
              onBlur={onBlur}
              error={formState.googleAnalytics.error}
              helperText={
                !formState.googleAnalytics.error &&
                "Add GA property ID Eg., AW-XXXXXXX-X"
              }
              extraSpace={!!formState.googleAnalytics.error}
            />
          </div>

          <div className={classes.button}>
            <CombinedButtons
              outlinedBtnText="Back"
              outlinedBtnAction={navigateToUrl}
              solidBtnAction={handleSave}
            />
          </div>
        </Paper>
      </div>

      <Toast
        autoClose
        message={success}
        open={!!success}
        close={() => setSuccess(false)}
      />
      <Toast
        autoClose
        message={error}
        open={!!error}
        close={() => setError(false)}
        severity="error"
      />
    </div>
  );
};

export default GoogleAnalyticsConfig;
