import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import StoreSwitcher from "../Sidebar/components/StoreSwitcher";
import useStyles from "./styles";
import { rowFlexAlignCenter } from "../../styles/common";
import ProfileIcon from "../../icons/ProfileIcon";
import OutlinkIcon from "../../icons/OutlinkIcon";
import { Button, ClickAwayListener } from "@material-ui/core";
import { CircleOutIcon } from "../../icons";
import SubscriptionIcon from "../../icons/SubscriptionIcon";
import { CLEAR_COMPLETE } from "../../redux/commom/commonTypes";
import { customerLogout } from "../../redux/customer/customerActions";
import { PLANS } from "../../pages/plans/constants";
import {
  getDefaultStore,
  getStoreIdFromUrl,
  getStoreUrl,
} from "../../utils/storeUtil";
import RoadmapIcon from "../../icons/RoadmapIcon";
import { getPlanRenewalInfo, fetchPlans } from "../../redux/store/storeActions";
import { isCustomDomain } from "../../utils/utils";
import { getDay } from "../../utils/dateUtils";

const SHOOPY_FEEDBACK_URL = "https://feedback.shoopy.in";

const Header = () => {
  const classes = useStyles();
  const header = useSelector((state) => state.header);
  const store = useSelector((state) => state.store.store);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [openProfileMenu, setOpenProfileMenu] = React.useState(false);
  const [renewObj, setRenewObj] = useState({});
  const [showUpgradeBtn, setShowUpgradeBtn] = useState(true);
  const localStore = getDefaultStore();
  const storeId = getStoreIdFromUrl();
  const customDomain = isCustomDomain();

  useEffect(() => {
    if (store && store.id && PLANS[store.plan]?.weight > 0) {
      dispatch(getPlanRenewalInfo(store.id)).then((renewInfo) => {
        setRenewObj(renewInfo);
      });
    }
  }, [store]);

  useEffect(() => {
    dispatch(fetchPlans());
  }, []);

  const redirectPlans = () => {
    setOpenProfileMenu(false);
    history.push(
      customDomain
        ? `/admin/${storeId}/billing/plans`
        : `/${storeId}/billing/plans`
    );
  };

  const redirectRenewPlan = () => {
    setOpenProfileMenu(false);
    history.push(
      customDomain
        ? `/admin/${storeId}/billing/plans/info`
        : `/${storeId}/billing/plans/info`
    );
  };

  const initList = [
    {
      icon: <SubscriptionIcon stroke="#000000" />,
      label: "My Billing Info",
      hideCustomDomain: true,
      onClick: redirectRenewPlan,
    },
    {
      icon: <RoadmapIcon stroke="#000000" />,
      label: "Shoopy Roadmap",
      hideCustomDomain: true,
      onClick: () => {
        setOpenProfileMenu(false);
        window.open(SHOOPY_FEEDBACK_URL, "_blank");
      },
    },
    {
      icon: <CircleOutIcon stroke="#FF1D1D" />,
      label: "Sign Out",
      color: "#FF1D1D",
      onClick: () => {
        setOpenProfileMenu(false);
        dispatch(customerLogout());
        dispatch({
          type: CLEAR_COMPLETE,
          payload: {},
        });
        history.push(customDomain ? "/admin/login" : "/login");
      },
    },
  ];

  const [list, setList] = useState(initList);
  useEffect(() => {
    let storeUser = getDefaultStore();
    if (storeUser && storeUser.role && storeUser.role !== "SUPER_ADMIN") {
      let modList = initList.filter((li) => li.label !== "Billing Plans");
      setList(modList);
      setShowUpgradeBtn(false);
    }
  }, [storeId]);

  const storeUrl = getStoreUrl(store);

  // Determine if we are on the billing page
  const isBillingPage = location.pathname.includes("/billing/plans");

  return (
    <div className={classes.root}>
      <div className={classes.title}>{header.title}</div>
      <div className={rowFlexAlignCenter}>
        {!isBillingPage && showUpgradeBtn ? (
          <PlanComponent
            store={store}
            redirectPlans={redirectPlans}
            renewPlan={redirectRenewPlan}
            currentPlan={store?.plan}
            renewObj={renewObj}
          />
        ) : (
          <></>
        )}
        <StoreSwitcher />
        <Divider />
        <a
          href={storeUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          <div className={rowFlexAlignCenter} style={{ height: 24 }}>
            <div className={classes.visit}>Visit Store</div> <OutlinkIcon />
          </div>
        </a>
        <Divider />
        <ClickAwayListener
          onClickAway={() => {
            setOpenProfileMenu(false);
          }}
        >
          <div style={{ position: "relative" }}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setOpenProfileMenu(!openProfileMenu)}
            >
              <ProfileIcon />
            </div>
            <div
              className={classes.arrow}
              style={{
                visibility: openProfileMenu ? "visible" : "hidden",
              }}
            />
            <div
              className={classes.sheet}
              style={{
                visibility: openProfileMenu ? "visible" : "hidden",
              }}
            >
              <div className={classes.profileCard}>
                <ProfileIcon size={34} />
                <div style={{ marginLeft: 12 }}>
                  <div>{localStore?.name}</div>
                  <div className={classes.phone}>{localStore?.mobile}</div>
                </div>
              </div>
              {list
                .filter(
                  (obj) =>
                    !customDomain || (customDomain && !obj.hideCustomDomain)
                )
                .map((obj, i) => (
                  <div key={i} className={classes.item} onClick={obj.onClick}>
                    <div className={classes.icon}>{obj.icon}</div>
                    <div className={classes.label} style={{ color: obj.color }}>
                      {obj.label}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </ClickAwayListener>
      </div>
    </div>
  );
};

const Divider = () => {
  const classes = useStyles();
  return <div className={classes.divider}></div>;
};

const PlanComponent = ({
  store,
  redirectPlans,
  renewPlan,
  currentPlan,
  renewObj,
}) => {
  const classes = useStyles();
  const calculateRemainingDays = (renewDate) => {
    if (!renewDate) return null;
    const renewTime = getDay(new Date(renewDate)).getTime();
    const nowTime = getDay(new Date()).getTime();
    return Math.floor((renewTime - nowTime) / (1000 * 3600 * 24));
  };
  const dueIn = renewObj ? calculateRemainingDays(renewObj.renew_date) : null;

  const getCTA = () => {
    if (
      store &&
      currentPlan &&
      PLANS[currentPlan]?.weight === 0 &&
      store.partner_slug !== "ninjacart"
    ) {
      return (
        <div className={rowFlexAlignCenter}>
          <Button
            onClick={redirectPlans}
            disableRipple
            className={classes.upgrade}
          >
            Select Plan
          </Button>
        </div>
      );
    }
    if (dueIn < 0) {
      const daysAgo = Math.abs(dueIn);
      return (
        <Button
          onClick={renewPlan}
          disableRipple
          className={classes.expired}
          style={{ marginRight: "10px" }}
        >
          Plan Expired {daysAgo}
          <span style={{ marginLeft: "2px" }}>
            {daysAgo === 1 ? "day ago" : "days ago"}
          </span>
        </Button>
      );
    }

    if (dueIn <= 30) {
      let message;
      let className;
      if (dueIn === 0) {
        message = "Plan Expires Today";
        className = classes.dueInWarn;
      } else if (dueIn > 0) {
        message = `Plan expires In - ${dueIn} ${dueIn === 1 ? "day" : "days"}`;
        className = classes.dueInWarn;
      } else {
        return null;
      }
      return (
        <Button onClick={renewPlan} disableRipple className={className}>
          {message}
        </Button>
      );
    }
    return null;
  };
  const cta = getCTA();
  if (!cta) return <></>;
  return <>{cta}</>;
};

export default Header;
