import { SvgIcon } from "@material-ui/core";
import React from "react";

const SubcriptionIcon = ({
  viewBox = "0 0 16 17",
  stroke = "#999999",
  color = "#ffffff",
}) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "16px", height: "17px" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="14"
        viewBox="0 0 15 14"
        fill="none"
      >
        <path
          d="M2.41042 13.95C1.95084 13.95 1.55279 13.7817 1.21624 13.4452C0.879698 13.1086 0.711426 12.7106 0.711426 12.251V1.69899C0.711426 1.23942 0.879698 0.841359 1.21624 0.504816C1.55279 0.168272 1.95084 0 2.41042 0H12.9624C13.422 0 13.8201 0.168272 14.1566 0.504816C14.4932 0.841359 14.6614 1.23942 14.6614 1.69899V12.251C14.6614 12.7106 14.4932 13.1086 14.1566 13.4452C13.8201 13.7817 13.422 13.95 12.9624 13.95H2.41042ZM2.41042 12.9662H12.9624C13.1711 12.9662 13.3425 12.8992 13.4765 12.7651C13.6106 12.631 13.6777 12.4597 13.6777 12.251V1.69899C13.6777 1.49033 13.6106 1.31895 13.4765 1.18488C13.3425 1.0508 13.1711 0.983765 12.9624 0.983765H2.41042C2.20175 0.983765 2.03038 1.0508 1.8963 1.18488C1.76223 1.31895 1.69519 1.49033 1.69519 1.69899V12.251C1.69519 12.4597 1.76223 12.631 1.8963 12.7651C2.03038 12.8992 2.20175 12.9662 2.41042 12.9662ZM10.5924 10.9542C10.8911 10.9542 11.1448 10.8502 11.3532 10.6421C11.5615 10.434 11.6657 10.1805 11.6657 9.88154C11.6657 9.58278 11.5616 9.32916 11.3535 9.12068C11.1454 8.9124 10.8927 8.80826 10.5953 8.80826C10.2979 8.80826 10.0443 8.91231 9.83444 9.12039C9.62461 9.32848 9.51969 9.58123 9.51969 9.87863C9.51969 10.176 9.62373 10.4297 9.83182 10.6395C10.0399 10.8493 10.2934 10.9542 10.5924 10.9542ZM4.72205 10.6174L11.3288 4.01062L10.6508 3.3326L4.04402 9.93937L4.72205 10.6174ZM4.77989 5.14174C5.07865 5.14174 5.33227 5.03769 5.54074 4.82961C5.74902 4.62152 5.85316 4.368 5.85316 4.06904C5.85316 3.77028 5.74912 3.51666 5.54103 3.30818C5.33294 3.0999 5.0802 2.99576 4.78279 2.99576C4.48539 2.99576 4.23177 3.09981 4.02194 3.30789C3.8121 3.51598 3.70719 3.76873 3.70719 4.06613C3.70719 4.36354 3.81123 4.61716 4.01932 4.82699C4.22741 5.03682 4.48093 5.14174 4.77989 5.14174Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
};

export default SubcriptionIcon;
