import { CLEAR_DATA, CLEAR_COMPLETE } from "../commom/commonTypes";
import {
  ADD_STORE_CUSTOMER,
  EDIT_STORE_CUSTOMER,
  FETCH_STORE_CUSTOMERS,
  DELETE_STORE_CUSTOMER,
  SET_STORE_CUSTOMER_ERROR,
  SET_STORE_CUSTOMER_LOADER,
  STORE_CUSTOMER_PROGRESS,
  CLEAR_STORE_CUSTOMER,
  CLEAR_COMPLETE_CUSTOMERS,
  SET_STORE_CUSTOMERS_PER_PAGE,
  SET_STORE_CUSTOMER_BALANCE,
  SET_STORE_CUSTOMERS_SEARCH_KEYWORD,
  SET_STORE_CUSTOMERS_INPUT_KEYWORD,
  SET_STORE_CUSTOMERS_CURRENT_PAGE,
  SET_STORE_CUSTOMERS_MAX_PAGE_SO_FAR,
  STORE_CUSTOMER_BALANCE_SELECTOR,
  SET_SUCCESS_TOAST,
  SET_STORE_CUSTOMER_WALLET_POINTS,
} from "./storeCustomerTypes";

const initialState = {
  storeCustomers: [],
  numberOfPages: 0,
  numberOfElements: 0,
  storeCustomer: null,
  progress: true,
  loader: false,
  error: null,
  pageDirty: false,
  storeCustomersPerPage: null,
  currentPage: 0,
  maxPageSoFar: 0,
  searchKeyword: "",
  inputKeyword: "",
  balanceSelector: null,
  successToast: false,
  message: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_CUSTOMER_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      };
    case FETCH_STORE_CUSTOMERS:
      let allStoreCustomers = state.storeCustomers;
      let page = action.payload.pageable.pageNumber;
      if (
        page > 0 &&
        state.storeCustomers.length > action.customersPerPage * page
      ) {
        allStoreCustomers = state.storeCustomers.slice(
          0,
          action.customersPerPage * page
        );
      }
      return {
        ...state,
        pageDirty: false,
        storeCustomers:
          action.payload.pageable.pageNumber > 0
            ? [...allStoreCustomers, ...action.payload.content]
            : action.payload.content,
        numberOfPages: action.payload.totalPages,
        numberOfElements: action.payload.totalElements,
        progress: false,
      };
    case ADD_STORE_CUSTOMER:
      let pageCnt = state.numberOfPages;
      let localPageStoreCustomers = state.storeCustomers;
      let count = state.storeCustomers.length;
      if (count + 1 > state.storeCustomersPerPage) {
        let fullPages = Math.floor((count + 1) / state.storeCustomersPerPage);
        localPageStoreCustomers = state.storeCustomers.slice(
          0,
          fullPages * state.storeCustomersPerPage - 1
        );
      }
      let availablePages = Math.floor(
        state.storeCustomers.length / state.storeCustomersPerPage
      );
      return {
        ...state,
        pageDirty: true,
        storeCustomer: action.payload,
        loader: false,
        storeCustomers: [action.payload, ...localPageStoreCustomers],
        numberOfPages:
          state.numberOfElements === pageCnt * state.storeCustomersPerPage
            ? pageCnt + 1
            : pageCnt,
        numberOfElements: state.numberOfElements + 1,
        currentPage: 0,
        maxPageSoFar: availablePages > 1 ? availablePages - 1 : 0,
        successToast: true,
        message: "Customer Added Successfully",
      };

    case EDIT_STORE_CUSTOMER:
      let modStoreCustomers = state.storeCustomers.map((customer) => {
        if (customer.id === action.payload.id) {
          return action.payload;
        }
        return customer;
      });
      return {
        ...state,
        storeCustomer: action.payload,
        loader: false,
        storeCustomers: modStoreCustomers,
      };
    case DELETE_STORE_CUSTOMER:
      let remCustomers = state.storeCustomers.filter(
        (customer) => customer.id !== action.payload
      );
      return {
        ...state,
        loader: false,
        storeCustomers: remCustomers,
        numberOfPages: Math.ceil(
          (state.numberOfElements - 1) / action.customersPerPage
        ),
        numberOfElements: state.numberOfElements - 1,
      };
    case SET_STORE_CUSTOMER_BALANCE:
      let updatedBalStoreCustomers = state.storeCustomers.map((customer) => {
        if (customer.customer_id === action.payload.customer_id) {
          return { ...customer, balance_amount: action.payload.balance_amount };
        }
        return customer;
      });
      return {
        ...state,
        storeCustomers: updatedBalStoreCustomers,
      };

    case SET_STORE_CUSTOMER_WALLET_POINTS:
      let updatedWalletStoreCustomers = state.storeCustomers.map((customer) => {
        if (customer.customer_id === action.payload.customer_id) {
          return { ...customer, wallet_points: action.payload.wallet_points };
        }
        return customer;
      });
      return {
        ...state,
        storeCustomers: updatedWalletStoreCustomers,
      };

    case CLEAR_STORE_CUSTOMER:
      return {
        ...state,
        storeCustomer: action.payload,
      };
    case SET_STORE_CUSTOMER_ERROR:
      return {
        ...state,
        error: action.payload.error,
        progress: false,
      };
    case SET_STORE_CUSTOMER_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case CLEAR_COMPLETE_CUSTOMERS:
      return {
        ...state,
        storeCustomers: [],
        numberOfPages: 0,
        numberOfElements: 0,
        storeCustomer: null,
        progress: false,
        loader: false,
        error: null,
        pageDirty: false,
        successToast: false,
        message: "",
      };
    case CLEAR_COMPLETE:
    case CLEAR_DATA:
      return {
        ...state,
        storeCustomers: [],
        numberOfPages: 0,
        numberOfElements: 0,
        storeCustomer: null,
        progress: false,
        loader: false,
        error: null,
        pageDirty: false,
        storeCustomersPerPage: null,
        searchKeyword: "",
        inputKeyword: "",
        currentPage: 0,
        maxPageSoFar: 0,
        balanceSelector: null,
        successToast: false,
        message: "",
      };
    case SET_STORE_CUSTOMERS_PER_PAGE:
      return {
        ...state,
        storeCustomersPerPage: action.payload,
      };
    case SET_STORE_CUSTOMERS_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SET_STORE_CUSTOMERS_MAX_PAGE_SO_FAR:
      return {
        ...state,
        maxPageSoFar: action.payload,
      };
    case SET_STORE_CUSTOMERS_INPUT_KEYWORD:
      return {
        ...state,
        inputKeyword: action.payload,
      };
    case SET_STORE_CUSTOMERS_SEARCH_KEYWORD:
      return {
        ...state,
        searchKeyword: action.payload,
      };
    case STORE_CUSTOMER_BALANCE_SELECTOR:
      return {
        ...state,
        balanceSelector: action.payload,
      };
    case SET_SUCCESS_TOAST:
      return {
        ...state,
        successToast: action.payload,
        meassage: "",
      };
    default:
      return state;
  }
};

export default reducer;
