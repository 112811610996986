import React, { useEffect, useState } from "react";
import BriefcaseIcon from "../../../icons/BriefcaseIcon";
import useStyles from "./styles";
import axios from "axios";
import BusinessTypesModal from "./BusinessTypesModal";
import { setUserStores } from "../../../redux/userstore/userStoreActions";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import SolidButton from "../../../components/buttons/SolidButton";
import Controls from "../../../components/controls/Controls";

const BusinessDetailsForm = ({ history }) => {
  const classes = useStyles();
  const [inputs, setInputs] = useState({
    bizname: { value: "", error: false },
    biztype: { value: "", error: false },
    biztypename: { value: "", error: false },
  });
  const [open, setOpen] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [businessTypes, setBusinessTypes] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/api/v1/business-categories`)
      .then((res) => {
        res.data.payload.push(...businessTypes);
        setBusinessTypes(res.data.payload);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedType === "Other") {
      document.getElementById("biztypename").focus();
    }
  }, [selectedType]);

  const onTypeSelect = (slug) => {
    setSelectedType(slug);
    setInputs({
      ...inputs,
      biztype: {
        value: businessTypes.find((t) => t.slug === slug)?.name,
        error: false,
      },
    });
  };

  const handleInputChange = (event) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: {
        ...inputs[event.target.name],
        value: event.target.value,
      },
    }));
  };

  const validate = () => {
    let isValid = true;
    let temp = inputs;
    let cloneObj = {...inputs};
    Object.keys(inputs).forEach((key) => {     
      if((key === 'bizname' || key === 'biztype') && inputs[key].value === ""){
        cloneObj[key].error = true;
        isValid = false;
      }
    });
    if(!isValid){
      setInputs(cloneObj);
    }
    return isValid;
  };

  const onCreate = () => {
    if (!validate()) return;
    const userName = sessionStorage.getItem('store_user_name');
    const data = {
      name: userName ?? "Owner",
      org_name: inputs.bizname.value,
      store_name: inputs.bizname.value,
      business_category: selectedType,
      locale: "en_",
      ...(selectedType === "Other" && {
        other_category: inputs.biztypename.value,
      }),
    };
    const accessToken = localStorage.getItem("access_token");
    axios
      .post(
        `${process.env.REACT_APP_AUTH_API}/api/v1/store/user?use-token=true`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        dispatch(setUserStores(res.data.payload));
        history.push(`/${res.data.payload.store_id}/dashboard`);
        document.body.style.backgroundColor = "#F9F9F9";
      });
  };

  return (
    <>
      <div className={classes.form}>
        <Controls.Input
          id="bizname"
          name="bizname"
          label="Business Name"
          required={true}
          labelPlacement="top"
          value={inputs.bizname.value}
          autoFocus
          textClass={classes.input}
          containerCss={{marginTop: 32}}
          autoComplete="organization"
          onChange={handleInputChange}
          endAdornment={<BriefcaseIcon />}
          error={inputs.bizname.error}
          helperText={
            inputs.bizname.error ? "Please provide Business Name" : ""
          }
          onBlur={(e) => {
            if (e.target.value.length < 1)
              setInputs({
                ...inputs,
                bizname: { ...inputs.bizname, error: true },
              });
          }}
          onFocus={() => {
            setInputs({
              ...inputs,
              bizname: { ...inputs.bizname, error: false },
            });
          }}
        />
        <div
          style={{ cursor: "pointer", marginTop: 32 }}
          onClick={() => {
            setOpen(true);
            setInputs({
              ...inputs,
              biztype: { ...inputs.biztype, error: false },
            });
          }}
        >
          <Controls.Input
            id="biztype"
            label="Business Category"
            name="biztype"
            labelPlacement="top"
            error={inputs.biztype.error}
            helperText={
              inputs.biztype.error
                ? "Please provide your Business Category"
                : " "
            }
            autoComplete="none"
            onChange={handleInputChange}
            textClass={classes.input}
            value={inputs.biztype.value}
            onFocus={() => {
              document.getElementById("biztype").blur();
              setOpen(true);
              setInputs({
                ...inputs,
                biztype: { ...inputs.biztype, error: false },
              });
            }}
          />
        </div>
        {selectedType === "Other" && (
          <Controls.Input
            id="biztypename"
            name="biztypename"
            label="Business Category Name"
            required={true}
            labelPlacement="top"
            value={inputs.biztypename.value}
            autoFocus
            autoComplete="none"
            onChange={handleInputChange}
            textClass={classes.input}
            endAdornment={<BriefcaseIcon />}
            error={inputs.biztypename.error}
            helperText={
              inputs.bizname.error
                ? "Please specify your Business Category"
                : ""
            }
            onBlur={(e) => {
              if (e.target.value.length < 1)
                setInputs({
                  ...inputs,
                  biztypename: { ...inputs.biztypename, error: true },
                });
            }}
            onFocus={() => {
              setInputs({
                ...inputs,
                biztypename: { ...inputs.biztypename, error: false },
              });
            }}
          />
        )}
        <SolidButton
          style={{ width: "100%", marginTop: 32 }}
          onClick={onCreate}
        >
          Create Store
        </SolidButton>
      </div>
      <BusinessTypesModal
        open={open}
        setOpen={setOpen}
        types={businessTypes}
        selected={selectedType}
        onTypeSelect={onTypeSelect}
      />
    </>
  );
};

export default withRouter(BusinessDetailsForm);
