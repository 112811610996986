// Core react imports
import React, { useEffect, useState, Fragment, useMemo } from "react";
// Mui imports
import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  LinearProgress,
  Modal,
  Typography,
  Box,
  Menu,
  MenuItem,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  TableBody,
} from "@material-ui/core";
// Redux imports
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import {
  cancelOrder,
  fetchOrders,
  startOrderLoader,
  stopOrderLoader,
  clearOrders,
  setOrderDateSelector,
  setOrderStateSelector,
  setOrderDateRange,
  setOrdersPerPage,
  setOrdersCurrentPage,
  setOrdersMaxPageSoFar,
  setOrdersInputKeyword,
  setOrdersSearchKeyword,
  setOrdersCustomerId,
  updateOrders,
  revertOrder,
} from "../../redux/orders/orderActions";
import { fetchApps } from "../../redux/apps/appActions";
// Style imports
import useStyles from "./styles";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
// Icon imports
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import StatusDotIcon from "../../icons/StatusDotIcon";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import {
  Check,
  DescriptionOutlined,
  DirectionsBike,
  Done,
  DoneAll,
  RemoveRedEye,
  RotateLeft,
} from "@material-ui/icons";
// Custom component imports
import Paginator from "../../components/Paginator/Paginator";
import DateRangePicker from "react-bootstrap-daterangepicker";
import ConfirmOrderModal from "./components/ConfirmOrderModal";
import ShipOrderModal from "./components/ShipOrderModel";
import CompleteOrderModal from "./components/CompleteOrderModal";
import SubtitleSelect from "../../components/controls/SubtitleSelect";
import Moment from "react-moment";
import {
  ORDERS_PER_PAGE,
  DEFAULT_ORDERS_PER_PAGE,
  ORDERS_KEY,
  getDateRange,
} from "../../utils/utils";
import ExpressDeliveryIcon from "../../icons/ExpressDeliveryIcon";
import { getPdfLink } from "../../utils/invoiceUtil";
import { getStoreIdFromUrl } from "../../utils/storeUtil";
import { downloadDocument } from "../../utils/documentUtil";
import ViewOrder from "./components/ViewOrder";
import RecordOrderPaymentModal from "./components/RecordOrderPaymentModal";
import {
  getColumnShippingDetails,
  getWhatsAppShareLink,
} from "../../utils/orderUtil";
import {
  setReloadFalse,
  setReloadTrue,
} from "../../redux/reload/reloadActions";
import Toast from "../../components/Layout/Toast";
import PickupIcon from "../../icons/PickupIcon";
import { bulkExport } from "../../utils/orderUtil";
// Lottie loader imports
import { StateElement } from "./components/StateElement";
import {
  anchor,
  layoutBodyBox,
  layoutContainer,
  layoutLoaderHeader,
  layoutSubHeader,
  tag,
} from "../../styles/common";
import CombinedButtons from "../../components/buttons/CombinedButtons";
import PageLoader from "../../components/Layout/PageLoader";
import ReturnOrderView from "./components/ReturnOrderView";
import SolidButton from "../../components/buttons/SolidButton";
import NoRecord from "../../components/invoiceorder/NoRecord";
import NoOrderFigma from "../../icons/NoOrderFigma";
import HeaderBreadcrumbs from "../../components/common/HeaderBreadcrumbs";
import { useQuery } from "../coupons/Components/CouponForm/formUtils";
import { SET_HIGHLIGHT_FALSE } from "../../redux/orders/orderTypes";
import ShippingOptionsModal from "./components/ShippingOptionsModal";
import ConfirmModal from "../../components/common/ConfirmModal";
import { ExportProdIcon } from "../../icons";
import BulkActionsHeader from "./components/BulkActionsHeader";
import ConfirmedStateTableList from "./components/ConfirmedStateTableList";

const textToState = (text) => {
  if (text === "New") return "NEW";
  if (text === "Confirmed") return "CONFIRMED";
  if (text === "Shipment Ready") return "SHIPMENT_READY";
  if (text === "Completed") return "COMPLETE";
  if (text === "Canceled") return "CANCELED";
  if (text === "In Transit") return "SHIPPED";
  if (text === "Return") return "RETURN";
  return "ALL";
};
const Orders = () => {
  const classes = useStyles();
  const history = useHistory();
  const queryParams = useQuery();
  const orderState = useSelector((state) => state.orders);
  const store = useSelector((state) => state.store);
  const orders = orderState.orders;
  const numberOfPages = orderState.numberOfPages;
  const loader = orderState.loader;
  const dateSelector = orderState.dateSelector;
  const stateSelector = orderState.stateSelector;
  const dateRange = orderState.dateRange;
  const ordersPerPage = orderState.ordersPerPage;
  const currentPage = orderState.currentPage;
  const maxPageSoFar = orderState.maxPageSoFar;
  const customerId = orderState.customerId;
  const searchKeyword = orderState.searchKeyword;
  const inputKeyword = orderState.inputKeyword;
  const states = [
    "All",
    "New",
    "Confirmed",
    "Shipment Ready",
    "In Transit",
    "Completed",
    "Canceled",
    ...(store.store?.plan !== "FREE" && store.store?.plan !== "REFER"
      ? ["Return"]
      : []),
  ];
  // const [searchKeyword, setSearchKeyword] = useState("");
  // const [inputKeyword, setInputKeyword] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [whiteSpaceDiv, setWhiteSpaceDiv] = useState(false);
  const [anchoredOrder, setAnchoredOrder] = useState(null);
  const [reloadPayment, setReloadPayment] = useState(false);
  const [openConfirmOrder, setOpenConfirmOrder] = useState(false);
  const [openShipOrder, setOpenShipOrder] = useState(false);
  const [openCompleteOrder, setOpenCompleteOrder] = useState(false);
  const [cancelOrd, setCancelOrd] = useState(false);
  const [openViewOrder, setOpenViewOrder] = useState(false);
  const [openReturnOrderView, setOpenReturnOrderView] = useState(false);
  const [revertConfirmed, setRevertConfirmed] = useState(false);
  const [openRecordPayment, setOpenRecordPayment] = useState(false);
  const [toast, setToast] = useState(false);
  const [successToast, setSuccessToast] = useState(false);
  const [toastRec, setToastRec] = useState(false);
  const [toastMaxQty, setToastMaxQty] = useState(false);
  const [selectedItems, setselectedItems] = useState({});
  const [isConfirmaUnassign, setIsConfirmUnassign] = useState(false);
  const paramCustomerId = queryParams.get("customer-id");
  const [isOpenShippingOptions, setOpenShippingOptions] = useState(false);
  const [pageProgress, setPageProgress] = useState(false);
  const [bulkOrdConfirm, setBulkOrdConfirm] = useState(false);
  const [bulkOrdDispatch, setBulkOrdDispatch] = useState(false);
  const [errorToast, setErrorToast] = useState(false);
  const [errorToastMsg, setErrorToastMsg] = useState("");
  const onChangeOrdersPerPage = (updatedOrdersPerPage) => {
    localStorage.setItem(ORDERS_KEY, updatedOrdersPerPage);
    dispatch(clearOrders());
    dispatch(setOrdersCurrentPage(0));
    dispatch(setOrdersMaxPageSoFar(0));
    dispatch(setOrdersPerPage(updatedOrdersPerPage));
    dispatch(
      fetchOrders(
        0,
        textToState(stateSelector),
        dateRange.aoe,
        dateRange.boe,
        searchKeyword,
        updatedOrdersPerPage,
        customerId
      )
    );
  };

  let startIndex = currentPage * ordersPerPage;
  let endIndex =
    startIndex + ordersPerPage < orders.length
      ? startIndex + ordersPerPage
      : orders.length;

  const dispatch = useDispatch();
  const storeId = getStoreIdFromUrl();
  const selectedItemsLength = Object.entries(selectedItems).length;

  useEffect(() => {
    if (!ordersPerPage) {
      dispatch(setOrdersPerPage(ORDERS_PER_PAGE));
    }
    // eslint-disable-next-line
  }, [ordersPerPage]);

  const refetch = queryParams.get("refetch");

  useEffect(() => {
    if (refetch || (orders.length === 0 && !dateSelector && !stateSelector)) {
      setPageProgress(true);
      dispatch(setOrderDateSelector("All"));
      dispatch(setOrderStateSelector("All"));
      dispatch(setOrderDateRange({ aoe: null, boe: null }));
      if (paramCustomerId || customerId) {
        dispatch(
          setOrdersCustomerId(paramCustomerId ? paramCustomerId : customerId)
        );
        dispatch(
          fetchOrders(
            0,
            "All",
            null,
            null,
            "",
            ORDERS_PER_PAGE,
            paramCustomerId ? paramCustomerId : customerId
          )
        ).then(() => {
          setPageProgress(false);
        });
      } else {
        dispatch(fetchOrders()).then(() => {
          setPageProgress(false);
        });
      }
    }
    // eslint-disable-next-line
  }, [storeId, refetch]);

  const apps = useSelector((state) => state.apps.apps);
  useEffect(() => {
    if (!apps.length) {
      dispatch(fetchApps());
    }
    // eslint-disable-next-line
  }, [apps.length]);

  const logisticApps = useMemo(() => {
    let logApps = {};
    apps.forEach((app) => {
      if (app.type === "LOGISTIC") {
        logApps[app.slug] = app.installed;
      }
    });
    return logApps;
    // eslint-disable-next-line
  }, [apps.length]);

  const logisticAppsInstalled = Object.values(logisticApps).some(
    (val) => val === true
  )
    ? true
    : false;
  const onNextPage = () => {
    setWhiteSpaceDiv(false);
    setselectedItems({});
    if (maxPageSoFar < currentPage + 1) {
      dispatch(
        fetchOrders(
          currentPage + 1,
          textToState(stateSelector),
          dateRange.aoe,
          dateRange.boe,
          searchKeyword,
          ordersPerPage,
          customerId
        )
      );
      dispatch(
        setOrdersMaxPageSoFar(
          maxPageSoFar < currentPage + 1 ? currentPage + 1 : maxPageSoFar
        )
      );
      dispatch(setOrdersCurrentPage(currentPage + 1));
    } else {
      dispatch(setOrdersCurrentPage(currentPage + 1));
    }
  };

  const onPrevPage = () => {
    setselectedItems({});
    dispatch(setOrdersCurrentPage(currentPage - 1));
  };

  const handleClick = (event, tableitem) => {
    setAnchoredOrder(tableitem);
    setAnchorEl(event.currentTarget);
  };

  const handleChangeDateSelector = (range) => {
    setWhiteSpaceDiv(true);
    setselectedItems({});
    if (range === dateSelector) return;
    dispatch(setOrdersMaxPageSoFar(0));
    dispatch(setOrdersCurrentPage(0));
    let res = getDateRange(range);
    let aoe = res.aoe;
    let boe = res.boe;
    dispatch(
      fetchOrders(
        0,
        textToState(stateSelector),
        aoe,
        boe,
        searchKeyword,
        ordersPerPage,
        customerId
      )
    );
    dispatch(setOrderDateSelector(range));
    dispatch(setOrderDateRange({ aoe: aoe, boe: boe }));
  };

  const handleCustomDateSelector = (rangePicker) => {
    setWhiteSpaceDiv(true);
    setselectedItems({});
    dispatch(setOrdersMaxPageSoFar(0));
    dispatch(setOrdersCurrentPage(0));
    dispatch(
      fetchOrders(
        0,
        textToState(stateSelector),
        rangePicker.startDate._d.toISOString(),
        rangePicker.endDate._d.toISOString(),
        searchKeyword,
        ordersPerPage,
        customerId
      )
    );
    dispatch(setOrderDateSelector("Custom"));
    dispatch(
      setOrderDateRange({
        aoe: rangePicker.startDate._d.toISOString(),
        boe: rangePicker.endDate._d.toISOString(),
      })
    );
  };

  const handleChangeStateSelector = (state) => {
    setselectedItems({});
    setWhiteSpaceDiv(true);
    dispatch(setOrdersMaxPageSoFar(0));
    dispatch(setOrdersCurrentPage(0));
    dispatch(
      fetchOrders(
        0,
        textToState(state),
        dateRange.aoe,
        dateRange.boe,
        searchKeyword,
        ordersPerPage,
        customerId
      )
    );
    dispatch(setOrderStateSelector(state));
  };

  const stateToText = (tableitem) => {
    let state = tableitem.state;
    if (state === "NEW") return { text: "New", fill: "#2238A8" };
    if (state === "CONFIRMED") return { text: "Confirmed", fill: "#FFB800" };
    if (state === "SHIPMENT_READY")
      return { text: "Shipment Ready", fill: "#666666" };
    if (state === "SHIPPED")
      return {
        text: tableitem.delivery_mode === "PICKUP" ? "Ready" : "In Transit",
        fill: "#FF7A00",
      };
    if (state === "COMPLETE") return { text: "Completed", fill: "#00C708" };
    if (state === "RETURN") {
      if (tableitem.status === "RETURN_REQUESTED")
        return { text: "Return Requested", fill: "#FF0000" };
      if (tableitem.status === "RETURN_REQUEST_ACCEPTED")
        return { text: "Return Accepted", fill: "#FF7A00" };
      if (tableitem.status === "RETURN_PRODUCT_SHIPPED")
        return { text: "Product Shipped", fill: "#FF7A00" };
      if (tableitem.status === "RETURN_REQUEST_REJECTED")
        return { text: "Return Rejected", fill: "#999999" };
      if (tableitem.status === "RETURN_RECEIVED")
        return { text: "Return Received", fill: "#FF7A00" };
      if (tableitem.status === "RETURN_PROCESSED")
        return { text: "Return Completed", fill: "#00C708" };
    }

    if (state === "CANCELED") return { text: "Cancelled", fill: "#999999" };
    return { text: "-", fill: "white" };
  };

  const getNextIcon = (tableitem) => {
    let state = tableitem.state;
    if (state === "NEW") {
      return (
        <Button
          className={classes.actionBtn}
          variant="outlined"
          classes={{ disabled: classes.disabledActionBtn }}
          disabled={selectedItemsLength > 0}
          onClick={() => {
            setAnchoredOrder(tableitem);
            setOpenConfirmOrder(true);
            setBulkOrdConfirm(false);
          }}
        >
          <Done
            style={{
              cursor: "pointer",
              fontSize: 16,
              color: selectedItemsLength > 0 && "#999999",
            }}
            color="secondary"
          />
          &nbsp;Confirm
        </Button>
      );
    }
    if (state === "CONFIRMED") {
      if (tableitem.delivery_mode === "PICKUP") {
        return (
          <Button
            className={classes.actionBtn}
            variant="outlined"
            classes={{ disabled: classes.disabledActionBtn }}
            disabled={selectedItemsLength > 0}
            onClick={() => {
              setAnchoredOrder(tableitem);
              setOpenShipOrder(true);
            }}
          >
            <PickupIcon
              style={{
                cursor: "pointer",
                color: selectedItemsLength > 0 && "#999999",
              }}
            />
            &nbsp;&nbsp;Pickup
          </Button>
        );
      } else {
        return (
          <Button
            className={classes.actionBtn}
            variant="outlined"
            classes={{ disabled: classes.disabledActionBtn }}
            disabled={selectedItemsLength > 0}
            onClick={() => {
              if (!tableitem.order_address) {
                setErrorToast(true);
                setErrorToastMsg("Order does not have an address");
                return;
              }
              setAnchoredOrder(tableitem);
              setOpenShippingOptions(true);
              setBulkOrdDispatch(false);
            }}
          >
            <DirectionsBike
              style={{
                cursor: "pointer",
                fontSize: 16,
                color: selectedItemsLength > 0 && "#999999",
              }}
              color="secondary"
            />
            &nbsp;Dispatch
          </Button>
        );
      }
    } else if (state === "SHIPMENT_READY") {
      return (
        <Button
          className={classes.actionBtn}
          variant="outlined"
          classes={{ disabled: classes.disabledActionBtn }}
          disabled={selectedItemsLength > 0}
          onClick={() => {
            setAnchoredOrder(tableitem);
            setIsConfirmUnassign(true);
          }}
        >
          <RotateLeft
            style={{
              cursor: "pointer",
              fontSize: 19,
              color: selectedItemsLength > 0 && "#999999",
            }}
            color="secondary"
          />
          &nbsp;Unassign
        </Button>
      );
    } else if (state === "SHIPPED") {
      return (
        <Button
          className={classes.actionBtn}
          variant="outlined"
          classes={{ disabled: classes.disabledActionBtn }}
          disabled={selectedItemsLength > 0}
          onClick={() => {
            setAnchoredOrder(tableitem);
            setOpenCompleteOrder(true);
          }}
        >
          <DoneAll
            style={{
              cursor: "pointer",
              fontSize: 16,
              color: selectedItemsLength > 0 && "#999999",
            }}
            color="secondary"
          />
          &nbsp;Complete
        </Button>
      );
    } else if (state === "RETURN") {
      return (
        <Button
          className={classes.actionBtn}
          variant="outlined"
          classes={{ disabled: classes.disabledActionBtn }}
          disabled={selectedItemsLength > 0}
          onClick={() => {
            setAnchoredOrder(tableitem);
            setOpenReturnOrderView(true);
          }}
        >
          <RemoveRedEye
            style={{
              cursor: "pointer",
              fontSize: 16,
              color: selectedItemsLength > 0 && "#999999",
            }}
            color="secondary"
          />
          &nbsp;View
        </Button>
      );
    } else if (state === "COMPLETE") return <div />;
    else if (state === "CANCELED") return <div />;
    return <div />;
  };

  const onSearch = (keyword) => {
    setWhiteSpaceDiv(true);
    setselectedItems({});
    if (keyword) {
      dispatch(setOrdersMaxPageSoFar(0));
      dispatch(setOrdersCurrentPage(0));
      dispatch(setOrderStateSelector("All"));
      dispatch(setOrderDateSelector("All"));
      dispatch(
        fetchOrders(0, "All", null, null, keyword, ordersPerPage, customerId)
      );
    }
  };
  const viewPdf = () => {
    dispatch(startOrderLoader());
    getPdfLink(anchoredOrder.id).then((link) => {
      window.open(link);
      dispatch(stopOrderLoader());
    });
  };

  const downloadInvoice = async () => {
    let filename = anchoredOrder.invoice_id + ".pdf";
    dispatch(startOrderLoader());
    await downloadDocument(store.store, "invoice", anchoredOrder.id, filename);
    dispatch(stopOrderLoader());
  };

  const downloadShippingLabel = async () => {
    let suffix = "-Shipping-Label.pdf";
    let filename = anchoredOrder.display_id + suffix;
    dispatch(startOrderLoader());
    await downloadDocument(
      store.store,
      "shipping-label",
      anchoredOrder.id,
      filename
    );
    dispatch(stopOrderLoader());
  };

  const onExport = async (type) => {
    if (Object.entries(selectedItems).length > 20) {
      setToastMaxQty(true);
      return;
    }
    dispatch(startOrderLoader());
    await bulkExport(type, store.store.slug, selectedItems);
    dispatch(stopOrderLoader());
  };

  const onSelect = (id, orgId) => {
    setselectedItems({ ...selectedItems, [id]: orgId });
  };

  const onUnselect = (id) => {
    let tempSet = { ...selectedItems };
    delete tempSet[id];
    setselectedItems(tempSet);
  };

  const shareOrder = async () => {
    dispatch(startOrderLoader());
    await getWhatsAppShareLink(anchoredOrder).then((link) => {
      window.open(link);
      dispatch(stopOrderLoader());
    });
    setAnchorEl(null);
  };

  const editOrder = async () => {
    history.push(`./orders/${anchoredOrder.display_id}/edit`);
  };

  if ((orderState.progress && orders.length === 0) || pageProgress) {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} className={layoutLoaderHeader}>
            <div className={layoutContainer}>
              <HeaderBreadcrumbs list={[{ label: "Orders" }]} />
              <PageLoader />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
  let noOrderCondn =
    orders.length === 0 &&
    stateSelector === "All" &&
    dateSelector === "All" &&
    searchKeyword === "" &&
    !customerId;

  return (
    <div>
      <Grid container spacing={2}>
        {loader && (
          <div
            style={{
              position: "fixed",
              left: "50%",
              top: "50%",
              zIndex: "900",
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        )}
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className={layoutContainer}>
            <div className={layoutSubHeader}>
              <HeaderBreadcrumbs list={[{ label: "Orders" }]} />
              <SolidButton
                style={{ width: 185, marginLeft: 20 }}
                onClick={() => {
                  history.push("./orders/create");
                }}
              >
                Create Order
              </SolidButton>
            </div>
            {noOrderCondn ? (
              <NoRecord
                text="Start creating Orders for your customers"
                image={<NoOrderFigma />}
                arrow={true}
              />
            ) : (
              <div style={{ position: "relative", width: "100%" }}>
                <div className={layoutBodyBox}>
                  <div className={classes.tableHeader}>
                    <div className={classes.search}>
                      <SearchIcon />
                      <input
                        type="text"
                        placeholder="Enter Order Number, Customer Name, Mobile"
                        className={classes.searchBox}
                        value={
                          customerId
                            ? `Customer ID: ${customerId}`
                            : inputKeyword
                        }
                        onChange={(e) =>
                          dispatch(setOrdersInputKeyword(e.target.value))
                        }
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            if (inputKeyword.trim() !== "") {
                              dispatch(
                                setOrdersSearchKeyword(inputKeyword.trim())
                              );
                              onSearch(inputKeyword.trim());
                            }
                          }
                          if (e.key === "Escape") {
                            setWhiteSpaceDiv(true);
                            dispatch(setOrdersSearchKeyword(""));
                            dispatch(setOrdersInputKeyword(""));
                            dispatch(setOrdersMaxPageSoFar(0));
                            dispatch(setOrdersCurrentPage(0));
                            dispatch(fetchOrders());
                            dispatch(setOrderStateSelector("All"));
                            dispatch(setOrderDateSelector("All"));
                            dispatch(
                              setOrderDateRange({ aoe: null, boe: null })
                            );
                            e.currentTarget.blur();
                          }
                        }}
                      />
                      {(searchKeyword !== "" || customerId) && (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setWhiteSpaceDiv(true);
                            setselectedItems({});
                            dispatch(setOrdersSearchKeyword(""));
                            dispatch(setOrdersInputKeyword(""));
                            dispatch(setOrdersMaxPageSoFar(0));
                            dispatch(setOrdersCurrentPage(0));
                            dispatch(fetchOrders());
                            dispatch(setOrderStateSelector("All"));
                            dispatch(setOrderDateSelector("All"));
                            dispatch(setOrdersCustomerId(null));
                            dispatch(
                              setOrderDateRange({ aoe: null, boe: null })
                            );
                            if (customerId) history.push(`./orders`);
                          }}
                        >
                          <CloseIcon fontSize="small" />
                        </span>
                      )}
                    </div>
                    <div className={classes.tableHeaderRight}>
                      <div style={{ zIndex: 4 }}>
                        <SubtitleSelect
                          optionList={[
                            { value: "All", title: "All", displayValue: "All" },
                            {
                              value: "Today",
                              title: "Today",
                              displayValue: "Today",
                            },
                            {
                              value: "Yesterday",
                              title: "Yesterday",
                              displayValue: "Yesterday",
                            },
                            {
                              value: "Last Week",
                              title: "Last Week",
                              displayValue: "Last Week",
                            },
                            {
                              value: "Last Month",
                              title: "Last Month",
                              displayValue: "Last Month",
                            },
                            {
                              value: "Custom",
                              title: "Custom",
                              displayValue: "Custom",
                            },
                          ]}
                          value={dateSelector}
                          onChange={(value) => {
                            handleChangeDateSelector(value);
                          }}
                          width="137px"
                        />
                      </div>
                      <DateRangePicker
                        initialSettings={{
                          startDate: new Date(
                            new Date().setMonth(new Date().getMonth() - 1)
                          ),
                          endDate: new Date(),
                          maxDate: new Date(),
                          applyButtonClasses: classes.applyBtn,
                        }}
                        onApply={(fn, rangePicker) => {
                          handleCustomDateSelector(rangePicker);
                        }}
                      >
                        <Box
                          margin="0px 8px"
                          border="1px solid #e6e6e6"
                          display="flex"
                          borderRadius="4px"
                        >
                          <Box
                            padding="9px"
                            borderRight="1px solid #e6e6e6"
                            style={{ cursor: "pointer" }}
                          >
                            <DateRangeOutlinedIcon
                              fontSize="small"
                              style={{ height: "14px", width: "14px" }}
                            />
                          </Box>
                          <Box padding="9px">
                            <Typography className={classes.dateText}>
                              {orderState.dateRange.aoe &&
                              orderState.dateRange.boe ? (
                                <>
                                  <Moment format="DD-MM-YYYY">
                                    {orderState.dateRange.aoe}
                                  </Moment>
                                  <span> - </span>
                                  <Moment format="DD-MM-YYYY">
                                    {orderState.dateRange.boe}
                                  </Moment>
                                </>
                              ) : (
                                "dd/mm/yyyy - dd/mm/yyyy"
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      </DateRangePicker>
                    </div>
                  </div>
                  <div className={classes.statusRow}>
                    {states.map((state) => (
                      <StateElement
                        key={state}
                        stateSelector={stateSelector}
                        selector={handleChangeStateSelector}
                        state={state}
                        numElements={orderState.totalCount}
                      />
                    ))}
                  </div>
                  {Object.entries(selectedItems).length > 0 && (
                    <BulkActionsHeader
                      orders={orders}
                      startIndex={startIndex}
                      endIndex={endIndex}
                      selectedItems={selectedItems}
                      setselectedItems={setselectedItems}
                      btnList={[
                        ...(stateSelector === "New"
                          ? [
                              {
                                name: "Confirm",
                                icon: <Check style={{ fontSize: 16 }} />,
                                onClick: () => {
                                  if (selectedItemsLength > 20) {
                                    setToastMaxQty(true);
                                    return;
                                  }
                                  setOpenConfirmOrder(true);
                                  setBulkOrdConfirm(true);
                                },
                              },
                            ]
                          : []),
                        ...(stateSelector === "Confirmed"
                          ? [
                              {
                                name: "Dispatch",
                                icon: (
                                  <DirectionsBike style={{ fontSize: 16 }} />
                                ),
                                onClick: () => {
                                  if (selectedItemsLength > 20) {
                                    setToastMaxQty(true);
                                    return;
                                  }
                                  setOpenShippingOptions(true);
                                  setBulkOrdDispatch(true);
                                },
                              },
                            ]
                          : []),
                        {
                          name: "Shipping Label",
                          icon: (
                            <DescriptionOutlined
                              style={{
                                fontSize: 16,
                                stroke: "#ffffff",
                                strokeWidth: "0.3px",
                              }}
                            />
                          ),
                          onClick: () => onExport("shipping-label"),
                        },
                        ...(stateSelector !== "New"
                          ? [
                              {
                                name: "Invoice",
                                icon: <ExportProdIcon />,
                                onClick: () => onExport("invoice"),
                              },
                            ]
                          : []),
                      ]}
                    />
                  )}
                  <TableContainer>
                    <Table>
                      {Object.entries(selectedItems).length === 0 && (
                        <TableHead className={classes.tablehead}>
                          <TableRow className={classes.headrow}>
                            <TableCell
                              className={classes.stickyTableColumn}
                              style={{
                                background: "white",
                                width: "5.5%",
                                borderBottom:
                                  Object.entries(selectedItems).length > 0 &&
                                  "none",
                              }}
                            >
                              <Checkbox
                                style={{ padding: 0 }}
                                checked={
                                  Object.entries(selectedItems).length !== 0 &&
                                  Object.entries(selectedItems).length ===
                                    endIndex - startIndex
                                }
                                onChange={(e) => {
                                  let selected = {};
                                  orders
                                    .slice(startIndex, endIndex)
                                    .forEach(
                                      (item) =>
                                        (selected[item.id] = item.display_id)
                                    );

                                  if (e.target.checked) {
                                    setselectedItems(selected);
                                  } else {
                                    setselectedItems({});
                                  }
                                }}
                              />
                            </TableCell>
                            <TableCell
                              className={classes.stickyTableColumn}
                              style={{
                                width: "16.5%",
                                left: 56,
                                background: "white",
                                paddingLeft: 8,
                              }}
                            >
                              Order #
                            </TableCell>
                            <TableCell style={{ width: "10%" }}>
                              Amount
                            </TableCell>
                            {/* <TableCell style={{ width: "10%" }}>
                            Balance
                          </TableCell> */}
                            <TableCell
                              style={{ width: "12%", whiteSpace: "nowrap" }}
                            >
                              Order Date
                            </TableCell>
                            <TableCell
                              style={{ width: "16%", whiteSpace: "nowrap" }}
                            >
                              Customer Name
                            </TableCell>
                            <TableCell
                              style={{ width: "15%", whiteSpace: "nowrap" }}
                            >
                              Deliver By
                            </TableCell>
                            {storeId === "20067" && (
                              <TableCell
                                style={{ width: "10%", whiteSpace: "nowrap" }}
                              >
                                UTM Id
                              </TableCell>
                            )}
                            {stateSelector === "Confirmed" && (
                              <ConfirmedStateTableList
                                type="Header"
                                logisticAppsInstalled={logisticAppsInstalled}
                              />
                            )}
                            <TableCell style={{ width: "12%" }}>
                              State
                            </TableCell>
                            <TableCell style={{ width: "5%" }}>
                              Action
                            </TableCell>
                            <TableCell style={{ width: "4%" }}></TableCell>
                          </TableRow>
                        </TableHead>
                      )}
                      <TableBody>
                        {orders
                          .slice(startIndex, endIndex)
                          .map((tableitem, index) => (
                            <TableRow
                              key={tableitem.id}
                              style={{
                                borderBottom:
                                  (index + 1) % ordersPerPage === 0
                                    ? "none"
                                    : "auto",
                                height: 68,
                                backgroundColor: tableitem.highlight
                                  ? "#F7F8FC"
                                  : "#fff",
                              }}
                            >
                              <TableCell
                                className={classes.stickyTableColumn}
                                style={{
                                  width: "5.5%",
                                  background: tableitem.highlight
                                    ? "#F7F8FC"
                                    : "#ffffff",
                                }}
                              >
                                <Checkbox
                                  style={{ padding: 0 }}
                                  checked={Boolean(selectedItems[tableitem.id])}
                                  onChange={(e) => {
                                    if (e.target.checked)
                                      onSelect(
                                        tableitem.id,
                                        tableitem.display_id
                                      );
                                    else onUnselect(tableitem.id);
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                className={classes.stickyTableColumn}
                                style={{
                                  fontSize: 12,
                                  width: "16.5%",
                                  left: 56,
                                  whiteSpace: "nowrap",
                                  paddingLeft: 8,
                                  background: tableitem.highlight
                                    ? "#F7F8FC"
                                    : "#ffffff",
                                }}
                              >
                                <div
                                  style={
                                    tableitem.highlight
                                      ? {
                                          background: "#16AC67",
                                          width: "6px",
                                          height: "6px",
                                          borderRadius: "50%",
                                          marginRight: "4px",
                                          display: "inline-block",
                                        }
                                      : {
                                          display: "none",
                                        }
                                  }
                                ></div>
                                <span
                                  style={
                                    tableitem.highlight
                                      ? {
                                          color: "#1641DB",
                                          cursor: "pointer",
                                          fontWeight: 600,
                                        }
                                      : {
                                          color: "#1641DB",
                                          cursor: "pointer",
                                        }
                                  }
                                  onClick={() => {
                                    if (tableitem.highlight) {
                                      dispatch({
                                        type: SET_HIGHLIGHT_FALSE,
                                        payload: tableitem.id,
                                      });
                                    }
                                    setAnchoredOrder(tableitem);
                                    tableitem.state === "RETURN"
                                      ? setOpenReturnOrderView(true)
                                      : setOpenViewOrder(true);
                                  }}
                                >
                                  {tableitem.display_id}
                                </span>
                                <span
                                  className={tag}
                                  style={{
                                    background:
                                      tableitem.delivery_mode === "PICKUP"
                                        ? "rgba(255,114,34,0.15)"
                                        : "rgba(34,215,255,0.15)",
                                  }}
                                >
                                  {tableitem.delivery_mode === "PICKUP"
                                    ? "Pick Up"
                                    : "Delivery"}
                                </span>
                              </TableCell>
                              <TableCell style={{ width: "10%" }}>
                                {tableitem.final_sale_price.toFixed(2)}
                              </TableCell>
                              {/* <TableCell>
                                {tableitem.due_amount.toFixed(2)}
                              </TableCell> */}
                              <TableCell
                                style={{ width: "12%", whiteSpace: "nowrap" }}
                              >
                                <Moment format="DD-MMM-YYYY">
                                  {tableitem.created_at}
                                </Moment>
                              </TableCell>
                              <TableCell
                                style={{ width: "16%", whiteSpace: "nowrap" }}
                              >
                                <Link
                                  to={`./customers/${tableitem.org_store_customer_id}`}
                                  style={{
                                    color: "#1641DB",
                                    textDecoration: "none",
                                  }}
                                >
                                  {tableitem.org_customer_name}
                                </Link>
                              </TableCell>
                              <TableCell
                                style={{ width: "15%", whiteSpace: "nowrap" }}
                              >
                                {tableitem.delivery_ends_at ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {tableitem.express && (
                                      <Fragment>
                                        <ExpressDeliveryIcon />
                                        &nbsp;
                                      </Fragment>
                                    )}
                                    {getColumnShippingDetails(tableitem)}
                                  </div>
                                ) : (
                                  "--"
                                )}
                              </TableCell>
                              {storeId === "20067" && (
                                <TableCell
                                  style={{ width: "10%", whiteSpace: "nowrap" }}
                                >
                                  {tableitem.utm_id}
                                </TableCell>
                              )}
                              {stateSelector === "Confirmed" && (
                                <ConfirmedStateTableList
                                  type="Body"
                                  tableitem={tableitem}
                                  logisticAppsInstalled={logisticAppsInstalled}
                                />
                              )}
                              <TableCell
                                style={{ width: "12%", whiteSpace: "nowrap" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <StatusDotIcon
                                    style={{ marginRight: 6 }}
                                    fill={stateToText(tableitem).fill}
                                  />
                                  {stateToText(tableitem).text}
                                </div>
                              </TableCell>
                              <TableCell
                                style={{ padding: 0, width: "5%" }}
                                align="center"
                              >
                                {getNextIcon(tableitem)}
                              </TableCell>
                              <TableCell
                                style={{ fontSize: 12, width: "4%" }}
                                align="center"
                              >
                                {tableitem.state !== "CANCELED" && (
                                  <Fragment>
                                    {" "}
                                    <MoreHorizIcon
                                      style={{ cursor: "pointer" }}
                                      aria-controls="simple-menu"
                                      aria-haspopup="true"
                                      onClick={(event) => {
                                        dispatch(setReloadTrue());
                                        handleClick(event, tableitem);
                                      }}
                                    />
                                    <Menu
                                      id="simple-menu"
                                      anchorEl={anchorEl}
                                      keepMounted
                                      open={Boolean(anchorEl)}
                                      onClose={() => {
                                        dispatch(setReloadFalse());
                                        setAnchorEl(null);
                                      }}
                                      className={anchor}
                                    >
                                      {anchoredOrder &&
                                        anchoredOrder.state !== "CANCELED" && (
                                          <MenuItem onClick={editOrder}>
                                            Edit Order
                                          </MenuItem>
                                        )}
                                      {anchoredOrder &&
                                        anchoredOrder.state !== "NEW" && (
                                          <MenuItem
                                            onClick={() => {
                                              viewPdf();
                                              setAnchorEl(null);
                                            }}
                                          >
                                            View Invoice
                                          </MenuItem>
                                        )}
                                      {anchoredOrder &&
                                        anchoredOrder.state !== "NEW" && (
                                          <MenuItem
                                            onClick={() => {
                                              downloadInvoice();
                                              setAnchorEl(null);
                                            }}
                                          >
                                            Download Invoice
                                          </MenuItem>
                                        )}
                                      {anchoredOrder &&
                                        anchoredOrder.state !== "NEW" && (
                                          <MenuItem
                                            onClick={() => {
                                              downloadShippingLabel();
                                              setAnchorEl(null);
                                            }}
                                          >
                                            Shipping Label
                                          </MenuItem>
                                        )}
                                      {anchoredOrder &&
                                        anchoredOrder.due_amount > 0 && (
                                          <MenuItem
                                            onClick={() => {
                                              setOpenRecordPayment(true);
                                              setAnchorEl(null);
                                            }}
                                          >
                                            Record Payment
                                          </MenuItem>
                                        )}
                                      {anchoredOrder &&
                                        anchoredOrder.state !== "NEW" &&
                                        anchoredOrder.state !== "COMPLETE" &&
                                        anchoredOrder.state !== "SHIPPED" &&
                                        logisticApps.shiprocket &&
                                        localStorage.getItem(
                                          "default_shiprocket"
                                        ) === "true" && (
                                          <MenuItem
                                            onClick={() => {
                                              setOpenShippingOptions(true);
                                              setAnchorEl(null);
                                            }}
                                          >
                                            Change Shipping Method &emsp;
                                          </MenuItem>
                                        )}
                                      {anchoredOrder &&
                                        anchoredOrder.state !== "CANCELED" && (
                                          <MenuItem onClick={shareOrder}>
                                            WhatsApp Share
                                          </MenuItem>
                                        )}
                                      {anchoredOrder &&
                                        (anchoredOrder.state === "SHIPPED" ||
                                          anchoredOrder.state ===
                                            "COMPLETE") && (
                                          <MenuItem
                                            onClick={() => {
                                              setRevertConfirmed(true);
                                              setAnchorEl(null);
                                            }}
                                          >
                                            Revert to Confirmed
                                          </MenuItem>
                                        )}
                                      {anchoredOrder &&
                                        anchoredOrder.state !== "COMPLETE" && (
                                          <MenuItem
                                            style={{ color: "#FF0000" }}
                                            onClick={() => {
                                              setCancelOrd(true);
                                              setAnchorEl(null);
                                            }}
                                          >
                                            Cancel Order;
                                          </MenuItem>
                                        )}
                                    </Menu>
                                  </Fragment>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>

                    {orderState.progress && (
                      <Fragment>
                        {!whiteSpaceDiv && (
                          <div
                            style={{
                              height: 68 * ordersPerPage - 4,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          ></div>
                        )}
                        <LinearProgress color="secondary" />
                      </Fragment>
                    )}
                    {orders.length === 0 && (
                      <div
                        style={{
                          height: 68 * 8 - 4,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: 18,
                            fontWeight: 600,
                            marginTop: -34,
                          }}
                        >
                          No Order Found
                        </Typography>
                      </div>
                    )}
                  </TableContainer>
                </div>
                <Paginator
                  changeRecordsPerPage={onChangeOrdersPerPage}
                  possibleRecordsPerPage={[
                    DEFAULT_ORDERS_PER_PAGE,
                    20,
                    50,
                    100,
                  ]}
                  totalCount={orderState.numberOfElements}
                  recordsPerPage={ordersPerPage}
                  onNext={onNextPage}
                  onPrev={onPrevPage}
                  total={numberOfPages === 0 ? 1 : numberOfPages}
                  currentPage={currentPage}
                />
              </div>
            )}
          </div>
        </Grid>
      </Grid>
      {cancelOrd && anchoredOrder && (
        <Modal
          open={cancelOrd}
          onClose={() => setCancelOrd(false)}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              padding: 25,
              borderRadius: 12,
              paddingBottom: 16,
              position: "absolute",
              background: "#ffffff",
              width: 500,
            }}
          >
            <p style={{ fontSize: 16 }}>
              Do you really want to cancel <b> {anchoredOrder.display_id}</b>?
            </p>
            <div
              style={{
                height: "50px",
                position: "relative",
                marginTop: 50,
                marginBottom: 7,
              }}
            >
              <div style={{ position: "absolute", bottom: 0, right: 0 }}>
                <CombinedButtons
                  isDelete={true}
                  solidBtnText="Cancel Order"
                  outlinedBtnAction={() => {
                    setCancelOrd(false);
                  }}
                  solidBtnAction={() => {
                    dispatch(cancelOrder({}, anchoredOrder.id));
                    setCancelOrd(false);
                  }}
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
      <ConfirmOrderModal
        openConfirmOrder={openConfirmOrder}
        setOpenConfirmOrder={setOpenConfirmOrder}
        order={anchoredOrder}
        setToast={setToast}
        selectedItems={selectedItems}
        setselectedItems={setselectedItems}
        bulkOrdConfirm={bulkOrdConfirm}
        setErrorToast={setErrorToast}
      />
      <ShipOrderModal
        openShipOrder={openShipOrder}
        setOpenShipOrder={setOpenShipOrder}
        order={anchoredOrder}
        setToast={setToast}
      />
      {isOpenShippingOptions && (
        <ShippingOptionsModal
          isOpen={isOpenShippingOptions}
          order={anchoredOrder}
          bulkOrdDispatch={bulkOrdDispatch}
          selectedItems={selectedItems}
          setselectedItems={setselectedItems}
          logisticApps={logisticApps}
          setToast={setToast}
          setSuccessToast={setSuccessToast}
          onClose={() => setOpenShippingOptions(false)}
        />
      )}
      <CompleteOrderModal
        openCompleteOrder={openCompleteOrder}
        setOpenCompleteOrder={setOpenCompleteOrder}
        order={anchoredOrder}
        setToast={setToast}
      />
      {anchoredOrder && openViewOrder && (
        <ViewOrder
          openViewOrder={openViewOrder}
          setOpenViewOrder={setOpenViewOrder}
          setAnchoredOrder={setAnchoredOrder}
          order={anchoredOrder}
          setOpenRecordPayment={setOpenRecordPayment}
          reloadPayment={reloadPayment}
        />
      )}

      {anchoredOrder && openReturnOrderView && (
        <ReturnOrderView
          openReturnOrderView={openReturnOrderView}
          setOpenReturnOrderView={setOpenReturnOrderView}
          setAnchoredOrder={setAnchoredOrder}
          order={anchoredOrder}
          setOpenRecordPayment={setOpenRecordPayment}
          reloadPayment={reloadPayment}
        />
      )}
      {anchoredOrder && openRecordPayment && (
        <RecordOrderPaymentModal
          order={anchoredOrder}
          setOpenRecordPayment={setOpenRecordPayment}
          openRecordPayment={openRecordPayment}
          setReloadPayment={setReloadPayment}
          reloadPayment={reloadPayment}
          setToastRec={setToastRec}
        />
      )}
      <ConfirmModal
        open={isConfirmaUnassign}
        title={"Unassign Order "}
        message={`Are you sure want to Unassign Order from Delivery Boy`}
        confirmBtnText="Confirm"
        closeBtnText="Cancel"
        intent="positive"
        onClose={() => setIsConfirmUnassign(false)}
        onConfirm={() => {
          dispatch(
            updateOrders(
              {
                state: "CONFIRMED",
                status: "VERIFIED",
              },
              [anchoredOrder.id]
            )
          ).then(() => {
            setToast(true);
            setIsConfirmUnassign(false);
          });
        }}
      />
      <ConfirmModal
        open={revertConfirmed}
        title={`Revert Order ${
          anchoredOrder ? anchoredOrder.display_id : ""
        } to Confirmed state`}
        message={`Do you want to revert order status back to "Confirmed" ?`}
        confirmBtnText="Yes, Proceed"
        closeBtnText="Cancel"
        intent="positive"
        onClose={() => setRevertConfirmed(false)}
        onConfirm={() => {
          dispatch(revertOrder(anchoredOrder.id)).then(() => {
            setToast(true);
            setRevertConfirmed(false);
          });
        }}
      />
      <Toast open={toast} close={setToast} message="Order Status Updated" />
      <Toast
        open={successToast}
        close={() => setSuccessToast(false)}
        message={successToast}
      />
      <Toast
        open={toastRec}
        close={setToastRec}
        message="Payment Recorded Successfully"
      />
      <Toast
        open={toastMaxQty}
        close={setToastMaxQty}
        message="Maximum 20 orders can be selected at once"
        severity="error"
      />
      <Toast
        message={
          errorToastMsg
            ? errorToastMsg
            : "Something went wrong, please try again!"
        }
        open={errorToast}
        close={() => {
          setErrorToast(false);
          setErrorToastMsg("");
        }}
        severity="error"
        autoClose
      />
    </div>
  );
};

export default Orders;
