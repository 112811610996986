import React, { useState } from "react";
import {
  Button,
  Box,
  ClickAwayListener,
  FormControl,
  FormGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { CancelOutlined } from "@material-ui/icons";
import { DownArrowIcon } from "../../../../icons";
import useStyles from "../../styles";

const PaymentMethodFilter = ({ filterMethod, setFilterMethod }) => {
  const classes = useStyles();
  const [openPaymentOptionSheet, setPaymentOptionSheet] = useState(false);

  const handleClickAway = () => setPaymentOptionSheet(false);
  const handleButtonClick = () => setPaymentOptionSheet(true);

  const handleMethodChange = (method) => {
    setFilterMethod(method);
    setPaymentOptionSheet(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div style={{ position: "relative", marginLeft: 10 }}>
        <Button
          className={classes.selector}
          style={{
            color: filterMethod ? "#1A1A1A" : "#999999",
            outline: "none",
            minWidth: 93,
            whiteSpace: "nowrap",
          }}
          endIcon={<DownArrowIcon stroke="#1A1A1A" />}
          onClick={handleButtonClick}
        >
          {filterMethod ? (
            <Box
              className={classes.rowFlexAlignCenter}
              padding="6px 4px"
              border="1px solid rgba(153,153,153,0.5)"
              borderRadius="4px"
            >
              <span
                style={{
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: "#000",
                }}
              >
                {filterMethod === "cod" ? "COD Only" : "Prepaid Only"}
              </span>
              <CancelOutlined
                htmlColor="#1614DB"
                style={{ fontSize: "16px", marginLeft: "6px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setFilterMethod(null);
                }}
              />
            </Box>
          ) : (
            "Payment Mode"
          )}
        </Button>
        {openPaymentOptionSheet && (
          <div className={classes.catSheet}>
            <FormControl style={{ width: 153, padding: 4 }}>
              <FormGroup
                style={{
                  background: filterMethod === null ? "#F5F7FC" : "transparent",
                  borderRadius: 6,
                }}
              >
                <FormControlLabel
                  control={
                    <Radio
                      checked={filterMethod === null}
                      onChange={() => handleMethodChange(null)}
                      name="All Payments"
                      color="secondary"
                    />
                  }
                  style={{
                    margin: 0,
                    fontWeight: 400,
                    borderBottom: "1px solid #F6F6F6",
                  }}
                  label="Default"
                  classes={{ label: classes.label }}
                />
              </FormGroup>
              <FormGroup
                style={{
                  background:
                    filterMethod === "cod" ? "#F5F7FC" : "transparent",
                  borderRadius: 6,
                }}
              >
                <FormControlLabel
                  control={
                    <Radio
                      checked={filterMethod === "cod"}
                      onChange={() => handleMethodChange("cod")}
                      name="cod"
                      color="secondary"
                    />
                  }
                  style={{
                    margin: 0,
                    fontWeight: 400,
                  }}
                  label="COD Only"
                  classes={{ label: classes.label }}
                />
              </FormGroup>
              <FormGroup
                style={{
                  background:
                    filterMethod === "prepaid" ? "#F5F7FC" : "transparent",
                  borderRadius: 6,
                }}
              >
                <FormControlLabel
                  control={
                    <Radio
                      checked={filterMethod === "prepaid"}
                      onChange={() => handleMethodChange("prepaid")}
                      name="prepaid"
                      color="secondary"
                    />
                  }
                  style={{
                    margin: 0,
                    fontWeight: 400,
                  }}
                  label="Prepaid Only"
                  classes={{ label: classes.label }}
                />
              </FormGroup>
            </FormControl>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default PaymentMethodFilter;
