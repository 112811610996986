import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { RemoveCircleIcon } from "../icons";
import { makeStyles } from "@material-ui/core";
const useStyle = makeStyles(() => ({
  image: {
    "&:hover": {
      "& .close": {
        display: "flex",
        opacity: 1,
      },
    },
  },
  close: {
    display: "none",
    opacity: 0,
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
}));

const ImageList = ({ cropImgs, setCropImgs, onImageRemove, onImageClick }) => {
  const classes = useStyle();
  // Choosed Images List can be drag to rearrange
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newImages = Array.from(cropImgs);
    const [reorderedItem] = newImages.splice(result.source.index, 1);
    newImages.splice(result.destination.index, 0, reorderedItem);
    setCropImgs(newImages);
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="image-list" direction="horizontal">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{ display: "flex" }}
          >
            {cropImgs.map((img, index) => (
              <Draggable
                key={index}
                draggableId={`draggable-img-${index}`}
                index={index}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      width: 60,
                      height: 60,
                      position: "relative",
                      marginRight: 24,
                      borderRadius: 6,
                      border: "1px solid #e1e1e1",
                      ...provided.draggableProps.style,
                    }}
                    className={classes.image}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: -11,
                        right: -11,
                      }}
                      className={`${classes.close} close`}
                      onClick={() => onImageRemove(index)}
                    >
                      <RemoveCircleIcon />
                    </div>
                    <img
                      src={img}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: 6,
                        objectFit: "contain",
                      }}
                      onClick={() => onImageClick(index)}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ImageList;
