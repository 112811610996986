import { makeStyles } from "@material-ui/core";
import React from "react";
import ShoopyIcon from "../../../icons/ShoopyIcon";

const useStyle = makeStyles((theme) => ({
  header: {
    height: 48,
    width: "100%",
    backgroundColor: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: "1px solid #EEEEEE",
    gap: 10,
    [theme.breakpoints.up("sm")]: {
      height: 64,
    },
  },
  headerLogo: {
    height: 26,
    width: 26,
    [theme.breakpoints.up("sm")]: {},
  },
  shoopyText: {
    fontSize: 10,
    fontWeight: 600,
    color: "#666666",
    [theme.breakpoints.up("sm")]: {
      fontSize: 12,
    },
  },
  walletText: {
    fontWeight: 700,
    fontSize: 12,
    color: "#1A1A1A",
    [theme.breakpoints.up("sm")]: {
      fontSize: 16,
    },
  },
}));

export default function RechargeHeader() {
  const classes = useStyle();
  return (
    <div className={classes.header}>
      <ShoopyIcon className={classes.headerLogo} />
      <div>
        <div className={classes.shoopyText}>Shoopy</div>
        <div className={classes.walletText}>Wallet</div>
      </div>
    </div>
  );
}
