import React, { useEffect, useState, useCallback } from "react";
import { Paper, Divider, Grid, Typography, Box } from "@material-ui/core";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  unInstallApp,
  installApp,
  getPixelValue,
  getPluginConfig,
} from "../../redux/apps/appActions";
import { fetchApps } from "../../redux/apps/appActions";
import SettingsIcon from "@material-ui/icons/Settings";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import { FreeChip, InstalledChip, PremiumChip } from "./components/Chips";
import ConfirmModal from "../../components/common/ConfirmModal";
import Toast from "../../components/Layout/Toast";
import Config from "./components/Config";
import MediaCarousel from "./components/MediaCarousel";
import UnInstallIcon from "../../icons/UninstallIcon";
import {
  centerContainer,
  layoutCenterContainer,
  layoutContainer,
  rowFlexOnlyJustifyBetweenCenter,
} from "../../styles/common";
import OutlinedButton from "../../components/buttons/OutlinedButton";
import SolidButton from "../../components/buttons/SolidButton";
import PageLoader from "../../components/Layout/PageLoader";
import { configApps, slugPixelMap, slugPlanMap } from "./constants";
import HeaderBreadcrumbs from "../../components/common/HeaderBreadcrumbs";
import PaidPopUp from "../../components/PaidPopUp";
import { BASIC, PLANS } from "../plans/constants";
import useStyles from "./AppPageStyles";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export default function AppPage() {
  const location = useLocation();
  const classes = useStyles();
  const params = useParams();
  const query = useQuery();
  const history = useHistory();
  const storeData = useSelector((state) => state.store);
  const store = storeData.store;
  const appStore = useSelector((state) => state.apps);
  const planInfo = useSelector((state) => state.store.storePlanRenewInfo);

  const apps = appStore.apps;
  const dispatch = useDispatch();
  const pageApp = apps.filter((app) => app.slug === params.slug)[0];
  const [installProcessed, setInstallProcessed] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [actionTriggered, setActionTriggered] = useState(false);
  const [isConfiguring, setIsConfiguring] = useState(false);
  const [planNeeded, setPlanNeeded] = useState(null);
  const [planSubTxt, setPlanSubTxt] = useState("");

  const configurationHandler = useCallback(() => {
    history.push(`./${pageApp.slug}/configure`);
    // eslint-disable-next-line
  }, [pageApp, params.storeId]);

  useEffect(() => {
    if (storeData.fetched) {
      if (pageApp && pageApp.installed && slugPixelMap[pageApp.slug]) {
        dispatch(getPixelValue(slugPixelMap[pageApp.slug]));
      } else if (pageApp && pageApp.installed && pageApp.slug === "gmc") {
        dispatch(getPluginConfig("gmc"));
      }
    }
    // eslint-disable-next-line
  }, [pageApp, storeData]);

  useEffect(() => {
    if (!apps.length) {
      dispatch(fetchApps());
    }
    // eslint-disable-next-line
  }, [apps.length]);

  useEffect(() => {
    if (!appStore.installloading && actionTriggered) {
      if (appStore.installsuccess) {
        if (pageApp && pageApp.installed) {
          setMessage("Successfully installed app");
        } else {
          setMessage("Successfully uninstalled app");
        }
      }
      if (appStore.installfailed) {
        if (pageApp && pageApp.installed) {
          setError("Failed to uninstall");
        } else {
          setError("Failed to install");
        }
      }
    }
  }, [
    appStore.installloading,
    appStore.installsuccess,
    appStore.installfailed,
    pageApp,
    actionTriggered,
  ]);

  useEffect(() => {
    let imAllowed = planInfo && planInfo.indiamart;
    let action = query.get("action");
    if (!action) {
      return null;
    } else {
      if (
        pageApp &&
        pageApp.slug &&
        action === "Install" &&
        !pageApp.installed &&
        !appStore.installloading &&
        !installProcessed
      ) {
        if (
          pageApp.plan === "FREE" ||
          (pageApp.slug === "indiamart" && imAllowed) ||
          PLANS[pageApp.plan].weight <= PLANS[store.plan].weight
        ) {
          dispatch(installApp(pageApp.slug));
          setActionTriggered(true);
          setInstallProcessed(true);
        } else {
          setPlanSubTxt(slugPlanMap[pageApp.slug].subTxt);
          setPlanNeeded(slugPlanMap[pageApp.slug].plan);
        }
      } else if (action === "Configure") {
        configurationHandler();
      }
    }
    // eslint-disable-next-line
  }, [
    pageApp,
    appStore.installloading,
    installProcessed,
    params.slug,
    params.storeId,
  ]);

  useEffect(() => {
    let pathArr = location.pathname.split("/");
    if (pathArr[pathArr.length - 1] === "configure") {
      setIsConfiguring(true);
    } else {
      setIsConfiguring(false);
    }
  }, [location]);

  const handleInstall = () => {
    if (
      pageApp.plan === "FREE" ||
      PLANS[pageApp.plan].weight <= PLANS[store.plan].weight
    ) {
      setActionTriggered(true);
      dispatch(installApp(pageApp.slug));
    } else {
      setPlanSubTxt(slugPlanMap[pageApp.slug].subTxt);
      setPlanNeeded(slugPlanMap[pageApp.slug].plan);
    }
  };

  const getStatusOrCostingChip = () => {
    let jsx = [];
    if (pageApp.plan === "FREE") {
      jsx.push(
        <Box marginRight="10px" key="freechip">
          <FreeChip />
        </Box>
      );
    } else {
      jsx.push(
        <Box marginRight="10px" key="premiumchip">
          <PremiumChip
            planName={
              PLANS[pageApp.plan] ? PLANS[pageApp.plan].name : "Upcoming"
            }
          />
        </Box>
      );
    }
    if (pageApp.installed) {
      jsx.push(<InstalledChip key="installed chip" />);
    }
    return jsx;
  };

  const getActionButton = () => {
    let jsx = [];
    if (pageApp.installed) {
      jsx.push(
        <OutlinedButton
          key="uninstallbtn"
          endIcon={
            appStore.installloading ? (
              <React.Fragment></React.Fragment>
            ) : (
              <UnInstallIcon />
            )
          }
          onClick={() => {
            setIsConfirmationOpen(true);
          }}
          loading={appStore.installloading}
          style={{ height: 44, marginRight: 20 }}
        >
          {appStore.installloading ? "Uninstalling..." : "Uninstall"}
        </OutlinedButton>
      );
    } else {
      jsx.push(
        <SolidButton
          key="installbtn"
          style={{ height: 44 }}
          endIcon={
            appStore.installloading ? (
              <React.Fragment></React.Fragment>
            ) : (
              <AddCircleOutlineOutlinedIcon />
            )
          }
          onClick={handleInstall}
          loading={appStore.installloading}
        >
          {appStore.installloading ? "Installing..." : "Install"}
        </SolidButton>
      );
    }
    if (
      pageApp.installed &&
      pageApp.config_required &&
      configApps.includes(pageApp.slug)
    ) {
      jsx.push(
        <SolidButton
          key="configbtn"
          style={{ height: 44 }}
          endIcon={appStore.installloading ? <></> : <SettingsIcon />}
          loading={appStore.installloading}
          onClick={configurationHandler}
        >
          Configure
        </SolidButton>
      );
    }
    return jsx;
  };
  let list = [];
  if (pageApp)
    list = !isConfiguring
      ? [
          {
            label: "App Store",
            link: "/apps",
          },
          {
            label: pageApp.title,
          },
        ]
      : [
          {
            label: "App Store",
            link: "/apps",
          },
          { label: pageApp.title, link: `/apps/${params.slug}` },
          {
            label: "Configure",
          },
        ];

  if (!pageApp) return <PageLoader />;

  return (
    <div className={layoutCenterContainer}>
      <div className={isConfiguring ? centerContainer : layoutContainer}>
        <div className={rowFlexOnlyJustifyBetweenCenter}>
          <div className={classes.backDiv}>
            <HeaderBreadcrumbs list={list} />
          </div>
        </div>

        <Paper elevation={0} className={classes.paper}>
          {!isConfiguring ? (
            <>
              <div style={{ display: "flex", width: "100%" }}>
                <img
                  src={pageApp.icon_url}
                  alt={`${pageApp.slug}_image`}
                  style={{ height: 120, width: 120 }}
                />
                <div style={{ flex: 1, marginLeft: 25 }}>
                  <div>
                    <Typography className={classes.appTitle}>
                      {pageApp.title}
                    </Typography>
                    <Typography className={classes.subTitle}>
                      {pageApp.sub_title}
                    </Typography>
                    <Typography className={classes.subTitle}>
                      Provided by:{" "}
                      <a
                        href={pageApp.developer_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {pageApp.developer}
                      </a>
                    </Typography>
                  </div>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    marginTop="4px"
                  >
                    <Box display="flex">{getStatusOrCostingChip()}</Box>
                    <Box>{getActionButton()}</Box>
                  </Box>
                </div>
              </div>
              <Divider style={{ marginBottom: "32px", marginTop: "32px" }} />
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={pageApp?.media_links.length ? 6 : 12}
                  style={{ paddingRight: 70 }}
                >
                  <Typography className={classes.description}>
                    Description
                  </Typography>
                  {pageApp.content
                    .replaceAll("\n", " \n")
                    .split("\n")
                    .map((_, idx) => {
                      if (_ === " ") {
                        return <br key={idx} />;
                      }
                      return (
                        <Typography
                          style={{ fontSize: "14px", lineHeight: "20px" }}
                          key={idx}
                        >
                          {_}
                        </Typography>
                      );
                    })}
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.imageContinaer}>
                    <MediaCarousel media={pageApp.media_links} />
                  </div>
                </Grid>
              </Grid>
            </>
          ) : (
            <Config app={pageApp} />
          )}
        </Paper>
      </div>

      <ConfirmModal
        open={isConfirmationOpen}
        title={"This app will be uninstalled"}
        message={`Uninstall ${pageApp.title} from your store?`}
        confirmBtnText="Uninstall"
        closeBtnText="Cancel"
        onClose={() => setIsConfirmationOpen(false)}
        onConfirm={() => {
          setActionTriggered(true);
          dispatch(unInstallApp(pageApp.slug));
          setIsConfirmationOpen(false);
        }}
      />
      <PaidPopUp
        open={!!planNeeded}
        close={() => setPlanNeeded(null)}
        plan={planNeeded ? planNeeded : BASIC}
        subtxt={planSubTxt}
      />

      <Toast
        open={error.length > 0}
        close={() => setError("")}
        message={error}
        severity="error"
      />
      <Toast
        open={message.length > 0}
        close={() => setMessage("")}
        message={message}
      />
    </div>
  );
}
