// Core react imports
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// Routing imports
import { useParams } from "react-router-dom";
// Component imports
import HeaderBreadcrumbs from "../../components/common/HeaderBreadcrumbs";
import PageLoader from "../../components/Layout/PageLoader";
import Form from "./Form";
import { getCollectionsById } from "./utils";
// Exporting default component
export default function Edit() {
  const params = useParams();
  const collectionId = params.collectionId;
  const collectionStore = useSelector((state) => state.collections);
  const [record, setRecord] = useState(null);

  useEffect(() => {
    let currentCollection = collectionStore.list.filter(
      (collection) => collection.id === collectionId
    )[0];
    if (!currentCollection) {
      getCollectionsById(collectionId).then((res) => {
        setRecord(res);
      });
    } else {
      setRecord(currentCollection);
    }
  }, [collectionId]);

  if (!record) {
    return <PageLoader />;
  }

  return (
    <>
      <HeaderBreadcrumbs
        list={[
          { label: "Collections", link: "/collections" },
          {
            label: `${record.title}`,
          },
        ]}
      />
      <Form mode="edit" record={record} />
    </>
  );
}
