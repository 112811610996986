import React, { useState, useEffect } from "react";
import useStyles from "../styles";
import { ClickAwayListener, Typography } from "@material-ui/core";
import { rowFlexJustifyBetween } from "../../../styles/common";
import Switch from "../../../components/controls/Switch";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import MobileIcon from "../../../icons/MobileIcon";
import DesktopIcon from "../../../icons/DesktopIcon";
import { isWindows } from "react-device-detect";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

const BannerCard = ({
  banner,
  index,
  onEdit,
  onToggle,
  onDelete,
  onPositionChange,
}) => {
  const classes = useStyles();
  const [scale, setScale] = useState(1);
  const [openDropdown, setOpenDropdown] = useState(false);

  useEffect(() => {
    if (isWindows) {
      setScale(window.devicePixelRatio - 0.07);
    }
  }, []);

  const getSlides = () => {
    const slides = [];
    if (banner?.desktop_url) {
      let deskSlide = {
        url: banner.desktop_url,
        icon: <DesktopIcon fill="#000000" height="15px" width="15px" />,
      };
      slides.push(deskSlide);
    }
    if (banner?.url) {
      let mobSlide = {
        url: banner.url,
        icon: <MobileIcon fill="#000000" />,
      };
      slides.push(mobSlide);
    }
    return slides;
  };

  return (
    <div className={classes.bannerCard} style={{ height: `${19 * scale}vw` }}>
      <div>
        <Carousel
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          showIndicators={getSlides().length !== 1}
          swipeable={true}
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            if (isSelected) {
              return (
                <li
                  className={classes.indicator}
                  style={{ background: "#1641DB" }}
                  aria-label={`Selected: ${label} ${index + 1}`}
                  title={`Selected: ${label} ${index + 1}`}
                />
              );
            }
            return (
              <li
                className={classes.indicator}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
                title={`${label} ${index + 1}`}
                aria-label={`${label} ${index + 1}`}
              />
            );
          }}
        >
          {getSlides().map((slide, idx) => (
            <div
              key={idx}
              className={classes.imgRoot}
              style={{
                height: `${15 * scale}vw`,
                cursor: "pointer",
              }}
              onClick={() => {
                onEdit(banner.id);
              }}
            >
              <div className={classes.indexInd}>{index}</div>
              <div className={classes.iconInd}>{slide.icon}</div>
              <img className={classes.img} src={slide.url} alt="" />
            </div>
          ))}
        </Carousel>
      </div>
      <div
        className={rowFlexJustifyBetween}
        style={{
          borderTop: "1px solid #E5E5E5 ",
          padding: "0px 17px ",
          height: `${4 * scale}vw`,
        }}
      >
        <Switch
          label={
            <Typography
              className={classes.switchSubTitle}
              style={{ color: "#1A1A1A" }}
            >
              {banner?.hide ? "Disabled" : "Enabled"}
            </Typography>
          }
          labelPlacement="end"
          value={!banner?.hide}
          onChange={() => onToggle(banner.id, !banner.hide)}
          style={{ marginTop: 6 }}
        />
        <div style={{ display: "flex" }}>
          <ClickAwayListener
            onClickAway={() => {
              setOpenDropdown(false);
            }}
          >
            <div style={{ position: "relative" }}>
              <MoreHorizIcon
                style={{ cursor: "pointer", marginLeft: 15 }}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={() => {
                  setOpenDropdown(!openDropdown);
                }}
              />
              <div
                className={classes.sheet}
                style={{
                  visibility: openDropdown ? "visible" : "hidden",
                  display: "block",
                  width: 95,
                  padding: 4,
                  top: 5,
                  right: -18,
                }}
              >
                <div
                  className={classes.bannerAction}
                  onClick={() => {
                    onPositionChange(banner.id, false);
                    setOpenDropdown(false);
                  }}
                >
                  <span>Move Up</span>
                </div>
                <div
                  className={classes.bannerAction}
                  onClick={() => {
                    onPositionChange(banner.id, true);
                    setOpenDropdown(false);
                  }}
                >
                  <span>Move at Top</span>
                </div>
                <div
                  className={classes.bannerAction}
                  onClick={() => {
                    onEdit(banner.id);
                    setOpenDropdown(false);
                  }}
                >
                  <span>Edit</span>
                </div>
                <div
                  className={classes.bannerAction}
                  onClick={() => {
                    onDelete(banner);
                    setOpenDropdown(false);
                  }}
                >
                  <span>Delete</span>
                </div>
              </div>
            </div>
          </ClickAwayListener>
        </div>
      </div>
    </div>
  );
};

export default BannerCard;
