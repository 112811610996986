import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const OrdersIcon = ({ viewBox = "0 0 24 24", stroke = "#999999" }) => {
  // negative margin to visually center the icon
  return (
    <SvgIcon
      viewBox={viewBox}
      style={{ width: "20px", height: "20px", marginBottom: "-2px" }}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 23C8.10457 23 9 22.1046 9 21C9 19.8954 8.10457 19 7 19C5.89543 19 5 19.8954 5 21C5 22.1046 5.89543 23 7 23Z"
          stroke={stroke}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 1H9V12H22V1Z"
          stroke={stroke}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 5H17"
          stroke={stroke}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18 16L20 23"
          stroke={stroke}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23 16H6L4 1H1"
          stroke={stroke}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default OrdersIcon;
