import { Grid, makeStyles, Typography } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import React from "react";
import StoreLink from "../../../components/common/StoreLink";
import {
  rowFlexJustifyBetween,
  rowFlexCenterAll,
  rowFlexAlignCenter,
} from "../../../styles/common";

const useStyles = makeStyles(() => ({
  overviewCard: {
    border: "1px solid #E6E6E6",
    padding: "8px",
    paddingRight: "28px",
    borderRadius: "12px",
  },
  overviewIcon: {
    width: 56,
    height: 56,
    borderRadius: "8px",
    marginRight: 18,
    backgroundColor: (props) => props.backgroundColor,
  },
  value: {
    fontSize: 20,
    lineHeight: "25px",
    fontWeight: 500,
    marginBottom: 4,
    color: (props) => props?.color ?? "#1A1A1A",
  },
  label: {
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: 400,
    color: "#666666",
  },
}));

const OverviewCard = ({
  icon,
  backgroundColor,
  value,
  label,
  link,
  color,
  iconWidth,
}) => {
  const classes = useStyles({ backgroundColor, color });
  return (
    <Grid item xs={6}>
      <StoreLink href={link} style={{ textDecoration: "none" }}>
        <div className={`${rowFlexJustifyBetween} ${classes.overviewCard}`}>
          <div className={rowFlexAlignCenter}>
            <div className={`${rowFlexCenterAll} ${classes.overviewIcon}`}>
              <img
                src={`https://storage.googleapis.com/shy-pub/_static/app-img/metrics/${icon}.png`}
                alt="icon"
                style={{ width: iconWidth }}
              />
            </div>
            <div>
              <Typography className={classes.value}>{value}</Typography>
              <Typography className={classes.label}>{label}</Typography>
            </div>
          </div>
          <ChevronRight fontSize="small" color="secondary" />
        </div>
      </StoreLink>
    </Grid>
  );
};

export default OverviewCard;
