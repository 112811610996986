import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useStyles from "../components/ConfigStyles";
import PageLoader from "../../../components/Layout/PageLoader";
import { centerContainer, layoutCenterContainer } from "../../../styles/common";
import HeaderBreadcrumbs from "../../../components/common/HeaderBreadcrumbs";
import { Paper, Typography } from "@material-ui/core";
import Controls from "../../../components/controls/Controls";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import Toast from "../../../components/Layout/Toast";
import { fetchApps } from "../../../redux/apps/appActions";

const list = [
  {
    label: "App Store",
    link: "/apps",
  },
  {
    label: "Microsoft Clarity",
    link: "/apps/clarity",
  },
  {
    label: "Configure",
  },
];

export const MicrosoftClarityConfig = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const store = useSelector((state) => state.store.store);
  const storeData = useSelector((state) => state.store);
  const appState = useSelector((state) => state.apps);
  const apps = appState.apps;

  const initialState = {
    scriptTag: {
      value: "",
      touched: false,
      error: false,
    },
  };
  const [formState, setFormState] = useState(initialState);
  const [fetching, setFetching] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (apps.length === 0) {
      dispatch(fetchApps());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (storeData.fetched) {
      init();
    }
    // eslint-disable-next-line
  }, [storeData]);

  const init = async () => {
    setFetching(true);
    try {
      let accessToken = localStorage.getItem("access_token");

      const res = await fetch(
        `${process.env.REACT_APP_API}/api/v1/org/store/${store.slug}/group-names/scripts/attributes/clarity`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const result = await res.json();
      const clarity = result.payload;

      setFormState({
        ...formState,
        scriptTag: {
          ...formState.scriptTag,
          value: clarity.value,
        },
      });

      setFetching(false);
    } catch (error) {
      setFetching(false);
    }
  };

  const handleChange = (event) => {
    let { name, value } = event.target;

    setFormState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        touched: true,
        value: value,
      },
    }));
  };

  const getMessage = (name) => {
    const messages = {
      scriptTag: "Please enter a valid Microsoft Clarity script tag.",
    };
    return messages[name] || "";
  };

  const onBlur = (event) => {
    const { name } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        error: !formState[name].value && getMessage(name),
      },
    }));
  };

  const onFocus = (event) => {
    const { name } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        error: "",
      },
    }));
  };

  const validation = () => {
    let errorFlag = false;
    const updatedFormData = Object.keys(formState).reduce((acc, key) => {
      const trimmedValue = formState[key].value.trim();
      const isEmptyAfterTrim = !trimmedValue;
      errorFlag = errorFlag || isEmptyAfterTrim;
      const updatedField = {
        ...formState[key],
        error: isEmptyAfterTrim && getMessage(key),
      };
      return { ...acc, [key]: updatedField };
    }, {});

    setFormState(updatedFormData);
    return errorFlag;
  };

  const handleOutlined = () => {
    history.goBack();
  };

  const handleSave = async () => {
    if (validation()) {
      return;
    }
    let accessToken = localStorage.getItem("access_token");
    const data = [
      {
        name: "clarity",
        value: formState.scriptTag.value,
      },
    ];
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API}/api/v1/org/store/${store.slug}/group-names/scripts/attributes`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      const result = await res.json();
      if (result) {
        setShowSuccess(true);
      }
    } catch (error) {
      setErrorMessage(error.message);
      setShowError(true);
    }
  };

  if (fetching) return <PageLoader />;

  return (
    <div className={layoutCenterContainer}>
      <div className={centerContainer}>
        <div className={classes.breadcrumbsContainer}>
          <div className={classes.backDiv}>
            <HeaderBreadcrumbs list={list} />
          </div>
        </div>
        <Paper elevation={0} className={classes.paper}>
          <div>
            <Typography className={classes.formHead}>
              Microsoft Clarity Configuration Settings
            </Typography>

            <Controls.Input
              name="scriptTag"
              label="Script Tag"
              labelPlacement="top"
              placeholder="Copy/Paste the Microsoft Clarity Script Tag here"
              multiline
              value={formState.scriptTag.value}
              onChange={handleChange}
              onFocus={onFocus}
              onBlur={onBlur}
              error={formState.scriptTag.error}
            />
          </div>

          <div className={classes.button}>
            <CombinedButtons
              outlinedBtnText="Back"
              outlinedBtnAction={handleOutlined}
              solidBtnAction={handleSave}
            />
          </div>
        </Paper>
        <Toast
          message={"Script tag saved successfully"}
          open={showSuccess}
          close={() => setShowSuccess(false)}
          autoClose
        />
        <Toast
          message={errorMessage ? errorMessage : "Something went wrong"}
          open={showError}
          close={() => setShowError(false)}
          severity="error"
          autoClose
        />
      </div>
    </div>
  );
};
