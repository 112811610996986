import React from "react";
import CombinedButtons from "../../../../../../components/buttons/CombinedButtons";
import { makeStyles } from "@material-ui/core";
import { useHandleSave } from "./hooks/useHandleSave";
import Toast from "../../../../../../components/Layout/Toast";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

const Saving = () => {
  const classes = useStyles();

  const {
    handleSave,
    showSuccess,
    showError,
    loading,
    setShowSuccess,
    setShowError,
  } = useHandleSave();

  return (
    <div className={classes.root}>
      <CombinedButtons
        loading={loading}
        outlinedBtnAction={() => {
          window.history.back();
        }}
        solidBtnAction={handleSave}
        solidBtnText="Save"
      />
      <Toast
        autoClose
        message={"Configuration saved successfully."}
        open={showSuccess}
        close={() => setShowSuccess(false)}
      />
      <Toast
        autoClose
        message={"Failed to save configrations! plz try again"}
        open={showError}
        close={() => setShowError(false)}
        severity="error"
      />
    </div>
  );
};

export default Saving;
