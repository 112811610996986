// Core react imports
import React from "react";
// Component imports
import HeaderBreadcrumbs from "../../components/common/HeaderBreadcrumbs";
import Form from "./Form";
// Exporting default component
export default function Create() {
  return (
    <>
      <HeaderBreadcrumbs
        list={[
          { label: "Collections", link: "/collections" },
          {
            label: "Create",
          },
        ]}
      />
      <Form mode="create" />
    </>
  );
}
