import React from "react";
import ShoopyHeader from "../../components/Layout/ShoopyHeader";
import RenewConfirmation from "./components/RenewConfirmation";
import { useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { NavigateNext } from "@material-ui/icons";
import StoreLink from "../../components/common/StoreLink";
import {
  layoutBodyBox,
  layoutContainer,
} from "../../styles/common";

const RenewConfirmPage = () => {
  const params = useParams();
  return (
    <>
      {isMobile ? (
        <>
          <ShoopyHeader />
          <RenewConfirmation
            storeId={params.storeId}
            orderId={params.orderId}
          />
        </>
      ) : (
        <div className={layoutContainer}>
          <div style={{ marginBottom: 20 }}>
            <Breadcrumbs
              style={{ fontSize: 15 }}
              separator={<NavigateNext style={{ fontSize: "1.05rem" }} />}
            >
              <StoreLink
                underline="hover"
                color="inherit"
                href="/billing/plans"
              >
                Billing
              </StoreLink>
              <Typography
                style={{ fontSize: 15, textTransform: "capitalize" }}
                color="secondary"
              >
                Renew Confirmation
              </Typography>
            </Breadcrumbs>
          </div>
          <div className={layoutBodyBox} style={{ maxWidth: 640 }}>
            <RenewConfirmation
              storeId={params.storeId}
              orderId={params.orderId}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default RenewConfirmPage;
