import { CLEAR_DATA, CLEAR_COMPLETE } from "../commom/commonTypes";
import {
  ADD_BANNER,
  UPDATE_BANNER,
  DELETE_BANNER,
  FETCH_BANNERS,
  SET_BANNER_ERROR,
  BANNER_PROGRESS,
  SET_BANNER_LOADER,
  SET_SUCCESS_TOAST,
  POSITION_MOVE_UP,
  POSITION_MOVE_TOP,
  CLEAR_BAANNER_ERROR,
} from "./bannerTypes";

const initialState = {
  banners: [],
  progress: true,
  error: null,
  loader: false,
  successToast: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case BANNER_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      };
    case SET_BANNER_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case FETCH_BANNERS:
      return {
        ...state,
        banners: action.payload,
        progress: false,
      };
    case ADD_BANNER:
      return {
        ...state,
        banners: [action.payload, ...state.banners],
        loader: false,
        successToast: true,
        message: "Banner Added Successfully",
      };
    case UPDATE_BANNER:
      let modBanners = state.banners.map((ban) => {
        if (ban.id === action.payload.id) {
          return action.payload;
        }
        return ban;
      });
      return {
        ...state,
        banners: modBanners,
        loader: false,
        successToast: true,
        message: "Banner Updated Successfully",
      };
    case DELETE_BANNER:
      return {
        ...state,
        banners: state.banners.filter(
          (banner) => banner.id !== action.payload.id
        ),
        loader: false,
        successToast: true,
        message: "Banner Deleted Successfully",
      };
    case POSITION_MOVE_UP:
      let clonedBanners = [...state.banners];
      let index = clonedBanners.findIndex((e) => e.id === action.payload.id);
      if (index > 0) {
        let el = clonedBanners[index];
        clonedBanners[index] = clonedBanners[index - 1];
        clonedBanners[index - 1] = el;
      }
      return {
        ...state,
        banners: clonedBanners,
        loader: false,
        successToast: true,
        message: "Banner Position Changed Successfully",
      };
    case POSITION_MOVE_TOP:
      const moveTop = state.banners.sort((x, y) => {
        return x.id === action.payload.id
          ? -1
          : y.code === action.payload.id
          ? 1
          : 0;
      });
      return {
        ...state,
        banners: moveTop,
        loader: false,
        successToast: true,
        message: "Banner Position Changed Successfully",
      };
    case SET_BANNER_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loader: false,
      };
    case CLEAR_BAANNER_ERROR:
      return {
        ...state,
        error: null,
      };
    case SET_SUCCESS_TOAST:
      return {
        ...state,
        successToast: action.payload,
      };
    case CLEAR_COMPLETE:
    case CLEAR_DATA:
      return {
        ...state,
        banners: [],
        progress: false,
        error: null,
        loader: false,
        successToast: false,
      };
    default:
      return state;
  }
};
export default reducer;
