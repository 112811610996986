// Core react imports
import React, { useState } from "react";
// Util imports
import { collectionsWithImage } from "../utils";
import { useHistory } from "react-router-dom";
// Importing styles
import {
  cardContainer,
  cardTitle,
  cardDescription,
  cardBody,
  cardControls,
  cardImage,
  numProducts,
  switchLabel,
  collectionAction,
  sheet,
  nopicctn,
} from "./style";
import {
  rowFlexAlignStart,
  colFlexJustifyBetween,
  rowFlexAlignCenter,
} from "../../../styles/common";
// Component imports
import Switch from "../../../components/controls/Switch";
import { Typography, ClickAwayListener } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { NoPicIcon } from "../../../icons";
// Icons
const Mobile = () => (
  <svg
    width="10"
    height="17"
    viewBox="0 0 10 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginRight: "6px" }}
  >
    <path
      d="M1.90789 0C0.855263 0 0 0.838815 0 1.8712V14.1288C0 15.1612 0.855263 16 1.90789 16H8.09211C9.14474 16 10 15.1612 10 14.1288V1.8712C10 0.838815 9.14474 0 8.09211 0H1.90789ZM1.90789 0.516194H8.09211C8.85421 0.516194 9.47368 1.12375 9.47368 1.8712V12.3887H0.526316V1.8712C0.526316 1.12272 1.14579 0.516194 1.90789 0.516194ZM3.93092 2.08696V2.60315H6.05263V2.08696H3.93092ZM0.526316 12.9049H9.47369V14.1308C9.47369 14.8783 8.85421 15.4858 8.09211 15.4858H1.90789C1.14579 15.4858 0.526316 14.8783 0.526316 14.1308V12.9049Z"
      fill="#999999"
    />
  </svg>
);

const Desktop = () => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginRight: "6px" }}
  >
    <path
      d="M2.4541 14.0908L16.9996 14.0908C17.8029 14.0908 18.4541 13.4396 18.4541 12.6363V2.45446C18.4541 1.65113 17.8029 0.99991 16.9996 0.99991L2.4541 0.99991C1.65078 0.99991 0.999556 1.65113 0.999556 2.45446V12.6363C0.999556 13.4396 1.65078 14.0908 2.4541 14.0908Z"
      stroke="#999999"
      strokeWidth="0.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6365 17H6.81836"
      stroke="#999999"
      strokeWidth="0.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const Product = () => (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginRight: "6px", marginTop: "-2px" }}
  >
    <path
      d="M1 4.7168H17"
      stroke="#999999"
      strokeWidth="0.7"
      strokeMiterlimit="10"
    />
    <path
      d="M17 4.63636L12.6364 1H5.36364L1 4.63636V17H17V4.63636Z"
      stroke="#999999"
      strokeWidth="0.7"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M6.67969 4.83984V9.72731H11.0433V4.83984"
      stroke="#999999"
      strokeWidth="0.7"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);
// Exporting card component
export default function CollectionCard({
  collection,
  onDeleteCollection,
  onToggleCollection,
  onMoveAbove,
  onMoveToTop,
}) {
  const history = useHistory();
  const [openDropdown, setOpenDropdown] = useState(false);
  const renderCardBody = () => {
    const collectionHasImage =
      collectionsWithImage.indexOf(collection.type) !== -1;
    if (collectionHasImage) {
      return (
        <div className={rowFlexAlignStart}>
          {collection.mobile_image ? (
            <img src={collection.mobile_image} className={cardImage} alt="" />
          ) : (
            <div style={{ marginRight: "16px" }}>
              <span className={nopicctn}>
                <NoPicIcon />
              </span>
            </div>
          )}
          <div
            className={colFlexJustifyBetween}
            style={{ flexBasis: "75%", height: "80px" }}
          >
            <p className={cardTitle}>{collection.title}</p>
            <p className={cardDescription}>{collection.description} </p>
            <div className={numProducts}>
              <div className={rowFlexAlignCenter}>
                <Product /> {collection.criterion.super_prod_skus.length}{" "}
                Products
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className={colFlexJustifyBetween} style={{ height: "80px" }}>
        <p className={cardTitle}>{collection.title}</p>
        <p className={cardDescription}>{collection.description} </p>
        <div className={numProducts}>
          <div className={rowFlexAlignCenter} style={{ marginRight: "14px" }}>
            <Mobile /> {collection.cols_in_mobile} Cols
          </div>
          <div className={rowFlexAlignCenter} style={{ marginRight: "14px" }}>
            <Desktop />
            {collection.cols_in_desktop} Cols
          </div>
          <div className={rowFlexAlignCenter} style={{ marginRight: "14px" }}>
            <Product />
            {collection.total_elements} Products
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className={cardContainer}>
      <div
        className={cardBody}
        onClick={() => {
          history.push(`./collections/${collection.id}`);
        }}
      >
        {renderCardBody()}
      </div>
      <div className={cardControls}>
        <Switch
          label={
            <Typography className={switchLabel} style={{ color: "#1A1A1A" }}>
              {collection.active ? "Enabled" : "Disabled"}
            </Typography>
          }
          labelPlacement="end"
          value={collection.active}
          onChange={() => {
            onToggleCollection(collection.id, !collection.active);
          }}
          style={{
            marginTop: "0px",
            marginRight: "0px",
            marginLeft: "0px",
            marginBottom: "0px",
          }}
        />
        <div style={{ display: "flex" }}>
          <ClickAwayListener
            onClickAway={() => {
              setOpenDropdown(false);
            }}
          >
            <div style={{ position: "relative" }}>
              <MoreHorizIcon
                style={{ cursor: "pointer", marginLeft: 15 }}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={() => {
                  setOpenDropdown(!openDropdown);
                }}
              />
              <div
                className={sheet}
                style={{
                  visibility: openDropdown ? "visible" : "hidden",
                  display: "block",
                  width: 95,
                  padding: 4,
                  top: 5,
                  right: -18,
                }}
              >
                <div
                  className={collectionAction}
                  onClick={() => {
                    onMoveAbove();
                    setOpenDropdown(!openDropdown);
                  }}
                >
                  <span>Move Up</span>
                </div>
                <div
                  className={collectionAction}
                  onClick={() => {
                    onMoveToTop();
                    setOpenDropdown(!openDropdown);
                  }}
                >
                  <span>Move at Top</span>
                </div>
                <div
                  className={collectionAction}
                  onClick={() => {
                    history.push(`./collections/${collection.id}`);
                  }}
                >
                  <span>Edit</span>
                </div>
                <div
                  className={collectionAction}
                  onClick={() => {
                    onDeleteCollection(collection.id);
                    setOpenDropdown(!openDropdown);
                  }}
                >
                  <span>Delete</span>
                </div>
              </div>
            </div>
          </ClickAwayListener>
        </div>
      </div>
    </div>
  );
}
