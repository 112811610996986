import { CLEAR_DATA, CLEAR_COMPLETE } from "../commom/commonTypes";
import {
  FETCH_INVOICES,
  INVOICE_PROGRESS,
  UPDATE_INVOICE,
  SET_INVOICE_ERROR,
  SET_INVOICE_LOADER,
  CREATE_INVOICE,
  CLEAR_INVOICE,
  CLEAR_COMPLETE_INVOICE,
  DELETE_INVOICE,
  INVOICE_DATE_SELECTOR,
  INVOICE_STATUS_SELECTOR,
  INVOICE_DATE_RANGE,
  SET_INVOICES_PER_PAGE,
  SET_INVOICES_CURRENT_PAGE,
  SET_INVOICES_MAX_PAGE_SO_FAR,
  SET_INVOICES_INPUT_KEYWORD,
  SET_INVOICES_SEARCH_KEYWORD,
  SET_INVOICES_CUSTOMER_ID,
} from "./invoiceTypes";

const initialState = {
  invoices: [],
  invoicesMap: {},
  invoice: null,
  numberOfPages: 0,
  numberOfElements: 0,
  progress: true,
  loader: false,
  error: null,
  dateSelector: null,
  statusSelector: null,
  dateRange: {
    aoe: null,
    boe: null,
  },
  invoicesPerPage: null,
  currentPage: 0,
  maxPageSoFar: 0,
  searchKeyword: "",
  inputKeyword: "",
  customerId: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INVOICE_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      };
    case FETCH_INVOICES:
      let allInvoices = state.invoices;
      let page = action.payload.pageable.pageNumber;
      if (page > 0 && state.invoices.length > action.invoicesPerPage * page) {
        allInvoices = state.invoices.slice(0, action.invoicesPerPage * page);
      }
      let newInvoices =
        action.payload.pageable.pageNumber > 0
          ? [...allInvoices, ...action.payload.content]
          : action.payload.content;

      let newInvoicesMap = {};
      newInvoices.forEach((element) => {
        newInvoicesMap[element.invoice_id] = element;
      });
      return {
        ...state,
        invoices: newInvoices,
        invoicesMap: newInvoicesMap,
        numberOfPages: action.payload.totalPages,
        numberOfElements: action.payload.totalElements,
        progress: false,
      };
    case CREATE_INVOICE:
      let pageCnt = state.numberOfPages;
      let localPageInvoices = state.invoices;
      let count = state.invoices.length;
      if (count + 1 > state.invoicesPerPage) {
        let fullPages = Math.floor((count + 1) / state.invoicesPerPage);
        localPageInvoices = state.invoices.slice(
          0,
          fullPages * state.invoicesPerPage - 1
        );
      }
      let availablePages = Math.floor(
        state.invoices.length / state.invoicesPerPage
      );
      return {
        ...state,
        invoice: action.payload,
        loader: false,
        progress: false,
        invoices: [action.payload, ...localPageInvoices],
        numberOfPages:
          state.numberOfElements === pageCnt * state.invoicesPerPage
            ? pageCnt + 1
            : pageCnt,
        numberOfElements: state.numberOfElements + 1,
        currentPage: 0,
        maxPageSoFar: availablePages > 1 ? availablePages - 1 : 0,
      };
    case UPDATE_INVOICE:
      let modInvoices = state.invoices.map((inv) => {
        if (inv.id === action.payload.id) {
          return action.payload;
        }
        return inv;
      });

      let modInvoicesMap = {};
      modInvoices.forEach((element) => {
        modInvoicesMap[element.invoice_id] = element;
      });

      return {
        ...state,
        invoices: modInvoices,
        invoicesMap: modInvoicesMap,
        invoice: action.payload,
        loader: false,
        progress: false,
      };

    case SET_INVOICE_ERROR:
      return {
        ...state,
        error: action.payload.error,
        progress: false,
      };
    case SET_INVOICE_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case CLEAR_INVOICE:
      return {
        ...state,
        invoice: action.payload,
      };
    case DELETE_INVOICE:
      return {
        ...state,
        invoices: state.invoices.filter(
          (invoice) => invoice.id !== action.payload.invoiceId
        ),
        loader: false,
      };
    case CLEAR_COMPLETE_INVOICE:
      return {
        ...state,
        invoices: [],
        invoicesMap: {},
        invoice: null,
        numberOfPages: 0,
        numberOfElements: 0,
        progress: false,
        loader: false,
        error: null,
      };
    case CLEAR_COMPLETE:
    case CLEAR_DATA:
      return {
        ...state,
        invoices: [],
        invoicesMap: {},
        invoice: null,
        numberOfPages: 0,
        numberOfElements: 0,
        progress: false,
        loader: false,
        error: null,
        dateSelector: null,
        statusSelector: null,
        dateRange: {
          aoe: null,
          boe: null,
        },
        invoicesPerPage: null,
        currentPage: 0,
        maxPageSoFar: 0,
        searchKeyword: "",
        inputKeyword: "",
      };
    case INVOICE_DATE_SELECTOR:
      return {
        ...state,
        dateSelector: action.payload,
      };
    case INVOICE_STATUS_SELECTOR:
      return {
        ...state,
        statusSelector: action.payload,
      };
    case INVOICE_DATE_RANGE:
      return {
        ...state,
        dateRange: action.payload,
      };
    case SET_INVOICES_PER_PAGE:
      return {
        ...state,
        invoicesPerPage: action.payload,
      };
    case SET_INVOICES_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SET_INVOICES_MAX_PAGE_SO_FAR:
      return {
        ...state,
        maxPageSoFar: action.payload,
      };
    case SET_INVOICES_INPUT_KEYWORD:
      return {
        ...state,
        inputKeyword: action.payload,
      };
    case SET_INVOICES_SEARCH_KEYWORD:
      return {
        ...state,
        searchKeyword: action.payload,
      };
    case SET_INVOICES_CUSTOMER_ID:
      return {
        ...state,
        customerId: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
