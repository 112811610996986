import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CustomersIcon = ({ viewBox = "0 0 20 20", stroke = "#999999" }) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "20px", height: "20px" }}>
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.625 5C5.625 3.96447 4.78553 3.125 3.75 3.125C2.71447 3.125 1.875 3.96447 1.875 5C1.875 6.03553 2.71447 6.875 3.75 6.875C4.78553 6.875 5.625 6.03553 5.625 5Z"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.375 18.125H1.875V14.375H0.625V10.625C0.625 10.2935 0.756696 9.97554 0.991117 9.74112C1.22554 9.5067 1.54348 9.375 1.875 9.375H3.125"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.375 5C14.375 6.03553 15.2145 6.875 16.25 6.875C17.2855 6.875 18.125 6.03553 18.125 5C18.125 3.96447 17.2855 3.125 16.25 3.125C15.2145 3.125 14.375 3.96447 14.375 5Z"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.625 18.125H18.125V14.375H19.375V10.625C19.375 10.2935 19.2433 9.97554 19.0089 9.74112C18.7745 9.5067 18.4565 9.375 18.125 9.375H16.875"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5 3.125C12.5 1.74429 11.3807 0.625 10 0.625C8.61929 0.625 7.5 1.74429 7.5 3.125C7.5 4.50571 8.61929 5.625 10 5.625C11.3807 5.625 12.5 4.50571 12.5 3.125Z"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.125 19.375H6.875V14.375H5.625V10.625C5.625 9.96196 5.88839 9.32607 6.35723 8.85723C6.82607 8.38839 7.46196 8.125 8.125 8.125H11.875C12.538 8.125 13.1739 8.38839 13.6428 8.85723C14.1116 9.32607 14.375 9.96196 14.375 10.625V14.375H13.125V19.375Z"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default CustomersIcon;
