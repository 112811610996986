import { Divider, Typography } from "@material-ui/core";
import React from "react";
import useStyles from "../styles";
import {
  rowFlexAlignCenter,
  rowFlexJustifyBetween,
} from "../../../../../styles/common";
import ProductNoPicIcon from "../../../../../icons/NoPicIcon";
import SubcriptionIcon from "../../../../../icons/SubcriptionIcon";
import DeliveryIcon from "../../../../../icons/DeliveryIcon";

const SubscriptionDetails = ({ contractDetails }) => {
  const classes = useStyles();

  const Discount =
    contractDetails.subscription_frequency.discount_type === "PERCENTAGE"
      ? "%"
      : "₹";
  return (
    <>
      <div className={rowFlexJustifyBetween}>
        <Typography className={classes.heading} style={{ marginBottom: 0 }}>
          Subscription
        </Typography>
      </div>

      <div style={{ marginTop: 16, display: "flex", alignItems: "center" }}>
        {false ? (
          <img className={classes.prodimage} alt="Thumbnail" />
        ) : (
          <div className={classes.nopicctn}>
            <ProductNoPicIcon />
          </div>
        )}
        <div style={{ width: "84%", marginLeft: 14 }}>
          <Typography className={classes.heading}>
            {contractDetails.product_name}
          </Typography>
          <div className={classes.flexBox}>
            <div>
              <Typography
                className={classes.subHeading}
                style={{
                  fontWeight: "normal",
                  marginTop: 8,
                  color: "#666666",
                  paddingBottom: 5,
                }}
              >
                One time purchase price <b>₹448</b>
              </Typography>
            </div>
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <Typography
                className={classes.subHeading}
                style={{
                  fontWeight: "normal",
                  marginTop: 8,
                  color: "#666666",
                  paddingBottom: 5,
                }}
              >
                MRP:{" "}
                <b>
                  ₹448 x 1{""}
                  {""} ₹448
                </b>
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <Divider style={{ margin: "20px 0px" }} />
      <div className={rowFlexAlignCenter} style={{ gap: 12 }}>
        <div className={rowFlexAlignCenter}>
          <Typography className={classes.subHeading}>
            <DeliveryIcon /> Delivery:{" "}
            <b style={{ color: "#000" }}>
              {" "}
              {contractDetails.subscription_frequency.interval
                .charAt(0)
                .toUpperCase() +
                contractDetails.subscription_frequency.interval
                  .slice(1)
                  .toLowerCase()}
            </b>
          </Typography>
        </div>
        <div className={rowFlexAlignCenter}>
          <Typography className={classes.subHeading}>
            <SubcriptionIcon color={"#1641DB"} /> Discount:
            <b style={{ color: "#000" }}> 30 {Discount} off</b>
          </Typography>
        </div>
      </div>
    </>
  );
};

export default SubscriptionDetails;
