import React from "react";
import { useHistory } from "react-router-dom";
import CustomerForm from "./CustomerForm";

const AddCustomer = () => {
  const history = useHistory();

  const onClose = () => {
    history.goBack();
  };
  const onSave = () => {
    history.goBack();
  };

  return <CustomerForm record={null} onClose={onClose} onSave={onSave} />;
};

export default AddCustomer;
