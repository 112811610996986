export const getFinalSalePriceWTax = (item, forOne) => {
  let salePrice = item.sale_price;
  if (!salePrice) {
    return 0;
  }
  let newSalePrice = salePrice;
  let totalTaxInPer = item.total_tax_in_per ? item.total_tax_in_per : 0;
  let spTaxIncluded = item.sp_tax_included;
  let fixedDiscount = item.fixed_discount ? item.fixed_discount : 0;
  if (totalTaxInPer > 0) {
    if (!spTaxIncluded) {
      newSalePrice = newSalePrice * ((100 + totalTaxInPer) / 100);
    }
  }
  newSalePrice = newSalePrice - fixedDiscount;
  return forOne ? newSalePrice : newSalePrice * item.qty;
};

const getFinalSalePriceWOTax = (item, forOne) => {
  let newSalePrice = getFinalSalePriceWTax(item, true);
  let totalTaxInPer = item.total_tax_in_per ? item.total_tax_in_per : 0;
  if (totalTaxInPer > 0) {
    newSalePrice = newSalePrice * (100 / (100 + totalTaxInPer));
  }
  return forOne ? newSalePrice : newSalePrice * item.qty;
};

export const updateCalculation = (order, updateItemDisc, paymentAmount) => {
  let items = order.order_items;
  if (!items || items.length === 0) {
    return {
      ...order,
      sale_price: 0,
      tax_amount: 0,
      final_sale_price: 0,
      due_amount: 0,
      order_discount: 0,
      packaging_charge: 0,
      shipping_charge: 0,
      coupon_discount: 0,
      round_value: 0,
      payment_charge: 0,
      payment_discount: 0,
      total_discount: 0,
    };
  }
  let tax = 0;
  let salePrice = 0;
  let itemsDiscount = 0;
  let mrp = 0;
  items.forEach((it) => {
    salePrice += getFinalSalePriceWTax(it, false);
    tax += getFinalSalePriceWTax(it, false) - getFinalSalePriceWOTax(it, false);
    let itMrp = it.valid_mrp ? it.valid_mrp : it.mrp;
    mrp += itMrp;
    if (updateItemDisc) {
      it.disc = Math.round((itMrp - it.sale_price) * 100) / 100;
      let discPercent = 0;
      if (itMrp > 0) {
        discPercent = (it.disc * 100) / itMrp;
        discPercent = Math.round(discPercent * 100) / 100;
      }
      it.disc_percent = discPercent;
    }
    itemsDiscount += it.disc * it.qty;
  });
  let total =
    salePrice +
    order.packaging_charge +
    order.payment_charge +
    order.shipping_charge -
    order.round_value -
    order.coupon_discount -
    order.payment_discount -
    order.order_discount;
  order.tax_amount = tax;
  order.sale_price = salePrice;
  order.mrp = mrp;
  order.total_discount =
    order.order_discount +
    itemsDiscount +
    order.round_value +
    order.coupon_discount +
    order.payment_discount;
  order.due_amount = total - (paymentAmount ? paymentAmount : 0); //Payment amount
  order.final_sale_price = total;
  return order;
};

export const getItemTax = (salePrice, quantity, taxPercent, spTaxIncluded) => {
  let itemTax = 0,
    perItemTax = 0;
  if (spTaxIncluded) {
    if (taxPercent) {
      perItemTax = salePrice * (taxPercent / (100 + taxPercent));
    }
    itemTax = perItemTax * quantity;
  } else {
    if (taxPercent) {
      perItemTax = (salePrice * taxPercent) / 100;
    }
    itemTax = perItemTax * quantity;
  }
  return itemTax;
};

export const createItemFromProduct = (product, type) => {
  let imgs = product.images;
  let imgUrl = null;
  if (imgs && imgs.length > 0) {
    imgUrl = imgs[0];
  }
  let item = {
    id: null,
    sku: product.sku,
    extra_fields: product.extra_fields,
    is_multi_barcode: product.is_multi_barcode,
    pp_tax_included: product.pp_tax_included,
    image: imgUrl,
    thumbnail: imgUrl,
    measuring_unit: product.measuring_unit,
    tax_group: product.tax_group,
    sale_price:
      type === "PURCHASE_ORDER" ? product.purchase_price : product.sale_price,
    barcode: product.barcode ? product.barcode : null,
    mrp: product.mrp,
    name: product.name,
    display_name: product.display_name,
    sp_tax_included: type === "PURCHASE_ORDER" ? product.pp_tax_included : product.sp_tax_included,
    brand: product.brand ? product.brand : null,
    purchase_price: product.purchase_price,
  };
  return item;
};

export const createInvoiceItem = (item, quantity) => {
  let taxDtls = {
    tax_group_id: "",
    tax_group_name: "",
    total: 0,
    cgst: 0,
    igst: 0,
    sgst: 0,
    utgst: 0,
    cess: 0,
  };
  if (item.tax_group) {
    let taxGrp = item.tax_group;
    taxDtls = {
      total: taxGrp.total,
      tax_group_id: taxGrp.id,
      tax_group_name: taxGrp.name,
      cgst: taxGrp.cgst,
      sgst: taxGrp.sgst,
      igst: taxGrp.igst,
      cess: taxGrp.cess,
      utgst: taxGrp.utgst,
    };
  }
  let totalTaxInPer = taxDtls.total;
  let mrp = item.mrp;
  let salePriceWTax = item.sale_price;
  let fixedDiscount = item.fixed_discount ? item.fixed_discount : 0;
  let spTaxIncluded = item.sp_tax_included;
  if (totalTaxInPer > 0 && !spTaxIncluded) {
    salePriceWTax = salePriceWTax * ((100 + totalTaxInPer) / 100);
  }
  salePriceWTax = salePriceWTax - fixedDiscount;
  let discount = item.mrp - item.sale_price;
  let discPercent = 0;
  if (item.mrp > 0) {
    discPercent = (discount * 100) / item.mrp;
    discPercent = Math.round(discPercent * 100) / 100;
  }
  return {
    id: item.id,
    display_name: item.display_name,
    name: item.name,
    image: item.image,
    thumbnail: item.thumbnail,
    brand: item.brand,
    qty: quantity,
    sku: item.sku,
    sale_price: Math.round(item.sale_price * 100) / 100,
    sale_price_wtax: Math.round(salePriceWTax * 100) / 100,
    mrp: mrp,
    purchase_price: item.purchase_price,
    disc: Math.round(discount * 100) / 100,
    disc_percent: Math.round(discPercent * 100) / 100,
    tax_group: item.tax_group,
    tax: taxDtls.total,
    total_tax_in_per: taxDtls.total,
    tax_group_name: taxDtls.tax_group_name,
    tax_group_id: taxDtls.tax_group_id,
    sp_tax_included: item.sp_tax_included,
    pp_tax_included: item.pp_tax_included,
    cgst: taxDtls.cgst,
    igst: taxDtls.igst,
    sgst: taxDtls.sgst,
    cess: taxDtls.cess,
    utgst: taxDtls.utgst,
    barcode: item.barcode,
    measuring_unit: item.measuring_unit,
    fixed_discount: 0,
  };
};

export const validateItem = (item) => {
  let itemErr = {};
  let salePriceWTax = item.sale_price_wtax;
  let mrp = item.mrp;
  let salePrice = item.sale_price;
  itemErr.mrp = mrp < 0 || salePriceWTax > mrp;
  itemErr.disc = item.disc < 0;
  itemErr.disc_percent = item.disc_percent < 0;
  itemErr.qty = item.qty <= 0;
  itemErr.sale_price = salePrice < 0 || salePrice > mrp;
  return itemErr;
};

export const initializeRecordValues = (record, taxGroups, payments) => {
  let clonedRec = { ...record };
  let groupMap = taxGroups.reduce(function (map, obj) {
    map[obj.total] = obj;
    return map;
  }, {});
  if (record.order_items && record.order_items.length > 0) {
    clonedRec.order_items = record.order_items.map((oi) => {
      let item = { ...oi };
      if (groupMap[item.total_tax_in_per]) {
        item.tax_group = groupMap[item.total_tax_in_per];
      }
      return item;
    });
  }
  return updateCalculation(
    clonedRec,
    true,
    record.final_sale_price - record.due_amount
  );
};

export const createNewRecord = (orgId, storeId, state, status, type) => {
  return {
    invoice_id: "",
    last_payment_date: null,
    invoice_date: null,
    amount_received: 0,
    cart_type: "STORE",
    customer_id: 0,
    delivery_mode: "DELIVER",
    display_id: null,
    due_amount: 0,
    order_discount: 0,
    has_items: 1,
    mrp: 0,
    order_address: null,
    order_items: [],
    order_payment: null,
    org_customer_id: 0,
    org_customer_mobile: "",
    org_customer_name: "",
    org_id: orgId,
    packaging_charge: 0,
    payment_due_date: null,
    payment_mode: "COD",
    payment_type: "CASH",
    round_value: 0,
    sale_price: 0,
    final_price: 0,
    shipping_charge: 0,
    state: state,
    status: status,
    term: null,
    user_id: null,
    is_amount_only: 0,
    store_id: storeId,
    tax_amount: 0,
    coupon_discount: 0,
    payment_charge: 0,
    payment_discount: 0,
    type: type,
    version: "V3",
  };
};
