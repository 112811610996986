import axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import FormModal from "../../../components/common/FormModal";
import Input from "../../../components/controls/Input";
import { UPDATE_ORDER } from "../../../redux/orders/orderTypes";

const DeclineReturnModal = ({
  openDeclineModal,
  setOpenDeclineModal,
  order,
  setToast,
  setMessage,
  setAnchoredOrder,
}) => {
  const [declineMessage, setDeclineMessage] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("access_token");

  const onDeclineRequestConfirmed = () => {
    if (!!!declineMessage.trim()) {
      setError(true);
      return;
    }
    setLoading(true);

    axios
      .post(
        `${process.env.REACT_APP_API}/api/v1/store/${order.store_id}/orders/${order.id}/return-requests`,
        {
          return_status: "RETURN_REQUEST_REJECTED",
          orderId: order.id,
          storeId: order.store_id,
          message: declineMessage,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then(() => {
        setAnchoredOrder({
          ...order,
          status: "RETURN_REQUEST_REJECTED",
        });
        dispatch({
          type: UPDATE_ORDER,
          payload: {
            ...order,
            status: "RETURN_REQUEST_REJECTED",
          },
        });
        setOpenDeclineModal(false);
        setMessage("Return Request Declined");
        setToast(true);
        setLoading(false);
      });
  };
  return (
    <FormModal
      open={openDeclineModal}
      onClose={() => {
        setOpenDeclineModal(false);
        setDeclineMessage("");
        setError(false);
      }}
      heading="Reason for Decilne"
      onSave={onDeclineRequestConfirmed}
      btnTxt="Decline"
      loading={loading}
    >
      <Input
        labelPlacement="top"
        multiline
        rows={4}
        value={declineMessage}
        onChange={(e) => setDeclineMessage(e.target.value)}
        onBlur={() => setError(!!!declineMessage.trim())}
        onFocus={() => setError(false)}
        error={error ? "Please enter the reason of decline" : ""}
      />
    </FormModal>
  );
};

export default DeclineReturnModal;
