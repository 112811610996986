import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const SettingsIcon = ({ viewBox = "0 0 20 20", stroke = "#999999" }) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "20px", height: "20px" }}>
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10 13.125C11.7259 13.125 13.125 11.7259 13.125 10C13.125 8.27411 11.7259 6.875 10 6.875C8.27411 6.875 6.875 8.27411 6.875 10C6.875 11.7259 8.27411 13.125 10 13.125Z"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.8751 10C16.8751 9.41875 16.7951 8.85875 16.6601 8.32L19.0563 6.93625L17.1813 3.68875L14.7876 5.07125C13.9826 4.28875 12.9863 3.705 11.8751 3.39125V0.625H8.1251V3.39C7.0151 3.705 6.01885 4.28875 5.2126 5.07125L2.81885 3.68875L0.943848 6.93625L3.3401 8.32C3.2051 8.85875 3.1251 9.41875 3.1251 10C3.1251 10.58 3.2051 11.1412 3.3401 11.68L0.943848 13.0638L2.81885 16.3112L5.21385 14.9287C6.01885 15.7112 7.0151 16.295 8.12635 16.6087V19.375H11.8763V16.61C12.9876 16.295 13.9826 15.7113 14.7888 14.93L17.1838 16.3125L19.0588 13.065L16.6626 11.6813C16.7951 11.1413 16.8751 10.58 16.8751 10Z"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default SettingsIcon;
