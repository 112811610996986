import React from "react";
import { SvgIcon } from "@material-ui/core";

const DiamondIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <path
      d="M19.9992 2.2917L17.9491 1.95834L17.6143 0L17.3214 2.00002L15.3967 2.2917L17.3214 2.58334L17.6143 4.58336L17.9491 2.62502L19.9992 2.2917Z"
      fill="#E40046"
    />
    <path
      d="M3.43073 0L3.34707 0.791678L2.51025 0.916678L3.30521 1.04168L3.43073 1.83332L3.59811 1.04168L4.39311 0.916678L3.55626 0.791678L3.43073 0Z"
      fill="#E40046"
    />
    <path
      d="M2.30159 2.54192L2.0924 1.12524L1.8832 2.54192L0.502441 2.75024L1.8832 3.00024L2.0924 4.41691L2.30159 3.00024L3.76606 2.75024L2.30159 2.54192Z"
      fill="#EE4971"
    />
    <path
      d="M13.1381 8.41705L10.5858 3.58368H9.5398L6.69458 8.41705H13.1381Z"
      fill="#FF3C78"
    />
    <path
      d="M16.4019 3.58368H10.5859L13.1383 8.41705L16.4019 3.58368Z"
      fill="#FFA3CF"
    />
    <path
      d="M19.9172 8.41705L16.4444 3.58368H16.4025L13.1389 8.41705H19.9172Z"
      fill="#FF3C78"
    />
    <path d="M9.95818 20L13.1381 8.41669H6.69458L9.95818 20Z" fill="#D6076A" />
    <path
      d="M9.54024 3.33344H3.51514L6.69503 8.1668L9.54024 3.33344Z"
      fill="#FFA3CF"
    />
    <path
      d="M3.51465 3.58368H3.4728L0 8.41705H6.69455L3.51465 3.58368Z"
      fill="#FF3C78"
    />
    <path
      d="M19.9164 8.16669L9.95816 19.75L0 8.16669H6.69455L9.95816 19.75L13.1381 8.16669H19.9164Z"
      fill="#FFA3CF"
    />
    <path
      d="M13.1379 7.12512L12.9705 8.20845L11.8408 8.37513L12.9705 8.54182L13.1379 9.70847L13.3053 8.54182L14.435 8.37513L13.3053 8.20845L13.1379 7.12512Z"
      fill="white"
    />
    <path
      d="M4.93597 4.70837L4.76863 5.79173L3.63892 5.95837L4.72677 6.12505L4.93597 7.29172L5.10334 6.16673L6.23306 5.95837L5.10334 5.79173L4.93597 4.70837Z"
      fill="white"
    />
  </SvgIcon>
);

export default DiamondIcon;
