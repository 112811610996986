import {
  ADD_USER,
  FETCH_USERS,
  DELETE_USER,
  USER_PROGRESS,
  SET_USER_ERROR,
  SET_USER_LOADER,
  SET_USERS_CURRENT_PAGE,
  SET_USERS_MAX_PAGE_SO_FAR,
  SET_USERS_PER_PAGE,
  CLEAR_COMPLETE_USERS,
  SET_SUCCESS_TOAST,
} from "./userTypes";
import axios from "axios";
import { getDefaultStore } from "../../utils/storeUtil";
import { USERS_PER_PAGE } from "../../utils/utils";

export const addUser = (userData) => async (dispatch) => {
  console.log("call adding");
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  let data = userData;
  let storeId = store.store_id;
  return await axios
    .post(
      `${process.env.REACT_APP_API}/api/v1/store-users/store/${storeId}`,
      data,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      console.log(response);
      dispatch({
        type: ADD_USER,
        payload: response.data.payload,
      });
    })
    .catch((error) => {
      dispatch({
        type: SET_USER_ERROR,
        payload: { error: error.message },
      });
    });
};

export const fetchUsers =
  (page = 0, size = USERS_PER_PAGE) =>
  async (dispatch) => {
    dispatch({
      type: USER_PROGRESS,
      payload: true,
    });
    let accessToken = localStorage.getItem("access_token");
    let store = getDefaultStore();
    let storeId = store.store_id;
    return await axios
      .get(
        `${process.env.REACT_APP_API}/api/v1/store-users/store/${storeId}/list`,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        let users = response.data.payload.filter(
          (su) =>
            !su.deleted && su.mobile && su.mobile.indexOf("9999999999") < 0
        );
        dispatch({
          type: FETCH_USERS,
          payload: users,
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_USER_ERROR,
          payload: { error: error.message },
        });
      });
  };

export const deleteUser = (userId) => async (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  let storeId = store.store_id;
  return await axios
    .delete(
      `${process.env.REACT_APP_API}/api/v1/business/store/${storeId}/store-users/${userId}`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      dispatch({
        type: DELETE_USER,
        payload: { id: userId },
      });
    })
    .catch((error) => {
      dispatch({
        type: SET_USER_ERROR,
        payload: { error: error.message },
      });
    });
};

export const setUsersPerPage = (data) => (dispatch) => {
  dispatch({
    type: SET_USERS_PER_PAGE,
    payload: data,
  });
};

export const setUsersCurrentPage = (data) => (dispatch) => {
  dispatch({
    type: SET_USERS_CURRENT_PAGE,
    payload: data,
  });
};

export const setUsersMaxPageSoFar = (data) => (dispatch) => {
  dispatch({
    type: SET_USERS_MAX_PAGE_SO_FAR,
    payload: data,
  });
};

export const setSuccessToast = (data) => (dispatch) => {
  dispatch({
    type: SET_SUCCESS_TOAST,
    payload: data,
  });
};

export const clearUsers = () => (dispatch) => {
  dispatch({
    type: CLEAR_COMPLETE_USERS,
  });
};

export const startUserLoader = () => (dispatch) => {
  dispatch({
    type: SET_USER_LOADER,
    payload: true,
  });
};

export const stopUserLoader = () => (dispatch) => {
  dispatch({
    type: SET_USER_LOADER,
    payload: false,
  });
};
