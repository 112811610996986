import React, { useState, Fragment } from "react";
import {
  Button,
  ButtonGroup,
  CircularProgress,
  ClickAwayListener,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  createInvoiceItem,
  createItemFromProduct,
  getItemTax,
  updateCalculation,
  validateItem,
} from "../../utils/invoiceHelper";
import useStyles from "./styles";
import { useSelector } from "react-redux";
import MinusIcon from "../../icons/MinusIcon";
import PlusIcon from "../../icons/PlusIcon";
import RemoveCrossIcon from "../../icons/RemoveCrossIcon";
import AddProductModal from "./AddProductModal";
import ErrorAlert from "../message/ErrorAlert";
import ItemInput from "./ItemInput";
import { getItemTaxCodes } from "../../utils/utils";
import SolidButton from "../buttons/SolidButton";
import ChargeComponent from "./ChargeComponent";
import StoreSignature from "./StoreSignature";
import Controls from "../controls/Controls";
import { cdnUrl } from "../../utils/cdnUtils";
import { NoPicIcon } from "../../icons";
import {
  nopicctn,
  rowFlexAlignCenter,
  rowFlexJustifyBetween,
} from "../../styles/common";

const BaseForm = ({
  initValues,
  record,
  scale,
  setRecord,
  tableHeight,
  type,
  enableDiscount,
  enableCoupon,
  enableShipping,
  enablePackaging,
  enableRoundOff,
  enablePmtCharge,
  enablePmtDiscount,
}) => {
  const classes = useStyles();
  const store = useSelector((state) => state.store.store);
  const taxGroups = useSelector((state) => state.tax.taxGroups);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [openSelect, setOpenSelect] = useState(false);
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [productSelected, setProductSelected] = useState(false);
  const [searchProgress, setSearchProgress] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [selectQty, setSelectQty] = useState(1);
  const [hoverIndex, setHoverIndex] = useState(null);
  const itemsCount = initValues.order_items ? initValues.order_items.length : 0;
  const initErrors = [];
  for (let i = 0; i < itemsCount; i++) {
    initErrors.push({});
  }
  const [itemErrors, setItemErrors] = useState(initErrors);
  const [alertMessage, setAlertMessage] = useState(null);
  const [itemsMap, setItemsMap] = useState(
    initValues.order_items
      ? initValues.order_items.reduce(function (map, obj) {
          map[obj.sku] = obj;
          return map;
        }, {})
      : {}
  );

  const searchProducts = (keyword) => {
      setSearchProgress(true);
      fetch(
        `${process.env.REACT_APP_API}/api/v1/org/products?q=${keyword}&org-id=${
          store.org_id
        }&child-cat-products=true&page=${0}&size=${10}&sort=name,asc`
      )
        .then((res) => res.json())
        .then((products) => {
          setSearchProgress(false);
          let prod = products.payload.content;
          if (
            prod.length === 1 &&
            prod[0].barcode &&
            prod[0].barcode === keyword.trim()
          ) {
            handleSelect(prod[0], 1);
          } else {
            setOpenSelect(true);
            setSearchResult(prod);
          }
        })
        .catch(() => {
          setSearchProgress(false);
        });
  };

  const handleSelect = (product, addQty) => {
    let inQuantity = addQty ? addQty : selectQty;
    let quantity = parseFloat(inQuantity);
    let existingItem = itemsMap[product.sku];
    let items = null;
    if (existingItem) {
      //Add quantity to existing item
      quantity = quantity + existingItem.qty;
      let newItem = createInvoiceItem(existingItem, quantity);
      let existingIndex = record.order_items.findIndex(
        (it) => it.sku === newItem.sku
      );
      let errMap = itemErrors.splice(existingIndex, 1);
      setItemErrors([errMap, ...itemErrors]);
      items = record.order_items.filter((it) => it.sku !== newItem.sku);
      items.unshift(newItem);
      setItemsMap({ ...itemsMap, [newItem.sku]: newItem });
    } else {
      let prodItem = createItemFromProduct(product, type);
      let newItem = createInvoiceItem(prodItem, quantity);
      items = [newItem, ...record.order_items];
      setItemsMap({ ...itemsMap, [newItem.sku]: newItem });
      setItemErrors([{}, ...itemErrors]);
    }
    let updatedRec = updateCalculation({
      ...record,
      order_items: items,
    });
    setRecord(updatedRec);
    setProductSelected(false);
    setSearchKeyword("");
    setSearchResult([]);
    setSelectQty(1);
    setTimeout(() => {
      if (document.getElementById("productSearch"))
        document.getElementById("productSearch").focus();
    }, 100);
  };

  const removeItem = (item, index) => {
    let modItems = record.order_items.filter((it, idx) => idx !== index);
    let errorItems = itemErrors.filter((it, idx) => idx !== index);
    delete itemsMap[item.sku];
    setItemsMap({ ...itemsMap });
    let updatedRec = updateCalculation({ ...record, order_items: modItems });
    setRecord(updatedRec);
    setItemErrors(errorItems);
    setTimeout(() => {
      if (document.getElementById("productSearch"))
        document.getElementById("productSearch").focus();
    }, 100);
  };

  const updateOnChange = (index, key, value) => {
    let tempItems = [...record.order_items];
    let modItem = { ...tempItems[index], [key]: value };
    tempItems[index] = createInvoiceItem(modItem, modItem.qty);
    let updatedRec = updateCalculation({ ...record, order_items: tempItems });
    setRecord(updatedRec);
  };

  const updateOnBlur = (index, key, error) => {
    let tempErrors = [...itemErrors];
    if (!error) {
      let item = record.order_items[index];
      let errorItem = validateItem(item);
      tempErrors[index] = errorItem;
    } else {
      let modErrors = { ...tempErrors[index], [key]: error };
      tempErrors[index] = modErrors;
    }
    setItemErrors(tempErrors);
  };

  const onMrpChange = (index, key, value) => {
    if (isNaN(value)) {
      return;
    }
    let mrp = value !== "" ? parseFloat(value) : 0.0;
    if (mrp < 0) {
      return;
    }
    updateOnChange(index, key, mrp);
  };

  const validateMrp = (index, key, value) => {
    if (value === "" || isNaN(value)) {
      updateOnBlur(index, key, true);
    } else {
      let mrp = parseFloat(value);
      let item = record.order_items[index];
      let salePriceWTax = item.sale_price_wtax;
      if (mrp < 0 || salePriceWTax > mrp) {
        setAlertMessage(
          mrp < 0
            ? "MRP value should be positive"
            : "MRP value should be more than Price + Tax"
        );
      }
      updateOnBlur(index, key, mrp < 0 || salePriceWTax > mrp);
    }
  };

  const onSalePriceChange = (index, key, value) => {
    if (isNaN(value)) {
      return;
    }
    let price = value !== "" ? parseFloat(value) : 0.0;
    if (price < 0) {
      return;
    }
    updateOnChange(index, key, price);
  };

  const validateSalePrice = (index, key, value) => {
    if (value === "" || isNaN(value)) {
      updateOnBlur(index, key, true);
    } else {
      //Calculate price with Tax + have some margin
      let item = record.order_items[index];
      let price = parseFloat(value);
      let perItemTax = 0;
      if (!item.sp_tax_included) {
        perItemTax = price * (item.total_tax_in_per / 100);
      }
      let priceWTax = price + perItemTax;
      let mrp = item.mrp;
      if (price < 0 || priceWTax > mrp) {
        setAlertMessage(
          price < 0
            ? "Price should be positive"
            : "Amount should be less than MRP"
        );
      }
      updateOnBlur(index, key, price < 0 || priceWTax > mrp);
    }
  };

  const onDiscountAmountChange = (index, key, value) => {
    if (value === "" || isNaN(value)) {
      return;
    }
    let discAmount = parseFloat(value);
    if (discAmount < 0) {
      return;
    }
    let salePrice = record.order_items[index].mrp - discAmount;
    let newPrice = (salePrice * 100) / 100;
    updateOnChange(index, "sale_price", newPrice);
  };

  const validateDiscountAmount = (index, key, value) => {
    if (value === "" || isNaN(value)) {
      updateOnBlur(index, key, true);
    } else {
      let newPrice = record.order_items[index].sale_price;
      let discAmount = parseFloat(value);
      if (discAmount < 0) {
        setAlertMessage("Discount amount should not be negative");
      } else {
        if (newPrice < 0) {
          setAlertMessage("Price should be positive");
        }
      }
      updateOnBlur(index, key, discAmount < 0 || newPrice < 0);
    }
  };

  const onDiscountPercentChange = (index, key, value) => {
    if (value === "" || isNaN(value)) {
      return;
    }
    let discPercent = parseFloat(value);
    if (discPercent < 0) {
      return;
    }
    let mrp = record.order_items[index].mrp;
    let discAmount = 0;
    if (mrp > 0) {
      discAmount = (mrp * discPercent) / 100;
    }
    let newPrice = mrp - discAmount;
    newPrice = (newPrice * 100) / 100;
    updateOnChange(index, "sale_price", newPrice);
  };

  const validateDiscountPercent = (index, key, value) => {
    if (value === "" || isNaN(value)) {
      updateOnBlur(index, key, 0.0);
    } else {
      let newPrice = record.order_items[index].sale_price;
      let discPercent = parseFloat(value);
      if (discPercent < 0) {
        setAlertMessage("Discount percent should not be negative");
      } else {
        if (newPrice < 0) {
          setAlertMessage("Price should not be negative");
        }
      }
      updateOnBlur(index, key, discPercent < 0 || newPrice < 0);
    }
  };

  const onItemQtyChange = (index, key, value) => {
    if (value === "" || isNaN(value)) {
      return;
    }
    let qty = parseFloat(value);
    if (qty <= 0) {
      return;
    }
    updateOnChange(index, key, qty);
  };

  const validateQty = (index, key, value) => {
    if (value === "" || isNaN(value)) {
      updateOnBlur(index, key, 0.0);
    } else {
      let qty = record.order_items[index].qty;
      if (qty <= 0) {
        setAlertMessage("Quantity should be greater than 0");
      }
      updateOnBlur(index, key, qty <= 0);
    }
  };

  const handleItemTaxChange = (index, value, taxGroups) => {
    let tempItems = [...record.order_items];
    let taxTotal = parseFloat(value);
    let groups = taxGroups.filter((tg) => tg.total === taxTotal);
    if (groups.length > 0) {
      let modItem = { ...tempItems[index], tax_group: groups[0] };
      tempItems[index] = createInvoiceItem(modItem, modItem.qty);
      let updatedRec = updateCalculation({
        ...record,
        order_items: tempItems,
      });
      setRecord(updatedRec);
    }
  };

  const renderItemTax = (index) => {
    let item = record.order_items[index];
    let taxAmount = getItemTax(
      item.sale_price,
      item.qty,
      item.total_tax_in_per ? item.total_tax_in_per : null,
      item.sp_tax_included
    );
    return taxAmount.toFixed(2);
  };

  const calculateItemAmount = (index) => {
    let item = record.order_items[index];
    let amount = item.sale_price * item.qty;
    let spTaxIncluded = item.sp_tax_included;
    if (!spTaxIncluded) {
      let taxAmount = getItemTax(
        item.sale_price,
        item.qty,
        item.total_tax_in_per ? item.total_tax_in_per : null,
        item.sp_tax_included
      );
      amount += taxAmount;
    }
    return amount.toFixed(2);
  };

  const updateRecordCharge = (chargeName, chargeValue) => {
    let updatedRecord = updateCalculation({
      ...record,
      [chargeName]: chargeValue ? parseFloat(chargeValue) : 0,
    });
    setRecord(updatedRecord);
  };

  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          setOpenSelect(false);
        }}
      >
        <div>
          {!productSelected && (
            <Input
              className={classes.cancelBtn}
              style={{
                borderRadius: 6,
                width: "100%",
                height: 32,
                fontSize: 12,
                marginRight: 0,
                textTransform: "none",
                position: "absolute",
                top: 6,
                left: 0,
              }}
              classes={{
                input: classes.searchPlaceholder,
              }}
              placeholder="Add Item + "
              inputProps={{
                style: {
                  fontSize: 12,
                  color: "#1A1A1A",
                  fontWeight: searchKeyword ? 400 : 600,
                },
              }}
              disableUnderline
              onFocus={() => {
                setOpenSelect(true);
              }}
              onChange={(e) => {
                let searchVal = e.target.value;
                let searchWord = searchVal.trimStart();
                setSearchKeyword(searchWord);
                if(searchWord){
                  if (timeoutId) {
                    clearTimeout(timeoutId);
                  }
                  let timer = setTimeout(
                    () => searchProducts(searchWord),
                    300
                  );
                  setTimeoutId(timer);  
                }else{
                  setSearchResult([]);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Escape") {
                  setOpenSelect(false);
                  e.currentTarget.blur();
                }
                if (searchKeyword !== "") {
                  if (e.key === "ArrowDown") {
                    e.preventDefault();
                    if (searchResult.length === 0)
                      document.getElementById("addProdOption").focus();
                    else document.getElementById(`option${0}`).focus();
                  }
                  if (e.key === "ArrowUp") {
                    e.preventDefault();
                    document.getElementById("addProdOption").focus();
                  }
                }
              }}
              value={searchKeyword}
              endAdornment={
                searchProgress && (
                  <CircularProgress
                    color="secondary"
                    style={{ height: 14, width: 14 }}
                  />
                )
              }
              autoComplete="off"
              id="productSearch"
            />
          )}
          {productSelected && (
            <div
              className={classes.cancelBtn}
              style={{
                borderRadius: 6,
                width: "100%",
                height: 32,
                fontSize: 12,
                marginRight: 0,
                textTransform: "none",
                position: "absolute",
                top: 6,
                left: 0,
                color: "#1A1A1A",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span
                style={{ width: "100%" }}
                onClick={() => {
                  setProductSelected(false);
                  setSearchKeyword("");
                  setSearchResult([]);
                  setSelectQty(1);
                  setTimeout(() => {
                    if (document.getElementById("productSearch"))
                      document.getElementById("productSearch").focus();
                  }, 100);
                }}
              >
                {productSelected.display_name}
              </span>
              <div style={{ display: "flex" }}>
                <ButtonGroup
                  classes={{ grouped: classes.grouped }}
                  size="small"
                  className={classes.groupedroot}
                  aria-label="small primary button group"
                >
                  <Button
                    variant="text"
                    style={{ outline: "none" }}
                    onClick={() => {
                      if (selectQty !== 0) setSelectQty(selectQty - 1);
                    }}
                  >
                    <MinusIcon />
                  </Button>
                  <Button
                    variant="text"
                    className={classes.text}
                    classes={{
                      textSizeSmall: classes.textsizesmall,
                    }}
                    style={{
                      position: "relative",
                      outline: "none",
                    }}
                  >
                    <TextField
                      type="number"
                      style={{ width: 40 }}
                      value={selectQty}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSelect(productSelected);
                        }
                        if (e.key === "Escape") {
                          setProductSelected(false);
                          setSearchKeyword("");
                          setSearchResult([]);
                          setSelectQty(1);
                          setTimeout(() => {
                            if (document.getElementById("productSearch"))
                              document.getElementById("productSearch").focus();
                          }, 100);
                        }
                      }}
                      color="secondary"
                      inputProps={{
                        style: { textAlign: "center" },
                      }}
                      InputProps={{
                        className: classes.text,
                        disableUnderline: true,
                      }}
                      id="selectQty"
                      onChange={(e) => {
                        if (e.target.value >= 0) setSelectQty(e.target.value);
                      }}
                    />
                  </Button>
                  <Button
                    variant="text"
                    style={{ outline: "none" }}
                    onClick={() => setSelectQty(selectQty + 1)}
                  >
                    <PlusIcon />
                  </Button>
                </ButtonGroup>
                <SolidButton
                  style={{
                    height: 25,
                    padding: 0,
                    width: 88,
                    fontSize: 12,
                    marginLeft: 5,
                    minWidth: 0,
                  }}
                  onClick={() => {
                    handleSelect(productSelected);
                  }}
                >
                  Add
                </SolidButton>
              </div>
            </div>
          )}
          {openSelect && searchKeyword !== "" && (
            <div
              style={{
                width: "100%",
                maxHeight: "45vh",
                overflowY: "auto",
                position: "absolute",
                top: 45,
                border: "1px solid #999999",
                borderRadius: 6,
                background: "white",
                boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.04)",
                boxSizing: "border-box",
                zIndex: 1,
              }}
            >
              {searchResult.map((item, i) => (
                <Button
                  style={{
                    width: "100%",
                    padding: 10,
                    borderBottom: "1px solid #F6F6F6",
                    outline: "none",
                    textTransform: "none",
                  }}
                  classes={{
                    focusVisible:
                      i === 0 ? classes.focusedTopBtn : classes.focusedBtn,
                  }}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  key={`option${i}`}
                  id={`option${i}`}
                  onClick={() => {
                    setProductSelected(item);
                    setOpenSelect(false);
                    document.getElementById("productSearch").blur();
                    setTimeout(() => {
                      if (document.getElementById("selectQty"))
                        document.getElementById("selectQty").focus();
                    }, 100);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Escape") {
                      setOpenSelect(false);
                      e.currentTarget.blur();
                    }
                    if (e.key === "ArrowDown") {
                      e.preventDefault();
                      if (i === searchResult.length - 1)
                        document.getElementById("addProdOption").focus();
                      else document.getElementById(`option${i + 1}`).focus();
                    }
                    if (e.key === "ArrowUp") {
                      e.preventDefault();
                      if (i === 0)
                        document.getElementById("productSearch").focus();
                      else document.getElementById(`option${i - 1}`).focus();
                    }
                  }}
                >
                  <div
                    className={rowFlexJustifyBetween}
                    style={{ width: "100%" }}
                  >
                    <div className={rowFlexAlignCenter}>
                      {item.images && item.images.length > 0 ? (
                        <img
                          className={classes.prodimage}
                          alt="Thumbnail"
                          src={cdnUrl(item.images[0], 100, 100, 1)}
                        ></img>
                      ) : (
                        <div
                          className={nopicctn}
                          style={{ width: 40, minWidth: 40, height: 40 }}
                        >
                          <NoPicIcon />
                        </div>
                      )}
                      <div style={{ textAlign: "left" }}>
                        <div className={classes.prodname}>
                          {item.display_name}
                        </div>
                      </div>
                    </div>
                    <div className={rowFlexAlignCenter}>
                      <div className={classes.qty}>
                        <span
                          style={{ ...(item.qty < 1 && { color: "#FF6060" }) }}
                        >
                          {item.qty}
                        </span>
                        <div>Qty</div>
                      </div>
                      <div className={classes.listMrp}>
                        ₹{item.mrp}
                        <div>MRP</div>
                      </div>
                      <div className={classes.listMrp}>
                        ₹
                        {type === "PURCHASE_ORDER"
                          ? item.sale_price
                          : item.purchase_price}
                        <div>
                          {type === "PURCHASE_ORDER"
                            ? "Sale Price"
                            : "Purchase"}
                        </div>
                      </div>
                      <div className={classes.listSalePrice}>
                        ₹
                        {type === "PURCHASE_ORDER"
                          ? item.purchase_price
                          : item.sale_price}
                        <div>
                          {type === "PURCHASE_ORDER"
                            ? "Purchase"
                            : "Sale Price"}
                        </div>
                      </div>
                    </div>
                  </div>
                </Button>
              ))}

              {
                <Button
                  style={{
                    width: "100%",
                    padding: "12px 16px",
                    justifyContent: "space-between",
                    fontSize: 14,
                    color: "#1641DB",
                    outline: "none",
                    textTransform: "none",
                  }}
                  classes={{
                    focusVisible: classes.focusedBottomBtn,
                  }}
                  disableFocusRipple
                  disableRipple
                  id="addProdOption"
                  onKeyDown={(e) => {
                    if (e.key === "Escape") {
                      setOpenSelect(false);
                      e.currentTarget.blur();
                    }
                    if (e.key === "ArrowDown") {
                      e.preventDefault();
                      document.getElementById("productSearch").focus();
                    } else if (e.key === "ArrowUp") {
                      e.preventDefault();
                      document
                        .getElementById(
                          searchResult.length !== 0
                            ? `option${searchResult.length - 1}`
                            : "productSearch"
                        )
                        .focus();
                    }
                  }}
                  onClick={() => {
                    document.getElementById("productSearch").blur();
                    setOpenSelect(false);
                    setOpenAddProduct(true);
                  }}
                >
                  <span>
                    Add <i>"</i> {searchKeyword}
                    <i>"</i>
                  </span>
                </Button>
              }
            </div>
          )}
        </div>
      </ClickAwayListener>
      <div
        style={{
          marginTop: 68,
          width: "100%",
          height: `${tableHeight * scale}vh`,
          overflowY: "auto",
        }}
      >
        <TableContainer>
          <Table>
            <TableHead className={classes.tablehead}>
              <TableRow
                style={{
                  borderLeft: "1px solid #F5F7FC",
                  borderRight: "1px solid #F5F7FC",
                  boxSizing: "border-box",
                }}
              >
                <TableCell align="center" style={{ width: "28%" }}>
                  Item Name
                </TableCell>
                <TableCell align="center" style={{ width: "14%" }}>
                  MRP
                </TableCell>
                <TableCell align="center" style={{ width: "14%" }}>
                  Price
                </TableCell>
                <TableCell align="center" style={{ width: "12%" }}>
                  Discount
                </TableCell>
                <TableCell align="center" style={{ width: "8%" }}>
                  Qty
                </TableCell>
                <TableCell align="right" style={{ width: "12%" }}>
                  Tax
                </TableCell>
                <TableCell align="right" style={{ width: "13%" }}>
                  Amount
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {record.order_items.map((item, index) => (
                <TableRow
                  key={index}
                  hover
                  className={classes.trow}
                  onMouseEnter={() => setHoverIndex(index)}
                  onMouseLeave={() => setHoverIndex(null)}
                >
                  <TableCell
                    style={{
                      fontSize: 12,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>{item.display_name}</span>
                      <span
                        style={{
                          display: hoverIndex === index ? "block" : "none",
                          cursor: "pointer",
                        }}
                        onClick={() => removeItem(item, index)}
                      >
                        <RemoveCrossIcon />
                      </span>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <ItemInput
                      name="mrp"
                      value={item.mrp}
                      itemIndex={index}
                      type="number"
                      startAdornment="₹"
                      onChange={onMrpChange}
                      onBlur={validateMrp}
                      onFocus={(event) => event.target.select()}
                      error={itemErrors[index].mrp}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <ItemInput
                      name="sale_price"
                      value={item.sale_price}
                      type="number"
                      itemIndex={index}
                      startAdornment="₹"
                      onChange={onSalePriceChange}
                      onBlur={validateSalePrice}
                      onFocus={(event) => event.target.select()}
                      error={itemErrors[index].sale_price}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <div>
                      <ItemInput
                        name="disc"
                        value={item.disc}
                        type="number"
                        itemIndex={index}
                        startAdornment="₹"
                        onChange={onDiscountAmountChange}
                        onBlur={validateDiscountAmount}
                        onFocus={(event) => event.target.select()}
                        error={itemErrors[index].disc}
                      />
                      <ItemInput
                        name="disc_percent"
                        value={item.disc_percent}
                        itemIndex={index}
                        type="number"
                        startAdornment="%"
                        className={classes.discpercent}
                        onChange={onDiscountPercentChange}
                        onBlur={validateDiscountPercent}
                        onFocus={(event) => event.target.select()}
                        error={itemErrors[index].disc_percent}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <ItemInput
                      name="qty"
                      value={item.qty}
                      itemIndex={index}
                      type="number"
                      textAlign="center"
                      onFocus={(event) => event.target.select()}
                      onChange={onItemQtyChange}
                      onBlur={validateQty}
                      error={itemErrors[index].qty}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Controls.OldSelect
                      name="tax"
                      value={item.total_tax_in_per}
                      options={getItemTaxCodes(taxGroups)}
                      onChange={(e) =>
                        handleItemTaxChange(index, e.target.value, taxGroups)
                      }
                      outlinedClass={classes.taxitem}
                      labelStartClass={classes.taxlabel}
                      renderValue={() => `₹${renderItemTax(index)}`}
                      controlClass={classes.taxcontrol}
                      iconClass={classes.taxicon}
                      color="secondary"
                    />
                    <Typography
                      style={{
                        fontSize: 12,
                        marginTop: 4,
                        marginRight: -11,
                        color: "#888888",
                      }}
                    >
                      ({item.total_tax_in_per}%
                      {item.sp_tax_included ? " Incl." : " Excl."})
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{
                      color: "#1A1A1A",
                      fontSize: 12,
                    }}
                  >
                    ₹{calculateItemAmount(index)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {record.order_items.length === 0 && (
            <div className={classes.noItem}> No Item Added </div>
          )}
        </TableContainer>
        {record.order_items.length !== 0 && (
          <Fragment>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                marginTop: 15,
                paddingBottom: 24,
              }}
            >
              <div>
                {enableCoupon && (
                  <ChargeComponent
                    label="Coupon Discount"
                    field="coupon_discount"
                    value={record.coupon_discount}
                    onUpdate={updateRecordCharge}
                  />
                )}
                {enableDiscount && (
                  <ChargeComponent
                    label="Discount"
                    field="order_discount"
                    value={record.order_discount}
                    onUpdate={updateRecordCharge}
                  />
                )}
                {enableShipping && (
                  <ChargeComponent
                    label="Shipping Charge"
                    field="shipping_charge"
                    value={record.shipping_charge}
                    onUpdate={updateRecordCharge}
                  />
                )}
                {enablePackaging && (
                  <ChargeComponent
                    label="Packaging Charge"
                    field="packaging_charge"
                    value={record.packaging_charge}
                    onUpdate={updateRecordCharge}
                  />
                )}
                {enableRoundOff && (
                  <ChargeComponent
                    label="Round Off"
                    field="round_value"
                    value={record.round_value}
                    onUpdate={updateRecordCharge}
                  />
                )}
                {enablePmtCharge && (
                  <ChargeComponent
                    label="Payment Charge"
                    field="payment_charge"
                    value={record.payment_charge}
                    onUpdate={updateRecordCharge}
                  />
                )}
                {enablePmtDiscount && (
                  <ChargeComponent
                    label="Payment Discount"
                    field="payment_discount"
                    value={record.payment_discount}
                    onUpdate={updateRecordCharge}
                  />
                )}
              </div>
            </div>
            <StoreSignature store={store} />
          </Fragment>
        )}
        {alertMessage && (
          <ErrorAlert
            message={alertMessage}
            open={alertMessage ? true : false}
            onClose={() => setAlertMessage(null)}
          />
        )}
      </div>
      {openAddProduct && (
        <AddProductModal
          openAddProduct={openAddProduct}
          searchKeyword={searchKeyword}
          setOpenAddProduct={setOpenAddProduct}
          handleSelect={handleSelect}
        />
      )}
    </>
  );
};

export default BaseForm;
