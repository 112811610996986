import React from "react";

import HeaderBreadcrumbs from "../../../../components/common/HeaderBreadcrumbs";

export default function FormHead({ params, code }) {
  return (
    <div style={{ marginBottom: 20 }}>
      <HeaderBreadcrumbs
        list={[
          { label: "Coupons", link: "/coupons" },
          {
            label: params.operation === "create" ? "Create" : code,
          },
        ]}
      />
    </div>
  );
}
