import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function NoSubscriptionFigma() {
  return (
    <SvgIcon viewBox={"0 0 275 168"} style={{ width: "566", height: "168" }}>
      <svg
        width="275"
        height="168"
        viewBox="0 0 275 168"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M274.423 71.0938C283.665 189.821 179.817 162.837 115.66 162.837C51.5025 162.837 23.4609 48.5807 80.5731 71.0938C125.99 88.9966 143.373 -11.1383 256.412 1.65869C294.803 12.0858 250.251 26.3046 274.423 71.0938Z"
          fill="#F2F6FF"
        />
        <g opacity="0.1">
          <path
            d="M22.427 155.551C25.0244 155.551 27.1299 152.794 27.1299 149.394C27.1299 145.994 25.0244 143.238 22.427 143.238C19.8297 143.238 17.7241 145.994 17.7241 149.394C17.7241 152.794 19.8297 155.551 22.427 155.551Z"
            fill="#3F3D56"
          />
          <path
            d="M26.1496 123.054C26.805 121.909 27.1434 120.609 27.1299 119.29C27.1299 115.891 25.0386 113.133 22.4244 113.133C19.8103 113.133 17.7189 115.891 17.7189 119.29C17.7051 120.61 18.0445 121.909 18.7018 123.054C18.0575 124.204 17.7191 125.499 17.7191 126.817C17.7191 128.135 18.0575 129.431 18.7018 130.58C18.0575 131.729 17.7191 133.025 17.7191 134.342C17.7191 135.66 18.0575 136.955 18.7018 138.104C18.0445 139.249 17.7051 140.548 17.7189 141.868C17.7189 145.267 19.8259 148.025 22.4244 148.025C25.0229 148.025 27.1299 145.267 27.1299 141.868C27.1434 140.549 26.805 139.249 26.1496 138.104C26.7925 136.954 27.1301 135.659 27.1301 134.342C27.1301 133.025 26.7925 131.73 26.1496 130.58C26.7925 129.43 27.1301 128.135 27.1301 126.817C27.1301 125.5 26.7925 124.204 26.1496 123.054Z"
            fill="#3F3D56"
          />
          <path
            d="M22.427 117.922C25.0244 117.922 27.1299 115.166 27.1299 111.766C27.1299 108.366 25.0244 105.609 22.427 105.609C19.8297 105.609 17.7241 108.366 17.7241 111.766C17.7241 115.166 19.8297 117.922 22.427 117.922Z"
            fill="#3F3D56"
          />
          <path
            d="M22.427 110.396C25.0244 110.396 27.1299 107.64 27.1299 104.24C27.1299 100.84 25.0244 98.0835 22.427 98.0835C19.8297 98.0835 17.7241 100.84 17.7241 104.24C17.7241 107.64 19.8297 110.396 22.427 110.396Z"
            fill="#3F3D56"
          />
          <path
            d="M4.83101 52.7405C4.1882 51.9229 3.60295 51.0616 3.07951 50.1629L15.4367 48.1343L2.07306 48.2337C0.790803 45.4438 0.091187 42.4216 0.0170212 39.352C-0.0571446 36.2825 0.495692 33.23 1.64171 30.3815L19.575 39.6827L3.03507 27.5268C4.58033 24.8414 6.66282 22.5036 9.1524 20.6594C11.642 18.8151 14.4852 17.5042 17.5043 16.8084C20.5234 16.1126 23.6535 16.047 26.6992 16.6156C29.7448 17.1842 32.6404 18.3748 35.2051 20.113C37.7698 21.8512 39.9485 24.0997 41.605 26.7179C43.2615 29.3362 44.3602 32.2679 44.8324 35.33C45.3047 38.392 45.1404 41.5185 44.3498 44.5142C43.5591 47.5099 42.1592 50.3103 40.2374 52.7405C41.2381 54.0142 42.0988 55.392 42.8045 56.85L26.7639 65.184L43.8685 59.4328C45.07 62.9831 45.3672 66.777 44.7332 70.4711C44.0992 74.1652 42.554 77.6428 40.2374 80.5893C42.851 83.9125 44.4767 87.904 44.9288 92.1076C45.3808 96.3111 44.6409 100.557 42.7937 104.36C40.9465 108.163 38.0664 111.369 34.4828 113.613C30.8993 115.856 26.7568 117.045 22.529 117.045C18.3012 117.045 14.1587 115.856 10.5751 113.613C6.99153 111.369 4.11149 108.163 2.26425 104.36C0.417007 100.557 -0.322887 96.3111 0.129166 92.1076C0.581218 87.904 2.20697 83.9125 4.82055 80.5893C1.69783 76.6177 0.000211955 71.7119 0.000211955 66.6597C0.000211955 61.6074 1.69783 56.7017 4.82055 52.73L4.83101 52.7405Z"
            fill="#76A1FF"
          />
          <path
            opacity="0.1"
            d="M45.0579 66.6714C45.0657 71.7246 43.3672 76.6325 40.2374 80.5998C42.851 83.9229 44.4767 87.9145 44.9288 92.118C45.3808 96.3216 44.6409 100.568 42.7937 104.37C40.9465 108.173 38.0664 111.38 34.4828 113.623C30.8993 115.866 26.7568 117.056 22.529 117.056C18.3012 117.056 14.1587 115.866 10.5751 113.623C6.99153 111.38 4.11149 108.173 2.26425 104.37C0.417007 100.568 -0.322887 96.3216 0.129166 92.118C0.581218 87.9145 2.20697 83.9229 4.82055 80.5998C1.80118 76.7674 45.0579 64.1409 45.0579 66.6714Z"
            fill="black"
          />
        </g>
        <rect
          x="100.441"
          y="4.05829"
          width="149.14"
          height="161.315"
          fill="white"
        />
        <path
          d="M244.183 166.103H105.393C103.962 166.101 102.589 165.532 101.577 164.519C100.565 163.507 99.9958 162.135 99.9941 160.703V5.39913C99.9958 3.96769 100.565 2.59533 101.577 1.58315C102.589 0.570964 103.962 0.00161144 105.393 0H244.183C245.614 0.00161144 246.986 0.570964 247.999 1.58315C249.011 2.59533 249.58 3.96769 249.582 5.39913V160.703C249.58 162.135 249.011 163.507 247.999 164.519C246.986 165.532 245.614 166.101 244.183 166.103ZM105.393 0.635192C104.13 0.636621 102.919 1.13899 102.026 2.03209C101.133 2.92519 100.631 4.13609 100.629 5.39913V160.703C100.631 161.967 101.133 163.177 102.026 164.071C102.919 164.964 104.13 165.466 105.393 165.467H244.183C245.446 165.466 246.657 164.964 247.55 164.071C248.443 163.177 248.945 161.967 248.947 160.703V5.39913C248.945 4.13609 248.443 2.92519 247.55 2.03209C246.657 1.13899 245.446 0.636621 244.183 0.635192H105.393Z"
          fill="#3F3D56"
        />
        <path
          d="M249.264 13.2088H100.312V13.844H249.264V13.2088Z"
          fill="#3F3D56"
        />
        <path
          d="M107.616 9.52807C109.02 9.52807 110.157 8.39053 110.157 6.9873C110.157 5.58407 109.02 4.44653 107.616 4.44653C106.213 4.44653 105.076 5.58407 105.076 6.9873C105.076 8.39053 106.213 9.52807 107.616 9.52807Z"
          fill="#1641DB"
        />
        <path
          d="M115.556 9.52807C116.96 9.52807 118.097 8.39053 118.097 6.9873C118.097 5.58407 116.96 4.44653 115.556 4.44653C114.153 4.44653 113.016 5.58407 113.016 6.9873C113.016 8.39053 114.153 9.52807 115.556 9.52807Z"
          fill="#1641DB"
        />
        <path
          d="M123.496 9.52807C124.9 9.52807 126.037 8.39053 126.037 6.9873C126.037 5.58407 124.9 4.44653 123.496 4.44653C122.093 4.44653 120.956 5.58407 120.956 6.9873C120.956 8.39053 122.093 9.52807 123.496 9.52807Z"
          fill="#1641DB"
        />
        <path
          d="M224.333 36.5236H125.243C124.148 36.5236 123.098 36.0886 122.324 35.3143C121.549 34.54 121.114 33.4899 121.114 32.3949C121.114 31.2998 121.549 30.2497 122.324 29.4754C123.098 28.7011 124.148 28.2661 125.243 28.2661H224.333C225.428 28.2661 226.478 28.7011 227.252 29.4754C228.027 30.2497 228.462 31.2998 228.462 32.3949C228.462 33.4899 228.027 34.54 227.252 35.3143C226.478 36.0886 225.428 36.5236 224.333 36.5236ZM125.243 28.9013C124.316 28.9013 123.428 29.2694 122.773 29.9245C122.118 30.5797 121.749 31.4683 121.749 32.3949C121.749 33.3214 122.118 34.21 122.773 34.8652C123.428 35.5203 124.316 35.8884 125.243 35.8884H224.333C225.259 35.8884 226.148 35.5203 226.803 34.8652C227.458 34.21 227.826 33.3214 227.826 32.3949C227.826 31.4683 227.458 30.5797 226.803 29.9245C226.148 29.2694 225.259 28.9013 224.333 28.9013H125.243Z"
          fill="#3F3D56"
        />
        <path
          d="M199.832 69.1058H154.733C154.059 69.1058 153.413 68.8381 152.937 68.3616C152.46 67.8851 152.192 67.2389 152.192 66.565C152.192 65.8911 152.46 65.2449 152.937 64.7684C153.413 64.2919 154.059 64.0242 154.733 64.0242H199.832C200.506 64.0242 201.152 64.2919 201.628 64.7684C202.105 65.2449 202.373 65.8911 202.373 66.565C202.373 67.2389 202.105 67.8851 201.628 68.3616C201.152 68.8381 200.506 69.1058 199.832 69.1058Z"
          fill="#CCCCCC"
        />
        <path
          d="M225.875 77.6813H154.733C154.059 77.6813 153.413 77.4136 152.937 76.9371C152.46 76.4606 152.192 75.8144 152.192 75.1405C152.192 74.4666 152.46 73.8204 152.937 73.3439C153.413 72.8674 154.059 72.5997 154.733 72.5997H225.875C226.548 72.5997 227.195 72.8674 227.671 73.3439C228.148 73.8204 228.415 74.4666 228.415 75.1405C228.415 75.8144 228.148 76.4606 227.671 76.9371C227.195 77.4136 226.548 77.6813 225.875 77.6813Z"
          fill="#CCCCCC"
        />
        <path
          d="M137.834 78.1576H124.178C123.378 78.1567 122.611 77.8385 122.045 77.2729C121.48 76.7072 121.162 75.9403 121.161 75.1404V66.5653C121.162 65.7654 121.48 64.9985 122.045 64.4329C122.611 63.8672 123.378 63.5491 124.178 63.5482H137.834C138.634 63.5491 139.401 63.8672 139.967 64.4329C140.533 64.9985 140.851 65.7654 140.852 66.5653V75.1404C140.851 75.9403 140.533 76.7072 139.967 77.2729C139.401 77.8385 138.634 78.1567 137.834 78.1576ZM124.178 64.1833C123.546 64.1841 122.941 64.4352 122.494 64.8818C122.048 65.3283 121.797 65.9338 121.796 66.5653V75.1404C121.797 75.7719 122.048 76.3774 122.494 76.8239C122.941 77.2705 123.546 77.5217 124.178 77.5224H137.834C138.466 77.5217 139.071 77.2705 139.518 76.8239C139.965 76.3774 140.216 75.7719 140.216 75.1404V66.5653C140.216 65.9338 139.965 65.3283 139.518 64.8818C139.071 64.4352 138.466 64.1841 137.834 64.1833H124.178Z"
          fill="#CCCCCC"
        />
        <path
          d="M199.832 99.2775H154.733C154.059 99.2775 153.413 99.0098 152.937 98.5333C152.46 98.0568 152.192 97.4105 152.192 96.7367C152.192 96.0628 152.46 95.4166 152.937 94.9401C153.413 94.4636 154.059 94.1959 154.733 94.1959H199.832C200.506 94.1959 201.152 94.4636 201.628 94.9401C202.105 95.4166 202.373 96.0628 202.373 96.7367C202.373 97.4105 202.105 98.0568 201.628 98.5333C201.152 99.0098 200.506 99.2775 199.832 99.2775Z"
          fill="#CCCCCC"
        />
        <path
          d="M225.875 107.853H154.733C154.059 107.853 153.413 107.585 152.937 107.108C152.46 106.632 152.192 105.986 152.192 105.312C152.192 104.638 152.46 103.992 152.937 103.515C153.413 103.039 154.059 102.771 154.733 102.771H225.875C226.548 102.771 227.195 103.039 227.671 103.515C228.148 103.992 228.415 104.638 228.415 105.312C228.415 105.986 228.148 106.632 227.671 107.108C227.195 107.585 226.548 107.853 225.875 107.853Z"
          fill="#CCCCCC"
        />
        <path
          d="M137.834 108.329H124.178C123.378 108.328 122.611 108.01 122.045 107.444C121.48 106.879 121.162 106.112 121.161 105.312V96.7366C121.162 95.9367 121.48 95.1698 122.045 94.6042C122.611 94.0386 123.378 93.7204 124.178 93.7195H137.834C138.634 93.7204 139.401 94.0386 139.967 94.6042C140.533 95.1698 140.851 95.9367 140.852 96.7366V105.312C140.851 106.112 140.533 106.879 139.967 107.444C139.401 108.01 138.634 108.328 137.834 108.329ZM124.178 94.3547C123.546 94.3554 122.941 94.6066 122.494 95.0531C122.048 95.4997 121.797 96.1051 121.796 96.7366V105.312C121.797 105.943 122.048 106.549 122.494 106.995C122.941 107.442 123.546 107.693 124.178 107.694H137.834C138.466 107.693 139.071 107.442 139.518 106.995C139.965 106.549 140.216 105.943 140.216 105.312V96.7366C140.216 96.1051 139.965 95.4997 139.518 95.0531C139.071 94.6066 138.466 94.3554 137.834 94.3547H124.178Z"
          fill="#CCCCCC"
        />
        <path
          d="M199.832 129.449H154.733C154.059 129.449 153.413 129.181 152.937 128.705C152.46 128.228 152.192 127.582 152.192 126.908C152.192 126.235 152.46 125.588 152.937 125.112C153.413 124.635 154.059 124.368 154.733 124.368H199.832C200.506 124.368 201.152 124.635 201.628 125.112C202.105 125.588 202.373 126.235 202.373 126.908C202.373 127.582 202.105 128.228 201.628 128.705C201.152 129.181 200.506 129.449 199.832 129.449Z"
          fill="#F2F2F2"
        />
        <path
          d="M225.875 138.024H154.733C154.059 138.024 153.413 137.757 152.937 137.28C152.46 136.804 152.192 136.157 152.192 135.484C152.192 134.81 152.46 134.163 152.937 133.687C153.413 133.21 154.059 132.943 154.733 132.943H225.875C226.548 132.943 227.195 133.21 227.671 133.687C228.148 134.163 228.415 134.81 228.415 135.484C228.415 136.157 228.148 136.804 227.671 137.28C227.195 137.757 226.548 138.024 225.875 138.024Z"
          fill="#F2F2F2"
        />
        <path
          d="M137.834 138.501H124.178C123.378 138.5 122.611 138.182 122.045 137.616C121.48 137.05 121.162 136.283 121.161 135.483V126.908C121.162 126.108 121.48 125.342 122.045 124.776C122.611 124.21 123.378 123.892 124.178 123.891H137.834C138.634 123.892 139.401 124.21 139.967 124.776C140.533 125.342 140.851 126.108 140.852 126.908V135.483C140.851 136.283 140.533 137.05 139.967 137.616C139.401 138.182 138.634 138.5 137.834 138.501Z"
          fill="#F2F2F2"
        />
        <path
          d="M73.311 100.629H59.6544C58.8544 100.629 58.0875 100.31 57.5219 99.7447C56.9563 99.1791 56.6381 98.4122 56.6372 97.6123V89.0372C56.6381 88.2373 56.9563 87.4704 57.5219 86.9047C58.0875 86.3391 58.8544 86.0209 59.6544 86.02H73.311C74.1109 86.0209 74.8778 86.3391 75.4434 86.9047C76.0091 87.4704 76.3272 88.2373 76.3281 89.0372V97.6123C76.3272 98.4122 76.0091 99.1791 75.4434 99.7447C74.8778 100.31 74.1109 100.629 73.311 100.629ZM59.6544 86.6552C59.0228 86.6559 58.4174 86.9071 57.9708 87.3537C57.5243 87.8002 57.2731 88.4057 57.2724 89.0372V97.6123C57.2731 98.2438 57.5243 98.8493 57.9708 99.2958C58.4174 99.7424 59.0228 99.9935 59.6544 99.9942H73.311C73.9425 99.9935 74.548 99.7424 74.9945 99.2958C75.4411 98.8493 75.6923 98.2438 75.693 97.6123V89.0372C75.6923 88.4057 75.4411 87.8002 74.9945 87.3537C74.548 86.9071 73.9425 86.6559 73.311 86.6552H59.6544Z"
          fill="#3F3D56"
        />
        <path
          d="M58.5855 162.643L62.1179 161.802L60.5578 147.778L55.3445 149.018L58.5855 162.643Z"
          fill="#FFB8B8"
        />
        <path
          d="M69.71 163.462L58.6086 166.103L57.5883 161.813L64.4005 160.193C64.9638 160.059 65.5479 160.037 66.1196 160.129C66.6912 160.221 67.2392 160.424 67.7322 160.728C68.2253 161.031 68.6537 161.429 68.993 161.898C69.3324 162.367 69.576 162.899 69.71 163.462Z"
          fill="#2F2E41"
        />
        <path
          d="M55.1184 153.612L47.4468 127.32L57.0334 124.942L62.6574 152.787C62.6986 152.991 62.6948 153.202 62.6462 153.404C62.5976 153.607 62.5054 153.796 62.376 153.959C62.2467 154.122 62.0833 154.255 61.8972 154.348C61.7112 154.441 61.507 154.493 61.299 154.499L56.5328 154.641C56.5185 154.641 56.5042 154.641 56.49 154.641C56.1808 154.641 55.8801 154.541 55.6328 154.355C55.3855 154.17 55.205 153.909 55.1184 153.612Z"
          fill="#2F2E41"
        />
        <path
          d="M36.711 162.731L40.3417 162.731L42.0685 148.726L36.7097 148.727L36.711 162.731Z"
          fill="#FFB8B8"
        />
        <path
          d="M47.3447 166.102L35.9336 166.103L35.9332 161.694L42.9355 161.693C44.1048 161.693 45.2262 162.158 46.0531 162.984C46.88 163.811 47.3446 164.933 47.3447 166.102Z"
          fill="#2F2E41"
        />
        <path
          d="M242.994 166.073C244.056 164.104 242.85 161.692 241.463 159.935C240.077 158.177 238.386 156.353 238.422 154.113C238.474 150.9 241.886 149 244.607 147.294C246.631 146.03 248.498 144.53 250.168 142.826C250.868 142.169 251.446 141.395 251.877 140.537C252.441 139.28 252.423 137.849 252.392 136.473C252.274 131.883 252.047 127.299 251.712 122.722"
          stroke="#3F3D56"
          stroke-width="1.03082"
          stroke-miterlimit="10"
        />
        <path
          d="M256.897 122.469C256.858 121.63 256.609 120.813 256.172 120.095C255.734 119.377 255.123 118.781 254.395 118.362L253.271 120.583L253.305 117.893C252.769 117.736 252.211 117.669 251.653 117.694C250.662 117.741 249.707 118.08 248.909 118.669C248.111 119.258 247.506 120.07 247.17 121.003C246.834 121.936 246.782 122.948 247.02 123.911C247.259 124.874 247.778 125.744 248.511 126.412C249.245 127.079 250.16 127.515 251.14 127.662C252.121 127.81 253.124 127.664 254.021 127.242C254.919 126.82 255.671 126.141 256.183 125.292C256.695 124.442 256.944 123.46 256.897 122.469Z"
          fill="#1641DB"
        />
        <path
          d="M247.955 156.42C247.428 157.442 246.567 158.252 245.514 158.715C244.461 159.177 243.281 159.264 242.173 158.96C241.064 158.656 240.093 157.98 239.423 157.045C238.754 156.11 238.426 154.974 238.495 153.826C238.563 152.678 239.025 151.589 239.801 150.741C240.578 149.893 241.623 149.337 242.76 149.168C243.897 148.999 245.058 149.226 246.048 149.811C247.038 150.396 247.797 151.303 248.197 152.381L245.063 154.93L248.516 154.126C248.515 154.924 248.322 155.711 247.955 156.42Z"
          fill="#1641DB"
        />
        <path
          d="M245.478 146.676C244.82 146.706 244.163 146.607 243.543 146.384C242.924 146.161 242.354 145.818 241.867 145.374C241.38 144.931 240.985 144.396 240.705 143.8C240.425 143.204 240.265 142.558 240.234 141.9C240.195 141.115 240.343 140.331 240.667 139.614C240.966 138.944 241.409 138.348 241.964 137.868C242.519 137.388 243.172 137.035 243.878 136.834L243.849 138.895L244.986 136.648C246.315 136.586 247.614 137.054 248.597 137.95C249.581 138.845 250.168 140.095 250.23 141.424C250.292 142.752 249.824 144.051 248.929 145.035C248.033 146.018 246.784 146.606 245.455 146.668L245.478 146.676Z"
          fill="#1641DB"
        />
        <path
          d="M257.652 138.576C256.986 138.62 256.318 138.531 255.688 138.314C255.057 138.097 254.475 137.757 253.978 137.312C253.48 136.868 253.076 136.329 252.789 135.726C252.502 135.124 252.339 134.47 252.307 133.804C252.276 133.138 252.378 132.472 252.608 131.845C252.837 131.219 253.189 130.644 253.643 130.155C254.097 129.666 254.644 129.273 255.252 128.998C255.859 128.723 256.516 128.572 257.183 128.554C258.12 128.508 259.051 128.729 259.868 129.19L258.982 131.618L260.806 129.868C261.786 130.761 262.37 132.006 262.432 133.329C262.455 133.793 262.412 134.258 262.304 134.71C262.054 135.773 261.465 136.725 260.626 137.423C259.786 138.12 258.742 138.525 257.652 138.576Z"
          fill="#1641DB"
        />
        <path
          opacity="0.1"
          d="M256.222 123.807C255.065 123.933 253.941 124.294 252.779 124.366C251.617 124.438 250.341 124.16 249.606 123.255C249.21 122.771 249.014 122.155 248.679 121.624C248.362 121.13 247.929 120.722 247.416 120.436C247.094 121.072 246.911 121.769 246.88 122.48C246.848 123.192 246.969 123.903 247.234 124.564C247.498 125.226 247.901 125.823 248.415 126.317C248.928 126.811 249.541 127.189 250.213 127.428C250.884 127.666 251.599 127.759 252.309 127.699C253.019 127.639 253.708 127.429 254.331 127.083C254.953 126.736 255.494 126.26 255.919 125.688C256.343 125.116 256.64 124.459 256.791 123.763C256.601 123.773 256.412 123.786 256.222 123.807Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M257.652 138.576C256.788 138.615 255.928 138.431 255.156 138.04C254.384 137.649 253.727 137.066 253.247 136.346C252.767 135.626 252.481 134.795 252.417 133.932C252.353 133.069 252.513 132.205 252.882 131.422C253.279 131.691 253.621 132.034 253.889 132.432C254.248 132.971 254.464 133.595 254.884 134.089C255.657 135.009 257 135.308 258.209 135.254C259.417 135.2 260.587 134.855 261.793 134.739C261.961 134.723 262.131 134.713 262.309 134.708C262.06 135.772 261.47 136.725 260.629 137.423C259.789 138.121 258.743 138.526 257.652 138.576Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M245.478 146.675C244.82 146.706 244.163 146.607 243.543 146.384C242.924 146.161 242.354 145.818 241.867 145.374C241.38 144.931 240.985 144.396 240.705 143.8C240.425 143.204 240.265 142.558 240.234 141.9C240.195 141.115 240.343 140.331 240.667 139.614C241.08 139.89 241.439 140.239 241.726 140.645C242.115 141.194 242.36 141.828 242.816 142.336C243.661 143.276 245.084 143.609 246.365 143.578C247.645 143.547 248.803 143.248 250.037 143.153C249.731 144.137 249.129 145.003 248.314 145.634C247.498 146.264 246.508 146.628 245.478 146.675Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M247.955 156.419C247.371 157.566 246.369 158.445 245.156 158.874C243.943 159.302 242.611 159.248 241.436 158.723C240.262 158.197 239.334 157.24 238.846 156.049C238.357 154.859 238.345 153.526 238.811 152.327C239.224 152.599 239.595 152.93 239.912 153.309C240.389 153.891 240.703 154.551 241.254 155.092C242.285 156.097 243.935 156.512 245.409 156.551C246.259 156.558 247.109 156.514 247.955 156.419Z"
          fill="black"
        />
        <path
          d="M36.0736 157.29C35.9401 157.161 35.8337 157.006 35.7604 156.836C35.6871 156.665 35.6484 156.481 35.6466 156.296L32.3386 130.067C32.3373 129.689 32.4858 129.325 32.7516 129.056C33.0175 128.787 33.3791 128.634 33.7574 128.631L41.141 128.577H41.1515C41.5265 128.578 41.8861 128.726 42.1532 128.989C42.4203 129.252 42.5737 129.61 42.5804 129.985L42.9729 155.822C42.9788 156.2 42.8353 156.564 42.5737 156.835C42.3122 157.107 41.9538 157.264 41.5768 157.273L37.0947 157.699C37.0877 157.699 37.0808 157.699 37.0739 157.699C36.6996 157.7 36.34 157.553 36.0736 157.29Z"
          fill="#2F2E41"
        />
        <path
          d="M37.6557 70.4776C41.9638 70.4776 45.4562 66.9852 45.4562 62.6771C45.4562 58.369 41.9638 54.8766 37.6557 54.8766C33.3476 54.8766 29.8552 58.369 29.8552 62.6771C29.8552 66.9852 33.3476 70.4776 37.6557 70.4776Z"
          fill="#FFB8B8"
        />
        <path
          d="M27.3794 128.697C22.3041 124.731 22.3578 120.105 24.0896 113.926C24.5775 112.185 25.1041 110.421 25.6615 108.553C28.2515 99.8746 31.187 90.0383 30.0726 81.9166C29.7673 79.6922 30.5454 77.3524 32.2072 75.4974C33.8691 73.6421 36.1059 72.6141 38.3544 72.6721C40.599 72.7319 42.7816 73.8793 44.3424 75.8203C45.8957 77.7517 46.637 84.5433 46.8567 85.5167C47.8656 89.9843 48.4745 92.6809 48.9112 96.9827C49.4277 102.068 55.6133 117.274 57.5844 119.824C58.1075 120.497 58.482 121.274 58.6833 122.102C58.8845 122.931 58.908 123.792 58.7521 124.63C58.6068 125.428 58.2918 126.184 57.8285 126.849C57.3652 127.514 56.7644 128.072 56.0667 128.484C52.8679 130.44 49.3529 131.823 45.6794 132.572C44.0859 132.881 42.4665 133.037 40.8434 133.038C35.8073 133.038 30.9953 131.522 27.3794 128.697Z"
          fill="#1641DB"
        />
        <path
          d="M59.8179 99.3089C59.6159 98.9232 59.3317 98.5865 58.9855 98.3225C58.6393 98.0585 58.2395 97.8736 57.8141 97.7809C57.3887 97.6881 56.9481 97.6898 56.5235 97.7857C56.0988 97.8817 55.7003 98.0696 55.3561 98.3362L49.4453 94.9634L46.5195 98.0555L54.9337 102.701C55.4199 103.25 56.0904 103.601 56.8182 103.688C57.546 103.775 58.2806 103.593 58.8826 103.174C59.4846 102.756 59.9123 102.132 60.0847 101.419C60.2571 100.707 60.1621 99.956 59.8179 99.3089Z"
          fill="#FFB8B8"
        />
        <path
          d="M50.8071 101.595L40.213 98.2671C38.7906 97.8169 37.5405 96.9414 36.6313 95.7585C35.7221 94.5757 35.1976 93.1424 35.1285 91.6521L34.6727 81.3585C34.647 80.7794 34.7385 80.2012 34.9415 79.6582C35.1445 79.1153 35.455 78.619 35.8543 78.1988C36.2535 77.7786 36.7335 77.4433 37.2653 77.2129C37.7972 76.9825 38.3701 76.8617 38.9497 76.8578C39.5293 76.8539 40.1038 76.967 40.6387 77.1902C41.1736 77.4134 41.658 77.7422 42.0629 78.157C42.4678 78.5717 42.7849 79.0639 42.9952 79.604C43.2055 80.1441 43.3047 80.7211 43.2869 81.3005L42.9955 90.7711L54.2707 95.3695C54.4698 95.4507 54.648 95.5758 54.792 95.7354C54.9361 95.895 55.0422 96.0851 55.1026 96.2915C55.163 96.4978 55.176 96.7152 55.1408 96.9273C55.1055 97.1394 55.0228 97.3408 54.8989 97.5165L52.4035 101.055C52.2294 101.303 51.9807 101.489 51.6936 101.587C51.4066 101.684 51.096 101.687 50.8071 101.595Z"
          fill="#2F2E41"
        />
        <path
          d="M25.0845 72.3834C25.4409 70.2457 25.4278 68.03 25.4151 65.8872C25.4117 65.3055 25.4082 64.7239 25.4117 64.1435C25.4283 61.4459 25.5957 58.5868 26.6986 55.9547C27.8989 53.0901 30.1216 51.0216 32.6443 50.4214C35.4886 49.744 38.5748 51.2414 39.504 53.707C40.1182 53.3601 40.8017 53.1538 41.5053 53.1031C42.2089 53.0524 42.915 53.1585 43.5726 53.4138C44.2302 53.6691 44.823 54.0671 45.3081 54.5792C45.7932 55.0913 46.1586 55.7048 46.3779 56.3752C46.5972 57.0457 46.665 57.7565 46.5763 58.4563C46.4876 59.1561 46.2446 59.8275 45.865 60.422C45.4853 61.0166 44.9785 61.5194 44.3809 61.8943C43.7834 62.2692 43.1101 62.5068 42.4096 62.5899L42.2904 62.6005L41.3609 60.185C41.3557 60.2273 41.3506 60.2701 41.3455 60.3133C41.2863 60.8142 41.2252 61.332 40.8117 61.6311C40.2954 62.0045 39.6525 63.2335 39.8403 64.4786C39.9575 65.1157 40.132 65.7409 40.3619 66.3465C40.6173 67.0114 40.8027 67.701 40.9152 68.4044C41.0333 69.3059 40.7974 70.2182 40.257 70.9495C40.0969 71.1629 39.8949 71.3414 39.6635 71.4742C39.432 71.607 39.176 71.6913 38.9109 71.7219L28.4341 72.8053L27.9657 69.3058L24.9856 72.9761L25.0845 72.3834Z"
          fill="#2F2E41"
        />
        <path
          d="M79.558 166.29H18.5796C18.4953 166.29 18.4145 166.257 18.355 166.197C18.2954 166.138 18.262 166.057 18.262 165.973C18.262 165.888 18.2954 165.808 18.355 165.748C18.4145 165.688 18.4953 165.655 18.5796 165.655H79.558C79.6422 165.655 79.723 165.688 79.7825 165.748C79.8421 165.808 79.8756 165.888 79.8756 165.973C79.8756 166.057 79.8421 166.138 79.7825 166.197C79.723 166.257 79.6422 166.29 79.558 166.29Z"
          fill="#CCCCCC"
        />
        <path
          opacity="0.15"
          d="M128.679 70.5774C127.985 70.5774 127.437 70.3017 127.037 69.7503C126.637 69.1988 126.437 68.5308 126.437 67.7462C126.437 66.9563 126.63 66.2896 127.017 65.7462C127.404 65.2028 127.95 64.9313 128.655 64.9319C129.403 64.9319 129.966 65.2182 130.345 65.7908C130.724 66.3634 130.914 67.0261 130.914 67.779C130.914 68.5264 130.718 69.1798 130.325 69.7391C129.933 70.2984 129.385 70.5778 128.679 70.5774ZM128.7 69.9571C129.157 69.9571 129.498 69.7579 129.724 69.3596C129.951 68.9613 130.064 68.4591 130.064 67.8531C130.064 67.1785 129.948 66.6262 129.716 66.1961C129.485 65.766 129.13 65.5509 128.652 65.5508C127.742 65.5508 127.288 66.2731 127.288 67.7177C127.288 69.2108 127.758 69.9572 128.699 69.9571H128.7ZM128.929 75.8974H127.924L134.501 65.0424H135.487L128.929 75.8974ZM134.747 76.0088C134.042 76.0088 133.493 75.7306 133.098 75.1741C132.704 74.6176 132.506 73.9496 132.505 73.1698C132.505 72.3852 132.697 71.7212 133.081 71.1778C133.465 70.6344 134.013 70.3629 134.723 70.3635C135.465 70.3635 136.025 70.6498 136.401 71.2224C136.777 71.795 136.966 72.4576 136.966 73.2103C136.966 73.9529 136.771 74.605 136.381 75.1668C135.992 75.7285 135.447 76.0092 134.747 76.0088H134.747ZM134.759 75.3726C135.214 75.3726 135.554 75.1768 135.778 74.7853C136.003 74.3937 136.115 73.8936 136.115 73.2849C136.115 72.6334 136.002 72.087 135.774 71.6458C135.547 71.2046 135.193 70.9836 134.712 70.983C133.797 70.983 133.34 71.7053 133.34 73.1499C133.34 74.6318 133.813 75.3727 134.759 75.3726H134.759Z"
          fill="black"
        />
        <path
          d="M128.048 70.5775C127.354 70.5775 126.806 70.3018 126.406 69.7504C126.006 69.199 125.806 68.5309 125.806 67.7463C125.806 66.9564 125.999 66.2898 126.386 65.7463C126.773 65.2029 127.319 64.9315 128.024 64.932C128.772 64.932 129.335 65.2183 129.714 65.7909C130.093 66.3635 130.283 67.0263 130.283 67.7791C130.283 68.5265 130.087 69.1799 129.694 69.7392C129.302 70.2985 128.753 70.578 128.048 70.5775ZM128.069 69.9572C128.526 69.9572 128.867 69.7581 129.093 69.3597C129.319 68.9614 129.432 68.4592 129.432 67.8532C129.432 67.1787 129.317 66.6264 129.085 66.1963C128.854 65.7662 128.499 65.551 128.021 65.5509C127.111 65.5509 126.657 66.2732 126.657 67.7178C126.657 69.2109 127.127 69.9574 128.068 69.9572H128.069ZM128.298 75.8975H127.293L133.87 65.0426H134.856L128.298 75.8975ZM134.116 76.0089C133.411 76.0089 132.862 75.7307 132.467 75.1742C132.073 74.6178 131.875 73.9497 131.873 73.17C131.873 72.3854 132.066 71.7213 132.45 71.1779C132.834 70.6345 133.382 70.363 134.092 70.3636C134.834 70.3636 135.394 70.6499 135.77 71.2225C136.146 71.7951 136.335 72.4578 136.335 73.2105C136.335 73.953 136.14 74.6051 135.75 75.1669C135.361 75.7286 134.816 76.0093 134.116 76.0089H134.116ZM134.128 75.3727C134.583 75.3727 134.923 75.1769 135.147 74.7854C135.372 74.3938 135.484 73.8937 135.484 73.285C135.484 72.6335 135.37 72.0872 135.143 71.6459C134.916 71.2047 134.562 70.9838 134.08 70.9831C133.166 70.9831 132.708 71.7054 132.708 73.15C132.708 74.6319 133.181 75.3729 134.128 75.3727H134.128Z"
          fill="#3F3D56"
        />
        <path
          opacity="0.15"
          d="M128.679 101.014C127.985 101.014 127.437 100.739 127.037 100.187C126.637 99.6357 126.437 98.9677 126.437 98.1831C126.437 97.3932 126.63 96.7265 127.017 96.1831C127.404 95.6397 127.95 95.3682 128.655 95.3688C129.403 95.3688 129.966 95.6551 130.345 96.2277C130.724 96.8003 130.914 97.463 130.914 98.2159C130.914 98.9633 130.718 99.6167 130.325 100.176C129.933 100.735 129.385 101.015 128.679 101.014ZM128.7 100.394C129.157 100.394 129.498 100.195 129.724 99.7965C129.951 99.3981 130.064 98.896 130.064 98.29C130.064 97.6154 129.948 97.0631 129.716 96.633C129.485 96.2029 129.13 95.9878 128.652 95.9877C127.742 95.9877 127.288 96.71 127.288 98.1546C127.288 99.6477 127.758 100.394 128.699 100.394H128.7ZM128.929 106.334H127.924L134.501 95.4793H135.487L128.929 106.334ZM134.747 106.446C134.042 106.446 133.493 106.167 133.098 105.611C132.704 105.055 132.506 104.386 132.505 103.607C132.505 102.822 132.697 102.158 133.081 101.615C133.465 101.071 134.013 100.8 134.723 100.8C135.465 100.8 136.025 101.087 136.401 101.659C136.777 102.232 136.966 102.895 136.966 103.647C136.966 104.39 136.771 105.042 136.381 105.604C135.992 106.165 135.447 106.446 134.747 106.446H134.747ZM134.759 105.809C135.214 105.809 135.554 105.614 135.778 105.222C136.003 104.831 136.115 104.33 136.115 103.722C136.115 103.07 136.002 102.524 135.774 102.083C135.547 101.641 135.193 101.421 134.712 101.42C133.797 101.42 133.34 102.142 133.34 103.587C133.34 105.069 133.813 105.81 134.759 105.809H134.759Z"
          fill="black"
        />
        <path
          d="M128.048 101.014C127.354 101.014 126.806 100.739 126.406 100.187C126.006 99.6357 125.806 98.9676 125.806 98.183C125.806 97.3931 125.999 96.7265 126.386 96.183C126.773 95.6396 127.319 95.3682 128.024 95.3687C128.772 95.3687 129.335 95.655 129.714 96.2276C130.093 96.8002 130.283 97.463 130.283 98.2158C130.283 98.9632 130.087 99.6166 129.694 100.176C129.302 100.735 128.753 101.015 128.048 101.014ZM128.069 100.394C128.526 100.394 128.867 100.195 129.093 99.7964C129.319 99.3981 129.432 98.8959 129.432 98.2899C129.432 97.6154 129.317 97.0631 129.085 96.633C128.854 96.2029 128.499 95.9877 128.021 95.9876C127.111 95.9876 126.657 96.7099 126.657 98.1545C126.657 99.6476 127.127 100.394 128.068 100.394H128.069ZM128.298 106.334H127.293L133.87 95.4793H134.856L128.298 106.334ZM134.116 106.446C133.411 106.446 132.862 106.167 132.467 105.611C132.073 105.054 131.875 104.386 131.873 103.607C131.873 102.822 132.066 102.158 132.45 101.615C132.834 101.071 133.382 100.8 134.092 100.8C134.834 100.8 135.394 101.087 135.77 101.659C136.146 102.232 136.335 102.894 136.335 103.647C136.335 104.39 136.14 105.042 135.75 105.604C135.361 106.165 134.816 106.446 134.116 106.446H134.116ZM134.128 105.809C134.583 105.809 134.923 105.614 135.147 105.222C135.372 104.831 135.484 104.33 135.484 103.722C135.484 103.07 135.37 102.524 135.143 102.083C134.916 101.641 134.562 101.42 134.08 101.42C133.166 101.42 132.708 102.142 132.708 103.587C132.708 105.069 133.181 105.81 134.128 105.809H134.128Z"
          fill="#3F3D56"
        />
        <path
          opacity="0.15"
          d="M63.7475 93.9123C63.0529 93.9123 62.5055 93.6365 62.1052 93.0851C61.705 92.5337 61.5049 91.8656 61.5049 91.081C61.5049 90.2911 61.6984 89.6245 62.0855 89.0811C62.4726 88.5376 63.0186 88.2662 63.7235 88.2667C64.4711 88.2667 65.0343 88.553 65.4133 89.1256C65.7922 89.6982 65.9817 90.361 65.9819 91.1138C65.9819 91.8612 65.7857 92.5146 65.3935 93.0739C65.0013 93.6332 64.4526 93.9127 63.7475 93.9123ZM63.7679 93.2919C64.2249 93.2919 64.5664 93.0928 64.7925 92.6944C65.0186 92.2961 65.1317 91.7939 65.1317 91.1879C65.1317 90.5134 65.016 89.9611 64.7846 89.531C64.5532 89.1009 64.1983 88.8857 63.7199 88.8856C62.8105 88.8856 62.3559 89.6079 62.3559 91.0525C62.3559 92.5456 62.8264 93.2921 63.7675 93.2919H63.7679ZM63.9967 99.2323H62.992L69.5694 88.3773H70.5551L63.9967 99.2323ZM69.8155 99.3436C69.1104 99.3436 68.5608 99.0654 68.1665 98.5089C67.7722 97.9525 67.5743 97.2844 67.5726 96.5047C67.5726 95.7201 67.7648 95.0561 68.1492 94.5126C68.5336 93.9692 69.0809 93.6978 69.7913 93.6983C70.5335 93.6983 71.0929 93.9846 71.4692 94.5572C71.8456 95.1298 72.0338 95.7925 72.0339 96.5452C72.0339 97.2877 71.8391 97.9399 71.4495 98.5016C71.0598 99.0634 70.515 99.344 69.8151 99.3436H69.8155ZM69.8273 98.7074C70.2821 98.7074 70.6218 98.5117 70.8465 98.1201C71.0711 97.7285 71.1834 97.2284 71.1834 96.6197C71.1834 95.9682 71.0697 95.4219 70.8424 94.9807C70.615 94.5394 70.2609 94.3185 69.7799 94.3178C68.8653 94.3178 68.4079 95.0401 68.4079 96.4847C68.4077 97.9667 68.8807 98.7076 69.8269 98.7074H69.8273Z"
          fill="black"
        />
        <path
          d="M63.1162 93.9122C62.4216 93.9122 61.8741 93.6365 61.4739 93.085C61.0737 92.5336 60.8735 91.8656 60.8735 91.081C60.8735 90.2911 61.0671 89.6244 61.4542 89.081C61.8412 88.5376 62.3872 88.2661 63.0922 88.2667C63.8397 88.2667 64.403 88.553 64.7819 89.1256C65.1608 89.6982 65.3504 90.3609 65.3505 91.1138C65.3505 91.8612 65.1544 92.5145 64.7622 93.0738C64.3699 93.6332 63.8213 93.9126 63.1162 93.9122ZM63.1366 93.2919C63.5935 93.2919 63.9351 93.0927 64.1612 92.6944C64.3873 92.296 64.5003 91.7939 64.5003 91.1879C64.5003 90.5133 64.3846 89.961 64.1532 89.5309C63.9218 89.1008 63.5669 88.8857 63.0885 88.8856C62.1792 88.8856 61.7245 89.6079 61.7245 91.0525C61.7245 92.5456 62.1951 93.292 63.1361 93.2919H63.1366ZM63.3654 99.2322H62.3607L68.938 88.3772H69.9238L63.3654 99.2322ZM69.1842 99.3436C68.4791 99.3436 67.9294 99.0653 67.5351 98.5089C67.1409 97.9524 66.9429 97.2843 66.9413 96.5046C66.9413 95.72 67.1335 95.056 67.5178 94.5126C67.9022 93.9691 68.4496 93.6977 69.1599 93.6982C69.9022 93.6982 70.4615 93.9845 70.8379 94.5571C71.2142 95.1298 71.4025 95.7924 71.4026 96.5451C71.4026 97.2877 71.2078 97.9398 70.8181 98.5015C70.4285 99.0633 69.8837 99.344 69.1838 99.3436H69.1842ZM69.196 98.7074C69.6508 98.7074 69.9905 98.5116 70.2151 98.12C70.4397 97.7285 70.552 97.2283 70.552 96.6196C70.552 95.9682 70.4384 95.4218 70.211 94.9806C69.9837 94.5394 69.6294 94.3184 69.1481 94.3177C68.2335 94.3177 67.7762 95.04 67.7762 96.4847C67.7762 97.9666 68.2493 98.7075 69.1956 98.7074H69.196Z"
          fill="#3F3D56"
        />
        <path
          d="M255.649 154.951L261.772 151.351C260.945 149.937 259.886 148.67 258.641 147.604L247.093 151.195L256.141 145.876C253.9 144.649 251.379 144.027 248.825 144.07C246.271 144.113 243.773 144.821 241.575 146.123C239.378 147.425 237.557 149.277 236.293 151.496C235.028 153.716 234.363 156.226 234.364 158.78C234.364 166.904 240.951 167.184 249.075 167.184C257.198 167.184 263.786 166.904 263.786 158.78C263.791 156.721 263.36 154.685 262.522 152.804L255.649 154.951Z"
          fill="#1641DB"
        />
        <path
          opacity="0.1"
          d="M257.207 162.849C256.555 162.195 255.826 161.623 255.037 161.143C253.929 160.403 252.775 159.649 251.465 159.401C250.316 159.18 249.13 159.369 247.958 159.3C245.922 159.176 244.006 158.297 242.583 156.836C241.747 155.971 241.089 154.909 240.082 154.247C238.957 153.579 237.629 153.336 236.34 153.562C235.967 153.614 235.597 153.69 235.232 153.788C234.656 155.389 234.362 157.078 234.364 158.78C234.364 166.904 240.951 167.184 249.075 167.184C253.019 167.184 256.596 167.12 259.239 166.122L258.779 165.299C258.362 164.419 257.833 163.595 257.207 162.849Z"
          fill="black"
        />
      </svg>
    </SvgIcon>
  );
}
