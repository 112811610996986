// Core react imports
import React, { useState, useEffect } from "react";
// Mui imports
import { makeStyles, Box, Paper } from "@material-ui/core";
// Routing imports
import { useParams, useHistory } from "react-router-dom";
// Redux imports
import { useSelector, useDispatch } from "react-redux";
import {
  createField,
  deleteField,
  fetchApps,
  getCheckoutFields,
  rearrangeFields,
  editField,
} from "../../redux/apps/appActions";
// Icon imports
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
// Component imports
import NoExtraFields from "./components/NoExtraFields";
import CheckoutFieldsTableHead from "./components/CheckoutFieldsTableHead";
import CheckoutFieldTableRow from "./components/CheckoutFieldTableRow";
import CheckoutFieldsNotice from "./components/CheckoutFieldsNotice";
import CheckoutFieldForm from "./components/CheckoutFieldForm";
import ConfirmModal from "../../components/common/ConfirmModal";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Toast from "../../components/Layout/Toast";
import { layoutContainer } from "../../styles/common";
import SolidButton from "../../components/buttons/SolidButton";
import PageLoader from "../../components/Layout/PageLoader";
import HeaderBreadcrumbs from "../../components/common/HeaderBreadcrumbs";
import { reorder } from "../../components/common/CommonUtils";
// Making styles
const useStyles = makeStyles(() => ({
  backDiv: {
    marginBottom: "24px",
  },
  linkIcon: { color: "#1A1A1A", fontSize: "14px", verticalAlign: "middle" },
  linkText: {
    fontSize: "14px",
    color: "#1A1A1A",
    fontWeight: 600,
    lineHeight: "20px",
  },
  endIcon: {
    marginLeft: 12,
  },
  paperList: {
    boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.04);",
    padding: "0px 0px 15px 0px",
    marginTop: "40px",
    borderRadius: 6,
  },
}));

export default function CheckoutFields() {
  // using hooks
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();
  const appsState = useSelector((state) => state.apps);
  const store = useSelector((state) => state.store);
  const apps = appsState.apps;
  let fields = appsState.checkoutFields || [];
  const dispatch = useDispatch();
  // State related stuff
  const initState = {
    type: { value: "none", hasError: false, error: "", helpText: "" },
    name: {
      value: "",
      hasError: false,
      error: "",
      helpText: "",
      touched: false,
    },
    description: {
      value: "",
      hasError: false,
      error: "",
      helpText: "Description of the field, will help users in what to enter",
      touched: false,
    },
    list: {
      value: "",
      hasError: false,
      error: "",
      helpText:
        "List options must be seperated by commas Eg- Value1, Value2, Value 3",
      touched: false,
    },
    required: {
      value: true,
      hasError: false,
      error: "",
      helpText: "You can uncheck this field if not required",
      touched: false,
    },
  };
  const [showForm, setShowForm] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [fieldToDelete, setFieldToDelete] = useState(null);
  const [fieldToEdit, setFieldToEdit] = useState(null);
  const [localFields, setLocalFields] = useState(fields);
  const handleDelete = (field) => {
    setFieldToDelete(field);
    setIsConfirmationOpen(true);
  };
  const handleEdit = (field) => {
    setFieldToEdit(field);
    let updatedFormState = {};
    Object.keys(initState).forEach((key) => {
      updatedFormState[key] = { ...initState[key] };
      let fieldKey = key === "list" ? "data" : key;
      updatedFormState[key].value = field[fieldKey];
    });
    setFormState(updatedFormState);
    setShowForm(true);
  };
  const handleCancel = () => {
    setFormState(initState);
    setFieldToEdit(null);
    setShowForm(false);
  };
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const [formState, setFormState] = useState(initState);

  const validate = (formState, forceTouch = false) => {
    let requiredFields = ["type", "name"];
    if (formState.type.value === "LIST") {
      requiredFields.push("list");
    }
    for (let field of requiredFields) {
      if (
        (formState[field].touched || forceTouch) &&
        (field === "type"
          ? formState[field].value === "none"
          : !formState[field].value)
      ) {
        formState[field].hasError = true;
        formState[field].error = "This field is required";
      } else if (
        formState[field].touched &&
        formState[field].value &&
        formState[field].hasError
      ) {
        formState[field].hasError = false;
        formState[field].error = "";
      }
    }
    return formState;
  };
  const onFormFieldChange = (field, value) => {
    let clonedState = { ...formState };
    let data = { ...clonedState[field] };
    data["value"] = value;
    data.touched = true;
    clonedState[field] = data;
    setFormState(validate(clonedState));
  };

  const handleFormSubmit = () => {
    let data = {};
    for (let key of Object.keys(formState)) {
      data[key] = { ...formState[key] };
    }
    let validatedData = validate(data, true);
    for (let key in validatedData) {
      if (validatedData[key].hasError) {
        if (key === "type") {
          setErrorMessage("Please select a field type");
        } else {
          setErrorMessage("Please make sure to clear all errors");
        }
        setFormState(validatedData);
        return;
      }
    }
    let fieldData = {
      type: formState.type.value,
      required: formState.required.value,
      description: formState.description.value,
      name: formState.name.value,
      data: formState.type.value === "LIST" ? formState.list.value : "",
    };
    if (fieldToEdit) {
      dispatch(editField(fieldToEdit.slug, fieldData));
    } else {
      dispatch(createField(fieldData));
    }
    setShowForm(false);
    setFieldToEdit(null);
    setFormState(initState);
  };

  // Selecting current app from slug
  let fieldsApp = apps.filter((app) => app.slug === "checkout-fields")[0];

  useEffect(() => {
    if (!apps.length) {
      dispatch(fetchApps());
    } else if (!fieldsApp || !fieldsApp.installed) {
      history.push(`/${params.storeId}/apps/checkout-fields`);
    }
    // eslint-disable-next-line
  }, [params.storeId, apps, fieldsApp]);

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    marginTop: 40,
    padding: 0,
    ...draggableStyle,
  });
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const { items, change } = reorder(
      fields,
      result.source.index,
      result.destination.index,
      "slug"
    );
    setLocalFields(items);
    dispatch(rearrangeFields(change));
  };

  useEffect(() => {
    if (store.fetched) {
      dispatch(getCheckoutFields());
    }
    // eslint-disable-next-line
  }, [store]);

  useEffect(() => {
    setLocalFields(fields);
  }, [fields]);

  useEffect(() => {
    if (appsState.checkoutActionSuccess) {
      setMessage(appsState.checkoutMessage);
    } else if (appsState.checkoutActionFailed) {
      setErrorMessage(appsState.checkoutMessage);
    }
  }, [
    appsState.checkoutMessage,
    appsState.checkoutActionFailed,
    appsState.checkoutActionSuccess,
  ]);

  return (
    <div className={layoutContainer}>
      {!showForm ? (
        <>
          <div className={classes.backDiv}>
            <HeaderBreadcrumbs
              list={[
                {
                  label: "App Store",
                  link: "/apps",
                },
                {
                  label: "Extra Checkout Field",
                  link: `/apps/checkout-fields`,
                },
                {
                  label: "Configure",
                },
              ]}
            />
          </div>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            {((fields && fields.length < 10) || !fields) && (
              <SolidButton
                endIcon={<AddBoxOutlinedIcon />}
                onClick={() => {
                  setShowForm(true);
                }}
              >
                Add Field
              </SolidButton>
            )}
          </Box>
          {fields && fields.length ? (
            <>
              <Paper elevation={0} className={classes.paperList}>
                <CheckoutFieldsNotice />
                <CheckoutFieldsTableHead />
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="fields" type="fields" onD>
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{ position: "relative", width: "100%" }}
                      >
                        {localFields.map((field, idx) => {
                          return (
                            <Draggable
                              key={field.id}
                              draggableId={`${field.id}`}
                              index={idx}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  <CheckoutFieldTableRow
                                    field={field}
                                    showDivider={idx !== fields.length - 1}
                                    handleProps={provided.dragHandleProps}
                                    handleDelete={handleDelete}
                                    handleEdit={handleEdit}
                                  />
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Paper>
            </>
          ) : appsState.checkoutLoading || !apps.length ? (
            <PageLoader />
          ) : (
            <NoExtraFields />
          )}
        </>
      ) : (
        <CheckoutFieldForm
          formState={formState}
          onFormFieldChange={onFormFieldChange}
          handleSave={handleFormSubmit}
          handleCancel={handleCancel}
        />
      )}
      {fieldToDelete && (
        <ConfirmModal
          open={isConfirmationOpen}
          title={"This field will be deleted"}
          message={`Remove ${fieldToDelete.name} from Checkout Fields?`}
          confirmBtnText="Delete"
          closeBtnText="Cancel"
          onClose={() => setIsConfirmationOpen(false)}
          onConfirm={() => {
            dispatch(deleteField(fieldToDelete.slug));
            setIsConfirmationOpen(false);
          }}
        />
      )}
      <Toast
        open={errorMessage.length > 0}
        close={() => setErrorMessage("")}
        message={errorMessage}
        severity="error"
      />
      <Toast
        open={message.length > 0}
        close={() => setMessage("")}
        message={message}
      />
    </div>
  );
}
