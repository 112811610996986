import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const NoBannerFigma = () => {
  return (
    <SvgIcon
      viewBox={"0 0 492 369"}
      style={{ width: "492px", height: "369px" }}
    >
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M270.788 9.10961C255.139 3.87363 238.601 -0.509899 221.718 0.0480045C196.878 0.85194 173.086 13.1397 161.398 31.1832C151.496 46.4684 150.275 64.3871 146.01 81.3495C140.756 102.252 130.694 122.115 119.941 141.541C114.164 151.961 108.072 162.634 107.125 174.072C105.807 189.95 115.032 205.776 129.494 216.348C143.956 226.921 163.175 232.482 182.445 233.699C192.706 234.347 203.425 233.747 212.54 229.818C222.181 225.659 229.208 218.143 238.887 214.047C247.859 210.236 258.351 209.716 268.422 210.211C281.613 210.861 294.611 213.134 306.983 216.955C321.058 221.307 334.147 227.607 348.192 232.018C362.237 236.429 377.929 238.893 392.286 235.21C405.758 231.744 416.427 223.358 426.039 214.851C441.815 200.888 456.204 185.905 469.067 170.046C477.736 159.359 485.802 148.083 489.638 135.626C496.964 111.812 486.88 84.9429 464.507 68.6771C445.982 55.2042 421.104 49.3549 396.757 45.8065C374.557 42.57 348.887 42.369 328.451 33.9346C308.957 25.8779 291.224 15.95 270.788 9.10961Z"
          fill="#DFE4F5"
        />
        <g clipPath="url(#clip0_9585_5217)">
          <path
            d="M458.734 85.9999H134.723C133.489 86.001 132.305 86.4918 131.433 87.3644C130.56 88.2371 130.069 89.4204 130.068 90.6545V310.214C130.069 311.448 130.56 312.631 131.433 313.504C132.305 314.376 133.489 314.867 134.723 314.868H458.734C459.407 314.868 460.072 314.722 460.683 314.44C461.294 314.158 461.837 313.747 462.274 313.235C462.311 313.195 462.344 313.152 462.375 313.107C462.653 312.767 462.878 312.387 463.042 311.98C463.276 311.421 463.396 310.82 463.394 310.214V90.6545C463.393 89.4195 462.901 88.2356 462.028 87.3628C461.154 86.49 459.969 85.9998 458.734 85.9999ZM462.065 310.214C462.066 310.863 461.875 311.498 461.515 312.039C461.196 312.516 460.763 312.904 460.256 313.171C459.785 313.414 459.264 313.54 458.734 313.539H134.723C133.841 313.538 132.996 313.187 132.373 312.564C131.749 311.941 131.398 311.095 131.397 310.214V90.6545C131.398 89.7729 131.749 88.9277 132.373 88.3042C132.996 87.6808 133.841 87.3301 134.723 87.329H458.734C459.617 87.3289 460.463 87.6791 461.088 88.3026C461.712 88.9261 462.064 89.772 462.065 90.6545V310.214Z"
            fill="#3F3D56"
          />
          <path d="M456 169H138V291H456V169Z" fill="white" />
          <path
            d="M462.727 104.656H130.731V105.986H462.727V104.656Z"
            fill="#3F3D56"
          />
          <path
            d="M142.042 99.9716C144.246 99.9716 146.034 98.1844 146.034 95.9797C146.034 93.775 144.246 91.9878 142.042 91.9878C139.837 91.9878 138.05 93.775 138.05 95.9797C138.05 98.1844 139.837 99.9716 142.042 99.9716Z"
            fill="#3F3D56"
          />
          <path
            d="M153.518 99.9716C155.723 99.9716 157.51 98.1844 157.51 95.9797C157.51 93.775 155.723 91.9878 153.518 91.9878C151.314 91.9878 149.526 93.775 149.526 95.9797C149.526 98.1844 151.314 99.9716 153.518 99.9716Z"
            fill="#3F3D56"
          />
          <path
            d="M164.995 99.9716C167.2 99.9716 168.987 98.1844 168.987 95.9797C168.987 93.775 167.2 91.9878 164.995 91.9878C162.79 91.9878 161.003 93.775 161.003 95.9797C161.003 98.1844 162.79 99.9716 164.995 99.9716Z"
            fill="#3F3D56"
          />
          <path
            d="M423.358 201.123H164.469C162.063 201.121 159.757 200.164 158.055 198.463C156.354 196.761 155.397 194.455 155.395 192.049V137.068C155.397 134.663 156.354 132.356 158.055 130.655C159.757 128.954 162.063 127.997 164.469 127.994H423.358C425.764 127.997 428.07 128.954 429.772 130.655C431.473 132.356 432.43 134.663 432.432 137.068V192.049C432.43 194.455 431.473 196.761 429.772 198.463C428.07 200.164 425.764 201.121 423.358 201.123ZM164.469 129.062C162.346 129.064 160.311 129.908 158.81 131.409C157.309 132.91 156.465 134.946 156.462 137.068V192.049C156.465 194.172 157.309 196.207 158.81 197.708C160.311 199.209 162.346 200.053 164.469 200.056H423.358C425.481 200.053 427.516 199.209 429.017 197.708C430.518 196.207 431.362 194.172 431.365 192.049V137.068C431.362 134.946 430.518 132.91 429.017 131.409C427.516 129.908 425.481 129.064 423.358 129.062H164.469Z"
            fill="#F2F2F2"
          />
          <path
            d="M221.585 300.409H164.469C162.063 300.406 159.757 299.449 158.055 297.748C156.354 296.047 155.397 293.74 155.395 291.334V250.766C155.397 248.36 156.354 246.054 158.055 244.352C159.757 242.651 162.063 241.694 164.469 241.692H221.585C223.991 241.694 226.297 242.651 227.998 244.352C229.7 246.054 230.656 248.36 230.659 250.766V291.334C230.656 293.74 229.7 296.047 227.998 297.748C226.297 299.449 223.991 300.406 221.585 300.409ZM164.469 242.759C162.346 242.762 160.311 243.606 158.81 245.107C157.309 246.608 156.465 248.643 156.462 250.766V291.334C156.465 293.457 157.309 295.492 158.81 296.993C160.311 298.494 162.346 299.339 164.469 299.341H221.585C223.708 299.339 225.743 298.494 227.244 296.993C228.745 295.492 229.589 293.457 229.592 291.334V250.766C229.589 248.643 228.745 246.608 227.244 245.107C225.743 243.606 223.708 242.762 221.585 242.759H164.469Z"
            fill="#F2F2F2"
          />
          <path
            d="M423.357 300.409H366.241C363.836 300.406 361.529 299.449 359.828 297.748C358.127 296.047 357.17 293.74 357.167 291.334V250.766C357.17 248.36 358.127 246.054 359.828 244.352C361.529 242.651 363.836 241.694 366.241 241.692H423.357C425.763 241.694 428.07 242.651 429.771 244.352C431.472 246.054 432.429 248.36 432.432 250.766V291.334C432.429 293.74 431.472 296.047 429.771 297.748C428.07 299.449 425.763 300.406 423.357 300.409ZM366.241 242.759C364.119 242.762 362.083 243.606 360.582 245.107C359.081 246.608 358.237 248.643 358.235 250.766V291.334C358.237 293.457 359.081 295.492 360.582 296.993C362.083 298.494 364.119 299.339 366.241 299.341H423.357C425.48 299.339 427.515 298.494 429.016 296.993C430.517 295.492 431.362 293.457 431.364 291.334V250.766C431.362 248.643 430.517 246.608 429.016 245.107C427.515 243.606 425.48 242.762 423.357 242.759H366.241Z"
            fill="#F2F2F2"
          />
          <path
            d="M326.742 300.409H269.626C267.22 300.406 264.914 299.449 263.213 297.748C261.511 296.047 260.554 293.74 260.552 291.334V250.766C260.554 248.36 261.511 246.054 263.213 244.352C264.914 242.651 267.22 241.694 269.626 241.692H326.742C329.148 241.694 331.454 242.651 333.156 244.352C334.857 246.054 335.814 248.36 335.816 250.766V291.334C335.814 293.74 334.857 296.047 333.156 297.748C331.454 299.449 329.148 300.406 326.742 300.409ZM269.626 242.759C267.503 242.762 265.468 243.606 263.967 245.107C262.466 246.608 261.622 248.643 261.619 250.766V291.334C261.622 293.457 262.466 295.492 263.967 296.993C265.468 298.494 267.503 299.339 269.626 299.341H326.742C328.865 299.339 330.9 298.494 332.401 296.993C333.902 295.492 334.746 293.457 334.749 291.334V250.766C334.746 248.643 333.902 246.608 332.401 245.107C330.9 243.606 328.865 242.762 326.742 242.759H269.626Z"
            fill="#F2F2F2"
          />
          <path
            d="M158.57 130.497L158.316 131.534L431.771 198.439L432.024 197.402L158.57 130.497Z"
            fill="#F2F2F2"
          />
          <path
            d="M429.251 130.481L158.32 197.6L158.577 198.637L429.508 131.517L429.251 130.481Z"
            fill="#F2F2F2"
          />
          <path
            d="M434.033 219.539H175.144C172.809 219.536 170.571 218.608 168.92 216.957C167.268 215.305 166.34 213.067 166.337 210.732V155.751C166.34 153.416 167.268 151.177 168.92 149.526C170.571 147.875 172.809 146.946 175.144 146.943H434.033C436.368 146.946 438.607 147.875 440.258 149.526C441.909 151.177 442.838 153.416 442.841 155.751V210.732C442.838 213.067 441.909 215.305 440.258 216.956C438.607 218.608 436.368 219.536 434.033 219.539Z"
            fill="white"
          />
          <path
            d="M264.131 244.102L263.475 244.944L331.888 298.311L332.545 297.469L264.131 244.102Z"
            fill="#F2F2F2"
          />
          <path
            d="M332.23 244.102L264.714 298.128L265.381 298.961L332.897 244.936L332.23 244.102Z"
            fill="#F2F2F2"
          />
          <path
            d="M158.973 244.102L158.316 244.944L226.73 298.312L227.387 297.47L158.973 244.102Z"
            fill="#F2F2F2"
          />
          <path
            d="M227.073 244.102L159.557 298.128L160.224 298.961L227.74 244.936L227.073 244.102Z"
            fill="#F2F2F2"
          />
          <path
            d="M360.748 244.101L360.092 244.943L428.506 298.311L429.162 297.469L360.748 244.101Z"
            fill="#F2F2F2"
          />
          <path
            d="M428.846 244.102L361.33 298.128L361.997 298.961L429.513 244.936L428.846 244.102Z"
            fill="#F2F2F2"
          />
          <path
            d="M334.748 339.109H277.633C275.298 339.106 273.059 338.177 271.408 336.526C269.757 334.875 268.828 332.636 268.825 330.301V289.733C268.828 287.398 269.757 285.159 271.408 283.508C273.059 281.857 275.298 280.928 277.633 280.925H334.748C337.084 280.928 339.322 281.857 340.973 283.508C342.625 285.159 343.553 287.398 343.556 289.733V330.301C343.553 332.636 342.625 334.875 340.973 336.526C339.322 338.177 337.084 339.106 334.748 339.109Z"
            fill="#BEBEBE"
          />
          <path
            d="M364.58 339.909H247.679C247.538 339.909 247.402 339.853 247.302 339.753C247.202 339.653 247.146 339.517 247.146 339.376C247.146 339.234 247.202 339.098 247.302 338.998C247.402 338.898 247.538 338.842 247.679 338.842H364.58C364.721 338.842 364.857 338.898 364.957 338.998C365.057 339.098 365.113 339.234 365.113 339.376C365.113 339.517 365.057 339.653 364.957 339.753C364.857 339.853 364.721 339.909 364.58 339.909Z"
            fill="#CCCCCC"
          />
          <path
            d="M117.434 368.2H0.533792C0.392221 368.2 0.256449 368.144 0.156344 368.044C0.0562382 367.944 0 367.808 0 367.666C0 367.525 0.0562382 367.389 0.156344 367.289C0.256449 367.189 0.392221 367.133 0.533792 367.133H117.434C117.576 367.133 117.711 367.189 117.812 367.289C117.912 367.389 117.968 367.525 117.968 367.666C117.968 367.808 117.912 367.944 117.812 368.044C117.711 368.144 117.576 368.2 117.434 368.2Z"
            fill="#CCCCCC"
          />
          <path
            d="M313.639 307.081H298.693C297.561 307.081 296.474 306.631 295.674 305.83C294.873 305.029 294.423 303.943 294.423 302.811C294.423 301.678 294.873 300.592 295.674 299.791C296.474 298.99 297.561 298.54 298.693 298.54H313.639C314.772 298.54 315.858 298.99 316.659 299.791C317.46 300.592 317.91 301.678 317.91 302.811C317.91 303.943 317.46 305.029 316.659 305.83C315.858 306.631 314.772 307.081 313.639 307.081Z"
            fill="white"
          />
          <path
            d="M322.471 321.493H289.91C288.777 321.493 287.691 321.044 286.89 320.243C286.09 319.442 285.64 318.356 285.64 317.223C285.64 316.091 286.09 315.004 286.89 314.204C287.691 313.403 288.777 312.953 289.91 312.953H322.471C323.604 312.953 324.69 313.403 325.491 314.204C326.292 315.004 326.742 316.091 326.742 317.223C326.742 318.356 326.292 319.442 325.491 320.243C324.69 321.044 323.604 321.493 322.471 321.493Z"
            fill="white"
          />
          <path
            d="M230.66 308.149H173.544C171.209 308.146 168.97 307.217 167.319 305.566C165.668 303.915 164.739 301.676 164.736 299.341V258.773C164.739 256.438 165.668 254.199 167.319 252.548C168.97 250.897 171.209 249.968 173.544 249.965H230.66C232.995 249.968 235.233 250.897 236.885 252.548C238.536 254.199 239.465 256.438 239.467 258.773V299.341C239.465 301.676 238.536 303.915 236.885 305.566C235.233 307.217 232.995 308.146 230.66 308.149Z"
            fill="#1641DB"
          />
          <path
            d="M209.549 276.121H194.602C193.47 276.121 192.384 275.671 191.583 274.87C190.782 274.07 190.332 272.983 190.332 271.851C190.332 270.718 190.782 269.632 191.583 268.831C192.384 268.03 193.47 267.581 194.602 267.581H209.549C210.681 267.581 211.767 268.03 212.568 268.831C213.369 269.632 213.819 270.718 213.819 271.851C213.819 272.983 213.369 274.07 212.568 274.87C211.767 275.671 210.681 276.121 209.549 276.121Z"
            fill="white"
          />
          <path
            d="M218.381 290.534H185.82C184.688 290.534 183.601 290.084 182.801 289.283C182 288.482 181.55 287.396 181.55 286.263C181.55 285.131 182 284.045 182.801 283.244C183.601 282.443 184.688 281.993 185.82 281.993H218.381C219.514 281.993 220.6 282.443 221.401 283.244C222.202 284.045 222.652 285.131 222.652 286.263C222.652 287.396 222.202 288.482 221.401 289.283C220.6 290.084 219.514 290.534 218.381 290.534Z"
            fill="white"
          />
          <path
            d="M435.635 323.629H378.519C376.184 323.626 373.946 322.697 372.295 321.046C370.643 319.395 369.715 317.156 369.712 314.821V274.253C369.715 271.918 370.643 269.679 372.295 268.028C373.946 266.377 376.184 265.448 378.519 265.445H435.635C437.97 265.448 440.209 266.377 441.86 268.028C443.511 269.679 444.44 271.918 444.443 274.253V314.821C444.44 317.156 443.511 319.395 441.86 321.046C440.209 322.697 437.97 323.626 435.635 323.629Z"
            fill="#E6E6E6"
          />
          <path
            d="M414.525 291.601H399.579C398.446 291.601 397.36 291.151 396.559 290.35C395.758 289.549 395.309 288.463 395.309 287.331C395.309 286.198 395.758 285.112 396.559 284.311C397.36 283.51 398.446 283.06 399.579 283.06H414.525C415.658 283.06 416.744 283.51 417.545 284.311C418.345 285.112 418.795 286.198 418.795 287.331C418.795 288.463 418.345 289.549 417.545 290.35C416.744 291.151 415.658 291.601 414.525 291.601Z"
            fill="white"
          />
          <path
            d="M423.357 306.014H390.796C389.663 306.014 388.577 305.564 387.776 304.763C386.975 303.962 386.525 302.876 386.525 301.743C386.525 300.611 386.975 299.524 387.776 298.724C388.577 297.923 389.663 297.473 390.796 297.473H423.357C424.49 297.473 425.576 297.923 426.377 298.724C427.177 299.524 427.627 300.611 427.627 301.743C427.627 302.876 427.177 303.962 426.377 304.763C425.576 305.564 424.49 306.014 423.357 306.014Z"
            fill="white"
          />
          <path
            d="M465.465 324.429H348.565C348.423 324.429 348.288 324.373 348.188 324.273C348.087 324.173 348.031 324.037 348.031 323.895C348.031 323.754 348.087 323.618 348.188 323.518C348.288 323.418 348.423 323.362 348.565 323.362H465.465C465.607 323.362 465.743 323.418 465.843 323.518C465.943 323.618 465.999 323.754 465.999 323.895C465.999 324.037 465.943 324.173 465.843 324.273C465.743 324.373 465.607 324.429 465.465 324.429Z"
            fill="#CCCCCC"
          />
          <path
            d="M362.649 218.597V219.272H248.578V218.566L249.192 217.697L285.357 166.642C285.524 166.41 285.744 166.221 285.999 166.09C286.254 165.96 286.536 165.892 286.822 165.892C287.108 165.892 287.39 165.96 287.645 166.09C287.899 166.221 288.12 166.41 288.287 166.642L312.537 200.877L329.8 176.721C329.968 176.49 330.187 176.302 330.442 176.172C330.696 176.043 330.977 175.975 331.263 175.975C331.548 175.975 331.829 176.043 332.083 176.172C332.338 176.302 332.558 176.49 332.725 176.721L362.036 217.734L362.649 218.597Z"
            fill="#1641DB"
          />
          <path
            d="M248.341 184.628C255.673 184.628 261.618 178.683 261.618 171.351C261.618 164.018 255.673 158.073 248.341 158.073C241.008 158.073 235.063 164.018 235.063 171.351C235.063 178.683 241.008 184.628 248.341 184.628Z"
            fill="#1641DB"
          />
          <path
            d="M434.034 219.806H175.145C172.739 219.803 170.432 218.847 168.731 217.145C167.03 215.444 166.073 213.138 166.07 210.732V155.751C166.073 153.345 167.03 151.039 168.731 149.338C170.432 147.636 172.739 146.679 175.145 146.677H434.034C436.44 146.679 438.746 147.636 440.447 149.338C442.149 151.039 443.105 153.345 443.108 155.751V210.732C443.105 213.138 442.149 215.444 440.447 217.145C438.746 218.847 436.44 219.803 434.034 219.806ZM175.145 147.744C173.022 147.747 170.987 148.591 169.486 150.092C167.985 151.593 167.14 153.628 167.138 155.751V210.732C167.14 212.855 167.985 214.89 169.486 216.391C170.987 217.892 173.022 218.736 175.145 218.739H434.034C436.156 218.736 438.192 217.892 439.693 216.391C441.194 214.89 442.038 212.855 442.041 210.732V155.751C442.038 153.628 441.194 151.593 439.693 150.092C438.192 148.591 436.156 147.747 434.034 147.744H175.145Z"
            fill="#3F3D56"
          />
          <path
            d="M126.131 204.026C125.44 204.337 124.687 204.5 123.923 204.502C123.16 204.504 122.405 204.346 121.712 204.038C121.019 203.731 120.406 203.283 119.914 202.724C119.422 202.165 119.064 201.51 118.865 200.805L83.8055 201.475L87.2751 191.424L122.899 194.541C124.16 194.3 125.469 194.51 126.579 195.131C127.688 195.752 128.521 196.741 128.919 197.91C129.318 199.08 129.254 200.348 128.74 201.475C128.226 202.602 127.298 203.51 126.131 204.026Z"
            fill="#FECFB4"
          />
          <path
            d="M49.8556 192.768L46.2374 189.411C45.1542 188.406 44.3492 186.779 43.9846 184.86C43.6201 182.94 43.7233 180.87 44.2733 179.068L44.1491 179.004C44.6862 177.343 45.5635 176.029 46.6334 175.284C47.7034 174.538 48.9007 174.406 50.0243 174.91L89.1426 191.606L87.7281 206.637L49.8556 192.768Z"
            fill="#D0CDE1"
          />
          <path
            d="M57.649 163.933C50.5737 163.933 44.838 158.317 44.838 151.389C44.838 144.461 50.5737 138.845 57.649 138.845C64.7243 138.845 70.46 144.461 70.46 151.389C70.46 158.317 64.7243 163.933 57.649 163.933Z"
            fill="#FECFB4"
          />
          <path
            d="M48.3883 161.555C50.8169 164.098 55.3272 162.733 55.6437 159.259C55.6685 158.989 55.6666 158.718 55.6383 158.448C55.4749 156.91 54.5705 155.513 54.7871 153.888C54.8361 153.484 54.9894 153.098 55.2324 152.768C57.1677 150.221 61.7106 153.908 63.5371 151.602C64.657 150.188 63.3405 147.962 64.2 146.382C65.3343 144.296 68.694 145.325 70.8009 144.182C73.1452 142.912 73.0049 139.376 71.4618 137.226C69.5799 134.604 66.2803 133.204 63.0219 133.003C59.7634 132.801 56.5274 133.667 53.4853 134.832C50.029 136.156 46.6015 137.986 44.4744 140.973C41.8878 144.606 41.6388 149.49 42.9325 153.738C43.7194 156.322 46.4051 159.478 48.3883 161.555Z"
            fill="#2F2E41"
          />
          <path
            d="M50.1776 361.436H56.326L59.251 338.483L50.1765 338.484L50.1776 361.436Z"
            fill="#FECFB4"
          />
          <path
            d="M48.5753 367.308L68.3252 367.307V367.055C68.325 364.999 67.5151 363.027 66.0735 361.572C64.6319 360.118 62.6767 359.301 60.638 359.301H60.6375L48.5749 359.302L48.5753 367.308Z"
            fill="#2F2E41"
          />
          <path
            d="M98.785 360.903L104.623 359.511L102.029 336.348L93.4134 338.402L98.785 360.903Z"
            fill="#FECFB4"
          />
          <path
            d="M98.9722 367.308L117.969 362.748L117.911 362.506C117.444 360.533 116.216 358.828 114.499 357.765C112.781 356.703 110.715 356.37 108.754 356.841L108.753 356.841L97.1509 359.626L98.9722 367.308Z"
            fill="#2F2E41"
          />
          <path
            d="M83.4218 242.681L107.292 353.833L92.3423 352.94L66.5666 289.945L62.6818 353.542L46.3523 354.497L46.5517 262.383C46.5517 262.383 39.7394 247.9 45.6469 239.994C51.5545 232.088 83.4218 242.681 83.4218 242.681Z"
            fill="#43434B"
          />
          <path
            d="M47.7921 169.588L62.3086 169.271H64.0598C71.0288 174.826 73.9566 187.186 74.9354 195.928L85.4062 247.991C85.4062 247.991 58.8898 255.391 43.8189 246.753L42.9764 212.631C42.9764 212.631 39.2551 177.939 47.7921 169.588Z"
            fill="#D0CDE1"
          />
          <path
            d="M117.143 245.587C116.387 245.531 115.653 245.311 114.992 244.942C114.331 244.574 113.76 244.065 113.318 243.453C112.876 242.841 112.574 242.14 112.434 241.399C112.294 240.659 112.319 239.896 112.507 239.166L84.6064 220.515L93.147 212.775L119.186 235.495C120.398 235.89 121.422 236.714 122.064 237.811C122.706 238.907 122.922 240.2 122.67 241.444C122.418 242.688 121.716 243.797 120.697 244.56C119.679 245.324 118.414 245.689 117.143 245.587Z"
            fill="#FECFB4"
          />
          <path
            d="M51.5157 191.714L48.0807 185.966C47.0525 184.245 46.7246 182.204 47.1633 180.255C47.602 178.305 48.7746 176.592 50.4444 175.461C51.9814 174.421 53.8388 173.942 55.6959 174.108C57.5529 174.273 59.2928 175.073 60.6152 176.369L94.748 213.042L85.4067 224.786L51.5157 191.714Z"
            fill="#D0CDE1"
          />
        </g>
        <defs>
          <clipPath id="clip0_9585_5217">
            <rect
              width="466"
              height="282.2"
              fill="white"
              transform="translate(0 86)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default NoBannerFigma;
