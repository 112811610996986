import { Box, Button, Grid, Typography } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useStyles from "../settings/styles";
import AboutSetting from "../settings/components/AboutSetting";
import HeaderSetting from "../settings/components/HeaderSetting";
import HomePageSetting from "../settings/components/HomePageSetting";
import TermsSettings from "../settings/components/TermsSettings";
import GeneralSetting from "../settings/components/GeneralSetting";
import { layoutBodyBox, layoutLoaderHeader } from "../../styles/common";
import Toast from "../../components/Layout/Toast";
import { useHistory } from "react-router-dom";
import { getStoreUrl } from "../../utils/storeUtil";
import { THEME_COLORS } from "../../utils/utils";
import HeaderBreadcrumbs from "../../components/common/HeaderBreadcrumbs";

const Display = () => {
  const classes = useStyles();
  const history = useHistory();
  const store = useSelector((state) => state.store.store);
  const initialState = {
    general: true,
    header: false,
    aboutUs: false,
    terms: false,
    home: false,
  };
  const [settings, setSettings] = useState(initialState);
  const { general, header, aboutUs, terms, home } = settings;
  const [about, setAbout] = useState({});
  const [tos, setTos] = useState([]);
  const [attributes, setAttributes] = useState({});
  const [toast, setToast] = useState(false);
  const [scrollIndex, setScrollIndex] = useState(0);
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedFontFamily, setSelectedFontFamily] = useState("Noto Sans");
  const [borderRadiusForButtons, setBorderRadiusForButtons] =
    useState("medium"); // "square", "medium", "rounded"
  const storeUrl = getStoreUrl(store);

  useEffect(() => {
    //Theme settings
    if (store.store_setting && store.store_setting.theme_color) {
      setSelectedColor(store.store_setting.theme_color.toUpperCase());
      let index = THEME_COLORS.findIndex(
        (color) => color.code === store.store_setting.theme_color.toUpperCase()
      );
      if (index > THEME_COLORS.length - 5) {
        index = THEME_COLORS.length - 5;
      }
      setScrollIndex(index);
    }
    if (store.store_setting && store.store_setting.font_family) {
      setSelectedFontFamily(store.store_setting.font_family);
    }
  }, [store]);

  useEffect(() => {
    if (attributes?.site_settings?.border_radius_for_button) {
      setBorderRadiusForButtons(
        attributes.site_settings.border_radius_for_button
      );
    }
  }, [attributes]);

  useEffect(() => {
    if (store.slug) {
      axios
        .get(
          `${process.env.REACT_APP_API}/api/v1/org/store/${store.slug}/pages?attr-group=socialmedia`
        )
        .then((res) => {
          let data = res.data.payload;
          let temp = [];
          data.forEach((item) => {
            if (item.slug === "about-us") {
              setAbout(item);
            } else {
              temp.push(item);
            }
          });
          setTos(temp);
        });
      axios
        .get(
          `${process.env.REACT_APP_API}/api/v1/store/external/slug/${store.slug}?attributes=product_settings,category_settings,header,site_settings,product_card_settings,product_page_settings,common`
        )
        .then((res) => {
          setAttributes(res.data.payload.attributes);
        });
    }
  }, [store]);

  const onSettingClick = (set) => {
    setSettings({
      ...initialState,
      general: set !== "general" ? false : true,
      [set]: true,
    });
  };

  const onClose = () => {
    history.goBack();
  };

  return (
    <Grid container spacing={2}>
      <div className={layoutLoaderHeader}>
        <HeaderBreadcrumbs list={[{ label: "Display Settings" }]} />
      </div>

      <Grid item xs={12}>
        <Box
          style={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 20,
          }}
        >
          <Box
            className={layoutBodyBox}
            style={{
              minWidth: "600px",
              maxWidth: "50%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                padding: "0px 0px 0px 20px",
              }}
            >
              <Button
                className={classes.subSetting}
                onClick={() => onSettingClick("general")}
                style={{
                  color: general ? "#1641DB" : "#1A1A1A",
                }}
              >
                <Typography
                  style={{
                    borderBottom: general
                      ? "4px solid #1641DB"
                      : "4px solid #FFFFFF",
                  }}
                  className={classes.subSettingText}
                >
                  General
                </Typography>
              </Button>
              <Button
                className={classes.subSetting}
                onClick={() => onSettingClick("header")}
                style={{
                  color: header ? "#1641DB" : "#1A1A1A",
                }}
              >
                <Typography
                  style={{
                    borderBottom: header
                      ? "4px solid #1641DB"
                      : "4px solid #FFFFFF",
                  }}
                  className={classes.subSettingText}
                >
                  Header
                </Typography>
              </Button>
              <Button
                className={classes.subSetting}
                onClick={() => onSettingClick("home")}
                style={{
                  color: home ? "#1641DB" : "#1A1A1A",
                }}
              >
                <Typography
                  style={{
                    borderBottom: home
                      ? "4px solid #1641DB"
                      : "4px solid #FFFFFF",
                  }}
                  className={classes.subSettingText}
                >
                  Home Page
                </Typography>
              </Button>
              <Button
                className={classes.subSetting}
                onClick={() => onSettingClick("aboutUs")}
                style={{
                  color: aboutUs ? "#1641DB" : "#1A1A1A",
                }}
              >
                <Typography
                  style={{
                    borderBottom: aboutUs
                      ? "4px solid #1641DB"
                      : "4px solid #FFFFFF",
                  }}
                  className={classes.subSettingText}
                >
                  About Us
                </Typography>
              </Button>
              <Button
                className={classes.subSetting}
                onClick={() => onSettingClick("terms")}
                style={{
                  color: terms ? "#1641DB" : "#1A1A1A",
                }}
              >
                <Typography
                  style={{
                    borderBottom: terms
                      ? "4px solid #1641DB"
                      : "4px solid #FFFFFF",
                  }}
                  className={classes.subSettingText}
                >
                  Terms Of Service
                </Typography>
              </Button>
            </div>
            <hr className={classes.divider} />
            <div style={{ padding: "0px 32px 32px" }}>
              {general && (
                <GeneralSetting
                  store={store}
                  onClose={onClose}
                  setToast={setToast}
                  scrollIndex={scrollIndex}
                  setScrollIndex={setScrollIndex}
                  selectedColor={selectedColor}
                  setSelectedColor={setSelectedColor}
                  selectedFontFamily={selectedFontFamily}
                  setSelectedFontFamily={setSelectedFontFamily}
                  attributes={attributes}
                  setAttributes={setAttributes}
                  borderRadiusForButtons={borderRadiusForButtons}
                  setBorderRadiusForButtons={setBorderRadiusForButtons}
                />
              )}
              {header && (
                <HeaderSetting
                  store={store}
                  attributes={attributes}
                  setAttributes={setAttributes}
                  onClose={onClose}
                  setToast={setToast}
                />
              )}
              {home && (
                <HomePageSetting
                  store={store}
                  onClose={onClose}
                  setToast={setToast}
                  attributes={attributes}
                  setAttributes={setAttributes}
                />
              )}
              {aboutUs && (
                <AboutSetting
                  store={store}
                  onClose={onClose}
                  about={about}
                  setAbout={setAbout}
                  setToast={setToast}
                />
              )}
              {terms && (
                <TermsSettings
                  store={store}
                  onClose={onClose}
                  tos={tos}
                  setTos={setTos}
                  setToast={setToast}
                />
              )}
            </div>
          </Box>
          {general && store.slug && (
            <Box
              style={{
                marginTop: 30,
                width: "100%",
              }}
            >
              <iframe
                title="Theme Preview"
                src={`${storeUrl}?theme=${
                  selectedColor ? selectedColor.replace("#", "") : ""
                }&font_family=${selectedFontFamily ? selectedFontFamily : ""}`}
                className={classes.fixedIframe}
                scrolling="yes"
              />
            </Box>
          )}
        </Box>
      </Grid>

      <Toast
        open={toast}
        close={setToast}
        message="Settings Saved Successfully"
      />
    </Grid>
  );
};

export default Display;
