import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const NoInvoiceFigma = () => {
  return (
    <SvgIcon
      viewBox={"0 0 500 396"}
      style={{ width: "500px", height: "396px" }}
    >
      <svg
        width="500"
        height="396"
        viewBox="0 0 500 396"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M228.69 11.5913C212.286 4.92892 194.95 -0.648811 177.253 0.0610823C151.214 1.08403 126.274 16.7193 114.023 39.6784C103.643 59.1277 102.363 81.9281 97.8923 103.511C92.3845 130.108 81.8367 155.382 70.565 180.101C64.51 193.359 58.1239 206.94 57.1309 221.495C55.7495 241.698 65.4191 261.835 80.5789 275.288C95.7386 288.741 115.885 295.817 136.085 297.365C146.841 298.19 158.077 297.427 167.632 292.427C177.738 287.136 185.104 277.572 195.25 272.36C204.655 267.51 215.653 266.849 226.21 267.479C240.037 268.306 253.662 271.198 266.631 276.06C281.385 281.598 295.106 289.614 309.829 295.227C324.552 300.84 341 303.975 356.05 299.288C370.172 294.878 381.356 284.208 391.431 273.383C407.969 255.616 423.052 236.55 436.535 216.371C445.623 202.773 454.078 188.425 458.099 172.574C465.778 142.273 455.208 108.084 431.756 87.3867C412.337 70.2435 386.259 62.8006 360.737 58.2855C337.465 54.1673 310.557 53.9115 289.135 43.1794C268.701 32.9278 250.112 20.2952 228.69 11.5913Z"
          fill="#DFE4F5"
        />
        <path
          d="M113.565 87.2867C116.011 87.2867 117.993 85.3076 117.993 82.8663C117.993 80.4249 116.011 78.4458 113.565 78.4458C111.12 78.4458 109.138 80.4249 109.138 82.8663C109.138 85.3076 111.12 87.2867 113.565 87.2867Z"
          fill="white"
        />
        <path
          d="M135.978 87.2867C138.423 87.2867 140.406 85.3076 140.406 82.8663C140.406 80.4249 138.423 78.4458 135.978 78.4458C133.533 78.4458 131.55 80.4249 131.55 82.8663C131.55 85.3076 133.533 87.2867 135.978 87.2867Z"
          fill="white"
        />
        <path
          d="M158.391 87.2867C160.836 87.2867 162.818 85.3076 162.818 82.8663C162.818 80.4249 160.836 78.4458 158.391 78.4458C155.945 78.4458 153.963 80.4249 153.963 82.8663C153.963 85.3076 155.945 87.2867 158.391 87.2867Z"
          fill="white"
        />
        <path
          d="M180.803 87.2867C183.249 87.2867 185.231 85.3076 185.231 82.8663C185.231 80.4249 183.249 78.4458 180.803 78.4458C178.358 78.4458 176.376 80.4249 176.376 82.8663C176.376 85.3076 178.358 87.2867 180.803 87.2867Z"
          fill="#1641DB"
        />
        <path
          d="M203.216 87.2867C205.661 87.2867 207.644 85.3076 207.644 82.8663C207.644 80.4249 205.661 78.4458 203.216 78.4458C200.771 78.4458 198.788 80.4249 198.788 82.8663C198.788 85.3076 200.771 87.2867 203.216 87.2867Z"
          fill="#E6E6E6"
        />
        <path
          d="M248.041 87.2867C250.486 87.2867 252.469 85.3076 252.469 82.8663C252.469 80.4249 250.486 78.4458 248.041 78.4458C245.596 78.4458 243.613 80.4249 243.613 82.8663C243.613 85.3076 245.596 87.2867 248.041 87.2867Z"
          fill="#E6E6E6"
        />
        <path
          d="M113.565 109.944C116.011 109.944 117.993 107.965 117.993 105.523C117.993 103.082 116.011 101.103 113.565 101.103C111.12 101.103 109.138 103.082 109.138 105.523C109.138 107.965 111.12 109.944 113.565 109.944Z"
          fill="white"
        />
        <path
          d="M135.978 109.944C138.423 109.944 140.406 107.965 140.406 105.523C140.406 103.082 138.423 101.103 135.978 101.103C133.533 101.103 131.55 103.082 131.55 105.523C131.55 107.965 133.533 109.944 135.978 109.944Z"
          fill="white"
        />
        <path
          d="M158.391 109.944C160.836 109.944 162.818 107.965 162.818 105.523C162.818 103.082 160.836 101.103 158.391 101.103C155.945 101.103 153.963 103.082 153.963 105.523C153.963 107.965 155.945 109.944 158.391 109.944Z"
          fill="white"
        />
        <path
          d="M180.803 109.944C183.249 109.944 185.231 107.965 185.231 105.523C185.231 103.082 183.249 101.103 180.803 101.103C178.358 101.103 176.376 103.082 176.376 105.523C176.376 107.965 178.358 109.944 180.803 109.944Z"
          fill="white"
        />
        <path
          d="M203.216 109.944C205.661 109.944 207.644 107.965 207.644 105.523C207.644 103.082 205.661 101.103 203.216 101.103C200.771 101.103 198.788 103.082 198.788 105.523C198.788 107.965 200.771 109.944 203.216 109.944Z"
          fill="#E6E6E6"
        />
        <path
          d="M113.565 132.601C116.011 132.601 117.993 130.622 117.993 128.181C117.993 125.739 116.011 123.76 113.565 123.76C111.12 123.76 109.138 125.739 109.138 128.181C109.138 130.622 111.12 132.601 113.565 132.601Z"
          fill="white"
        />
        <path
          d="M135.978 132.601C138.423 132.601 140.406 130.622 140.406 128.181C140.406 125.739 138.423 123.76 135.978 123.76C133.533 123.76 131.55 125.739 131.55 128.181C131.55 130.622 133.533 132.601 135.978 132.601Z"
          fill="white"
        />
        <path
          d="M158.391 132.601C160.836 132.601 162.818 130.622 162.818 128.181C162.818 125.739 160.836 123.76 158.391 123.76C155.945 123.76 153.963 125.739 153.963 128.181C153.963 130.622 155.945 132.601 158.391 132.601Z"
          fill="white"
        />
        <path
          d="M180.803 132.601C183.249 132.601 185.231 130.622 185.231 128.181C185.231 125.739 183.249 123.76 180.803 123.76C178.358 123.76 176.376 125.739 176.376 128.181C176.376 130.622 178.358 132.601 180.803 132.601Z"
          fill="white"
        />
        <path
          d="M203.216 132.601C205.661 132.601 207.644 130.622 207.644 128.181C207.644 125.739 205.661 123.76 203.216 123.76C200.771 123.76 198.788 125.739 198.788 128.181C198.788 130.622 200.771 132.601 203.216 132.601Z"
          fill="#E6E6E6"
        />
        <path
          d="M225.628 132.601C228.074 132.601 230.056 130.622 230.056 128.181C230.056 125.739 228.074 123.76 225.628 123.76C223.183 123.76 221.201 125.739 221.201 128.181C221.201 130.622 223.183 132.601 225.628 132.601Z"
          fill="white"
        />
        <path
          d="M248.041 132.601C250.486 132.601 252.469 130.622 252.469 128.181C252.469 125.739 250.486 123.76 248.041 123.76C245.596 123.76 243.613 125.739 243.613 128.181C243.613 130.622 245.596 132.601 248.041 132.601Z"
          fill="white"
        />
        <path
          d="M270.454 132.601C272.899 132.601 274.881 130.622 274.881 128.181C274.881 125.739 272.899 123.76 270.454 123.76C268.008 123.76 266.026 125.739 266.026 128.181C266.026 130.622 268.008 132.601 270.454 132.601Z"
          fill="white"
        />
        <path
          d="M292.866 132.601C295.312 132.601 297.294 130.622 297.294 128.181C297.294 125.739 295.312 123.76 292.866 123.76C290.421 123.76 288.439 125.739 288.439 128.181C288.439 130.622 290.421 132.601 292.866 132.601Z"
          fill="white"
        />
        <path
          d="M315.279 132.601C317.724 132.601 319.707 130.622 319.707 128.181C319.707 125.739 317.724 123.76 315.279 123.76C312.834 123.76 310.851 125.739 310.851 128.181C310.851 130.622 312.834 132.601 315.279 132.601Z"
          fill="white"
        />
        <path
          d="M203.216 155.258C205.661 155.258 207.644 153.279 207.644 150.838C207.644 148.396 205.661 146.417 203.216 146.417C200.771 146.417 198.788 148.396 198.788 150.838C198.788 153.279 200.771 155.258 203.216 155.258Z"
          fill="white"
        />
        <path
          d="M225.628 155.258C228.074 155.258 230.056 153.279 230.056 150.838C230.056 148.396 228.074 146.417 225.628 146.417C223.183 146.417 221.201 148.396 221.201 150.838C221.201 153.279 223.183 155.258 225.628 155.258Z"
          fill="#1641DB"
        />
        <path
          d="M248.041 155.258C250.486 155.258 252.469 153.279 252.469 150.838C252.469 148.396 250.486 146.417 248.041 146.417C245.596 146.417 243.613 148.396 243.613 150.838C243.613 153.279 245.596 155.258 248.041 155.258Z"
          fill="white"
        />
        <path
          d="M270.454 155.258C272.899 155.258 274.881 153.279 274.881 150.838C274.881 148.396 272.899 146.417 270.454 146.417C268.008 146.417 266.026 148.396 266.026 150.838C266.026 153.279 268.008 155.258 270.454 155.258Z"
          fill="white"
        />
        <path
          d="M292.866 155.258C295.312 155.258 297.294 153.279 297.294 150.838C297.294 148.396 295.312 146.417 292.866 146.417C290.421 146.417 288.439 148.396 288.439 150.838C288.439 153.279 290.421 155.258 292.866 155.258Z"
          fill="#E6E6E6"
        />
        <path
          d="M315.279 155.258C317.724 155.258 319.707 153.279 319.707 150.838C319.707 148.396 317.724 146.417 315.279 146.417C312.834 146.417 310.851 148.396 310.851 150.838C310.851 153.279 312.834 155.258 315.279 155.258Z"
          fill="#E6E6E6"
        />
        <path
          d="M203.216 177.915C205.661 177.915 207.644 175.936 207.644 173.495C207.644 171.053 205.661 169.074 203.216 169.074C200.771 169.074 198.788 171.053 198.788 173.495C198.788 175.936 200.771 177.915 203.216 177.915Z"
          fill="white"
        />
        <path
          d="M225.628 177.915C228.074 177.915 230.056 175.936 230.056 173.495C230.056 171.053 228.074 169.074 225.628 169.074C223.183 169.074 221.201 171.053 221.201 173.495C221.201 175.936 223.183 177.915 225.628 177.915Z"
          fill="white"
        />
        <path
          d="M248.041 177.915C250.486 177.915 252.469 175.936 252.469 173.495C252.469 171.053 250.486 169.074 248.041 169.074C245.596 169.074 243.613 171.053 243.613 173.495C243.613 175.936 245.596 177.915 248.041 177.915Z"
          fill="white"
        />
        <path
          d="M270.454 177.915C272.899 177.915 274.881 175.936 274.881 173.495C274.881 171.053 272.899 169.074 270.454 169.074C268.008 169.074 266.026 171.053 266.026 173.495C266.026 175.936 268.008 177.915 270.454 177.915Z"
          fill="white"
        />
        <path
          d="M292.866 177.915C295.312 177.915 297.294 175.936 297.294 173.495C297.294 171.053 295.312 169.074 292.866 169.074C290.421 169.074 288.439 171.053 288.439 173.495C288.439 175.936 290.421 177.915 292.866 177.915Z"
          fill="#1641DB"
        />
        <path
          d="M315.279 177.915C317.724 177.915 319.707 175.936 319.707 173.495C319.707 171.053 317.724 169.074 315.279 169.074C312.834 169.074 310.851 171.053 310.851 173.495C310.851 175.936 312.834 177.915 315.279 177.915Z"
          fill="#E6E6E6"
        />
        <path
          d="M203.216 200.572C205.661 200.572 207.644 198.593 207.644 196.152C207.644 193.711 205.661 191.732 203.216 191.732C200.771 191.732 198.788 193.711 198.788 196.152C198.788 198.593 200.771 200.572 203.216 200.572Z"
          fill="white"
        />
        <path
          d="M225.628 200.572C228.074 200.572 230.056 198.593 230.056 196.152C230.056 193.711 228.074 191.732 225.628 191.732C223.183 191.732 221.201 193.711 221.201 196.152C221.201 198.593 223.183 200.572 225.628 200.572Z"
          fill="white"
        />
        <path
          d="M248.041 200.572C250.486 200.572 252.469 198.593 252.469 196.152C252.469 193.711 250.486 191.732 248.041 191.732C245.596 191.732 243.613 193.711 243.613 196.152C243.613 198.593 245.596 200.572 248.041 200.572Z"
          fill="white"
        />
        <path
          d="M270.454 200.572C272.899 200.572 274.881 198.593 274.881 196.152C274.881 193.711 272.899 191.732 270.454 191.732C268.008 191.732 266.026 193.711 266.026 196.152C266.026 198.593 268.008 200.572 270.454 200.572Z"
          fill="white"
        />
        <path
          d="M292.866 200.572C295.312 200.572 297.294 198.593 297.294 196.152C297.294 193.711 295.312 191.732 292.866 191.732C290.421 191.732 288.439 193.711 288.439 196.152C288.439 198.593 290.421 200.572 292.866 200.572Z"
          fill="white"
        />
        <path
          d="M315.279 200.572C317.724 200.572 319.707 198.593 319.707 196.152C319.707 193.711 317.724 191.732 315.279 191.732C312.834 191.732 310.851 193.711 310.851 196.152C310.851 198.593 312.834 200.572 315.279 200.572Z"
          fill="#E6E6E6"
        />
        <path
          d="M113.565 223.23C116.011 223.23 117.993 221.25 117.993 218.809C117.993 216.368 116.011 214.389 113.565 214.389C111.12 214.389 109.138 216.368 109.138 218.809C109.138 221.25 111.12 223.23 113.565 223.23Z"
          fill="white"
        />
        <path
          d="M135.978 223.23C138.423 223.23 140.406 221.25 140.406 218.809C140.406 216.368 138.423 214.389 135.978 214.389C133.533 214.389 131.55 216.368 131.55 218.809C131.55 221.25 133.533 223.23 135.978 223.23Z"
          fill="white"
        />
        <path
          d="M158.391 223.23C160.836 223.23 162.818 221.25 162.818 218.809C162.818 216.368 160.836 214.389 158.391 214.389C155.945 214.389 153.963 216.368 153.963 218.809C153.963 221.25 155.945 223.23 158.391 223.23Z"
          fill="white"
        />
        <path
          d="M180.803 223.23C183.249 223.23 185.231 221.25 185.231 218.809C185.231 216.368 183.249 214.389 180.803 214.389C178.358 214.389 176.376 216.368 176.376 218.809C176.376 221.25 178.358 223.23 180.803 223.23Z"
          fill="white"
        />
        <path
          d="M203.216 223.23C205.661 223.23 207.644 221.25 207.644 218.809C207.644 216.368 205.661 214.389 203.216 214.389C200.771 214.389 198.788 216.368 198.788 218.809C198.788 221.25 200.771 223.23 203.216 223.23Z"
          fill="white"
        />
        <path
          d="M225.628 223.23C228.074 223.23 230.056 221.25 230.056 218.809C230.056 216.368 228.074 214.389 225.628 214.389C223.183 214.389 221.201 216.368 221.201 218.809C221.201 221.25 223.183 223.23 225.628 223.23Z"
          fill="white"
        />
        <path
          d="M248.041 223.23C250.486 223.23 252.469 221.25 252.469 218.809C252.469 216.368 250.486 214.389 248.041 214.389C245.596 214.389 243.613 216.368 243.613 218.809C243.613 221.25 245.596 223.23 248.041 223.23Z"
          fill="white"
        />
        <path
          d="M270.454 223.23C272.899 223.23 274.881 221.25 274.881 218.809C274.881 216.368 272.899 214.389 270.454 214.389C268.008 214.389 266.026 216.368 266.026 218.809C266.026 221.25 268.008 223.23 270.454 223.23Z"
          fill="white"
        />
        <path
          d="M292.866 223.23C295.312 223.23 297.294 221.25 297.294 218.809C297.294 216.368 295.312 214.389 292.866 214.389C290.421 214.389 288.439 216.368 288.439 218.809C288.439 221.25 290.421 223.23 292.866 223.23Z"
          fill="white"
        />
        <path
          d="M315.279 223.23C317.724 223.23 319.707 221.25 319.707 218.809C319.707 216.368 317.724 214.389 315.279 214.389C312.834 214.389 310.851 216.368 310.851 218.809C310.851 221.25 312.834 223.23 315.279 223.23Z"
          fill="#E6E6E6"
        />
        <path
          d="M113.563 245.886C116.008 245.886 117.99 243.907 117.99 241.466C117.99 239.024 116.008 237.045 113.563 237.045C111.118 237.045 109.135 239.024 109.135 241.466C109.135 243.907 111.118 245.886 113.563 245.886Z"
          fill="white"
        />
        <path
          d="M135.978 245.887C138.423 245.887 140.406 243.908 140.406 241.466C140.406 239.025 138.423 237.046 135.978 237.046C133.533 237.046 131.55 239.025 131.55 241.466C131.55 243.908 133.533 245.887 135.978 245.887Z"
          fill="white"
        />
        <path
          d="M113.563 268.543C116.008 268.543 117.99 266.564 117.99 264.123C117.99 261.681 116.008 259.702 113.563 259.702C111.117 259.702 109.135 261.681 109.135 264.123C109.135 266.564 111.117 268.543 113.563 268.543Z"
          fill="white"
        />
        <path
          d="M135.978 268.544C138.423 268.544 140.406 266.565 140.406 264.123C140.406 261.682 138.423 259.703 135.978 259.703C133.533 259.703 131.55 261.682 131.55 264.123C131.55 266.565 133.533 268.544 135.978 268.544Z"
          fill="white"
        />
        <path
          d="M180.803 268.544C183.249 268.544 185.231 266.565 185.231 264.123C185.231 261.682 183.249 259.703 180.803 259.703C178.358 259.703 176.376 261.682 176.376 264.123C176.376 266.565 178.358 268.544 180.803 268.544Z"
          fill="#E6E6E6"
        />
        <path
          d="M404.205 107.941H228.746C227.885 107.94 227.059 107.598 226.45 106.99C225.841 106.382 225.498 105.557 225.497 104.697V61.244C225.498 60.3839 225.841 59.5594 226.45 58.9513C227.059 58.3431 227.885 58.001 228.746 58H404.205C405.067 58.001 405.893 58.3431 406.502 58.9513C407.111 59.5594 407.454 60.3839 407.455 61.244V104.697C407.454 105.557 407.111 106.382 406.502 106.99C405.893 107.598 405.067 107.94 404.205 107.941ZM228.746 59.2976C228.229 59.2982 227.734 59.5034 227.368 59.8683C227.003 60.2332 226.797 60.7279 226.797 61.244V104.697C226.797 105.213 227.003 105.708 227.368 106.073C227.734 106.438 228.229 106.643 228.746 106.644H404.205C404.722 106.643 405.218 106.438 405.583 106.073C405.949 105.708 406.154 105.213 406.155 104.697V61.244C406.154 60.7279 405.949 60.2332 405.583 59.8683C405.218 59.5034 404.722 59.2982 404.205 59.2976H228.746Z"
          fill="white"
        />
        <path
          d="M251.491 96.5949C259.028 96.5949 265.138 90.495 265.138 82.9703C265.138 75.4456 259.028 69.3456 251.491 69.3456C243.954 69.3456 237.844 75.4456 237.844 82.9703C237.844 90.495 243.954 96.5949 251.491 96.5949Z"
          fill="#1641DB"
        />
        <path
          d="M283.009 73.8872C282.405 73.8872 281.827 74.1264 281.4 74.5523C280.974 74.9782 280.734 75.5557 280.734 76.158C280.734 76.7602 280.974 77.3378 281.4 77.7637C281.827 78.1895 282.405 78.4288 283.009 78.4288H343.444C344.048 78.4288 344.626 78.1895 345.053 77.7637C345.479 77.3378 345.719 76.7602 345.719 76.158C345.719 75.5557 345.479 74.9782 345.053 74.5523C344.626 74.1264 344.048 73.8872 343.444 73.8872H283.009Z"
          fill="white"
        />
        <path
          d="M283.009 87.5118C282.405 87.5118 281.827 87.7511 281.4 88.1769C280.974 88.6028 280.734 89.1804 280.734 89.7826C280.734 90.3849 280.974 90.9624 281.4 91.3883C281.827 91.8142 282.405 92.0534 283.009 92.0534H390.233C390.837 92.0534 391.415 91.8142 391.842 91.3883C392.268 90.9624 392.508 90.3849 392.508 89.7826C392.508 89.1804 392.268 88.6028 391.842 88.1769C391.415 87.7511 390.837 87.5118 390.233 87.5118H283.009Z"
          fill="white"
        />
        <path
          d="M178.708 196.177H3.24924C2.3878 196.176 1.56193 195.834 0.952797 195.226C0.343666 194.618 0.00100956 193.793 0 192.933V149.48C0.00100956 148.62 0.343666 147.795 0.952797 147.187C1.56193 146.579 2.3878 146.237 3.24924 146.236H178.708C179.57 146.237 180.396 146.579 181.005 147.187C181.614 147.795 181.957 148.62 181.958 149.48V192.933C181.957 193.793 181.614 194.618 181.005 195.226C180.396 195.834 179.57 196.176 178.708 196.177ZM3.24924 147.534C2.73236 147.534 2.23681 147.739 1.87133 148.104C1.50584 148.469 1.30026 148.964 1.29969 149.48V192.933C1.30026 193.449 1.50584 193.944 1.87133 194.309C2.23681 194.674 2.73236 194.879 3.24924 194.88H178.708C179.225 194.879 179.721 194.674 180.086 194.309C180.452 193.944 180.657 193.449 180.658 192.933V149.48C180.657 148.964 180.452 148.469 180.086 148.104C179.721 147.739 179.225 147.534 178.708 147.534H3.24924Z"
          fill="white"
        />
        <path
          d="M25.9939 184.831C33.5308 184.831 39.6407 178.731 39.6407 171.206C39.6407 163.682 33.5308 157.582 25.9939 157.582C18.457 157.582 12.3471 163.682 12.3471 171.206C12.3471 178.731 18.457 184.831 25.9939 184.831Z"
          fill="#1641DB"
        />
        <path
          d="M57.5115 162.123C56.9083 162.123 56.3297 162.363 55.9032 162.788C55.4766 163.214 55.237 163.792 55.237 164.394C55.237 164.996 55.4766 165.574 55.9032 166C56.3297 166.426 56.9083 166.665 57.5115 166.665H120.547C121.15 166.665 121.728 166.426 122.155 166C122.582 165.574 122.821 164.996 122.821 164.394C122.821 163.792 122.582 163.214 122.155 162.788C121.728 162.363 121.15 162.123 120.547 162.123H57.5115Z"
          fill="#E6E6E6"
        />
        <path
          d="M57.5115 175.748C56.9083 175.748 56.3297 175.987 55.9032 176.413C55.4766 176.839 55.237 177.416 55.237 178.019C55.237 178.621 55.4766 179.199 55.9032 179.624C56.3297 180.05 56.9083 180.289 57.5115 180.289H164.736C165.34 180.289 165.918 180.05 166.345 179.624C166.771 179.199 167.011 178.621 167.011 178.019C167.011 177.416 166.771 176.839 166.345 176.413C165.918 175.987 165.34 175.748 164.736 175.748H57.5115Z"
          fill="white"
        />
        <path
          d="M337.271 291.55H161.812C160.951 291.549 160.125 291.207 159.516 290.599C158.906 289.991 158.564 289.166 158.563 288.306V244.853C158.564 243.993 158.906 243.168 159.516 242.56C160.125 241.952 160.951 241.61 161.812 241.609H337.271C338.132 241.61 338.958 241.952 339.567 242.56C340.177 243.168 340.519 243.993 340.52 244.853V288.306C340.519 289.166 340.177 289.991 339.567 290.599C338.958 291.207 338.132 291.549 337.271 291.55ZM161.812 242.906C161.295 242.907 160.8 243.112 160.434 243.477C160.069 243.842 159.863 244.337 159.862 244.853V288.306C159.863 288.822 160.069 289.317 160.434 289.682C160.8 290.047 161.295 290.252 161.812 290.252H337.271C337.788 290.252 338.283 290.047 338.649 289.682C339.014 289.317 339.22 288.822 339.221 288.306V244.853C339.22 244.337 339.014 243.842 338.649 243.477C338.283 243.112 337.788 242.907 337.271 242.906H161.812Z"
          fill="#3F3D56"
        />
        <path
          d="M184.557 280.204C192.094 280.204 198.203 274.104 198.203 266.579C198.203 259.055 192.094 252.955 184.557 252.955C177.02 252.955 170.91 259.055 170.91 266.579C170.91 274.104 177.02 280.204 184.557 280.204Z"
          fill="#1641DB"
        />
        <path
          d="M216.074 257.496C215.471 257.496 214.893 257.735 214.466 258.161C214.039 258.587 213.8 259.165 213.8 259.767C213.8 260.369 214.039 260.947 214.466 261.373C214.893 261.798 215.471 262.038 216.074 262.038H273.911C274.514 262.038 275.092 261.798 275.519 261.373C275.946 260.947 276.185 260.369 276.185 259.767C276.185 259.165 275.946 258.587 275.519 258.161C275.092 257.735 274.514 257.496 273.911 257.496H216.074Z"
          fill="#E6E6E6"
        />
        <path
          d="M216.074 271.121C215.471 271.121 214.893 271.36 214.466 271.786C214.039 272.212 213.8 272.789 213.8 273.392C213.8 273.994 214.039 274.571 214.466 274.997C214.893 275.423 215.471 275.662 216.074 275.662H323.299C323.902 275.662 324.481 275.423 324.907 274.997C325.334 274.571 325.574 273.994 325.574 273.392C325.574 272.789 325.334 272.212 324.907 271.786C324.481 271.36 323.902 271.121 323.299 271.121H216.074Z"
          fill="#E6E6E6"
        />
        <path
          d="M431.661 280.53C424.041 303.352 392.868 279.146 392.868 279.146L401.181 192.696C413.65 183.014 426.812 191.313 426.812 191.313L431.661 280.53Z"
          fill="#E6E6E6"
        />
        <path
          d="M430.024 357.126L440.646 357.238L437.993 387.806L430.025 387.806L430.024 357.126Z"
          fill="#FFB8B8"
        />
        <path
          d="M427.993 385.209H443.683C446.334 385.212 448.875 386.265 450.75 388.136C452.624 390.008 453.679 392.545 453.682 395.192V395.516H427.993V385.209Z"
          fill="#2F2E41"
        />
        <path
          d="M401.229 357.126L389.471 357.126L393.261 387.806L401.228 387.807L401.229 357.126Z"
          fill="#FFB8B8"
        />
        <path
          d="M377.571 395.516V395.191C377.571 392.544 378.625 390.005 380.5 388.133C382.375 386.26 384.918 385.209 387.57 385.209L403.26 385.21L403.26 395.517L377.571 395.516Z"
          fill="#2F2E41"
        />
        <path
          d="M429.307 382.511L415.392 301.527L402.499 381.467L389.437 382.192L385.954 275.085L386.216 275.026C388.376 274.544 439.203 263.398 447.535 279.342L447.577 279.423L440.623 382.875L429.307 382.511Z"
          fill="#2F2E41"
        />
        <path
          d="M414.56 182.565C423.375 182.565 430.52 175.431 430.52 166.63C430.52 157.829 423.375 150.695 414.56 150.695C405.745 150.695 398.599 157.829 398.599 166.63C398.599 175.431 405.745 182.565 414.56 182.565Z"
          fill="#FFB8B8"
        />
        <path
          d="M427.968 287.359C426.097 286.842 424.749 284.697 423.726 280.608C423.549 279.903 406.978 209.428 420.992 196.66L424.222 187.701L428.643 190.643C430.207 190.658 446.202 191.062 447.569 201.298C448.907 211.318 452.55 278.449 448.888 282.624C448.817 282.716 448.726 282.791 448.621 282.844C448.517 282.896 448.403 282.925 448.286 282.929C444.575 282.929 443.448 278.818 443.184 277.507C442.301 277.872 439.673 279.213 435.354 283.525C432.679 286.196 430.581 287.477 428.848 287.477C428.551 287.477 428.255 287.438 427.968 287.359Z"
          fill="#2F2E41"
        />
        <path
          d="M393.331 283.525C389.011 279.212 386.382 277.871 385.501 277.507C385.237 278.818 384.11 282.929 380.399 282.929C380.282 282.925 380.168 282.896 380.064 282.844C379.96 282.791 379.868 282.716 379.797 282.624C376.135 278.449 379.778 211.318 381.116 201.298C382.483 191.062 398.478 190.658 400.043 190.643L404.463 187.701L407.694 196.66C421.707 209.427 405.136 279.903 404.96 280.608C403.936 284.697 402.588 286.842 400.717 287.359C400.43 287.438 400.135 287.477 399.838 287.477C398.104 287.477 396.006 286.196 393.331 283.525Z"
          fill="#2F2E41"
        />
        <path
          d="M427.737 175.733C430.437 173.794 432.482 171.08 433.599 167.952C434.717 164.824 434.854 161.431 433.993 158.224C433.132 155.016 431.313 152.147 428.778 149.996C426.244 147.846 423.113 146.517 419.804 146.187C418.335 146.04 416.752 146.062 415.534 145.228C414.452 144.488 413.869 143.205 412.874 142.351C412.242 141.863 411.517 141.51 410.742 141.315C409.968 141.12 409.161 141.087 408.373 141.218C406.8 141.489 405.276 141.989 403.849 142.702C400.309 144.277 396.547 146.216 394.937 149.735C393.542 152.783 394.158 156.541 396.132 159.251C398.209 161.933 401.236 163.717 404.591 164.237C407.926 164.735 411.332 164.429 414.524 163.344C416.63 162.683 418.71 161.781 420.917 161.707C422.64 161.65 424.341 162.105 425.803 163.015C427.266 163.926 428.424 165.25 429.131 166.819C429.838 168.389 430.061 170.133 429.773 171.829C429.484 173.526 428.696 175.098 427.51 176.347"
          fill="#2F2E41"
        />
        <path
          d="M340.742 270.527C341.663 270.506 342.572 270.304 343.415 269.932C344.258 269.56 345.02 269.026 345.656 268.361C346.293 267.695 346.791 266.911 347.124 266.053C347.457 265.194 347.618 264.279 347.596 263.359L356.904 247.68L349.713 241.098L339.908 256.546C338.099 256.719 336.428 257.587 335.246 258.966C334.064 260.344 333.465 262.127 333.573 263.938C333.681 265.749 334.488 267.448 335.825 268.677C337.162 269.906 338.925 270.569 340.742 270.527Z"
          fill="#FFB8B8"
        />
        <path
          d="M448.729 287.704C449.501 287.201 450.166 286.552 450.687 285.793C451.208 285.033 451.574 284.179 451.764 283.279C451.954 282.378 451.964 281.449 451.795 280.545C451.625 279.64 451.279 278.778 450.775 278.007L450.419 259.784L440.833 257.969L440.646 276.256C439.199 277.355 438.236 278.971 437.959 280.764C437.681 282.557 438.111 284.388 439.158 285.871C440.206 287.354 441.788 288.374 443.573 288.716C445.358 289.058 447.206 288.695 448.729 287.704Z"
          fill="#FFB8B8"
        />
        <path
          d="M343.573 245.317L343.465 245.144L343.572 244.972C357.533 222.589 386.839 194.63 387.133 194.35L387.226 194.262L390.845 194.225L394.758 203.957C393.54 206.296 360.709 252.039 356.373 255.073C356.028 255.311 355.637 255.475 355.224 255.552C354.812 255.63 354.387 255.62 353.979 255.523C353.257 255.357 352.569 255.065 351.949 254.66C347.948 252.186 343.778 245.64 343.573 245.317Z"
          fill="#2F2E41"
        />
        <path
          d="M439.009 270.858C438.62 270.725 438.263 270.513 437.96 270.236C437.658 269.959 437.416 269.621 437.25 269.246C435.16 264.377 444.119 202.873 444.501 200.257L444.573 199.762L447.602 201.678L447.632 201.809C447.721 202.201 456.543 241.383 454.151 267.419L454.134 267.605L453.964 267.683C453.6 267.852 446.252 271.221 441.355 271.221C440.557 271.242 439.763 271.119 439.009 270.858Z"
          fill="#2F2E41"
        />
        <path
          d="M499.35 396H326.491C326.318 396 326.153 395.932 326.031 395.81C325.909 395.688 325.841 395.523 325.841 395.351C325.841 395.179 325.909 395.014 326.031 394.892C326.153 394.771 326.318 394.702 326.491 394.702H499.35C499.522 394.702 499.688 394.771 499.81 394.892C499.932 395.014 500 395.179 500 395.351C500 395.523 499.932 395.688 499.81 395.81C499.688 395.932 499.522 396 499.35 396Z"
          fill="#3F3D56"
        />
      </svg>
    </SvgIcon>
  );
};

export default NoInvoiceFigma;
