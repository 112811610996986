import FormModal from "../../../components/common/FormModal";

import React, { useState, useEffect } from "react";
import { LocationIcon } from "../../../icons";
import { rowFlexOnlyJustifyBetween } from "../../../styles/common";
import useStyles from "../styles";
import { Radio, Typography } from "@material-ui/core";
import { Done } from "@material-ui/icons";

const AddressListModal = ({
  open,
  setOpen,
  addresses,
  selectedAddress,
  onSave,
}) => {
  const classes = useStyles();

  const [selAddress, setSelAddress] = useState(null);

  useEffect(() => {
    setSelAddress(selectedAddress);
  }, [selectedAddress]);

  const handleSave = () => {
    onSave(selAddress).then(() => {
      setOpen(false);
    });
  };
  return (
    <FormModal
      width={600}
      heading="Addresses"
      open={open}
      onClose={() => {
        setSelAddress(selectedAddress);
        setOpen(false);
      }}
      onSave={handleSave}
    >
      {addresses.map((address, i) => {
        return (
          <div
            key={i}
            style={{
              marginBottom: 12,
              cursor: "pointer",
              border: address.id === selAddress?.id && "1px solid #1641DB",
            }}
            className={classes.cardContainer}
            onClick={() => setSelAddress(address)}
          >
            <div className={rowFlexOnlyJustifyBetween}>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  marginRight: 12,
                }}
              >
                <LocationIcon fill={"#1641DB"} />
                <div style={{ marginLeft: 10 }}>
                  <Typography className={classes.primaryTxt}>
                    {address?.customer_name && address.customer_name}
                  </Typography>
                  <Typography className={classes.mobileTxt}>
                    {address?.mobile && address.mobile}
                  </Typography>
                  <Typography className={classes.secondaryTxt}>
                    {address?.street1 && `${address.street1}, `}
                    {address?.street2 && `${address.street2}, `}
                    {address?.city && `${address.city}, `}
                    {address?.state && `${address.state} - `}
                    {address?.pincode && `${address.pincode}`}
                  </Typography>
                </div>
              </div>
              <div>
                <div style={{ width: 24 }}>
                  <Radio
                    classes={{
                      root: classes.radioRoot,
                    }}
                    checked={address.id === selAddress?.id}
                    checkedIcon={
                      <div
                        style={{
                          background: "#1641DB",
                          borderRadius: "50%",
                          width: 24,
                          height: 24,
                          border: "2px solid #FFFFFF",
                        }}
                      >
                        <Done
                          style={{
                            fontSize: 16,
                            color: "#FFFFFF",
                            marginTop: -20,
                          }}
                        />
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </FormModal>
  );
};

export default AddressListModal;
