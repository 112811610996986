import { ESTIMATES_PER_PAGE } from "../../utils/utils";
import {
  CREATE_ESTIMATE,
  FETCH_ESTIMATES,
  ESTIMATE_PROGRESS,
  SET_ESTIMATE_ERROR,
  SET_ESTIMATE_LOADER,
  UPDATE_ESTIMATE,
  CLEAR_ESTIMATE,
  CLEAR_COMPLETE_ESTIMATE,
  DELETE_ESTIMATE,
  ESTIMATE_DATE_SELECTOR,
  ESTIMATE_STATUS_SELECTOR,
  ESTIMATE_DATE_RANGE,
  SET_ESTIMATES_PER_PAGE,
  SET_ESTIMATES_CURRENT_PAGE,
  SET_ESTIMATES_MAX_PAGE_SO_FAR,
  SET_ESTIMATES_INPUT_KEYWORD,
  SET_ESTIMATES_SEARCH_KEYWORD,
  SET_ESTIMATES_CUSTOMER_ID,
} from "./estimateTypes";
import axios from "axios";
import { getDefaultStore } from "../../utils/storeUtil";

export const saveEstimate = (estimateData) => async (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  let data = estimateData;
  let newEst = estimateData.id ? false : true;
  let storeId = store.store_id;
  data.org_id = store.org_id;
  data.store_id = storeId;
  return await axios
    .post(
      `${process.env.REACT_APP_API}/api/v1/org/store/${storeId}/order/from-store-order?src=web`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      dispatch({
        type: newEst ? CREATE_ESTIMATE : UPDATE_ESTIMATE,
        payload: response.data.payload,
      });
      return response.data.payload;
    })
    .catch((error) => {
      dispatch({
        type: SET_ESTIMATE_ERROR,
        payload: { error: error.message },
      });
    });
};

export const fetchEstimates =
  (
    page = 0,
    state = "ALL",
    aoe = null,
    boe = null,
    keyword = null,
    size = ESTIMATES_PER_PAGE,
    customerId = null
  ) =>
  async (dispatch) => {
    dispatch({
      type: ESTIMATE_PROGRESS,
      payload: true,
    });
    let accessToken = localStorage.getItem("access_token");
    let store = getDefaultStore();
    return await axios
      .get(
        `${process.env.REACT_APP_API}/api/v1/org/store/${
          store.store_id
        }/order/find?type=ESTIMATE&page=${page}&size=${size}&sort=createdAt,desc&${
          state === "ALL" ? "" : `&state=${state}`
        }${aoe && boe ? `&created-aoe=${aoe}&created-boe=${boe}` : ""}${
          keyword ? `&q=${keyword}` : ""
        }${customerId ? `&org-store-customer-id=${customerId}` : ""}`,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: FETCH_ESTIMATES,
          payload: response.data.payload,
          estimatesPerPage: size,
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_ESTIMATE_ERROR,
          payload: { error: error.message },
        });
      });
  };

export const clearEstimates = () => (dispatch) => {
  dispatch({
    type: CLEAR_COMPLETE_ESTIMATE,
  });
};
export const refetchEstimate = (estimateId) => (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  axios
    .get(
      `${process.env.REACT_APP_API}/api/v1/org/store/${store.store_id}/order/${estimateId}`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      dispatch({
        type: UPDATE_ESTIMATE,
        payload: response.data.payload,
      });
    });
};

export const deleteEstimate = (estimateId) => (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  axios
    .delete(
      `${process.env.REACT_APP_API}/api/v1/org/store/${store.store_id}/order/${estimateId}`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      dispatch({
        type: DELETE_ESTIMATE,
        payload: { estimateId },
      });
    });
};

export const setEstimateDateSelector = (data) => (dispatch) => {
  dispatch({
    type: ESTIMATE_DATE_SELECTOR,
    payload: data,
  });
};

export const setEstimateStatusSelector = (data) => (dispatch) => {
  dispatch({
    type: ESTIMATE_STATUS_SELECTOR,
    payload: data,
  });
};

export const setEstimateDateRange = (data) => (dispatch) => {
  dispatch({
    type: ESTIMATE_DATE_RANGE,
    payload: data,
  });
};

export const setEstimatesPerPage = (data) => (dispatch) => {
  dispatch({
    type: SET_ESTIMATES_PER_PAGE,
    payload: data,
  });
};

export const clearEstimate = () => (dispatch) => {
  dispatch({
    type: CLEAR_ESTIMATE,
    payload: null,
  });
};

export const startEstimateLoader = () => (dispatch) => {
  dispatch({
    type: SET_ESTIMATE_LOADER,
    payload: true,
  });
};

export const stopEstimateLoader = () => (dispatch) => {
  dispatch({
    type: SET_ESTIMATE_LOADER,
    payload: false,
  });
};

export const setEstimatesCurrentPage = (data) => (dispatch) => {
  dispatch({
    type: SET_ESTIMATES_CURRENT_PAGE,
    payload: data,
  });
};

export const setEstimatesMaxPageSoFar = (data) => (dispatch) => {
  dispatch({
    type: SET_ESTIMATES_MAX_PAGE_SO_FAR,
    payload: data,
  });
};

export const setEstimatesInputKeyword = (data) => (dispatch) => {
  dispatch({
    type: SET_ESTIMATES_INPUT_KEYWORD,
    payload: data,
  });
};

export const setEstimatesSearchKeyword = (data) => (dispatch) => {
  dispatch({
    type: SET_ESTIMATES_SEARCH_KEYWORD,
    payload: data,
  });
};

export const convertEstToInv = (estimateId) => async (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  return await axios
    .post(
      `${process.env.REACT_APP_API}/api/v1/org/store/${store.store_id}/order/estimate-to-invoice?order-id=${estimateId}`,
      {},
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      dispatch({
        type: UPDATE_ESTIMATE,
        payload: response.data.payload,
      });
      return response.data.payload;
    })
    .catch((error) => {
      dispatch({
        type: SET_ESTIMATE_ERROR,
        payload: { error: error.message },
      });
    });
};

export const setEstimatesCustomerId = (data) => (dispatch) => {
  dispatch({
    type: SET_ESTIMATES_CUSTOMER_ID,
    payload: data,
  });
};
