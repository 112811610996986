import React, { useEffect, useState } from "react";
import { Container, CircularProgress } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { createSubscribeRequest, razorPayWallet } from "../../subscribe/utils";
import { container, circular, progressbar } from "../../../styles/common";

const PaymentModal = (props) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    createSubscribeRequest(
      props.selectedPlan.sku,
      props.selectedPlan.plan,
      props.addons.map((addon) => addon.sku),
      props.selectedDomain ? props.selectedDomain.domainName : null,
      props.selectedDomain ? "CUSTOM" : null,
      props.selectedDomain ? props.selectedDomain.purchasePrice * 100 : null,
      props.selectedDomain ? "registration" : null,
      "shoopy-desktop",
      props.slug
    ).then((subOrder) => {
      setLoading(false);

      razorPayWallet(subOrder, props.slug, setLoading)
        .then(() => {
          props.unmount("success");
          setLoading(false);
        })
        .catch((err) => {
          props.unmount("failed");
          setLoading(false);
        });
    });
  }, [props.slug]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <script src="https://checkout.razorpay.com/v1/checkout.js"></script>
      </Helmet>
      <Container className={container}>
        {loading && (
          <CircularProgress
            color="secondary"
            className={progressbar}
            classes={{ colorSecondary: circular }}
          />
        )}
      </Container>
    </>
  );
};

export default PaymentModal;
