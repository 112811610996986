import React from "react";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import {
  makeStyles,
  Grid,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  card: {
    padding: 8,
    borderRadius: 10,
    backgroundColor: "#FFFFFF",
    marginTop: 15,
  },
}));
const SubscribeConfirmation = () => {
  const classes = useStyles();
  return (
    <Grid
      item
      xs={12}
      style={{ padding: "0px 16px", marginBottom: 16, marginTop: 12 }}
    >
      <div
        className={classes.card}
        style={{ margin: 0, border: "1px solid #E5E5E5" }}
      >
        <div style={{ display: "flex" }}>
          <DoneOutlineIcon style={{ color: "#39cc3a" }} />
          <Typography
            className={classes.primarytxt}
            style={{ marginLeft: 8}}
          >
            Congratulations !!
          </Typography>
        </div>
        <Typography style={{ marginTop: 16}}>Your Subscription is activated</Typography>
      </div>
    </Grid>
  );
};

export default SubscribeConfirmation;
