import {
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import useStyles from "../styles";
import React, { useEffect, useState } from "react";
import InvoiceSettingsSide from "./InvoiceSettingsSide";
import { getBillSequence } from "../../../utils/invoiceUtil";
import { useSelector, useDispatch } from "react-redux";
import RecordPaymentModal from "../../../components/invoiceorder/RecordPaymentModal";
import { getPaymentDetails } from "../../../utils/orderUtil";
import {
  createNewRecord,
  initializeRecordValues,
} from "../../../utils/invoiceHelper";
import {
  startInvoiceLoader,
  saveInvoice,
  clearInvoice,
  stopInvoiceLoader,
} from "../../../redux/invoices/invoiceActions";
import { toLocaleISOString } from "../../../utils/dateUtils";
import ConfirmModal from "../../../components/common/ConfirmModal";
import { isWindows } from "react-device-detect";
import OrderHeader from "../../../components/invoiceorder/OrderHeader";
import CustomerDetails from "../../../components/invoiceorder/CustomerDetails";
import ErrorAlert from "../../../components/message/ErrorAlert";
import BaseCTAs from "../../../components/invoiceorder/BaseCTAs";
import BaseForm from "../../../components/invoiceorder/BaseForm";
import BaseFormFooter from "../../../components/invoiceorder/BaseFormFooter";
import Controls from "../../../components/controls/Controls";
import { printDocument } from "../../../utils/documentUtil";
import OutlinedButton from "../../../components/buttons/OutlinedButton";
import Toast from "../../../components/Layout/Toast";

const InvoiceForm = ({ record, invoicePrefix, onSave, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const store = useSelector((state) => state.store.store);
  const loader = useSelector((state) => state.invoices.loader);
  const taxGroups = useSelector((state) => state.tax.taxGroups);
  const billSettings = useSelector((state) => state.billSettings.settings);

  const [invDateFocus, setInvDateFocus] = useState(false);
  const [invDateError, setInvDateError] = useState(false);
  const [dueDateFocus, setDueDateFocus] = useState(false);
  const [dueDateError, setDueDateError] = useState(false);
  const [payment, setPayment] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);

  let initValues = record
    ? initializeRecordValues(record, taxGroups)
    : createNewRecord(
        billSettings.org_id,
        billSettings.store_id,
        null,
        null,
        "INVOICE"
      );
  const [invoice, setInvoice] = useState({
    ...initValues,
    last_payment_date: initValues.last_payment_date
      ? initValues.last_payment_date
      : null,
    invoice_date: initValues.invoice_date ? initValues.invoice_date : null,
  });

  const [openRecordPayment, setOpenRecordPayment] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [invoiceId, setInvoiceId] = useState(initValues.invoice_id);
  const [autoInvoiceId, setAutoInvoiceId] = useState(initValues.invoice_id);
  const [paymentDetails, setPaymentDetails] = useState({
    progress: true,
    payments: [],
  });

  const [confirmModal, setConfirmModal] = useState(false);
  const { payments } = paymentDetails;
  const [scale, setScale] = useState(1);
  const [printInvoice, setPrintInvoice] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    if (isWindows) {
      setScale(window.devicePixelRatio - 0.07);
    }

    let printTogglerState = localStorage.getItem("printInvoice")
      ? JSON.parse(localStorage.getItem("printInvoice"))
      : false;

    setPrintInvoice(printTogglerState);
  }, []);

  useEffect(() => {
    if (record != null) {
      getPaymentDetails(record.id).then((data) => {
        setPaymentDetails({ progress: false, payments: data });
      });
    } else {
      setPaymentDetails({ ...paymentDetails, progress: false });
    }
    // eslint-disable-next-line
  }, [record]);

  useEffect(() => {
    if (record === null) {
      getBillSequence().then((bs) => {
        setInvoiceId(bs);
        setAutoInvoiceId(bs);
      });
    } else {
      setCustomer({
        name: record.org_customer_name,
        mobile: record.org_customer_mobile,
        type: record.org_customer_name,
        customer_id: record.customer_id,
        store_id: record.store_id,
        org_id: record.org_id,
        id: record.org_store_customer_id,
      });
    }
    // eslint-disable-next-line
  }, []);

  const getDueAmount = () => {
    if (payments.length === 0 && payment === null) {
      return invoice.due_amount;
    }
    let totalPaid = payments.reduce((total, pmt) => {
      return total + pmt.amount;
    }, 0);
    let salePrice = Math.round(invoice.final_sale_price * 100) / 100;
    let dueAmt = salePrice - totalPaid;
    return Math.round(dueAmt * 100) / 100;
  };

  let dueAmount = getDueAmount();

  const processInvoice = (skipDueAmtCheck) => {
    if (customer === null || customer.customer_id <= 0) {
      setAlertMessage("Please provide customer information");
      return;
    }
    if (invoice.order_items.length === 0) {
      setAlertMessage("Invoice should have at least one Item");
      return;
    }
    let invReq = {
      ...invoice,
      invoice_id:
        record != null
          ? invoiceId
          : autoInvoiceId !== invoiceId
          ? invoiceId
          : null,
      customer_id: customer.customer_id,
      org_customer_id: customer.org_customer_id,
      org_customer_mobile: customer.mobile,
      org_customer_name: customer.name,
    };
    if (payment && Object.keys(payment).length > 0) {
      invReq.order_payment = payment;
      invReq.order_payment.order_id = record != null ? record.id : null;
      if (record == null) {
        invReq.due_amount =
          Math.round((invoice.due_amount - payment.amount) * 100) / 100;
      } else {
        let totalPaid = payments.reduce((total, pmt) => {
          return total + pmt.amount;
        }, 0);
        let salePrice = Math.round(invoice.final_sale_price * 100) / 100;
        let dueAmt = salePrice - totalPaid;
        invReq.due_amount = Math.round(dueAmt * 100) / 100;
      }
    } else {
      //Don't see order payment request
      invReq.order_payment = null;
      let totalPaid = payments.reduce((total, pmt) => {
        return total + pmt.amount;
      }, 0);
      let salePrice = Math.round(invoice.final_sale_price * 100) / 100;
      let dueAmt = salePrice - totalPaid;
      invReq.due_amount = Math.round(dueAmt * 100) / 100;
    }
    if (!skipDueAmtCheck && invReq.due_amount < 0) {
      setConfirmModal(true);
      return;
    }
    return invReq;
  };

  const handleClose = (shouldSave) => {
    setSuccessMessage("Invoice created successfully.");
    dispatch(stopInvoiceLoader());
    dispatch(clearInvoice());
    if (shouldSave) setTimeout(() => onSave(record == null), 1000);
    else setTimeout(() => window.location.reload(), 1000);
  };

  const handleInvoice = async (skipDueAmtCheck, shouldSave) => {
    let invoicePayload = processInvoice(skipDueAmtCheck);
    if (!invoicePayload) return;

    try {
      dispatch(startInvoiceLoader());
      const response = await dispatch(saveInvoice(invoicePayload));

      if (printInvoice) {
        dispatch(startInvoiceLoader());
        await printDocument(store, "invoice", response.id, () =>
          handleClose(shouldSave)
        );
      } else handleClose(shouldSave);
    } catch (error) {
      console.log("error", error);
    }
  };

  const onCreateOrUpdate = async (skipDueAmtCheck) =>
    handleInvoice(skipDueAmtCheck, true);

  const onCreateAndNew = async (skipDueAmtCheck) =>
    handleInvoice(skipDueAmtCheck, false);

  const printToggler = () => {
    const newValue = !printInvoice;
    setPrintInvoice(newValue);
    localStorage.setItem("printInvoice", JSON.stringify(newValue));
  };

  const onCloseConfirmModal = () => setConfirmModal(false);

  const onPaymentEdit = () => setOpenRecordPayment(true);

  const handleCustomer = (customer) => setCustomer(customer);

  const onPaymentClose = () => setOpenRecordPayment(false);

  const onPaymentRecord = (payment) => {
    setPayment(payment.amount > 0 ? payment : null);
    setOpenRecordPayment(false);
  };

  const onInvoiceNumberEdit = (number) => setInvoiceId(number);

  return (
    <div>
      <Grid container spacing={2} className={classes.root}>
        {loader && (
          <div
            style={{
              position: "fixed",
              left: "50%",
              top: "50%",
              zIndex: "900",
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        )}
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            <OrderHeader
              label={record ? "Edit Invoice" : "Create Invoice"}
              noEdit={record != null}
              prefix={invoicePrefix}
              id={invoiceId}
              autoId={autoInvoiceId}
              onNumberChange={onInvoiceNumberEdit}
              startLabel={{
                label: "Invoices",
                link: "/invoices",
              }}
            />
            <Grid
              container
              style={{
                position: "relative",
                width: "100%",
                marginTop: 24,
              }}
            >
              <Grid
                item
                xs={9}
                className={classes.addInvoiceContainer}
                style={{ height: `${88 * scale}vh` }}
              >
                <Grid
                  container
                  style={{
                    borderBottom: "4px solid #E5E5E5",
                    paddingBottom: 22,
                  }}
                >
                  <Grid item xs={6}>
                    <CustomerDetails
                      label="Bill To"
                      customer={customer}
                      noEdit={record != null}
                      onCustomer={handleCustomer}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ paddingLeft: 20 }}>
                    <Typography
                      style={{
                        fontWeight: 600,
                        color: "#1A1A1A",
                        paddingBottom: 12,
                      }}
                    >
                      Invoice Date
                    </Typography>
                    <TextField
                      InputProps={{
                        style: { marginBottom: 2 },
                        defaultValue: toLocaleISOString(
                          invoice.invoice_date
                            ? new Date(invoice.invoice_date)
                            : new Date()
                        ),
                      }}
                      inputProps={{
                        style: {
                          color: invDateFocus ? "#1A1A1A" : "#999999",
                          fontSize: 12,
                        },
                      }}
                      type="date"
                      variant="outlined"
                      color="secondary"
                      onFocus={() => {
                        setInvDateError(false);
                        setInvDateFocus(true);
                      }}
                      onBlur={(e) => {
                        if (Date.parse(e.target.value)) {
                          setInvoice({
                            ...invoice,
                            invoice_date: new Date(
                              e.target.value
                            ).toISOString(),
                          });
                        } else {
                          setInvDateError(true);
                        }
                        setInvDateFocus(false);
                      }}
                    />
                    <br />
                    <span
                      style={{
                        fontSize: 12,
                        color: invDateError ? "red" : "white",
                      }}
                    >
                      Enter Valid Date
                    </span>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      style={{
                        fontWeight: 600,
                        color: "#1A1A1A",
                        paddingBottom: 12,
                      }}
                    >
                      Due Date
                    </Typography>
                    <TextField
                      InputProps={{
                        style: {
                          marginBottom: 2,
                        },
                        defaultValue: toLocaleISOString(
                          invoice.last_payment_date
                            ? new Date(invoice.last_payment_date)
                            : new Date()
                        ),
                      }}
                      inputProps={{
                        style: {
                          color: dueDateFocus ? "#1A1A1A" : "#999999",
                          fontSize: 12,
                        },
                      }}
                      type="date"
                      variant="outlined"
                      color="secondary"
                      onFocus={() => {
                        setDueDateError(false);
                        setDueDateFocus(true);
                      }}
                      onBlur={(e) => {
                        if (Date.parse(e.target.value)) {
                          setInvoice({
                            ...invoice,
                            last_payment_date: new Date(
                              e.target.value
                            ).toISOString(),
                          });
                        } else {
                          setDueDateError(true);
                        }
                        setDueDateFocus(false);
                      }}
                    />
                    <span
                      style={{
                        fontSize: 12,
                        color: dueDateError ? "red" : "white",
                      }}
                    >
                      Enter Valid Date
                    </span>
                  </Grid>
                </Grid>
                <Grid container style={{ marginTop: 24, position: "relative" }}>
                  <BaseForm
                    initValues={initValues}
                    record={invoice}
                    scale={scale}
                    setRecord={setInvoice}
                    tableHeight={54}
                    enableDiscount={billSettings?.enable_discount}
                    enableCoupon={record && record.coupon_discount > 0}
                    enableShipping={billSettings?.enable_shipping_charge}
                    enablePmtCharge={record && record.payment_charge > 0}
                    enablePmtDiscount={record && record.payment_discount > 0}
                    enablePackaging={billSettings?.enable_packaging_charge}
                    enableRoundOff={billSettings?.enable_round_off}
                  />
                </Grid>
                <Grid className={classes.bottomSheet}>
                  <BaseFormFooter record={invoice} />
                </Grid>
              </Grid>
              <Grid item xs={3} style={{ paddingLeft: 24 }}>
                <BaseCTAs
                  onSave={() => onCreateOrUpdate(false)}
                  onCancel={onClose}
                />

                {!record && (
                  <>
                    <OutlinedButton
                      fullWidth
                      onClick={onCreateAndNew}
                      style={{ marginTop: 10, marginBottom: 10 }}
                    >
                      Save & New
                    </OutlinedButton>
                    <Controls.Switch
                      name="printOption"
                      label="Print Invoice After Save"
                      value={printInvoice}
                      onChange={printToggler}
                    />
                  </>
                )}

                <InvoiceSettingsSide
                  invoice={invoice}
                  setInvoice={setInvoice}
                  payment={payment}
                  dueAmount={dueAmount}
                  paymentDetails={paymentDetails}
                  onPaymentEdit={onPaymentEdit}
                  setOpenRecordPayment={setOpenRecordPayment}
                  isEdit={record ? true : false}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
        {successMessage && <div className={classes.whiteLayover} />}
      </Grid>
      {alertMessage && (
        <ErrorAlert
          message={alertMessage}
          open={alertMessage ? true : false}
          onClose={() => setAlertMessage(null)}
        />
      )}
      {successMessage && (
        <Toast
          open={successMessage ? true : false}
          message={successMessage}
          close={() => setSuccessMessage(false)}
          autoClose
        />
      )}
      {openRecordPayment && (
        <RecordPaymentModal
          open={openRecordPayment}
          payment={payment}
          amount={dueAmount}
          onClose={onPaymentClose}
          onRecord={onPaymentRecord}
        />
      )}
      {confirmModal && (
        <ConfirmModal
          open={confirmModal}
          message="Negative due amount will be captured as advance from Customer."
          onClose={onCloseConfirmModal}
          onConfirm={() => onCreateOrUpdate(true)}
        />
      )}
    </div>
  );
};

export default InvoiceForm;
