import {
  FETCH_CARTS,
  CART_PROGRESS,
  SET_CART_ERROR,
  SET_CART_LOADER,
  CLEAR_CART,
  CLEAR_COMPLETE_CART,
  CART_DATE_SELECTOR,
  CART_STATUS_SELECTOR,
  CART_DATE_RANGE,
  SET_CARTS_PER_PAGE,
  SET_CARTS_CURRENT_PAGE,
  SET_CARTS_MAX_PAGE_SO_FAR,
  SET_CARTS_INPUT_KEYWORD,
  SET_CARTS_SEARCH_KEYWORD,
} from "./cartTypes";
import axios from "axios";
import { getDefaultStore } from "../../utils/storeUtil";
import { CARTS_PER_PAGE } from "../../utils/utils";

export const fetchCarts =
  (
    page = 0,
    status = "ALL",
    aoe = null,
    boe = null,
    keyword = null,
    size = CARTS_PER_PAGE
  ) =>
  (dispatch) => {
    dispatch({
      type: CART_PROGRESS,
      payload: true,
    });
    let accessToken = localStorage.getItem("access_token");
    let store = getDefaultStore();
    axios
      .get(
        `${process.env.REACT_APP_API}/api/v1/business/stores/${
          store.store_id
        }/carts/abandoned-list?page=${page}&size=${size}&sort=createdAt,desc&${
          aoe && boe ? `&created-aoe=${aoe}&created-boe=${boe}` : ""
        }${keyword ? `&q=${keyword}` : ""}`,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: FETCH_CARTS,
          payload: response.data.payload,
          cartsPerPage: size,
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_CART_ERROR,
          payload: { error: error.message },
        });
      });
  };

export const clearCarts = () => (dispatch) => {
  dispatch({
    type: CLEAR_COMPLETE_CART,
  });
};

export const setCartDateSelector = (data) => (dispatch) => {
  dispatch({
    type: CART_DATE_SELECTOR,
    payload: data,
  });
};

export const setCartStatusSelector = (data) => (dispatch) => {
  dispatch({
    type: CART_STATUS_SELECTOR,
    payload: data,
  });
};

export const setCartDateRange = (data) => (dispatch) => {
  dispatch({
    type: CART_DATE_RANGE,
    payload: data,
  });
};

export const setCartsPerPage = (data) => (dispatch) => {
  dispatch({
    type: SET_CARTS_PER_PAGE,
    payload: data,
  });
};

export const clearCart = () => (dispatch) => {
  dispatch({
    type: CLEAR_CART,
    payload: null,
  });
};

export const startCartLoader = () => (dispatch) => {
  dispatch({
    type: SET_CART_LOADER,
    payload: true,
  });
};

export const stopCartLoader = () => (dispatch) => {
  dispatch({
    type: SET_CART_LOADER,
    payload: false,
  });
};

export const setCartsCurrentPage = (data) => (dispatch) => {
  dispatch({
    type: SET_CARTS_CURRENT_PAGE,
    payload: data,
  });
};

export const setCartsMaxPageSoFar = (data) => (dispatch) => {
  dispatch({
    type: SET_CARTS_MAX_PAGE_SO_FAR,
    payload: data,
  });
};

export const setCartsInputKeyword = (data) => (dispatch) => {
  dispatch({
    type: SET_CARTS_INPUT_KEYWORD,
    payload: data,
  });
};

export const setCartsSearchKeyword = (data) => (dispatch) => {
  dispatch({
    type: SET_CARTS_SEARCH_KEYWORD,
    payload: data,
  });
};
