// Core react imports
import React from "react";
// MUI imports
import { Box, Typography, makeStyles } from "@material-ui/core";
import CheckoutNoticeIcon from "../../../icons/CheckoutNoticeIcon";

const useStyles = makeStyles(() => ({
  noticeText: {
    color: "#1A1A1A",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "20px",
    padding: "22px 18px",
  },
}));

// Exporting default component
export default function CheckoutFieldsNotice() {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      paddingRight="18px"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography className={classes.noticeText}>
        You can add a maximum of 5 checkout fields
      </Typography>
      <CheckoutNoticeIcon />
    </Box>
  );
}
