import { Button, CircularProgress, Typography } from "@material-ui/core";
import useStyles from "../styles";
import React, { Fragment } from "react";
import PaymentAccordion from "../../payments/components/PaymentAccordion";
import { transparentButton } from "../../../styles/common";

const OrderPaymentSide = ({
  payment,
  paymentDetails,
  dueAmount,
  onPaymentEdit,
  setOpenRecordPayment,
}) => {
  const classes = useStyles();

  let { payments } = paymentDetails;
  let dueAmountWithPayment = dueAmount - (payment ? payment.amount : 0);

  return (
    <Fragment>
      <div>
        <Typography
          style={{
            fontWeight: 600,
            color: "#1A1A1A",
            paddingBottom: 12,
            marginTop: 38,
            borderBottom: "1px solid #E5E5E5",
            fontSize: 16,
          }}
        >
          Payment Details
        </Typography>
        {paymentDetails.progress && (
          <div style={{ textAlign: "center", marginTop: 20 }}>
            <CircularProgress color="secondary" />
          </div>
        )}
        {!paymentDetails.progress && (
          <div>
            {payments.length > 0 &&
              payments.map((pmt, i) => {
                return <PaymentAccordion key={i} payment={pmt} />;
              })}
            {payment != null && (
              <PaymentAccordion
                payment={payment}
                showEdit={true}
                onEdit={onPaymentEdit}
              />
            )}
            <div
              style={{
                width: "100%",
                marginTop: -12,
              }}
              className={classes.flexBox}
            >
              <Typography
                className={classes.subHeading}
                style={{ paddingTop: 18 }}
              >
                Due
              </Typography>
              <Typography
                className={classes.subHeading}
                style={{ paddingTop: 18 }}
              >
                ₹ {parseFloat(dueAmountWithPayment).toFixed(2)}
              </Typography>
            </div>
            {dueAmountWithPayment > 0 && payment === null && (
              <Button
                className={transparentButton}
                onClick={() => setOpenRecordPayment(true)}
              >
                + RECORD PAYMENT
              </Button>
            )}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default OrderPaymentSide;
