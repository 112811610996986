import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { makeStyles } from "@material-ui/core";
import { rowFlexAlignCenter } from "../../styles/common";
import CustomLabel, { generateLabelProps } from "./CustomLabel";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  listItem: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  inputRoot: {
    "&.MuiOutlinedInput-root": {
      padding: "0px !important",
    },
    "& .MuiAutocomplete-tag": {
      border: "1px solid #E1E1E1",
      borderRadius: "5px",
      background: "#F9F9F9",
    },
  },

  optionItem: {
    width: "100%",
    fontSize: 14,
    fontWeight: 400,
    border: "1px solid #EBEBEB",
    borderRadius: "6px",
    paddingLeft: "10px",
  },
}));

export default function Checkboxes({ ...props }) {
  const classes = useStyles();

  const labelProps = generateLabelProps(props);

  return (
    <div
      className={
        labelProps.labelPlacement === "start" ? rowFlexAlignCenter : ""
      }
    >
      <CustomLabel {...labelProps} />

      <div
        style={{
          width: props.width ? props.width : "100%",
          ...(!props.width && { flexGrow: 1 }),
        }}
      >
        <Autocomplete
          closeIcon={<></>}
          multiple
          blurOnSelect={false}
          id="checkboxes-tags-demo"
          options={props.options}
          disableCloseOnSelect
          onChange={props.onChange}
          value={props.selectedOptions}
          getOptionLabel={(option) => option.title}
          getOptionSelected={(option, value) => option.id === value.id}
          classes={{
            listbox: classes.listbox,
            paper: classes.paper,
            option: classes.autocompleteOption,
          }}
          renderOption={(option, { selected }) => (
            <li
              style={{
                marginLeft: option.indent && "20px",
              }}
              key={option.id}
              className={classes.optionItem}
            >
              <div className={classes.listItem}>
                {option.title}
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={selected}
                />
              </div>
            </li>
          )}
          style={{ marginBottom: 20 }}
          renderTags={props.renderTags}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              helperText={props.helperText}
              InputProps={{
                ...params.InputProps,
                endAdornment: null,
                classes: { root: classes.inputRoot },
              }}
              placeholder={!props.selectedOptions.length && props.placeholder}
            />
          )}
        />
      </div>
    </div>
  );
}
