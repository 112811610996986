import { FETCH_TAX_GROUPS, TAX_GROUP_PROGRESS } from './taxGroupTypes';
const initialState = {
  taxGroups: [],
  progress: false,
  error: ''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TAX_GROUPS:
      return {
        ...state,
        taxGroups: [...action.payload],
        progress: false,
      };
    case TAX_GROUP_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      };
    default:
      return state;
  }
}
export default reducer;