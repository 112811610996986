const GOOGLE_CDN = "https://img.clevup.in/";
const SHY_PUB = "/shy-pub/";

export const cdnUrl = (url, width, height, sharp) => {
  let cUrl = url;
  let env = process.env.REACT_APP_ENV;
  if (env && url && url.indexOf("https:/") === 0 && url.indexOf(GOOGLE_CDN) < 0) {
    let pubPos = url.indexOf(SHY_PUB);
    if (pubPos <= 0) {
      return url;
    }
    let subPathStart = pubPos + SHY_PUB.length;
    let qMarkIndex = url.lastIndexOf("?");
    let subPath = "";
    if(qMarkIndex < 0) {
      subPath = url.substring(subPathStart);
    }else if(qMarkIndex > subPathStart){
      subPath = url.substring(subPathStart, qMarkIndex);
    }
    let params = {};
    if (width) {
      params["width"] = width;
    }
    if (height) {
      params["height"] = height;
    }
    if (sharp) {
      params["sharpen"] = sharp ? true : false;
    }
    let urlParams = Object.keys(params)
      .map(function (k) {
        return k + "=" + params[k];
      })
      .join("&");
    cUrl = GOOGLE_CDN + subPath + "?" + urlParams;
  }
  return cUrl;
};

export const cdnUrlOrParams = (url, width, height, sharp) => {
  let cUrl = url;
  let env = process.env.REACT_APP_ENV;
  if (env && url.indexOf(GOOGLE_CDN) < 0) {
    cUrl = cdnUrl(url, width, height, sharp);
  } else if(env){
    let qIndex  = url.lastIndexOf("?");
    if(url.length > qIndex + 1){
      return url;
    }
    let params = {};
    if (width) {
      params["width"] = width;
    }
    if (height) {
      params["height"] = height;
    }
    if (sharp) {
      params["sharpen"] = sharp ? true : false;
    }
    let urlParams = Object.keys(params)
      .map(function (k) {
        return k + "=" + params[k];
      })
      .join("&");
    let addQMark = ( qIndex < 0);
    cUrl = url + (addQMark ? "?" : "") + urlParams;
  }
  return cUrl;
};
