export const FETCH_STORE_CUSTOMERS = "FETCH_STORE_CUSTOMERS";
export const ADD_STORE_CUSTOMER = "ADD_STORE_CUSTOMER";
export const EDIT_STORE_CUSTOMER = "EDIT_STORE_CUSTOMER";
export const DELETE_STORE_CUSTOMER = "DELETE_STORE_CUSTOMER";
export const SET_STORE_CUSTOMER_BALANCE = "SET_STORE_CUSTOMER_BALANCE";
export const SET_STORE_CUSTOMER_WALLET_POINTS =
  "SET_STORE_CUSTOMER_WALLET_POINTS";
export const STORE_CUSTOMER_PROGRESS = "STORE_CUSTOMER_PROGRESS";
export const SET_STORE_CUSTOMER_ERROR = "SET_STORE_CUSTOMER_ERROR";
export const SET_STORE_CUSTOMER_LOADER = "SET_STORE_CUSTOMER_LOADER";
export const CLEAR_STORE_CUSTOMER = "CLEAR_STORE_CUSTOMER";
export const CLEAR_COMPLETE_CUSTOMERS = "CLEAR_COMPLETE_CUSTOMERS";
export const SET_STORE_CUSTOMERS_PER_PAGE = "SET_STORE_CUSTOMERS_PER_PAGE";
export const SET_STORE_CUSTOMERS_CURRENT_PAGE =
  "SET_STORE_CUSTOMERS_CURRENT_PAGE";
export const SET_STORE_CUSTOMERS_MAX_PAGE_SO_FAR =
  "SET_STORE_CUSTOMERS_MAX_PAGE_SO_FAR";
export const SET_STORE_CUSTOMERS_INPUT_KEYWORD =
  "SET_STORE_CUSTOMERS_INPUT_KEYWORD";
export const SET_STORE_CUSTOMERS_SEARCH_KEYWORD =
  "SET_STORE_CUSTOMERS_SEARCH_KEYWORD";
export const STORE_CUSTOMER_BALANCE_SELECTOR =
  " STORE_CUSTOMER_BALANCE_SELECTOR";
export const SET_SUCCESS_TOAST = "SET_SUCCESS_TOAST";
