import React, { useState, useEffect, useRef } from "react";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { getDefaultStore } from "../../utils/storeUtil";
import AddCustomerModal from "./AddCustomerModal";
import Lottie from "react-lottie";
import ComponentLoader from "../../components/ComponentLoader.json";
import { Typography, Divider, makeStyles } from "@material-ui/core";
import { CustomerIcon } from "../../icons";

const useStyles = makeStyles(() => ({
  autocompleteOption: {
    borderTop: "1px solid #E5E5E5",
  },
}));

const CustomerDetails = ({ label, customer, onCustomer, noEdit }) => {
  const classes = useStyles();
  const [customerOptions, setCustomerOptions] = useState([]);
  const [customerInputValue, setCustomerInputValue] = useState("");
  const timeout = useRef(null);
  const [loader, setLoader] = useState(false);
  const [customerModal, setCustomerModal] = useState(false);

  const [dialogValue, setDialogValue] = React.useState({
    name: "",
  });
  const [isEditable, setIsEditable] = useState(
    customer === null ? false : true
  );
  const addCustomer = (value) => {
    onCustomer(value);
    setCustomerModal(false);
    setDialogValue({ name: "" });
  };

  const closeModal = () => {
    setCustomerModal(false);
    setDialogValue({ name: "" });
  };

  useEffect(() => {
    if (customerInputValue.trim().length === 0) {
      setCustomerOptions([]);
      return;
    }
    let accessToken = localStorage.getItem("access_token");
    let store = getDefaultStore();
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setLoader(true);
      axios
        .get(
          `${process.env.REACT_APP_API}/api/v2/org/store/${
            store.store_id
          }/customers?page=${0}&size=15&sort=name,asc&query=${customerInputValue}`,
          {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((res) => {
          setLoader(false);
          setCustomerOptions(res.data.payload.content);
        });
    }, 500);
    // eslint-disable-next-line
  }, [customerInputValue.trim()]);

  const filter = createFilterOptions({
    stringify: (option) => `${option.name} ${option.mobile}`,
  });
  return (
    <>
      <Typography
        style={{
          fontWeight: 600,
          color: "#1A1A1A",
          paddingBottom: 12,
        }}
      >
        {label}
      </Typography>
      {(customer === null || isEditable) && (
        <Autocomplete
          options={customerOptions}
          onClose={(e, reason) => {
            if (reason === "select-option") {
              if (document.getElementById("productSearch"))
                document.getElementById("productSearch").focus();
            }
          }}
          getOptionLabel={(option) => `${option.name ?? "Adding customer..."}`}
          renderOption={(option) => (
            <>
              <div
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <CustomerIcon />
                <div style={{ marginLeft: 10 }}>
                  <Typography
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {`${option.name}${
                      option?.mobile ? ` - ${option.mobile}` : ""
                    }`}
                  </Typography>
                  {option.billing_address && (
                    <Typography style={{ fontSize: 12, color: "#666666" }}>
                      {option.billing_address.street1 &&
                        `${option.billing_address.street1}, `}
                      {option.billing_address.street2 &&
                        `${option.billing_address.street2}, `}
                      {option.billing_address.city &&
                        `${option.billing_address.city}, `}
                      {option.billing_address.state &&
                        `${option.billing_address.state} - `}
                      {option.billing_address.pincode &&
                        `${option.billing_address.pincode}`}
                    </Typography>
                  )}
                </div>
              </div>
              <Divider />
            </>
          )}
          classes={{
            option: classes.autocompleteOption,
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            if (params.inputValue.trim() !== "") {
              filtered.push({
                inputValue: params.inputValue,
                name: `Add "${params.inputValue}"`,
              });
            }

            return filtered;
          }}
          value={customer}
          onChange={(event, newValue) => {
            if (typeof newValue === "string" || newValue?.inputValue) {
              const name = newValue?.inputValue
                ? newValue.inputValue
                : newValue;
              setCustomerModal(true);
              setDialogValue({
                name: name.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                  letter.toUpperCase()
                ),
              });
            } else {
              onCustomer(newValue);
              setIsEditable(false);
            }
          }}
          inputValue={customerInputValue}
          onInputChange={(event, newInputValue) => {
            setCustomerInputValue(newInputValue);
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          autoComplete
          freeSolo
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <TextField
                onBlur={() => {
                  setIsEditable(false);
                }}
                placeholder="Customer Name"
                {...params}
                InputProps={{
                  // ...params.InputProps,
                  style: { width: "100%" },
                  endAdornment: (
                    <>
                      {loader && (
                        <Lottie
                          options={{
                            loop: true,
                            autoplay: true,
                            animationData: ComponentLoader,
                            // rendererSettings: {
                            //   preserveAspectRatio: "xMidYMid slice",
                            // },
                          }}
                          style={{ position: "absolute", right: 26, top: 4 }}
                          width={16}
                          height={20}
                        />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                variant="outlined"
                color="secondary"
                autoFocus={true}
                inputProps={{
                  ...params.inputProps,
                  style: {
                    fontSize: 12,
                    color: "#1A1A1A",
                  },
                }}
                onKeyDown={(e) => {
                  if (e.key === "Tab") {
                    e.preventDefault();
                    document.getElementById("productSearch").focus();
                  }
                }}
              />
            </div>
          )}
        />
      )}
      {customer !== null && !isEditable && (
        <span
          onClick={() => {
            if (!noEdit) {
              setIsEditable(true);
            }
          }}
        >
          <Typography
            style={{
              textTransform: "capitalize",
              cursor: "pointer",
              color: "#1641DB",
              margin: "3px 0 3px 0",
            }}
          >
            {customer.name ? customer.name : customer.mobile}
          </Typography>
        </span>
      )}
      <AddCustomerModal
        customerModal={customerModal}
        dialogValue={dialogValue}
        closeModal={closeModal}
        addCustomer={addCustomer}
        setDialogValue={setDialogValue}
      />
    </>
  );
};

export default CustomerDetails;
