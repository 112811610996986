import React from "react";

export default function NoCouponImage() {
  return (
    <svg
      width="532"
      height="342"
      viewBox="0 0 532 342"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M207.431 11.8374C191.027 5.17501 173.691 -0.402717 155.994 0.307176C129.955 1.33013 105.015 16.9654 92.764 39.9245C82.3839 59.3738 81.104 82.1742 76.6333 103.758C71.1255 130.354 60.5776 155.628 49.306 180.347C43.2509 193.605 36.8649 207.186 35.8719 221.741C34.4905 241.944 44.1601 262.081 59.3198 275.534C74.4796 288.987 94.6264 296.064 114.826 297.611C125.581 298.436 136.818 297.673 146.373 292.673C156.479 287.382 163.845 277.818 173.991 272.606C183.396 267.756 194.394 267.095 204.951 267.725C218.778 268.552 232.403 271.444 245.372 276.306C260.126 281.844 273.847 289.86 288.57 295.473C303.293 301.086 319.741 304.221 334.791 299.534C348.913 295.124 360.097 284.454 370.172 273.629C386.71 255.862 401.793 236.797 415.276 216.617C424.363 203.019 432.819 188.671 436.84 172.82C444.519 142.519 433.949 108.33 410.497 87.6328C391.078 70.4896 365 63.0467 339.478 58.5316C316.206 54.4134 289.298 54.1576 267.876 43.4255C247.442 33.1739 228.853 20.5413 207.431 11.8374Z"
        fill="#DFE4F5"
      />
      <path
        opacity="0.1"
        d="M9.97888 52.4075C15.251 52.4075 19.5249 48.1375 19.5249 42.8703C19.5249 37.603 15.251 33.333 9.97888 33.333C4.70676 33.333 0.432861 37.603 0.432861 42.8703C0.432861 48.1375 4.70676 52.4075 9.97888 52.4075Z"
        fill="#1739B7"
      />
      <path
        opacity="0.1"
        d="M29.071 111.47C37.9067 111.47 45.0693 104.314 45.0693 95.4861C45.0693 86.6586 37.9067 79.5024 29.071 79.5024C20.2354 79.5024 13.0728 86.6586 13.0728 95.4861C13.0728 104.314 20.2354 111.47 29.071 111.47Z"
        fill="#1739B7"
      />
      <path
        d="M343.33 18.4339C338.284 15.1752 331.067 13.9301 325.125 14.2103C282.999 16.1973 287.244 104.239 295.957 122.994C304.67 141.751 301.281 148.649 269.589 162.756C237.898 176.862 256.01 194.65 261.382 205.671C266.753 216.692 275.843 225.463 253.212 239.726C230.581 253.989 239.754 260.08 239.754 260.08C239.754 260.08 289.343 300.085 285.184 285.889C281.026 271.693 282.862 249.297 322.322 242.94C339.318 240.201 364.51 228.955 368.136 209.98C368.981 205.561 369.148 198.945 367.6 194.659C365.453 188.713 360.869 184.369 357.959 178.878C351.177 166.081 363.039 154.717 369.473 144.864C372.832 139.721 376.175 134.457 378.541 128.766C383.45 116.951 379.715 105.346 371.73 95.9354C367.901 91.4234 362.771 86.7625 360.662 81.0887C358.397 74.9947 358.708 66.2651 358.306 59.7673C357.99 54.671 357.875 49.5716 356.989 44.5302C355.342 35.153 351.766 23.8819 343.33 18.4339Z"
        fill="#1641DB"
      />
      <path
        opacity="0.8"
        d="M240.253 285.642C239.98 285.778 239.639 285.695 239.462 285.436C239.268 285.152 239.342 284.765 239.625 284.572C239.799 284.453 257.261 272.453 276.122 253.861C293.502 236.73 315.661 210.34 322.531 181.899C328.268 158.144 331.503 119.615 331.406 76.1901C331.319 37.2505 328.66 8.75859 326.138 3.71002C325.985 3.40274 326.11 3.02841 326.417 2.87511C326.725 2.7218 327.099 2.84672 327.252 3.15399C330.2 9.05992 332.572 41.1402 332.65 76.1876C332.747 119.707 329.499 158.345 323.74 182.191C316.801 210.924 294.481 237.516 276.979 254.764C258.04 273.429 240.501 285.48 240.327 285.6C240.303 285.615 240.278 285.628 240.253 285.642Z"
        fill="white"
      />
      <path
        opacity="0.8"
        d="M332.219 83.6439C331.913 83.7969 331.577 83.7463 331.423 83.4405C331.218 83.0362 310.684 42.9833 289.482 35.9008C289.156 35.7921 288.989 35.4576 289.098 35.1314C289.207 34.8056 289.564 34.6386 289.89 34.7473C311.577 41.9899 331.684 81.2029 332.531 82.8723C332.687 83.179 332.532 83.488 332.225 83.6413C332.222 83.6418 332.22 83.6424 332.219 83.6439Z"
        fill="white"
      />
      <path
        opacity="0.8"
        d="M298.165 232.181C298.1 232.195 298.03 232.199 297.961 232.192C297.62 232.153 297.374 231.846 297.413 231.504C297.433 231.325 299.409 213.327 298.065 193.928C296.834 176.142 292.494 153.504 278.805 146.668C278.497 146.515 278.372 146.142 278.526 145.834C278.679 145.526 279.053 145.402 279.36 145.555C290.775 151.256 297.486 167.507 299.307 193.859C300.655 213.368 298.669 231.462 298.648 231.642C298.617 231.914 298.417 232.126 298.165 232.181Z"
        fill="white"
      />
      <path
        opacity="0.8"
        d="M327.364 206.428C327.103 206.485 326.824 206.369 326.686 206.124C326.518 205.824 326.625 205.445 326.925 205.277C329.099 204.062 380.37 175.589 398.228 181.214C398.556 181.317 398.738 181.666 398.635 181.994C398.532 182.323 398.183 182.505 397.855 182.4C380.503 176.935 328.061 206.069 327.533 206.363C327.479 206.394 327.422 206.416 327.364 206.428Z"
        fill="white"
      />
      <path
        opacity="0.8"
        d="M316.517 245.101C316.45 245.101 316.383 245.09 316.316 245.067C315.992 244.956 315.818 244.603 315.929 244.277C315.988 244.106 321.812 226.964 324.7 207.733C327.349 190.102 328.011 167.061 316.126 157.423C315.859 157.207 315.818 156.815 316.035 156.548C316.251 156.281 316.643 156.24 316.91 156.457C326.82 164.493 329.854 181.812 325.929 207.934C323.023 227.272 317.166 244.508 317.108 244.679C317.018 244.938 316.776 245.101 316.517 245.101Z"
        fill="white"
      />
      <path
        opacity="0.8"
        d="M350.6 226.278C350.331 226.278 350.085 226.104 350.004 225.835C349.905 225.506 350.092 225.16 350.421 225.06C352.806 224.344 409.025 207.645 425.242 217.002C425.54 217.173 425.642 217.554 425.469 217.852C425.298 218.15 424.917 218.252 424.619 218.079C408.861 208.988 351.356 226.078 350.778 226.252C350.719 226.27 350.659 226.278 350.6 226.278Z"
        fill="white"
      />
      <path
        d="M390.738 102.696L365.371 75.9636L327.281 35.7333C324.718 33.0329 321.182 31.398 317.469 31.2159C313.738 31.0301 310.142 32.3197 307.495 34.8008L271.828 68.2448L235.689 102.263C230.607 107.03 227.803 113.506 227.803 120.249V309.586C227.803 321.605 237.902 334.173 250.613 332.607H285.37H343.479H389.641C400.491 331.274 397.244 301.686 397.244 290.784V118.848C397.243 112.825 394.855 107.039 390.738 102.696ZM316.917 78.2936C326.208 78.4447 333.67 85.8831 333.67 94.9084C333.67 103.93 326.208 111.268 316.917 111.289C307.524 111.316 299.834 103.873 299.834 94.671C299.834 85.4731 307.525 78.1378 316.917 78.2936Z"
        fill="#F3F3F3"
      />
      <path
        d="M352.81 227.549C374.223 206.798 374.223 173.155 352.81 152.405C331.396 131.654 296.678 131.654 275.265 152.405C253.852 173.155 253.852 206.798 275.265 227.549C296.678 248.299 331.396 248.299 352.81 227.549Z"
        fill="#A8A8A8"
      />
      <path
        d="M314.153 237.568C340.847 237.568 362.487 216.415 362.487 190.321C362.487 164.226 340.847 143.073 314.153 143.073C287.459 143.073 265.819 164.226 265.819 190.321C265.819 216.415 287.459 237.568 314.153 237.568Z"
        fill="white"
      />
      <path
        d="M313.868 177.02C313.702 187.93 306.822 193.601 299.162 193.571C290.818 193.538 284.127 187.193 284.286 176.772C284.437 166.864 290.731 159.805 300.08 160.498C309.153 161.171 314.003 168.116 313.868 177.02ZM294.057 176.811C293.97 182.502 295.847 186.639 299.516 186.706C303.047 186.771 304.806 183.204 304.898 177.233C304.98 171.835 303.585 167.575 299.729 167.347C295.978 167.123 294.14 171.371 294.057 176.811ZM301.801 211.887L328.38 164.155C328.889 163.242 329.886 162.706 330.936 162.784C332.886 162.929 334.032 165.022 333.095 166.724L306.81 214.443C306.359 215.262 305.509 215.787 304.569 215.829L304.274 215.842C302.176 215.934 300.786 213.711 301.801 211.887ZM347.278 198.435C347.124 208.54 341.218 214.069 334.665 214.354C327.618 214.662 321.932 209.088 322.003 199.484C322.143 190.351 327.507 183.563 335.485 183.776C343.25 183.983 347.405 190.188 347.278 198.435ZM330.398 199.099C330.246 204.355 331.844 208.093 334.975 208.002C337.994 207.915 339.503 204.544 339.588 199.021C339.664 194.029 338.544 190.156 335.248 190.116C331.974 190.077 330.474 194.077 330.398 199.099Z"
        fill="#A8A8A8"
      />
      <path
        d="M405.713 197.343C404.991 196.791 404.267 196.238 403.545 195.686C402.913 195.866 392.53 198.986 390.474 208.12C390.192 209.376 389.15 214.007 391.686 216.983C395.938 221.973 408.275 220.008 414.067 213.031C418.617 207.548 419.184 198.901 416.554 197.281C413.373 195.321 403.887 202.653 403.165 203.211C401.714 204.331 400.399 205.524 400.167 205.314C399.773 204.958 402.888 200.886 405.713 197.343Z"
        fill="#D39999"
      />
      <path
        d="M481.662 261.996C481.662 261.996 481.109 260.559 481.976 259.809C482.842 259.058 487.635 256.885 490.04 256.929C492.444 256.974 490.507 258.828 489.133 259.877C487.76 260.926 484.558 263.123 483.733 263.31C482.909 263.494 481.662 261.996 481.662 261.996Z"
        fill="url(#paint0_linear_7675_4513)"
      />
      <path
        d="M477.42 264.535C478.816 263.447 480.034 262.598 480.955 261.987C482.992 260.632 483.899 260.214 484.808 259.931C485.601 259.683 487.397 259.223 487.566 259.595C487.737 259.974 486.072 260.863 481.983 263.967C481.389 264.417 479.505 265.857 479.505 265.857L477.42 264.535Z"
        fill="url(#paint1_linear_7675_4513)"
      />
      <path
        d="M421.96 329.728C421.96 329.728 422.516 331.164 421.65 331.916C420.784 332.668 415.996 334.848 413.59 334.806C411.186 334.764 413.121 332.909 414.492 331.857C415.863 330.806 419.063 328.604 419.886 328.417C420.711 328.23 421.96 329.728 421.96 329.728Z"
        fill="url(#paint2_linear_7675_4513)"
      />
      <path
        d="M421.614 323.375C421.716 325.204 421.526 326.689 421.325 327.722C421.037 329.198 420.864 330.032 420.164 330.773C418.929 332.082 416.764 332.34 416.451 331.813C416.095 331.215 418.385 330.05 419.145 327.252C419.478 326.026 419.4 324.917 419.27 324.137L421.614 323.375Z"
        fill="url(#paint3_linear_7675_4513)"
      />
      <path
        d="M411.987 247.663C408.746 251.074 409.455 255.95 410.469 264.689C411.32 272.02 412.48 282.861 413.571 296.585C414.562 306.158 415.552 315.731 416.542 325.303C418.192 325.303 419.843 325.303 421.493 325.303C423.517 321.005 425.929 314.422 426.048 306.29C426.148 299.553 424.574 298.668 424.27 291.038C424.103 286.842 424.475 284.491 427.633 266.28C430.145 251.794 430.639 249.076 428.623 246.87C425.066 242.98 416.107 243.325 411.987 247.663Z"
        fill="#383838"
      />
      <path
        opacity="0.4"
        d="M421.89 314.562C421.037 311.891 418.774 309.162 418.622 303.867C418.441 297.619 421.437 296.06 420.999 290.201C420.577 284.57 417.672 284.161 416.839 278.614C415.872 272.171 419.455 270.486 420.702 262.868C421.838 255.919 420.193 249.145 418.479 244.617C422.326 243.916 426.467 244.708 428.624 247.067C430.64 249.273 430.146 251.991 427.634 266.477C424.475 284.688 424.104 287.039 424.271 291.235C424.576 298.865 426.15 299.749 426.05 306.487C425.929 314.62 423.518 321.202 421.495 325.5C420.949 325.5 420.403 325.5 419.855 325.5C422.502 320.688 422.724 317.18 421.89 314.562Z"
        fill="url(#paint4_linear_7675_4513)"
      />
      <path
        d="M486.522 265.156C479.58 274.72 466.307 290.522 454.829 287.843C448.761 286.427 446.559 280.628 428.723 256.136C424.434 250.246 420.782 245.391 418.19 241.983C425.122 240.267 432.055 238.55 438.986 236.834C443.666 244.554 446.742 251.091 448.648 255.505C453.203 266.055 454.845 273.237 458.992 273.674C461.019 273.887 462.333 272.351 465.132 270.11C468.797 267.175 474.474 263.46 482.958 260.602C484.145 262.119 485.333 263.637 486.522 265.156Z"
        fill="#383838"
      />
      <path
        d="M511.107 172.649C507.386 159.972 488.555 153.974 478.965 158.924C472.056 162.49 472.957 170.182 465.51 172.39C458.876 174.356 456.346 168.791 448.397 170.93C442.179 172.604 441.218 176.688 434.608 176.997C431.936 177.121 430.45 176.532 426.756 177.181C423.908 177.683 421.349 178.169 419.316 180.318C418.964 180.691 416.109 183.786 416.892 187.593C417.78 191.916 422.426 193.649 423.738 194.138C428.094 195.762 431.711 194.498 434.083 194.265C446.977 192.995 451.364 214.459 469.883 220.918C480.152 224.499 492.978 222.842 496.97 216.707C497.4 216.046 500.063 211.953 498.964 207.689C498.112 204.381 495.667 203.58 495.953 201.435C496.354 198.418 500.848 197.489 504.409 194.741C510.515 190.029 513.257 179.971 511.107 172.649Z"
        fill="#383838"
      />
      <path
        d="M438.712 194.269C433.397 194.249 426.816 194.308 420.09 195.018C405.63 196.544 406.199 203.738 408.818 210.298C411.489 216.991 411.397 222.902 411.343 225.005C411.257 228.392 410.641 231.108 409.409 236.539C407.271 245.975 405.677 248.142 407.183 250.555C409.379 254.073 415.534 253.899 416.614 253.848C422.08 253.585 423.241 250.377 427.849 249.869C433.204 249.279 434.442 253.301 441.888 256.007C450.677 259.202 461.991 258.356 467.247 252.472C469.276 250.201 470.578 247.061 469.706 246.073C468.518 244.727 464.386 248.599 459.987 247.063C457.072 246.046 457.336 243.806 453.847 241.621C449.647 238.992 447.177 240.927 444.328 239.229C439.887 236.58 440.841 228.857 440.482 216.141C440.334 210.875 439.921 203.383 438.712 194.269Z"
        fill="#D39999"
      />
      <path
        d="M438.712 194.641C446.425 195.017 449.181 203.044 457.233 204.628C462.111 205.589 469.388 204.271 479.835 194.122C481.316 194.807 482.798 195.492 484.279 196.178C482.757 205.29 478.529 210.403 476.453 212.556C464.593 224.859 442.102 224.481 429.923 213.935C426.101 210.625 423.947 206.878 422.776 204.415C426.627 199.629 432.229 194.324 438.712 194.641Z"
        fill="#D39999"
      />
      <path
        d="M421.829 189.208C422.31 188.862 422.987 189.087 423.17 189.652C423.675 191.213 424.718 193.872 426.141 194.535C428.188 195.491 431.273 195.072 431.662 195.45C432.05 195.827 422.466 200.801 416.08 198.54C416.036 198.525 415.991 198.505 415.95 198.483L415.586 198.287C414.865 197.899 415.028 196.821 415.831 196.663C417.063 196.42 420.29 195.493 421.102 194.086C421.195 193.924 421.222 192.655 421.198 192.47L420.936 190.412C420.895 190.095 421.033 189.78 421.292 189.593L421.829 189.208Z"
        fill="url(#paint5_linear_7675_4513)"
      />
      <path
        d="M423.829 189.562C424.945 188.215 424.264 186.492 423.929 186.104C423.452 185.552 422.592 185.199 422.056 185.228C420.447 185.315 417.901 189.676 419.515 190.905C421.316 192.277 423.164 190.901 423.164 190.901C423.342 190.926 423.737 190.954 424.14 190.741C424.256 190.678 424.766 190.407 424.727 190.078C424.697 189.819 424.33 189.572 423.829 189.562Z"
        fill="url(#paint6_linear_7675_4513)"
      />
      <path
        d="M481.083 195.204L481.81 193.771C481.81 193.771 478.095 187.694 481.139 186.72C484.184 185.744 483.326 187.481 482.981 188.311C482.636 189.14 483.192 191.843 483.721 192.668C484.25 193.495 483.329 196.245 483.329 196.245L481.083 195.204Z"
        fill="url(#paint7_linear_7675_4513)"
      />
      <path
        d="M404.024 196.355L404.593 194.557C404.593 194.557 405.279 193.075 407.213 192.822C409.148 192.569 409.127 193.614 409.127 193.614L407.865 193.987C407.865 193.987 407.048 194.223 406.489 195.406C405.706 196.611 404.913 197.163 404.913 197.163L404.024 196.355Z"
        fill="url(#paint8_linear_7675_4513)"
      />
      <path
        d="M106.35 334.187H98.8821C97.9413 333.676 97.1046 332.508 96.8395 330.764C96.4742 328.358 93.7786 327.047 93.1955 325.078C92.6125 323.111 95.6015 320.342 95.6015 320.342L96.1013 319.698L96.6218 319.03L101.578 320.198C101.578 320.198 101.701 321.379 101.775 322.882C101.829 323.967 101.858 325.22 101.795 326.32C101.65 328.943 102.53 331.77 104.86 332.281C106.585 332.657 106.507 333.68 106.35 334.187Z"
        fill="#233862"
      />
      <path
        d="M149.692 334.187H132.613C131.792 333.183 132.095 329.485 132.219 328.277C132.246 328.033 132.263 327.893 132.263 327.893C132.263 327.893 138.095 323.228 138.896 326.8C138.921 326.912 138.951 327.022 138.983 327.13C139.992 330.471 144.52 331.989 147.134 332.411C148.83 332.687 149.459 333.572 149.692 334.187Z"
        fill="#233862"
      />
      <path
        d="M146.131 236.359C146.131 236.359 145.245 247.408 144.204 260.69C142.79 278.732 141.088 300.893 140.919 305.092C140.628 312.382 139.462 324.042 139.462 324.042C139.462 324.042 136.694 326.812 131.153 325.646C131.153 325.646 126.216 308.999 124.806 291.386C124.783 291.099 124.763 290.807 124.74 290.516C123.428 272.585 121.387 234.83 121.387 234.83L146.131 236.359Z"
        fill="#5C5C8E"
      />
      <path
        d="M146.131 236.36C146.131 236.36 145.245 247.409 144.204 260.69L124.804 291.385C124.782 291.097 124.761 290.806 124.738 290.514C123.426 272.584 121.385 234.828 121.385 234.828L146.131 236.36Z"
        fill="#5C5C8E"
      />
      <path
        d="M142.085 236.977C142.085 236.977 126.124 276.991 115.627 295.796C105.132 314.601 101.852 319.849 101.852 319.849C101.852 319.849 95.8377 317.443 95.2925 315.803C95.2925 315.803 99.3056 279.499 106.334 270.65C110.306 265.648 114.972 250.643 115.19 245.393C115.409 240.146 116.611 229.759 116.611 229.759L142.085 236.977Z"
        fill="#5C5C8E"
      />
      <path
        d="M131.564 180.699C125.607 183.4 114.257 179.418 114.257 179.418C114.257 179.418 119.449 174.445 118.077 165.57L123.33 168.853L126.898 171.081C126.898 171.081 127.07 172.627 127.042 175.378C127.034 176.101 127.013 176.909 126.97 177.795C129.804 178.933 131.564 180.699 131.564 180.699Z"
        fill="#FFB27D"
      />
      <path
        d="M126.898 171.078C126.898 171.078 127.07 172.624 127.042 175.375C123.364 175.191 118.573 169.858 118.573 169.858C120.344 169.107 121.938 168.819 123.33 168.848L126.898 171.078Z"
        fill="#ED975D"
      />
      <path
        d="M128.774 171.698C128.774 171.698 118.75 174.631 117.02 168.359C115.29 162.088 112.831 158.243 119.159 156.106C125.486 153.969 127.396 156.017 128.482 157.97C129.567 159.924 131.337 170.775 128.774 171.698Z"
        fill="#FFB27D"
      />
      <path
        d="M146.751 232.421C147.064 235.312 147.21 237.112 147.14 237.182C146.971 237.36 146.767 237.479 146.519 237.509C144.19 237.759 137.565 239.866 131.755 239.97C126.862 240.089 122.408 239.171 117.56 237.665C116.918 237.466 115.421 236.885 115.371 236.197C115.301 235.45 115.245 234.356 115.173 232.979C114.927 228.194 114.51 219.963 112.822 210.612C112.511 208.872 112.252 207.136 112.038 205.425C110.452 192.668 111.629 181.138 116.255 176.724C116.655 176.36 119.477 177.343 122.073 178.255C124.473 179.099 126.75 178.952 128.838 178.259L131.206 179.892C134.104 181.997 137.353 184.943 139.195 189.785C140.764 193.906 142.305 201.491 143.603 209.387C144.19 212.967 144.754 216.58 145.199 220.018L145.214 220.141C145.286 220.478 145.332 220.853 145.372 221.194C145.455 221.805 145.532 222.405 145.612 222.996C146.093 226.668 146.483 229.945 146.751 232.421Z"
        fill="#42383C"
      />
      <path
        d="M116.937 167.748C116.937 167.748 117.965 167.445 117.622 166.02C117.28 164.595 117.052 162.705 118.271 162.915C119.491 163.126 119.661 164.061 120.077 163.453C120.494 162.845 120.223 160.528 123.702 159.945C127.182 159.362 128.749 160.409 130.29 159.497C131.831 158.586 131.619 156.114 131.619 156.114C131.619 156.114 131.263 157.049 130.597 157.413C130.597 157.413 132.863 153.375 129.415 151.207C125.966 149.042 122.964 151.094 121.158 151.423C117.181 152.148 114.624 152.701 113.632 155.491C112.642 158.28 114.061 165.524 116.937 167.748Z"
        fill="#42383C"
      />
      <path
        d="M146.752 232.42C146.259 232.511 137.711 234.046 131.899 230.217C125.915 226.276 118.906 202.907 118.906 202.907C118.906 202.907 132.346 217.854 132.607 218.444C132.825 218.94 142.517 222.022 145.61 222.997C146.093 226.668 146.483 229.944 146.752 232.42Z"
        fill="#42383C"
      />
      <path
        d="M169.223 226.426C166.978 226.384 164.101 225.419 163.177 224.961C161.617 224.185 160.822 223.433 160.822 223.433L161.059 221.955L161.343 220.19C161.343 220.19 161.517 220.162 161.805 220.134C162.091 220.103 162.496 220.073 162.952 220.065C162.952 220.065 163.69 220.289 164.302 219.744C164.911 219.198 166.036 218.829 166.887 219.032C167.737 219.236 169.566 219.045 169.625 219.344C169.769 220.064 167.103 219.933 166.793 220.383C166.511 220.792 166.768 221.067 166.819 221.118C166.823 221.12 166.827 221.124 166.831 221.127C168.39 222.7 173.759 223.272 173.545 224.52C173.462 224.982 170.172 226.443 169.223 226.426Z"
        fill="#FFB27D"
      />
      <path
        d="M114.813 193.953C114.813 193.953 127.11 222.38 132.605 225.089C138.101 227.798 163.177 225.693 163.177 225.693L163.342 220.841C163.342 220.841 141.744 216.563 139.66 213.021C137.576 209.479 134.076 187.731 128.543 185.539C123.01 183.348 114.603 186.201 114.813 193.953Z"
        fill="#42383C"
      />
      <path
        opacity="0.1"
        d="M202.429 333.468C206.38 333.468 209.583 333.028 209.583 332.485C209.583 331.942 206.38 331.501 202.429 331.501C198.478 331.501 195.275 331.942 195.275 332.485C195.275 333.028 198.478 333.468 202.429 333.468Z"
        fill="#1739B7"
      />
      <path
        opacity="0.1"
        d="M170.154 329.711C174.105 329.711 177.308 329.271 177.308 328.728C177.308 328.185 174.105 327.745 170.154 327.745C166.203 327.745 163 328.185 163 328.728C163 329.271 166.203 329.711 170.154 329.711Z"
        fill="#1739B7"
      />
      <path
        opacity="0.1"
        d="M184.956 340.39C187.764 340.39 190.04 340.079 190.04 339.694C190.04 339.309 187.764 338.997 184.956 338.997C182.148 338.997 179.872 339.309 179.872 339.694C179.872 340.079 182.148 340.39 184.956 340.39Z"
        fill="#1739B7"
      />
      <path
        d="M202.274 332.268C202.274 332.268 212.306 299.41 200.703 286.249C192.022 276.403 182.167 277.58 177.754 278.797C176.613 279.102 175.551 279.65 174.643 280.405C173.735 281.159 173.001 282.101 172.493 283.167C170.949 286.491 171.227 291.888 182.119 298.189C200.35 308.736 201.484 323.203 201.484 323.203L202.274 332.268Z"
        fill="#1739B7"
      />
      <path
        d="M179.329 283.458C179.329 283.458 208.898 292.717 202.278 332.269"
        stroke="#565987"
        strokeMiterlimit="10"
      />
      <path
        d="M202.68 332.529C202.68 332.529 190.927 308.648 187.701 309.159C186.17 309.411 185.464 310.923 185.142 312.431C184.745 314.388 184.969 316.419 185.782 318.243C187.45 322.079 191.929 329.332 202.68 332.529Z"
        fill="#1739B7"
      />
      <path
        d="M187.79 313.701C187.79 313.701 200.12 331.568 202.504 332.352"
        stroke="#565987"
        strokeMiterlimit="10"
      />
      <path
        d="M186.656 316.492H189.533"
        stroke="#565987"
        strokeMiterlimit="10"
      />
      <path
        d="M190.102 323.027L194.617 323.221"
        stroke="#565987"
        strokeMiterlimit="10"
      />
      <path
        d="M192.852 317.797L192.362 320.148"
        stroke="#565987"
        strokeMiterlimit="10"
      />
      <path
        d="M197.649 324.945L197.561 327.079"
        stroke="#565987"
        strokeMiterlimit="10"
      />
      <path
        d="M202.332 332.529C202.332 332.529 214.08 308.648 217.306 309.159C218.842 309.411 219.548 310.923 219.866 312.431C220.262 314.388 220.038 316.419 219.226 318.243C217.558 322.079 213.074 329.332 202.332 332.529Z"
        fill="#1739B7"
      />
      <path
        d="M182.154 338.106C181.834 337.845 181.602 337.493 181.487 337.097C181.433 336.898 181.45 336.686 181.535 336.498C181.621 336.311 181.769 336.159 181.955 336.069C182.397 335.906 182.838 336.202 183.191 336.51C183.544 336.819 183.946 337.145 184.387 337.07C184.153 336.858 183.978 336.589 183.879 336.289C183.78 335.989 183.76 335.669 183.822 335.359C183.84 335.231 183.894 335.111 183.977 335.011C184.215 334.755 184.647 334.866 184.934 335.068C185.817 335.703 186.095 336.934 186.099 338.045C186.144 337.637 186.144 337.226 186.099 336.819C186.088 336.614 186.127 336.408 186.213 336.222C186.299 336.035 186.43 335.872 186.594 335.747C186.809 335.63 187.051 335.572 187.295 335.58C187.498 335.551 187.705 335.565 187.902 335.621C188.099 335.677 188.282 335.775 188.438 335.906C188.589 336.111 188.666 336.359 188.658 336.613C188.65 336.866 188.557 337.11 188.394 337.304C188.056 337.683 187.656 338.002 187.212 338.248C186.874 338.451 186.586 338.726 186.369 339.054C186.342 339.099 186.321 339.146 186.307 339.196H183.738C183.163 338.906 182.63 338.539 182.154 338.106Z"
        fill="#1739B7"
      />
      <path
        d="M169.686 318.662C169.686 318.662 168.729 319.914 170.128 321.805C171.527 323.697 172.683 325.293 172.215 326.47C172.215 326.47 170.101 322.943 168.38 322.912C166.659 322.881 167.793 320.756 169.686 318.662Z"
        fill="#1739B7"
      />
      <path
        opacity="0.1"
        d="M169.686 318.662C169.604 318.783 169.538 318.915 169.492 319.054C167.815 321.025 166.919 322.864 168.534 322.912C170.035 322.956 171.84 325.637 172.268 326.316C172.254 326.368 172.238 326.42 172.219 326.47C172.219 326.47 170.105 322.943 168.384 322.912C166.663 322.881 167.793 320.756 169.686 318.662Z"
        fill="black"
      />
      <path
        d="M167.908 320.258C167.908 320.7 167.956 321.057 168.018 321.057C168.08 321.057 168.128 320.7 168.128 320.258C168.128 319.817 168.067 320.024 168.005 320.024C167.943 320.024 167.908 319.817 167.908 320.258Z"
        fill="#FFD037"
      />
      <path
        d="M167.294 320.787C167.683 320.999 168.018 321.123 168.049 321.07C168.08 321.017 167.788 320.805 167.4 320.593C167.012 320.381 167.166 320.536 167.135 320.593C167.104 320.65 166.906 320.575 167.294 320.787Z"
        fill="#FFD037"
      />
      <path
        d="M174.748 318.662C174.748 318.662 175.71 319.914 174.307 321.805C172.903 323.697 171.752 325.293 172.219 326.47C172.219 326.47 174.333 322.943 176.055 322.912C177.776 322.881 176.642 320.756 174.748 318.662Z"
        fill="#1739B7"
      />
      <path
        opacity="0.1"
        d="M174.748 318.662C174.832 318.783 174.898 318.915 174.947 319.054C176.624 321.025 177.52 322.864 175.905 322.912C174.404 322.956 172.599 325.637 172.167 326.316C172.179 326.369 172.197 326.421 172.219 326.47C172.219 326.47 174.333 322.943 176.055 322.912C177.776 322.881 176.642 320.756 174.748 318.662Z"
        fill="black"
      />
      <path
        d="M176.531 320.258C176.531 320.7 176.483 321.057 176.421 321.057C176.359 321.057 176.311 320.7 176.311 320.258C176.311 319.817 176.372 320.024 176.434 320.024C176.496 320.024 176.531 319.817 176.531 320.258Z"
        fill="#FFD037"
      />
      <path
        d="M177.145 320.787C176.756 320.999 176.416 321.123 176.39 321.07C176.363 321.017 176.65 320.805 177.039 320.593C177.427 320.381 177.273 320.536 177.299 320.593C177.326 320.65 177.529 320.575 177.145 320.787Z"
        fill="#FFD037"
      />
      <path
        d="M176.227 326.307C176.227 326.307 173.548 326.224 172.74 325.65C171.933 325.077 168.618 324.389 168.415 325.311C168.212 326.232 164.39 329.897 167.413 329.919C170.436 329.941 174.444 329.447 175.251 328.957C176.059 328.468 176.227 326.307 176.227 326.307Z"
        fill="#A8A8A8"
      />
      <path
        opacity="0.2"
        d="M167.36 329.596C170.388 329.623 174.391 329.129 175.198 328.635C175.812 328.265 176.055 326.924 176.139 326.303H176.227C176.227 326.303 176.059 328.463 175.251 328.948C174.444 329.433 170.441 329.936 167.413 329.91C166.531 329.91 166.239 329.592 166.257 329.133C166.376 329.42 166.734 329.574 167.36 329.596Z"
        fill="black"
      />
      <path
        d="M370 327.056C370 327.056 377.529 326.827 379.802 325.213C382.075 323.599 391.387 321.686 391.952 324.256C392.517 326.827 403.272 337.14 394.768 337.211C386.263 337.281 375.009 335.888 372.741 334.508C370.472 333.128 370 327.056 370 327.056Z"
        fill="#A8A8A8"
      />
      <path
        opacity="0.1"
        d="M121.016 341.148C145.877 341.148 166.032 339.324 166.032 337.074C166.032 334.824 145.877 333 121.016 333C96.1543 333 76 334.824 76 337.074C76 339.324 96.1543 341.148 121.016 341.148Z"
        fill="#1739B7"
      />
      <path
        opacity="0.1"
        d="M456.016 335.148C480.877 335.148 501.032 333.324 501.032 331.074C501.032 328.824 480.877 327 456.016 327C431.154 327 411 328.824 411 331.074C411 333.324 431.154 335.148 456.016 335.148Z"
        fill="#1739B7"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7675_4513"
          x1="476.825"
          y1="272.416"
          x2="497.93"
          y2="242.883"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AB316D" />
          <stop offset="1" stopColor="#792D3D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7675_4513"
          x1="486.628"
          y1="255.915"
          x2="459.524"
          y2="296.674"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="0.9964" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_7675_4513"
          x1="426.782"
          y1="319.301"
          x2="405.72"
          y2="348.864"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AB316D" />
          <stop offset="1" stopColor="#792D3D" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_7675_4513"
          x1="420.693"
          y1="333.115"
          x2="410.264"
          y2="298.531"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="0.9964" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_7675_4513"
          x1="409.054"
          y1="324.643"
          x2="425.394"
          y2="267.303"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#58595A" />
          <stop offset="0.9964" stopColor="#26264F" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_7675_4513"
          x1="429.219"
          y1="210.802"
          x2="418.255"
          y2="184.015"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="0.9964" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_7675_4513"
          x1="427.635"
          y1="184.06"
          x2="398.9"
          y2="207.974"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="0.9964" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_7675_4513"
          x1="481.367"
          y1="181.034"
          x2="483.407"
          y2="218.399"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="0.9964" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_7675_4513"
          x1="407.745"
          y1="192.744"
          x2="405.035"
          y2="196.801"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="0.9964" stopColor="#F36F56" />
        </linearGradient>
      </defs>
    </svg>
  );
}
