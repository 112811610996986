export const getDomainPrice = (dollarVal) => {
  return Math.floor((dollarVal * 90).toFixed(2));
};
export const getStageTitle = (stage) => {
  let title = "";
  switch (stage) {
    case 0:
      title = "Domain Mapping";
      break;
    case 1:
      title = "Addons";
      break;
    case 2:
      title = "Business & Address  Details";
      break;
    default:
  }
  return title;
};

export const calculateCartTotal = (
  currentSelectedPlan,
  selectedDomain = null,
  addons = []
) => {
  if (!currentSelectedPlan) {
    return 0;
  }

  let totalPrice = currentSelectedPlan.amount / 100;

  if (addons && Array.isArray(addons)) {
    addons.forEach((addon) => {
      if (addon && addon.amount) {
        totalPrice += addon.amount / 100;
      }
    });
  }

  if (selectedDomain) {
    const domainCost = (selectedDomain.purchasePrice * 90).toFixed(2);
    return Math.floor(+domainCost + totalPrice);
  } else {
    return Math.floor(totalPrice);
  }
};
