import { Box, TableCell } from "@material-ui/core";
import React, { useState, useEffect, useRef, useCallback } from "react";
import Controls from "../../../components/controls/Controls";
import useStyles from "../styles";
import { useDispatch } from "react-redux";
import { updateOrderFields } from "../../../redux/orders/orderActions";

function useFocusNext() {
  const controls = useRef([]);
  const handler = useCallback((event) => {
    const code = event.which || event.keyCode || event.charCode;
    if (code === 13) {
      // Required if the controls can be reordered
      controls.current = controls.current
        .filter((control) => document.body.contains(control))
        .sort((a, b) =>
          a.compareDocumentPosition(b) & Node.DOCUMENT_POSITION_FOLLOWING
            ? -1
            : 1
        );
      const index = controls.current.indexOf(event.target);
      const next = controls.current[index + 1];
      next && next.focus();
      // IE 9, 10
      event.preventDefault();
    }
  }, []);

  return useCallback(
    (element) => {
      if (element && !controls.current.includes(element)) {
        controls.current.push(element);
        element.addEventListener("keydown", handler);
      }
    },
    [handler]
  );
}

const ConfirmedStateTableList = ({
  type,
  tableitem,
  logisticAppsInstalled,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [trackingId, setTrackingId] = useState("");
  const [deliveryPartner, setDeliveryPartner] = useState("");
  const [dimension, setDimension] = useState({ l: "", w: "", h: "" });
  const [weight, setWeight] = useState("");
  const focusNextRef = useFocusNext();

  useEffect(() => {
    const dim = tableitem?.dimension?.split("X") ?? null;
    setTrackingId(tableitem?.tracking_id ?? "");
    setDeliveryPartner(tableitem?.delivery_partner ?? "");
    setDimension({
      l: dim && dim[0] ? dim[0] : "",
      w: dim && dim[1] ? dim[1] : "",
      h: dim && dim[2] ? dim[2] : "",
    });
    setWeight(tableitem?.weight ?? "");
    // eslint-disable-next-line
  }, []);

  const onEnterValue = (e) => {
    let payload = {};

    if (e.target.name === "tracking_id") {
      payload.tracking_id = trackingId;
    }
    if (e.target.name === "delivery_partner") {
      payload.delivery_partner = deliveryPartner;
    }
    if (e.target.name === "dimension") {
      const isEmpty = Object.values(dimension).every(
        (x) => x === null || x === ""
      );
      if (isEmpty) {
        payload.dimension = "";
      } else {
        payload.dimension = dimension.l + "X" + dimension.w + "X" + dimension.h;
      }
    }
    if (e.target.name === "weight") {
      payload.weight = weight;
    }
    dispatch(updateOrderFields(payload, tableitem.id)).then(() => {});
  };

  if (type === "Header") {
    return (
      <>
        <TableCell style={{ width: "10%", whiteSpace: "nowrap" }}>
          Tracking ID
        </TableCell>
        <TableCell style={{ width: "10%", whiteSpace: "nowrap" }}>
          Delivery Partner
        </TableCell>
        {logisticAppsInstalled && (
          <>
            <TableCell style={{ width: "20%", whiteSpace: "nowrap" }}>
              Dimension (cms)
            </TableCell>
            <TableCell style={{ width: "10%", whiteSpace: "nowrap" }}>
              Weight (gms)
            </TableCell>
          </>
        )}
      </>
    );
  }

  if (type === "Body") {
    return (
      <>
        <TableCell style={{ minWidth: "105px", whiteSpace: "nowrap" }}>
          <Controls.Input
            name="tracking_id"
            inputRef={focusNextRef}
            placeholder="Add"
            color="secondary"
            value={trackingId}
            onChange={(e) => {
              setTrackingId(e.target.value);
            }}
            onEnter={onEnterValue}
            onBlur={onEnterValue}
            InputProps={{
              classes: {
                notchedOutline: classes.dashedTextField,
                input: classes.inputDashedTextField,
              },
            }}
            className={classes.rootDashedTextField}
            hideHelperText={true}
            style={{ minWidth: 105 }}
          />
          {/* <div className={classes.customeInfoToast}>
            please hit <b>Enter</b> to save
          </div> */}
        </TableCell>
        <TableCell style={{ minWidth: "115px", whiteSpace: "nowrap" }}>
          <Controls.Input
            name="delivery_partner"
            placeholder="Add"
            inputRef={focusNextRef}
            color="secondary"
            value={deliveryPartner}
            onChange={(e) => {
              setDeliveryPartner(e.target.value);
            }}
            onEnter={onEnterValue}
            onBlur={onEnterValue}
            InputProps={{
              classes: {
                notchedOutline: classes.dashedTextField,
                input: classes.inputDashedTextField,
              },
            }}
            className={classes.rootDashedTextField}
            hideHelperText={true}
            style={{ minWidth: 110 }}
          />
        </TableCell>
        {logisticAppsInstalled && (
          <>
            <TableCell style={{ width: "20%", whiteSpace: "nowrap" }}>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Controls.Input
                  name="dimension"
                  type="number"
                  placeholder="L"
                  inputRef={focusNextRef}
                  color="secondary"
                  value={dimension.l}
                  onChange={(e) => {
                    setDimension({ ...dimension, l: e.target.value });
                  }}
                  onEnter={onEnterValue}
                  onBlur={onEnterValue}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.dashedTextField,
                      input: classes.inputDashedTextField,
                    },
                  }}
                  className={classes.rootDashedTextField}
                  hideHelperText={true}
                  style={{ width: 50 }}
                />
                <p
                  style={{
                    flexBasis: "8%",
                    margin: "0px 6px",
                    textAlign: "center",
                  }}
                >
                  x
                </p>
                <Controls.Input
                  name="dimension"
                  placeholder="W"
                  type="number"
                  inputRef={focusNextRef}
                  color="secondary"
                  value={dimension.w}
                  onChange={(e) => {
                    setDimension({ ...dimension, w: e.target.value });
                  }}
                  onEnter={onEnterValue}
                  onBlur={onEnterValue}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.dashedTextField,
                      input: classes.inputDashedTextField,
                    },
                  }}
                  className={classes.rootDashedTextField}
                  hideHelperText={true}
                  style={{ width: 50 }}
                />
                <p
                  style={{
                    flexBasis: "8%",
                    margin: "0px 6px",
                    textAlign: "center",
                  }}
                >
                  x
                </p>
                <Controls.Input
                  name="dimension"
                  placeholder="H"
                  type="number"
                  inputRef={focusNextRef}
                  color="secondary"
                  value={dimension.h}
                  onChange={(e) => {
                    setDimension({ ...dimension, h: e.target.value });
                  }}
                  onEnter={onEnterValue}
                  onBlur={onEnterValue}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.dashedTextField,
                      input: classes.inputDashedTextField,
                    },
                  }}
                  className={classes.rootDashedTextField}
                  hideHelperText={true}
                  style={{ width: 50 }}
                />
              </Box>
            </TableCell>
            <TableCell style={{ minWidth: "85px", whiteSpace: "nowrap" }}>
              <Controls.Input
                name="weight"
                placeholder="Add"
                type="number"
                inputRef={focusNextRef}
                color="secondary"
                value={weight}
                onChange={(e) => {
                  setWeight(e.target.value);
                }}
                onEnter={onEnterValue}
                onBlur={onEnterValue}
                InputProps={{
                  classes: {
                    notchedOutline: classes.dashedTextField,
                    input: classes.inputDashedTextField,
                  },
                }}
                className={classes.rootDashedTextField}
                hideHelperText={true}
                style={{ minWidth: 85 }}
              />
            </TableCell>
          </>
        )}
      </>
    );
  }
};

export default ConfirmedStateTableList;
