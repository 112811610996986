import React from "react";
import { makeStyles } from "@material-ui/core";

import Controls from "../../../components/controls/Controls";

const useStyles = makeStyles(() => ({
  twoColumnGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: "20px",
  },
}));

const RenderDataTypeFields = ({
  filedType,
  formData,
  handleChange,
  onFocus,
  onBlur,
}) => {
  const classes = useStyles();

  switch (filedType) {
    case "TEXT":
      return (
        <div className={classes.twoColumnGrid}>
          <Controls.Input
            name="textLimit"
            label="Set Text Limit"
            labelPlacement="top"
            value={formData.textLimit.value}
            onChange={handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
            endAdornment={
              <span style={{ fontSize: 14, color: "#666666" }}>chars</span>
            }
            helperText={
              formData.textLimit.error ||
              "Set the character limit for customer input."
            }
            error={!!formData.textLimit.error}
            extraSpace={!!formData.textLimit.error}
          />
        </div>
      );
    case "FILE":
      return (
        <div>
          <div style={{ fontSize: 14, fontWeight: 400, color: "#1A1A1A" }}>
            Choose File Type
          </div>
          <p style={{ fontSize: 12, fontWeight: 400, color: "#666666" }}>
            Selecting the file type permits customers to download only the
            chosen files.
          </p>
          <div style={{ paddingBottom: 10 }}>
            <Controls.Checkbox
              name="documentType"
              label={
                <span style={{ fontWeight: 400 }}>
                  Document (.xlxs, .csv, pdf, doc)
                </span>
              }
              checkboxStyles={{ padding: 0, marginLeft: 11, marginRight: 12 }}
              value={formData.documentType.value}
              color="secondary"
              onChange={handleChange}
              onFocus={onFocus}
            />
            <Controls.Checkbox
              name="imageType"
              label={
                <span style={{ fontWeight: 400 }}>
                  Image (.png, .jpeg, jpg)
                </span>
              }
              checkboxStyles={{ padding: 0, marginLeft: 11, marginRight: 12 }}
              value={formData.imageType.value}
              color="secondary"
              onChange={handleChange}
              onFocus={onFocus}
            />
          </div>
          {formData.fileError.value && (
            <p style={{ color: "#F44336" }}>{formData.fileError.error}</p>
          )}
        </div>
      );
    case "DATE_TIME":
      return (
        <div className={classes.twoColumnGrid}>
          <Controls.Input
            name="minBufferPeriod"
            label="Min Buffer Period"
            labelPlacement="top"
            value={formData.minBufferPeriod.value}
            onChange={handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
            helperText={
              formData.minBufferPeriod.error ||
              "Set the minimum required days between booking and service."
            }
            endAdornment={<span>Days</span>}
            error={!!formData.minBufferPeriod.error}
            extraSpace={!!formData.minBufferPeriod.error}
          />
          <Controls.Input
            name="maxBufferPeriod"
            label="Max Allowed Duration"
            labelPlacement="top"
            value={formData.maxBufferPeriod.value}
            onChange={handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
            helperText={
              formData.maxBufferPeriod.error ||
              "Limits the maximum days for advance booking."
            }
            endAdornment={<span>Days</span>}
            error={!!formData.maxBufferPeriod.error}
            extraSpace={!!formData.maxBufferPeriod.error}
          />
          <Controls.Input
            name="minTimeRange"
            label="Min Time Range"
            labelPlacement="top"
            type="time"
            value={formData.minTimeRange.value}
            onChange={handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
            helperText={
              formData.minTimeRange.error || "Set the starting time in a day."
            }
            error={!!formData.minTimeRange.error}
            extraSpace={!!formData.minTimeRange.error}
          />
          <Controls.Input
            name="maxTimeRange"
            label="Max Time Range"
            labelPlacement="top"
            type="time"
            value={formData.maxTimeRange.value}
            onChange={handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
            helperText={
              formData.maxTimeRange.error || "Set the closing time in a day."
            }
            error={!!formData.maxTimeRange.error}
            extraSpace={!!formData.maxTimeRange.error}
          />
        </div>
      );
    case "DATE":
      return (
        <div className={classes.twoColumnGrid}>
          <Controls.Input
            name="minBufferPeriod"
            label="Min Buffer Period"
            labelPlacement="top"
            value={formData.minBufferPeriod.value}
            onChange={handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
            helperText={
              formData.minBufferPeriod.error ||
              "Set the minimum required days between booking and service."
            }
            endAdornment={<span>Days</span>}
            error={!!formData.minBufferPeriod.error}
            extraSpace={!!formData.minBufferPeriod.error}
          />
          <Controls.Input
            name="maxBufferPeriod"
            label="Max Allowed Duration"
            labelPlacement="top"
            value={formData.maxBufferPeriod.value}
            onChange={handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
            helperText={
              formData.maxBufferPeriod.error ||
              "Limits the maximum days for advance booking."
            }
            endAdornment={<span>Days</span>}
            error={!!formData.maxBufferPeriod.error}
            extraSpace={!!formData.maxBufferPeriod.error}
          />
        </div>
      );
    default:
      return <></>;
  }
};

export default RenderDataTypeFields;
