import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CarouselPrevBtn = ({ viewBox = "0 0 40 60" }) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "40px", height: "60px" }}>
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_8740_7491)">
          <path
            d="M33 48C33 52.4183 29.4183 56 25 56L1 56L1 4L25 4C29.4183 4 33 7.58172 33 12L33 48Z"
            fill="white"
          />
        </g>
        <path
          d="M19 34L15 30L19 26"
          stroke="#1641DB"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <filter
            id="filter0_d_8740_7491"
            x="0"
            y="0"
            width="40"
            height="60"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="3" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_8740_7491"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_8740_7491"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default CarouselPrevBtn;
