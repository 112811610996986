export const FETCH_INVOICES = "FETCH_INVOICES";
export const INVOICE_PROGRESS = "INVOICE_PROGRESS";
export const UPDATE_INVOICE = "UPDATE_INVOICE";
export const SET_INVOICE_ERROR = "SET_INVOICE_ERROR";
export const SET_INVOICE_LOADER = "SET_INVOICE_LOADER";
export const CREATE_INVOICE = "CREATE_INVOICE";
export const CLEAR_INVOICE = "CLEAR_INVOICE";
export const CLEAR_COMPLETE_INVOICE = "CLEAR_COMPLETE_INVOICE";
export const DELETE_INVOICE = "DELETE_INVOICE";
export const INVOICE_DATE_SELECTOR = "INVOICE_DATE_SELECTOR";
export const INVOICE_STATUS_SELECTOR = "INVOICE_STATUS_SELECTOR";
export const INVOICE_DATE_RANGE = "INVOICE_DATE_RANGE";
export const SET_INVOICES_PER_PAGE = "SET_INVOICES_PER_PAGE";
export const SET_INVOICES_CURRENT_PAGE = "SET_INVOICES_CURRENT_PAGE";
export const SET_INVOICES_MAX_PAGE_SO_FAR = "SET_INVOICES_MAX_PAGE_SO_FAR";
export const SET_INVOICES_INPUT_KEYWORD = "SET_INVOICES_INPUT_KEYWORD";
export const SET_INVOICES_SEARCH_KEYWORD = "SET_INVOICES_SEARCH_KEYWORD";
export const SET_INVOICES_CUSTOMER_ID = "SET_INVOICES_CUSTOMER_ID";
