import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ContractIcon = ({ viewBox = '0 0 16 16', stroke = '#999999', fill = '#999999' }) => {
    return (
        <SvgIcon viewBox={viewBox} style={{ width: '16px', height: '16px' }}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.5 9.5L8 6L11.5 9.5" stroke="#1A1A1A" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8 0.5C3.85786 0.5 0.5 3.85786 0.5 8C0.5 12.1421 3.85786 15.5 8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.85786 12.1421 0.5 8 0.5Z" stroke="#1A1A1A" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        </SvgIcon>
    );
};

export default ContractIcon;
