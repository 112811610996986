import React, { useEffect, useState } from "react";
import useStyles from "../components/ConfigStyles";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchApps,
  getPixelValue,
  postPixelValue,
} from "../../../redux/apps/appActions";
import PageLoader from "../../../components/Layout/PageLoader";
import Toast from "../../../components/Layout/Toast";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import Controls from "../../../components/controls/Controls";
import { Paper, Typography } from "@material-ui/core";
import HeaderBreadcrumbs from "../../../components/common/HeaderBreadcrumbs";
import { centerContainer, layoutCenterContainer } from "../../../styles/common";
const list = [
  {
    label: "App Store",
    link: "/apps",
  },
  {
    label: "Tawk To",
    link: "/apps/tawkto",
  },
  {
    label: "Configure",
  },
];

const helperText =
  "Enter the Tawk To script source URL Eg.https://embed.tawk.to/6109964ed6e7610a49ae6ba1/1fc6n696f";

const TawkToConfig = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const storeData = useSelector((state) => state.store);
  const appState = useSelector((state) => state.apps);
  const apps = appState.apps;

  const initialState = {
    tawkto: {
      value: "",
      touched: false,
      error: "",
    },
  };

  const [formState, setFormState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (apps.length === 0) {
      dispatch(fetchApps());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apps.length]);

  useEffect(() => {
    if (storeData.fetched) {
      dispatch(getPixelValue("tawkto"));
    }
    //eslint-disable-next-line
  }, [storeData]);

  useEffect(() => {
    setFormState((prevState) => ({
      ...prevState,
      tawkto: {
        ...prevState.tawkto,
        value: appState.pixels.tawkto,
      },
    }));
    setLoading(false);
    //eslint-disable-next-line
  }, [appState.pixels]);

  const getMessage = (name) => {
    const messages = {
      tawkto: "Please enter a valid Tawk.to embed script URL.",
    };
    return messages[name] || "";
  };

  const handleChnage = (event) => {
    let { name, value } = event.target;
    let cloneData = formState[name];
    cloneData.touched = true;
    cloneData.value = value;
    setFormState({ ...formState, [name]: cloneData });
  };

  const onBlur = (event) => {
    const { name } = event.target;
    const cloneData = formState[name];
    if (!cloneData.value) cloneData.error = getMessage(name);
    setFormState((prevState) => ({ ...prevState, [name]: cloneData }));
  };

  const onFocus = (event) => {
    const { name } = event.target;
    let cloneData = formState[name];
    cloneData.touched = true;
    cloneData.error = "";
    setFormState((prevState) => ({ ...prevState, [name]: cloneData }));
  };

  const validatation = () => {
    let errorFlag = false;
    Object.keys(formState).forEach((key) => {
      let cloneData = formState[key];
      if (!cloneData.value) {
        errorFlag = true;
        cloneData.error = getMessage(key);
      }
      setFormState({ ...formState, [key]: cloneData });
    });
    return errorFlag;
  };

  const navigateToUrl = () => {
    history.goBack();
  };

  const handleSave = async () => {
    let isError = validatation();
    if (isError) return;

    dispatch(
      postPixelValue([
        {
          name: "tawkto",
          value: formState.tawkto.value,
        },
      ])
    ).then((response) => {
      if (response.error)
        setError(
          "Failed to save the Tawk To script source URL. Please ensure the URL format and try again."
        );
      else setSuccess("Tawk To script source URL has been successfully saved.");
    });
  };

  if (loading && !storeData.fetched) return <PageLoader />;

  return (
    <div className={layoutCenterContainer}>
      <div className={centerContainer}>
        <div className={classes.breadcrumbsContainer}>
          <div className={classes.backDiv}>
            <HeaderBreadcrumbs list={list} />
          </div>
        </div>
        <Paper elevation={0} className={classes.paper}>
          <div>
            <Typography className={classes.formHead}>
              Tawk To Configuration Settings
            </Typography>
            <Controls.Input
              name="tawkto"
              label="Tawk To"
              labelPlacement="top"
              value={formState.tawkto.value}
              onChange={handleChnage}
              onFocus={onFocus}
              onBlur={onBlur}
              error={formState.tawkto.error}
              helperText={!formState.tawkto.error && helperText}
              extraSpace={!!formState.tawkto.error}
            />
          </div>

          <div className={classes.button}>
            <CombinedButtons
              outlinedBtnText="Back"
              outlinedBtnAction={navigateToUrl}
              solidBtnAction={handleSave}
            />
          </div>
        </Paper>
      </div>

      <Toast
        autoClose
        message={success}
        open={!!success}
        close={() => setSuccess(false)}
      />
      <Toast
        autoClose
        message={error}
        open={!!error}
        close={() => setError(false)}
        severity="error"
      />
    </div>
  );
};

export default TawkToConfig;
