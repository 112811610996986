import { getDefaultStore } from "../../../utils/storeUtil";

export const fetchProducts = async (
  keyword,
  sort,
  catSlugs,
  rootCatSlugs,
  page,
  size
) => {
  let store = getDefaultStore();
  let accessToken = localStorage.getItem("access_token");

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/v2/org/${
        store.org_id
      }/super-products/search?child-cat-products=true&page=${page}&size=${size}&${
        keyword && keyword !== "" ? `q=${keyword}` : ""
      }${sort ? `&sort-by=${sort}` : ""}${catSlugs}${rootCatSlugs}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch the products");
    }
    const data = await response.json();
    return data.payload;
  } catch (error) {
    return {
      error: error.message,
    };
  }
};

export const position = async (list, slug) => {
  let store = getDefaultStore();
  const accessToken = localStorage.getItem("access_token");
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/v2/business/org/${store.org_id}/store/${store.store_id}/categories/${slug}/super-products`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(list),
      }
    );

    const data = await response.json();

    if (!response.ok) {
      if (data.message) {
        throw new Error(data.message);
      }
      throw new Error("Something went wrong");
    }
    return data.payload;
  } catch (error) {
    return {
      error: error.message,
    };
  }
};

export const unassignProducts = async (list, slug) => {
  let store = getDefaultStore();
  const accessToken = localStorage.getItem("access_token");
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/v2/business/org/${store.org_id}/store/${store.store_id}/categories/${slug}/super-products`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(list),
      }
    );
    if (!response.ok) {
      throw new Error("Failed to delete super-products.");
    }
    const data = await response.json();
    return data.payload;
  } catch (error) {
    return {
      error: error.message,
    };
  }
};

export const showOrHideCategory = async (categoryId, value, params) => {
  let store = getDefaultStore();
  const accessToken = localStorage.getItem("access_token");
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/v3/store/${store.store_id}/categories/${categoryId}/${params}`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          show: value,
        }),
      }
    );
    if (!response.ok) {
      throw new Error(
        "Category Offline/Online action failed. Please try again."
      );
    }
    const data = await response.json();
    return data.payload;
  } catch (error) {
    return {
      error: error.message,
    };
  }
};
