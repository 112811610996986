import React, { useState, useEffect } from "react";
import { getCityAndState } from "../../../../utils/customerUtils";
import FormModal from "../../../../components/common/FormModal";
import Controls from "../../../../components/controls/Controls";
import { rowFlexJustifyBetween } from "../../../../styles/common";
import states from "../../../../constants/states.json";

const LocationFormModal = ({ open, setOpen, title, onSave, record }) => {
  const [locationState, setLocationState] = useState({
    pickup_location: "",
    name: "",
    phone: "",
    address: "",
    address_2: "",
    pin_code: "",
    city: "",
    state: "",
  });
  const [errors, setErrors] = useState({
    pickup_location: "",
    name: "",
    phone: "",
    address: "",
    address_2: "",
    pin_code: "",
    city: "",
    state: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, [open, record]);

  const init = () => {
    setLocationState({
      pickup_location: record?.pickup_location ?? "",
      name: record?.name ?? "",
      phone: record?.phone?.replaceAll(" ", "").slice(-10) ?? "",
      address: record?.address ?? "",
      address_2: record?.address_2 ?? "",
      pin_code: record?.pin_code ?? "",
      city: record?.city ?? "",
      state: record?.state ?? "",
    });
  };

  useEffect(() => {
    if (record === null && locationState.pin_code.length === 6) {
      getCityAndState(locationState.pin_code).then((res) => {
        if (res.data && res.data.state) {
          setLocationState({
            ...locationState,
            city: res.data.city ? res.data.city : locationState.city,
            state: res.data.state,
          });
        }
      });
    }
    // eslint-disable-next-line
  }, [locationState.pin_code]);

  const onChange = (e) => {
    setLocationState({ ...locationState, [e.target.name]: e.target.value });
  };

  const onBlur = (e) => {
    if (e.target.name === "name" && e.target.value.trim() === "") {
      setErrors({ ...errors, name: "Please enter contact name" });
    }
    if (e.target.name === "phone") {
      if (e.target.value.trim() === "") {
        setErrors({ ...errors, phone: "Please enter phone" });
      }
      if (e.target.value.trim() !== "" && e.target.value.length < 10) {
        setErrors({ ...errors, phone: "Invalid phone number" });
      }
    }
    if (e.target.name === "pin_code") {
      if (e.target.value.trim() === "") {
        setErrors({ ...errors, pin_code: "Please enter pincode" });
      }
      if (e.target.value.trim() !== "" && e.target.value.length < 6) {
        setErrors({ ...errors, pin_code: "Invalid pincode" });
      }
    }
    if (e.target.name === "city" && e.target.value.trim() === "") {
      setErrors({ ...errors, city: "Please enter city" });
    }

    if (e.target.name === "pickup_location" && e.target.value.trim() === "") {
      setErrors({ ...errors, pickup_location: "Please enter location name" });
    }
    if (e.target.name === "state" && e.target.value === "") {
      setErrors({ ...errors, state: "Please select state" });
    }
  };

  const onFocus = (e) => {
    setErrors({ ...errors, [e.target.name]: false });
  };

  const handleSave = () => {
    const tempErrors = { ...errors };
    let invalid = false;
    Object.entries(locationState).forEach(([key, value]) => {
      if (key === "name" && value.trim() === "") {
        tempErrors[key] = "Please enter contact name";
        invalid = true;
        return;
      }
      if (key === "phone") {
        if (value.trim() === "") {
          tempErrors[key] = "Please enter phone";
          invalid = true;
          return;
        }
        if (value.trim() !== "" && value < 10) {
          tempErrors[key] = "Invalid phone number";
          invalid = true;
          return;
        }
      }
      if (key === "pin_code") {
        if (value.trim() === "") {
          tempErrors[key] = "Please enter pincode";
          invalid = true;
          return;
        }
        if (value.trim() !== "" && value < 10) {
          tempErrors[key] = "Invalid pincode";
          invalid = true;
          return;
        }
      }
      if (key === "pickup_location" && value.trim() === "") {
        tempErrors[key] = "Please enter location name";
        invalid = true;
        return;
      }
      if (key === "city" && value.trim() === "") {
        tempErrors[key] = "Please enter city";
        invalid = true;
        return;
      }
      if (key === "state" && value === "none") {
        tempErrors[key] = "Please select state";
        invalid = true;
        return;
      }
    });
    if (invalid) {
      setErrors(tempErrors);
      return;
    }
    setLoading(true);
    onSave({
      ...locationState,
      state: locationState.state ? locationState.state : "",
    }).then(() => {
      setLoading(false);
      setOpen(false);
      init();
      setErrors({
        pickup_location: "",
        name: "",
        phone: "",
        address: "",
        address_2: "",
        pin_code: "",
        city: "",
        state: "",
      });
    });
  };

  return (
    <FormModal
      width={600}
      heading={title}
      open={open}
      onClose={() => {
        setOpen(false);
        init();
        setErrors({
          pickup_location: "",
          name: "",
          phone: "",
          address: "",
          address_2: "",
          pin_code: "",
          city: "",
          state: "",
        });
      }}
      onSave={handleSave}
      loading={loading}
    >
      <Controls.Input
        name="pickup_location"
        label="Location Name"
        type="string"
        value={locationState.pickup_location}
        onChange={onChange}
        labelPlacement="top"
        onBlur={onBlur}
        onFocus={onFocus}
        error={errors.pickup_location ? errors.pickup_location : ""}
      />
      <div className={rowFlexJustifyBetween}>
        <Controls.Input
          name="name"
          label="Contact Name"
          type="string"
          width={267}
          value={locationState.name}
          onChange={onChange}
          labelPlacement="top"
          onBlur={onBlur}
          onFocus={onFocus}
          error={errors.name ? errors.name : ""}
        />
        <Controls.Input
          name="phone"
          maxLength={10}
          label="Contact Mobile"
          type="number"
          width={267}
          value={locationState.phone}
          onChange={onChange}
          labelPlacement="top"
          onBlur={onBlur}
          onFocus={onFocus}
          error={errors.phone ? errors.phone : ""}
        />
      </div>
      <Controls.Input
        name="address"
        label="Address"
        type="string"
        value={locationState.address}
        onChange={onChange}
        maxLength={255}
        labelPlacement="top"
        onBlur={onBlur}
        onFocus={onFocus}
        error={errors.address ? errors.address : ""}
      />
      <div className={rowFlexJustifyBetween}>
        <Controls.Input
          name="pin_code"
          maxLength={6}
          label="Pincode"
          type="number"
          width={136}
          value={locationState.pin_code}
          onChange={onChange}
          labelPlacement="top"
          onBlur={onBlur}
          onFocus={onFocus}
          error={errors.pin_code ? errors.pin_code : ""}
        />
        <Controls.Input
          name="city"
          label="City"
          type="string"
          width={192}
          value={locationState.city}
          onChange={onChange}
          labelPlacement="top"
          maxLength={64}
          onBlur={onBlur}
          onFocus={onFocus}
          error={errors.city ? errors.city : ""}
        />
        <Controls.Select
          name="state"
          label="State"
          labelPlacement="top"
          width={192}
          value={locationState.state}
          placeholder="Select State"
          options={states.map((state) => ({ id: state, title: state }))}
          color="secondary"
          onChange={(e) => {
            setLocationState({ ...locationState, state: e.target.value });
            onFocus(e);
          }}
          onBlur={onBlur}
          error={errors.state ? errors.state : ""}
        />
      </div>
    </FormModal>
  );
};

export default LocationFormModal;
