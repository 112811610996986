import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const MinusIcon = ({ viewBox = "0 0 9 9", stroke = "#1641DB" }) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "9px", height: "9px" }}>
      <svg
        width="9"
        height="9"
        viewBox="0 0 9 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.5 4.5H8.5"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default MinusIcon;
