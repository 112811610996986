import React from "react";
import useStyles from "../../components/ConfigStyles";
import { Button } from "@material-ui/core";

const WebhookDetails = ({ formData, handleCopyToClipboard }) => {
  const classes = useStyles();
  return (
    <div className={classes.detailsContainer}>
      <p className={classes.infoText}>
        Go to Xpressbees Account, and Navigate to Settings around left sidebar
        and then look for Webhooks option.
      </p>
      <div>
        <div className={classes.copyLabel}>Name</div>
        <p className={classes.helpText}>
          Specify name for the Webhook e.g. My Store Webhook.
        </p>
      </div>
      <div>
        <div className={classes.copyLabel}>Delivery URL</div>
        <div className={classes.detailField}>
          <div className={classes.promotionTeaser}>
            {formData.webhook_url.value}
          </div>
          <Button
            variant="contained"
            color="secondary"
            className={classes.copyButton}
            onClick={handleCopyToClipboard(formData.webhook_url.value)}
          >
            Copy
          </Button>
        </div>
        <p className={classes.helpText}>
          Paste above URL in the webhook section of Xpressbees.
        </p>
      </div>
      <div>
        <div className={classes.copyLabel}>X API KEY</div>
        <div className={classes.detailField}>
          <div className={classes.promotionTeaser}>
            {formData.x_api_key.value}
          </div>
          <Button
            variant="contained"
            color="secondary"
            className={classes.copyButton}
            onClick={handleCopyToClipboard(formData.x_api_key.value)}
          >
            Copy
          </Button>
        </div>
        <p className={classes.helpText}>
          Paste above Secret in the key section of Xpressbees.
        </p>
      </div>
      <div>
        <div className={classes.copyLabel}>Status</div>
        <p className={classes.helpText}>
          Paste above Secret in the key section of Xpressbees.
        </p>
      </div>
    </div>
  );
};

export default WebhookDetails;
