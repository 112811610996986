import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  flexCenterBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 20,
    marginTop: 25,
  },
  InfoContainer: {
    padding: 20,
    borderRadius: 6,
    boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.04)",
  },
  creditContainer: {
    height: "85px",
    width: "100%",
    padding: "20px",
    borderRadius: "6px",
  },
  listContainer: {
    borderRadius: 6,
    boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.04)",
  },
  tabelHead: {
    fontSize: 14,
    fontWeight: 600,
  },

  filterButton: {
    textDecoration: "none",
    fontSize: 12,
    fontWeight: 400,
    color: "#1A1A1A",
    "&:hover": {
      background: "#FFFFFF !important",
    },
    minWidth: 100,
    border: "1px solid #E5E5E5",
  },
  sheet: {
    position: "absolute",
    minWidth: 100,
    padding: 4,
    top: 35,
    display: "block",
    visibility: "hidden",
    background: "white",
  },
  bulkAction: {
    cursor: "pointer",
    padding: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  upperDiv: {
    padding: 25,
    borderRadius: 12,
    paddingBottom: 16,
    position: "absolute",
    background: "#ffffff",
    width: 500,
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  infoDiv: {
    background: "#F9F9F9",
    borderRadius: 8,
    padding: 10,
    display: "flex",
    alignItems: "center",
    gap: 8,
  },

  button: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  chipContainer: {
    display: "flex",
    justifyContent: "flex-start",
    gap: 20,
    marginBottom: 20,
  },
  chip: {
    minWidth: 80,
    padding: "10px 10px",
  },
  instructions: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    listStyleType: "disc",
    listStylePosition: "inside",
    padding: "10px 10px",
    borderBottom: "1px solid #E1E1E1",
    margin: 0,
  },
  knowMoreText: {
    marginLeft: 5,
    fontWeight: 600,
    color: "#1641DB",
    cursor: "pointer",
  },
}));

export const useStylesWalletRecharge = makeStyles((theme) => ({
  loader: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  nonHeader: {
    width: "100%",
    height: `calc(100vh - 48px)`,
    [theme.breakpoints.up("sm")]: {
      height: `calc(100vh - 64px)`,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  card: {
    minWidth: 270,
    width: "100%",
    height: "100%",
    padding: 12,
    borderRadius: 8,
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.up("sm")]: {
      maxWidth: 480,
      maxHeight: 600,
      padding: 20,
      border: "1px solid #EEEEEE",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
  },
  addCreditText: {
    fontWeight: 700,
    fontSize: 16,
    color: "#1A1A1A",
    [theme.breakpoints.up("sm")]: {
      fontSize: 20,
    },
  },
  storeSection: {
    display: "flex",
    alignItems: "center",
    gap: 12,
    borderBottom: "1px solid #e1e1e1",
    borderTop: "1px solid #e1e1e1",
    padding: "10px 0px",
    [theme.breakpoints.up("sm")]: {
      gap: 24,
    },
  },
  storeName: {
    fontWeight: 600,
    fontSize: 16,
    color: "#1A1A1A",
  },

  infoDiv: {
    background: "#F9F9F9",
    borderRadius: 8,
    padding: 10,
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  chipContainer: {
    display: "flex",
    justifyContent: "flex-start",
    gap: 4,
    marginTop: -10,
    borderBottom: "1px solid #E1E1E1",
    paddingBottom: 20,
    flexWrap: "wrap",
    [theme.breakpoints.up("sm")]: {
      gap: 10,
    },
  },
  chip: {
    minWidth: 80,
    padding: "10px 10px",
  },
  knowMoreText: {
    fontWeight: 600,
    color: "#1641DB",
    cursor: "pointer",
  },
  solidButton: {
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      padding: 12,
    },
  },
}));
