import {
  ADD_BANNER,
  FETCH_BANNERS,
  BANNER_PROGRESS,
  SET_BANNER_ERROR,
  SET_BANNER_LOADER,
  UPDATE_BANNER,
  CLEAR_COMPLETE_BANNER,
  DELETE_BANNER,
  SET_SUCCESS_TOAST,
  POSITION_MOVE_TOP,
  POSITION_MOVE_UP,
  CLEAR_BAANNER_ERROR,
} from "./bannerTypes";
import axios from "axios";
import { getDefaultStore } from "../../utils/storeUtil";

export const addBanner = (bannerData) => async (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  let data = bannerData;
  let storeId = store.store_id;
  return await axios
    .post(
      `${process.env.REACT_APP_API}/api/v1/org/stores/${storeId}/offers`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      dispatch({
        type: ADD_BANNER,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: SET_BANNER_ERROR,
        payload: { error: error.message },
      });
    });
};

export const fetchBanners = () => (dispatch) => {
  dispatch({
    type: BANNER_PROGRESS,
    payload: true,
  });
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  axios
    .get(
      `${process.env.REACT_APP_API}/api/v1/org/stores/${store.store_id}/offers?page=0&size=50&sort=position,desc`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .then((response) => {
      dispatch({
        type: FETCH_BANNERS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: SET_BANNER_ERROR,
        payload: { error: error.message },
      });
    });
};

export const updateBanner = (bannerId, bannerData) => async (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  let data = bannerData;
  let storeId = store.store_id;
  return await axios
    .put(
      `${process.env.REACT_APP_API}/api/v1/org/stores/${storeId}/offers/${bannerId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      dispatch({
        type: UPDATE_BANNER,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: SET_BANNER_ERROR,
        payload: { error: error.message },
      });
    });
};

export const deleteBanner = (bannerId) => async (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  let storeId = store.store_id;
  return await axios
    .delete(
      `${process.env.REACT_APP_API}/api/v1/org/stores/${storeId}/offers/${bannerId}`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      dispatch({
        type: DELETE_BANNER,
        payload: { id: bannerId },
      });
    })
    .catch((error) => {
      dispatch({
        type: SET_BANNER_ERROR,
        payload: { error: error.message },
      });
    });
};

export const toggleBannerActivation =
  (bannerId, activationState) => async (dispatch) => {
    let accessToken = localStorage.getItem("access_token");
    let store = getDefaultStore();
    let storeId = store.store_id;
    return await axios
      .patch(
        `${process.env.REACT_APP_API}/api/v1/org/stores/${storeId}/offers/${bannerId}`,
        { is_hide: activationState },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        dispatch({
          type: UPDATE_BANNER,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_BANNER_ERROR,
          payload: { error: error.message },
        });
      });
  };

export const changeBannerPosition =
  (bannerId, positionState) => async (dispatch) => {
    let accessToken = localStorage.getItem("access_token");
    let store = getDefaultStore();
    let storeId = store.store_id;
    return await axios
      .patch(
        `${process.env.REACT_APP_API}/api/v1/org/stores/${storeId}/offers/${bannerId}/position?move-top=${positionState}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        dispatch({
          type: positionState ? POSITION_MOVE_TOP : POSITION_MOVE_UP,
          payload: { id: bannerId },
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_BANNER_ERROR,
          payload: { error: error.message },
        });
      });
  };

export const setSuccessToast = (data) => (dispatch) => {
  dispatch({
    type: SET_SUCCESS_TOAST,
    payload: data,
  });
};

export const clearBanners = () => (dispatch) => {
  dispatch({
    type: CLEAR_COMPLETE_BANNER,
  });
};

export const clearBannerError = () => (dispatch) => {
  dispatch({
    type: CLEAR_BAANNER_ERROR,
    payload: { error: null },
  });
};

export const startBannerLoader = () => (dispatch) => {
  dispatch({
    type: SET_BANNER_LOADER,
    payload: true,
  });
};

export const stopBannerLoader = () => (dispatch) => {
  dispatch({
    type: SET_BANNER_LOADER,
    payload: false,
  });
};
