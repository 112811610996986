import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const BulbIcon = ({ viewBox = "0 0 20 20" }) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "20px", height: "20px" }}>
      <path d="M9.3335 0V2.66667H10.6668V0H9.3335Z" fill="#1641DB" />
      <path
        d="M4.47183 4.86133L2.47183 2.86133L1.52783 3.80533L3.52783 5.80533L4.47183 4.86133Z"
        fill="#1641DB"
      />
      <path
        d="M16.4718 5.80533L18.4718 3.80533L17.5278 2.86133L15.5278 4.86133L16.4718 5.80533Z"
        fill="#1641DB"
      />
      <path
        d="M9.99978 4C8.72877 3.99979 7.50036 4.45818 6.54019 5.29098C5.58002 6.12378 4.95258 7.27505 4.77312 8.53333L4.75978 8.62667C4.65843 9.32765 4.69947 10.0419 4.88046 10.7266C5.06144 11.4114 5.37865 12.0526 5.81312 12.612C6.32378 13.272 6.66645 13.9027 6.66645 14.5347V16.6667C6.66645 16.8435 6.73669 17.013 6.86171 17.1381C6.98674 17.2631 7.15631 17.3333 7.33312 17.3333H12.6665C12.8433 17.3333 13.0128 17.2631 13.1379 17.1381C13.2629 17.013 13.3331 16.8435 13.3331 16.6667V14.5347C13.3331 13.9027 13.6758 13.272 14.1865 12.612C15.0265 11.5267 15.4505 10.112 15.2398 8.62533L15.2265 8.532C15.0467 7.27396 14.4191 6.123 13.459 5.29048C12.4988 4.45795 11.2706 3.99974 9.99978 4Z"
        fill="#FF6060"
      />
      <path d="M0 10.6668H2.66667V9.3335H0V10.6668Z" fill="#1641DB" />
      <path
        d="M17.3335 10.6663H20.0002V9.33301H17.3335V10.6663Z"
        fill="#1641DB"
      />
      <path d="M8 19.9998H12V18.6665H8V19.9998Z" fill="#1641DB" />
    </SvgIcon>
  );
};

export default BulbIcon;
