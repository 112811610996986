import React from "react";
import styles from "./SubscribtionComparePage.module.css";
const pricingTable = [
  {
    feature: "Domain Mapping",
    Basic: true,
    Professional: true,
    Business: true,
  },
  {
    feature: "Shoopy Branding Removed",
    Basic: true,
    Professional: true,
    Business: true,
  },
  {
    feature: "Bulk Product Import",
    Basic: true,
    Professional: true,
    Business: true,
  },
  {
    feature: "Rating and Review",
    Basic: true,
    Professional: true,
    Business: true,
  },
  {
    feature: "Advanced Shipping",
    Basic: true,
    Professional: true,
    Business: true,
  },
  { feature: "Wishlist", Basic: true, Professional: true, Business: true },
  {
    feature: "Desktop Access",
    Basic: true,
    Professional: true,
    Business: true,
  },
  {
    feature: "Products Limit",
    Basic: "Upto 200 products",
    Professional: "Upto 25,000",
    Business: "Upto 1,00,000",
  },
  {
    feature: "Staff Account",
    Basic: "1 member",
    Professional: "Upto 5 members",
    Business: "Upto 20 members",
  },
  {
    feature: "Official WhatsApp Integration",
    Basic: "Additional Cost",
    Professional: "Additional Cost",
    Business: "No Additional Cost",
  },
  {
    feature: "Store Setup By Professionals",
    Basic: "At Additional Cost",
    Professional: "At Additional Cost",
    Business: "No Additional Cost",
  },
  {
    feature: "Premium Support",
    Basic: "WhatsApp only",
    Professional: "WhatsApp + Call",
    Business: "Dedicated WhatsApp Group + Call",
  },
  {
    feature: "Android App publish",
    Basic: false,
    Professional: "Additional Cost",
    Business: "Additional Cost",
  },
  {
    feature: "Detailed Analytics Screen",
    Basic: false,
    Professional: true,
    Business: true,
  },
  {
    feature: "Abandoned cart Information",
    Basic: false,
    Professional: true,
    Business: true,
  },
  {
    feature: "Bulk Product Edit",
    Basic: false,
    Professional: true,
    Business: true,
  },
  {
    feature: "Business reports",
    Basic: false,
    Professional: true,
    Business: true,
  },
  {
    feature: "Chat Widget Integration",
    Basic: false,
    Professional: true,
    Business: true,
  },
  {
    feature: "Unlimited Push Notifications",
    Basic: false,
    Professional: true,
    Business: true,
  },
];

const PricingTable = () => {
  const renderCellContent = (content) => {
    if (content === true) return "✓";
    if (content === false) return "-";
    return content;
  };

  return (
    <table className={`${styles.pricing_table}`}>
      <thead>
        <tr>
          <th></th>
          <th>Basic</th>
          <th>Professional</th>
          <th>Business</th>
        </tr>
      </thead>
      <tbody>
        {pricingTable.map((row, index) => (
          <tr key={index}>
            <td>{row.feature}</td>
            <td>{renderCellContent(row.Basic)}</td>
            <td>{renderCellContent(row.Professional)}</td>
            <td>{renderCellContent(row.Business)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PricingTable;
