import React from "react";
import { SvgIcon } from "@material-ui/core";

const ReportFail = ({ viewBox = "0 0 16 16" }) => {
  return (
    <SvgIcon style={{ width: "16px", height: "16px" }} viewBox={viewBox}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
          fill="#F44B4B"
        />
        <path
          d="M5 5L11 11"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 5L5 11"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}

export default ReportFail;
