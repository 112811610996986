import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CategoryArrow = ({ viewBox = '0 0 147 95', stroke = '#999999', fill = '#999999' }) => {
    return (
        <SvgIcon viewBox={viewBox} style={{ width: '147px', height: '95px' }}>
            <svg width="147" height="95" viewBox="0 0 147 95" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M140.817 0.422968C140.498 -0.0280946 139.874 -0.135408 139.423 0.183278L132.072 5.37656C131.621 5.69525 131.514 6.31925 131.833 6.77032C132.151 7.22138 132.775 7.32869 133.227 7.01001L139.76 2.39375L144.377 8.92753C144.695 9.37859 145.319 9.4859 145.77 9.16722C146.221 8.84853 146.329 8.22453 146.01 7.77346L140.817 0.422968ZM139.014 0.830514C132.328 39.7137 108.195 64.8471 80.4895 78.731C52.7422 92.6357 21.5044 95.208 0.785254 89.0415L0.214746 90.9585C21.4956 97.292 53.2578 94.6143 81.3855 80.519C109.555 66.4029 134.172 40.7863 140.986 1.16949L139.014 0.830514Z" fill="#999999"/>
            </svg>
        </SvgIcon>
    );
};

export default CategoryArrow;
