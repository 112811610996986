import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CircleOutlinedIcon = ({ viewBox = "0 0 16 16", stroke = "#1641DB" }) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "16px", height: "16px" }}>
      <circle
        cx="8"
        cy="8"
        r="7.25"
        fill={stroke}
        stroke={stroke}
        strokeWidth="1.5"
        fillOpacity="0.1"
      />
    </SvgIcon>
  );
};

export default CircleOutlinedIcon;
