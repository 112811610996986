import React from "react";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import { Button, Typography } from "@material-ui/core";

const OutlinedLocationButton = ({
  onClick = () => {},
  style = {},
  textStyle = {},
}) => {
  return (
    <Button onClick={onClick} style={{ background: "#FFFFFF", ...style }}>
      <LocationOnOutlinedIcon color="secondary" />
      <Typography
        color="secondary"
        style={{ fontSize: 12, fontWeight: 600, marginLeft: 4, ...textStyle }}
      >
        Pickup Location
      </Typography>
    </Button>
  );
};

export default OutlinedLocationButton;
