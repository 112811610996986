import { ORDERS_PER_PAGE } from "../../utils/utils";
import {
  CREATE_ORDER,
  FETCH_ORDERS,
  ORDER_PROGRESS,
  UPDATE_ORDER,
  SET_ORDER_ERROR,
  SET_ORDER_LOADER,
  CLEAR_ORDER,
  CLEAR_COMPLETE_ORDERS,
  ORDER_DATE_SELECTOR,
  ORDER_STATE_SELECTOR,
  ORDER_DATE_RANGE,
  SET_ORDERS_PER_PAGE,
  SET_ORDERS_CURRENT_PAGE,
  SET_ORDERS_MAX_PAGE_SO_FAR,
  SET_ORDERS_INPUT_KEYWORD,
  SET_ORDERS_SEARCH_KEYWORD,
  SET_ORDERS_CUSTOMER_ID,
  UPDATE_ORDERS,
} from "./orderTypes";
import axios from "axios";
import { getDefaultStore } from "../../utils/storeUtil";

export const saveOrder = (orderData) => async (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  let data = orderData;
  let newOrd = orderData.id ? false : true;
  let storeId = store.store_id;
  data.org_id = store.org_id;
  data.store_id = storeId;
  return await axios
    .post(
      `${process.env.REACT_APP_API}/api/v1/org/store/${storeId}/order/from-store-order?src=web`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      dispatch({
        type: newOrd ? CREATE_ORDER : UPDATE_ORDER,
        payload: response.data.payload,
      });
      return response.data.payload;
    })
    .catch((error) => {
      dispatch({
        type: SET_ORDER_ERROR,
        payload: { error: error.message },
      });
    });
};

export const fetchOrders =
  (
    page = 0,
    state = "ALL",
    aoe = null,
    boe = null,
    keyword = null,
    ordersPerPage = ORDERS_PER_PAGE,
    customerId = null
  ) =>
  async (dispatch) => {
    dispatch({
      type: ORDER_PROGRESS,
      payload: true,
    });
    let accessToken = localStorage.getItem("access_token");
    let store = getDefaultStore();
    return await axios
      .get(
        `${process.env.REACT_APP_API}/api/v1/org/store/${
          store.store_id
        }/order/find?type=ONLINE&page=${page}&size=${ordersPerPage}&sort=createdAt,desc${
          state === "ALL" ? "" : `&state=${state}`
        }${aoe && boe ? `&created-aoe=${aoe}&created-boe=${boe}` : ""}${
          keyword ? `&q=${keyword}` : ""
        }${customerId ? `&org-store-customer-id=${customerId}` : ""}`,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: FETCH_ORDERS,
          payload: response.data.payload,
          ordersPerPage: ordersPerPage,
          storeId: store.store_id,
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_ORDER_ERROR,
          payload: { error: error.message },
        });
      });
  };

export const updateOrders = (data, ordersId) => async (dispatch) => {
  startOrderLoader();
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  let ordersStr = "";
  ordersId.map((val, idx) => {
    ordersStr =
      ordersStr +
      `order-id=${val}` +
      `${ordersId.length === idx + 1 ? "" : "&"}`;
  });

  return await axios
    .patch(
      `${process.env.REACT_APP_API}/api/v1/org/store/${store.store_id}/order/state-status?${ordersStr}`,
      data,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      dispatch({
        type: UPDATE_ORDERS,
        payload: response.data.payload,
      });
      stopOrderLoader();
      return response.data.payload;
    })
    .catch((error) => {
      stopOrderLoader();
    });
};

export const updateOrderFields = (data, orderId) => async (dispatch) => {
  startOrderLoader();
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  return await axios
    .patch(
      `${process.env.REACT_APP_API}/api/v1/org/store/${store.store_id}/order/${orderId}`,
      data,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      dispatch({
        type: UPDATE_ORDER,
        payload: response.data.payload,
      });
      stopOrderLoader();
      return response.data.payload;
    })
    .catch((error) => {
      stopOrderLoader();
    });
};

export const recordOrderPayment = (data, orderId) => async (dispatch) => {
  startOrderLoader();
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  return await axios
    .post(
      `${process.env.REACT_APP_API}/api/v1/org/store/${store.store_id}/order/${orderId}/payment`,
      data,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then(() => {
      axios
        .get(
          `${process.env.REACT_APP_API}/api/v1/org/store/${store.store_id}/order/${orderId}`,
          {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          dispatch({
            type: UPDATE_ORDER,
            payload: response.data.payload,
          });
        })
        .catch((error) => {
          stopOrderLoader();
        });
      stopOrderLoader();
    })
    .catch((error) => {
      stopOrderLoader();
    });
};

export const cancelOrder = (data, orderId) => (dispatch) => {
  startOrderLoader();
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  axios
    .post(
      `${process.env.REACT_APP_API}/api/v1/org/store/${store.store_id}/order/cancel/${orderId}`,
      data,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      dispatch({
        type: UPDATE_ORDER,
        payload: response.data.payload,
      });
      stopOrderLoader();
    })
    .catch((error) => {
      stopOrderLoader();
    });
};

export const revertOrder = (orderId) => async (dispatch) => {
  startOrderLoader();
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  let data = {};
  return await axios
    .patch(
      `${process.env.REACT_APP_API}/api/v1/org/store/${store.store_id}/order/${orderId}/revert-to-confirmed`,
      data,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      dispatch({
        type: UPDATE_ORDER,
        payload: response.data.payload,
      });
      stopOrderLoader();
    })
    .catch((error) => {
      stopOrderLoader();
    });
};

export const setOrderDateSelector = (data) => (dispatch) => {
  dispatch({
    type: ORDER_DATE_SELECTOR,
    payload: data,
  });
};

export const setOrderStateSelector = (data) => (dispatch) => {
  dispatch({
    type: ORDER_STATE_SELECTOR,
    payload: data,
  });
};

export const setOrderDateRange = (data) => (dispatch) => {
  dispatch({
    type: ORDER_DATE_RANGE,
    payload: data,
  });
};

export const setOrdersPerPage = (data) => (dispatch) => {
  dispatch({
    type: SET_ORDERS_PER_PAGE,
    payload: data,
  });
};

export const setOrdersCurrentPage = (data) => (dispatch) => {
  dispatch({
    type: SET_ORDERS_CURRENT_PAGE,
    payload: data,
  });
};

export const setOrdersMaxPageSoFar = (data) => (dispatch) => {
  dispatch({
    type: SET_ORDERS_MAX_PAGE_SO_FAR,
    payload: data,
  });
};

export const setOrdersCustomerId = (data) => (dispatch) => {
  dispatch({
    type: SET_ORDERS_CUSTOMER_ID,
    payload: data,
  });
};

export const clearOrders = () => (dispatch) => {
  dispatch({
    type: CLEAR_COMPLETE_ORDERS,
  });
};

export const clearOrder = () => (dispatch) => {
  dispatch({
    type: CLEAR_ORDER,
    payload: null,
  });
};

export const startOrderLoader = () => (dispatch) => {
  dispatch({
    type: SET_ORDER_LOADER,
    payload: true,
  });
};

export const stopOrderLoader = () => (dispatch) => {
  dispatch({
    type: SET_ORDER_LOADER,
    payload: false,
  });
};

export const setOrdersInputKeyword = (data) => (dispatch) => {
  dispatch({
    type: SET_ORDERS_INPUT_KEYWORD,
    payload: data,
  });
};

export const setOrdersSearchKeyword = (data) => (dispatch) => {
  dispatch({
    type: SET_ORDERS_SEARCH_KEYWORD,
    payload: data,
  });
};
