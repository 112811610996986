import axios from "axios";
import { getDefaultStore } from "./storeUtil";
import { getDateRange } from "./utils";

export const FILTER_KEY = "insightsFilter";
export const INSIGHTS_FILTER = localStorage.getItem(FILTER_KEY) || "Today";

export const formateTime = (seconds) => {
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return {
    minutes,
    remainingSeconds,
  };
};

export const getSales = async (filterBy) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  let res = getDateRange(filterBy);
  let aoe = res.aoe;
  let boe = res.boe;
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API}/api/v1/org/store/${store.store_id}/dashboard/sales?created-aoe=${aoe}&created-boe=${boe}`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data.payload;
  } catch (err) {
    console.log("Failure while fetching sales", err);
  }
};

export const getOrders = async (filterBy) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  let res = getDateRange(filterBy);
  let aoe = res.aoe;
  let boe = res.boe;
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API}/api/v1/org/store/${store.store_id}/dashboard/orders?created-aoe=${aoe}&created-boe=${boe}`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data.payload;
  } catch (err) {
    console.log("Failure while fetching orders", err);
  }
};

export const getAllSummary = async () => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API}/api/v1/org/customer/transaction/all-summary-v2?store-id=${store.store_id}`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data.payload;
  } catch (err) {
    console.log("Failure while fetching summary", err);
  }
};

export const getLowStockCount = async () => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API}/api/v1/store/${store.store_id}/product-inventory/low-stock-count`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data.payload;
  } catch (err) {
    console.log("Failure while fetching stock", err);
  }
};

export const getAbandonedCarts = async (filterBy) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  let res = getDateRange(filterBy);
  let aoe = res.aoe;
  let boe = res.boe;
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API}/api/v1/business/stores/${store.store_id}/carts/abandoned?created-aoe=${aoe}&created-boe=${boe}`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data.payload;
  } catch (err) {
    console.log("Failure while fetching carts", err);
  }
};

export const getAnalyticsData = async (filterBy) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  let { aoe, boe } = getDateRange(filterBy, "timestamp");

  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API}/api/v1/store/${store.store_id}/site/stats?startAt=${aoe}&endAt=${boe}`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return response.data.payload;
  } catch (err) {
    console.log("Failure while fetching analytics data", err);
  }
};
