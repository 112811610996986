import { Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useStyles } from "../../formStyles";
import CategoryCard from "./CategoryCard";
import FormModal from "../../../../../../components/common/FormModal";
export default function CategorySelect({
  isOpen,
  close,
  categories,
  cat_slugs,
}) {
  const classes = useStyles();
  const [selectedCats, setSelectedCats] = useState([]);
  useEffect(() => {
    let temp = [];
    cat_slugs.forEach((cat) => {
      temp.push(cat);
    });
    setSelectedCats(temp);
    // eslint-disable-next-line
  }, []);
  return (
    <FormModal
      open={isOpen}
      onClose={() => close(cat_slugs.length > 0 ? cat_slugs : [])}
      heading="Select Categories"
      width={600}
      maxHeight={60}
      onSave={() => close(selectedCats)}
    >
      <Typography
        className={classes.linkText}
        style={{ fontWeight: 400, marginBottom: "16px" }}
      >
        Select categories to apply on the coupon
      </Typography>
      {Object.keys(categories).map((cat) => {
        return (
          <React.Fragment key={cat}>
            <CategoryCard
              category={categories[cat].data}
              onChange={(checked, updatedCat) => {
                let clonedState = [...selectedCats];
                let allCatsRelated = [
                  ...categories[updatedCat.slug].children.map((_) => _.slug),
                  updatedCat.slug,
                ];
                if (checked) {
                  clonedState.push(...allCatsRelated);
                } else {
                  clonedState = clonedState.filter(
                    (_) => allCatsRelated.indexOf(_) === -1
                  );
                }
                setSelectedCats(clonedState);
              }}
              selected={selectedCats.indexOf(cat) !== -1}
            />
            {categories[cat].children.map((child_cat) => {
              return (
                <CategoryCard
                  key={child_cat.slug}
                  category={child_cat}
                  leftPadded
                  onChange={(checked, updatedCat) => {
                    let clonedState = [...selectedCats];
                    if (checked) {
                      clonedState.push(updatedCat.slug);
                    } else {
                      clonedState = clonedState.filter(
                        (_) =>
                          _ !== updatedCat.slug &&
                          _ !== updatedCat.parent_cat_slug
                      );
                    }
                    setSelectedCats(clonedState);
                  }}
                  selected={selectedCats.indexOf(child_cat.slug) !== -1}
                />
              );
            })}
          </React.Fragment>
        );
      })}
    </FormModal>
  );
}
