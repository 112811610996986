import { Typography, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ImageHandler from "../../../components/ImageHandler";
import RemoveCircleIcon from "../../../icons/RemoveCircleIcon";
import Editor from "../../products/components/Editor";
import useStyles from "../styles";
import { uploadImage } from "../../../utils/imageUploader";
import axios from "axios";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import SolidButton from "../../../components/buttons/SolidButton";

const AboutSetting = ({ onClose, about, store, setAbout, setToast }) => {
  const defaultCrop = { x: 0, y: 0, width: 250, height: 250 };
  const classes = useStyles();
  const [cropImg, setCropImg] = useState(null);
  const [filename, setFilename] = useState("");
  const [description, setDescription] = useState(about?.description ?? "");
  const [ytLink, setYtLink] = useState("");
  const [expand, setExpand] = useState({
    about: false,
  });

  const [loading, setLoading] = useState(false);
  const [openImageHandler, setOpenImageHandler] = useState(false);
  const [openImageHandlerIndex, setOpenImageHandlerIndex] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, [about]);

  const onSave = async () => {
    setLoading(true);
    let imageUrl = null;
    if (cropImg) {
      if (cropImg.startsWith("https:")) {
        imageUrl = cropImg;
      } else {
        let cloudFile = await uploadImage(filename, cropImg);
        imageUrl = cloudFile.payload.location;
      }
    }

    let obj = {
      description: description ? description : null,
      images: imageUrl ? [imageUrl] : [],
      slug: "about-us",
      title: "About Us",
      video: ytLink ? ytLink : null,
      store: null,
      store_id: store.id,
    };

    let accessToken = localStorage.getItem("access_token");
    if (Object.entries(about).length > 0) {
      axios
        .patch(
          `${process.env.REACT_APP_API}/api/v1/org/store/${store.slug}/pages/about-us`,
          obj,
          {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          let temp = res.data.payload;
          temp.images = imageUrl ? [imageUrl] : [];
          setAbout(temp);
          setToast(true);
          setLoading(false);
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API}/api/v1/org/store/${store.slug}/pages`,
          obj,
          {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          let temp = res.data.payload;
          temp.images = imageUrl ? [imageUrl] : [];
          setAbout(temp);
          setToast(true);
          setLoading(false);
        });
    }
  };

  const init = () => {
    if (about && about.slug) {
      if (about.video) setYtLink(about.video);
      if (about.description) setDescription(about.description);
      if (about.images && about.images.length > 0) {
        setCropImg(about.images[0]);
      }
    }
  };

  if (expand.about) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          minHeight: "100%",
        }}
      >
        <div>
          <Typography
            className={classes.subHeading}
            style={{ marginBottom: 32 }}
          >
            About Us
          </Typography>
          <Editor
            editorText={description}
            setEditorText={setDescription}
            height="650px"
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingTop: 32,
          }}
        >
          <SolidButton
            onClick={() => {
              setExpand({ ...expand, about: false });
            }}
          >
            Continue
          </SolidButton>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "100%",
      }}
    >
      <div>
        <div className={classes.dividerGrid} style={{ display: "block" }}>
          <Typography
            className={classes.subHeading}
            style={{ marginBottom: 12 }}
          >
            About Us
          </Typography>
          <Editor
            editorText={description}
            setEditorText={setDescription}
            height="200px"
          />
          <p
            style={{
              margin: "0px",
              color: "#1641DB",
              cursor: "pointer",
              fontSize: 12,
              marginTop: 6,
              marginBottom: 20,
            }}
            onClick={() => {
              setExpand({ ...expand, about: true });
            }}
          >
            Expand and Edit Field
          </p>
          <Typography
            className={classes.subHeading}
            style={{ marginBottom: 12, fontSize: 14 }}
          >
            Add Youtube Video Link
          </Typography>
          <TextField
            color="secondary"
            variant="outlined"
            value={ytLink}
            onChange={(e) => setYtLink(e.target.value)}
            style={{ marginBottom: 20, width: "100%" }}
            inputProps={{ style: { fontSize: 14 } }}
          />
          <Typography className={classes.subHeading}>Add Image</Typography>
          <div
            style={{
              paddingBottom: 6,
              marginTop: 16,
            }}
          >
            <ImageHandler
              cropImg={cropImg}
              setCropImg={setCropImg}
              defaultCrop={defaultCrop}
              setFilename={setFilename}
              maxSize={1400}
              openImageHandler={openImageHandler}
              setOpenImageHandler={setOpenImageHandler}
              openImageHandlerIndex={openImageHandlerIndex}
              openModal={openModal}
              setOpenModal={setOpenModal}
            />
            {cropImg && (
              <div style={{ width: 60, position: "relative" }}>
                <div
                  style={{ position: "absolute", top: -11, right: -11 }}
                  onClick={() => {
                    setCropImg(null);
                  }}
                >
                  <RemoveCircleIcon />
                </div>
                <img
                  src={cropImg}
                  alt=""
                  style={{
                    width: 60,
                    height: 60,
                    borderRadius: 6,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setOpenImageHandler(true);
                    setOpenImageHandlerIndex(0);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "flex-end", paddingTop: 32 }}
      >
        <CombinedButtons
          outlinedBtnAction={() => {
            onClose();
            init();
          }}
          solidBtnAction={onSave}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default AboutSetting;
