import React, { useState } from "react";
import { Tooltip, makeStyles } from "@material-ui/core";
import { cdnUrl } from "../../../utils/cdnUtils";
import { DeleteIcon, EditIcon, NoPicIcon } from "../../../icons";
import ReOrderSharpIcon from "../../../icons/ReOrderSharpIcon";
import ContentCopyIcon from "../../../icons/ContentCopyIcon";
import { copyToClipboard } from "../../../utils/utils";
import Controls from "../../../components/controls/Controls";

const useStyles = makeStyles({
  categoryContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderTop: "1px #E1E1E1 solid",
    padding: 10,
    marginLeft: 20,
  },
  info: {
    display: "flex",
    alignItems: "center",
    gap: 15,
  },
  icon: {
    display: "flex",
    alignItems: "center",
    gap: 20,
    marginRight: 10,
    height: 25,
  },
  productImage: {
    height: 40,
    width: 40,
    borderRadius: 4,
  },
  noProductImage: {
    border: "1px solid #E5E5E5",
    boxSizing: "border-box",
    borderRadius: "4px",
    width: 40,
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  customTooltip: {
    fontSize: 12,
    borderRadius: 5,
    padding: "8px 12px",
  },
  subCategoryName: {
    color: "#1A1A1A",
    fontSize: 14,
    fontWeight: "400",
  },
  subcategoriesAndProduct: {
    color: "#1641DB",
    fontSize: 12,
    fontWeight: "400",
    cursor: "pointer",
  },
  pointer: {
    cursor: "pointer",
  },
});

const SubCategory = ({
  parentCategory,
  subCategory,
  categoryMap,
  handleProps,
  handleViewProducts,
  onSwitchChange,
  handleOnEdit,
  handleOnDelete,
}) => {
  const classes = useStyles();

  const [isCopySubCat, setIsCopySubCat] = useState(false);
  const [openSubCatCopyIcon, setOpenSubCatCopyIcon] = useState({
    val: false,
    id: "",
  });

  return (
    <div
      className={classes.categoryContainer}
      onMouseEnter={() => {
        setOpenSubCatCopyIcon({
          val: true,
          id: subCategory.id,
        });
      }}
      onMouseLeave={() => {
        setOpenSubCatCopyIcon({
          val: false,
          id: subCategory.id,
        });
      }}
    >
      <div className={classes.info}>
        <span {...handleProps}>
          <ReOrderSharpIcon />
        </span>
        {subCategory.thumbnail ? (
          <img
            className={classes.productImage}
            alt=""
            src={cdnUrl(subCategory.thumbnail, 200, 200, 1)}
          />
        ) : (
          <div className={classes.noProductImage}>
            <NoPicIcon />
          </div>
        )}
        <span>
          <div style={{ height: "20px" }}>
            <span className={classes.subCategoryName}>{subCategory.name}</span>
            <span>
              {openSubCatCopyIcon.val &&
                openSubCatCopyIcon.id === subCategory.id && (
                  <Tooltip
                    className={classes.customTooltip}
                    leaveDelay={0}
                    title={"Sub category name copied"}
                    open={isCopySubCat}
                    onOpen={() => {
                      setIsCopySubCat(false);
                    }}
                    onClose={() => {
                      setIsCopySubCat(false);
                    }}
                    placement="top-start"
                    PopperProps={{
                      disablePortal: true,
                    }}
                  >
                    <span
                      style={{
                        padding: "0px 12px",
                        cursor: "pointer",
                        zIndex: 9,
                      }}
                      onClick={() => {
                        let result = copyToClipboard(subCategory.name);
                        if (result) {
                          setIsCopySubCat(true);
                        }
                      }}
                    >
                      <ContentCopyIcon />
                    </span>
                  </Tooltip>
                )}
            </span>
          </div>
          <div>
            <span
              className={classes.subcategoriesAndProduct}
              id={subCategory.id}
              onClick={handleViewProducts}
            >
              View Products
            </span>
          </div>
        </span>
      </div>
      <div className={classes.icon}>
        <Tooltip
          title={
            categoryMap.get(parentCategory.id).hidden
              ? `Enable ${parentCategory.name} to activate subcategory.`
              : ""
          }
        >
          <span
            style={{ marginTop: 6 }}
            onClick={(event) => event.stopPropagation()}
          >
            <Controls.Switch
              name={subCategory.id}
              value={!categoryMap.get(subCategory.id).hidden}
              onChange={onSwitchChange}
              disabled={categoryMap.get(parentCategory.id).hidden}
            />
          </span>
        </Tooltip>

        <Tooltip title="Edit">
          <span
            className={classes.pointer}
            id={subCategory.id}
            onClick={handleOnEdit}
          >
            <EditIcon />
          </span>
        </Tooltip>
        <Tooltip title="Delete">
          <span
            className={classes.pointer}
            id={subCategory.id}
            onClick={handleOnDelete}
          >
            <DeleteIcon />
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

export default SubCategory;
