import { useState } from "react";

const useValidator = (formData) => {
  const [errors, setErrors] = useState({});
  const validateFields = (formData) => {
    const newErrors = {};
    if (!formData.display_title) {
      newErrors.display_title = "This field is required.";
    }

    if (!formData.display_name) {
      newErrors.display_name = "This field is required.";
    }

    if (formData.frequencies) {
      formData.frequencies.forEach((frequency, index) => {
        if (!frequency.interval) {
          newErrors[`frequency_${index}_interval`] = "Required field.";
        }
        if (!frequency.repeat_every) {
          newErrors[`frequency_${index}_frequency`] = "Required field.";
        }
        if (!frequency.discount_type) {
          newErrors[`frequency_${index}_discount_type`] = "Required field.";
        }
        if (!frequency.discount_value) {
          newErrors[`frequency_${index}_discount_value`] = "Required field.";
        }
      });
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const onBlur = (fieldName, value, index) => {
    const newErrors = { ...errors };

    switch (fieldName) {
      case "display_title":
        if (!value) {
          newErrors.display_title = "This field is required.";
        } else {
          delete newErrors.display_title;
        }
        break;
      case "display_name":
        if (!value) {
          newErrors.display_name = "This field is required.";
        } else {
          delete newErrors.display_name;
        }
        break;
      case `frequency_${index}_interval`:
        if (!value) {
          newErrors[`frequency_${index}_interval`] = "Required field.";
        } else {
          delete newErrors[`frequency_${index}_interval`];
        }
        break;
      case `frequency_${index}_frequency`:
        if (!value) {
          newErrors[`frequency_${index}_frequency`] = "Required field.";
        } else {
          delete newErrors[`frequency_${index}_frequency`];
        }
        break;
      case `frequency_${index}_discount_type`:
        if (!value) {
          newErrors[`frequency_${index}_discount_type`] = "Required field.";
        } else {
          delete newErrors[`frequency_${index}_discount_type`];
        }
        break;
      case `frequency_${index}_discount_value`:
        if (!value) {
          newErrors[`frequency_${index}_discount_value`] = "Required field.";
        } else {
          delete newErrors[`frequency_${index}_discount_value`];
        }
        break;
      default:
        break;
    }

    setErrors(newErrors);
  };

  const clearErrors = () => {
    setErrors({});
  };

  return {
    errors,
    validateFields,
    onBlur,
    clearErrors,
  };
};

export default useValidator;
