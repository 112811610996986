import React from "react";

export default function LabelIcon({ fill = "#666666", strokeWidth = 0 }) {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.666748 14L5.15404 7L0.666748 0H13.0515C13.4701 0 13.8646 0.0949867 14.2348 0.284959C14.605 0.474931 14.912 0.740445 15.1559 1.0815L19.3334 7L15.066 12.9634C14.8372 13.2895 14.5451 13.5438 14.1899 13.7264C13.8346 13.9088 13.4552 14 13.0515 14H0.666748ZM2.77112 12.8333H13.0515C13.2757 12.8333 13.485 12.781 13.6795 12.6764C13.8739 12.5716 14.0385 12.4295 14.1732 12.25L17.911 7L14.1732 1.75C14.0385 1.57053 13.8814 1.42839 13.7019 1.32358C13.5224 1.21897 13.3056 1.16667 13.0515 1.16667H2.77112L6.56279 7L2.77112 12.8333Z"
        fill={fill}
        stroke={fill}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
}
