import React from "react";
import { Box } from "@material-ui/core";
import AddonCard from "../AddonCard";
export default function ListAddons({
  currentSelectedPlan,
  addonList,
  handleAddonSelection,
}) {
  return (
    <Box overflow="auto" maxHeight="440px">
      {currentSelectedPlan.plan_add_ons.map((addon) => (
        <AddonCard
          addon={addon}
          key={addon.id}
          handleAddonSelection={() => handleAddonSelection(addon)}
          selectedAddons={addonList}
        />
      ))}
    </Box>
  );
}
