export const toLocaleISOString = (inputDt) => {
  const zOffsetMs = inputDt.getTimezoneOffset() * 60 * 1000;
  const localTimeMs = inputDt - zOffsetMs;
  const date = new Date(localTimeMs);
  const utcOffsetHr = inputDt.getTimezoneOffset() / 60;
  const utcOffsetSign = utcOffsetHr <= 0 ? "+" : "-";
  const utcOffsetString =
    utcOffsetSign +
    (utcOffsetHr.toString.length === 1 ? `0${utcOffsetHr}` : `${utcOffsetHr}`) +
    ":00";
  return date.toISOString().replace("Z", utcOffsetString).substr(0, 10);
};

export const getDay = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
