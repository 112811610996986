import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const FileRestore = () => {
  return (
    <SvgIcon viewBox="0 0 24 24" style={{ width: "24px", height: "24px" }}>
      <mask
        id="mask0_12694_7946"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_12694_7946)">
        <path
          d="M12 17.75C13.3 17.75 14.4167 17.2833 15.35 16.35C16.2833 15.4167 16.75 14.3 16.75 13C16.75 11.7 16.2833 10.5833 15.35 9.65C14.4167 8.71667 13.3 8.25 12 8.25C11.3667 8.25 10.775 8.36667 10.225 8.6C9.675 8.83333 9.18333 9.15 8.75 9.55V8H7.25V12.25H11.5V10.75H9.7C9.98333 10.45 10.325 10.2083 10.725 10.025C11.125 9.84167 11.55 9.75 12 9.75C12.9 9.75 13.6667 10.0667 14.3 10.7C14.9333 11.3333 15.25 12.1 15.25 13C15.25 13.9 14.9333 14.6667 14.3 15.3C13.6667 15.9333 12.9 16.25 12 16.25C11.2667 16.25 10.625 16.0377 10.075 15.613C9.525 15.1877 9.13333 14.65 8.9 14H7.35C7.58333 15.0833 8.12933 15.9793 8.988 16.688C9.846 17.396 10.85 17.75 12 17.75ZM6 22C5.45 22 4.97933 21.8043 4.588 21.413C4.196 21.021 4 20.55 4 20V4C4 3.45 4.196 2.979 4.588 2.587C4.97933 2.19567 5.45 2 6 2H14L20 8V20C20 20.55 19.8043 21.021 19.413 21.413C19.021 21.8043 18.55 22 18 22H6ZM6 20H18V8.85L13.15 4H6V20Z"
          fill="#1641DB"
        />
      </g>
    </SvgIcon>
  );
};

export default FileRestore;
