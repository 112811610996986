import axios from "axios";
import { getDefaultStore } from "../../../utils/storeUtil";
import { getDateRange } from "../../../utils/utils";

export const CouponTriggeredPoint = [
  { id: "CUSTOMER_REGISTERED", title: "After referred user registration" },
  { id: "ORDER_PLACED", title: "After order gets confirmed" },
  { id: "ORDER_DELIVERED", title: "After order gets delivered" },
];
export const getButtonColor = (color) => {
  switch (color?.toLowerCase()) {
    case "#29a56c":
      return {
        bgColor: color,
        txtColor: "#e8fff1",
      };
    default:
      return {
        bgColor: color,
        txtColor: "#FFFFFF",
      };
  }
};

export const getReferralConfig = async () => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API}/api/v1/business/store/${store.store_id}/referral-config`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.payload;
  } catch (err) {
    console.log("Failure while fetching config", err);
  }
};

export const setReferralConfig = async (payload) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_API}/api/v1/business/store/${store.store_id}/referral-config`,
      payload,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.payload;
  } catch (err) {
    console.log("Failure while fetching config", err);
  }
};

export const REFER_EARN_SUMMARY_FILTER_KEY = "referEarnSummaryFilter";
export const REFER_EARN_SUMMARY_FILTER =
  localStorage.getItem(REFER_EARN_SUMMARY_FILTER_KEY) || "Today";

export const getRefereeOrder = async (filterBy) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  let res = getDateRange(filterBy);
  let aoe = res.aoe;
  let boe = res.boe;
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API}/api/v1/business/store/${store.store_id}/referral-transactions/referee-orders?
created-aoe=${aoe}&created-boe=${boe}`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data.payload;
  } catch (err) {
    console.log("Failure while fetching sales", err);
  }
};

export const getReferredOrder = async (filterBy) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  let res = getDateRange(filterBy);
  let aoe = res.aoe;
  let boe = res.boe;
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API}/api/v1/business/store/${store.store_id}/referral-transactions/referred-orders?
created-aoe=${aoe}&created-boe=${boe}`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data.payload;
  } catch (err) {
    console.log("Failure while fetching sales", err);
  }
};

export const getRefereeDiscount = async (filterBy) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  let res = getDateRange(filterBy);
  let aoe = res.aoe;
  let boe = res.boe;
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API}/api/v1/business/store/${store.store_id}/referral-transactions/referee-discount?
created-aoe=${aoe}&created-boe=${boe}`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data.payload;
  } catch (err) {
    console.log("Failure while fetching sales", err);
  }
};

export const getReferredDiscount = async (filterBy) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  let res = getDateRange(filterBy);
  let aoe = res.aoe;
  let boe = res.boe;
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API}/api/v1/business/store/${store.store_id}/referral-transactions/referred-discount?
created-aoe=${aoe}&created-boe=${boe}`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data.payload;
  } catch (err) {
    console.log("Failure while fetching sales", err);
  }
};
