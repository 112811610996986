import React from 'react'

const ItalicsIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
        <path d="M9 1.5V0H4.5V1.5H5.45299C5.6812 1.5 5.8565 1.70212 5.82422 1.92803L4.69165 9.85607C4.63886 10.2256 4.32242 10.5 3.94919 10.5H3V12H7.5V10.5H6.547C6.31879 10.5 6.1435 10.2979 6.17577 10.072L7.30835 2.14394C7.36114 1.77445 7.67758 1.5 8.05081 1.5H9Z" fill="#999999"/>
        </svg>
    )
}

export default ItalicsIcon
