import React from "react";
import { Typography } from "@material-ui/core";

const BaseFormFooter = ({ record }) => {
  const getDisplayValue = (name, value) => {
    return (
      <div>
        <Typography
          style={{
            textAlign: "right",
            color: "#1A1A1A",
            fontWeight: 400,
            fontSize: 14,
          }}
        >
          {name}
        </Typography>
        <Typography
          style={{ marginLeft: 8, fontWeight: 700, textAlign: "right" }}
        >
          ₹{value}
        </Typography>
      </div>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "55%",
      }}
    >
      {getDisplayValue(
        "Total Savings",
        record.total_discount ? record.total_discount.toFixed(2) : 0.0
      )}
      {getDisplayValue(
        "Tax",
        record.tax_amount ? record.tax_amount.toFixed(2) : 0.0
      )}
      {getDisplayValue(
        "Total Amount",
        record.final_sale_price ? record.final_sale_price.toFixed(2) : 0.0
      )}
    </div>
  );
};
export default BaseFormFooter;
