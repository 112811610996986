import { Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import {
  rowFlexAlignCenter,
  rowFlexJustifyBetween,
} from "../../../styles/common";
import { timeSince } from "../../../utils/utils";

const useStyles = makeStyles((theme) => ({
  subHeading: {
    color: theme.palette.text.secondary,
    fontWeight: 400,
    fontSize: 14,
  },
  subTxt: {
    fontWeight: 400,
    fontSize: 14,
  },
  reason: {
    fontWeight: 600,
    fontSize: 14,
    marginTop: 20,
    marginBottom: 8,
  },
  image: {
    width: 64,
    height: 64,
    objectFit: "contain",
    borderRadius: 8,
    border: "1px solid #c4c4c4",
  },
}));

const ReturnSummary = ({ customerReturnData }) => {
  const classes = useStyles();
  return (
    <div>
      <div className={rowFlexJustifyBetween}>
        <Typography className={classes.subHeading}>Return Request</Typography>
        <Typography className={classes.subTxt}>
          {timeSince(new Date(customerReturnData.created_at))}
        </Typography>
      </div>
      <Typography className={classes.reason}>{customerReturnData.reason}</Typography>
      <Typography className={classes.subTxt}>{customerReturnData.message}</Typography>
      <div style={{ marginTop: 16 }} className={rowFlexAlignCenter}>
        {customerReturnData.image_location1 && (
          <Link target="_blank" href={customerReturnData.image_location1}>
            <img
              alt="Return 1"
              className={classes.image}
              src={customerReturnData.image_location1}
            ></img>
          </Link>
        )}
        {customerReturnData.image_location2 && (
          <Link
            target="_blank"
            style={{ marginLeft: 12 }}
            href={customerReturnData.image_location2}
          >
            <img
              className={classes.image}
              alt="Return 2"
              src={customerReturnData.image_location2}
            ></img>
          </Link>
        )}
      </div>
    </div>
  );
};

export default ReturnSummary;
