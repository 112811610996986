import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ExportProdIcon = ({
  viewBox = "0 0 12 12",
  stroke = "#1641DB",
  fill = "#999999",
}) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "12px", height: "12px" }}>
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10 5.5L6 9.5L2 5.5"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 9.5V0.5"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.5 11.5H0.5"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default ExportProdIcon;
