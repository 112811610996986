import {
  FETCH_PROMOTION_BANNER,
  FETCH_ACTIVITIES,
  SET_DASHBOARD_LOADER,
  FETCH_ONLINE_STORES,
  SET_INSIGHTS_FILTER,
} from "./dashboardTypes";
import axios from "axios";
import { getDefaultStore } from "../../utils/storeUtil";

export const fetchPromotionBanner = () => (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  axios
    .get(
      `${process.env.REACT_APP_API}/api/v1/wallposts?group=SHOOPY_PROMOTION`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .then((response) => {
      dispatch({
        type: FETCH_PROMOTION_BANNER,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const fetchActivities = () => (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  const store = getDefaultStore();
  axios
    .get(`${process.env.REACT_APP_API}/api/v1/store/${store.store_id}/tasks`, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then((response) => {
      dispatch({
        type: FETCH_ACTIVITIES,
        payload: response.data.payload,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const fetchOnlineStores = () => async (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  return await axios
    .get(`${process.env.REACT_APP_API}/api/v1/wallposts?group=CAT_OTHER`, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then((response) => {
      dispatch({
        type: FETCH_ONLINE_STORES,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const setInsightsFilter = (data) => (dispatch) => {
  dispatch({
    type: SET_INSIGHTS_FILTER,
    payload: data,
  });
};

export const startDashboardLoader = () => (dispatch) => {
  dispatch({
    type: SET_DASHBOARD_LOADER,
    payload: true,
  });
};

export const stopDashboardLoader = () => (dispatch) => {
  dispatch({
    type: SET_DASHBOARD_LOADER,
    payload: false,
  });
};
