import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import Controls from "../../../components/controls/Controls";
import Toast from "../../../components/Layout/Toast";
import { useDispatch, useSelector } from "react-redux";
import { postPixelValue } from "../../../redux/apps/appActions";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import useStyles from "./ConfigStyles";
import { slugPixelMap } from "../constants";

export default function Config({ app }) {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const appState = useSelector((state) => state.apps);

  const [pixel, setPixel] = useState("");
  const [pixelError, setPixelError] = useState("");
  const [pixelTouched, setPixelTouched] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (slugPixelMap[app.slug]) {
      setPixel(appState.pixels[slugPixelMap[app.slug]]);
    }
    // eslint-disable-next-line
  }, [appState.pixels, app.slug]);

  useEffect(() => {
    if (appState.configActionStatus !== "initial") {
      history.goBack();
    }
    // eslint-disable-next-line
  }, [appState.configActionStatus, params.slug, params.storeId]);

  const handleSave = () => {
    if (slugPixelMap[app.slug]) {
      dispatch(
        postPixelValue([{ name: slugPixelMap[app.slug], value: pixel }])
      ).then((cobj) => {
        if (cobj.error) {
          setErrorMessage(cobj.message);
          setShowError(true);
        }
      });
    }
  };

  let formBody = null;
  if (slugPixelMap[app.slug]) {
    let name, helperText;
    if (app.slug === "facebook-pixel") {
      helperText = "Enter the pixel value by visting facebook";
      name = "Facebook Pixel";
    } else if (app.slug === "google-analytics") {
      helperText = "Add GA property ID Eg., AW-XXXXXXX-X";
      name = "GA Property Id";
    } else if (app.slug === "tawkto") {
      helperText =
        "Enter the Tawk To script source URL Eg.https://embed.tawk.to/6109964ed6e7610a49ae6ba1/1fc6n696f";
      name = "Tawk To";
    } else if (app.slug === "tidio") {
      helperText =
        "Enter the Tidio script source URL Eg. //code.tidio.co/ywwjgjizvtykfwdmz6iqlwopt5chwvhe.js";
      name = "Tidio";
    }

    formBody = (
      <Controls.Input
        placeholder=""
        helperText={helperText}
        name={name}
        label={name}
        value={pixel}
        color="secondary"
        onChange={(e) => {
          setPixelTouched(true);
          setPixel(e.target.value);
          if (e.target.value.length === 0 && pixelTouched) {
            setPixelError("Pixel cannot be empty");
          } else if (e.target.value.length > 0 && pixelError) {
            setPixelError("");
          }
        }}
        error={pixelError.length > 0 ? pixelError : ""}
        onFocus={() => {}}
        onBlur={() => {}}
        controlClass={classes.controlInput}
        labelClass={classes.label}
        labelPlacement="top"
      />
    );
  }

  return (
    <>
      <div>
        <Typography className={classes.formHead}>
          {app.title} Configuration Settings
        </Typography>
        {formBody}
      </div>

      <div className={classes.button}>
        <CombinedButtons
          outlinedBtnText="Back"
          outlinedBtnAction={() => history.goBack()}
          solidBtnAction={handleSave}
        />
      </div>

      <Toast
        message={errorMessage ? errorMessage : "Could not copy to clipboard"}
        open={showError}
        close={() => setShowError(false)}
        severity="error"
        autoClose
      />
      <Toast
        message={"Copied to clipboard"}
        open={showSuccess}
        close={() => setShowSuccess(false)}
        autoClose
      />
    </>
  );
}
