import React from "react";

export default function NoCouponImage() {
  return (
    <svg
      width="425"
      height="330"
      viewBox="0 0 425 330"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M241.779 11.249C173.442 11.249 118.04 66.6553 118.04 134.999C118.04 203.343 173.442 258.749 241.779 258.749C310.117 258.749 365.519 203.343 365.519 134.999C365.519 66.6553 310.117 11.249 241.779 11.249ZM253.029 179.999C253.029 182.983 251.843 185.844 249.734 187.954C247.624 190.064 244.763 191.249 241.779 191.249C238.796 191.249 235.935 190.064 233.825 187.954C231.716 185.844 230.53 182.983 230.53 179.999V146.249H196.783C193.8 146.249 190.939 145.064 188.829 142.954C186.719 140.844 185.534 137.983 185.534 134.999C185.534 132.015 186.719 129.154 188.829 127.044C190.939 124.934 193.8 123.749 196.783 123.749H230.53V89.999C230.53 87.0153 231.716 84.1539 233.825 82.0441C235.935 79.9343 238.796 78.749 241.779 78.749C244.763 78.749 247.624 79.9343 249.734 82.0441C251.843 84.1539 253.029 87.0153 253.029 89.999V123.749H286.776C289.759 123.749 292.62 124.934 294.73 127.044C296.84 129.154 298.025 132.015 298.025 134.999C298.025 137.983 296.84 140.844 294.73 142.954C292.62 145.064 289.759 146.249 286.776 146.249H253.029V179.999Z"
        fill="black"
      />
      <path
        opacity="0.6"
        d="M9.38019 269.919C9.62139 270.067 9.86595 270.209 10.1138 270.345C10.1976 270.392 10.283 270.437 10.3668 270.48C10.5594 270.583 10.752 270.681 10.9463 270.779C20.254 275.35 31.6069 271.841 36.667 262.663C41.4925 253.907 38.8997 243.062 30.9805 237.367C30.349 236.913 29.6841 236.491 28.9856 236.107C28.7344 235.97 28.4831 235.836 28.2285 235.712C27.9823 235.59 27.7327 235.472 27.4832 235.362C26.7177 235.02 25.9405 234.734 25.155 234.497C23.9875 234.146 22.8033 233.91 21.6158 233.784C17.9342 233.394 14.2292 234.067 10.9446 235.673C7.45903 237.377 4.44576 240.134 2.43079 243.789C-2.64099 252.996 0.486156 264.509 9.38019 269.919Z"
        fill="#C0CEFF"
        fillOpacity="0.8"
      />
      <path
        opacity="0.6"
        d="M19.2891 271.176C25.7846 271.176 31.7675 267.645 34.8997 261.961C37.1961 257.795 37.7321 252.984 36.4105 248.414C35.089 243.845 32.0673 240.064 27.9017 237.767C25.272 236.318 22.304 235.551 19.3192 235.551C12.8237 235.551 6.84079 239.082 3.70862 244.766C1.41225 248.932 0.876259 253.743 2.1978 258.313C3.51934 262.882 6.54098 266.663 10.7066 268.96C13.3363 270.408 16.3043 271.176 19.2891 271.176ZM19.3192 236.612C22.1248 236.612 24.9153 237.332 27.3875 238.695C31.3052 240.855 34.146 244.411 35.3888 248.708C36.6316 253.004 36.1274 257.529 33.9684 261.447C31.0222 266.792 25.3977 270.112 19.2874 270.112C16.4819 270.112 13.6914 269.392 11.2191 268.028C7.30138 265.869 4.46065 262.313 3.21783 258.016C1.97501 253.719 2.47917 249.195 4.63819 245.277C7.58612 239.932 13.2107 236.612 19.3192 236.612Z"
        fill="#5177FF"
        fillOpacity="0.8"
      />
      <path
        opacity="0.6"
        d="M19.306 240.63L15.3782 248.588L6.59644 249.864L12.9512 256.059L11.4521 264.806L19.306 260.676L27.1616 264.806L25.6608 256.059L32.0156 249.864L23.2338 248.588L19.306 240.63Z"
        fill="#4D73FF"
        fillOpacity="0.8"
      />
      <path
        d="M422.494 161.922C414.551 132.589 365.056 141.756 365.056 141.756C365.056 141.756 331.449 193.086 321.674 194.309C311.896 195.533 341.838 144.811 341.838 144.811C322.897 142.979 274.626 166.2 274.626 166.2C274.626 166.2 277.68 226.085 267.906 224.865C258.128 223.642 267.906 171.701 267.906 171.701C249.573 177.202 232.467 198.587 232.467 198.587C211.08 233.417 256.908 259.084 256.908 259.084C256.908 259.084 257.52 279.861 244.688 303.693C244.688 303.693 241.791 308.094 238.85 315.574H243.848C244.255 314.235 244.705 312.938 245.204 311.694C245.204 311.683 245.208 311.676 245.215 311.672C245.222 311.655 245.229 311.637 245.236 311.62C245.577 310.846 246.069 309.75 246.684 308.41L246.687 308.407C246.687 308.403 246.691 308.403 246.691 308.4C248.216 305.412 249.25 303.201 249.9 301.609C249.903 301.605 249.903 301.598 249.903 301.595C254.48 292.213 261.07 279.985 265.463 277.422C265.463 277.422 264.911 276.645 264.395 275.13C264.736 275.861 265.091 276.624 265.463 277.422C274.018 295.756 316.789 298.811 340.622 293.922C364.452 289.032 354.066 282.311 341.234 271.401C328.401 260.49 301.517 263.365 293.574 259.088C285.631 254.81 297.24 250.532 313.126 251.755C329.013 252.979 336.956 268.866 362.621 271.309C388.285 273.752 407.837 225.477 407.837 225.477C390.116 230.978 358.343 221.199 357.124 215.09C355.9 208.981 402.952 209.589 410.284 207.758C417.605 205.919 430.437 191.255 422.494 161.922Z"
        fill="#DFEBFD"
      />
      <path
        d="M7.65147 181.045C15.381 155.073 63.5432 163.19 63.5432 163.19C63.5432 163.19 96.2464 208.639 105.758 209.722C115.273 210.805 86.1366 165.895 86.1366 165.895C104.568 164.273 151.54 184.833 151.54 184.833C151.54 184.833 148.568 237.857 158.079 236.777C167.594 235.694 158.079 189.704 158.079 189.704C175.918 194.575 192.564 213.51 192.564 213.51C213.376 244.349 168.78 267.075 168.78 267.075C168.78 267.075 168.185 285.472 180.672 306.573C180.672 306.573 183.49 310.47 186.353 317.093H181.49C181.093 315.907 180.655 314.758 180.17 313.657C180.17 313.647 180.166 313.641 180.159 313.638C180.152 313.622 180.146 313.607 180.139 313.591C179.807 312.907 179.328 311.936 178.73 310.75L178.726 310.747C178.726 310.744 178.723 310.744 178.723 310.74C177.238 308.095 176.233 306.137 175.6 304.728C175.597 304.724 175.597 304.718 175.597 304.715C171.144 296.408 164.731 285.581 160.456 283.312C160.456 283.312 160.993 282.624 161.496 281.283C161.164 281.93 160.818 282.605 160.456 283.312C152.131 299.546 110.512 302.25 87.3199 297.921C64.1314 293.592 74.2379 287.641 86.7248 277.981C99.2117 268.32 125.372 270.866 133.102 267.078C140.831 263.291 129.535 259.503 114.076 260.586C98.6166 261.669 90.8871 275.737 65.9133 277.9C40.9395 280.063 21.9134 237.319 21.9134 237.319C39.1577 242.189 70.0756 233.531 71.2624 228.122C72.4526 222.713 26.6674 223.251 19.533 221.63C12.4089 220.002 -0.0780182 207.017 7.65147 181.045Z"
        fill="#DFEBFD"
      />
      <path
        opacity="0.8"
        d="M85.9385 181.998C85.6167 181.998 85.3207 181.79 85.2244 181.467C85.1052 181.072 85.3293 180.656 85.7244 180.537C88.5865 179.678 156.043 159.639 175.502 170.867C175.859 171.072 175.981 171.529 175.775 171.887C175.569 172.245 175.112 172.367 174.754 172.16C155.846 161.251 86.8465 181.758 86.1526 181.967C86.0822 181.988 86.0103 181.998 85.9385 181.998Z"
        fill="white"
      />
      <path
        d="M59.9952 133.908C54.6261 135.32 44.3965 133.238 44.3965 133.238C44.3965 133.238 49.0763 130.639 47.8393 126L52.5737 127.716L55.7897 128.88C55.7897 128.88 55.9449 129.689 55.9193 131.127C55.9125 131.505 55.8937 131.927 55.8545 132.39C58.4085 132.985 59.9952 133.908 59.9952 133.908Z"
        fill="#FAD4BD"
      />
      <g opacity="0.5">
        <path
          d="M32.8168 321.825H23.8561C22.7273 321.211 21.7233 319.81 21.4053 317.717C20.9669 314.83 17.7325 313.256 17.0329 310.894C16.3333 308.533 19.9198 305.21 19.9198 305.21L20.5195 304.438L21.1441 303.636L27.0906 305.037C27.0906 305.037 27.2382 306.455 27.3268 308.259C27.3927 309.56 27.4268 311.064 27.3518 312.384C27.1769 315.532 28.2331 318.924 31.0292 319.537C33.0984 319.989 33.0053 321.216 32.8168 321.825Z"
          fill="#233862"
        />
        <path
          d="M84.8226 321.823H64.3301C63.3443 320.619 63.7077 316.181 63.8577 314.732C63.8895 314.439 63.9099 314.271 63.9099 314.271C63.9099 314.271 70.9081 308.674 71.8689 312.96C71.8984 313.094 71.9347 313.226 71.9734 313.355C73.184 317.364 78.6172 319.186 81.754 319.693C83.7891 320.024 84.5432 321.085 84.8226 321.823Z"
          fill="#233862"
        />
        <path
          d="M80.5487 204.431C80.5487 204.431 79.4857 217.69 78.2365 233.628C76.5397 255.278 74.4978 281.872 74.2956 286.91C73.9458 295.658 72.5466 309.651 72.5466 309.651C72.5466 309.651 69.2258 312.974 62.5775 311.575C62.5775 311.575 56.6537 291.599 54.9615 270.464C54.9343 270.118 54.9093 269.769 54.882 269.419C53.3079 247.902 50.8594 202.596 50.8594 202.596L80.5487 204.431Z"
          fill="#383838"
        />
        <path
          d="M80.5491 204.431C80.5491 204.431 79.486 217.69 78.2368 233.628L54.9596 270.462C54.9323 270.116 54.9073 269.767 54.8801 269.417C53.306 247.9 50.8574 202.594 50.8574 202.594L80.5491 204.431Z"
          fill="#2B2B2B"
        />
        <path
          d="M75.6954 205.173C75.6954 205.173 56.543 253.189 43.9481 275.755C31.3555 298.321 27.4192 304.618 27.4192 304.618C27.4192 304.618 20.203 301.731 19.5488 299.764C19.5488 299.764 24.3642 256.199 32.7979 245.58C37.5632 239.578 43.1622 221.571 43.4234 215.272C43.6869 208.975 45.1293 196.511 45.1293 196.511L75.6954 205.173Z"
          fill="#383838"
        />
        <path
          d="M59.7225 126.838C59.7225 126.838 47.6955 130.356 45.6195 122.831C43.5434 115.305 40.5929 110.691 48.1861 108.127C55.7771 105.562 58.069 108.02 59.3727 110.364C60.6743 112.709 62.798 125.729 59.7225 126.838Z"
          fill="#FFB27D"
        />
        <path
          d="M81.2936 199.705C81.6683 203.174 81.8432 205.334 81.7592 205.418C81.557 205.631 81.3117 205.775 81.0142 205.811C78.2204 206.111 70.2705 208.639 63.2996 208.764C57.428 208.907 52.0835 207.805 46.2664 205.997C45.4964 205.759 43.6997 205.061 43.6407 204.237C43.5566 203.339 43.4885 202.026 43.4022 200.375C43.1069 194.632 42.6072 184.756 40.5811 173.534C40.2086 171.446 39.8974 169.363 39.6408 167.31C37.7373 152.002 39.1501 138.165 44.7014 132.868C45.1807 132.432 48.5673 133.611 51.6814 134.706C54.5615 135.719 57.294 135.542 59.7994 134.71L62.6409 136.671C66.1184 139.197 70.0161 142.731 72.2262 148.542C74.1092 153.487 75.9581 162.589 77.5162 172.064C78.2204 176.36 78.8972 180.696 79.431 184.821L79.4492 184.969C79.5355 185.373 79.59 185.823 79.6377 186.232C79.7377 186.966 79.8308 187.686 79.9262 188.395C80.5031 192.802 80.971 196.734 81.2936 199.705Z"
          fill="#9E9E9E"
        />
        <path
          d="M45.5192 122.098C45.5192 122.098 46.7526 121.734 46.3415 120.024C45.9304 118.313 45.6578 116.046 47.1206 116.299C48.5833 116.551 48.7878 117.673 49.2875 116.944C49.7872 116.214 49.4624 113.434 53.6372 112.734C57.812 112.035 59.6927 113.291 61.5416 112.196C63.3905 111.103 63.1361 108.137 63.1361 108.137C63.1361 108.137 62.7091 109.259 61.9096 109.695C61.9096 109.695 64.6285 104.85 60.4922 102.249C56.3538 99.6501 52.7513 102.112 50.5844 102.508C45.8122 103.378 42.7436 104.041 41.5534 107.389C40.3654 110.735 42.069 119.429 45.5192 122.098Z"
          fill="#2B478B"
        />
        <path
          d="M81.294 199.704C80.7035 199.814 70.4458 201.656 63.4727 197.06C56.2928 192.331 47.8818 164.288 47.8818 164.288C47.8818 164.288 64.0087 182.225 64.3222 182.933C64.5834 183.529 76.2129 187.227 79.9244 188.397C80.5036 192.801 80.9715 196.733 81.294 199.704Z"
          fill="#888888"
        />
        <path
          d="M108.258 192.51C105.564 192.46 102.112 191.302 101.003 190.752C99.1317 189.821 98.1777 188.919 98.1777 188.919L98.4617 187.145L98.8024 185.028C98.8024 185.028 99.0113 184.994 99.3566 184.96C99.6996 184.924 100.186 184.887 100.733 184.878C100.733 184.878 101.619 185.146 102.353 184.492C103.084 183.838 104.433 183.395 105.455 183.638C106.475 183.883 108.669 183.654 108.74 184.013C108.912 184.876 105.714 184.719 105.342 185.26C105.003 185.75 105.312 186.08 105.374 186.141C105.378 186.143 105.383 186.148 105.387 186.152C107.259 188.04 113.7 188.726 113.444 190.223C113.344 190.777 109.396 192.531 108.258 192.51Z"
          fill="#FFB27D"
        />
        <path
          d="M42.9714 153.543C42.9714 153.543 57.7264 187.656 64.3203 190.907C70.9141 194.157 101.003 191.631 101.003 191.631L101.201 185.809C101.201 185.809 75.2865 180.675 72.7857 176.425C70.2849 172.175 66.0851 146.077 59.4458 143.446C52.8066 140.818 42.7193 144.241 42.9714 153.543Z"
          fill="#9E9E9E"
        />
      </g>
      <rect
        opacity="0.5"
        x="4.7998"
        y="328.8"
        width="419.964"
        height="1.1999"
        fill="#999999"
      />
      <path
        opacity="0.6"
        d="M171.757 323.158C172.111 323.374 172.47 323.583 172.833 323.782C172.956 323.85 173.082 323.917 173.204 323.981C173.487 324.13 173.769 324.275 174.054 324.418C187.706 331.123 204.357 325.976 211.778 312.515C218.856 299.673 215.053 283.767 203.438 275.414C202.512 274.748 201.537 274.129 200.512 273.566C200.144 273.365 199.775 273.168 199.402 272.987C199.041 272.807 198.675 272.635 198.309 272.473C197.186 271.972 196.046 271.552 194.894 271.205C193.182 270.689 191.445 270.343 189.703 270.159C184.303 269.586 178.869 270.574 174.052 272.93C168.94 275.429 164.52 279.473 161.565 284.833C154.126 298.336 158.713 315.222 171.757 323.158Z"
        fill="#C0CEFF"
        fillOpacity="0.8"
      />
      <path
        opacity="0.6"
        d="M186.292 325C195.819 325 204.594 319.821 209.188 311.485C212.556 305.375 213.342 298.319 211.404 291.617C209.465 284.914 205.034 279.369 198.924 276.001C195.067 273.876 190.714 272.751 186.336 272.751C176.809 272.751 168.034 277.93 163.44 286.266C160.072 292.376 159.286 299.432 161.225 306.134C163.163 312.836 167.595 318.381 173.704 321.75C177.561 323.875 181.914 325 186.292 325ZM186.336 274.306C190.451 274.306 194.544 275.362 198.17 277.362C203.916 280.529 208.082 285.745 209.905 292.047C211.728 298.348 210.988 304.984 207.822 310.731C203.501 318.571 195.251 323.44 186.29 323.44C182.175 323.44 178.082 322.384 174.456 320.384C168.71 317.217 164.543 312.001 162.721 305.699C160.898 299.397 161.637 292.762 164.804 287.015C169.128 279.175 177.377 274.306 186.336 274.306Z"
        fill="#5177FF"
        fillOpacity="0.8"
      />
      <path
        opacity="0.6"
        d="M186.315 280.199L180.554 291.871L167.674 293.744L176.995 302.829L174.796 315.658L186.315 309.6L197.837 315.658L195.636 302.829L204.956 293.744L192.076 291.871L186.315 280.199Z"
        fill="#4D73FF"
        fillOpacity="0.8"
      />
    </svg>
  );
}
