import React from "react";
import {
  FormControlLabel,
  Switch as MuiSwitch,
  Typography,
} from "@material-ui/core";

const Switch = (props) => {
  const {
    name,
    label,
    value,
    color,
    onChange,
    width = "auto",
    labelPlacement = "start",
    style = {},
    labelClass,
    helperText = "",
    labelFontWeight = 600,
    disabled,
  } = props;
  return (
    <FormControlLabel
      control={
        <MuiSwitch
          checked={value}
          onChange={onChange}
          name={name}
          color={color}
          disabled={disabled}
        />
      }
      labelPlacement={labelPlacement}
      label={
        helperText ? (
          <div>
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: labelFontWeight,
              }}
            >
              {label}
            </Typography>
            <Typography
              style={{
                fontSize: "12px",
                fontWeight: 400,
                color: "#666666",
                width: props.helperTextWidth || "auto",
              }}
            >
              {helperText}
            </Typography>
          </div>
        ) : (
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: labelFontWeight,
            }}
          >
            {label}
          </Typography>
        )
      }
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: width,
        ...style,
      }}
      classes={{ label: labelClass }}
    />
  );
};
export default Switch;
