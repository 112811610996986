import React from "react";
import NoExtraFieldsImage from "../../../icons/NoExtraFieldsImage";
import ArrowIcon from "../../../icons/ArrowIcon";

const NoCoupon = () => {
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <div style={{ position: "absolute", right: "14%", top: "20px" }}>
        <ArrowIcon />
      </div>
      <div
        style={{
          position: "absolute",
          right: "32%",
          top: 55,
          maxWidth: 372,
          textAlign: "center",
        }}
      >
        <p
          style={{
            fontWeight: 600,
            fontSize: 20,
            lineHeight: "32px",
            color: "#1A1A1A",
          }}
        >
          Start Creating extra fields for store checkout
        </p>
      </div>
      <div style={{ position: "absolute", right: "30%", top: 150 }}>
        <NoExtraFieldsImage />
      </div>
    </div>
  );
};

export default NoCoupon;
