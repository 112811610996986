import axios from "axios";
import { getDefaultStore } from "./storeUtil";

export const uploadFile = async (filename, file) => {
  const store = getDefaultStore();
  const formData = new FormData();
  formData.append("file", file);
  const accessToken = localStorage.getItem("access_token");
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
      "X-Requested-With": "XMLHttpRequest",
    },
  };
  const port = process.env.REACT_APP_LOCAL_PORT;
  const host = process.env.REACT_APP_LOCAL_HOST;
  const proto = process.env.REACT_APP_LOCAL_PROTO;
  const timestampName =
    filename.split(".")[0] + Date.now() + "." + filename.split(".")[1];
  const url = `${proto}://${host}:${port}/api/v1/cloud/file/pub?cloud-file-name=${timestampName}&cloud-file-loc=${store.org_id}`;
  const response = await axios.post(url, formData, config);
  return response.data;
};
