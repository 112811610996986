import React from "react";

export default function BannerCollectionIcon() {
  return (
    <svg
      width="73"
      height="60"
      viewBox="0 0 73 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.643913"
        y="0.643913"
        width="71.7122"
        height="58.7122"
        rx="4.50739"
        fill="white"
        stroke="#666666"
        strokeWidth="1.28783"
      />
      <rect
        x="5.03711"
        y="6.31445"
        width="62.931"
        height="17.3684"
        rx="2.57565"
        fill="#8994FF"
      />
      <path
        d="M5.03711 28.4219H67.9681V33.1587H5.03711V28.4219Z"
        fill="#C4C4C4"
      />
      <rect
        x="5.03711"
        y="37.8945"
        width="62.931"
        height="4.73684"
        fill="#C4C4C4"
      />
      <rect
        x="5.03711"
        y="47.3691"
        width="62.931"
        height="4.73684"
        fill="#C4C4C4"
      />
    </svg>
  );
}
