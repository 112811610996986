import React from "react";
import { Button } from "@material-ui/core";
import DesktopIcon from "../../../icons/DesktopIcon";
import MobileIcon from "../../../icons/MobileIcon";
import { outlineButton } from "../../../styles/common";
import useStyles from "../styles";

const SwitchButton = ({ isMobileActive, setIsMobileActive }) => {
  const classes = useStyles();
  return (
    <div style={{ display: "flex" }}>
      <Button
        className={`${outlineButton} ${!isMobileActive && classes.disabledBtn}`}
        style={{
          fontSize: 14,
          fontWeight: 400,
          borderRadius: 4,
          width: 164,
          height: 40,
        }}
        startIcon={
          <MobileIcon fill={!isMobileActive ? "#999999" : "#1641DB"} />
        }
        onClick={() => setIsMobileActive(true)}
      >
        Mobile
      </Button>
      <Button
        className={`${outlineButton} ${isMobileActive && classes.disabledBtn}`}
        style={{
          fontSize: 14,
          fontWeight: 400,
          borderRadius: 4,
          width: 164,
          height: 40,
        }}
        startIcon={
          <DesktopIcon fill={isMobileActive ? "#999999" : "#1641DB"} />
        }
        onClick={() => setIsMobileActive(false)}
      >
        Desktop
      </Button>
    </div>
  );
};

export default SwitchButton;
