import React from "react";
import ShoopyIcon from "../../icons/ShoopyIconLatest";
import { Grid, Typography } from "@material-ui/core";
import useStyles from "./components/styles";
import HeartIcon from "../../icons/HeartIcon";
import IndiaFlagIcon from "../../icons/IndiaFlagIcon";
import BusinessDetailsForm from "./components/BusinessDetailsForm";
import LoginBanner from "./components/LoginBanner";

const CreateAccount = () => {
  const classes = useStyles();
  return (
    <div>
      <Grid
        container
        className={classes.root}
        style={{
          height: "100vh",
        }}
      >
        <Grid item md={5} xs={12}>
          <div className={classes.icon}>
            <ShoopyIcon width={72} height={72} viewBox="0 0 40 40" />
          </div>
          <div className={classes.signin}>
            <div style={{ width: 360 }}>
              <div>
                <Typography variant="h2" className={classes.heading}>
                  Business Details
                </Typography>
                <BusinessDetailsForm />
              </div>
              <div className={classes.madeinindiactn}>
                <div className={classes.madeinindiamsg}>
                  <Typography style={{ fontSize: 10, lineHeight: "12px" }}>
                    Made with &nbsp;
                  </Typography>
                  <HeartIcon />
                  <Typography style={{ fontSize: 10, lineHeight: "12px" }}>
                    &nbsp; in INDIA &nbsp;
                  </Typography>
                  <IndiaFlagIcon />
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item md={7} className={classes.banner}>
          <LoginBanner />
        </Grid>
      </Grid>
    </div>
  );
};
export default CreateAccount;
