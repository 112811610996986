import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const unInstallIcon = ({
  viewBox = "0 0 26 26",
  stroke = "#999999",
  fill = "#999999",
}) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "26px", height: "26px" }}>
      <svg
        width="26"
        height="26"
        viewBox={viewBox}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="13"
          cy="13.0001"
          r="8.49051"
          transform="rotate(45 13 13.0001)"
          fill="white"
          stroke="#1641DB"
        />
        <path
          d="M9.99805 9.99829L16.0017 16.002"
          stroke="#1641DB"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.0034 9.99976L9.99972 16.0035"
          stroke="#1641DB"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default unInstallIcon;
