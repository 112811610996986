import { Typography, Button, makeStyles, Modal } from "@material-ui/core";
import React from "react";
import { CloseIcon, NoPicIcon } from "../../../icons";

const useStyles = makeStyles(() => ({
  heading: {
    fontWeight: 700,
    fontSize: 24,
    lineHeight: "32px",
    color: "#1A1A1A",
  },
  typeCon: {
    display: "flex",
    alignItems: "center",
    padding: 10,
    marginBottom: 8,
    cursor: "pointer",
    borderRadius: 6,
  },
  name: {
    color: "#666666",
    fontWeight: 700,
    fontSize: 14,
  },
  img: {
    width: 40,
    height: 40,
    borderRadius: 6,
    marginRight: 20,
  },
  nopicctn: {
    border: "1px solid #E5E5E5",
    boxSizing: "border-box",
    borderRadius: 6,
    width: 40,
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 20,
  },
}));

const BusinessTypesModal = ({
  open,
  setOpen,
  types,
  selected,
  onTypeSelect,
}) => {
  const classes = useStyles();
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          padding: 25,
          borderRadius: 12,
          paddingBottom: 16,
          position: "absolute",
          background: "#ffffff",
          width: 546,
          outline: "none",
          height: "calc(100vh - 200px)",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 25,
          }}
        >
          <Typography className={classes.heading}>
            Select Business Category
          </Typography>
          <Button onClick={() => setOpen(false)} style={{ outline: "none" }}>
            <CloseIcon />
          </Button>
        </div>
        <div style={{ height: "calc(100vh - 300px)", overflow: "overlay" }}>
          {types.map((type) => {
            return (
              <div
                className={classes.typeCon}
                onClick={() => {
                  onTypeSelect(type.slug);
                  setOpen(false);
                }}
                key={type.slug}
                style={{
                  background: selected === type.slug ? "#E1E1E1" : "#FFF",
                }}
              >
                {type.thumbnail ? (
                  <img
                    src={type.thumbnail}
                    alt={type.name}
                    className={classes.img}
                  />
                ) : (
                  <div className={classes.nopicctn}>
                    <NoPicIcon />
                  </div>
                )}
                <div className={classes.name}>{type.name}</div>
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

export default BusinessTypesModal;
