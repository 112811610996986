import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const BriefcaseIcon = ({ viewBox = "0 0 16 16", stroke = "#1A1A1A" }) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "16px", height: "16px" }}>
      <path
        d="M14.2826 3.26087H11.1733V2.41304C11.1729 2.03841 11.0239 1.67923 10.759 1.41433C10.4941 1.14942 10.1349 1.00041 9.76031 1H6.369C5.99436 1.00041 5.63519 1.14942 5.37028 1.41433C5.10537 1.67923 4.95637 2.03841 4.95596 2.41304V3.26087H1.84783C1.62304 3.26111 1.40754 3.35051 1.24859 3.50946C1.08965 3.66841 1.00024 3.88391 1 4.1087V13.1522C1.00024 13.377 1.08965 13.5925 1.24859 13.7514C1.40754 13.9104 1.62304 13.9998 1.84783 14H14.2826C14.5074 13.9998 14.7229 13.9104 14.8818 13.7514C15.0408 13.5925 15.1302 13.377 15.1304 13.1522V4.1087C15.1302 3.88391 15.0408 3.66841 14.8818 3.50946C14.7229 3.35051 14.5074 3.26111 14.2826 3.26087ZM5.52117 2.41304C5.52144 2.18827 5.61085 1.97277 5.76979 1.81383C5.92873 1.65489 6.14422 1.56548 6.369 1.56522H9.76031C9.98508 1.56548 10.2006 1.65489 10.3595 1.81383C10.5185 1.97277 10.6079 2.18827 10.6081 2.41304V3.26087H5.52117V2.41304ZM10.6081 3.82609V13.4348H5.52117V3.82609H10.6081ZM1.56522 13.1522V4.1087C1.56531 4.03377 1.59512 3.96194 1.64809 3.90896C1.70107 3.85599 1.7729 3.82618 1.84783 3.82609H4.95596V13.4348H1.84783C1.7729 13.4347 1.70107 13.4049 1.64809 13.3519C1.59512 13.2989 1.56531 13.2271 1.56522 13.1522ZM14.5652 13.1522C14.5651 13.2271 14.5353 13.2989 14.4823 13.3519C14.4294 13.4049 14.3575 13.4347 14.2826 13.4348H11.1733V3.82609H14.2826C14.3575 3.82618 14.4294 3.85599 14.4823 3.90896C14.5353 3.96194 14.5651 4.03377 14.5652 4.1087V13.1522Z"
        fill={stroke}
        stroke={stroke}
        strokeWidth="0.378182"
      />
    </SvgIcon>
  );
};

export default BriefcaseIcon;
