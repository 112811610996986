import React, { useState } from "react";
import ImageList from "../../../components/ImageList";
import { Grid } from "@material-ui/core";
import ImageHandler from "../../../components/ImageHandler";
import { RemoveCircleIcon } from "../../../icons";

const Multiple = ({ maxSize = 1920 }) => {
  const [cropImg, setCropImg] = useState("");
  const [filenames, setFilenames] = useState("");
  const [openImageHandler, setOpenImageHandler] = useState(false);
  const [openImageHandlerIndex, setOpenImageHandlerIndex] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  return (
    <Grid
      item
      xs={12}
      style={{
        marginBottom: 16,
        display: "flex",
      }}
    >
      {cropImg && (
        <div
          style={{
            height: 80,
            width: "70%",
            border: "1px solid #E6E6E6",
            borderRadius: 6,
            position: "relative",
          }}
        >
          <img
            src={cropImg}
            alt=""
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              cursor: "pointer",
            }}
            onClick={() => {
              setOpenImageHandler(true);
              setOpenImageHandlerIndex(0);
            }}
          />
          <div
            style={{
              position: "absolute",
              top: -8,
              right: -8,
              cursor: "pointer",
            }}
            onClick={() => setCropImg(null)}
          >
            <RemoveCircleIcon />
          </div>
        </div>
      )}
      <ImageHandler
        setCropImg={setCropImg}
        setFilename={setFilenames}
        cropImg={cropImg}
        filename={filenames}
        maxSize={maxSize}
        openImageHandler={openImageHandler}
        setOpenImageHandler={setOpenImageHandler}
        openImageHandlerIndex={openImageHandlerIndex}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </Grid>
  );
};

export default Multiple;
