import { CLEAR_DATA, CLEAR_COMPLETE } from "../commom/commonTypes";
import {
  FETCH_ADDITIONAL_FIELDS,
  ADDITIONAL_FIELDS_PROGRESS,
  ADD_ADDITIONAL_FIELD,
  CLEAR_ADDITIONAL_FIELDS_ERROR,
  DELETE_ADDITIONAL_FIELD,
  SET_ADDITIONAL_FIELDS_ERROR,
  UPDATE_ADDITIONAL_FIELD,
  UPDATE_ADDITIONAL_FIELDS_LIST,
  CLEAR_ADDITIONAL_FIELDS,
} from "./additionalFieldsTypes";

const initialState = {
  fields: [],
  variantFields: {
    nativeFields: {},
    nonNativeFields: [],
  },
  checkoutFields: [],
  attributeFields: [],
  progress: false,
  error: null,
};

const organizeFields = (fields) => {
  const additionalFields = {
    nativeFields: {},
    checkoutFields: [],
    nonNativeFields: [],
    attributeFields: [],
  };

  const fieldGroupMap = {
    CHECKOUT: "checkoutFields",
    VARIANT: "nonNativeFields",
    ATTRIBUTE: "attributeFields",
  };

  if (Array.isArray(fields)) {
    fields.forEach((field) => {
      if (field.native) {
        additionalFields.nativeFields[field.slug] = field;
      } else {
        const targetArray = fieldGroupMap[field.group];
        if (targetArray) {
          additionalFields[targetArray].push(field);
        }
      }
    });
  }

  return additionalFields;
};

const getUpdatedState = (state, fields) => {
  const organizedFields = organizeFields(fields);
  return {
    ...state,
    fields: fields,
    variantFields: {
      nativeFields: organizedFields.nativeFields,
      nonNativeFields: organizedFields.nonNativeFields,
    },
    checkoutFields: organizedFields.checkoutFields,
    attributeFields: organizedFields.attributeFields,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADDITIONAL_FIELDS_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      };
    case FETCH_ADDITIONAL_FIELDS:
      return getUpdatedState(state, action.payload);

    case ADD_ADDITIONAL_FIELD:
      const updatedFields = [...state.fields, action.payload];
      const fieldKey = action.payload.group.toLowerCase() + "Fields";

      let updatedFieldGroup;
      if (fieldKey === "variantFields") {
        const fieldSubKey = action.payload.native
          ? "nativeFields"
          : "nonNativeFields";

        if (action.payload.native) {
          updatedFieldGroup = {
            ...state.variantFields,
            [fieldSubKey]: {
              ...state.variantFields[fieldSubKey],
              [action.payload.slug]: action.payload,
            },
          };
        } else {
          updatedFieldGroup = {
            ...state.variantFields,
            [fieldSubKey]: [
              ...state.variantFields[fieldSubKey],
              action.payload,
            ],
          };
        }
      } else {
        updatedFieldGroup = [...state[fieldKey], action.payload];
      }

      return {
        ...state,
        fields: updatedFields,
        [fieldKey]: updatedFieldGroup,
      };

    case UPDATE_ADDITIONAL_FIELD:
      const updateList = state.fields.map((field) =>
        field.id === action.payload.id ? action.payload : field
      );

      return getUpdatedState(state, updateList);

    case UPDATE_ADDITIONAL_FIELDS_LIST:
      const { data, fieldType } = action.payload;
      const stateKey = fieldType.toLowerCase() + "Fields";

      return {
        ...state,
        [stateKey]:
          fieldType === "VARIANT"
            ? { ...state[stateKey], nonNativeFields: data }
            : data,
      };

    case DELETE_ADDITIONAL_FIELD:
      const fieldsAfterDeletion = state.fields.filter(
        (field) => field.id !== action.payload.id
      );
      return getUpdatedState(state, fieldsAfterDeletion);

    case SET_ADDITIONAL_FIELDS_ERROR:
      return {
        ...state,
        error: action.payload.error,
      };

    case CLEAR_ADDITIONAL_FIELDS_ERROR:
      return {
        ...state,
        error: null,
      };
    case CLEAR_ADDITIONAL_FIELDS:
    case CLEAR_COMPLETE:
    case CLEAR_DATA:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
