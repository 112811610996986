import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ExpressDeliveryIcon = () => {
  return (
    <SvgIcon viewBox="0 0 10 11" style={{ width: "10px", height: "11px" }}>
      <svg
        width="10"
        height="11"
        viewBox="0 0 10 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.98838 3.48495H6.07256L8.69455 0.172064C8.74881 0.101915 8.69984 0 8.61116 0H3.53528C3.49822 0 3.46248 0.0198535 3.44395 0.0529427L0.0145881 5.97591C-0.0264426 6.04606 0.023853 6.13474 0.105914 6.13474H2.41422L1.23095 10.8678C1.2058 10.9711 1.33021 11.0439 1.40698 10.9697L9.06118 3.66628C9.13 3.60143 9.08368 3.48495 8.98838 3.48495Z"
          fill="#FF6060"
        />
      </svg>
    </SvgIcon>
  );
};

export default ExpressDeliveryIcon;
