import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const NoCustomersFigma = () => {
  return (
    <SvgIcon
      viewBox={"0 0 566 405"}
      style={{ width: "566px", height: "405px" }}
    >
      <svg
        width="566"
        height="405"
        viewBox="0 0 566 405"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M334.445 11.5696C318.064 4.91967 300.754 -0.647593 283.081 0.0609677C257.08 1.082 232.176 16.688 219.942 39.604C209.576 59.0168 208.298 81.7744 203.834 103.317C198.334 129.864 187.801 155.091 176.546 179.763C170.499 192.996 164.122 206.552 163.131 221.079C161.751 241.245 171.407 261.344 186.545 274.771C201.683 288.199 221.802 295.263 241.973 296.807C252.713 297.63 263.933 296.869 273.474 291.878C283.566 286.597 290.922 277.051 301.053 271.849C310.445 267.008 321.427 266.348 331.969 266.977C345.776 267.802 359.382 270.689 372.333 275.542C387.065 281.069 400.767 289.07 415.469 294.673C430.171 300.275 446.596 303.404 461.624 298.726C475.726 294.325 486.894 283.675 496.955 272.87C513.469 255.136 528.531 236.107 541.995 215.965C551.069 202.393 559.513 188.072 563.528 172.25C571.196 142.006 560.641 107.881 537.222 87.2228C517.831 70.1117 491.79 62.6828 466.304 58.1762C443.066 54.0657 416.196 53.8104 394.804 43.0984C374.4 32.866 355.837 20.2572 334.445 11.5696Z"
          fill="#DFE4F5"
        />
        <path
          d="M449.442 156.09H184.472V346.344H449.442V156.09Z"
          fill="white"
        />
        <path
          d="M500 346.951H183.863V108.679H500V346.951ZM185.081 345.736H498.782V109.894H185.081V345.736Z"
          fill="#3F3D56"
        />
        <path
          d="M199.091 128.13C202.455 128.13 205.183 125.408 205.183 122.051C205.183 118.694 202.455 115.973 199.091 115.973C195.727 115.973 193 118.694 193 122.051C193 125.408 195.727 128.13 199.091 128.13Z"
          fill="#3F3D56"
        />
        <path
          d="M216.147 128.13C219.511 128.13 222.238 125.408 222.238 122.051C222.238 118.694 219.511 115.973 216.147 115.973C212.783 115.973 210.056 118.694 210.056 122.051C210.056 125.408 212.783 128.13 216.147 128.13Z"
          fill="#3F3D56"
        />
        <path
          d="M233.202 128.13C236.567 128.13 239.294 125.408 239.294 122.051C239.294 118.694 236.567 115.973 233.202 115.973C229.838 115.973 227.111 118.694 227.111 122.051C227.111 125.408 229.838 128.13 233.202 128.13Z"
          fill="#3F3D56"
        />
        <path
          d="M381.952 185.821H251.962V187.279H381.952V185.821Z"
          fill="#3F3D56"
        />
        <path
          d="M381.952 196.024H251.962V197.481H381.952V196.024Z"
          fill="#3F3D56"
        />
        <path
          d="M381.952 206.226H251.962V207.684H381.952V206.226Z"
          fill="#3F3D56"
        />
        <path
          d="M381.952 227.155H251.962V228.612H381.952V227.155Z"
          fill="#3F3D56"
        />
        <path
          d="M381.952 237.357H251.962V238.814H381.952V237.357Z"
          fill="#3F3D56"
        />
        <path
          d="M381.952 247.559H251.962V249.017H381.952V247.559Z"
          fill="#3F3D56"
        />
        <path
          d="M316.768 337.226C310.383 337.226 304.141 335.337 298.832 331.797C293.523 328.257 289.385 323.226 286.942 317.339C284.498 311.452 283.859 304.975 285.105 298.726C286.35 292.476 289.425 286.736 293.94 282.231C298.455 277.725 304.208 274.657 310.47 273.414C316.732 272.171 323.224 272.809 329.123 275.247C335.022 277.686 340.064 281.815 343.611 287.113C347.159 292.41 349.052 298.639 349.052 305.011C349.042 313.552 345.638 321.74 339.586 327.78C333.533 333.819 325.327 337.216 316.768 337.226ZM316.768 274.011C310.624 274.011 304.618 275.829 299.509 279.235C294.4 282.642 290.419 287.483 288.067 293.148C285.716 298.812 285.101 305.045 286.3 311.058C287.498 317.072 290.457 322.595 294.802 326.931C299.146 331.266 304.682 334.219 310.708 335.415C316.734 336.611 322.98 335.997 328.656 333.651C334.333 331.304 339.185 327.331 342.598 322.233C346.012 317.135 347.834 311.142 347.834 305.011C347.824 296.792 344.548 288.912 338.724 283.101C332.901 277.289 325.004 274.02 316.768 274.011Z"
          fill="#1641DB"
        />
        <path
          d="M332.605 303.795H317.987V289.207H315.55V303.795H300.931V306.226H315.55V320.814H317.987V306.226H332.605V303.795Z"
          fill="#1641DB"
        />
        <path
          d="M61.7332 322.942C61.7332 322.942 48.9415 336.922 47.1141 369.138C45.2867 401.353 44.6776 405 44.6776 405H90.3621L98.8899 383.118L108.636 405H151.884C151.884 405 156.757 338.138 151.884 336.314C147.011 334.491 61.7332 322.942 61.7332 322.942Z"
          fill="#2F2E41"
        />
        <path
          d="M122.95 158.521C135.061 158.521 144.879 148.724 144.879 136.639C144.879 124.554 135.061 114.757 122.95 114.757C110.84 114.757 101.022 124.554 101.022 136.639C101.022 148.724 110.84 158.521 122.95 158.521Z"
          fill="#FFB8B8"
        />
        <path
          d="M103.154 138.159L98.8899 177.061L128.737 180.708C128.737 180.708 129.955 146.669 130.564 145.453C131.174 144.237 103.154 138.159 103.154 138.159Z"
          fill="#FFB8B8"
        />
        <path
          d="M187.822 220.825L200.614 245.746C200.614 245.746 217.67 270.668 221.934 291.334C226.197 312.001 230.461 321.726 230.461 321.726C230.461 321.726 252.39 353.334 240.207 358.804C228.025 364.275 217.67 323.55 217.67 323.55L184.168 262.766L164.676 231.158L187.822 220.825Z"
          fill="#FFB8B8"
        />
        <path
          d="M66.6062 226.903L62.3423 245.138L22.1399 298.02C22.1399 298.02 -10.1438 322.334 3.25696 327.804C16.6577 333.275 33.1042 305.922 33.1042 305.922L83.0526 262.158L80.6161 226.903H66.6062Z"
          fill="#FFB8B8"
        />
        <path
          d="M97.6717 169.159C97.6717 169.159 115.761 176.727 129.254 167.472C129.254 167.472 162.239 172.806 164.066 174.629C165.894 176.453 162.239 231.158 162.239 231.158L154.93 262.158C154.93 262.158 165.285 309.569 150.057 321.726L153.102 338.138C153.102 338.138 85.4891 343.001 58.6875 326.589L63.5605 310.177L76.3522 258.511L68.4336 186.786C68.4336 186.786 74.5248 170.375 97.6717 169.159Z"
          fill="#D0CDE1"
        />
        <path
          d="M148.534 177.364L164.371 174.933C164.371 174.933 172.29 176.149 179.599 191.345C186.909 206.541 194.827 226.599 194.827 226.599L167.417 238.756L144.879 203.501L148.534 177.364Z"
          fill="#D0CDE1"
        />
        <path
          d="M72.0884 183.747L68.4336 186.786C68.4336 186.786 63.5606 204.413 63.5606 216.57V232.982L85.4891 235.413L72.0884 183.747Z"
          fill="#D0CDE1"
        />
        <path
          d="M101.028 142.578C101.028 142.578 98.0473 139.366 98.349 135.345C98.5478 133.057 98.4012 130.753 97.914 128.509C97.914 128.509 98.847 121.928 100.225 118.113C101.602 114.298 100.441 112.97 106.362 110.888C112.284 108.805 108.514 101.274 125.972 107.277C126.636 107.946 127.484 108.404 128.409 108.592C129.333 108.781 130.293 108.692 131.167 108.337C134.39 107.159 136.332 111.578 136.332 111.578C136.332 111.578 138.343 109.932 139.471 111.643C140.599 113.355 146.055 109.454 148.056 121.904C150.057 134.353 143.188 145.455 143.188 145.455C143.188 145.455 142.224 125.533 130.886 125.172C119.548 124.811 106.726 120.525 105.136 128.914C103.546 137.304 101.028 142.578 101.028 142.578Z"
          fill="#2F2E41"
        />
        <path
          d="M233.812 281.913H4.77983V363.971H233.812V281.913Z"
          fill="#3F3D56"
        />
        <path
          d="M86.2964 348.331C86.2713 348.336 86.2462 348.34 86.221 348.345C85.6198 348.455 85.014 348.551 84.3988 348.617C84.1916 348.641 83.9785 348.66 83.7714 348.678C83.5582 348.696 83.3511 348.714 83.1379 348.726C82.7907 348.751 82.4374 348.763 82.0841 348.769C81.9318 348.775 81.7734 348.775 81.6211 348.775H81.5298C81.3409 348.775 81.1521 348.775 80.9633 348.769C80.7988 348.763 80.6344 348.757 80.476 348.751C80.3846 348.751 80.2932 348.745 80.208 348.739C76.3459 348.531 72.5813 347.452 69.1973 345.583C65.8133 343.714 62.898 341.103 60.6707 337.948C58.4433 334.793 56.9619 331.175 56.3378 327.366C55.7136 323.557 55.9631 319.656 57.0673 315.957C58.1716 312.258 60.1019 308.857 62.7131 306.01C65.3243 303.163 68.5485 300.944 72.1431 299.519C75.7377 298.095 79.6092 297.502 83.4665 297.786C87.3237 298.069 91.0663 299.222 94.4128 301.157C90.5328 303.4 87.3119 306.622 85.0727 310.498C82.8336 314.375 81.655 318.771 81.655 323.246C81.655 327.72 82.8336 332.116 85.0727 335.993C87.3119 339.87 90.5328 343.092 94.4128 345.335C91.8985 346.788 89.1534 347.801 86.2964 348.331Z"
          fill="#1641DB"
        />
        <path
          d="M124.778 343.226C125.755 344.005 126.789 344.71 127.872 345.335C123.987 347.588 119.574 348.775 115.081 348.775H114.752C110.838 348.73 106.987 347.79 103.495 346.027C100.003 344.264 96.9631 341.725 94.6088 338.606C92.2544 335.486 90.6486 331.869 89.9149 328.033C89.1813 324.197 89.3393 320.244 90.3768 316.478C91.4144 312.713 93.3038 309.235 95.8997 306.313C98.4956 303.39 101.729 301.101 105.351 299.621C108.972 298.141 112.886 297.51 116.79 297.776C120.695 298.043 124.486 299.199 127.872 301.157C124.258 303.234 121.209 306.162 118.991 309.686C116.774 313.21 115.454 317.223 115.149 321.373C114.844 325.523 115.562 329.685 117.24 333.494C118.919 337.303 121.507 340.645 124.778 343.226Z"
          fill="#1641DB"
        />
        <path
          d="M174.117 323.246C174.117 328.295 172.617 333.231 169.806 337.429C166.994 341.628 162.999 344.9 158.324 346.832C153.649 348.764 148.505 349.27 143.543 348.285C138.58 347.3 134.021 344.868 130.444 341.298C126.866 337.728 124.429 333.179 123.442 328.227C122.455 323.274 122.961 318.141 124.898 313.476C126.834 308.812 130.113 304.824 134.32 302.019C138.528 299.214 143.474 297.717 148.534 297.717C151.893 297.716 155.22 298.377 158.324 299.659C161.428 300.942 164.249 302.823 166.625 305.193C169 307.564 170.885 310.379 172.17 313.476C173.456 316.574 174.117 319.893 174.117 323.246Z"
          fill="#1641DB"
        />
        <path
          d="M158.889 343.304C158.89 344.46 158.774 345.613 158.542 346.745C155.376 348.084 151.972 348.775 148.534 348.775C145.095 348.775 141.692 348.084 138.526 346.745C138.294 345.613 138.178 344.46 138.179 343.304C138.179 337.299 141.243 332.223 145.464 330.528C143.778 329.82 142.39 328.553 141.535 326.94C140.68 325.328 140.41 323.47 140.772 321.681C141.133 319.893 142.104 318.284 143.519 317.129C144.933 315.973 146.705 315.342 148.534 315.342C150.362 315.342 152.134 315.973 153.549 317.129C154.964 318.284 155.935 319.893 156.296 321.681C156.658 323.47 156.388 325.328 155.533 326.94C154.677 328.553 153.289 329.82 151.604 330.528C155.825 332.223 158.889 337.299 158.889 343.304Z"
          fill="white"
        />
        <path
          d="M124.778 343.225V343.304C124.692 344.737 124.489 346.16 124.169 347.559C121.19 348.824 118.181 348.732 114.752 348.775H114.423C110.984 348.779 107.579 348.088 104.415 346.745C104.39 346.641 104.372 346.535 104.36 346.429C104.162 345.399 104.064 344.353 104.067 343.304C104.067 337.299 107.131 332.223 111.353 330.528C109.667 329.82 108.279 328.553 107.424 326.941C106.569 325.328 106.299 323.47 106.66 321.682C107.022 319.894 107.993 318.285 109.408 317.13C110.822 315.975 112.594 315.344 114.423 315.344C115.042 315.342 115.66 315.416 116.262 315.563C114.706 320.491 114.686 325.776 116.207 330.716C117.728 335.656 120.717 340.018 124.778 343.225H124.778Z"
          fill="white"
        />
        <path
          d="M91.8727 343.657C91.8592 344.694 91.7471 345.728 91.5377 346.745C89.2617 347.711 86.8568 348.342 84.3988 348.617C84.1916 348.641 83.9785 348.659 83.7713 348.678C83.5582 348.696 83.351 348.714 83.1379 348.726C82.7907 348.751 82.4374 348.763 82.0841 348.769C82.011 348.775 81.9318 348.775 81.8587 348.775H81.5298C81.3409 348.775 81.1521 348.775 80.9633 348.769C80.7988 348.763 80.6344 348.757 80.476 348.751C80.3846 348.751 80.2932 348.744 80.208 348.738C77.2173 348.591 74.2765 347.916 71.5218 346.745C71.5168 346.731 71.5147 346.717 71.5157 346.702C71.4931 346.612 71.4768 346.521 71.467 346.429C71.2694 345.399 71.1715 344.353 71.1746 343.304C71.1746 337.299 74.2385 332.223 78.4598 330.528C76.7745 329.82 75.3864 328.553 74.5311 326.941C73.6758 325.328 73.406 323.47 73.7676 321.682C74.1292 319.894 75.0998 318.285 76.5147 317.13C77.9296 315.975 79.7015 315.344 81.5298 315.344C81.9686 315.343 82.4068 315.38 82.8394 315.453C81.2168 320.508 81.215 325.942 82.8343 330.997C84.4535 336.053 87.6132 340.478 91.8727 343.657H91.8727Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};

export default NoCustomersFigma;
