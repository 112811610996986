import React from "react";
import useStyles from "../../components/ConfigStyles";
import { Button } from "@material-ui/core";

const WebhookDetails = ({ formData, handleCopyToClipboard }) => {
  const classes = useStyles();
  return (
    <div className={classes.detailsContainer}>
      <p className={classes.infoText}>
        Connect with iThink Customer supporrt for setting up Webhook with the
        following details.
      </p>
      <div>
        <div className={classes.copyLabel}>Webhook URL</div>
        <div className={classes.detailField}>
          <div className={classes.promotionTeaser}>
            {formData.webhook_url.value}
          </div>
          <Button
            variant="contained"
            color="secondary"
            className={classes.copyButton}
            onClick={handleCopyToClipboard(formData.webhook_url.value)}
          >
            Copy
          </Button>
        </div>
        <p className={classes.helpText}>
          Provide above Webhook URL to iThink Team.
        </p>
      </div>
      <div>
        <div className={classes.copyLabel}>X API KEY</div>
        <div className={classes.detailField}>
          <div className={classes.promotionTeaser}>
            {formData.x_api_key.value}
          </div>
          <Button
            variant="contained"
            color="secondary"
            className={classes.copyButton}
            onClick={handleCopyToClipboard(formData.x_api_key.value)}
          >
            Copy
          </Button>
        </div>
        <p className={classes.helpText}>
          Share above API Key for Webhook URL with the iThink Team.
        </p>
      </div>
    </div>
  );
};

export default WebhookDetails;
