import React from "react";

const MoveToBottomIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g mask="url(#mask0_19059_8576)">
        <path
          d="M10.8337 2.5L10.8337 11L13.0003 8.83333L14.167 10L10.0003 14.1667L5.83366 10L7.00033 8.83333L9.16699 11L9.16699 2.5L10.8337 2.5ZM16.667 15.8333V17.5H3.33366V15.8333L16.667 15.8333Z"
          fill="#1641DB"
        />
      </g>
    </svg>
  );
};

export default MoveToBottomIcon;
