import React from "react";
import useStyles from "../../common/styles";
import { cdnUrl } from "../../../../utils/cdnUtils";

const INDIAMART_ICON_URL =
  "https://storage.googleapis.com/shy-pub/_static/app-img/india-mart.png";

const InfoContainer = () => {
  const classes = useStyles();
  return (
    <div className={classes.infoContainer}>
      <div>
        <img
          alt="Indiamart"
          src={cdnUrl(INDIAMART_ICON_URL)}
          className={classes.imgctn}
        />
      </div>
      <div>
        <p className={classes.title}>Indiamart</p>
        <p className={classes.subtitle}>
          Connect your Shoopy Account with the Indiamart Marketplace. Shoopy
          will publish your product catalog and make it available to customers
          to purchase and grow your business
        </p>
      </div>
    </div>
  );
};

export default InfoContainer;
