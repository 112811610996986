import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import useStyles from "../styles";
import {
  refetchInvoice,
  startInvoiceLoader,
  stopInvoiceLoader,
} from "../../../redux/invoices/invoiceActions";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import RecordPaymentModal from "../../../components/invoiceorder/RecordPaymentModal";
import { getPdfLink, fetchInvoiceById } from "../../../utils/invoiceUtil";
import { downloadDocument, printDocument } from "../../../utils/documentUtil";
import { getPaymentDetails } from "../../../utils/orderUtil";
import { getDefaultStore } from "../../../utils/storeUtil";
import PaymentAccordion from "../../payments/components/PaymentAccordion";
import { updateCalculation } from "../../../utils/invoiceHelper";
import { transparentButton } from "../../../styles/common";
import BaseView from "../../../components/invoiceorder/BaseView";
import { ViewBaseCTAs } from "../../../components/invoiceorder/ViewBaseCTAs";
import { fetchBillSettings } from "../../../redux/billSettings/billSettingsActions";
import HeaderBreadcrumbs from "../../../components/common/HeaderBreadcrumbs";
import PageLoader from "../../../components/Layout/PageLoader";
import { isCustomDomain } from "../../../utils/utils";

const InvoicePage = () => {
  const params = useParams();
  const history = useHistory();
  const classes = useStyles();
  const store = useSelector((state) => state.store.store);
  const billSettings = useSelector((state) => state.billSettings.settings);
  const invoicesMap = useSelector((state) => state.invoices.invoicesMap);
  const loading = useSelector((state) => state.invoices.loader);
  const currInvoice = invoicesMap[params.invoiceid];
  const [record, setRecord] = useState(currInvoice);
  const [loader, setLoader] = useState(true);
  const [openRecordPayment, setOpenRecordPayment] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState({
    progress: true,
    payments: [],
  });
  const [dueAmount, setDueAmount] = useState(0);
  const { payments } = paymentDetails;
  const dispatch = useDispatch();
  const [reloadPayment, setReloadPayment] = useState(0);
  const pathPrefix = isCustomDomain()
    ? `/admin/${params.storeId}`
    : `/${params.storeId}`;

  useEffect(() => {
    if (billSettings.id === null) {
      dispatch(fetchBillSettings());
    }
    // eslint-disable-next-line
  }, [store.id]);

  useEffect(() => {
    if (!record) {
      fetchInvoiceById(params.invoiceid).then((rec) => {
        if (rec) {
          let calcRec = updateCalculation(
            { ...rec },
            true,
            rec.final_sale_price - rec.due_amount
          );
          getPaymentDetails(rec.id).then((data) => {
            setRecord(calcRec);
            setLoader(false);
            setPaymentDetails({ progress: false, payments: data });
            setDueAmount(getDueAmount(calcRec, data));
          });
        }
      });
    } else {
      getPaymentDetails(record.id).then((data) => {
        let calcRec = updateCalculation(
          { ...record },
          true,
          record.final_sale_price - record.due_amount
        );
        setRecord(calcRec);
        setLoader(false);
        setPaymentDetails({ progress: false, payments: data });
        setDueAmount(getDueAmount(calcRec, data));
      });
    }
    // eslint-disable-next-line
  }, [params.invoiceid]);

  useEffect(() => {
    if (record && reloadPayment > 0) {
      getPaymentDetails(record.id).then((data) => {
        setPaymentDetails({ progress: false, payments: data });
        setDueAmount(getDueAmount(record, data));
      });
    }
  }, [record, reloadPayment]);

  const getDueAmount = (invoice, pmts) => {
    if (pmts.length === 0) {
      return invoice.due_amount;
    }
    const totalPaid = pmts.reduce((total, payment) => {
      return total + payment.amount;
    }, 0);
    let salePrice = Math.round(invoice.final_sale_price * 100) / 100;
    let dueAmt = salePrice - totalPaid;
    return Math.round(dueAmt * 100) / 100;
  };

  const viewPdf = () => {
    dispatch(startInvoiceLoader());
    getPdfLink(record.id).then((link) => {
      window.open(link);
      dispatch(stopInvoiceLoader());
    });
  };

  const onPaymentModalClose = () => {
    setOpenRecordPayment(false);
  };

  const captureInvoicePayment = async (payment) => {
    let invoiceId = record.id;
    let accessToken = localStorage.getItem("access_token");
    let store = getDefaultStore();
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/org/store/${store.store_id}/order/${invoiceId}/payments`,
        payment,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data.payload;
    } catch (err) {
      console.log("Failure while capturing payment", err);
    }
  };

  const onRecordPayment = async (payment) => {
    dispatch(startInvoiceLoader());
    let pmtReq = { ...payment, order_id: record.id };
    await captureInvoicePayment(pmtReq);
    //TODO handle error if payment capture failed
    dispatch(refetchInvoice(record.id));
    setReloadPayment(reloadPayment + 1);
    setOpenRecordPayment(false);
  };

  const downloadInvoice = async () => {
    dispatch(startInvoiceLoader());
    await downloadDocument(
      store,
      "invoice",
      record.id,
      record.invoice_id + ".pdf"
    );
    dispatch(stopInvoiceLoader());
  };

  const printInvoice = async () => {
    dispatch(startInvoiceLoader());
    await printDocument(store, "invoice", record.id);
    dispatch(stopInvoiceLoader());
  };

  const onEdit = () => {
    if (record.type === "INVOICE") {
      history.push(
        `${pathPrefix}/invoices/${encodeURIComponent(record.invoice_id)}/edit`
      );
    } else {
      history.push(
        `${pathPrefix}/orders/${encodeURIComponent(record.display_id)}/edit`
      );
    }
  };

  /*
    <Helmet>
      <title>{record.invoice_id} - Invoice</title>
      <meta charSet="utf-8" />
    </Helmet>
  */
  return (
    <div>
      <Grid container spacing={2} className={classes.root}>
        {loading && (
          <div
            style={{
              position: "fixed",
              left: "50%",
              top: "50%",
              zIndex: "900",
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        )}
        <Grid item xs={12}>
          <HeaderBreadcrumbs
            list={[
              {
                label: "Invoices",
                link: "/invoices",
              },
              {
                label: record?.invoice_id,
              },
            ]}
          />
          {!loader ? (
            <div
              style={{
                width: "100%",
              }}
            >
              <Grid
                container
                style={{
                  position: "relative",
                  width: "100%",
                  marginTop: 24,
                }}
              >
                <BaseView
                  record={record}
                  store={store}
                  dateLabel="Invoice Date"
                  enableDescount={billSettings.enable_discount}
                  enableShipping={billSettings.enable_shipping_charge}
                  enablePackaging={billSettings.enable_packaging_charge}
                  enableRoundOff={billSettings.enable_round_off}
                />
                <Grid item xs={3} style={{ paddingLeft: 24 }}>
                  <ViewBaseCTAs
                    onDownload={downloadInvoice}
                    onPrint={printInvoice}
                    viewPdf={viewPdf}
                    onEdit={onEdit}
                  />
                  <Typography
                    style={{
                      fontWeight: 600,
                      color: "#1A1A1A",
                      paddingBottom: 12,
                      marginTop: 48,
                      borderBottom: "1px solid #E5E5E5",
                      fontSize: 16,
                    }}
                  >
                    Payment Details
                  </Typography>
                  {paymentDetails.progress && (
                    <div style={{ textAlign: "center", marginTop: 20 }}>
                      <CircularProgress color="secondary" />
                    </div>
                  )}
                  {!paymentDetails.progress && (
                    <div>
                      {payments.length > 0 &&
                        payments.map((pmt, i) => {
                          return <PaymentAccordion key={i} payment={pmt} />;
                        })}
                      {payments.length === 0 && (
                        <Typography
                          style={{
                            fontWeight: 400,
                            color: "#999999",
                            fontSize: 12,
                            marginTop: 12,
                          }}
                        >
                          No Payment Recorded
                        </Typography>
                      )}
                      <Grid
                        style={{
                          width: "100%",
                          marginTop: -12,
                        }}
                        className={classes.flexBox}
                      >
                        <Typography
                          className={classes.subHeading}
                          style={{ paddingTop: 18 }}
                        >
                          Due
                        </Typography>
                        <Typography
                          className={classes.subHeading}
                          style={{ paddingTop: 18 }}
                        >
                          ₹ {parseFloat(dueAmount).toFixed(2)}
                        </Typography>
                      </Grid>
                      {dueAmount > 0 && (
                        <Button
                          className={transparentButton}
                          onClick={() => setOpenRecordPayment(true)}
                        >
                          + RECORD PAYMENT
                        </Button>
                      )}
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>
          ) : (
            <PageLoader />
          )}
        </Grid>
      </Grid>
      {openRecordPayment && (
        <RecordPaymentModal
          open={openRecordPayment}
          amount={dueAmount}
          onClose={onPaymentModalClose}
          onRecord={onRecordPayment}
        />
      )}
    </div>
  );
};

export default InvoicePage;
