import { IconButton, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import AddIcon from "../../../icons/AddIcon";
import VideoIcon from "../../../icons/VideoIcon";
import Controls from "../../../components/controls/Controls";
import { useDispatch, useSelector } from "react-redux";
import {
  addAdditionalField,
  deleteAdditionalField,
  fetchAdditionalFields,
  rearrangeAdditionalField,
} from "../../../redux/additionalFields/additionalFieldsActions";
import AddAdditionalField from "./AddAdditionalField";
import { fetchCategories } from "../../../redux/category/categoryActions";
import { getStoreIdFromUrl } from "../../../utils/storeUtil";
import SolidButton from "../../../components/buttons/SolidButton";
import { fetchApps } from "../../../redux/apps/appActions";
import ProductFields from "./ProductFields";
import { ClearOutlined } from "@material-ui/icons";
import DeleteModal from "../../../components/common/DeleteModal";
import {
  nativeHelperText,
  nonGmcNativeFieldsConfig,
  gmcNativeFieldsConfig,
} from "./ProductHelper";
import { reorder } from "../../../components/common/CommonUtils";

const useStyles = makeStyles(() => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  flexCenterBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  heading: {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 10,
    marginTop: 20,
  },

  pointer: {
    cursor: "pointer",
  },
  nativeFields: {
    borderBottom: "1px solid #E6E6E6",
    padding: "10px 0px 3.5px 0px",
  },
}));

const AdditionalFields = ({ setOpenFields, setToast, setMessage }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const storeId = getStoreIdFromUrl();

  const apps = useSelector((state) => state.apps.apps);
  const { categories } = useSelector((state) => state.categories);
  const { fields, variantFields, checkoutFields, attributeFields } =
    useSelector((state) => state.additionalFields);
  const { nativeFields, nonNativeFields } = variantFields;

  const [record, setRecord] = useState(null);
  const [openCustomField, setOpenCustomField] = useState(false);
  const [deleteField, setDeleteField] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (!apps.length) {
      dispatch(fetchApps());
    }
    // eslint-disable-next-line
  }, [apps.length]);

  useEffect(() => {
    if (categories.length === 0) {
      dispatch(fetchCategories());
    }

    if (fields.length === 0) {
      dispatch(fetchAdditionalFields());
    }
    // eslint-disable-next-line
  }, [storeId]);

  const gmc = useMemo(() => {
    let shApps = apps.filter((app) => app.slug === "gmc");
    let shApp = {};
    if (shApps.length > 0) {
      shApp = shApps[0];
    }
    return shApp;
    // eslint-disable-next-line
  }, [apps.length]);

  const onSwitchChange = (field) => async (event) => {
    const { name, label } = field;
    const { checked } = event.target;

    const action = checked ? addAdditionalField : deleteAdditionalField;
    const payload = checked
      ? { slug: name, name: label, native: true }
      : variantFields.nativeFields[name]?.id;

    if (!payload) return;
    const response = await dispatch(action(payload));
    const success = checked ? response?.data : response?.status;

    if (success) {
      setToast(true);
      setMessage(`Field ${checked ? "added" : "removed"}  successfully.`);
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const itemsList =
      result.type === "CHECKOUT"
        ? checkoutFields
        : result.type === "VARIANT"
        ? variantFields.nonNativeFields
        : attributeFields;

    const { items, change } = reorder(
      itemsList,
      result.source.index,
      result.destination.index,
      "slug"
    );

    dispatch(
      rearrangeAdditionalField(itemsList, items, change, result.type)
    ).then((response) => {
      if (response?.data) {
        setToast(true);
        setMessage("Field position updated successfully.");
      }
    });
  };

  const onAddField = () => {
    setRecord(null);
    setOpenCustomField(true);
  };

  const getGroupFields = (group) => {
    return group === "CHECKOUT"
      ? checkoutFields
      : group === "ATTRIBUTE"
      ? attributeFields
      : nonNativeFields;
  };

  const onEditField = (event) => {
    const group = event.currentTarget.getAttribute("name");
    const slug = event.currentTarget.getAttribute("id");
    const field = getGroupFields(group).find((field) => field.slug === slug);
    if (!field) return;
    setRecord(field);
    setOpenCustomField(true);
  };

  const onDeleteField = (event) => {
    const group = event.currentTarget.getAttribute("name");
    const slug = event.currentTarget.getAttribute("id");
    const field = getGroupFields(group).find((field) => field.slug === slug);
    if (!field) return;
    setDeleteField(field);
  };

  const onDeleteCancel = () => {
    setDeleteField(null);
  };

  const onConfirmDelete = async () => {
    setIsDeleting(true);
    const response = await dispatch(deleteAdditionalField(deleteField.id));
    if (response?.status) {
      setToast(true);
      setMessage("Field removed successfully.");
    }
    setIsDeleting(false);
    setDeleteField(null);
  };

  if (openCustomField)
    return (
      <AddAdditionalField
        record={record}
        setToast={setToast}
        setMessage={setMessage}
        setOpenCustomField={setOpenCustomField}
      />
    );

  return (
    <div className={classes.container}>
      <div>
        <div style={{ borderBottom: "1px solid #E1E1E1" }}>
          <div className={classes.flexCenterBetween}>
            <div style={{ fontSize: 20, fontWeight: 700 }}>Product Fields</div>
            <IconButton
              aria-label="Close"
              aria-haspopup="true"
              color="inherit"
              onClick={() => setOpenFields(false)}
            >
              <ClearOutlined fontSize="medium" />
            </IconButton>
          </div>
          <p style={{ fontWeight: 400, fontSize: 12, color: "#1A1A1A" }}>
            These are the optional fields which are available for you. You can
            also add more fields as per your product needs
          </p>
        </div>
        <div>
          <div className={classes.heading} style={{ color: "#1A1A1A" }}>
            ADDITIONAL PRODUCT FIELD
          </div>

          {[
            ...nonGmcNativeFieldsConfig,
            ...(gmc && gmc.installed ? gmcNativeFieldsConfig : []),
          ].map((field, index) => (
            <div key={index} className={classes.nativeFields}>
              <Controls.Switch
                name={field.name}
                label={field.label}
                value={!!nativeFields[field.name]}
                onChange={onSwitchChange(field)}
                helperText={nativeHelperText[field.name]}
              />
            </div>
          ))}

          {[nonNativeFields, attributeFields, checkoutFields].map(
            (fields, index) =>
              fields.length > 0 ? (
                <React.Fragment key={index}>
                  <ProductFields
                    fields={fields}
                    onDragEnd={onDragEnd}
                    onEditField={onEditField}
                    onDeleteField={onDeleteField}
                  />
                </React.Fragment>
              ) : null
          )}
        </div>
      </div>

      <div className={classes.flexCenterBetween} style={{ marginTop: 20 }}>
        <a
          href="https://www.youtube.com/watch?v=PcqpZMkGBFA"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          <div
            style={{
              gap: 5,
              cursor: "pointer",
            }}
            className={classes.flexCenter}
          >
            <VideoIcon />
            <Typography
              color="secondary"
              className={classes.heading}
              style={{ marginTop: 10 }}
            >
              Check out Help Video
            </Typography>
          </div>
        </a>

        <SolidButton onClick={onAddField} endIcon={<AddIcon />}>
          Add New Field
        </SolidButton>
      </div>
      {deleteField && (
        <DeleteModal
          item={deleteField}
          onCancel={onDeleteCancel}
          onConfirmDelete={onConfirmDelete}
          isDeleting={isDeleting}
        />
      )}
    </div>
  );
};

export default AdditionalFields;
