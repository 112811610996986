import React, { useState, useEffect } from "react";
import { Divider, Grid, Typography } from "@material-ui/core";
import useStyles from "../styles";
import {
  rowFlexAlignCenter,
  rowFlexJustifyBetween,
} from "../../../styles/common";
import { useSelector } from "react-redux";
import TextButton from "../../../components/buttons/TextButton";
import { useHistory, useParams } from "react-router-dom";
import { StoreIcon } from "../../../icons";
import Whatsapp from "../../../icons/Whatsapp";
import FacebookIcon from "../../../icons/FacebookIcon";
import XIcon from "../../../icons/XIcon";
import { getStoreUrl } from "../../../utils/storeUtil";
import OutlinedButton from "../../../components/buttons/OutlinedButton";
import { copyToClipboard } from "../../../utils/utils";
import Toast from "../../../components/Layout/Toast";
import { Skeleton } from "@material-ui/lab";
import { findDomains } from "../../../utils/domainUtils";

const DashboardStoreLink = () => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();
  const store = useSelector((state) => state.store.store);
  const storeUrl = getStoreUrl(store);
  const [successOpen, setSuccessOpen] = useState(false);
  const [failOpen, setFailOpen] = useState(false);
  const [domainName, setDomainName] = useState(null);

  useEffect(() => {
    if (
      store.name &&
      store.plan &&
      (store.plan === "FREE" || store.plan === "REFER") &&
      /^[A-Za-z0-9? ,_-]+$/gm.test(store.name)
    ) {
      findDomains(store.name).then((domains) => {
        if (domains.length !== 0) {
          setDomainName(domains[0].domainName);
        }
      });
    }
  }, [store]);

  const objectToGetParams = (object) => {
    const params = Object.entries(object)
      .filter(([, value]) => value !== undefined && value !== null)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
      );

    return params.length > 0 ? `?${params.join("&")}` : "";
  };

  const getPromotionText = (store, storeUrl, channel) => {
    return `Dear Customers, Now shop from your favorite store ${
      store.name
    } right from your mobile. Get your products delivered at home. Express Contact-less delivery, Online payment and more. To place an order visit : ${
      channel === "twitter" ? "" : storeUrl
    }`;
  };

  return (
    <div>
      <div className={classes.linkCard}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={2}>
            <Typography
              style={{ fontWeight: 600, fontSize: "16px", marginTop: "5px" }}
            >
              Your Store Link
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={7}>
            <div className={classes.link}>
              {!store.domain ? (
                <Skeleton animation="wave" width={"100%"} height={20} />
              ) : (
                <>
                  <TextButton
                    startIcon={<StoreIcon stroke="#1842DB" />}
                    children={storeUrl}
                    onClick={() => window.open(storeUrl)}
                  />
                  <TextButton
                    style={{
                      color: "#1A1A1A",
                      textDecoration: "none",
                      fontWeight: 400,
                      fontSize: "12px",
                    }}
                    onClick={() => {
                      let result = copyToClipboard(storeUrl);
                      if (!result) {
                        setFailOpen(true);
                      } else {
                        setSuccessOpen(true);
                      }
                    }}
                    children="COPY"
                  />
                </>
              )}
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={3}
            className={rowFlexAlignCenter}
          >
            {!store.domain ? (
              <>
                <Skeleton
                  variant="circle"
                  width={30}
                  height={30}
                  animation="wave"
                  style={{ margin: "0px 12px" }}
                />
                <Skeleton
                  variant="circle"
                  width={30}
                  height={30}
                  animation="wave"
                  style={{ margin: "0px 12px" }}
                />
                <Skeleton
                  variant="circle"
                  width={30}
                  height={30}
                  style={{ margin: "0px 12px" }}
                  animation="wave"
                />
              </>
            ) : (
              <>
                <Typography
                  className={classes.shareVia}
                  style={{ marginRight: 20 }}
                >
                  Share Via -{" "}
                </Typography>

                <div
                  className={classes.sociallink}
                  onClick={() => {
                    window.open(
                      "https://web.whatsapp.com/send" +
                        objectToGetParams({
                          text: getPromotionText(store, storeUrl, "whatsapp"),
                        })
                    );
                  }}
                >
                  <Whatsapp />
                </div>
                <div
                  className={classes.sociallink}
                  onClick={() => {
                    window.open(
                      "https://www.facebook.com/sharer/sharer.php" +
                        objectToGetParams({
                          u: storeUrl,
                          quote: getPromotionText(store, storeUrl, "facebook"),
                        })
                    );
                  }}
                  style={{ margin: "0px 6px", cursor: "pointer" }}
                >
                  <FacebookIcon />
                </div>
                <div
                  className={classes.sociallink}
                  onClick={() => {
                    window.open(
                      "https://www.twitter.com/share/" +
                        objectToGetParams({
                          url: storeUrl,
                          text: getPromotionText(store, storeUrl, "twitter"),
                        })
                    );
                  }}
                  style={{ margin: "0px 6px", cursor: "pointer" }}
                >
                  <XIcon size={20} />
                </div>
              </>
            )}
          </Grid>
        </Grid>
        {store && store.plan && store.plan === "FREE" && (
          <>
            <Divider style={{ margin: "16px 0px" }} />
            {domainName ? (
              <div className={rowFlexJustifyBetween}>
                <Typography style={{ fontSize: 14, lineHeight: "24px" }}>
                  Your domain{" "}
                  <span style={{ fontWeight: 600 }}>{domainName}</span> is
                  available
                </Typography>
                <OutlinedButton
                  style={{ fontSize: 12, minWidth: 76, height: 36, padding: 8 }}
                  children="Get Now"
                  onClick={() =>
                    history.push(
                      `/${params.storeId}/billing/plans?key=${store.name}`
                    )
                  }
                />
              </div>
            ) : (
              <div className={rowFlexJustifyBetween}>
                <Typography style={{ fontSize: 14, lineHeight: "24px" }}>
                  Upgrade to get personalised domain and features to scale up
                  your business
                </Typography>
                <OutlinedButton
                  style={{ fontSize: 12, minWidth: 76, height: 36, padding: 8 }}
                  children="Upgrade"
                  onClick={() =>
                    history.push(`/${params.storeId}/billing/plans`)
                  }
                />
              </div>
            )}
          </>
        )}
      </div>
      <Toast
        open={successOpen}
        close={() => setSuccessOpen(false)}
        message="Copied to clipboard"
        severity="success"
      />
      <Toast
        open={failOpen}
        close={() => setFailOpen(false)}
        message="Could not copy text"
        severity="error"
      />
    </div>
  );
};

export default DashboardStoreLink;
