import { STORE_CUSTOMERS_PER_PAGE } from "../../utils/utils";
import {
  FETCH_STORE_CUSTOMERS,
  STORE_CUSTOMER_PROGRESS,
  SET_STORE_CUSTOMER_ERROR,
  SET_STORE_CUSTOMER_LOADER,
  ADD_STORE_CUSTOMER,
  EDIT_STORE_CUSTOMER,
  CLEAR_STORE_CUSTOMER,
  CLEAR_COMPLETE_CUSTOMERS,
  SET_STORE_CUSTOMERS_PER_PAGE,
  SET_STORE_CUSTOMER_BALANCE,
  SET_STORE_CUSTOMERS_INPUT_KEYWORD,
  SET_STORE_CUSTOMERS_SEARCH_KEYWORD,
  SET_STORE_CUSTOMERS_CURRENT_PAGE,
  SET_STORE_CUSTOMERS_MAX_PAGE_SO_FAR,
  STORE_CUSTOMER_BALANCE_SELECTOR,
  SET_SUCCESS_TOAST,
  SET_STORE_CUSTOMER_WALLET_POINTS,
  DELETE_STORE_CUSTOMER,
} from "./storeCustomerTypes";
import axios from "axios";
import { getDefaultStore, getStoreIdFromUrl } from "../../utils/storeUtil";

export const fetchStoreCustomers =
  (
    page = 0,
    keyword = null,
    balFilter = "All",
    size = STORE_CUSTOMERS_PER_PAGE
  ) =>
  (dispatch) => {
    dispatch({
      type: STORE_CUSTOMER_PROGRESS,
      payload: true,
    });
    let accessToken = localStorage.getItem("access_token");
    let store = getDefaultStore();
    axios
      .get(
        `${process.env.REACT_APP_API}/api/v2/org/store/${
          store.store_id
        }/customers?page=${page}&size=${size}&sort=createdAt,desc${
          keyword ? `&query=${keyword}` : ""
        }${balFilter ? `&balance=${balFilter}` : ""}`,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: FETCH_STORE_CUSTOMERS,
          payload: response.data.payload,
          customersPerPage: size,
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_STORE_CUSTOMER_ERROR,
          payload: { error: error.message },
        });
      });
  };

export const clearStoreCustomer = () => (dispatch) => {
  dispatch({ type: CLEAR_STORE_CUSTOMER, payload: null });
};

export const addStoreCustomer = (customerData) => async (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  let data = customerData;
  // data.org_id = store.org_id;
  dispatch({
    type: SET_STORE_CUSTOMER_LOADER,
    payload: true,
  });
  return await axios
    .post(
      `${process.env.REACT_APP_API}/api/v2/org/store/${store.store_id}/customers`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return dispatch({
        type: ADD_STORE_CUSTOMER,
        payload: response.data.payload,
      });
    })
    .catch((error) => {
      dispatch({
        type: SET_STORE_CUSTOMER_ERROR,
        payload: { error: error.message },
      });
    });
};

export const editStoreCustomer = (customerData) => async (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  let data = customerData;
  dispatch({
    type: SET_STORE_CUSTOMER_LOADER,
    payload: true,
  });

  return await axios
    .put(
      `${process.env.REACT_APP_API}/api/v2/org/store/${store.store_id}/customers/${data.id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return dispatch({
        type: EDIT_STORE_CUSTOMER,
        payload: response.data.payload,
      });
    })
    .catch((error) => {
      dispatch({
        type: SET_STORE_CUSTOMER_ERROR,
        payload: { error: error.message },
      });
    });
};

export const deleteStoreCustomer = (customerId, customersPerPage) => async(dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  let storeId = getStoreIdFromUrl();
  return await axios
    .delete(`${process.env.REACT_APP_API}/api/v2/org/store/${storeId}/customers/${customerId}`, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${accessToken}`,
      },
  }).then((response) => {
    return dispatch({
      type: DELETE_STORE_CUSTOMER,
      payload: customerId,
      customersPerPage
    });
  })

};

export const setCustomerBalance = (record) => (dispatch) => {
  dispatch({
    type: SET_STORE_CUSTOMER_BALANCE,
    payload: record,
  });
};

export const setCustomerWalletPoints = (record) => (dispatch) => {
  dispatch({
    type: SET_STORE_CUSTOMER_WALLET_POINTS,
    payload: record,
  });
};

export const clearStoreCustomers = () => (dispatch) => {
  dispatch({
    type: CLEAR_COMPLETE_CUSTOMERS,
  });
};

export const startStoreCustomerLoader = () => (dispatch) => {
  dispatch({
    type: SET_STORE_CUSTOMER_LOADER,
    payload: true,
  });
};

export const stopStoreCustomerLoader = () => (dispatch) => {
  dispatch({
    type: SET_STORE_CUSTOMER_LOADER,
    payload: false,
  });
};

export const setStoreCustomersPerPage = (data) => (dispatch) => {
  dispatch({
    type: SET_STORE_CUSTOMERS_PER_PAGE,
    payload: data,
  });
};

export const setStoreCustomersCurrentPage = (data) => (dispatch) => {
  dispatch({
    type: SET_STORE_CUSTOMERS_CURRENT_PAGE,
    payload: data,
  });
};

export const setStoreCustomersMaxPageSoFar = (data) => (dispatch) => {
  dispatch({
    type: SET_STORE_CUSTOMERS_MAX_PAGE_SO_FAR,
    payload: data,
  });
};

export const setStoreCustomersInputKeyword = (data) => (dispatch) => {
  dispatch({
    type: SET_STORE_CUSTOMERS_INPUT_KEYWORD,
    payload: data,
  });
};

export const setStoreCustomersSearchKeyword = (data) => (dispatch) => {
  dispatch({
    type: SET_STORE_CUSTOMERS_SEARCH_KEYWORD,
    payload: data,
  });
};

export const setStoreCustomerBalanceSelector = (data) => (dispatch) => {
  dispatch({
    type: STORE_CUSTOMER_BALANCE_SELECTOR,
    payload: data,
  });
};

export const setSuccessToast = (data) => (dispatch) => {
  dispatch({
    type: SET_SUCCESS_TOAST,
    payload: data,
  });
};
