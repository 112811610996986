import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const FilledEditIcon = ({ size = 16, fill = "#1641DB" }) => {
  return (
    <SvgIcon viewBox="0 0 16 16" style={{ width: size, height: size }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 11.5V14H4.5L11.8733 6.62662L9.37333 4.12662L2 11.5ZM13.8067 4.69329C14.0667 4.43329 14.0667 4.01329 13.8067 3.75329L12.2467 2.19329C11.9867 1.93329 11.5667 1.93329 11.3067 2.19329L10.0867 3.41329L12.5867 5.91329L13.8067 4.69329Z"
        fill={fill}
      />
    </SvgIcon>
  );
};

export default FilledEditIcon;
