import React from "react";
import SmallDiamond from "../../../icons/SmallDiamond";
import useStyles from "./styles";
import { rowFlexJustifyBetween } from "../../../styles/common";
import ChargeDetails from "./ChargeDetails";
import { Typography } from "@material-ui/core";
import { planCustomProps } from "../../plans/constants";
import ChargeRow from "./ChargeRow";

const ChargeSummary = ({ subscription }) => {
  const classes = useStyles();
  return (
    <div className={classes.plansummary}>
      <div>
        <div className={rowFlexJustifyBetween}>
          <Typography className={classes.primary}>Charge Information</Typography>
        </div>
        <ChargeDetails
          diamond={
            <SmallDiamond {...planCustomProps[subscription.plan].diamondProps} />
          }
          title={subscription.plan_title}
          shortDescription={subscription.description}
          amount={subscription.plan_amount / 100}
        />
        <hr style={{ marginTop: 20, marginBottom: 20 }}></hr>
        <Typography className={classes.primary}>Payment</Typography>
        <ChargeRow
          label="ONLINE"
          amount={subscription.plan_amount / 100}
        />
        <hr />
      </div>
    </div>
  );
};
export default ChargeSummary;
