import React from "react";
import { Grid } from "@material-ui/core";
import { useStyles } from "./formStyles";
import Controls from "../../../../components/controls/Controls";
export default function FormSectionTwo({
  formState,
  params,
  onFormFieldChange,
}) {
  const classes = useStyles();
  return (
    <Grid container spacing={0}>
      <p className={classes.sectionHead}>DISCOUNT DETAILS</p>
      <Grid item xs={12}>
        <Controls.RadioGroup
          name="type"
          label="Discount Type"
          value={formState.type.value}
          labelClass={classes.label}
          onChange={(e) => onFormFieldChange(e.target.name, e.target.value)}
          items={[
            { id: "FLAT", title: "Flat" },
            { id: "PERCENTAGE", title: "Percentage" },
          ]}
        />
      </Grid>
      {formState.type.value === "PERCENTAGE" && (
        <Grid item xs={6} style={{ paddingRight: "20px" }}>
          <Controls.Input
            type="number"
            endAdornment={<span className={classes.rupee}>%</span>}
            helperText={formState.percentage.helpText}
            name="percentage"
            label="Discount Percentage"
            value={formState.percentage.value}
            onChange={(e) => {
              if (e.target.value >= 100) {
                e.target.value = Number(String(e.target.value).slice(0, 2));
              }
              onFormFieldChange(e.target.name, Math.floor(e.target.value));
            }}
            onFocus={() => {}}
            onBlur={() => {}}
            error={
              formState.percentage.hasError ? formState.percentage.error : ""
            }
            labelPlacement="top"
          />
        </Grid>
      )}
      <Grid
        item
        xs={6}
        style={
          formState.type.value === "PERCENTAGE"
            ? { paddingLeft: "20px" }
            : { paddingRight: "20px" }
        }
      >
        <Controls.Input
          type="number"
          helperText={
            formState.type.value === "PERCENTAGE"
              ? "Type the maximum discount amount you want to provide"
              : formState.max_discount.helpText
          }
          startAdornment={<span className={classes.rupee}>₹</span>}
          name="max_discount"
          label={
            formState.type.value === "PERCENTAGE"
              ? "Max Discount Amount"
              : "Discount Amount"
          }
          value={formState.max_discount.value}
          onChange={(e) => onFormFieldChange(e.target.name, e.target.value)}
          onFocus={() => {}}
          onBlur={() => {}}
          error={
            formState.max_discount.hasError ? formState.max_discount.error : ""
          }
          labelPlacement="top"
        />
      </Grid>      
    </Grid>
  );
}
