import React, { useEffect, useState } from "react";
import PhoneButtonIcon from "../../../icons/PhoneButtonIcon";
import useStyles from "./styles";
import axios from "axios";
import SolidButton from "../../../components/buttons/SolidButton";
import { isCustomDomain } from "../../../utils/utils";
import FullNameIcon from "../../../icons/FullNameIcon";
import Controls from "../../../components/controls/Controls";

const MOBILE_MAX_LENGTH = 10;
const MobileForm = ({ onOtpSuccess, formType, record }) => {
  const classes = useStyles();
  const [inputs, setInputs] = useState({
    mobile: { value: record.mobile, error: false },
    name: { value: record.name, error: false },
  });
  const [signupReq, setSignupReq] = useState(false);

  useEffect(() => {
    if(formType === 'SIGNUP'){
      setSignupReq(false);
    }
    let melm = null;
    if(formType === 'SIGNIN'){
      melm = document.getElementById("mobile");
    }else{
      melm = document.getElementById("name");
    }
    if(melm){
      melm.focus();
    }
  }, [formType]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    let mobile = inputs["mobile"].value;
    if (!mobile || mobile.length !== MOBILE_MAX_LENGTH) {
      setInputs({
        ...inputs,
        [event.target.name]: { value: mobile, error: true },
      });
    } else {
      if (window.location.search.includes("nootp")) {
        onOtpSuccess(mobile, "");
      } else {
        const name = inputs["name"].value;
        if (name) {
          sessionStorage.setItem("store_user_name", name);
        }
        if (formType === "SIGNIN") {
          let exists = await checkByMobile(mobile);
          if (!exists) {
            setSignupReq(true);
            return;
          }
        }
        let res = await getOTP(mobile);
        onOtpSuccess(mobile, name, res.is_new);
      }
    }
  };

  const handleInputChange = (event) => {
    event.persist();
    setInputs({
      ...inputs,
      [event.target.name]: { value: event.target.value, error: false },
    });
  };

  const checkByMobile = async (mobile) => {
    let resp = await axios.get(
      `${process.env.REACT_APP_API}/api/v1/store/user/find-by-mobile/${mobile}?key=ABCFER545343434GRTEWERT456087612333ACD`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${process.env.REACT_APP_TRUSTED_TOKEN}`,
        },
      }
    );
    return resp.data;
  };

  const getOTP = async (mobile) => {
    let customDomain = isCustomDomain();
    let hostname = window.location.hostname;
    let slug = "";
    if (customDomain) {
      let slugResp = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/store/external/${hostname}/slug`,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${process.env.REACT_APP_TRUSTED_TOKEN}`,
          },
        }
      );
      slug = slugResp.data.payload;
    }
    let response = await axios.post(
      `${process.env.REACT_APP_AUTH_API}/api/v1/auth/oauth/otp?slug=${slug}`,
      `${mobile}`,
      {
        headers: {
          "Content-Type": "text/plain",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${process.env.REACT_APP_TRUSTED_TOKEN}`,
        },
      }
    );
    return response.data;
  };

  return (
    <>
      <form className={classes.form} onSubmit={handleSubmit}>
        {formType === "SIGNUP" && (
          <Controls.Input
            id="name"
            name="name"
            label="Name"
            required={true}
            labelPlacement="top"
            value={inputs.name.value}
            textClass={classes.input}
            autoFocus
            onChange={handleInputChange}
            endAdornment={<FullNameIcon />}
            error={inputs.name.error}
            helperText={inputs.name.error ? "Please provide your name" : " "}
            onFocus={() => {
              setInputs({ ...inputs, name: { ...inputs.name, error: false } });
            }}
            maxLength={64}
          />
        )}
        <Controls.Input
          id="mobile"
          name="mobile"
          label="Mobile Number"
          required={true}
          labelPlacement="top"
          textClass={classes.input}
          inputCss={{ marginBottom: 16 }}
          value={inputs.mobile.value}
          onChange={handleInputChange}
          type="number"
          endAdornment={<PhoneButtonIcon />}
          error={inputs.mobile.error || signupReq}
          helperText={
            inputs.mobile.error && inputs.mobile.value
              ? "Please provide a correct mobile number"
              : signupReq
              ? "Mobile not registered, Please sign up!"
              : " "
          }
          onFocus={() => {
            setInputs({
              ...inputs,
              mobile: { ...inputs.mobile, error: false },
            });
            setSignupReq(false);
          }}
          maxLength={10}
        />
        <SolidButton style={{ width: "100%" }}>Request OTP</SolidButton>
      </form>
    </>
  );
};

export default MobileForm;
