import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CancelOutlinedIcon = ({
  viewBox = "0 0 16 16",
  size = 16,
  color = "#999999",
  ...props
}) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      style={{ width: size, height: size, color }}
      {...props}
    >
      <circle cx="8" cy="8" r="7.5" fill="white" stroke={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 5.205L10.795 4.5L8 7.295L5.205 4.5L4.5 5.205L7.295 8L4.5 10.795L5.205 11.5L8 8.705L10.795 11.5L11.5 10.795L8.705 8L11.5 5.205Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default CancelOutlinedIcon;
