import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CategoryIcon = ({
  viewBox = "0 0 20 20",
  stroke = "#999999",
  size = 20,
}) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      style={{ width: size, height: size, fill: stroke }}
    >
      <path
        d="M4.375 6.875C5.75571 6.875 6.875 5.75571 6.875 4.375C6.875 2.99429 5.75571 1.875 4.375 1.875C2.99429 1.875 1.875 2.99429 1.875 4.375C1.875 5.75571 2.99429 6.875 4.375 6.875Z"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.625 3.125H19.375"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.625 8.125H19.375"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.375 18.125C5.75571 18.125 6.875 17.0057 6.875 15.625C6.875 14.2443 5.75571 13.125 4.375 13.125C2.99429 13.125 1.875 14.2443 1.875 15.625C1.875 17.0057 2.99429 18.125 4.375 18.125Z"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.625 14.375H19.375"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.625 19.375H19.375"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default CategoryIcon;
