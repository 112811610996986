import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const EditPen = ({ viewBox = "0 0 15 16", stroke = "#1641DB" }) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "15px", height: "16px" }}>
      <svg
        width="15"
        height="16"
        viewBox="0 0 15 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.5 15.5H1.5C1.23478 15.5 0.98043 15.3946 0.792893 15.2071C0.605357 15.0196 0.5 14.7652 0.5 14.5V1.5C0.5 1.23478 0.605357 0.98043 0.792893 0.792893C0.98043 0.605357 1.23478 0.5 1.5 0.5H10.5C10.7652 0.5 11.0196 0.605357 11.2071 0.792893C11.3946 0.98043 11.5 1.23478 11.5 1.5V5.5"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 15L7.5 15.5L8 13L12.5 8.5L14.5 10.5L10 15Z"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default EditPen;
