import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles(() => ({
  linkIcon: { color: "#1A1A1A", fontSize: "14px", verticalAlign: "middle" },
  linkText: {
    fontSize: "14px",
    color: "#1A1A1A",
    fontWeight: 600,
    lineHeight: "20px",
  },
  backDiv: {
    marginBottom: "24px",
  },
  paper: {
    padding: "28px",
    minHeight: "84vh",
    width: "100%",
    minWidth: 370,
    margin: "auto",
    borderRadius: 6,
    boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.04)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  appTitle: {
    fontSize: "24px",
    lineHeight: "28px",
    fontWeight: "600",
  },
  filledActionBtn: {
    textTransform: "none",
    fontSize: "16px",
    fontWeight: "600",
    "&:disabled": {
      backgroundColor: "#999999",
      color: "#fff",
    },
    borderRadius: "6px",
  },
  outlinedBtn: {
    textTransform: "none",
    padding: "8px 12px",
    color: "#1641DB",
    borderColor: "#1641DB",
    fontWeight: "600",
    "&:disabled": {
      color: "#999",
      border: "1px solid #999",
    },
    borderRadius: "6px",
  },
  description: {
    fontSize: "16px",
    lineHeight: "16px",
    fontWeight: "600",
    marginBottom: "20px",
  },
  imageContainer: {
    justifyContent: "center",
    position: "static",
    paddingRight: 64,
    width: "100%",
    marginBottom: 48,
  },
  subTitle: {
    fontSize: 14,
    fontWeight: 400,
    marginTop: 6,
  },
}));
export default useStyles;
