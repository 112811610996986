import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import {
  CircularProgress,
  Container,
  Link,
  Typography,
} from "@material-ui/core";
import ChargeSummary from "./ChargeSummary";
import { getRenewalConfirmationInfo } from "../utils";
import { CheckCircleOutline } from "@material-ui/icons";
import {
  circular,
  progressbar,
  rowFlexAlignCenter,
} from "../../../styles/common";
import OutlinedButton from "../../../components/buttons/OutlinedButton";
import { ExportProdIcon } from "../../../icons";
import { downloadCustomerOrderPdf } from "../../../utils/documentUtil";

const RenewConfirmation = ({ storeId, orderId }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [downloadProgress, setDownloadProgress] = useState(false);
  const [subscription, setSubscription] = useState(null);
  useEffect(() => {
    getRenewalConfirmationInfo(storeId, orderId)
      .then((subDtls) => {
        setSubscription(subDtls);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [storeId, orderId]);

  if (loading) {
    return (
      <CircularProgress
        color="secondary"
        className={progressbar}
        classes={{ colorSecondary: circular }}
      />
    );
  }

  const onDownloadInvoice = async () => {
    setDownloadProgress(true);
    await downloadCustomerOrderPdf(orderId, "Renewal-Confirmation.pdf");
    setDownloadProgress(false);
  };

  return (
    <Container className={classes.renew}>
      <div className={classes.renewinfo}>
        <div style={{ maxWidth: "420px" }}>
          <div className={rowFlexAlignCenter}>
            <CheckCircleOutline style={{ color: "green" }} />
            <Typography
              variant="h1"
              className={classes.pageheading}
              style={{ marginLeft: 16 }}
            >
              Renew Confirmation
            </Typography>
          </div>
          <Typography className={classes.text} style={{ marginTop: 16 }}>
            Thank you for the payment. You will also get a SMS with Invoice
            Download link shortly.
          </Typography>
        </div>
        <hr style={{ marginTop: 24, marginBottom: 24 }}></hr>
        <div style={{ display: "flex", maxWidth: 388 }}>
          <div>
            <img
              src={subscription.store.logo}
              alt={subscription.store.name}
              className={classes.media}
            />
          </div>
          <div style={{ marginLeft: 8 }}>
            <Typography
              variant="h2"
              className={classes.primary}
              style={{ fontWeight: "normal" }}
            >
              {subscription.store.name}
            </Typography>
            <div style={{ marginTop: 8, marginBottom: 24 }}>
              <Link
                style={{
                  textDecoration: "none",
                }}
                color="secondary"
                href={`https://${subscription.store.domain}`}
              >
                https://{subscription.store.domain}
              </Link>
            </div>
          </div>
        </div>
        <hr style={{ marginTop: 0, marginBottom: 0 }}></hr>
      </div>
      <ChargeSummary subscription={subscription} />
      <div className={classes.paymentbutton}>
        <OutlinedButton
          onClick={onDownloadInvoice}
          loading={downloadProgress}
          endIcon={<ExportProdIcon />}
        >
          Donwload Invoice
        </OutlinedButton>
      </div>
    </Container>
  );
};

export default RenewConfirmation;
