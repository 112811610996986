// Core react imports
import React, { useState } from "react";
import PaidPopUp from "../../../components/PaidPopUp";
// Mui imports
import {
  Paper,
  Typography,
  makeStyles,
  TextField,
  Box,
} from "@material-ui/core";
// Importing components
import Controls from "../../../components/controls/Controls";
import { layoutHeading } from "../../../styles/common";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { BASIC, PLANS } from "../../plans/constants";
import { useSelector } from "react-redux";
const useStyles = makeStyles(() => ({
  backDiv: {
    marginBottom: "24px",
    cursor: "pointer",
  },
  linkIcon: { color: "#1A1A1A", fontSize: "14px", verticalAlign: "middle" },
  linkText: {
    fontSize: "14px",
    color: "#1A1A1A",
    fontWeight: 600,
    lineHeight: "20px",
  },
  paperForm: {
    boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.04)",
    padding: "40px",
    width: "51%",
  },
  formHeader: {
    fontSize: "14px",
    lineHeight: "20px",
    color: "#1A1A1A",
    fontWeight: "bold",
  },
  label: {
    alignItems: "flex-start",
    justifyContent: "flex-end",
    margin: "0 0 8px 0",
    width: "100%",
  },
  labelWidth: {
    width: "auto",
  },
  controlInput: {
    width: "100% !important",
    margin: "12px 0px 0px 0px !important",
  },
  formField: {
    padding: "14px 0px",
  },

  descriptionHelper: {
    marginBottom: 0,
  },
}));

// Component export
export default function CheckoutFieldForm({
  onFormFieldChange,
  formState,
  handleSave,
  handleCancel,
}) {
  const classes = useStyles();
  const storeState = useSelector((state) => state.store);
  const [isOpenPaidPopUp, setIsOpenPaidPopUp] = useState(false);
  return (
    <>
      <div className={classes.backDiv}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
          onClick={handleCancel}
        >
          <ArrowBackIosIcon className={classes.linkIcon} />
          <span className={classes.linkText}>Back to Configure</span>
        </div>
      </div>
      <p className={layoutHeading} style={{ marginBottom: 24 }}>
        Extra Checkout Field
      </p>
      <Paper elevation={0} className={classes.paperForm}>
        <Typography className={classes.formHeader}>
          CHECKOUT FIELD DETAILS
        </Typography>
        <div className={classes.formField}>
          <Controls.Select
            name="fieldType"
            label="Select field type"
            labelPlacement="top"
            value={formState.type.value}
            placeholder="Select field type"
            options={[
              { id: "TEXT", title: "Text" },
              { id: "MOBILE", title: "Mobile" },
              { id: "EMAIL", title: "Email" },
              { id: "LIST", title: "List" },
              { id: "DATETIME", title: "Date Time", plan: BASIC },
              { id: "DATE", title: "Date Only", plan: BASIC },
              { id: "IMAGE", title: "Image", plan: BASIC },
            ]}
            color="secondary"
            onChange={(e) => {
              if (PLANS[storeState.store.plan].weight < PLANS[BASIC].weight) {
                setIsOpenPaidPopUp(true);
                return;
              }
              onFormFieldChange("type", e.target.value);
            }}
            controlClass={classes.controlInput}
            labelClass={classes.label}
            error={formState.type.hasError ? formState.type.error : ""}
            extLabelWidth={classes.labelWidth}
          />
        </div>
        <div className={classes.formField}>
          <Controls.Input
            placeholder="Enter Field name"
            helperText={formState.name.helpText}
            name="Field Name"
            label="Field Name"
            value={formState.name.value}
            color="secondary"
            onChange={(e) => onFormFieldChange("name", e.target.value)}
            onFocus={() => {}}
            onBlur={() => {}}
            controlClass={classes.controlInput}
            error={formState.name.hasError ? formState.name.error : ""}
            labelClass={classes.label}
            labelPlacement="top"
            extLabelWidth={classes.labelWidth}
            fullWidth
          />
        </div>
        <div className={classes.formField}>
          <Typography
            style={{ fontSize: "14px", lineHeight: "20px", fontWeight: 600 }}
          >
            Short Description
          </Typography>
          <TextField
            color="secondary"
            variant="outlined"
            multiline
            rows="1"
            style={{ marginTop: 12, marginBottom: 8, width: "100%" }}
            InputProps={{
              style: {
                padding: 9,
              },
            }}
            inputProps={{ style: { fontSize: 14 } }}
            value={formState.description.value}
            onChange={(e) => {
              if (e.target.value.length > 40) {
                onFormFieldChange("description", e.target.value.slice(0, 40));
              } else {
                onFormFieldChange("description", e.target.value);
              }
            }}
            placeholder="Enter field description"
            helperText={
              <Box display="flex" justifyContent="space-between">
                <p className={classes.descriptionHelper}>
                  Eg: Special delivery instructions, Provide unlisted products
                </p>
                <p className={classes.descriptionHelper}>
                  {formState.description.value.length}/40
                </p>
              </Box>
            }
            FormHelperTextProps={{ style: { margin: 0 } }}
          />
        </div>
        {formState.type.value === "LIST" && (
          <div className={classes.formField}>
            <Controls.Input
              placeholder=""
              helperText={formState.list.helpText}
              name="List Options"
              label="List Options"
              value={formState.list.value}
              color="secondary"
              onChange={(e) => onFormFieldChange("list", e.target.value)}
              onFocus={() => {}}
              onBlur={() => {}}
              controlClass={classes.controlInput}
              error={formState.list.hasError ? formState.list.error : ""}
              labelClass={classes.label}
              labelPlacement="top"
              extLabelWidth={classes.labelWidth}
              fullWidth
            />
          </div>
        )}
        <div className={classes.formField}>
          <Controls.Checkbox
            name="required"
            label="Field is mandatory"
            style={{ marginRight: 0, marginLeft: 0 }}
            checkboxStyles={{ padding: 0, marginRight: "12px" }}
            value={formState.required.value}
            color="secondary"
            onChange={(e) => {
              onFormFieldChange("required", e.target.checked);
            }}
          />
        </div>
        <div
          style={{
            marginTop: "50px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CombinedButtons
            outlinedBtnAction={handleCancel}
            solidBtnAction={handleSave}
          />
        </div>
      </Paper>
      <PaidPopUp
        open={isOpenPaidPopUp}
        close={() => setIsOpenPaidPopUp(false)}
        plan={BASIC}
        subtxt="Upgrade to basic plan to use Image and Date Time type fields"
      />
    </>
  );
}
