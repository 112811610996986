// Importing coupon types
import {
  TOGGLE_LOADING,
  CLEAR_COUPONS,
  SET_COUPONS,
  SET_COUPON_BANNERS,
  COUPON_CREATE_ERROR,
  CREATE_COUPON_LOADING,
  CREATE_COUPON_SUCCESS,
  DELETE_COUPON_SUCCESS,
  TOGGLE_COUPON_ACTIVATION,
  CLEAR_COUPON,
  COUPON_ERROR,
  SET_COUPONS_PER_PAGE,
} from "./couponTypes";
// Utility imports
import { getDefaultStore } from "../../utils/storeUtil";
import { getCouponsPerPage } from "../../utils/utils";

export const setCoupons = (payload) => (dispatch) => {
  dispatch({ type: SET_COUPONS, payload });
};

export const clearCoupons = () => (dispatch) => {
  dispatch({ type: CLEAR_COUPONS });
};

export const clearCoupon = () => (dispatch) => {
  dispatch({ type: CLEAR_COUPON });
};

export const fetchCoupons =
  (page = 0, size = getCouponsPerPage(), keyword = "") =>
  async (dispatch, getState) => {
    dispatch({ type: TOGGLE_LOADING });
    const store = getDefaultStore();
    let accessToken = localStorage.getItem("access_token");
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/v1/store/${
        store.store_id
      }/coupons/page?page=${page}&sort=createdAt,desc&size=${size}${
        keyword !== "" ? `&q=${keyword}` : ""
      }`,
      {
        method: "GET",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data = await response.json();
    dispatch(setCoupons(data.payload));
  };

export const createCoupon = (coupon) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_COUPON_LOADING });
    const accessToken = localStorage.getItem("access_token");
    const store = getDefaultStore();
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/v1/store/${store.store_id}/coupons`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          ...coupon,
          org_id: store.org_id,
          store_id: store.id,
        }),
      }
    );
    const data = await response.json();
    if (response.status !== 200) {
      throw data;
    }
    dispatch({ type: CREATE_COUPON_SUCCESS, payload: data.payload });
  } catch (err) {
    console.log(err);
    dispatch({ type: COUPON_CREATE_ERROR, payload: err });
  }
};

export const deleteCoupon = (couponId) => async (dispatch) => {
  try {
    const accessToken = localStorage.getItem("access_token");
    const store = getDefaultStore();
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/v1/store/${store.store_id}/coupons/${couponId}`,
      {
        method: "DELETE",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data = await response.json();
    if (response.status !== 200) {
      throw data;
    }
    dispatch({ type: DELETE_COUPON_SUCCESS, payload: couponId });
  } catch (err) {
    console.log("error occured in delete", err);
    dispatch({ type: COUPON_ERROR, payload: "Failed to delete coupon" });
  }
};

export const fetchCouponBanners = () => async (dispatch) => {
  const accessToken = localStorage.getItem("access_token");
  const response = await fetch(
    `${process.env.REACT_APP_API}/api/v1/offer?type=MOBILE_COUPON`,
    {
      method: "GET",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const data = await response.json();
  dispatch({ type: SET_COUPON_BANNERS, payload: data.payload });
};

export const updateCoupon = (couponId, payload) => async (dispatch) => {
  try {
    const store = getDefaultStore();
    let accessToken = localStorage.getItem("access_token");
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/v1/store/${store.store_id}/coupons/${couponId}`,
      {
        method: "PATCH",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    if (!response.ok) {
      dispatch({ type: COUPON_CREATE_ERROR, payload: "Failed to update coupon" });
      return;
    }
    let data = await response.json();
    dispatch({ type: CREATE_COUPON_SUCCESS, payload: { couponId, data:data.payload } });
  } catch (err) {
    console.log("failed to toggle");
    dispatch({ type: COUPON_CREATE_ERROR, payload: "Failed to change status" });
  }
};

export const toggleCouponActivation =
  (couponId, activationState) => async (dispatch) => {
    try {
      const store = getDefaultStore();
      let accessToken = localStorage.getItem("access_token");
      const response = await fetch(
        `${process.env.REACT_APP_API}/api/v1/store/${store.store_id}/coupons/${couponId}`,
        {
          method: "PATCH",
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            active: activationState,
          }),
        }
      );
      if (!response.ok) {
        dispatch({ type: COUPON_ERROR, payload: "Failed to change status" });
        return;
      }
      await response.json();
      dispatch({ type: TOGGLE_COUPON_ACTIVATION, payload: couponId });
    } catch (err) {
      console.log("failed to toggle");
      dispatch({ type: COUPON_ERROR, payload: "Failed to change status" });
    }
  };

export const setCouponsPerPage = (data) => (dispatch) => {
  dispatch({
    type: SET_COUPONS_PER_PAGE,
    payload: data,
  });
};
