import { getDateRange, getParticularDayTimestamp } from "../../utils/utils";
import { BASIC, BUSINESS, BUSINESS_APP_PRO } from "../plans/constants";

export const initialState = {
  report_type: { error: "", value: "ORDER" },
  duration: { error: "", value: "Today" },
  format: { error: "", value: "CSV" },
  startDate: { error: "", value: "" },
  endDate: { error: "", value: "" },
  columns: { error: "", value: [] },
};

export const reportTypes = [
  {
    value: "ORDER",
    title: "Order Report",
    displayValue: "Order Report",
    subtitle: "Includes Online Order Details",
    note: "This report provides Order Details e.g. Order Info, Customer Info, Charges, Delivery Address, UTM Tags and Checkout Information etc",
  },
  {
    value: "INVOICE",
    title: "Invoice Report",
    displayValue: "Invoice Report",
    subtitle: "Provides Invoice Information",
    note: "This report provides Invoice Details e.g. Invoice Id, Invoice Date, Customer Info, Charges and Tax for the invoices created in the system",
  },
  {
    value: "CUSTOMER",
    title: "Customer Report",
    displayValue: "Customer Report",
    subtitle: "Provides Information for all the Customers",
    note: "This report provides Customer Details e.g. Name, Mobile, Email, Type, GSTIN and Balance Amount etc",
  },
  {
    value: "ORDERITEM",
    title: "Order Item Report",
    plan: BASIC,
    displayValue: "Order Item Report",
    subtitle: "Includes Order Item detailed information",
    note: "This report includes Order Item details e.g Name, Sku, HSN, Sale Price, Tax, Quantity, Order, Customer and Checkout information",
  },
  {
    value: "FLATORDERITEM",
    title: "Flat Order Item Report",
    plan: BASIC,
    displayValue: "Flat Order Item Report",
    availableForStoreIds: [25756],
    subtitle:
      "Similar to order item report but item will have rows equal to quantity count",
    note: "This report includes Order Item details in flat format e.g Name, Sku, HSN, Sale Price, Tax, Order, Customer and Checkout information",
  },
  {
    value: "PAYMENT",
    title: "Payment Report",
    plan: BASIC,
    displayValue: "Payment Report",
    subtitle: "Includes Payment information against an Order/Invoice",
    note: "This report provides Payments information e.g Payment mode, Amount and Time of Payment against Orders/Invoices during the given period",
  },
  {
    value: "INVENTORY",
    title: "Product Inventory Report",
    plan: BASIC,
    displayValue: "Product Inventory Report",
    subtitle: "Provides current Inventory information snapshot",
    note: "This report provides a snapshot of current Inventory details along with Product Sku, Name, Sale and MRP information",
  },
  {
    value: "GSTSALES",
    plan: BUSINESS_APP_PRO,
    title: "GST Sales Report",
    displayValue: "GST Sales Report",
    subtitle: "Includes Sales information for GST Filing purpose",
    note: "This report includes Invoice details, Order Item information e.g HSN, Amount, Tax and Customer details including Sale location and State Code",
  },
  {
    value: "ORDERSUMMARY",
    plan: BUSINESS_APP_PRO,
    title: "Daily Sale Summary",
    displayValue: "Daily Sale Summary",
    subtitle: "Cumulative Sales, Discount, Delivery Charge on daily basis",
    note: "Report includes Total Sales, Discounts, Delivery Charges and other financial data on daily basis for all the orders during the given period",
  },
  {
    value: "ABANDONEDCART",
    plan: BUSINESS_APP_PRO,
    title: "Abandoned Cart Report",
    displayValue: "Abandoned Cart Report",
    subtitle: "Report contains information about Carts Abandoned by customers",
    note: "Includes columns Cart Id, Create Date, Cart Item(s), Customer Contact and Amout Details",
  },
  {
    value: "ORDERORDERITEMREPORT",
    plan: BUSINESS_APP_PRO,
    title: "Order & Order Item Report",
    displayValue: "Order & Order Item Report",
    subtitle:
      "Single report containing Order, Invoice, Order Item, Order Address & Delivery Details",
    note: "Combines all order related information e.g Order Items, Charges, Address and Delivery Info into one report",
  }
];

function StringifyDate(date) {
  return (
    String(date.getDate()).padStart(2, "0") +
    "/" +
    String(date.getMonth() + 1).padStart(2, "0") +
    "/" +
    date.getFullYear()
  );
}

function getDateDisplayRange(range) {
  let date = null;
  if (range === "Today") {
    date = new Date();
    return StringifyDate(date);
  } else if (range === "Yesterday") {
    date = new Date(
      new Date(new Date().setHours(0, 0, 0, 0)).setDate(
        new Date().getDate() - 1
      )
    );
    return StringifyDate(date);
  } else if (range === "Last Week") {
    let startDate = new Date(
      new Date(getParticularDayTimestamp(7)).setHours(0, 0, 0, 0)
    );
    let endDate = new Date(
      new Date(getParticularDayTimestamp(1)).setHours(23, 59, 59, 59)
    );
    return StringifyDate(startDate) + " - " + StringifyDate(endDate);
  } else if (range === "This Week") {
    let sow = new Date();
    let dayOfWeek = sow.getDay();
    let diff = sow.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
    sow.setDate(diff);
    sow.setHours(0, 0, 0, 0);
    let startDate = sow;
    let endDate = new Date(new Date().setHours(23, 59, 59, 59));
    return StringifyDate(startDate) + " - " + StringifyDate(endDate);
  } else if (range === "This Month") {
    let startDate = new Date(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(
        0,
        0,
        0,
        0
      )
    );
    let endDate = new Date(new Date().setHours(23, 59, 59, 59));
    return StringifyDate(startDate) + " - " + StringifyDate(endDate);
  } else if (range === "Last Month") {
    let startDate = new Date(
      new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1).setHours(
        0,
        0,
        0,
        0
      )
    );
    let endDate = new Date(
      new Date(new Date().getFullYear(), new Date().getMonth(), 0).setHours(
        23,
        59,
        59,
        59
      )
    );
    return StringifyDate(startDate) + " - " + StringifyDate(endDate);
  }
}

export const formatList = [
  {
    value: "CSV",
    title: "Comma-separated values (.csv)",
    displayValue: "Comma-separated values (.csv)",
  },
  {
    value: "XLSX",
    title: "Microsoft XLS (.xlsx)",
    displayValue: "Microsoft XLS (.xlsx)",
  },
  {
    value: "PDF",
    title: "PDF (.pdf)",
    displayValue: "PDF (.pdf)",
  },
];

export const durationList = [
  {
    value: "Today",
    title: "Today",
    displayValue: `Today [${getDateDisplayRange("Today")}]`,
  },
  {
    value: "Yesterday",
    title: "Yesterday",
    displayValue: `Yesterday [${getDateDisplayRange("Yesterday")}]`,
  },
  {
    value: "This Week",
    title: "This Week",
    displayValue: `This Week [${getDateDisplayRange("This Week")}]`,
  },
  {
    value: "Last Week",
    title: "Last Week",
    displayValue: `Last Week [${getDateDisplayRange("Last Week")}]`,
  },
  {
    value: "This Month",
    title: "This Month",
    displayValue: `This Month [${getDateDisplayRange("This Month")}]`,
  },
  {
    value: "Last Month",
    title: "Last Month",
    displayValue: `Last Month [${getDateDisplayRange("Last Month")}]`,
  },
  {
    value: "custom",
    title: "Custom Date",
    displayValue: `Custom Date`,
  },
];
