export const FETCH_MENU = "FETCH_MENU";
export const ADD_MENU = "ADD_MENU";
export const UPDATE_MENU = "UPDATE_MENU";
export const ADD_MENU_ITEM = "ADD_MENU_ITEM";
export const ADD_SUB_MENU_ITEM = "ADD_SUB_MENU_ITEM";
export const DELETE_MENU_ITEM = "DELETE_MENU_ITEM";
export const DELETE_SUB_MENU_ITEM = "DELETE_SUB_MENU_ITEM";
export const UPDATE_MENU_ITEM = "UPDATE_MENU_ITEM";
export const UPDATE_SUB_MENU_ITEM = "UPDATE_SUB_MENU_ITEM";
export const SET_MENU_ERROR = "SET_MENU_ERROR";
export const CLEAR_MENU_ERROR = "CLEAR_MENU_ERROR";
export const MENU_PROGRESS = "MENU_PROGRESS";
export const MENU_LOADER = "MENU_LOADER";
export const UPDATE_MENU_ITEM_LIST = "UPDATE_MENU_ITEM_LIST";
