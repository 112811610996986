import { Typography } from "@material-ui/core";
import React from "react";
import Controls from "../../../components/controls/Controls";
import useStyles from "../styles";
import { CouponTriggeredPoint } from "./referralUtils";

const Setting = ({ config, setConfig, minOrdAndExp, setMinOrdAndExp }) => {
  const classes = useStyles();
  const onFormFieldChange = (fieldName, fieldValue) => {
    const clonedState = { ...config };
    clonedState[fieldName] = fieldValue;
    setConfig(clonedState);
  };

  return (
    <div>
      <Typography className={classes.stepText}>
        <b>Step 1</b>: Commission for Referee
      </Typography>
      <Typography className={classes.stepSubText}>
        Customer (Referee) will get following commission after a successfull
        referral
      </Typography>
      <Controls.RadioGroup
        style={{}}
        value={config.referee_commission_type}
        radioLabelClass={classes.radioBtnLabel}
        onChange={(e) =>
          onFormFieldChange("referee_commission_type", e.target.value)
        }
        items={[
          { id: "PERCENTAGE", title: "Percentage" },
          { id: "FLAT", title: "Flat" },
        ]}
      />
      <Controls.Input
        style={{ marginTop: 10 }}
        type="number"
        preventDecimal
        value={config.referee_commission}
        onChange={(e) => {
          if (
            (parseInt(e.target.value) < 0 || parseInt(e.target.value) > 100) &&
            config.referee_commission_type === "PERCENTAGE"
          ) {
            return;
          }
          onFormFieldChange("referee_commission", e.target.value);
        }}
        onFocus={() => {}}
        onBlur={() => {}}
        labelPlacement="top"
        endAdornment={
          <span className={classes.rupee}>
            {config.referee_commission_type === "FLAT" ? "₹" : "%"}
          </span>
        }
        helperText={`Referee will get ${
          config.referee_commission_type === "PERCENTAGE"
            ? `${config.referee_commission ? config.referee_commission : 0}%`
            : `₹${config.referee_commission ? config.referee_commission : 0}`
        } off when referred friend makes their first purchase`}
      />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Controls.Checkbox
          style={{ margin: "-3px 0px 16px" }}
          label={
            <Typography style={{ fontSize: 14 }}>
              This discount requires a minimum order subtotal
            </Typography>
          }
          checkboxStyles={{ padding: 0, marginRight: 12 }}
          value={minOrdAndExp.refereeMinOrd}
          onChange={(e) => {
            setMinOrdAndExp({
              ...minOrdAndExp,
              refereeMinOrd: e.target.checked,
            });
            if (!e.target.checked) {
              setConfig({ ...config, referee_min_order_val: "" });
            }
          }}
          color="secondary"
        />
        {minOrdAndExp.refereeMinOrd ? (
          <Controls.Input
            style={{ marginBottom: 5 }}
            type="number"
            endAdornment={<span className={classes.rupee}>₹</span>}
            helperText={"The order subtotal excludes taxes & shipping fees."}
            value={config.referee_min_order_val}
            onChange={(e) => {
              onFormFieldChange("referee_min_order_val", e.target.value);
            }}
            onFocus={() => {}}
            onBlur={() => {}}
            labelPlacement="top"
          />
        ) : (
          <></>
        )}
        <Controls.Checkbox
          style={{ margin: "-10px 0px 16px" }}
          label={
            <Typography style={{ fontSize: 14 }}>
              Commission will expire after
            </Typography>
          }
          checkboxStyles={{ padding: 0, marginRight: 12 }}
          value={minOrdAndExp.refereeCommExp}
          onChange={(e) => {
            setMinOrdAndExp({
              ...minOrdAndExp,
              refereeCommExp: e.target.checked,
            });
            if (!e.target.checked) {
              setConfig({ ...config, referee_commission_expiry: "" });
            }
          }}
          color="secondary"
        />
        {minOrdAndExp.refereeCommExp ? (
          <Controls.Input
            type="number"
            preventDecimal
            value={config.referee_commission_expiry}
            onChange={(e) => {
              onFormFieldChange("referee_commission_expiry", e.target.value);
            }}
            onFocus={() => {}}
            onBlur={() => {}}
            labelPlacement="top"
            endAdornment={<span className={classes.rupee}>Days</span>}
            helperText={"Starts from the day when the reward is granted."}
          />
        ) : (
          <></>
        )}
        <Controls.Select
          label="Coupon Trigger Point"
          placeholder="Select"
          value={config.referee_coupon_trigger_point}
          onChange={(e) => {
            onFormFieldChange("referee_coupon_trigger_point", e.target.value);
          }}
          options={CouponTriggeredPoint}
          color="secondary"
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          labelPlacement="top"
          labelFontWeight={400}
        />
      </div>
      <hr className={classes.divider} style={{ marginBottom: 20 }} />
      <Typography className={classes.stepText}>
        <b>Step 2</b>: Commission to the Referred friends
      </Typography>
      <Typography className={classes.stepSubText}>
        Referred friends will get following reward from the referee
      </Typography>
      <Controls.RadioGroup
        style={{}}
        value={config.referred_commission_type}
        radioLabelClass={classes.radioBtnLabel}
        onChange={(e) =>
          onFormFieldChange("referred_commission_type", e.target.value)
        }
        items={[
          { id: "PERCENTAGE", title: "Percentage" },
          { id: "FLAT", title: "Flat" },
        ]}
      />
      <Controls.Input
        style={{ marginTop: 10 }}
        type="number"
        preventDecimal
        value={config.referred_commission}
        onChange={(e) => {
          if (
            (parseInt(e.target.value) < 0 || parseInt(e.target.value) > 100) &&
            config.referred_commission_type === "PERCENTAGE"
          ) {
            return;
          }
          onFormFieldChange("referred_commission", e.target.value);
        }}
        onFocus={() => {}}
        onBlur={() => {}}
        endAdornment={
          <span className={classes.rupee}>
            {config.referred_commission_type === "FLAT" ? "₹" : "%"}
          </span>
        }
        helperText={`Referred friend will get ${
          config.referred_commission_type === "PERCENTAGE"
            ? `${config.referred_commission ? config.referred_commission : 0}%`
            : `₹${config.referred_commission ? config.referred_commission : 0}`
        } off their first purchase`}
        labelPlacement="top"
      />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Controls.Checkbox
          style={{ margin: "-3px 0px 16px" }}
          label={
            <Typography style={{ fontSize: 14 }}>
              This discount requires a minimum order subtotal
            </Typography>
          }
          checkboxStyles={{ padding: 0, marginRight: 12 }}
          value={minOrdAndExp.referredMinOrd}
          onChange={(e) => {
            setMinOrdAndExp({
              ...minOrdAndExp,
              referredMinOrd: e.target.checked,
            });
            if (!e.target.checked) {
              setConfig({ ...config, referred_min_order_val: "" });
            }
          }}
          color="secondary"
        />
        {minOrdAndExp.referredMinOrd ? (
          <Controls.Input
            style={{ marginBottom: 5 }}
            type="number"
            endAdornment={<span className={classes.rupee}>₹</span>}
            helperText={"The order subtotal excludes taxes & shipping fees."}
            value={config.referred_min_order_val}
            onChange={(e) => {
              onFormFieldChange("referred_min_order_val", e.target.value);
            }}
            onFocus={() => {}}
            onBlur={() => {}}
            labelPlacement="top"
          />
        ) : (
          <></>
        )}
        <Controls.Checkbox
          style={{ margin: "-10px 0px 16px" }}
          label={
            <Typography style={{ fontSize: 14 }}>
              Commission will expire after
            </Typography>
          }
          checkboxStyles={{ padding: 0, marginRight: 12 }}
          value={minOrdAndExp.referredCommExp}
          onChange={(e) => {
            setMinOrdAndExp({
              ...minOrdAndExp,
              referredCommExp: e.target.checked,
            });
            if (!e.target.checked) {
              setConfig({ ...config, referred_commission_expiry: "" });
            }
          }}
          color="secondary"
        />
        {minOrdAndExp.referredCommExp ? (
          <Controls.Input
            style={{ marginBottom: 5 }}
            type="number"
            preventDecimal
            value={config.referred_commission_expiry}
            onChange={(e) => {
              onFormFieldChange("referred_commission_expiry", e.target.value);
            }}
            onFocus={() => {}}
            onBlur={() => {}}
            labelPlacement="top"
            endAdornment={<span className={classes.rupee}>Days</span>}
            helperText={"Starts from the day when the reward is granted."}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Setting;
