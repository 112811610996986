import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import { cdnUrl } from "../../../utils/cdnUtils";
import { DeleteIcon, EditIcon, NoPicIcon } from "../../../icons";
import { copyToClipboard } from "../../../utils/utils";
import ContentCopyIcon from "../../../icons/ContentCopyIcon";
import ReOrderSharpIcon from "../../../icons/ReOrderSharpIcon";
import Controls from "../../../components/controls/Controls";

const useStyles = makeStyles({
  categoryContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  info: {
    display: "flex",
    alignItems: "center",
    gap: 15,
  },
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 20,
    marginRight: 10,
    height: 25,
  },
  productImage: {
    height: 40,
    width: 40,
    borderRadius: 4,
  },
  noProductImage: {
    border: "1px solid #E5E5E5",
    boxSizing: "border-box",
    borderRadius: "4px",
    width: 40,
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  customTooltip: {
    fontSize: 12,
    borderRadius: 5,
    padding: "8px 12px",
  },
  categoryName: {
    color: "#1A1A1A",
    fontWeight: 600,
    fontSize: 16,
  },

  subcategoriesAndProduct: {
    color: "#1641DB",
    fontSize: 12,
    fontWeight: "400",
  },
});

const ParentCategory = ({
  category,
  categoryMap,
  handleProps,
  handleViewProducts,
  onSwitchChange,
  handleOnEdit,
  handleOnDelete,
}) => {
  const classes = useStyles();
  const [isCopyCat, setIsCopyCat] = useState(false);
  const [openCopyIcon, setOpenCopyIcon] = useState(false);

  return (
    <div
      className={classes.categoryContainer}
      onMouseEnter={() => setOpenCopyIcon(true)}
      onMouseLeave={() => setOpenCopyIcon(false)}
    >
      <div className={classes.info}>
        <span {...handleProps}>
          <ReOrderSharpIcon />
        </span>
        {category.thumbnail ? (
          <img
            className={classes.productImage}
            alt=""
            src={cdnUrl(category.thumbnail, 200, 200, 1)}
          />
        ) : (
          <div className={classes.noProductImage}>
            <NoPicIcon />
          </div>
        )}
        <div>
          <div style={{ height: "25px" }}>
            <span className={classes.categoryName}>{category.name}</span>
            <span>
              {openCopyIcon && (
                <Tooltip
                  className={classes.customTooltip}
                  leaveDelay={0}
                  title={"Category name copied"}
                  open={isCopyCat}
                  onOpen={() => {
                    setIsCopyCat(false);
                  }}
                  onClose={() => {
                    setIsCopyCat(false);
                  }}
                  placement="top-start"
                  PopperProps={{
                    disablePortal: true,
                  }}
                >
                  <span
                    style={{
                      padding: "0px 25px",
                      cursor: "pointer",
                    }}
                    id={category.id}
                    onClick={(event) => {
                      event.stopPropagation();
                      let result = copyToClipboard(category.name);
                      if (result) {
                        setIsCopyCat(true);
                      }
                    }}
                  >
                    <ContentCopyIcon />
                  </span>
                </Tooltip>
              )}
            </span>
          </div>
          <div>
            <span className={classes.subcategoriesAndProduct}>
              {`${category.subcat.length} Subcategories`}
            </span>
            <span> | </span>
            <span
              className={classes.subcategoriesAndProduct}
              id={category.id}
              onClick={handleViewProducts}
            >
              View Products
            </span>
          </div>
        </div>
      </div>
      <div className={classes.icon}>
        <span
          style={{ marginTop: 6 }}
          onClick={(event) => event.stopPropagation()}
        >
          <Controls.Switch
            name={category.id}
            value={!categoryMap.get(category.id).hidden}
            onChange={onSwitchChange}
          />
        </span>
        <Tooltip title="Edit">
          <span id={category.id} onClick={handleOnEdit}>
            <EditIcon />
          </span>
        </Tooltip>
        {category.subcat.length === 0 ? (
          <Tooltip title="Delete">
            <span id={category.id} onClick={handleOnDelete}>
              <DeleteIcon />
            </span>
          </Tooltip>
        ) : (
          <span id={category.id} style={{ cursor: "auto" }}>
            <DeleteIcon color="#b1b1b1" />
          </span>
        )}
      </div>
    </div>
  );
};

export default ParentCategory;
