// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getStoreIdFromUrl } from "./utils/storeUtil";
import { isCustomDomain } from "./utils/utils";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDR4xl6YxRwMfamEuDECtVhLp1zJ4ZVHnE",
  authDomain: "business-f9b95.firebaseio.com",
  projectId: "business-f9b95",
  storageBucket: "business-f9b95.appspot.com",
  messagingSenderId: "823747835432",
  appId: "1:823747835432:web:55a758411e38ef5769e71d"
};

// Initialize Firebase
const customDomain = isCustomDomain();
const firebaseApp = initializeApp(firebaseConfig);
const messaging = customDomain ? {} : getMessaging(firebaseApp);
export const getUserMessagingToken = (_onMessage) => {
  return getToken(messaging, {
    vapidKey:
      "BLUViS5H8PXl7i2YrikZlDP8jS6zt71de2jt1QSmyKvV43BH-X6yk4nHa7XgRvvM6BBT3KEfx1J10qRnoNiFOVg",
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("FCM Token:", currentToken);
        let accessToken = localStorage.getItem("access_token");
        const sessionStorageObj = sessionStorage.getItem("fcm_token_shared");
        let isTokenShared = false;
        if (sessionStorageObj) {
          isTokenShared = JSON.parse(sessionStorageObj).value === "true";
        }
        if (isTokenShared) {
          fetch(
            `${
              process.env.REACT_APP_API
            }/api/v1/business/store/${getStoreIdFromUrl()}/fcm-token-info`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
              },
              body: JSON.stringify({
                fcm_token: currentToken,
              }),
            }
          )
            .then(() => {
              sessionStorage.setItem(
                "fcm_token_shared",
                JSON.stringify({ value: true })
              );
            })
            .catch((err) => {
              console.log({ err });
              sessionStorage.setItem(
                "fcm_token_shared",
                JSON.stringify({ value: false })
              );
            });
        }
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
        onMessage(messaging, _onMessage);
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      // console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};
