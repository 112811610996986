import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import OrderForm from "./components/OrderForm";
import { useDispatch } from "react-redux";
import {
  clearEstimate,
  saveEstimate,
} from "../../redux/estimateOrders/estimateActions";

const CreateEstimate = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);

  const onOrderFormClose = () => {
    history.goBack();
  };

  const onOrderCreate = (ordReq) => {
    setLoader(true);
    dispatch(saveEstimate(ordReq)).then(() => {
      dispatch(clearEstimate());
      setLoader(false);
      history.goBack();
    });
  };

  return (
    <div>
      <OrderForm
        record={null}
        headerLabel="Create Estimate"
        dateLabel="Estimate Date"
        type="ESTIMATE"
        orderPrefix="EST"
        state="CONFIRMED"
        status="VERIFIED"
        note={null}
        loader={loader}
        enableDescount={true}
        enableCoupon={false}
        onClose={onOrderFormClose}
        onSave={onOrderCreate}
        startLabel={{
          label: "Estimates",
          link: "/estimates",
        }}
      />
    </div>
  );
};
export default CreateEstimate;
