import React from "react";
import { layoutBodyBox, layoutContainer } from "../../styles/common";
import { Helmet } from "react-helmet";
import RenewSubscription from "./components/RenewSubscription";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { NavigateNext } from "@material-ui/icons";
import StoreLink from "../../components/common/StoreLink";
import { getStoreIdFromUrl } from "../../utils/storeUtil";
import { isMobile } from "react-device-detect";
import ShoopyHeader from "../../components/Layout/ShoopyHeader";

const RenewalPage = () => {
  const storeId = getStoreIdFromUrl();
  return (
    <>
      <Helmet>
        <title>Renew Subscription - Shoopy</title>
        <meta charSet="utf-8" />
        <script src="https://checkout.razorpay.com/v1/checkout.js"></script>
      </Helmet>
      {isMobile ? (
        <>
          <ShoopyHeader />
          <RenewSubscription storeId={storeId} />
        </>
      ) : (
        <div className={layoutContainer}>
          <div style={{ marginBottom: 20 }}>
            <Breadcrumbs
              style={{ fontSize: 15 }}
              separator={<NavigateNext style={{ fontSize: "1.05rem" }} />}
            >
              <StoreLink
                underline="hover"
                color="inherit"
                href="/billing/plans"
              >
                Billing
              </StoreLink>

              <Typography
                style={{ fontSize: 15, textTransform: "capitalize" }}
                color="secondary"
              >
                Information
              </Typography>
            </Breadcrumbs>
          </div>
          <div className={layoutBodyBox} style={{ maxWidth: 640 }}>
            <RenewSubscription storeId={storeId} />
          </div>
        </div>
      )}
    </>
  );
};

export default RenewalPage;
