// Core react imports
import React, { useState, useEffect } from "react";
// Router imports
import { useParams, useHistory } from "react-router-dom";
// Ui lib imports
import { Paper } from "@material-ui/core";
// Redux imports
import { useSelector, useDispatch } from "react-redux";
import { useStyles } from "./formStyles";
import {
  useQuery,
  initialFormState,
  hydrateProductData,
  hydrateCustomerData,
  hydrateCategoryData,
  fetchCouponById,
} from "./formUtils";
import {
  createCoupon,
  updateCoupon,
} from "../../../../redux/coupons/couponActions";
// Custom component imports
import Toast from "../../../../components/Layout/Toast";
import { layoutContainer } from "../../../../styles/common";
import CombinedButtons from "../../../../components/buttons/CombinedButtons";
import FormSectionOne from "./FormSectionOne";
import FormSectionTwo from "./FormSectionTwo";
import FormSectionFive from "./FormSectionFive";
import FormHead from "./FormHead";
import FormSectionThree from "./FormSectionThree";
import { fetchCategories } from "../../../../redux/category/categoryActions";
import FormSectionFour from "./FormSectionFour";
import PaidPopUp from "../../../../components/PaidPopUp";
import { BASIC } from "../../../plans/constants";
// Exporting default component
export default function CouponForm() {
  // Using 3rd party/custom hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const query = useQuery();
  const initState = {};
  Object.keys(initialFormState).forEach((key) => {
    initState[key] = { ...initialFormState[key] };
  });
  // Constructing initial state
  const couponState = useSelector((state) => state.coupon);
  const categoryState = useSelector((state) => state.categories);
  const [categories, setCategories] = useState({});
  const [allCatsBySlug, setAllCatsBySlug] = useState({});
  const [appliesTo, setAppliesTo] = useState("entire-order");
  const [customerAppliesTo, setCustomerAppliesTo] = useState("everyone");
  useEffect(() => {
    let cats = {};
    let allCatsSlug = {};
    categoryState.categories.forEach((category) => {
      allCatsSlug[category.slug] = { ...category };
      if (category.level === 0) {
        if (cats.hasOwnProperty(category.slug)) {
          cats[category.slug].data = { ...category };
        } else {
          cats[category.slug] = { data: { ...category }, children: [] };
        }
      } else {
        if (cats.hasOwnProperty(category.parent_cat_slug)) {
          cats[category.parent_cat_slug].children.push(category);
        } else {
          cats[category.parent_cat_slug] = {
            data: {},
            children: [category],
          };
        }
      }
    });
    setCategories(cats);
    setAllCatsBySlug(allCatsSlug);
  }, [categoryState]);

  //    State extraction/declaration of local state fields
  const [errorMessage, setErrorMessage] = useState("");
  const [formState, setFormState] = useState({ ...initState });
  const [isPaidPopUpOpen, setIsPaidPopUpOpen] = useState(false);

  useEffect(() => {
    if (couponState.couponCreateError === null) {
    } else if (couponState.couponCreateError === false) {
      history.push(`/${params.storeId}/coupons`);
    } else {
      setErrorMessage("Error while trying to save coupon");
      setTimeout(() => setErrorMessage(""), 3000);
    }
  }, [couponState, params, history]);

  // This effect is to reset state
  useEffect(() => {
    if (!categoryState.loaded) {
      dispatch(fetchCategories());
    }
    return () => {
      setFormState(initialFormState);
    };
    // eslint-disable-next-line
  }, []);
  const handleDataHydration = async () => {
    let couponId = query.get("id");
    let currentCoupon = couponState.coupons.filter(
      (coupon) => coupon.id === Number(couponId)
    )[0];
    if (!currentCoupon) {
      currentCoupon = await fetchCouponById(couponId);
    }
    if (params.operation !== "create") {
      initState.type.value = currentCoupon.type;
      initState.code.value = currentCoupon.code;
      initState.description.value = currentCoupon.description;
      initState.percentage.value = currentCoupon.percentage;
      initState.min_sale_price.value = currentCoupon.min_sale_price;
      initState.max_discount.value = currentCoupon.max_discount;
      initState.usage_per_customer.value = currentCoupon.usage_per_customer;
      initState.auto_apply.value = currentCoupon.auto_apply;
      initState.new_customer.value = currentCoupon.new_customer;
      initState.visible.value = currentCoupon.visible;
      initState.validity.value = currentCoupon.validity
        ? currentCoupon.validity.split("T")[0]
        : null;
      setFormState(initState);
    }
    if (
      currentCoupon?.cat_slugs?.split(",")?.filter((_) => _ !== "")?.length > 0
    ) {
      // Todo fetch categories here
      let cats = await hydrateCategoryData(currentCoupon.cat_slugs);
      onFormFieldChange("cat_slugs", cats);
      setAppliesTo("selected-categories");
    } else if (
      currentCoupon?.super_prod_skus?.split(",")?.filter((_) => _ !== "")
        ?.length > 0
    ) {
      // Todo fetch products here
      let products = await hydrateProductData(currentCoupon.super_prod_skus);
      onFormFieldChange("super_prod_skus", products);
      setAppliesTo("selected-products");
    }
    if (
      currentCoupon?.customers?.split(",")?.filter((_) => _ !== "")?.length > 0
    ) {
      // Todo fetch customers here
      let customers = await hydrateCustomerData(currentCoupon.customers);
      onFormFieldChange("customers", customers);
      setCustomerAppliesTo("specific-customers");
    }
  };
  useEffect(() => {
    if (params.operation === "edit") {
      handleDataHydration();
    }
    // eslint-disable-next-line
  }, []);

  // Handler methods
  const onFormFieldChange = (fieldName, value) => {
    let ClonedState = { ...formState };
    ClonedState[fieldName].value = value;
    ClonedState[fieldName].touched = true;
    setFormState(validate(ClonedState));
  };
  const validate = (state, cb = null) => {
    let formHasError = false;
    const required = ["code"];
    if (state.type.value === "PERCENTAGE") {
      required.push("percentage");
    } else {
      required.push("max_discount");
    }
    Object.keys(state).forEach((key) => {
      if (required.indexOf(key) !== -1) {
        if (!state[key].value) {
          state[key].hasError = true;
          if (state[key].touched || cb !== null) {
            state[key].error = "This field is required";
          }
        } else {
          state[key].hasError = false;
          state[key].error = "";
        }
      } else {
        state[key].hasError = false;
        state[key].error = "";
      }
      formHasError = formHasError || state[key].hasError;
    });
    if (!formHasError && cb) {
      cb();
      return true;
    }
    return state;
  };
  const handleSave = () => {
    const payload = {
      type: formState.type.value,
      min_sale_price: formState.min_sale_price.value,
      max_discount: formState.max_discount.value,
      percentage: formState.percentage.value,
      description: formState.description.value,
      auto_apply: formState.auto_apply.value,
      new_customer: formState.new_customer.value,
      visible: formState.visible.value,
      active: true,
      usage_per_customer: formState.usage_per_customer.value,
      validity: formState.validity.value,
      code: formState.code.value,
      cat_slugs: formState.cat_slugs.value
        .filter((_) => {
          let bool =
            formState.cat_slugs.value.indexOf(
              allCatsBySlug[_].parent_cat_slug
            ) === -1;
          return bool;
        })
        .join(","),
      super_prod_skus: Object.keys(formState.super_prod_skus.value).join(","),
      customers: Object.keys(formState.customers.value).join(","),
    };
    if (params.operation === "edit") {
      let couponId = query.get("id");
      Object.keys(formState).forEach((key) => {
        if (!formState[key].touched) {
          delete payload[key];
        }
      });
      dispatch(updateCoupon(couponId, payload));
    } else {
      dispatch(createCoupon(payload));
    }
  };
  return (
    <div className={layoutContainer}>
      <FormHead params={params} code={formState.code.value} />
      <Paper elevation={3} className={classes.paper}>
        <FormSectionOne
          formState={formState}
          onFormFieldChange={onFormFieldChange}
          params={params}
        />
        <hr className={classes.horizontalDivider} />
        <FormSectionTwo
          formState={formState}
          onFormFieldChange={onFormFieldChange}
          params={params}
        />
        <hr className={classes.horizontalDivider} />
        <FormSectionThree
          categories={categories}
          allCatsBySlug={allCatsBySlug}
          formState={formState}
          onFormFieldChange={onFormFieldChange}
          appliesTo={appliesTo}
          setAppliesTo={setAppliesTo}
          setIsPaidPopUpOpen={setIsPaidPopUpOpen}
        />
        <hr className={classes.horizontalDivider} />
        <FormSectionFour
          formState={formState}
          onFormFieldChange={onFormFieldChange}
          appliesTo={customerAppliesTo}
          setAppliesTo={setCustomerAppliesTo}
          setIsPaidPopUpOpen={setIsPaidPopUpOpen}
        />
        <hr className={classes.horizontalDivider} />
        <FormSectionFive
          params={params}
          onFormFieldChange={onFormFieldChange}
          formState={formState}
        />
        <div
          style={{
            marginTop: "36px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CombinedButtons
            outlinedBtnAction={() => {
              history.push(`/${params.storeId}/coupons`);
            }}
            solidBtnAction={() => {
              let result = validate({ ...formState }, handleSave);
              if (result !== true) {
                setErrorMessage("Please fill out all required fields");
                setFormState(result);
              }
            }}
            solidBtnText={"Save"}
          />
        </div>
      </Paper>
      <Toast
        open={errorMessage.length > 0}
        close={() => setErrorMessage("")}
        message={errorMessage}
        severity="error"
      />
      <PaidPopUp
        open={isPaidPopUpOpen}
        close={() => setIsPaidPopUpOpen(false)}
        plan={BASIC}
        subtxt="Create products, categories and customers specific coupons to drive more sales"
      />
    </div>
  );
}
