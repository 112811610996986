import React from "react";
import { Box, Typography, Button, IconButton } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import CloseIcon from "../../../../icons/CloseIcon";
import { useStyles } from "../../styles";
import { clickable } from "../../../../styles/common";
import { getStageTitle } from "../../utils";

export default function ModalHeader({
  stage,
  setStage,
  close,
  EmpryPlanaddon,
}) {
  const classes = useStyles();

  const handleBackClick = () => {
    if (EmpryPlanaddon === 0) {
      setStage(0);
    } else {
      if (stage > 0) {
        setStage(stage - 1);
      }
    }
  };

  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        {stage > 0 && (
          <IconButton
            style={{
              display: "inline",
              padding: "0px",
              marginRight: "20px",
            }}
            onClick={handleBackClick}
          >
            <ArrowBack style={{ color: "#1A1A1A" }} />
          </IconButton>
        )}
        <Typography className={classes.modalTitle}>
          {getStageTitle(stage)}
        </Typography>
      </Box>
      <span onClick={close} className={clickable}>
        <CloseIcon />
      </span>
    </Box>
  );
}
