import React from 'react';
import Editor from "../../products/components/Editor";
import SolidButton from "../../../components/buttons/SolidButton";
import { Typography } from "@material-ui/core";

const ExpandAndEdit = ({heading, editorText, setEditorText, onContinue}) => {
  return (
    <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          minHeight: "100%",
        }}
      >
        <div>
          <Typography
            style={{ 
                marginBottom: 32,   
                fontWeight: 600,
                fontSize: 14,
                color: "#1A1A1A",
                paddingTop: 6,
             }}
          >
            {heading}
          </Typography>
          <Editor
            editorText={editorText}
            setEditorText={setEditorText}
            height="650px"
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingTop: 32,
          }}
        >
          <SolidButton
            onClick={onContinue}
          >
            Continue
          </SolidButton>
        </div>
      </div>
    );
}
export default ExpandAndEdit;