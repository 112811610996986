import React, { useState, useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import OrderForm from "./components/OrderForm";
import { useDispatch } from "react-redux";
import { fetchInvoiceById } from "../../utils/invoiceUtil";
import {
  clearPurchase,
  savePurchase,
} from "../../redux/purchaseOrders/purchaseActions";

const EditPurchase = () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [record, setRecord] = useState(null);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchInvoiceById(params.purchaseid).then((rec) => {
      setRecord(rec);
      setLoading(false);
    });
  }, [params.purchaseid]);

  const onOrderFormClose = () => {
    history.goBack();
  };

  const onOrderSave = (ordReq) => {
    setLoader(true);
    dispatch(savePurchase(ordReq)).then(() => {
      dispatch(clearPurchase());
      setLoader(false);
      history.goBack();
    });
  };
  return (
    <div>
      {loading && (
        <div
          style={{
            position: "fixed",
            left: "50%",
            top: "50%",
            zIndex: "900",
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      )}

      {record && (
        <OrderForm
          record={record}
          headerLabel="Edit Purchase"
          dateLabel="Purchase Date"
          type="PURCHASE_ORDER"
          loader={loader}
          enableDescount={true}
          enableCoupon={false}
          onClose={onOrderFormClose}
          onSave={onOrderSave}
          startLabel={{
            label: "Purchases",
            link: "/purchases",
          }}
        />
      )}
    </div>
  );
};

export default EditPurchase;
