import { RELOAD } from "./reloadTypes";

export const setReloadTrue = () => (dispatch) => {
  dispatch({
    type: RELOAD,
    payload: true,
  });
};

export const setReloadFalse = () => (dispatch) => {
  dispatch({
    type: RELOAD,
    payload: false,
  });
};
