import React from "react";

export default function ProductGridCollectionIcon() {
  return (
    <svg
      width="73"
      height="60"
      viewBox="0 0 73 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.633357"
        y="0.633357"
        width="71.7333"
        height="58.7333"
        rx="4.4335"
        fill="white"
        stroke="#666666"
        strokeWidth="1.26671"
      />
      <rect
        x="4.97778"
        y="6.66699"
        width="29.8636"
        height="21.6667"
        rx="2.3844"
        fill="#FF7D7D"
      />
      <rect
        x="4.86719"
        y="33.0615"
        width="30.4167"
        height="22.0408"
        rx="2.3844"
        fill="#FF7D7D"
      />
      <rect
        x="38.1589"
        y="6.66699"
        width="29.8636"
        height="21.6667"
        rx="2.3844"
        fill="#FF7D7D"
      />
      <rect
        x="37.7166"
        y="33.0615"
        width="30.4167"
        height="22.0408"
        rx="2.3844"
        fill="#FF7D7D"
      />
    </svg>
  );
}
