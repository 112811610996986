import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const PhoneButtonIcon = ({ viewBox = '0 0 16 16', stroke = '#1A1A1A', fill = '#1A1A1A' }) => {
    return (
        <SvgIcon viewBox={viewBox} style={{ width: '16px', height: '16px' }}>
            <svg fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.5 0.5H3.5C2.94772 0.5 2.5 0.947715 2.5 1.5V14.5C2.5 15.0523 2.94772 15.5 3.5 15.5H12.5C13.0523 15.5 13.5 15.0523 13.5 14.5V1.5C13.5 0.947715 13.0523 0.5 12.5 0.5Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 13C8.55228 13 9 12.5523 9 12C9 11.4477 8.55228 11 8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13Z" fill={fill} />
            </svg>
        </SvgIcon>
    );
};

export default PhoneButtonIcon;

