import React from "react";
import { SvgIcon } from "@material-ui/core";

const ReportSuccess = ({ viewBox = "0 0 16 16" }) => {
  return (
    <SvgIcon style={{ width: "16px", height: "16px" }} viewBox={viewBox}>
      <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="8" cy="8" r="8" fill="#3AC973" />
        <path
          d="M10.8538 5.52113L6.65847 9.71646L5.12981 8.18779C5.00407 8.06635 4.83567 7.99916 4.66087 8.00068C4.48608 8.00219 4.31887 8.07231 4.19526 8.19591C4.07166 8.31952 4.00154 8.48673 4.00003 8.66152C3.99851 8.83632 4.0657 9.00472 4.18714 9.13046L6.18714 11.1305C6.31216 11.2554 6.4817 11.3256 6.65847 11.3256C6.83525 11.3256 7.00479 11.2554 7.12981 11.1305L11.7965 6.46379C11.9179 6.33806 11.9851 6.16966 11.9836 5.99486C11.9821 5.82006 11.912 5.65285 11.7884 5.52925C11.6647 5.40564 11.4975 5.33553 11.3227 5.33401C11.1479 5.33249 10.9795 5.39969 10.8538 5.52113Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
}
export default ReportSuccess;
