import React, { useEffect } from "react";
import { Typography, Select, MenuItem } from "@material-ui/core";
import useStyles from "./styles";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { CLEAR_COMPLETE, CLEAR_DATA } from "../../../redux/commom/commonTypes";
import { fetchStore } from "../../../redux/store/storeActions";
import { DownArrowIcon } from "../../../icons";
import { customerLogout } from "../../../redux/customer/customerActions";
import { getStoreIdFromUrl } from "../../../utils/storeUtil";
import { fetchUserStores } from "../../../redux/userstore/userStoreActions";

const MobileSwitcher = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const storeId = getStoreIdFromUrl();
  const state = useSelector((state) => state);
  const stores = state.userstore.stores;
  const loaded = state.userstore.loaded;

  useEffect(() => {
    dispatch(fetchUserStores());
    // eslint-disable-next-line
  }, []);

  const handleChange = (event) => {
    if (event.target.value === "__ACTION_SIGNOUT_BUTTON") {
      dispatch(customerLogout());
      dispatch({
        type: CLEAR_COMPLETE,
        payload: {},
      });
      history.push("/login/redirect?callback=/subscribe/renew");
    } else {
      dispatch({
        type: CLEAR_DATA,
        payload: {},
      });
      history.push(`/${event.target.value}/billing/plans/info`);
      dispatch(fetchStore());
    }
  };

  return (
    <>
      {loaded && storeId && (
        <Select
          id="store-select"
          value={storeId}
          onChange={handleChange}
          disableUnderline={true}
          classes={{
            selectMenu: classes.mobilemenu,
          }}
          MenuProps={{
            classes: {
              paper: classes.dropdownMenuContainer,
              list: classes.dropdownList,
            },
          }}
          IconComponent={DownArrowIcon}
        >
          {stores.map((store, index) => (
            <MenuItem
              key={index + 1}
              value={store.store_id.toString()}
              className={classes.menuitem}
              classes={{ gutters: classes.menuitemGutter }}
            >
              <Typography className={classes.menutxt}>
                {store.store_name}
              </Typography>
            </MenuItem>
          ))}
          <MenuItem
            style={{ marginTop: "16px", borderTop: "1px solid #dddddd" }}
            className={classes.menuitem}
            classes={{ gutters: classes.menuitemGutter }}
            value="__ACTION_SIGNOUT_BUTTON"
          >
            <Typography
              style={{
                color: "#999999",
                fontSize: "14px",
                lineHeight: "24px",
              }}
            >
              Sign out
            </Typography>
          </MenuItem>
        </Select>
      )}
    </>
  );
};
export default withRouter(MobileSwitcher);
