import { makeStyles } from "@material-ui/styles";
export default makeStyles((theme) => ({
  heading: {
    fontWeight: 700,
    fontSize: 24,
    lineHeight: "32px",
    color: "#1A1A1A",
  },
  addBtn: {
    outline: "none",
    background: "#1641DB",
    color: "white",
    borderRadius: 6,
    height: 48,
    fontWeight: 600,
    lineHeight: "24px",
    fontSize: 16,
    padding: 16,
    textTransform: "none",
    width: 163,
    boxShadow: "none",
    "&:hover": {
      background: "#1641DB",
    },
  },
  cancelBtn: {
    border: "1px solid #1641DB",
    color: "#1641DB",
    background: "white",
    borderRadius: 6,
    fontWeight: 600,
    fontSize: 16,
    padding: 7,
    width: 149,
    marginRight: 24,
    textTransform: "none",
    boxShadow: "none",
    height: 48,
    "&:hover": {
      background: "white",
    },
  },
  addInvoiceContainer: {
    background: "white",
    boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.04)",
    borderRadius: 6,
    padding: 32,
    position: "relative",
  },
  bottomSheet: {
    background: "#FAFAFA",
    bottom: 0,
    left: 0,
    width: "100%",
    height: 64,
    display: "flex",
    justifyContent: "flex-end",
    boxShadow: "0px -4px 16px rgba(229, 229, 229, 0.63)",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    padding: 16,
    alignItems: "center",
  },
  tosBox: {
    border: "1px dashed #BEC7EB",
    borderRadius: 6,
    width: "100%",
    alignItems: "center",
    marginTop: 8,
    padding: 8,
    background: "#FFFFFF",
  },
  tosHead: {
    color: "#999999",
    fontWeight: 600,
    fontSize: 12,
  },
  tosBody: {
    color: "#999999",
    fontWeight: 400,
    fontSize: 12,
  },
  paymentSelector: {
    background: "#1641DB",
    color: "white",
    borderRadius: 6,
    height: 48,
    width: 141,
    textTransform: "none",
  },
  bodyBox: {
    marginTop: 44,
    backgroundColor: "#ffffff",
    boxShadow: "0px 8px 20px rgba(0,0,0,0.04)",
    borderRadius: 10,
    padding: "24px 0px 0px 0px",
    width: "100%",
  },
  tableHeader: {
    display: "flex",
    marginLeft: theme.spacing(3.25),
    marginRight: theme.spacing(4),
    justifyContent: "space-between",
    marginBottom: theme.spacing(3),
  },
  search: {
    display: "flex",
    border: "1px solid #E6E6E6",
    borderRadius: 6,
    padding: "12px",
    paddingTop: 9,
    height: "44px",
    maxWidth: 410,
    marginRight: "16px",
    flex: 1,
    minWidth: 0,
  },
  searchIcon: {
    height: "100%",
  },
  searchBox: {
    outlineWidth: 0,
    lineHeight: "16px",
    marginLeft: "12px",
    border: "none",
    fontSize: "12px",
    flex: 1,
    minWidth: 0,
  },
  button: {
    borderRadius: "6px",
    border: "1px solid #1641DB",
    backgroundColor: "rgba(255,255,255,0.1)",
    marginLeft: "24px",
    color: "#1641DB",
    boxShadow: "none",
    height: "40px",
    width: "100px",
    padding: "6px 8px",
  },
  disabledButton: {
    border: "1px solid #eeeeee",
  },
  buttonlabel: {
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "16px",
    textTransform: "none",
  },
  tablehead: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px -1px 0px #E5E5E5, 0px 1px 0px #E6E6E6",
    borderTop: "1px solid #E6E6E6",
  },
  delBtn: {
    background: "#ED0000",
    color: "white",
    borderRadius: 6,
    height: 48,
    fontWeight: 600,
    lineHeight: "24px",
    fontSize: 16,
    padding: 16,
    textTransform: "none",
    width: 163,
  },
  modal: {
    width: "570px",
    marginLeft: "auto",
    top: "0px",
    marginRight: "0px",
    zIndex: 3,
  },
  row: {
    "& td": {
      fontWeight: 600,
      paddingLeft: theme.spacing(2.75),
      fontSize: 12,
      lineHeight: "16px",
      color: "#666666",
    },
  },
  nopicctn: {
    border: "1px solid #E5E5E5",
    boxSizing: "border-box",
    borderRadius: "6px",
    width: 60,
    height: 60,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  anchor: {
    "& li": {
      color: "#1641DB",
      fontSize: 12,
      lineHeight: "16px",
    },
  },
  tableHeaderRight: {
    display: "flex",
    alignItems: "center",
  },
  dateFilter: {
    fontWeight: 400,
    fontSize: 12,
    color: "#666666",
    padding: 10,
    alignItems: "center",
  },
  statusSelector: {
    background: "white",
    color: "#666666",
    fontSize: 12,
    borderRadius: 6,
    height: 44,
    width: 100,
    marginLeft: 20,
    border: "1px solid #666666",
    textTransform: "none",
  },
  customWidth: {
    "& div": {
      width: 101,
    },
  },
  customSearchWidth: {
    "& div": {
      width: 300,
    },
  },
  dividerGrid: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "space-between",
    paddingTop: 20,
    paddingBottom: 20,
    borderBottom: "1px solid #E6E6E6",
  },
  subHeading: {
    fontWeight: 600,
    fontSize: 14,
    color: "#1A1A1A",
    paddingTop: 6,
  },
  inputBox: {
    width: "372px",
    height: "32px",
    borderRadius: "6px",
    border: "1px solid #999999",
    padding: "0px 4px",
    outlineColor: "#1641DB",
    fontSize: 14,
  },
  rupee: {
    "& p": {
      fontSize: 12,
      lineHeight: "16px",
    },
  },
  saleprice: {
    marginLeft: 35,
    width: 410,
  },
  accemail: {
    marginLeft: 90,
    width: 368,
  },
  amtRec: {
    marginLeft: 43,
    width: 397,
  },
  refid: {
    marginLeft: 32,
    width: 374,
  },
  noteRec: {
    marginLeft: 40,
    width: 410,
  },
  label: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginRight: 0,
  },
  taxbtn: {
    fontSize: 12,
    textTransform: "capitalize",
    borderRadius: 12,
    width: "64px",
    padding: 0,
    border: "1px solid #1641DB",
  },
  labelinput: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  labelinputModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginRight: 0,
    marginBottom: 0,
  },
  menuitem: {
    fontSize: 12,
    color: "#1a1a1a",
  },
  placeholder: {
    fontSize: 12,
    color: "#999999",
  },
  taxlabel: {
    marginBottom: 0,
    width: "100%",
  },
  taxicon: {
    display: "none",
  },
  taxcontrol: {
    width: "100%",
    fontSize: 12,
    lineHeight: "14px",
    "& fieldset": {
      border: "1px dashed #1641DB",
    },
    "& .MuiSelect-outlined": {
      padding: "6px 0px 7px",
      paddingRight: 0,
    },
  },
  addprodtaxcontrol: {
    width: 120,
    fontSize: 12,
    lineHeight: "14px",
    marginLeft: 98,
    "& fieldset": {
      border: "1px dashed #1641DB",
    },
    "& .MuiSelect-outlined": {
      padding: "8px 10px 8px",
      paddingRight: 0,
    },
  },
  discpercent: {
    marginTop: 4,
  },
  qtycontrol: {
    width: 136,
    marginLeft: 10,
    marginRight: 11,
  },
  namecontrol: {
    width: 418,
    marginLeft: 36,
  },
  prefixcontrol: {
    width: 150,
    marginLeft: 36,
  },
  prefixlabel: {
    width: "35%",
  },
  noItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid rgba(224, 224, 224, .5)",
    height: 62,
    fontSize: 12,
    fontWeight: 600,
    color: "#555555",
    borderTop: "none",
  },
  text: {
    fontSize: "12px",
    lineHeight: "20px",
    fontWeight: 600,
    letterSpacing: "0.02em",
    textAlign: "center",
    color: "#1641DB",
  },
  grouped: {
    minWidth: "24px",
  },
  groupedroot: {
    border: "1px solid",
    borderColor: "#1641DB",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 6,
    height: 25,
  },
  textsizesmall: {
    padding: "1px 0px",
  },
  focusedBtn: {
    fontWeight: 700,
    background: "#FAFAFA",
  },
  focusedTopBtn: {
    fontWeight: 700,
    background: "#FAFAFA",
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  focusedBottomBtn: {
    fontWeight: 700,
    background: "#FAFAFA",
    borderBottomLeftRadius: 6,
    borderBottomightRadius: 6,
  },
  searchPlaceholder: {
    "&::placeholder": {
      fontWeight: 600,
      color: "#1641DB",
      opacity: 0.9,
    },
  },
  applyBtn: {
    background: "#1641DB",
    color: "white",
    opacity: 0.95,
  },
  endicon: {
    marginLeft: 6,
  },
  managebtn: {
    color: "#1641DB",
    marginRight: 24,
    border: "1px solid #1641DB",
    borderRadius: 6,
    width: 188,
    fontSize: 16,
    padding: 12,
    fontWeight: 600,
    lineHeight: "24px",
    textTransform: "none",
    "&:hover": {
      color: "#1641DB",
      background: "#ffffff",
    },
  },
  flexBox: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "space-between",
  },
  tempelateHolder: {
    position: "relative",
    width: 100,
    height: 142,
    padding: 8,
    border: "1px solid #CCCCCC",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    borderRadius: 6,
    cursor: "pointer",
    "&:hover": {
      border: "1px solid #1641DB",
    },
  },
  innerTempHolder: {
    width: "100%",
    height: "100%",
    border: "1px solid #F5F7FC",
    borderRadius: 6,
  },
  tablebody: {
    "& tr": {
      height: "68px",
    },
  },
  timeBtns: {
    borderRadius: 19,
    border: "1px solid #999999",
    background: "white",
    color: "#1A1A1A",
    marginTop: 12,
    padding: "4px 11px",
    textTransform: "none",
    fontSize: 12,
  },
  selectedTimeBtns: {
    borderRadius: 19,
    border: "1px solid #1641DB",
    color: "white",
    background: "#1641DB",
    padding: "4px 11px",
    marginTop: 12,
    fontSize: 12,
    textTransform: "none",
    "&:hover": {
      color: "white",
      background: "#1641DB",
    },
  },
  breadcrumbLink: {
    cursor: "pointer",
    textDecoration: "underline",
    "&:hover": {
      color: "#1641DB",
    },
  },
  progress: {
    color: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  accholder: {
    marginLeft: 4,
    width: 368,
  },
  accnumber: {
    marginLeft: 6,
    width: 372,
  },
  reaccnumber: {
    marginLeft: 6,
    width: 374,
  },
  accifsc: {
    marginLeft: 92,
    width: 375,
  },
  accbank: {
    marginLeft: 19,
    width: 276,
  },
  accholderlabel: {
    width: "40%",
  },
  prodname: {
    fontSize: 14,
    fontWeight: 500,
    marginLeft: 16,
    lineHeight: "22px",
    color: "#000000",
  },
  listSalePrice: {
    color: "#000000",
    paddingLeft: 8,
    fontSize: 14,
    fontWeight: 600,
    minWidth: 78,
    textAlign: "right",
    "&>div": {
      fontSize: 10,
      fontWeight: 400,
    },
  },
  listMrp: {
    color: "#666666",
    paddingLeft: 8,
    minWidth: 70,
    fontSize: 14,
    fontWeight: 500,
    textAlign: "right",
    "&>div": {
      fontSize: 10,
      fontWeight: 400,
    },
  },
  qty: {
    color: "#666666",
    paddingLeft: 8,
    minWidth: 44,
    fontSize: 14,
    fontWeight: 500,
    textAlign: "right",
    "&>div": {
      fontSize: 10,
      fontWeight: 400,
    },
  },
  prodimage: {
    height: "40px",
    width: "40px",
    borderRadius: "6px",
    objectFit: "contain",
  },
  trow: {
    border: "1px solid rgba(224, 224, 224, .5)",
    verticalAlign: "top",
    [theme.breakpoints.down("md")]: {
      "&>td": {
        padding: "8px 4px !important",
      },
    },
  },
}));
