import React, { Fragment, useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  makeStyles,
  Typography,
  Button,
  Modal,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import {
  addProduct,
  editProduct,
  fetchCurrentProduct,
  fetchSuperProduct,
} from "../../../redux/product/productActions";
import { fetchCategories } from "../../../redux/category/categoryActions";
import ImageHandler from "../../../components/ImageHandler";
import Controls from "../../../components/controls/Controls";
import { Form } from "../../../components/form/useForm";
import {
  getTaxCodes,
  getGSTTaxGroupById,
  getMeasuringUnits,
  paymentOptions,
} from "../../../utils/utils";
import Editor from "./Editor";
import AdditionalFields from "./AdditionalFields";
import { uploadImages } from "../../../utils/imageUploader";
import { fetchAdditionalFields } from "../../../redux/additionalFields/additionalFieldsActions";
import { getStoreIdFromUrl, getStoreUrl } from "../../../utils/storeUtil";
import SolidButton from "../../../components/buttons/SolidButton";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import {
  rowFlexAlignCenter,
  rowFlexJustifyBetween,
} from "../../../styles/common";
import PaidTab from "../../../components/common/PaidTab";
import PaidPopUp from "../../../components/PaidPopUp";
import { BASIC, BUSINESS, PLANS } from "../../plans/constants";
import { gmcFieldsConfig } from "../../../utils/productUtils";
import { fetchApps } from "../../../redux/apps/appActions";
import ImageList from "../../../components/ImageList";
import Toast from "../../../components/Layout/Toast";
import OutlinkIcon from "../../../icons/OutlinkIcon";
import useMultiSelectCategory from "./hooks/useMultiSelectCategory";
import {
  nativeFieldsConfig,
  generateCategoryName,
  shouldRenderField,
  createProductFields,
} from "./ProductHelper";
import DrawerCloseIcon from "../../../icons/DrawerCloseIcon";
import TaxSwitchButtons from "./TaxSwitchButton";
import UpArrowIcon from "../../../icons/UpArrowIcon";
import { DownArrowIcon } from "../../../icons";
import RenderSection from "./RenderSection";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  flexCenterBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexCenterCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexCenterEnd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  heading: {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 10,
    marginTop: 20,
  },
  subHeading: {
    fontWeight: 600,
    fontSize: 14,
    color: "#1A1A1A",
    marginBottom: 10,
  },
  headerBorder: {
    paddingBottom: 10,
    borderBottom: "1px solid #E1E1E1",
  },
  editButton: {
    fontSize: "14px",
    fontWeight: 600,
  },
  editorHelperText: {
    color: "#1641DB",
    cursor: "pointer",
    fontSize: 12,
    marginTop: 6,
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "space-between",
  },
  images: {
    width: "60px",
    height: "60px",
    borderRadius: "6px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #1641DB",
    marginRight: "24px",
  },
  label: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginRight: 0,
  },
  rupee: {
    fontSize: 16,
    lineHeight: "16px",
    color: "#999999",
  },
  addfields: {
    width: "24px",
    height: "24px",
    border: "1px solid #1641DB",
    borderRadius: "6px",
    paddingLeft: "4px",
    marginLeft: "16px",
    marginTop: "5px",
  },
  labels: {
    fontSize: "14px",
    lineHeight: "20px",
    color: "1A1A1A",
    fontWeight: 600,
    fontFamily: "Noto Sans",
    paddingTop: "8px",
  },
  addIcon: {
    height: "14px",
    width: "14px",
    color: "#1641DB",
    cursor: "pointer",
  },
  loader: {
    height: "calc(100vh)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const initialState = {
  sku: "",
  super_product_sku: "",
  name: "",
  sale_price: "",
  description: "",
  summary: "",
  mrp: "",
  qty: 0,
  tax: "none",
  categories: [],
  purchase_price: "",
  weight: "",
  barcode: "",
  hsn_code: "",
  product_id: "",
  min_qty: 0,
  max_qty: 0,
  measuring_unit: "pcs",
  brand: "",
  show_online: true,
  pp_tax_included: true,
  sp_tax_included: true,
  created_at: null,
  updated_at: null,
  video_url: "",
  condition: "new",
  gender: "none",
  age_group: "none",
  mpn: "",
  gtin: "",
  material: "",
  pattern: "",
  no_gmc: false,
  payment_mode: "none",
  attributes: [],
  extra_product_fields: [],
};
const VIDEO_UPSELL_MSG =
  "Add product related video for better product representation and much more";
const PAYMENT_MODE_MSG =
  "Activate Prepaid or COD for each product with advanced payment controls";

const ProductForm = ({
  productSku,
  onClose,
  onProductSave,
  recordsPerPage,
  setToast,
  setMessage,
}) => {
  const classes = useStyles();
  const storeId = getStoreIdFromUrl();
  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  const apps = state.apps.apps;
  const taxGroups = state.tax.taxGroups;
  const store = state.store.store;
  const { categories, categoryOptions } = state.categories;
  const {
    fields: additionalFields,
    variantFields: { nativeFields, nonNativeFields },
    attributeFields,
  } = state.additionalFields;
  const storeUrl = getStoreUrl(store, true);
  const defaultCrop = { x: 0, y: 0, width: 250, height: 250 };
  const [expandDesc, setExpandDesc] = useState(false);
  const [expendSummary, setExpendSummary] = useState(false);
  const [openFields, setOpenFields] = useState(false);
  const [preview, setPreview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openPremiumModal, setOpenPremiumModal] = useState(false);
  const [openImageHandler, setOpenImageHandler] = useState(false);
  const [openImageHandlerIndex, setOpenImageHandlerIndex] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const [cropImgs, setCropImgs] = useState(null);
  const [filenames, setFilenames] = useState(null);
  const [product, setProduct] = useState(null);
  const [plan, setPlan] = useState(null);
  const [planUpsellMessage, setPlanUpsellMessage] = useState(null);
  const [loadingData, setLoadingData] = useState(true);

  const [selectedCategories, lastSelectedCategory, renderChips] =
    useMultiSelectCategory(product?.categories, product?.cat_slug);

  const [openSections, setOpenSections] = useState({
    nonNative: true,
    attributes: true,
    gmc: true,
  });

  const [error, setError] = useState({
    value: false,
    message: "",
  });

  const [errors, setErrors] = useState({
    name: { msg: "Please enter Name", required: true, error: false },
    sale_price: {
      msg: "Please enter Sale Price",
      required: true,
      error: false,
      errorMRP: false,
      msgMRP: "Sale Price can't be more than MRP",
    },
  });

  useEffect(() => {
    if (!apps.length) {
      dispatch(fetchApps());
    }
    // eslint-disable-next-line
  }, [apps.length]);

  useEffect(() => {
    if (additionalFields.length === 0) {
      dispatch(fetchAdditionalFields());
    }

    if (categories.length === 0) {
      dispatch(fetchCategories());
    }
    // eslint-disable-next-line
  }, [storeId]);

  useEffect(() => {
    if (productSku) {
      fetchProduct(productSku).then((productData) => {
        if (productData) {
          setProduct(productData);
          setCropImgs(productData?.images || []);
          setFilenames(productData?.images || []);
          setLoadingData(false);
        }
      });
    } else {
      setProduct(initialState);
      setCropImgs([]);
      setFilenames([]);
      setLoadingData(false);
    }
    //eslint-disable-next-line
  }, []);

  const gmc = useMemo(() => {
    let shApps = apps.filter((app) => app.slug === "gmc");
    let shApp = {};
    if (shApps.length > 0) {
      shApp = shApps[0];
    }
    return shApp;
    // eslint-disable-next-line
  }, [apps.length]);

  const accumulateFields = (sourceArray, initialFields = {}) => {
    return sourceArray.reduce((fields, item) => {
      if (item && "ef_id" in item) {
        return {
          ...fields,
          [item.ef_id]: {
            value: item?.val,
            data: item?.data,
          },
        };
      }
      return fields;
    }, initialFields);
  };

  const fetchProduct = async (sku) => {
    const currentProduct = await fetchCurrentProduct(sku);
    const superProduct = await fetchSuperProduct(currentProduct);
    const categories = Array.isArray(currentProduct.categories)
      ? currentProduct.categories.map((category) => ({
          id: category.cat_slug,
          title: category.cat_name,
        }))
      : [];

    const variantFields = currentProduct.extra_product_fields
      ? accumulateFields(currentProduct.extra_product_fields)
      : [];
    const attributeFields = superProduct.attributes
      ? accumulateFields(superProduct.attributes)
      : [];
    const productData = {
      ...currentProduct,
      tax: currentProduct.tax_group?.id ?? "none",
      measuring_unit: currentProduct.measuring_unit ?? "pcs",
      payment_mode: currentProduct.payment_mode ?? "none",
      no_gmc: currentProduct.no_gmc ?? false,
      attributes: attributeFields,
      extra_product_fields: variantFields,
      categories: categories,
      summary: superProduct.summary ?? "",
      qty: currentProduct.qty,
      seo_title: currentProduct.seo_title,
      seo_description: currentProduct.seo_description,
      seo_keywords: currentProduct.seo_keywords,
    };
    return productData;
  };

  const handleTaxIncluded = (field, value) => {
    setProduct({ ...product, [field]: value });
  };

  const toggleSection = (sectionKey) => {
    setOpenSections((prev) => ({
      ...prev,
      [sectionKey]: !prev[sectionKey],
    }));
  };

  const changeHandler = (e) => {
    if (e.target.name === "hsn_code" && e.target.value.length > 16) {
      return;
    }
    if (e.target.name === "show_online") {
      setProduct({ ...product, [e.target.name]: !product.show_online });
    } else if (e.target.name === "no_gmc") {
      setProduct({ ...product, [e.target.name]: !product.no_gmc });
    } else {
      setProduct({ ...product, [e.target.name]: e.target.value });
    }
  };

  const onFieldChange = (field, value) => {
    let event = { target: { name: field, value: value } };
    changeHandler(event);
  };

  const changeField = (event) => {
    const { id, name, value } = event.target;
    const fieldKey = id === "ATTRIBUTE" ? "attributes" : "extra_product_fields";
    setProduct((prevState) => ({
      ...prevState,
      [fieldKey]: {
        ...prevState[fieldKey],
        [name]: { value },
      },
    }));
  };

  const handleMultiSelect = (event, newValue) => {
    setProduct((prevState) => ({
      ...prevState,
      categories: newValue,
    }));
  };

  const onFocus = (event) => {
    setErrors({
      ...errors,
      [event.target.name]: {
        ...errors[event.target.name],
        error: false,
      },
    });
  };

  const onImageRemove = (index) => {
    let newImages = cropImgs.filter((img, i) => {
      return index !== i;
    });
    let fnames = filenames.filter((fname, i) => {
      return index !== i;
    });
    setFilenames(fnames);
    setCropImgs(newImages);
  };

  const onImageClick = (index) => {
    setOpenImageHandler(true);
    setOpenImageHandlerIndex(index);
  };

  const planCheck = (event, plan, message) => {
    if (PLANS[store.plan]?.weight < PLANS[plan].weight) {
      event.stopPropagation();
      setPlan(plan);
      setPlanUpsellMessage(message);
      setOpenPremiumModal(true);
    }
  };

  const createProductRequest = (product, images) => {
    let request = {
      name: product.name,
      display_name: product.name,
      description: product.description,
      qty: product.qty,
      sale_price: parseFloat(product.sale_price),
      images: images,
      show_online: product.show_online,
      brand: nativeFields.brand ? product.brand : null,
      measuring_unit: product.measuring_unit ?? "pcs",
      product_id: nativeFields.product_id ? product.product_id : null,
      min_qty: nativeFields.min_qty ? product.min_qty : 0,
      max_qty: nativeFields.max_qty ? product.max_qty : 0,
      weight: product.weight ? parseFloat(product.weight) : null,
      barcode: nativeFields.barcode ? product.barcode : null,
      hsn_code: nativeFields.hsn_code ? product.hsn_code : null,
      mrp: product.mrp ? parseFloat(product.mrp) : null,
      purchase_price: product.purchase_price
        ? parseFloat(product.purchase_price)
        : null,
      sp_tax_included: product.sp_tax_included,
      pp_tax_included: product.pp_tax_included,
      created_at: product.created_at,
      updated_at: product.updated_at,
      video_url: product.video_url,
      condition: nativeFields.condition ? product.condition : null,
      gender:
        nativeFields.gender && product.gender !== "none"
          ? product.gender
          : null,
      age_group:
        nativeFields.age_group && product.age_group !== "none"
          ? product.age_group
          : null,
      mpn: nativeFields.mpn ? product.mpn : null,
      gtin: nativeFields.gtin ? product.gtin : null,
      material: nativeFields.material ? product.material : null,
      pattern: nativeFields.pattern ? product.pattern : null,
      no_gmc: nativeFields.no_gmc ? product.no_gmc : null,
      categories: !selectedCategories.length
        ? selectedCategories
        : selectedCategories.map((catObj) => ({
            cat_name: catObj.title,
            cat_slug: catObj.id,
            position: 0,
          })),
      summary: product?.summary,
      update_summary: nativeFields["summary"] ? true : false,
      seo_title: product?.seo_title,
      seo_description: product?.seo_description,
      seo_keywords: product?.seo_keywords,
      payment_mode:
        product?.payment_mode === "none" ? null : product?.payment_mode,
      attributes: createProductFields(attributeFields, product.attributes),
      extra_product_fields: createProductFields(
        nonNativeFields,
        product.extra_product_fields
      ),
    };

    if (product.id) {
      request.id = product.id;
    }

    if (product.sku) {
      request.sku = product.sku;
      request.super_product_sku = product.super_product_sku;
    }

    if (product.tax) {
      let taxGroup = getGSTTaxGroupById(taxGroups, product.tax);
      request.tax_group = taxGroup != null ? taxGroup : {};
    }

    if (selectedCategories.length) {
      request.cat_name = lastSelectedCategory?.title;
      request.cat_slug = lastSelectedCategory?.id;
    }

    return request;
  };

  const validate = (event) => {
    let unvalid = false;
    if (event) {
      if (event.target.name !== "sale_price" && event.target.name !== "mrp") {
        setErrors({
          ...errors,
          [event.target.name]: {
            ...errors[event.target.name],
            error: event.target.value.trim() === "",
          },
        });
        unvalid = unvalid || event.target.value.trim() === "";
      } else {
        if (event.target.name === "mrp") {
          setErrors({
            ...errors,
            sale_price: {
              ...errors["sale_price"],
              errorMRP:
                event.target.value.trim() !== "" &&
                parseFloat(product.sale_price) > parseFloat(event.target.value),
            },
          });
          unvalid =
            unvalid ||
            (event.target.value.trim() !== "" &&
              parseFloat(product.sale_price) > parseFloat(event.target.value));
        } else {
          setErrors({
            ...errors,
            sale_price: {
              ...errors["sale_price"],
              error: event.target.value.trim() === "",
              errorMRP:
                product.mrp !== "" &&
                parseFloat(event.target.value) > parseFloat(product.mrp),
            },
          });
          unvalid =
            unvalid ||
            event.target.value.trim() === "" ||
            (product.mrp !== "" &&
              parseFloat(event.target.value) > parseFloat(product.mrp));
        }
      }
    } else {
      setErrors({
        ...errors,
        name: {
          ...errors["name"],
          error: product.name.trim() === "",
        },
        sale_price: {
          ...errors["sale_price"],
          error: product.sale_price === "",
          errorMRP:
            product.sale_price !== "" &&
            product.mrp !== "" &&
            parseFloat(product.sale_price) > parseFloat(product.mrp),
        },
      });
      unvalid =
        product.name.trim() === "" ||
        product.sale_price === "" ||
        (product.sale_price !== "" &&
          product.mrp !== "" &&
          parseFloat(product.sale_price) > parseFloat(product.mrp));
    }

    return unvalid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) return;
    if (
      errors.name.error ||
      errors.sale_price.error ||
      errors.sale_price.errorMRP
    ) {
      return;
    }
    setLoading(true);
    let cloudImages = [];
    let newImgsAndNames = cropImgs
      .map((url, index) => ({ url, index }))
      .filter(({ url }) => !url.startsWith("https:"))
      .map(({ url, index }) => ({ url, filename: filenames[index] }));
    if (newImgsAndNames && newImgsAndNames.length > 0) {
      let fnames = newImgsAndNames.map((nif) => nif.filename);
      let imgs = newImgsAndNames.map((nif) => nif.url);
      let imgCloudLocs = await uploadImages(fnames, imgs, (msg) => {
        setError({ value: true, message: msg });
      });
      cloudImages = imgCloudLocs.map((imgLoc) => imgLoc.payload.location);
    }
    let cloudImagesIndex = 0;
    const images = cropImgs.map((url) => {
      if (url.startsWith("https:")) {
        return url;
      } else {
        return cloudImages[cloudImagesIndex++];
      }
    });
    const payload = createProductRequest(product, images, categories);

    dispatch(
      payload.sku
        ? editProduct(payload, recordsPerPage)
        : addProduct(payload, recordsPerPage)
    ).then((response) => {
      if (response) {
        setMessage(`Product ${payload.sku ? "Saved" : "Added"} Successfully`);
        setToast(true);
        onClose();
      }
      setLoading(false);
    });

    onProductSave(payload.sku ? false : true);
  };

  const section = [
    { key: "attributes", label: "Attributes", fields: attributeFields },
    { key: "nonNative", label: "Variants", fields: nonNativeFields },
  ];

  if (loadingData) {
    return (
      <div className={classes.loader}>
        <CircularProgress style={{ color: "#1641db" }} />
      </div>
    );
  }

  if (expandDesc) {
    return (
      <Fragment>
        <div className={classes.modalStyles}>
          <div className={classes.modalHeader}>
            <Typography variant="h4" className={classes.heading}>
              Description
            </Typography>
          </div>
        </div>
        <div style={{ marginTop: 56 }}>
          <Editor
            editorText={product.description}
            setEditorText={(desc) => onFieldChange("description", desc)}
            height="464px"
          />
        </div>
        <div style={{ height: "100%", position: "relative" }}>
          <div style={{ position: "absolute", bottom: 0, right: 0 }}>
            <SolidButton onClick={() => setExpandDesc(false)}>
              Continue
            </SolidButton>
          </div>
        </div>
      </Fragment>
    );
  }

  if (expendSummary) {
    return (
      <Fragment>
        <div className={classes.modalStyles}>
          <div className={classes.modalHeader}>
            <Typography variant="h4" className={classes.heading}>
              Summary
            </Typography>
          </div>
        </div>
        <div style={{ marginTop: 56 }}>
          <Editor
            editorText={product.description}
            setEditorText={(desc) => onFieldChange("summary", desc)}
            height="464px"
          />
        </div>
        <div style={{ height: "100%", position: "relative" }}>
          <div style={{ position: "absolute", bottom: 0, right: 0 }}>
            <SolidButton onClick={() => setExpendSummary(false)}>
              Continue
            </SolidButton>
          </div>
        </div>
      </Fragment>
    );
  }

  if (openFields) {
    return (
      <AdditionalFields
        setOpenFields={setOpenFields}
        setToast={setToast}
        setMessage={setMessage}
      />
    );
  }
  return (
    <>
      <div className={classes.modalStyles}>
        <div className={classes.headerBorder}>
          <div className={classes.flexCenterBetween}>
            <Typography style={{ fontWeight: 700, fontSize: 20 }}>
              {product.sku ? (
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  {product.sku}
                  <a
                    href={`${storeUrl}/products/${generateCategoryName(
                      product.name
                    )}-${product.sku}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      cursor: "pointer",
                      display: "flex",
                    }}
                  >
                    <OutlinkIcon size={18} />
                  </a>
                </div>
              ) : (
                "Add Product"
              )}
            </Typography>
            <IconButton
              aria-label="Close"
              aria-haspopup="true"
              color="inherit"
              onClick={onClose}
            >
              <DrawerCloseIcon />
            </IconButton>
          </div>
        </div>

        <Form onSubmit={handleSubmit} style={{ marginTop: 12 }}>
          <div className={classes.flexCenterBetween}>
            <p className={classes.subHeading}>Product Image</p>
            {cropImgs.length > 0 && (
              <Button
                size="small"
                color="secondary"
                className={classes.editButton}
                onClick={() => setOpenImageHandler(true)}
              >
                Edit
              </Button>
            )}
          </div>
          <div>
            <div className={classes.flexCenter}>
              <ImageList
                cropImgs={cropImgs}
                setCropImgs={setCropImgs}
                onImageRemove={onImageRemove}
                onImageClick={onImageClick}
              />
              <ImageHandler
                setCropImg={setCropImgs}
                defaultCrop={defaultCrop}
                setFilename={setFilenames}
                cropImg={cropImgs}
                multiple={true}
                filename={filenames}
                maxSize={1920}
                openImageHandler={openImageHandler}
                setOpenImageHandler={setOpenImageHandler}
                openImageHandlerIndex={openImageHandlerIndex}
                openModal={openModal}
                setOpenModal={setOpenModal}
              />
            </div>
            <p style={{ marginTop: 10 }}>
              Maximum 6 Images. <strong>Drag</strong> thumbnail to re-order
            </p>
          </div>
          <div style={{ border: "2px solid #F1F1F1", marginBottom: 16 }} />
          <Controls.Input
            name="name"
            label="Name"
            labelPlacement="top"
            labelFontWeight={400}
            value={product.name}
            onChange={changeHandler}
            onFocus={onFocus}
            onBlur={validate}
            error={errors.name.error ? errors.name.msg : ""}
          />
          <Controls.Input
            name="sale_price"
            label="Sale Price"
            labelPlacement="top"
            labelFontWeight={400}
            type="number"
            startAdornment={<span className={classes.rupee}>₹</span>}
            endAdornment={
              <TaxSwitchButtons
                product={product}
                handleTaxIncluded={handleTaxIncluded}
                taxType="sp_tax_included"
              />
            }
            value={product.sale_price}
            onChange={changeHandler}
            onFocus={onFocus}
            onBlur={validate}
            error={
              errors.sale_price.error
                ? errors.sale_price.msg
                : errors.sale_price.errorMRP
                ? errors.sale_price.msgMRP
                : ""
            }
          />

          <div className={rowFlexJustifyBetween}>
            <div style={{ width: "48%" }}>
              <Controls.Input
                name="mrp"
                label="MRP"
                labelPlacement="top"
                labelFontWeight={400}
                type="number"
                startAdornment={<span className={classes.rupee}>₹</span>}
                placeholder="Optional"
                value={product.mrp}
                onChange={changeHandler}
                onBlur={validate}
              />
            </div>
            <div style={{ width: "48%" }}>
              <Controls.Select
                name="tax"
                label="Tax"
                labelPlacement="top"
                labelFontWeight={400}
                value={product.tax}
                onChange={changeHandler}
                options={getTaxCodes(taxGroups)}
                placeholder="Optional"
                error={errors.tax}
                labelWidth={54}
              />
            </div>
          </div>

          {nativeFields["purchase_price"] && (
            <Controls.Input
              name="purchase_price"
              label="Purchase Price"
              labelPlacement="top"
              labelFontWeight={400}
              type="number"
              startAdornment={<span className={classes.rupee}>₹</span>}
              endAdornment={
                <TaxSwitchButtons
                  product={product}
                  handleTaxIncluded={handleTaxIncluded}
                  taxType="pp_tax_included"
                />
              }
              value={product.purchase_price}
              onChange={changeHandler}
              error={errors.purchase_price}
            />
          )}

          <Controls.Checkboxes
            name="categories"
            label="Categories"
            labelPlacement="top"
            labelFontWeight={400}
            placeholder={"Categories"}
            selectedOptions={selectedCategories}
            options={categoryOptions}
            onChange={handleMultiSelect}
            renderTags={renderChips}
          />

          {nativeFields["summary"] && (
            <div>
              <p style={{ fontSize: 14 }}>Summary</p>
              <Editor
                editorText={product.summary}
                setEditorText={(summary) => onFieldChange("summary", summary)}
                height="80px"
              />
              <p
                className={classes.editorHelperText}
                onClick={() => setExpendSummary(true)}
              >
                Expand and Edit Field
              </p>
            </div>
          )}

          {nativeFields["description"] && (
            <div>
              <p style={{ fontSize: 14 }}>Description</p>
              <Editor
                editorText={product.description}
                setEditorText={(desc) => onFieldChange("description", desc)}
                height="80px"
              />
              <p
                className={classes.editorHelperText}
                onClick={() => setExpandDesc(true)}
              >
                Expand and Edit Field
              </p>
            </div>
          )}

          <div className={rowFlexJustifyBetween}>
            <div style={{ width: "48%" }}>
              <Controls.Input
                name="qty"
                label="Quantity"
                labelPlacement="top"
                labelFontWeight={400}
                value={product.qty}
                onChange={changeHandler}
              />
            </div>
            <div style={{ width: "48%" }}>
              <Controls.Select
                name="measuring_unit"
                label="Measuring Units"
                labelPlacement="top"
                labelFontWeight={400}
                value={product.measuring_unit}
                onChange={changeHandler}
                options={getMeasuringUnits()}
                error={errors.measuring_unit}
              />
            </div>
          </div>

          <div onClick={(e) => planCheck(e, BASIC, VIDEO_UPSELL_MSG)}>
            <Controls.Input
              name="video_url"
              label={
                <div className={rowFlexAlignCenter}>
                  Youtube URL <PaidTab plan={BASIC} />
                </div>
              }
              labelPlacement="top"
              labelFontWeight={400}
              value={product.video_url}
              onChange={changeHandler}
              onFocus={onFocus}
              onBlur={validate}
              disabled={PLANS[store.plan]?.weight < PLANS[BASIC].weight}
            />
          </div>

          <RenderSection
            product={product}
            errors={errors}
            sectionLabel={null}
            fieldsConfig={nativeFieldsConfig}
            activeFields={nativeFields}
            handleChange={changeHandler}
          />

          <div className={rowFlexJustifyBetween}>
            {nativeFields["min_qty"] && (
              <div style={{ width: nativeFields["max_qty"] ? "48%" : "100%" }}>
                <Controls.Input
                  name="min_qty"
                  label="Min Quantity"
                  labelPlacement="top"
                  labelFontWeight={400}
                  value={product.min_qty}
                  onChange={changeHandler}
                />
              </div>
            )}

            {nativeFields["max_qty"] && (
              <div style={{ width: nativeFields["min_qty"] ? "48%" : "100%" }}>
                <Controls.Input
                  name="max_qty"
                  label="Max Quantity"
                  labelPlacement="top"
                  labelFontWeight={400}
                  value={product.max_qty}
                  onChange={changeHandler}
                />
              </div>
            )}
          </div>

          <div onClick={(e) => planCheck(e, BUSINESS, PAYMENT_MODE_MSG)}>
            <Controls.Select
              name="payment_mode"
              label={
                <div className={rowFlexAlignCenter}>
                  Payment Mode <PaidTab plan={BUSINESS} />
                </div>
              }
              labelPlacement="top"
              labelFontWeight={400}
              value={product.payment_mode}
              onChange={changeHandler}
              options={paymentOptions}
              disabled={PLANS[store.plan]?.weight < PLANS[BUSINESS].weight}
              error={errors.payment_mode}
            />
          </div>
          {section.map(({ key, label, fields }) => (
            <>
              {fields.length > 0 && (
                <div
                  className={classes.flexCenterCenter}
                  style={{ marginBottom: 10, cursor: "pointer" }}
                  onClick={() => toggleSection(key)}
                >
                  <label className={classes.labels}>{label}</label>
                  {openSections[key] ? (
                    <UpArrowIcon stroke="#666666" />
                  ) : (
                    <DownArrowIcon stroke="#666666" />
                  )}
                </div>
              )}
              {openSections[key] &&
                fields
                  .filter((field) =>
                    shouldRenderField(field, selectedCategories)
                  )
                  .map((field) => {
                    const fieldKey =
                      field.group === "ATTRIBUTE"
                        ? "attributes"
                        : "extra_product_fields";
                    const fieldId = field.id.toString();
                    return field.name.toLowerCase() === "color" ||
                      field.name.toLowerCase() === "colour" ? (
                      <Controls.ColorInput
                        key={field.id}
                        id={field.group}
                        name={fieldId}
                        label={field.name}
                        labelPlacement="top"
                        labelFontWeight={400}
                        value={
                          product[fieldKey][fieldId]?.value
                            ? `${product[fieldKey][fieldId].value}:${
                                product[fieldKey][fieldId]?.data ?? ""
                              }`
                            : ""
                        }
                        onChange={changeField}
                      />
                    ) : (
                      <Controls.Input
                        id={field.group}
                        name={fieldId}
                        label={field.name}
                        labelPlacement="top"
                        labelFontWeight={400}
                        value={product[fieldKey][fieldId]?.value ?? ""}
                        onChange={changeField}
                      />
                    );
                  })}
            </>
          ))}

          {gmc && gmc.installed && (
            <RenderSection
              product={product}
              errors={errors}
              sectionLabel={"Google Merchant Center Fields"}
              fieldsConfig={gmcFieldsConfig}
              activeFields={nativeFields}
              handleChange={changeHandler}
            />
          )}

          <div className={classes.flexCenterBetween}>
            <div className={classes.flexCenter}>
              <label className={classes.labels}>Additional Fields</label>
              <div
                className={classes.addfields}
                onClick={() => setOpenFields(true)}
              >
                <AddIcon className={classes.addIcon} />
              </div>
            </div>
            <Controls.Switch
              name="show_online"
              label="Show Online"
              color="secondary"
              value={product.show_online}
              onChange={changeHandler}
            />
          </div>
          <div className={classes.flexCenterEnd} style={{ marginTop: 20 }}>
            <CombinedButtons
              outlinedBtnAction={onClose}
              solidBtnAction={handleSubmit}
              loading={loading}
            />
          </div>
        </Form>
      </div>
      {openPremiumModal && (
        <PaidPopUp
          open={openPremiumModal}
          close={() => setOpenPremiumModal(false)}
          plan={plan}
          subtxt={planUpsellMessage}
        />
      )}
      {preview && (
        <Modal
          open={Boolean(preview)}
          disableAutoFocus
          disableEnforceFocus
          onClose={() => setPreview(false)}
          className={classes.flexCenterCenter}
        >
          <img src={preview} style={{ height: "85%" }} alt="" />
        </Modal>
      )}

      {error.value && (
        <Toast
          open={error.value}
          message={error.message}
          close={() => setError({ value: false, message: "" })}
          severity="error"
        />
      )}
    </>
  );
};
export default ProductForm;
