import React, { useState, useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import { fetchBannerById } from "./bannerUtils";
import BannerForm from "./BannerForm";

const EditBanner = () => {
  const params = useParams();
  const history = useHistory();
  const [record, setRecord] = useState(null);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    fetchBannerById(params.bannerid).then((rec) => {
      setRecord(rec);
      setLoader(false);
    });
  }, [params.bannerid]);

  const onClose = () => {
    history.goBack();
  };

  const onSave = () => {
    history.goBack();
  };

  return (
    <div>
      {loader && (
        <div
          style={{
            position: "fixed",
            left: "50%",
            top: "50%",
            zIndex: "900",
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      )}

      {record && (
        <BannerForm record={record} onSave={onSave} onClose={onClose} />
      )}
    </div>
  );
};

export default EditBanner;
