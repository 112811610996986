import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import PageLoader from "../../../components/Layout/PageLoader";
import { fetchStoreCustomerDetails } from "../../../utils/customerUtils";
import CustomerForm from "./CustomerForm";

const AddCustomer = () => {
  const params = useParams();
  const history = useHistory();
  const [record, setRecord] = useState(null);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    fetchStoreCustomerDetails(params.customerid).then((rec) => {
      setRecord(rec);
      setLoader(false);
    });
  }, [params.customerid]);

  const onClose = () => {
    history.goBack();
  };
  const onSave = () => {
    history.goBack();
  };

  return (
    <div>
      {loader && <PageLoader />}
      {record && (
        <CustomerForm
          record={record}
          setRecord={setRecord}
          onSave={onSave}
          onClose={onClose}
        />
      )}
    </div>
  );
};

export default AddCustomer;
