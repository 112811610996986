import { makeStyles } from "@material-ui/styles";
export default makeStyles((theme) => ({
  heading: {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "20px",
    marginBottom: "20px",
  },
  subHeading: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16x",
    color: "#666666",
  },
  card: {
    // width: 214,
    height: "80px",
    borderRadius: "12px",
    padding: "12px",
    display: "flex",
    textTransform: "none",
    textAlign: "left",
    justifyContent: "flex-start",
    alignItems: "center",
    border: "1px solid #E1E1E1",
  },
  rupee: {
    fontSize: "16px",
    lineHeight: "16px",
    fontWeight: "normal",
  },
  actCard: {
    borderRadius: "12px",
    padding: "16px",
    border: "1px solid #E1E1E1",
    height: "212px",
    overflow: "overlay",
  },
  actContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  actHead: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "20px",
    marginTop: "-2px",
    marginBottom: "4px",
  },
  actDesc: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    color: "#7D7D7D",
  },
  divider: {
    "&:not(:last-child)": {
      borderTop: "1px solid #E6E6E6",
      marginBottom: 16,
      marginTop: 16,
    },
  },
  linkCard: {
    borderRadius: "12px",
    padding: "16px",
    border: "1px solid #E1E1E1",
    // height: "40px",
    width: "100%",
  },
  link: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#F4F6FD",
    borderRadius: "30px",
    padding: "10px 10px 10px 21px",
  },
  shareVia: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "400",
  },
  sociallink: {
    margin: "0px 6px",
    cursor: "pointer",
  },

  taskContainer: {
    display: "flex",
    flexDirection: "column",
    width: "33.33%",
  },
  taskTitle: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 400,
    marginTop: "12px",
    color: "#1A1A1A",
  },
  taskSubTitle: {
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: 400,
    color: "#666666",
  },

  bulkAction: {
    fontWeight: 400,
    fontSize: 12,
    borderRadius: 6,
    padding: 9,
    cursor: "pointer",
    "&:hover": {
      background: "#F5F7FC",
    },
    display: "flex",
    alignItems: "center",
  },
  sheet: {
    position: "absolute",
    top: 50,
    display: "flex",
    borderRadius: 6,
    background: "#FFFFFF",
    overflow: "hidden",
    zIndex: 1,
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
  },
  detailAnalytics: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    color: "#1641DB",
    fontSize: 14,
    fontFamily: "Noto Sans",
    fontWeight: "600",
    lineHeight: 4,
  },
  loading: { display: "flex", justifyContent: "center", marginTop: "250px" },
}));
