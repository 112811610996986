import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import FormModal from "../../../../components/common/FormModal";
import Input from "../../../../components/controls/Input";
import SolidButton from "../../../../components/buttons/SolidButton";
import OutlinedButton from "../../../../components/buttons/OutlinedButton";
import Toast from "../../../../components/Layout/Toast";
import { getStoreIdFromUrl } from "../../../../utils/storeUtil";
const ExportCta = () => {
  const initalData = {
    target_date: "",
  };
  const storeId = getStoreIdFromUrl();

  const [openExport, setOpenExport] = useState(false);
  const [formData, setFormData] = useState(initalData);
  const [errors, setErrors] = useState({});
  const [openToast, setOpenToast] = useState(false);

  const onClickExport = () => {
    setOpenExport(true);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onBlur = (e) => {
    const { name, value } = e.target;
    let error = "";
    if (name === "display_name" && value.trim() === "") {
      error = "Display name is required.";
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };
  const handleExport = () => {
    setOpenToast(true);
    console.log(formData);
  };

  return (
    <>
      <OutlinedButton onClick={onClickExport}>Export</OutlinedButton>
      <FormModal
        open={openExport}
        onClose={() => setOpenExport(false)}
        heading="Upcoming Contracts Report"
        width={370}
        maxHeight={65}
        hideBottomStickyButton={true}
      >
        <div style={{ borderTop: "1px solid #e1e1e1", paddingTop: "14px" }}>
          <div>
            <Typography style={{ fontSize: "14px", marginBottom: "15px" }}>
              Select a target date to download a report of all upcoming
              contracts due by that date
            </Typography>
            <Input
              type="date"
              name="target_date"
              label="Target date"
              value={formData.target_date}
              onChange={onChange}
              error={errors.target_date}
              onBlur={onBlur}
              labelFontWeight={400}
              labelPlacement="top"
              inputProps={{ maxLength: 30 }}
            />

            <div style={{ margin: "8px 0px 20px 0px", textAlign: "end" }}>
              <SolidButton onClick={handleExport}>Generate Report</SolidButton>
            </div>
          </div>
        </div>
        <Toast
          open={openToast}
          close={() => setOpenToast(false)}
          severity="success"
          message={
            <React.Fragment>
              Contract Report generation is scheduled. To download, please visit{" "}
              <a href={`${storeId}/reports`} rel="noopener noreferrer">
                Reports{" "}
              </a>
              section
            </React.Fragment>
          }
        />
      </FormModal>
    </>
  );
};

export default ExportCta;
