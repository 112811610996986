import React, { Fragment, useEffect, useRef, useState } from "react";
import { fetchProducts, position, unassignProducts } from "./AsyncUtils";
import ProductList from "./ProductList";
import DrawerCloseIcon from "../../../icons/DrawerCloseIcon";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import NoProducts from "./NoProducts";
import { useDispatch } from "react-redux";
import { editCategory } from "../../../redux/category/categoryActions";
import SortFilter from "./SortFilter";
import ProductSearch from "./ProductSearch";
import InfiniteScroll from "react-infinite-scroller";
import { KeyboardBackspace } from "@material-ui/icons";
import Toast from "../../../components/Layout/Toast";
import {
  ASSIGN_PRODUCT_CATEGORY,
  UPDATE_PRODUCT_CATEGORY,
} from "../../../redux/product/productTypes";

const useStyles = makeStyles({
  styckyBox: {
    padding: "15px 28px",
    paddingBottom: 0,
  },
  loader: {
    height: "calc(100vh)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  gridContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  heading: {
    fontWeight: 700,
    fontSize: 20,
  },

  flexBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px #E1E1E1 solid",
  },

  TotalProductText: {
    color: "#1A1A1A",
    fontSize: 16,
    fontWeight: "600",
  },

  secondaryAssignButton: {
    height: "36px",
    padding: 8,
    background: "white",
    borderRadius: 4,
    border: "1px #1641DB solid",
    color: "#1641DB",
    fontSize: 14,
    fontWeight: "600",
  },
  assignButtonContainer: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    padding: "15px",
    paddingLeft: 130,
    background: "white",
  },
  productListing: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: "20px",
    borderBottom: "1px #E1E1E1 solid",
  },
});

const ProductsDrawer = ({ category, closeProductsDrawer, childrenSlugs }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const headingRef = useRef(null);
  const scrollableRef = useRef(null);
  const [headingHeight, setHeadingHeight] = useState(0);

  const [progress, setProgress] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchKeywordForAllProd, setSearchKeywordForAllProd] = useState("");
  const [searchFlag, setSearchFlag] = useState(false);
  const [sortfilter, setSortFilter] = useState(
    category?.product_sorting ?? null
  );
  // eslint-disable-next-line no-unused-vars
  const [categoryfilter, setCategoryFilter] = useState("");
  const [products, setProducts] = useState([]);
  const [productsFetched, setProductsFetched] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [assignProductsPage, setAssignProductsPage] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [currentState, setCurrentState] = useState("LOADING");
  const [currentStateForAssignProdPage, setCurrentStateForAssignProdPage] =
    useState("LOADING");
  const [totAssignedProds, setTotAssignedProds] = useState(0);

  const [unassignLoading, setUnassignLoading] = useState(false);
  const [unassignSku, setUnassignSku] = useState(null);

  const [success, setSuccess] = useState({
    value: false,
    message: "",
  });
  const [error, setError] = useState({
    value: false,
    message: "",
  });

  useEffect(() => {
    if (!assignProductsPage) {
      if (searchKeyword) {
        setSearchFlag(true);
      }
      setCurrentState("LOADING");
      scrollToTop();
      const timer = setTimeout(
        () => {
          fetchAssignedProducts();
        },
        searchKeyword ? 750 : 0
      );
      return () => {
        clearTimeout(timer);
      };
    }
    // eslint-disable-next-line
  }, [searchKeyword, sortfilter]);

  useEffect(() => {
    if (assignProductsPage) {
      setCurrentStateForAssignProdPage("LOADING");
      scrollToTop();
      const timer = setTimeout(() => {
        fetchProductsToAssigned();
      }, 750);
      return () => {
        clearTimeout(timer);
      };
    }
    // eslint-disable-next-line
  }, [searchKeywordForAllProd]);

  const scrollToTop = () => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTop = 0;
    }
  };

  const fetchMoreData = async () => {
    if (!assignProductsPage) {
      if (currentState !== "LOADED") return;
      let data = await fetchAssignedProducts(
        products.length > 0 ? Math.ceil(products.length / 10) : 0,
        10
      );
      if (data?.content && data.content.length > 0) {
        setProducts(products.concat(data.content));
        if (data.last) {
          setCurrentState("ALL_LOADED");
        } else {
          setCurrentState("LOADED");
        }
      } else {
        setCurrentState("ALL_LOADED");
      }
    } else {
      if (currentStateForAssignProdPage !== "LOADED") return;
      let data = await fetchProductsToAssigned(
        productsFetched.length > 0 ? Math.ceil(productsFetched.length / 10) : 0,
        10
      );
      if (data?.content && data.content.length > 0) {
        setProductsFetched(productsFetched.concat(data.content));
        if (data.last) {
          setCurrentStateForAssignProdPage("ALL_LOADED");
        } else {
          setCurrentStateForAssignProdPage("LOADED");
        }
      } else {
        setCurrentStateForAssignProdPage("ALL_LOADED");
      }
    }
  };

  const fetchAssignedProducts = async (page = 0, size = 10) => {
    let filterValue = sortfilter ? sortfilter : "createdAt,desc";
    let catSlugs = `&cat-slugs%5B%5D=${category.slug}`;
    let rootCatSlugs =
      category.level === 0
        ? `&root-cat-slugs%5B%5D=${category.root_cat_slug}`
        : "";

    try {
      const response = await fetchProducts(
        searchKeyword.trim(),
        filterValue,
        catSlugs,
        rootCatSlugs,
        page,
        size
      );
      if (response.error) {
        setError({
          value: true,
          message: response.error,
        });
      } else {
        const { super_products } = response;
        if (page === 0) {
          if (!searchKeyword) {
            setTotAssignedProds(super_products.totalElements);
          }
          setProducts(super_products.content);
          setProgress(false);
          if (super_products.last) {
            setCurrentState("ALL_LOADED");
          } else {
            setCurrentState("LOADED");
          }
        } else {
          return super_products;
        }
      }
    } catch (error) {
      setError({
        value: true,
        message: error,
      });
      setProgress(false);
    }
  };

  const fetchProductsToAssigned = async (page = 0, size = 10, searchKey) => {
    let catSlugs = `&cat-slugs-not-in%5B%5D=${category.slug}${childrenSlugs
      .map((cslug) => `&cat-slugs-not-in%5B%5D=${cslug}`)
      .join("")}`;

    if (category.parent_cat_slug) {
      catSlugs += `&root-cat-slugs-not-in%5B%5D=${category.parent_cat_slug}`;
    }

    let rootCatSlugs = "";

    try {
      if (page === 0) {
        setLoadingProducts(true);
      }
      const response = await fetchProducts(
        searchKey ?? searchKeywordForAllProd.trim(),
        categoryfilter,
        catSlugs,
        rootCatSlugs,
        page,
        size
      );
      if (response.error) {
        setError({
          value: true,
          message: response.error,
        });
      } else {
        const { super_products } = response;
        if (page === 0) {
          setProductsFetched(super_products.content);
          setLoadingProducts(false);
          if (super_products.last) {
            setCurrentStateForAssignProdPage("ALL_LOADED");
          } else {
            setCurrentStateForAssignProdPage("LOADED");
          }
        } else {
          return super_products;
        }
      }
    } catch (error) {
      setError({
        value: true,
        message: error,
      });
      setLoadingProducts(false);
    }
  };

  const productsAssign = async (list) => {
    try {
      const response = await position(list, category.slug);
      if (response.error) {
        setError({
          value: true,
          message: response.error,
        });
      } else {
        const updateProducts = [...response, ...products];
        setProducts(updateProducts);
        setTotAssignedProds(totAssignedProds + response.length);
        setSuccess({
          value: true,
          message: "Products assigned successfully",
        });
      }
      setAssignProductsPage(false);
      setSelectedProducts([]);
      setSearchKeywordForAllProd("");

      dispatch({
        type: ASSIGN_PRODUCT_CATEGORY,
        payload: response,
      });
    } catch (error) {
      setError({
        value: true,
        message: error,
      });
    }
  };

  const productUnassign = (event) => {
    const { id: productSku } = event.target;
    const list = [
      {
        ssku: productSku,
        position: null,
      },
    ];
    unassignProduct(list);
  };

  const unassignProduct = async (list) => {
    if (unassignLoading) return;
    try {
      let productSku = list[0].ssku;
      setUnassignSku(productSku);
      setUnassignLoading(true);
      const response = await unassignProducts(list, category.slug);
      if (response.error) {
        setError({
          value: true,
          message: response.error,
        });
      } else {
        const updateProducts = products.filter(
          (product) => response[0] && product.prod_sku !== response[0].prod_sku
        );
        setProducts(updateProducts);
        setTotAssignedProds(totAssignedProds - 1);
        setSuccess({
          value: true,
          message: "Product unassigned successfully",
        });
        dispatch({
          type: UPDATE_PRODUCT_CATEGORY,
          payload: response[0],
        });
      }
    } catch (error) {
      setError({
        value: true,
        message: error,
      });
    }
    setUnassignLoading(false);
    setUnassignSku(null);
  };

  const persistSortOrder = async (query) => {
    try {
      const response = await dispatch(
        editCategory({
          id: category.id,
          product_sorting: query,
        })
      );
      if (response.error) {
        setError({
          value: true,
          message: response.error,
        });
      }
    } catch (error) {
      setError({
        value: true,
        message: error.message,
      });
    }
  };

  const onCheckboxChange = (selectedProduct) => {
    const found = selectedProducts.find(
      (product) => product.ssku === selectedProduct.ssku
    );
    if (found) {
      setSelectedProducts(
        selectedProducts.filter(
          (product) => product.ssku !== selectedProduct.ssku
        )
      );
    } else {
      setSelectedProducts([...selectedProducts, selectedProduct]);
    }
    return true;
  };

  const findPosition = (product, catSlug) => {
    const category = product.categories.find((cat) => cat.cat_slug === catSlug);
    return category ? category.position : undefined;
  };

  const reorder = (list, startIndex, endIndex) => {
    const items = Array.from(list);
    const [removed] = items.splice(startIndex, 1);
    if (endIndex !== "") {
      items.splice(endIndex, 0, removed);
    }
    return items;
  };

  const onDragEnd = async (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const { index: sourceIndex } = source;
    const { index: destinationIndex } = destination;

    setProducts(reorder(products, sourceIndex, destinationIndex));

    const product = products[sourceIndex];

    const upperProduct =
      destinationIndex === 0
        ? null
        : destinationIndex === products.length - 1
        ? products[products.length - 1]
        : sourceIndex < destinationIndex
        ? products[destinationIndex]
        : products[destinationIndex - 1];

    const bottomProduct =
      destinationIndex === 0
        ? products[0]
        : destinationIndex === products.length - 1
        ? null
        : sourceIndex < destinationIndex
        ? products[destinationIndex + 1]
        : products[destinationIndex];

    const upperPosition = upperProduct
      ? findPosition(upperProduct, category.slug)
      : findPosition(bottomProduct, category.slug) + 1;

    const bottomPosition = bottomProduct
      ? findPosition(bottomProduct, category.slug)
      : findPosition(upperProduct, category.slug) - 1;

    const finalPosition = (upperPosition + bottomPosition) / 2 || 0;

    const list = [
      {
        ssku: product.super_prod_sku,
        position: finalPosition,
      },
    ];

    try {
      const response = await position(list, category.slug);
      if (response.error) {
        setError({
          value: true,
          message: response.error,
        });
      } else {
        const index = products.findIndex(
          (product) => product.super_prod_sku === response[0].super_prod_sku
        );

        const updatedProducts =
          index !== -1
            ? products.map((product, currentIndex) =>
                currentIndex === index ? response[0] : product
              )
            : products;

        const items = reorder(updatedProducts, sourceIndex, destinationIndex);
        setProducts(items);
        setSuccess({
          value: true,
          message: "Category order updated successfully",
        });
      }
    } catch (error) {
      setError({
        value: true,
        message: error,
      });
    }
  };

  const manuallyOperation = async (product, item) => {
    if (item.value === "unassign") {
      unassignProduct([
        {
          ssku: product.super_prod_sku,
          position: null,
        },
      ]);
    } else {
      const destinationIndex =
        item.value === "top"
          ? 0
          : item.value === "bottom"
          ? products.length - 1
          : "";

      let sourceIndex = "";
      products.forEach((prod, index) => {
        if (prod.super_prod_sku === product.super_prod_sku) {
          sourceIndex = index;
        }
      });
      const list = [
        {
          ssku: product.super_prod_sku,
          position: item.value,
        },
      ];
      try {
        const response = await position(list, category.slug);
        if (response.error) {
          setError({
            value: true,
            message: response.error,
          });
        } else {
          const index = products.findIndex(
            (product) => product.super_prod_sku === response[0].super_prod_sku
          );
          const updatedProducts =
            index !== -1
              ? products.map((product, currentIndex) =>
                  currentIndex === index ? response[0] : product
                )
              : products;
          const items = reorder(updatedProducts, sourceIndex, destinationIndex);
          setProducts(items);
          setSuccess({
            value: true,
            message: "Category order updated successfully",
          });
        }
      } catch (error) {
        setError({
          value: true,
          message: error,
        });
      }
    }
  };

  const resetFetchProductsToAssigned = () => {
    setAssignProductsPage(true);
    setSearchKeywordForAllProd("");
    setCurrentStateForAssignProdPage("LOADING");
    fetchProductsToAssigned(0, 10);
  };

  useEffect(() => {
    if (headingRef.current) {
      const height = headingRef.current.getBoundingClientRect().height;
      setHeadingHeight(height);
    }
  }, []);

  const DrawerToastStyle = {
    position: "absolute",
    top: 12,
    left: "50%",
    transform: "translateX(-50%)",
    width: "80%",
  };

  if (progress) {
    return (
      <div className={classes.loader}>
        <CircularProgress style={{ color: "#1641db" }} />
      </div>
    );
  }

  return (
    <>
      <Grid
        className={classes.gridContainer}
        style={{
          ...(products.length === 0 &&
            !assignProductsPage &&
            !searchFlag && { height: "100%" }),
        }}
      >
        <Box className={classes.styckyBox}>
          {assignProductsPage ? (
            <Grid
              className={classes.flexBox}
              style={{ border: "none", justifyContent: "flex-start" }}
            >
              <IconButton
                aria-label="Close"
                aria-haspopup="true"
                color="inherit"
                onClick={() => {
                  setSelectedProducts([]);
                  setAssignProductsPage(false);
                  setSearchKeywordForAllProd("");
                }}
              >
                <KeyboardBackspace />
              </IconButton>

              <div className={classes.heading} style={{ marginLeft: 12 }}>
                {`Assign Products in ${category.name}`}
              </div>
            </Grid>
          ) : (
            <Grid className={classes.flexBox} style={{ border: "none" }}>
              <div className={classes.heading}>
                {`Products in ${category.name}`}
              </div>
              <IconButton
                aria-label="Close"
                aria-haspopup="true"
                color="inherit"
                onClick={closeProductsDrawer}
              >
                <DrawerCloseIcon />
              </IconButton>
            </Grid>
          )}

          {!(products.length === 0 && !assignProductsPage && !searchFlag) && (
            <Fragment>
              <Grid
                className={classes.flexBox}
                style={{ paddingTop: 16, paddingBottom: 16 }}
              >
                <ProductSearch
                  searchKeyword={
                    assignProductsPage ? searchKeywordForAllProd : searchKeyword
                  }
                  setSearchKeyword={(val) => {
                    assignProductsPage
                      ? setSearchKeywordForAllProd(val)
                      : setSearchKeyword(val);
                  }}
                />
                {!assignProductsPage && (
                  <SortFilter
                    sortfilter={sortfilter}
                    setSortFilter={setSortFilter}
                    persistSortOrder={persistSortOrder}
                  />
                )}
              </Grid>
              {!assignProductsPage && (
                <Grid
                  className={classes.flexBox}
                  style={{ paddingTop: 16, paddingBottom: 16 }}
                >
                  <div
                    className={classes.TotalProductText}
                  >{`Total Products (${totAssignedProds}) `}</div>
                  <div>
                    <Button
                      className={classes.secondaryAssignButton}
                      onClick={resetFetchProductsToAssigned}
                    >
                      Assign Products
                    </Button>
                  </div>
                </Grid>
              )}
            </Fragment>
          )}
        </Box>

        {products.length === 0 && !assignProductsPage && !searchFlag ? (
          <NoProducts
            category={category}
            fetchProductsToAssigned={resetFetchProductsToAssigned}
          />
        ) : (
          <Fragment>
            {loadingProducts ? (
              <div className={classes.loader}>
                <CircularProgress style={{ color: "#1641db" }} />
              </div>
            ) : (
              <>
                <Grid
                  ref={scrollableRef}
                  style={{
                    padding: "28px",
                    paddingTop: 0,
                    height: `calc(100% - ${headingHeight}px)`,
                    overflow: "auto",
                  }}
                >
                  <InfiniteScroll
                    pageStart={0}
                    loadMore={() => fetchMoreData()}
                    hasMore={
                      assignProductsPage
                        ? currentStateForAssignProdPage === "LOADED"
                        : currentState === "LOADED"
                    }
                    useWindow={false}
                    loader={
                      <div
                        style={{
                          marginTop: 20,
                          marginBottom: assignProductsPage && 50,
                          textAlign: "center",
                        }}
                        key={0}
                      >
                        <CircularProgress color="secondary" fontSize="small" />
                      </div>
                    }
                  >
                    <ProductList
                      products={assignProductsPage ? productsFetched : products}
                      filter={assignProductsPage ? categoryfilter : sortfilter}
                      assignProductsPage={assignProductsPage}
                      productUnassign={productUnassign}
                      onCheckboxChange={onCheckboxChange}
                      onDragEnd={onDragEnd}
                      manuallyOperation={manuallyOperation}
                      unassignSku={unassignSku}
                    />
                  </InfiniteScroll>
                </Grid>
                {productsFetched.length > 0 && assignProductsPage && (
                  <Grid className={classes.assignButtonContainer}>
                    <CombinedButtons
                      style={{ height: "40px", fontWeight: 500 }}
                      outlinedBtnText="Cancel"
                      outlinedBtnAction={() => {
                        setSelectedProducts([]);
                        setAssignProductsPage(false);
                        setSearchKeywordForAllProd("");
                      }}
                      solidBtnText={
                        selectedProducts.length >= 1
                          ? `Assign (${selectedProducts.length}) `
                          : "Assign"
                      }
                      disabled={selectedProducts.length === 0}
                      solidBtnAction={() => productsAssign(selectedProducts)}
                    />
                  </Grid>
                )}
              </>
            )}
          </Fragment>
        )}
      </Grid>

      {success.value && (
        <Toast
          open={success.value}
          message={success.message}
          close={() => setSuccess({ value: false, message: "" })}
          customStyle={DrawerToastStyle}
        />
      )}
      {error.value && (
        <Toast
          open={error.value}
          message={error.message}
          close={() => setError({ value: false, message: "" })}
          severity="error"
          customStyle={DrawerToastStyle}
        />
      )}
    </>
  );
};

export default ProductsDrawer;
