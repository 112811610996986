import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const StatusDotIcon = ({ fill = "#72C472", style }) => {
  return (
    <SvgIcon
      viewBox="0 0 8 8"
      style={{ ...style, width: "8px", height: "8px" }}
    >
      <svg
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="4" cy="4" r="4" fill={fill} />
      </svg>
    </SvgIcon>
  );
};

export default StatusDotIcon;
