import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HeaderBreadcrumbs from "../../components/common/HeaderBreadcrumbs";
import PageLoader from "../../components/Layout/PageLoader";
import {
  layoutBodyBox,
  layoutContainer,
  rowFlexCenterAll,
} from "../../styles/common";
import {
  fetchCustomerStoreTransactions,
  fetchStoreCustomerDetails,
} from "../../utils/customerUtils";
import TableHeaderCard from "./components/TableHeaderCard";
import StoreTransactionModal from "./components/StoreTransactionModal";
import StoreTransactionsTable from "./components/StoreTransactionsTable";
import { setCustomerBalance } from "../../redux/storeCustomers/storeCustomerActions";
import { useDispatch } from "react-redux";

const PAGE_SIZE = 20;

const CustomerStoreTransactions = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState(null);
  const [transactionModalType, toggleTransactionModalType] = useState("GIVEN");
  const [openModal, setOpenModal] = useState(false);
  const [txnsListData, setTxnsListData] = useState({
    content: [],
    last: false,
    empty: false,
    currentPage: 0,
  });

  useEffect(() => {
    fetchStoreCustomerDetails(params.customerid).then((data) =>
      setCustomer(data)
    );
    fetchPagedTransaction(0);
    // eslint-disable-next-line
  }, [params.customerid]);

  const fetchPagedTransaction = (page) => {
    setLoading(true);
    fetchCustomerStoreTransactions(params.customerid, PAGE_SIZE, page).then(
      (data) => {
        if (data) {
          setTxnsListData({
            content: [...txnsListData.content, ...data.content],
            currentPage: page,
            last: data.last,
          });
        }
        setLoading(false);
      }
    );
  };

  const saveRecordCallback = (record) => {
    setTxnsListData({
      ...txnsListData,
      content: [record, ...txnsListData.content],
    });
    setCustomer({
      ...customer,
      balance_amount: record.balance_amount,
    });
    dispatch(setCustomerBalance(record));
  };

  if (!customer) {
    return <PageLoader />;
  }

  return (
    <>
      <div className={layoutContainer}>
        <div>
          <HeaderBreadcrumbs
            list={[
              {
                label: "Customers",
                link: "/customers",
              },
              {
                label: customer.name ? customer.name : customer.mobile,
                link: `/customers/${customer.id}`,
              },
              {
                label: "Transactions",
              },
            ]}
          />
        </div>
      </div>
      <div className={rowFlexCenterAll}>
        <div
          className={layoutBodyBox}
          style={{
            maxWidth: 780,
            padding: "30px 0px 36px",
            marginTop: 34,
          }}
        >
          <TableHeaderCard
            icon={customer.balance_amount > 0 ? "pay-money" : "receive-money"}
            backgroundColor={
              customer.balance_amount > 0 ? "#E8F7F0" : "#FDE6EA"
            }
            value={"₹" + Math.abs(customer.balance_amount)}
            name={customer.name ? customer.name : customer.mobile}
            mobile={customer.mobile}
            subText={customer.balance_amount > 0 ? "You Give" : "You Recieve"}
            btn1Txt="Given"
            btn2Txt="Recieved"
            btn1Action={() => {
              toggleTransactionModalType("GIVEN");
              setOpenModal(true);
            }}
            btn2Action={() => {
              toggleTransactionModalType("RECEIVED");
              setOpenModal(true);
            }}
          />
          <StoreTransactionsTable
            txnsListData={txnsListData}
            fetchMore={fetchPagedTransaction}
            loading={loading}
          />
        </div>
      </div>
      <StoreTransactionModal
        name={customer.name}
        mobile={customer.mobile}
        balance={Math.abs(customer.balance_amount)}
        transactionModalType={transactionModalType}
        onClose={() => {
          setOpenModal(false);
        }}
        customerId={customer.id}
        subText={customer.balance_amount > 0 ? "You Give" : "You Recieve"}
        open={openModal}
        saveRecordCallback={saveRecordCallback}
      />
    </>
  );
};

export default CustomerStoreTransactions;
