import React from "react";

export default function ProductListCollectionIcon() {
  return (
    <svg
      width="73"
      height="60"
      viewBox="0 0 73 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.62931"
        y="0.62931"
        width="71.7414"
        height="58.7414"
        rx="4.40517"
        fill="white"
        stroke="#666666"
        strokeWidth="1.25862"
      />
      <path
        d="M4.86719 28.1631H68.1339V33.061H4.86719V28.1631Z"
        fill="#C4C4C4"
      />
      <rect
        x="4.86719"
        y="36.7344"
        width="63.2667"
        height="4.89796"
        fill="#C4C4C4"
      />
      <rect
        x="4.86719"
        y="46.5293"
        width="63.2667"
        height="4.89796"
        fill="#C4C4C4"
      />
      <rect
        x="4.86719"
        y="7.34766"
        width="13.7889"
        height="15.034"
        rx="1.56692"
        fill="#FF7D7D"
      />
      <rect
        x="20.9551"
        y="7.34766"
        width="13.7889"
        height="15.034"
        rx="1.56692"
        fill="#FF7D7D"
      />
      <rect
        x="37.041"
        y="7.34766"
        width="13.7889"
        height="15.034"
        rx="1.56692"
        fill="#FF7D7D"
      />
      <rect
        x="53.127"
        y="7.34766"
        width="13.7889"
        height="15.034"
        rx="1.56692"
        fill="#FF7D7D"
      />
    </svg>
  );
}
