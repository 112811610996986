import { Button, Modal, Typography } from "@material-ui/core";
import React, { useState } from "react";
import Dropzone from "react-dropzone";
import ErrorIcon from "../../../icons/ErrorIcon";
import {
  startJobCheck,
  updateBulkProduct,
  validateBulkProduct,
} from "../../../utils/productUtils";
import { getDefaultStore, getStoreIdFromUrl } from "../../../utils/storeUtil";
import useStyles from "../styles";
import DownloadIcon from "../../../icons/DownloadIcon";
import { useDispatch } from "react-redux";
import { setBulkToast } from "../../../redux/product/productActions";
import OutlinedButton from "../../../components/buttons/OutlinedButton";
import FormModal from "../../../components/common/FormModal";
import { DoneAll } from "@material-ui/icons";
import CloseButton from "../../../components/buttons/CloseButton";
import SolidButton from "../../../components/buttons/SolidButton";
import { saveCsvDocument } from "../../../utils/documentUtil";

const BulkEdit = ({ open, toggleState, onExport, loading, qty }) => {
  const classes = useStyles();

  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [verified, setVerified] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState("");
  const [invalidRows, setInvalidRows] = useState(null);
  const [imported, setImported] = useState(false);
  const dispatch = useDispatch();

  const onUpdate = () => {
    setUpdating(true);
    const formData = new FormData();
    formData.append("file", uploadedFile);
    updateBulkProduct(formData)
      .then((res) => {
        setImported(true);
        startJobCheck(res.data.payload.id, getStoreIdFromUrl()).then(
          (success) => {
            setUpdating(false);
            if (success) {
              dispatch(
                setBulkToast({
                  message: "Products updated sucessfully, please refresh.",
                  severity: "success",
                })
              );
            } else {
              dispatch(
                setBulkToast({
                  message:
                    "Products update failed. Please contact support for assist.",
                  severity: "error",
                })
              );
            }
          }
        );
      })
      .catch(() => {
        setUpdating(false);
        dispatch(
          setBulkToast({
            message:
              "Products update failed. Please contact support for assist.",
            severity: "error",
          })
        );
      });
  };

  const reset = () => {
    setUploading(false);
    setError("");
    setInvalidRows(null);
    setVerified(false);
    setVerifying(false);
    setUploadedFile(null);
    setImported(false);
  };

  const handleDrop = (acceptedFiles) => {
    setError("");
    setUploading(true);
    const file = acceptedFiles[0];
    if (file.size > 5e6) {
      setError("Max file size exceeded");
      return;
    }
    setUploadedFile(file);
    setUploading(false);
    verify(file);
  };

  const verify = (file) => {
    setVerifying(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("org-id", getDefaultStore().org_id);
    validateBulkProduct(formData, true)
      .then((res) => {
        if (res.data.payload.header_error) {
          let message =
            "Missing required field(s): " +
            res.data.payload.data.map((obj) => obj.comment).join(", ");
          setError(message);
          setVerifying(false);
          return;
        }
        if (res.data.payload.data_error_count > 0) {
          setError(`${res.data.payload.data_error_count} Invalid Row(s)`);
          setVerifying(false);
          setInvalidRows(res.data.payload.data);
          return;
        }
        setVerified(true);
        setVerifying(false);
      })
      .catch(() => {
        setError(
          "Something went wrong. Please check the file and try again. Contact support if issue continues"
        );
        setVerifying(false);
      });
  };

  const onExportErrors = () => {
    saveCsvDocument(invalidRows, "ProductErrors.csv");
  };

  const onClose = () => {
    toggleState(false);
    reset();
  };

  if (imported) {
    return (
      <Modal
        open={open}
        onClose={onClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        disableAutoFocus
      >
        <div
          style={{
            padding: 25,
            borderRadius: 12,
            position: "absolute",
            background: "#ffffff",
            width: 546,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 25,
            }}
          >
            <Typography className={classes.heading} style={{ fontSize: 19 }}>
              Update In Progress
            </Typography>
            <CloseButton onClick={onClose} />
          </div>
          <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 12 }}>
            We are updating your products, process will take some time. Please
            refresh after sometime to see the newly added products, meanwhile
            sit back and relax! <br /> <br />
            For any issue
            <a
              href="https://wa.me/917651838757"
              target="_blank"
              rel="noopener noreferrer"
              style={{ margin: "0px 5px", cursor: "pointer" }}
            >
              contact support
            </a>
          </Typography>
          <div
            style={{
              height: "50px",
              position: "relative",
              marginTop: 28,
            }}
          >
            <div style={{ position: "absolute", bottom: 0, right: 0 }}>
              <SolidButton onClick={onClose}>Continue</SolidButton>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <FormModal
      open={open}
      onClose={onClose}
      onSave={onUpdate}
      heading="Bulk Update"
      btnTxt="Update"
      width={624}
      disableSave={!verified}
      loading={updating}
    >
      <div className={classes.dividerBox}>
        <div>
          <div className={classes.heading}>1. Export your products</div>
          <div className={classes.subTxt}>
            To get started export your products in a CSV file
          </div>
        </div>
        <OutlinedButton
          style={{ width: 176, fontSize: 14, fontWeight: 600, height: 40 }}
          onClick={onExport}
          loading={loading}
        >
          Export {qty} Products
        </OutlinedButton>
      </div>
      <div className={classes.dividerBox}>
        <div>
          <div className={classes.heading}>2. Edit the CSV file</div>
          <div className={classes.subTxt}>
            Make the changes you need in the CSV file. Remove the columns or rows you do not want the system to update. e.g If you don't want to update quantity, remove it from the file. The SKU column must always be present.
          </div>
        </div>
      </div>
      <div className={classes.dividerBox}>
        <div>
          <div className={classes.heading}>3. Upload the modified CSV file</div>
          <div className={classes.subTxt}>
            Once your file is ready, upload it so the system can process your
            changes.
          </div>
        </div>
        <Dropzone
          onDrop={handleDrop}
          multiple={false}
          maxSize={5e6}
          accept=".csv"
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} style={{ outline: "none" }}>
              <input {...getInputProps()} />
              <OutlinedButton
                style={{
                  width: 176,
                  fontSize: 14,
                  fontWeight: 600,
                  height: 40,
                }}
                loading={uploading || verifying}
                startIcon={verified ? <DoneAll fontSize="small" /> : <></>}
              >
                {verified ? "Uploaded" : "Upload your file"}
              </OutlinedButton>
            </div>
          )}
        </Dropzone>
      </div>
      {error && (
        <div className={classes.errorDiv}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ErrorIcon />
            <Typography
              style={{ marginLeft: 12, fontSize: 12, fontWeight: 600 }}
            >
              {error}
            </Typography>
          </div>
          {invalidRows && (
            <Button
              className={classes.downloadBtn}
              endIcon={<DownloadIcon />}
              onClick={onExportErrors}
            >
              Download
            </Button>
          )}
        </div>
      )}
    </FormModal>
  );
};

export default BulkEdit;
