import React from "react";

const FilterIcon = () => {
  return (
    <svg
      width="17"
      height="11"
      viewBox="0 0 17 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 11V9.49998H5.37498V11H0ZM0 6.24995V4.75H11.1827V6.24995H0ZM0 1.49998V0H17V1.49998H0Z"
        fill="#1C1B1F"
      />
    </svg>
  );
};

export default FilterIcon;
