import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import HeaderBreadcrumbs from "../../components/common/HeaderBreadcrumbs";
import PageLoader from "../../components/Layout/PageLoader";
import PaidPopUp from "../../components/PaidPopUp";
import { setCustomerWalletPoints } from "../../redux/storeCustomers/storeCustomerActions";
import {
  layoutBodyBox,
  layoutContainer,
  rowFlexCenterAll,
} from "../../styles/common";
import {
  fetchCustomerWalletTransactions,
  fetchStoreCustomerDetails,
} from "../../utils/customerUtils";
import { BUSINESS_APP_PRO, PLANS } from "../plans/constants";
import TableHeaderCard from "./components/TableHeaderCard";
import WalletTransactionModal from "./components/WalletTransactionModal";
import WalletTransactionsTable from "./components/WalletTransactionsTable";

const PAGE_SIZE = 20;

const CustomerWalletTransactions = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const store = state.store.store;
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState(null);
  const [transactionModalType, toggleOpenTransactionModal] = useState(null);
  const [openPlanModal, setOpenPlanModal] = useState(false);
  const [planMessage, setPlanMessage] = useState("");
  const [txnsListData, setTxnsListData] = useState({
    content: [],
    last: false,
    empty: false,
    currentPage: 0,
  });

  useEffect(() => {
    fetchStoreCustomerDetails(params.customerid).then((data) =>
      setCustomer(data)
    );
    fetchPagedWalletTransaction(0);
    // eslint-disable-next-line
  }, [params.customerid]);

  const fetchPagedWalletTransaction = (page) => {
    setLoading(true);
    fetchCustomerWalletTransactions(params.customerid, PAGE_SIZE, page).then(
      (data) => {
        if (data) {
          setTxnsListData({
            content: [...txnsListData.content, ...data.content],
            currentPage: page,
            last: data.last,
          });
        }
        setLoading(false);
      }
    );
  };

  const saveRecordCallback = (record) => {
    setTxnsListData({
      ...txnsListData,
      content: [record, ...txnsListData.content],
    });
    setCustomer({
      ...customer,
      wallet_points: record.wallet_points,
    });
    let payload = {
      ...record,
      customer_id: customer.customer_id,
    };
    dispatch(setCustomerWalletPoints(payload));
  };

  if (!customer) {
    return <PageLoader />;
  }

  return (
    <>
      <div className={layoutContainer}>
        <div>
          <HeaderBreadcrumbs
            list={[
              {
                label: "Customers",
                link: "/customers",
              },
              {
                label: customer.name ? customer.name : customer.mobile,
                link: `/customers/${customer.id}`,
              },
              {
                label: "Wallet",
              },
            ]}
          />
        </div>
      </div>
      <div className={rowFlexCenterAll}>
        <div
          className={layoutBodyBox}
          style={{
            maxWidth: 780,
            padding: "30px 0px 36px",
            marginTop: 34,
          }}
        >
          <TableHeaderCard
            icon="wallet"
            value={Math.abs(customer.wallet_points)}
            backgroundColor="#FFF3E3"
            name={customer.name ? customer.name : customer.mobile}
            mobile={customer.mobile}
            subText="Wallet Points -"
            btn1Txt="Issue"
            btn2Txt="Withdraw"
            btn1Action={() => {
              if (PLANS[store.plan].weight < PLANS.BUSINESS_APP_PRO.weight) {
                setOpenPlanModal(true);
                setPlanMessage(
                  "Start offering Wallet Points to your Loyal Customers and Grow Business"
                );
                return;
              }
              toggleOpenTransactionModal("ISSUED");
            }}
            btn2Action={() => {
              if (PLANS[store.plan].weight < PLANS.BUSINESS_APP_PRO.weight) {
                setOpenPlanModal(true);
                setPlanMessage(
                  "Start withdrawing Wallet Points from your Customers"
                );
                return;
              }
              toggleOpenTransactionModal("WITHDREW");
            }}
          />
          <WalletTransactionsTable
            txnsListData={txnsListData}
            fetchMore={fetchPagedWalletTransaction}
            loading={loading}
          />
        </div>
      </div>
      <WalletTransactionModal
        name={customer.name}
        walletPoints={customer.wallet_points}
        saveRecordCallback={saveRecordCallback}
        transactionModalType={transactionModalType}
        onClose={() => toggleOpenTransactionModal(false)}
        customerId={customer.id}
      />
      <PaidPopUp
        open={openPlanModal}
        close={() => setOpenPlanModal(false)}
        plan={BUSINESS_APP_PRO}
        subtxt={planMessage}
      />
    </>
  );
};

export default CustomerWalletTransactions;
