export const menuItemOptions = [
  { id: "CATEGORY", title: "Category" },
  { id: "COLLECTION", title: "Collection" },
  { id: "PAGE", title: "Page" },
  { id: "CUSTOM", title: "Custom" },
];

export const createCollectionOptions = (newColl) => {
  if (newColl.length === 0) {
    return [];
  }
  let collOptions = [];
  let index = 0;
  newColl.forEach((coll, ind) => {
    collOptions[index++] = {
      id: coll.id,
      title: coll.title !== " " ? coll.title : `Collection #${ind + 1}`,
      slug: coll.slug ?? "",
    };
  });
  return collOptions;
};
export const createPageOptions = (newPage) => {
  if (newPage.length === 0) {
    return [];
  }
  let pageOptions = [];
  let index = 0;
  newPage.forEach((page) => {
    pageOptions[index++] = {
      id: page.slug,
      title: page.title,
    };
  });
  return pageOptions;
};

export const getMenuColor = (color) => {
  switch (color?.toLowerCase()) {
    case "#f30020":
      return {
        color: "#FFFFFF",
        bgColor: "#F30020",
      };

    case "#f6740a":
      return {
        color: "#FFFFFF",
        bgColor: "#3A3E46",
      };

    case "#296fc2":
      return {
        color: "#FFFFFF",
        bgColor: "#296fc2",
      };

    case "#fbb4c2":
      return {
        color: "#000000",
        bgColor: "#fbb4c2",
      };

    case "#f33396":
      return {
        color: "#FFFFFF",
        bgColor: "F33396",
      };

    case "#e02858":
      return {
        color: "#e02858",
        bgColor: "#FFFFFF",
      };

    case "#2a5ee1":
      return {
        color: "#FFFFFF",
        bgColor: "#2a5ee1",
      };

    case "#654321":
      return {
        color: "#654321",
        bgColor: "#FAF3EC",
      };

    case "#444444":
      return {
        color: "#444444",
        bgColor: "#F7F7F7",
      };
    case "#29a56c":
      return {
        color: "#FFFFFF",
        bgColor: "#29a56c",
      };
    case "#f6740c":
      return {
        color: "#3A3E46",
        bgColor: "#FFFFFF",
      };
    case "#ed6f1e":
      return {
        bgColor: "#2C2C2C",
        color: "#FFFFFF",
      };
    case "#4eb547":
      return {
        bgColor: "#4eb547",
        color: "#000000",
      };
    default:
      return {
        color: "#FFFFFF",
        bgColor: color,
      };
  }
};
