import React from "react";
import { NoPicIcon } from "../../../../icons";
import { CancelOutlined } from "@material-ui/icons";
import { cardImage, cardImageFallback, cardStyle } from "./formStyles";
const SelectedItem = ({ item, onRemove }) => {
  return (
    <div className={cardStyle}>
      {item.image ? (
        <img
          src={item.image}
          alt={`${item.title}`}
          className={cardImage}
        />
      ) : (
        <div className={cardImageFallback}>
          <NoPicIcon />
        </div>
      )}
      <div style={{ flexGrow: 1, margin: "0px 8px" }}>
        <p
          style={{
            marginBottom: "0px",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "20px",
            color: "#1A1A1A",
          }}
        >
          {item.title}
        </p>
        <p
          style={{
            marginBottom: "0px",
            fontSize: "12px",
            lineHeight: "16px",
            fontWeight: 400,
            color: "#666666",
            marginTop: "4px",
          }}
        >
          {item.subTitle}
        </p>
      </div>
      <CancelOutlined
        htmlColor="#1614DB"
        style={{ fontSize: "16px", cursor: "pointer" }}
        onClick={onRemove.bind(null, false, item)}
      />
    </div>
  );
};
export default SelectedItem;
