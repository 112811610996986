import { CLEAR_DATA, CLEAR_COMPLETE } from "../commom/commonTypes";
import {
  FETCH_ESTIMATES,
  ESTIMATE_PROGRESS,
  UPDATE_ESTIMATE,
  SET_ESTIMATE_ERROR,
  SET_ESTIMATE_LOADER,
  CREATE_ESTIMATE,
  CLEAR_ESTIMATE,
  CLEAR_COMPLETE_ESTIMATE,
  DELETE_ESTIMATE,
  ESTIMATE_DATE_SELECTOR,
  ESTIMATE_STATUS_SELECTOR,
  ESTIMATE_DATE_RANGE,
  SET_ESTIMATES_PER_PAGE,
  SET_ESTIMATES_CURRENT_PAGE,
  SET_ESTIMATES_MAX_PAGE_SO_FAR,
  SET_ESTIMATES_INPUT_KEYWORD,
  SET_ESTIMATES_SEARCH_KEYWORD,
  SET_ESTIMATES_CUSTOMER_ID,
} from "./estimateTypes";

const initialState = {
  estimates: [],
  estimatesMap: {},
  estimate: null,
  numberOfPages: 0,
  numberOfElements: 0,
  progress: true,
  loader: false,
  error: null,
  dateSelector: null,
  statusSelector: null,
  dateRange: {
    aoe: null,
    boe: null,
  },
  estimatesPerPage: null,
  currentPage: 0,
  maxPageSoFar: 0,
  searchKeyword: "",
  inputKeyword: "",
  customerId: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ESTIMATE_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      };
    case FETCH_ESTIMATES:
      let allEstimates = state.estimates;
      let page = action.payload.pageable.pageNumber;
      if (page > 0 && state.estimates.length > action.estimatesPerPage * page) {
        allEstimates = state.estimates.slice(0, action.estimatesPerPage * page);
      }
      let newEstimates =
        action.payload.pageable.pageNumber > 0
          ? [...allEstimates, ...action.payload.content]
          : action.payload.content;

      let newEstimatesMap = {};
      newEstimates.forEach((element) => {
        newEstimatesMap[element.invoice_id] = element;
      });
      return {
        ...state,
        estimates: newEstimates,
        estimatesMap: newEstimatesMap,
        numberOfPages: action.payload.totalPages,
        numberOfElements: action.payload.totalElements,
        progress: false,
      };
    case CREATE_ESTIMATE:
      let pageCnt = state.numberOfPages;
      let localPageEstimates = state.estimates;
      let count = state.estimates.length;
      if (count + 1 > state.estimatesPerPage) {
        let fullPages = Math.floor((count + 1) / state.estimatesPerPage);
        localPageEstimates = state.estimates.slice(
          0,
          fullPages * state.estimatesPerPage - 1
        );
      }
      let availablePages = Math.floor(
        state.estimates.length / state.estimatesPerPage
      );
      return {
        ...state,
        estimate: action.payload,
        loader: false,
        progress: false,
        estimates: [action.payload, ...localPageEstimates],
        numberOfPages:
          state.numberOfElements === pageCnt * state.estimatesPerPage
            ? pageCnt + 1
            : pageCnt,
        numberOfElements: state.numberOfElements + 1,
        currentPage: 0,
        maxPageSoFar: availablePages > 1 ? availablePages - 1 : 0,
      };
    case UPDATE_ESTIMATE:
      let modEstimates = state.estimates.map((est) => {
        if (est.id === action.payload.id) {
          return action.payload;
        }
        return est;
      });

      let modEstimatesMap = {};
      modEstimates.forEach((element) => {
        modEstimatesMap[element.invoice_id] = element;
      });

      return {
        ...state,
        estimates: modEstimates,
        estimatesMap: modEstimatesMap,
        estimate: action.payload,
        loader: false,
        progress: false,
      };

    case SET_ESTIMATE_ERROR:
      return {
        ...state,
        error: action.payload.error,
        progress: false,
      };
    case SET_ESTIMATE_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case CLEAR_ESTIMATE:
      return {
        ...state,
        purchase: action.payload,
      };
    case DELETE_ESTIMATE:
      return {
        ...state,
        estimates: state.estimates.filter(
          (estimate) => estimate.id !== action.payload.estimateId
        ),
        loader: false,
      };
    case CLEAR_COMPLETE_ESTIMATE:
      return {
        ...state,
        estimates: [],
        estimatesMap: {},
        estimate: null,
        numberOfPages: 0,
        numberOfElements: 0,
        progress: false,
        loader: false,
        error: null,
      };
    case CLEAR_COMPLETE:
    case CLEAR_DATA:
      return {
        ...state,
        estimates: [],
        estimatesMap: {},
        estimate: null,
        numberOfPages: 0,
        numberOfElements: 0,
        progress: true,
        loader: false,
        error: null,
        dateSelector: null,
        statusSelector: null,
        dateRange: {
          aoe: null,
          boe: null,
        },
        estimatesPerPage: null,
        currentPage: 0,
        maxPageSoFar: 0,
        searchKeyword: "",
        inputKeyword: "",
      };
    case ESTIMATE_DATE_SELECTOR:
      return {
        ...state,
        dateSelector: action.payload,
      };
    case ESTIMATE_STATUS_SELECTOR:
      return {
        ...state,
        statusSelector: action.payload,
      };
    case ESTIMATE_DATE_RANGE:
      return {
        ...state,
        dateRange: action.payload,
      };
    case SET_ESTIMATES_PER_PAGE:
      return {
        ...state,
        estimatesPerPage: action.payload,
      };
    case SET_ESTIMATES_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SET_ESTIMATES_MAX_PAGE_SO_FAR:
      return {
        ...state,
        maxPageSoFar: action.payload,
      };
    case SET_ESTIMATES_INPUT_KEYWORD:
      return {
        ...state,
        inputKeyword: action.payload,
      };
    case SET_ESTIMATES_SEARCH_KEYWORD:
      return {
        ...state,
        searchKeyword: action.payload,
      };
    case SET_ESTIMATES_CUSTOMER_ID:
      return {
        ...state,
        customerId: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
