// Core react imports
import React from "react";
// Mui related imports
import Snackbar from "@material-ui/core/Snackbar";
// Style related stuff
import { rowFlexJustifyBetween } from "../../styles/common";
import { style } from "typestyle";
const notificationBase = style({
  backgroundColor: "#FFF",
  padding: "12px",
  boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.11)",
  borderRadius: "8px",
});
const iconBase = style({
  marginRight: "15px",
  padding: "5px",
  backgroundColor: "#F4F6FD",
  borderRadius: "8px",
});
const subTitle_style = style({
  margin: "0px",
  fontSize: "12px",
  fontWeight: "400",
  lineHeight: "20px",
  color: "#7D7D7D",
});
const title_style = style({
  margin: "0px",
  fontWeight: "600",
  fontSize: "14px",
  lineHeight: "20px",
  color: "#1A1A1A",
});
const actionButton = style({
  margin: 0,
  marginRight: "8px",
  marginLeft: "20px",
  cursor: "pointer",
  fontSize: "16px",
  lineHeight: "20px",
  fontWeight: "500",
  color: "#1641DB",
  "&:hover": {
    textDecoration: "underline",
  },
});
// Component declaration
const PushNotification = ({ title, subTitle, close, onClick }) => {
  return (
    <Snackbar
      open={true}
      onClose={close}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      style={{ top: 80 }}
    >
      <div className={`${rowFlexJustifyBetween} ${notificationBase}`}>
        <div className={iconBase}>
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 0C6.72 0 0 6.72 0 15C0 23.28 6.72 30 15 30C23.28 30 30 23.28 30 15C30 6.72 23.28 0 15 0ZM15 24.75C13.755 24.75 12.75 23.745 12.75 22.5H17.25C17.25 23.745 16.245 24.75 15 24.75ZM21 21H9C8.175 21 7.5 20.325 7.5 19.5C7.5 18.675 8.175 18 9 18V13.5C9 10.71 10.92 8.385 13.5 7.71V6.75C13.5 5.925 14.175 5.25 15 5.25C15.825 5.25 16.5 5.925 16.5 6.75V7.71C19.08 8.385 21 10.71 21 13.5V18C21.825 18 22.5 18.675 22.5 19.5C22.5 20.325 21.825 21 21 21Z"
              fill="#16AC67"
            />
          </svg>
        </div>
        <div style={{ flexGrow: 1 }}>
          <p className={title_style}>{title}</p>
          <p className={subTitle_style}>{subTitle}</p>
        </div>
        <div style={{ borderLeft: "1px solid #E6E6E6", marginLeft: "30px" }}>
          <p className={actionButton} onClick={onClick}>
            View
          </p>
        </div>
      </div>
    </Snackbar>
  );
};

export default PushNotification;
