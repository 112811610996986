import React, { useEffect, useState } from "react";
import useStyles from "../components/ConfigStyles";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchApps,
  getPluginConfig,
  postPluginConfig,
} from "../../../redux/apps/appActions";
import PageLoader from "../../../components/Layout/PageLoader";
import { centerContainer, layoutCenterContainer } from "../../../styles/common";
import HeaderBreadcrumbs from "../../../components/common/HeaderBreadcrumbs";
import { Paper, Typography } from "@material-ui/core";
import Toast from "../../../components/Layout/Toast";
import Controls from "../../../components/controls/Controls";
import FilledEditButton from "../../../components/buttons/FilledEditButton";
import CombinedButtons from "../../../components/buttons/CombinedButtons";

const list = [
  {
    label: "App Store",
    link: "/apps",
  },
  {
    label: "Shipway",
    link: "/apps/shipway",
  },
  {
    label: "Configure",
  },
];

const shipwayLink =
  "https://app.shipway.com/register_new?utm_source=homepage&utm_medium=organic&utm_campaign=website";

export default function Shipway() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const storeData = useSelector((state) => state.store);
  const appState = useSelector((state) => state.apps);
  const apps = appState.apps;

  const initialState = {
    email: {
      value: "",
      touched: false,
      error: null,
    },
    password: {
      value: "",
      touched: false,
      error: null,
    },
  };
  const [formState, setFormState] = useState(initialState);
  const [isEditing, setIsEditing] = useState(true);
  const [loading, setLoading] = useState(true);
  const [pluginLoading, setPluginLoading] = useState(true);
  const [savingConfig, setSavingConfig] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (apps.length === 0) {
      dispatch(fetchApps());
    }
    // eslint-disable-next-line
  }, [apps.length]);

  useEffect(() => {
    if (storeData.fetched) {
      dispatch(getPluginConfig("shipway"));
    }
    //eslint-disable-next-line
  }, [storeData]);

  useEffect(() => {
    if (appState.shipway && !Object.keys(appState.shipway).length) {
      setPluginLoading(false);
    }
    if (Object.keys(appState.shipway).length > 0) {
      setPluginLoading(false);
      const formData = Object.keys(appState.shipway).reduce((acc, key) => {
        acc[key] = {
          value: appState.shipway[key],
          touched: false,
          error: null,
        };
        return acc;
      }, {});

      setIsEditing(false);
      setFormState(formData);
    }
    setLoading(false);
    // eslint-disable-next-line
  }, [appState.shipway]);

  const handleChange = (event) => {
    let { name, value } = event.target;

    setFormState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value: value,
        touched: true,
      },
    }));
  };

  const getMessage = (name) => {
    const messages = {
      email: "please enter valid email",
      licenseKey: "Please enter valid License Key",
    };
    return messages[name] || null;
  };

  const onBlur = (event) => {
    const { name } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        error: !prevState[name].value ? getMessage(name) : "",
      },
    }));
  };

  const onFocus = (event) => {
    const { name } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        error: null,
      },
    }));
  };

  const handleEdit = () => {
    setIsEditing(true);
    setFormState((prevState) => ({
      ...prevState,
      password: {
        ...prevState.password,
        value: "",
        touched: true,
      },
    }));
  };

  const validatation = () => {
    let errorFlag = false;
    const updatedFormData = Object.keys(formState).reduce((acc, key) => {
      let isEmpty = !formState[key].value;
      let errorMessage;

      if (isEmpty) {
        errorFlag = true;
        errorMessage = getMessage(key);
      }

      const updatedField = {
        ...formState[key],
        error: errorMessage,
      };

      return { ...acc, [key]: updatedField };
    }, {});
    setFormState(updatedFormData);
    return errorFlag;
  };

  const handleOutlined = () => {
    if (isEditing && Object.keys(appState.shipway).length > 0) {
      const formData = Object.keys(appState.shipway).reduce((acc, key) => {
        acc[key] = {
          value: appState.shipway[key],
          touched: false,
          error: null,
        };
        return acc;
      }, {});
      setFormState(formData);
      setIsEditing(false);
    } else {
      history.goBack();
    }
  };

  const handleSave = () => {
    let isError = validatation();
    if (isError) return;

    setSavingConfig(true);
    dispatch(
      postPluginConfig("shipway", {
        email: formState.email.value,
        password: formState.password.value,
      })
    ).then((configObj) => {
      if (configObj.error) {
        setError(configObj.message);
        setSavingConfig(false);
      } else {
        setSuccess("Shipway configuration settings saved successfully.");
        setSavingConfig(false);
      }
    });
  };

  if (loading || pluginLoading) return <PageLoader />;

  return (
    <div className={layoutCenterContainer}>
      <div className={centerContainer}>
        <div className={classes.breadcrumbsContainer}>
          <div className={classes.backDiv}>
            <HeaderBreadcrumbs list={list} />
          </div>
        </div>
        <Paper elevation={0} className={classes.paper}>
          <div>
            <div className={classes.headingButtonSection}>
              <Typography className={classes.formHead}>
                Shipway Configuration Settings
              </Typography>
              {!isEditing && <FilledEditButton onClick={handleEdit} />}
            </div>
            <div>
              <Controls.Input
                name="email"
                label="Email"
                labelPlacement="top"
                value={formState.email.value}
                onChange={handleChange}
                onFocus={onFocus}
                onBlur={onBlur}
                error={formState.email.error}
                helperText={
                  !formState.email.error &&
                  "Enter your registered email with Shipway."
                }
                extraSpace={formState.email.error && true}
              />

              <Controls.Input
                name="password"
                label="License Key"
                labelPlacement="top"
                value={formState.password.value}
                onChange={handleChange}
                onFocus={onFocus}
                onBlur={onBlur}
                error={formState.password.error}
                helperText={
                  !formState.password.error && (
                    <span>
                      <span style={{ fontWeight: 600 }}>Note:</span>{" "}
                      {"Copy your License Key from, "}
                      <span style={{ color: "black" }}>
                        {"Shipway > Profile > Manage Profile."}
                      </span>
                    </span>
                  )
                }
                extraSpace={formState.password.error && true}
                type={isEditing ? "text" : "password"}
              />
            </div>
            {!!isEditing && (
              <div style={{ marginTop: 10 }}>
                Don’t have a Shipway Account?{" "}
                <a href={shipwayLink} target="_blank" rel="noopener noreferrer">
                  Create account
                </a>
              </div>
            )}
          </div>
          {isEditing && (
            <div className={classes.button}>
              <CombinedButtons
                outlinedBtnText="Back"
                outlinedBtnAction={handleOutlined}
                solidBtnAction={handleSave}
                loading={savingConfig}
              />
            </div>
          )}
        </Paper>
      </div>
      <Toast
        autoClose
        message={success}
        open={!!success}
        close={() => setSuccess(null)}
      />
      <Toast
        autoClose
        message={error}
        open={!!error}
        close={() => setError(null)}
        severity="error"
      />
    </div>
  );
}
