import {
  SET_SORT_BY,
  FETCH_SUPER_PROD,
  SET_SUPER_PROD_ERROR,
  SET_SUPER_PROD_LOADER,
  SET_SUPER_PROD_PER_PAGE,
  SET_SUPER_PROD_CURRENT_PAGE,
  SET_SUPER_PROD_MAX_PAGE_SO_FAR,
  SET_SUPER_PROD_SEARCH_KEYWORD,
  SET_SUPER_PROD_INPUT_KEYWORD,
  CLEAR_SUPER_PROD_PLANS,
  SUPER_PROD_PROGRESS,
} from "./superProdTypes";
import { CLEAR_DATA, CLEAR_COMPLETE } from "../../commom/commonTypes";

const initialState = {
  productsPerPage: null,
  superProduct: [],
  shortBy: "name,asc",
  currentPage: 0,
  maxPageSoFar: 0,
  loader: true,
  searchKeyword: "",
  inputKeyword: "",
  subPlansPerPage: null,
  error: null,
  progress: true,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SORT_BY:
      return {
        ...state,
        shortBy: action.payload,
        superProduct: [],
        progress: true,
        loader: true,
      };
    case SUPER_PROD_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      };
    case SET_SUPER_PROD_SEARCH_KEYWORD:
      return {
        ...state,
        searchKeyword: action.payload,
        superProduct: [],
        loader: true,
      };

    case FETCH_SUPER_PROD: {
      let allSubPlans = state.superProduct;
      let page = action.payload.pageable.pageNumber;
      if (
        page > 0 &&
        state.superProduct.length > action.superProdPerPage * page
      ) {
        allSubPlans = state.superProduct.slice(
          0,
          action.superProdPerPage * page
        );
      }

      return {
        ...state,
        superProduct:
          action.payload.pageable.pageNumber > 0
            ? [...allSubPlans, ...action.payload.content]
            : action.payload.content,
        number: action.payload.number,
        numberOfElements: action.payload.totalElements,
        numberOfPages: action.payload.totalPages,
        productsPerPage: action.payload.size,
        progress: false,
        loader: false,
      };
    }

    case SET_SUPER_PROD_ERROR:
      return {
        ...state,

        error: action.payload,
        progress: false,
        loader: false,
      };

    case SET_SUPER_PROD_LOADER:
      return {
        ...state,
        loader: action.payload,
      };

    case CLEAR_COMPLETE:
    case CLEAR_DATA:
      return {
        ...state,
        superProduct: [],
        loader: false,
      };

    case SET_SUPER_PROD_PER_PAGE:
      return {
        ...state,
        subPlansPerPage: action.payload,
      };

    case SET_SUPER_PROD_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };

    case SET_SUPER_PROD_MAX_PAGE_SO_FAR:
      return {
        ...state,
        maxPageSoFar: action.payload,
      };

    case SET_SUPER_PROD_INPUT_KEYWORD:
      return {
        ...state,
        inputKeyword: action.payload,
        process: true,
      };
    case CLEAR_SUPER_PROD_PLANS:
      return {
        ...state,
        superProduct: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
