import axios from "axios";
import { getStoreIdFromUrl } from "../../utils/storeUtil";
import { PROGRESS_WALLET_BALANCE, FETCH_WALLET_BALANCE } from "./walletTypes";

export const fetchWalletBalance = () => async (dispatch) => {
  const storeId = getStoreIdFromUrl();
  let accessToken = localStorage.getItem("access_token");
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  dispatch({ type: PROGRESS_WALLET_BALANCE, payload: true });

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/api/v1/business/store/${storeId}/walletx`,
      config
    );
    dispatch({ type: FETCH_WALLET_BALANCE, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_WALLET_BALANCE, payload: null });
    throw new Error(
      `Failed to fetch wallet balance: ${error.message || "Unknown error"}`
    );
  } finally {
    dispatch({ type: PROGRESS_WALLET_BALANCE, payload: false });
  }
};
