// Constant definitions

// Request methods
const GET = "GET";
const POST = "POST";
const PUT = "PUT";
const PATCH = "PATCH";
const DELETE = "DELETE";

// Config Objects
const DEFAULT_HEADERS = {
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
};
const methodsWithoutBody = [GET, DELETE];

// Utilities
const getToken = () => localStorage.getItem("access_token");

const constructHeaders = (overrideHeaders) => {
  return {
    ...DEFAULT_HEADERS,
    Authorization: `Bearer ${getToken()}`,
    ...overrideHeaders,
  };
};

const constructFetchOptions = (method, headers, body = {}) => {
  if (methodsWithoutBody.indexOf(method) < 0) {
    return {
      method,
      headers,
      body: JSON.stringify(body),
    };
  }
  return {
    method,
    headers,
  };
};

const requestConstructor = (
  method,
  path,
  { body = {}, overrideHeaders = {} }
) => {
  const headers = constructHeaders(overrideHeaders);
  const fetchOptions = constructFetchOptions(method, headers, body);
  return fetch(`${process.env.REACT_APP_API}${path}`, fetchOptions);
};

const resolveRequest = async (request) => {
  const response = { error: false, data: {} };
  try {
    const apiResponse = await request;
    response.data = await apiResponse.json();
    if (!apiResponse.ok) {
      response.error = true;
    }
  } catch (err) {
    response.error = true;
    response.data = { message: "Request failed" };
  }
  return response;
};

// API Methods
export const getWithToken = (path, overrideHeaders = {}) => {
  return resolveRequest(requestConstructor(GET, path, { overrideHeaders }));
};

export const postWithToken = (path, body, overrideHeaders = {}) => {
  return resolveRequest(
    requestConstructor(POST, path, { body, overrideHeaders })
  );
};

export const putWithToken = (path, body, overrideHeaders = {}) => {
  return resolveRequest(
    requestConstructor(PUT, path, { body, overrideHeaders })
  );
};

export const patchWithToken = (path, body, overrideHeaders = {}) => {
  return resolveRequest(
    requestConstructor(PATCH, path, { body, overrideHeaders })
  );
};

export const deleteWithToken = (path, overrideHeaders) => {
  return resolveRequest(requestConstructor(DELETE, path, { overrideHeaders }));
};
