import React, { useState, useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import OrderForm from "./components/OrderForm";
import { useDispatch } from "react-redux";
import { fetchInvoiceById } from "../../utils/invoiceUtil";
import {
  clearEstimate,
  saveEstimate,
} from "../../redux/estimateOrders/estimateActions";

const EditEstimate = () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [record, setRecord] = useState(null);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchInvoiceById(params.estimateid).then((rec) => {
      setRecord(rec);
      setLoading(false);
    });
  }, [params.estimateid]);

  const onOrderFormClose = () => {
    history.goBack();
  };

  const onOrderSave = (ordReq) => {
    setLoader(true);
    dispatch(saveEstimate(ordReq)).then(() => {
      dispatch(clearEstimate());
      setLoader(false);
      history.goBack();
    });
  };
  return (
    <div>
      {loading && (
        <div
          style={{
            position: "fixed",
            left: "50%",
            top: "50%",
            zIndex: "900",
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      )}

      {record && (
        <OrderForm
          record={record}
          headerLabel="Edit Estimate"
          dateLabel="Estimate Date"
          type="ESTIMATE"
          loader={loader}
          enableDescount={true}
          enableCoupon={false}
          onClose={onOrderFormClose}
          onSave={onOrderSave}
          startLabel={{
            label: "Estimates",
            link: "/estimates",
          }}
        />
      )}
    </div>
  );
};

export default EditEstimate;
