import React, { useState } from "react";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import classnames from "classnames";
import useStyles from "./styles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

function comparePaths(path, pathname) {
  const targetString = "/settings/";
  return path.includes(targetString) && pathname.includes(targetString);
}

const SidebarLink = ({ path, icon, label, location, children, nested }) => {
  let isLinkActive =
    location.pathname === path ||
    location.pathname.indexOf(path) !== -1 ||
    comparePaths(path, location.pathname);

  if (children) {
    let matchChild = children.some((child) => {
      if (
        location.pathname === child.path ||
        location.pathname.indexOf(child.path) !== -1
      ) {
        return true;
      }
      if (child.subpaths) {
        return child.subpaths.some(
          (subpath) =>
            location.pathname === subpath ||
            location.pathname.indexOf(subpath) !== -1
        );
      }
      return false;
    });
    isLinkActive = matchChild;
  }

  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(isLinkActive);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  if (!children) {
    return (
      <ListItem
        button
        component={path && Link}
        to={path}
        className={!nested ? classes.link : classes.subLink}
        classes={{
          root: classnames(classes.linkRoot, {
            [classes.linkActive]: isLinkActive && !nested,
            [classes.linkNested]: nested,
          }),
        }}
        disableRipple
      >
        {nested ? (
          <></>
        ) : (
          <ListItemIcon
            className={classnames(classes.linkIcon, {
              [classes.linkIconActive]: isLinkActive,
            })}
          >
            {isLinkActive
              ? React.cloneElement(icon, { stroke: "#FFFFFF", fill: "#FFFFFF" })
              : icon}
          </ListItemIcon>
        )}
        <ListItemText
          classes={{
            primary: classnames(
              !nested ? classes.linkText : classes.subLinkText,
              {
                [classes.linkTextActive]: isLinkActive && !nested,
                [classes.subLinkTextActive]: isLinkActive && nested,
              }
            ),
          }}
          primary={label}
        />
      </ListItem>
    );
  } else {
    return (
      <>
        <ListItem
          button
          component={path && Link}
          onClick={toggleCollapse}
          className={classes.link}
          to={path}
          classes={{
            root: classnames(classes.linkRoot, {
              [classes.linkActive]: isLinkActive,
            }),
          }}
          disableRipple
          style={{ marginBottom: 0 }}
        >
          <ListItemIcon
            className={classnames(classes.linkIcon, {
              [classes.linkIconActive]: isLinkActive,
            })}
          >
            {isLinkActive
              ? React.cloneElement(icon, { stroke: "#FFFFFF", fill: "#FFFFFF" })
              : icon}
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classnames(classes.linkText, {
                [classes.linkTextActive]: isLinkActive,
              }),
            }}
            primary={label}
          />
          {isOpen ? (
            <ExpandLess
              style={{ color: isLinkActive ? "#FFFFFF" : "#FFFFFFB3" }}
            />
          ) : (
            <ExpandMore
              style={{ color: isLinkActive ? "#FFFFFF" : "#FFFFFFB3" }}
            />
          )}
        </ListItem>
        <div style={{ marginBottom: 10 }}>
          {children && (
            <Collapse
              in={isOpen}
              timeout="auto"
              unmountOnExit
              className={classes.collapse}
            >
              <List
                component="div"
                style={{
                  background: "#081648",
                  paddingLeft: 40,
                }}
              >
                {children.map((childrenLink) => (
                  <SidebarLink
                    key={childrenLink.id}
                    path={childrenLink.path}
                    location={location}
                    classes={classes}
                    nested
                    {...childrenLink}
                  />
                ))}
              </List>
            </Collapse>
          )}
        </div>
      </>
    );
  }
};
export default SidebarLink;
