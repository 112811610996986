import React from "react";

export default function DesktopFiveColumnIcon() {
  return (
    <svg
      width="64"
      height="36"
      viewBox="0 0 64 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="63"
        height="35"
        rx="1.5"
        fill="white"
        stroke="#999999"
      />
      <rect x="3" y="3" width="11" height="10" rx="1" fill="#FF7D7D" />
      <rect x="15" y="3" width="10" height="10" rx="1" fill="#FF7D7D" />
      <rect x="26" y="3" width="11" height="10" rx="1" fill="#FF7D7D" />
      <rect x="38" y="3" width="11" height="10" rx="1" fill="#FF7D7D" />
      <rect x="50" y="3" width="11" height="10" rx="1" fill="#FF7D7D" />
    </svg>
  );
}
