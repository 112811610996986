// Core react imports
import React from "react";
// Mui imports
import { Typography } from "@material-ui/core";
// Style imports
import useStyles from "./styles";
// Icon imports
import NoReportIcon from "../../icons/NoReportIcon";

export default function NoReports() {
  const classes = useStyles();
  return (
    <div className={classes.reportArea}>
      <div className={classes.reportHead}>
        <Typography
          className={classes.reportHeadColumn}
          style={{ flexBasis: "30%" }}
        >
          Report
        </Typography>
        <Typography
          className={classes.reportHeadColumn}
          style={{ flexBasis: "30%" }}
        >
          Duration
        </Typography>
        <Typography
          className={classes.reportHeadColumn}
          style={{ flexBasis: "30%" }}
        >
          Report Generated On
        </Typography>
        <Typography className={classes.reportHeadColumn}>Action</Typography>
      </div>
      <div className={classes.noReportArea}>
        <NoReportIcon />
        <Typography className={classes.noReportText}>
          You have not generated any report
        </Typography>
      </div>
    </div>
  );
}
