import React from "react";
import Controls from "../../../../components/controls/Controls";
import { rowFlexOnlyJustifyBetween } from "../../../../styles/common";
import { useStyles } from "../../styles";
import states from "../../../../constants/states.json";
import { Box } from "@material-ui/core";

export default function AddressForm({
  addressForm,
  handleAddressFormFieldChange,
}) {
  const classes = useStyles();

  if (!addressForm) {
    return <div>Loading...</div>; // Handle the case where addressForm is undefined
  }

  return (
    <>
      <Box display="flex">
        <Box flex={1} marginRight={1}>
          <Controls.Input
            type="text"
            color="secondary"
            label="Business Name"
            labelPlacement="top"
            value={
              addressForm.business_name ? addressForm.business_name.value : ""
            }
            onChange={(e) =>
              handleAddressFormFieldChange("business_name", e.target.value)
            }
            error={
              addressForm.business_name ? addressForm.business_name.error : ""
            }
            fullWidth
          />
        </Box>
        <Box flex={1} marginLeft={1}>
          <Controls.Input
            type="text"
            color="secondary"
            label="GSTIN (Optional)"
            labelPlacement="top"
            value={addressForm.gstin ? addressForm.gstin.value : ""}
            onChange={(e) =>
              handleAddressFormFieldChange("gstin", e.target.value)
            }
            error={addressForm.gstin ? addressForm.gstin.error : ""}
            fullWidth
          />
        </Box>
      </Box>
      <br />
      <Box flex={1}>
        <Controls.Input
          type="text"
          color="secondary"
          label="Street 1"
          labelPlacement="top"
          value={addressForm.street ? addressForm.street.value : ""}
          onChange={(e) =>
            handleAddressFormFieldChange("street", e.target.value)
          }
          error={addressForm.street ? addressForm.street.error : ""}
          fullWidth
        />
      </Box>
      <br />
      <Controls.Input
        type="text"
        color="secondary"
        label="Street 2"
        labelPlacement="top"
        value={addressForm.street2 ? addressForm.street2.value : ""}
        onChange={(e) =>
          handleAddressFormFieldChange("street2", e.target.value)
        }
        error={addressForm.street2 ? addressForm.street2.error : ""}
        fullWidth
      />
      <br />
      <div className={rowFlexOnlyJustifyBetween}>
        <div className={classes.horizontalMargin}>
          <Controls.Input
            type="number"
            color="secondary"
            label="Pincode"
            labelPlacement="top"
            value={addressForm.pincode ? addressForm.pincode.value : ""}
            onChange={(e) =>
              handleAddressFormFieldChange("pincode", e.target.value)
            }
            error={addressForm.pincode ? addressForm.pincode.error : ""}
            fullWidth
          />
        </div>
        <div className={classes.horizontalMargin}>
          <Controls.Input
            type="text"
            color="secondary"
            label="City"
            labelPlacement="top"
            value={addressForm.city ? addressForm.city.value : ""}
            onChange={(e) =>
              handleAddressFormFieldChange("city", e.target.value)
            }
            error={addressForm.city ? addressForm.city.error : ""}
            fullWidth
          />
        </div>
        <div className={classes.horizontalMargin}>
          <Controls.Select
            className={classes.selectstate}
            type="text"
            color="secondary"
            label="State"
            labelPlacement="top"
            value={addressForm.state ? addressForm.state.value : ""}
            onChange={(e) =>
              handleAddressFormFieldChange("state", e.target.value)
            }
            error={addressForm.state ? addressForm.state.error : ""}
            options={states.map((state) => ({ id: state, title: state }))}
            placeholder="Select"
            fullWidth
          />
        </div>
      </div>
    </>
  );
}
