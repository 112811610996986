import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import OrderForm from "./components/OrderForm";
import { useDispatch } from "react-redux";
import { getStoreIdFromUrl } from "../../utils/storeUtil";
import { fetchBillSettings } from "../../redux/billSettings/billSettingsActions";
import { fetchBillConfig } from "../../redux/billConfig/billConfigActions";
import { saveOrder, clearOrder } from "../../redux/orders/orderActions";

const CreateOrder = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const storeId = getStoreIdFromUrl();

  const [orderPrefix, setOrderPrefix] = useState(null);
  const [note, setNote] = useState(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    dispatch(fetchBillSettings()).then((billSetting) => {
      setNote(billSetting.note);
    });
    dispatch(fetchBillConfig()).then((billConfig) => {
      let prefix = billConfig.prefix;
      if (billConfig.year_included) {
        prefix += String(new Date().getUTCFullYear());
      }
      if (billConfig.month_included) {
        prefix += String(new Date().getUTCMonth() + 1);
      }
      if (billConfig.day_included) {
        prefix += String(new Date().getUTCDate());
      }
      setOrderPrefix(prefix);
    });
    // eslint-disable-next-line
  }, [storeId]);

  const onOrderFormClose = () => {
    history.goBack();
  };

  const onOrderCreate = (ordReq) => {
    setLoader(true);
    dispatch(saveOrder(ordReq)).then(() => {
      dispatch(clearOrder());
      setLoader(false);
      history.goBack();
    });
  };

  return (
    <div>
      <OrderForm
        record={null}
        headerLabel="Create Order"
        dateLabel="Invoice Date"
        type="ONLINE"
        orderPrefix={orderPrefix}
        state="CONFIRMED"
        status="VERIFIED"
        note={note}
        loader={loader}
        enableDescount={true}
        enableCoupon={true}
        onClose={onOrderFormClose}
        onSave={onOrderCreate}
        startLabel={{
          label: "Order",
          link: "/orders",
        }}
      />
    </div>
  );
};
export default CreateOrder;
