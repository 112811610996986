import React from "react";

export default function ReturnOrderIcon({ fill = "#666666", strokeWidth = 0 }) {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99984 15.8597L10.8255 15.034L8.38225 12.584H13.8589V11.4173H8.38225L10.8255 8.96732L9.99984 8.14161L6.1408 12.0007L9.99984 15.8597ZM2.55125 21.334C2.02917 21.334 1.58448 21.1504 1.21717 20.7833C0.85006 20.416 0.666504 19.9713 0.666504 19.4492V4.55207C0.666504 4.02998 0.85006 3.58529 1.21717 3.21798C1.58448 2.85087 2.02917 2.66732 2.55125 2.66732H8.03459C7.92823 2.07193 8.07066 1.53419 8.46188 1.05411C8.85291 0.574026 9.36556 0.333984 9.99984 0.333984C10.6489 0.333984 11.169 0.574026 11.5603 1.05411C11.9515 1.53419 12.0864 2.07193 11.9651 2.66732H17.4484C17.9705 2.66732 18.4152 2.85087 18.7825 3.21798C19.1496 3.58529 19.3332 4.02998 19.3332 4.55207V19.4492C19.3332 19.9713 19.1496 20.416 18.7825 20.7833C18.4152 21.1504 17.9705 21.334 17.4484 21.334H2.55125ZM2.55125 20.1673H17.4484C17.6281 20.1673 17.7927 20.0926 17.9422 19.943C18.0917 19.7935 18.1665 19.6289 18.1665 19.4492V4.55207C18.1665 4.3724 18.0917 4.2078 17.9422 4.05828C17.7927 3.90875 17.6281 3.83398 17.4484 3.83398H2.55125C2.37159 3.83398 2.20699 3.90875 2.05746 4.05828C1.90793 4.2078 1.83317 4.3724 1.83317 4.55207V19.4492C1.83317 19.6289 1.90793 19.7935 2.05746 19.943C2.20699 20.0926 2.37159 20.1673 2.55125 20.1673ZM9.99984 3.18328C10.2526 3.18328 10.4616 3.10064 10.6269 2.93536C10.7922 2.77008 10.8748 2.56105 10.8748 2.30828C10.8748 2.0555 10.7922 1.84647 10.6269 1.68119C10.4616 1.51592 10.2526 1.43328 9.99984 1.43328C9.74706 1.43328 9.53803 1.51592 9.37275 1.68119C9.20747 1.84647 9.12484 2.0555 9.12484 2.30828C9.12484 2.56105 9.20747 2.77008 9.37275 2.93536C9.53803 3.10064 9.74706 3.18328 9.99984 3.18328Z"
        fill={fill}
        stroke={fill}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
}
