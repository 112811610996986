import React from "react";

export default function DelhverySettingsIcon({
  fill = "#666666",
  strokeWidth = 0,
}) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_22814_1815"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="28"
        height="28"
      >
        <rect width="28" height="28" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_22814_1815)">
        <path
          d="M6.63603 22.3011C5.78708 22.3011 5.06715 22.0044 4.47623 21.4109C3.88551 20.8177 3.59015 20.0973 3.59015 19.2497H1.97461V7.71776C1.97461 7.1807 2.15447 6.73231 2.51419 6.37259C2.87391 6.01287 3.3223 5.83301 3.85936 5.83301H19.2952V10.0511H21.9876L26.026 15.4805V19.2497H24.1413C24.1413 20.0973 23.8442 20.8177 23.2499 21.4109C22.6555 22.0044 21.9338 22.3011 21.0849 22.3011C20.2358 22.3011 19.5158 22.0044 18.9251 21.4109C18.3342 20.8177 18.0387 20.0973 18.0387 19.2497H9.69269C9.69269 20.1023 9.39548 20.824 8.80107 21.4147C8.20684 22.0056 7.48516 22.3011 6.63603 22.3011ZM6.64128 21.1344C7.16628 21.1344 7.61165 20.9516 7.9774 20.5858C8.34315 20.2201 8.52603 19.7747 8.52603 19.2497C8.52603 18.7247 8.34315 18.2793 7.9774 17.9136C7.61165 17.5478 7.16628 17.3649 6.64128 17.3649C6.11628 17.3649 5.6709 17.5478 5.30515 17.9136C4.9396 18.2793 4.75682 18.7247 4.75682 19.2497C4.75682 19.7747 4.9396 20.2201 5.30515 20.5858C5.6709 20.9516 6.11628 21.1344 6.64128 21.1344ZM3.14128 18.083H3.89523C4.06129 17.5685 4.39933 17.1258 4.90936 16.7548C5.41939 16.3838 5.99669 16.1983 6.64128 16.1983C7.25611 16.1983 7.82603 16.3801 8.35103 16.7437C8.87603 17.1071 9.22155 17.5535 9.38761 18.083H18.1286V6.99967H3.85936C3.67989 6.99967 3.51529 7.07444 3.36557 7.22397C3.21604 7.37349 3.14128 7.53809 3.14128 7.71776V18.083ZM21.0901 21.1344C21.6151 21.1344 22.0604 20.9516 22.426 20.5858C22.7917 20.2201 22.9746 19.7747 22.9746 19.2497C22.9746 18.7247 22.7917 18.2793 22.426 17.9136C22.0604 17.5478 21.6151 17.3649 21.0901 17.3649C20.5651 17.3649 20.1198 17.5478 19.754 17.9136C19.3883 18.2793 19.2054 18.7247 19.2054 19.2497C19.2054 19.7747 19.3883 20.2201 19.754 20.5858C20.1198 20.9516 20.5651 21.1344 21.0901 21.1344ZM19.2952 15.7497H24.792L21.3594 11.2178H19.2952V15.7497Z"
          fill={fill}
          stroke={fill}
          strokeWidth={strokeWidth}
        />
      </g>
    </svg>
  );
}
