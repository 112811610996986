export const SET_SORT_BY = "SET_SORT_BY";
export const FETCH_SUPER_PROD = "FETCH_SUPER_PROD";
export const SET_SUPER_PROD_ERROR = "SET_SUPER_PROD_ERROR";
export const SUPER_PROD_PROGRESS = "SUPER_PROD_PROGRESS";
export const SET_SUPER_PROD_MAX_PAGE_SO_FAR = "SET_SUPER_PROD_MAX_PAGE_SO_FAR";
export const SET_SUPER_PROD_SEARCH_KEYWORD = "SET_SUPER_PROD_SEARCH_KEYWORD";
export const SET_SUPER_PROD_INPUT_KEYWORD = "SET_SUPER_PROD_INPUT_KEYWORD";
export const SET_SUPER_PROD_CURRENT_PAGE = "SET_SUPER_PROD_CURRENT_PAGE";
export const SET_SUPER_PROD_PER_PAGE = "SET_SUPER_PROD_PER_PAGE";
export const SET_SUPER_PROD_LOADER = "SET_SUPER_PROD_LOADER";
export const CLEAR_SUPER_PROD_PLANS = "CLEAR_SUPER_PROD_PLANS";
