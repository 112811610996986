import { makeStyles, Typography } from "@material-ui/core";
import { ColorPicker, createColor } from "material-ui-color";
import React, { useState } from "react";
import { rowFlexAlignCenter } from "../../styles/common";
import Input from "./Input";
import { getColorName } from "../../constants/colorPickerUtils";
import { colorPickerColors } from "../../utils/colorPickerColors";
import CustomLabel, { generateLabelProps } from "./CustomLabel";

const useStyles = makeStyles(() => ({
  colorPicker: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #999999",
    padding: 4,
    borderRadius: 4,
    height: 35,
  },
  colorBox: {
    width: 24,
    minWidth: 24,
    height: 24,
    border: "0px solid rgb(118, 118, 118)",
    borderRadius: 4,
  },
  popover: {
    position: "absolute",
    top: 35,
    zIndex: 2,
    right: -70,
  },
  cover: {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
}));

const ColorInput = ({ name, value, onChange, ...props }) => {
  const classes = useStyles();
  const labelProps = generateLabelProps(props);

  let cLabel;
  let cHex;
  if (value) {
    let vals = value.split(":");
    cLabel = vals[0];
    cHex = vals[1];
  }
  const [color, setColor] = useState(cHex ? createColor(cHex) : null);
  const [colorLabel, setColorLabel] = useState(cLabel ? cLabel : "");

  const onColorChange = (colorValue) => {
    let colorName = colorValue ? getColorName(colorValue) : "";
    setColorLabel(colorName);
    setColor(colorValue);
    const e = {
      target: {
        name,
        value: `${colorName}:${
          colorValue?.css?.backgroundColor !== "white"
            ? colorValue?.css?.backgroundColor ?? ""
            : ""
        }`,
      },
    };
    onChange(e);
  };

  const onColorLabelChange = (event) => {
    let colorValue = "";
    if (event.target.value.trim() === "") {
      setColor(null);
      colorValue = "";
    } else {
      colorValue = `${colorLabel}:${
        color?.css?.backgroundColor !== "white"
          ? color?.css?.backgroundColor ?? ""
          : ""
      }`;
    }
    const e = {
      target: {
        name,
        value: colorValue,
      },
    };
    onChange(e);
  };

  return (
    <div
      className={
        labelProps.labelPlacement === "start" ? rowFlexAlignCenter : ""
      }
    >
      <CustomLabel {...labelProps} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ flexBasis: "48%" }}>
          <Input
            name="colorLabel"
            value={colorLabel}
            onChange={(e) => setColorLabel(e.target.value)}
            placeholder="Label"
            onBlur={onColorLabelChange}
          />
        </div>

        <div
          className={classes.colorPicker}
          style={{ flexBasis: "48%", marginTop: -18 }}
        >
          <ColorPicker
            hideTextfield
            value={color}
            onChange={(value) => onColorChange(value)}
            palette={colorPickerColors}
          />
          <Typography style={{ fontSize: 14, marginLeft: 10 }}>
            {color?.css?.backgroundColor}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default ColorInput;
