import React, { useEffect } from "react";
import { Link } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import WalletIcon from "../../icons/WalletIcon";
import { makeStyles } from "@material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
import { fetchWalletBalance } from "../../redux/wallet/walletActions";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    gap: 10,
    padding: 10,
    borderRadius: 4,
    margin: "20px 30px",
    cursor: "pointer",
    textDecoration: "none",
  },
  balance: {
    color: "white",
    fontSize: "16px",
    lineHeight: "20px",
    display: "inline-block",
    height: "20px",
  },
}));

const WalletLink = ({ pathPrefix, location }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { progress, loaded, walletData } = useSelector((state) => state.wallet);

  useEffect(() => {
    if (!loaded) {
      dispatch(fetchWalletBalance());
    }
    //eslint-disable-next-line
  }, [loaded]);

  const isActive = location.pathname === `/${pathPrefix}/wallet`;

  return (
    <Link
      className={classes.root}
      style={{
        textDecoration: "none",
        background: isActive ? "#FCFCFC4D" : "#FCFCFC21",
      }}
      onClick={() => history.push(`/${pathPrefix}/wallet`)}
    >
      <WalletIcon />
      <div>
        <div
          style={{
            color: isActive ? "#E1E1E1" : "#B9B9B9",
            fontSize: 14,
            fontWeight: 400,
          }}
        >
          Wallet Balance
        </div>
        {progress ? (
          <Skeleton variant="text" width={100} height={20} animation="wave" />
        ) : (
          <span className={classes.balance}>
            {walletData?.balance || 0} points
          </span>
        )}
      </div>
    </Link>
  );
};

export default WalletLink;
