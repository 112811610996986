import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
const useStyles = makeStyles(() => ({
  chip: {
    fontSize: "10px",
    lineHeight: "12px",
    borderRadius: "6px",
    width: "70px",
    textAlign: "center",
    padding: "4px 5px",
    color: "#fff",
  },
  freeChip: {
    backgroundColor: "#4A72FF",
  },
  installedChip: {
    backgroundColor: "#72C472",
  },
  premiumChip: {
    backgroundColor: "#FF8A00",
  },
}));
export function FreeChip() {
  const classes = useStyles();
  return (
    <Typography className={[classes.freeChip, classes.chip].join(" ")}>
      FREE
    </Typography>
  );
}

export function PremiumChip({ planName }) {
  const classes = useStyles();
  return (
    <Typography className={[classes.premiumChip, classes.chip].join(" ")}>
      {planName}
    </Typography>
  );
}

export function InstalledChip() {
  const classes = useStyles();
  return (
    <Typography className={[classes.installedChip, classes.chip].join(" ")}>
      INSTALLED
    </Typography>
  );
}
