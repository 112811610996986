import {
  FETCH_CATEGORIES,
  ADD_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  CATEGORY_PROGRESS,
  CATEGORY_LOADER,
  UPDATE_CATEGORY_LIST,
} from "./categoryTypes";
import axios from "axios";
import { getStoreIdFromUrl } from "../../utils/storeUtil";
import { clearProductData } from "../product/productActions";
import { clearAdditionalFieldData } from "../additionalFields/additionalFieldsActions";

export const startLoader = () => (dispatch) => {
  dispatch({
    type: CATEGORY_LOADER,
    payload: true,
  });
};

export const fetchCategories = () => async (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  let storeId = getStoreIdFromUrl();

  dispatch({
    type: CATEGORY_PROGRESS,
    payload: true,
  });

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/v3/store/${storeId}/categories`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const data = await response.json();

    if (!response.ok) {
      if (data.message) {
        throw new Error(data.message);
      }
      throw new Error("Unable to Fetch Categories");
    } else {
      dispatch({
        type: FETCH_CATEGORIES,
        payload: data.payload,
      });
    }
  } catch (error) {
    return {
      error: error.message,
    };
  }
};

export const rearrangeCategories = (data) => (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  let storeId = getStoreIdFromUrl();
  axios
    .patch(
      `${process.env.REACT_APP_AUTH_API}/api/v3/store/${storeId}/categories/position`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .then((res) => {
      dispatch({
        type: UPDATE_CATEGORY_LIST,
        payload: res.data.payload,
      });
    })
    .catch((err) => console.log(err));
};

export const addCategory = (categoryData) => async (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  let storeId = getStoreIdFromUrl();

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/v3/store/${storeId}/categories`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(categoryData),
      }
    );

    const data = await response.json();

    if (!response.ok) {
      if (data.message) {
        throw new Error(data.message);
      }
      throw new Error("Category creation failed.");
    }

    await dispatch({
      type: ADD_CATEGORY,
      payload: data,
    });

    dispatch(clearProductData());

    return data;
  } catch (error) {
    return {
      error: error.message,
    };
  }
};

export const editCategory = (categoryData) => async (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  let storeId = getStoreIdFromUrl();

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/v3/store/${storeId}/categories/${categoryData.id}`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(categoryData),
      }
    );

    const data = await response.json();

    if (!response.ok) {
      if (data.message) {
        throw new Error(data.message);
      }
      throw new Error("Unable to Update Category");
    }

    if (data.level === 0 && data.children !== null) {
      await dispatch({
        type: UPDATE_CATEGORY_LIST,
        payload: data.children,
      });
    }
    await dispatch({
      type: UPDATE_CATEGORY,
      payload: data,
    });

    dispatch(clearAdditionalFieldData());

    dispatch(clearProductData());

    return data;
  } catch (error) {
    return {
      error: error.message,
    };
  }
};

export const deleteCategory = (categoryId) => async (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  let storeId = getStoreIdFromUrl();

  try {
    startLoader();
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/v3/store/${storeId}/categories/${categoryId}`,
      {
        method: "DELETE",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Unable to Delete Category");
    }

    dispatch({
      type: DELETE_CATEGORY,
      payload: { id: categoryId },
    });

    dispatch(clearProductData());
    dispatch(clearAdditionalFieldData());
  } catch (error) {
    return {
      error: error.message,
    };
  }
};
