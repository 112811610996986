export const CREATE_ORDER = "CREATE_ORDER";
export const FETCH_ORDERS = "FETCH_ORDERS";
export const ORDER_PROGRESS = "ORDER_PROGRESS";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const UPDATE_ORDERS = "UPDATE_ORDERS";
export const SET_ORDER_ERROR = "SET_ORDER_ERROR";
export const SET_ORDER_LOADER = "SET_ORDER_LOADER";
export const CLEAR_COMPLETE_ORDERS = "CLEAR_COMPLETE_ORDERS";
export const CLEAR_ORDER = "CLEAR_ORDER";
export const ORDER_DATE_SELECTOR = "ORDER_DATE_SELECTOR";
export const ORDER_STATE_SELECTOR = "ORDER_STATE_SELECTOR";
export const ORDER_DATE_RANGE = "ORDER_DATE_RANGE";
export const SET_ORDERS_PER_PAGE = "SET_ORDERS_PER_PAGE";
export const SET_ORDERS_CURRENT_PAGE = "SET_ORDERS_CURRENT_PAGE";
export const SET_ORDERS_MAX_PAGE_SO_FAR = "SET_ORDERS_MAX_PAGE_SO_FAR";
export const SET_ORDERS_INPUT_KEYWORD = "SET_ORDERS_INPUT_KEYWORD";
export const SET_ORDERS_SEARCH_KEYWORD = "SET_ORDERS_SEARCH_KEYWORD";
export const SET_HIGHLIGHT_FALSE = "SET_HIGHLIGHT_FALSE";
export const SET_ORDERS_CUSTOMER_ID = "SET_ORDERS_CUSTOMER_ID";
