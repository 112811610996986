import { Button, Typography } from "@material-ui/core";
import axios from "axios";
import { createColor } from "material-ui-color";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import HeaderBreadcrumbs from "../../../components/common/HeaderBreadcrumbs";
import Controls from "../../../components/controls/Controls";
import PageLoader from "../../../components/Layout/PageLoader";
import Toast from "../../../components/Layout/Toast";
import {
  layoutBodyBox,
  layoutContainer,
  rowFlexAlignStart,
} from "../../../styles/common";
import { updateAttributes } from "../../../utils/storeUtil";
import useStyles from "../styles";
import ContentAndLayoutSetting from "./ContentAndLayoutSetting";
import {
  getButtonColor,
  getReferralConfig,
  setReferralConfig,
} from "./referralUtils";
import Setting from "./Setting";

const ReferralConfig = () => {
  const classes = useStyles();
  const history = useHistory();
  const state = useSelector((state) => state);
  const store = state.store.store;
  const initialState = {
    Settings: true,
    "Content & Layout": false,
  };

  const [tabs, setTabs] = useState(initialState);
  const [activated, setActivated] = useState(false);
  const [showActivateSwitch, setShowActivateSwitch] = useState(false);
  const [configId, setConfigId] = useState(null);
  const [isAttributes, setIsAttributes] = useState(false);
  const [config, setConfig] = useState({
    referee_commission_type: "PERCENTAGE",
    referee_commission: "",
    referee_min_order_val: "",
    referee_commission_expiry: "",
    referee_coupon_trigger_point: "none",
    referred_commission_type: "PERCENTAGE",
    referred_commission: "",
    referred_min_order_val: "",
    referred_commission_expiry: "",
  });
  const [attributes, setAttributes] = useState({
    referee_title: "",
    referee_desc: "",
    referred_title: "",
    referred_desc: "",
    referred_button_text: "Shop Now",
    modal_background_color: createColor("#FFFFFF"),
    title_color: createColor("#1A1A1A"),
    desc_color: createColor("#1A1A1A"),
    button_color: createColor(
      getButtonColor(store?.store_setting?.theme_color).bgColor ?? "#000000"
    ),
    button_text_color: createColor(
      getButtonColor(store?.store_setting?.theme_color).txtColor ?? "#FFFFFF"
    ),
    widget_type: "WITH_ICON",
    widget_text: "",
    widget_desktop_pos: "RIGHT_CENTER",
    widget_mobile_pos: "RIGHT_CENTER",
  });

  const [minOrdAndExp, setMinOrdAndExp] = useState({
    refereeMinOrd: false,
    referredMinOrd: false,
    refereeCommExp: false,
    referredCommExp: false,
  });

  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState(false);
  const [toastSucc, setToastSucc] = useState(false);
  const [toastErr, setToastErr] = useState(false);

  const disabled =
    !config.referee_commission ||
    config.referee_coupon_trigger_point === "none" ||
    !config.referred_commission ||
    (minOrdAndExp.refereeMinOrd && !config.referee_min_order_val) ||
    (minOrdAndExp.referredMinOrd && !config.referred_min_order_val) ||
    (minOrdAndExp.refereeCommExp && !config.referee_commission_expiry) ||
    (minOrdAndExp.referredCommExp && !config.referred_commission_expiry);

  const onSettingClick = (set) => {
    setTabs({
      ...initialState,
      Settings: set !== "Settings" ? false : true,
      [set]: true,
    });
  };

  const handleSwitch = async () => {
    let accessToken = localStorage.getItem("access_token");
    let payload = { activated: !activated, id: configId };
    await axios
      .patch(
        `${process.env.REACT_APP_API}/api/v1/business/store/${store.id}/referral-config`,
        payload,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setActivated(res.data.payload?.activated);
        setToastSucc(true);
      });
  };

  useEffect(() => {
    getReferralConfig().then((res) => {
      setLoading(false);
      if (res.id > 0) {
        setShowActivateSwitch(true);
        setActivated(res.activated ?? false);
        setConfigId(res.id);
        setConfig({
          referee_commission_type: res.referee_commission_type ?? "PERCENTAGE",
          referee_commission:
            res.referee_commission > 0 ? res.referee_commission : "",
          referee_min_order_val:
            res.referee_min_order_val > 0 ? res.referee_min_order_val : "",
          referee_commission_expiry: res.referee_commission_expiry
            ? res.referee_commission_expiry
            : "",
          referee_coupon_trigger_point:
            res.referee_coupon_trigger_point ?? "none",
          referred_commission_type:
            res.referred_commission_type ?? "PERCENTAGE",
          referred_commission:
            res.referred_commission > 0 ? res.referred_commission : "",
          referred_min_order_val:
            res.referred_min_order_val > 0 ? res.referred_min_order_val : "",
          referred_commission_expiry:
            res.referred_commission_expiry > 0
              ? res.referred_commission_expiry
              : "",
        });
        setMinOrdAndExp({
          refereeMinOrd: res.referee_min_order_val > 0 ?? false,
          referredMinOrd: res.referred_min_order_val > 0 ?? false,
          refereeCommExp: res.referee_commission_expiry > 0 ?? false,
          referredCommExp: res.referred_commission_expiry > 0 ?? false,
        });
      }
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (store.slug) {
      getAttributes();
    }
    // eslint-disable-next-line
  }, [store]);

  const getAttributes = async () => {
    axios
      .get(
        `${process.env.REACT_APP_API}/api/v1/store/external/slug/${store.slug}?attributes=referral_layout`
      )
      .then((res) => {
        let layout = res.data.payload.attributes?.referral_layout ?? {};
        if (Object.keys(layout).length !== 0) {
          setIsAttributes(true);
          setAttributes({
            ...layout,
            modal_background_color: createColor(layout.modal_background_color),
            title_color: createColor(layout.title_color),
            desc_color: createColor(layout.desc_color),
            button_color: createColor(layout.button_color),
            button_text_color: createColor(layout.button_text_color),
          });
        }
      });
  };

  const onSave = async () => {
    setLoader(true);
    if (tabs.Settings) {
      let payload = {
        ...config,
        activated: true,
      };
      if (configId) {
        payload = {
          ...payload,
          id: configId,
        };
      }
      await setReferralConfig(payload).then((res) => {
        if (res) {
          setActivated(true);
          setShowActivateSwitch(true);
          setToastSucc(true);
          if (!isAttributes) {
            getAttributes();
          }
        } else {
          setToastErr(true);
        }
      });
    }
    if (tabs["Content & Layout"]) {
      await onSaveAttributes();
    }
    setLoader(false);
  };

  const onSaveAttributes = async () => {
    let payload = [
      {
        name: "referee_title",
        value: attributes.referee_title,
      },
      {
        name: "referee_desc",
        value: attributes.referee_desc,
      },
      {
        name: "referred_title",
        value: attributes.referred_title,
      },
      {
        name: "referred_desc",
        value: attributes.referred_desc,
      },
      {
        name: "referred_button_text",
        value: attributes.referred_button_text,
      },
      {
        name: "modal_background_color",
        value: attributes.modal_background_color.css.backgroundColor,
      },
      {
        name: "title_color",
        value: attributes.title_color.css.backgroundColor,
      },
      {
        name: "desc_color",
        value: attributes.desc_color.css.backgroundColor,
      },
      {
        name: "button_color",
        value: attributes.button_color.css.backgroundColor,
      },
      {
        name: "button_text_color",
        value: attributes.button_text_color.css.backgroundColor,
      },
      {
        name: "widget_type",
        value: attributes.widget_type,
      },
      {
        name: "widget_text",
        value: attributes.widget_text,
      },
      {
        name: "widget_desktop_pos",
        value: attributes.widget_desktop_pos,
      },
      {
        name: "widget_mobile_pos",
        value: attributes.widget_mobile_pos,
      },
    ];
    let res = await updateAttributes(store.slug, "referral_layout", payload);
    if (res) {
      setToastSucc(true);
      setAttributes(attributes);
    } else {
      setToastErr(true);
      return;
    }
  };

  if (loading) return <PageLoader />;

  return (
    <div style={{ position: "relative" }}>
      <div className={layoutContainer}>
        <div style={{ marginBottom: 20 }}>
          <HeaderBreadcrumbs
            list={[
              { label: "Refer And Earn", link: "/refer-and-earn" },
              {
                label: "Settings",
              },
            ]}
          />
        </div>
        <div
          className={rowFlexAlignStart}
          style={{ position: "relative", width: "100%" }}
        >
          <div style={{ width: "100%" }}>
            {showActivateSwitch && (
              <div
                className={layoutBodyBox}
                style={{ maxWidth: 604, width: "65%" }}
              >
                <div style={{ padding: "0px 32px 24px" }}>
                  <Controls.Switch
                    name="refandearn"
                    label="Activate Refer and Earn"
                    color="secondary"
                    value={activated}
                    onChange={handleSwitch}
                    width="100%"
                  />
                  <Typography
                    style={{
                      fontWeight: 400,
                      fontSize: 12,
                      color: " #1A1A1A",
                      lineHeight: "18px",
                      marginTop: -12,
                    }}
                  >
                    Customer can refer other customers and get rewarded
                  </Typography>
                </div>
              </div>
            )}
            <div
              className={layoutBodyBox}
              style={{
                maxWidth: 604,
                width: "65%",
                paddingTop: 16,
                marginTop: 20,
              }}
            >
              <div style={{ display: "flex", padding: "0px 20px" }}>
                {Object.entries(tabs).map(([key, value]) => (
                  <Button
                    className={classes.subSetting}
                    onClick={() => onSettingClick(key)}
                    style={{
                      color: value ? "#1641DB" : "#1A1A1A",
                    }}
                    key={key + "_tab"}
                  >
                    <Typography
                      style={{
                        borderBottom: value
                          ? "4px solid #1641DB"
                          : "4px solid #FFFFFF",
                      }}
                      className={classes.subSettingText}
                    >
                      {key}
                    </Typography>
                  </Button>
                ))}
              </div>
              <hr className={classes.divider} />

              <div style={{ padding: "8px 32px 32px" }}>
                {tabs.Settings ? (
                  <Setting
                    config={config}
                    setConfig={setConfig}
                    minOrdAndExp={minOrdAndExp}
                    setMinOrdAndExp={setMinOrdAndExp}
                  />
                ) : (
                  <ContentAndLayoutSetting
                    attributes={attributes}
                    setAttributes={setAttributes}
                  />
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingTop: 50,
                  }}
                >
                  <CombinedButtons
                    style={{ width: 155 }}
                    solidBtnText={tabs.Settings ? "Save & Activate" : "Save"}
                    disabled={tabs.Settings && disabled}
                    outlinedBtnAction={() => {
                      history.goBack();
                    }}
                    solidBtnAction={onSave}
                    loading={loader}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toast
        open={toastSucc}
        close={setToastSucc}
        message="Setting saved successfully"
      />
      <Toast
        severity="error"
        open={toastErr}
        close={setToastErr}
        message="Something went wrong, please try again!"
      />
    </div>
  );
};

export default ReferralConfig;
