import React from "react";
import { Modal, makeStyles } from "@material-ui/core";
import CombinedButtons from "../buttons/CombinedButtons";

const useStyles = makeStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  upperDiv: {
    padding: 25,
    borderRadius: 12,
    paddingBottom: 16,
    position: "absolute",
    background: "#ffffff",
    width: 500,
  },
  div: {
    height: "50px",
    position: "relative",
    marginTop: 50,
    marginBottom: 7,
  },
  button: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
});

const DeleteModal = ({ item, onCancel, onConfirmDelete, isDeleting }) => {
  const classes = useStyles();
  return (
    <Modal
      open={item ? true : false}
      onClose={onCancel}
      className={classes.modal}
    >
      <div className={classes.upperDiv}>
        <p style={{ fontSize: 16 }}>
          Do you really want to delete <b> {item.name ? item.name : item}</b> ?
        </p>
        <div className={classes.div}>
          <div className={classes.button}>
            <CombinedButtons
              loading={isDeleting}
              outlinedBtnAction={onCancel}
              solidBtnAction={onConfirmDelete}
              isDelete={true}
              solidBtnText="Delete"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
