export const documentIcons = (filename) => {
  if (filename.endsWith(".pdf")) {
    return "https://i.imgur.com/hFiztfY.png";
  } else if (filename.endsWith(".csv")) {
    return "https://i.imgur.com/24OoMov.png";
  } else if (filename.endsWith(".xlxs")) {
    return "https://i.imgur.com/lJla50y.png";
  } else {
    return "https://i.imgur.com/JIH7tTR.png";
  }
};
