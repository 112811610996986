import { Typography } from "@material-ui/core";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import useStyles from "../styles";
import PlusMinusButton from "../../../components/controls/PlusMinusButton";
import { getStoreIdFromUrl } from "../../../utils/storeUtil";
import { PRODUCT_QUANTITY_UPDATE } from "../../../redux/product/productTypes";
import FormModal from "../../../components/common/FormModal";

const StockModal = ({ record, stockModal, onClose, setToast, setMessage }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [qty, setQty] = useState(1);
  const [loading, setLoading] = useState(false);

  const changeQuantity = (quantity) => {
    setQty(quantity);
  };

  useEffect(() => {
    setQty(1);
  }, [record]);

  const onSave = () => {
    let accessToken = localStorage.getItem("access_token");
    let storeId = getStoreIdFromUrl();
    setMessage(
      `Stock ${stockModal.mode === "IN" ? "Added" : "Removed"} Successfully`
    );
    setLoading(true);
    const data = {
      action_type: stockModal.mode === "IN" ? "STOCK_IN" : "STOCK_OUT",
      display_name: record.display_name,
      mrp: record.mrp,
      org_id: record.org_id,
      pp_tax_included: record.pp_tax_included,
      qty: qty,
      quantity: qty,
      sale_price: record.sale_price,
      sku: record.sku,
      sp_tax_included: record.sp_tax_included,
      store_id: storeId,
    };
    axios
      .post(`${process.env.REACT_APP_API}/api/v1/org/product/inventory`, data, {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setToast(true);
        dispatch({
          type: PRODUCT_QUANTITY_UPDATE,
          payload: {
            sku: response.data.payload.sku,
            qty: response.data.payload.qty,
          },
        });
        onClose();
      });
  };

  return (
    <FormModal
      open={stockModal.visible}
      heading={`${stockModal.mode === "IN" ? "Stock In" : "Stock Out"} - ${
        record ? record.name : ""
      }`}
      onClose={onClose}
      onSave={onSave}
      btnTxt={stockModal.mode === "IN" ? "Stock In" : "Stock Out"}
      loading={loading}
    >
      <hr className={classes.horizontalLine} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 32,
          marginBottom: 48,
        }}
      >
        <Typography>Available Stock</Typography>
        <Typography>{record ? record.qty.toFixed(1) : 0} PIECES</Typography>
      </div>
      <div style={{ marginBottom: 36 }}>
        <PlusMinusButton
          value={qty}
          increment={changeQuantity}
          decrement={changeQuantity}
        />
      </div>
    </FormModal>
  );
};

export default StockModal;
