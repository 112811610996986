import React from "react";

export default function CoinIcon({ width = "24", height = "24" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0049 22.0031C6.48188 22.0031 2.00488 17.5261 2.00488 12.0031C2.00488 6.48005 6.48188 2.00305 12.0049 2.00305C17.5279 2.00305 22.0049 6.48005 22.0049 12.0031C22.0049 17.5261 17.5279 22.0031 12.0049 22.0031ZM12.0049 7.76005L7.76188 12.0031L12.0049 16.2451L16.2469 12.0031L12.0049 7.76005Z"
        fill="#FFAC41"
      />
    </svg>
  );
}
