import { makeStyles } from "@material-ui/styles";
export default makeStyles((theme) => ({
  addInvoiceContainer: {
    background: "white",
    boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.04)",
    borderRadius: 6,
    padding: 32,
    position: "relative",
  },
  bottomSheet: {
    background: "#FAFAFA",
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: 90,
    display: "flex",
    justifyContent: "space-between",
    boxShadow: "0px -4px 16px rgba(229, 229, 229, 0.63)",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    padding: "20px 24px",
  },
  tosBox: {
    border: "1px dashed #BEC7EB",
    borderRadius: 6,
    width: "100%",
    alignItems: "center",
    marginTop: 8,
    padding: 8,
    background: "#FFFFFF",
  },
  tosHead: {
    color: "#999999",
    fontWeight: 600,
    fontSize: 12,
  },
  tosBody: {
    color: "#999999",
    fontWeight: 400,
    fontSize: 12,
  },
  paymentSelector: {
    background: "#1641DB",
    color: "white",
    borderRadius: 6,
    height: 48,
    width: 141,
    textTransform: "none",
  },
  tableHeader: {
    display: "flex",
    marginLeft: theme.spacing(3.25),
    marginRight: theme.spacing(4),
    justifyContent: "space-between",
    marginBottom: theme.spacing(3),
  },
  search: {
    display: "flex",
    border: "1px solid #E6E6E6",
    borderRadius: 6,
    padding: "12px",
    paddingTop: 9,
    height: "44px",
    maxWidth: 410,
    marginRight: "16px",
    flex: 1,
    minWidth: 0,
  },
  searchIcon: {
    height: "100%",
  },
  searchBox: {
    outlineWidth: 0,
    lineHeight: "16px",
    marginLeft: "12px",
    border: "none",
    fontSize: "12px",
    flex: 1,
    minWidth: 0,
  },
  button: {
    borderRadius: "6px",
    border: "1px solid #1641DB",
    backgroundColor: "rgba(255,255,255,0.1)",
    marginLeft: "24px",
    color: "#1641DB",
    boxShadow: "none",
    height: "40px",
    width: "100px",
    padding: "6px 8px",
  },
  disabledButton: {
    border: "1px solid #eeeeee",
  },
  buttonlabel: {
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "16px",
    textTransform: "none",
  },
  tablehead: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px -1px 0px #E5E5E5, 0px 1px 0px #E6E6E6",
    borderTop: "1px solid #E6E6E6",
  },
  delBtn: {
    background: "#ED0000",
    color: "white",
    borderRadius: 6,
    height: 48,
    fontWeight: 600,
    lineHeight: "24px",
    fontSize: 16,
    padding: 16,
    textTransform: "none",
    width: 163,
  },
  modal: {
    width: "570px",
    marginLeft: "auto",
    top: "0px",
    marginRight: "0px",
    zIndex: 3,
  },
  row: {
    "& td": {
      fontWeight: 600,
      paddingLeft: theme.spacing(2.75),
      fontSize: 14,
      lineHeight: "20px",
      color: "#666666",
    },
  },
  prodimage: {
    height: "60px",
    width: "60px",
    borderRadius: "6px",
  },
  nopicctn: {
    border: "1px solid #E5E5E5",
    boxSizing: "border-box",
    borderRadius: "6px",
    width: 60,
    height: 60,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  anchor: {
    "& li": {
      color: "#1641DB",
      fontSize: 12,
      lineHeight: "16px",
    },
  },
  tableHeaderRight: {
    display: "flex",
    alignItems: "center",
  },
  dateFilter: {
    fontWeight: 400,
    fontSize: 12,
    color: "#666666",
    padding: 10,
    alignItems: "center",
  },
  statusSelector: {
    background: "white",
    color: "#666666",
    fontSize: 12,
    borderRadius: 6,
    height: 44,
    width: 100,
    marginLeft: 20,
    border: "1px solid #666666",
    textTransform: "none",
  },
  customWidth: {
    "& div": {
      width: 101,
    },
  },
  customSearchWidth: {
    "& div": {
      width: 300,
    },
  },
  dividerGrid: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "space-between",
    paddingTop: 24,
    paddingBottom: 18,
    borderBottom: "1px solid #E6E6E6",
  },
  subHeading: {
    fontWeight: 600,
    fontSize: 14,
    color: "#1A1A1A",
    paddingTop: 6,
  },
  inputBox: {
    width: "372px",
    height: "32px",
    borderRadius: "6px",
    border: "1px solid #999999",
    padding: "0px 4px",
    outlineColor: "#1641DB",
    fontSize: 14,
  },
  rupee: {
    "& p": {
      fontSize: 12,
      lineHeight: "16px",
    },
  },
  saleprice: {
    marginLeft: 35,
    width: 410,
  },
  accholder: {
    marginLeft: 27,
    width: 356,
  },
  pymtrec: {
    marginLeft: 12,
    width: 356,
  },
  accnumber: {
    marginLeft: 11,
    width: 367,
  },
  accifsc: {
    marginLeft: 45,
    width: 422,
  },
  accbank: {
    marginLeft: 36,
    width: 378,
  },
  label: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginRight: 0,
  },
  taxbtn: {
    fontSize: 12,
    textTransform: "capitalize",
    borderRadius: 12,
    width: "64px",
    padding: 0,
    border: "1px solid #1641DB",
  },
  labelinput: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  menuitem: {
    fontSize: 12,
    color: "#1a1a1a",
  },
  placeholder: {
    fontSize: 12,
    color: "#999999",
  },
  taxcontrol: {
    width: 372,
    marginLeft: 100,
  },
  namecontrol: {
    width: 379,
    marginLeft: 36,
  },
  accholderlabel: {
    width: "24%",
  },
  reclabel: {
    width: "26%",
  },
  noItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid rgba(224, 224, 224, .5)",
    height: 62,
    fontSize: 12,
    fontWeight: 600,
    color: "#555555",
    borderTop: "none",
  },
  text: {
    fontSize: "12px",
    lineHeight: "20px",
    fontWeight: 600,
    letterSpacing: "0.02em",
    textAlign: "center",
    color: "#1641DB",
  },
  grouped: {
    minWidth: "24px",
  },
  groupedroot: {
    border: "1px solid",
    borderColor: "#1641DB",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 6,
    height: 25,
  },
  textsizesmall: {
    padding: "1px 0px",
  },
  focusedBtn: {
    fontWeight: 700,
    background: "#FAFAFA",
  },
  focusedTopBtn: {
    fontWeight: 700,
    background: "#FAFAFA",
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  focusedBottomBtn: {
    fontWeight: 700,
    background: "#FAFAFA",
    borderBottomLeftRadius: 6,
    borderBottomightRadius: 6,
  },
  searchPlaceholder: {
    "&::placeholder": {
      fontWeight: 600,
      color: "#1641DB",
      opacity: 0.9,
    },
  },
  applyBtn: {
    background: "#1641DB",
    color: "white",
    opacity: 0.95,
  },
  endicon: {
    marginLeft: 6,
  },
  managebtn: {
    color: "#1641DB",
    marginRight: 24,
    border: "1px solid #1641DB",
    borderRadius: 6,
    width: 188,
    fontSize: 16,
    padding: 12,
    fontWeight: 600,
    lineHeight: "24px",
    textTransform: "none",
    "&:hover": {
      color: "#1641DB",
      background: "#ffffff",
    },
  },
  flexBox: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "space-between",
  },
  tempelateHolder: {
    width: 100,
    height: 142,
    padding: 8,
    border: "1px solid #CCCCCC",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    borderRadius: 6,
    cursor: "pointer",
    "&:hover": {
      border: "1px solid #1641DB",
    },
  },
  innerTempHolder: {
    width: "100%",
    height: "100%",
    border: "1px solid #F5F7FC",
    borderRadius: 6,
  },
  timeBtns: {
    borderRadius: 19,
    border: "1px solid #999999",
    background: "white",
    color: "#1A1A1A",
    marginRight: 12,
    marginTop: 12,
    padding: "4px 11px",
    textTransform: "none",
    fontSize: 12,
  },
  selectedTimeBtns: {
    borderRadius: 19,
    border: "1px solid #1641DB",
    color: "white",
    background: "#1641DB",
    marginRight: 12,
    padding: "4px 11px",
    marginTop: 12,
    fontSize: 12,
    textTransform: "none",
    "&:hover": {
      color: "white",
      background: "#1641DB",
    },
  },
  typePicker: {
    backgroundColor: "rgba(22,65,219,0.05)",
    padding: "16px 24px",
    margin: "0px 16px",
    marginBottom: "20px",
    borderRadius: "8px",
  },
  typePickerHeader: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "600",
    color: "#1A1A1A",
    display: "flex",
    alignItems: "flex-start",
    marginLeft: "0px !important",
    marginBottom: "12px",
  },
  reportsControl: {
    width: 500,
    marginTop: "12px",
  },
  noteText: {
    fontSize: "12px",
    lineHeight: "16px",
    flexBasis: "97%",
  },
  reportsSecondaryControl: {
    width: "320px",
    marginTop: "12px",
  },
  secondaryLabel: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "600",
    color: "#1A1A1A",
    alignItems: "flex-start",
    marginLeft: "40px !important",
    marginBottom: "12px !important",
  },
  dateLabel: {
    alignItems: "flex-start",
    justifyContent: "flex-end",
    height: "18.98px",
    margin: "0px !important",
    width: "25%",
    padding: "0px 16px",
  },
  labelWidth: {
    width: "auto",
  },
  controlInput: {
    margin: "12px 0px 0px 0px !important",
    width: "100%",
  },
  reportArea: {
    marginTop: "24px",
    backgroundColor: "white",
  },
  reportHead: {
    backgroundColor: "#FFFFFF",
    padding: "16px 32px",
    display: "flex",
    alignItems: "center",
    boxShadow: "0px -1px 0px #e5e5e5, 0px 1px 0px #e6e6e6",
    borderBottom: "1px solid #E6E6E6",
  },
  reportHeadColumn: {
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: "600",
    color: "#1F1F1F",
  },
  noReportArea: {
    margin: "auto",
    marginTop: "28px",
    textAlign: "center",
    marginBottom: "12px",
  },
  noReportText: {
    color: "#999",
    fontSize: "14px",
    lineHeight: "20px",
    marginTop: "11px",
  },
  reportBody: {
    display: "flex",
    alignItems: "center",
    padding: "16px 32px",
  },
  reportBodyText: {
    fontSize: "12px",
    lineHeight: "16px",
    color: "#1A1A1A",
  },
  subHead: {
    color: "#1A1A1A",
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: "700",
    margin: "20px 0px",
  },
  downloadBtn: {
    border: "1px solid #1641DB",
    textTransform: "none",
    padding: "8px 16px",
    borderRadius: "6px",
    color: "#1641DB",
    "&:hover": {
      backgroundColor: "none",
    },
  },
  generatingBtn: {
    border: "1px solid #06AA47",
    padding: "8px 16px",
    textTransform: "none",
    borderRadius: "6px",
    color: "#06AA47",
    "&:hover": {
      backgroundColor: "none",
    },
  },
  SelectDisplayBox: {
    marginLeft: "40px",
    marginBottom: "16px",
  },
  customSelectLabel: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "600",
    color: "#1A1A1A",
    alignItems: "flex-start",
    marginBottom: "12px !important",
  },
  moreBtn: {
    margin: "auto",
    marginTop: "20px",
    textTransform: "none",
  },
  errorText: {
    margin: "0px !important",
    color: "red",
    fontSize: "12px",
  },
  columnsDisplayVal: {
    color: "#666",
    fontSize: "12px",
    lineHeight: "16px",
  },
  columnsListText: {
    fontSize: "12px",
    lineHeight: "16px",
    color: "#1A1A1A",
  },
  checkedColumnsListText: {
    fontSize: "12px",
    lineHeight: "16px",
    color: "#1A1A1A",
    fontWeight: "600",
  },
  forceWhiteBg: {
    backgroundColor: "white !important",
    border: "1px solid rgba(132, 117, 117, 0.46)",
    borderRadius: "4px",
    padding: "9px 12px",
  },
  icon: {
    top: "calc(50% - 12px)",
    color: "#B9B9B9",
    right: "12px",
    position: "absolute",
    pointerEvents: "none",
  },
}));
