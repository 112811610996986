import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const AddIcon = ({ viewBox = "0 0 18 18", stroke = "white" }) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "18px", height: "18px" }}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 6V12"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 9H12"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.8571 17H2.14286C1.512 17 1 16.488 1 15.8571V2.14286C1 1.512 1.512 1 2.14286 1H15.8571C16.488 1 17 1.512 17 2.14286V15.8571C17 16.488 16.488 17 15.8571 17Z"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default AddIcon;
