// Importing types
import {
  TOGGLE_LOADING,
  CLEAR_COUPONS,
  COUPON_CREATE_ERROR,
  SET_COUPONS,
  SET_COUPON_BANNERS,
  CREATE_COUPON_LOADING,
  CREATE_COUPON_SUCCESS,
  DELETE_COUPON_SUCCESS,
  TOGGLE_COUPON_ACTIVATION,
  CLEAR_COUPON,
  COUPON_ERROR,
  SET_COUPONS_PER_PAGE,
} from "./couponTypes";
import { CLEAR_COMPLETE, CLEAR_DATA } from "../commom/commonTypes";
// Initial state
const initState = {
  coupons: [],
  couponBanners: [],
  loading: false,
  numberOfPages: 0,
  loaded: false,
  bannersLoaded: false,
  couponCreateError: null,
  couponCreateLoading: false,
  deleteSuccess: false,
  toggleSuccess: false,
  createSuccess: false,
  updateSuccess: false,
  message: "",
  error: false,
  couponsPerPage: null,
};
// Reducer function
const reducer = (state = initState, action) => {
  let clonedState = { ...state, coupons: [...state.coupons] };
  switch (action.type) {
    case TOGGLE_LOADING:
      return {
        ...clonedState,
        loading: !clonedState.loading,
        loaded: !clonedState.loaded,
      };
    case SET_COUPONS:
      let allCoupons = state.coupons;
      let page = action.payload.pageable.pageNumber;
      if (
        page > 0 &&
        state.coupons.length > action.payload.pageable.pageSize * page
      ) {
        allCoupons = state.coupons.slice(0, action.payload.pageable.offset);
      }
      return {
        ...clonedState,
        coupons:
          action.payload.pageable.pageNumber > 0
            ? [...allCoupons, ...action.payload.content]
            : action.payload.content,
        loading: false,
        numberOfPages: action.payload.totalPages,
        deleteSuccess: false,
      };
    case CREATE_COUPON_SUCCESS:
      if (action.payload.couponId) {
        return {
          ...clonedState,
          coupons: clonedState.coupons.map((_) => {
            if (_.id === action.payload.couponId) {
              return action.payload.data;
            } else {
              return _;
            }
          }),
          couponCreateError: false,
          numberOfPages: Math.max(
            Math.ceil((clonedState.coupons.length + 1) / state.couponsPerPage),
            clonedState.numberOfPages
          ),
          updateSuccess: true,
          message: "Successfully updated coupon",
        };
      }
      return {
        ...clonedState,
        coupons: [action.payload, ...clonedState.coupons],
        couponCreateError: false,
        numberOfPages: Math.max(
          Math.ceil((clonedState.coupons.length + 1) / state.couponsPerPage),
          clonedState.numberOfPages
        ),
        createSuccess: true,
        message: "Coupon Created successfully",
      };
    case CREATE_COUPON_LOADING:
      return {
        ...clonedState,
        couponCreateLoading: true,
        couponCreateError: null,
        createSuccess: false,
        updateSuccess: false,
        message: "",
      };
    case COUPON_CREATE_ERROR:
      return { ...clonedState, couponCreateError: action.payload.message };
    case DELETE_COUPON_SUCCESS:
      return {
        ...clonedState,
        coupons: clonedState.coupons.filter(
          (c) => Number(c.id) !== Number(action.payload)
        ),
        numberOfPages: Math.min(
          Math.ceil((clonedState.coupons.length - 1) / state.couponsPerPage),
          clonedState.numberOfPages
        ),
        deleteSuccess: true,
        message: "Successfully deleted coupon",
      };
    case CLEAR_COUPON:
      return {
        ...clonedState,
        deleteSuccess: false,
        toggleSuccess: false,
        error: false,
      };
    case SET_COUPON_BANNERS:
      return {
        ...clonedState,
        couponBanners: action.payload,
        bannersLoaded: true,
      };
    case TOGGLE_COUPON_ACTIVATION:
      let coupons = clonedState.coupons.map((coupon) => {
        if (coupon.id === action.payload) {
          return {
            ...coupon,
            active: !coupon.active,
          };
        } else {
          return coupon;
        }
      });
      return {
        ...clonedState,
        coupons,
        toggleSuccess: true,
        message: "Status toggle success",
      };
    case COUPON_ERROR:
      return {
        ...clonedState,
        message: action.payload,
        error: true,
      };
    case CLEAR_COUPONS:
      return {
        ...state,
        coupons: [],
        couponBanners: [],
        loading: false,
        numberOfPages: 0,
        loaded: false,
        bannersLoaded: false,
        couponCreateError: null,
        couponCreateLoading: false,
        deleteSuccess: false,
        toggleSuccess: false,
        message: "",
        error: false,
      };
    case CLEAR_COMPLETE:
    case CLEAR_DATA:
      return initState;
    case SET_COUPONS_PER_PAGE:
      return {
        ...state,
        couponsPerPage: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
