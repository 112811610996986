import React from "react";
import { textButton } from "../../styles/common";
import { Button } from "@material-ui/core";
export default function TextButton({
  onClick = () => {},
  intent = "positive",
  style = {},
  startIcon = <></>,
  endIcon = <></>,
  children = "",
  fullWidth = false,
  className = "",
}) {
  return (
    <Button
      color="secondary"
      className={textButton}
      style={
        intent === "negative" ? { color: "#FF6060", ...style } : { ...style }
      }
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      disableRipple
      disableElevation
      disableFocusRipple
      disableTouchRipple
      fullWidth={fullWidth}
    >
      {children}
    </Button>
  );
}
