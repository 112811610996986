import { CLEAR_DATA, CLEAR_COMPLETE } from "../commom/commonTypes";
import {
  FETCH_TRANSACTIONS,
  TRANSACTION_PROGRESS,
  SET_TRANSACTION_ERROR,
  SET_TRANSACTION_LOADER,
  CLEAR_TRANSACTION,
  CLEAR_COMPLETE_TRANSACTIONS,
  TRANSACTION_DATE_SELECTOR,
  TRANSACTION_DATE_RANGE,
  SET_TRANSACTIONS_PER_PAGE,
  SET_TRANSACTIONS_CURRENT_PAGE,
  SET_TRANSACTIONS_MAX_PAGE_SO_FAR,
  SET_TRANSACTIONS_INPUT_KEYWORD,
  SET_TRANSACTIONS_SEARCH_KEYWORD,
} from "./referAndEarnTypes";
import { SET_SUMMARY_FILTER } from "./referAndEarnTypes";

const initialState = {
  transactions: [],
  transaction: null,
  numberOfPages: 0,
  numberOfElements: 0,
  progress: true,
  loader: false,
  error: null,
  dateSelector: null,
  dateRange: {
    aoe: null,
    boe: null,
  },
  ordersPerPage: null,
  searchKeyword: "",
  inputKeyword: "",
  currentPage: 0,
  maxPageSoFar: 0,
  summaryFilter: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TRANSACTION_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      };
    case FETCH_TRANSACTIONS:
      let allTransactions = state.transactions;
      let page = action.payload.pageable.pageNumber;
      if (
        page > 0 &&
        state.transactions.length > action.transactionsPerPage * page
      ) {
        allTransactions = state.transactions.slice(
          0,
          action.transactionsPerPage * page
        );
      }
      return {
        ...state,

        transactions:
          action.payload.pageable.pageNumber > 0
            ? [...allTransactions, ...action.payload.content]
            : action.payload.content,
        numberOfPages: action.payload.totalPages,
        numberOfElements: action.payload.totalElements,
        progress: false,
      };

    case SET_TRANSACTION_ERROR:
      return {
        ...state,
        error: action.payload.error,
        progress: false,
      };
    case SET_TRANSACTION_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case CLEAR_TRANSACTION:
      return {
        ...state,
        transaction: action.payload,
      };
    case CLEAR_COMPLETE_TRANSACTIONS:
      return {
        ...state,
        transactions: [],
        transaction: null,
        numberOfPages: 0,
        numberOfElements: 0,
        progress: false,
        loader: false,
        error: null,
      };
    case CLEAR_COMPLETE:
    case CLEAR_DATA:
      return {
        ...state,
        transactions: [],
        transaction: null,
        numberOfPages: 0,
        numberOfElements: 0,
        progress: false,
        loader: false,
        error: null,
        dateSelector: null,
        dateRange: {
          aoe: null,
          boe: null,
        },
        ordersPerPage: null,
        searchKeyword: "",
        inputKeyword: "",
        summaryFilter: null,
      };
    case TRANSACTION_DATE_SELECTOR:
      return {
        ...state,
        dateSelector: action.payload,
      };

    case TRANSACTION_DATE_RANGE:
      return {
        ...state,
        dateRange: action.payload,
      };
    case SET_TRANSACTIONS_PER_PAGE:
      return {
        ...state,
        transactionsPerPage: action.payload,
      };
    case SET_TRANSACTIONS_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SET_TRANSACTIONS_MAX_PAGE_SO_FAR:
      return {
        ...state,
        maxPageSoFar: action.payload,
      };
    case SET_TRANSACTIONS_INPUT_KEYWORD:
      return {
        ...state,
        inputKeyword: action.payload,
      };
    case SET_TRANSACTIONS_SEARCH_KEYWORD:
      return {
        ...state,
        searchKeyword: action.payload,
      };
    case SET_SUMMARY_FILTER:
      return {
        ...state,
        summaryFilter: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
