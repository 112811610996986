import { Alert } from "@material-ui/lab";
import React from "react";
import Snackbar from "@material-ui/core/Snackbar";

const Toast = ({
  open,
  close,
  message,
  severity = "success",
  autoClose = true,
  customStyle = {},
}) => {
  const defaultStyle = {
    top: 80,
  }; // default Style

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoClose ? 3000 : null}
      onClose={() => close(false)}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      style={{ ...defaultStyle, ...customStyle }}
    >
      <Alert onClose={() => close(false)} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
