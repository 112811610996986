import {
  getAttributeValue,
  getSiteAttributesForAnalytics,
} from "../../utils/analyticsUtils";
import { useSelector } from "react-redux";
import useStyles from "./styles";
import React, { useEffect, useRef, useState } from "react";
import HeaderBreadcrumbs from "../../components/common/HeaderBreadcrumbs";
import { layoutBodyBox, layoutContainer } from "../../styles/common";
import { CircularProgress } from "@material-ui/core";

const Analytics = () => {
  const classes = useStyles();
  const iframeRef = useRef(null);
  const state = useSelector((state) => state);
  const store = state.store.store;
  const [loaded, setLoaded] = useState(false);
  const [shareId, setShareId] = useState("");
  const [slug, setSlug] = useState("");

  useEffect(() => {
    if (store.slug) {
      getSiteAttributesForAnalytics(store.slug).then((data) => {
        setSlug(getAttributeValue("website_slug", data));
        setShareId(getAttributeValue("share_id", data));
        setLoaded(true);
      });
    }
  }, [store]);

  if (!loaded) {
    return (
      <div className={classes.loading}>
        <CircularProgress style={{ color: "#1641db" }} />
      </div>
    );
  } else {
    return (
      <div className={layoutContainer}>
        <div style={{ marginBottom: 20 }}>
          <HeaderBreadcrumbs
            list={[
              { label: "Dashboard", link: "/dashboard" },
              {
                label: "Analytics",
              },
            ]}
          />
        </div>
        <div style={{ position: "relative", width: "100%" }}>
          <div
            className={layoutBodyBox}
            style={{
              maxWidth: "100%",
              height: "calc(100vh - 135px)",
            }}
          >
            <div style={{ height: "650px" }}>
              <iframe
                ref={iframeRef}
                id="analyticsIframe"
                title="Analytics"
                src={`https://${process.env.REACT_APP_ANALYTICS_HOST}/share/${shareId}/${slug}`}
                height={"100%"}
                width={"100%"}
                frameBorder={0}
                scrolling="auto"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Analytics;
