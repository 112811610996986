import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const BeautyIcon = ({ viewBox = "0 0 24 24" }) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "24px", height: "24px" }}>
      <path
        d="M7.50049 5.6L10.0005 7L8.60049 4.5L10.0005 2L7.50049 3.4L5.00049 2L6.40049 4.5L5.00049 7L7.50049 5.6ZM19.5005 15.4L17.0005 14L18.4005 16.5L17.0005 19L19.5005 17.6L22.0005 19L20.6005 16.5L22.0005 14L19.5005 15.4ZM22.0005 2L19.5005 3.4L17.0005 2L18.4005 4.5L17.0005 7L19.5005 5.6L22.0005 7L20.6005 4.5L22.0005 2ZM14.3705 7.29C14.278 7.1973 14.1681 7.12375 14.0471 7.07357C13.9261 7.02339 13.7965 6.99756 13.6655 6.99756C13.5345 6.99756 13.4048 7.02339 13.2839 7.07357C13.1629 7.12375 13.053 7.1973 12.9605 7.29L1.29049 18.96C1.19779 19.0525 1.12424 19.1624 1.07406 19.2834C1.02388 19.4043 0.998047 19.534 0.998047 19.665C0.998047 19.796 1.02388 19.9257 1.07406 20.0466C1.12424 20.1676 1.19779 20.2775 1.29049 20.37L3.63049 22.71C4.02049 23.1 4.65049 23.1 5.04049 22.71L16.7005 11.05C16.7932 10.9575 16.8667 10.8476 16.9169 10.7266C16.9671 10.6057 16.9929 10.476 16.9929 10.345C16.9929 10.214 16.9671 10.0843 16.9169 9.96338C16.8667 9.8424 16.7932 9.73251 16.7005 9.64L14.3705 7.29ZM13.3405 12.78L11.2205 10.66L13.6605 8.22L15.7805 10.34L13.3405 12.78Z"
        fill="#1641DB"
      />
    </SvgIcon>
  );
};

export default BeautyIcon;
