// plan Constants
export const FREE = "FREE";
export const REFERRAL = "REFERRAL";
export const BASIC = "BASIC";
export const BUSINESS_APP_PRO = "BUSINESS_APP_PRO";
export const ENTERPRISE = "ENTERPRISE";
export const BUSINESS = "BUSINESS";
// Exporting plan list
export const planList = [FREE, BASIC, BUSINESS_APP_PRO, ENTERPRISE, BUSINESS];
// Exporting plan custom props
export const planCustomProps = {
  FREE: {
    background:
      "linear-gradient(319.1deg, #7995FE 49.75%, rgba(121, 149, 254, 0) 162.62%)",
    isFree: true,
    validityText: " year",
  },
  BASIC: {
    background: "linear-gradient(137.32deg, #E67682 5.16%, #CA6189 78.33%)",
    diamondProps: {},
    validityText: " year",
  },
  BUSINESS_APP_PRO: {
    background: "linear-gradient(306.57deg, #6D74C9 6.23%, #9937E4 115.75%)",
    diamondProps: {
      primary: "#FF1D1D",
      secondary: "#2238A8",
      tertiary: "#8715F4",
      quaternary: "#B0B5F1",
      quinary: "#9973EF",
      ellipse: "#CF9EFF",
    },
    validityText: " year",
  },
  ENTERPRISE: {
    background: "linear-gradient(314.21deg, #DECC2B 40.33%, #FFF7CC 193.99%)",
    diamondProps: { ellipse: "#DECC2B" },
    validityText: "per year",
  },
  BUSINESS: {
    background: "linear-gradient(314.21deg, #DECC2B 40.33%, #FFF7CC 193.99%)",
    diamondProps: { ellipse: "#DECC2B" },
    validityText: " year",
  },
};

export const PLANS = {
  FREE: {
    name: "Free",
    weight: 0,
    users: 1,
    features: [
      "Upto 50 Products",
      "Unlimited Orders",
      "100% Data Safety & More",
    ],
  },
  REFERRAL: {
    name: "Referral",
    weight: 0,
    users: 1,
    features: [
      "Unlimited Products",
      "Unlimited Orders",
      "No Commision",
      "100% Data Safety & More",
    ],
  },
  BASIC: {
    name: "Basic",
    weight: 1,
    users: 1,
    features: [
      "Upto 200 Products",
      "Domain Mapping",
      "No Shoopy Branding",
      "Detailed Business Reports",
      "Advanced Shipping & More",
    ],
    color: "#B3263F",
  },
  BUSINESS_APP_PRO: {
    name: "Professional",
    weight: 2,
    users: 5,
    features: [      
      "All Features From Basic Plan",
      "Upto 25000 Products",
      "Play Store Application file",
      "Premium Support",
      "No Shoopy Branding in SMS & More",
    ],
    color: "#9937e4",
  },
  ENTERPRISE: {
    name: "Enterprise",
    weight: 2,
    users: 5,
    features: [
      "All Features From Basic Plan",
      "Play Store Application file",
      "Premium Support",
      "No Shoopy Branding in SMS & More",
    ],
    color: "#9937e4",
  },
  BUSINESS: {
    name: "Business",
    weight: 3,
    users: 25,
    features: [
      "All Features From Professional Plan",
      "Upto 100000 Products",
      "Free Domain (.in, co.in etc.)",
      "Upto 25 Staff Members",
      "App Publishing",
      "Dedicated Support Manager",
    ],
    color: "#FFAC41",
  },
};

export const paidPlanTopFeatures = [
  "Premium support",
  "Domain mapping",
  "Your store APK/AAB file",
  "Store chat widgets",
  "Unlimited products",
  "Additional business reports",
  "Shoopy branding removed",
  "and many more ...",
];
