import React from "react";
import NoCouponImage from "./NoCouponImage";
import ArrowIcon from "../../icons/ArrowIcon";

const NoCoupon = () => {
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <div style={{ position: "absolute", right: "15%", top: -2 }}>
        <ArrowIcon />
      </div>
      <div
        style={{
          position: "absolute",
          right: "32%",
          top: 60,
          textAlign: "center",
        }}
      >
        <p
          style={{
            fontWeight: 600,
            fontSize: 20,
            lineHeight: "32px",
            color: "#1A1A1A",
          }}
        >
          Start Creating Coupons for your customers
        </p>
      </div>
      <div style={{ position: "absolute", right: "30%", top: 150 }}>
        <NoCouponImage />
      </div>
    </div>
  );
};

export default NoCoupon;
