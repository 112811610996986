import React, { useEffect, useRef, useState } from "react";
import { Button, makeStyles } from "@material-ui/core";
import FilterIcon from "../../../icons/FilterIcon";
import { DownArrowIcon } from "../../../icons";

const useStyles = makeStyles({
  selector: {
    border: "1px solid #E6E6E6",
    borderRadius: 6,
    fontSize: 12,
    padding: 12,
    textTransform: "none",
    justifyContent: "space-between",
    outline: "none",
    whiteSpace: "nowrap",
    height: "36px",
    width: "100%",
  },
  fliterSheet: {
    position: "absolute",
    top: 40,
    borderRadius: 6,
    background: "#FFFFFF",
    overflow: "hidden",
    zIndex: 1,
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: 12,
    width: "100%",
  },
  filterOption: {
    "&:hover": {
      background: "rgba(220, 220, 220, 0.2)",
    },
    width: "100%",
    padding: 6,
    borderRadius: 8,
    cursor: "pointer",
  },
});

export const FilterSortByList = [
  { title: "Feature Manually", query: "catManual" },
  {
    title: "Newest First",
    query: "createdAt,desc",
  },
  { title: "Price: High to Low", query: "salePrice,desc" },
  { title: "Price: Low to High", query: "salePrice,asc" },
  {
    title: "Alphabetically (A → Z)",
    query: "displayName,asc",
  },
  {
    title: "Alphabetically (Z → A)",
    query: "displayName,desc",
  },
];

const SortFilter = ({ sortfilter, setSortFilter, persistSortOrder }) => {
  const classes = useStyles();

  const wrapperRef = useRef(null);
  const [openFilterList, setOpenFilterList] = useState(false);

  const getFilterTitle = (query) => {
    const foundItem = FilterSortByList.find((item) => item.query === query);
    return foundItem ? foundItem.title : null;
  };

  useEffect(() => {
    const closeFilterList = (event) => {
      if (
        wrapperRef.current &&
        openFilterList &&
        !wrapperRef.current.contains(event.target)
      ) {
        setOpenFilterList(false);
      }
    };
    document.addEventListener("mousedown", closeFilterList);
    return () => {
      document.removeEventListener("mousedown", closeFilterList);
    };
  }, [openFilterList]);

  return (
    <div
      ref={wrapperRef}
      style={{ position: "relative", width: "65%", marginLeft: 15 }}
    >
      <Button
        className={classes.selector}
        startIcon={<FilterIcon />}
        endIcon={<DownArrowIcon stroke="#1A1A1A" />}
        onClick={() => {
          setOpenFilterList(!openFilterList);
        }}
      >
        <span>{sortfilter ? getFilterTitle(sortfilter) : "Sort By"}</span>
      </Button>
      {openFilterList && (
        <div className={classes.fliterSheet}>
          {FilterSortByList.map((item, index) => {
            return (
              <div
                className={classes.filterOption}
                key={index}
                onClick={() => {
                  setSortFilter(item.query);
                  setOpenFilterList(false);
                  persistSortOrder(item.query);
                }}
              >
                <span
                  style={{
                    fontSize: 14,
                    fontWeight: item.query === sortfilter ? 600 : 500,
                  }}
                >
                  {item.title}
                </span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SortFilter;
