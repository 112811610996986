import { CLEAR_DATA, CLEAR_COMPLETE } from "../commom/commonTypes";
import {
  FETCH_CONFIG,
  CONFIG_PROGRESS,
  SET_CONFIG_ERROR,
  UPDATE_CONFIG,
} from "./billConfigTypes";

const initialState = {
  config: {
    prefix: "INV",
    number_length: 3,
    active: true,
    day_included: true,
    month_included: true,
    reset_sequence: true,
    year_included: true,
  },
  progress: true,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CONFIG_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      };
    case FETCH_CONFIG:
      return {
        ...state,
        config: action.payload,
        progress: false,
      };
    case UPDATE_CONFIG:
      return {
        ...state,
        config: action.payload,
        progress: false,
      };
    case SET_CONFIG_ERROR:
      return {
        ...state,
        error: action.payload.error,
        progress: false,
      };
    case CLEAR_COMPLETE:
    case CLEAR_DATA:
      return {
        ...state,
        config: {},
        progress: false,
        error: null,
      };
    default:
      return state;
  }
};

export default reducer;
