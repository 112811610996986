import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const InvoiceIcon = ({ viewBox = "0 0 18 20", stroke = "#999999" }) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "18px", height: "20px" }}>
      <svg
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.875 0.625H2.125C1.79348 0.625 1.47554 0.756696 1.24112 0.991117C1.0067 1.22554 0.875 1.54348 0.875 1.875V19.375L4 16.875L6.5 19.375L9 16.875L11.5 19.375L14 16.875L17.125 19.375V1.875C17.125 1.54348 16.9933 1.22554 16.7589 0.991117C16.5245 0.756696 16.2065 0.625 15.875 0.625Z"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.625 11.875H8.375"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 8.125C10.0355 8.125 10.875 7.28553 10.875 6.25C10.875 5.21447 10.0355 4.375 9 4.375C7.96447 4.375 7.125 5.21447 7.125 6.25C7.125 7.28553 7.96447 8.125 9 8.125Z"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.375 11.875H12.125"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default InvoiceIcon;
