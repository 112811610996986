import { FormControl, Typography, Select, MenuItem } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useStyles from "../styles";
import Controls from "../../../components/controls/Controls";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import {
  bannerHeightOptions,
  catLayDeskOptions,
  catLayMobOptions,
  catTypeOptions,
  deskWidthOptions,
  prodLayDesktopOptions,
  prodLayMobileOptions,
  productCards,
  shapeOptions,
  sortOptions,
} from "../settingUtils";
import { updateAttributes } from "../../../utils/storeUtil";

const HomePageSetting = ({
  store,
  onClose,
  setToast,
  attributes,
  setAttributes,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const initColsInMob = store?.store_setting?.theme === "fashion" ? "2" : "3";
  const [catSection, setCatSection] = useState({
    show_categories: true,
    type: "GRID_BASED",
    cols_in_mobile: initColsInMob,
    cols_in_desktop: "6",
    desktop_width: "BOXED",
    shape: "SQUARE",
    show_title: true,
  });
  const [prodSection, setProdSection] = useState({
    show_products: true,
    max_count: "20",
    sort_by: "qty,desc",
    card_type: "TWO_CARD",
    cols_in_mobile: "2",
    cols_in_desktop: "5",
  });
  const [freeHeight, setFreeHeight] = useState(false);

  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, [attributes]);

  const init = () => {
    let catSettings = attributes?.category_settings ?? null;
    let prodSettings = attributes?.product_settings ?? null;
    setCatSection({
      show_categories: catSettings?.show_categories === "false" ? false : true,
      type: catSettings?.type ?? "GRID_BASED",
      cols_in_mobile: catSettings?.cols_in_mobile ?? initColsInMob,
      cols_in_desktop: catSettings?.cols_in_desktop ?? "6",
      desktop_width: catSettings?.desktop_width ?? "BOXED",
      shape: catSettings?.shape ?? "SQUARE",
      show_title: catSettings?.show_title === "false" ? false : true,
    });
    setFreeHeight(getBoolean(attributes.common?.banner_free_aspect_ratio));
    setProdSection({
      show_products: prodSettings?.show_products === "false" ? false : true,
      max_count: prodSettings?.max_count ?? "20",
      sort_by: prodSettings?.sort_by ?? "qty,desc",
      card_type: prodSettings?.card_type ?? "TWO_CARD",
      cols_in_mobile: prodSettings?.cols_in_mobile ?? "2",
      cols_in_desktop: prodSettings?.cols_in_desktop ?? "5",
    });
  };

  const onChangeCatSection = (e) => {
    setCatSection({ ...catSection, [e.target.name]: e.target.value });
    setIsSticky(true);
  };

  const onChangeProdSection = (e) => {
    setProdSection({ ...prodSection, [e.target.name]: e.target.value });
    setIsSticky(true);
  };

  const onSave = async () => {
    setLoading(true);
    let catPayload = [
      {
        name: "show_categories",
        value: catSection.show_categories,
      },
      { name: "type", value: catSection.type },
      { name: "cols_in_mobile", value: catSection.cols_in_mobile },
      {
        name: "cols_in_desktop",
        value: catSection.cols_in_desktop,
      },
      {
        name: "desktop_width",
        value: catSection.desktop_width,
      },
      { name: "shape", value: catSection.shape },
      { name: "show_title", value: catSection.show_title },
    ];

    let prodPayload = [
      {
        name: "show_products",
        value: prodSection.show_products,
      },
      { name: "max_count", value: prodSection.max_count },
      { name: "sort_by", value: prodSection.sort_by },
      { name: "card_type", value: prodSection.card_type },
      { name: "cols_in_mobile", value: prodSection.cols_in_mobile },
      {
        name: "cols_in_desktop",
        value: prodSection.cols_in_desktop,
      },
    ];
    let catres = await updateAttributes(
      store.slug,
      "category_settings",
      catPayload,
      true
    );
    await updateAttributes(store.slug, "common", [
      {
        name: "banner_free_aspect_ratio",
        value: freeHeight,
      },
    ]);
    if (catres) {
      let prodres = await updateAttributes(
        store.slug,
        "product_settings",
        prodPayload,
        true
      );
      if (prodres) {
        setToast(true);
        setLoading(false);
        setIsSticky(false);
        setAttributes({
          ...attributes,
          category_settings: {
            ...catSection,
            show_categories: catSection.show_categories ? "true" : "false",
            show_title: catSection.show_title ? "true" : "false",
          },
          product_settings: {
            ...prodSection,
            show_products: prodSection.show_products ? "true" : "false",
          },
          common: {
            ...attributes.common,
            banner_free_aspect_ratio: freeHeight,
          },
        });
      }
    }
  };

  const menuItems = (opt, idx) => {
    return (
      <MenuItem key={idx} value={opt.id}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {opt.img ? <img src={opt.img} alt="" /> : opt.icon}
          <Typography
            style={{
              fontSize: 12,
              marginLeft: 12,
            }}
          >
            {opt.title}
          </Typography>
        </div>
      </MenuItem>
    );
  };

  useEffect(() => {
    window.onscroll = function (ev) {
      if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
        // you're at the bottom of the page
        setIsSticky(false);
      }
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "100%",
      }}
    >
      <div className={classes.dividerBox} style={{ display: "block" }}>
        <Typography style={{ fontSize: 14, fontWeight: 600, marginBottom: 14 }}>
          Banner Section
        </Typography>
        <Controls.Select
          name="banner height"
          label="Height"
          value={freeHeight ? "FREE" : "BOXED"}
          onChange={(e) => setFreeHeight(e.target.value === "FREE")}
          options={bannerHeightOptions}
          color="secondary"
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          labelPlacement="top"
          labelFontWeight={500}
        />
      </div>
      <div className={classes.dividerBox} style={{ display: "block" }}>
        <Controls.Switch
          name="show_categories"
          label="Category Section"
          color="secondary"
          value={catSection.show_categories}
          onChange={() => {
            setCatSection({
              ...catSection,
              show_categories: !catSection.show_categories,
            });
            setIsSticky(true);
          }}
          width="100%"
        />
        <div style={{ position: "relative" }}>
          <Typography
            className={classes.lightSubHeading}
            style={{ marginBottom: 12 }}
          >
            Category Type
          </Typography>
          <FormControl fullWidth color="secondary" style={{ marginBottom: 12 }}>
            <Select
              name="type"
              variant="outlined"
              value={catSection.type}
              onChange={onChangeCatSection}
              color="secondary"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              renderValue={(selected) => {
                let value = catTypeOptions.filter((o) => o.id === selected)[0]
                  .title;
                return (
                  <Typography
                    style={{
                      fontSize: 14,
                    }}
                  >
                    {value}
                  </Typography>
                );
              }}
            >
              {catTypeOptions.map((opt, idx) => menuItems(opt, idx))}
            </Select>
            <Typography className={classes.helperTxt}>
              {catSection.type === "GRID_BASED"
                ? "Categories may be shown in multiple rows"
                : "Categories will be shown in single row"}
            </Typography>
          </FormControl>
          <Typography
            className={classes.lightSubHeading}
            style={{ marginBottom: 12 }}
          >
            Category Layout Mobile
          </Typography>
          <FormControl fullWidth color="secondary" style={{ marginBottom: 12 }}>
            <Select
              name="cols_in_mobile"
              color="secondary"
              variant="outlined"
              fullWidth
              value={catSection.cols_in_mobile}
              onChange={onChangeCatSection}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              renderValue={(selected) => {
                let value = catLayMobOptions.filter((o) => o.id === selected)[0]
                  .title;
                return (
                  <Typography
                    style={{
                      fontSize: 14,
                    }}
                  >
                    {value}
                  </Typography>
                );
              }}
            >
              {catLayMobOptions.map((opt, idx) => menuItems(opt, idx))}
            </Select>
            <Typography className={classes.helperTxt}>
              {catSection.cols_in_mobile} categories will be shown in a row
            </Typography>
          </FormControl>
          <Typography
            className={classes.lightSubHeading}
            style={{ marginBottom: 12 }}
          >
            Category Layout Desktop
          </Typography>
          <FormControl fullWidth color="secondary" style={{ marginBottom: 12 }}>
            <Select
              name="cols_in_desktop"
              color="secondary"
              variant="outlined"
              fullWidth
              value={catSection.cols_in_desktop}
              onChange={onChangeCatSection}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                classes: { paper: classes.paper },
              }}
              renderValue={(selected) => {
                let value = catLayDeskOptions.filter(
                  (o) => o.id === selected
                )[0].title;
                return (
                  <Typography
                    style={{
                      fontSize: 14,
                    }}
                  >
                    {value}
                  </Typography>
                );
              }}
            >
              {catLayDeskOptions.map((opt, idx) => menuItems(opt, idx))}
            </Select>
            <Typography className={classes.helperTxt}>
              {catSection.cols_in_desktop} categories will be shown in a row
            </Typography>
          </FormControl>
          <Typography
            className={classes.lightSubHeading}
            style={{ marginBottom: 12 }}
          >
            Max Width Desktop
          </Typography>
          <FormControl fullWidth color="secondary" style={{ marginBottom: 12 }}>
            <Select
              name="desktop_width"
              color="secondary"
              variant="outlined"
              fullWidth
              value={catSection.desktop_width}
              onChange={onChangeCatSection}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              renderValue={(selected) => {
                let value = deskWidthOptions.filter((o) => o.id === selected)[0]
                  .title;
                return (
                  <Typography
                    style={{
                      fontSize: 14,
                    }}
                  >
                    {value}
                  </Typography>
                );
              }}
            >
              {deskWidthOptions.map((opt, idx) => menuItems(opt, idx))}
            </Select>
            <Typography className={classes.helperTxt}>
              Width of Categories will be shown in a row
            </Typography>
          </FormControl>
          <Controls.Select
            name="shape"
            label="Category Card Shape"
            value={catSection.shape}
            onChange={onChangeCatSection}
            options={shapeOptions}
            color="secondary"
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            labelPlacement="top"
            labelFontWeight={500}
            helperTxt={`${
              catSection.shape === "ROUNDED" ? "Rounded" : "Square"
            } Category will be shown`}
          />
          <Controls.Switch
            name="show_title"
            label={
              <Typography style={{ fontWeight: 500, fontSize: 14 }}>
                Show Category Title
              </Typography>
            }
            color="secondary"
            value={catSection.show_title}
            onChange={() => {
              setCatSection({
                ...catSection,
                show_title: !catSection.show_title,
              });
              setIsSticky(true);
            }}
            width="100%"
            style={{ marginTop: -10 }}
          />
          {!catSection.show_categories && (
            <div className={classes.whiteLayover} />
          )}
        </div>
      </div>

      <div className={classes.dividerBox} style={{ display: "block" }}>
        <Controls.Switch
          name="show_products"
          label="Product Section"
          color="secondary"
          value={prodSection.show_products}
          onChange={() => {
            setProdSection({
              ...prodSection,
              show_products: !prodSection.show_products,
            });
            setIsSticky(true);
          }}
          width="100%"
        />
        <div style={{ position: "relative" }}>
          <Typography className={classes.lightSubHeading}>
            Product - Max Count
          </Typography>
          <Controls.Input
            name="max_count"
            color="secondary"
            variant="outlined"
            type="number"
            value={prodSection.max_count}
            onChange={(e) => {
              if (parseInt(e.target.value) < 0) return;
              onChangeProdSection(e);
            }}
            helperTxt="Max product shown in the homescreen"
            style={{ marginTop: 12 }}
          />
          <Controls.Select
            name="sort_by"
            label="Product - Sort By"
            value={prodSection.sort_by}
            onChange={onChangeProdSection}
            options={sortOptions}
            color="secondary"
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            labelPlacement="top"
            labelFontWeight={500}
            helperTxt="Sorting for products in homescreen "
          />
          <Typography
            className={classes.lightSubHeading}
            style={{ marginBottom: 12 }}
          >
            Product Display
          </Typography>
          <FormControl fullWidth color="secondary" style={{ marginBottom: 12 }}>
            <Select
              name="card_type"
              color="secondary"
              variant="outlined"
              fullWidth
              value={prodSection.card_type}
              onChange={onChangeProdSection}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              renderValue={(selected) => {
                let value = productCards.filter((o) => o.id === selected)[0]
                  .title;
                return (
                  <Typography
                    style={{
                      fontSize: 14,
                    }}
                  >
                    {value}
                  </Typography>
                );
              }}
            >
              {productCards.map((opt, idx) => menuItems(opt, idx))}
            </Select>
            <Typography className={classes.helperTxt}>
              Product Card Display
            </Typography>
          </FormControl>
          <Typography
            className={classes.lightSubHeading}
            style={{ marginBottom: 12 }}
          >
            Product Layout Mobile
          </Typography>
          <FormControl fullWidth color="secondary" style={{ marginBottom: 12 }}>
            <Select
              name="cols_in_mobile"
              color="secondary"
              variant="outlined"
              fullWidth
              value={prodSection.cols_in_mobile}
              onChange={onChangeProdSection}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              renderValue={(selected) => {
                let value = prodLayMobileOptions.filter(
                  (o) => o.id === selected
                )[0]?.title;
                return (
                  <Typography
                    style={{
                      fontSize: 14,
                    }}
                  >
                    {value}
                  </Typography>
                );
              }}
            >
              {prodLayMobileOptions.map((opt, idx) => menuItems(opt, idx))}
            </Select>
            <Typography className={classes.helperTxt}>
              {prodSection.cols_in_mobile} Products will be shown in a row
            </Typography>
          </FormControl>
          <Typography
            className={classes.lightSubHeading}
            style={{ marginBottom: 12 }}
          >
            Product Layout Desktop
          </Typography>
          <FormControl fullWidth color="secondary" style={{ marginBottom: 12 }}>
            <Select
              name="cols_in_desktop"
              color="secondary"
              variant="outlined"
              fullWidth
              value={prodSection.cols_in_desktop}
              onChange={onChangeProdSection}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                classes: { paper: classes.paper },
              }}
              renderValue={(selected) => {
                let value = prodLayDesktopOptions.filter(
                  (o) => o.id === selected
                )[0]?.title;
                return (
                  <Typography
                    style={{
                      fontSize: 14,
                    }}
                  >
                    {value}
                  </Typography>
                );
              }}
            >
              {prodLayDesktopOptions.map((opt, idx) => menuItems(opt, idx))}
            </Select>
            <Typography className={classes.helperTxt}>
              {prodSection.cols_in_desktop} Products will be shown in a row
            </Typography>
          </FormControl>
          {!prodSection.show_products && (
            <div className={classes.whiteLayover} />
          )}
        </div>
      </div>

      {/* <div
        style={{ display: "flex", justifyContent: "flex-end", paddingTop: 32 }}
      >
        <CombinedButtons
          outlinedBtnAction={() => {
            onClose();
            init();
          }}
          solidBtnAction={onSave}
          loading={loading}
        />
      </div> */}
      <div
        className={isSticky && classes.stickyCont}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingTop: !isSticky && 32,
        }}
      >
        <CombinedButtons
          outlinedBtnAction={() => {
            onClose();
            init();
          }}
          solidBtnAction={onSave}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default HomePageSetting;

const getBoolean = (value) => {
  if (!value) return false;
  return value === "true" || value === true ? true : false;
};
