import { Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import FilledEditButton from "../../../components/buttons/FilledEditButton";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import useStyles from "../components/ConfigStyles";
import {
  fetchApps,
  getPluginConfig,
  postPluginConfig,
} from "../../../redux/apps/appActions";
import { useDispatch, useSelector } from "react-redux";
import { copyToClipboard } from "../../../utils/utils";
import Controls from "../../../components/controls/Controls";
import Toast from "../../../components/Layout/Toast";
import { useHistory } from "react-router-dom";
import { centerContainer, layoutCenterContainer } from "../../../styles/common";
import HeaderBreadcrumbs from "../../../components/common/HeaderBreadcrumbs";
import WebhookDetails from "./components/WebhookDetails";
const list = [
  {
    label: "App Store",
    link: "/apps",
  },
  { label: "iThink Logistics", link: "/apps/ithink" },
  {
    label: "Configure",
  },
];

const IThinkCfg = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const storeData = useSelector((state) => state.store);
  const appState = useSelector((state) => state.apps);
  const apps = appState.apps;

  const initialState = {
    access_token: { value: "", touched: false, error: "" },
    secret_key: {
      touched: false,
      value: "",
      error: "",
    },
  };
  const [formData, setFormData] = useState(initialState);
  const [isEditing, setIsEditing] = useState(true);
  const [showError, setShowError] = useState(null);
  const [showSuccess, setShowSuccess] = useState(null);
  useEffect(() => {
    if (apps.length === 0) {
      dispatch(fetchApps());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (storeData.fetched) {
      dispatch(getPluginConfig("ithink"));
    }
    // eslint-disable-next-line
  }, [storeData]);
  useEffect(() => {
    let form = {};
    let keys = Object.keys(appState.ithink);
    if (keys.length > 0) {
      keys.forEach((key) => {
        form[key] = {
          value: appState.ithink[key],
          touched: false,
          error: "",
        };
      });
      setIsEditing(false);
      setFormData(form);
    }
    // eslint-disable-next-line
  }, [appState.ithink]);

  const getMessage = (name) => {
    const messages = {
      access_token: "API key is required. Please enter a valid API key .",
      secret_key: "Secret key is required. Please enter a valid Secret key .",
    };
    return messages[name] || "";
  };

  const handleChange = (event) => {
    let { name, value } = event.target;

    setFormData((formData) => {
      return {
        ...formData,
        [name]: {
          ...formData[name],
          value: value,
        },
      };
    });
  };

  const onBlur = (event) => {
    let { name } = event.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        error: !formData[name] && getMessage(name),
      },
    }));
  };

  const onFocus = (event) => {
    const { name } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        error: "",
      },
    }));
  };

  const handleCopyToClipboard = (text) => () => {
    const result = copyToClipboard(text);
    if (!result) {
      setShowError("Could not copy to clipboard");
    } else {
      setShowSuccess("Copied to clipboard");
    }
  };

  const validation = () => {
    let errorFlag = false;
    const updatedFormData = Object.keys(formData).reduce((acc, key) => {
      errorFlag = errorFlag || !formData[key].value;
      const updatedField = {
        ...formData[key],
        error: !formData[key].value && getMessage(key),
      };
      return { ...acc, [key]: updatedField };
    }, {});

    setFormData(updatedFormData);

    return errorFlag;
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleOutlined = () => {
    if (isEditing && Object.keys(appState.ithink).length > 0) {
      let form = {};
      Object.keys(appState.ithink).forEach((key) => {
        form[key] = {
          value: appState.ithink[key],
          touched: false,
          error: "",
        };
      });
      setFormData(form);
      setIsEditing(false);
    } else {
      history.goBack();
    }
  };

  const handleSave = async () => {
    const isError = validation();
    if (isError) return;

    dispatch(
      postPluginConfig("ithink", {
        secret_key: formData.secret_key.value,
        access_token: formData.access_token.value,
      })
    ).then((response) => {
      if (response.error) setShowError(response.message);
      else setShowSuccess("API key and secret key saved successfully.");
    });
  };

  return (
    <div className={layoutCenterContainer}>
      <div className={centerContainer}>
        <div className={classes.breadcrumbsContainer}>
          <div className={classes.backDiv}>
            <HeaderBreadcrumbs list={list} />
          </div>
        </div>
        <Paper elevation={0} className={classes.paper}>
          <div>
            <div className={classes.headingButtonSection}>
              <Typography className={classes.formHead}>
                iThink Logistics Configuration Settings
              </Typography>
              {!isEditing && <FilledEditButton onClick={handleEdit} />}
            </div>
            <Controls.Input
              name="access_token"
              labelPlacement="top"
              label="API Key"
              required={true}
              value={formData.access_token.value}
              onChange={handleChange}
              onBlur={onBlur}
              onFocus={onFocus}
              error={formData.access_token.error}
              helperText={!formData.access_token.error && ""}
              type={isEditing ? "text" : "password"}
              extraSpace={!!formData.access_token.error}
            />
            <Controls.Input
              name="secret_key"
              label="Secret Key"
              required={true}
              value={formData.secret_key.value}
              onChange={handleChange}
              onBlur={onBlur}
              onFocus={onFocus}
              labelPlacement="top"
              error={formData.secret_key.error}
              helperText={!formData.secret_key.error && ""}
              type={isEditing ? "text" : "password"}
              extraSpace={!!formData.secret_key.error}
            />
            {!isEditing && (
              <WebhookDetails
                formData={formData}
                handleCopyToClipboard={handleCopyToClipboard}
              />
            )}
          </div>

          {isEditing && (
            <div className={classes.button}>
              <CombinedButtons
                outlinedBtnText="Back"
                outlinedBtnAction={handleOutlined}
                solidBtnAction={handleSave}
              />
            </div>
          )}
        </Paper>
      </div>
      <Toast
        message={showError}
        open={!!showError}
        close={() => setShowError(false)}
        severity="error"
        autoClose
      />
      <Toast
        message={showSuccess}
        open={!!showSuccess}
        close={() => setShowSuccess(false)}
        autoClose
      />
    </div>
  );
};

export default IThinkCfg;
