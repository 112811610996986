import { SvgIcon } from "@material-ui/core";
import React from "react";

export default function PaidPlanIcon({ style = {} }) {
  return (
    <SvgIcon
      viewBox="0 0 58 36"
      style={{ width: "58px", height: "36px", ...style }}
    >
      <svg
        width="58"
        height="36"
        viewBox="0 0 58 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <ellipse cx="29.0004" cy="18" rx="18.3901" ry="18" fill="#FFECEC" />
        <path
          d="M41.7307 8.39384L39.1206 7.97843L38.6944 5.53809L38.3215 8.03037L35.8711 8.39384L38.3215 8.75727L38.6944 11.2496L39.1206 8.80921L41.7307 8.39384Z"
          fill="#FF1D1D"
        />
        <path
          d="M20.6358 5.53809L20.5292 6.52462L19.4639 6.68039L20.476 6.83615L20.6358 7.82264L20.8489 6.83615L21.861 6.68039L20.7956 6.52462L20.6358 5.53809Z"
          fill="#FF1D1D"
        />
        <path
          d="M19.1997 8.70482L18.9334 6.93945L18.6671 8.70482L16.9092 8.96441L18.6671 9.27594L18.9334 11.0413L19.1997 9.27594L21.0642 8.96441L19.1997 8.70482Z"
          fill="#9C46C8"
        />
        <path
          d="M32.9955 16.0269L29.746 10.0039H28.4143L24.792 16.0269H32.9955Z"
          fill="#8715F4"
        />
        <path
          d="M37.1496 10.0039H29.7451L32.9946 16.0269L37.1496 10.0039Z"
          fill="#D7DAFF"
        />
        <path
          d="M41.6267 16.0269L37.2054 10.0039H37.1521L32.9971 16.0269H41.6267Z"
          fill="#8715F4"
        />
        <path
          d="M28.947 30.4617L32.9955 16.0273H24.792L28.947 30.4617Z"
          fill="#9973EF"
        />
        <path
          d="M28.414 9.7168H20.7432L24.7916 15.7398L28.414 9.7168Z"
          fill="#D7DAFF"
        />
        <path
          d="M20.7432 10.0039H20.6899L16.2686 16.0269H24.7916L20.7432 10.0039Z"
          fill="#8715F4"
        />
        <path
          d="M41.6248 15.7393L28.9467 30.1736L16.2686 15.7393H24.7916L28.9467 30.1736L32.9952 15.7393H41.6248Z"
          fill="#D7DAFF"
        />
        <path
          d="M33 14.418L32.7869 15.7679L31.3486 15.9756L32.7869 16.1834L33 17.6372L33.2131 16.1834L34.6514 15.9756L33.2131 15.7679L33 14.418Z"
          fill="white"
        />
        <path
          d="M22.5566 11.4062L22.3436 12.7563L20.9053 12.9639L22.2903 13.1716L22.5566 14.6254L22.7697 13.2235L24.208 12.9639L22.7697 12.7563L22.5566 11.4062Z"
          fill="white"
        />
        <path
          d="M16.9758 17.8267L15.2357 17.5498L14.9516 15.9229L14.703 17.5844L13.0693 17.8267L14.703 18.069L14.9516 19.7306L15.2357 18.1036L16.9758 17.8267Z"
          fill="#FF1D1D"
        />
        <path
          d="M2.91409 15.9229L2.84308 16.5806L2.13281 16.6844L2.80755 16.7882L2.91409 17.4459L3.05616 16.7882L3.73093 16.6844L3.02063 16.5806L2.91409 15.9229Z"
          fill="#FF1D1D"
        />
        <path
          d="M1.95285 18.0344L1.77529 16.8574L1.59773 18.0344L0.425781 18.2074L1.59773 18.4151L1.77529 19.592L1.95285 18.4151L3.19585 18.2074L1.95285 18.0344Z"
          fill="#EE4971"
        />
        <path
          d="M11.1517 22.9148L8.98538 18.8994H8.09756L5.68262 22.9148H11.1517Z"
          fill="#EE4998"
        />
        <path
          d="M13.9218 18.8994H8.98535L11.1517 22.9148L13.9218 18.8994Z"
          fill="#FFA3CF"
        />
        <path
          d="M16.9056 22.9148L13.9579 18.8994H13.9224L11.1523 22.9148H16.9056Z"
          fill="#EE4998"
        />
        <path
          d="M8.45267 32.5381L11.1517 22.915H5.68262L8.45267 32.5381Z"
          fill="#D6076A"
        />
        <path
          d="M8.09735 18.6914H2.9834L5.6824 22.7068L8.09735 18.6914Z"
          fill="#FFA3CF"
        />
        <path
          d="M2.98314 18.8994H2.94762L0 22.9148H5.68215L2.98314 18.8994Z"
          fill="#EE4998"
        />
        <path
          d="M16.9045 22.707L8.45221 32.3301L0 22.707H5.68215L8.45221 32.3301L11.1513 22.707H16.9045Z"
          fill="#FFA3CF"
        />
        <path
          d="M11.1498 21.8418L11.0077 22.7418L10.0488 22.8803L11.0077 23.0187L11.1498 23.988L11.2918 23.0187L12.2507 22.8803L11.2918 22.7418L11.1498 21.8418Z"
          fill="white"
        />
        <path
          d="M4.18879 19.834L4.04676 20.734L3.08789 20.8724L4.01123 21.0109L4.18879 21.9801L4.33086 21.0455L5.28973 20.8724L4.33086 20.734L4.18879 19.834Z"
          fill="white"
        />
        <path
          d="M57.9993 19.2125L56.2591 18.9355L55.975 17.3086L55.7264 18.9701L54.0928 19.2125L55.7264 19.4548L55.975 21.1163L56.2591 19.4894L57.9993 19.2125Z"
          fill="#FF1D1D"
        />
        <path
          d="M43.9375 17.3086L43.8665 17.9663L43.1562 18.0701L43.831 18.174L43.9375 18.8317L44.0796 18.174L44.7544 18.0701L44.0441 17.9663L43.9375 17.3086Z"
          fill="#FF1D1D"
        />
        <path
          d="M42.9782 19.4201L42.8007 18.2432L42.6231 19.4201L41.4512 19.5932L42.6231 19.8009L42.8007 20.9778L42.9782 19.8009L44.2212 19.5932L42.9782 19.4201Z"
          fill="#04113F"
        />
        <path
          d="M52.1771 24.3006L50.0108 20.2852H49.123L46.708 24.3006H52.1771Z"
          fill="#F85531"
        />
        <path
          d="M54.9471 20.2852H50.0107L52.1771 24.3006L54.9471 20.2852Z"
          fill="#FFCE88"
        />
        <path
          d="M57.9309 24.3006L54.9833 20.2852H54.9478L52.1777 24.3006H57.9309Z"
          fill="#F85531"
        />
        <path
          d="M49.4781 33.9239L52.1771 24.3008H46.708L49.4781 33.9239Z"
          fill="#F87231"
        />
        <path
          d="M49.1227 20.0938H44.0088L46.7078 24.1092L49.1227 20.0938Z"
          fill="#FFCE88"
        />
        <path
          d="M44.0085 20.2852H43.973L41.0254 24.3006H46.7075L44.0085 20.2852Z"
          fill="#F85531"
        />
        <path
          d="M57.9299 24.1084L49.4776 33.7315L41.0254 24.1084H46.7075L49.4776 33.7315L52.1767 24.1084H57.9299Z"
          fill="#FFCE88"
        />
        <path
          d="M52.1761 23.2275L52.0341 24.1275L51.0752 24.266L52.0341 24.4045L52.1761 25.3737L52.3182 24.4045L53.2771 24.266L52.3182 24.1275L52.1761 23.2275Z"
          fill="white"
        />
        <path
          d="M45.2152 21.2197L45.0731 22.1197L44.1143 22.2582L45.0376 22.3967L45.2152 23.3659L45.3572 22.4313L46.3161 22.2582L45.3572 22.1197L45.2152 21.2197Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
}
