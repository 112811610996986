import React from "react";
import { rowFlexOnlyJustifyBetween } from "../../styles/common";
import OutlinedButton from "../buttons/OutlinedButton";
import SolidButton from "../buttons/SolidButton";

export const BaseCTAs = ({ onSave, onCancel }) => {
  return (
    <div className={rowFlexOnlyJustifyBetween}>
      <SolidButton
        style={{
          width: "49%",
          minWidth: 0,
          fontSize: 15,
        }}
        onClick={onSave}
      >
        {"Save"}
      </SolidButton>
      <OutlinedButton
        style={{
          width: "49%",
          minWidth: 0,
          fontSize: 15,
        }}
        onClick={onCancel}
      >
        Cancel
      </OutlinedButton>
    </div>
  );
};
export default BaseCTAs;
