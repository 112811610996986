import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CatalogueIcon = ({
  viewBox = "0 0 26 26",
  stroke = "#999999",
  fill = "#999999",
}) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "26px", height: "26px" }}>
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.0512 14.8932C20.4223 14.4945 20.7116 13.9423 20.6179 13.4613L21.0182 4.1097L12.5385 5.76923C10.7366 6.10197 10.7796 5.99193 10.4084 6.39063L1.67217 15.7755C1.30103 16.1742 1.33224 16.7902 1.7419 17.1514L9.90114 24.346C10.3108 24.7072 10.9438 24.6768 11.3149 24.2781L20.0512 14.8932Z"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.9892 10.0231C17.3242 10.7375 16.2115 10.793 15.5049 10.17C14.7984 9.54697 14.7456 8.46381 15.4106 7.74942C16.0757 7.03503 17.1884 6.97954 17.8949 7.60255C18.6015 8.22556 18.6542 9.30873 17.9892 10.0231Z"
          stroke={stroke}
        />
        <path
          d="M7.34693 16.3376C7.75658 16.6988 8.38955 16.6685 8.7607 16.2698C9.13184 15.8711 9.10062 15.255 8.69097 14.8938C8.28131 14.5326 7.64834 14.563 7.2772 14.9617C6.90605 15.3604 6.93727 15.9764 7.34693 16.3376Z"
          fill={fill}
        />
        <path
          d="M12.9524 16.3376C13.3621 16.6988 13.995 16.6685 14.3662 16.2698C14.7373 15.8711 14.7061 15.255 14.2964 14.8938C13.8868 14.5326 13.2538 14.563 12.8827 14.9617C12.5115 15.3604 12.5427 15.9764 12.9524 16.3376Z"
          fill={fill}
        />
        <line
          x1="11.1084"
          y1="11.6235"
          x2="11.1084"
          y2="19.9752"
          stroke={stroke}
          strokeLinecap="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default CatalogueIcon;
