import { CLEAR_DATA, CLEAR_COMPLETE } from "../commom/commonTypes";
import {
  ADD_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  FETCH_CATEGORIES,
  SET_CATEGORY_ERROR,
  CLEAR_CATEGORY_ERROR,
  CATEGORY_PROGRESS,
  CATEGORY_LOADER,
  UPDATE_CATEGORY_LIST,
} from "./categoryTypes";

const initialState = {
  categories: [],
  categoryOptions: [],
  categoriesMap: {},
  progress: true,
  error: null,
  loader: false,
  loaded: false,
};

const createCategoryOptions = (newCats) => {
  if (newCats.length === 0) {
    return [];
  }
  let parentSlugMap = newCats
    .filter((cat) => cat.level !== 0)
    .reduce((accu, cat) => {
      const key = cat["parent_cat_slug"];
      if (!accu[key]) {
        accu[key] = [];
      }
      accu[key].push(cat);
      return accu;
    }, {});

  let catOptions = [];

  newCats
    .filter((cat) => cat.level === 0)
    .forEach((pcat) => {
      catOptions.push({ id: pcat.slug, title: pcat.name });

      if (parentSlugMap[pcat.slug]) {
        parentSlugMap[pcat.slug].forEach((ccat) => {
          catOptions.push({
            id: ccat.slug,
            title: ccat.name,
            indent: true,
            parent_slug: ccat.parent_cat_slug || null,
          });
        });
      }
    });

  return catOptions;
};

const createCategoryMap = (cats) => {
  return cats.reduce((acc, cat) => {
    acc[cat.slug] = cat;
    return acc;
  }, {});
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CATEGORY_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      };
    case CATEGORY_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case FETCH_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
        categoryOptions: createCategoryOptions(action.payload),
        categoriesMap: createCategoryMap(action.payload),
        progress: false,
        loaded: true,
      };
    case ADD_CATEGORY:
      let newCats = [action.payload, ...state.categories];
      return {
        ...state,
        category: action.payload,
        categories: newCats,
        categoriesMap: createCategoryMap(newCats),
        categoryOptions: createCategoryOptions(newCats),
        loader: false,
      };
    case UPDATE_CATEGORY:
      let modCategories = state.categories.map((cat) => {
        if (cat.id === action.payload.id) {
          return action.payload;
        }
        return cat;
      });
      return {
        ...state,
        category: action.payload,
        categories: modCategories,
        categoriesMap: createCategoryMap(modCategories),
        categoryOptions: createCategoryOptions(modCategories),
        loader: false,
      };

    case UPDATE_CATEGORY_LIST:
      const catMap = new Map(
        state.categories.map((category, index) => [category.id, index])
      );

      action.payload.forEach((updatedCategory) => {
        const index = catMap.get(updatedCategory.id);
        if (index !== undefined) {
          state.categories[index] = updatedCategory;
        }
      });

      state.categories = state.categories.sort(
        (a, b) => b.position - a.position
      );

      return {
        ...state,
        categories: state.categories,
        categoryOptions: createCategoryOptions(state.categories),
      };

    case DELETE_CATEGORY:
      let modCats = state.categories.filter(
        (category) => category.id !== action.payload.id
      );
      return {
        ...state,
        categories: modCats,
        categoryOptions: createCategoryOptions(modCats),
        loader: false,
      };
    case SET_CATEGORY_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loader: false,
      };
    case CLEAR_CATEGORY_ERROR:
      return {
        ...state,
        error: null,
      };
    case CLEAR_COMPLETE:
    case CLEAR_DATA:
      return initialState;
    default:
      return state;
  }
};
export default reducer;
