import { RELOAD } from "./reloadTypes";

const initialState = {
  reload: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RELOAD:
      return {
        reload: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
