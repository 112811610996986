import { CLEAR_COMPLETE, CLEAR_DATA } from "../commom/commonTypes";
import {
  FETCH_ACTIVITIES,
  FETCH_ONLINE_STORES,
  FETCH_PROMOTION_BANNER,
  SET_DASHBOARD_LOADER,
  SET_INSIGHTS_FILTER,
} from "./dashboardTypes";

const initialState = {
  banners: [],
  activities: [],
  onlineStores: [],
  insightsFilter: null,
  loader: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROMOTION_BANNER:
      return {
        ...state,
        banners: action.payload,
      };
    case FETCH_ACTIVITIES:
      return {
        ...state,
        activities: action.payload,
        loader: false,
      };
    case FETCH_ONLINE_STORES:
      return {
        ...state,
        onlineStores: action.payload,
        loader: false,
      };
    case SET_INSIGHTS_FILTER:
      return {
        ...state,
        insightsFilter: action.payload,
      };
    case SET_DASHBOARD_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case CLEAR_COMPLETE:
    case CLEAR_DATA:
      return {
        ...state,
        banners: [],
        activities: [],
        insightsFilter: null,
        loader: false,
      };
    default:
      return state;
  }
};
export default reducer;
