import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ContentCopyIcon = ({ fill = "#BDBDBD", ...props }) => {
  return (
    <SvgIcon
      viewBox={"0 0 17 20"}
      style={{ width: "17px", height: "20px" }}
      {...props}
    >
      <svg
        width="17px"
        height="20px"
        viewBox="0 0 17 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.7502 19.2998C2.2502 19.2998 1.8252 19.1248 1.4752 18.7748C1.1252 18.4248 0.950195 17.9998 0.950195 17.4998V4.7998H2.4502V17.4998C2.4502 17.5831 2.4792 17.6538 2.5372 17.7118C2.59586 17.7705 2.66686 17.7998 2.7502 17.7998H12.4502V19.2998H2.7502ZM6.2502 15.7998C5.7502 15.7998 5.32519 15.6248 4.97519 15.2748C4.62519 14.9248 4.4502 14.4998 4.4502 13.9998V2.6248C4.4502 2.10814 4.62519 1.6748 4.97519 1.3248C5.32519 0.974805 5.7502 0.799805 6.2502 0.799805H14.6252C15.1419 0.799805 15.5752 0.974805 15.9252 1.3248C16.2752 1.6748 16.4502 2.10814 16.4502 2.6248V13.9998C16.4502 14.4998 16.2752 14.9248 15.9252 15.2748C15.5752 15.6248 15.1419 15.7998 14.6252 15.7998H6.2502ZM6.2502 14.2998H14.6252C14.7085 14.2998 14.7835 14.2705 14.8502 14.2118C14.9169 14.1538 14.9502 14.0831 14.9502 13.9998V2.6248C14.9502 2.54147 14.9169 2.46647 14.8502 2.3998C14.7835 2.33314 14.7085 2.2998 14.6252 2.2998H6.2502C6.16686 2.2998 6.0962 2.33314 6.0382 2.3998C5.97953 2.46647 5.9502 2.54147 5.9502 2.6248V13.9998C5.9502 14.0831 5.97953 14.1538 6.0382 14.2118C6.0962 14.2705 6.16686 14.2998 6.2502 14.2998Z"
          fill={fill}
        />
      </svg>
    </SvgIcon>
  );
};

export default ContentCopyIcon;
