import React from "react";
import { Grid, IconButton, makeStyles, Modal } from "@material-ui/core";
import { useState } from "react";
import Controls from "../../../components/controls/Controls";
import "react-image-crop/dist/ReactCrop.css";
import RemoveCircleIcon from "../../../icons/RemoveCircleIcon";
import {
  addCategory,
  editCategory,
  startLoader,
} from "../../../redux/category/categoryActions";
import { useDispatch, useSelector } from "react-redux";
import { uploadImage } from "../../../utils/imageUploader";
import ImageHandler from "../../../components/ImageHandler";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import GoogleCategory from "./GoogleCategory";
import DrawerCloseIcon from "../../../icons/DrawerCloseIcon";
import { getStoreUrl } from "../../../utils/storeUtil";
import SeoForm from "../../../components/common/SeoForm";
import {
  generateSlug,
  convertToSlug,
} from "../../../components/common/CommonUtils";

const useStyles = makeStyles(() => ({
  parentContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  formSection: {
    width: "100%",
  },
  heading: {
    fontWeight: 700,
    fontSize: 20,
  },
  subHeading: {
    fontWeight: 600,
    fontSize: 14,
    color: "#1A1A1A",
    marginBottom: 10,
  },
  bottomDivider: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingBottom: 10,
    borderBottom: "1px #E1E1E1 solid",
  },
  previewModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonSection: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
  },
}));

const CategoryDrawer = ({
  gmc,
  edit,
  category,
  parentCategory,
  parentCategories,
  closeCategoryDrawer,
  setSuccess,
  setError,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const store = useSelector((state) => state.store.store);
  const storeUrl = getStoreUrl(store);

  const defaultCrop = { aspect: 1 / 1, x: 0, y: 0, width: 250, height: 250 };
  const initialState = {
    category_name: {
      value: category?.name ?? "",
      error: "",
      touched: false,
    },
    gmc_category: {
      value: category?.google_category
        ? { name: category.google_category }
        : null,
      error: "",
      touched: false,
    },
    slug: {
      value: category?.slug ?? "",
      error: "",
      touched: false,
    },
    seo_title: {
      value: category?.seo_title ?? "",
      error: "",
      touched: false,
    },
    seo_description: {
      value: category?.seo_description ?? "",
      error: "",
      touched: false,
    },
    ...(parentCategory && {
      parent_category: {
        value: parentCategory.slug ?? "none",
        error: "",
        touched: false,
      },
    }),
  };

  const [formState, setFormState] = useState(initialState);
  const [filename, setFilename] = useState(category?.thumbnail ?? "");
  const [cropImg, setCropImg] = useState(category?.thumbnail ?? null);
  const [preview, setPreview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSeoFiled, setOpenSeoField] = useState(false);
  const [openImageHandler, setOpenImageHandler] = useState(false);
  const [openImageHandlerIndex, setOpenImageHandlerIndex] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const handleChange = (event) => {
    let { name, value } = event.target;

    if (name === "category_name") {
      let cloneData = formState.category_name;
      let slugData = formState.slug;
      let seoTitleData = formState.seo_title;

      cloneData.touched = true;
      cloneData.value = value;

      if (!category?.slug && !slugData.touched) {
        slugData.value = generateSlug(value);
      }

      if (!category?.seo_title && !seoTitleData.touched) {
        seoTitleData.value = value;
      }

      setFormState({
        ...formState,
        category_name: cloneData,
        slug: slugData,
        seo_title: seoTitleData,
      });
    } else {
      let cloneData = formState[name];
      cloneData.touched = true;
      cloneData.value = name === "slug" ? convertToSlug(value) : value;
      setFormState({ ...formState, [name]: cloneData });
    }
  };

  const onBlur = (event) => {
    const { name } = event.target;
    const cloneData = formState[name];
    if (!cloneData.value) {
      cloneData.error = "This field is required.";
    }
    setFormState((prevState) => ({ ...prevState, [name]: cloneData }));
  };

  const onFocus = (event) => {
    const { name } = event.target;
    let cloneData = formState[name];
    cloneData.touched = true;
    cloneData.error = "";
    setFormState((prevState) => ({ ...prevState, [name]: cloneData }));
  };

  const formValidation = () => {
    let isValid = true;

    if (formState.category_name.value.trim().length < 3) {
      let cloneData = formState.category_name;
      cloneData.error = "Enter category name of atleast 3 characters";
      cloneData.touched = true;
      setFormState({ ...formState, category_name: cloneData });
      isValid = false;
    }

    if (parentCategory && formState.parent_category.value === "none") {
      let cloneData = formState.parent_category;
      cloneData.error = "Please select parent category.";
      cloneData.touched = true;
      setFormState({ ...formState, parent_category: cloneData });
      isValid = false;
    }

    if (!formState.slug.value) {
      let cloneData = formState.slug;
      cloneData.error = "This field required.";
      cloneData.touched = true;
      setFormState({ ...formState, slug: cloneData });
      isValid = false;
    }

    return isValid;
  };

  const handleCategoryOperation = async (operationType) => {
    const isValid = formValidation();
    if (!isValid) {
      return;
    }

    dispatch(startLoader());
    setLoading(true);

    let imageUrl = null;
    if (cropImg) {
      imageUrl = cropImg.startsWith("https:")
        ? cropImg
        : (await uploadImage(filename, cropImg)).payload.location;
    }

    const categoryData = {
      name: formState.category_name.value,
      level: !formState?.parent_category?.value ? 0 : 1,
      is_leaf_node: !formState?.parent_category?.value ? false : true,
      parent_cat_slug: formState?.parent_category?.value,
      thumbnail: imageUrl,
      google_category: formState.gmc_category?.value?.name ?? null,
      slug: formState.slug.value,
      seo_title: formState.seo_title.value,
      seo_description: formState.seo_description.value,
    };

    if (operationType === "update") {
      categoryData.id = category.id; // Only needed for update
    }

    try {
      const response = await dispatch(
        operationType === "add"
          ? addCategory(categoryData)
          : editCategory(categoryData)
      );

      handleResponse(response);
    } catch (error) {
      setLoading(false);
      setError({
        value: true,
        message: error.message,
      });
    }
  };

  const handleResponse = (response) => {
    const pattern = /^URL Slug already used in '(.+)'$/;

    if (response.error) {
      setLoading(false);
      if (pattern.test(response.error)) {
        let cloneData = {
          ...formState["slug"],
          error: "Provided URL slug is already used in a category.",
        };
        setFormState((prevState) => ({
          ...prevState,
          slug: cloneData,
        }));
        setOpenSeoField(true);
      } else {
        setError({
          value: true,
          message: response.error,
        });
      }
    } else {
      setLoading(false);
      setSuccess({
        value: true,
        message: `${response.name} category ${
          response.operationType === "add" ? "added" : "saved"
        } successfully`,
      });
      closeCategoryDrawer();
    }
  };

  const onAddCategory = () => handleCategoryOperation("add");

  const onUpdateCategory = () => handleCategoryOperation("update");

  return (
    <div className={classes.parentContainer}>
      <div className={classes.formSection}>
        <Grid item xs={12} className={classes.bottomDivider}>
          <div className={classes.heading}>
            {parentCategory
              ? edit
                ? "Edit Sub Category"
                : "Add Sub Category"
              : edit
              ? "Edit Category"
              : "Add Category"}
          </div>
          <IconButton
            aria-label="Close"
            aria-haspopup="true"
            color="inherit"
            onClick={closeCategoryDrawer}
          >
            <DrawerCloseIcon />
          </IconButton>
        </Grid>
        {preview && (
          <Modal
            open={Boolean(preview)}
            disableAutoFocus
            disableEnforceFocus
            onClose={() => setPreview(false)}
            className={classes.previewModal}
          >
            <img src={preview} style={{ height: "85%" }} alt="" />
          </Modal>
        )}
        <Grid item xs={12} style={{ marginTop: 16 }}>
          <p className={classes.subHeading}>Image</p>

          <ImageHandler
            cropImg={cropImg}
            setCropImg={setCropImg}
            defaultCrop={defaultCrop}
            setFilename={setFilename}
            imgFormat="image/png"
            maxSize={800}
            openImageHandler={openImageHandler}
            setOpenImageHandler={setOpenImageHandler}
            openImageHandlerIndex={openImageHandlerIndex}
            openModal={openModal}
            setOpenModal={setOpenModal}
          />
          {cropImg && (
            <div style={{ width: 60, position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  top: -11,
                  right: -11,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setCropImg(null);
                }}
              >
                <RemoveCircleIcon />
              </div>
              <img
                src={cropImg}
                alt=""
                style={{ width: 60, height: 60, borderRadius: 6 }}
                onClick={() => {
                  setOpenImageHandler(true);
                  setOpenImageHandlerIndex(0);
                }}
                role="button"
              />
            </div>
          )}
        </Grid>
        <Grid item xs={12} style={{ marginTop: 16 }}>
          <Controls.Input
            type="text"
            label="Name"
            name="category_name"
            value={formState.category_name.value}
            maxLength={45}
            onChange={handleChange}
            onBlur={onBlur}
            onFocus={onFocus}
            labelPlacement="top"
            error={formState.category_name.error}
          />
        </Grid>
        {parentCategory && (
          <Grid item xs={12} style={{ marginTop: 16 }}>
            <Controls.Select
              name="parent_category"
              label="Parent Category"
              value={formState.parent_category.value}
              placeholder="Select Parent Category"
              onChange={(event) => {
                onFocus(event);
                handleChange(event);
              }}
              onBlur={onBlur}
              options={
                !parentCategories
                  ? []
                  : parentCategories.map((category) => ({
                      id: category.slug,
                      title: category.name,
                    }))
              }
              helperText=""
              labelPlacement="top"
              error={formState.parent_category.error}
            />
          </Grid>
        )}
        <Grid item xs={12} style={{ margin: "16px 0px 20px" }}>
          <SeoForm
            previewSubHeading="(Google search displays the category page as follows.)"
            previewPageUrl={`${storeUrl}/categories/${
              formState.slug.value !== ""
                ? formState.slug.value
                : "catgeory-slug"
            }`}
            previewSeoTitle={
              formState.seo_title.value !== ""
                ? formState.seo_title.value
                : formState.category_name.value
                ? formState.category_name.value
                : "SEO Title"
            }
            previewSeoDescription={
              formState.seo_description.value
                ? formState.seo_description.value
                : `Buy ${formState.category_name.value} products from our online store.`
            }
            seoDescriptionPlaceholder={`Buy ${formState.category_name.value} products from our online store.`}
            formState={formState}
            handleChange={handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
            openSeoSettingFileld={openSeoFiled}
          />
        </Grid>
        {gmc && gmc.installed && (
          <Grid item xs={12} style={{ marginTop: 16, marginBottom: 20 }}>
            <p className={classes.subHeading}>
              Google Merchant Center (GMC) Category
            </p>
            <GoogleCategory formState={formState} setFormState={setFormState} />
          </Grid>
        )}
      </div>
      <div className={classes.buttonSection}>
        <CombinedButtons
          outlinedBtnAction={closeCategoryDrawer}
          solidBtnText={edit ? "Save" : "Add"}
          solidBtnAction={edit ? onUpdateCategory : onAddCategory}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default CategoryDrawer;
