// Importing action types
import { CLEAR_COMPLETE, CLEAR_DATA } from "../commom/commonTypes";
import {
  SET_REPORTS,
  FETCH_REPORTS_STATUS,
  CLEAR_REPORTS,
  ADD_REPORT,
  UPDATE_REPORT,
} from "./reportTypes";
// Declaring initial state
const initialState = {
  reportList: [],
  fetchReportStatus: null,
  generateReportStatus: null,
  currentPage: 0,
  totalElements: 0,
};
// Defining reducer function
const reducer = (state = initialState, action) => {
  let clonedState = { ...state };
  switch (action.type) {
    case SET_REPORTS:
      clonedState.reportList = [
        ...clonedState.reportList,
        ...action.payload.content,
      ];
      clonedState.totalElements = action.payload.totalElements;
      clonedState.fetchReportStatus = "completed";
      clonedState.currentPage += 1;
      return clonedState;
    case ADD_REPORT:
      clonedState.totalElements += 1;
      clonedState.reportList = [action.payload, ...clonedState.reportList];
      return clonedState;
    case UPDATE_REPORT:
      clonedState.reportList = clonedState.reportList.map((report) => {
        if (report.id === action.id) {
          return { ...action.payload.data };
        } else {
          return report;
        }
      });
      return clonedState;
    case FETCH_REPORTS_STATUS:
      clonedState.fetchReportStatus = action.payload;
      return clonedState;
    case CLEAR_REPORTS:
    case CLEAR_COMPLETE:
    case CLEAR_DATA:
      return initialState;
    default:
      return state;
  }
};
// Exporting reducer
export default reducer;
