import React from "react";
import useStyles from "../styles";
import { Typography } from "@material-ui/core";
import { RemoveCircleIcon } from "../../../icons";

const DisplayBannerImg = ({
  height,
  width,
  src,
  recommendSize = "",
  removeCropImg,
  removeImgFilename,
  onImageClick = () => {},
}) => {
  const classes = useStyles();
  return (
    <>
      <div
        style={{
          height: height,
          width: width,
          position: "relative",
        }}
      >
        <div
          style={{ position: "absolute", top: -11, right: -11 }}
          onClick={() => {
            removeCropImg(null);
            removeImgFilename(null);
          }}
          role="button"
        >
          <RemoveCircleIcon />
        </div>
        <img
          src={src}
          className={classes.bannerImg}
          onClick={onImageClick}
          alt=""
          style={{ cursor: "pointer" }}
        />
      </div>
      {recommendSize.length > 0 && (
        <Typography className={classes.sizeNote}>
          Recommend Size - {recommendSize}
        </Typography>
      )}
    </>
  );
};

export default DisplayBannerImg;
