// Core react imports
import React from "react";
// mui imports
import {
  Box,
  Typography,
  Divider,
  makeStyles,
  Button,
} from "@material-ui/core";
// Icon imports
import Reorder from "../../../icons/Reorder";
import DeleteIcon from "../../../icons/DeleteIcon";
import FilledEditButton from "../../../components/buttons/FilledEditButton";
const useStyles = makeStyles(() => ({
  tableText: {
    fontSize: "12px",
    lineHeight: "16px",
    padding: "0px 10px",
  },
}));
// exporting component
export default function CheckoutFieldTableRow({
  field,
  showDivider,
  handleProps,
  handleDelete,
  handleEdit,
}) {
  const classes = useStyles();
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        padding="36px 16px"
        marginTop="-40px"
      >
        <Box width="7%" textAlign="center" {...handleProps}>
          <Reorder />
        </Box>
        <Typography
          style={{ width: "14%", color: "#1A1A1A" }}
          className={classes.tableText}
        >
          {field.name}
        </Typography>
        <Typography
          style={{ width: "10%", color: "#666" }}
          className={classes.tableText}
        >
          {field.type}
        </Typography>
        <Typography
          style={{ width: "20%", color: "#666" }}
          className={classes.tableText}
        >
          {field.description}
        </Typography>
        <Typography
          style={{ width: "11%", color: "#666" }}
          className={classes.tableText}
        >
          {field.required ? "YES" : "NO"}
        </Typography>
        <Typography
          style={{
            width: "20%",
            textAlign: "left",
            color: "#666",
          }}
          className={classes.tableText}
        >
          {field.type === "LIST" ? field.data : " ----"}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-around"
          width="18%"
        >
          <FilledEditButton
            textStyle={{ fontSize: 14, fontWeight: 500 }}
            onClick={() => handleEdit(field)}
          />
          <Button
            style={{
              margin: "0px 10px",
              textTransform: "none",
              color: "#ED0000",
              background: "none",
            }}
            startIcon={<DeleteIcon />}
            onClick={() => handleDelete(field)}
          >
            Delete
          </Button>
        </Box>
      </Box>
      {showDivider && <Divider style={{ margin: "0px 16px" }} />}
    </>
  );
}
