import { makeStyles } from "@material-ui/styles";
export default makeStyles((theme) => ({
  tablehead: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px -1px 0px #E5E5E5, 0px 1px 0px #E6E6E6",
  },
  tablebody: {
    "& tr": {
      height: "68px",
    },
  },
  permmisonHead: {
    fontWeight: 600,
    fontSize: "14px",
    marginLeft: "9px",
  },
  indicator: {
    background: "#1A1A1A",
    width: "4px",
    height: "4px",
    display: "inline-block",
    marginLeft: "4px",
    borderRadius: "50%",
  },
  permmisonTxt: {
    fontWeight: 400,
    fontSize: "14px",
    marginLeft: "16px",
  },
  formField: {
    marginBottom: "16px",
  },
  upgradeLink: {
    cursor: "pointer",
    color: "#1641DB",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));
