import React, { Fragment, useEffect, useState } from "react";
import useStyles from "./styles";
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";

import {
  AddCustomerIcon,
  ArrowIcon,
  NoCustomersFigma,
  SettingsIcon,
} from "../../icons";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchStoreCustomers,
  clearStoreCustomers,
  setStoreCustomersPerPage,
  setCustomerBalance,
  setStoreCustomersInputKeyword,
  setStoreCustomersSearchKeyword,
  setStoreCustomersCurrentPage,
  setStoreCustomersMaxPageSoFar,
  setStoreCustomerBalanceSelector,
  setSuccessToast,
} from "../../redux/storeCustomers/storeCustomerActions";
import {
  STORE_CUSTOMERS_PER_PAGE,
  DEFAULT_CUSTOMERS_PER_PAGE,
  CUSTOMERS_KEY,
} from "../../utils/utils";
import Paginator from "../../components/Paginator/Paginator";
import { getStoreIdFromUrl } from "../../utils/storeUtil";
import Toast from "../../components/Layout/Toast";
import {
  layoutBodyBox,
  layoutContainer,
  layoutLoaderHeader,
  layoutSubHeader,
  rowFlexJustifyBetween,
} from "../../styles/common";
import SolidButton from "../../components/buttons/SolidButton";
import PageLoader from "../../components/Layout/PageLoader";
import HeaderBreadcrumbs from "../../components/common/HeaderBreadcrumbs";
import OutlinedButton from "../../components/buttons/OutlinedButton";
import { useHistory, useParams } from "react-router-dom";
import { WhatsApp } from "@material-ui/icons";
import SubtitleSelect from "../../components/controls/SubtitleSelect";
import StoreTransactionModal from "./components/StoreTransactionModal";
import PaidPopUp from "../../components/PaidPopUp";
import { PLANS, BUSINESS_APP_PRO } from "../plans/constants";

const Customers = () => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();
  const state = useSelector((state) => state);
  const store = state.store.store;
  const storeCustomerState = useSelector((state) => state.storeCustomers);
  const storeCustomers = storeCustomerState.storeCustomers;
  const numberOfPages = storeCustomerState.numberOfPages;
  const storeCustomersPerPage = storeCustomerState.storeCustomersPerPage;
  const currentPage = storeCustomerState.currentPage;
  const maxPageSoFar = storeCustomerState.maxPageSoFar;
  const searchKeyword = storeCustomerState.searchKeyword;
  const inputKeyword = storeCustomerState.inputKeyword;
  const balanceSelector = storeCustomerState.balanceSelector;
  const successToast = storeCustomerState.successToast;
  const [whiteSpaceDiv, setWhiteSpaceDiv] = useState(false);
  const [openTxnModal, setOpenTxnModal] = useState(false);
  const [anchoredCustomer, setAnchoredCustomer] = useState(null);
  const [transactionModalType, toggleTransactionModalType] = useState("GIVEN");
  const [toast, setToast] = useState(false);
  const [openPremiumModal, setOpenPremiumModal] = useState(false);

  const onChangeCustomersPerPage = (updatedCustomersPerPage) => {
    localStorage.setItem(CUSTOMERS_KEY, updatedCustomersPerPage);
    dispatch(clearStoreCustomers());
    dispatch(setStoreCustomersCurrentPage(0));
    dispatch(setStoreCustomersMaxPageSoFar(0));
    dispatch(setStoreCustomersPerPage(updatedCustomersPerPage));
    dispatch(
      fetchStoreCustomers(
        0,
        searchKeyword,
        balanceSelector,
        updatedCustomersPerPage
      )
    );
  };
  let startIndex = currentPage * storeCustomersPerPage;
  let endIndex =
    startIndex + storeCustomersPerPage < storeCustomers.length
      ? startIndex + storeCustomersPerPage
      : storeCustomers.length;

  const dispatch = useDispatch();

  const storeId = getStoreIdFromUrl();
  useEffect(() => {
    if (
      (storeCustomers.length === 0 && !balanceSelector) ||
      storeCustomerState.pageDirty
    ) {
      dispatch(setStoreCustomerBalanceSelector("All"));
      dispatch(fetchStoreCustomers());
    }
    // eslint-disable-next-line
  }, [storeId]);

  useEffect(() => {
    if (!storeCustomersPerPage) {
      dispatch(setStoreCustomersPerPage(STORE_CUSTOMERS_PER_PAGE));
    }
    // eslint-disable-next-line
  }, [storeCustomersPerPage]);

  const onNextPage = () => {
    setWhiteSpaceDiv(false);
    if (maxPageSoFar < currentPage + 1) {
      dispatch(
        fetchStoreCustomers(
          currentPage + 1,
          searchKeyword,
          balanceSelector,
          storeCustomersPerPage
        )
      );
      dispatch(
        setStoreCustomersMaxPageSoFar(
          maxPageSoFar < currentPage + 1 ? currentPage + 1 : maxPageSoFar
        )
      );
      dispatch(setStoreCustomersCurrentPage(currentPage + 1));
    } else {
      dispatch(setStoreCustomersCurrentPage(currentPage + 1));
    }
  };

  const onPrevPage = () => {
    dispatch(setStoreCustomersCurrentPage(currentPage - 1));
  };

  const onSearch = (keyword) => {
    setWhiteSpaceDiv(true);
    if (keyword) {
      dispatch(setStoreCustomersMaxPageSoFar(0));
      dispatch(setStoreCustomersCurrentPage(0));
      dispatch(setStoreCustomerBalanceSelector("All"));
      dispatch(fetchStoreCustomers(0, keyword, "All", storeCustomersPerPage));
    }
  };

  const handleChangeBalanceSelector = (balance) => {
    setWhiteSpaceDiv(true);
    dispatch(setStoreCustomersMaxPageSoFar(0));
    dispatch(setStoreCustomersCurrentPage(0));
    dispatch(
      fetchStoreCustomers(0, searchKeyword, balance, storeCustomersPerPage)
    );
    dispatch(setStoreCustomerBalanceSelector(balance));
  };

  const handleClick = (event, tableitem) => {
    setOpenTxnModal(true);
    setAnchoredCustomer(tableitem);
    if (tableitem.balance_amount < 0) {
      toggleTransactionModalType("RECEIVED");
    } else {
      toggleTransactionModalType("GIVEN");
    }
  };

  const saveRecordCallback = (record) => {
    dispatch(setCustomerBalance(record));
  };

  if (successToast) {
    setToast(true);
    dispatch(setSuccessToast(false));
  }

  if (storeCustomerState.progress && storeCustomers.length === 0) {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} className={layoutLoaderHeader}>
            <div className={layoutContainer}>
              <HeaderBreadcrumbs list={[{ label: "Customers" }]} />
              <PageLoader />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className={layoutContainer}>
            <div className={layoutSubHeader}>
              <HeaderBreadcrumbs list={[{ label: "Customers" }]} />
              <OutlinedButton
                endIcon={<SettingsIcon stroke="#1641DB" />}
                style={{ width: 185, marginRight: 20 }}
                onClick={() => {
                  if (
                    PLANS[store.plan].weight < PLANS.BUSINESS_APP_PRO.weight
                  ) {
                    setOpenPremiumModal(true);
                    return;
                  }

                  history.push("./customers/wallet-settings");
                }}
              >
                Wallet Settings
              </OutlinedButton>
              <SolidButton
                endIcon={<AddCustomerIcon />}
                onClick={() => {
                  history.push("./customers/add");
                }}
                style={{ width: 185 }}
              >
                Add Customer
              </SolidButton>
            </div>
            {storeCustomers.length === 0 &&
            searchKeyword === "" &&
            balanceSelector === "All" ? (
              <div style={{ position: "relative", width: "100%" }}>
                <div style={{ position: "absolute", right: "13%", top: 30 }}>
                  <ArrowIcon />
                </div>
                <div
                  style={{
                    position: "absolute",
                    right: "32%",
                    top: 95,
                    textAlign: "center",
                  }}
                >
                  <p
                    style={{
                      fontWeight: 600,
                      fontSize: 20,
                      lineHeight: "32px",
                      color: "#1A1A1A",
                    }}
                  >
                    Start adding your customers
                  </p>
                </div>
                <div style={{ position: "absolute", right: "31%", top: 210 }}>
                  <NoCustomersFigma />
                </div>
              </div>
            ) : (
              <div style={{ position: "relative", width: "100%" }}>
                <div className={layoutBodyBox}>
                  <div className={classes.tableHeader}>
                    <div className={classes.search}>
                      <SearchIcon />
                      <input
                        type="text"
                        placeholder="Search by name or phone number or affiliate id"
                        className={classes.searchBox}
                        value={inputKeyword}
                        onChange={(e) => {
                          dispatch(
                            setStoreCustomersInputKeyword(e.target.value)
                          );
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            if (inputKeyword.trim() !== "") {
                              dispatch(
                                setStoreCustomersSearchKeyword(inputKeyword)
                              );
                              onSearch(inputKeyword.trim());
                            }
                          }
                          if (e.key === "Escape") {
                            setWhiteSpaceDiv(true);
                            dispatch(setStoreCustomersInputKeyword(""));
                            dispatch(setStoreCustomersSearchKeyword(""));
                            dispatch(setStoreCustomersMaxPageSoFar(0));
                            dispatch(setStoreCustomersCurrentPage(0));
                            dispatch(
                              fetchStoreCustomers(
                                0,
                                null,
                                "All",
                                storeCustomersPerPage
                              )
                            );
                          }
                        }}
                      />
                      {searchKeyword !== "" && (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setWhiteSpaceDiv(true);
                            dispatch(setStoreCustomersSearchKeyword(""));
                            dispatch(setStoreCustomersInputKeyword(""));
                            dispatch(setStoreCustomersMaxPageSoFar(0));
                            dispatch(setStoreCustomersCurrentPage(0));
                            dispatch(
                              fetchStoreCustomers(
                                0,
                                null,
                                "All",
                                storeCustomersPerPage
                              )
                            );
                          }}
                        >
                          <CloseIcon fontSize="small" />
                        </span>
                      )}
                    </div>
                    <div>
                      <SubtitleSelect
                        optionList={[
                          { value: "All", title: "All", displayValue: "All" },
                          {
                            value: "advance",
                            title: "Advance",
                            displayValue: "Advance",
                          },
                          {
                            value: "due",
                            title: "Due",
                            displayValue: "Due",
                          },
                        ]}
                        value={balanceSelector}
                        onChange={(value) => {
                          handleChangeBalanceSelector(value);
                        }}
                        width="137px"
                      />
                    </div>
                    <div className={classes.tableHeaderRight}>
                      {/* EXPORT DATA BUTTON */}
                      {/* <Button
                        variant="contained"
                        endIcon={<ExportDataIcon />}
                        className={classes.button}
                        classes={{
                          label: classes.buttonlabel,
                          disabled: classes.disabledButton,
                          endIcon: classes.endIcon,
                        }}
                        // disabled={selectedItems.length > 0 ? false : true}
                        // onClick={() => setselectedItems([])}
                        style={{outline: "none"}}
                      >
                        Export Data
                      </Button> */}
                    </div>
                  </div>
                  <TableContainer>
                    <Table>
                      <TableHead className={classes.tablehead}>
                        <TableRow className={classes.headrow}>
                          <TableCell style={{ width: "16%" }}>Name</TableCell>
                          <TableCell style={{ width: "12%" }}>
                            Phone Number
                          </TableCell>
                          <TableCell style={{ width: "12%" }}>Type</TableCell>

                          <TableCell style={{ width: "10%", textAlign: "right" }}>
                            Wallet Points
                          </TableCell>
                          <TableCell style={{ width: "10%", textAlign: "right" }}>
                            Advance
                          </TableCell>
                          <TableCell style={{ width: "12%", textAlign: "right" }}>Due</TableCell>
                          <TableCell style={{ width: "14%", textAlign: "center" }}>UTM Id</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes.tablebody}>
                        {storeCustomers
                          .slice(startIndex, endIndex)
                          .map((tableitem, index) => (
                            <TableRow
                              key={tableitem.id}
                              style={{
                                borderBottom:
                                  (index + 1) % storeCustomersPerPage === 0
                                    ? "none"
                                    : "auto",
                              }}
                            >
                              <TableCell>
                                <span
                                  onClick={() => {
                                    history.push(
                                      `./customers/${tableitem.id}`
                                    );
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    color: "#1641DB",
                                  }}
                                >
                                  {tableitem.name
                                    ? tableitem.name
                                    : tableitem.mobile}
                                </span>
                              </TableCell>
                              <TableCell>
                                {tableitem.mobile ? tableitem.mobile : "--"}
                              </TableCell>
                              <TableCell
                                style={{ textTransform: "capitalize" }}
                              >
                                {tableitem.type
                                  ? tableitem?.type.toLowerCase()
                                  : "Consumer"}
                              </TableCell>
                              <TableCell style={{textAlign: "right"}}>
                                {tableitem.wallet_points ?? 0}
                              </TableCell>
                              <TableCell style={{textAlign: "right"}}>
                                {tableitem.balance_amount > 0 ? (
                                  <span style={{ color: "#16AC67" }}>
                                    {new Intl.NumberFormat("en-IN", {
                                      style: "currency",
                                      currency: "INR",
                                    }).format(
                                      Math.abs(tableitem.balance_amount)
                                    )}
                                  </span>
                                ) : (
                                  "--"
                                )}
                              </TableCell>
                              <TableCell style={{textAlign: "right"}}>
                                {tableitem.balance_amount < 0 ? (
                                  <span style={{ color: "#F0042E" }}>
                                    {new Intl.NumberFormat("en-IN", {
                                      style: "currency",
                                      currency: "INR",
                                    }).format(
                                      Math.abs(tableitem.balance_amount)
                                    )}
                                  </span>
                                ) : (
                                  "--"
                                )}
                              </TableCell>
                              <TableCell style={{textAlign: "center"}}>
                                {tableitem.utm_id ?? "--"}
                              </TableCell>
                              <TableCell>
                                <div className={rowFlexJustifyBetween}>
                                  <span
                                    onClick={(event) => {
                                      handleClick(event, tableitem);
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      color: "#1641DB",
                                      fontWeight: 600,
                                    }}
                                  >
                                    {tableitem.balance_amount < 0
                                      ? "Received ?"
                                      : "Given ?"}
                                  </span>
                                  {tableitem.mobile && (
                                    <span style={{ marginRight: 16 }}>
                                      <a
                                        href={
                                          "https://wa.me/91" +
                                          (tableitem.mobile.length > 10
                                            ? tableitem.mobile.substring(
                                                tableitem.mobile.length - 10
                                              )
                                            : tableitem.mobile)
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <WhatsApp
                                          htmlColor="#16AC67"
                                          fontSize="small"
                                        />
                                      </a>
                                    </span>
                                  )}
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>

                    {storeCustomerState.progress && (
                      <Fragment>
                        {!whiteSpaceDiv && (
                          <div
                            style={{
                              height: 69 * storeCustomersPerPage - 4,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          ></div>
                        )}
                        <LinearProgress color="secondary" />
                      </Fragment>
                    )}
                    {storeCustomers.length === 0 && (
                      <div
                        style={{
                          height: 69 * storeCustomersPerPage,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: 18,
                            fontWeight: 600,
                            marginTop: -34,
                          }}
                        >
                          No Customers Found
                        </Typography>
                      </div>
                    )}
                  </TableContainer>
                </div>
                <Paginator
                  changeRecordsPerPage={onChangeCustomersPerPage}
                  possibleRecordsPerPage={[
                    DEFAULT_CUSTOMERS_PER_PAGE,
                    20,
                    50,
                    100,
                  ]}
                  totalCount={storeCustomerState.numberOfElements}
                  recordsPerPage={storeCustomersPerPage}
                  onNext={onNextPage}
                  onPrev={onPrevPage}
                  total={numberOfPages === 0 ? 1 : numberOfPages}
                  currentPage={currentPage}
                />
              </div>
            )}
          </div>
        </Grid>
      </Grid>
      <Toast
        open={toast}
        close={() => setToast(false)}
        message={storeCustomerState.message}
      />
      {anchoredCustomer && openTxnModal && (
        <StoreTransactionModal
          name={anchoredCustomer.name}
          mobile={anchoredCustomer.mobile}
          balance={Math.abs(anchoredCustomer.balance_amount)}
          transactionModalType={transactionModalType}
          onClose={() => {
            setOpenTxnModal(false);
          }}
          customerId={anchoredCustomer.id}
          subText={
            anchoredCustomer.balance_amount > 0 ? "You Give" : "You Recieve"
          }
          open={openTxnModal}
          saveRecordCallback={saveRecordCallback}
        />
      )}
      <PaidPopUp
        open={openPremiumModal}
        close={() => setOpenPremiumModal(false)}
        plan={BUSINESS_APP_PRO}
        subtxt="Start offering Reward Points to your Loyal Customers and Grow Business"
      />
    </Fragment>
  );
};
export default Customers;
