import axios from "axios";
import { getStoreIdFromUrl } from "../../../utils/storeUtil";
import { getDateRange } from "../../../utils/utils";
import { MONTHS } from "../../../utils/dateUtils";

export const fetchTransactions = async (sort, period, page, size) => {
  const storeId = getStoreIdFromUrl();
  let accessToken = localStorage.getItem("access_token");
  let res = getDateRange(period);
  let aoe = res.aoe;
  let boe = res.boe;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  let url = `${process.env.REACT_APP_API}/api/v1/business/store/${storeId}/walletx-transaction?created-aoe=${aoe}&created-boe=${boe}`;

  if (sort !== "All Transactions") {
    url += `&tx-type=${sort}`;
  }

  url += `&page=${page}&size=${size}&sort=createdAt,desc`;

  try {
    const response = await axios.get(url, config);
    return response.data;
  } catch (error) {
    console.error("Error fetching data: ", error);
    throw new Error(
      `Failed to fetch transactions: ${error.message || "Unknown error"}`
    );
  }
};

export const rechargeRequest = async (amount, storeID) => {
  const storeId = storeID || getStoreIdFromUrl();
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/api/v1/business/store/${storeId}/walletx/recharge/request`,
      { amount }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data: ", error);
    throw new Error(
      `Failed to initiate recharge: ${error.message || "Unknown error"}`
    );
  }
};

export const postRechargeRequest = async (request, storeID) => {
  const storeId = storeID || getStoreIdFromUrl();

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/api/v1/business/store/${storeId}/walletx/recharge/complete`,
      request
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data: ", error);
    throw new Error(
      `Failed to initiate recharge: ${error.message || "Unknown error"}`
    );
  }
};

export const getRechargeInfo = async (storeId, requestId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/api/v1/business/store/${storeId}/walletx/recharge/request/${requestId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data: ", error);
    throw new Error(
      `Failed to fetch recharge info: ${error.message || "Unknown error"}`
    );
  }
};

export const fetchStoreDetails = async (storeSlug) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/api/v1/web/store/${storeSlug}`
    );
    return response.data.payload;
  } catch (error) {
    return { error: "Failed to fetch store details" };
  }
};

export const periodOptions = [
  "Today",
  "Yesterday",
  "This Week",
  "This Month",
  "Last Week",
  "Last Month",
];

export const sortOptions = ["All Transactions", "Credit", "Debit"];

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = MONTHS[date.getMonth()];
  const year = date.getFullYear().toString().slice(2);
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHour = (((hours + 11) % 12) + 1).toString().padStart(2, "0");
  return `${day} ${month} ${year}, ${formattedHour}:${minutes} ${ampm}`;
};

export function formatToFourDigits(number) {
  let numberStr = number.toString();
  while (numberStr.length < 4) {
    numberStr = "0" + numberStr;
  }
  return numberStr;
}

export function capitalizeString(inputString) {
  if (inputString && typeof inputString === "string") {
    return (
      inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase()
    );
  }
  return "";
}

export function formatIsoString(isoString) {
  const date = new Date(isoString);
  const dateOptions = { day: "numeric", month: "long", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-GB", dateOptions);
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  const formattedTime = `${hours}:${minutes}:${seconds}`;

  return {
    date: formattedDate,
    time: formattedTime,
  };
}

const MODE_APP_WEBVIEW = "appwebview";

export const razorPayWallet = async (
  rechargeReq,
  onPaymentSuccess,
  setLoading,
  mode
) => {
  const options = {
    key: `${process.env.REACT_APP_RAZORPAY_API_KEY}`,
    name: "Shoopy",
    description: "Wallet Recharge",
    image: "https://logo.clearbit.com/shoopy.in",
    order_id: rechargeReq.gw_order_id,
    amount: rechargeReq.amount,
    notes: {
      order_id: rechargeReq.order_id,
      store_id: rechargeReq.store_id,
      recharge: "true",
      recharge_request_id: rechargeReq.recharge_request_id,
    },
    prefill: { contact: rechargeReq.mobile, email: rechargeReq.email },
    handler: async (response) => {
      const paymentData = {
        id: response.razorpay_payment_id,
        mode: "ONLINE",
        gateway: "razorpay",
        order_id: response.razorpay_order_id,
        signature: response.razorpay_signature,
      };

      onPaymentSuccess(rechargeReq.recharge_request_id, paymentData);
      if (mode) {
        if (mode === MODE_APP_WEBVIEW) {
          window.flutter_inappwebview.callHandler("Response", "success");
        } else {
          Response.postMessage("success");
        }
      }
    },
    modal: {
      ondismiss: () => {
        setLoading(false);
        if (mode) {
          if (mode === MODE_APP_WEBVIEW) {
            window.flutter_inappwebview.callHandler("Response", "success");
          } else {
            Response.postMessage("success");
          }
        }
      },
      escape: true,
    },
    theme: {
      color: "#1641DB",
    },
  };
  const rzp1 = new window.Razorpay(options);
  rzp1.open();
};
