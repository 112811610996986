import { makeStyles } from "@material-ui/core";
import React from "react";
import { keyframes } from "typestyle";
const useStyles = makeStyles(() => ({
  container: {
    position: "absolute",
    zIndex: 2,
    left: (props) => (props.left ? props.left : "50%"),
    transform: "translateX(-50%)",
    minWidth: 280,
    top: (props) => props.top,
  },
  body: {
    background: "#000000",
    color: "#FFFFFF",
    lineHeight: 1.5,
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 3px 6px #00000029",
  },
  txt: {
    color: "#FFFFFF",
    lineHeight: 1.5,
    fontSize: "12px",
    padding: "14px 12px",
  },
  svg: {
    transform: "rotate(180deg) scale(1.5)",
    position: "relative",
    left: (props) => (props.svgLeft ? props.svgLeft : "50%"),
    right: (props) => props.svgRight,
    top: 2,
  },
  bounceAnimation: {
    "-webkit-animation-name": bounce,
    animationName: bounce,
    "-webkit-transform-origin": "center bottom",
    transformOrigin: "center bottom",
    animationDuration: "1s",
    animationDelay: "0.5s",
  },
}));

const FixedToast = ({ left, top, svgLeft, svgRight, text, onAnimationEnd }) => {
  const classes = useStyles(left, top, svgLeft, svgRight);
  return (
    <div
      onAnimationEnd={() => {
        onAnimationEnd &&
          setTimeout(() => {
            onAnimationEnd();
          }, 1800);
      }}
      className={`${classes.container} ${classes.bounceAnimation}`}
    >
      <svg
        className={classes.svg}
        width="13"
        height="9"
        viewBox="0 0 13 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.38858 7.28106C7.65114 8.56356 5.82475 8.62926 4.99707 7.40306L0 0H12.5752L8.38858 7.28106Z"
          fill="#000000"
        />
      </svg>

      <div className={classes.body}>
        <div className={classes.txt}>{text}</div>
      </div>
    </div>
  );
};

export default FixedToast;

const bounce = keyframes({
  "from, 20%, 53%, to": {
    "-webkit-animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
    animationTimingFunction: "cubic-bezier(0.215, 0.61, 0.355, 1)",
    "-webkit-transform": "translate3d(-50%, 0, 0)",
    transform: "translate3d(-50%, 0, 0)",
  },
  "40%, 43%": {
    "-webkit-animation-timing-function":
      "cubic-bezier(0.755, 0.05, 0.855, 0.06)",
    animationTimingFunction: "cubic-bezier(0.755, 0.05, 0.855, 0.06)",
    "-webkit-transform": "translate3d(-50%, 30px, 0) scaleY(1.1)",
    transform: "translate3d(-50%, 30px, 0) scaleY(1.1)",
  },
  "70%": {
    "-webkit-animation-timing-function":
      "cubic-bezier(0.755, 0.05, 0.855, 0.06)",
    animationTimingFunction: "cubic-bezier(0.755, 0.05, 0.855, 0.06)",
    "-webkit-transform": "translate3d(-50%, 15px, 0) scaleY(1.05)",
    transform: "translate3d(-50%, 15px, 0) scaleY(1.05)",
  },
  "80%": {
    "-webkit-transition-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
    transitionTimingFunction: "cubic-bezier(0.215, 0.61, 0.355, 1)",
    "-webkit-transform": "translate3d(-50%, 0, 0) scaleY(0.95)",
    transform: "translate3d(-50%, 0, 0) scaleY(0.95)",
  },
  "90%": {
    "-webkit-transform": "translate3d(-50%, 4px, 0) scaleY(1.02)",
    transform: "translate3d(-50%, 4px, 0) scaleY(1.02)",
  },
});
