import { CLEAR_DATA, CLEAR_COMPLETE } from "../../commom/commonTypes";
import {
  ADD_CONTRACT,
  FETCH_CONTRACTS,
  CONTRACTS_PROGRESS,
  SET_SUCCESS_TOAST,
  EDIT_CONTRACT,
  DELETE_CONTRACT,
  CLEAR_CONTRACT,
  SET_CONTRACTS_ERROR,
  SET_CONTRACTS_LOADER,
  CLEAR_COMPLETE_CONTRACTS,
  SET_CONTRACTS_PER_PAGE,
  SET_CONTRACTS_CURRENT_PAGE,
  SET_CONTRACTS_MAX_PAGE_SO_FAR,
  SET_CONTRACTS_INPUT_KEYWORD,
  SET_CONTRACTS_SEARCH_KEYWORD,
  SET_CONTRACTS_STATE_FILTER,
} from "./contractTypes";

const initialState = {
  contracts: [],
  numberOfPages: 0,
  numberOfElements: 0,
  contract: null,
  progress: true,
  loader: false,
  error: null,
  contractsPerPage: null,
  currentPage: 0,
  maxPageSoFar: 0,
  searchKeyword: "",
  inputKeyword: "",
  successToast: false,
  message: "",
  state: null,
  pageDirty: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CONTRACTS_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      };
    case FETCH_CONTRACTS:
      let allContracts = state.contracts;
      let page = action.payload.pageable.pageNumber;
      if (page > 0 && state.contracts.length > action.contractsPerPage * page) {
        allContracts = state.contracts.slice(0, action.contractsPerPage * page);
      }
      return {
        ...state,
        pageDirty: false,
        contracts:
          action.payload.pageable.pageNumber > 0
            ? [...allContracts, ...action.payload.content]
            : action.payload.content,
        numberOfPages: action.payload.totalPages,
        numberOfElements: action.payload.totalElements,
        progress: false,
      };
    case ADD_CONTRACT:
      let pageCnt = state.numberOfPages;
      let localPageContracts = state.contracts;
      let count = state.contracts.length;
      if (count + 1 > state.contractsPerPage) {
        let fullPages = Math.floor((count + 1) / state.contractsPerPage);
        localPageContracts = state.contracts.slice(
          0,
          fullPages * state.contractsPerPage - 1
        );
      }
      let availablePages = Math.floor(
        state.contracts.length / state.contractsPerPage
      );
      return {
        ...state,
        pageDirty: true,
        contract: action.payload,
        loader: false,
        contracts: [action.payload, ...localPageContracts],
        numberOfPages:
          state.numberOfElements === pageCnt * state.contractsPerPage
            ? pageCnt + 1
            : pageCnt,
        numberOfElements: state.numberOfElements + 1,
        currentPage: 0,
        maxPageSoFar: availablePages > 1 ? availablePages - 1 : 0,
        successToast: true,
        message: "Customer Added Successfully",
      };

    case EDIT_CONTRACT:
      let modContracts = state.contracts.map((contract) => {
        if (contract.id === action.payload.id) {
          return action.payload;
        }
        return contract;
      });
      return {
        ...state,
        contract: action.payload,
        loader: false,
        contracts: modContracts,
      };
    case DELETE_CONTRACT:
      let remContracts = state.contracts.filter(
        (contract) => contract.id !== action.payload
      );
      return {
        ...state,
        loader: false,
        contracts: remContracts,
        numberOfPages: Math.ceil(
          (state.numberOfElements - 1) / action.contractsPerPage
        ),
        numberOfElements: state.numberOfElements - 1,
      };

    case CLEAR_CONTRACT:
      return {
        ...state,
        contracts: action.payload,
      };
    case SET_CONTRACTS_ERROR:
      return {
        ...state,
        error: action.payload.error,
        progress: false,
      };
    case SET_CONTRACTS_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case CLEAR_COMPLETE_CONTRACTS:
      return {
        ...state,
        contracts: [],
        numberOfPages: 0,
        numberOfElements: 0,
        contract: null,
        progress: false,
        loader: false,
        error: null,
        pageDirty: false,
        successToast: false,
        message: "",
      };
    case CLEAR_COMPLETE:
    case CLEAR_DATA:
      return {
        ...state,
        contracts: [],
        numberOfPages: 0,
        numberOfElements: 0,
        contract: null,
        progress: false,
        loader: false,
        error: null,
        pageDirty: false,
        contractsPerPage: null,
        searchKeyword: "",
        inputKeyword: "",
        currentPage: 0,
        maxPageSoFar: 0,
        successToast: false,
        message: "",
      };
    case SET_CONTRACTS_PER_PAGE:
      return {
        ...state,
        contractsPerPage: action.payload,
      };
    case SET_CONTRACTS_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SET_CONTRACTS_MAX_PAGE_SO_FAR:
      return {
        ...state,
        maxPageSoFar: action.payload,
      };
    case SET_CONTRACTS_INPUT_KEYWORD:
      return {
        ...state,
        inputKeyword: action.payload,
      };
    case SET_CONTRACTS_SEARCH_KEYWORD:
      return {
        ...state,
        searchKeyword: action.payload,
      };
    case SET_CONTRACTS_STATE_FILTER:
      return {
        ...state,
        state: action.payload,
      };
    case SET_SUCCESS_TOAST:
      return {
        ...state,
        successToast: action.payload,
        meassage: "",
      };
    default:
      return state;
  }
};

export default reducer;
