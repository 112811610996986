import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./editor.css";

const Editor = ({ editorText, setEditorText, height }) => {
  return (
    <CKEditor
      editor={ClassicEditor}
      config={{
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "|",
          "bulletedList",
          "numberedList",
          "|",
          "undo",
          "redo",
        ],
      }}
      data={editorText}
      onChange={(event, editor) => {
        const data = editor.getData();
        setEditorText(data);
      }}
      onReady={(editor) => {
        editor.editing.view.change((writer) => {
          writer.setStyle(
            "height",
            height,
            editor.editing.view.document.getRoot()
          );
        });
      }}
    />
  );
};

export default Editor;
