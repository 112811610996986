import {
  SET_NOTIFICATION_SETTINGS,
  SET_NOTIFICATION_SETTINGS_ERROR,
  SET_NOTIFICATION_SETTINGS_LOADED,
  SET_NOTIFICATION_SETTINGS_LOADING,
  SET_NOTIFICATION_SETTING,
  REVERT_NOTIFICATION_SETTING,
} from "./settingsTypes";

const initialState = {
  notificationSettings: {
    data: {},
    loading: false,
    loaded: false,
    error: null,
  },
};

// Utility function to convert array to map
const arrayToMap = (array, keyField) => {
  return array.reduce((map, item) => {
    map[item[keyField]] = item.value === "true";
    return map;
  }, {});
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATION_SETTINGS_LOADING:
      return {
        ...state,
        notificationSettings: {
          ...state.notificationSettings,
          loading: true,
          loaded: false,
          error: null,
        },
      };
    case SET_NOTIFICATION_SETTINGS_LOADED:
      return {
        ...state,
        notificationSettings: {
          ...state.notificationSettings,
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case SET_NOTIFICATION_SETTINGS:
      return {
        ...state,
        notificationSettings: {
          ...state.notificationSettings,
          data: arrayToMap(action.payload, "name"),
        },
      };
    case SET_NOTIFICATION_SETTING:
      return {
        ...state,
        notificationSettings: {
          ...state.notificationSettings,
          data: {
            ...state.notificationSettings.data,
            [action.payload.name]: action.payload.value,
          },
        },
      };

    case REVERT_NOTIFICATION_SETTING:
      return {
        ...state,
        notificationSettings: {
          ...state.notificationSettings,
          data: {
            ...state.notificationSettings.data,
            [action.payload.name]: action.payload.previousValue,
          },
        },
      };

    case SET_NOTIFICATION_SETTINGS_ERROR:
      return {
        ...state,
        notificationSettings: {
          ...state.notificationSettings,
          loading: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};

export default reducer;
