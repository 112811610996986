import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const PermissionIcon = () => {
  return (
    <SvgIcon viewBox="0 0 15 17" style={{ width: "15px", height: "17px" }}>
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.252 0.0747393C7.32753 0.0258414 7.41301 0.00012207 7.5 0.00012207C7.58699 0.00012207 7.67247 0.0258414 7.748 0.0747393L14.748 4.60807C14.8245 4.65761 14.8881 4.7292 14.9324 4.81558C14.9766 4.90196 15 5.00006 15 5.09994V5.91594C15 8.41604 14.2814 10.8481 12.9528 12.8439C11.6243 14.8398 9.75818 16.2908 7.637 16.9773C7.54745 17.0062 7.45255 17.0062 7.363 16.9773C5.24198 16.2905 3.37607 14.8394 2.04757 12.8436C0.719076 10.8478 0.000300345 8.41595 0 5.91594L0 5.09994C2.8427e-05 5.00006 0.0233513 4.90196 0.0676055 4.81558C0.11186 4.7292 0.175473 4.65761 0.252 4.60807L7.252 0.0747393ZM7.072 12.1391L11.39 6.02021L10.61 5.31301L6.928 10.5275L4.32 8.06474L3.68 8.93514L7.072 12.1391Z"
          fill="#16AC67"
        />
      </svg>
    </SvgIcon>
  );
};
export default PermissionIcon;
