import React, { Fragment } from "react";
import Multiple from "./components/Multiple";
import Single from "./components/Single";

const ImageTest = () => {
  return (
    <Fragment>
      <h1>Multiple</h1>
      <div style={{ marginBottom: 24 }}>
        <p>Multiple 600px Max Size</p>
        <Multiple maxSize={600} />
      </div>
      <div style={{ marginBottom: 24 }}>
        <p>Multiple 1024px Max Size</p>
        <Multiple maxSize={1024} />
      </div>
      <div style={{ marginBottom: 24 }}>
        <p>Multiple 1400px Max Size</p>
        <Multiple maxSize={1400} />
      </div>
      <div style={{ marginBottom: 24 }}>
        <p>Multiple 1920px Max Size</p>
        <Multiple maxSize={1920} />
      </div>
      <div style={{ marginBottom: 24 }}>
        <p>Multiple 3000px Max Size</p>
        <Multiple maxSize={3000} />
      </div>
      <div style={{ marginBottom: 24 }}>
        <p>Multiple 6000px Max Size</p>
        <Multiple maxSize={6000} />
      </div>
      <h1>Single Image</h1>
      <div style={{ marginBottom: 24 }}>
        <p>Single 600px Max Size</p>
        <Single maxSize={600} />
      </div>
      <div style={{ marginBottom: 24 }}>
        <p>Single 1024px Max Size</p>
        <Single maxSize={1024} />
      </div>
      <div style={{ marginBottom: 24 }}>
        <p>Single 1400px Max Size</p>
        <Single maxSize={1400} />
      </div>
      <div style={{ marginBottom: 24 }}>
        <p>Single 1920px Max Size</p>
        <Single maxSize={1920} />
      </div>
      <div style={{ marginBottom: 24 }}>
        <p>Single 3000px Max Size</p>
        <Single maxSize={3000} />
      </div>
      <div style={{ marginBottom: 24 }}>
        <p>Single 6000px Max Size</p>
        <Single maxSize={6000} />
      </div>
    </Fragment>
  );
};

export default ImageTest;
