import React, { useState } from "react";
import { Button, Typography, makeStyles, Grid } from "@material-ui/core";
import AddCustomerModal from "./AddCustomerModal";
const useStyles = makeStyles(() => ({
  heading: {
    fontWeight: 600,
    color: "#1A1A1A",
    paddingBottom: 6,
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },

  textBtn: {
    textTransform: "none",
    textDecoration: "underline",
    padding: "0px",
    fontWeight: 400,
    fontSize: "14px",
    marginRight: "-15px",
    "&:hover": {
      backgroundColor: "#fff",
      textDecoration: "underline",
    },
  },
  addressName: {
    fontWeight: "normal",
    fontSize: 14,
    color: "#1A1A1A",
    paddingTop: 0,
    paddingBottom: 3,
  },
  address: {
    fontWeight: "normal",
    fontSize: 12,
    color: "#1A1A1A",
    paddingTop: 0,
  },
}));
const CustomerAddress = ({
  customer,
  onCustomer,
  billingAddress,
  isSameAddress,
  shippingAddress,
}) => {
  const classes = useStyles();

  const [customerModal, setCustomerModal] = useState(false);

  const closeModal = () => {
    setCustomerModal(false);
  };

  const addCustomer = (value) => {
    onCustomer(value);
    setCustomerModal(false);
  };

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={6}
          style={{ borderRight: "1px solid #E5E5E5", paddingRight: 20 }}
        >
          <div className={classes.row}>
            <Typography className={classes.heading}>Billing Address</Typography>
            <Button
              variant="text"
              color="secondary"
              className={classes.textBtn}
              style={{}}
              onClick={() => setCustomerModal(true)}
            >
              {billingAddress && customer ? "Edit" : "Add"}
            </Button>
          </div>
          {customer && billingAddress ? (
            <div>
              <Typography className={classes.addressName}>
                {billingAddress?.customer_name &&
                  `${billingAddress.customer_name}, `}
                {billingAddress?.mobile && `${billingAddress.mobile}`}
              </Typography>
              <Typography
                className={classes.address}
                style={{ color: "#666666" }}
              >
                {billingAddress?.street1 && `${billingAddress.street1}, `}
                {billingAddress?.street2 && `${billingAddress.street2}`}
              </Typography>
              <Typography
                className={classes.address}
                style={{ color: "#666666" }}
              >
                {billingAddress?.city && `${billingAddress.city}, `}
                {billingAddress?.state && `${billingAddress.state} - `}
                {billingAddress?.pincode && `${billingAddress.pincode}`}
              </Typography>
            </div>
          ) : (
            <Typography className={classes.addressName}>
              Billing Address not added.
            </Typography>
          )}
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: 20 }}>
          <div className={classes.row}>
            <Typography className={classes.heading}>
              Shipping Address
            </Typography>
            <Button
              variant="text"
              color="secondary"
              className={classes.textBtn}
              onClick={() => setCustomerModal(true)}
            >
              {shippingAddress ? "Edit" : "Add"}
            </Button>
          </div>
          {customer && isSameAddress && (
            <Typography className={classes.addressName}>
              Same as Billing Address
            </Typography>
          )}
          {customer && !isSameAddress && shippingAddress && (
            <div>
              <Typography className={classes.addressName}>
                {shippingAddress.customer_name &&
                  `${shippingAddress.customer_name}, `}
                {shippingAddress.mobile && `${shippingAddress.mobile}`}
              </Typography>
              <Typography
                className={classes.address}
                style={{ color: "#666666" }}
              >
                {shippingAddress.street1 && `${shippingAddress.street1}, `}
                {shippingAddress.street2 && `${shippingAddress.street2}`}
              </Typography>
              <Typography
                className={classes.address}
                style={{ color: "#666666" }}
              >
                {shippingAddress.city && `${shippingAddress.city}, `}
                {shippingAddress.state && `${shippingAddress.state} - `}
                {shippingAddress.pincode && `${shippingAddress.pincode}`}
              </Typography>
            </div>
          )}
          {customer && !isSameAddress && !shippingAddress && (
            <Typography className={classes.addressName}>
              Shipping Address not added.
            </Typography>
          )}
        </Grid>
      </Grid>
      {customerModal && customer && (
        <AddCustomerModal
          customerModal={customerModal}
          closeModal={closeModal}
          record={customer}
          addCustomer={addCustomer}
        />
      )}
    </>
  );
};

export default CustomerAddress;
