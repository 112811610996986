import React from "react";
import { rowFlexJustifyBetween } from "../../../../styles/common";
export default function SelectedAreaHead({ title, updateAction }) {
  return (
    <div className={rowFlexJustifyBetween}>
      <p
        style={{
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "20px",
          marginBottom: "12px",
          color: "#1A1A1A",
        }}
      >
        {title}
      </p>
      <p
        style={{
          fontSize: "12px",
          lineHeight: "16px",
          color: "#1641DB",
          textDecoration: "underline",
          cursor: "pointer",
          marginBottom: "12px",
        }}
        onClick={() => updateAction(true)}
      >
        Update
      </p>
    </div>
  );
}
