import React from "react";
import { Modal, Typography } from "@material-ui/core";
import OutlinedButton from "../../../components/buttons/OutlinedButton";
import { rowFlexCenterAll } from "../../../styles/common";

const PaymentStatusModal = ({ selectedPlan, onClose }) => {

  const handleClose = () => {
    window.location.reload();
  };

  return (
    <Modal open={true} className={rowFlexCenterAll}>
      <div
        style={{
          padding: 65,
          borderRadius: 12,
          position: "absolute",
          background: "#ffffff",
          width: "60%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          minWidth: 700,
          maxWidth: 900,
        }}
      >
        <img
          src={require("../../../images/confetti.png")}
          alt="payment status"
          style={{ height: "188px", width: "188px", objectFit: "scale-down" }}
        />
        <Typography
          style={{
            marginTop: "50px",
            fontSize: "24px",
            lineHeight: "28px",
            fontWeight: 600,
          }}
        >
          Congratulations! Your order is complete
        </Typography>
        <Typography
          style={{
            marginTop: "12px",
            fontSize: "12px",
            lineHeight: "16px",
            fontWeight: 600,
            color: "#666",
          }}
        >
          You have successfully bought {selectedPlan.title} plan.
        </Typography>
        <OutlinedButton
          style={{ marginTop: "50px", width: "60%" }}
          onClick={handleClose}
        >
          Close
        </OutlinedButton>
      </div>
    </Modal>
  );
};

export default PaymentStatusModal;
