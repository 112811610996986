import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  taxbtn: {
    fontSize: 12,
    textTransform: "capitalize",
    borderRadius: 12,
    width: "64px",
    padding: 0,
    border: "1px solid #1641DB",
  },
}));

const TaxSwitchButtons = ({ product, handleTaxIncluded, taxType }) => {
  const classes = useStyles();
  const taxIncluded = product[taxType];

  return (
    <>
      <Button
        className={classes.taxbtn}
        variant="contained"
        style={{
          color: taxIncluded ? "white" : "#1641DB",
          backgroundColor: taxIncluded ? "#1641DB" : "white",
          outline: "none",
        }}
        onClick={() => handleTaxIncluded(taxType, true)}
        disableElevation
      >
        Tax Incl.
      </Button>
      <Button
        className={classes.taxbtn}
        variant="outlined"
        style={{
          marginLeft: 8,
          color: !taxIncluded ? "white" : "#1641DB",
          backgroundColor: !taxIncluded ? "#1641DB" : "white",
          outline: "none",
        }}
        onClick={() => handleTaxIncluded(taxType, false)}
        disableElevation
      >
        Tax Excl.
      </Button>
    </>
  );
};

export default TaxSwitchButtons;
