import { CLEAR_COMPLETE, CLEAR_DATA } from "../commom/commonTypes";
import {
  FETCH_STORE,
  UPDATE_STORE,
  FETCH_PLANS,
  SET_STORE_LOADER,
  UPDATE_SETTING,
  STORE_PLAN_RENEW_INFO,
  STORE_PINCODE_INFO,
} from "./storeTypes";

const initialState = {
  store: {},
  fetched: false,
  error: "",
  loader: false,
  storePlanRenewInfo: {},
  plans: [], // Add this line to handle plans data
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STORE:
      return {
        ...state,
        store: action.payload,
        fetched: true,
      };
    case UPDATE_STORE:
      action.payload.store_setting = state.store.store_setting;
      return {
        ...state,
        store: action.payload,
      };
    case UPDATE_SETTING:
      return {
        ...state,
        store: { ...state.store, store_setting: action.payload },
      };
    case STORE_PLAN_RENEW_INFO:
      return {
        ...state,
        storePlanRenewInfo: action.payload,
      };
    case FETCH_PLANS:
      return {
        ...state,
        plans: action.payload,
      };
    case SET_STORE_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case STORE_PINCODE_INFO:
      return {
        ...state,
        addressData: action.payload,
      };

    case CLEAR_COMPLETE:
    case CLEAR_DATA:
      return {
        ...state,
        store: {},
        plans: [],
        fetched: false,
        error: "",
        loader: false,
      };
    default:
      return state;
  }
};
export default reducer;
