import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CloseIcon = ({
  viewBox = "0 0 20 20",
  height = 20,
  width = 20,
  stroke = "#1A1A1A",
}) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: width, height: height }}>
      <svg xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2 18L17.9999 2.00007"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 2L17.9999 17.9999"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default CloseIcon;
