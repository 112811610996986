import React from "react";
import { Route, Switch } from "react-router-dom/cjs/react-router-dom";
import NavigationSidebar from "./NavigationSidebar";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import { makeStyles } from "@material-ui/core";
import SettingSlug from "./SettingSlug";

const useStyles = makeStyles(() => ({
  layoutContainer: {
    display: "flex",
  },
  content: {
    marginLeft: 328,
    marginTop: 54,
    width: "100%",
  },
}));

export default function SettingsLayout() {
  const classes = useStyles();
  let { path } = useRouteMatch();
  return (
    <div className={classes.layoutContainer}>
      <NavigationSidebar />
      <div className={classes.content}>
        <Switch>
          <Route path={`${path}/:settingSlug`} component={SettingSlug} />
        </Switch>
      </div>
    </div>
  );
}
