import React, {
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import Dropzone from "react-dropzone";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import AddImage from "../icons/AddImage";
import {
  Button,
  CircularProgress,
  Modal,
  Typography,
  makeStyles,
} from "@material-ui/core";
import CombinedButtons from "./buttons/CombinedButtons";
import {
  rowFlexJustifyBetween,
  rowFlexCenterAll,
  rowFlexOnlyJustifyBetweenCenter,
} from "../styles/common";
import { generateUniqueId } from "../utils/generateUniqueId";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { RemoveCircleIcon } from "../icons";
import { asyncForEach } from "../utils/asyncForEach";
import Toast from "./Layout/Toast";
import { Alert } from "@material-ui/lab";

const useStyle = makeStyles(() => ({
  image: {
    width: 60,
    height: 60,
    position: "relative",
    marginRight: 24,
    borderRadius: 6,
    "&:hover": {
      "& .close": {
        display: "flex",
        opacity: 1,
      },
    },
  },
  close: {
    display: "none",
    opacity: 0,
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    position: "absolute",
    top: -11,
    right: -11,
  },
  editImage: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: "24px",
    color: "#1A1A1A",
  },
  headerRow: {
    padding: "18px 24px",
    background: "#ffffff",
    borderRadius: 12,
    minWidth: 600,
    maxWidth: 900,
    margin: 24,
    objectFit: "contain",
    position: "relative",
  },
  cropImgCtn: {
    padding: "16px 0",
  },
  devider: {
    height: 2,
    width: "100%",
    background: "#E1E1E1",
  },
  cropVarBtns: {
    borderRadius: 100,
    fontSize: 12,
    padding: "4px 12px",
    fontWeight: 600,
  },
  cropImgCol: {
    overflow: "hidden",
    margin: "auto",
    padding: "0 16px",
    display: "flex",
    alignItems: "center",
  },
  cropImgWrapper: {
    marginBottom: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "url(https://i.imgur.com/uU9KiqQ.png)",
    backgroundSize: 6,
    backgroundRepeat: "repeat",
    backgroundPositionX: "x-start",
    backgroundPositionY: "y-start",
  },
  originalImg: {
    objectFit: "contain",
  },
  circleProgress: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#00000050",
  },
  thumbnainCtn: {
    display: "flex",
    width: "100%",
    overflowX: "auto",
    paddingTop: 16,
    paddingBottom: 16,
    justifyContent: "center",
    alignItems: "center",
  },
  thumbnail: {
    width: "100%",
    height: "100%",
    borderRadius: 6,
    objectFit: "contain",
  },
  error: {
    width: "fit-content",
    margin: "auto",
    paddingTop: 2,
    paddingBottom: 2,
    backgroundColor: "transparent",
    color: "red",
  },
  noImage: {
    background: "url(https://i.imgur.com/uU9KiqQ.png)",
    backgroundSize: 6,
    backgroundRepeat: "repeat",
    backgroundPositionX: "x-start",
    backgroundPositionY: "y-start",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  noImagePara: {
    margin: 0,
    padding: "6px 12px",
    background: "#fff",
    borderRadius: 6,
    boxShadow: "0px 0px 8px -3px #00000040",
  },
}));

const variants = [
  {
    title: "Original", // Original is important if passing variants from externally
    id: "original",
    ratio: null,
  },
  {
    title: "1:1",
    id: "1:1",
    ratio: 1 / 1,
  },
  {
    title: "3:4",
    id: "3:4",
    ratio: 3 / 4,
  },
  {
    title: "4:3",
    id: "4:3",
    ratio: 4 / 3,
  },
  {
    title: "16:9",
    id: "16:9",
    ratio: 16 / 9,
  },
  {
    title: "9:16",
    id: "9:16",
    ratio: 9 / 16,
  },
  {
    title: "Custom",
    id: "custom",
    ratio: undefined,
  },
];

const filenameRegex =
  /https:\/\/storage\.googleapis\.com\/(shy-pub|shy-test|shoopy-test)\/([0-9])+\//;

// TODO: Make multiple component
// TODO: get filename from url -> write a util
const ImageHandler = ({
  setCropImg,
  defaultAspect = "original", // Default Crop Option Selected Original
  setFilename,
  filename,
  multiple = false,
  cropImg,
  forceJpeg,
  uploadContainer = (
    <div style={{ width: 60, cursor: "pointer" }}>
      <AddImage />
    </div>
  ),
  defaultVariants,
  maxSize,
  openImageHandler,
  setOpenImageHandler = () => {},
  openImageHandlerIndex = 0,
  openModal,
  setOpenModal,
}) => {
  const classes = useStyle();
  const [loader, setLoader] = useState(false); // Loading while crop saving
  const [choosedFiles, setChoosedFiles] = useState([]); // Array of Choosed Files
  const [selectedImage, setSelectedImage] = useState(null); // Selected Image Id
  const [errorMessage, setErrorMessage] = useState("");
  const imgRef = useRef(null); // Crop Area Image Rendered Ref
  const modalRef = useRef(null); // Modal Ref
  const imageWrapperRef = useRef(null); // Image Wrapper Ref
  const [dimensions, setDimensions] = useState({
    width:
      ((window.innerHeight > 600 ? 420 : window.innerHeight - 300) * 190.48) /
      100,
    height: window.innerHeight > 720 ? 420 : window.innerHeight - 300,
  }); // Crop Area Dimensions [Width, Height
  useLayoutEffect(() => {
    if (typeof window !== "undefined") {
      const height = window.innerHeight > 720 ? 420 : window.innerHeight - 300;
      const width = (height * 190.48) / 100;
      setDimensions({
        width,
        height,
      });
    }
  }, [openModal]);
  // Current Selected File Object
  const currentSelectedFile = choosedFiles.find(
    (file) => file.id === selectedImage
  );
  const cropsVariant = defaultVariants ?? variants; // All Supported Crop Options Object
  const currFilesLength = multiple // Total Locally Choosed and Already Uploaded Files Length - Max 6
    ? selectedImage
      ? choosedFiles.length
      : cropImg.length
    : cropImg
    ? 1
    : 0;
  const disabledCrops =
    currentSelectedFile?.loadingState === "not-yet" ||
    currentSelectedFile?.loadingState === "loading" ||
    !currentSelectedFile;

  function calculateContainerDimensions(naturalWidth, naturalHeight) {
    const maxWidth = dimensions.width; // Maximum width constraint
    const maxHeight = dimensions.height; // Maximum height constraint
    let containerWidth = naturalWidth;
    let containerHeight = naturalHeight;

    // Adjust container width and height if they exceed maxWidth or maxHeight
    if (containerWidth > maxWidth || containerHeight > maxHeight) {
      const widthRatio = maxWidth / containerWidth;
      const heightRatio = maxHeight / containerHeight;
      const scale = Math.min(widthRatio, heightRatio);

      containerWidth *= scale;
      containerHeight *= scale;
    }

    return { width: containerWidth, height: containerHeight };
  }

  useEffect(() => {
    if (openImageHandler === true) {
      if (!imgRef?.current) {
        setOpenModal(true);
        loadExistingImages(true, openImageHandlerIndex);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openImageHandler]);

  // This is to demonstate how to make and center a % aspect crop
  // which is a bit trickier so we use some helper functions.
  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    const maxWidth = dimensions.width; // Hardcoded maximum width
    const maxHeight = dimensions.height; // Hardcoded maximum height
    // Calculate dimensions based on maxWidth and maxHeight constraints
    let cropWidth = mediaWidth;
    let cropHeight = mediaHeight;
    // Adjust width and height if they exceed maxWidth or maxHeight
    if (cropWidth > maxWidth || cropHeight > maxHeight) {
      const widthRatio = maxWidth / cropWidth;
      const heightRatio = maxHeight / cropHeight;
      const scale = Math.min(widthRatio, heightRatio);
      cropWidth *= scale;
      cropHeight *= scale;
    }
    return centerCrop(
      makeAspectCrop(
        {
          unit: "px",
          width: cropWidth,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  const handleCropChange = async (aspectId) => {
    const aspect = cropsVariant.find((e) => e.id === aspectId).ratio;
    handleChoosedFiles(selectedImage, {
      aspect: aspectId,
      ...(aspect && {
        crop: centerAspectCrop(
          currentSelectedFile.ctnDimension.width,
          currentSelectedFile.ctnDimension.height,
          aspect
        ),
      }),
      error: "",
    });
  };

  const handleExportImages = async () => {
    if (currentSelectedFile) {
      const reCheckFileSize = (file) => {
        handleChoosedFiles(file.id, {
          error:
            "Image size is more than 1MB, kindly remove or crop the image.",
        });
        setSelectedImage(file.id);
        setLoader(false);
        throw new Error();
      };
      if (!choosedFiles.some((e) => e.error)) {
        try {
          if (multiple) {
            const files = [];
            await asyncForEach(choosedFiles, async (file) => {
              if (file.aspect !== "original") {
                const url = await getCroppedImg(file);
                const size = await getImageFileSize(url);
                if (size && size / 1024 > 1024) {
                  reCheckFileSize(file);
                } else {
                  files.push({ ...file, url, modified: true });
                }
              } else {
                if (file.url.match(/^data:image/)) {
                  const size = await getImageFileSize(file.url);
                  if (size && size / 1024 > 1024) {
                    reCheckFileSize(file);
                  } else {
                    files.push(file);
                  }
                } else {
                  files.push(file);
                }
              }
            });
            setCropImg([
              ...files.map((file) =>
                !file.modified && file.cropImgUrl ? file.cropImgUrl : file.url
              ),
            ]);
            setFilename([...choosedFiles.map((file) => file.fileName)]);
          } else {
            if (currentSelectedFile.aspect !== "original") {
              const url = await getCroppedImg(currentSelectedFile);
              const size = await getImageFileSize(url);
              if (size && size / 1024 > 1024) {
                reCheckFileSize(currentSelectedFile);
              } else {
                setCropImg(url);
              }
            } else {
              const size = await getImageFileSize(currentSelectedFile.url);
              if (size && size / 1024 > 1024) {
                reCheckFileSize(currentSelectedFile);
              } else {
                setCropImg(
                  currentSelectedFile.cropImgUrl
                    ? currentSelectedFile.cropImgUrl
                    : currentSelectedFile.url
                );
              }
            }
            setFilename(currentSelectedFile.fileName);
          }
          closeModalAndClean();
        } catch (error) {}
      } else {
        const file = choosedFiles.find((e) => e.error);
        setSelectedImage(file.id);
      }
    } else {
      if (multiple) {
        setCropImg([]);
        setFilename([]);
      } else {
        setCropImg("");
        setFilename("");
      }
      closeModalAndClean();
    }
  };

  const handleSelectImage = (file) => {
    if (choosedFiles.length > 1) {
      if (selectedImage === file.id) {
        const currFileIndex = choosedFiles.findIndex((e) => e.id === file.id);
        const newFile =
          choosedFiles[currFileIndex - 1] || choosedFiles[currFileIndex + 1];
        setSelectedImage(newFile.id);
        checkIfImagesHasLoaded(newFile);
      }
      setChoosedFiles((choosedFiles) =>
        choosedFiles.filter((e) => e.id !== file.id)
      );
    } else {
      setChoosedFiles([]);
      setSelectedImage(null);
    }
  };

  async function convertCDNToBase64(cdnSrc) {
    return new Promise((resolve, reject) => {
      const fileType = forceJpeg
        ? "jpeg"
        : cdnSrc.substring(cdnSrc.lastIndexOf(".") + 1);
      const img = new Image();
      img.crossOrigin = "anonymous"; // Handle potential CORS issues
      img.src = cdnSrc;
      img.onload = function () {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = this.width;
        canvas.height = this.height;
        ctx.drawImage(this, 0, 0);
        const dataURL = canvas.toDataURL(`image/${fileType}`); // Or any other desired format
        resolve(dataURL);
      };
      img.onerror = function () {
        reject(new Error("Failed to load image"));
      };
    });
  }

  const checkIfImagesHasLoaded = async (file) => {
    if (file.loadingState === "not-yet") {
      handleChoosedFiles(file.id, {
        loadingState: "loading",
      });
      try {
        const base64Url = await convertCDNToBase64(file.url);
        handleChoosedFiles(file.id, {
          url: base64Url,
          loadingState: "loaded",
        });
      } catch (error) {
        handleChoosedFiles(file.id, {
          loadingState: "not-yet",
        });
        setErrorMessage("Provided Image can't be loaded!");
      }
    }
  };

  // While choosed a new image it will load all the existing images which has already uploaded
  const loadExistingImages = async (selectLast = false, selectIndex = 0) => {
    const generateImgObj = async (imageUrl, index, fileType, updatedName) => {
      const imgDimension = await new Promise((resolve, reject) => {
        let img = new Image();
        img.src = imageUrl;
        img.onload = function () {
          resolve({ width: this.width, height: this.height });
        };
        img.onerror = () =>
          resolve({ width: dimensions.height, height: dimensions.height });
      });
      const ctnDimension = calculateContainerDimensions(
        imgDimension.width,
        imgDimension.height
      );
      const aspect = defaultAspect
        ? cropsVariant.find((e) => e.id === defaultAspect).ratio
        : 1 / 1;
      const imgObj = {
        fileType,
        fileName: updatedName,
        width: imgDimension.width,
        image: null,
        url: imageUrl,
        id: generateUniqueId(),
        crop: centerAspectCrop(ctnDimension.width, ctnDimension.height, aspect),
        dimension: imgDimension,
        ctnDimension,
        aspect: defaultAspect,
        cropImgUrl: imageUrl,
        cropImgIndex: index,
        loadingState: imageUrl.match(/^data:image/) ? "loaded" : "not-yet", // "loaded" | "not-yet" | "loading"
        error: "",
      };

      return imgObj;
    };
    if (multiple) {
      await asyncForEach(cropImg, async (imageUrl, index) => {
        const fileType = forceJpeg
          ? "jpeg"
          : filename[index].substring(imageUrl.lastIndexOf(".") + 1);
        const updatedName = filename[index].replace(filenameRegex, "");
        const imgObj = await generateImgObj(
          imageUrl,
          index,
          fileType,
          updatedName
        );
        setChoosedFiles((choosedFiles) => [
          ...choosedFiles
            .filter((e) => typeof e.cropImgIndex === "number")
            .sort((a, b) => a.cropImgIndex - b.cropImgIndex)
            .concat(imgObj),
          ...choosedFiles.filter((e) => !e.cropImgUrl),
        ]);
        if (selectLast && index === selectIndex) {
          setSelectedImage(imgObj.id);
          checkIfImagesHasLoaded(imgObj);
        }
      });
    } else {
      if (cropImg) {
        const fileType = forceJpeg
          ? "jpeg"
          : cropImg.substring(cropImg.lastIndexOf(".") + 1);
        const updatedName = cropImg.replace(filenameRegex, "");
        const imgObj = await generateImgObj(cropImg, 0, fileType, updatedName);
        setChoosedFiles([imgObj]);
        setSelectedImage(imgObj.id);
        checkIfImagesHasLoaded(imgObj);
      }
    }
  };

  // When Add a new image, The Image context will be created - Supports single/multiple images
  const handleDrop = async (droppedFiles) => {
    const acceptedFiles = droppedFiles.splice(
      0,
      multiple ? 6 - currFilesLength : 1
    );
    await asyncForEach(acceptedFiles, async (file, fileIndex) => {
      let fileType = forceJpeg ? "jpeg" : file.type.split("/")[1];
      if (file.type.split("/")[1] === "png" && file.size / 1024 > 512) {
        fileType = "jpeg";
      }
      const fileNameWithoutExtension = file.name.replace(/\.[^.]+$/, "");
      const updatedName =
        fileNameWithoutExtension.replace(/[^\w-]+/g, "") + "." + fileType;

      const reader = new FileReader();
      reader.addEventListener(
        "load",
        async () => {
          const i = new Image();
          i.src = reader.result;

          const imgObj = await new Promise((resolve, reject) => {
            i.onload = async function () {
              const ctnDimension = calculateContainerDimensions(
                i.width,
                i.height
              );
              const aspect = defaultAspect
                ? cropsVariant.find((e) => e.id === defaultAspect).ratio
                : 1 / 1;
              const imgObj = {
                fileType,
                fileName: updatedName,
                width: i.width,
                dimension: { width: i.width, height: i.height },
                ctnDimension,
                image: null,
                url: reader.result,
                id: generateUniqueId(),
                crop: centerAspectCrop(
                  ctnDimension.width,
                  ctnDimension.height,
                  aspect
                ),
                droppedImgIndex: fileIndex,
                aspect: defaultAspect,
                error: "",
              };

              try {
                if (
                  file.type.split("/")[1] === "png" &&
                  file.size / 1024 > 512
                ) {
                  const pngToJpegOptimizedImageUrl = await getCroppedImg({
                    ...imgObj,
                    image: i,
                    crop: centerAspectCrop(
                      ctnDimension.width,
                      ctnDimension.height,
                      ctnDimension.width / ctnDimension.height
                    ),
                  });
                  const size = await getImageFileSize(
                    pngToJpegOptimizedImageUrl
                  );
                  setLoader(false);
                  imgObj.url = pngToJpegOptimizedImageUrl;
                  if (size && size / 1024 > 1024) {
                    imgObj.error =
                      "Image size is more than 1MB, kindly remove or crop the image.";
                  }
                } else {
                  const optimizedImageUrl = await getCroppedImg({
                    ...imgObj,
                    image: i,
                    crop: centerAspectCrop(
                      ctnDimension.width,
                      ctnDimension.height,
                      ctnDimension.width / ctnDimension.height
                    ),
                  });
                  const size = await getImageFileSize(optimizedImageUrl);
                  setLoader(false);
                  imgObj.url = optimizedImageUrl;
                  if (size && size / 1024 > 1024) {
                    imgObj.error =
                      "Image size is more than 1MB, kindly remove or crop the image.";
                  }
                }
              } catch (error) {}
              resolve(imgObj);
            };
            i.onerror = (error) => reject(error);
          });

          if (multiple) {
            setChoosedFiles((choosedFiles) => [
              ...choosedFiles
                .filter((e) => typeof e.cropImgIndex === "number")
                .sort((a, b) => a.cropImgIndex - b.cropImgIndex),
              ...choosedFiles
                .filter((e) => typeof e.droppedImgIndex === "number")
                .concat(imgObj)
                .sort((a, b) => a.droppedImgIndex - b.droppedImgIndex),
            ]);
          } else {
            setChoosedFiles([imgObj]);
          }
          if (fileIndex === 0) {
            setSelectedImage(() => imgObj.id);
          }
        },
        false
      );
      reader.readAsDataURL(file);
    });
  };

  const getImageFileSize = async (base64Url) => {
    try {
      const response = await fetch(base64Url);
      if (!response.ok) {
        throw new Error("Failed to fetch the image");
      }
      const imageData = await response.blob();
      const fileSizeInBytes = imageData.size;
      console.log(fileSizeInBytes);
      return fileSizeInBytes;
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  };

  // Optimize image width, height based on Requirment i.e. for Product MaxSize=1920px, Logo MaxSize=800px
  const optimizeImg = (img, format, maxSize) => {
    return new Promise((resolve) => {
      let width = img.width,
        height = img.height;
      if (width > height) {
        if (width > maxSize) {
          height = (height * maxSize) / width;
          width = maxSize;
        }
      } else {
        if (height > maxSize) {
          width = (width * maxSize) / height;
          height = maxSize;
        }
      }
      let canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      let context = canvas.getContext("2d");
      if (format !== "image/png") {
        context.fillStyle = "#FFFFFF";
        context.fillRect(0, 0, width, height);
      }
      context.drawImage(img, 0, 0, width, height);
      let optimized = canvas.toDataURL(format);
      resolve(optimized);
    });
  };

  // While Crop Complete This function generate a new Image based on Cropped Area and Optimize based on requirment
  const getCroppedImg = async (file) => {
    setLoader(true);
    const canvas = document.createElement("canvas");
    const scaleX =
      file.image.naturalWidth / (file.ctnDimension?.width ?? file.image.width);
    const scaleY =
      file.image.naturalHeight /
      (file.ctnDimension?.height ?? file.image.height);
    canvas.width = Math.round(file.crop.width * scaleX);
    canvas.height = Math.round(file.crop.height * scaleY);
    const ctx = canvas.getContext("2d");
    // ctx.imageSmoothingQuality = "high";
    ctx.drawImage(
      file.image,
      Math.round(file.crop.x * scaleX),
      Math.round(file.crop.y * scaleY),
      Math.round(file.crop.width * scaleX),
      Math.round(file.crop.height * scaleY),
      0,
      0,
      Math.round(file.crop.width * scaleX),
      Math.round(file.crop.height * scaleY)
    );

    const format = forceJpeg ? "image/jpeg" : `image/${file.fileType}`;

    let img = new Image();
    img.src = canvas.toDataURL(format);

    return await new Promise((resolve, reject) => {
      img.onload = async () => {
        let optImg = await optimizeImg(img, format, maxSize);
        resolve(optImg);
      };
      img.onerror = (error) => reject(error);
    });
  };

  // Can be modify Choosed Images Object Attributes
  const handleChoosedFiles = (id, payload) => {
    setChoosedFiles((choosedFiles) => {
      return choosedFiles.map((file) => {
        if (file.id === id) {
          return {
            ...file,
            ...payload,
          };
        } else {
          return file;
        }
      });
    });
  };

  // Close Modal or Just Reset Whole State of Upload Image Context
  const closeModalAndClean = () => {
    setSelectedImage(null);
    setChoosedFiles([]);
    setLoader(false);
    setOpenModal(false);
    setOpenImageHandler(false);
    imgRef.current = null;
  };

  // Choosed Images List can be drag to rearrange
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newImages = Array.from(choosedFiles);
    const [reorderedItem] = newImages.splice(result.source.index, 1);
    newImages.splice(result.destination.index, 0, reorderedItem);
    setChoosedFiles(newImages);
  };

  return (
    <div>
      {(multiple ? currFilesLength < 6 : currFilesLength === 0) && (
        <Dropzone
          onDrop={(acceptedFiles) => {
            setOpenModal(true);
            loadExistingImages();
            handleDrop(acceptedFiles);
          }}
          multiple={multiple}
          accept="image/jpeg, image/png, image/x-png, image/jpg, image/webp"
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} style={{ outline: "none", width: 60 }}>
              <input {...getInputProps()} />
              {uploadContainer}
            </div>
          )}
        </Dropzone>
      )}
      <Modal
        open={openModal}
        onClose={(_, reason) => {
          if (!(reason === "escapeKeyDown" || reason === "backdropClick")) {
            setOpenModal(false);
            setSelectedImage(() => null);
          }
        }}
        className={`edit-image-modal ${rowFlexCenterAll}`}
      >
        <div className={classes.headerRow} ref={modalRef}>
          <div className={rowFlexJustifyBetween} style={{ marginBottom: 18 }}>
            <Typography className={classes.editImage}>Edit Image</Typography>
            <CombinedButtons
              style={{
                minWidth: "unset",
                width: 78,
                height: 36,
                borderRadius: 8,
                fontSize: 14,
                fontWeight: 600,
              }}
              outlineBtnStyle={{ color: "#666666", borderColor: "#666666" }}
              outlinedBtnText="Cancel"
              solidBtnText="Done"
              outlinedBtnAction={closeModalAndClean}
              disabled={choosedFiles.some((e) => e.loadingState === "loading")}
              solidBtnAction={handleExportImages}
              loading={loader}
            />
          </div>
          <div className={classes.devider}></div>
          <div
            className={`${rowFlexOnlyJustifyBetweenCenter} ${classes.cropImgCtn}`}
          >
            <div className={classes.cropImgCol}>
              <Typography
                style={{ fontSize: 14, fontWeight: 600, marginRight: 16 }}
              >
                Crop Image:
              </Typography>
              <div style={{ display: "flex", margin: "-8px" }}>
                {cropsVariant.map((variant) => (
                  <div style={{ padding: "8px" }} key={variant.id}>
                    <Button
                      className={classes.cropVarBtns}
                      style={{
                        backgroundColor:
                          currentSelectedFile?.aspect === variant.id
                            ? "#E9EEFF"
                            : "#FFFFFF",
                        border:
                          currentSelectedFile?.aspect === variant.id
                            ? "1px solid #666666"
                            : "1px solid #E1E1E1",
                      }}
                      disabled={disabledCrops}
                      onClick={() => handleCropChange(variant.id)}
                    >
                      {variant.title}
                    </Button>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {currentSelectedFile && (
            <Fragment>
              <div
                className={classes.cropImgWrapper}
                style={{ height: dimensions.height }}
                ref={imageWrapperRef}
              >
                {currentSelectedFile.aspect === "original" ? (
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <img
                      alt=""
                      src={currentSelectedFile.url}
                      className={classes.originalImg}
                      style={{
                        width: currentSelectedFile.ctnDimension.width,
                        height: currentSelectedFile.ctnDimension.height,
                      }}
                    />
                    {disabledCrops && (
                      <div className={classes.circleProgress}>
                        <CircularProgress style={{ color: "#73caff" }} />
                      </div>
                    )}
                  </div>
                ) : (
                  <ReactCrop
                    key={currentSelectedFile.aspect}
                    crop={currentSelectedFile.crop}
                    onChange={(crop) => {
                      handleChoosedFiles(selectedImage, { crop });
                    }}
                    onComplete={(crop) => {
                      if (crop && crop.height !== 0 && crop.width !== 0) {
                        handleChoosedFiles(selectedImage, {
                          crop,
                          error: "",
                        });
                      }
                    }}
                    aspect={
                      cropsVariant.find(
                        (variant) => variant.id === currentSelectedFile.aspect
                      ).ratio
                    }
                    minHeight={100}
                    minWidth={100}
                  >
                    <img
                      ref={imgRef}
                      alt=""
                      src={currentSelectedFile.url}
                      style={{
                        width: currentSelectedFile.ctnDimension.width,
                        height: currentSelectedFile.ctnDimension.height,
                        objectFit: "fill",
                      }}
                      onLoad={(e) =>
                        handleChoosedFiles(selectedImage, {
                          image: e.currentTarget,
                        })
                      }
                    />
                  </ReactCrop>
                )}
              </div>
              {currentSelectedFile.error && (
                <Alert
                  severity="error"
                  className={classes.error}
                  style={{ ...(multiple && { marginBottom: "-8px" }) }}
                >
                  {currentSelectedFile.error}
                </Alert>
              )}
              <div className={classes.thumbnainCtn}>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="image-list" direction="horizontal">
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{ display: "flex", flexWrap: "wrap" }}
                      >
                        {choosedFiles.map((file, index) => (
                          <Draggable
                            key={file.id}
                            draggableId={file.id}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  outline:
                                    file.id === selectedImage
                                      ? "3px solid #73caff"
                                      : "1px solid #e1e1e1",
                                  ...provided.draggableProps.style,
                                }}
                                className={classes.image}
                              >
                                <div
                                  className={`${classes.close} close`}
                                  onClick={() => handleSelectImage(file)}
                                >
                                  <RemoveCircleIcon />
                                </div>
                                <img
                                  src={file.url}
                                  alt=""
                                  className={classes.thumbnail}
                                  onClick={() => {
                                    setSelectedImage(() => file.id);
                                    checkIfImagesHasLoaded(file);
                                  }}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                {multiple && currFilesLength < 6 && (
                  <Dropzone
                    onDrop={handleDrop}
                    multiple={multiple}
                    accept="image/jpeg, image/png, image/x-png, image/jpg, image/webp"
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps()}
                        style={{ outline: "none", width: 60 }}
                      >
                        <input {...getInputProps()} />
                        <div style={{ width: 60, cursor: "pointer" }}>
                          <AddImage />
                        </div>
                      </div>
                    )}
                  </Dropzone>
                )}
              </div>
              {multiple && (
                <p style={{ textAlign: "center", margin: 0, fontSize: 12.5 }}>
                  Maximum 6 Images. <strong>Drag</strong> thumbnail to re-order
                </p>
              )}
            </Fragment>
          )}
          {!currentSelectedFile && (
            <Fragment>
              <div
                className={classes.noImage}
                style={{ height: dimensions.height }}
              >
                <p className={classes.noImagePara}>
                  No Image Selected, please add image
                </p>
              </div>
              <div className={classes.thumbnainCtn}>
                <Dropzone
                  onDrop={handleDrop}
                  multiple={multiple}
                  accept="image/jpeg, image/png, image/x-png, image/jpg, image/webp"
                >
                  {({ getRootProps, getInputProps }) => (
                    <div
                      {...getRootProps()}
                      style={{ outline: "none", width: 60 }}
                    >
                      <input {...getInputProps()} />
                      <div style={{ width: 60, cursor: "pointer" }}>
                        <AddImage />
                      </div>
                    </div>
                  )}
                </Dropzone>
              </div>
            </Fragment>
          )}
          <Toast
            open={!!errorMessage}
            message={errorMessage}
            close={() => setErrorMessage("")}
            severity="error"
          />
        </div>
      </Modal>
    </div>
  );
};

export default ImageHandler;
