import React, { useState, useMemo, useRef, useEffect } from "react";
import { Grid, Box, Button, Link, Typography } from "@material-ui/core";
import PricingTable from "./SubscribtionComparePage";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./styles";
import Plan from "./Components/Plan";
import SubscriptionModal from "./Components/SubscriptionModal";
import { planCustomProps } from "./constants";
import PaymentModal from "./Components/PaymentModal";
import PaymentStatusModal from "./Components/PaymentStatusModal";
import PageLoader from "../../components/Layout/PageLoader";
import Header from "./Components/RenewalData/Header";
import {
  layoutContainer,
  allplansHeader,
  ContactSalesLink,
} from "../../styles/common";
import { getStoreIdFromUrl } from "../../utils/storeUtil";
import { getPlanRenewalInfo } from "../../redux/store/storeActions";

const Plans = () => {
  const classes = useStyles();
  const storeState = useSelector((state) => state.store);
  const [stage, setStage] = useState(0);
  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);
  const [currentSelectedPlan, setCurrentSelectedPlan] = useState("");
  const [paymentProps, setPaymentProps] = useState(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [showPaymentStatusModal, setShowPaymentStatusModal] = useState(false);
  const sectionRef = useRef(null);
  const storeId = getStoreIdFromUrl();
  const dispatch = useDispatch();
  const selectedPlan = useMemo(() => {
    return storeState.plans.find((plan) => plan.plan === currentSelectedPlan);
  }, [storeState.plans, currentSelectedPlan]);

  const scrollToSection = () => {
    if (sectionRef.current) {
      const offset = 62;
      const elementPosition = sectionRef.current.offsetTop;
      window.scrollTo({
        top: elementPosition - offset,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    dispatch(getPlanRenewalInfo(storeId));
  }, [storeId]);

  const handlePlanSelect = (plan) => {
    setIsSubscriptionModalOpen(true);
    setCurrentSelectedPlan(plan);
    setStage(0);
  };

  const handlePayment = (selectedPlan, selectedDomain, addons) => {
    setPaymentProps({ selectedDomain, selectedPlan, addons });
    setShowPaymentModal(true);
    setIsSubscriptionModalOpen(false);
  };

  const handlePaymentModalUnMount = (_paymentStatus) => {
    setPaymentStatus(_paymentStatus);
    setShowPaymentModal(false);
    if (_paymentStatus === "success") {
      setShowPaymentStatusModal(true);
    } else {
      setIsSubscriptionModalOpen(true);
      setStage(1);
      if (selectedPlan && selectedPlan.plan_add_ons.length === 0) {
        setStage(0);
      }
    }
  };

  const handleCloseModal = () => {
    setPaymentProps([]);
    setIsSubscriptionModalOpen(false);
  };

  const maxFeatures =
    storeState && storeState.plans
      ? Math.max(...storeState.plans.map((plan) => plan.plan_features.length))
      : 0;
  const featureHeight = 32;
  const calculatedHeight = maxFeatures * featureHeight;

  const handleStageChange = (newStage) => {
    setStage(newStage);
  };
  const isEmptyObject = (obj) => Object.keys(obj).length === 0;
  const hasStorePlanRenewInfo = !isEmptyObject(storeState.storePlanRenewInfo);

  if (!hasStorePlanRenewInfo) {
    return <PageLoader />;
  }

  return (
    <>
      <Box>
        <div className={layoutContainer}>
          <Typography className={classes.subscriptionTitle}>
            Subscription
          </Typography>
          <Box>
            <Box
              style={{
                background: "#FFFFFF",
                borderRadius: "5px",
                padding: "20px 28px",
              }}
            >
              <Header
                plans={storeState.plans}
                renewObj={storeState.storePlanRenewInfo}
              />
            </Box>
            <div className={classes.dataloded}>
              <Grid
                className={allplansHeader}
                style={{
                  borderRadius: "10px",
                  margin: "0px",
                }}
              >
                <Grid style={{ marginLeft: "8px" }}>
                  <Typography style={{ fontSize: "20px", fontWeight: "600" }}>
                    All plans
                  </Typography>
                  <div>
                    Need help in deciding which plan is better for your
                    business?
                    <span>
                      <Link
                        href="https://www.shoopy.in/contact-us"
                        className={ContactSalesLink}
                        style={{ cursor: "pointer" }}
                        target="_blank"
                      >
                        Contact Sales
                      </Link>
                    </span>
                  </div>
                </Grid>
                <Grid container className={classes.planSection} spacing={2}>
                  {storeState &&
                    storeState.plans
                      .filter((plan) => plan.plan !== "FREE")
                      .map((plan, idx) => (
                        <Grid
                          item
                          md={4}
                          key={`${idx}-${plan.id}`}
                          style={{
                            width: "100%",
                            padding: "0px px",
                            position: idx === 1 ? "relative" : "static",
                          }}
                        >
                          <Box>
                            <Plan
                              key={plan.plan}
                              planTitle={plan.title}
                              subTitle={plan.short_descriptionn}
                              renewObj={storeState.storePlanRenewInfo}
                              mrp={plan.mrp / 100}
                              amount={plan.amount / 100}
                              real_amount={plan.real_amount / 100}
                              discountPercent={plan.discountPercent}
                              isCurrent={storeState.store.plan === plan.plan}
                              isButtonActive={
                                idx + 1 >
                                storeState.plans.findIndex(
                                  (el) => el.plan === storeState.store.plan
                                )
                              }
                              features={plan.plan_features}
                              calculatedHeight={calculatedHeight}
                              onPlanSelect={() => handlePlanSelect(plan.plan)}
                              {...planCustomProps[plan.plan]}
                              unroundHead={idx === 1}
                              renewal_amount={plan.renewal_amount / 100}
                            />
                          </Box>
                        </Grid>
                      ))}
                </Grid>
              </Grid>

              <Grid container style={{ justifyContent: "center" }}>
                <Grid item>
                  <Button
                    className={classes.compareallfeatures}
                    onClick={scrollToSection}
                  >
                    <span style={{ fontWeight: 600 }}>
                      Compare all features
                    </span>
                    <span>
                      <img
                        style={{ width: "20px", height: "20px" }}
                        src="/subscribtion_page/arrow_downward_alt.svg"
                        alt="Arrow Down"
                      />
                    </span>
                  </Button>

                  <Typography
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      textAlign: "center",
                    }}
                  >
                    Read our{" "}
                    <Link
                      href="https://www.shoopy.in/fair-use-policy"
                      className={classes.fairpolicy}
                      style={{ cursor: "pointer" }}
                      target="_blank"
                    >
                      Fair use policy
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div
              style={{
                padding: "20px",
                borderRadius: "5px",
                background: "#FFFFFF",
              }}
            >
              <div ref={sectionRef}>
                <PricingTable />
              </div>
            </div>
          </Box>
        </div>
      </Box>
      {isSubscriptionModalOpen && (
        <SubscriptionModal
          isOpen={isSubscriptionModalOpen}
          close={handleCloseModal}
          currentSelectedPlan={storeState.plans.find(
            (plan) => plan.plan === currentSelectedPlan
          )}
          handlePaymentFlow={handlePayment}
          storeState={storeState}
          hydrateState={paymentStatus === "failed"}
          currentStage={handleStageChange}
          paymentProps={paymentProps}
          handlePlanSelect={handlePlanSelect}
          stage={stage}
          setStage={setStage}
        />
      )}
      {showPaymentModal && (
        <PaymentModal
          {...paymentProps}
          slug={storeState.store.slug}
          unmount={handlePaymentModalUnMount}
        />
      )}
      {showPaymentStatusModal && (
        <PaymentStatusModal
          status={paymentStatus}
          onClose={() => setShowPaymentStatusModal(false)}
          selectedPlan={storeState.storePlanRenewInfo}
        />
      )}
    </>
  );
};

export default Plans;
