import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const AppStoreIcon = ({ viewBox = "0 0 16 17", stroke = "#999999" }) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "16px", height: "17px" }}>
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.747645 1.30072H5.23351C5.4611 1.30072 5.6479 1.48634 5.6479 1.71816V6.22228C5.6479 6.4541 5.4611 6.63972 5.23351 6.63972H0.747645C0.520059 6.63972 0.333255 6.4541 0.333255 6.22228V1.71816C0.333255 1.48634 0.520059 1.30072 0.747645 1.30072Z"
          stroke={stroke}
          strokeWidth="0.66651"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.73067 3.72115L11.7056 0.734147C11.8459 0.593217 12.0832 0.589535 12.2356 0.742538L15.2606 3.77987C15.413 3.93287 15.4093 4.1711 15.2689 4.31203L12.294 7.29903C12.1537 7.43996 11.9164 7.44364 11.764 7.29064L8.73903 4.25331C8.58664 4.1003 8.59031 3.86208 8.73067 3.72115Z"
          stroke={stroke}
          strokeWidth="0.66651"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.1139 10.3423H14.5997C14.8273 10.3423 15.0141 10.5279 15.0141 10.7597V15.2638C15.0141 15.4957 14.8273 15.6813 14.5997 15.6813H10.1139C9.88627 15.6813 9.69947 15.4957 9.69947 15.2638V10.7597C9.69947 10.5279 9.88627 10.3423 10.1139 10.3423Z"
          stroke={stroke}
          strokeWidth="0.66651"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.747645 10.3436H5.23351C5.4611 10.3436 5.6479 10.5292 5.6479 10.7611V15.2652C5.6479 15.497 5.4611 15.6826 5.23351 15.6826H0.747645C0.520059 15.6826 0.333255 15.497 0.333255 15.2652V10.7611C0.333255 10.5292 0.520059 10.3436 0.747645 10.3436Z"
          stroke={stroke}
          strokeWidth="0.66651"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default AppStoreIcon;
