import React from "react";
import { ArrowIcon } from "../../icons";

const NoRecord = ({ text, image, arrow }) => {
  return (
    <div style={{ position: "relative", width: "100%" }}>
      {arrow && (
        <div
          style={{
            position: "absolute",
            right: "13%",
            top: 30,
          }}
        >
          <ArrowIcon />
        </div>
      )}
      <div
        style={{
          position: "absolute",
          right: "32%",
          top: 95,
          textAlign: "center",
        }}
      >
        <p
          style={{
            fontWeight: 600,
            fontSize: 20,
            lineHeight: "32px",
            color: "#1A1A1A",
          }}
        >
          {text}
        </p>
      </div>
      {image && (
        <div
          style={{
            position: "absolute",
            right: "31%",
            top: 210,
          }}
        >
          {image}
        </div>
      )}
    </div>
  );
};

export default NoRecord;
