import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const NoCategoriesFigma = ({
  viewBox = "0 0 500 424",
  stroke = "#999999",
  fill = "#999999",
}) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "500px", height: "424px" }}>
      <svg
        width="500"
        height="424"
        viewBox="0 0 500 424"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M452.38 277.536C468.691 277.536 481.914 264.334 481.914 248.049C481.914 231.765 468.691 218.563 452.38 218.563C436.069 218.563 422.847 231.765 422.847 248.049C422.847 264.334 436.069 277.536 452.38 277.536Z"
          fill="#F2F2F2"
        />
        <path
          d="M317.619 277.536C333.93 277.536 347.153 264.334 347.153 248.049C347.153 231.765 333.93 218.563 317.619 218.563C301.309 218.563 288.086 231.765 288.086 248.049C288.086 264.334 301.309 277.536 317.619 277.536Z"
          fill="#F2F2F2"
        />
        <path
          d="M348.028 11.9419H275.835V97.5609H348.028V11.9419Z"
          fill="#F2F2F2"
        />
        <path
          d="M366.552 98.1451H275.25V0H366.552V98.1451ZM276.421 96.9765H365.382V1.1687H276.421V96.9765Z"
          fill="#3F3D56"
        />
        <path
          d="M366.552 291.225H275.25V193.08H366.552V291.225ZM276.421 290.056H365.382V194.248H276.421V290.056Z"
          fill="#3F3D56"
        />
        <path
          d="M233.105 291.225H141.802V193.08H233.105V291.225ZM142.973 290.056H231.934V194.248H142.973V290.056Z"
          fill="#3F3D56"
        />
        <path
          d="M500 291.225H408.697V193.08H500V291.225ZM409.868 290.056H498.829V194.248H409.868V290.056Z"
          fill="#3F3D56"
        />
        <path
          d="M321.486 97.5607H320.316V193.664H321.486V97.5607Z"
          fill="#3F3D56"
        />
        <path
          d="M454.934 193.664H453.763V140.081H188.038V193.664H186.868V138.912H454.934V193.664Z"
          fill="#3F3D56"
        />
        <path
          d="M335.777 39.1324H275.835V40.3012H335.777V39.1324Z"
          fill="#3F3D56"
        />
        <path
          d="M335.777 55.0406H275.835V56.2094H335.777V55.0406Z"
          fill="#3F3D56"
        />
        <path
          d="M335.777 70.9489H275.835V72.1176H335.777V70.9489Z"
          fill="#3F3D56"
        />
        <path
          d="M251.748 419.66H14.2231V420.696H251.748V419.66Z"
          fill="#3F3D56"
        />
        <path
          d="M187.453 277.536C207.026 277.536 222.894 261.694 222.894 242.152C222.894 222.61 207.026 206.769 187.453 206.769C167.88 206.769 152.013 222.61 152.013 242.152C152.013 261.694 167.88 277.536 187.453 277.536Z"
          fill="#1641DB"
        />
        <path
          d="M209.768 238.658H190.953V219.874H183.953V238.658H165.139V245.647H183.953V264.43H190.953V245.647H209.768V238.658Z"
          fill="white"
        />
        <path
          d="M320.901 278.12C313.776 278.12 306.811 276.01 300.886 272.058C294.962 268.106 290.344 262.489 287.618 255.917C284.891 249.344 284.178 242.112 285.568 235.135C286.958 228.158 290.389 221.75 295.427 216.719C300.465 211.689 306.884 208.264 313.872 206.876C320.861 205.488 328.104 206.2 334.687 208.922C341.27 211.645 346.896 216.255 350.855 222.17C354.813 228.084 356.926 235.038 356.926 242.152C356.915 251.688 353.116 260.83 346.363 267.573C339.609 274.316 330.452 278.109 320.901 278.12ZM320.901 207.353C314.007 207.353 307.268 209.394 301.537 213.218C295.805 217.042 291.337 222.477 288.699 228.835C286.061 235.194 285.371 242.191 286.716 248.941C288.061 255.692 291.38 261.892 296.255 266.759C301.129 271.626 307.34 274.94 314.101 276.283C320.862 277.625 327.871 276.936 334.239 274.302C340.608 271.668 346.052 267.208 349.882 261.485C353.712 255.763 355.756 249.035 355.756 242.152C355.745 232.926 352.07 224.081 345.535 217.557C339.001 211.033 330.142 207.364 320.901 207.353Z"
          fill="#3F3D56"
        />
        <path
          d="M343.8 246.231H298.001V238.073H343.8L343.8 246.231ZM299.172 245.062H342.63V239.242H299.172V245.062Z"
          fill="#3F3D56"
        />
        <path
          d="M474.799 246.231H429V238.073H474.799L474.799 246.231ZM430.171 245.062H473.628V239.242H430.171V245.062Z"
          fill="#3F3D56"
        />
        <path
          d="M454.349 278.12C447.224 278.12 440.259 276.011 434.334 272.058C428.41 268.106 423.792 262.489 421.066 255.917C418.339 249.345 417.625 242.113 419.015 235.136C420.405 228.159 423.836 221.75 428.875 216.72C433.913 211.689 440.332 208.264 447.32 206.876C454.308 205.488 461.552 206.2 468.135 208.922C474.717 211.645 480.344 216.255 484.302 222.17C488.261 228.084 490.374 235.038 490.374 242.152C490.363 251.688 486.564 260.83 479.81 267.573C473.057 274.316 463.9 278.109 454.349 278.12ZM454.349 207.353C447.455 207.353 440.716 209.394 434.984 213.218C429.253 217.042 424.785 222.477 422.147 228.835C419.509 235.194 418.819 242.191 420.163 248.941C421.508 255.691 424.828 261.892 429.703 266.759C434.577 271.626 440.788 274.94 447.549 276.283C454.31 277.625 461.318 276.936 467.687 274.302C474.056 271.668 479.5 267.208 483.329 261.485C487.159 255.763 489.203 249.035 489.203 242.152C489.193 232.926 485.517 224.081 478.983 217.557C472.449 211.034 463.59 207.364 454.349 207.353Z"
          fill="#3F3D56"
        />
        <path
          d="M149.589 209.881C149.589 209.881 162.048 235.796 153.742 236.832C145.436 237.869 143.359 213.509 143.359 213.509L149.589 209.881Z"
          fill="#FFB9B9"
        />
        <path
          d="M102.348 375.735L110.655 390.431L95.5998 399.836L92.0049 382.483L102.348 375.735Z"
          fill="#FFB9B9"
        />
        <path
          d="M105.896 392.473C105.896 392.473 110.456 385.059 112.106 386.3C113.755 387.541 120.559 392.918 120.559 392.918L129.421 401.393C129.421 401.393 159.363 404.553 140.337 415.027C121.312 425.501 110.798 416.817 110.798 416.817C110.798 416.817 84.1505 418.2 84.5803 410.778C85.0102 403.355 93.6041 391.612 93.6041 391.612C93.6041 391.612 104.435 398.862 105.896 392.473Z"
          fill="#2F2E41"
        />
        <path
          opacity="0.1"
          d="M105.896 392.473C105.896 392.473 110.456 385.059 112.106 386.3C113.755 387.541 120.559 392.918 120.559 392.918L129.421 401.393C129.421 401.393 159.363 404.553 140.337 415.027C121.312 425.501 110.798 416.817 110.798 416.817C110.798 416.817 84.1505 418.2 84.5803 410.778C85.0102 403.355 93.6041 391.612 93.6041 391.612C93.6041 391.612 104.435 398.862 105.896 392.473Z"
          fill="black"
        />
        <path
          d="M65.4907 209.622L73.2776 303.692C73.2776 303.692 73.5372 359.927 81.8432 370.811C90.1493 381.696 90.4088 387.138 90.4088 387.138L107.54 378.327C107.54 378.327 101.83 377.808 104.425 374.18C107.021 370.552 114.808 374.18 107.54 368.997C100.272 363.814 98.7149 363.645 100.532 361.397C101.652 359.972 102.449 358.321 102.868 356.558L98.1957 298.509L107.021 197.442L65.4907 209.622Z"
          fill="#2F2E41"
        />
        <path
          opacity="0.1"
          d="M65.4907 209.622L73.2776 303.692C73.2776 303.692 73.5372 359.927 81.8432 370.811C90.1493 381.696 90.4088 387.138 90.4088 387.138L107.54 378.327C107.54 378.327 101.83 377.808 104.425 374.18C107.021 370.552 114.808 374.18 107.54 368.997C100.272 363.814 98.7149 363.645 100.532 361.397C101.652 359.972 102.449 358.321 102.868 356.558L98.1957 298.509L107.021 197.442L65.4907 209.622Z"
          fill="black"
        />
        <path
          d="M86.7746 375.735L79.5068 394.912L58.7417 387.138L71.7199 373.662L86.7746 375.735Z"
          fill="#FFB9B9"
        />
        <path
          d="M76.4636 386.668C76.4636 386.668 84.2228 381.826 85.1927 383.763C86.1625 385.7 90.0421 393.93 90.0421 393.93L94.4066 406.035C94.4066 406.035 121.079 422.98 98.2861 423.949C75.4937 424.917 69.6744 411.845 69.6744 411.845C69.6744 411.845 43.9724 400.709 47.852 393.93C51.7315 387.152 65.31 380.122 65.31 380.122C65.31 380.122 72.0992 391.994 76.4636 386.668Z"
          fill="#2F2E41"
        />
        <path
          d="M131.42 209.881L135.573 296.436C135.573 296.436 115.327 353.967 97.6766 363.296C97.6766 363.296 97.6766 374.699 93.5236 376.772C92.2861 377.41 91.1891 378.289 90.2975 379.357C89.4059 380.425 88.7377 381.661 88.3323 382.991L65.4907 377.29C65.4907 377.29 71.2011 371.589 69.1246 368.997C67.0481 366.406 75.8733 360.705 75.8733 360.705L98.7149 305.247C98.7149 305.247 102.349 303.692 103.387 298.509C104.425 293.326 106.502 293.326 101.31 292.29C96.1192 291.253 96.1192 286.07 96.1192 286.07L78.988 207.289L131.42 209.881Z"
          fill="#2F2E41"
        />
        <path
          d="M112.731 74.0877C124.199 74.0877 133.496 64.8058 133.496 53.3559C133.496 41.9061 124.199 32.6241 112.731 32.6241C101.263 32.6241 91.9658 41.9061 91.9658 53.3559C91.9658 64.8058 101.263 74.0877 112.731 74.0877Z"
          fill="#FFB9B9"
        />
        <path
          d="M94.3024 54.6516C94.3024 54.6516 92.745 81.0846 90.6685 83.6761C88.592 86.2676 112.472 85.7493 112.472 85.7493C112.472 85.7493 110.914 63.4626 112.472 61.3894C114.029 59.3163 94.3024 54.6516 94.3024 54.6516Z"
          fill="#FFB9B9"
        />
        <path
          d="M87.2937 75.6425C87.2937 75.6425 107.021 73.5693 114.288 79.7889C116.61 81.7678 119.097 83.5443 121.723 85.099C123.916 86.4122 125.743 88.2549 127.036 90.4576C128.329 92.6603 129.046 95.1522 129.122 97.7041L132.977 215.582C132.977 215.582 74.3155 210.399 63.4138 215.582C63.4138 215.582 62.8947 203.661 61.8565 202.106C61.0996 200.973 59.7909 155.768 59.1372 131.35C58.7683 117.571 63.5116 104.054 72.767 93.8257C75.9134 90.3484 79.4289 87.4337 83.1407 86.0084C83.1407 86.0084 82.1024 76.1608 87.2937 75.6425Z"
          fill="#575A89"
        />
        <path
          d="M118.961 86.0084C121.883 86.7762 124.513 88.3889 126.52 90.644C128.528 92.8991 129.823 95.6961 130.243 98.6837L137.649 151.314L148.032 193.295C148.032 193.295 153.223 197.96 151.147 200.552C149.07 203.143 153.742 214.027 153.742 214.027L140.764 218.174C140.764 218.174 139.207 207.289 136.611 206.771C134.33 206.316 108.795 138.21 102.639 121.711C101.791 119.436 101.311 118.143 101.311 118.143C101.311 118.143 102.349 83.4169 118.961 86.0084Z"
          fill="#575A89"
        />
        <path
          opacity="0.1"
          d="M101.57 117.884L132.977 202.712C132.977 202.712 102.608 145.353 101.57 117.884Z"
          fill="black"
        />
        <path
          d="M101.883 59.4648C103.639 59.3602 105.354 59.9527 107.098 60.1838C113.337 61.0109 119.828 56.4906 121.205 50.3591C121.316 49.5425 121.56 48.7496 121.927 48.0118C122.887 46.4466 125.074 46.1481 126.877 46.5081C128.68 46.868 130.389 47.7104 132.225 47.8066C135.059 47.9551 137.781 46.2127 139.292 43.8132C140.802 41.4138 141.23 38.4614 141.026 35.6346L139.667 37.0586C139.115 35.8922 138.902 34.5945 139.052 33.3132C138.332 33.1217 137.574 33.118 136.852 33.3024C136.13 33.4869 135.468 33.8533 134.928 34.3666C133.73 34.4939 134.635 32.1475 133.744 31.3366C133.372 30.9971 132.8 31.0611 132.295 31.066C129.746 31.0905 127.684 29.1438 125.675 27.5767C122.195 24.8676 118.124 23.014 113.794 22.1658C110.915 21.6052 107.854 21.5151 105.124 22.5853C102.882 23.4642 101.006 25.065 99.2695 26.7317C94.9956 30.8348 91.2109 35.6558 89.4329 41.3031C87.9874 45.9287 87.9465 50.8781 89.3152 55.527C90.0317 57.9412 92.1909 66.2094 95.3519 66.402C99.3238 66.6439 96.9391 59.7593 101.883 59.4648Z"
          fill="#2F2E41"
        />
        <path
          d="M37.1502 420.011L36.593 419.802C36.4706 419.756 24.2853 415.095 18.5772 404.526C12.8688 393.957 15.6639 381.231 15.6928 381.104L15.8244 380.525L16.3813 380.733C16.5037 380.779 28.6887 385.441 34.3971 396.009C40.1055 406.579 37.3104 419.304 37.2815 419.431L37.1502 420.011ZM19.4912 404.034C24.3172 412.97 33.9464 417.551 36.3862 418.597C36.85 415.986 38.3048 405.429 33.4831 396.501C28.6619 387.575 19.0289 382.987 16.5881 381.939C16.124 384.551 14.6698 395.108 19.4912 404.034H19.4912Z"
          fill="#3F3D56"
        />
        <path
          d="M22.9394 400.819C33.1976 406.981 37.1484 419.22 37.1484 419.22C37.1484 419.22 24.4673 421.469 14.209 415.307C3.95078 409.145 0 396.906 0 396.906C0 396.906 12.6811 394.657 22.9394 400.819Z"
          fill="#1641DB"
        />
      </svg>
    </SvgIcon>
  );
};

export default NoCategoriesFigma;
