import { FETCH_USER_STORES, STORE_USER_PROGRESS } from "./userStoreTypes";
import axios from "axios";
import { getStores, setupStores } from "../../utils/storeUtil";
import { isCustomDomain } from "../../utils/utils";

export const fetchUserStores =
  (logoUpdate = false) =>
  async (dispatch) => {
    let stores = getStores();
    if (stores.length > 0 && !logoUpdate) {
      dispatch({
        type: FETCH_USER_STORES,
        payload: stores,
      });
      return stores;
    }
    dispatch({
      type: STORE_USER_PROGRESS,
      payload: true,
    });
    let domain = "";
    if (isCustomDomain()) {
      domain = window.location.hostname;
    }
    let accessToken = localStorage.getItem("access_token");
    return await axios
      .get(
        `${process.env.REACT_APP_API}/api/v1/store/user/all/store-users?domain=${domain}&logo=true`,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        let storeUsers = response.data.payload.filter((su) => !su.deleted);
        setupStores(storeUsers);
        dispatch({
          type: FETCH_USER_STORES,
          payload: storeUsers,
        });
        return storeUsers;
      });
  };

export const setUserStores = (storeUser) => (dispatch) => {
  const storeUsers = [storeUser];
  setupStores(storeUsers);
  dispatch({
    type: FETCH_USER_STORES,
    payload: storeUsers,
  });
};
