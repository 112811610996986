import React from "react";
import { Grid } from "@material-ui/core";
import { useStyles } from "./formStyles";
import Controls from "../../../../components/controls/Controls";
export default function FormSectionFive({
  formState,
  onFormFieldChange,
  params,
}) {
  const classes = useStyles();
  return (
    <Grid container spacing={0}>
      <p className={classes.sectionHead}>EXTRAS</p>
      <Grid item xs={12}>
        <Controls.Checkbox
          name="auto_apply"
          label="Auto apply"
          style={{ marginRight: 0, marginLeft: 0 }}
          checkboxStyles={{ padding: 0, marginRight: "12px" }}
          value={formState.auto_apply.value}
          color="secondary"
          onChange={(e) => {
            onFormFieldChange(e.target.name, e.target.checked);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Controls.Checkbox
          name="visible"
          label="Show this coupon to Customers"
          style={{ marginRight: 0, marginLeft: 0 }}
          checkboxStyles={{ padding: 0, marginRight: "12px" }}
          value={formState.visible.value}
          color="secondary"
          onChange={(e) => {
            onFormFieldChange(e.target.name, e.target.checked);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Controls.Checkbox
          name="new_customer"
          label="Allow this coupon only for New Customers"
          style={{ marginRight: 0, marginLeft: 0 }}
          checkboxStyles={{ padding: 0, marginRight: "12px" }}
          value={formState.new_customer.value}
          color="secondary"
          onChange={(e) => {
            onFormFieldChange(e.target.name, e.target.checked);
          }}
        />
      </Grid>
    </Grid>
  );
}
