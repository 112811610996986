import React from "react";

export default function RechargeSuccessIcon() {
  return (
    <svg
      width="242"
      height="136"
      viewBox="0 0 242 136"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_22114_901)">
        <mask
          id="mask0_22114_901"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="242"
          height="136"
        >
          <path d="M242 0H0V136H242V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_22114_901)">
          <g opacity="0.31948">
            <path
              d="M161.232 7.15311C165.035 7.15311 168.122 10.2379 168.122 14.0373C168.122 17.8366 165.035 20.9214 161.232 20.9214C157.429 20.9214 154.341 17.8366 154.341 14.0373C154.341 10.2379 157.429 7.15311 161.232 7.15311Z"
              fill="#FF5722"
            />
            <path
              d="M79.4352 17.8124C82.5021 17.8124 84.9921 20.3002 84.9921 23.3642C84.9921 26.4282 82.5021 28.9159 79.4352 28.9159C76.3684 28.9159 73.8784 26.4282 73.8784 23.3642C73.8784 20.3002 76.3684 17.8124 79.4352 17.8124Z"
              fill="#FF5722"
            />
            <path
              d="M76.3234 98.2015C79.8809 98.2015 82.7693 101.087 82.7693 104.641C82.7693 108.196 79.8809 111.081 76.3234 111.081C72.7659 111.081 69.8774 108.196 69.8774 104.641C69.8774 101.087 72.7659 98.2015 76.3234 98.2015Z"
              fill="#FF5722"
            />
            <path
              d="M169.233 91.9835C172.054 91.9835 174.345 94.2722 174.345 97.0911C174.345 99.91 172.054 102.199 169.233 102.199C166.411 102.199 164.121 99.91 164.121 97.0911C164.121 94.2722 166.411 91.9835 169.233 91.9835Z"
              fill="#FF5722"
            />
            <path
              d="M70.7659 73.7738C72.238 73.7738 73.4332 74.9679 73.4332 76.4386C73.4332 77.9094 72.238 79.1035 70.7659 79.1035C69.2938 79.1035 68.0986 77.9094 68.0986 76.4386C68.0986 74.9679 69.2938 73.7738 70.7659 73.7738Z"
              fill="#FF5722"
            />
            <path
              d="M169.9 52.4552C171.617 52.4552 173.012 53.8483 173.012 55.5642C173.012 57.28 171.617 58.6731 169.9 58.6731C168.182 58.6731 166.788 57.28 166.788 55.5642C166.788 53.8483 168.182 52.4552 169.9 52.4552Z"
              fill="#FF5722"
            />
            <path
              d="M117.221 10.2621C118.816 10.2621 120.111 11.5557 120.111 13.149C120.111 14.7423 118.816 16.0359 117.221 16.0359C115.626 16.0359 114.332 14.7423 114.332 13.149C114.332 11.5557 115.626 10.2621 117.221 10.2621Z"
              fill="#FF5722"
            />
            <path
              d="M120.777 116.855C122.618 116.855 124.112 118.348 124.112 120.186C124.112 122.025 122.618 123.517 120.777 123.517C118.937 123.517 117.443 122.025 117.443 120.186C117.443 118.348 118.937 116.855 120.777 116.855Z"
              fill="#FF5722"
            />
          </g>
          <g opacity="0.31948">
            <path
              d="M179.473 53.7834L180.871 56.6132L183.997 57.0669L181.735 59.2696L182.269 62.3797L179.473 60.9113L176.678 62.3797L177.211 59.2696L174.95 57.0669L178.075 56.6132L179.473 53.7834Z"
              fill="#03FF98"
            />
            <path
              d="M67.4265 54.5524L69.0781 57.896L72.7714 58.4321L70.0989 61.0347L70.7298 64.7097L67.4265 62.9746L64.1231 64.7097L64.754 61.0347L62.0815 58.4321L65.7748 57.896L67.4265 54.5524Z"
              fill="#03FF98"
            />
            <path
              d="M118.713 4.50647L119.773 6.65351L122.145 6.9975L120.429 8.66844L120.834 11.0281L118.713 9.91373L116.592 11.0281L116.997 8.66844L115.281 6.9975L117.653 6.65351L118.713 4.50647Z"
              fill="#03FF98"
            />
            <path
              d="M122.634 115.257L124.285 118.6L127.978 119.136L125.306 121.739L125.937 125.414L122.634 123.679L119.33 125.414L119.961 121.739L117.289 119.136L120.982 118.6L122.634 115.257Z"
              fill="#03FF98"
            />
          </g>
          <g opacity="0.01">
            <path
              d="M121 26.3375C143.795 26.3375 162.302 44.828 162.302 67.6018C162.302 90.3756 143.795 108.866 121 108.866C98.2053 108.866 79.6978 90.3756 79.6978 67.6018C79.6978 44.828 98.2053 26.3375 121 26.3375Z"
              fill="#7FFFCA"
            />
          </g>
          <g opacity="0.999998">
            <path
              d="M121 41.8074C135.156 41.8074 146.65 53.2904 146.65 67.4334C146.65 81.5763 135.156 93.0593 121 93.0593C106.844 93.0593 95.3506 81.5763 95.3506 67.4334C95.3506 53.2904 106.844 41.8074 121 41.8074Z"
              fill="#00FF96"
            />
          </g>
          <path
            d="M110.234 66.3415L117.586 73.6864L130.866 60.4182"
            stroke="white"
            stroke-width="5.6916"
            stroke-linecap="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_22114_901">
          <rect width="242" height="136" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
