import React, { useState } from "react";
import ImageList from "../../../components/ImageList";
import { Grid } from "@material-ui/core";
import ImageHandler from "../../../components/ImageHandler";

const Multiple = ({ maxSize = 1920 }) => {
  const [cropImgs, setCropImgs] = useState([]);
  const [filenames, setFilenames] = useState([]);
  const [openImageHandler, setOpenImageHandler] = useState(false);
  const [openImageHandlerIndex, setOpenImageHandlerIndex] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  return (
    <Grid
      item
      xs={12}
      style={{
        marginBottom: 16,
        display: "flex",
      }}
    >
      <ImageList
        cropImgs={cropImgs}
        setCropImgs={setCropImgs}
        onImageRemove={(index) => {
          let newImages = cropImgs.filter((img, i) => {
            return index !== i;
          });
          let fnames = filenames.filter((fname, i) => {
            return index !== i;
          });
          setFilenames(fnames);
          setCropImgs(newImages);
        }}
        onImageClick={(index) => {
          setOpenImageHandler(true);
          setOpenImageHandlerIndex(index);
        }}
      />
      <ImageHandler
        setCropImg={setCropImgs}
        setFilename={setFilenames}
        cropImg={cropImgs}
        multiple={true}
        filename={filenames}
        maxSize={maxSize}
        openImageHandler={openImageHandler}
        setOpenImageHandler={setOpenImageHandler}
        openImageHandlerIndex={openImageHandlerIndex}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </Grid>
  );
};

export default Multiple;
