import React from "react";

export default function TickCircle() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="7.5" fill="white" stroke="#1641DB" />
      <path
        d="M4 8.2665L6.46154 10.6665L12 4.6665"
        stroke="#1641DB"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
