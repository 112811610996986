import { Link } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { getStoreIdFromUrl } from "../../utils/storeUtil";
import { isCustomDomain } from "../../utils/utils";

const StoreLink = (props) => {
  const history = useHistory();
  const storeId = getStoreIdFromUrl();
  const customDomain = isCustomDomain();
  return (
    <Link
      {...props}
      onClick={(e) => {
        e.preventDefault();
        history.push(customDomain? `/admin/${storeId}${props.href}` : `/${storeId}${props.href}`);
      }}
    >
      {props.children}
    </Link>
  );
};

export default StoreLink;
