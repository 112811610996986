import React from "react";

const CalendarIcon = (props) => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.7544 9.5C3.54313 9.5 3.36458 9.42855 3.21875 9.28565C3.07292 9.14273 3 8.96565 3 8.7544C3 8.54313 3.07145 8.36458 3.21435 8.21875C3.35727 8.07292 3.53435 8 3.7456 8C3.95687 8 4.13542 8.07145 4.28125 8.21435C4.42708 8.35727 4.5 8.53435 4.5 8.7456C4.5 8.95687 4.42855 9.13542 4.28565 9.28125C4.14273 9.42708 3.96565 9.5 3.7544 9.5ZM7.0044 9.5C6.79313 9.5 6.61458 9.42855 6.46875 9.28565C6.32292 9.14273 6.25 8.96565 6.25 8.7544C6.25 8.54313 6.32145 8.36458 6.46435 8.21875C6.60727 8.07292 6.78435 8 6.9956 8C7.20687 8 7.38542 8.07145 7.53125 8.21435C7.67708 8.35727 7.75 8.53435 7.75 8.7456C7.75 8.95687 7.67855 9.13542 7.53565 9.28125C7.39273 9.42708 7.21565 9.5 7.0044 9.5ZM10.2544 9.5C10.0431 9.5 9.86458 9.42855 9.71875 9.28565C9.57292 9.14273 9.5 8.96565 9.5 8.7544C9.5 8.54313 9.57145 8.36458 9.71435 8.21875C9.85727 8.07292 10.0344 8 10.2456 8C10.4569 8 10.6354 8.07145 10.7812 8.21435C10.9271 8.35727 11 8.53435 11 8.7456C11 8.95687 10.9285 9.13542 10.7856 9.28125C10.6427 9.42708 10.4656 9.5 10.2544 9.5ZM1.5 16C1.0875 16 0.734375 15.8507 0.440625 15.5521C0.146875 15.2535 0 14.9028 0 14.5V3.5C0 3.09722 0.146875 2.74653 0.440625 2.44792C0.734375 2.14931 1.0875 2 1.5 2H3V0H4.5V2H9.5V0H11V2H12.5C12.9125 2 13.2656 2.14931 13.5594 2.44792C13.8531 2.74653 14 3.09722 14 3.5V14.5C14 14.9028 13.8531 15.2535 13.5594 15.5521C13.2656 15.8507 12.9125 16 12.5 16H1.5ZM1.5 14.5H12.5V7H1.5V14.5ZM1.5 5.5H12.5V3.5H1.5V5.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CalendarIcon;
