import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const NoOrderFigma = () => {
  return (
    <SvgIcon
      viewBox={"0 0 566 405"}
      style={{ width: "566px", height: "405px" }}
    >
      <svg
        width="566"
        height="405"
        viewBox="0 0 566 405"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M334.445 11.5696C318.064 4.91967 300.754 -0.647593 283.081 0.0609677C257.08 1.082 232.176 16.688 219.942 39.604C209.576 59.0168 208.298 81.7744 203.834 103.317C198.334 129.864 187.801 155.091 176.546 179.763C170.499 192.996 164.122 206.552 163.131 221.079C161.751 241.245 171.407 261.344 186.545 274.771C201.683 288.199 221.802 295.263 241.973 296.807C252.713 297.63 263.933 296.869 273.474 291.878C283.566 286.597 290.922 277.051 301.053 271.849C310.445 267.008 321.427 266.348 331.969 266.977C345.776 267.802 359.382 270.689 372.333 275.542C387.065 281.069 400.767 289.07 415.469 294.673C430.171 300.275 446.596 303.404 461.624 298.726C475.726 294.325 486.894 283.675 496.955 272.87C513.469 255.136 528.531 236.107 541.995 215.965C551.069 202.393 559.513 188.072 563.528 172.25C571.196 142.006 560.641 107.881 537.222 87.2228C517.831 70.1117 491.79 62.6828 466.304 58.1762C443.066 54.0657 416.196 53.8104 394.804 43.0984C374.4 32.866 355.837 20.2572 334.445 11.5696Z"
          fill="#DFE4F5"
        />
        <g clipPath="url(#no_order_clip0)">
          <path
            d="M32.7868 324.658L29.7842 312.862C24.4603 310.615 19.0134 308.668 13.4693 307.032L13.0902 312.514L11.5529 306.474C4.67744 304.519 3.05176e-05 303.657 3.05176e-05 303.657C3.05176e-05 303.657 6.31813 327.588 19.5699 345.883L35.0111 348.585L23.0152 350.307C24.6829 352.306 26.475 354.197 28.3811 355.971C47.6587 373.794 69.1303 381.971 76.3393 374.236C83.5482 366.5 73.7647 345.781 54.4871 327.958C48.5109 322.433 41.0035 317.999 33.4886 314.509L32.7868 324.658Z"
            fill="#E6E6E6"
          />
          <path
            d="M61.3432 311.848L64.8944 300.204C61.5031 295.539 57.8504 291.069 53.9536 286.814L50.7842 291.311L52.6024 285.35C47.7315 280.136 44.1745 276.99 44.1745 276.99C44.1745 276.99 37.1641 300.729 39.0137 323.213L50.8301 333.475L39.6671 328.774C40.0577 331.343 40.6101 333.885 41.3212 336.385C48.5744 361.567 62.7114 379.622 72.8971 376.712C83.0829 373.801 85.4601 351.027 78.207 325.845C75.9584 318.039 71.8327 310.378 67.2106 303.521L61.3432 311.848Z"
            fill="#E6E6E6"
          />
          <path d="M500 378.464H0.0448761V379.72H500V378.464Z" fill="#3F3D56" />
          <path
            d="M79.0345 248.342C79.0345 248.342 75.1411 294.97 74.3052 304.962C73.7319 311.225 72.3264 317.385 70.1254 323.28C70.1254 323.28 68.4535 326.61 68.4535 329.941V372.405C68.4535 372.405 65.1098 372.405 65.9457 375.735C66.7817 379.066 67.6176 384.894 67.6176 384.894H80.1568C80.1568 384.894 79.3208 382.396 81.8286 381.564C84.3365 380.731 81.8286 374.07 81.8286 374.07L95.2037 324.112L115.266 282.481C115.266 282.481 126.97 319.949 129.477 324.112C129.477 324.112 138.673 370.739 140.345 374.903C142.017 379.066 142.853 379.066 142.017 380.731C141.181 382.396 141.181 384.061 142.017 384.894C142.853 385.727 158.735 384.894 158.735 384.894L154.556 325.778L147.032 255.004L108.579 243.347L79.0345 248.342Z"
            fill="#2F2E41"
          />
          <path
            d="M76.813 378.233C76.813 378.233 72.6333 363.246 65.9457 374.903C59.2582 386.559 59.2582 390.722 59.2582 390.722C59.2582 390.722 48.3909 406.542 63.4379 404.877C78.4849 403.212 75.1411 395.718 75.1411 395.718C75.1411 395.718 80.9927 392.388 80.9927 389.89C80.9927 387.392 76.813 378.233 76.813 378.233Z"
            fill="#2F2E41"
          />
          <path
            d="M144.524 376.568C144.524 376.568 148.704 361.58 155.392 373.237C162.079 384.894 162.079 389.057 162.079 389.057C162.079 389.057 172.946 404.877 157.9 403.212C142.853 401.547 146.196 394.053 146.196 394.053C146.196 394.053 140.345 390.722 140.345 388.225C140.345 385.727 144.524 376.568 144.524 376.568Z"
            fill="#2F2E41"
          />
          <path
            d="M105.235 135.938C105.235 135.938 106.907 146.763 101.891 149.261C96.8756 151.758 112.759 160.917 116.938 160.917C121.118 160.917 128.641 152.591 128.641 152.591C128.641 152.591 124.462 140.102 125.298 137.604L105.235 135.938Z"
            fill="#A0616A"
          />
          <path
            d="M114.43 144.265C124.126 144.265 131.985 136.436 131.985 126.78C131.985 117.123 124.126 109.294 114.43 109.294C104.735 109.294 96.8756 117.123 96.8756 126.78C96.8756 136.436 104.735 144.265 114.43 144.265Z"
            fill="#A0616A"
          />
          <path
            d="M148.704 162.583L127.806 149.972C127.806 149.972 115.916 163.415 103.052 148.428L80.9927 154.256L84.3365 210.875C84.3365 210.875 79.3208 233.356 80.9927 237.519C82.6646 241.682 76.813 243.348 78.4849 244.18C80.1568 245.013 78.4849 250.841 78.4849 250.841C78.4849 250.841 111.087 267.494 147.032 255.004L143.688 244.18C144.144 242.765 144.229 241.258 143.936 239.801C143.644 238.344 142.983 236.986 142.017 235.854C142.017 235.854 145.36 230.858 141.181 226.695C141.181 226.695 142.853 219.201 138.673 215.871L137.837 204.214L140.345 198.386L148.704 162.583Z"
            fill="#CFCCE0"
          />
          <path
            d="M105.446 118.661C105.446 118.661 103.354 123.172 111.725 121.368C111.725 121.368 114.864 123.172 114.864 120.465C114.864 120.465 119.05 124.977 120.097 122.27C121.143 119.563 123.236 123.172 123.236 123.172L125.329 118.661L127.422 121.368H134.747C134.747 121.368 136.84 103.322 111.725 105.127C86.6104 106.931 98.9655 135.522 98.9655 135.522C98.9655 135.522 99.1677 130.39 101.261 133.097C103.354 135.804 105.446 118.661 105.446 118.661Z"
            fill="#2F2E41"
          />
          <path
            d="M156.228 195.888L157.064 213.373C157.064 213.373 160.407 245.845 153.72 260V267.494C153.72 267.494 152.048 286.644 143.688 285.811C135.329 284.979 146.196 258.335 146.196 258.335L144.524 221.699L142.853 200.883L156.228 195.888Z"
            fill="#A0616A"
          />
          <path
            d="M139.509 163.415L147.848 162.066C153.855 167.814 157.947 175.26 159.571 183.398C162.079 196.72 162.915 199.218 162.915 199.218L142.853 204.214L131.985 183.398L139.509 163.415Z"
            fill="#CFCCE0"
          />
          <path
            d="M235.523 153.913H103.604V219.185H235.523V153.913Z"
            fill="white"
          />
          <path
            d="M118.009 157.838C114.619 155.808 110.533 155.963 108.979 156.118C109.571 157.557 111.346 161.227 114.735 163.258C118.133 165.293 122.214 165.133 123.765 164.979C123.174 163.541 121.398 159.87 118.009 157.838Z"
            fill="#1641DB"
          />
          <path
            d="M133.934 198.121H112.752C112.126 198.12 111.505 198.242 110.926 198.48C110.348 198.719 109.822 199.068 109.378 199.509C108.935 199.95 108.584 200.474 108.344 201.05C108.104 201.626 107.981 202.244 107.981 202.868C107.981 203.492 108.104 204.109 108.344 204.686C108.584 205.262 108.935 205.785 109.378 206.226C109.822 206.667 110.348 207.017 110.926 207.255C111.505 207.493 112.126 207.616 112.752 207.615H133.934C134.561 207.616 135.181 207.493 135.76 207.255C136.339 207.017 136.865 206.667 137.308 206.226C137.751 205.785 138.103 205.262 138.342 204.686C138.582 204.109 138.706 203.492 138.706 202.868C138.706 202.244 138.582 201.626 138.342 201.05C138.103 200.474 137.751 199.95 137.308 199.509C136.865 199.068 136.339 198.719 135.76 198.48C135.181 198.242 134.561 198.12 133.934 198.121Z"
            fill="#1641DB"
          />
          <path
            d="M229.856 148.011H111.092C109.711 148.011 108.343 148.283 107.066 148.809C105.79 149.336 104.63 150.108 103.653 151.081C102.676 152.054 101.901 153.209 101.373 154.48C100.844 155.751 100.572 157.114 100.572 158.49V216.125C100.572 218.904 101.68 221.569 103.653 223.534C105.626 225.499 108.302 226.603 111.092 226.603H229.856C232.646 226.603 235.322 225.499 237.295 223.534C239.268 221.569 240.377 218.904 240.377 216.125V158.49C240.377 157.114 240.105 155.751 239.576 154.48C239.047 153.209 238.272 152.054 237.295 151.081C236.318 150.108 235.159 149.336 233.882 148.809C232.606 148.283 231.238 148.011 229.856 148.011ZM108.154 155.16C108.388 155.117 113.95 154.175 118.555 156.935C123.159 159.694 124.929 165.03 125.001 165.255L125.188 165.831L124.59 165.936C123.738 166.069 122.876 166.132 122.013 166.125C119.276 166.177 116.574 165.499 114.189 164.161C109.584 161.402 107.815 156.066 107.743 155.84L107.556 155.265L108.154 155.16ZM133.934 208.67H112.752C111.207 208.67 109.725 208.058 108.633 206.97C107.54 205.882 106.927 204.406 106.927 202.868C106.927 201.329 107.54 199.853 108.633 198.765C109.725 197.677 111.207 197.065 112.752 197.065H133.934C135.479 197.065 136.961 197.677 138.053 198.765C139.146 199.853 139.76 201.329 139.76 202.868C139.76 204.406 139.146 205.882 138.053 206.97C136.961 208.058 135.479 208.67 133.934 208.67ZM230.315 207.615C230.31 209.292 229.639 210.899 228.448 212.085C227.258 213.271 225.644 213.939 223.96 213.944H208.073C206.389 213.939 204.776 213.271 203.585 212.085C202.395 210.899 201.723 209.292 201.719 207.615V198.12C201.723 196.443 202.395 194.836 203.585 193.65C204.776 192.464 206.389 191.796 208.073 191.791H223.96C225.644 191.796 227.258 192.464 228.448 193.65C229.639 194.836 230.31 196.443 230.315 198.12V207.615Z"
            fill="#1641DB"
          />
          <path
            d="M213.104 205.769H206.22C205.728 205.769 205.257 205.963 204.909 206.31C204.562 206.656 204.366 207.125 204.366 207.615C204.366 208.105 204.562 208.574 204.909 208.92C205.257 209.267 205.728 209.461 206.22 209.461H213.104C213.596 209.461 214.067 209.267 214.415 208.92C214.762 208.574 214.958 208.105 214.958 207.615C214.958 207.125 214.762 206.656 214.415 206.31C214.067 205.963 213.596 205.769 213.104 205.769ZM213.104 208.406H206.22C206.115 208.406 206.012 208.386 205.915 208.346C205.819 208.307 205.731 208.249 205.657 208.175C205.583 208.102 205.524 208.014 205.484 207.918C205.444 207.822 205.423 207.719 205.423 207.615C205.423 207.511 205.444 207.408 205.484 207.312C205.524 207.216 205.583 207.128 205.657 207.055C205.731 206.981 205.819 206.923 205.915 206.883C206.012 206.844 206.115 206.823 206.22 206.824H213.104C213.314 206.824 213.516 206.908 213.664 207.056C213.813 207.205 213.896 207.405 213.896 207.615C213.896 207.824 213.813 208.025 213.664 208.174C213.516 208.322 213.314 208.406 213.104 208.406Z"
            fill="#1641DB"
          />
          <path
            d="M225.814 205.769H218.929C218.438 205.769 217.966 205.963 217.619 206.31C217.271 206.656 217.076 207.125 217.076 207.615C217.076 208.105 217.271 208.574 217.619 208.92C217.966 209.267 218.438 209.461 218.929 209.461H225.814C226.305 209.461 226.777 209.267 227.124 208.92C227.472 208.574 227.667 208.105 227.667 207.615C227.667 207.125 227.472 206.656 227.124 206.31C226.777 205.963 226.305 205.769 225.814 205.769ZM225.814 208.406H218.929C218.825 208.406 218.721 208.386 218.625 208.346C218.528 208.307 218.44 208.249 218.366 208.175C218.292 208.102 218.234 208.014 218.193 207.918C218.153 207.822 218.133 207.719 218.133 207.615C218.133 207.511 218.153 207.408 218.193 207.312C218.234 207.216 218.292 207.128 218.366 207.055C218.44 206.981 218.528 206.923 218.625 206.883C218.721 206.844 218.825 206.823 218.929 206.824H225.814C226.024 206.824 226.225 206.908 226.374 207.056C226.522 207.205 226.606 207.405 226.606 207.615C226.606 207.824 226.522 208.025 226.374 208.174C226.225 208.322 226.024 208.406 225.814 208.406Z"
            fill="#1641DB"
          />
          <path
            d="M213.104 201.021H206.22C205.728 201.021 205.257 201.216 204.909 201.562C204.562 201.908 204.366 202.378 204.366 202.868C204.366 203.357 204.562 203.827 204.909 204.173C205.257 204.519 205.728 204.714 206.22 204.714H213.104C213.596 204.714 214.067 204.519 214.415 204.173C214.762 203.827 214.958 203.357 214.958 202.868C214.958 202.378 214.762 201.908 214.415 201.562C214.067 201.216 213.596 201.021 213.104 201.021ZM213.104 203.659H206.22C206.115 203.659 206.012 203.638 205.916 203.599C205.819 203.559 205.732 203.501 205.658 203.427C205.584 203.354 205.526 203.266 205.486 203.17C205.446 203.074 205.425 202.972 205.425 202.868C205.425 202.764 205.446 202.661 205.486 202.565C205.526 202.469 205.584 202.382 205.658 202.308C205.732 202.235 205.819 202.176 205.916 202.137C206.012 202.097 206.115 202.076 206.22 202.076H213.104C213.208 202.076 213.312 202.097 213.408 202.137C213.504 202.176 213.592 202.235 213.666 202.308C213.74 202.382 213.798 202.469 213.838 202.565C213.878 202.661 213.898 202.764 213.898 202.868C213.898 202.972 213.878 203.074 213.838 203.17C213.798 203.266 213.74 203.354 213.666 203.427C213.592 203.501 213.504 203.559 213.408 203.599C213.312 203.638 213.208 203.659 213.104 203.659Z"
            fill="#1641DB"
          />
          <path
            d="M225.814 201.021H218.929C218.438 201.021 217.966 201.216 217.619 201.562C217.271 201.908 217.076 202.378 217.076 202.868C217.076 203.357 217.271 203.827 217.619 204.173C217.966 204.519 218.438 204.714 218.929 204.714H225.814C226.305 204.714 226.777 204.519 227.124 204.173C227.472 203.827 227.667 203.357 227.667 202.868C227.667 202.378 227.472 201.908 227.124 201.562C226.777 201.216 226.305 201.021 225.814 201.021ZM225.814 203.659H218.929C218.825 203.659 218.722 203.638 218.625 203.599C218.529 203.559 218.441 203.501 218.368 203.427C218.294 203.354 218.235 203.266 218.195 203.17C218.156 203.074 218.135 202.972 218.135 202.868C218.135 202.764 218.156 202.661 218.195 202.565C218.235 202.469 218.294 202.382 218.368 202.308C218.441 202.235 218.529 202.176 218.625 202.137C218.722 202.097 218.825 202.076 218.929 202.076H225.814C225.918 202.076 226.021 202.097 226.118 202.137C226.214 202.176 226.302 202.235 226.375 202.308C226.449 202.382 226.508 202.469 226.547 202.565C226.587 202.661 226.608 202.764 226.608 202.868C226.608 202.972 226.587 203.074 226.547 203.17C226.508 203.266 226.449 203.354 226.375 203.427C226.302 203.501 226.214 203.559 226.118 203.599C226.021 203.638 225.918 203.659 225.814 203.659Z"
            fill="#1641DB"
          />
          <path
            d="M213.104 196.274H206.22C205.728 196.274 205.257 196.469 204.909 196.815C204.562 197.161 204.366 197.631 204.366 198.121C204.366 198.61 204.562 199.08 204.909 199.426C205.257 199.772 205.728 199.967 206.22 199.967H213.104C213.596 199.967 214.067 199.772 214.415 199.426C214.762 199.08 214.958 198.61 214.958 198.121C214.958 197.631 214.762 197.161 214.415 196.815C214.067 196.469 213.596 196.274 213.104 196.274ZM213.104 198.912H206.22C206.115 198.912 206.012 198.892 205.915 198.852C205.819 198.812 205.731 198.754 205.657 198.681C205.583 198.607 205.524 198.52 205.484 198.424C205.444 198.328 205.423 198.225 205.423 198.121C205.423 198.016 205.444 197.913 205.484 197.817C205.524 197.721 205.583 197.634 205.657 197.56C205.731 197.487 205.819 197.429 205.915 197.389C206.012 197.349 206.115 197.329 206.22 197.329H213.104C213.314 197.33 213.516 197.414 213.664 197.562C213.813 197.71 213.896 197.911 213.896 198.121C213.896 198.33 213.813 198.531 213.664 198.679C213.516 198.828 213.314 198.911 213.104 198.912Z"
            fill="#1641DB"
          />
          <path
            d="M225.814 196.274H218.929C218.438 196.274 217.966 196.469 217.619 196.815C217.271 197.161 217.076 197.631 217.076 198.121C217.076 198.61 217.271 199.08 217.619 199.426C217.966 199.772 218.438 199.967 218.929 199.967H225.814C226.305 199.967 226.777 199.772 227.124 199.426C227.472 199.08 227.667 198.61 227.667 198.121C227.667 197.631 227.472 197.161 227.124 196.815C226.777 196.469 226.305 196.274 225.814 196.274ZM225.814 198.912H218.929C218.825 198.912 218.721 198.892 218.625 198.852C218.528 198.812 218.44 198.754 218.366 198.681C218.292 198.607 218.234 198.52 218.193 198.424C218.153 198.328 218.133 198.225 218.133 198.121C218.133 198.016 218.153 197.913 218.193 197.817C218.234 197.721 218.292 197.634 218.366 197.56C218.44 197.487 218.528 197.429 218.625 197.389C218.721 197.349 218.825 197.329 218.929 197.329H225.814C226.024 197.33 226.225 197.414 226.374 197.562C226.522 197.71 226.606 197.911 226.606 198.121C226.606 198.33 226.522 198.531 226.374 198.679C226.225 198.828 226.024 198.911 225.814 198.912Z"
            fill="#1641DB"
          />
          <path
            d="M68.4536 185.896C68.4536 185.896 60.9301 217.536 70.1255 217.536C79.3208 217.536 101.055 181.733 101.055 181.733C101.055 181.733 123.626 170.076 114.43 165.913C105.235 161.75 93.5319 172.574 93.5319 172.574L80.028 191.248L79.3208 181.733L68.4536 185.896Z"
            fill="#A0616A"
          />
          <path
            d="M86.0084 159.252L80.9927 154.256C80.9927 154.256 70.9614 160.085 70.1254 165.913C69.2895 171.742 65.1098 190.059 65.1098 190.059L80.5747 191.308L85.5904 183.815L86.0084 159.252Z"
            fill="#CFCCE0"
          />
          <path
            d="M323.61 147.408C322.98 145.556 315.083 139.26 319.922 138.201L323.891 143.472L342.136 125.299L343.971 127.127L323.61 147.408Z"
            fill="#1641DB"
          />
          <path
            d="M323.61 209.202C322.98 207.35 315.083 201.054 319.922 199.996L323.891 205.266L342.136 187.094L343.971 188.922L323.61 209.202Z"
            fill="#1641DB"
          />
          <path
            d="M323.61 271.88C322.98 270.027 315.083 263.732 319.922 262.673L323.891 267.944L342.136 249.771L343.971 251.599L323.61 271.88Z"
            fill="#1641DB"
          />
          <path
            d="M378.874 145.501C381.984 145.501 384.505 142.991 384.505 139.894C384.505 136.797 381.984 134.286 378.874 134.286C375.765 134.286 373.244 136.797 373.244 139.894C373.244 142.991 375.765 145.501 378.874 145.501Z"
            fill="#3F3D56"
          />
          <path
            d="M490.632 139.333H399.987V140.454H490.632V139.333Z"
            fill="#3F3D56"
          />
          <path
            d="M378.874 207.296C381.984 207.296 384.505 204.785 384.505 201.688C384.505 198.591 381.984 196.08 378.874 196.08C375.765 196.08 373.244 198.591 373.244 201.688C373.244 204.785 375.765 207.296 378.874 207.296Z"
            fill="#3F3D56"
          />
          <path
            d="M490.632 201.127H399.987V202.249H490.632V201.127Z"
            fill="#3F3D56"
          />
          <path
            d="M378.874 269.973C381.984 269.973 384.505 267.462 384.505 264.365C384.505 261.268 381.984 258.758 378.874 258.758C375.765 258.758 373.244 261.268 373.244 264.365C373.244 267.462 375.765 269.973 378.874 269.973Z"
            fill="#3F3D56"
          />
          <path
            d="M490.632 263.804H399.987V264.926H490.632V263.804Z"
            fill="#3F3D56"
          />
          <path
            d="M337.83 280.235H309.679V251.635H328.822V252.757H310.805V279.114H336.704V266.216H337.83V280.235Z"
            fill="#3F3D56"
          />
          <path
            d="M337.83 217.428H309.679V188.828H328.822V189.95H310.805V216.306H336.704V203.408H337.83V217.428Z"
            fill="#3F3D56"
          />
          <path
            d="M337.83 153.499H309.679V124.899H328.822V126.021H310.805V152.377H336.704V139.479H337.83V153.499Z"
            fill="#3F3D56"
          />
        </g>
        <defs>
          <clipPath id="no_order_clip0">
            <rect
              width="500"
              height="300"
              fill="white"
              transform="translate(0 105)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default NoOrderFigma;
