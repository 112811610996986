import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const PickupIcon = ({ viewBox = "0 0 16 16" }) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "16px", height: "16px" }}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 1C8.66304 1 9.29893 1.26339 9.76777 1.73223C10.2366 2.20107 10.5 2.83696 10.5 3.5V4H5.5V3.5C5.5 2.83696 5.76339 2.20107 6.23223 1.73223C6.70107 1.26339 7.33696 1 8 1V1ZM11.5 4V3.5C11.5 2.57174 11.1313 1.6815 10.4749 1.02513C9.8185 0.368749 8.92826 0 8 0C7.07174 0 6.1815 0.368749 5.52513 1.02513C4.86875 1.6815 4.5 2.57174 4.5 3.5V4H1V14C1 14.5304 1.21071 15.0391 1.58579 15.4142C1.96086 15.7893 2.46957 16 3 16H13C13.5304 16 14.0391 15.7893 14.4142 15.4142C14.7893 15.0391 15 14.5304 15 14V4H11.5ZM2 5H14V14C14 14.2652 13.8946 14.5196 13.7071 14.7071C13.5196 14.8946 13.2652 15 13 15H3C2.73478 15 2.48043 14.8946 2.29289 14.7071C2.10536 14.5196 2 14.2652 2 14V5Z"
          fill="#1641DB"
        />
        <path
          d="M4 8.5C6.93825 11.0175 8.67488 11.2197 12 8.5"
          stroke="#1641DB"
        />
      </svg>
    </SvgIcon>
  );
};

export default PickupIcon;
