export const CREATE_PURCHASE = "CREATE_PURCHASE";
export const FETCH_PURCHASES = "FETCH_PURCHASES";
export const PURCHASE_PROGRESS = "PURCHASE_PROGRESS";
export const UPDATE_PURCHASE = "UPDATE_PURCHASE";
export const SET_PURCHASE_ERROR = "SET_PURCHASE_ERROR";
export const SET_PURCHASE_LOADER = "SET_PURCHASE_LOADER";
export const CLEAR_COMPLETE_PURCHASE = "CLEAR_COMPLETE_PURCHASE";
export const DELETE_PURCHASE = "DELETE_PURCHASE";
export const CLEAR_PURCHASE = "CLEAR_PURCHASE";
export const PURCHASE_DATE_SELECTOR = "PURCHASE_DATE_SELECTOR";
export const PURCHASE_STATE_SELECTOR = "PURCHASE_STATE_SELECTOR";
export const PURCHASE_STATUS_SELECTOR = "PURCHASE_STATUS_SELECTOR";
export const PURCHASE_DATE_RANGE = "PURCHASE_DATE_RANGE";
export const SET_PURCHASES_PER_PAGE = "SET_PURCHASES_PER_PAGE";
export const SET_PURCHASES_CURRENT_PAGE = "SET_PURCHASES_CURRENT_PAGE";
export const SET_PURCHASES_MAX_PAGE_SO_FAR = "SET_PURCHASES_MAX_PAGE_SO_FAR";
export const SET_PURCHASES_INPUT_KEYWORD = "SET_PURCHASES_INPUT_KEYWORD";
export const SET_PURCHASES_SEARCH_KEYWORD = "SET_PURCHASES_SEARCH_KEYWORD";
export const SET_PURCHASES_CUSTOMER_ID = "SET_PURCHASES_CUSTOMER_ID";
