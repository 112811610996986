import React from "react";

export default function CreateCoupon() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginTop: 8 }}
    >
      <path
        d="M21.8179 17.5H21.8178H9.27235C8.97097 17.5 8.68193 17.3803 8.46882 17.1671C8.25571 16.954 8.13599 16.665 8.13599 16.3636V7.63636C8.13599 7.33498 8.25571 7.04594 8.46882 6.83283C8.68193 6.61972 8.97097 6.5 9.27235 6.5H21.8173C21.8246 6.50003 21.8317 6.5018 21.8381 6.50517C21.8446 6.50856 21.8502 6.51346 21.8544 6.51946L21.8549 6.52019L25.6729 11.9745C25.6729 11.9745 25.673 11.9746 25.673 11.9746C25.6783 11.9822 25.6811 11.9913 25.6811 12.0005C25.6811 12.0098 25.6783 12.0189 25.673 12.0265C25.673 12.0265 25.6729 12.0265 25.6729 12.0266L21.8551 17.4806C21.855 17.4807 21.855 17.4807 21.855 17.4808C21.8508 17.4867 21.8453 17.4915 21.8388 17.4949C21.8324 17.4982 21.8252 17.5 21.8179 17.5Z"
        stroke="white"
      />
      <path
        d="M4.36333 11.5H4.36325C4.35595 11.5 4.34876 11.4982 4.34227 11.4949L4.11155 11.9384L4.34227 11.4948C4.33579 11.4915 4.33022 11.4866 4.32603 11.4806L0.508202 6.02658C0.508184 6.02655 0.508167 6.02653 0.508149 6.0265C0.502844 6.01888 0.5 6.00982 0.5 6.00054C0.5 5.99124 0.502847 5.98218 0.508159 5.97455C0.508173 5.97453 0.508188 5.97451 0.508203 5.97449L4.32622 0.520186L4.32673 0.519454C4.33091 0.513456 4.33648 0.508555 4.34296 0.505168C4.3494 0.501803 4.35655 0.500031 4.36381 0.5H16.9088C17.2101 0.5 17.4992 0.619723 17.7123 0.832832C17.9254 1.04594 18.0451 1.33498 18.0451 1.63636V10.3636C18.0451 10.665 17.9254 10.954 17.7123 11.1671C17.4992 11.3803 17.2101 11.5 16.9088 11.5H4.36333Z"
        stroke="white"
      />
    </svg>
  );
}
