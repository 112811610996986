import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const TwoCardIcon = () => {
  return (
    <SvgIcon viewBox={"0 0 36 36"} style={{ width: 36, height: 36 }}>
      <rect
        x="0.5"
        y="0.5"
        width="35"
        height="35"
        rx="0.5"
        fill="white"
        stroke="#999999"
      />
      <rect
        x="2.08789"
        y="29.9072"
        width="15.1304"
        height="3.32308"
        rx="1.6"
        fill="#C4C4C4"
      />
      <rect
        x="18.7832"
        y="29.9072"
        width="15.1304"
        height="3.32308"
        rx="1.6"
        fill="#C4C4C4"
      />
      <rect
        x="3.13086"
        y="19.9385"
        width="12.5217"
        height="1.10769"
        rx="0.553846"
        fill="#C4C4C4"
      />
      <rect
        x="20.3477"
        y="19.9385"
        width="12.5217"
        height="1.10769"
        rx="0.553846"
        fill="#C4C4C4"
      />
      <rect
        x="3.13086"
        y="22.708"
        width="8.34783"
        height="1.10769"
        rx="0.553846"
        fill="#C4C4C4"
      />
      <rect
        x="20.3477"
        y="22.708"
        width="8.34783"
        height="1.10769"
        rx="0.553846"
        fill="#C4C4C4"
      />
      <rect
        x="2.08789"
        y="2.76953"
        width="15.1304"
        height="13.8462"
        rx="1.6"
        fill="#8994FF"
      />
      <rect
        x="18.7832"
        y="2.76953"
        width="15.1304"
        height="13.8462"
        rx="1.6"
        fill="#8994FF"
      />
    </SvgIcon>
  );
};

export default TwoCardIcon;
