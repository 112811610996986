// Core react imports
import React from "react";
// Mui imports
import { Typography } from "@material-ui/core";
// Importing styles
import useStyles from "./styles";
// Exporting component
export default function ReportTableHead() {
  const classes = useStyles();
  return (
    <div className={classes.reportHead}>
      <Typography
        className={classes.reportHeadColumn}
        style={{ flexBasis: "25%" }}
      >
        Report
      </Typography>
      <Typography
        className={classes.reportHeadColumn}
        style={{ flexBasis: "25%" }}
      >
        Duration
      </Typography>
      <Typography
        className={classes.reportHeadColumn}
        style={{ flexBasis: "30%" }}
      >
        Report Generated On
      </Typography>
      <Typography
        className={classes.reportHeadColumn}
        style={{ flexBasis: "20%" }}
      >
        Action
      </Typography>
    </div>
  );
}
