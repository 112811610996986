import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const SettingsIcon = ({
  viewBox = "0 0 9 12",
  stroke = "#999999",
  fill = "#999999",
}) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "9px", height: "12px" }}>
      <svg
        width="9"
        height="12"
        viewBox={viewBox}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.86388 7.91079L4.16943 11.1644C4.25771 11.2509 4.37639 11.2994 4.49999 11.2994C4.6236 11.2994 4.74227 11.2509 4.83055 11.1644L8.1361 7.91079C8.22565 7.82312 8.2767 7.70347 8.27803 7.57816C8.27936 7.45285 8.23085 7.33215 8.14319 7.2426C8.05552 7.15305 7.93587 7.102 7.81055 7.10067C7.68524 7.09934 7.56454 7.14785 7.47499 7.23552L4.97221 9.69579V0.888848C4.97221 0.763607 4.92246 0.643495 4.8339 0.554937C4.74534 0.466378 4.62523 0.416626 4.49999 0.416626C4.37475 0.416626 4.25464 0.466378 4.16608 0.554937C4.07752 0.643495 4.02777 0.763607 4.02777 0.888848V9.69579L1.52499 7.23552C1.43544 7.14785 1.31474 7.09934 1.18943 7.10067C1.06412 7.102 0.944465 7.15305 0.856796 7.2426C0.769127 7.33215 0.720622 7.45285 0.72195 7.57816C0.723279 7.70347 0.774332 7.82312 0.86388 7.91079Z"
          fill="#1641DB"
          stroke="#1641DB"
          strokeWidth="0.2"
        />
      </svg>
    </SvgIcon>
  );
};

export default SettingsIcon;
