import React, { useEffect, useState } from "react";
import useStyles from "../components/ConfigStyles";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchApps, getPluginConfig } from "../../../redux/apps/appActions";
import PageLoader from "../../../components/Layout/PageLoader";
import Toast from "../../../components/Layout/Toast";
import { centerContainer, layoutCenterContainer } from "../../../styles/common";
import HeaderBreadcrumbs from "../../../components/common/HeaderBreadcrumbs";
import { Button, Paper, Typography } from "@material-ui/core";
import Controls from "../../../components/controls/Controls";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import { copyToClipboard } from "../../../utils/utils";

const list = [
  {
    label: "App Store",
    link: "/apps",
  },
  {
    label: "Google Marchant Center",
    link: "/apps/gmc",
  },
  {
    label: "Configure",
  },
];

const GmcConfig = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const store = useSelector((state) => state.store.store);
  const storeData = useSelector((state) => state.store);
  const appState = useSelector((state) => state.apps);
  const apps = appState.apps;

  const initialState = {
    googleId: {
      value: "",
      touched: false,
      error: "",
    },
  };

  const [formState, setFormState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (apps.length === 0) {
      dispatch(fetchApps());
    }
    // eslint-disable-next-line
  }, [apps.length]);

  useEffect(() => {
    if (storeData.fetched) {
      fetchData();
    }

    // eslint-disable-next-line
  }, [appState.gmc, storeData]);

  const fetchData = async () => {
    if (!Object.keys(appState.gmc).length) {
      dispatch(getPluginConfig("gmc"));
    }

    let formData = {};

    for (const key of Object.keys(appState.gmc)) {
      formData[key] = {
        value: appState.gmc[key],
        touched: false,
        error: "",
      };
    }

    setFormState((prevState) => ({
      ...prevState,
      ...formData,
    }));

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API}/api/v1/org/stores/${store.slug}/seo-settings/metas/google-site-verification`
      );

      if (!response.ok) {
        throw new Error("Error fetching data. Please try again.");
      } else {
        const result = await response.json();
        setFormState((prevState) => ({
          ...prevState,
          googleId: {
            ...formState.googleId,
            value: result.payload.content,
          },
        }));
      }
    } catch (error) {
      setError(error.message);
    }
    setLoading(false);
  };

  const handleChnage = (event) => {
    let { name, value } = event.target;
    let cloneData = formState[name];
    cloneData.touched = true;
    cloneData.value = value;
    setFormState({ ...formState, [name]: cloneData });
  };

  const onBlur = (event) => {
    const { name } = event.target;
    const cloneData = formState[name];
    if (!cloneData.value)
      cloneData.error =
        name === "googleId"
          ? "Missing Google Verification ID. Please enter a valid ID to proceed."
          : "";

    setFormState((prevState) => ({ ...prevState, [name]: cloneData }));
  };

  const onFocus = (event) => {
    const { name } = event.target;
    let cloneData = formState[name];
    cloneData.touched = true;
    cloneData.error = "";
    setFormState((prevState) => ({ ...prevState, [name]: cloneData }));
  };

  const handleCopyToClipboard = (text) => () => {
    const result = copyToClipboard(text);
    if (!result) {
      setError("Could not copy to clipboard.");
    } else {
      setSuccess("Copied to clipboard.");
    }
  };

  const validatation = () => {
    let errorFlag = false;
    Object.keys(formState).forEach((key) => {
      let cloneData = formState[key];
      if (!cloneData.value) {
        errorFlag = true;
        cloneData.error =
          key === "googleId"
            ? "Missing Google Verification ID. Please enter a valid ID to proceed."
            : "";
      }
      setFormState({ ...formState, [key]: cloneData });
    });
    return errorFlag;
  };

  const handleOutlined = () => {
    history.goBack();
  };

  const handleSave = async () => {
    let isError = validatation();
    if (isError) return;

    let accessToken = localStorage.getItem("access_token");

    const data = {
      content: formState.googleId.value,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API}/api/v1/org/stores/${store.slug}/seo-settings/metas/google-site-verification`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      const result = await response.json();

      if (result) {
        setSuccess("Google Site Verification ID saved successfully.");
      }
    } catch (error) {
      setError(error.message);
    }
  };

  if (loading || !storeData.fetched) return <PageLoader />;

  return (
    <div className={layoutCenterContainer}>
      <div className={centerContainer}>
        <div className={classes.breadcrumbsContainer}>
          <div className={classes.backDiv}>
            <HeaderBreadcrumbs list={list} />
          </div>
        </div>
        <Paper elevation={0} className={classes.paper}>
          <div>
            <Typography className={classes.formHead}>
              Google Marchant Center Configuration Settings
            </Typography>
            <Controls.Input
              name="googleId"
              label="Google Site Verification ID"
              labelPlacement="top"
              value={formState.googleId.value}
              onChange={handleChnage}
              onFocus={onFocus}
              onBlur={onBlur}
              error={formState.googleId.error}
              helperText={
                !formState.googleId.error &&
                "Enter your Google Verification ID (e.g., 'abc123def456ghi') for site ownership."
              }
              extraSpace={!!formState.googleId.error}
            />

            <div className={classes.detailsContainer}>
              <p className={classes.infoText}>
                In Google Merchant Centre Account, Go to Feeds Section. Click on
                Add Primary Feed and select Scheduled Fetch.
              </p>
              <div>
                <div className={classes.copyLabel}> Product Feed URL</div>
                <div className={classes.detailField}>
                  <div className={classes.promotionTeaser}>
                    {formState?.product_feed_url?.value}
                  </div>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.copyButton}
                    onClick={handleCopyToClipboard(
                      formState?.product_feed_url?.value
                    )}
                  >
                    Copy
                  </Button>
                </div>
                <p className={classes.helpText}>
                  Paste this URL in the in File URL Input Field and click Create
                  Feed.
                </p>
              </div>
            </div>
          </div>

          <div className={classes.button}>
            <CombinedButtons
              outlinedBtnText="Back"
              outlinedBtnAction={handleOutlined}
              solidBtnAction={handleSave}
            />
          </div>
        </Paper>
      </div>

      <Toast
        autoClose
        message={success}
        open={!!success}
        close={() => setSuccess(false)}
      />
      <Toast
        autoClose
        message={error}
        open={!!error}
        close={() => setError(false)}
        severity="error"
      />
    </div>
  );
};

export default GmcConfig;
