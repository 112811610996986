import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// Mui imports
import { Modal, Paper, Grid, Box, Typography, Button } from "@material-ui/core";
import { useStyles } from "../../styles";
// Custom component imports
import CartDetail from "./CartDetail";
import ModalHeader from "./ModalHeader";
import ModalForm from "./ModalForm";
// Util methods
import { gstinStateCodes } from "../RenewalData/utils";
import { updateStore } from "../../../../redux/store/storeActions";
import { calculateCartTotal } from "../../utils";
import { getCityAndState } from "../../../../utils/customerUtils";
import { findDomains } from "../../../../utils/domainUtils";
// Exporting default component
export default function SubscriptionModal(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const store = useSelector((state) => state.store.store);

  const [searchKey, setSearchKey] = useState("");
  const [domainList, setDomainList] = useState([]);
  const [timeoutId, setTimeoutId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [addonList, setAddonList] = useState([]);
  const [total, setTotal] = useState(props.currentSelectedPlan.amount / 100);
  const [addressForm, setAddressForm] = useState({
    street: { value: "", touched: false, error: "", isRequired: true },
    street2: { value: "", touched: false, error: "", isRequired: false },
    pincode: { value: "", touched: false, error: "", isRequired: true },
    city: { value: "", touched: false, error: "", isRequired: true },
    state: { value: "", touched: false, error: "", isRequired: true },
    business_name: { value: "", touched: false, error: "", isRequired: true },
    gstin: { value: "", touched: false, error: "", isRequired: false },
  });
  const stage = props.stage;
  const setStage = props.setStage;
  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const searchKeyword = params.get("key") ? params.get("key") : "";
  const calculateAddonTotalAmount = () =>
    addonList.reduce((total, addon) => total + addon.amount / 100, 0);

  const calculateTotalPrice = () => {
    const planAmount = props.currentSelectedPlan.amount / 100 || 0;
    const addonsAmount = calculateAddonTotalAmount();
    const price = selectedDomain ? selectedDomain.purchasePrice * 90 : 0;

    return planAmount + addonsAmount + price;
  };

  const calculateTotalTaxAmount = (totalPrice) => {
    return Math.floor((totalPrice * 18) / 100);
  };
  const EmpryPlanaddon = props.currentSelectedPlan.plan_add_ons.length;
  const calculateTotalAmount = () => {
    const totalPrice = calculateTotalPrice();
    const totalTaxAmount = calculateTotalTaxAmount(totalPrice);

    return Math.floor(totalPrice + totalTaxAmount);
  };
  const totalPrice = calculateTotalPrice();
  const totalTaxAmount = calculateTotalTaxAmount(totalPrice);
  const totalAmount = calculateTotalAmount();
  useEffect(() => {
    if (searchKeyword !== "") {
      handleDomainSearch({ target: { value: searchKeyword } });
    }
  }, [searchKeyword]);

  useEffect(() => {
    if (props.hydrateState) {
      setSelectedDomain(props.paymentProps?.selectedDomain || null);
      setAddonList(props.paymentProps?.addons || []);
      setTotal(
        calculateCartTotal(
          props.currentSelectedPlan,
          props.paymentProps?.selectedDomain || null,
          props.paymentProps?.addons || []
        )
      );
    }

    setAddressForm({
      street: {
        value: store.street,
        touched: false,
        error: "",
        isRequired: true,
      },
      business_name: {
        value: store.business_name,
        touched: false,
        error: "",
        isRequired: true,
      },
      gstin: {
        value: store.gstin,
        touched: false,
        error: "",
        isRequired: false,
      },
      street2: {
        value: store.street2,
        touched: false,
        error: "",
        isRequired: false,
      },
      pincode: {
        value: store.pincode,
        touched: false,
        error: "",
        isRequired: true,
      },
      city: { value: store.city, touched: false, error: "", isRequired: true },
      state: {
        value: store.state,
        touched: false,
        error: "",
        isRequired: true,
      },
    });
    // eslint-disable-next-line
  }, [store]);

  const handleAddonSelection = (addon) => {
    let updatedList = [...addonList, addon];
    setAddonList(updatedList);
    setTotal(
      calculateCartTotal(props.currentSelectedPlan, selectedDomain, updatedList)
    );
  };

  const handleDomainSelect = (domain) => {
    setSelectedDomain(domain);
    setTotal(calculateCartTotal(props.currentSelectedPlan, domain, addonList));
  };

  const fetchDomains = async (keyword) => {
    setLoading(true);
    let domains = await findDomains(keyword);
    setDomainList(domains);
    setLoading(false);
  };

  const handleDomainSearch = (e) => {
    setLoading(true);
    setSearchKey(e.target.value);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    let newTimeout = setTimeout(() => fetchDomains(e.target.value), 1000);
    setTimeoutId(newTimeout);
  };
  const handleContinueWithoutDomain = () => {
    if (selectedDomain) {
      return;
    }
    if (props.currentSelectedPlan.plan_add_ons.length > 0) {
      setStage(1);
    } else if (props.currentSelectedPlan.plan_add_ons.length === 0) {
      setStage(2);
    } else {
      props.handlePaymentFlow(
        props.currentSelectedPlan,
        selectedDomain,
        addonList
      );
    }
  };

  const handlePrimaryButton = async () => {
    let hasNextStage = false;

    // Handle stage 0
    if (stage === 0) {
      if (props.currentSelectedPlan.plan_add_ons.length > 0) {
        setStage(1);
        return;
      } else {
        setStage(2);
        return;
      }
    }

    // Handle stage 1
    if (stage === 1) {
      if (!store.pincode) {
        hasNextStage = true;
      } else {
        setStage(2);
        return;
      }
    }

    // Handle stage 2
    if (stage === 2) {
      let formHasError = false;
      let addressFormStateClone = { ...addressForm };

      Object.keys(addressFormStateClone).forEach((key) => {
        const field = addressFormStateClone[key];

        if (field.isRequired && !field.value) {
          field.error = "This field is required.";
          formHasError = true;
        } else if (key === "gstin") {
          const gstinValue = field.value;

          if (gstinValue) {
            // Check GSTIN length
            if (gstinValue.length !== 15) {
              field.error = "GSTIN must be 15 characters long.";
              formHasError = true;
            } else {
              // Check if GSTIN matches the state code
              const stateCode = gstinValue.substring(0, 2);
              const stateEntry = gstinStateCodes.find(
                (entry) => entry.state === addressFormStateClone.state.value
              );

              if (!stateEntry || stateEntry.code !== stateCode) {
                field.error = "GST number does not match the selected state.";
                formHasError = true;
              } else {
                field.error = "";
              }
            }
          } else {
            field.error = ""; // No error if GSTIN is empty
          }
        } else {
          field.error = "";
        }

        addressFormStateClone[key] = field;
      });

      if (formHasError) {
        setAddressForm(addressFormStateClone);
        return;
      } else {
        await dispatch(
          updateStore({
            street: addressForm.street.value,
            street2: addressForm.street2.value,
            gstin: addressForm.gstin.value,
            city: addressForm.city.value,
            state: addressForm.state.value,
            pincode: addressForm.pincode.value,
            business_name: addressForm.business_name.value,
          })
        );
      }
    }

    // If there is a next stage, move to the next stage
    if (hasNextStage) {
      setStage(stage + 1);
    } else {
      props.handlePaymentFlow(
        props.currentSelectedPlan,
        selectedDomain,
        addonList
      );
    }
  };

  const handleRemoveAddon = (idx) => {
    let updatedList = addonList.filter((_, index) => index !== idx);
    setAddonList(updatedList);
    setTotal(
      calculateCartTotal(props.currentSelectedPlan, selectedDomain, updatedList)
    );
  };
  const handleAddressFormFieldChange = async (field, value) => {
    let formStateClone = { ...addressForm };
    let fieldObjectClone = { ...formStateClone[field] };

    fieldObjectClone.value = value;
    if (field === "pincode") {
      if (value.length === 6) {
        try {
          const addressData = await getCityAndState(value);
          console.log("Fetched address data:", addressData);
          if (addressData) {
            formStateClone = {
              ...formStateClone,
              city: {
                ...formStateClone.city,
                value: addressData.data.city || "",
                error: "",
              },
              state: {
                ...formStateClone.state,
                value: addressData.data.state || "",
                error: "",
              },
            };
          }
        } catch (error) {
          console.error("Error fetching city and state:", error);
          formStateClone = {
            ...formStateClone,
            city: {
              ...formStateClone.city,
              value: "",
              error: "Error fetching city.",
            },
            state: {
              ...formStateClone.state,
              value: "",
              error: "Error fetching state.",
            },
          };
        }
      } else {
        // Clear city and state if pincode length is not 6
        formStateClone = {
          ...formStateClone,
          city: { ...formStateClone.city, value: "", error: "" },
          state: { ...formStateClone.state, value: "", error: "" },
        };
      }
    }

    // Validation logic for required fields and GSTIN
    if (fieldObjectClone.isRequired) {
      if (!value) {
        fieldObjectClone.error = "This field is required.";
      } else if (field === "gstin") {
        if (value.length < 15) {
          fieldObjectClone.error = "GSTIN must be 15 characters long.";
        } else {
          const stateCode = value.substring(0, 2);
          const stateEntry = gstinStateCodes.find(
            (entry) => entry.state === formStateClone.state.value
          );

          if (!stateEntry || stateEntry.code !== stateCode) {
            fieldObjectClone.error =
              "GST number does not match the selected state.";
          } else {
            fieldObjectClone.error = "";
          }
        }
      } else {
        fieldObjectClone.error = "";
      }
    } else {
      // If the field is not required, clear the error when the field is empty
      if (!value) {
        fieldObjectClone.error = "";
      } else {
        fieldObjectClone.error = "";
      }
    }

    formStateClone[field] = fieldObjectClone;
    setAddressForm(formStateClone);
  };

  return (
    <Modal
      open={props.isOpen}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Paper className={classes.modalBody}>
        <ModalHeader
          stage={stage}
          setStage={setStage}
          close={props.close}
          EmpryPlanaddon={EmpryPlanaddon}
        />
        <Grid
          container
          style={{
            flexDirection: stage === 2 ? "column" : "row",
          }}
        >
          <Grid
            item
            xs={stage === 2 ? 12 : 6}
            className={[
              classes.modalColumn,
              stage === 2 ? classes.addressColumn : classes.leftColumn,
            ].join(" ")}
          >
            <ModalForm
              addonList={addonList}
              domainList={domainList}
              handleAddonSelection={handleAddonSelection}
              handleDomainSearch={handleDomainSearch}
              handleDomainSelect={handleDomainSelect}
              handleContinueWithoutDomain={handleContinueWithoutDomain}
              currentSelectedPlan={props.currentSelectedPlan}
              stage={stage}
              loading={loading}
              searchKey={searchKey}
              selectedDomain={selectedDomain}
              addressForm={addressForm}
              handleAddressFormFieldChange={handleAddressFormFieldChange}
            />
          </Grid>

          <Grid
            item
            xs={stage === 2 ? 12 : 6}
            className={classes.modalColumn}
            style={{ textAlign: "center" }}
          >
            <Box style={{ height: "calc(100% - 71px)" }}>
              {stage !== 2 && (
                <>
                  <Typography className={classes.cart_detail_head}>
                    Checkout
                  </Typography>
                  <CartDetail
                    currentSelectedPlan={props.currentSelectedPlan}
                    addonList={addonList}
                    selectedDomain={selectedDomain}
                    handleRemoveAddon={handleRemoveAddon}
                    handleDomainSelect={handleDomainSelect}
                    subtotal={totalPrice}
                    taxAmount={totalTaxAmount}
                    totalAmount={totalAmount}
                    stage={stage}
                  />
                </>
              )}
            </Box>
            <Button
              variant="contained"
              color="secondary"
              className={classes.continueBtn}
              style={{
                width: stage === 2 ? "30%" : "100%",
                textAlign: "center",
              }}
              onClick={handlePrimaryButton}
            >
              {stage === 0 && "Continue"}
              {stage === 1 && "Continue"}
              {stage === 2 && "Proceed To Pay"}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}
