export const reorder = (list, startIndex, endIndex, field) => {
  const items = Array.from(list);
  const [removed] = items.splice(startIndex, 1);
  items.splice(endIndex, 0, removed);
  let change = {};
  for (let index = 0; index < items.length; index++) {
    change[items[index][field]] = items.length - index;
  }
  return { items, change };
};

export const generateSlug = (title) => {
  const trimmedTitle = title.trim(); // Trim any leading/trailing spaces
  const lowercased = trimmedTitle.toLowerCase(); // Convert title to lowercase
  const slug = lowercased
    .replace(/[^\w\s-]/g, "") // Remove non-word characters except spaces and hyphens
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/-{2,}/g, "-") // Replace multiple consecutive hyphens with a single hyphen
    .substring(0, 50) // Limit the slug length to 60 characters
    .replace(/-+$/, ""); // Remove trailing hyphens at the end of the slug

  return slug;
};

export const convertToSlug = (value) => {
  return value
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "")
    .replace(/-{2,}/g, "-");
};
