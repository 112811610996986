import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { rowFlexAlignCenter } from "../../styles/common";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "10px",
    background: "#E7E7E7",
    borderRadius: 8,
    marginBottom: 16,
  },
  img: {
    height: 36,
    marginRight: 16,
  },
  headingTxt: {
    fontSize: 14,
    fontWeight: 600,
  },
  subHeadingTxt: {
    fontSize: 12,
    marginTop: 2,
  },
  orderIdsTxt: {
    fontSize: 12,
    color: "#1641DB",
    fontWeight: 600,
    marginTop: 4,
  },
}));

const AlertBar = ({ icon, heading, subHeading, orderIds, backgroundColor }) => {
  const classes = useStyles();
  return (
    <div className={classes.root} style={{ background: backgroundColor }}>
      <div className={rowFlexAlignCenter} style={{ alignItems: "flex-start" }}>
        {icon}
        <div style={{ marginLeft: 10 }}>
          {heading && (
            <Typography className={classes.headingTxt}>{heading}</Typography>
          )}
          {subHeading && (
            <Typography className={classes.subHeadingTxt}>
              {subHeading}
            </Typography>
          )}
          {orderIds && (
            <Typography className={classes.orderIdsTxt}>{orderIds}</Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export default AlertBar;
