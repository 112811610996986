import React from "react";
const ClockIcon = ({fill = "#1a1a1a"}) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 0C3.13791 0 0 3.13778 0 7C0 10.8622 3.13778 14 7 14C10.8622 14 14 10.8622 14 7C14 3.13778 10.8622 0 7 0ZM7 0.636364C10.5185 0.636364 13.3636 3.4817 13.3636 7C13.3636 10.5183 10.5183 13.3636 7 13.3636C3.4817 13.3636 0.636364 10.5183 0.636364 7C0.636364 3.4817 3.4817 0.636364 7 0.636364Z"
        fill={fill}
      />
      <path
        d="M6.99982 1.90918C6.91543 1.90918 6.8345 1.9427 6.77483 2.00237C6.71516 2.06204 6.68163 2.14297 6.68163 2.22736V6.86836L4.22941 9.32059C4.16975 9.38026 4.13623 9.46118 4.13623 9.54556C4.13623 9.62994 4.16975 9.71086 4.22941 9.77053C4.28907 9.83019 4.37 9.8637 4.45438 9.8637C4.53875 9.8637 4.61968 9.83019 4.67935 9.77053L7.2248 7.22508C7.28447 7.16541 7.318 7.08449 7.31802 7.00011V2.22738C7.31802 2.143 7.2845 2.06207 7.22483 2.0024C7.16516 1.94272 7.08422 1.9092 6.99984 1.9092L6.99982 1.90918Z"
        fill={fill}
      />
    </svg>
  );
};

export default ClockIcon;
