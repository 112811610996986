import React, { useEffect, useState } from "react";
import { Chip } from "@material-ui/core";
import BulbIcon from "../../icons/BulbIcon";
import Controls from "../../components/controls/Controls";
import {
  fetchStoreDetails,
  postRechargeRequest,
  razorPayWallet,
  rechargeRequest,
} from "./components/WalletUtils";
import SolidButton from "../../components/buttons/SolidButton";
import { InfoOutlined } from "@material-ui/icons";
import PageLoader from "../../components/Layout/PageLoader";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from "react-router-dom";
import RechargeHeader from "./components/RechargeHeader";
import Toast from "../../components/Layout/Toast";
import { useStylesWalletRecharge } from "./components/WalletStyle";

export default function WalletRecharge() {
  const classes = useStylesWalletRecharge();
  const history = useHistory();
  const { slug } = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const mode = query.get("mode");

  const [storeData, setStoreData] = useState(null);
  const [rechargeAmount, setRechargeAmount] = useState(0);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [amountError, setAmountError] = useState(null);
  const [progress, setProgress] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchStoreDetails(slug).then((response) => {
      if (response.error) {
        // handle error
      } else {
        setStoreData(response);
        setProgress(false);
      }
    });
  }, [slug]);

  const handleChipClick = (amount) => {
    setSelectedAmount(amount);
    setRechargeAmount(amount);
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    if (!value || value.match(/^\d*$/)) {
      const intValue = value ? parseInt(value) : "";
      setRechargeAmount(intValue);
      setSelectedAmount(intValue);
    }
  };

  const onBlur = () => {
    const amount = Number(rechargeAmount);

    if (!rechargeAmount || amount < 50 || amount > 10000) {
      const errorMessage =
        !rechargeAmount || amount < 50
          ? "Minimum recharge amount is ₹50. Please increase your amount."
          : "Maximum recharge amount is ₹10,000. Please decrease your amount.";

      setAmountError(errorMessage);
    } else {
      setAmountError("");
    }
  };

  const onFocus = () => {
    setAmountError(null);
  };

  const onPaymentSuccess = (requestId, payment) => {
    setProgress(true);
    postRechargeRequest(
      {
        recharge_request_id: requestId,
        payment: payment,
      },
      storeData.id
    )
      .then((response) => {
        if (response) {
          history.push(`/${slug}/recharge-wallet/${requestId}/thank-you`);
        }
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const handleMakePayment = () => {
    const amount = Number(rechargeAmount);

    if (!rechargeAmount || amount < 50 || amount > 10000) {
      const errorMessage =
        !rechargeAmount || amount < 50
          ? "Minimum recharge amount is ₹50. Please increase your amount."
          : "Maximum recharge amount is ₹10,000. Please decrease your amount.";

      setAmountError(errorMessage);
      return;
    }

    setLoading(true);
    rechargeRequest(rechargeAmount, storeData.id)
      .then((rechargeReq) =>
        razorPayWallet(rechargeReq, onPaymentSuccess, setLoading, mode)
      )
      .catch((error) => setError(error.message));
  };

  if (progress)
    return (
      <div className={classes.loader}>
        <PageLoader />
      </div>
    );

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <script src="https://checkout.razorpay.com/v1/checkout.js"></script>
      </Helmet>
      <RechargeHeader />

      <div className={classes.nonHeader}>
        <div className={classes.card}>
          <div style={{ display: "flex", flexDirection: "column", gap: 12 }}>
            <div className={classes.addCreditText}>Add Credits</div>
            <div className={classes.storeSection}>
              <img
                src={storeData.logo}
                alt="logo"
                style={{ height: 72, width: 72, objectFit: "contain" }}
              />
              <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
                <div className={classes.storeName}>{storeData.name}</div>

                <a
                  href={
                    storeData.domain.endsWith("shoopy.in")
                      ? `https://${storeData.domain}/${storeData.slug}`
                      : `https://${storeData.domain}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", wordBreak: "break-all" }}
                >
                  {storeData.domain.endsWith("shoopy.in")
                    ? `https://${storeData.domain}/${storeData.slug}`
                    : `https://${storeData.domain}`}
                </a>
              </div>
            </div>
            <div className={classes.infoDiv}>
              <BulbIcon />
              <div>
                <b>₹1 = 3 </b>Credit points. Min recharge amount <b>₹50</b> .
              </div>
            </div>
            <Controls.Input
              name="amount"
              label="Enter Amount"
              labelPlacement="top"
              labelFontWeight={400}
              startAdornment={"₹"}
              value={rechargeAmount}
              onBlur={onBlur}
              onFocus={onFocus}
              onChange={handleInputChange}
              error={!!amountError}
              helperText={
                amountError
                  ? amountError
                  : `${
                      rechargeAmount * 3 || 0
                    } points will be credited in your wallet.`
              }
              extraSpace={!!amountError}
            />
            <div className={classes.chipContainer}>
              {["500", "800", "1000", "2000"].map((amount) => (
                <Chip
                  key={amount}
                  className={classes.chip}
                  label={`₹${amount}`}
                  onClick={() => handleChipClick(amount)}
                  clickable
                  style={{
                    border: "1px solid  #E1E1E1",
                    backgroundColor:
                      selectedAmount === amount ? "#1641DB" : "white",
                    color: selectedAmount === amount ? "white" : "#1A1A1A",
                  }}
                />
              ))}
            </div>
            <div style={{ display: "flex", gap: 5 }}>
              <InfoOutlined color="secondary" />
              <a
                href="https://www.shoopy.in/fair-use-policy"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.knowMoreText}
                style={{ textDecoration: "none" }}
              >
                Know more about credits
              </a>
            </div>
          </div>

          <div className={classes.solidButton}>
            <SolidButton
              loading={loading}
              onClick={handleMakePayment}
              style={{ width: "100%" }}
            >
              Make Payment
            </SolidButton>
          </div>
        </div>
      </div>

      <Toast open={!!error} message={error} close={setError} severity="error" />
    </div>
  );
}
