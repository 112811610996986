// Core react imports
import React from "react";
// Mui imports
import { SvgIcon } from "@material-ui/core";
// Exporting icon component
export default function InfoIcon({ style = {}, onClick }) {
  return (
    <SvgIcon
      viewBox="0 0 15 15"
      style={{
        height: "15px",
        width: "15px",
        cursor: onClick ? "pointer" : "auto",
        ...style,
      }}
      onClick={() => {
        onClick && onClick();
      }}
    >
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="7.5" cy="7.5" r="7.5" fill="white" />
        <path
          d="M7.5 0.0625C3.39277 0.0625 0.0625 3.39277 0.0625 7.5C0.0625 11.6072 3.39277 14.9375 7.5 14.9375C11.6072 14.9375 14.9375 11.6072 14.9375 7.5C14.9375 3.39277 11.6072 0.0625 7.5 0.0625ZM7.5 13.6758C4.09004 13.6758 1.32422 10.91 1.32422 7.5C1.32422 4.09004 4.09004 1.32422 7.5 1.32422C10.91 1.32422 13.6758 4.09004 13.6758 7.5C13.6758 10.91 10.91 13.6758 7.5 13.6758Z"
          fill="#1641DB"
        />
        <path
          d="M6.70312 4.57812C6.70313 4.78947 6.78708 4.99216 6.93652 5.1416C7.08597 5.29104 7.28866 5.375 7.5 5.375C7.71134 5.375 7.91403 5.29104 8.06348 5.1416C8.21292 4.99216 8.29688 4.78947 8.29688 4.57812C8.29688 4.36678 8.21292 4.16409 8.06348 4.01465C7.91403 3.86521 7.71134 3.78125 7.5 3.78125C7.28866 3.78125 7.08597 3.86521 6.93652 4.01465C6.78708 4.16409 6.70313 4.36678 6.70312 4.57812ZM7.89844 6.4375H7.10156C7.02852 6.4375 6.96875 6.49727 6.96875 6.57031V11.0859C6.96875 11.159 7.02852 11.2188 7.10156 11.2188H7.89844C7.97148 11.2188 8.03125 11.159 8.03125 11.0859V6.57031C8.03125 6.49727 7.97148 6.4375 7.89844 6.4375Z"
          fill="#1641DB"
        />
      </svg>
    </SvgIcon>
  );
}
