import { makeStyles } from "@material-ui/styles";
export default makeStyles((theme) => ({
  heading: {
    fontWeight: 700,
    fontSize: 24,
    lineHeight: "32px",
    color: "#1A1A1A",
  },
  addInvoiceContainer: {
    background: "white",
    boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.04)",
    borderRadius: 6,
    padding: 32,
    position: "relative",
  },
  bottomSheet: {
    background: "#FAFAFA",
    bottom: 0,
    left: 0,
    width: "100%",
    height: 64,
    display: "flex",
    justifyContent: "flex-end",
    boxShadow: "0px -4px 16px rgba(229, 229, 229, 0.63)",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    padding: 16,
    alignItems: "center",
  },
  tosBox: {
    border: "1px dashed #BEC7EB",
    borderRadius: 6,
    width: "100%",
    alignItems: "center",
    marginTop: 8,
    padding: 8,
    background: "#FFFFFF",
  },
  tosHead: {
    color: "#999999",
    fontWeight: 600,
    fontSize: 12,
  },
  tosBody: {
    color: "#999999",
    fontWeight: 400,
    fontSize: 12,
  },
  paymentSelector: {
    background: "#1641DB",
    color: "white",
    borderRadius: 6,
    height: 48,
    width: 141,
    textTransform: "none",
  },
  tableHeader: {
    display: "flex",
    marginLeft: theme.spacing(3.25),
    marginRight: theme.spacing(4),
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  search: {
    display: "flex",
    border: "1px solid #E6E6E6",
    borderRadius: 6,
    padding: "12px",
    paddingTop: 9,
    height: "44px",
    maxWidth: 410,
    marginRight: "16px",
    flex: 1,
    minWidth: 0,
  },
  searchIcon: {
    height: "100%",
  },
  searchBox: {
    outlineWidth: 0,
    lineHeight: "16px",
    marginLeft: "12px",
    border: "none",
    fontSize: "12px",
    flex: 1,
    minWidth: 0,
    marginTop: "3px",
  },
  button: {
    borderRadius: "6px",
    border: "1px solid #1641DB",
    backgroundColor: "rgba(255,255,255,0.1)",
    marginLeft: "24px",
    color: "#1641DB",
    boxShadow: "none",
    height: "40px",
    width: "100px",
    padding: "6px 8px",
  },
  disabledButton: {
    border: "1px solid #eeeeee",
  },
  buttonlabel: {
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "16px",
    textTransform: "none",
  },
  tablehead: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px -1px 0px #E5E5E5, 0px 1px 0px #E6E6E6",
    borderTop: "1px solid #E6E6E6",
  },
  seltablehead: {
    backgroundColor: "#FFFFFF",
    // boxShadow:
    //   "0px 3px 4px rgba(0, 0, 0, 0.08), 0px -1px 0px rgba(0, 0, 0, 0.15)",
    borderTop: "1px solid #E6E6E6",
    borderBottom: "1px solid #E6E6E6",
  },
  delBtn: {
    background: "#ED0000",
    color: "white",
    borderRadius: 6,
    height: 48,
    fontWeight: 600,
    lineHeight: "24px",
    fontSize: 16,
    padding: 16,
    textTransform: "none",
    width: 163,
  },
  modal: {
    width: "570px",
    marginLeft: "auto",
    top: "0px",
    marginRight: "0px",
    zIndex: 3,
  },
  row: {
    "& td": {
      fontWeight: 600,
      paddingLeft: theme.spacing(2.75),
      fontSize: 14,
      lineHeight: "20px",
      color: "#666666",
    },
  },
  prodimage: {
    height: "60px",
    width: "60px",
    borderRadius: "6px",
  },
  nopicctn: {
    border: "1px solid #E5E5E5",
    boxSizing: "border-box",
    borderRadius: "6px",
    width: 60,
    height: 60,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tableHeaderRight: {
    display: "flex",
    alignItems: "center",
  },
  dateFilter: {
    fontWeight: 400,
    fontSize: 12,
    color: "#666666",
    padding: 10,
    paddingBottom: "4px",
    alignItems: "center",
  },
  statusSelector: {
    background: "white",
    color: "#666666",
    fontSize: 12,
    borderRadius: 6,
    height: 44,
    width: 100,
    marginLeft: 20,
    border: "1px solid #666666",
    textTransform: "none",
  },
  customWidth: {
    "& div": {
      width: 101,
    },
  },
  customSearchWidth: {
    "& div": {
      width: 300,
    },
  },
  dividerGrid: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "space-between",
    paddingTop: 24,
    paddingBottom: 18,
    borderBottom: "1px solid #E6E6E6",
    flexWrap: "wrap",
  },
  subHeading: {
    fontWeight: 600,
    fontSize: 14,
    color: "#1A1A1A",
    paddingTop: 6,
  },
  inputBox: {
    width: "372px",
    height: "32px",
    borderRadius: "6px",
    border: "1px solid #999999",
    padding: "0px 4px",
    outlineColor: "#1641DB",
    fontSize: 14,
  },
  rupee: {
    "& p": {
      fontSize: 12,
      lineHeight: "16px",
    },
  },
  saleprice: {
    marginLeft: 35,
    width: 410,
  },
  accholder: {
    marginLeft: 27,
    width: 356,
  },
  pymtrec: {
    marginLeft: 12,
    width: 356,
  },
  accnumber: {
    marginLeft: 11,
    width: 367,
  },
  accifsc: {
    marginLeft: 45,
    width: 422,
  },
  accbank: {
    marginLeft: 36,
    width: 378,
  },
  label: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginRight: 0,
    width: "100%",
  },
  taxbtn: {
    fontSize: 12,
    textTransform: "capitalize",
    borderRadius: 12,
    width: "64px",
    padding: 0,
    border: "1px solid #1641DB",
  },
  labelinput: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  menuitem: {
    fontSize: 12,
    color: "#1a1a1a",
  },
  placeholder: {
    fontSize: 12,
    color: "#999999",
  },
  taxcontrol: {
    width: 372,
    marginLeft: 100,
  },
  namecontrol: {
    width: 379,
    marginLeft: 36,
  },
  shipRocketControl: {
    width: 349,
    marginLeft: 13,
  },
  accholderlabel: {
    width: "24%",
  },
  reclabel: {
    width: "26%",
  },
  noItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid rgba(224, 224, 224, .5)",
    height: 62,
    fontSize: 12,
    fontWeight: 600,
    color: "#555555",
    borderTop: "none",
  },
  text: {
    fontSize: "12px",
    lineHeight: "20px",
    fontWeight: 600,
    letterSpacing: "0.02em",
    textAlign: "center",
    color: "#1641DB",
  },
  grouped: {
    minWidth: "24px",
  },
  groupedroot: {
    border: "1px solid",
    borderColor: "#1641DB",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 6,
    height: 25,
  },
  textsizesmall: {
    padding: "1px 0px",
  },
  focusedBtn: {
    fontWeight: 700,
    background: "#FAFAFA",
  },
  focusedTopBtn: {
    fontWeight: 700,
    background: "#FAFAFA",
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  focusedBottomBtn: {
    fontWeight: 700,
    background: "#FAFAFA",
    borderBottomLeftRadius: 6,
    borderBottomightRadius: 6,
  },
  searchPlaceholder: {
    "&::placeholder": {
      fontWeight: 600,
      color: "#1641DB",
      opacity: 0.9,
    },
  },
  applyBtn: {
    background: "#1641DB",
    color: "white",
    opacity: 0.95,
  },
  endicon: {
    marginLeft: 6,
  },
  managebtn: {
    color: "#1641DB",
    marginRight: 24,
    border: "1px solid #1641DB",
    borderRadius: 6,
    width: 188,
    fontSize: 16,
    padding: 12,
    fontWeight: 600,
    lineHeight: "24px",
    textTransform: "none",
    "&:hover": {
      color: "#1641DB",
      background: "#ffffff",
    },
  },
  flexBox: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "space-between",
  },
  tempelateHolder: {
    width: 100,
    height: 142,
    padding: 8,
    border: "1px solid #CCCCCC",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    borderRadius: 6,
    cursor: "pointer",
    "&:hover": {
      border: "1px solid #1641DB",
    },
  },
  innerTempHolder: {
    width: "100%",
    height: "100%",
    border: "1px solid #F5F7FC",
    borderRadius: 6,
  },
  timeBtns: {
    borderRadius: 19,
    border: "1px solid #999999",
    background: "white",
    color: "#1A1A1A",
    marginRight: 12,
    marginTop: 12,
    padding: "4px 11px",
    textTransform: "none",
    fontSize: 12,
  },
  selectedTimeBtns: {
    borderRadius: 19,
    border: "1px solid #1641DB",
    color: "white",
    background: "#1641DB",
    marginRight: 12,
    padding: "4px 11px",
    marginTop: 12,
    fontSize: 12,
    textTransform: "none",
    "&:hover": {
      color: "white",
      background: "#1641DB",
    },
  },
  refid: {
    marginLeft: 23,
    width: 383,
  },
  noteRec: {
    marginLeft: 34,
    width: 419,
  },
  amtRec: {
    marginLeft: 27,
    width: 414,
  },
  bulkActionBtn: {
    background: "transparent",
    textTransform: "none",
    fontSize: 14,
    fontWeight: 600,
    border: "1px solid",
    borderRadius: 6,
    padding: "6px 22px",
    "&:hover": {
      background: "transparent",
    },
  },
  menuActionBtn: {
    background: "#FFFFFF !important",
    textTransform: "none",
    fontSize: 12,
    fontWeight: 500,
    padding: "8px 12px 8px 10px",
    display: "flex",
    alignItems: "center",
    transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "&:hover": {
      background: "#F5F7FC !important",
    },
  },
  statusRow: {
    marginLeft: "26px",
    marginRight: "32px",
    display: "flex",
    marginBottom: "16px",
  },
  dateText: {
    color: "#999",
    fontSize: "14px",
    lineHeight: "18px",
  },
  breadcrumbLink: {
    cursor: "pointer",
    textDecoration: "underline",
    "&:hover": {
      color: "#1641DB",
    },
  },
  upgradeLink: {
    cursor: "pointer",
    color: "#1641DB",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  iconStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1.5px solid #3D5FDB",
    width: 30,
    height: 30,
    borderRadius: "50%",
    marginLeft: 14,
    cursor: "pointer",
  },
  dateTimeInfo: {
    width: "377px",
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    background: "#EFEFFF",
    padding: "12px",
    marginTop: "8px",
  },
  shipBtn: {
    padding: "8px",
    borderRadius: "4px",
    background: "#F9F9F9",
    border: "1px solid #F9F9F9",
    width: "100%",
    "&:hover": {
      background: "#F9F9F9",
    },
    "&:focus": {
      outline: "none !important",
    },
  },
  activeShipBtn: {
    padding: "8px",
    borderRadius: "4px",
    background: "#F5F6FF",
    border: "1px solid #1641DB",
    width: "100%",
    "&:hover": {
      background: "#F5F6FF",
    },
    "&:focus": {
      outline: "none !important",
    },
  },
  delBoyCont: {
    padding: "8px 14px",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #E1E1E1",
    justifyContent: "space-between",
    cursor: "pointer",
    "&:hover": {
      background: "#F9F9F9",
    },
    "&:last-child": {
      borderBottom: "none",
    },
  },
  delBoyIcon: {
    width: "35px",
    height: "35px",
    borderRadius: "50%",
    background: "#F6F6F6",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "18px",
  },
  clickableHeading: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 500,
    marginBottom: "16px",
    color: "#1641DB",
    cursor: "pointer",
  },
  bottomStickyBtnRoot: {
    height: 84,
    position: "sticky",
    background: "#ffffff",
    zIndex: 2,
    bottom: 0,
    marginTop: 24,
  },
  bottomStickyBtnCont: {
    position: "absolute",
    bottom: 0,
    right: 0,
    padding: "25px 0px",
  },
  sideOrdDetail: {
    padding: "16px",
    background: "#F9F9F9",
    borderRadius: "4px",
    height: "100%",
  },
  tooltip: {
    border: "1px solid #1641DB",
    backgroundColor: "#F9F9F9",
    color: "#1a1a1a",
    padding: 10,
    width: 205,
    fontSize: 12,
  },
  arrow: {
    color: "#1641DB !important",
  },
  actionBtn: {
    width: 92,
    minWidth: 92,
    height: 32,
    outline: "none",
    textTransform: "none",
    fontSize: 12,
    padding: 8,
    "&:focus": {
      outline: "none !important",
    },
  },
  disabledActionBtn: {
    border: "1px solid rgba(0, 0, 0, 0.23) !important",
  },
  shake: {
    animation: "$shake 0.2s ease-in-out 0s 3",
  },
  "@keyframes shake": {
    "0%": {
      marginLeft: "0rem",
    },
    "25%": {
      marginLeft: "0.5rem",
    },
    "20%": {
      transform: "translate(-3px, 0px) rotate(1deg)",
    },
    "75%": {
      marginLeft: "-0.5rem",
    },
    "100%": {
      marginLeft: "0rem",
    },
  },
  dashedTextField: {
    borderStyle: "dashed",
  },
  rootDashedTextField: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderStyle: "solid !important",
      },
    },
  },
  inputDashedTextField: {
    fontSize: "12px !important",
  },
  customeInfoToast: {
    position: "absolute",
    padding: "8px",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.04)",
    top: 10,
    left: 0,
    borderRadius: "4px",
    border: "1px solid #F87231",
    textAlign: "center",
    background: "#FFFFFF",
  },
  stickyTableColumn: {
    position: "sticky",
    left: 0,
    zIndex: 2,
  },
  selectimg: {
    width: 24,
    height: 24,
    marginRight: 8,
    objectFit: "contain",
  },
  orderDetailHead: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: "15px",
  },
  orderDetailText: {
    fontSize: 14,
  },
  whiteLayover: {
    pointerEvents: "none",
    opacity: 0.4,
  },
}));
