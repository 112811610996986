import React, { useEffect } from "react";
import SmallDiamond from "../../../icons/SmallDiamond";
import useStyles from "./styles";
import { rowFlexJustifyBetween } from "../../../styles/common";
import ChargeDetails from "./ChargeDetails";
import { Typography } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { planCustomProps } from "../../plans/constants";
import { fetchPlans } from "../../../redux/store/storeActions";
import ChargeRow from "./ChargeRow";
import { getDay } from "../../../utils/dateUtils";

const PlanChargeSummary = ({ planInfo, allowpayment }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const plans = useSelector((state) => state.store.plans);

  useEffect(() => {
    dispatch(fetchPlans());
  }, []);

  const planTitles = ["FREE", "BASIC", "BUSINESS_APP_PRO", "BUSINESS"];

  // Get the plan index
  const planIndex = planTitles.findIndex((title) => title === planInfo.plan);

  // Calculate the plan amount
  const planAmount =
    planIndex >= 0 && planIndex < plans.length
      ? Math.floor(plans[planIndex].renewal_amount / 100)
      : 0;

  // Calculate tax amount as 18% of planAmount
  const taxAmount = Math.floor(planAmount * 0.18);

  // Calculate total amount including tax
  const totalAmount = planAmount + taxAmount;
  const now = getDay(new Date());
  const renewalDate = getDay(new Date(planInfo.renew_date));
  const dueIn = Math.floor(
    (renewalDate.getTime() - now.getTime()) / (1000 * 3600 * 24)
  );

  return (
    <div className={classes.plansummary}>
      <div>
        <div className={rowFlexJustifyBetween}>
          <Typography className={classes.primary}>Current Plan</Typography>
          <div
            className={classes.planstatus}
            style={{
              background: dueIn < 0 ? "#FF4C4C" : "#0AAF51", // Red for expired, green for active
            }}
          >
            {dueIn < 0 ? " Plan Expired" : "Active"}
          </div>
        </div>
        <ChargeDetails
          diamond={
            <SmallDiamond {...planCustomProps[planInfo.plan].diamondProps} />
          }
          title={planInfo.plan_title}
          shortDescription={planInfo.description}
          amount={planAmount}
          term="yearly"
        />
        <hr style={{ marginTop: 20, marginBottom: 20 }}></hr>
        {allowpayment && (
          <>
            <Typography className={classes.primary}>Charge Summary</Typography>
            <ChargeRow label="Sub Total (Without Tax)" amount={planAmount} />
            <hr />
            <ChargeRow label="Tax (18%)" amount={taxAmount} />
            <hr />
            <ChargeRow label="Total" amount={totalAmount} />
          </>
        )}
      </div>
    </div>
  );
};

export default PlanChargeSummary;
