import { Paper } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import {
  getStoreAttribute,
  getStoreAttributes,
  getStoreIdFromUrl,
  updateAttributes,
} from "../../../utils/storeUtil";
import { v4 as uuidv4 } from "uuid";
import { useSelector, useDispatch } from "react-redux";
import { fetchStore } from "../../../redux/store/storeActions";
import ConfirmModal from "../../../components/common/ConfirmModal";
import Toast from "../../../components/Layout/Toast";
import { fetchApp } from "../../../redux/apps/appActions";
import { layoutCenterContainer } from "../../../styles/common";
import HeaderBreadcrumbs from "../../../components/common/HeaderBreadcrumbs";
import useStyles from "../common/styles";
import PageLoader from "../../../components/Layout/PageLoader";
import ConfigurationDetails from "./components/ConfigurationDetails";
import ConfiguredDetails from "./components/ConfiguredDetails";

const list = [
  {
    label: "App Store",
    link: "/apps",
  },
  {
    label: "Razorpay Payment Gateway",
    link: `/apps/razorpay`,
  },
  {
    label: "Configure",
  },
];

const RazorpayConnect = () => {
  const classes = useStyles();
  const [configured, setConfigured] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isShowSteps, setIsShowSteps] = useState(true);
  const [activateRazorpaySwitch, setActivateRazorpaySwitch] = useState(false);
  const [affordabilitySwitch, setaffordabilitySwitch] = useState(false);
  const [showSecondSwitchConfirm, setShowSecondSwitchConfirm] = useState(false);
  const [pendingSecondSwitchValue, setPendingSecondSwitchValue] =
    useState(false);
  const [steps, setSteps] = useState([]);
  const [loading, setLoading] = useState(true);
  const [toast, setToast] = useState(false);
  const [affordabilityCheakbox, setAffordabilityCheakbox] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);
  const storeId = getStoreIdFromUrl();
  const store = useSelector((state) => state.store.store);
  const dispatch = useDispatch();

  const handleCheckboxChange = (event) => {
    setAffordabilityCheakbox(event.target.checked);
  };

  const razorpaySettingDone = async (e) => {
    const isCheckedValue = e.target.checked;
    setIsChecked(isCheckedValue);
    if (!isCheckedValue) {
      const payload = [
        { name: "razor_pay", value: "false" },
        { name: "razorpay_affordability_widget", value: "false" },
      ];
      await updateAttributes(store.slug, "payment_settings", payload);
      setActivateRazorpaySwitch(false);
      setaffordabilitySwitch(false);
    }
  };

  const handleRazorpaySwitch = async (e) => {
    const newValue = e.target.checked;
    setActivateRazorpaySwitch(newValue);
    if (!newValue) {
      setaffordabilitySwitch(false);
      let payload = [
        { name: "razor_pay", value: "false" },
        { name: "razorpay_affordability_widget", value: "false" },
      ];
      await updateAttributes(store.slug, "payment_settings", payload);
    } else {
      let payload = [{ name: "razor_pay", value: "true" }];
      await updateAttributes(store.slug, "payment_settings", payload);
    }
  };

  const handleAffordBilitychange = async (e) => {
    const newValue = e.target.checked;
    if (newValue) {
      setPendingSecondSwitchValue(newValue);
      setShowSecondSwitchConfirm(true);
    } else {
      setAffordabilityCheakbox(false);
      setaffordabilitySwitch(newValue);
      if (activateRazorpaySwitch) {
        let payload = [
          { name: "razorpay_affordability_widget", value: "false" },
        ];
        await updateAttributes(store.slug, "payment_settings", payload);
      }
    }
  };
  const onConfirmSecondSwitch = async () => {
    setaffordabilitySwitch(pendingSecondSwitchValue);
    if (activateRazorpaySwitch) {
      let payload = [
        {
          name: "razorpay_affordability_widget",
          value: "true",
        },
      ];
      await updateAttributes(store.slug, "payment_settings", payload);
    }
    setShowSecondSwitchConfirm(false);
  };

  const onCancelSecondSwitch = () => {
    setPendingSecondSwitchValue(false);
    setaffordabilitySwitch(false);
    setShowSecondSwitchConfirm(false);
  };

  const sendToRazorpay = () => {
    let url = createOAuthUrl();
    window.location = url;
  };

  const getBoolean = (value) => {
    if (!value) return false;
    return value === "true" || value === true ? true : false;
  };

  const getSteps = (domain) => {
    return [
      { label: "Go To Razorpay Dashboard" },
      { label: "Navigate to My Account, then Profile" },
      {
        label: "View Business Website/App Field",
      },

      {
        label: "Ensure field has domain",
        url: `https://${domain}`,
      },
    ];
  };

  const AffordabilityWidget = (domain) => {
    return [
      { label: "Login to your Razorpay panel." },
      { label: " Navigate to the Affordability option in the left menu." },
      {
        label: "Activate the Affordability Widget.",
      },
      {
        label:
          "Request Affordability Widget from the Razorpay team if not available.",
      },
    ];
  };

  useEffect(() => {
    if (Object.keys(store).length > 0) {
      if (store && store.domain) {
        setSteps(getSteps(store.domain));
      }
      fetchApp("razorpay").then((app) => {
        if (app.configured) {
          setConfigured(app.configured);
          getStoreAttributes(store.slug, "payment_settings")
            .then((res) => {
              if (res?.razor_pay) {
                setLoading(false);
                setIsChecked(true);
                // setIsShowSteps(false);
                let isFirstEnabled = getBoolean(res?.razor_pay);
                if (isFirstEnabled) {
                  setActivateRazorpaySwitch(true);
                  setApiKey(res?.razorpay_live_key ?? "");
                  if (getBoolean(res?.razorpay_affordability_widget)) {
                    setaffordabilitySwitch(true);
                  }
                }
              } else {
                setLoading(false);
              }
            })
            .catch((error) => {
              setLoading(false);
            });
        } else {
          setLoading(false);
        }
      });
    } else {
      dispatch(fetchStore());
    }
    // eslint-disable-next-line
  }, [store]);

  const createOAuthUrl = () => {
    let url = process.env.REACT_APP_SELF;
    let state = uuidv4();
    let hash = storeId + state;
    let callback = `${url}/apps/razorpay/auth`;
    sessionStorage.setItem("state_token", hash);
    sessionStorage.setItem("storeid", storeId);
    let clientId = process.env.REACT_APP_RAZORPAY_CLIENT_ID;
    return `https://auth.razorpay.com/authorize?client_id=${clientId}&response_type=code&redirect_uri=${callback}&scope=read_write&state=${state}`;
  };

  const handleSaveKey = async () => {
    let payload = [
      {
        name: "razorpay_live_key",
        value: apiKey,
      },
    ];
    await updateAttributes(store.slug, "payment_settings", payload);
    setBtnDisabled(true);
    setToast(true);
  };

  if (loading) return <PageLoader />;

  return (
    <div className={layoutCenterContainer}>
      <div style={{ width: "54%" }}>
        <div className={classes.breadcrumbsContainer}>
          <div className={classes.backDiv}>
            <HeaderBreadcrumbs list={list} />
          </div>
        </div>
        <Paper elevation={0} className={classes.paper}>
          {configured ? (
            <ConfiguredDetails
              steps={steps}
              isChecked={isChecked}
              activateRazorpaySwitch={activateRazorpaySwitch}
              affordabilitySwitch={affordabilitySwitch}
              handleAffordBilitychange={handleAffordBilitychange}
              razorpaySettingDone={razorpaySettingDone}
              handleRazorpaySwitch={handleRazorpaySwitch}
              isShowSteps={isShowSteps}
              apiKey={apiKey}
              setApiKey={setApiKey}
              btnDisabled={btnDisabled}
              setBtnDisabled={setBtnDisabled}
              handleSaveKey={handleSaveKey}
            />
          ) : (
            <ConfigurationDetails sendToRazorpay={sendToRazorpay} />
          )}
        </Paper>
      </div>

      <ConfirmModal
        open={showSecondSwitchConfirm}
        message="You are about to enable the Razorpay Affordability Widget. Do you want to proceed?"
        closeBtnText="Cancel"
        confirmBtnText="Activate"
        onClose={onCancelSecondSwitch}
        onConfirm={onConfirmSecondSwitch}
        solidBtnStyle={{ backgroundColor: "blue", color: "white" }}
        disabled={!affordabilityCheakbox}
        combinedButtons={13}
      >
        <div>
          <h5 style={{ fontWeight: "500", marginBottom: "25px" }}>
            Activate Affordability Widget
          </h5>
          <div style={{ marginTop: "-7px" }}>
            {AffordabilityWidget().map((step, index) => (
              <p key={index} style={{ marginBottom: "6px" }} variant="h6">
                {" "}
                {index + 1}.{" "}
                <span style={{ marginLeft: "5px" }}>{step.label}</span>
              </p>
            ))}
          </div>

          <p
            style={{
              display: "flex",
              alignItems: "center",
              margin: 0,
              paddingTop: "10px",
            }}
          >
            <input
              id="affordability-widget-checkbox"
              type="checkbox"
              onChange={handleCheckboxChange}
              style={{ marginRight: "10px", height: 16, width: 16 }}
            />
            <label
              htmlFor="affordability-widget-checkbox"
              style={{ margin: 0 }}
            >
              I have activated the affordability widget in RazorPay panel
            </label>
          </p>
        </div>
      </ConfirmModal>
      <Toast
        close={() => setToast(false)}
        open={toast}
        severity="success"
        message="Key Saved Successfully"
      />
    </div>
  );
};

export default RazorpayConnect;
