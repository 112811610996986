import { CLEAR_DATA, CLEAR_COMPLETE } from "../commom/commonTypes";
import {
  FETCH_CARTS,
  CART_PROGRESS,
  SET_CART_ERROR,
  SET_CART_LOADER,
  CLEAR_CART,
  CLEAR_COMPLETE_CART,
  CART_DATE_SELECTOR,
  CART_STATUS_SELECTOR,
  CART_DATE_RANGE,
  SET_CARTS_PER_PAGE,
  SET_CARTS_CURRENT_PAGE,
  SET_CARTS_MAX_PAGE_SO_FAR,
  SET_CARTS_INPUT_KEYWORD,
  SET_CARTS_SEARCH_KEYWORD,
} from "./cartTypes";

const initialState = {
  carts: [],
  cart: null,
  numberOfPages: 0,
  numberOfElements: 0,
  progress: true,
  loader: false,
  error: null,
  dateSelector: null,
  statusSelector: null,
  dateRange: {
    aoe: null,
    boe: null,
  },
  cartsPerPage: null,
  currentPage: 0,
  maxPageSoFar: 0,
  searchKeyword: "",
  inputKeyword: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CART_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      };
    case FETCH_CARTS:
      let allcarts = state.carts;
      let page = action.payload.pageable.pageNumber;
      if (page > 0 && state.carts.length > action.cartsPerPage * page) {
        allcarts = state.carts.slice(0, action.cartsPerPage * page);
      }
      let newCarts =
        action.payload.pageable.pageNumber > 0
          ? [...allcarts, ...action.payload.content]
          : action.payload.content;

      return {
        ...state,
        carts: newCarts,
        numberOfPages: action.payload.totalPages,
        numberOfElements: action.payload.totalElements,
        progress: false,
      };

    case SET_CART_ERROR:
      return {
        ...state,
        error: action.payload.error,
        progress: false,
      };
    case SET_CART_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case CLEAR_CART:
      return {
        ...state,
        cart: action.payload,
      };
    case CLEAR_COMPLETE_CART:
      return {
        ...state,
        carts: [],
        cart: null,
        numberOfPages: 0,
        numberOfElements: 0,
        progress: false,
        loader: false,
        error: null,
      };
    case CLEAR_COMPLETE:
    case CLEAR_DATA:
      return {
        carts: [],
        cart: null,
        numberOfPages: 0,
        numberOfElements: 0,
        progress: true,
        loader: false,
        error: null,
        dateSelector: null,
        statusSelector: null,
        dateRange: {
          aoe: null,
          boe: null,
        },
        cartsPerPage: null,
        currentPage: 0,
        maxPageSoFar: 0,
        searchKeyword: "",
        inputKeyword: "",
      };
    case CART_DATE_SELECTOR:
      return {
        ...state,
        dateSelector: action.payload,
      };
    case CART_STATUS_SELECTOR:
      return {
        ...state,
        statusSelector: action.payload,
      };
    case CART_DATE_RANGE:
      return {
        ...state,
        dateRange: action.payload,
      };
    case SET_CARTS_PER_PAGE:
      return {
        ...state,
        cartsPerPage: action.payload,
      };
    case SET_CARTS_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SET_CARTS_MAX_PAGE_SO_FAR:
      return {
        ...state,
        maxPageSoFar: action.payload,
      };
    case SET_CARTS_INPUT_KEYWORD:
      return {
        ...state,
        inputKeyword: action.payload,
      };
    case SET_CARTS_SEARCH_KEYWORD:
      return {
        ...state,
        searchKeyword: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
