import React from "react";
import { LocationIcon } from "../../../../icons";
import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  addressCard: {
    border: "1px solid #E0E0E0",
    padding: 16,
    borderRadius: 8,
    marginTop: 10,
    marginBottom: 25,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#f9f9f9",
  },
  container: {
    display: "flex",
    alignItems: "center,",
    gap: 10,
    margin: "5px 0px",
  },
  addressContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "5px 0px",
  },
  name: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20px",
  },
  phone: {
    fontSize: "14px",
    lineHeight: "20px",
  },
  addressHeading: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "24px",
    marginLeft: "5px",
  },
  address: {
    color: "#666666",
    fontSize: "14px",
    lineHeight: "24px",
    marginTop: 2,
  },
}));

const AddressCard = ({ formData }) => {
  const classes = useStyles();
  return (
    <div className={classes.addressCard}>
      <div className={""}>
        <Typography className={classes.name}>{formData.name.value}</Typography>
        <div className={classes.container}>
          <Typography className={classes.phone}>
            {formData.registerd_name.value},
          </Typography>
          <Typography className={classes.phone}>
            {formData.phone.value}
          </Typography>
        </div>
      </div>

      <div className={classes.addressContainer}>
        <div>
          <LocationIcon fill={"#1641DB"} style={{ marginRight: 2 }} />
          <span className={classes.addressHeading}>{`Pickup Address`}</span>
        </div>

        <Typography className={classes.address}>
          {`${formData.address.value}, `}
          {`${formData.city.value}, `}
          {`${formData.state.value}, `}
          {`${formData.pin.value} `}
        </Typography>
      </div>

      <div className={classes.addressContainer}>
        <div>
          <LocationIcon fill={"#1641DB"} style={{ marginRight: 2 }} />
          <span className={classes.addressHeading}>{`Return Address`}</span>
        </div>

        <Typography className={classes.address}>
          {`${formData.return_address.value}, `}
          {`${formData.return_city.value}, `}
          {`${formData.return_state.value}, `}
          {`${formData.return_pin.value} `}
        </Typography>
      </div>
    </div>
  );
};

export default AddressCard;
