import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const OfflineIcon = ({
  viewBox = "0 0 16 16",
  stroke = "#999999",
  size = 16,
}) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: size, height: size }}>
      <path
        d="M14.6194 11.8044C15.1638 11.3531 15.5 10.6753 15.5 9.75C15.5 7.88063 13.8438 7.07 12.5 7C12.2222 4.20187 10.2812 2.5 8 2.5C7.1825 2.5 6.47531 2.71656 5.8875 3.06687M2.92875 5.72656C1.54656 6.18906 0.5 7.28437 0.5 9C0.5 11.0625 2.1875 12.5 4.25 12.5H10.0116L2.92875 5.72656Z"
        stroke="#666666"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M14 14L2 2"
        stroke="#666666"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default OfflineIcon;
