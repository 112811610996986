import React from "react";

export default function MiniBannerCollectionIcon() {
  return (
    <svg
      width="73"
      height="60"
      viewBox="0 0 73 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.675581"
        y="0.675581"
        width="71.6488"
        height="58.6488"
        rx="4.72906"
        fill="white"
        stroke="#666666"
        strokeWidth="1.35116"
      />
      <rect
        x="4.86719"
        y="6.12305"
        width="17.0333"
        height="22.0408"
        rx="2.70232"
        fill="#8994FF"
      />
      <rect
        x="26.7656"
        y="6.12305"
        width="17.0333"
        height="22.0408"
        rx="2.70232"
        fill="#C4C4C4"
      />
      <rect
        x="48.666"
        y="6.12305"
        width="17.0333"
        height="22.0408"
        rx="2.70232"
        fill="#C4C4C4"
      />
      <path
        d="M4.86719 31.8379H68.1339V36.7358H4.86719V31.8379Z"
        fill="#C4C4C4"
      />
      <rect
        x="4.86719"
        y="40.4062"
        width="63.2667"
        height="4.89796"
        fill="#C4C4C4"
      />
      <rect
        x="4.86719"
        y="50.2031"
        width="63.2667"
        height="4.89796"
        fill="#C4C4C4"
      />
    </svg>
  );
}
