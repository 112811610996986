import {
  CircularProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useState } from "react";
import Toast from "../../../components/Layout/Toast";
import Moment from "react-moment";
import StatusDotIcon from "../../../icons/StatusDotIcon";
import { rowFlexAlignCenter } from "../../../styles/common";
import { fetchOrderById } from "../../../utils/orderUtil";
import RecordOrderPaymentModal from "../../orders/components/RecordOrderPaymentModal";
import ViewOrder from "../../orders/components/ViewOrder";

const useStyles = makeStyles((theme) => ({
  tablehead: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px -1px 0px #E5E5E5, 0px 1px 0px #E6E6E6",
    borderTop: "1px solid #E6E6E6",
  },
  headrow: {
    "& th": {
      textAlign: "left",
    },
  },
  tablebody: {
    "& tr": {
      height: "68px",
      "& td": {
        textAlign: "left",
      },
    },
  },
}));

const StoreTransactionsTable = ({ txnsListData, fetchMore, loading }) => {
  const classes = useStyles();
  const observer = React.useRef();
  const hasMore = !txnsListData.last;
  const [viewOrderId, toggleViewOrder] = useState(null);
  const [loadingOrder, setLoadingOrder] = useState(true);
  const [orderRecord, setOrderRecord] = useState(null);
  const [openRecordPayment, setOpenRecordPayment] = useState(false);
  const [reloadPayment, setReloadPayment] = useState(false);
  const [toastRec, setToastRec] = useState(false);

  const lastCard = React.useCallback(
    (node) => {
      if (loading) {
        return;
      }
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver((entries) => {
        let entry = entries[0];
        if (entry.isIntersecting && hasMore) {
          fetchMore(txnsListData.currentPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line
    [loading, hasMore]
  );

  React.useEffect(() => {
    if (viewOrderId) {
      setLoadingOrder(true);
      fetchOrderById(viewOrderId).then((order) => {
        setOrderRecord(order);
        setLoadingOrder(false);
      });
    }
  }, [viewOrderId]);

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead className={classes.tablehead}>
            <TableRow className={classes.headrow}>
              <TableCell style={{ width: "15%" }}>Transaction#</TableCell>
              <TableCell style={{ width: "14%" }}>Date</TableCell>
              <TableCell style={{ width: "14%" }}>Ref. Order</TableCell>
              <TableCell style={{ width: "25%" }}>Details</TableCell>
              <TableCell
                style={{ width: "17%", textAlign: "right", paddingRight: 36 }}
              >
                Amount
              </TableCell>
              <TableCell style={{ width: "15%" }}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tablebody}>
            {txnsListData.content?.map((txn, index) => (
              <TableRow
                key={txn.id}
                style={{
                  borderBottom:
                    (index + 1) % txnsListData.content.length === 0
                      ? "none"
                      : "auto",
                }}
              >
                <TableCell>
                  {txn?.display_id ?? `TXN-${String(txn.id).padStart(4, "0")}`}
                </TableCell>
                <TableCell>
                  <Moment format="DD-MMM-YYYY">{txn.transaction_date}</Moment>
                </TableCell>
                <TableCell
                  onClick={() => {
                    txn.ref_display_ord_id &&
                      toggleViewOrder(txn.ref_display_ord_id);
                  }}
                  style={{
                    ...(txn.ref_display_ord_id && {
                      color: "#1641DB",
                      cursor: "pointer",
                    }),
                  }}
                >
                  {txn?.ref_display_ord_id ?? "-"}
                </TableCell>
                <TableCell>{txn.description ? txn.description : "-"}</TableCell>
                <TableCell
                  style={{
                    color: txn.type === "RECEIVED" ? "#16AC67" : "#F0042E",
                    textAlign: "right",
                    paddingRight: 36,
                  }}
                >
                  {!!txn.amount && <>{txn.type === "RECEIVED" ? "+" : "-"}</>}{" "}
                  {txn.amount}
                </TableCell>
                <TableCell
                  style={{
                    whiteSpace: "nowrap",
                    textTransform: "capitalize",
                    textAlign: "left",
                  }}
                >
                  <span className={rowFlexAlignCenter}>
                    <StatusDotIcon
                      fill={txn.type === "RECEIVED" ? "#16AC67" : "#F0042E"}
                    />
                    &ensp;{txn.type?.toLowerCase()}
                  </span>
                </TableCell>
              </TableRow>
            ))}
            {hasMore && (
              <TableRow ref={lastCard}>
                <TableCell colSpan={12} style={{ textAlign: "center" }}>
                  <CircularProgress color="secondary" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <ViewOrder
        openViewOrder={!!viewOrderId}
        setOpenViewOrder={toggleViewOrder}
        order={orderRecord}
        setOpenRecordPayment={setOpenRecordPayment}
        setAnchoredOrder={() => {}}
        reloadPayment={reloadPayment}
        loading={loadingOrder}
      />
      {orderRecord && (
        <RecordOrderPaymentModal
          order={orderRecord}
          setOpenRecordPayment={setOpenRecordPayment}
          openRecordPayment={openRecordPayment}
          setReloadPayment={setReloadPayment}
          reloadPayment={reloadPayment}
          setToastRec={setToastRec}
        />
      )}
      <Toast
        open={toastRec}
        close={setToastRec}
        message="Payment Recorded Successfully"
      />
    </>
  );
};

export default StoreTransactionsTable;
