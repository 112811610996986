import React, { useState, useEffect } from "react";
import FormModal from "../../../components/common/FormModal";
import Controls from "../../../components/controls/Controls";
import { rowFlexJustifyBetween } from "../../../styles/common";
import { getCityAndState } from "../../../utils/customerUtils";
import states from "../../../constants/states.json";

const initError = {
  customer_name: "",
  mobile: "",
  street1: "",
  pincode: "",
  city: "",
  state: "",
};

const AddressFormModal = ({ open, setOpen, title, onSave, initialValue }) => {
  const [addressState, setAddressState] = useState({
    customer_name: "",
    mobile: "",
    street1: "",
    pincode: "",
    city: "",
    state: "none",
  });
  const [errors, setErrors] = useState(initError);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, [initialValue]);

  const init = () => {
    setAddressState({
      customer_name: initialValue?.customer_name ?? "",
      mobile: initialValue?.mobile?.replaceAll(" ", "").slice(-10) ?? "",
      street1: initialValue?.street1 ?? "",
      pincode: initialValue?.pincode ?? "",
      city: initialValue?.city ?? "",
      state: initialValue?.state ?? "none",
      country: "India",
    });
  };

  useEffect(() => {
    if (addressState.pincode.length === 6) {
      getCityAndState(addressState.pincode).then((res) => {
        if (res.data && res.data.state) {
          setAddressState({
            ...addressState,
            city: res.data.city ? res.data.city : addressState.city,
            state: res.data.state,
          });
        }
      });
    }
    // eslint-disable-next-line
  }, [addressState.pincode]);

  const onChange = (e) => {
    setAddressState({ ...addressState, [e.target.name]: e.target.value });
  };

  const onBlur = (e) => {
    if (e.target.name === "customer_name" && e.target.value.trim() === "") {
      setErrors({ ...errors, customer_name: "Please enter name" });
    }
    if (e.target.name === "mobile") {
      if (e.target.value.trim() !== "" && e.target.value.length < 10) {
        setErrors({ ...errors, mobile: "Invalid mobile number" });
      }
    }
    if (e.target.name === "pincode") {
      if (e.target.value.trim() === "") {
        setErrors({ ...errors, pincode: "Please enter pincode" });
      }
      if (e.target.value.trim() !== "" && e.target.value.length < 6) {
        setErrors({ ...errors, pincode: "Invalid pincode" });
      }
    }
    if (e.target.name === "city" && e.target.value.trim() === "") {
      setErrors({ ...errors, city: "Please enter city" });
    }
    if (e.target.name === "state" && e.target.value === "none") {
      setErrors({ ...errors, state: "Please select state" });
    }
  };

  const onFocus = (e) => {
    setErrors({ ...errors, [e.target.name]: false });
  };

  const handleSave = () => {
    const tempErrors = { ...errors };
    let invalid = false;
    Object.entries(addressState).forEach(([key, value]) => {
      if (key === "customer_name" && value.trim() === "") {
        tempErrors[key] = "Please enter name";
        invalid = true;
        return;
      }
      if (key === "mobile") {
        if (value.trim() !== "" && value < 10) {
          tempErrors[key] = "Invalid mobile number";
          invalid = true;
          return;
        }
      }
      if (key === "pincode") {
        if (value.trim() === "") {
          tempErrors[key] = "Please enter pincode";
          invalid = true;
          return;
        }
        if (value.trim() !== "" && value < 10) {
          tempErrors[key] = "Invalid pincode";
          invalid = true;
          return;
        }
      }
      if (key === "city" && value.trim() === "") {
        tempErrors[key] = "Please enter city";
        invalid = true;
        return;
      }
      if (key === "state" && value === "none") {
        tempErrors[key] = "Please select state";
        invalid = true;
        return;
      }
    });
    if (invalid) {
      setErrors(tempErrors);
      return;
    }
    setLoading(true);

    onSave({
      ...addressState,
      state: addressState.state === "none" ? "" : addressState.state,
    }).then(() => {
      setLoading(false);
      setOpen(false);
      init();
      setErrors(initError);
    });
  };

  return (
    <FormModal
      width={600}
      heading={title}
      open={open}
      onClose={() => {
        setOpen(false);
        init();
        setErrors(initError);
      }}
      onSave={handleSave}
      loading={loading}
    >
      <div className={rowFlexJustifyBetween}>
        <Controls.Input
          name="customer_name"
          label="Name"
          type="string"
          width={292}
          value={addressState.customer_name}
          onChange={onChange}
          labelPlacement="top"
          onBlur={onBlur}
          onFocus={onFocus}
          error={errors.customer_name ? errors.customer_name : ""}
        />
        <Controls.Input
          name="mobile"
          maxLength={10}
          label="Phone Number"
          type="number"
          width={242}
          value={addressState.mobile}
          onChange={onChange}
          labelPlacement="top"
          onBlur={onBlur}
          onFocus={onFocus}
          error={errors.mobile ? errors.mobile : ""}
        />
      </div>
      <Controls.Input
        name="street1"
        label="Address"
        type="string"
        value={addressState.street1}
        onChange={onChange}
        maxLength={255}
        labelPlacement="top"
        onBlur={onBlur}
        onFocus={onFocus}
        error={errors.street1 ? errors.street1 : ""}
      />
      <div className={rowFlexJustifyBetween}>
        <Controls.Input
          name="pincode"
          maxLength={6}
          label="Pincode"
          type="number"
          width={136}
          value={addressState.pincode}
          onChange={onChange}
          labelPlacement="top"
          onBlur={onBlur}
          onFocus={onFocus}
          error={errors.pincode ? errors.pincode : ""}
        />
        <Controls.Input
          name="city"
          label="City"
          type="string"
          width={192}
          value={addressState.city}
          onChange={onChange}
          labelPlacement="top"
          maxLength={64}
          onBlur={onBlur}
          onFocus={onFocus}
          error={errors.city ? errors.city : ""}
        />
        <Controls.Select
          name="state"
          label="State"
          labelPlacement="top"
          width={192}
          value={addressState.state}
          placeholder="Select State"
          options={states.map((state) => ({ id: state, title: state }))}
          color="secondary"
          onChange={(e) => {
            setAddressState({ ...addressState, state: e.target.value });
            onFocus(e);
          }}
          onBlur={onBlur}
          error={errors.state ? errors.state : ""}
        />
      </div>
    </FormModal>
  );
};

export default AddressFormModal;
