import { useEffect, useState } from "react";
import { useChatWindowState } from "./hooks/useChatWindowState";
import { useChatBubbleState } from "./hooks/useChatBubbleState";
import { useDispatch, useSelector } from "react-redux";
import { getPluginConfig } from "../../../../../redux/apps/appActions";
import { createColor } from "material-ui-color";

const initialize = {
  value: "",
  error: null,
};

const initialState = {
  profile_icon: initialize,
  name: initialize,
  welcome_message: initialize,
  button_text: { ...initialize, value: "Start Chat" },
  whatsapp_number: initialize,
};

export const useContextState = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(initialState);
  const [pluginEnabled, setPluginEnabled] = useState(false);
  const [generatedScript, setGeneratedScript] = useState(null);
  const [loading, setLoading] = useState(false);

  const chatWindowStates = useChatWindowState();
  const chatBubbleStates = useChatBubbleState();

  const formDispatch = (type, payload) => {
    if (type === "value") {
      setFormData((formData) => ({
        ...formData,
        [payload.name]: {
          ...formData[payload.name],
          value: payload.value,
        },
      }));
    } else if (type === "error") {
      setFormData((formData) => ({
        ...formData,
        [payload.name]: {
          ...formData[payload.name],
          error: payload.error,
        },
      }));
    }
  };

  const onBlur = (event) => {
    let isAllValid = true;
    const name = event.target.name;
    const value = event.target.value;
    if (value.toString().trim() === "") {
      isAllValid = false;
      setFormData((formData) => ({
        ...formData,
        [name]: {
          ...formData[name],
          error: "This field is required",
        },
      }));
    } else if (
      name === "whatsapp_number" &&
      !value.match(/^(\+?\d{1,3}[- ]?)?\d{10}$/)
    ) {
      isAllValid = true;
      setFormData((formData) => ({
        ...formData,
        [name]: {
          ...formData[name],
          error: "Plz enter a valid number",
        },
      }));
    } else {
      isAllValid = true;
      setFormData((formData) => ({
        ...formData,
        [name]: {
          ...formData[name],
          error: null,
        },
      }));
    }

    return isAllValid;
  };

  const onFocus = (event) => {
    const name = event.target.name;
    setFormData((formData) => ({
      ...formData,
      [name]: {
        ...formData[name],
        error: null,
      },
    }));
  };

  const storeData = useSelector((state) => state.store);

  useEffect(() => {
    if (storeData.fetched) {
      setLoading(true);
      dispatch(getPluginConfig("whatsapp-chat-bubble")).then((responses) => {
        if (responses.length > 0) {
          const newFormState = Object.fromEntries(
            Object.entries(initialState)
              .map(([key, value]) => ({
                [key]: {
                  ...value,
                  value:
                    responses.find((e) => e.name === key)?.value || value.value,
                },
              }))
              .flatMap(Object.entries)
          );
          setFormData(newFormState);
          setPluginEnabled(true);
          const colorDataGenerate = (colorsData) =>
            Object.fromEntries(
              Object.entries(colorsData)
                .map(([key, value]) => ({
                  [key]: {
                    ...value,
                    value:
                      (responses.find((e) => e.name === key) &&
                        createColor(responses.find((e) => e.name === key).value)
                          .css.backgroundColor) ||
                      value.value,
                    color:
                      (responses.find((e) => e.name === key) &&
                        createColor(
                          responses.find((e) => e.name === key).value
                        )) ||
                      value.color,
                  },
                }))
                .flatMap(Object.entries)
            );
          const {
            chatWindowColors,
            setChatWindowColors,
            textFont,
            textFontDispatch,
          } = chatWindowStates;
          const {
            setChatBubbleColors,
            chatBubblePositionDispatch,
            bubbleStyle,
            bubbleStyleDispatch,
            chatBubbleColors,
            chatBubblePosition,
            bubbleTextConfigDispatch,
            bubbleTextDispatch,
          } = chatBubbleStates;
          setChatBubbleColors(colorDataGenerate(chatBubbleColors));
          chatBubblePositionDispatch(
            responses.find((e) => e.name === "bubble_position")?.value ||
              chatBubblePosition
          );
          bubbleStyleDispatch(
            responses.find((e) => e.name === "bubble_style")?.value ||
              bubbleStyle
          );
          textFontDispatch(
            responses.find((e) => e.name === "font_family")?.value || textFont
          );
          setChatWindowColors(colorDataGenerate(chatWindowColors));
          setPluginEnabled(
            responses.find((e) => e.name === "plugin_enabled")?.value === "true"
              ? true
              : false
          );
          bubbleTextConfigDispatch({
            bubble_text:
              responses.find((e) => e.name === "bubble_text_enabled")?.value ===
              "true"
                ? true
                : false,
            extend_bubble_when_hovered:
              responses.find((e) => e.name === "extend_bubble_when_hovered")
                ?.value === "true"
                ? true
                : false,
          });
          bubbleTextDispatch((bubbleText) => ({
            ...bubbleText,
            value:
              responses.find((e) => e.name === "bubble_text")?.value ||
              bubbleText.value,
          }));
        }
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeData.fetched]);

  return {
    pluginEnabled,
    setPluginEnabled,
    formData,
    formDispatch,
    onBlur,
    onFocus,
    chatWindowStates,
    chatBubbleStates,
    generatedScript,
    generatedScriptDispatch: setGeneratedScript,
    loading,
  };
};
