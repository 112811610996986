import React, { useEffect, useState } from "react";
import { Container, CircularProgress, makeStyles } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import Toast from "../../../components/Layout/Toast";

const useStyles = makeStyles(() => ({
  progressbar: {
    position: "fixed",
    top: "50%",
    left: "50%",
  },
}));

const RazorpayOAuthCallback = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [toast, setToast] = useState(false);
  const [message, setMessage] = useState("");
  const history = useHistory();
  const location = useLocation();
  const storeId = sessionStorage.getItem('storeid');
  const query = new URLSearchParams(location.search);

  useEffect(() => {
    let vr = validate(query);
    if (vr.error) {
      setLoading(false);
      setToast(true);
      setMessage("Failure - " + vr.message);
      setTimeout(function () {
        history.push(`/${storeId}/apps/razorpay`);
      }, 2000);
    } else {
      configureToken(query).then((resp) => {
        setLoading(false);
        if (resp.error) {
          setToast(true);
          setMessage("Failure - Unable to Link your Razorpay Account");    
        } else {
          setToast(true);
          setMessage("Your Razorpay Account is connected successfully");
        }
        setTimeout(function () {
          history.push(`/${storeId}/apps/razorpay/configure?referrer=apppage&success=true`);
        }, 2000);
      });
    }
    // eslint-disable-next-line
  }, []);

  const validate = (params) => {
    let hasError = false;
    let code = params.get("code");
    let state = params.get("state");
    let message = "";
    if (code) {
      let hash = sessionStorage.getItem('state_token');
      let computedHash = storeId + state
      if (hash !== computedHash) {
        hasError = true;
        message = "Invalid state";
      }
    } else {
      message = params.get("error");
      hasError = true;
    }
    return { error: hasError, message };
  };

  const configureToken = async (params) => {
    let code = params.get("code");
    let url = process.env.REACT_APP_SELF;
    let redirectUri = `${url}/apps/razorpay/auth`;
    let accessToken = localStorage.getItem("access_token");
    let data = { code, redirect_uri: redirectUri };
    let resp = {};
    try {
        await axios.post(
        `${process.env.REACT_APP_API}/api/v1/store/${storeId}/plugins/razorpay/configure`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      resp = { code: "success" };
    } catch (err) {
      resp.error = err;
    }
    return resp;
  };

  return (
    <Container className={classes.container}>
      {loading && (
        <CircularProgress
          color="secondary"
          className={classes.progressbar}
        />
      )}
      <Toast open={toast} close={setToast} message={message} />
    </Container>
  );
};
export default RazorpayOAuthCallback;
