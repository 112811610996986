import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import useStyles from "./styles";
import {
  startPurchaseLoader,
  stopPurchaseLoader,
} from "../../redux/purchaseOrders/purchaseActions";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import RecordPaymentModal from "../../components/invoiceorder/RecordPaymentModal";
import { getPdfLink, fetchInvoiceById } from "../../utils/invoiceUtil";
import { downloadDocument } from "../../utils/documentUtil";
import { getPaymentDetails } from "../../utils/orderUtil";
import { getDefaultStore } from "../../utils/storeUtil";
import { updateCalculation } from "../../utils/invoiceHelper";
import { transparentButton } from "../../styles/common";
import BaseView from "../../components/invoiceorder/BaseView";
import { ViewBaseCTAs } from "../../components/invoiceorder/ViewBaseCTAs";
import PaymentAccordion from "../payments/components/PaymentAccordion";
import { refetchPurchase } from "../../redux/purchaseOrders/purchaseActions";
import HeaderBreadcrumbs from "../../components/common/HeaderBreadcrumbs";
import PageLoader from "../../components/Layout/PageLoader";

const ViewPurchase = () => {
  const params = useParams();
  const history = useHistory();
  const classes = useStyles();
  const store = useSelector((state) => state.store.store);
  const purchasesMap = useSelector((state) => state.purchases.purchasesMap);
  const loading = useSelector((state) => state.purchases.loader);
  const currPurchase = purchasesMap[params.purchaseid];
  const [record, setRecord] = useState(currPurchase);
  const [loader, setLoader] = useState(true);
  const [openRecordPayment, setOpenRecordPayment] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState({
    progress: true,
    payments: [],
  });
  const [dueAmount, setDueAmount] = useState(0);
  const { payments } = paymentDetails;
  const dispatch = useDispatch();
  const [reloadPayment, setReloadPayment] = useState(0);

  useEffect(() => {
    if (!record) {
      fetchInvoiceById(params.purchaseid).then((rec) => {
        console.log(rec);
        if (rec) {
          let calcRec = updateCalculation(
            { ...rec },
            true,
            rec.final_sale_price - rec.due_amount
          );
          getPaymentDetails(rec.id).then((data) => {
            setRecord(calcRec);
            setLoader(false);
            setPaymentDetails({ progress: false, payments: data });
            setDueAmount(getDueAmount(calcRec, data));
          });
        }
      });
    } else {
      getPaymentDetails(record.id).then((data) => {
        let calcRec = updateCalculation(
          { ...record },
          true,
          record.final_sale_price - record.due_amount
        );
        setRecord(calcRec);
        setLoader(false);
        setPaymentDetails({ progress: false, payments: data });
        setDueAmount(getDueAmount(calcRec, data));
      });
    }
    // eslint-disable-next-line
  }, [params.purchaseid]);

  useEffect(() => {
    if (record && reloadPayment > 0) {
      getPaymentDetails(record.id).then((data) => {
        setPaymentDetails({ progress: false, payments: data });
        setDueAmount(getDueAmount(record, data));
      });
    }
  }, [record, reloadPayment]);

  const getDueAmount = (recData, pmts) => {
    if (pmts.length === 0) {
      return recData.due_amount;
    }
    const totalPaid = pmts.reduce((total, payment) => {
      return total + payment.amount;
    }, 0);
    let salePrice = Math.round(recData.final_sale_price * 100) / 100;
    let dueAmt = salePrice - totalPaid;
    return Math.round(dueAmt * 100) / 100;
  };

  const viewPdf = () => {
    dispatch(startPurchaseLoader());
    getPdfLink(record.id).then((link) => {
      window.open(link);
      dispatch(stopPurchaseLoader());
    });
  };

  const onPaymentModalClose = () => {
    setOpenRecordPayment(false);
  };

  const captureInvoicePayment = async (payment) => {
    let invoiceId = record.id;
    let accessToken = localStorage.getItem("access_token");
    let store = getDefaultStore();
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/org/store/${store.store_id}/order/${invoiceId}/payments`,
        payment,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data.payload;
    } catch (err) {
      console.log("Failure while capturing payment", err);
    }
  };

  const onRecordPayment = async (payment) => {
    dispatch(startPurchaseLoader());
    let pmtReq = { ...payment, order_id: record.id };
    await captureInvoicePayment(pmtReq);
    //TODO handle error if payment capture failed
    dispatch(refetchPurchase(record.id));
    setReloadPayment(reloadPayment + 1);
    setOpenRecordPayment(false);
  };

  const downloadInvoice = async () => {
    dispatch(startPurchaseLoader());
    await downloadDocument(
      store,
      "purchase_order",
      record.id,
      record.invoice_id + ".pdf"
    );
    dispatch(stopPurchaseLoader());
  };

  const onEdit = () => {
    history.push(
      `./${encodeURIComponent(
        params.purchaseid
      )}/edit`
    );
  };

  return (
    <div>
      <Grid container spacing={2} className={classes.root}>
        {loading && (
          <div
            style={{
              position: "fixed",
              left: "50%",
              top: "50%",
              zIndex: "900",
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        )}
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            <HeaderBreadcrumbs
              list={[
                {
                  label: "Purchases",
                  link: "/purchases",
                },
                {
                  label: record?.invoice_id,
                },
              ]}
            />
            {!loader ? (
              <Grid
                container
                style={{
                  position: "relative",
                  width: "100%",
                  marginTop: 24,
                }}
              >
                <BaseView
                  record={record}
                  store={store}
                  dateLabel="Purchase Date"
                  enableDescount={true}
                  enableShipping={true}
                  enablePackaging={true}
                  enableRoundOff={true}
                />
                <Grid item xs={3} style={{ paddingLeft: 24 }}>
                  <ViewBaseCTAs
                    onDownload={downloadInvoice}
                    viewPdf={viewPdf}
                    onEdit={onEdit}
                  />
                  <Typography
                    style={{
                      fontWeight: 600,
                      color: "#1A1A1A",
                      paddingBottom: 12,
                      marginTop: 48,
                      borderBottom: "1px solid #E5E5E5",
                      fontSize: 16,
                    }}
                  >
                    Payment Details
                  </Typography>
                  {paymentDetails.progress && (
                    <div style={{ textAlign: "center", marginTop: 20 }}>
                      <CircularProgress color="secondary" />
                    </div>
                  )}
                  {!paymentDetails.progress && (
                    <div>
                      {payments.length > 0 &&
                        payments.map((pmt, i) => {
                          return <PaymentAccordion key={i} payment={pmt} />;
                        })}
                      {payments.length === 0 && (
                        <Typography
                          style={{
                            fontWeight: 400,
                            color: "#999999",
                            fontSize: 12,
                            marginTop: 12,
                          }}
                        >
                          No Payment Recorded
                        </Typography>
                      )}
                      <Grid
                        style={{
                          width: "100%",
                          marginTop: -12,
                        }}
                        className={classes.flexBox}
                      >
                        <Typography
                          className={classes.subHeading}
                          style={{ paddingTop: 18 }}
                        >
                          Due
                        </Typography>
                        <Typography
                          className={classes.subHeading}
                          style={{ paddingTop: 18 }}
                        >
                          ₹ {parseFloat(dueAmount).toFixed(2)}
                        </Typography>
                      </Grid>
                      {dueAmount > 0 && (
                        <Button
                          className={transparentButton}
                          onClick={() => setOpenRecordPayment(true)}
                        >
                          + RECORD PAYMENT
                        </Button>
                      )}
                    </div>
                  )}
                </Grid>
              </Grid>
            ) : (
              <PageLoader />
            )}
          </div>
        </Grid>
      </Grid>
      {openRecordPayment && (
        <RecordPaymentModal
          open={openRecordPayment}
          amount={dueAmount}
          onClose={onPaymentModalClose}
          onRecord={onRecordPayment}
        />
      )}
    </div>
  );
};

export default ViewPurchase;
