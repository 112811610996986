import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  root: {
    backgroundColor: "#04113F",
    width: "260px",
    position: "fixed",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    zIndex: 1,
    marginTop: -64,
    overflow: "overlay",
    [theme.breakpoints.down("md")]: {
      width: "208px",
    },
  },
  icon: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginTop: (props) => (props.matches ? "20px" : "40px"),
  },
  sidebarlist: {
    padding: 0,
  },
  signout: {
    color: "#999999",
    textTransform: "none",
    fontSize: 14,
    lineHeight: "24px",
    marginLeft: theme.spacing(2),
  },
  logo: {
    marginTop: 30,
    marginBottom: 50,
  },
}));
