import React, { useState, useEffect } from "react";
import HeaderBreadcrumbs from "../../../components/common/HeaderBreadcrumbs";
import {
  layoutBodyBox,
  layoutContainer,
  rowFlexCenterAll,
} from "../../../styles/common";
import useStyles from "../styles";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import { Typography } from "@material-ui/core";
import Controls from "../../../components/controls/Controls";
import { useSelector } from "react-redux";
import axios from "axios";
import { updateAttributes } from "../../../utils/storeUtil";
import Toast from "../../../components/Layout/Toast";
import { useHistory } from "react-router-dom";

const WalletSettings = () => {
  const classes = useStyles();
  const history = useHistory();
  const store = useSelector((state) => state.store.store);
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const [attributes, setAttributes] = useState({
    points_rate: "",
    points_redemption_limit: "",
  });
  const disabled =
    !attributes.points_rate || !attributes.points_redemption_limit;

  useEffect(() => {
    if (store.slug) {
      axios
        .get(
          `${process.env.REACT_APP_API}/api/v1/store/external/slug/${store.slug}?attributes=wallet_settings`
        )
        .then((res) => {
          let attributes = res.data.payload.attributes.wallet_settings;
          if (attributes) {
            setAttributes(attributes);
          }
        });
    }
  }, [store]);

  const onChange = (e) => {
    setAttributes({ ...attributes, [e.target.name]: e.target.value });
  };

  const onSave = async () => {
    setLoading(true);

    let payload = [
      {
        name: "points_rate",
        value: attributes.points_rate,
      },
      {
        name: "points_redemption_limit",
        value: attributes.points_redemption_limit,
      },
    ];

    let res = await updateAttributes(store.slug, "wallet_settings", payload);
    if (res) {
      setToast(true);
      setLoading(false);
      setAttributes({
        ...attributes,
      });
    }
  };

  return (
    <div>
      <div className={layoutContainer}>
        <div style={{ marginBottom: 20 }}>
          <HeaderBreadcrumbs
            list={[
              { label: "Customers", link: "/customers" },
              {
                label: "Wallet Settings",
              },
            ]}
          />
        </div>
        <div className={rowFlexCenterAll}>
          <div
            className={layoutBodyBox}
            style={{
              width: 604,
              padding: 32,
            }}
          >
            <div style={{ minHeight: "50vh" }}>
              <Typography className={classes.heading}>
                Earn Wallet Points
              </Typography>
              <Controls.Input
                name="points_rate"
                color="secondary"
                variant="outlined"
                label="On purchase, offer wallet points"
                labelPlacement="top"
                type="number"
                value={attributes.points_rate}
                preventDecimal
                onChange={(e) => {
                  if (
                    parseInt(e.target.value) < 0 ||
                    parseInt(e.target.value) > 100
                  ) {
                    return;
                  }
                  onChange(e);
                }}
                endAdornment={
                  <Typography
                    style={{
                      fontSize: 14,
                    }}
                  >
                    %
                  </Typography>
                }
                helperText="Points will be credited to the customers wallet based on order total and percentage."
                labelFontWeight={400}
              />
              <hr className={classes.horizontalDivider} />
              <Typography className={classes.heading}>
                Redeem Wallet Points
              </Typography>
              <Controls.Input
                name="points_redemption_limit"
                color="secondary"
                variant="outlined"
                label="Apply redemption limit"
                labelPlacement="top"
                type="number"
                value={attributes.points_redemption_limit}
                preventDecimal
                onChange={(e) => {
                  if (
                    parseInt(e.target.value) < 0 ||
                    parseInt(e.target.value) > 100
                  ) {
                    return;
                  }
                  onChange(e);
                }}
                endAdornment={
                  <Typography
                    style={{
                      fontSize: 14,
                    }}
                  >
                    %
                  </Typography>
                }
                helperText="Specify the percentage of total cart value which can be paid by redeeming points"
                labelFontWeight={400}
              />
            </div>
            <div
              style={{
                marginTop: "50px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <CombinedButtons
                disabled={disabled}
                loading={loading}
                solidBtnAction={onSave}
                outlinedBtnAction={() => history.push("../customers")}
              />
            </div>
          </div>
        </div>
      </div>
      <Toast
        open={toast}
        close={() => setToast(false)}
        message="Settings Saved Successfully"
      />
    </div>
  );
};

export default WalletSettings;
