import axios from "axios";
export const createSubscribeRequest = async (
  mainSku,
  plan,
  addOnSkus,
  domainName,
  domainOption,
  domainCharge,
  domainPurchaseType,
  signature,
  slug
) => {
  let data = {
    main_sku: mainSku,
    plan,
    add_on_skus: addOnSkus,
    domain_name: domainName,
    domain_option: domainOption,
    domain_charge: domainCharge,
    domain_purchase_type: domainPurchaseType,
    exclusive_tax: true,
    signature,
  };
  let response = await axios.post(
    `${process.env.REACT_APP_API}/api/v1/store/${slug}/subscription/request`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data.payload;
};

export const processOrder = async (
  slug,
  reqId,
  id,
  mode,
  gateway,
  orderId,
  signature
) => {
  let payment = { id, mode, gateway, order_id: orderId, signature };
  let resp = {};
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_API}/api/v1/store/${slug}/subscription`,
      { subscription_request_id: reqId, payment },
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
      }
    );
    return { id: response.data.payload.id };
  } catch (err) {
    resp.error = err;
  }
  return resp;
};

export const razorPayWallet = (subReq, slug, setLoading) => {
  return new Promise(async (res, rej) => {
    const options = {
      key: `${process.env.REACT_APP_RAZORPAY_API_KEY}`,
      name: "Shoopy",
      buttontext: "Buy Subscription",
      description: subReq.description,
      image: "https://logo.clearbit.com/shoopy.in",
      order_id: subReq.gw_order_id,
      amount: subReq.amount,
      notes: {
        subscription_request_id: subReq.subscription_request_id,
        subscription: true,
      },
      prefill: { contact: subReq.mobile, email: subReq.email },
      handler: async (response) => {
        try {
          setLoading(true);
          let resp = await processOrder(
            slug,
            subReq.subscription_request_id,
            response.razorpay_payment_id,
            "ONLINE",
            "razorpay",
            response.razorpay_order_id,
            response.razorpay_signature
          );
          if (resp.error) {
            throw response.error;
          }
          res();
        } catch (err) {
          console.log({ err });
          rej();
        }
      },
      modal: {
        ondismiss: function () {
          rej();
        },
        escape: true,
      },
      theme: {
        color: "#1641DB",
      },
    };
    const rzp1 = new window.Razorpay(options);

    rzp1.open();
  });
};

export const getRenewalConfirmationInfo = async (storeId, subscriptionId) => {
  let token = localStorage.getItem("access_token");
  let headers = {};
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  let response = await axios.get(
    `${process.env.REACT_APP_API}/api/v1/business/store/${storeId}/subscriptions/${subscriptionId}/renew-confirmation`,
    {headers}
  );
  return response.data.payload;
};

export const createRenewalOrder = async (storeId) => {
  let token = localStorage.getItem("access_token");
  let headers = {
    "Content-Type": "application/json",
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  let response = await axios.post(
    `${process.env.REACT_APP_API}/api/v1/business/store/${storeId}/subscriptions/renew/order`,
    {},
    {headers}
  );
  return response.data.payload;
};

export const renewApi = async (
  storeId,
  id,
  mode,
  gateway,
  gwOrderId,
  signature,
  orderId
) => {
  let payment = { id, mode, gateway, order_id: gwOrderId, signature };
  let resp = {};
  try {
    let token = localStorage.getItem("access_token");
    let headers = {
      "Content-Type": "application/json",
    };
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    let response = await axios.post(
      `${process.env.REACT_APP_API}/api/v1/business/store/${storeId}/subscriptions/renew?order-id=${orderId}`,
      payment,
      {headers}
    );
    return { id: response.data.payload.id };
  } catch (err) {
    resp.error = err;
  }
  return resp;
};
