import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const WhatsappOutlinedIcon = ({
  viewBox = "0 0 16 16",
  stroke = "#1641DB",
  size = 16,
}) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: size, height: size }}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.6 2.32005C12.8669 1.58147 11.9943 0.996016 11.033 0.597731C10.0716 0.199445 9.04061 -0.00371983 8 5.15589e-05C6.60885 0.00140904 5.24248 0.368247 4.03771 1.06383C2.83294 1.75941 1.83208 2.75932 1.13534 3.96341C0.438603 5.16751 0.0704551 6.53354 0.0677629 7.92468C0.0650707 9.31583 0.427929 10.6833 1.12 11.8901L0 16.0001L4.2 14.9001C5.35975 15.5453 6.66289 15.8891 7.99 15.9001C10.0997 15.9001 12.124 15.0669 13.6222 13.5817C15.1205 12.0965 15.9715 10.0796 15.99 7.97005C15.983 6.91736 15.7682 5.8764 15.3581 4.90686C14.948 3.93731 14.3505 3.05825 13.6 2.32005ZM8 14.5301C6.81769 14.5308 5.65701 14.213 4.64 13.6101L4.4 13.4601L1.91 14.1201L2.57 11.6901L2.41 11.4401C1.55925 10.0667 1.24174 8.42905 1.51762 6.83728C1.7935 5.24552 2.64361 3.81021 3.9069 2.80328C5.1702 1.79634 6.75893 1.28771 8.37213 1.37374C9.98533 1.45976 11.511 2.13447 12.66 3.27005C13.916 4.49825 14.635 6.17353 14.66 7.93005C14.6442 9.68598 13.9355 11.3646 12.6882 12.6006C11.441 13.8367 9.756 14.5301 8 14.5301ZM11.61 9.59005C11.41 9.49005 10.44 9.01005 10.26 8.95005C10.08 8.89005 9.94 8.85005 9.81 9.05005C9.61436 9.31814 9.40404 9.5752 9.18 9.82005C9.07 9.96005 8.95 9.97005 8.75 9.82005C7.60973 9.36943 6.65969 8.53946 6.06 7.47005C5.85 7.12005 6.26 7.14005 6.64 6.39005C6.66805 6.33598 6.68269 6.27596 6.68269 6.21505C6.68269 6.15414 6.66805 6.09412 6.64 6.04005C6.64 5.94005 6.19 4.96005 6.03 4.57005C5.87 4.18005 5.71 4.24005 5.58 4.23005H5.19C5.08895 4.2316 4.9894 4.25471 4.898 4.29782C4.8066 4.34093 4.72546 4.40306 4.66 4.48005C4.43562 4.69823 4.26061 4.96197 4.14676 5.25349C4.03291 5.54501 3.98287 5.85755 4 6.17005C4.0627 6.91813 4.34443 7.63115 4.81 8.22005C5.6622 9.49582 6.83006 10.5293 8.2 11.2201C8.91847 11.6395 9.75348 11.8149 10.58 11.7201C10.8552 11.6654 11.1159 11.5535 11.345 11.3916C11.5742 11.2296 11.7667 11.0213 11.91 10.7801C12.0428 10.4857 12.0846 10.1583 12.03 9.84005C11.94 9.74005 11.81 9.69005 11.61 9.59005Z"
          fill="#1641DB"
        />
      </svg>
    </SvgIcon>
  );
};

export default WhatsappOutlinedIcon;
