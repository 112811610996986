import React from "react";
// Icon imports
import BannerCollectionIcon from "../../icons/BannerCollectionIcon";
import BannerProductCollectionIcon from "../../icons/BannerProductCollectionIcon";
import DesktopFiveColumnIcon from "../../icons/DesktopFiveColumnIcon";
import DesktopFourColumnIcon from "../../icons/DesktopFourColumnIcon";
import DesktopOneColumnIcon from "../../icons/DesktopOneColumnIcon";
import DesktopThreeColumnIcon from "../../icons/DesktopThreeColumnIcon";
import DesktopTwoColumnIcon from "../../icons/DesktopTwoColumnIcon";
import MiniBannerCollectionIcon from "../../icons/MiniBannerCollectionIcon";
import MobileOneColumnIcon from "../../icons/MobileOneColumnIcon";
import MobileTwoColumnIcon from "../../icons/MobileTwoColumnIcon";
import ProductGridCollectionIcon from "../../icons/ProductGridCollectionIcon";
import ProductListCollectionIcon from "../../icons/ProductListCollectionIcon";
import { getWithToken } from "../../utils/apiUtils";
import { getDefaultStore } from "../../utils/storeUtil";
import { BASIC } from "../plans/constants";

export const BANNER = "BANNER";
export const BANNER_PRODUCT = "BANNER_PRODUCT";
export const MINI_BANNER = "MINI_BANNER";
export const PRODUCT_GRID = "PRODUCT_GRID";
export const PRODUCT_LIST = "PRODUCT_LIST";
export const BANNER_VIDEO = "BANNER_VIDEO";

export const collectionsWithImage = [
  BANNER,
  BANNER_PRODUCT,
  MINI_BANNER,
  BANNER_VIDEO,
];
export const collectionsWithDescription = [BANNER_PRODUCT, PRODUCT_GRID];
export const collectionsWithLayout = [PRODUCT_GRID, PRODUCT_LIST];
export const collectionsWithGradient = [BANNER, MINI_BANNER, BANNER_PRODUCT];
export const collectionsWithDesktopImage = [BANNER, BANNER_VIDEO];
export const collectionsWithHideTitle = [BANNER, MINI_BANNER, BANNER_VIDEO];
export const collectionsWithDisplayOnly = [BANNER, MINI_BANNER];
export const collectionsWithVideoUrl = [BANNER_VIDEO];
export const collectionsWithNoProduct = [BANNER_VIDEO];
export const collectionsWithSeo = [
  BANNER,
  BANNER_PRODUCT,
  MINI_BANNER,
  PRODUCT_GRID,
  PRODUCT_LIST,
];

export const collectionTypes = [
  {
    value: BANNER_PRODUCT,
    title: "Banner and Products",
    displayValue: "Banner and Products",
    subtitle: "Banner followed by products will be shown in one row",
    icon: () => <BannerProductCollectionIcon />,
  },
  {
    value: PRODUCT_GRID,
    title: "Multi Row Product Grid",
    displayValue: "Multi Row Product Grid",
    subtitle:
      "Only products are shown as per the product card selected in multiple rows",
    plan: BASIC,
    icon: () => <ProductGridCollectionIcon />,
  },
  {
    value: PRODUCT_LIST,
    title: "Single Row Product List",
    displayValue: "Single Row Product List",
    subtitle:
      "Only products will be shown in one row with scroll if applicable ",
    plan: BASIC,
    icon: () => <ProductListCollectionIcon />,
  },
  {
    value: BANNER,
    title: "Banner Collection",
    displayValue: "Banner Collection",
    subtitle:
      "Banner with title will be shown. Upon clicking the banner product list will come",
    plan: BASIC,
    icon: () => <BannerCollectionIcon />,
  },
  {
    value: MINI_BANNER,
    title: "Mini Banner Collection",
    displayValue: "Mini Banner Collection",
    subtitle:
      "1/3rd of the space will be taken by collection. Remaining area will be used for other mini banner collections.",
    plan: BASIC,
    icon: () => <MiniBannerCollectionIcon />,
  },
  {
    value: BANNER_VIDEO,
    title: "Video Card",
    displayValue: "Video Card",
    subtitle:
      "Youtube Video with thumbnail image. Upon clicking the thumbnail image, the youtube video will play",
    plan: BASIC,
    icon: () => <BannerCollectionIcon />,
  },
];

export const layoutMobileList = [
  {
    value: 1,
    title: "1 Column",
    displayValue: "1 Column",
    icon: () => <MobileOneColumnIcon />,
  },
  {
    value: 2,
    title: "2 Columns",
    displayValue: "2 Columns",
    icon: () => <MobileTwoColumnIcon />,
  },
];

export const layoutDesktopList = [
  {
    value: 1,
    title: "1 Column",
    displayValue: "1 Column",
    icon: () => <DesktopOneColumnIcon />,
  },
  {
    value: 2,
    title: "2 Columns",
    displayValue: "2 Columns",
    icon: () => <DesktopTwoColumnIcon />,
  },
  {
    value: 3,
    title: "3 Columns",
    displayValue: "3 Columns",
    icon: () => <DesktopThreeColumnIcon />,
  },
  {
    value: 4,
    title: "4 Columns",
    displayValue: "4 Columns",
    icon: () => <DesktopFourColumnIcon />,
  },
  {
    value: 5,
    title: "5 Columns",
    displayValue: "5 Columns",
    icon: () => <DesktopFiveColumnIcon />,
  },
];

export const maxNumOfProducts = [
  { value: 1, title: "1", displayValue: "1" },
  { value: 2, title: "2", displayValue: "2" },
  { value: 3, title: "3", displayValue: "3" },
  { value: 4, title: "4", displayValue: "4" },
  { value: 5, title: "5", displayValue: "5" },
  { value: 6, title: "6", displayValue: "6" },
  { value: 7, title: "7", displayValue: "7" },
  { value: 8, title: "8", displayValue: "8" },
  { value: 9, title: "9", displayValue: "9" },
  { value: 10, title: "10", displayValue: "10" },
  { value: 11, title: "11", displayValue: "11" },
  { value: 12, title: "12", displayValue: "12" },
];

export const getCollectionsById = async (collectionId) => {
  const store = getDefaultStore();
  const path = `/api/v1/org/${store.org_id}/stores/${store.store_id}/product-collections/${collectionId}`;
  const { error, data } = await getWithToken(path);
  if (error) {
    // dispatch error action or show toast
    console.log("Failure while fetching invoice", error);
    return;
  }
  return data.payload;
};
