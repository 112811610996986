// Core react imports
import React from "react";
// Component imports
import SolidButton from "../../../components/buttons/SolidButton";
import HeaderBreadcrumbs from "../../../components/common/HeaderBreadcrumbs";
import CreateCoupon from "../../../icons/CreateCoupon";
// Style imports
import { layoutSubHeader } from "../../../styles/common";
// Default component export
export default function LandingHeader({ onClick }) {
  return (
    <div className={layoutSubHeader}>
      <HeaderBreadcrumbs list={[{ label: "Collections" }]} />
      <SolidButton
        endIcon={<CreateCoupon />}
        onClick={onClick}
        style={{ width: 195 }}
      >
        Create Collection
      </SolidButton>
    </div>
  );
}
