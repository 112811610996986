import { Typography } from "@material-ui/core";
import React from "react";

const StoreSignature = ({ store }) => {
  return (
    <div
      style={{
        borderTop: "4px solid rgb(229, 229, 229)",
        paddingTop: 32,
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        paddingBottom: 28,
      }}
    >
      <div style={{ width: "50%" }}></div>
      {store && store.signature_url && (
        <div
          style={{
            width: "50%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Typography
            style={{
              padding: "0px 18px",
              color: "#666666",
              fontSize: 12,
              fontWeight: 600,
            }}
          >
            Signature
          </Typography>
          <div
            style={{
              height: 80,
              width: 180,
              border: "1px solid #F6F6F6",
              borderRadius: 6,
            }}
          >
            <img
              src={store.signature_url}
              alt=""
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default StoreSignature;