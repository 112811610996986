export const FETCH_TRANSACTIONS = "FETCH_TRANSACTIONS";
export const TRANSACTION_PROGRESS = "TRANSACTION_PROGRESS";
export const SET_TRANSACTION_ERROR = "SET_TRANSACTION_ERROR";
export const SET_TRANSACTION_LOADER = "SET_TRANSACTION_LOADER";
export const CLEAR_COMPLETE_TRANSACTIONS = "CLEAR_COMPLETE_TRANSACTIONS";
export const CLEAR_TRANSACTION = "CLEAR_TRANSACTION";
export const TRANSACTION_DATE_SELECTOR = "TRANSACTION_DATE_SELECTOR";
export const TRANSACTION_DATE_RANGE = "TRANSACTION_DATE_RANGE";
export const SET_TRANSACTIONS_PER_PAGE = "SET_TRANSACTIONS_PER_PAGE";
export const SET_TRANSACTIONS_CURRENT_PAGE = "SET_TRANSACTIONS_CURRENT_PAGE";
export const SET_TRANSACTIONS_MAX_PAGE_SO_FAR =
  "SET_TRANSACTIONS_MAX_PAGE_SO_FAR";
export const SET_TRANSACTIONS_INPUT_KEYWORD = "SET_TRANSACTIONS_INPUT_KEYWORD";
export const SET_TRANSACTIONS_SEARCH_KEYWORD =
  "SET_TRANSACTIONS_SEARCH_KEYWORD";
export const SET_SUMMARY_FILTER = "SET_SUMMARY_FILTER";
