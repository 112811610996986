import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const DesktopIcon = ({
  viewBox = "0 0 19 18",
  fill = "#1641DB",
  height = "18px",
  width = "19px",
}) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: width, height: height }}>
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.4541 14.0909L16.9996 14.0909C17.8029 14.0909 18.4541 13.4397 18.4541 12.6364V2.45455C18.4541 1.65122 17.8029 1 16.9996 1L2.4541 1C1.65078 1 0.999556 1.65122 0.999556 2.45455V12.6364C0.999556 13.4397 1.65078 14.0909 2.4541 14.0909Z"
          stroke={fill}
          strokeWidth="0.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.6365 17H6.81836"
          stroke={fill}
          strokeWidth="0.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default DesktopIcon;
