// Importing report types
import {
  ADD_REPORT,
  CLEAR_REPORTS,
  FETCH_REPORTS_STATUS,
  SET_REPORTS,
  UPDATE_REPORT,
} from "./reportTypes";
// Util imports
import { getDefaultStore } from "../../utils/storeUtil";
// Defining actions
const setFetchReportStatus = (status) => {
  return { type: FETCH_REPORTS_STATUS, payload: status };
};
const setReportList = (payload) => {
  return { type: SET_REPORTS, payload };
};
export const fetchReportList =
  (pageSize = 5, pageNumber = 0) =>
  async (dispatch) => {
    try {
      dispatch(setFetchReportStatus("loading"));
      let accessToken = localStorage.getItem("access_token");
      const store = getDefaultStore();
      let response = await fetch(
        `${process.env.REACT_APP_API}/api/v3/stores/${store.store_id}/report-requests?size=${pageSize}&page=${pageNumber}&sort=createdAt,desc`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (!response.ok) {
        throw response;
      } else {
        let data = await response.json();
        dispatch(setReportList(data.payload));
      }
    } catch (err) {
      console.log({ err });
      dispatch(setFetchReportStatus("failed"));
    }
  };
export const clearReports = () => {
  return { type: CLEAR_REPORTS };
};
export const generateReport = (data) => async (dispatch) => {
  try {
    let accessToken = localStorage.getItem("access_token");
    const store = getDefaultStore();
    let response = await fetch(
      `${process.env.REACT_APP_API}/api/v3/stores/${store.store_id}/report-requests`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ ...data, store_id: store.store_id, org_id: store.org_id }),
      }
    );
    if (!response.ok) {
      throw response;
    }
    response = await response.json();
    localStorage.setItem(`report_${response.payload.id}`,0)
    dispatch({ type: ADD_REPORT, payload: { ...response.payload } });
  } catch (err) {
    console.log({ err });
  }
};
export const updateReport = (data) => {
  return { type: UPDATE_REPORT, payload: { data }, id: data.id };
};
