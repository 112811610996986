import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    overflow: "hidden",
  },
  banner: {
    padding: 46,
    paddingLeft: 0,
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));
