import React, { useEffect, useState } from "react";
import { Container, CircularProgress } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import { container, progressbar, circular } from "../../styles/common";
import { createRenewalOrder, renewApi } from "./utils";

const MODE_APP_WEBVIEW = "appwebview";

const Subscribe = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const slug = query.get("slug");
  const storeId = query.get("storeid");
  const renew = query.get("renew");
  const mainSku = query.get("main_sku");
  const plan = query.get("plan");
  const addOnSkus = query.getAll("add_on_skus");
  const domainName = query.get("domain_name");
  const domainOption = query.get("domain_option");
  const domainCharge = query.get("domain_charge");
  const domainPurchaseType = query.get("domain_purchase_type");
  const signature = query.get("signature");
  const mode = query.get("mode");
  const exclusiveTax = query.get("exclusive_tax");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(renew && storeId) {
      createRenewalOrder(storeId).then((subOrder) => {
        setLoading(false);
        razorPayRenewWallet(subOrder);
      });
    }else {
      createSubscribeRequest(exclusiveTax).then((subOrder) => {
        setLoading(false);
        razorPayWallet(subOrder, slug);
      });
    }
    // eslint-disable-next-line
  }, [storeId, slug, signature]);

  const createSubscribeRequest = async (exclusiveTax) => {
    let data = {
      main_sku: mainSku,
      plan,
      add_on_skus: addOnSkus,
      domain_name: domainName,
      domain_option: domainOption,
      domain_charge: domainCharge,
      domain_purchase_type: domainPurchaseType,
      exclusive_tax: exclusiveTax,
      signature,
    };
    let response = await axios.post(
      `${process.env.REACT_APP_API}/api/v1/store/${slug}/subscription/request`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.payload;
  };

  const processOrder = async (
    slug,
    reqId,
    id,
    mode,
    gateway,
    orderId,
    signature
  ) => {
    let payment = { id, mode, gateway, order_id: orderId, signature };
    let resp = {};
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/store/${slug}/subscription`,
        { subscription_request_id: reqId, payment },
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
          },
        }
      );
      return { id: response.data.payload.id };
    } catch (err) {
      resp.error = err;
    }
    return resp;
  };

  const razorPayWallet = async (subReq, slug) => {
    const options = {
      key: `${process.env.REACT_APP_RAZORPAY_API_KEY}`,
      name: "Shoopy",
      buttontext: "Buy Subscription",
      description: subReq.description,
      image: "https://logo.clearbit.com/shoopy.in",
      order_id: subReq.gw_order_id,
      amount: subReq.amount,
      notes: {
        subscription_request_id: subReq.subscription_request_id,
        subscription: true,
      },
      prefill: { contact: subReq.mobile, email: subReq.email },
      handler: async (response) => {
        setLoading(true);
        await processOrder(
          slug,
          subReq.subscription_request_id,
          response.razorpay_payment_id,
          "ONLINE",
          "razorpay",
          response.razorpay_order_id,
          response.razorpay_signature
        );
        setLoading(false);
        if (mode && mode === MODE_APP_WEBVIEW) {
          window.flutter_inappwebview.callHandler("Response", "success");
        } else {
          Response.postMessage("success");
        }
      },
      modal: {
        ondismiss: function () {
          if (mode && mode === MODE_APP_WEBVIEW) {
            window.flutter_inappwebview.callHandler("Response", "failed");
          } else {
            Response.postMessage("failed");
          }
        },
        escape: true,
      },
      theme: {
        color: "#1641DB",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const razorPayRenewWallet = async (subReq) => {
    const options = {
      key: `${process.env.REACT_APP_RAZORPAY_API_KEY}`,
      name: "Shoopy",
      description: subReq.description,
      image: "https://logo.clearbit.com/shoopy.in",
      order_id: subReq.gw_order_id,
      amount: subReq.amount,
      notes: {
        order_id: subReq.order_id,
        store_id: storeId,
        renew: "true",
      },
      prefill: { contact: subReq.mobile, email: subReq.email },
      handler: async (response) => {
        setLoading(true);
        await renewApi(
          storeId,
          response.razorpay_payment_id,
          "ONLINE",
          "razorpay",
          response.razorpay_order_id,
          response.razorpay_signature,
          subReq.order_id
        );
        if (mode && mode === MODE_APP_WEBVIEW) {
          window.flutter_inappwebview.callHandler("Response", "success");
        } else {
          Response.postMessage("success");
        }
        setLoading(false);
      },
      modal: {
        ondismiss: function () {
          if(mode && mode === MODE_APP_WEBVIEW){
            window.flutter_inappwebview.callHandler("Response", "failed");
          }else{
            Response.postMessage("failed");
          }
        },
        escape: true,
      },
      theme: {
        color: "#1641DB",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };


  return (
    <>
      <Helmet>
        <title>Buy Subscription - Shoopy</title>
        <meta charSet="utf-8" />
        <script src="https://checkout.razorpay.com/v1/checkout.js"></script>
      </Helmet>
      <Container className={container}>
        {loading && (
          <CircularProgress
            color="secondary"
            className={progressbar}
            classes={{ colorSecondary: circular }}
          />
        )}
      </Container>
    </>
  );
};
export default Subscribe;
