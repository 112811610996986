export const FETCH_USERS = "FETCH_USERS";
export const ADD_USER = "ADD_USER";
export const DELETE_USER = "DELETE_USER";
export const USER_PROGRESS = "USER_PROGRESS";
export const SET_USER_ERROR = "SET_USER_ERROR";
export const SET_USER_LOADER = "SET_USER_LOADER";
export const SET_USERS_PER_PAGE = "SET_USERS_PER_PAGE";
export const SET_USERS_CURRENT_PAGE = "SET_USERS_CURRENT_PAGE";
export const SET_USERS_MAX_PAGE_SO_FAR = "SET_USERS_MAX_PAGE_SO_FAR";
export const CLEAR_COMPLETE_USERS = "CLEAR_COMPLETE_USERS";
export const SET_SUCCESS_TOAST = "SET_SUCCESS_TOAST";
