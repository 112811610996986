import React from "react";
import { Fragment } from "react";
import OutlinedButton from "../buttons/OutlinedButton";

export const ViewBaseCTAs = ({ onEdit, viewPdf, onDownload, onPrint }) => {
  return (
    <Fragment>
      <OutlinedButton
        style={{
          width: "100%",
          fontSize: 15,
          height: 44,
        }}
        onClick={viewPdf}
      >
        VIEW PDF
      </OutlinedButton>
      <OutlinedButton
        style={{
          width: "100%",
          marginTop: 12,
          fontSize: 15,
          height: 44,
        }}
        onClick={onDownload}
      >
        DOWNLOAD
      </OutlinedButton>
      <OutlinedButton
        style={{
          width: "100%",
          marginTop: 12,
          fontSize: 15,
          height: 44,
        }}
        onClick={onPrint}
      >
        PRINT
      </OutlinedButton>
      <OutlinedButton
        style={{
          width: "100%",
          marginTop: 12,
          fontSize: 15,
        }}
        onClick={onEdit}
      >
        EDIT
      </OutlinedButton>
    </Fragment>
  );
};
