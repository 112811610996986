// React imports
import React, { useState } from "react";
// Mui imports
import { Grid } from "@material-ui/core";
// Custom component imports
import Controls from "../../../../../components/controls/Controls";
import CategorySelect from "./CategorySelect";
import SearchableSelect from "../SearchableSelect";
import SelectedItem from "../SelectedItem";
import SelectedAreaHead from "../SelectedAreaHead";
// Icon imports
import { NoPicIcon } from "../../../../../icons";
// Util imports
import { CouponItemsLinkingTypes, handleProductDataFetch } from "../formUtils";
import { useSelector } from "react-redux";
import { useStyles } from "../formStyles";

export default function FormSectionThree({
  categories,
  onFormFieldChange,
  formState,
  allCatsBySlug,
  appliesTo,
  setAppliesTo,
  setIsPaidPopUpOpen,
}) {
  // States
  const [showCategoryPopUp, setShowCategoryPopUp] = useState(false);
  const [showProductPopUp, setShowProductPopUp] = useState(false);
  const storeState = useSelector((state) => state.store);
  const classes = useStyles();
  // Handler methods
  const handleItemLinkingTypeChange = (e) => {
    if (storeState.store.plan === "FREE" && e.target.value !== "entire-order") {
      setIsPaidPopUpOpen(true);
      return;
    }
    switch (e.target.value) {
      case "selected-categories":
        setShowCategoryPopUp(true);
        break;
      case "entire-order":
        onFormFieldChange("cat_slugs", []);
        onFormFieldChange("super_prod_skus", {});
        break;
      case "selected-products":
        setShowProductPopUp(true);
        break;
      default:
    }
    setAppliesTo(e.target.value);
  };

  const getItemData = (slug) => {
    return {
      title: allCatsBySlug[slug].name,
      subTitle:
        categories[slug]?.children?.length > 0
          ? categories[slug]?.children?.length + " Sub-Categories"
          : "",
      image: allCatsBySlug[slug].thumbnail,
      slug: slug,
      level: allCatsBySlug[slug].level,
    };
  };

  // Selected item removal handlers
  const handleCatRemoval = (checked = false, updatedCat) => {
    let clonedState = [...formState.cat_slugs.value];
    let allCatsRelated = [updatedCat.slug];
    if (updatedCat.level === 0) {
      allCatsRelated = [
        ...categories[updatedCat.slug].children.map((_) => _.slug),
        updatedCat.slug,
      ];
    }
    clonedState = clonedState.filter((_) => allCatsRelated.indexOf(_) === -1);
    if (clonedState.length === 0) {
      setAppliesTo("entire-order");
    }
    onFormFieldChange("cat_slugs", clonedState);
  };

  const handleProductRemoval = (checked = false, updatedProduct) => {
    let clonedObj = {
      ...formState.super_prod_skus.value,
    };
    delete clonedObj[updatedProduct.id];
    if (Object.keys(clonedObj).length === 0) {
      setAppliesTo("entire-order");
    }
    onFormFieldChange("super_prod_skus", clonedObj);
  };

  // Filter handlers
  const filterCat = (_) => {
    let bool =
      formState.cat_slugs.value.indexOf(allCatsBySlug[_].parent_cat_slug) ===
      -1;
    return bool;
  };

  // Pop up close handlers
  const handleCatPopupClose = (selectedCats = []) => {
    if (selectedCats.length > 0) {
      onFormFieldChange("cat_slugs", selectedCats);
      onFormFieldChange("super_prod_skus", {});
    } else {
      if (Object.keys(formState.super_prod_skus.value).length > 0) {
        setAppliesTo("selected-products");
      } else {
        setAppliesTo("entire-order");
      }
    }
    setShowCategoryPopUp(false);
  };

  const handleProductPopupClose = (selectedProducts = {}) => {
    if (Object.keys(selectedProducts).length > 0) {
      onFormFieldChange("super_prod_skus", selectedProducts);
      onFormFieldChange("cat_slugs", []);
    } else {
      if (formState.cat_slugs.value.length > 0) {
        setAppliesTo("selected-categories");
      } else {
        setAppliesTo("entire-order");
      }
    }
    setShowProductPopUp(false);
  };
  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Controls.Select
            options={CouponItemsLinkingTypes}
            value={appliesTo}
            label="Applies To"
            onChange={handleItemLinkingTypeChange}
            labelPlacement="top"
          />
        </Grid>
        {/* If categories is selected */}
        {appliesTo === "selected-categories" &&
          formState.cat_slugs.value.length > 0 && (
            <Grid item xs={12}>
              <SelectedAreaHead
                title="Selected Categories"
                updateAction={setShowCategoryPopUp}
              />
              <div style={{ maxHeight: "220px", overflow: "auto" }}>
                {/* Filter and display only parents if parent is present in selected cats */}
                {formState.cat_slugs.value.filter(filterCat).map((slug) => {
                  return (
                    <SelectedItem
                      key={`${slug}-selected-item`}
                      item={getItemData(slug)}
                      onRemove={handleCatRemoval}
                    />
                  );
                })}
              </div>
            </Grid>
          )}
        {/* If products is selected */}
        {appliesTo === "selected-products" &&
          Object.keys(formState.super_prod_skus.value).length > 0 && (
            <Grid item xs={12}>
              <SelectedAreaHead
                title="Selected Products"
                updateAction={setShowProductPopUp}
              />
              <div style={{ maxHeight: "220px", overflow: "auto" }}>
                {Object.keys(formState.super_prod_skus.value).map(
                  (productKey) => {
                    let product = formState.super_prod_skus.value[productKey];
                    return (
                      <SelectedItem
                        key={`${product.id}-selected-item`}
                        item={product}
                        onRemove={handleProductRemoval}
                      />
                    );
                  }
                )}
              </div>
            </Grid>
          )}
        <Grid item xs={12} style={{ marginTop: 16 }}>
          <Controls.Input
            type="number"
            startAdornment={<span className={classes.rupee}>₹</span>}
            helperText={
              appliesTo === "selected-products" ||
              appliesTo === "selected-categories"
                ? "Type minimum amount to be eligible. Total price of applicable items will be compared with the minimum amount."
                : "Type minimum order amount to be eligible for the discount."
            }
            name="min_sale_price"
            label="Min Order Amount"
            value={formState.min_sale_price.value}
            onChange={(e) => onFormFieldChange(e.target.name, e.target.value)}
            onFocus={() => {}}
            onBlur={() => {}}
            error={
              formState.min_sale_price.hasError
                ? formState.min_sale_price.error
                : ""
            }
            labelPlacement="top"
          />
        </Grid>
      </Grid>
      {showCategoryPopUp && (
        <CategorySelect
          isOpen={showCategoryPopUp}
          close={handleCatPopupClose}
          categories={categories}
          cat_slugs={formState.cat_slugs.value}
        />
      )}
      {showProductPopUp && (
        <SearchableSelect
          isOpen={showProductPopUp}
          heading="Select Products"
          subHeading="Select products to apply on the coupon"
          close={handleProductPopupClose}
          ImageFallback={NoPicIcon}
          fetchData={handleProductDataFetch}
          selected_items={formState.super_prod_skus.value}
        />
      )}
    </>
  );
}
