// Scoring algorithm for domains
const score = (domain, keyword) => {
  const tldScore = {
    com: 5,
    in: 5,
    online: 4,
    store: 4,
    pro: 3,
    "co.in": 3,
    biz: 3,
    info: 3,
    org: 3,
    net: 3,
    "net.in": 3,
    io: 3,
  };
  let priceScore = Math.trunc(domain.purchasePrice / 5);
  priceScore = 5 - (priceScore > 5 ? 5 : priceScore);

  let rPriceScore = Math.trunc(domain.renewalPrice / 5);
  rPriceScore = 5 - (rPriceScore > 5 ? 5 : rPriceScore);

  let score = domain.premium ?? false ? 0 : 5;
  score += domain.domainName.includes(keyword) ? 5 : 0;
  score += tldScore[domain.tld] ?? 0;
  score += priceScore + rPriceScore;
  return score;
};

const filterAndSort = (domainList, keyword) => {
  let purchaseableDomains = domainList.filter((domain) => domain.purchasable);
  purchaseableDomains.sort((a, b) => score(b, keyword) - score(a, keyword));
  return purchaseableDomains;
};

export const findDomains = async (keyword) => {
  let domains = [];

  try {
    let response = await fetch(
      `${process.env.REACT_APP_NAME_DOT_COM_API}/v4/domains:search`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${process.env.REACT_APP_NAME_DOT_COM_API_KEY}`,
        },
        body: JSON.stringify({ keyword: keyword }),
      }
    );
    if (response.ok) {
      let data = await response.json();
      domains = filterAndSort(data.results, keyword);
    }
  } catch (err) {
    console.log({ err });
  }
  return domains;
};
