import {
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import useStyles from "../styles";
import React, { useEffect, useState } from "react";
import { getBillSequence } from "../../../utils/invoiceUtil";
import { useSelector } from "react-redux";
import {
  getCustomerDetails,
  getPaymentDetails,
  compareBillAndShipAddress,
} from "../../../utils/orderUtil";
import {
  createNewRecord,
  initializeRecordValues,
} from "../../../utils/invoiceHelper";
import { toLocaleISOString } from "../../../utils/dateUtils";
import { isWindows } from "react-device-detect";
import OrderHeader from "../../../components/invoiceorder/OrderHeader";
import CustomerDetails from "../../../components/invoiceorder/CustomerDetails";
import ErrorAlert from "../../../components/message/ErrorAlert";
import RecordPaymentModal from "../../../components/invoiceorder/RecordPaymentModal";
import ConfirmModal from "../../../components/common/ConfirmModal";
import OrderPaymentSide from "./OrderPaymentSide";
import CustomerAddress from "../../../components/invoiceorder/CustomerAddress";
import BaseCTAs from "../../../components/invoiceorder/BaseCTAs";
import BaseForm from "../../../components/invoiceorder/BaseForm";
import BaseFormFooter from "../../../components/invoiceorder/BaseFormFooter";

const OrderForm = ({
  record,
  headerLabel,
  dateLabel,
  type,
  orderPrefix,
  state,
  status,
  note,
  loader,
  enableDescount,
  enableCoupon,
  onClose,
  onSave,
  startLabel,
}) => {
  const classes = useStyles();
  const taxGroups = useSelector((state) => state.tax.taxGroups);
  const store = useSelector((state) => state.store.store);
  const [invDateFocus, setInvDateFocus] = useState(false);
  const [invDateError, setInvDateError] = useState(false);
  const [shippingAddress, setShippingAddress] = useState(record?.order_address);
  const [payment, setPayment] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);

  let initValues = record
    ? initializeRecordValues(record, taxGroups)
    : createNewRecord(store.id, store.org_id, state, status, type);
  const [order, setOrder] = useState({
    ...initValues,
    last_payment_date: initValues.last_payment_date
      ? initValues.last_payment_date
      : null,
    invoice_date: initValues.invoice_date ? initValues.invoice_date : null,
  });
  const [openRecordPayment, setOpenRecordPayment] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [invoiceId, setInvoiceId] = useState(initValues.invoice_id);
  const [autoInvoiceId, setAutoInvoiceId] = useState(initValues.invoice_id);

  const [paymentDetails, setPaymentDetails] = useState({
    progress: true,
    payments: [],
  });

  const [confirmModal, setConfirmModal] = useState(false);
  const { payments } = paymentDetails;
  const [scale, setScale] = useState(1);

  useEffect(() => {
    if (isWindows) {
      setScale(window.devicePixelRatio - 0.07);
    }
  }, []);

  useEffect(() => {
    if (record != null) {
      getPaymentDetails(record.id).then((data) => {
        setPaymentDetails({ progress: false, payments: data });
      });
    } else {
      setPaymentDetails({ ...paymentDetails, progress: false });
    }
    // eslint-disable-next-line
  }, [record]);

  useEffect(() => {
    if (record === null) {
      getBillSequence(type).then((bs) => {
        setInvoiceId(bs);
        setAutoInvoiceId(bs);
      });
    } else {
      getCustomerDetails(record).then((cust) => {
        setCustomer(cust);
      });
    }
    // eslint-disable-next-line
  }, []);

  const getDueAmount = () => {
    if (payments.length === 0 && payment === null) {
      return order.due_amount;
    }
    let totalPaid = payments.reduce((total, pmt) => {
      return total + pmt.amount;
    }, 0);
    let salePrice = Math.round(order.final_sale_price * 100) / 100;
    let dueAmt = salePrice - totalPaid;
    return Math.round(dueAmt * 100) / 100;
  };

  let dueAmount = getDueAmount();

  const onCreateOrUpdate = (skipDueAmtCheck) => {
    if (customer === null || customer.customer_id <= 0) {
      setAlertMessage("Please provide customer information");
      return;
    }
    if (order.order_items.length === 0) {
      setAlertMessage("Order should have at least one Item");
      return;
    }
    let ordReq = {
      ...order,
      invoice_id:
        record != null
          ? invoiceId
          : autoInvoiceId !== invoiceId
          ? invoiceId
          : null,
      customer_id: customer.customer_id,
      org_customer_id: customer.org_customer_id,
      org_customer_mobile: customer.mobile,
      org_customer_name: customer.name,
    };
    if (shippingAddress !== null) {
      ordReq.order_address = shippingAddress;
    }
    if (payment && Object.keys(payment).length > 0) {
      ordReq.order_payment = payment;
      ordReq.order_payment.order_id = record != null ? record.id : null;
      if (record == null) {
        ordReq.due_amount =
          Math.round((order.due_amount - payment.amount) * 100) / 100;
      } else {
        let totalPaid = payments.reduce((total, pmt) => {
          return total + pmt.amount;
        }, 0);
        let salePrice = Math.round(order.final_sale_price * 100) / 100;
        let dueAmt = salePrice - totalPaid;
        ordReq.due_amount = Math.round(dueAmt * 100) / 100;
      }
    } else {
      //Don't see order payment request
      ordReq.order_payment = null;
      let totalPaid = payments.reduce((total, pmt) => {
        return total + pmt.amount;
      }, 0);
      let salePrice = Math.round(order.final_sale_price * 100) / 100;
      let dueAmt = salePrice - totalPaid;
      ordReq.due_amount = Math.round(dueAmt * 100) / 100;
    }
    if (!skipDueAmtCheck && ordReq.due_amount < 0) {
      setConfirmModal(true);
      return;
    }

    onSave(ordReq);
  };

  const onCloseConfirmModal = () => {
    setConfirmModal(false);
  };

  const onFormClose = () => {
    onClose();
  };

  const onPaymentEdit = () => {
    setOpenRecordPayment(true);
  };

  const handleCustomer = (modalCustomer) => {
    setCustomer(modalCustomer);
    let cloneAddress = null;
    if (modalCustomer && modalCustomer.shipping_address) {
      cloneAddress = modalCustomer.shipping_address;
    } else if (modalCustomer && modalCustomer.use_same_address) {
      if (modalCustomer.billing_address) {
        cloneAddress = modalCustomer.billing_address;
      }
    }
    if (cloneAddress != null) {
      setShippingAddress({
        customer_name: cloneAddress.customer_name,
        mobile: cloneAddress.mobile,
        street1: cloneAddress.street1,
        street2: cloneAddress.street2,
        pincode: cloneAddress.pincode,
        city: cloneAddress.city,
        state: cloneAddress.state,
        lat: cloneAddress.lat,
        lng: cloneAddress.lng,
        country: cloneAddress.country,
        id: record?.order_address?.id ?? null,
      });
    } else {
      setShippingAddress(null);
    }
  };

  const onPaymentClose = () => {
    setOpenRecordPayment(false);
  };

  const onPaymentRecord = (payment) => {
    setPayment(payment.amount > 0 ? payment : null);
    setOpenRecordPayment(false);
  };

  const onInvoiceNumberEdit = (number) => {
    setInvoiceId(number);
  };

  return (
    <div>
      <Grid container spacing={2} className={classes.root}>
        {loader && (
          <div
            style={{
              position: "fixed",
              left: "50%",
              top: "50%",
              zIndex: "900",
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        )}
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            <OrderHeader
              label={headerLabel}
              noEdit={record != null}
              prefix={orderPrefix}
              displayId={
                !record ? invoiceId : record?.invoice_id ?? record?.display_id
              }
              id={invoiceId}
              autoId={autoInvoiceId}
              onNumberChange={onInvoiceNumberEdit}
              startLabel={startLabel}
            />
            <Grid
              container
              style={{
                position: "relative",
                width: "100%",
                marginTop: 24,
              }}
            >
              <Grid
                item
                xs={9}
                className={classes.addInvoiceContainer}
                // style={{ height: `${88 * scale}vh` }}
              >
                <Grid
                  container
                  style={{
                    borderBottom: "4px solid #E5E5E5",
                    paddingBottom: 22,
                  }}
                >
                  <Grid item xs={8}>
                    <CustomerDetails
                      label="Bill To"
                      customer={customer}
                      noEdit={record != null}
                      onCustomer={handleCustomer}
                    />
                  </Grid>
                  <Grid item xs={4} style={{ paddingLeft: 35 }}>
                    <Typography
                      style={{
                        fontWeight: 600,
                        color: "#1A1A1A",
                        paddingBottom: 12,
                      }}
                    >
                      {dateLabel}
                    </Typography>
                    <TextField
                      InputProps={{
                        style: { width: 164, marginBottom: 2 },
                        defaultValue: toLocaleISOString(
                          order.invoice_date
                            ? new Date(order.invoice_date)
                            : new Date()
                        ),
                      }}
                      inputProps={{
                        style: {
                          color: invDateFocus ? "#1A1A1A" : "#999999",
                          fontSize: 12,
                        },
                      }}
                      type="date"
                      variant="outlined"
                      color="secondary"
                      onFocus={() => {
                        setInvDateError(false);
                        setInvDateFocus(true);
                      }}
                      onBlur={(e) => {
                        if (Date.parse(e.target.value)) {
                          setOrder({
                            ...order,
                            invoice_date: new Date(
                              e.target.value
                            ).toISOString(),
                          });
                        } else {
                          setInvDateError(true);
                        }
                        setInvDateFocus(false);
                      }}
                    />
                    <br />
                    <span
                      style={{
                        fontSize: 12,
                        color: invDateError ? "red" : "white",
                      }}
                    >
                      Enter Valid Date
                    </span>
                  </Grid>
                  <Grid item xs={12}>
                    {customer && (
                      <CustomerAddress
                        customer={customer}
                        billingAddress={customer?.billing_address}
                        shippingAddress={shippingAddress}
                        isSameAddress={compareBillAndShipAddress(
                          customer?.billing_address,
                          shippingAddress
                        )}
                        onCustomer={handleCustomer}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid container style={{ marginTop: 24, position: "relative" }}>
                  <BaseForm
                    initValues={initValues}
                    record={order}
                    scale={scale}
                    setRecord={setOrder}
                    tableHeight={46}
                    type={type}
                    enableDiscount={enableDescount}
                    enableCoupon={enableCoupon}
                    enableShipping={true}
                    enablePackaging={true}
                    enableRoundOff={true}
                    enablePmtCharge={true}
                    enablePmtDiscount={true}
                  />
                </Grid>
                <Grid className={classes.bottomSheet}>
                  <BaseFormFooter record={order} />
                </Grid>
              </Grid>
              <Grid item xs={3} style={{ paddingLeft: 24 }}>
                <BaseCTAs
                  onSave={() => onCreateOrUpdate(false)}
                  onCancel={onFormClose}
                />
                {type !== "ESTIMATE" && (
                  <OrderPaymentSide
                    payment={payment}
                    dueAmount={dueAmount}
                    paymentDetails={paymentDetails}
                    onPaymentEdit={onPaymentEdit}
                    setOpenRecordPayment={setOpenRecordPayment}
                  />
                )}
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      {alertMessage && (
        <ErrorAlert
          message={alertMessage}
          open={alertMessage ? true : false}
          onClose={() => setAlertMessage(null)}
        />
      )}
      {openRecordPayment && (
        <RecordPaymentModal
          open={openRecordPayment}
          payment={payment}
          amount={dueAmount}
          onClose={onPaymentClose}
          onRecord={onPaymentRecord}
        />
      )}
      {confirmModal && (
        <ConfirmModal
          open={confirmModal}
          message="Negative due amount will be captured as advance from Customer."
          onClose={onCloseConfirmModal}
          onConfirm={() => onCreateOrUpdate(true)}
        />
      )}
    </div>
  );
};

export default OrderForm;
