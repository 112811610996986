import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dueIn: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.common.white,
  },
  dueInWarn: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
  },
  expired: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.common.white,
  },
  activePlan: {},
  upgrade: {
    background: "#1641DB",
    color: "#FFFFFF",
    borderRadius: 20,
    padding: "6px 20px",
    marginLeft: "8px",
    fontWeight: 500,
    fontSize: 14,
    "&:hover": {
      background: "#1641DB",
    },
    "&:focus": {
      outline: "none !important",
    },
  },
  plansmessage: {
    display: "flex",
  },
  runningplan: {
    color: "#1A1A1A",
    background: "#F9F9F9",
    padding: "8px 12px ",
    borderRadius: "8px",
    margin: "auto",
    display: "flex",
    alignItems: "center",
  },
  planexpired: {
    background: "#FF6060",
    color: "white",
    padding: "6px 10px",
    borderRadius: "5px",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
  },
  activeforever: {
    background: "green",
    color: "white",
    padding: "6px 10px",
    borderRadius: "5px",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
  },
}));

export default useStyles;
