import React from "react";
import {
  cardStyle,
  smallCardImage,
  smallCardImageFallback,
} from "../../formStyles";
import Controls from "../../../../../../components/controls/Controls";
import { NoPicIcon } from "../../../../../../icons";
const CategoryCard = ({ category, leftPadded = false, selected, onChange }) => {
  return (
    <div
      style={{
        marginLeft: leftPadded ? "20px" : "0px",
        marginTop: leftPadded ? "4px" : "12px",
      }}
      className={cardStyle}
    >
      {category.thumbnail ? (
        <img
          src={category.thumbnail}
          alt={`${category.name}-thumbnail`}
          className={smallCardImage}
        />
      ) : (
        <div className={smallCardImageFallback}>
          <NoPicIcon />
        </div>
      )}
      <p
        style={{
          flexGrow: 1,
          marginLeft: "8px",
          marginBottom: "0px",
          fontSize: "14px",
          lineHeight: "20px",
          color: "#1a1a1a",
        }}
      >
        {category.name}
      </p>
      <Controls.Checkbox
        style={{ margin: 0, padding: 0 }}
        checkboxStyles={{ padding: 0, margin: 0 }}
        value={selected}
        onChange={(e) => {
          onChange(e.target.checked, category);
        }}
      />
    </div>
  );
};
export default CategoryCard;
