import React from "react";

export default function whatsappShare() {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.05" cx="30" cy="30" r="30" fill="#2DB842" />
      <path
        d="M10 50L12.8256 39.7289C11.0824 36.723 10.1657 33.3126 10.1668 29.8198C10.1713 18.8912 19.1058 10 30.0834 10C35.4114 10.0024 40.412 12.0666 44.1718 15.813C47.9324 19.5595 50.0022 24.5393 50 29.8353C49.9954 40.7642 41.0604 49.6562 30.0842 49.6562C30.0834 49.6562 30.0847 49.6562 30.0842 49.6562H30.0753C26.7422 49.6551 23.467 48.8222 20.5582 47.2439L10 50Z"
        fill="url(#paint0_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.4555 20.5592C24.051 19.6639 23.6251 19.646 23.2403 19.6303C22.9255 19.6169 22.5652 19.6177 22.2054 19.6177C21.8453 19.6177 21.2602 19.7524 20.7654 20.2904C20.2703 20.8283 18.875 22.1289 18.875 24.7739C18.875 27.4192 20.8104 29.9752 21.0802 30.3342C21.3502 30.6926 24.8162 36.2941 30.3053 38.449C34.8672 40.2399 35.7956 39.8837 36.7858 39.794C37.776 39.7044 39.9808 38.4938 40.4309 37.2384C40.881 35.9832 40.881 34.9073 40.746 34.6824C40.611 34.4583 40.2509 34.3239 39.7108 34.0551C39.1707 33.7862 36.5157 32.4854 36.0206 32.3062C35.5255 32.127 35.1654 32.0374 34.8054 32.5756C34.4453 33.1133 33.4109 34.3239 33.0959 34.6824C32.7808 35.0417 32.4657 35.0865 31.9256 34.8176C31.3855 34.548 29.6462 33.9808 27.5828 32.1493C25.9774 30.7245 24.8935 28.9647 24.5785 28.4264C24.2634 27.8888 24.5447 27.5975 24.8156 27.3295C25.0581 27.0886 25.3557 26.7019 25.6258 26.388C25.8952 26.074 25.9853 25.85 26.1653 25.4915C26.3453 25.1325 26.2553 24.8186 26.1203 24.5498C25.9853 24.2809 24.9357 21.6222 24.4555 20.5592Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="30"
          y1="50"
          x2="30"
          y2="10.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#20B038" />
          <stop offset="1" stopColor="#60D66A" />
        </linearGradient>
      </defs>
    </svg>
  );
}
