import { Paper, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { centerContainer, layoutCenterContainer } from "../../../styles/common";
import HeaderBreadcrumbs from "../../../components/common/HeaderBreadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import { fetchApps, getPluginConfig } from "../../../redux/apps/appActions";
import useStyles from "../components/ConfigStyles";
import { useState } from "react";
import { ApiKeyForm } from "./components/ApiKeyForm";
import { SettingsMessage } from "./components/SettingsMessage";
import PageLoader from "../../../components/Layout/PageLoader";
import FilledEditButton from "../../../components/buttons/FilledEditButton";

const list = [
  {
    label: "App Store",
    link: "/apps",
  },
  { label: "Baat", link: "/apps/baat" },
  {
    label: "Configure",
  },
];

export const BaatConfig = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.store);
  const appState = useSelector((state) => state.apps);
  const apps = appState.apps;

  const [loading, setLoading] = useState(true);
  const [showSetting, setShowSetting] = useState(true);

  useEffect(() => {
    if (apps.length === 0) {
      dispatch(fetchApps());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (storeData.fetched) {
      dispatch(getPluginConfig("baat"));
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [storeData]);

  if (loading) return <PageLoader />;

  return (
    <div className={layoutCenterContainer}>
      <div className={centerContainer}>
        <div className={classes.breadcrumbsContainer}>
          <div className={classes.backDiv}>
            <HeaderBreadcrumbs list={list} />
          </div>
        </div>
        <Paper elevation={0} className={classes.paper}>
          <div className={classes.headingButtonSection}>
            <Typography className={classes.formHead}>
              Baat Configuration Settings
            </Typography>
            {appState.baat["X-API-KEY"] && showSetting && (
              <FilledEditButton onClick={() => setShowSetting(false)} />
            )}
          </div>
          <div className={classes.paperContent}>
            {appState.baat["X-API-KEY"] && showSetting ? (
              <SettingsMessage />
            ) : (
              <ApiKeyForm
                showSetting={showSetting}
                setShowSetting={setShowSetting}
              />
            )}
          </div>
        </Paper>
      </div>
    </div>
  );
};
