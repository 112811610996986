import React from "react";
import { Input } from "@material-ui/core";

const ItemInput = ({
  name,
  value,
  type,
  itemIndex,
  startAdornment,
  className,
  onChange,
  textAlign,
  onBlur,
  onFocus,
  error,
}) => {
  return (
    <Input
      disableUnderline
      placeholder="00.00"
      className={className}
      style={{
        fontSize: 12,
        border: "1px dashed",
        borderColor: error ? "#FF3020" : "#1641DB",
        borderRadius: 6,
        paddingLeft: textAlign ? 0 : 12,
      }}
      inputProps={{
        style: {
          fontSize: 12,
          textAlign: textAlign ? textAlign : "left",
          cursor: "pointer",
        },
      }}
      type={type}
      startAdornment={startAdornment}
      value={value}
      name={name}
      onChange={(e) => {
        typeof onChange == "function" &&
          onChange(itemIndex, e.target.name, e.target.value);
      }}
      onFocus={onFocus}
      onBlur={(e) => {
        typeof onBlur == "function" &&
          onBlur(itemIndex, e.target.name, e.target.value);
      }}
    />
  );
};
export default ItemInput;
