import React, { useState } from "react";
import { Button, TextField } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.02em",
    textAlign: "center",
    color: theme.palette.secondary.main,
  },
  textinput: {
    width: 120,
    "& fieldset": {
      border: "none",
    },
  },
  button: {
    "&:focus": {
      outline: "none",
    },
  },
  grouped: {
    minWidth: "24px",
  },
  groupedroot: {
    border: "1px solid",
    borderColor: theme.palette.secondary.main,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "6px",
    margin: "auto",
    width: "40%",
  },
  textsizesmall: {
    padding: "8px",
  },
  removeicon: {
    fill: theme.palette.secondary.main,
    width: "20px",
  },
  addicon: {
    fill: theme.palette.secondary.main,
    width: "20px",
  },
}));

const PlusMinusButton = ({ value, increment, decrement, style }) => {
  const classes = useStyles();
  const [userValue, setUserValue] = useState(value ? parseInt(value) : 0);
  const incrementVal = () => {
    setUserValue(userValue + 1);
    increment(value + 1);
  };
  const decrementVal = () => {
    if (value === 1) {
      return;
    }
    setUserValue(userValue - 1);
    decrement(value - 1);
  };

  const validate = () => {
    if (userValue && parseInt(userValue) > 0) {
      increment(parseInt(userValue));
    } else {
      setUserValue(0);
      decrement(0);
    }
  };

  return (
    <div className={classes.groupedroot} style={style ? style : {}}>
      <Button
        variant="text"
        onClick={decrementVal}
        className={classes.button}
        classes={{ textSizeSmall: classes.textsizesmall }}
      >
        <RemoveIcon className={classes.removeicon} />
      </Button>
      <TextField
        type="number"
        className={classes.textinput}
        value={userValue}
        onKeyDown={(e) => {
          if (
            e.key === "." ||
            e.key === "-" ||
            e.key === "e" ||
            e.key === "E"
          ) {
            e.preventDefault();
          }
        }}
        onChange={(e) => {
          setUserValue(parseInt(e.target.value));
        }}
        onBlur={validate}
        color="secondary"
        inputProps={{ style: { textAlign: "center" } }}
        InputProps={{ className: classes.text, disableUnderline: true }}
      />
      <Button
        variant="text"
        className={classes.button}
        classes={{ textSizeSmall: classes.textsizesmall }}
        onClick={incrementVal}
      >
        <AddIcon className={classes.addicon} />
      </Button>
    </div>
  );
};

export default PlusMinusButton;
