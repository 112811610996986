import React, { useEffect, useState } from "react";
import { Typography, Box, FormHelperText } from "@material-ui/core";
import { AlertTitle, Alert } from "@material-ui/lab";
import { useSelector, useDispatch } from "react-redux";
import Controls from "../../../components/controls/Controls";
import Toast from "../../../components/Layout/Toast";
import useStyles from "../styles";
import { UPDATE_ORDER, UPDATE_ORDERS } from "../../../redux/orders/orderTypes";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import Select from "../../../components/controls/Select";
import AlertBar from "../../../components/common/AlertBar";
import { CheckCircleOutlineOutlined, ReportOutlined } from "@material-ui/icons";
import { getStoreIdFromUrl } from "../../../utils/storeUtil";
import { CourierSelect } from "../../../components/controls/CourierSelect";
import { DimensionField } from "../../../components/controls/DimensionField";
import axios from "axios";

const ShiprocketForm = (props) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);

  const [location, setLocation] = useState({
    value: "none",
    touched: false,
    error: false,
  });

  const [pickupLocations, setPickupLocations] = useState(null);

  const [length, setLength] = useState({
    value: null,
    touched: false,
  });

  const [width, setWidth] = useState({
    value: null,
    touched: false,
  });

  const [height, setHeight] = useState({
    value: null,
    touched: false,
  });
  const [weight, setWeight] = useState({
    value: undefined,
    touched: false,
    error: false,
  });

  const [courier, setCourier] = useState({
    value: "none",
    touched: false,
    error: false,
  });
  const [couriers, setCouriers] = useState([]);
  const [courierflag, setCourierFlag] = useState(false);

  const [courierLoading, setCourierLoading] = useState(false);

  const [uniqueKey, setUniqueKey] = useState("");

  const [dimensionsError, setDimensionsError] = useState("");
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [combLoading, setComLoading] = useState(false);
  const [matchedOrder, setMatchedOrder] = useState({});

  const [filterOrdWeightAndDim, setFilterOrdWeightAndDim] = useState([]);
  const [filterOrdNotWeightAndDim, setFilterOrdNotWeightAndDim] = useState([]);
  const [filterOrdWeightAndNotDim, setFilterOrdWeightAndNotDim] = useState([]);
  const [filterOrdDimAndNotWeight, setFilterOrdDimAndNotWeight] = useState([]);
  const [notExistOrderAddress, setNotExistOrderAddress] = useState([]);

  const store = useSelector((state) => state.store.store);
  const orders = useSelector((state) => state.orders.orders);
  const dispatch = useDispatch();
  const disabledComBtn =
    filterOrdNotWeightAndDim.length > 0 ||
    filterOrdWeightAndNotDim.length > 0 ||
    filterOrdDimAndNotWeight.length > 0 ||
    notExistOrderAddress.length > 0;

  useEffect(() => {
    if (!props.bulkOrdDispatch) {
      let matchOrder =
        orders.filter((ord) => ord.id === props.order.id)[0] ?? null;
      setMatchedOrder(matchOrder ? matchOrder : {});
      let dim = matchOrder?.dimension?.split("X") ?? null;
      setLength({ value: dim && dim[0] ? dim[0] : null, touched: false });
      setWidth({ value: dim && dim[1] ? dim[1] : null, touched: false });
      setHeight({ value: dim && dim[2] ? dim[2] : null, touched: false });
      setWeight({ value: matchOrder?.weight ?? undefined, touched: false });
    }
    //eslint-disable-next-line
  }, [orders]);

  useEffect(() => {
    if (props.bulkOrdDispatch) {
      let updatedOrders = props.selectedOrders.map((od) => {
        let ord = { ...od };
        let dim = ord.dimension ? ord?.dimension?.split("X") : null;
        ord.dimension = {
          l: dim && dim[0] ? dim[0] : "",
          w: dim && dim[1] ? dim[1] : "",
          h: dim && dim[2] ? dim[2] : "",
        };
        return ord;
      });
      let filOrdWeightAndDim = updatedOrders.filter(
        (ord) =>
          ord?.weight !== "" &&
          ord?.weight !== undefined &&
          ord.dimension !== undefined &&
          !Object.values(ord?.dimension).includes("")
      );
      setFilterOrdWeightAndDim(filOrdWeightAndDim);

      let filOrdNotWeightAndDim = updatedOrders.filter(
        (ord) =>
          (ord?.weight === "" || ord?.weight === undefined) &&
          (ord?.dimension === undefined ||
            Object.values(ord?.dimension).includes(""))
      );
      setFilterOrdNotWeightAndDim(filOrdNotWeightAndDim);

      let filOrdDimAndNotWeight = updatedOrders.filter(
        (ord) =>
          (ord?.weight === "" || ord?.weight === undefined) &&
          ord?.dimension !== undefined &&
          !Object.values(ord?.dimension).includes("")
      );
      setFilterOrdDimAndNotWeight(filOrdDimAndNotWeight);

      let filOrdWeightAndNotDim = updatedOrders.filter(
        (ord) =>
          ord?.weight !== "" &&
          ord?.weight !== undefined &&
          (ord?.dimension === undefined ||
            Object.values(ord?.dimension).includes(""))
      );
      setFilterOrdWeightAndNotDim(filOrdWeightAndNotDim);
      let filterNotExistOrderAddress = props.selectedOrders.filter(
        (ord) => !ord?.order_address
      );
      setNotExistOrderAddress(filterNotExistOrderAddress);
    }
    //eslint-disable-next-line
  }, [props.selectedOrders, props.selectedItems]);

  useEffect(() => {
    const fetchPickupLocations = async () => {
      let shipOpt = props.shippingOption ?? "shiprocket";
      let app = shipOpt.toLowerCase();
      let accessToken = localStorage.getItem("access_token");
      try {
        let response = await fetch(
          `${process.env.REACT_APP_API}/api/v1/store/${store.slug}/pickup-locations?app=${app}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (!response.ok) {
          throw response;
        }
        let data = await response.json();
        console.log("pickup location", data);
        setPickupLocations(data.payload);
        setLoading(false);
      } catch (err) {
        setPickupLocations(null);
        setLoading(false);
      }
    };
    setLocation({
      value: "none",
      touched: false,
      error: false,
    });
    setCourier({
      value: "none",
      touched: false,
      error: false,
    });
    setCouriers([]);

    if (props.shippingOption) fetchPickupLocations();
    //eslint-disable-next-line
  }, [props.shippingOption]);

  const validateDimension = (value, type) => {
    if (isNaN(value) || Number(value) <= 0) {
      setDimensionsError(`${type} can accept only positive numeric values`);
      return true;
    } else if (
      dimensionsError.length &&
      dimensionsError.split(" ")[0] === type
    ) {
      setDimensionsError("");
      return false;
    }
  };

  const makeCourierRequest = async () => {
    let error = validation();

    if (!error) {
      if (!uniqueKey) {
        let key = `${props.shippingOption}|${location}|${length.value}|${width.value}|${height.value}|${weight.value}`;
        setUniqueKey(key);
      } else {
        let key = `${props.shippingOption}|${location}|${length.value}|${width.value}|${height.value}|${weight.value}`;
        if (key === uniqueKey) return;
        else {
          setUniqueKey(key);
          setCouriers([]);
        }
      }
      setCourierLoading(true);
      let app = props.shippingOption.toLowerCase();
      let accessToken = localStorage.getItem("access_token");
      let storeId = getStoreIdFromUrl();
      let pickupPinCode;
      pickupLocations.forEach((loc) => {
        if (loc.location_id === location.value) {
          pickupPinCode = loc.pin_code;
        }
      });

      try {
        let response;
        if (props.bulkOrdDispatch) {
          response = await fetch(
            `${process.env.REACT_APP_API}/api/v1/store/${storeId}/couriers?app=${app}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
        } else {
          response = await fetch(
            `${process.env.REACT_APP_API}/api/v1/store/${storeId}/couriers/rates?app=${app}&dimensions=${length.value}x${width.value}x${height.value}&from-pincode=${pickupPinCode}&weight=${weight.value}&orderId=${props.order.id}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
        }

        const res = await response.json();

        if (!response.ok) {
          throw new Error(res.message);
        }

        setCouriers(res.payload);
        setCourierLoading(false);
        setLoading(false);
      } catch (error) {
        setErrorMessage("Unable to fetch courier rates.");
        setShowError(true);
        setCourierLoading(false);
        setLoading(false);
        setCouriers([]);
      }
    }
  };

  const handleCourierSelect = (event) =>
    setCourier({ value: event.target.value, touched: true });

  const validation = () => {
    let error = false;
    if (location.value === "none") {
      error = true;
      setLocation({ ...location, touched: true, error: true });
    }

    if (!props.bulkOrdDispatch) {
      if (
        validateDimension(length.value, "Length") ||
        validateDimension(width.value, "Width") ||
        validateDimension(height.value, "Height")
      ) {
        error = true;
      }
      if (
        weight.value === undefined ||
        isNaN(weight.value) ||
        weight.value <= 0
      ) {
        error = true;
        setWeight({ ...weight, touched: true, error: true });
      }
    }

    return error;
  };

  const validateBeforeSubmit = () => {
    let error = false;
    if (
      props.shippingOption &&
      (props.shippingOption === "XPRESSBEES" ||
        props.shippingOption === "ITHINK" ||
        (props.shippingOption === "SHIPROCKET" && !props.bulkOrdDispatch)) &&
      courier.value === "none" &&
      !courierflag
    ) {
      error = true;
      setCourier({ ...courier, touched: true, error: true });
    }
    return error;
  };

  const addHandler = async () => {
    if (validation()) return;
    if (validateBeforeSubmit()) return;

    setComLoading(true);
    let shipOpt = props.shippingOption ?? "shiprocket";
    let app = shipOpt.toLowerCase();
    let accessToken = localStorage.getItem("access_token");
    try {
      if (!props.bulkOrdDispatch) {
        await axios.post(
          `${process.env.REACT_APP_API}/api/v1/store/${store.slug}/shipments?app=${app}`,
          {
            location_name: location.value,
            weight: +weight.value,
            length: +length.value,
            height: +height.value,
            breadth: +width.value,
            store_id: store.id,
            order_id: props.order.id,
            courier_id: courier.value === "none" ? null : courier.value,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        let moddedOrder = {
          ...matchedOrder,
          state: "SHIPPED",
          status: "IN_TRANSIT",
        };
        dispatch({
          type: UPDATE_ORDER,
          payload: moddedOrder,
        });
        props.setSuccessToast(`Succesfully added order to ${app}`);
        setComLoading(false);
        props.onClose(true);
      } else {
        await axios.post(
          `${process.env.REACT_APP_API}/api/v1/store/${store.slug}/shipments/bulk?app=${app}`,
          {
            location_name: location.value,
            courier_id: courier.value,
            order_ids: Object.keys(props.selectedItems),
            type: "shiprocketbulk",
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        let moddedOrders = props.selectedOrders.map((od) => {
          let ord = { ...od };
          ord.state = "SHIPPED";
          ord.status = "IN_TRANSIT";
          return ord;
        });
        dispatch({
          type: UPDATE_ORDERS,
          payload: moddedOrders,
        });
        props.setSuccessToast(`Succesfully added orders to ${app}`);
        setComLoading(false);
        props.onClose(true);
        props.setselectedItems({});
      }
      localStorage.setItem(
        "default_shipping_option",
        `${props.shippingOption}`
      );
    } catch (err) {
      setComLoading(false);
      let dataMsg = err.response.data;
      if (dataMsg && dataMsg.message) {
        let msg = dataMsg.message;
        let begpos = msg.indexOf("message");
        let endpos = msg.lastIndexOf("}");
        if (begpos > 0 && endpos > 0) {
          let errMsg = msg.substring(begpos + 10, endpos - 3);
          setErrorMessage(errMsg);
        } else {
          setErrorMessage(msg);
        }
      }
      setShowError(true);
    }
    setTimeout(() => setShowError(false), 2000);
  };

  const getOrderIds = (ordsArray) => {
    let ordIdsStr = "";
    ordsArray.forEach((ord, i) => {
      ordIdsStr =
        ordIdsStr +
        ord.display_id +
        `${ordsArray.length === i + 1 ? "" : ", "}`;
    });
    return ordIdsStr;
  };

  return (
    <>
      <div style={{ minHeight: 251 }}>
        <div
          className={classes.dividerGrid}
          style={{ display: "block", padding: "0px 0 2px", border: "none" }}
        >
          <Select
            name="pickupLocation"
            label="Location"
            placeholder="Select pickup location"
            value={location.value}
            options={
              pickupLocations
                ? pickupLocations.map((location) => {
                    return {
                      id: location.location_id,
                      title: location.pickup_location,
                    };
                  })
                : []
            }
            onChange={(e) => {
              setLocation({ value: e.target.value, touched: true });
            }}
            error={location.error ? "Please select a pickup location" : ""}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          />
          {!loading && (!pickupLocations || pickupLocations.length === 0) && (
            <>
              <Alert
                severity="error"
                style={{ width: "100%", marginBottom: 10 }}
              >
                <AlertTitle
                  style={{
                    color: "#1a1a1a",
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                >
                  Note
                </AlertTitle>
                <span
                  style={{
                    color: "#1a1a1a",
                    fontSize: "12px",
                    lineHeight: "16px",
                  }}
                >
                  Please add a pickup location in your{" "}
                  {props.shippingOption?.toLowerCase()} account
                </span>
              </Alert>
            </>
          )}
        </div>
        {!props.bulkOrdDispatch ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography className={classes.subHeading} style={{}}>
                Dimension
              </Typography>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <DimensionField
                  value={length.value}
                  placeholder="Length"
                  onChange={(e) => {
                    validateDimension(e.target.value, "Length");
                    setLength({ value: e.target.value, touched: true });
                  }}
                />
                <p style={{ flexBasis: "8%", margin: 0, textAlign: "center" }}>
                  x
                </p>
                <DimensionField
                  value={width.value}
                  placeholder="Width"
                  onChange={(e) => {
                    validateDimension(e.target.value, "Width");
                    setWidth({ value: e.target.value, touched: true });
                  }}
                />
                <p style={{ flexBasis: "8%", margin: 0, textAlign: "center" }}>
                  x
                </p>
                <DimensionField
                  value={height.value}
                  placeholder="Height"
                  onChange={(e) => {
                    validateDimension(e.target.value, "Height");
                    setHeight({ value: e.target.value, touched: true });
                  }}
                />
              </Box>
            </div>
            {dimensionsError.length ? (
              <FormHelperText
                style={{
                  color: "red",
                  fontSize: "12px",
                  marginLeft: "7.5rem",
                  marginBottom: "-16px",
                }}
              >
                {dimensionsError}
              </FormHelperText>
            ) : (
              <></>
            )}

            <div
              className={classes.dividerGrid}
              style={{
                display: "block",
                padding: "4px 0 2px",
                border: "none",
                marginTop: 16,
              }}
            >
              <Controls.Input
                name="weight"
                label="Weight"
                type="number"
                value={weight.value}
                onChange={(e) => {
                  setWeight({ value: e.target.value, touched: true });
                }}
                error={
                  weight.error ||
                  ((isNaN(weight.value) ||
                    Number(weight.value) <= 0 ||
                    !weight.value) &&
                    weight.touched)
                    ? "Please input a valid number"
                    : ""
                }
                endAdornment={
                  <span
                    style={{
                      fontSize: "12px",
                      color: "#999999",
                      lineHeight: "16px",
                    }}
                  >
                    Grams
                  </span>
                }
                labelWidth={110}
              />
            </div>
          </>
        ) : (
          <div style={{ marginTop: 16 }}>
            {filterOrdWeightAndDim.length > 0 && (
              <AlertBar
                heading={`${filterOrdWeightAndDim.length} ${
                  filterOrdWeightAndDim.length > 1 ? "orders" : "order"
                } have weight and dimension`}
                icon={
                  <CheckCircleOutlineOutlined
                    style={{ color: "#00A55D", fonSize: 20, marginTop: -1 }}
                  />
                }
                backgroundColor="#EEFFEF"
              />
            )}
            {filterOrdNotWeightAndDim.length > 0 && (
              <AlertBar
                heading={`${filterOrdNotWeightAndDim.length} ${
                  filterOrdNotWeightAndDim.length > 1 ? "orders" : "order"
                } have missing weight and dimension`}
                orderIds={getOrderIds(filterOrdNotWeightAndDim)}
                icon={
                  <ReportOutlined
                    style={{ color: "#FF0000", fonSize: 20, marginTop: -1 }}
                  />
                }
                backgroundColor="#FFEEEE"
              />
            )}
            {filterOrdWeightAndNotDim.length > 0 && (
              <AlertBar
                heading={`${filterOrdWeightAndNotDim.length} ${
                  filterOrdWeightAndNotDim.length > 1 ? "orders" : "order"
                } have weight but missing dimension`}
                orderIds={getOrderIds(filterOrdWeightAndNotDim)}
                icon={
                  <ReportOutlined
                    style={{ color: "#FF0000", fonSize: 20, marginTop: -1 }}
                  />
                }
                backgroundColor="#FFEEEE"
              />
            )}
            {filterOrdDimAndNotWeight.length > 0 && (
              <AlertBar
                heading={`${filterOrdDimAndNotWeight.length} ${
                  filterOrdDimAndNotWeight.length > 1 ? "orders" : "order"
                } have dimension but missing weight`}
                orderIds={getOrderIds(filterOrdDimAndNotWeight)}
                icon={
                  <ReportOutlined
                    style={{ color: "#FF0000", fonSize: 20, marginTop: -1 }}
                  />
                }
                backgroundColor="#FFEEEE"
              />
            )}
            {notExistOrderAddress.length > 0 && (
              <AlertBar
                heading={`${notExistOrderAddress.length} ${
                  notExistOrderAddress.length > 1 ? "orders" : "order"
                } does not have an address`}
                orderIds={getOrderIds(notExistOrderAddress)}
                icon={
                  <ReportOutlined
                    style={{ color: "#FF0000", fonSize: 20, marginTop: -1 }}
                  />
                }
                backgroundColor="#FFEEEE"
              />
            )}
          </div>
        )}
        {props.shippingOption === "XPRESSBEES" ||
        props.shippingOption === "ITHINK" ||
        props.shippingOption === "DELHIVERY" ||
        props.shippingOption === "SHIPWAY" ||
        props.shippingOption === "ESHIPZ" ||
        (props.shippingOption === "SHIPROCKET" && !props.bulkOrdDispatch) ? (
          <div
            className={classes.dividerGrid}
            style={{
              display: "block",
              padding: "4px 0 2px",
              border: "none",
            }}
          >
            <div className={courierflag ? classes.whiteLayover : ""}>
              <CourierSelect
                name="courier"
                label="Courier"
                placeholder="Select Courier"
                value={courier.value}
                slug={props.shippingOption}
                options={couriers}
                loading={courierLoading}
                onChange={handleCourierSelect}
                onFocus={makeCourierRequest}
                error={
                  courier.error && !courierflag ? "Please select a courier" : ""
                }
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                disabled={courierflag ? true : false}
                bulkOrder={props.bulkOrdDispatch ? true : false}
              />
            </div>
            {props.shippingOption === "SHIPROCKET" && (
              <Controls.Checkbox
                name="courierFlag"
                label={
                  <span style={{ fontWeight: 400 }}>
                    {" "}
                    Select courier via Ship Rocket Control Panel
                  </span>
                }
                checkboxStyles={{ padding: 0, marginLeft: 11, marginRight: 12 }}
                value={courierflag}
                style={{
                  marginLeft: 110,
                  fontWeight: 500,
                }}
                color="secondary"
                onChange={(e) => {
                  setCourierFlag(e.target.checked);
                }}
              />
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className={classes.bottomStickyBtnRoot}>
        <div className={classes.bottomStickyBtnCont}>
          <CombinedButtons
            style={{ width: 200 }}
            solidBtnText={`Add Shipment ${
              props.bulkOrdDispatch
                ? " (" + props.selectedOrders.length + ")"
                : ""
            }`}
            outlinedBtnAction={props.onClose}
            solidBtnAction={addHandler}
            loading={combLoading}
            disabled={disabledComBtn}
          />
        </div>
      </div>
      <Toast
        message={`Failed to add shipment at ${props.shippingOption.toLowerCase()}: ${errorMessage}`}
        open={showError}
        close={() => {
          setShowError(false);
          setErrorMessage(null);
        }}
        severity="error"
        autoClose
      />
    </>
  );
};

export default ShiprocketForm;
