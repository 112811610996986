import { CircularProgress, Grid } from "@material-ui/core";
import useStyles from "./styles";
import {
  startEstimateLoader,
  stopEstimateLoader,
} from "../../redux/estimateOrders/estimateActions";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { getPdfLink, fetchInvoiceById } from "../../utils/invoiceUtil";
import { downloadDocument, printDocument } from "../../utils/documentUtil";
import { updateCalculation } from "../../utils/invoiceHelper";
import BaseView from "../../components/invoiceorder/BaseView";
import { ViewBaseCTAs } from "../../components/invoiceorder/ViewBaseCTAs";
import HeaderBreadcrumbs from "../../components/common/HeaderBreadcrumbs";
import PageLoader from "../../components/Layout/PageLoader";

const ViewEstimate = () => {
  const params = useParams();
  const history = useHistory();
  const classes = useStyles();
  const store = useSelector((state) => state.store.store);
  const estimatesMap = useSelector((state) => state.estimates.estimatesMap);
  const loading = useSelector((state) => state.estimates.loader);
  const currEstimate = estimatesMap[params.estimateid];
  const [record, setRecord] = useState(currEstimate);
  const [loader, setLoader] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!record) {
      fetchInvoiceById(params.estimateid).then((rec) => {
        if (rec) {
          let calcRec = updateCalculation(
            { ...rec },
            true,
            rec.final_sale_price - rec.due_amount
          );

          setRecord(calcRec);
          setLoader(false);
        }
      });
    } else {
      let calcRec = updateCalculation(
        { ...record },
        true,
        record.final_sale_price - record.due_amount
      );
      setRecord(calcRec);
      setLoader(false);
    }
    // eslint-disable-next-line
  }, [params.estimateid]);

  const viewPdf = () => {
    dispatch(startEstimateLoader());
    getPdfLink(record.id).then((link) => {
      window.open(link);
      dispatch(stopEstimateLoader());
    });
  };

  const downloadInvoice = async () => {
    dispatch(startEstimateLoader());
    await downloadDocument(
      store,
      "estimate",
      record.id,
      record.invoice_id + ".pdf"
    );
    dispatch(stopEstimateLoader());
  };

  const onEdit = () => {
    history.push(`./${encodeURIComponent(params.estimateid)}/edit`);
  };

  const onPrint = async () => {
    dispatch(startEstimateLoader());
    await printDocument(store, "estimate", record.id);
    dispatch(stopEstimateLoader());
  };

  return (
    <div>
      <Grid container spacing={2} className={classes.root}>
        {loading && (
          <div
            style={{
              position: "fixed",
              left: "50%",
              top: "50%",
              zIndex: "900",
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        )}
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            <HeaderBreadcrumbs
              list={[
                {
                  label: "Estimates",
                  link: "/estimates",
                },
                {
                  label: record?.invoice_id,
                },
              ]}
            />
            {!loader ? (
              <Grid
                container
                style={{
                  position: "relative",
                  width: "100%",
                  marginTop: 24,
                }}
              >
                <BaseView
                  record={record}
                  store={store}
                  dateLabel="Estimate Date"
                  enableDescount={true}
                  enableShipping={true}
                  enablePackaging={true}
                  enableRoundOff={true}
                />
                <Grid item xs={3} style={{ paddingLeft: 24 }}>
                  <ViewBaseCTAs
                    onDownload={downloadInvoice}
                    viewPdf={viewPdf}
                    onEdit={onEdit}
                    onPrint={onPrint}
                  />
                </Grid>
              </Grid>
            ) : (
              <PageLoader />
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ViewEstimate;
