import axios from "axios";

export const getSiteAttributesForAnalytics = async (slug) => {
  let accessToken = localStorage.getItem("access_token");

  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API}/api/v1/org/store/${slug}/group-names/site_settings/attributes`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data.payload;
  } catch (err) {
    console.log("Failure while fetching analytics data", err);
  }
};

export const getAttributeValue = (attributeName, siteAttributes) => {
  let value = "";
  siteAttributes.forEach((attribute) => {
    if (attribute.name === attributeName) {
      value = attribute.value;
    }
  });
  return value;
};
