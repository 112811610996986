import React from "react";
import Product from "./Product";
import ProductNotFound from "./ProductNotFound";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  marginTop: 0,
  padding: 0,
  ...draggableStyle,
});

const ProductList = ({
  filter,
  products,
  assignProductsPage,
  productUnassign,
  onCheckboxChange,
  onDragEnd,
  manuallyOperation,
  unassignSku,
}) => {
  if (products.length === 0) {
    return <ProductNotFound />;
  }

  if (filter && filter === "catManual") {
    return (
      <div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="product" type="PRODUCT">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  backgroundColor: snapshot.isDraggingOver ? "" : "",
                }}
              >
                {products.length > 0 &&
                  products.map((product, index) => {
                    return (
                      <Draggable
                        key={product.prod_sku}
                        draggableId={product.prod_sku}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <Product
                              product={product}
                              filter={filter}
                              handleProps={provided.dragHandleProps}
                              manuallyOperation={manuallyOperation}
                              assignProductsPage={assignProductsPage}
                              onCheckboxChange={onCheckboxChange}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                {provided.placeholder /* Ensure to include the placeholder */}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  } else {
    return (
      <div
        style={{
          ...(assignProductsPage && { marginBottom: "45px" }),
        }}
      >
        {products.map((product, index) => (
          <Product
            key={index}
            product={product}
            filter={filter}
            assignProductsPage={assignProductsPage}
            onCheckboxChange={onCheckboxChange}
            productUnassign={productUnassign}
            unassignSku={unassignSku}
          />
        ))}
      </div>
    );
  }
};

export default ProductList;
