import { makeStyles } from "@material-ui/styles";
export default makeStyles(() => ({
  flexBox: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "space-between",
  },
  horizontalLine: {
    color: "#E6E6E6",
    marginTop: 3,
    marginBottom: 21,
  },
  divider: {
    color: "#E6E6E6",
    marginTop: 6,
    marginBottom: 6,
  },
  clickableHeading: {
    fontSize: "12px",
    lineHeight: "20px",
    fontWeight: 500,
    color: "#1641DB",
    cursor: "pointer",
  },
  formLabel: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 600,
    marginBottom: "15px",
  },
  colorPicker: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #E6E6E6",
    padding: 4,
    borderRadius: 4,
  },
}));
