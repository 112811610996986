import { Typography } from "@material-ui/core";
import React from "react";
import { DeleteIcon, LocationIcon } from "../../../icons";
import EditPen from "../../../icons/EditPen";
import { rowFlexOnlyJustifyBetween } from "../../../styles/common";
import useStyles from "../styles";

const AddressCard = ({
  address,
  onChange,
  isEditDelete = false,
  onDelete,
  onEdit,
  showChange = false,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.cardContainer}>
      <div className={rowFlexOnlyJustifyBetween}>
        <div
          style={{ display: "flex", alignItems: "flex-start", marginRight: 12 }}
        >
          <LocationIcon fill={"#1641DB"} />
          <div style={{ marginLeft: 10 }}>
            <Typography className={classes.primaryTxt}>
              {address?.customer_name && address.customer_name}
            </Typography>
            <Typography className={classes.mobileTxt}>
              {address?.mobile && address.mobile}
            </Typography>
            <Typography className={classes.secondaryTxt}>
              {address?.street1 && `${address.street1}, `}
              {address?.street2 && `${address.street2}, `}
              {address?.city && `${address.city}, `}
              {address?.state && `${address.state} - `}
              {address?.pincode && `${address.pincode}`}
            </Typography>
          </div>
        </div>
        {showChange && (
          <div>
            <span
              style={{
                fontSize: 14,
                color: "#1641DB",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={onChange}
            >
              Change
            </span>
          </div>
        )}
        {isEditDelete && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <div style={{ display: "flex" }}>
              <div
                onClick={onDelete}
                style={{ marginRight: 12, cursor: "pointer" }}
              >
                <DeleteIcon stroke="#ED0000" />
              </div>
              <div
                style={{
                  cursor: "pointer",
                }}
                onClick={onEdit}
              >
                <EditPen />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddressCard;
