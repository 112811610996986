import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const RoadmapIcon = ({ viewBox = '0 0 128 128' }) => {
    return (
        <SvgIcon viewBox={viewBox}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox}>
                <title>Shoopy  Roadmap</title>
                <path d="M32,72a8,8,0,1,0,8,8A8,8,0,0,0,32,72Zm0,12a4,4,0,1,1,4-4A4,4,0,0,1,32,84Z"></path>
                <path d="M96,12A20,20,0,0,0,77.68,24H50.14a12,12,0,0,0-8.48,20.49L83.51,86.34A8,8,0,0,1,77.86,100H46.39c3-5.44,5.61-12.41,5.61-20a20,20,0,0,0-40,0c0,10.11,4.67,19.11,8.6,24.88C23.74,109.49,29.35,116,32,116s8.26-6.51,11.4-11.12L44,104H77.86a12,12,0,0,0,8.48-20.49L44.49,41.66A8,8,0,0,1,50.14,28H76.4a20.25,20.25,0,0,0-.4,4c0,10.11,4.67,19.11,8.6,24.88C87.74,61.49,93.35,68,96,68s8.26-6.51,11.4-11.12C111.33,51.11,116,42.11,116,32A20,20,0,0,0,96,12ZM32,111.79C28.91,109.57,16,95.93,16,80a16,16,0,0,1,32,0C48,95.93,35.09,109.57,32,111.79Zm64-48C92.91,61.57,80,47.93,80,32a16,16,0,0,1,32,0C112,47.93,99.09,61.57,96,63.79Z"></path>
                <path d="M96,24a8,8,0,1,0,8,8A8,8,0,0,0,96,24Zm0,12a4,4,0,1,1,4-4A4,4,0,0,1,96,36Z"></path>
            </svg>
        </SvgIcon>
    );
};

export default RoadmapIcon;
