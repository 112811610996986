import {
  Typography,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import Controls from "../../../components/controls/Controls";
import { updateStore } from "../../../redux/store/storeActions";
import useStyles from "../styles";
import { BUSSINESS_TYPES } from "../../../utils/utils";
import FormModal from "../../../components/common/FormModal";

const AddBankModal = ({ openAddBank, setOpenAddBank }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const store = useSelector((state) => state.store.store);
  const [account, setAccount] = useState({
    name: "",
    bank: "",
    ifsc: "",
    number: "",
    renumber: "",
    bizType: "",
    accType: "",
    email: "",
    name_error: false,
    ifsc_error: false,
    number_error: false,
    renumber_error: false,
    type_error: false,
    email_error: false,
  });
  const [ifscDetails, setIfscDetails] = useState({});

  useEffect(() => {
    setAccount({
      ...account,
      name: store.ac_holder_name ? store.ac_holder_name : "",
      bank: store.bank_name ? store.bank_name : "",
      ifsc: store.ifsc_code ? store.ifsc_code : "",
      number: store.account_number ? store.account_number : "",
      renumber: store.account_number ? store.account_number : "",
      bizType: store.business_type ? store.business_type : "individual",
      accType: store.ac_type ? store.ac_type : "",
      email: store.email ? store.email : "",
    });
    // eslint-disable-next-line
  }, [store]);

  const changeHandler = (e) => {
    setAccount({ ...account, [e.target.name]: e.target.value });
  };

  const validate = () => {
    var name_error,
      renumber_error,
      ifsc_error,
      number_error,
      type_error,
      email_error = false;
    if (account.name.trim() === "") name_error = true;
    if (account.accType.trim() === "") type_error = true;
    if (account.ifsc.trim().length !== 11) ifsc_error = true;
    if (account.number.trim() === "") number_error = true;
    if (!/\S+@\S+\.\S+/.test(account.email)) email_error = true;
    if (account.number.trim() !== account.renumber.trim())
      renumber_error = true;

    setAccount({
      ...account,
      name_error,
      renumber_error,
      ifsc_error,
      number_error,
      type_error,
      email_error,
    });

    if (
      name_error ||
      ifsc_error ||
      number_error ||
      renumber_error ||
      type_error ||
      email_error
    ) {
      return false;
    }
    return true;
  };

  const onAdd = () => {
    if (!validate()) {
      return;
    }
    dispatch(
      updateStore({
        ac_holder_name: account.name,
        bank_name: account.bank,
        ifsc_code: account.ifsc,
        account_number: account.number,
        business_type: account.bizType,
        ac_type: account.accType,
        email: account.email,
      })
    );
    setOpenAddBank(false);
  };

  const bizNametoDisplay = (key) => {
    if (key === "other" || key === "not_yet_registered") return "Bussiness";
    return BUSSINESS_TYPES.find((biz) => biz.name === key).display_name;
  };

  const initFocus = {
    name: false,
    number: false,
    ifsc: false,
    renumber: false,
    bizType: false,
    accType: false,
    email: false,
  };

  const [focus, setFocus] = useState(initFocus);

  const infoBox = (text) => {
    return (
      <Fragment>
        <div
          style={{
            position: "absolute",
            left: -32,
            top: 21,
            background: "#e1e2fd",
            height: 17,
            width: 17,
            transform: "rotate(45deg)",
            zIndex: 5,
          }}
        />
        <div
          style={{
            position: "absolute",
            left: -221,
            top: 10,
            background: "#e1e2fd",
            padding: 10,
            width: 200,
            zIndex: 5,
            borderRadius: 6,
            borderTopRightRadius: 4,
          }}
        >
          <Typography
            style={{ fontSize: 12, fontWeight: 14, color: "#333333" }}
          >
            {text}
          </Typography>
        </div>
      </Fragment>
    );
  };

  return (
    <FormModal
      open={openAddBank}
      onClose={() => setOpenAddBank(false)}
      onSave={onAdd}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      heading="Add Bank Account"
    >
      <div
        className={classes.dividerGrid}
        style={{
          paddingBottom: 12,
          paddingTop: 18,
          position: "relative",
          display: "block",
        }}
      >
        {focus.bizType && infoBox("Select the type of your business")}
        <Controls.Select
          name="bizType"
          value={account.bizType}
          variant="outlined"
          label="Bussiness Type"
          onChange={changeHandler}
          onFocus={() => {
            setFocus({ ...initFocus, bizType: true });
          }}
          onBlur={() => {
            setFocus({ ...initFocus, bizType: false });
          }}
          options={BUSSINESS_TYPES}
        />
      </div>

      <div
        className={classes.dividerGrid}
        style={{
          paddingBottom: 12,
          paddingTop: 18,
          position: "relative",
          display: "block",
        }}
      >
        {!ifscDetails.BANK &&
          focus.ifsc &&
          infoBox(
            "Should be a valid 11 digit IFSC code of the the bank branch of your account"
          )}
        {ifscDetails.BANK &&
          focus.ifsc &&
          infoBox(
            <Fragment>
              <span>
                <b>Bank:</b> {ifscDetails.BANK}
              </span>
              <br />
              <span>
                <b>Branch:</b> {ifscDetails.BRANCH}
              </span>
              <br />
              <span>
                <b>City:</b> {ifscDetails.CITY}
              </span>
              <br />
              <span>
                <b>State:</b> {ifscDetails.STATE}
              </span>
            </Fragment>
          )}
        <Controls.Input
          name="ifsc"
          label="IFSC"
          value={account.ifsc}
          onChange={(e) => {
            changeHandler(e);
            if (e.target.value.trim().length === 11) {
              axios
                .get(`https://ifsc.razorpay.com/${e.target.value.trim()}`)
                .then((res) => {
                  if (res.data.BANK) {
                    setIfscDetails(res.data);
                    setAccount({
                      ...account,
                      bank: res.data.BANK,
                      ifsc: e.target.value.trim(),
                    });
                  }
                })
                .catch(() => {
                  setAccount({
                    ...account,
                    ifsc_error: true,
                    ifsc: e.target.value.trim(),
                  });
                });
            } else {
              setIfscDetails({});
              setAccount({
                ...account,
                ifsc_error: false,
                ifsc: e.target.value.trim(),
              });
            }
          }}
          onFocus={() => {
            setAccount({ ...account, ifsc_error: false });
            setFocus({ ...initFocus, ifsc: true });
          }}
          onBlur={(e) => {
            if (e.target.value.trim().length !== 11)
              setAccount({ ...account, ifsc_error: true });
            setFocus({ ...initFocus, ifsc: false });
          }}
          error={account.ifsc_error ? "Please enter valid IFSC" : ""}
          onEnter={onAdd}
          maxLength={11}
        />
      </div>

      <div
        className={classes.dividerGrid}
        style={{
          paddingBottom: 12,
          paddingTop: 18,
          position: "relative",
          display: "block",
        }}
      >
        {focus.number &&
          infoBox(
            "Should be a current bank account of the company to which your payments will be settled"
          )}
        <Controls.Input
          name="number"
          label="Account Number"
          value={account.number}
          onChange={changeHandler}
          onFocus={() => {
            setAccount({ ...account, number_error: false });
            setFocus({ ...initFocus, number: true });
          }}
          onBlur={(e) => {
            if (e.target.value.trim() === "")
              setAccount({ ...account, number_error: true });
            setFocus({ ...initFocus, number: false });
          }}
          error={account.number_error ? "Please enter Account Number" : ""}
          onEnter={onAdd}
          type={focus.number ? "text" : "password"}
        />
      </div>

      <div
        className={classes.dividerGrid}
        style={{
          paddingBottom: 12,
          paddingTop: 18,
          position: "relative",
          display: "block",
        }}
      >
        {focus.renumber && infoBox("Please re-enter the bank account number")}
        <Controls.Input
          name="renumber"
          label="Re-enter Acc No."
          value={account.renumber}
          onChange={changeHandler}
          onFocus={() => {
            setAccount({ ...account, renumber_error: false });
            setFocus({ ...initFocus, renumber: true });
          }}
          onBlur={() => {
            setFocus({ ...initFocus, renumber: false });
            if (account.number !== account.renumber)
              setAccount({ ...account, renumber_error: true });
          }}
          error={account.renumber_error ? "Account number mismatch" : ""}
          onEnter={onAdd}
        />
      </div>

      <div
        className={classes.dividerGrid}
        style={{
          paddingBottom: 12,
          paddingTop: 18,
          position: "relative",
          display: "block",
        }}
      >
        {focus.accType && infoBox("Select the type of your account")}

        <Controls.Select
          name="accType"
          label="Account Type"
          value={account.accType}
          style={{ fontSize: 12 }}
          error={account.type_error}
          onFocus={() => {
            setAccount({ ...account, type_error: false });
            setFocus({ ...initFocus, accType: true });
          }}
          onBlur={(e) => {
            if (e.target.value.trim() === "")
              setAccount({ ...account, type_error: true });
            setFocus({ ...initFocus, accType: false });
          }}
          onChange={changeHandler}
          options={[
            { id: "CURRENT", title: "Current" },
            { id: "SAVINGS", title: "Savings" },
          ]}
          placeholder="Select Account Type"
        />
      </div>

      <div
        className={classes.dividerGrid}
        style={{
          paddingBottom: 12,
          paddingTop: 18,
          position: "relative",
          display: "block",
        }}
      >
        {focus.name &&
          infoBox(
            `The Benificiary Name should be same as ${bizNametoDisplay(
              account.bizType
            )} Name`
          )}
        <Controls.Input
          name="name"
          label="Beneficiary Name"
          value={account.name}
          onChange={changeHandler}
          onFocus={() => {
            setAccount({ ...account, name_error: false });
            setFocus({ ...initFocus, name: true });
          }}
          onBlur={(e) => {
            if (e.target.value.trim() === "")
              setAccount({ ...account, name_error: true });
            setFocus({ ...initFocus, name: false });
          }}
          error={account.name_error ? "Please enter Beneficiary Name" : ""}
          onEnter={onAdd}
        />
      </div>

      <div
        className={classes.dividerGrid}
        style={{
          paddingBottom: 12,
          paddingTop: 18,
          position: "relative",
          display: "block",
        }}
      >
        {focus.email &&
          infoBox("Enter the email linked with the above entered account")}
        <Controls.Input
          name="email"
          label="Email"
          value={account.email}
          onChange={changeHandler}
          error={account.email_error ? "Please enter a valid email" : ""}
          onFocus={() => {
            setAccount({ ...account, email_error: false });
            setFocus({ ...initFocus, email: true });
          }}
          onBlur={(e) => {
            if (!/\S+@\S+\.\S+/.test(e.target.value))
              setAccount({ ...account, email_error: true });
            setFocus({ ...initFocus, email: false });
          }}
          onEnter={onAdd}
        />
      </div>
    </FormModal>
  );
};

export default AddBankModal;
