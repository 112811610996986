import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const HeartIcon = ({ viewBox = '0 0 9 9', fill = '#E86C60' }) => {
    return (
        <SvgIcon viewBox={viewBox} style={{ width: '9px', height: '9px' }}>
            <svg xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#hear_clip0)">
                    <path d="M6.021 0.354126C5.33117 0.354126 4.68561 0.661169 4.25016 1.18611C3.81472 0.661169 3.16916 0.354126 2.47933 0.354126C1.21 0.354126 0.177246 1.38679 0.177246 2.65621C0.177246 4.84001 3.97624 7.97346 4.1381 8.10576C4.17061 8.13247 4.21039 8.14579 4.25016 8.14579C4.28994 8.14579 4.32971 8.13247 4.36222 8.10576C4.52409 7.97346 8.32308 4.84001 8.32308 2.65621C8.32308 1.38679 7.29032 0.354126 6.021 0.354126Z" fill={fill} />
                </g>
                <defs>
                    <clipPath id="hear_clip0">
                        <rect width="8.5" height="8.5" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    );
};

export default HeartIcon;
