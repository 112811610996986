import { PURCHASES_PER_PAGE } from "../../utils/utils";
import {
  CREATE_PURCHASE,
  FETCH_PURCHASES,
  PURCHASE_PROGRESS,
  SET_PURCHASE_ERROR,
  SET_PURCHASE_LOADER,
  UPDATE_PURCHASE,
  CLEAR_PURCHASE,
  CLEAR_COMPLETE_PURCHASE,
  DELETE_PURCHASE,
  PURCHASE_DATE_SELECTOR,
  PURCHASE_STATUS_SELECTOR,
  PURCHASE_DATE_RANGE,
  SET_PURCHASES_PER_PAGE,
  SET_PURCHASES_CURRENT_PAGE,
  SET_PURCHASES_MAX_PAGE_SO_FAR,
  SET_PURCHASES_INPUT_KEYWORD,
  SET_PURCHASES_SEARCH_KEYWORD,
  SET_PURCHASES_CUSTOMER_ID,
} from "./purchaseTypes";
import axios from "axios";
import { getDefaultStore } from "../../utils/storeUtil";

export const savePurchase = (purchaseData) => async (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  let data = purchaseData;
  let newPur = purchaseData.id ? false : true;
  let storeId = store.store_id;
  data.org_id = store.org_id;
  data.store_id = storeId;
  return await axios
    .post(
      `${process.env.REACT_APP_API}/api/v1/org/store/${storeId}/order/from-store-order?src=web`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      dispatch({
        type: newPur ? CREATE_PURCHASE : UPDATE_PURCHASE,
        payload: response.data.payload,
      });
      return response.data.payload;
    })
    .catch((error) => {
      dispatch({
        type: SET_PURCHASE_ERROR,
        payload: { error: error.message },
      });
    });
};

export const fetchPurchases =
  (
    page = 0,
    status = "ALL",
    aoe = null,
    boe = null,
    keyword = null,
    size = PURCHASES_PER_PAGE,
    customerId = null
  ) =>
  async (dispatch) => {
    dispatch({
      type: PURCHASE_PROGRESS,
      payload: true,
    });
    let accessToken = localStorage.getItem("access_token");
    let store = getDefaultStore();
    return await axios
      .get(
        `${process.env.REACT_APP_API}/api/v1/org/store/${
          store.store_id
        }/order/find?type=PURCHASE_ORDER&page=${page}&size=${size}&sort=createdAt,desc&state=${status}${
          aoe && boe ? `&created-aoe=${aoe}&created-boe=${boe}` : ""
        }${keyword ? `&q=${keyword}` : ""}${
          customerId ? `&org-store-customer-id=${customerId}` : ""
        }`,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: FETCH_PURCHASES,
          payload: response.data.payload,
          purchasesPerPage: size,
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_PURCHASE_ERROR,
          payload: { error: error.message },
        });
      });
  };

export const clearPurchases = () => (dispatch) => {
  dispatch({
    type: CLEAR_COMPLETE_PURCHASE,
  });
};
export const refetchPurchase = (purchaseId) => (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  axios
    .get(
      `${process.env.REACT_APP_API}/api/v1/org/store/${store.store_id}/order/${purchaseId}`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      dispatch({
        type: UPDATE_PURCHASE,
        payload: response.data.payload,
      });
    });
};

export const deletePurchase = (purchaseId) => (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  axios
    .delete(
      `${process.env.REACT_APP_API}/api/v1/org/store/${store.store_id}/order/${purchaseId}`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      dispatch({
        type: DELETE_PURCHASE,
        payload: { purchaseId },
      });
    });
};

export const setPurchaseDateSelector = (data) => (dispatch) => {
  dispatch({
    type: PURCHASE_DATE_SELECTOR,
    payload: data,
  });
};

export const setPurchaseStatusSelector = (data) => (dispatch) => {
  dispatch({
    type: PURCHASE_STATUS_SELECTOR,
    payload: data,
  });
};

export const setPurchaseDateRange = (data) => (dispatch) => {
  dispatch({
    type: PURCHASE_DATE_RANGE,
    payload: data,
  });
};

export const setPurchasesPerPage = (data) => (dispatch) => {
  dispatch({
    type: SET_PURCHASES_PER_PAGE,
    payload: data,
  });
};

export const clearPurchase = () => (dispatch) => {
  dispatch({
    type: CLEAR_PURCHASE,
    payload: null,
  });
};

export const startPurchaseLoader = () => (dispatch) => {
  dispatch({
    type: SET_PURCHASE_LOADER,
    payload: true,
  });
};

export const stopPurchaseLoader = () => (dispatch) => {
  dispatch({
    type: SET_PURCHASE_LOADER,
    payload: false,
  });
};

export const setPurchasesCurrentPage = (data) => (dispatch) => {
  dispatch({
    type: SET_PURCHASES_CURRENT_PAGE,
    payload: data,
  });
};

export const setPurchasesMaxPageSoFar = (data) => (dispatch) => {
  dispatch({
    type: SET_PURCHASES_MAX_PAGE_SO_FAR,
    payload: data,
  });
};

export const setPurchasesInputKeyword = (data) => (dispatch) => {
  dispatch({
    type: SET_PURCHASES_INPUT_KEYWORD,
    payload: data,
  });
};

export const setPurchasesSearchKeyword = (data) => (dispatch) => {
  dispatch({
    type: SET_PURCHASES_SEARCH_KEYWORD,
    payload: data,
  });
};

export const setPurchasesCustomerId = (data) => (dispatch) => {
  dispatch({
    type: SET_PURCHASES_CUSTOMER_ID,
    payload: data,
  });
};
