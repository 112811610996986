import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useStyles } from "../../styles";
import SmallDiamond from "../../../../icons/SmallDiamond";
import { planCustomProps } from "../../constants";
import { getDomainPrice } from "../../utils";
import GlobeIcon from "../../../../icons/GlobeIcon";
import TextButton from "../../../../components/buttons/TextButton";
export default function CartDetail({
  currentSelectedPlan,
  selectedDomain,
  addonList,
  handleRemoveAddon,
  handleDomainSelect,
  stage,
  subtotal,
  taxAmount,
  totalAmount,
  domain,
}) {
  const classes = useStyles();
  const renderAddonsList = () => {
    return (
      <>
        {addonList?.length > 0 &&
          addonList?.map((addon, idx) => {
            return (
              <Box className={classes.cartItem} key={addon.id}>
                <img
                  src={addon.icon_url}
                  alt="addon icon"
                  style={{
                    width: "40px",
                    height: "40px",
                    marginRight: "10px",
                  }}
                />
                <Box
                  display="flex"
                  flexGrow={2}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    className={classes.domainName}
                    style={{
                      fontSize: "14px",
                      width: "72%",
                      textAlign: "left",
                    }}
                  >
                    {addon.title}
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="flex-end"
                    flexDirection="column"
                  >
                    <Typography className={classes.addonpriceright}>
                      <span style={{ fontWeight: 400, width: "70%" }}>₹</span>
                      {addon.amount / 100}
                    </Typography>
                    <Typography>
                      <span
                        style={{
                          fontSize: "12px",
                          textAlign: "end",
                          fontWeight: "400",
                        }}
                      >
                        {" "}
                        one time
                      </span>
                    </Typography>

                    <TextButton
                      onClick={() => handleRemoveAddon(idx)}
                      style={{
                        fontSize: "12px",
                        lineHeight: "16px",
                        textDecoration: "none",
                        fontWeight: "500",
                        display: "contents",
                      }}
                      intent="negative"
                    >
                      Remove
                    </TextButton>
                  </Box>
                </Box>
              </Box>
            );
          })}
      </>
    );
  };
  //   Function to render domain data
  const renderDomainData = () => {
    return (
      <>
        {selectedDomain && (
          <Box className={classes.cartItem}>
            <Box
              height="40px"
              width="40px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <GlobeIcon />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              marginLeft="10px"
              flexGrow="2"
            >
              <Typography
                style={{
                  fontSize: "14px",
                  width: "72%",
                  textAlign: "left",
                  wordBreak: "break-all",
                }}
              >
                {selectedDomain.domainName}
              </Typography>
              <Box display="flex" flexDirection="column" alignItems="flex-end">
                <Typography className={classes.domainName}>
                  <span style={{ fontWeight: 400 }}>₹</span>
                  {getDomainPrice(selectedDomain.purchasePrice)}
                  {"  "}
                </Typography>
                <Typography
                  className={classes.peryear}
                  style={{
                    fontSize: "12px",
                    textAlign: "end",
                    fontWeight: "400",
                  }}
                >
                  per year
                </Typography>
                <TextButton
                  onClick={() => handleDomainSelect(null)}
                  style={{
                    fontSize: "12px",
                    lineHeight: "16px",
                    textDecoration: "none",
                    fontWeight: "500",
                    display: "contents",
                  }}
                  intent="negative"
                >
                  Remove
                </TextButton>
              </Box>
            </Box>
          </Box>
        )}
      </>
    );
  };
  //   Function to render selected plan
  const renderSelectedPlan = () => {
    return (
      currentSelectedPlan && (
        <Box>
          <Box className={classes.cartItem}>
            <SmallDiamond
              {...planCustomProps[currentSelectedPlan.plan].diamondProps}
            />
            <Box
              flexGrow={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              marginLeft="10px"
            >
              <Box>
                <Typography
                  style={{ fontSize: "14px", fontWeight: 400 }}
                  className={classes.domainName}
                >
                  {currentSelectedPlan.title}
                </Typography>
                <Typography
                  className={classes.renewalText}
                  style={{ marginTop: "4px" }}
                >
                  {currentSelectedPlan.short_descriptionn}
                </Typography>
              </Box>
              <Typography
                className={classes.domainName}
                style={{ fontSize: "14px", fontWeight: 400 }}
              >
                <span style={{ fontWeight: 400 }}>₹</span>
                {currentSelectedPlan.amount / 100}
                {"  "}
                <span
                  className={classes.renewalText}
                  style={{ fontWeight: "400" }}
                >
                  {/* per year */}
                </span>
              </Typography>
            </Box>
          </Box>
        </Box>
      )
    );
  };

  return (
    <Box className={classes.cart_detail_container}>
      <Box style={{ flex: 1, overflow: "auto " }}>
        {renderSelectedPlan()}
        {renderAddonsList()}
        {renderDomainData()}
      </Box>

      <Box margin="0px 0px" style={{ borderTop: "1px solid #E1E1E1" }}>
        <Box
          className={classes.boxContainer}
          style={{ padding: "8px 12px", flexDirection: "column" }}
        >
          <Box
            className={classes.boxContainer}
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontWeight: "normal",
            }}
          >
            <Typography className={classes.totalText}>Subtotal</Typography>
            <Typography className={classes.totalText}>
              <span style={{ fontWeight: 400 }}>₹</span>
              {Math.floor(subtotal)}
            </Typography>
          </Box>
          <Box className={classes.boxContainer}>
            <Typography className={classes.totalText}>
              GST{" "}
              <span>
                ({!domain ? `${currentSelectedPlan?.tax_percent}%` : "18%"})
              </span>
            </Typography>

            <Typography className={classes.totalText}>
              <span>₹</span>

              {taxAmount}
            </Typography>
          </Box>
          <Box className={classes.boxContainer}>
            <Typography className={classes.totalAmount}>Total</Typography>
            <Typography className={classes.totalAmount}>
              ₹{totalAmount}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
