import { Link } from "@material-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import CombinedButtons from "../../../../components/buttons/CombinedButtons";
import Controls from "../../../../components/controls/Controls";
import { rowFlexJustifyBetween } from "../../../../styles/common";
import useStyles from "../../common/styles";
import states from "../../../../constants/states.json";
import ConfirmModal from "../../../../components/common/ConfirmModal";
import Toast from "../../../../components/Layout/Toast";
import axios from "axios";
import { getStoreIdFromUrl } from "../../../../utils/storeUtil";
import { useSelector } from "react-redux";

const INDIAMART_ONBOARDING_URL =
  "https://ext.indiamart.com/ecom/userIdentify.php?platform=shoopy";
export const IndiamartForm = ({
  record,
  configured,
  connection,
  onConnect,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [toast, setToast] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [errors, setErrors] = useState({});

  const store = useSelector((state) => state.store.store);

  const isNotCustomDomain = store?.domain?.endsWith("shoopy.in");
  const [domainError, setDomainError] = useState(false);

  const initState = {
    api_key: {
      value: record?.api_key ?? "",
    },
    first_name: {
      value: record?.first_name ?? "",
    },
    last_name: {
      value: record?.last_name ?? "",
    },
    email_id: {
      value: record?.email_id ?? "",
    },
    mobile_number: {
      value: record?.mobile_number ?? "",
    },
    company: {
      value: record?.company ?? "",
    },
    address_line1: {
      value: record?.address_line1 ?? "",
    },
    address_line2: {
      value: record?.address_line2 ?? "",
    },
    city: {
      value: record?.city ?? "",
    },
    pincode: {
      value: record?.pincode ?? "",
    },
    state: {
      value: record?.state ?? "",
    },
    pan: {
      value: record?.pan ?? "",
    },
    gstin: { value: record?.gstin ?? "" },
    cin: { value: record?.cin ?? "" },
  };
  const [formData, setFormData] = useState(initState);
  const [error, setError] = useState(null);

  const validate = (name, value) => {
    let hasError = false;
    switch (name) {
      case "api_key":
      case "first_name":
      case "last_name":
      case "company":
      case "address_line1":
      case "city":
      case "state":
        hasError = value === "";
        break;
      case "mobile_number":
        hasError = value.length === 0 || !/^([+]\d{2})?\d{10}$/gm.test(value);
        break;
      case "email_id":
        hasError =
          value.length === 0 ||
          !/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g.test(
            value
          );
        break;
      case "gstin":
        hasError =
          value.length !== 0 &&
          !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/g.test(
            value
          );
        break;
      case "pincode":
        hasError = value.length === 0 || !/^[0-9]{6}$/g.test(value);
        break;
      case "pan":
        hasError = value.length > 0 && value.length < 10;
        break;
      case "cin":
        hasError = value.length > 0 && value.length < 21;
        break;
      default:
        break;
    }
    return hasError;
  };

  const onBlur = (e) => {
    let value = e.target.value;
    let tvalue = value.trim();
    let hasError = validate(e.target.name, tvalue);
    setErrors((errors) => ({
      ...errors,
      [e.target.name]: hasError,
    }));
  };

  const handleFormDataChange = (event) => {
    setFormData((formData) => {
      return {
        ...formData,
        [event.target.name]: { value: event.target.value },
      };
    });
  };

  const onCloseModal = () => {
    setShowConfirm(false);
  };

  const onConfirmModal = async () => {
    let config = {};
    for (const key in formData) {
      config[key] = formData[key].value;
    }
    setShowConfirm(false);
    setLoader(true);
    if (configured && connection) {
      config.action = "update";
    }
    let response = await configureIndiamart(config);
    if (response.error) {
      setError(response.error);
    }
    setLoader(false);
    setToast(true);
    if (!configured && !connection) {
      onConnect();
    }
  };

  const configureIndiamart = async (data) => {
    let accessToken = localStorage.getItem("access_token");
    let storeId = getStoreIdFromUrl();
    let resp = {};
    try {
      await axios.post(
        `${process.env.REACT_APP_API}/api/v1/store/${storeId}/plugins/indiamart/configure`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      resp = { code: "success" };
    } catch (err) {
      resp.error = err.response ? err.response.data : "Unknown";
    }
    return resp;
  };

  const onConnectAndSync = () => {
    if (isNotCustomDomain) {
      setDomainError(true);
      return;
    }
    let errs = {};
    for (const key in formData) {
      let hasError = validate(key, formData[key].value);
      if (hasError) {
        errs[key] = true;
      }
    }
    if (Object.keys(errs).length > 0) {
      setErrors((errors) => ({
        ...errors,
        ...errs,
      }));
      return;
    }
    setShowConfirm(true);
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ width: configured ? "100%" : "75%" }}>
          <Controls.Input
            name="api_key"
            label="Indiamart API Key"
            required={true}
            labelPlacement="top"
            value={formData.api_key.value}
            onChange={handleFormDataChange}
            onBlur={onBlur}
            error={errors.api_key ? "Provid valid API Key" : ""}
            maxLength={64}
            disabled={configured}
            InputProps={{
              style: configured
                ? {
                    color: "#666666",
                    background: "#f6f6f6",
                  }
                : {},
            }}
          />
        </div>
        {!configured && (
          <Link
            target="_blank"
            href={INDIAMART_ONBOARDING_URL}
            underline="always"
            style={{ marginTop: 8, marginLeft: 16, color: "#007bff" }}
          >
            Get API Key
          </Link>
        )}
      </div>
      <div>
        <Controls.Input
          name="mobile_number"
          label="Mobile Number"
          labelPlacement="top"
          value={formData.mobile_number.value}
          onChange={handleFormDataChange}
          // onFocus={onFocus}
          onBlur={onBlur}
          disabled={configured}
          InputProps={{
            style: configured
              ? {
                  color: "#666666",
                  background: "#f6f6f6",
                }
              : {},
          }}
          error={errors.mobile_number ? "Enter valid Mobile Number" : ""}
        />
        <p style={{ marginTop: -8, color: "#666666", fontSize: 12 }}>
          Note: Mobile number must be same as the number used for API Key
        </p>
      </div>
      <div className={classes.row} style={{ marginTop: 16 }}>
        <Controls.Input
          name="first_name"
          label="First Name"
          labelPlacement="top"
          value={formData.first_name.value}
          onChange={handleFormDataChange}
          // onFocus={onFocus}
          onBlur={onBlur}
          error={errors.first_name ? "Enter your First Name" : ""}
        />
        <Controls.Input
          name="last_name"
          label="Last Name"
          labelPlacement="top"
          value={formData.last_name.value}
          onChange={handleFormDataChange}
          // onFocus={onFocus}
          onBlur={onBlur}
          error={errors.last_name ? "Enter your Last Name" : ""}
        />
      </div>
      <div>
        <Controls.Input
          name="email_id"
          label="Email"
          labelPlacement="top"
          value={formData.email_id.value}
          onChange={handleFormDataChange}
          // onFocus={onFocus}
          onBlur={onBlur}
          error={errors.email_id ? "Enter valid Email Address" : ""}
        />
      </div>
      <div className={classes.row}>
        <Controls.Input
          name="company"
          label="Business Name"
          maxLength={255}
          labelPlacement="top"
          value={formData.company.value}
          onChange={handleFormDataChange}
          onBlur={onBlur}
          error={errors.company ? "Enter Business Name" : ""}
        />
        <Controls.Input
          name="gstin"
          label="GSTIN"
          maxLength={15}
          labelPlacement="top"
          value={formData.gstin.value}
          onBlur={onBlur}
          onChange={handleFormDataChange}
          error={errors.gstin ? "Enter valid GSTIN Number" : ""}
        />
      </div>
      <div className={classes.row}>
        <Controls.Input
          name="pan"
          label="PAN"
          maxLength={10}
          labelPlacement="top"
          value={formData.pan.value}
          onChange={handleFormDataChange}
          onBlur={onBlur}
          error={errors.pan ? "Enter valid PAN Number" : ""}
        />
        <Controls.Input
          name="cin"
          label="CIN"
          maxLength={21}
          labelPlacement="top"
          value={formData.cin.value}
          onBlur={onBlur}
          onChange={handleFormDataChange}
          error={errors.cin ? "Enter valid CIN Number" : ""}
        />
      </div>
      <div
        style={{
          marginTop: "20px",
          marginBottom: "16px",
        }}
      >
        <div>
          <Controls.Input
            name="address_line1"
            label="Address Line 1"
            type="string"
            value={formData.address_line1.value}
            onChange={handleFormDataChange}
            maxLength={255}
            labelPlacement="top"
            onBlur={onBlur}
            error={errors.address_line1 ? "Enter Street Address" : ""}
          />
          <Controls.Input
            name="address_line2"
            label="Address Line 2"
            type="string"
            value={formData.address_line2.value}
            onChange={handleFormDataChange}
            maxLength={255}
            labelPlacement="top"
            onBlur={onBlur}
          />
          <div className={rowFlexJustifyBetween}>
            <Controls.Input
              name="pincode"
              maxLength={6}
              label="Pincode"
              type="number"
              width={136}
              value={formData.pincode.value}
              onChange={handleFormDataChange}
              labelPlacement="top"
              onBlur={onBlur}
              error={errors.pincode ? "Enter valid Pincode" : ""}
            />
            <Controls.Input
              name="city"
              label="City"
              type="string"
              width={192}
              value={formData.city.value}
              onChange={handleFormDataChange}
              labelPlacement="top"
              maxLength={64}
              onBlur={onBlur}
              error={errors.city ? "Enter city" : ""}
            />
            <Controls.Select
              name="state"
              label="State"
              labelPlacement="top"
              width={192}
              value={formData.state.value}
              placeholder="Select State"
              options={states.map((state) => ({ id: state, title: state }))}
              color="secondary"
              onChange={(e) => {
                let state = { value: e.target.value };
                setFormData({ ...formData, state });
              }}
              error={errors.state ? "Select State" : ""}
              onBlur={onBlur}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: 50,
        }}
      >
        <CombinedButtons
          outlinedBtnText="Back"
          solidBtnText={configured ? "Update Indiamart" : "Connect Indiamart"}
          solidBtnAction={onConnectAndSync}
          outlinedBtnAction={() => history.goBack()}
          loading={loader}
          disabled={configured && !connection}
        />
      </div>
      <ConfirmModal
        open={showConfirm}
        message={
          configured
            ? "Update your Business details on Indiamart"
            : "You are about to connect your Shoopy Account to your Indiamart Account"
        }
        closeBtnText="Cancel"
        confirmBtnText="Yes, Proceed"
        intent=""
        onClose={onCloseModal}
        onConfirm={onConfirmModal}
        title={
          configured ? "Update Business Profile" : "Connect with Indiamart"
        }
      />
      <Toast
        open={toast}
        close={setToast}
        severity={error ? "error" : "success"}
        message={
          error
            ? `${configured ? "Update" : "Connect"} failed - ${error}`
            : `${configured ? "Update" : "Connect"} success`
        }
      />
      <Toast
        open={domainError}
        close={setDomainError}
        severity={"error"}
        message={"Connect your own domain for Indiamart store setup"}
      />
    </>
  );
};
