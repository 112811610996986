import {
  FETCH_ADDITIONAL_FIELDS,
  ADDITIONAL_FIELDS_PROGRESS,
  ADD_ADDITIONAL_FIELD,
  CLEAR_ADDITIONAL_FIELDS_ERROR,
  DELETE_ADDITIONAL_FIELD,
  SET_ADDITIONAL_FIELDS_ERROR,
  UPDATE_ADDITIONAL_FIELD,
  UPDATE_ADDITIONAL_FIELDS_LIST,
  CLEAR_ADDITIONAL_FIELDS,
} from "./additionalFieldsTypes";

import axios from "axios";
import { getStoreIdFromUrl } from "../../utils/storeUtil";

export const fetchAdditionalFields = () => async (dispatch) => {
  try {
    dispatch({
      type: ADDITIONAL_FIELDS_PROGRESS,
      payload: true,
    });

    let storeId = getStoreIdFromUrl();
    let accessToken = localStorage.getItem("access_token");

    const response = await axios.get(
      `${process.env.REACT_APP_API}/api/v2/business/store/${storeId}/product-field-defs?cat-slugs=&group=all`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    dispatch({
      type: FETCH_ADDITIONAL_FIELDS,
      payload: response.data,
    });
  } catch (error) {
    setAdditionalFieldError(error.message);
  }
};

export const rearrangeAdditionalField =
  (prevList, newList, data, type) => async (dispatch) => {
    try {
      dispatch(rearrangeFieldOptimistically(newList, type));

      let accessToken = localStorage.getItem("access_token");
      let storeId = getStoreIdFromUrl();
      let fieldType = type.toLowerCase();

      const response = await axios.patch(
        `${process.env.REACT_APP_API}/api/v1/business/store/${storeId}/product-field-defs/position?group=${fieldType}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch({
        type: UPDATE_ADDITIONAL_FIELDS_LIST,
        payload: {
          data: response.data,
          fieldType: type,
        },
      });

      return response;
    } catch (error) {
      dispatch(rearrangeFieldOptimistically(prevList, type));
      setAdditionalFieldError(error.message);
      throw error;
    }
  };

export const rearrangeFieldOptimistically = (data, type) => (dispatch) => {
  // Optimistically update the state before the API call
  dispatch({
    type: UPDATE_ADDITIONAL_FIELDS_LIST,
    payload: {
      data: data,
      fieldType: type,
    },
  });
};

export const addAdditionalField = (fieldData) => async (dispatch) => {
  try {
    let accessToken = localStorage.getItem("access_token");
    let storeId = getStoreIdFromUrl();
    let data = fieldData;
    const response = await axios.post(
      `${process.env.REACT_APP_API}/api/v1/business/store/${storeId}/product-field-defs`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({
      type: ADD_ADDITIONAL_FIELD,
      payload: response.data,
    });

    return response;
  } catch (error) {
    setAdditionalFieldError(error.message);
    throw error;
  }
};

export const editAdditionalField = (fieldData) => async (dispatch) => {
  try {
    let accessToken = localStorage.getItem("access_token");
    let storeId = getStoreIdFromUrl();
    let data = fieldData;
    const response = await axios.patch(
      `${process.env.REACT_APP_API}/api/v1/business/store/${storeId}/product-field-defs/${fieldData.id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({
      type: UPDATE_ADDITIONAL_FIELD,
      payload: response.data,
    });

    return response.data;
  } catch (error) {
    setAdditionalFieldError(error.message);
    throw error;
  }
};

export const deleteAdditionalField = (fieldId) => async (dispatch) => {
  try {
    let accessToken = localStorage.getItem("access_token");
    let storeId = getStoreIdFromUrl();

    await axios.delete(
      `${process.env.REACT_APP_API}/api/v1/business/store/${storeId}/product-field-defs/${fieldId}`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    dispatch({
      type: DELETE_ADDITIONAL_FIELD,
      payload: { id: fieldId },
    });

    return { status: "success" };
  } catch (error) {
    setAdditionalFieldError(error.message);
    throw error;
  }
};

export const clearAdditionalFieldError = () => (dispatch) => {
  dispatch({
    type: CLEAR_ADDITIONAL_FIELDS_ERROR,
    payload: { error: null },
  });
};

export const setAdditionalFieldError = (message) => (dispatch) => {
  dispatch({
    type: SET_ADDITIONAL_FIELDS_ERROR,
    payload: { error: message },
  });
};

export const clearAdditionalFieldData = () => (dispatch) => {
  dispatch({
    type: CLEAR_ADDITIONAL_FIELDS,
  });
};
