import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const VideoIcon = ({ viewBox = "0 0 16 14", stroke = "#1641DB" }) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "16px", height: "14px" }}>
      <svg
        width="16"
        height="14"
        viewBox="0 0 16 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.5 0.5H0.5V9.5H15.5V0.5Z"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.5 11.5H12.5"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.5 13.5H15.5"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M7 7L10 5L7 3V7Z" fill={stroke} />
      </svg>
    </SvgIcon>
  );
};

export default VideoIcon;
