// Core react imports
import React from "react";
import { Typography, Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  mainBox: {
    padding: "16px",
    backgroundColor: "#F5F7FC",
    display: "flex",
  },
  tableHead: {
    color: "#666666",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    padding: "0px 10px",
  },
}));

// Exporting component
export default function CheckoutFieldsTableHead() {
  const classes = useStyles();
  return (
    <Box className={classes.mainBox}>
      <Box width="7%"></Box>
      <Typography style={{ width: "14%" }} className={classes.tableHead}>
        Name
      </Typography>
      <Typography style={{ width: "10%" }} className={classes.tableHead}>
        Type
      </Typography>
      <Typography style={{ width: "20%" }} className={classes.tableHead}>
        Description
      </Typography>
      <Typography style={{ width: "11%" }} className={classes.tableHead}>
        Required
      </Typography>
      <Typography style={{ width: "20%" }} className={classes.tableHead}>
        List Options
      </Typography>
      <Typography
        style={{ width: "18%" }}
        className={classes.tableHead}
      ></Typography>
    </Box>
  );
}
