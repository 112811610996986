import { CLEAR_DATA, CLEAR_COMPLETE } from "../../commom/commonTypes";
import {
  ADD_SUB_PLAN,
  FETCH_SUB_PLANS,
  SUB_PLANS_PROGRESS,
  SET_SUCCESS_TOAST,
  EDIT_SUB_PLAN,
  DELETE_SUB_PLAN,
  CLEAR_SUB_PLAN,
  SET_SUB_PLANS_ERROR,
  SET_SUB_PLANS_LOADER,
  CLEAR_COMPLETE_SUB_PLANS,
  SET_SUB_PLANS_PER_PAGE,
  SET_SUB_PLANS_CURRENT_PAGE,
  SET_SUB_PLANS_MAX_PAGE_SO_FAR,
  SET_SUB_PLANS_INPUT_KEYWORD,
  DELETE_SUB_PLANS_ERROR,
  SET_SUB_PLANS_SEARCH_KEYWORD,
} from "./planTypes";

const initialState = {
  subPlans: [],
  numberOfPages: 0,
  numberOfElements: 0,
  subPlan: null,
  progress: true,
  loader: false,
  error: null,
  subPlansPerPage: null,
  currentPage: 0,
  maxPageSoFar: 0,
  searchKeyword: "",
  inputKeyword: "",
  successToast: false,
  message: "",
  state: null,
  pageDirty: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUB_PLANS_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      };
    case FETCH_SUB_PLANS:
      let allSubPlans = state.subPlans;
      let page = action.payload.pageable.pageNumber;
      if (page > 0 && state.subPlans.length > action.subPlansPerPage * page) {
        allSubPlans = state.subPlans.slice(0, action.subPlansPerPage * page);
      }
      return {
        ...state,
        pageDirty: false,
        subPlans:
          action.payload.pageable.pageNumber > 0
            ? [...allSubPlans, ...action.payload.content]
            : action.payload.content,
        numberOfPages: action.payload.totalPages,
        numberOfElements: action.payload.totalElements,
        progress: false,
      };
    case ADD_SUB_PLAN:
      let pageCnt = state.numberOfPages;
      let localPageSubPlans = state.subPlans;
      let count = state.subPlans.length;
      if (count + 1 > state.subPlansPerPage) {
        let fullPages = Math.floor((count + 1) / state.subPlansPerPage);
        localPageSubPlans = state.subPlans.slice(
          0,
          fullPages * state.subPlansPerPage - 1
        );
      }
      let availablePages = Math.floor(
        state.subPlans.length / state.subPlansPerPage
      );
      return {
        ...state,
        pageDirty: true,
        subPlan: action.payload,
        loader: false,
        subPlans: [action.payload, ...localPageSubPlans],
        numberOfPages:
          state.numberOfElements === pageCnt * state.subPlansPerPage
            ? pageCnt + 1
            : pageCnt,
        numberOfElements: state.numberOfElements + 1,
        currentPage: 0,
        maxPageSoFar: availablePages > 1 ? availablePages - 1 : 0,
        successToast: true,
        message: "Plan Added Successfully",
      };

    case EDIT_SUB_PLAN:
      let modSubPlans = state.subPlans.map((subPlan) => {
        if (subPlan.id === action.payload.id) {
          return action.payload;
        }
        return subPlan;
      });
      return {
        ...state,
        subPlan: action.payload,
        loader: false,
        subPlans: modSubPlans,
        successToast: true,
        message: "Plan Update Successfully",
      };
    case DELETE_SUB_PLAN:
      let remSubPlans = state.subPlans.filter(
        (subPlan) => subPlan.id !== action.payload
      );
      return {
        ...state,
        loader: false,
        subPlans: remSubPlans,
        numberOfPages: Math.ceil(
          (state.numberOfElements - 1) / action.subPlansPerPage
        ),
        numberOfElements: state.numberOfElements - 1,
        successToast: true,
        message: "Plan Delete Successfully",
      };
    case DELETE_SUB_PLANS_ERROR:
      return {
        ...state,
        error: action.payload.error,
        progress: false,
      };
    case CLEAR_SUB_PLAN:
      return {
        ...state,
        subPlans: action.payload,
      };
    case SET_SUB_PLANS_ERROR:
      return {
        ...state,
        error: action.payload.error,
        progress: false,
      };
    case SET_SUB_PLANS_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case CLEAR_COMPLETE_SUB_PLANS:
      return {
        ...state,
        subPlans: [],
        numberOfPages: 0,
        numberOfElements: 0,
        subPlan: null,
        progress: false,
        loader: false,
        error: null,
        pageDirty: false,
        successToast: false,
        message: "",
      };
    case CLEAR_COMPLETE:
    case CLEAR_DATA:
      return {
        ...state,
        subPlans: [],
        numberOfPages: 0,
        numberOfElements: 0,
        subPlan: null,
        progress: false,
        loader: false,
        error: null,
        pageDirty: false,
        subPlansPerPage: null,
        searchKeyword: "",
        inputKeyword: "",
        currentPage: 0,
        maxPageSoFar: 0,
        successToast: false,
        message: "",
      };
    case SET_SUB_PLANS_PER_PAGE:
      return {
        ...state,
        subPlansPerPage: action.payload,
      };
    case SET_SUB_PLANS_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SET_SUB_PLANS_MAX_PAGE_SO_FAR:
      return {
        ...state,
        maxPageSoFar: action.payload,
      };
    case SET_SUB_PLANS_INPUT_KEYWORD:
      return {
        ...state,
        inputKeyword: action.payload,
      };
    case SET_SUB_PLANS_SEARCH_KEYWORD:
      return {
        ...state,
        searchKeyword: action.payload,
      };
    case SET_SUCCESS_TOAST:
      return {
        ...state,
        successToast: action.payload,
        meassage: "",
      };
    default:
      return state;
  }
};

export default reducer;
