import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const BigCardIcon = () => {
  return (
    <SvgIcon viewBox={"0 0 36 36"} style={{ width: 36, height: 36 }}>
      <rect
        x="0.5"
        y="0.5"
        width="35"
        height="35"
        rx="1.5"
        fill="white"
        stroke="#999999"
      />
      <rect
        x="2.08691"
        y="29.9082"
        width="31.8261"
        height="3.32308"
        rx="1.04348"
        fill="#C4C4C4"
      />
      <rect
        x="2.08691"
        y="2.76953"
        width="31.8261"
        height="15.5077"
        rx="1.04348"
        fill="#8994FF"
      />
      <rect x="3" y="21" width="29" height="1" rx="0.5" fill="#C4C4C4" />
      <rect x="3" y="24" width="20" height="1" rx="0.5" fill="#C4C4C4" />
    </SvgIcon>
  );
};

export default BigCardIcon;
