import React from "react";
import { FormControlLabel, Checkbox as MuiCheckbox } from "@material-ui/core";

const Checkbox = (props) => {
  const {
    disabled,
    name,
    label,
    value,
    color,
    onChange,
    style = {},
    checkboxStyles = {},
  } = props;
  return (
    <FormControlLabel
      control={
        <MuiCheckbox
          name={name}
          color={color}
          checked={value}
          onChange={disabled ? () => {} : onChange}
          style={{ ...checkboxStyles }}
        />
      }
      label={label}
      labelPlacement="end"
      style={{ ...style }}
    />
  );
};
export default Checkbox;
