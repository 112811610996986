import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
  endIcon: {
    marginLeft: 12,
    paddingTop: 8,
  },
  tableHeader: {
    display: "flex",
    marginLeft: theme.spacing(3.25),
    marginRight: theme.spacing(4),
    justifyContent: "space-between",
    marginBottom: theme.spacing(3),
  },
  search: {
    display: "flex",
    border: "1px solid #E6E6E6",
    borderRadius: 6,
    padding: "12px",
    paddingTop: 9,
    height: "44px",
    width: 410,
  },
  searchIcon: {
    height: "100%",
  },
  searchBox: {
    outlineWidth: 0,
    lineHeight: "16px",
    marginLeft: "12px",
    border: "none",
    fontSize: "12px",
    flex: 1,
    minWidth: 0,
    marginTop: "3px",
  },
  tablehead: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px -1px 0px #E5E5E5, 0px 1px 0px #E6E6E6",
    borderTop: "1px solid #E6E6E6",
  },
  tablebody: {
    "& tr": {
      height: "69px",
    },
  },
  anchor: {
    "& li": {
      color: "#1641DB",
      fontSize: 12,
      lineHeight: "16px",
    },
  },
  root: {
    width: "45px",
    height: "20px",
    padding: "0px",
  },
  switchBaseActive: {
    color: "#818181",
    padding: "1px",
    paddingLeft: 2,
    "&$checked": {
      "& + $track": {
        backgroundColor: "#23bf58",
      },
    },
  },
  switchBaseInactive: {
    color: "#818181",
    padding: "1px",
    "&$checked": {
      "& + $track": {
        backgroundColor: "#23bf58",
      },
    },
  },
  thumb: {
    color: "white",
    width: "16px",
    height: "16px",
    margin: "1.25px",
  },
  trackActive: {
    borderRadius: "20px",
    backgroundColor: "#818181",
    opacity: "1 !important",
    "&:after, &:before": {
      color: "white",
      fontSize: "11px",
      position: "absolute",
      top: "6px",
    },
    "&:after": {
      content: "",
      left: "8px",
    },
    "&:before": {
      content: "",
      right: "7px",
    },
  },
  trackInactive: {
    borderRadius: "20px",
    backgroundColor: "#818181",
    opacity: "1 !important",
    "&:after, &:before": {
      color: "white",
      fontSize: "11px",
      position: "absolute",
      top: "6px",
    },
    "&:after": {
      content: "",
      left: "8px",
    },
    "&:before": {
      content: "",
      right: "7px",
    },
  },
  checked: {
    color: "#23bf58 !important",
    transform: "translateX(24px) !important",
  },
  blackText: {
    color: "#000",
  },
  greyText: {
    color: "#999999",
  },
}));
