import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const NoActivityIcon = ({ viewBox = "0 0 55 53" }) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "55px", height: "53px" }}>
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_7779_4085)">
          <path
            d="M34.9164 11.9195H20.0963C19.7586 11.9199 19.4348 12.0526 19.196 12.2883C18.9572 12.5241 18.8229 12.8437 18.8225 13.1771V45.6936L18.6526 45.7447L15.017 46.8438C14.8447 46.8957 14.6586 46.8779 14.4995 46.7945C14.3405 46.711 14.2215 46.5686 14.1686 46.3986L3.35435 11.5263C3.30172 11.3562 3.31966 11.1725 3.40422 11.0154C3.48878 10.8584 3.63304 10.7409 3.8053 10.6888L9.40777 8.99527L25.6495 4.08741L31.252 2.39389C31.3372 2.36799 31.4268 2.35893 31.5156 2.36725C31.6044 2.37557 31.6907 2.4011 31.7695 2.44237C31.8483 2.48364 31.9181 2.53985 31.9749 2.60777C32.0316 2.6757 32.0743 2.75401 32.1004 2.83823L34.8646 11.7519L34.9164 11.9195Z"
            fill="#F2F2F2"
          />
          <path
            d="M38.1502 11.7517L34.8186 1.00869C34.7632 0.829697 34.6726 0.663235 34.5519 0.518823C34.4313 0.37441 34.2831 0.254878 34.1156 0.167054C33.9482 0.0792305 33.7649 0.024837 33.5761 0.00698387C33.3874 -0.0108693 33.1969 0.0081678 33.0157 0.0630053L25.1389 2.44316L8.89805 7.35186L1.02133 9.73285C0.65542 9.84377 0.349038 10.0935 0.169435 10.4272C-0.0101677 10.7609 -0.0483179 11.1513 0.0633602 11.5127L11.45 48.2278C11.5407 48.5195 11.7238 48.7749 11.9723 48.9564C12.2208 49.1379 12.5217 49.236 12.8309 49.2363C12.974 49.2364 13.1163 49.2152 13.2529 49.1735L18.6524 47.542L18.8223 47.49V47.3148L18.6524 47.3659L13.2029 49.0133C12.8799 49.1105 12.531 49.0773 12.2328 48.9209C11.9346 48.7645 11.7115 48.4978 11.6122 48.1791L0.22645 11.4633C0.177286 11.3054 0.160151 11.1395 0.176026 10.9751C0.1919 10.8106 0.240471 10.6509 0.31896 10.5051C0.397448 10.3592 0.504313 10.2301 0.633432 10.125C0.76255 10.02 0.911387 9.94114 1.07142 9.89298L8.94814 7.51199L25.1891 2.60413L33.0658 0.223137C33.1872 0.186567 33.3134 0.16792 33.4403 0.167804C33.7126 0.168407 33.9777 0.255092 34.1965 0.41516C34.4153 0.575229 34.5765 0.80027 34.6564 1.05732L37.9727 11.7517L38.0254 11.9193H38.202L38.1502 11.7517Z"
            fill="#3F3D56"
          />
          <path
            d="M10.4187 10.7161C10.255 10.716 10.0957 10.6641 9.96409 10.568C9.83248 10.4719 9.73551 10.3368 9.68742 10.1823L8.59356 6.65512C8.56417 6.56037 8.55397 6.46084 8.56355 6.3622C8.57312 6.26356 8.60229 6.16775 8.64938 6.08024C8.69647 5.99273 8.76056 5.91524 8.83799 5.85219C8.91543 5.78913 9.00468 5.74176 9.10066 5.71276L24.0421 1.19695C24.236 1.13856 24.4454 1.15847 24.6243 1.25232C24.8033 1.34617 24.9372 1.50628 24.9968 1.69752L26.0906 5.22476C26.1497 5.41611 26.1295 5.62279 26.0345 5.79946C25.9394 5.97614 25.7773 6.10837 25.5836 6.16716L10.642 10.683C10.5697 10.7049 10.4944 10.7161 10.4187 10.7161Z"
            fill="#999999"
          />
          <path
            d="M16.1487 3.76904C17.0867 3.76904 17.8472 3.01833 17.8472 2.09228C17.8472 1.16624 17.0867 0.415527 16.1487 0.415527C15.2106 0.415527 14.4502 1.16624 14.4502 2.09228C14.4502 3.01833 15.2106 3.76904 16.1487 3.76904Z"
            fill="#6C63FF"
          />
          <path
            d="M16.1488 3.15382C16.7428 3.15382 17.2243 2.67845 17.2243 2.09205C17.2243 1.50565 16.7428 1.03027 16.1488 1.03027C15.5548 1.03027 15.0732 1.50565 15.0732 2.09205C15.0732 2.67845 15.5548 3.15382 16.1488 3.15382Z"
            fill="white"
          />
          <path
            d="M51.1787 48.8082H22.4743C22.2829 48.808 22.0994 48.7328 21.9641 48.5993C21.8288 48.4657 21.7527 48.2845 21.7524 48.0956V14.1413C21.7527 13.9524 21.8288 13.7713 21.9641 13.6377C22.0994 13.5041 22.2829 13.4289 22.4743 13.4287H51.1787C51.3701 13.4289 51.5535 13.5041 51.6889 13.6377C51.8242 13.7713 51.9003 13.9524 51.9005 14.1413V48.0956C51.9003 48.2845 51.8242 48.4657 51.6889 48.5992C51.5535 48.7328 51.3701 48.808 51.1787 48.8082Z"
            fill="#E6E6E6"
          />
          <path
            d="M37.9726 11.752H20.0961C19.7133 11.7525 19.3464 11.9028 19.0758 12.17C18.8052 12.4372 18.6529 12.7994 18.6523 13.1772V47.3662L18.8222 47.3151V13.1772C18.8226 12.8438 18.9569 12.5242 19.1958 12.2884C19.4346 12.0527 19.7583 11.92 20.0961 11.9196H38.0253L37.9726 11.752ZM53.5562 11.752H20.0961C19.7133 11.7525 19.3464 11.9028 19.0758 12.17C18.8052 12.4372 18.6529 12.7994 18.6523 13.1772V51.5749C18.6529 51.9527 18.8052 52.3149 19.0758 52.5821C19.3464 52.8492 19.7133 52.9996 20.0961 53.0001H53.5562C53.9389 52.9996 54.3058 52.8492 54.5765 52.5821C54.8471 52.3149 54.9994 51.9527 54.9999 51.5749V13.1772C54.9994 12.7994 54.8471 12.4372 54.5765 12.17C54.3058 11.9028 53.9389 11.7525 53.5562 11.752ZM54.8301 51.5749C54.8297 51.9083 54.6953 52.2279 54.4565 52.4636C54.2177 52.6994 53.8939 52.832 53.5562 52.8324H20.0961C19.7583 52.832 19.4346 52.6994 19.1958 52.4636C18.9569 52.2279 18.8226 51.9083 18.8222 51.5749V13.1772C18.8226 12.8438 18.9569 12.5242 19.1958 12.2884C19.4346 12.0527 19.7583 11.92 20.0961 11.9196H53.5562C53.8939 11.92 54.2177 12.0527 54.4565 12.2884C54.6953 12.5242 54.8297 12.8438 54.8301 13.1772V51.5749Z"
            fill="#3F3D56"
          />
          <path
            d="M44.6394 15.4406H29.0133C28.8107 15.4404 28.6164 15.3608 28.4731 15.2194C28.3298 15.0779 28.2493 14.8861 28.249 14.6861V10.9972C28.2493 10.7972 28.3299 10.6054 28.4731 10.4639C28.6164 10.3225 28.8107 10.2429 29.0133 10.2427H44.6394C44.842 10.2429 45.0363 10.3225 45.1796 10.4639C45.3229 10.6054 45.4035 10.7972 45.4037 10.9972V14.6861C45.4035 14.8861 45.3229 15.0779 45.1796 15.2194C45.0363 15.3608 44.842 15.4404 44.6394 15.4406Z"
            fill="#999999"
          />
          <path
            d="M36.8259 10.4946C37.764 10.4946 38.5244 9.74391 38.5244 8.81787C38.5244 7.89182 37.764 7.14111 36.8259 7.14111C35.8879 7.14111 35.1274 7.89182 35.1274 8.81787C35.1274 9.74391 35.8879 10.4946 36.8259 10.4946Z"
            fill="#6C63FF"
          />
          <path
            d="M36.826 9.83899C37.3974 9.83899 37.8606 9.38173 37.8606 8.81769C37.8606 8.25364 37.3974 7.79639 36.826 7.79639C36.2547 7.79639 35.7915 8.25364 35.7915 8.81769C35.7915 9.38173 36.2547 9.83899 36.826 9.83899Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_7779_4085">
            <rect width="55" height="53" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default NoActivityIcon;
