import React from "react";
import Controls from "../../../../components/controls/Controls";
import {
  Box,
  CircularProgress,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import DomainCard from "../DomainCard";
import NoDomainGraphic from "../../../../icons/NoDomainGraphic";
import { useStyles } from "../../styles";
import TextButton from "../../../../components/buttons/TextButton";

export default function DomainSelection({
  domainList,
  searchKey,
  loading,
  handleContinueWithoutDomain,
  handleDomainSelect,
  selectedDomain,
  handleDomainSearch,
  domain,
}) {
  const classes = useStyles();

  // Button style based on whether a domain is selected
  const getButtonStyle = (selectedDomain) =>
    selectedDomain
      ? {
          color: "#E1E1E1",
          textDecoration: "none",
          cursor: "default",
          background: "none",
        }
      : {};

  return (
    <>
      {/* Input for domain search */}
      <Box>
        <Controls.Input
          className={classes.inputdomainfield}
          type="text"
          placeholder="Find a perfect domain"
          color="secondary"
          labelPlacement="top"
          value={searchKey}
          onChange={handleDomainSearch}
          endAdornment={
            <InputAdornment
              position="end"
              onClick={() => handleDomainSearch({ target: { value: "" } })}
              style={{ cursor: "pointer", color: "#666" }}
            >
              {searchKey.length > 0 && <Close />}
            </InputAdornment>
          }
          fullWidth
        />
      </Box>

      {/* Loading spinner */}
      {loading ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          marginTop="70px"
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <>
          {/* No domains found or no search results */}
          {domainList.length === 0 ? (
            <>
              {searchKey.length === 0 ? (
                <>
                  {!domain && (
                    <>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-around"
                        marginTop="30px"
                      >
                        <Box className={classes.horizontalRule}></Box>
                        <span className={classes.orText}>Or</span>
                        <Box className={classes.horizontalRule}></Box>
                      </Box>

                      {/* Continue without domain and help text */}
                      <Box
                        marginTop="32px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                      >
                        <TextButton
                          onClick={handleContinueWithoutDomain}
                          style={getButtonStyle(selectedDomain)}
                          disabled={selectedDomain !== null}
                        >
                          Continue Without Domain
                        </TextButton>
                        <Typography className={classes.helpText}>
                          Domain can be mapped after purchasing the plan
                        </Typography>
                      </Box>
                    </>
                  )}
                  {domain && (
                    <Typography style={{ textAlign: "center" }}>
                      No domain available
                    </Typography>
                  )}
                </>
              ) : (
                /* No domain graphic for search with no results */
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                >
                  <NoDomainGraphic />
                </Box>
              )}
            </>
          ) : (
            <>
              {/* Domain list display */}
              <Typography className={classes.domainListHead}>
                Available Domains
              </Typography>
              <Box overflow="auto" maxHeight="365px">
                {domainList.map((domain) => (
                  <DomainCard
                    domain={domain}
                    key={domain.domainName}
                    handleDomainSelect={() => handleDomainSelect(domain)}
                    selectedDomain={selectedDomain}
                  />
                ))}
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
}
