import React from "react";
import FormModal from "./common/FormModal";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import TickCircle from "../icons/TickCircle";
import { planCustomProps, PLANS } from "../pages/plans/constants";
import Diamond from "../icons/Diamond";
import { getStoreIdFromUrl } from "../utils/storeUtil";

const textStyle = { color: "#1a1a1a", fontSize: "14px", lineHeight: "20px" };
export default function PaidPopUp({
  open,
  close,
  plan,
  subtxt,
  outlinedBtnText = "Close",
  hideCloseIcon = false,
  hideCancel = false,
}) {
  const history = useHistory();
  const storeId = getStoreIdFromUrl();
  const requiredPlan = PLANS[plan];

  return (
    <FormModal
      btnTxt="View Plans"
      open={open}
      outlinedBtnText={outlinedBtnText}
      onClose={close}
      onSave={() => {
        history.push(`/${storeId}/billing/plans`);
      }}
      hideCloseIcon={hideCloseIcon}
      hideCancel={hideCancel}
      heading={
        <>
          <span style={{ marginRight: "8px" }}>
            Upgrade to {requiredPlan.name} Plan{" "}
          </span>
          <Diamond
            {...planCustomProps[plan].diamondProps}
            style={{ marginBottom: 10, marginLeft: -12 }}
          />
        </>
      }
    >
      <Typography
        style={{
          ...textStyle,
          marginBottom: "10px",
          marginTop: "-10px",
          width: "90%",
        }}
      >
        {subtxt}
      </Typography>
      <Typography
        style={{
          marginBottom: "10px",
          ...textStyle,
        }}
      >
        {requiredPlan.name} Plan Includes
      </Typography>
      {requiredPlan.features.map((feature, index) => {
        return (
          <div key={index} style={{ marginBottom: "12px" }}>
            <span>
              <TickCircle />
            </span>
            <span style={{ marginLeft: "12px", ...textStyle }}>{feature}</span>
          </div>
        );
      })}
    </FormModal>
  );
}
