import React from "react";
import { makeStyles, TextField, FormControlLabel } from "@material-ui/core";
import BulletList from "../../icons/BulletList";
import ItalicsIcon from "../../icons/ItalicsIcon";
import BoldIcon from "../../icons/BoldIcon";
const useStyles = makeStyles((theme) => ({
  stylingIcons: {
    border: "1px solid #999999",
    marginLeft: "8px",
    height: "24px",
    width: "24px",
    paddingLeft: "6px",
    borderRadius: "6px",
  },
}));

const RichText = (props) => {
  const { name, label, value, color, error = null, onChange } = props;
  const classes = useStyles();
  return (
    <>
      <FormControlLabel
        control={
          <TextField
            variant="outlined"
            name={name}
            value={value}
            onChange={onChange}
            color={color}
            {...(error && { error: true, helperText: error })}
          />
        }
        labelPlacement="start"
        label={label}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      />
      <div
        style={{
          marginLeft: "90px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <p style={{ margin: "0px", color: "#1641DB", cursor: "pointer" }}>
          Expand and Edit Field
        </p>
        <div style={{ display: "flex" }}>
          <div className={classes.stylingIcons}>
            <BulletList />
          </div>
          <div className={classes.stylingIcons}>
            <ItalicsIcon />
          </div>
          <div className={classes.stylingIcons}>
            <BoldIcon />
          </div>
        </div>
      </div>
    </>
  );
};
export default RichText;
