import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
const IndiaFlagIcon = ({ viewBox = '0 0 11 11' }) => {
    return (
        <SvgIcon viewBox={viewBox} style={{ width: '11px', height: '11px' }}>
            <svg xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#flag_clip0)">
                    <path d="M10.5 3.9375H0V1.75C0 1.50828 0.195781 1.3125 0.4375 1.3125H10.0625C10.3042 1.3125 10.5 1.50828 10.5 1.75V3.9375Z" fill="#FD9840" />
                    <path d="M10.5 3.9375H0V6.5625H10.5V3.9375Z" fill="#E6E6E6" />
                    <path d="M10.5 8.75C10.5 8.99172 10.3042 9.1875 10.0625 9.1875H0.4375C0.195781 9.1875 0 8.99172 0 8.75V6.5625H10.5V8.75Z" fill="#1B8717" />
                    <path d="M5.25 4.375C4.76678 4.375 4.375 4.76678 4.375 5.25C4.375 5.73322 4.76678 6.125 5.25 6.125C5.73322 6.125 6.125 5.73322 6.125 5.25C6.125 4.76678 5.73322 4.375 5.25 4.375ZM5.25 5.6875C5.0085 5.6875 4.8125 5.49172 4.8125 5.25C4.8125 5.00828 5.0085 4.8125 5.25 4.8125C5.4915 4.8125 5.6875 5.00828 5.6875 5.25C5.6875 5.49172 5.4915 5.6875 5.25 5.6875Z" fill="#11188A" />
                </g>
                <defs>
                    <clipPath id="flag_clip0">
                        <rect width="10.5" height="10.5" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    );
};

export default IndiaFlagIcon;