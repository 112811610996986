import { Link, Paper } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import {
  getDefaultStore,
  getStoreAttributes,
  getStoreIdFromUrl,
} from "../../../utils/storeUtil";
import { useSelector, useDispatch } from "react-redux";
import { fetchStore } from "../../../redux/store/storeActions";
import Toast from "../../../components/Layout/Toast";
import { fetchApp } from "../../../redux/apps/appActions";
import { centerContainer, layoutCenterContainer } from "../../../styles/common";
import HeaderBreadcrumbs from "../../../components/common/HeaderBreadcrumbs";
import { IndiamartForm } from "./components/IndiamartForm";
import useStyles from "../common/styles";
import axios from "axios";
import OutlinkIcon from "../../../icons/OutlinkIcon";
import PageLoader from "../../../components/Layout/PageLoader";
import InfoContainer from "./components/InfoContainer";

function populateRecord(store, storeUser, attributes) {
  let firstName = "",
    lastName = "";
  if (storeUser.name) {
    let fullName = storeUser.name;
    let toks = fullName.split(" ");
    firstName = toks[0];
    if (toks.length > 1) {
      lastName = toks[1];
    }
  }
  return {
    api_key: attributes.api_key ?? "",
    first_name: attributes.first_name ?? firstName ?? "",
    last_name: attributes.last_name ?? lastName ?? "",
    email_id: attributes.email_id ?? store.email ?? "",
    mobile_number: attributes.mobile_number ?? store.phone ?? "",
    company: attributes.company ?? store.business_name ?? "",
    gstin: attributes.gstin ?? store.gstin ?? "",
    cin: attributes.cin ?? store.cin ?? "",
    pan: attributes.pan ?? store.pan ?? "",
    address_line1: attributes.address_line1 ?? store.street ?? "",
    address_line2: attributes.address_line2 ?? store.street2 ?? "",
    city: attributes.city ?? store.city ?? "",
    pincode: attributes.pincode ?? store.pincode ?? "",
    state: attributes.state ?? store.state ?? "",
    connection: attributes.connected ? attributes.connected === "true" : false,
  };
}

const list = [
  {
    label: "App Store",
    link: "/apps",
  },
  {
    label: "Indiamart",
    link: `/apps/indiamart`,
  },
  {
    label: "Configure",
  },
];

const IndiamartConnect = () => {
  const classes = useStyles();
  const [record, setRecord] = useState({});
  const [loading, setLoading] = useState(false);
  const store = useSelector((state) => state.store.store);
  const [connection, setConnection] = useState(false);
  const [error, setError] = useState(false);
  const [configured, setConfigured] = useState(false);
  const [imUrl, setIMUrl] = useState("");
  const [toast, setToast] = useState(false);
  const storeUser = getDefaultStore();
  const dispatch = useDispatch();

  const getIMUrl = async () => {
    let accessToken = localStorage.getItem("access_token");
    let storeId = getStoreIdFromUrl();
    let resp = await axios.get(
      `${process.env.REACT_APP_API}/api/v1/store/${storeId}/plugins/indiamart/remote-info`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return resp.data.payload.catalog_url;
  };

  useEffect(() => {
    setLoading(true);
    if (Object.keys(store).length > 0) {
      fetchApp("indiamart").then((app) => {
        getStoreAttributes(store.slug, "plugin-indiamart").then((attrs) => {
          let cfgd = attrs.api_key ? true : false;
          let rec = populateRecord(store, storeUser, attrs);
          if (cfgd) {
            getIMUrl().then((url) => {
              setIMUrl(url);
              setConfigured(cfgd);
              setRecord(rec);
              setConnection(rec.connection);
              setLoading(false);
            });
          } else {
            setConfigured(cfgd);
            setRecord(rec);
            setConnection(rec.connection);
            setLoading(false);
          }
        });
      });
    } else {
      dispatch(fetchStore());
    }
    // eslint-disable-next-line
  }, [store]);

  const onIMConnect = () => {
    setConnection(true);
    setConfigured(true);
  };

  if (loading) return <PageLoader />;

  return (
    <div className={layoutCenterContainer}>
      <div className={centerContainer}>
        <div className={classes.breadcrumbsContainer}>
          <div className={classes.backDiv}>
            <HeaderBreadcrumbs list={list} />
          </div>
        </div>
        <Paper elevation={0} className={classes.paper}>
          {configured && (
            <div className={classes.switchctn}>
              {imUrl && (
                <Link
                  target="_blank"
                  href={imUrl}
                  underline="none"
                  style={{ marginTop: 8, color: "#007bff" }}
                >
                  My Indiamart Store <OutlinkIcon />
                </Link>
              )}
            </div>
          )}

          <InfoContainer />

          <IndiamartForm
            record={record}
            configured={configured}
            connection={connection}
            onConnect={onIMConnect}
          />
        </Paper>
      </div>
      <Toast
        open={toast}
        close={setToast}
        severity={error ? "error" : "success"}
        message={
          error
            ? "Unable to toggle Indiamart connect status"
            : `Indiamart Account has been ${
                connection ? "connected" : "disconnected"
              }`
        }
      />
    </div>
  );
};

export default IndiamartConnect;
