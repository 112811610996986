import React from "react";
import {
  FormControlLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import {
  setReloadFalse,
  setReloadTrue,
} from "../../redux/reload/reloadActions";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(() => ({
  menuitem: {
    fontSize: 12,
    color: "#1a1a1a",
  },
  placeholder: {
    fontSize: 12,
    color: "#999999",
  },
}));

const Select = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    name,
    label,
    labelPlacement,
    value,
    renderValue,
    color,
    placeholder,
    labelClass,
    iconClass,
    labelStartClass,
    onChange,
    options,
    controlClass,
    error,
  } = props;
  return (
    <>
      <FormControlLabel
        control={
          <>
            <MuiSelect
              variant="outlined"
              // label={label}
              name={name}
              color={color}
              value={value}
              onChange={onChange}
              className={controlClass}
              renderValue={renderValue}
              classes={{
                outlined:
                  value === "none" || !value
                    ? classes.placeholder
                    : classes.menuitem,
                icon: iconClass,
              }}
              onOpen={() => {
                dispatch(setReloadTrue());
              }}
              onClose={() => {
                dispatch(setReloadFalse());
              }}
            >
              {placeholder && (
                <MenuItem
                  key="none"
                  value="none"
                  className={classes.placeholder}
                >
                  {placeholder}
                </MenuItem>
              )}
              {options.map((item) => (
                <MenuItem
                  key={item.id}
                  value={item.id}
                  className={classes.menuitem}
                >
                  {item.title}
                </MenuItem>
              ))}
            </MuiSelect>
          </>
        }
        label={label}
        labelPlacement={labelPlacement ? labelPlacement : "start"}
        className={labelClass}
        classes={{
          labelPlacementStart: labelStartClass,
          labelPlacementTop: labelStartClass,
        }}
      />
      {error && error.length ? (
        <FormHelperText style={{ color: "red", display: "block",flexBasis:"100%" }}>
          {error}
        </FormHelperText>
      ) : (
        <></>
      )}
    </>
  );
};
export default Select;
