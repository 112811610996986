import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CatalogueIcon = ({
  viewBox = "0 0 20 20",
  stroke = "#999999",
  fill = "#999999",
}) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "20px", height: "20px" }}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_5109_1181)">
          <path
            d="M16.875 1H3.125C2.43464 1 1.875 1.55964 1.875 2.25V18.5C1.875 19.1904 2.43464 19.75 3.125 19.75H16.875C17.5654 19.75 18.125 19.1904 18.125 18.5V2.25C18.125 1.55964 17.5654 1 16.875 1Z"
            stroke={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5 6.25C5.69036 6.25 6.25 5.69036 6.25 5C6.25 4.30964 5.69036 3.75 5 3.75C4.30964 3.75 3.75 4.30964 3.75 5C3.75 5.69036 4.30964 6.25 5 6.25Z"
            fill={fill}
          />
          <rect x="8" y="5" width="7" height="1" fill={fill} />
          <rect x="8" y="10" width="7" height="1" fill={fill} />
          <rect x="8" y="15" width="7" height="1" fill={fill} />
          <path
            d="M5 16.25C5.69036 16.25 6.25 15.6904 6.25 15C6.25 14.3096 5.69036 13.75 5 13.75C4.30964 13.75 3.75 14.3096 3.75 15C3.75 15.6904 4.30964 16.25 5 16.25Z"
            fill={fill}
          />
          <path
            d="M5 11.25C5.69036 11.25 6.25 10.6904 6.25 10C6.25 9.30964 5.69036 8.75 5 8.75C4.30964 8.75 3.75 9.30964 3.75 10C3.75 10.6904 4.30964 11.25 5 11.25Z"
            fill={fill}
          />
        </g>
        <defs>
          <clipPath id="clip0_5109_1181">
            <rect width="20" height="20" fill={fill} />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default CatalogueIcon;
