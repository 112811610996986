import axios from "axios";
import printJS from "print-js";

export const saveFileDocument = (fileData, fileName) => {
  if (typeof window.navigator.msSaveBlob !== "undefined") {
    window.navigator.msSaveBlob(fileData, fileName);
  } else {
    let blobURL =
      window.URL && window.URL.createObjectURL
        ? window.URL.createObjectURL(fileData)
        : window.webkitURL.createObjectURL(fileData);
    let aelm = document.createElement("a");
    aelm.style.display = "none";
    aelm.setAttribute("href", blobURL);
    aelm.setAttribute("download", fileName);
    if (typeof aelm.download === "undefined") {
      aelm.setAttribute("target", "_blank");
    }
    aelm.click();
    setTimeout(function () {
      aelm.remove();
      window.URL.revokeObjectURL(blobURL);
    }, 1000);
  }
};

export const saveCsvDocument = (data, fileName) => {
  let encodedUri = "data:text/csv;charset=utf-8," + encodeURIComponent(data);
  let aelm = document.createElement("a");
  aelm.style.display = "none";
  aelm.setAttribute("href", encodedUri);
  aelm.setAttribute("download", fileName);
  if (typeof aelm.download === "undefined") {
    aelm.setAttribute("target", "_blank");
  }
  aelm.click();
  setTimeout(function () {
    aelm.remove();
  }, 1000);
};

export const downloadDocument = async (
  store,
  recordType,
  recordId,
  fileName
) => {
  let accessToken = localStorage.getItem("access_token");
  let response = await axios.get(
    `${process.env.REACT_APP_API}/api/v1/org/store/${store.slug}/records/${recordType}/${recordId}/document`,
    {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${accessToken}`,
      },
      responseType: "blob",
    }
  );
  saveFileDocument(response.data, fileName);
};

export const downloadCustomerOrderPdf = async (orderId, fileName) => {
  let accessToken = localStorage.getItem("access_token");
  let response = await axios.get(
    `${process.env.REACT_APP_API}/api/v1/customer/orders/${orderId}/pdf`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      responseType: "blob",
    }
  );
  saveFileDocument(response.data, fileName);
};

export const printDocument = async (
  store,
  recordType,
  recordId,
  onPrintDialogClose
) => {
  let accessToken = localStorage.getItem("access_token");
  let response = await axios.get(
    `${process.env.REACT_APP_API}/api/v1/org/store/${store.slug}/records/${recordType}/${recordId}/document`,
    {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${accessToken}`,
      },
      responseType: "blob",
    }
  );

  let pdfUrl = URL.createObjectURL(response.data);

  printJS({
    printable: pdfUrl,
    onPrintDialogClose: onPrintDialogClose,
  });
};
