import { INVOICES_PER_PAGE } from "../../utils/utils";
import {
  CREATE_INVOICE,
  FETCH_INVOICES,
  INVOICE_PROGRESS,
  SET_INVOICE_ERROR,
  SET_INVOICE_LOADER,
  UPDATE_INVOICE,
  CLEAR_INVOICE,
  CLEAR_COMPLETE_INVOICE,
  DELETE_INVOICE,
  INVOICE_DATE_SELECTOR,
  INVOICE_STATUS_SELECTOR,
  INVOICE_DATE_RANGE,
  SET_INVOICES_PER_PAGE,
  SET_INVOICES_CURRENT_PAGE,
  SET_INVOICES_MAX_PAGE_SO_FAR,
  SET_INVOICES_INPUT_KEYWORD,
  SET_INVOICES_SEARCH_KEYWORD,
  SET_INVOICES_CUSTOMER_ID,
} from "./invoiceTypes";
import axios from "axios";
import { getDefaultStore } from "../../utils/storeUtil";

export const saveInvoice = (invoiceData) => async (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  let data = invoiceData;
  let newInv = invoiceData.id ? false : true;
  let storeId = store.store_id;
  data.org_id = store.org_id;
  data.store_id = storeId;
  return await axios
    .post(
      `${process.env.REACT_APP_API}/api/v1/org/store/${storeId}/order/from-store-order?src=web`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      dispatch({
        type: newInv ? CREATE_INVOICE : UPDATE_INVOICE,
        payload: response.data.payload,
      });
      return response.data.payload;
    })
    .catch((error) => {
      dispatch({
        type: SET_INVOICE_ERROR,
        payload: { error: error.message },
      });
    });
};

export const fetchInvoices =
  (
    page = 0,
    status = "ALL",
    aoe = null,
    boe = null,
    keyword = null,
    size = INVOICES_PER_PAGE,
    customerId = null
  ) =>
  async (dispatch) => {
    dispatch({
      type: INVOICE_PROGRESS,
      payload: true,
    });
    let accessToken = localStorage.getItem("access_token");
    let store = getDefaultStore();
    return await axios
      .get(
        `${process.env.REACT_APP_API}/api/v1/org/store/${
          store.store_id
        }/order/find?type=INVOICE&page=${page}&all-invoices=true&size=${size}&sort=createdAt,desc&term=${status}${
          aoe && boe ? `&created-aoe=${aoe}&created-boe=${boe}` : ""
        }${keyword ? `&q=${keyword}` : ""}${
          customerId ? `&org-store-customer-id=${customerId}` : ""
        }`,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: FETCH_INVOICES,
          payload: response.data.payload,
          invoicesPerPage: size,
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_INVOICE_ERROR,
          payload: { error: error.message },
        });
      });
  };

export const clearInvoices = () => (dispatch) => {
  dispatch({
    type: CLEAR_COMPLETE_INVOICE,
  });
};
export const refetchInvoice = (invoiceId) => (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  axios
    .get(
      `${process.env.REACT_APP_API}/api/v1/org/store/${store.store_id}/order/${invoiceId}`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      dispatch({
        type: UPDATE_INVOICE,
        payload: response.data.payload,
      });
    });
};

export const deleteInvoice = (invoiceId) => (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  axios
    .delete(
      `${process.env.REACT_APP_API}/api/v1/org/store/${store.store_id}/order/${invoiceId}`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      dispatch({
        type: DELETE_INVOICE,
        payload: { invoiceId },
      });
    });
};

export const setInvoiceDateSelector = (data) => (dispatch) => {
  dispatch({
    type: INVOICE_DATE_SELECTOR,
    payload: data,
  });
};

export const setInvoiceStatusSelector = (data) => (dispatch) => {
  dispatch({
    type: INVOICE_STATUS_SELECTOR,
    payload: data,
  });
};

export const setInvoiceDateRange = (data) => (dispatch) => {
  dispatch({
    type: INVOICE_DATE_RANGE,
    payload: data,
  });
};

export const setInvoicesPerPage = (data) => (dispatch) => {
  dispatch({
    type: SET_INVOICES_PER_PAGE,
    payload: data,
  });
};

export const clearInvoice = () => (dispatch) => {
  dispatch({
    type: CLEAR_INVOICE,
    payload: null,
  });
};

export const startInvoiceLoader = () => (dispatch) => {
  dispatch({
    type: SET_INVOICE_LOADER,
    payload: true,
  });
};

export const stopInvoiceLoader = () => (dispatch) => {
  dispatch({
    type: SET_INVOICE_LOADER,
    payload: false,
  });
};

export const setInvoicesCurrentPage = (data) => (dispatch) => {
  dispatch({
    type: SET_INVOICES_CURRENT_PAGE,
    payload: data,
  });
};

export const setInvoicesMaxPageSoFar = (data) => (dispatch) => {
  dispatch({
    type: SET_INVOICES_MAX_PAGE_SO_FAR,
    payload: data,
  });
};

export const setInvoicesInputKeyword = (data) => (dispatch) => {
  dispatch({
    type: SET_INVOICES_INPUT_KEYWORD,
    payload: data,
  });
};

export const setInvoicesSearchKeyword = (data) => (dispatch) => {
  dispatch({
    type: SET_INVOICES_SEARCH_KEYWORD,
    payload: data,
  });
};

export const setInvoicesCustomerId = (data) => (dispatch) => {
  dispatch({
    type: SET_INVOICES_CUSTOMER_ID,
    payload: data,
  });
};
