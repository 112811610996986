import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  pageheading: {
    fontSize: 20,
    lineHeight: "28px",
    fontWeight: 600,
  },
  primary: {
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 600,
    [theme.breakpoints.up("md")]: {
      fontSize: 16,
      lineHeight: "24px",
    },
  },
  charge: {
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 600,
    [theme.breakpoints.up("md")]: {
      fontSize: 16,
      lineHeight: "20px",
    },
  },
  secondary: {
    fontSize: 12,
    color: "#666666",
    lineHeight: "16px",
  },
  text: {
    fontSize: 14,
    color: "#666666",
    lineHeight: "20px",
  },
  rupee: {
    fontSize: 12,
    lineHeight: "16px",
    fontWeight: 600,
  },
  renewinfo: {
    paddingLeft: 32,
    paddingRight: 32,
    backgroundColor: "#ffffff",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 16,
      marginTop: 16,
      paddingRight: 16,
    },
  },
  duedate: {
    borderRadius: 4,
    marginTop: 16,
    padding: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 388,
  },
  media: {
    width: 48,
    height: 48,
    objectFit: "cover",
    borderRadius: 8,
  },
  planstatus: {
    color: "#FFFFFF",
    borderRadius: 32,
    padding: "4px 16px",
    fontSize: 12,
    lineHeight: "16px",
    [theme.breakpoints.up("md")]: {
      fontSize: 14,
      lineHeight: "20px",
    },
  },
  renew: {
    padding: "0px 0px 0px 0px",
    display: "flex",
    maxWidth: 640,
    flexDirection: "column",
  },
  plansummary: {
    backgroundColor: "#ffffff",
    flexGrow: 1,
    padding: "24px 16px 32px 16px",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      padding: "24px 32px 64px 32px",
    },
  },
  paymentLoading: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paymentbutton: {
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
      paddingBottom: "24px",
      paddingRight: "16px",
    },
  },
}));
