import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CircleOutIcon = ({ viewBox = "0 0 24 24", stroke = "#999999" }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      style={{ width: "24px", height: "24px", fill: "transparent" }}
    >
      <path
        d="M7.6001 16.5C9.0001 18.9 11.6001 20.5 14.5001 20.5C18.9001 20.5 22.5001 16.9 22.5001 12.5C22.5001 8.1 18.9001 4.5 14.5001 4.5C11.5001 4.5 9.0001 6.1 7.6001 8.5"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 12.5H1.5"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 15.5L1.5 12.5L4.5 9.5"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
export default CircleOutIcon;
