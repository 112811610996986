import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const NoReportIcon = () => {
  return (
    <SvgIcon viewBox="0 0 42 41" style={{ width: "42px", height: "41px" }}>
      <svg
        width="42"
        height="41"
        viewBox="0 0 42 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.75 4.25C2.4925 4.25 2.2425 4.27 1.995 4.3075C2.47455 3.17797 3.276 2.21461 4.2994 1.53753C5.32281 0.860442 6.52289 0.499602 7.75 0.5H30.25C31.9076 0.5 33.4973 1.15848 34.6694 2.33058C35.8415 3.50268 36.5 5.0924 36.5 6.75V17C35.701 16.5912 34.8636 16.2621 34 16.0175V6.75C34 5.75544 33.6049 4.80161 32.9016 4.09835C32.1984 3.39509 31.2446 3 30.25 3H7.75C6.4675 3 5.335 3.645 4.6575 4.625C4.05243 4.37603 3.40428 4.24861 2.75 4.25Z"
          fill="#E5E5E5"
        />
        <path
          d="M17.0175 33.0003C17.265 33.8728 17.5925 34.7078 18 35.5003H7.75C6.0924 35.5003 4.50269 34.8418 3.33058 33.6697C2.15848 32.4976 1.5 30.9079 1.5 29.2503V14.0928C2.31994 14.304 3.18006 14.304 4 14.0928V29.2503C4 30.2448 4.39509 31.1987 5.09835 31.9019C5.80161 32.6052 6.75544 33.0003 7.75 33.0003H17.0175Z"
          fill="#E5E5E5"
        />
        <path
          d="M18 23C18.4575 22.105 19.01 21.2675 19.6425 20.5H12.75C12.4185 20.5 12.1005 20.6317 11.8661 20.8661C11.6317 21.1005 11.5 21.4185 11.5 21.75C11.5 22.0815 11.6317 22.3995 11.8661 22.6339C12.1005 22.8683 12.4185 23 12.75 23H18Z"
          fill="#E5E5E5"
        />
        <path
          d="M2.75 6.75C2.08696 6.75 1.45107 7.01339 0.982233 7.48223C0.513392 7.95107 0.25 8.58696 0.25 9.25C0.25 9.91304 0.513392 10.5489 0.982233 11.0178C1.45107 11.4866 2.08696 11.75 2.75 11.75C3.41304 11.75 4.04892 11.4866 4.51777 11.0178C4.98661 10.5489 5.25 9.91304 5.25 9.25C5.25 8.58696 4.98661 7.95107 4.51777 7.48223C4.04892 7.01339 3.41304 6.75 2.75 6.75Z"
          fill="#E5E5E5"
        />
        <path
          d="M12.75 13C12.4185 13 12.1005 13.1317 11.8661 13.3661C11.6317 13.6005 11.5 13.9185 11.5 14.25C11.5 14.5815 11.6317 14.8995 11.8661 15.1339C12.1005 15.3683 12.4185 15.5 12.75 15.5H25.25C25.5815 15.5 25.8995 15.3683 26.1339 15.1339C26.3683 14.8995 26.5 14.5815 26.5 14.25C26.5 13.9185 26.3683 13.6005 26.1339 13.3661C25.8995 13.1317 25.5815 13 25.25 13H12.75Z"
          fill="#E5E5E5"
        />
        <path
          d="M41.5 29.25C41.5 32.2337 40.3147 35.0952 38.2049 37.2049C36.0952 39.3147 33.2337 40.5 30.25 40.5C27.2663 40.5 24.4048 39.3147 22.295 37.2049C20.1853 35.0952 19 32.2337 19 29.25C19 26.2663 20.1853 23.4048 22.295 21.295C24.4048 19.1853 27.2663 18 30.25 18C33.2337 18 36.0952 19.1853 38.2049 21.295C40.3147 23.4048 41.5 26.2663 41.5 29.25ZM31.5 24.25C31.5 23.9185 31.3683 23.6005 31.1339 23.3661C30.8995 23.1317 30.5815 23 30.25 23C29.9185 23 29.6005 23.1317 29.3661 23.3661C29.1317 23.6005 29 23.9185 29 24.25V28H25.25C24.9185 28 24.6005 28.1317 24.3661 28.3661C24.1317 28.6005 24 28.9185 24 29.25C24 29.5815 24.1317 29.8995 24.3661 30.1339C24.6005 30.3683 24.9185 30.5 25.25 30.5H29V34.25C29 34.5815 29.1317 34.8995 29.3661 35.1339C29.6005 35.3683 29.9185 35.5 30.25 35.5C30.5815 35.5 30.8995 35.3683 31.1339 35.1339C31.3683 34.8995 31.5 34.5815 31.5 34.25V30.5H35.25C35.5815 30.5 35.8995 30.3683 36.1339 30.1339C36.3683 29.8995 36.5 29.5815 36.5 29.25C36.5 28.9185 36.3683 28.6005 36.1339 28.3661C35.8995 28.1317 35.5815 28 35.25 28H31.5V24.25Z"
          fill="#E5E5E5"
        />
      </svg>
    </SvgIcon>
  );
};
export default NoReportIcon;
