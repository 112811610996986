import { Divider, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import useStyles from "../styles";
import { rowFlexJustifyBetween } from "../../../../../styles/common";
import TextButton from "../../../../../components/buttons/TextButton";
import { useParams } from "react-router-dom";
import { addSkipResume, getUpcomingActivities } from "../../contractUtils";

const UpcomingOrders = () => {
  const classes = useStyles();
  const params = useParams();

  const [activities, setActvities] = useState([]);

  useEffect(() => {
    if (params.contractId) {
      getUpcomingActivities(params.contractId).then((res) => {
        setActvities(res);
      });
    }
  }, [params.contractId]);

  const handleSkipResume = async (activity) => {
    const type = activity.skipped ? "Resume" : "Skip";
    try {
      await addSkipResume(activity.id, type, params.contractId);
      getUpcomingActivities(params.contractId).then((res) => {
        setActvities(res);
      });
    } catch (error) {
      console.error("Error in handling skip/resume", error);
    }
  };
  return (
    <>
      <Typography className={classes.heading} style={{ marginBottom: 0 }}>
        Upcoming Orders
      </Typography>
      <Typography className={classes.subHeading} style={{ marginTop: 8 }}>
        Manage customers upcoming orders
      </Typography>

      {activities?.map((activity, idx) => {
        return (
          <>
            <div
              key={idx}
              className={rowFlexJustifyBetween}
              style={{ marginTop: 20 }}
            >
              <Typography
                className={classes.heading}
                style={{ fontWeight: 400 }}
              >
                {activity.activity_at}
              </Typography>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <TextButton
                  style={{ fontSize: "14px", textDecoration: "none" }}
                  onClick={() => handleSkipResume(activity)}
                >
                  {activity.skipped ? "Resume" : "Skip"}
                </TextButton>
              </div>
            </div>
            {idx !== activities.length - 1 && (
              <Divider style={{ margin: "14px 0px" }} />
            )}
          </>
        );
      })}
    </>
  );
};

export default UpcomingOrders;
