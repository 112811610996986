import { Button } from "@material-ui/core";
import React from "react";
import { CloseIcon } from "../../icons";

const CloseButton = ({ onClick, style = {}, iconStyle = {} }) => {
  return (
    <Button
      onClick={onClick}
      style={{
        outline: "none",
        background: "none",
        padding: 6,
        minWidth: 0,
        height: 32,
        ...style,
      }}
    >
      <CloseIcon height={iconStyle.height} width={iconStyle.width} />
    </Button>
  );
};

export default CloseButton;
