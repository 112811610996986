import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const LocationIcon = ({ fill = "#1A1A1A", style = {} }) => {
  return (
    <SvgIcon
      viewBox={"0 0 13 18"}
      style={{ width: "13px", height: "18px", ...style }}
    >
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.5 18L6.15179 17.5814C5.91965 17.277 0 10.4651 0 6.39324C0 2.85413 2.94048 0 6.5 0C10.0595 0 13 2.89218 13 6.39324C13 10.4651 7.08035 17.315 6.84821 17.5814L6.5 18ZM6.5 0.875271C3.40476 0.875271 0.928575 3.34884 0.928575 6.35518C0.928575 9.5518 5.22321 14.9937 6.5 16.5539C7.77679 14.9937 12.0714 9.58985 12.0714 6.35518C12.0714 3.34884 9.59524 0.875271 6.5 0.875271Z"
          fill={fill}
        />
        <path
          d="M6.49987 8.8288C5.1457 8.8288 4.02368 7.72521 4.02368 6.39329C4.02368 5.06137 5.1457 3.95776 6.49987 3.95776C7.85404 3.95776 8.97606 5.06137 8.97606 6.39329C8.97606 7.72521 7.85404 8.8288 6.49987 8.8288ZM6.49987 4.87109C5.64868 4.87109 4.95225 5.55608 4.95225 6.39329C4.95225 7.2305 5.64868 7.91549 6.49987 7.91549C7.35106 7.91549 8.04749 7.2305 8.04749 6.39329C8.04749 5.55608 7.35106 4.87109 6.49987 4.87109Z"
          fill={fill}
        />
      </svg>
    </SvgIcon>
  );
};

export default LocationIcon;
