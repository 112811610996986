import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
    marginLeft: "260px",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    height: 64,
    zIndex: 10,
    padding: "12px 40px",
    border: "1px solid #F5F5F5",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      marginLeft: "208px",
    },
  },
  title: {
    fontWeight: 600,
    fontSize: 20,
  },
  divider: {
    borderLeft: "1px solid #BEBEBE",
    margin: "2px 26px",
    width: 1,
    height: 24,
  },
  visit: {
    fontSize: 12,
    fontWeight: 400,
    marginRight: 5,
    color: "#1A1A1A",
  },
  sheet: {
    position: "absolute",
    top: 50,
    borderRadius: 6,
    background: "#FFFFFF",
    overflow: "hidden",
    zIndex: 1,
    boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.1)",
    display: "block",
    width: 203,
    right: -10,
  },
  svg: {
    transform: "rotate(180deg) scale(1.5)",
    position: "absolute",
    bottom: -12,
    right: 14,
  },
  label: {
    fontSize: 14,
    color: "#000000",
    marginLeft: 16,
  },
  item: {
    display: "flex",
    alignItems: "center",
    padding: "12px 14px",
    cursor: "pointer",
    borderTop: "1px solid #F5F5F5",
  },
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 24,
  },
  profileCard: {
    padding: 12,
    display: "flex",
  },
  phone: {
    color: "#666666",
    marginTop: 4,
  },
  arrow: {
    position: "absolute",
    top: 45,
    width: 16,
    height: 16,
    transform: "rotate(226deg)",
    background: "#FFFFFF",
    left: 12,
    zIndex: 2,
  },
  upgrade: {
    background: "#1641DB",
    color: "#FFFFFF",
    borderRadius: 20,
    padding: "6px 20px",
    marginLeft: 12,
    fontWeight: 500,
    fontSize: 14,
    marginRight: "10px",
    "&:hover": {
      background: "#1641DB",
    },
    "&:focus": {
      outline: "none !important",
    },
  },
  expired: {
    background: "#FF6060",
    border: "1px solid #FF6060",
    color: "#FFFFFF",
    borderRadius: 20,
    padding: "6px 36px",
    fontWeight: 500,
    fontSize: 14,
    "&:hover": {
      background: "#FF6060",
    },
  },
  dueIn: {
    background: "#FFEFEF",
    border: "1px solid #FF6060",
    color: "#FF6060",
    borderRadius: 20,
    padding: "6px 36px",
    fontWeight: 500,
    fontSize: 14,
    "&:hover": {
      background: "#FFEFEF",
    },
  },
  dueInWarn: {
    marginRight: "10px",
    background: "#FFF7EC",
    border: "1px solid #FFF7EC",
    color: "#FF6060",
    borderRadius: 20,
    padding: "6px 36px",
    fontWeight: 500,
    fontSize: 14,
    "&:hover": {
      background: "#FFF7EC",
    },
  },
}));
