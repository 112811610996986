import { Divider, Drawer, Grid, IconButton } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CloseIcon from "../../../icons/CloseIcon";
import "react-image-crop/dist/ReactCrop.css";
import OrderSummary from "./OrderSummary";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import axios from "axios";
import { Skeleton } from "@material-ui/lab";
import ReturnSummary from "./ReturnSummary";
import DeclineReturnModal from "./DeclineReturnModal";
import Toast from "../../../components/Layout/Toast";
import { useDispatch } from "react-redux";
import { UPDATE_ORDER } from "../../../redux/orders/orderTypes";

const ReturnOrderView = (props) => {
  const [customerReturnData, setCustomerReturnData] = useState(null);
  const [openDeclineModal, setOpenDeclineModal] = useState(false);
  const [message, setMessage] = useState("");
  const [toast, setToast] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("access_token");

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API}/api/v1/store/${props.order.store_id}/orders/${props.order.id}/return-requests`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((res) => {
        setCustomerReturnData(
          res.data.payload.find(
            (obj) => obj.return_status === "RETURN_REQUESTED"
          )
        );
      });
    // eslint-disable-next-line
  }, []);

  const sendReturnStatus = (status, message) => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API}/api/v1/store/${props.order.store_id}/orders/${props.order.id}/return-requests`,
        {
          return_status: status,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then(() => {
        props.setAnchoredOrder({
          ...props.order,
          status,
        });
        dispatch({
          type: UPDATE_ORDER,
          payload: {
            ...props.order,
            status,
          },
        });
        setMessage(message);
        setToast(true);
        setLoading(false);
      });
  };

  const onAcceptRequest = () => {
    sendReturnStatus("RETURN_REQUEST_ACCEPTED", "Return Request Accepted");
  };

  const onReturnComplete = () => {
    sendReturnStatus("RETURN_PROCESSED", "Return Request Processed");
  };
  const onShipmentReceived = () => {
    sendReturnStatus("RETURN_RECEIVED", "Shipment Marked Received");
  };
  return (
    <Drawer
      open={props.openReturnOrderView}
      onClose={() => {
        props.setOpenReturnOrderView(false);
        props.setAnchoredOrder(null);
      }}
      anchor="right"
      PaperProps={{
        style: {
          borderTopLeftRadius: 16,
          borderBottomLeftRadius: 16,
          padding: 52,
          paddingRight: 42,
          width: 570,
        },
      }}
    >
      <Grid container style={{ position: "relative" }}>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <p style={{ fontWeight: 700, fontSize: 24, marginTop: -10 }}>
            Return {props.order.display_id}
          </p>
          <IconButton
            aria-label="Close"
            aria-haspopup="true"
            color="inherit"
            onClick={() => {
              props.setOpenReturnOrderView(false);
              props.setAnchoredOrder(null);
            }}
            style={{ marginTop: -26, outline: "none" }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          {!customerReturnData ? (
            <Skeleton
              style={{
                width: "100%",
                height: 140,
                borderRadius: 8,
                transform: "none",
              }}
            />
          ) : (
            <ReturnSummary customerReturnData={customerReturnData} />
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider
            style={{
              marginTop: 20,
              height: 1,
            }}
          />
        </Grid>
        <OrderSummary {...props} />
        {props.order.status !== "RETURN_REQUEST_REJECTED" &&
          props.order.status !== "RETURN_PROCESSED" && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 40,
                width: "100%",
              }}
            >
              <CombinedButtons
                solidBtnText={
                  props.order.status === "RETURN_REQUESTED"
                    ? "Accept Request"
                    : props.order.status === "RETURN_PRODUCT_SHIPPED" ||
                      props.order.status === "RETURN_REQUEST_ACCEPTED"
                    ? "Shipment Received"
                    : "Return Complete"
                }
                outlinedBtnText="Decline Request"
                outlinedBtnAction={() => setOpenDeclineModal(true)}
                solidBtnAction={() =>
                  props.order.status === "RETURN_REQUESTED"
                    ? onAcceptRequest()
                    : props.order.status === "RETURN_PRODUCT_SHIPPED" ||
                      props.order.status === "RETURN_REQUEST_ACCEPTED"
                    ? onShipmentReceived()
                    : onReturnComplete()
                }
                loading={loading}
              />
            </div>
          )}
      </Grid>
      <DeclineReturnModal
        setOpenDeclineModal={setOpenDeclineModal}
        openDeclineModal={openDeclineModal}
        order={props.order}
        setMessage={setMessage}
        setToast={setToast}
        setAnchoredOrder={props.setAnchoredOrder}
      />
      <Toast message={message} open={toast} close={setToast} />
    </Drawer>
  );
};

export default ReturnOrderView;
