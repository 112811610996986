import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postPluginConfig } from "../../../../redux/apps/appActions";
import Controls from "../../../../components/controls/Controls";
import useStyles from "../../components/ConfigStyles";
import CombinedButtons from "../../../../components/buttons/CombinedButtons";
import Toast from "../../../../components/Layout/Toast";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const ApiKeyForm = ({ showSetting, setShowSetting }) => {
  const classes = useStyles();

  const history = useHistory();

  const dispatch = useDispatch();
  const appState = useSelector((state) => state.apps);

  const initialState = {
    x_api_key: {
      value: "",
      touched: false,
      error: false,
    },
  };

  const [formState, setFormState] = useState(initialState);
  const [channels, setChannels] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (appState.baat["X-API-KEY"]) {
      setFormState({
        ...formState,
        x_api_key: {
          ...formState.x_api_key,
          value: appState.baat["X-API-KEY"],
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.baat]);

  const handleChnage = (event) => {
    let { name, value } = event.target;
    let cloneData = formState[name];
    cloneData.touched = true;
    cloneData.value = value;
    setFormState({ ...formState, [name]: cloneData });
  };

  const onBlur = (event) => {
    const { name } = event.target;
    const cloneData = formState[name];
    if (!cloneData.value)
      cloneData.error =
        name === "x_api_key" &&
        "Baat API Key is required. Please enter a valid Key.";
    setFormState((prevState) => ({ ...prevState, [name]: cloneData }));
  };

  const onFocus = (event) => {
    const { name } = event.target;
    let cloneData = formState[name];
    cloneData.touched = true;
    cloneData.error = "";
    setFormState((prevState) => ({ ...prevState, [name]: cloneData }));
  };

  const valiadteAPIKey = async (value) => {
    const requestChannels = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BAAT_API}/v1/remote/account/channels`,
        {
          method: "GET",
          headers: {
            "X-API-KEY": value,
          },
        }
      );
      const data = await response.json();
      return data;
    };

    if (value.length === 0) {
      return "Please ensure to enter a valid api key";
    }
    setLoading(true);
    try {
      const res = await requestChannels(appState);
      setLoading(false);
      if (res) {
        setChannels(res);
        return "";
      } else {
        return "Please ensure to enter a valid api key";
      }
    } catch (err) {
      setLoading(false);
      return "Please ensure to enter a valid api key";
    }
  };

  const modifyState = () => {
    let payload = {};
    payload["X-API-KEY"] = formState.x_api_key.value;
    Object.keys(appState.baat).forEach((key) => {
      if (key !== "channel_id" && key !== "X-API-KEY") {
        payload[key] = null;
      }
    });
    return payload;
  };

  const navigateToUrl = () => {
    if (appState.baat["X-API-KEY"] && !showSetting) {
      setShowSetting(true);
    } else {
      history.goBack();
    }
  };

  const handleSave = async () => {
    let cloneData = { ...formState.x_api_key };
    cloneData.touched = true;
    cloneData.error = await valiadteAPIKey(formState.x_api_key.value);
    setFormState({ ...formState, x_api_key: cloneData });
    if (cloneData.error.length > 0) {
      return;
    }

    try {
      if (appState.baat["X-API-KEY"] !== formState.x_api_key.value) {
        let channelIdExist = false;
        channels.length > 0 &&
          channels.forEach((channel) => {
            if (channel.id === appState.baat.channel_id) {
              channelIdExist = true;
            }
          });

        if (channelIdExist) {
          dispatch(
            postPluginConfig("baat", {
              "X-API-KEY": formState.x_api_key.value,
            })
          );
        } else {
          const payload = modifyState();
          dispatch(postPluginConfig("baat", payload));
        }
      }
      setShowSuccess(true);
      setTimeout(() => {
        setShowSetting(true);
      }, 500);
    } catch (error) {
      setShowError(true);
      setErrorMessage(error.message);
    }
  };

  return (
    <>
      <Controls.Input
        name="x_api_key"
        label="Baat API Key"
        labelPlacement="top"
        value={formState.x_api_key.value}
        onChange={handleChnage}
        onFocus={onFocus}
        onBlur={onBlur}
        error={formState.x_api_key.error}
        helperText={!formState.x_api_key.error && "Paste Baat API Key here."}
      />
      <div className={classes.button}>
        <CombinedButtons
          outlinedBtnText="Back"
          outlinedBtnAction={navigateToUrl}
          loading={loading}
          solidBtnAction={handleSave}
        />
      </div>
      <Toast
        message={errorMessage ? errorMessage : "Something went wrong"}
        open={showError}
        close={() => setShowError(false)}
        severity="error"
        autoClose
      />
      <Toast
        message={"API Key saved successfully"}
        open={showSuccess}
        close={() => setShowSuccess(false)}
        autoClose
      />
    </>
  );
};
