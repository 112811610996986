import axios from "axios";
export const createUpdatePage = async (pages, store, pageSlug, page) => {
  let accessToken = localStorage.getItem("access_token");
  let existingSlug = pages?.find((pg) => pg.slug === pageSlug)?.slug;
  let response = {};
  if (existingSlug) {
    response = await axios.patch(
      `${process.env.REACT_APP_API}/api/v1/org/store/${store.slug}/pages/${pageSlug}`,
      page,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
  } else {
    response = await axios.post(
      `${process.env.REACT_APP_API}/api/v1/org/store/${store.slug}/pages`,
      page,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
  }
  return response.data.payload;
};
