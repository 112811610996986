import React from "react";
import { ArrowIcon } from "../../../icons";

const NoUser = ({ text, image, arrow }) => {
  return (
    <div style={{ position: "relative", width: "100%" }}>
      {arrow && (
        <div
          style={{
            position: "absolute",
            right: "13%",
            top: 30,
          }}
        >
          <ArrowIcon />
        </div>
      )}
      <div
        style={{
          position: "absolute",
          left: "50%",
          transform: "translate(-50%, 95px)",
        }}
      >
        <p
          style={{
            fontWeight: 600,
            fontSize: 20,
            lineHeight: "32px",
            color: "#1A1A1A",
          }}
        >
          {text}
        </p>
      </div>
      {image && (
        <div
          style={{
            position: "absolute",
            left: "50%",
            transform: "translate(-50%, 210px)",
          }}
        >
          {image}
        </div>
      )}
    </div>
  );
};

export default NoUser;
