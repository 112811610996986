import React from "react";
import Lottie from "react-lottie";
import Loader from "../Loader.json";

const PageLoader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Lottie options={defaultOptions} height={300} width={300} />
    </div>
  );
};

export default PageLoader;
