import React from "react";
import { makeStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles({
  search: {
    border: "1px solid #E6E6E6",
    borderRadius: 6,
    padding: "12px",
    paddingTop: 9,
    height: "36px",
    flexGrow: 1,
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  searchIcon: {
    height: "100%",
  },
  searchBox: {
    outlineWidth: 0,
    lineHeight: "16px",
    marginLeft: "12px",
    border: "none",
    fontSize: "12px",
    flex: 1,
    minWidth: 0,
    marginTop: "3px",
  },
});

const ProductSearch = ({ searchKeyword, setSearchKeyword }) => {
  const classes = useStyles();
  return (
    <div className={classes.search}>
      <SearchIcon />
      <input
        type="text"
        placeholder="Enter Product Name"
        className={classes.searchBox}
        value={searchKeyword}
        onChange={(event) => {
          const { value } = event.target;
          setSearchKeyword(value);
        }}
        onKeyDown={(event) => {
          if (event.key === "Escape") {
            setSearchKeyword("");
          }
        }}
      />
      {searchKeyword !== "" && (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSearchKeyword("");
          }}
        >
          <CloseIcon fontSize="small" />
        </span>
      )}
    </div>
  );
};

export default ProductSearch;
