import axios from "axios";
import { getDefaultStore } from "../../../utils/storeUtil";
import {
  SET_SORT_BY,
  FETCH_SUPER_PROD,
  SET_SUPER_PROD_ERROR,
  SET_SUPER_PROD_MAX_PAGE_SO_FAR,
  SET_SUPER_PROD_SEARCH_KEYWORD,
  SET_SUPER_PROD_CURRENT_PAGE,
  SET_SUPER_PROD_PER_PAGE,
  SUPER_PROD_PROGRESS,
  SET_SUPER_PROD_LOADER,
  SET_SUPER_PROD_INPUT_KEYWORD,
  CLEAR_SUPER_PROD_PLANS,
} from "./superProdTypes";
import { SUPER_PROD_PER_PAGE } from "../../../utils/utils";
export const handleFetchSuperProduct =
  (
    keyword = null,
    sortQuery = "name,asc",
    page = 0,
    size = SUPER_PROD_PER_PAGE,
    excludePlanProducts = true
  ) =>
  async (dispatch) => {
    // Add async here
    dispatch({
      type: SUPER_PROD_PROGRESS,
      payload: true,
    });

    try {
      let accessToken = localStorage.getItem("access_token");
      const store = getDefaultStore();
      const baseUrl = process.env.REACT_APP_API;
      const isLocal = process.env.REACT_APP_LOCAL_HOST === "api.shoopy.in";
      const endpoint = `${baseUrl}/api/v1/org/${store.org_id}/super-products${
        isLocal ? "/search" : ""
      }`;
      const url = `${endpoint}?q=${encodeURIComponent(
        keyword
      )}&page=${page}&size=${size}&sort=${sortQuery}&exclude-plan-products=${excludePlanProducts}`;

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });

      const data = response.data.payload;
      dispatch({
        type: FETCH_SUPER_PROD,
        payload: data,
        superProdPerPage: size,
      });
    } catch (error) {
      dispatch({ type: SET_SUPER_PROD_ERROR, payload: error.message });
    }
  };

export const setSuperProdSortBy = (sortQuery) => ({
  type: SET_SORT_BY,
  payload: sortQuery,
});

export const startSuperProdLoader = () => (dispatch) => {
  dispatch({
    type: SET_SUPER_PROD_LOADER,
    payload: true,
  });
};

export const stopSuperProdLoader = () => (dispatch) => {
  dispatch({
    type: SET_SUPER_PROD_LOADER,
    payload: false,
  });
};
export const setSuperProdPerPage = (data) => (dispatch) => {
  dispatch({
    type: SET_SUPER_PROD_PER_PAGE,
    payload: data,
  });
};
export const setSuperProdCurrentPage = (data) => (dispatch) => {
  dispatch({
    type: SET_SUPER_PROD_CURRENT_PAGE,
    payload: data,
  });
};
export const setSuperProdMaxPageSoFar = (data) => (dispatch) => {
  dispatch({
    type: SET_SUPER_PROD_MAX_PAGE_SO_FAR,
    payload: data,
  });
};
export const setSuperProdSearchKeyword = (data) => (dispatch) => {
  dispatch({
    type: SET_SUPER_PROD_SEARCH_KEYWORD,
    payload: data,
  });
};
export const setSuperProdInputKeyword = (data) => (dispatch) => {
  dispatch({
    type: SET_SUPER_PROD_INPUT_KEYWORD,
    payload: data,
  });
};

export const clearSuperProdPlan = () => (dispatch) => {
  dispatch({ type: CLEAR_SUPER_PROD_PLANS, payload: [] });
};
