import React from 'react'

export default function twitterShare() {
    return (
      <svg
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle opacity="0.05" cx="30" cy="30" r="30" fill="#55ACEE" />
        <circle cx="30" cy="30" r="20" fill="#55ACEE" />
        <path
          d="M43.3319 22.4585C42.3916 22.8755 41.3811 23.1573 40.3206 23.2841C41.4032 22.6352 42.2344 21.6077 42.6259 20.3834C41.5967 20.9942 40.4707 21.4244 39.2965 21.6557C38.3401 20.6368 36.9776 20 35.4695 20C32.5739 20 30.2263 22.3475 30.2263 25.2429C30.2263 25.6539 30.2727 26.054 30.3621 26.4378C26.0047 26.2191 22.1414 24.1318 19.5554 20.9597C19.1042 21.7341 18.8456 22.6348 18.8456 23.5955C18.8456 25.4146 19.7713 27.0193 21.1781 27.9596C20.3454 27.9335 19.5312 27.7086 18.8032 27.3037C18.8029 27.3257 18.8029 27.3476 18.8029 27.3697C18.8029 29.91 20.6101 32.0291 23.0086 32.5107C22.2365 32.7207 21.4266 32.7514 20.6409 32.6006C21.308 34.6836 23.2444 36.1993 25.5386 36.2418C23.7442 37.6479 21.4834 38.4862 19.0271 38.4862C18.6038 38.4862 18.1866 38.4613 17.7764 38.4129C20.0966 39.9005 22.8525 40.7685 25.8134 40.7685C35.4573 40.7685 40.7308 32.7793 40.7308 25.8509C40.7308 25.6235 40.7259 25.3974 40.7157 25.1726C41.7421 24.4306 42.6281 23.5115 43.3319 22.4585Z"
          fill="white"
        />
      </svg>
    );
}
