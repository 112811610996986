import { Drawer, Typography } from "@material-ui/core";
import { ColorPicker, createColor } from "material-ui-color";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import CombinedButtons from "../../../../components/buttons/CombinedButtons";
import OutlinedButton from "../../../../components/buttons/OutlinedButton";
import HeaderBreadcrumbs from "../../../../components/common/HeaderBreadcrumbs";
import Select from "../../../../components/controls/Select";
import PageLoader from "../../../../components/Layout/PageLoader";
import Toast from "../../../../components/Layout/Toast";
import {
  addMenu,
  fetchMenu,
  rearrangeMenuItems,
  updateMenu,
} from "../../../../redux/menu/menuActions";
import {
  layoutBodyBox,
  layoutContainer,
  rowFlexAlignStart,
} from "../../../../styles/common";
import { getStoreIdFromUrl } from "../../../../utils/storeUtil";
import { getMenuColor } from "./Constants";
import MenuDrawer from "./MenuDrawer";
import MenuItemCard from "./MenuItemCard";
import useStyles from "./styles";
import { colorPickerColors } from "../../../../utils/colorPickerColors";
import { reorder } from "../../../../components/common/CommonUtils";

const Menu = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const state = useSelector((state) => state);
  const store = state.store.store;
  const menuState = state.menu;
  const menu = menuState.menu;
  const menuItems = menuState.menuItems;
  const progress = menuState.progress;
  const loaded = menuState.loaded;
  const defMenuColor = getMenuColor(
    store.store_setting && store.store_setting.theme_color
  );
  const storeId = getStoreIdFromUrl();
  const [menuAnchor, setMenuAnchor] = useState(false);
  const [parentMenuItems, setParentMenuItems] = useState([]);
  const [selParentMenuItem, setSelParentMenuItem] = useState(null);
  const [toastSucc, setToastSucc] = useState(false);
  const [toastSuccMsg, setToastSuccMsg] = useState(false);
  const [toastDel, setToastDel] = useState(false);
  const [toastErr, setToastErr] = useState(false);
  const [menuCss, setMenuCss] = useState({
    textAlign: "left",
    color: createColor(defMenuColor.color),
    backgroundColor: createColor(defMenuColor.bgColor),
  });
  const [editItemRecord, setEditItemRecord] = useState({
    record: null,
    parentId: null,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (parentMenuItems.length === 0) {
      dispatch(fetchMenu());
    }
    // eslint-disable-next-line
  }, [storeId]);

  useEffect(() => {
    if (menu.css && defMenuColor.bgColor) {
      setMenuCss({
        textAlign: menu.css?.textAlign ?? "left",
        color: createColor(menu.css?.color) ?? createColor(defMenuColor.color),
        backgroundColor:
          createColor(menu.css?.backgroundColor) ??
          createColor(defMenuColor.bgColor),
      });
    }
    // eslint-disable-next-line
  }, [menu]);

  useEffect(() => {
    setParentMenuItems(menuItems);
  }, [menuItems]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.type === "PARENTMENUITEM") {
      const { items, change } = reorder(
        parentMenuItems,
        result.source.index,
        result.destination.index,
        "id"
      );
      dispatch(rearrangeMenuItems(menu.id, change));
      setParentMenuItems(items);
      setToastSucc(true);
      setToastSuccMsg("Position changed successfully");
    } else {
      let menuItemIndex = parentMenuItems.findIndex(
        (item) => `${item.name}-${item.id}` === result.destination.droppableId
      );
      if (menuItemIndex >= 0) {
        const { items, change } = reorder(
          parentMenuItems[menuItemIndex].children,
          result.source.index,
          result.destination.index,
          "id"
        );
        const newParentMenuItems = Array.from(parentMenuItems);
        newParentMenuItems[menuItemIndex].children = items;
        dispatch(rearrangeMenuItems(menu.id, change));
        setParentMenuItems(newParentMenuItems);
        setToastSucc(true);
        setToastSuccMsg("Position changed successfully");
      }
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    let css = {
      textAlign: menuCss.textAlign,
      color: menuCss.color?.css?.backgroundColor,
      backgroundColor: menuCss.backgroundColor?.css?.backgroundColor,
    };

    if (!menu?.id) {
      let payload = {
        name: "Header Menu",
        type: "header",
        css: css,
        items: [],
      };
      await dispatch(addMenu(payload)).then((res) => {
        if (res?.id) {
          setToastSucc(true);
          setToastSuccMsg("Setting Saved Successfully");
          setLoading(false);
        } else {
          setToastErr(true);
        }
      });
    } else {
      let payload = {
        css: css,
      };
      await dispatch(updateMenu(menu.id, payload)).then(() => {
        setToastSucc(true);
        setToastSuccMsg("Setting Saved Successfully");
        setLoading(false);
      });
    }
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    marginTop: 20,
    padding: 0,
    ...draggableStyle,
  });

  if (progress && !loaded) return <PageLoader />;

  return (
    <div style={{ position: "relative" }}>
      <div className={layoutContainer}>
        <div style={{ marginBottom: 20 }}>
          <HeaderBreadcrumbs
            list={[
              { label: "Display Settings", link: "/display" },
              {
                label: "Menu",
              },
            ]}
          />
        </div>

        <div
          className={rowFlexAlignStart}
          style={{ position: "relative", width: "100%" }}
        >
          <div
            className={layoutBodyBox}
            style={{ maxWidth: 604, width: "65%" }}
          >
            <div style={{ padding: "8px 32px 32px" }}>
              {parentMenuItems.length === 0 ? (
                <div style={{ textAlign: "center", margin: "60px 0px" }}>
                  <img
                    alt="no menu item"
                    src="https://storage.googleapis.com/shy-pub/_static/app-img/no-menuitem.png"
                    style={{ height: 193 }}
                  />
                </div>
              ) : (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable
                    droppableId="parentMenuItem"
                    type="PARENTMENUITEM"
                    onD
                  >
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          position: "relative",
                          width: "100%",
                          marginTop: -10,
                        }}
                      >
                        {parentMenuItems &&
                          parentMenuItems.map((item, index) => {
                            return (
                              <Draggable
                                key={item.id}
                                draggableId={`${item.id}`}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    <MenuItemCard
                                      menu={menu}
                                      menuItem={item}
                                      handleProps={provided.dragHandleProps}
                                      subMenuItems={item.children}
                                      setMenuAnchor={setMenuAnchor}
                                      setSelParentMenuItem={
                                        setSelParentMenuItem
                                      }
                                      setEditItemRecord={setEditItemRecord}
                                      setToastDel={setToastDel}
                                      setToastErr={setToastErr}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
              <div style={{ textAlign: "center", marginTop: 60 }}>
                <OutlinedButton
                  style={{ width: "40%", height: 40 }}
                  fullWidth
                  onClick={() => {
                    setMenuAnchor(true);
                    setSelParentMenuItem(null);
                    setEditItemRecord({
                      record: null,
                      parentMenuItem: null,
                    });
                  }}
                >
                  <span style={{ fontWeight: "400" }}>Add Menu Item</span>
                </OutlinedButton>
              </div>
            </div>
          </div>
          <div
            className={layoutBodyBox}
            style={{ marginLeft: 14, width: "35%" }}
          >
            <div style={{ padding: "8px 32px 32px" }}>
              <Select
                name="aligment"
                label="Select Menu Item Alignment"
                value={menuCss.textAlign}
                onChange={(e) => {
                  setMenuCss({ ...menuCss, textAlign: e.target.value });
                }}
                options={[
                  { title: "Left Aligned", id: "left" },
                  { title: "Center Aligned", id: "center" },
                ]}
                color="secondary"
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                labelPlacement="top"
              />
              <div style={{ marginBottom: "16px" }}>
                <Typography className={classes.formLabel}>
                  Text Color
                </Typography>
                <div className={classes.colorPicker}>
                  <ColorPicker
                    hideTextfield
                    value={menuCss?.color}
                    onChange={(value) => {
                      setMenuCss({ ...menuCss, color: value });
                    }}
                    palette={colorPickerColors}
                  />
                  <Typography style={{ fontSize: 14, marginLeft: 10 }}>
                    {menuCss?.color?.css?.backgroundColor}
                  </Typography>
                </div>
              </div>
              <div style={{ marginBottom: "16px" }}>
                <Typography className={classes.formLabel}>
                  Background Color
                </Typography>
                <div className={classes.colorPicker}>
                  <ColorPicker
                    hideTextfield
                    value={menuCss?.backgroundColor}
                    onChange={(value) => {
                      setMenuCss({ ...menuCss, backgroundColor: value });
                    }}
                    palette={colorPickerColors}
                  />
                  <Typography style={{ fontSize: 14, marginLeft: 10 }}>
                    {menuCss?.backgroundColor?.css?.backgroundColor}
                  </Typography>
                </div>
              </div>
              <div
                style={{
                  marginTop: "40px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CombinedButtons
                  loading={loading}
                  solidBtnAction={handleSubmit}
                  hideOutlineBtn={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        open={menuAnchor}
        onClose={() => setMenuAnchor(false)}
        anchor="right"
        PaperProps={{
          style: {
            borderTopLeftRadius: 16,
            borderBottomLeftRadius: 16,
            padding: 52,
            width: 570,
          },
        }}
      >
        <MenuDrawer
          setMenuAnchor={setMenuAnchor}
          menu={menu}
          selParentMenuItem={selParentMenuItem}
          editItemRecord={editItemRecord}
          setToastSucc={setToastSucc}
          setToastSuccMsg={setToastSuccMsg}
          setToastErr={setToastErr}
          menuCss={menuCss}
        />
      </Drawer>
      <Toast open={toastSucc} close={setToastSucc} message={toastSuccMsg} />
      <Toast
        open={toastDel}
        close={setToastDel}
        message="Item Deleted Successfully"
      />
      <Toast
        severity="error"
        open={toastErr}
        close={setToastErr}
        message="Something went wrong, please try again!"
      />
    </div>
  );
};

export default Menu;
