import { REFER_EARN_TRANSACTIONS_PER_PAGE } from "../../utils/utils";
import {
  FETCH_TRANSACTIONS,
  TRANSACTION_PROGRESS,
  SET_TRANSACTION_ERROR,
  SET_TRANSACTION_LOADER,
  CLEAR_TRANSACTION,
  CLEAR_COMPLETE_TRANSACTIONS,
  TRANSACTION_DATE_SELECTOR,
  TRANSACTION_DATE_RANGE,
  SET_TRANSACTIONS_PER_PAGE,
  SET_TRANSACTIONS_CURRENT_PAGE,
  SET_TRANSACTIONS_MAX_PAGE_SO_FAR,
  SET_TRANSACTIONS_INPUT_KEYWORD,
  SET_TRANSACTIONS_SEARCH_KEYWORD,
  SET_SUMMARY_FILTER,
} from "./referAndEarnTypes";
import axios from "axios";
import { getDefaultStore } from "../../utils/storeUtil";

export const fetchTransactions =
  (
    page = 0,
    aoe = null,
    boe = null,
    keyword = null,
    transactionsPerPage = REFER_EARN_TRANSACTIONS_PER_PAGE
  ) =>
  async (dispatch) => {
    console.log("aoe, boe", aoe, boe);

    dispatch({
      type: TRANSACTION_PROGRESS,
      payload: true,
    });
    let accessToken = localStorage.getItem("access_token");
    let store = getDefaultStore();
    return await axios
      .get(
        `${process.env.REACT_APP_API}/api/v1/business/store/${
          store.store_id
        }/referral-transactions?page=${page}&size=${transactionsPerPage}&sort=createdAt,desc${
          aoe && boe ? `&created-aoe=${aoe}&created-boe=${boe}` : ""
        }${keyword ? `&q=${keyword}` : ""}`,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: FETCH_TRANSACTIONS,
          payload: response.data.payload,
          transactionsPerPage: transactionsPerPage,
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_TRANSACTION_ERROR,
          payload: { error: error.message },
        });
      });
  };

export const setTransactionDateSelector = (data) => (dispatch) => {
  dispatch({
    type: TRANSACTION_DATE_SELECTOR,
    payload: data,
  });
};

export const setTransactionDateRange = (data) => (dispatch) => {
  dispatch({
    type: TRANSACTION_DATE_RANGE,
    payload: data,
  });
};

export const setTransactionsPerPage = (data) => (dispatch) => {
  dispatch({
    type: SET_TRANSACTIONS_PER_PAGE,
    payload: data,
  });
};

export const setTransactionsCurrentPage = (data) => (dispatch) => {
  dispatch({
    type: SET_TRANSACTIONS_CURRENT_PAGE,
    payload: data,
  });
};

export const setTransactionsMaxPageSoFar = (data) => (dispatch) => {
  dispatch({
    type: SET_TRANSACTIONS_MAX_PAGE_SO_FAR,
    payload: data,
  });
};

export const clearTransactions = () => (dispatch) => {
  dispatch({
    type: CLEAR_COMPLETE_TRANSACTIONS,
  });
};

export const clearTransaction = () => (dispatch) => {
  dispatch({
    type: CLEAR_TRANSACTION,
    payload: null,
  });
};

export const startOrderLoader = () => (dispatch) => {
  dispatch({
    type: SET_TRANSACTION_LOADER,
    payload: true,
  });
};

export const stopTransactionLoader = () => (dispatch) => {
  dispatch({
    type: SET_TRANSACTION_LOADER,
    payload: false,
  });
};

export const setTransactionsInputKeyword = (data) => (dispatch) => {
  dispatch({
    type: SET_TRANSACTIONS_INPUT_KEYWORD,
    payload: data,
  });
};

export const setTransactionsSearchKeyword = (data) => (dispatch) => {
  dispatch({
    type: SET_TRANSACTIONS_SEARCH_KEYWORD,
    payload: data,
  });
};

export const setReferAndEarnSummaryFilter = (data) => (dispatch) => {
  dispatch({
    type: SET_SUMMARY_FILTER,
    payload: data,
  });
};
