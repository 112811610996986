import { CircularProgress, Typography } from "@material-ui/core";
import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import OutlinedButton from "../../../components/buttons/OutlinedButton";
import ConfirmModal from "../../../components/common/ConfirmModal";
import FormModal from "../../../components/common/FormModal";
import {
  fetchCustomerAddresses,
  addCustomerAddress,
  deleteCustomerAddress,
  editCustomerAddress,
} from "../../../utils/customerUtils";
import AddressCard from "./AddressCard";
import AddressFormModal from "./AddressFormModal";

const ManageAddressesModal = ({ open, setOpen }) => {
  const params = useParams();
  const [openAddressFormModal, setOpenAddressFormModal] = useState(false);
  const [custAddresses, setCustAddresses] = useState([]);
  const [address, setAddress] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [loader, setLoader] = useState(true);
  const customerId = params?.customerid;

  useEffect(() => {
    fetchCustomerAddresses(params.customerid).then((rec) => {
      setCustAddresses(rec);
      setLoader(false);
    });
    // eslint-disable-next-line
  }, []);

  const onAddressSave = async (add) => {
    let payload = {};
    if (address) {
      payload = {
        ...address,
        customer_name: add.customer_name,
        mobile: add.mobile,
        street1: add.street1,
        pincode: add.pincode,
        city: add.city,
        state: add.state,
        country: add.country,
      };
    } else {
      payload = { ...add };
    }

    if (address) {
      await editCustomerAddress(payload, customerId).then((res) => {
        let modCustAddresses = custAddresses.map((addr) => {
          if (addr.id === res.id) {
            return res;
          }
          return addr;
        });
        setCustAddresses(modCustAddresses);
        setOpenAddressFormModal(false);
        return res;
      });
    } else {
      await addCustomerAddress(payload, customerId).then((res) => {
        setCustAddresses([res, ...custAddresses]);
        setOpenAddressFormModal(false);
        return res;
      });
    }
  };

  const onConfirm = () => {
    deleteCustomerAddress(address.id, customerId).then((res) => {
      let modCustAddresses = custAddresses.filter(
        (addr) => addr.id !== address.id
      );
      setCustAddresses(modCustAddresses);
      setIsDeleting(false);
    });
  };
  return (
    <FormModal
      width={600}
      heading="Manage Addresses"
      open={open}
      btnTxt="Add Address"
      hideCancel={true}
      onClose={setOpen}
      onSave={() => {
        setOpenAddressFormModal(true);
        setAddress(null);
      }}
      maxHeight={75}
      hideCombinedButton={true}
      bottomCenterAdornment={
        <OutlinedButton
          onClick={() => {
            setOpenAddressFormModal(true);
            setAddress(null);
          }}
          style={{ width: 150 }}
        >
          Add Address
        </OutlinedButton>
      }
    >
      <div>
        {loader && (
          <div
            style={{
              margin: "50px 0px",
              textAlign: "center",
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        )}
        {!loader && custAddresses.length === 0 ? (
          <div
            style={{
              margin: "50px 0px",
              textAlign: "center",
            }}
          >
            <Typography>Address is not found</Typography>
          </div>
        ) : (
          <Fragment>
            {custAddresses?.map((address, i) => {
              return (
                <div style={{ marginBottom: 16 }} key={i}>
                  <AddressCard
                    isEditDelete={true}
                    address={address}
                    onEdit={() => {
                      setAddress(address);
                      setOpenAddressFormModal(true);
                    }}
                    onDelete={() => {
                      setIsDeleting(true);
                      setAddress(address);
                    }}
                  />
                </div>
              );
            })}
          </Fragment>
        )}
        <AddressFormModal
          open={openAddressFormModal}
          setOpen={setOpenAddressFormModal}
          title={address ? "Edit Address" : "Add Address"}
          initialValue={address}
          onSave={onAddressSave}
        />
        <ConfirmModal
          open={isDeleting}
          message={
            <span>
              Are you sure want to delete <b>Address</b>?
            </span>
          }
          closeBtnText="Cancel"
          confirmBtnText="Delete"
          onClose={() => {
            setIsDeleting(false);
          }}
          onConfirm={onConfirm}
        />
      </div>
    </FormModal>
  );
};

export default ManageAddressesModal;
