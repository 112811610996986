import React, { useEffect, useState } from "react";
import ShoopyIcon from "../../../icons/ShoopyIconLatest";
import { Typography } from "@material-ui/core";
import useStyles from "./styles";
import MobileForm from "./MobileForm";
import OTPVerifyForm from "./OTPVerifyForm";
import HeartIcon from "../../../icons/HeartIcon";
import IndiaFlagIcon from "../../../icons/IndiaFlagIcon";
import EditIcon from "../../../icons/EditIcon";
import { isCustomDomain } from "../../../utils/utils";

const SignIn = ({
  onSuccess,
  newAcc,
  setNewAcc,
  otpSent,
  setOtpSent,
  onSubmit,
}) => {
  const classes = useStyles();
  const [record, setRecord] = useState({name: '', mobile: ''});
  const [formType, setFormType] = useState('');
  const customDomain = isCustomDomain();

  const onOtpSuccess = (mobile, name, isNew) => {
    setOtpSent(true);
    setNewAcc(isNew);
    setRecord({name, mobile});
    setFormType(isNew ? "SIGNUP" : "SIGNIN");
  };

  useEffect(() => {
    let isSignupShown = localStorage.getItem("is_signup_shown");
    if (isSignupShown) {
      setFormType("SIGNIN");
    } else {
      setFormType("SIGNUP");
    }
  }, []);

  const onChangeNum = () => {
    setOtpSent(false);
  };

  return (
    <>
      {!customDomain && (
        <div className={classes.icon}>
          <ShoopyIcon width={64} height={64} viewBox="0 0 32 32" />
        </div>
      )}
      <div className={classes.signin}>
        <div style={{ maxWidth: 400, width: "100%", padding: "0px 20px" }}>
          <div>
            <Typography variant="h2" className={classes.heading}>
              {formType === "SIGNUP" ? "Sign Up" : "Sign In"}
            </Typography>
            {otpSent && (
              <div className={classes.subContainer}>
                <Typography className={classes.subheading}>
                  OTP sent to <span>+91{record.mobile}</span>
                </Typography>
                <div onClick={onChangeNum}>
                  <EditIcon stroke="#1641DB" /> &nbsp;Change
                </div>
              </div>
            )}
          </div>
          <div>
            {otpSent ? (
              <OTPVerifyForm
                onSubmit={onSubmit}
                mobile={record.mobile}
                onSuccess={onSuccess}
                newAcc={newAcc}
              />
            ) : (
              <>
                <MobileForm
                  onOtpSuccess={onOtpSuccess}
                  formType={formType}
                  record={record}
                />
                <Typography
                  className={classes.subheading}
                  style={{ textAlign: "center" }}
                >
                  {formType === "SIGNUP"
                    ? "Already have an account?"
                    : "Don't have an account?"}{" "}
                  &nbsp;
                  <span
                    style={{ color: "#1641DB", cursor: "pointer" }}
                    onClick={() => {
                      let newType = formType === "SIGNUP" ? "SIGNIN" : "SIGNUP";
                      setFormType(newType);
                    }}
                  >
                    {formType === "SIGNUP" ? "Sign In" : "Sign Up"}
                  </span>
                </Typography>
              </>
            )}
          </div>
          <div className={classes.madeinindiactn}>
            <div className={classes.madeinindiamsg}>
              <Typography style={{ fontSize: 10, lineHeight: "12px" }}>
                Made with &nbsp;
              </Typography>
              <HeartIcon />
              <Typography style={{ fontSize: 10, lineHeight: "12px" }}>
                &nbsp; in INDIA &nbsp;
              </Typography>
              <IndiaFlagIcon />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SignIn;
