import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CloudUploadIcon = ({ viewBox = "0 0 66 45" }) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "66px", height: "45px" }}>
      <svg
        width="66"
        height="45"
        viewBox="0 0 66 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32.7273 0C27.3283 0.0169303 22.1133 1.96419 18.0245 5.49C14.8909 8.19 12.6205 11.7082 12.0355 15.2386C5.17909 16.7523 0 22.725 0 29.9373C0 38.3155 6.98727 45 15.4677 45H51.9014C59.3264 45 65.4545 39.15 65.4545 31.7986C65.4545 25.1059 60.3736 19.6527 53.8609 18.7323C52.8668 8.17773 43.7318 0 32.7273 0ZM42.3573 21.0518C42.5475 21.242 42.6983 21.4678 42.8012 21.7162C42.9042 21.9647 42.9571 22.231 42.9571 22.5C42.9571 22.769 42.9042 23.0353 42.8012 23.2838C42.6983 23.5322 42.5475 23.758 42.3573 23.9482C42.1671 24.1384 41.9413 24.2892 41.6928 24.3921C41.4444 24.4951 41.178 24.548 40.9091 24.548C40.6401 24.548 40.3738 24.4951 40.1253 24.3921C39.8769 24.2892 39.6511 24.1384 39.4609 23.9482L34.7727 19.2559V34.7727C34.7727 35.3152 34.5572 35.8355 34.1736 36.2191C33.79 36.6027 33.2698 36.8182 32.7273 36.8182C32.1848 36.8182 31.6645 36.6027 31.2809 36.2191C30.8973 35.8355 30.6818 35.3152 30.6818 34.7727V19.2559L25.9936 23.9482C25.6096 24.3323 25.0886 24.548 24.5455 24.548C24.0023 24.548 23.4814 24.3323 23.0973 23.9482C22.7132 23.5641 22.4974 23.0432 22.4974 22.5C22.4974 21.9568 22.7132 21.4359 23.0973 21.0518L31.2791 12.87C31.4691 12.6795 31.6948 12.5284 31.9433 12.4253C32.1918 12.3221 32.4582 12.2691 32.7273 12.2691C32.9963 12.2691 33.2627 12.3221 33.5112 12.4253C33.7597 12.5284 33.9855 12.6795 34.1755 12.87L42.3573 21.0518Z"
          fill="#37479E"
        />
      </svg>
    </SvgIcon>
  );
};

export default CloudUploadIcon;
