import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ProductNoPicIcon = ({
  viewBox = "0 0 24 24",
  width = 24,
  height = 24,
  stroke = "#999999",
}) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: width, height: height }}>
      <svg
        viewBox={viewBox}
        style={{ width: width, height: height }}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.5 23.5V0.5H23.5V23.5H0.5Z"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.5 16.5L8.5 14.5L13.5 18.5L18.5 13.5"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.5 10.5C12.6046 10.5 13.5 9.60457 13.5 8.5C13.5 7.39543 12.6046 6.5 11.5 6.5C10.3954 6.5 9.5 7.39543 9.5 8.5C9.5 9.60457 10.3954 10.5 11.5 10.5Z"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default ProductNoPicIcon;
