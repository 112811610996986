import React from "react";

export default function ProductsSettingsIcon({
  fill = "#666666",
  strokeWidth = 0,
}) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_22814_1843"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="28"
        height="28"
      >
        <rect width="28" height="28" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_22814_1843)">
        <path
          d="M6.55142 24.5C6.02933 24.5 5.58464 24.3164 5.21733 23.9493C4.85022 23.582 4.66667 23.1373 4.66667 22.6152V9.64075C4.33164 9.50153 4.05339 9.27675 3.83192 8.96642C3.61064 8.65608 3.5 8.29976 3.5 7.89746V5.38475C3.5 4.86267 3.68356 4.41797 4.05067 4.05067C4.41797 3.68356 4.86267 3.5 5.38475 3.5H22.6152C23.1373 3.5 23.582 3.68356 23.9493 4.05067C24.3164 4.41797 24.5 4.86267 24.5 5.38475V7.89746C24.5 8.29976 24.3894 8.65608 24.1681 8.96642C23.9466 9.27675 23.6684 9.50153 23.3333 9.64075V22.6152C23.3333 23.1373 23.1498 23.582 22.7827 23.9493C22.4154 24.3164 21.9707 24.5 21.4486 24.5H6.55142ZM5.83333 9.78192V22.5032C5.83333 22.7426 5.91938 22.9408 6.09146 23.0977C6.26335 23.2548 6.47646 23.3333 6.73079 23.3333H21.4486C21.658 23.3333 21.8301 23.2661 21.9648 23.1315C22.0994 22.9968 22.1667 22.8247 22.1667 22.6152V9.78192H5.83333ZM5.38475 8.61525H22.6152C22.8247 8.61525 22.9968 8.54797 23.1315 8.41342C23.2661 8.27886 23.3333 8.10688 23.3333 7.89746V5.38475C23.3333 5.17533 23.2661 5.00325 23.1315 4.8685C22.9968 4.73394 22.8247 4.66667 22.6152 4.66667H5.38475C5.17533 4.66667 5.00325 4.73394 4.8685 4.8685C4.73394 5.00325 4.66667 5.17533 4.66667 5.38475V7.89746C4.66667 8.10688 4.73394 8.27886 4.8685 8.41342C5.00325 8.54797 5.17533 8.61525 5.38475 8.61525ZM10.9486 15.0098H17.0514V14H10.9486V15.0098Z"
          fill={fill}
          stroke={fill}
          strokeWidth={strokeWidth}
        />
      </g>
    </svg>
  );
}
