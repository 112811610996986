import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  pageHead: {
    fontSize: "24px",
    lineHeight: "30px",
    fontWeight: 700,
    color: "#1A1A1A",
    margin: "0px",
  },
  inputdomainfield: {
    paddingRight: "5px",
  },
  planSection: {
    marginTop: "10px",
    display: "flex",
    // gap: "10px",modalColumn
    // margin: "0px 10px",
    padding: "0px 8px",
  },
  addbtn: {
    background: " #f50057 !important",
  },
  fairpolicy: {
    color: "blue !important",
    textDecoration: "underline",
  },
  dataloded: {
    background: "#FFFFFF",
    marginBottom: "10px",
    marginTop: "10px",
    borderRadius: "5px",
    padding: "20px",
  },
  subscriptionTitle: {
    fontSize: "24px",
    fontWeight: 700,
    paddingBottom: "20px",
  },
  selectstate: {
    minWidth: "30%",
  },
  customStyle: {
    backgroundColor: "blue ",
    color: "white ",
    // any other styles for blue button
  },
  compareallfeatures: {
    color: "#1641DB",
    border: " 1px solid #1641DB",
    fontweight: 600,
    fontSize: "14px",
    borderRadius: "17px",
    margin: "28px 1px 13px 0px",
    padding: "7px 12px",
  },
  planItem: {
    paddingLeft: theme.spacing(2), // Horizontal spacing
    paddingRight: theme.spacing(2), // Horizontal spacing
    "&:first-child": {
      paddingLeft: 0, // No padding for the first item
    },
    "&:last-child": {
      paddingRight: 0, // No padding for the last item
    },
  },
  allplansHeader: {
    // background: "#FFFFFF",
    borderRadius: "10px",
    padding: "15px 0px",
  },
  planCard: {
    // margin: "0px 9px",
    height: "100%",
    borderRadius: "0px 0px 8px 8px",
    position: "relative",
    outline: "1px solid #E1E1E1",
    padding: "20px",
    borderRadius: "5px",
  },
  disablewithoutdomain: {
    color: "#E1E1E1",
    textDecoration: "none !important",
    cursor: "default !important",
    "&:hover": {
      background: "none !important",
    },
  },
  planCardProfessional: {
    outline: "3px solid #1641DB",
  },
  planHeader: {
    padding: "12px",
    borderRadius: "8px 8px 0px 0px",
  },
  planTitle: {
    fontSize: "16px",

    fontWeight: 700,
    // color: "#fff",
    marginBottom: "4px",
    display: "flex",
    justifyContent: "space-between",
  },
  ruppesiocn: {
    fontWeight: 400,
    fontSize: " 20px",
  },
  planSubTitle: {
    color: "#666666",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: 400,
    // margin: "14px 0px",
    padding: "20px 0px",
    minHeight: "60px",
  },
  pricing: {
    margin: "16px 0px",
    // color: "#fff",
    fontSize: "12px",
    lineHeight: "16px",
  },
  pricingAmount: {
    fontSize: "24px",
    lineHeight: "20px",
    fontWeight: 700,
    // color: "#fff",
    // marginRight: "8px",
  },
  priceoblic: {
    fontWeight: 600,
    margin: "0px 3px",
  },
  pricingValidity: {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "16px",
    // color: "#fff",
  },
  planBody: {
    // padding: "0px 12px 12px 12px",
  },
  planShortDesc: {
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    marginTop: "14px",
    color: "#000",
  },
  bluetickField: {
    width: "20px",
    height: "20px",
    color: "#fff",
    backgroundColor: "blue",
    borderRadius: "50%",
  },
  keyhighlights: {
    fontWeight: 600,
    fontSize: "14px",
    margin: "14px 0px",
  },
  real_ruppesiocn: {
    fontWeight: 400,
    fontSize: "14px",
  },
  real_pricing: {
    marginTop: "8px",
    // color: "#fff",
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
  },
  real_pricingAmount: {
    fontWeight: 400,
    fontSize: "16px",
  },
  real_priceoblic: {
    fontWeight: 400,
    margin: "0px 3px",
    fontSize: "12px",
  },
  real_pricingValidity: {
    fontWeight: 400,
    fontSize: "12px",
  },
  real_discoutpercent: {
    color: "blue",
    fontWeight: 600,
    fontSize: "14px",
    marginLeft: "5px",
  },
  pricelightarrow: {
    color: "#999999",
    textDecoration: "line-through",
    display: "flex",
    alignItems: "center",
  },
  pricing: {
    display: "flex",
    alignItems: "center",
  },
  noHover: {
    "&:hover": {
      backgroundColor: "inherit",
    },
  },
  domainBtn: {
    width: "100%",
    textTransform: "none",
    backgroundColor: "#fff ",
    borderRadius: "6px",
    border: "1px solid #E1E1E1",
    fontSize: "16px",
    lineHeight: "24px",
    // fontWeight: "600",
    boxShadow: "none",
    color: "#1641db",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  professionalbtn: {
    border: "1px solid #1641db",
    width: "100%",
    textTransform: "none",
    backgroundColor: "#fff ",
    borderRadius: "6px",
    fontSize: "16px",
    lineHeight: "24px",
    // fontWeight: "600",
    boxShadow: "none",
    color: "#1641db",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  activeborder: {
    border: "1px solid #1641db",
  },
  inactiveborder: {
    border: "1px solid #E1E1E1",
  },
  expired: {
    marginRight: "10px",
  },
  disabledButton: {
    // border: "1px solid #999",
    fontWeight: "400",
    color: "blue",
  },
  highlightBorder: {
    border: "5px solid #9C75FF",
  },
  professionalPlan: {
    backgroundColor: "blue !important",
    color: "white ",
  },
  blueBackground: {
    backgroundColor: "blue",
    color: "white",
    "&:hover": {
      backgroundColor: "blue",
      color: "white",
    },
  },
  inactiveDomainBtn: {
    backgroundColor: "#E1E1E1 !important",
    color: "black !important",
    border: "none",
    fontWeight: "400",
    height: "42px",
  },
  renewalBox: {
    marginTop: "16px",
    backgroundColor: "rgba(255,68,68,0.15)",
    padding: "6px 8px",
    width: "100%",
    borderRadius: "8px",
    height: "48px",
  },
  renewalText: {
    fontSize: "12px",
    lineHeight: "18px",
    textAlign: "left",
  },
  addonpriceright: {
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: "400",
  },
  renewalAmount: {
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "20px",
  },
  featureTitle: {
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: 400,
  },
  featureDesc: {
    fontSize: "12px",
    lineHeight: "16px",
    color: "#999",
  },
  modalBody: {
    width: "66%", // Default width for small screens
    padding: 40,
    borderRadius: 16,
    // For screens larger than or equal to 1200px but less than 1470px
    "@media (min-width: 1200px) and (max-width: 1469px)": {
      width: "55%",
    },
    // For screens larger than or equal to 1470px
    "@media (min-width: 1470px)": {
      width: "50%",
    },
  },

  modalTitle: {
    fontSize: "24px",
    lineHeight: "28px",
    color: "#1A1A1A",
    fontWeight: 700,
    display: "inline",
  },
  modalColumn: {
    // maxHeight: "500px",
    height: "500px",
    overflow: "auto",
  },
  renewalat: {
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: 400,
  },
  cart_detail_head: {
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 700,
    marginBottom: "16px",
    textAlign: "left",
    wordBreak: "break-all",
  },
  cart_detail_container: {
    border: "1px solid #E1E1E1",
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "calc(100% - 36px)",
    overflow: "auto",
  },
  continueBtn: {
    marginTop: "24px",
    width: "100%",
    padding: "12px 0px",
    textAlign: "center",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 600,
    textTransform: "none",
    height: "40px",
  },
  renewalatruppesicon: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 400,
  },

  renewal_typo: {
    background: "#E9EEFF",
    padding: "8px",
    borderRadius: "5px",
    margin: "20px 0px",
  },
  renewal_domain: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 400,
  },
  renewalmaount: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 600,
  },
  leftColumn: {
    paddingTop: "36px",
    overflow: "hidden",
    paddingRight: "40px",
  },
  addressColumn: {
    paddingTop: "36px",
    overflow: "hidden",
  },
  domainSearchField: {
    width: "100%",
    margin: "0px",
  },
  orText: {
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.02em",
    color: "#BEBEBE",
  },
  secondaryAction: {
    textTransform: "none",
    textDecoration: "underline",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    padding: "0px",
  },
  helpText: {
    color: "#666",
    fontSize: "12px",
    lineHeight: "16px",
    marginTop: "10px",
  },
  domainCard: {
    border: "1px solid #E1E1E1",
    padding: "8px 12px",
    marginBottom: "16px",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  domainListHead: {
    marginBottom: "16px",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#1A1A1A",
    fontWeight: "600",
  },
  domainName: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "400",
    color: "#000",
    textAlign: "left",
  },
  peryear: {
    fontSize: "14px",
  },
  addonPrice: {
    fontSize: "16px",
    lineHeight: "16px",
    fontWeight: "600",
  },
  cartItem: {
    display: "flex",

    alignItems: "center",
    justifyContent: "center",
    borderBottom: "1px solid #E1E1E1",
    padding: "8px 12px",
  },
  addedItem: {
    color: "#1641DB",
    fontSize: "12px",
    lineHeight: "32px",
    width: "80px",
    textAlign: "center",
  },
  addonadd: {
    padding: "0px !important",
  },
  removeBtn: {
    color: "#FF6060",
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: "500",
    textTransform: "none",
    padding: "0px",
    cursor: "pointer",
  },
  totalContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // borderTop: "1px solid #E1E1E1",
    width: "100%",
    padding: "20px 0px",
    padding: "5px 0px",
    paddingBottom: "16px",
    backgroundColor: "#fff",
  },
  boxContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // borderTop: "1px solid #E1E1E1",
    width: "100%",
    fontSize: "14px",
    fontweight: 400,
    padding: "3px 0px",
    // paddingBottom: "16px",
    backgroundColor: "#fff",
  },
  totalText: {
    fontSize: "14px",
    fontWeight: "400",
  },

  totalAmount: {
    fontSize: "16px",
    fontWeight: "600",
  },
  addBtn: {
    width: "80px",
    height: "32px",
    color: "#1641DB",
    textTransform: "none",
    fontSize: "12px",
    lineHeight: "16px",
  },
  horizontalMargin: {
    marginLeft: "12px",
    marginRight: "12px",
    width: "30%",
    "&:first-child": {
      marginLeft: "0px",
    },
    "&:last-child": {
      marginRight: "0px",
    },
  },
  highlightCard: {
    border: "1px solid #1641DB",
  },
  mostPopular: {
    padding: "11px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 68, 68, 0.15)",
    fontSize: "12px",
    lineHeight: "16px",
    position: "absolute",
    top: "-20px",
    width: "calc(100% - 50px)",
    left: "25px",
    borderRadius: "8Px 8px 0px 0px",
    color: "#1A1A1A",
  },
  horizontalRule: {
    height: "1px",
    background: "#bebebe",
    width: "45%",
  },
  bilingsouterdiv: {
    backgroundColor: "#FFFFFF",
    color: "black",
  },
}));
