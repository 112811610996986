// Core react imports
import React, { useEffect, useState } from "react";
// Component imports
import { Paper, Typography, Grid } from "@material-ui/core";
import SubtitleSelect from "../../components/controls/SubtitleSelect";
import Controls from "../../components/controls/Controls";
import { ColorPicker, createColor } from "material-ui-color";
import ImageHandler from "../../components/ImageHandler";
import BannerImgContainer from "../banners/components/BannerImgContainer";
import OutlinedButton from "../../components/buttons/OutlinedButton";
import CombinedButtons from "../../components/buttons/CombinedButtons";
import { NoPicIcon } from "../../icons";
import SearchableSelect from "../coupons/Components/CouponForm/SearchableSelect";
import SelectedAreaHead from "../coupons/Components/CouponForm/SelectedAreaHead";
import SelectedItem from "../coupons/Components/CouponForm/SelectedItem";
// Routing imports
import { useHistory } from "react-router-dom";
// Style imports
import {
  formLabel,
  controlInput,
  textFieldLabel,
  labelWidth,
  colorPicker,
  sizeNote,
  formWrapperRight,
  formWrapperLeft,
} from "./style";
import { rowFlexAlignStart, rowFlexOnlyJustifyEnd } from "../../styles/common";
// Util imports
import {
  collectionsWithDescription,
  collectionsWithDesktopImage,
  collectionsWithDisplayOnly,
  collectionsWithGradient,
  collectionsWithHideTitle,
  collectionsWithImage,
  collectionsWithLayout,
  collectionsWithNoProduct,
  collectionsWithSeo,
  collectionsWithVideoUrl,
  collectionTypes,
  layoutDesktopList,
  layoutMobileList,
  maxNumOfProducts,
} from "./utils";
import {
  handleProductDataFetch,
  hydrateProductData,
} from "../coupons/Components/CouponForm/formUtils";
import DisplayBannerImg from "../banners/components/DisplayBannerImg";
import {
  createCollections,
  updateCollection,
} from "../../redux/collections/collectionActions";
import { useDispatch, useSelector } from "react-redux";
import { uploadImage } from "../../utils/imageUploader";
import { BASIC, PLANS } from "../plans/constants";
import PaidPopUp from "../../components/PaidPopUp";
import SeoForm from "../../components/common/SeoForm";
import { getStoreUrl } from "../../utils/storeUtil";

import Toast from "../../components/Layout/Toast";
import { colorPickerColors } from "../../utils/colorPickerColors";
import {
  convertToSlug,
  generateSlug,
} from "../../components/common/CommonUtils";

export default function Form({ mode, record }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const storeState = useSelector((state) => state.store);
  const storeUrl = getStoreUrl(storeState.store);

  const initState = {
    type: {
      value: record?.type ?? "BANNER_PRODUCT",
    },
    title: {
      value: record?.title ?? "",
    },
    description: {
      value: record?.description ?? "",
    },
    video_url: {
      value: record?.video_url ?? "",
    },
    auto_play: {
      value: record?.auto_play ?? false,
    },
    background_color: {
      value: record?.background_color
        ? createColor(record.background_color)
        : createColor("#FFFFFF"),
    },
    color: {
      value: record?.color ? createColor(record.color) : createColor("#000000"),
    },
    desktop_image: {
      value: record?.desktop_image ?? null,
    },
    mobile_image: {
      value: record?.mobile_image ?? null,
    },
    link: {
      value: record?.link ?? null,
    },
    mobile_image_name: { value: "" },
    desktop_image_name: { value: "" },
    max_number_of_products: {
      value: record?.max_number_of_products ?? 5,
    },
    cols_in_desktop: {
      value: record?.cols_in_desktop ?? 3,
    },
    cols_in_mobile: {
      value: record?.cols_in_mobile ?? 2,
    },
    active: { value: record?.active ?? true },
    overlay: { value: record?.overlay ?? true },
    show_title: { value: record?.show_title ?? true },
    display_only: { value: record?.display_only ?? false },
    super_prod_skus: {
      value: {},
      error: false,
    },
    slug: { value: record?.slug ?? "", error: false },
    seo_title: { value: record?.seo_title ?? "" },
    seo_description: { value: record?.seo_description ?? "" },
  };

  const [formState, setFormState] = useState(initState);
  const [showProductSelectionPopUp, setShowProductSelectionPopUp] =
    useState(false);
  const [planNeeded, setPlanNeeded] = useState(null);
  const [planSubTxt, setPlanSubTxt] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSlugFilled, setIsSlugFilled] = useState(
    initState.slug.value ? true : false
  );
  const [isSeoTitleFilled, setIsSeoTitleFilled] = useState(
    initState.seo_title.value ? true : false
  );
  const [isSeoDescFilled, setIsSeoDescFilled] = useState(
    initState.seo_description.value ? true : false
  );
  const [errorToast, setErrorToast] = useState({ value: false, msg: "" });
  const [openMobImageHandler, setOpenMobImageHandler] = useState(false);
  const [openMobImageHandlerIndex, setOpenMobImageHandlerIndex] = useState(0);
  const [openDeskImageHandler, setOpenDeskImageHandler] = useState(false);
  const [openDeskImageHandlerIndex, setOpenDeskImageHandlerIndex] = useState(0);
  const [openMobModal, setOpenMobModal] = useState(false);
  const [openDeskModal, setOpenDeskModal] = useState(false);

  useEffect(() => {
    if (record) {
      if (record?.criterion.super_prod_skus.length > 0) {
        let skus = record?.criterion.super_prod_skus.join(",");
        hydrateProductData(skus).then((res) => {
          const clonedState = { ...initState };
          clonedState.super_prod_skus.value = res;
          setFormState(clonedState);
        });
      }
    }
    // eslint-disable-next-line
  }, [record]);

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  const disabled =
    !formState?.title?.value ||
    (collectionsWithImage.indexOf(formState?.type?.value) !== -1 &&
      !formState?.mobile_image?.value &&
      formState?.type?.value !== "BANNER_PRODUCT") ||
    (collectionsWithDesktopImage.indexOf(formState?.type?.value) !== -1 &&
      !formState?.desktop_image?.value) ||
    (collectionsWithVideoUrl.indexOf(formState?.type?.value) !== -1 &&
      !formState?.video_url?.value);

  const onBlur = (e) => {
    const { name } = e.target;
    let cloneData = formState[name];
    if (cloneData.value.trim() === "") {
      cloneData.error = "This field is required.";
    }
    setFormState((prevState) => ({ ...prevState, [name]: cloneData }));
  };

  const onFocus = (event) => {
    const { name } = event.target;
    let cloneData = formState[name];
    cloneData.error = "";
    setFormState((prevState) => ({ ...prevState, [name]: cloneData }));
  };

  const onFormFieldChange = (fieldName, fieldValue) => {
    const clonedState = { ...formState };

    if (fieldName === "slug") {
      setIsSlugFilled(true);
      fieldValue = convertToSlug(fieldValue);
    }
    if (fieldName === "seo_title") {
      setIsSeoTitleFilled(true);
    }
    if (fieldName === "seo_description") {
      setIsSeoDescFilled(true);
    }

    if (fieldName === "title") {
      clonedState[fieldName].value = fieldValue;
      if (!isSlugFilled) {
        const autoSlug = generateSlug(fieldValue);
        clonedState["slug"].value = autoSlug;
      }
      if (!isSeoTitleFilled) {
        clonedState["seo_title"].value = fieldValue;
      }
    } else if (fieldName === "description" && !isSeoDescFilled) {
      clonedState[fieldName].value = fieldValue;
      clonedState["seo_description"].value = fieldValue;
    } else if (fieldName === "link" && fieldValue?.trim() !== "") {
      clonedState[fieldName].value = fieldValue;
      clonedState["super_prod_skus"].error = false;
    } else {
      clonedState[fieldName].value = fieldValue;
    }
    setFormState(clonedState);
  };

  const handleProductPopupClose = (selectedProducts = {}) => {
    if (Object.keys(selectedProducts).length > 0) {
      onFormFieldChange("super_prod_skus", selectedProducts);
      setFormState((prevState) => ({
        ...prevState,
        super_prod_skus: { ...prevState.super_prod_skus, error: false },
      }));
    } else {
      onFormFieldChange("super_prod_skus", {});
    }
    setShowProductSelectionPopUp(false);
  };

  const handleProductRemoval = (checked = false, updatedProduct) => {
    let clonedObj = {
      ...formState.super_prod_skus.value,
    };
    delete clonedObj[updatedProduct.id];
    if (Object.keys(clonedObj).length === 0) {
      onFormFieldChange("super_prod_skus", {});
    }
    onFormFieldChange("super_prod_skus", clonedObj);
  };

  const handleSave = async () => {
    let invalid = false;
    Object.entries(formState).forEach(([key, value]) => {
      if (key === "slug" && value.value.trim() === "") {
        setFormState((prevState) => ({
          ...prevState,
          [key]: {
            ...prevState[key],
            error: "This field is required.",
          },
        }));
        invalid = true;
        window.scrollTo({ top: 100, behavior: "smooth" });
      }
      if (key === "super_prod_skus") {
        if (
          !(Object.keys(formState?.super_prod_skus?.value).length > 0) &&
          ((collectionsWithDisplayOnly.indexOf(formState?.type?.value) !== -1 &&
            !formState?.display_only?.value) ||
            !(
              collectionsWithDisplayOnly.indexOf(formState?.type?.value) !== -1
            )) &&
          (!formState?.link?.value || formState?.link?.value?.trim() === "") &&
          !(collectionsWithNoProduct.indexOf(formState?.type?.value) !== -1)
        ) {
          setFormState((prevState) => ({
            ...prevState,
            [key]: { ...prevState[key], error: true },
          }));
          invalid = true;
        }
      }
    });

    if (invalid) {
      return;
    }

    setLoading(true);
    let mobImgUrl = null;
    let deskImgUrl = null;
    if (formState.mobile_image.value) {
      if (formState.mobile_image.value.startsWith("https:")) {
        mobImgUrl = formState.mobile_image.value;
      } else {
        let cloudFile = await uploadImage(
          formState.mobile_image_name.value,
          formState.mobile_image.value
        );
        mobImgUrl = cloudFile.payload.location;
      }
    }
    if (formState.desktop_image.value) {
      if (formState.desktop_image.value.startsWith("https:")) {
        deskImgUrl = formState.desktop_image.value;
      } else {
        let cloudFile = await uploadImage(
          formState.desktop_image_name.value,
          formState.desktop_image.value
        );
        deskImgUrl = cloudFile.payload.location;
      }
    }

    let payload = {
      type: formState.type.value,
      title: formState.title.value,
      color: formState.color.value.css.backgroundColor,
      background_color: formState.background_color.value.css.backgroundColor,
      criterion: {
        super_prod_skus: Object.keys(formState.super_prod_skus.value),
      },
      active: formState.active.value,
    };

    if (collectionsWithDescription.indexOf(formState?.type?.value) !== -1) {
      payload.description = formState.description.value;
    }
    if (collectionsWithImage.indexOf(formState?.type?.value) !== -1) {
      payload.mobile_image = mobImgUrl;
    }
    if (collectionsWithDesktopImage.indexOf(formState?.type?.value) !== -1) {
      payload.desktop_image = deskImgUrl;
    }
    if (collectionsWithLayout.indexOf(formState?.type?.value) !== -1) {
      payload.cols_in_mobile = formState.cols_in_mobile.value;
      payload.cols_in_desktop = formState.cols_in_desktop.value;
      payload.max_number_of_products = formState.max_number_of_products.value;
    }
    if (collectionsWithGradient.indexOf(formState?.type?.value) !== -1) {
      payload.overlay = formState.overlay.value;
    } else {
      payload.overlay = false;
    }
    if (collectionsWithHideTitle.indexOf(formState?.type?.value) !== -1) {
      payload.show_title = formState.show_title.value;
    }
    if (collectionsWithDisplayOnly.indexOf(formState?.type?.value) !== -1) {
      payload.link = formState.link.value;
      payload.display_only = formState.display_only.value;
    }
    if (collectionsWithVideoUrl.indexOf(formState?.type?.value) !== -1) {
      payload.video_url = formState.video_url.value;
      payload.auto_play = formState.auto_play.value;
    }

    if (collectionsWithSeo.indexOf(formState?.type?.value) !== -1) {
      payload.slug = formState.slug.value;
      payload.seo_title = formState.seo_title.value;
      payload.seo_description = formState.seo_description.value;
    }

    if (record) {
      payload = { ...record, ...payload };
      dispatch(updateCollection(record.id, payload)).then((res) => {
        if (res.error) {
          setErrorToast({ value: true, msg: res.errorMsg });
          setLoading(false);
        } else {
          setLoading(false);
          history.goBack();
        }
      });
    } else {
      dispatch(createCollections(payload)).then((res) => {
        if (res.error) {
          setErrorToast({ value: true, msg: res.errorMsg });
          setLoading(false);
        } else {
          setLoading(false);
          history.goBack();
        }
      });
    }
  };

  return (
    <>
      <div
        className={rowFlexAlignStart}
        style={{ position: "relative", width: "100%" }}
      >
        <Paper className={formWrapperLeft}>
          <SubtitleSelect
            optionList={collectionTypes}
            value={formState?.type?.value}
            onChange={(val, plan) => {
              if (
                plan &&
                PLANS[storeState.store.plan].weight < PLANS[plan].weight
              ) {
                setPlanSubTxt(
                  "Create Banner, Mini banner, Single row product list and Multi row product grid collections with the Basic plan"
                );
                setPlanNeeded(plan);
                return;
              }
              onFormFieldChange("type", val);
            }}
            label="Select Type *"
            labelClass={formLabel}
            wrapperStyle={{ marginBottom: "16px" }}
          />
          {collectionsWithImage.indexOf(formState?.type?.value) !== -1 && (
            <div style={{ marginBottom: "16px" }}>
              <Typography
                style={{
                  fontSize: "14px",
                  marginBottom: "12px",
                  lineHeight: "20px",
                  fontWeight: 600,
                }}
              >
                {collectionsWithVideoUrl.indexOf(formState?.type?.value) !== -1
                  ? "Thumbnail Image"
                  : "Banner Image"}
                {formState?.type?.value === "BANNER_PRODUCT" ? "" : " *"}
              </Typography>
              <div className={rowFlexAlignStart}>
                <div
                  style={{ width: "88px", height: "88px", marginRight: "20px" }}
                >
                  {formState?.mobile_image?.value && (
                    <DisplayBannerImg
                      height={88}
                      width={88}
                      src={formState?.mobile_image?.value}
                      removeCropImg={() => {
                        onFormFieldChange("mobile_image_name", "");
                        onFormFieldChange("mobile_image", null);
                      }}
                      removeImgFilename={() => {}}
                      onImageClick={() => {
                        setOpenMobImageHandler(true);
                        setOpenMobImageHandlerIndex(0);
                      }}
                    />
                  )}
                  <ImageHandler
                    cropImg={formState?.mobile_image?.value}
                    setCropImg={(val) => onFormFieldChange("mobile_image", val)}
                    defaultCrop={{
                      aspect: 1 / 1,
                      x: 0,
                      y: 0,
                      width: 250,
                      height: 250,
                    }}
                    setFilename={(val) =>
                      onFormFieldChange("mobile_image_name", val)
                    }
                    uploadContainer={
                      <BannerImgContainer
                        showSmall
                        height="88px"
                        width="88px"
                        text="Mobile"
                      />
                    }
                    maxSize={1920}
                    openImageHandler={openMobImageHandler}
                    setOpenImageHandler={setOpenMobImageHandler}
                    openImageHandlerIndex={openMobImageHandlerIndex}
                    openModal={openMobModal}
                    setOpenModal={setOpenMobModal}
                  />
                </div>
                {collectionsWithDesktopImage.indexOf(formState?.type?.value) !==
                  -1 && (
                  <div style={{ width: "267px", height: "88px" }}>
                    {formState?.desktop_image?.value && (
                      <DisplayBannerImg
                        height={88}
                        width={267}
                        src={formState?.desktop_image?.value}
                        removeCropImg={() => {
                          onFormFieldChange("desktop_image_name", "");
                          onFormFieldChange("desktop_image", null);
                        }}
                        removeImgFilename={() => {}}
                        onImageClick={() => {
                          setOpenDeskImageHandler(true);
                          setOpenDeskImageHandlerIndex(0);
                        }}
                      />
                    )}
                    <ImageHandler
                      cropImg={formState?.desktop_image?.value}
                      setCropImg={(val) =>
                        onFormFieldChange("desktop_image", val)
                      }
                      defaultCrop={{
                        aspect: 16 / 9,
                        x: 0,
                        y: 0,
                        width: 250,
                        height: (250 * 9) / 16,
                      }}
                      setFilename={(val) =>
                        onFormFieldChange("desktop_image_name", val)
                      }
                      uploadContainer={
                        <BannerImgContainer
                          showSmall
                          height="88px"
                          width="267px"
                          text="Desktop"
                        />
                      }
                      maxSize={1920}
                      openImageHandler={openDeskImageHandler}
                      setOpenImageHandler={setOpenDeskImageHandler}
                      openImageHandlerIndex={openDeskImageHandlerIndex}
                      openModal={openDeskModal}
                      setOpenModal={setOpenDeskModal}
                    />
                  </div>
                )}
              </div>
              {collectionsWithVideoUrl.indexOf(formState?.type?.value) !==
                -1 && (
                <Typography className={sizeNote}>
                  Recommend Size - 1280px X 720px (16:9)
                </Typography>
              )}
            </div>
          )}
          <Controls.Input
            placeholder="Eg-Diwali Sale"
            name="title"
            label="Title *"
            value={formState?.title?.value}
            color="secondary"
            onChange={(e) => {
              onFormFieldChange("title", e.target.value);
            }}
            onFocus={() => {}}
            onBlur={() => {}}
            controlClass={controlInput}
            error={""}
            labelClass={textFieldLabel}
            labelPlacement="top"
            extLabelWidth={labelWidth}
            fullWidth
          />
          {collectionsWithDescription.indexOf(formState?.type?.value) !==
            -1 && (
            <Controls.Input
              label="Description"
              placeholder="Eg-80% of on All Products"
              color="secondary"
              variant="outlined"
              multiline
              rows="2"
              value={formState?.description?.value}
              onChange={(e) => {
                onFormFieldChange("description", e.target.value);
              }}
              onFocus={() => {}}
              onBlur={() => {}}
              labelPlacement="top"
            />
          )}
          {collectionsWithVideoUrl.indexOf(formState?.type?.value) !== -1 && (
            <>
              <Controls.Input
                label="Video Url *"
                placeholder="Eg-youtube video url (https://youtu.be/abPWmLBZ3qc)"
                color="secondary"
                variant="outlined"
                value={formState?.video_url?.value}
                onChange={(e) => {
                  onFormFieldChange("video_url", e.target.value);
                }}
                onFocus={() => {}}
                onBlur={() => {}}
                labelPlacement="top"
              />
              <Controls.Checkbox
                style={{ margin: "-10px 0px 20px" }}
                name="Auto play"
                label={
                  <Typography style={{ fontSize: 14 }}>Auto play</Typography>
                }
                checkboxStyles={{ padding: 0, marginRight: 12 }}
                value={formState?.auto_play?.value}
                onChange={(e) => {
                  onFormFieldChange("auto_play", e.target.checked);
                }}
                color="secondary"
              />
            </>
          )}

          {collectionsWithLayout.indexOf(formState?.type?.value) !== -1 && (
            <>
              <SubtitleSelect
                optionList={layoutMobileList}
                value={formState?.cols_in_mobile?.value}
                onChange={(val) => {
                  onFormFieldChange("cols_in_mobile", val);
                }}
                label="Product Layout (Mobile) *"
                labelClass={formLabel}
                details="Preview products will be shown as per the count selected"
                wrapperStyle={{ marginBottom: "16px" }}
              />
              <SubtitleSelect
                optionList={layoutDesktopList}
                value={formState?.cols_in_desktop?.value}
                onChange={(val) => {
                  onFormFieldChange("cols_in_desktop", val);
                }}
                label="Product Layout (Desktop) *"
                labelClass={formLabel}
                details="Preview products will be shown as per the count selected"
                wrapperStyle={{ marginBottom: "16px" }}
              />
              <SubtitleSelect
                optionList={maxNumOfProducts}
                value={formState?.max_number_of_products?.value}
                onChange={(val) => {
                  onFormFieldChange("max_number_of_products", val);
                }}
                label="Maximum Preview Products *"
                labelClass={formLabel}
                details={`View all options will be shown if collection has more than ${formState?.max_number_of_products?.value} Products`}
                wrapperStyle={{ marginBottom: "16px" }}
              />
            </>
          )}
          <div style={{ marginBottom: "16px" }}>
            <Typography
              style={{
                fontSize: "14px",
                lineHeight: "20px",
                fontWeight: 600,
                marginBottom: "15px",
              }}
            >
              Text Color *
            </Typography>
            <div className={colorPicker}>
              <ColorPicker
                hideTextfield
                value={formState?.color?.value}
                onChange={(value) => {
                  onFormFieldChange("color", value);
                }}
                palette={colorPickerColors}
              />
              <Typography style={{ fontSize: 14, marginLeft: 10 }}>
                {formState?.color?.value?.css.backgroundColor}
              </Typography>
            </div>
            <Typography
              style={{
                fontSize: "12px",
                lineHeight: "16px",
                color: "#666",
                margin: "2px",
              }}
            >
              Choose text color for collection text
            </Typography>
          </div>
          <div style={{ marginBottom: "16px" }}>
            <Typography
              style={{
                fontSize: "14px",
                lineHeight: "20px",
                fontWeight: 600,
                marginBottom: "15px",
              }}
            >
              Background Color *
            </Typography>
            <div className={colorPicker}>
              <ColorPicker
                hideTextfield
                value={formState?.background_color?.value}
                onChange={(value) => {
                  onFormFieldChange("background_color", value);
                }}
                palette={colorPickerColors}
              />
              <Typography style={{ fontSize: 14, marginLeft: 10 }}>
                {formState?.background_color?.value?.css.backgroundColor}
              </Typography>
            </div>
            <Typography
              style={{
                fontSize: "12px",
                lineHeight: "16px",
                color: "#666",
                margin: "2px",
              }}
            >
              Background color for collection area
            </Typography>
          </div>
          {collectionsWithGradient.indexOf(formState?.type?.value) !== -1 && (
            <div style={{ marginBottom: "20px", marginTop: "6px" }}>
              <Controls.Switch
                label="Show Gradient Overlay"
                value={formState?.overlay?.value}
                onChange={() =>
                  onFormFieldChange("overlay", !formState?.overlay?.value)
                }
                helperText="Activate gradient on your banner"
              />
            </div>
          )}
          {collectionsWithHideTitle.indexOf(formState?.type?.value) !== -1 && (
            <div style={{ marginBottom: "20px", marginTop: "6px" }}>
              <Controls.Switch
                label="Show Title"
                value={formState?.show_title?.value}
                onChange={() =>
                  onFormFieldChange("show_title", !formState?.show_title?.value)
                }
                helperText="Show title inside banner"
              />
            </div>
          )}
          {collectionsWithDisplayOnly.indexOf(formState?.type?.value) !==
            -1 && (
            <>
              <div style={{ marginBottom: "20px", marginTop: "6px" }}>
                <Controls.Switch
                  label="Display Only"
                  value={formState?.display_only?.value}
                  onChange={() =>
                    onFormFieldChange(
                      "display_only",
                      !formState?.display_only?.value
                    )
                  }
                  helperText="Banner which is only for display won't be clickable"
                />
              </div>
              <div style={{ marginBottom: "20px", marginTop: "6px" }}>
                <Controls.Input
                  value={formState?.link?.value}
                  type="text"
                  name="Banner Link"
                  label="Banner Link"
                  placeholder="Link - Optional"
                  helperText="Provide Link if Banner should open the page other than collection page"
                  onChange={(e) => onFormFieldChange("link", e.target.value)}
                  color="secondary"
                  onFocus={() => {}}
                  onBlur={() => {}}
                  controlClass={controlInput}
                  error={""}
                  labelClass={textFieldLabel}
                  labelPlacement="top"
                  extLabelWidth={labelWidth}
                  fullWidth
                />
              </div>
            </>
          )}
          {(!(
            collectionsWithDisplayOnly.indexOf(formState?.type?.value) !== -1
          ) ||
            (collectionsWithDisplayOnly.indexOf(formState?.type?.value) !==
              -1 &&
              !formState?.display_only.value)) &&
            !(
              collectionsWithNoProduct.indexOf(formState?.type?.value) !== -1
            ) && (
              <>
                {Object.keys(formState?.super_prod_skus?.value || {}).length ===
                  0 && (
                  <>
                    <OutlinedButton
                      fullWidth
                      onClick={() => {
                        setShowProductSelectionPopUp(true);
                      }}
                    >
                      <span style={{ fontWeight: "400" }}>Add Products</span>
                    </OutlinedButton>
                    {formState?.super_prod_skus?.error && (
                      <p
                        style={{
                          color: "#f44336",
                          fontSize: 12,
                          marginTop: 6,
                        }}
                      >
                        Please add product
                      </p>
                    )}
                  </>
                )}
                {Object.keys(formState?.super_prod_skus?.value || {}).length >
                  0 && (
                  <Grid item xs={12}>
                    <SelectedAreaHead
                      title="Selected Products"
                      updateAction={setShowProductSelectionPopUp}
                    />
                    <div style={{ maxHeight: "220px", overflow: "auto" }}>
                      {Object.keys(formState.super_prod_skus.value).map(
                        (productKey) => {
                          let product =
                            formState.super_prod_skus.value[productKey];
                          return (
                            <SelectedItem
                              key={`${product.id}-selected-item`}
                              item={product}
                              onRemove={handleProductRemoval}
                            />
                          );
                        }
                      )}
                    </div>
                  </Grid>
                )}
              </>
            )}
          <div style={{ marginTop: "54px" }} className={rowFlexOnlyJustifyEnd}>
            <CombinedButtons
              disabled={disabled}
              solidBtnAction={handleSave}
              outlinedBtnAction={() => history.goBack()}
              loading={loading}
            />
          </div>
        </Paper>
        {showProductSelectionPopUp && (
          <SearchableSelect
            isOpen={showProductSelectionPopUp}
            heading="Select Products"
            subHeading="Select products for the collection"
            close={handleProductPopupClose}
            ImageFallback={NoPicIcon}
            fetchData={handleProductDataFetch}
            selected_items={formState?.super_prod_skus?.value}
            maxSelectedItem={40}
          />
        )}
        <PaidPopUp
          open={!!planNeeded}
          close={() => setPlanNeeded(null)}
          plan={planNeeded ? planNeeded : BASIC}
          subtxt={planSubTxt}
        />
        <Toast
          open={errorToast.value}
          close={() => setErrorToast({ value: false, msg: "" })}
          message={errorToast.msg}
          severity="error"
        />
        {collectionsWithSeo.indexOf(formState?.type?.value) !== -1 && (
          <Paper className={formWrapperRight}>
            <SeoForm
              openSeoSettingFileld={true}
              previewSubHeading="(Google search displays the collection page as follows.)"
              previewPageUrl={`${storeUrl}/collections/${
                formState.slug.value !== ""
                  ? formState.slug.value
                  : "collection-slug"
              }`}
              previewSeoTitle={
                formState.seo_title.value !== ""
                  ? formState.seo_title.value
                  : formState.title.value
                  ? formState.title.value
                  : "SEO Title"
              }
              previewSeoDescription={
                formState.seo_description.value
                  ? formState.seo_description.value
                  : `Buy ${formState.title.value} products from our online store.`
              }
              seoDescriptionPlaceholder={`Buy ${formState.title.value} products from our online store.`}
              formState={formState}
              handleChange={(e) => {
                onFormFieldChange(e.target.name, e.target.value);
              }}
              onBlur={onBlur}
              onFocus={onFocus}
            />
          </Paper>
        )}
      </div>
    </>
  );
}
