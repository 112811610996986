import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const DashboardIcon = ({ viewBox = '0 0 20 20', stroke = '#999999', fill = '#999999' }) => {
    return (
        <SvgIcon viewBox={viewBox} style={{ width: '20px', height: '20px' }}>
            <svg fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.125 0.625H0.625V10.625H8.125V0.625Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8.125 14.375H0.625V19.375H8.125V14.375Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M19.375 0.625H11.875V6.875H19.375V0.625Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M19.375 10.625H11.875V19.375H19.375V10.625Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </SvgIcon>
    );
};

export default DashboardIcon;
