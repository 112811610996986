import {
  Button,
  Typography,
  makeStyles,
  Grid,
  FormControl,
  MenuItem,
  TextField,
  Select,
  InputLabel,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Controls from "../../components/controls/Controls";
import LocationIcon from "../../icons/LocationIcon";
import states from "../../constants/states.json";
import {
  commonAddStoreCustomer,
  commonEditStoreCustomer,
} from "../../utils/orderUtil";
import FormModal from "../common/FormModal";
import { getCityAndState } from "../../utils/customerUtils";

const useStyles = makeStyles(() => ({
  heading: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "24px",
    color: "#1A1A1A",
  },
  subHeading: {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "20px",
    color: "#1A1A1A",
  },
  row: {
    display: "grid",
    gap: "42px",
    gridTemplateColumns: "1fr 1fr",
    paddingBottom: "16px",
    marginBottom: "12px",
    borderBottom: "1px solid #e5e5e5",
  },
  input: {
    padding: "12.5px 10px !important",
  },
  divider: {
    color: "#E5E5E5",
    marginTop: "14px",
    marginBottom: "16px",
    borderWidth: "1px",
  },
  menuPaper: {
    maxHeight: "350px",
  },
  addMenuPaper: {
    maxHeight: "115px",
    maxWidth: "496px",
  },
  menuText: {
    fontSize: "13px",
    color: "#1a1a1a",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: "495px",
  },
  select: {
    fontSize: "14px !important",
    padding: "11px 28px 11px 11px  !important",
  },
  checkBox: {
    marginLeft: "0px",
  },
  textBtn: {
    textTransform: "none",
    textDecoration: "underline",
    padding: "0px",
    fontWeight: 400,
    fontSize: "14px",
    "&:hover": {
      backgroundColor: "#fff",
      textDecoration: "underline",
    },
  },
  addBtn: {
    outline: "none",
    background: "#1641DB",
    color: "white",
    borderRadius: 6,
    height: 48,
    fontWeight: 600,
    lineHeight: "24px",
    fontSize: 16,
    padding: 16,
    textTransform: "none",
    width: 163,
    boxShadow: "none",
    "&:hover": {
      background: "#1641DB",
    },
  },
  cancelBtn: {
    border: "1px solid #1641DB",
    color: "#1641DB",
    background: "white",
    borderRadius: 6,
    fontWeight: 600,
    fontSize: 16,
    padding: 7,
    width: 149,
    marginRight: 24,
    textTransform: "none",
    height: 48,
    "&:hover": {
      background: "white",
    },
  },
  error: {
    marginTop: 10,
    marginBottom: 8,
    fontSize: "0.75rem",
    lineHeight: "1.66",
    color: "#f44336",
  },
}));

const initialValue = {
  name: false,
  mobile: false,
  gstin: false,
  shipping_mobile: false,
};
const AddCustomerModal = ({
  customerModal,
  dialogValue,
  addCustomer,
  closeModal,
  record = null,
}) => {
  const classes = useStyles();

  const [isSameShippingAddress, setIsSameShippingAddress] = useState(true);
  const [isExistingAdd, setIsExistingAdd] = useState(null);
  const [existingAddress, setExistingAddress] = useState("");
  const [isNewShippingAddress, setIsNewShippingAddress] = useState(null);
  const [errors, setErrors] = useState(initialValue);
  const [tapped, setTapped] = useState(initialValue);
  const [emptyShippingError, setEmptyShippingError] = useState(false);
  const [isBusinessCustomer, setIsBusinessCustomer] = useState(
    record?.gstin ? true : false
  );
  const [loading, setLoading] = useState(false);

  let savedBillingAddress = record?.billing_address ?? "";
  const initialValues = {
    name: dialogValue?.name ? dialogValue.name : record?.name ?? "",
    mobile: record?.mobile?.replaceAll(" ", "").slice(-10) ?? "",
    gstin: record?.gstin ?? "",
    company_name: record?.company_name ?? "",
    billing_street1: savedBillingAddress?.street1
      ? savedBillingAddress.street1 +
        (savedBillingAddress?.street2 ? ", " + savedBillingAddress.street2 : "")
      : "",
    billing_city: savedBillingAddress?.city ?? "",
    billing_state: savedBillingAddress?.state ?? "",
    billing_postalCode: savedBillingAddress?.pincode ?? "",
    shipping_name: "",
    shipping_mobile: "",
    shipping_street1: "",
    shipping_city: "",
    shipping_state: "",
    shipping_postalCode: "",
  };
  const [formData, setFormData] = useState(initialValues);
  const [shippingError, setShippingError] = useState(false);
  const [billingError, setBillingError] = useState(false);

  useEffect(() => {
    setFormData(initialValues);
    // eslint-disable-next-line
  }, [dialogValue, record]);

  useEffect(() => {
    if (record) {
      setIsSameShippingAddress(record.use_same_address);
      if (
        record.shipping_addresses &&
        record.shipping_addresses?.length !== 0
      ) {
        let add = record.shipping_addresses.filter(
          (rs) => rs.id === record?.shipping_address_id
        )[0];

        setExistingAddress(add ? add : "");
        setIsExistingAdd(true);
      }
    }
    // eslint-disable-next-line
  }, [record]);

  useEffect(() => {
    if (formData.billing_postalCode.length === 6) {
      getCityAndState(formData.billing_postalCode).then((res) => {
        if (res.data && res.data.state) {
          setFormData({
            ...formData,
            billing_city: res.data.city ? res.data.city : formData.billing_city,
            billing_state: res.data.state,
          });
        }
      });
    }
    // eslint-disable-next-line
  }, [formData.billing_postalCode]);

  useEffect(() => {
    if (formData.shipping_postalCode.length === 6) {
      getCityAndState(formData.shipping_postalCode).then((res) => {
        if (res.data && res.data.state) {
          setFormData({
            ...formData,
            shipping_city: res.data.city
              ? res.data.city
              : formData.shipping_city,
            shipping_state: res.data.state,
          });
        }
      });
    }
    // eslint-disable-next-line
  }, [formData.shipping_postalCode]);

  const validate = (e) => {
    switch (e.target.name) {
      case "name":
        if (e.target.value.trim() === "") {
          setErrors((errors) => ({
            ...errors,
            [e.target.name]: "Name cannot be empty",
          }));
        } else {
          setErrors((errors) => ({
            ...errors,
            [e.target.name]: false,
          }));
        }
        break;
      case "mobile":
        if (
          !/^([+]\d{2})?\d{10}$/gm.test(e.target.value.trim()) &&
          e.target.value.trim().length !== 0
        ) {
          setErrors((errors) => ({
            ...errors,
            [e.target.name]: "Invalid mobile number",
          }));
        } else {
          setErrors((errors) => ({
            ...errors,
            [e.target.name]: false,
          }));
        }
        break;
      case "shipping_mobile":
        if (
          !/^([+]\d{2})?\d{10}$/gm.test(e.target.value.trim()) &&
          e.target.value.trim().length !== 0
        ) {
          setErrors((errors) => ({
            ...errors,
            [e.target.name]: "Invalid mobile number",
          }));
        } else {
          setErrors((errors) => ({
            ...errors,
            [e.target.name]: false,
          }));
        }
        break;
      case "gstin":
        if (
          !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/g.test(
            e.target.value.trim()
          ) &&
          e.target.value.trim().length !== 0
        ) {
          setErrors((errors) => ({
            ...errors,
            [e.target.name]: "Invalid gst number",
          }));
        } else {
          setErrors((errors) => ({
            ...errors,
            [e.target.name]: false,
          }));
        }
        break;
      default:
    }
  };

  const validateAddress = (fields) => {
    const isAllFieldsEmpty = fields.every((field) => field === "");
    const isAllFieldsFilled = fields.every((field) => field !== "");
    return [isAllFieldsEmpty, isAllFieldsFilled];
  };

  const onBlur = (e) => {
    validate(e);
    setTapped((tapped) => ({ ...tapped, [e.target.name]: true }));
  };

  const handleFormDataChange = (e) => {
    setFormData((formData) => {
      return { ...formData, [e.target.name]: e.target.value };
    });
    validate(e);
  };

  const handleNewShippingAddress = () => {
    setIsNewShippingAddress(true);
    setIsExistingAdd(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.name.length === 0) {
      setErrors((errors) => ({
        ...errors,
        name: "Name cannot be empty",
      }));
      setTapped((tapped) => ({
        ...tapped,
        name: "Name cannot be empty",
      }));
      return;
    }
    const [billingEmpty, billingFilled] = validateAddress([
      formData.billing_street1,
      formData.billing_city,
      formData.billing_state,
      formData.billing_postalCode,
    ]);
    if (!billingEmpty && !billingFilled) {
      setBillingError(true);
    } else {
      setBillingError(false);
    }
    const [shippingEmpty, shippingFilled] = validateAddress([
      formData.shipping_name,
      formData.shipping_mobile,
      formData.shipping_street1,
      formData.shipping_city,
      formData.shipping_state,
      formData.shipping_postalCode,
    ]);

    if (!shippingEmpty && !shippingFilled) {
      setShippingError(true);
    } else {
      setShippingError(false);
    }
    if (record && record.shipping_address) {
      if (!isSameShippingAddress && shippingEmpty && existingAddress === "") {
        setEmptyShippingError(true);
        return;
      } else {
        setEmptyShippingError(false);
      }
    }
    if (
      Object.keys(errors).every((key) => !errors[key]) &&
      (shippingEmpty || shippingFilled) &&
      (billingEmpty || billingFilled)
    ) {
      let billingAddress = null;
      if (billingFilled) {
        billingAddress = {
          ...savedBillingAddress,
          customer_name: formData.name,
          mobile: formData.mobile,
          street1: formData.billing_street1,
          street2: "",
          city: formData.billing_city,
          pincode: formData.billing_postalCode,
          state: formData.billing_state,
          country: "India",
        };
      }

      let shippingAddress = null;
      if (isSameShippingAddress) {
        setIsSameShippingAddress(true);
      } else if (shippingFilled && !isExistingAdd) {
        shippingAddress = {
          customer_name: formData.shipping_name,
          mobile: formData.shipping_mobile,
          street1: formData.shipping_street1,
          street2: "",
          city: formData.shipping_city,
          pincode: formData.shipping_postalCode,
          state: formData.shipping_state,
          country: "India",
        };
      }

      let payload = {};
      if (record !== null) {
        payload = { ...record };
      }
      if (record && isSameShippingAddress) {
        payload.shipping_address_id = billingAddress?.id;
      }
      if (!isSameShippingAddress && shippingFilled && !isExistingAdd) {
        payload.shipping_address = shippingAddress;
        payload.shipping_address_id = 0;
      }

      if (!isSameShippingAddress && existingAddress && isExistingAdd) {
        payload.shipping_address = existingAddress;
        payload.shipping_address_id = existingAddress.id;
      }
      payload.name = formData.name;
      payload.mobile = formData.mobile;
      payload.gstin = isBusinessCustomer ? formData.gstin : "";
      payload.company_name = isBusinessCustomer ? formData.company_name : "";
      payload.billing_address = billingAddress;
      payload.use_same_address = isSameShippingAddress;
      setLoading(true);
      if (record) {
        commonEditStoreCustomer(payload).then((res) => {
          addCustomer(res);
          setIsNewShippingAddress(null);
          setIsExistingAdd(null);
          setExistingAddress("");
          setLoading(false);
        });
      } else {
        commonAddStoreCustomer(payload).then((res) => {
          addCustomer(res);
          setIsNewShippingAddress(null);
          setIsExistingAdd(null);
          setExistingAddress("");
          setLoading(false);
        });
      }
    }
  };
  return (
    <>
      <FormModal
        open={customerModal}
        onClose={closeModal}
        hideCancel
        heading="Bill To"
        onSave={handleSubmit}
        loading={loading}
        headingStyle={{ fontSize: "20px", lineHeight: "24px" }}
        width={600}
        bottomLeftAdornment={
          <Controls.Checkbox
            name="isShippingSameAsBilling"
            label="Same as Bill To"
            checkboxStyles={{ padding: 0, marginLeft: 11, marginRight: 12 }}
            value={isSameShippingAddress}
            color="secondary"
            onChange={(e) => {
              setIsSameShippingAddress(e.target.checked);
            }}
          />
        }
      >
        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={0}>
            <Grid item xs={6} style={{ paddingRight: 12 }}>
              <TextField
                name="name"
                label="Name"
                variant="outlined"
                fullWidth
                autoFocus
                value={formData.name}
                size="small"
                color="secondary"
                onChange={handleFormDataChange}
                onBlur={onBlur}
                error={tapped.name && errors.name}
                helperText={tapped.name && errors.name ? errors.name : ""}
                FormHelperTextProps={{
                  style: {
                    color: "red",
                    marginLeft: 2,
                    marginTop: 4,
                  },
                }}
                InputProps={{
                  classes: {
                    input: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: 12 }}>
              <TextField
                name="mobile"
                label="Mobile"
                type="number"
                value={formData.mobile}
                variant="outlined"
                size="small"
                disabled={record !== null}
                fullWidth
                color="secondary"
                onChange={(e) => {
                  if (e.target.value.length > 10) {
                    return;
                  }
                  handleFormDataChange(e);
                }}
                onBlur={onBlur}
                error={tapped.mobile && errors.mobile}
                helperText={tapped.mobile && errors.mobile ? errors.mobile : ""}
                FormHelperTextProps={{
                  style: {
                    color: "red",
                    marginLeft: 2,
                    marginTop: 4,
                  },
                }}
                InputProps={{
                  classes: {
                    input: classes.input,
                  },
                }}
              />
            </Grid>
          </Grid>
          <Controls.Checkbox
            style={{ marginTop: 12, marginBottom: 0 }}
            name="business_customer"
            label={
              <Typography style={{ fontSize: 14 }}>
                Customer has GST No. (Optional)
              </Typography>
            }
            checkboxStyles={{ padding: 0, marginLeft: 11, marginRight: 12 }}
            value={isBusinessCustomer}
            color="secondary"
            onChange={(e) => {
              setIsBusinessCustomer(e.target.checked);
            }}
          />
          {isBusinessCustomer && (
            <Grid container spacing={0}>
              <Grid item xs={6} style={{ marginTop: 12, paddingRight: 12 }}>
                <TextField
                  name="gstin"
                  label="GST Number"
                  variant="outlined"
                  value={formData.gstin}
                  onChange={handleFormDataChange}
                  fullWidth
                  size="small"
                  color="secondary"
                  onBlur={onBlur}
                  error={tapped.gstin && errors.gstin}
                  helperText={tapped.gstin && errors.gstin ? errors.gstin : ""}
                  FormHelperTextProps={{
                    style: {
                      color: "red",
                      marginLeft: 2,
                      marginTop: 4,
                    },
                  }}
                  InputProps={{
                    classes: {
                      input: classes.input,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6} style={{ marginTop: 12, paddingLeft: 12 }}>
                <TextField
                  name="company_name"
                  label="Company Name"
                  variant="outlined"
                  value={formData.company_name}
                  onChange={handleFormDataChange}
                  fullWidth
                  size="small"
                  color="secondary"
                  InputProps={{
                    classes: {
                      input: classes.input,
                    },
                  }}
                />
              </Grid>
            </Grid>
          )}
          <Grid container spacing={0}>
            <Grid item xs={12} style={{ marginBottom: 20, marginTop: 20 }}>
              <TextField
                name="billing_street1"
                label="Address"
                variant="outlined"
                value={formData.billing_street1}
                onChange={handleFormDataChange}
                fullWidth
                size="small"
                color="secondary"
                InputProps={{
                  classes: {
                    input: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={3} style={{ paddingRight: 12 }}>
              <TextField
                name="billing_postalCode"
                label="Pincode"
                variant="outlined"
                value={formData.billing_postalCode}
                onChange={handleFormDataChange}
                fullWidth
                size="small"
                color="secondary"
                InputProps={{
                  classes: {
                    input: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs style={{ paddingLeft: 12, paddingRight: 12 }}>
              <TextField
                name="billing_city"
                label="City"
                variant="outlined"
                value={formData.billing_city}
                onChange={handleFormDataChange}
                fullWidth
                size="small"
                color="secondary"
                InputProps={{
                  classes: {
                    input: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs style={{ paddingLeft: 12 }}>
              <FormControl
                fullWidth
                variant="outlined"
                color="secondary"
                size="small"
              >
                <InputLabel id="select-label" color="secondary">
                  State
                </InputLabel>
                <Select
                  name="billing_state"
                  labelId="select-label"
                  label="State"
                  color="secondary"
                  value={formData.billing_state}
                  onChange={handleFormDataChange}
                  MenuProps={{
                    // getContentAnchorEl: null,
                    classes: {
                      paper: classes.menuPaper,
                    },
                  }}
                  renderValue={(selected) => {
                    return selected;
                  }}
                  classes={{ select: classes.select }}
                >
                  {states.map((state) => (
                    <MenuItem key={state} value={state}>
                      <Typography className={classes.menuText}>
                        {state}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <hr className={classes.divider} />
          {billingError && (
            <div
              style={{
                marginTop: -10,
                marginBottom: 8,
                fontSize: "0.75rem",
                lineHeight: "1.66",
                color: "#f44336",
              }}
            >
              All address fields must either be empty or filled
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              marginBottom: isSameShippingAddress ? -22 : 0,
            }}
          >
            <Typography className={classes.heading}>Ship To</Typography>
            {record &&
              !isSameShippingAddress &&
              !isExistingAdd &&
              record.shipping_addresses &&
              record.shipping_addresses.length !== 0 && (
                <Button
                  variant="text"
                  color="secondary"
                  className={classes.textBtn}
                  onClick={() => {
                    setIsExistingAdd(true);
                    setIsNewShippingAddress(false);
                  }}
                >
                  Select From Existing Addresses
                </Button>
              )}
            {record &&
              !isSameShippingAddress &&
              !isNewShippingAddress &&
              isExistingAdd && (
                <Button
                  variant="text"
                  color="secondary"
                  className={classes.textBtn}
                  onClick={handleNewShippingAddress}
                >
                  Add Shipping Address
                </Button>
              )}
          </div>
          <Grid container spacing={0}>
            {!isSameShippingAddress && isExistingAdd && (
              <Grid item xs={12} style={{ marginTop: 20 }}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  size="small"
                >
                  <InputLabel id="select-address-label" color="secondary">
                    Select Address
                  </InputLabel>
                  <Select
                    labelId="select-address-label"
                    label="Select Address"
                    color="secondary"
                    onChange={(e) => setExistingAddress(e.target.value)}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      classes: {
                        paper: classes.addMenuPaper,
                      },
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    renderValue={(selected) => {
                      return (
                        <>
                          {selected.customer_name &&
                            `${selected.customer_name}, `}
                          {selected.mobile && `${selected.mobile}, `}
                          {selected.street1 && `${selected.street1}, `}
                          {selected.street2 && `${selected.street2}, `}
                          {selected.city && `${selected.city}, `}
                          {selected.state && `${selected.state} - `}
                          {selected.pincode && `${selected.pincode}`}
                        </>
                      );
                    }}
                    value={existingAddress}
                  >
                    {record &&
                      record.shipping_addresses.map((address) => {
                        return (
                          <MenuItem key={address.id} value={address}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                              }}
                            >
                              <LocationIcon />
                              <div
                                style={{
                                  marginLeft: 10,
                                }}
                              >
                                <Typography className={classes.menuText}>
                                  {address.customer_name &&
                                    `${address.customer_name}, `}
                                  {address.mobile && `${address.mobile}`}
                                </Typography>

                                <Typography
                                  className={classes.menuText}
                                  style={{ fontSize: 12, color: "#666666" }}
                                >
                                  {address.street1 && `${address.street1}, `}
                                  {address.street2 && `${address.street2}, `}
                                  {address.city && `${address.city}, `}
                                  {address.state && `${address.state} - `}
                                  {address.pincode && `${address.pincode}`}
                                </Typography>
                              </div>
                            </div>
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {!isSameShippingAddress && !isExistingAdd && (
              <>
                <Grid item xs={6} style={{ paddingRight: 12, marginTop: 20 }}>
                  <TextField
                    name="shipping_name"
                    label="Name"
                    variant="outlined"
                    fullWidth
                    value={formData.shipping_name}
                    size="small"
                    color="secondary"
                    onChange={handleFormDataChange}
                    InputProps={{
                      classes: {
                        input: classes.input,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 12, marginTop: 20 }}>
                  <TextField
                    name="shipping_mobile"
                    label="Mobile"
                    type="number"
                    value={formData.shipping_mobile}
                    variant="outlined"
                    size="small"
                    fullWidth
                    color="secondary"
                    onChange={(e) => {
                      if (e.target.value.length > 10) {
                        return;
                      }
                      handleFormDataChange(e);
                    }}
                    onBlur={onBlur}
                    error={tapped.shipping_mobile && errors.shipping_mobile}
                    helperText={
                      tapped.shipping_mobile && errors.shipping_mobile
                        ? errors.shipping_mobile
                        : ""
                    }
                    FormHelperTextProps={{
                      style: {
                        color: "red",
                        marginLeft: 2,
                        marginTop: 4,
                      },
                    }}
                    InputProps={{
                      classes: {
                        input: classes.input,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 20, marginTop: 20 }}>
                  <TextField
                    name="shipping_street1"
                    label="Street Address"
                    variant="outlined"
                    value={formData.shipping_street1}
                    onChange={handleFormDataChange}
                    fullWidth
                    size="small"
                    color="secondary"
                    InputProps={{
                      classes: {
                        input: classes.input,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={3} style={{ paddingRight: 12 }}>
                  <TextField
                    name="shipping_postalCode"
                    label="Pincode"
                    variant="outlined"
                    value={formData.shipping_postalCode}
                    onChange={handleFormDataChange}
                    fullWidth
                    size="small"
                    color="secondary"
                    InputProps={{
                      classes: {
                        input: classes.input,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs style={{ paddingLeft: 12, paddingRight: 12 }}>
                  <TextField
                    name="shipping_city"
                    label="City"
                    variant="outlined"
                    value={formData.shipping_city}
                    onChange={handleFormDataChange}
                    fullWidth
                    size="small"
                    color="secondary"
                    InputProps={{
                      classes: {
                        input: classes.input,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs style={{ paddingLeft: 12 }}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    size="small"
                  >
                    <InputLabel id="select-label-shipping" color="secondary">
                      State
                    </InputLabel>
                    <Select
                      name="shipping_state"
                      labelId="select-label-shipping"
                      label="State"
                      color="secondary"
                      value={formData.shipping_state}
                      onChange={handleFormDataChange}
                      MenuProps={{
                        classes: {
                          paper: classes.menuPaper,
                        },
                      }}
                      classes={{ select: classes.select }}
                      renderValue={(selected) => {
                        return selected;
                      }}
                    >
                      {states.map((state) => (
                        <MenuItem key={state} value={state}>
                          <Typography className={classes.menuText}>
                            {state}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
          </Grid>
          {shippingError && (
            <div className={classes.error}>
              All address fields must either be empty or filled
            </div>
          )}
          {emptyShippingError && !isSameShippingAddress && (
            <div className={classes.error}>Shipping Address is required</div>
          )}
        </form>
      </FormModal>
      <style jsx>{`
        .MuiMenu-paper {
          box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
            0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
        }
      `}</style>
    </>
  );
};

export default AddCustomerModal;
