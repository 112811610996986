import { Drawer, Grid, Modal, Typography } from "@material-ui/core";
import {
  CheckCircle,
  Done,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { COLORS, TEMPELATES } from "../../../utils/invoiceUtil";
import useStyles from "../styles";
import Controls from "../../../components/controls/Controls";
import { updateBillSettings } from "../../../redux/billSettings/billSettingsActions";
import { BackIcon, RemoveCircleIcon } from "../../../icons";
import ImageHandler from "../../../components/ImageHandler";
import {
  startInvoiceLoader,
  stopInvoiceLoader,
} from "../../../redux/invoices/invoiceActions";
import { uploadImage } from "../../../utils/imageUploader";
import { updateStore } from "../../../redux/store/storeActions";
import EditPen from "../../../icons/EditPen";
import { updateBillConfig } from "../../../redux/billConfig/billConfigActions";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import CloseButton from "../../../components/buttons/CloseButton";

const InvoiceSettings = ({
  openInvoiceSettings,
  setOpenInvoiceSettings,
  setToast,
  setMessage,
}) => {
  const classes = useStyles();
  const billSettings = useSelector((state) => state.billSettings.settings);
  const billConfig = useSelector((state) => state.billConfig.config);
  const store = useSelector((state) => state.store.store);
  const [settings, setSettings] = useState({});
  const [config, setConfig] = useState({});
  const [scrollIndex, setScrollIndex] = useState(0);
  const [preview, setPreview] = useState(false);
  const [cropImg, setCropImg] = useState(null);
  const [filename, setFilename] = useState("");
  const [editPrefix, setEditPrefix] = useState(false);
  const defaultCrop = { x: 0, y: 0, width: 250, height: 250 };
  const [prefixError, setPrefixError] = useState("");
  const [openImageHandler, setOpenImageHandler] = useState(false);
  const [openImageHandlerIndex, setOpenImageHandlerIndex] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setSettings(billSettings);
    if (billSettings.tos === null) {
      setSettings({
        ...billSettings,
        tos: `All goods remain the property of ${store.name} until full payment has been received`,
      });
    }
    if (billSettings.notes === null) {
      setSettings({
        ...billSettings,
        tos: billSettings.tos
          ? billSettings.tos
          : `All goods remain the property of ${store.name} until full payment has been received`,
        notes: "Thank you for your Business",
      });
    }
    // eslint-disable-next-line
  }, [billSettings]);

  useEffect(() => {
    setCropImg(store.signature_url);
  }, [store]);

  useEffect(() => {
    setConfig(billConfig);
  }, [billConfig]);

  function hexToRGBA(h) {
    let r = 0,
      g = 0,
      b = 0;

    r = "0x" + h[1] + h[2];
    g = "0x" + h[3] + h[4];
    b = "0x" + h[5] + h[6];

    return "rgba(" + +r + "," + +g + "," + +b + ",0.24)";
  }

  const onClose = () => {
    setOpenInvoiceSettings(false);
    setScrollIndex(0);
    setSettings(billSettings);
    setCropImg(store.signature_url);
  };

  const onSave = async () => {
    dispatch(startInvoiceLoader());
    setOpenInvoiceSettings(false);
    let imageUrl = null;
    if (cropImg) {
      if (cropImg.startsWith("https:")) {
        imageUrl = cropImg;
      } else {
        let cloudFile = await uploadImage(filename, cropImg);
        imageUrl = cloudFile.payload.location;
      }
    }
    setMessage("Invoice Settings Updated");
    dispatch(updateStore({ ...store, signature_url: imageUrl }));
    dispatch(updateBillSettings(settings)).then(() => setToast(true));
    dispatch(stopInvoiceLoader());
  };

  const onPrefixSave = () => {
    if (config.prefix.trim() === "") {
      setPrefixError("Enter prefix");
      return;
    }
    setMessage("Invoice Prefix Updated");
    dispatch(startInvoiceLoader());
    setEditPrefix(false);
    dispatch(updateBillConfig(config)).then(() => setToast(true));
    dispatch(stopInvoiceLoader());
  };

  if (editPrefix) {
    return (
      <Drawer
        open={editPrefix}
        onClose={() => {
          setEditPrefix(false);
          setConfig(billConfig);
        }}
        anchor="right"
        PaperProps={{
          style: {
            borderTopLeftRadius: 16,
            borderBottomLeftRadius: 16,
            padding: 52,
            width: 570,
          },
        }}
      >
        <Grid container style={{ position: "relative" }}>
          <Grid item xs={12} className={classes.flexBox}>
            <p
              style={{
                fontWeight: 600,
                fontSize: 14,
                marginTop: -10,
                cursor: "pointer",
              }}
              onClick={() => {
                setEditPrefix(false);
                setConfig(billConfig);
              }}
            >
              <BackIcon /> Back to Manage Settings
            </p>
          </Grid>
        </Grid>

        <Grid
          style={{
            display: "block",
            width: "100%",
            marginBottom: 30,
            marginTop: 38,
          }}
        >
          <p style={{ fontWeight: 700, fontSize: 24, color: "#1A1A1A" }}>
            Prefix
          </p>
        </Grid>
        <Grid className={classes.dividerGrid}>
          <Controls.Input
            name="prefix"
            label="Change Prefix"
            value={config.prefix}
            onChange={(e) => setConfig({ ...config, prefix: e.target.value })}
            onFocus={() => {
              setPrefixError("");
            }}
            onBlur={(e) => {
              if (e.target.value.trim() === "") setPrefixError("Enter prefix");
            }}
            error={prefixError}
            onEnter={onPrefixSave}
            autoFocus={true}
          />
        </Grid>
        <Grid className={classes.dividerGrid} style={{ padding: 0 }}>
          <div
            className={classes.dividerGrid}
            style={{ border: "none", width: "39%" }}
          >
            <Controls.Switch
              name="year_included"
              label="Include Year"
              color="secondary"
              value={config.year_included}
              onChange={() => {
                setConfig({
                  ...config,
                  year_included: !config.year_included,
                });
              }}
              width="100%"
            />
          </div>
        </Grid>
        <Grid className={classes.dividerGrid} style={{ padding: 0 }}>
          <div
            className={classes.dividerGrid}
            style={{ border: "none", width: "39%" }}
          >
            <Controls.Switch
              name="month_included"
              label="Include Month"
              color="secondary"
              value={config.month_included}
              onChange={() => {
                setConfig({
                  ...config,
                  month_included: !config.month_included,
                });
              }}
              width="100%"
            />
          </div>
        </Grid>
        <Grid className={classes.dividerGrid} style={{ padding: 0 }}>
          <div
            className={classes.dividerGrid}
            style={{
              border: "none",
              width: "39%",
              justifyContent: "flex-start",
            }}
          >
            <Controls.Switch
              name="day_included"
              label="Include Day"
              color="secondary"
              value={config.day_included}
              onChange={() => {
                setConfig({
                  ...config,
                  day_included: !config.day_included,
                });
              }}
              width="100%"
            />
          </div>
        </Grid>
        <Grid className={classes.dividerGrid} style={{ border: "none" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Typography
              className={classes.subHeading}
              style={{ padding: 0, width: "29%" }}
            >
              Preview
            </Typography>

            <Typography className={classes.subHeading} style={{ padding: 0 }}>
              {config.prefix}
              {config.year_included ? "YY" : ""}
              {config.month_included ? "MM" : ""}
              {config.day_included ? "DD" : ""}
            </Typography>
          </div>
        </Grid>
        <div style={{ height: "100%", position: "relative" }}>
          <div style={{ position: "absolute", bottom: 0, right: 0 }}>
            <CombinedButtons
              outlinedBtnAction={() => {
                setEditPrefix(false);
                setConfig(billConfig);
              }}
              solidBtnAction={onPrefixSave}
            />
          </div>
        </div>
      </Drawer>
    );
  }

  return (
    <Fragment>
      <Drawer
        open={openInvoiceSettings}
        onClose={onClose}
        anchor="right"
        PaperProps={{
          style: {
            borderTopLeftRadius: 16,
            borderBottomLeftRadius: 16,
            padding: 52,
            width: 570,
          },
        }}
      >
        <Grid container style={{ position: "relative" }}>
          <Grid item xs={12} className={classes.flexBox}>
            <p style={{ fontWeight: 700, fontSize: 24, marginTop: -10 }}>
              Manage Settings
            </p>
            <CloseButton
              onClick={onClose}
              style={{ marginTop: -10, outline: "none" }}
            />
          </Grid>
        </Grid>

        <Grid
          className={classes.dividerGrid}
          style={{ display: "block", width: "100%", paddingBottom: 6 }}
        >
          <p className={classes.subHeading}>Choose a Template</p>
          <Grid container>
            {TEMPELATES.map((temp, i) => (
              <Grid
                item
                xs={3}
                key={i}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  className={classes.tempelateHolder}
                  style={{
                    border:
                      settings.bill_template === temp.bill_template
                        ? "1px solid #1641DB"
                        : "1px solid #CCCCCC",
                  }}
                  onClick={() =>
                    setSettings({
                      ...settings,
                      bill_template: temp.bill_template,
                      page_size: temp.page_size,
                    })
                  }
                >
                  <div className={classes.innerTempHolder}>
                    <img
                      src={require(`../../../images/${temp.name}.png`)}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                      alt=""
                    />
                  </div>
                  <div style={{ position: "absolute", right: 1, top: 1 }}>
                    {settings.bill_template === temp.bill_template && (
                      <CheckCircle color="secondary" />
                    )}
                  </div>
                </div>
                <p
                  className={classes.subHeading}
                  style={{
                    fontSize: 12,
                    textAlign: "center",
                    marginTop: 16,
                    paddingBottom: 0,
                  }}
                >
                  {temp.name}
                </p>
                <p
                  className={classes.subHeading}
                  style={{
                    fontSize: 12,
                    color: "#1641DB",
                    textAlign: "center",
                    marginTop: -6,
                    cursor: "pointer",
                  }}
                  onClick={() => setPreview(temp.name)}
                >
                  Preview
                </p>
              </Grid>
            ))}
          </Grid>
        </Grid>

        {(settings.bill_template === "A4" ||
          settings.bill_template === "A5") && (
          <Grid className={classes.dividerGrid}>
            <div style={{ width: "110%", marginTop: -6, marginLeft: "-5%" }}>
              <p
                className={classes.subHeading}
                style={{ marginBottom: 20, marginLeft: "5%" }}
              >
                Set Invoice Colour
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    marginTop: -42,
                    cursor: scrollIndex > 0 ? "pointer" : "default",
                  }}
                  onClick={() => {
                    if (scrollIndex > 0) setScrollIndex(scrollIndex - 1);
                  }}
                >
                  {scrollIndex > 0 && <KeyboardArrowLeft color="secondary" />}
                  {scrollIndex <= 0 && (
                    <div style={{ height: 24, width: 24 }} />
                  )}
                </span>
                {COLORS.map((color, i) => {
                  if (i < scrollIndex || i > 7 + scrollIndex) {
                    return <Fragment key={i} />;
                  }

                  return (
                    <div
                      style={{
                        width: 66,
                        textAlign: "-webkit-center",
                        cursor: "pointer",
                      }}
                      key={i}
                      onClick={() =>
                        setSettings({ ...settings, color: color.code })
                      }
                    >
                      <div
                        style={{
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: 28,
                          width: 28,
                          background: color.code,
                          boxShadow: `0px 10px 20px ${hexToRGBA(color.code)}`,
                          border:
                            settings.color === color.code
                              ? "2px solid white"
                              : "",
                        }}
                      >
                        {settings.color === color.code && (
                          <Done fontSize="small" color="primary" />
                        )}
                      </div>
                      <p
                        style={{
                          marginTop: 12,
                          fontSize: 12,
                          color: "#666666",
                          fontWeight: settings.color === color.code ? 600 : 400,
                        }}
                      >
                        {color.name}
                      </p>
                    </div>
                  );
                })}
                <span
                  style={{
                    marginTop: -42,
                    cursor:
                      scrollIndex < COLORS.length - 8 ? "pointer" : "default",
                  }}
                  onClick={() => {
                    if (scrollIndex < COLORS.length - 8)
                      setScrollIndex(scrollIndex + 1);
                  }}
                >
                  {scrollIndex < COLORS.length - 8 && (
                    <KeyboardArrowRight color="secondary" />
                  )}
                  {scrollIndex >= COLORS.length - 8 && (
                    <div style={{ height: 24, width: 24 }} />
                  )}
                </span>
              </div>
            </div>
          </Grid>
        )}

        <Grid className={classes.dividerGrid}>
          <div
            style={{
              width: "50%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography className={classes.subHeading} style={{ padding: 0 }}>
              Prefix
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Typography
                className={classes.subHeading}
                style={{ padding: 0, color: "#1641DB" }}
              >
                {billConfig.prefix}
                {billConfig.year_included ? "YY" : ""}
                {billConfig.month_included ? "MM" : ""}
                {billConfig.day_included ? "DD" : ""}
              </Typography>
              <span
                style={{ marginLeft: 10, cursor: "pointer" }}
                onClick={() => setEditPrefix(true)}
              >
                <EditPen />
              </span>
            </div>
          </div>
        </Grid>

        <Grid className={classes.dividerGrid} style={{ padding: 0 }}>
          <div
            className={classes.dividerGrid}
            style={{ border: "none", width: "30%" }}
          >
            <Controls.Switch
              name="discount"
              label="Discount"
              color="secondary"
              value={settings.enable_discount}
              onChange={() => {
                setSettings({
                  ...settings,
                  enable_discount: !settings.enable_discount,
                });
              }}
              width="100%"
            />
          </div>
          <div
            className={classes.dividerGrid}
            style={{ border: "none", width: "45%" }}
          >
            <Controls.Switch
              name="shipping_charge"
              label="Shipping Charge"
              color="secondary"
              value={settings.enable_shipping_charge}
              onChange={() => {
                setSettings({
                  ...settings,
                  enable_shipping_charge: !settings.enable_shipping_charge,
                });
              }}
              width="100%"
            />
          </div>
        </Grid>
        <Grid className={classes.dividerGrid} style={{ padding: 0 }}>
          <div
            className={classes.dividerGrid}
            style={{ border: "none", width: "30%" }}
          >
            <Controls.Switch
              name="round_off"
              label="Round Off"
              color="secondary"
              value={settings.enable_round_off}
              onChange={() => {
                setSettings({
                  ...settings,
                  enable_round_off: !settings.enable_round_off,
                });
              }}
              width="100%"
            />
          </div>
          <div
            className={classes.dividerGrid}
            style={{ border: "none", width: "45%" }}
          >
            <Controls.Switch
              name="packaging_charge"
              label="Packaging Charge"
              color="secondary"
              value={settings.enable_packaging_charge}
              onChange={() => {
                setSettings({
                  ...settings,
                  enable_packaging_charge: !settings.enable_packaging_charge,
                });
              }}
              width="100%"
            />
          </div>
        </Grid>
        <Grid
          className={classes.dividerGrid}
          style={{
            display: "block",
          }}
        >
          <Controls.Input
            label="Notes"
            multiline
            rows="3"
            labelPlacement="top"
            value={settings.notes}
            onChange={(e) =>
              setSettings({ ...settings, notes: e.target.value })
            }
          />
        </Grid>
        <Grid
          className={classes.dividerGrid}
          style={{
            display: "block",
          }}
        >
          <Controls.Input
            label="Terms of Services"
            multiline
            rows="3"
            labelPlacement="top"
            value={settings.tos}
            onChange={(e) => setSettings({ ...settings, tos: e.target.value })}
          />
        </Grid>

        <Grid className={classes.dividerGrid}>
          <Typography className={classes.subHeading}>Signature</Typography>
          {cropImg && (
            <div
              style={{
                height: 80,
                width: "70%",
                border: "1px solid #E6E6E6",
                borderRadius: 6,
                position: "relative",
              }}
            >
              <img
                src={cropImg}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpenImageHandler(true);
                  setOpenImageHandlerIndex(0);
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: -8,
                  right: -8,
                  cursor: "pointer",
                }}
                onClick={() => setCropImg(null)}
              >
                <RemoveCircleIcon />
              </div>
            </div>
          )}
          <ImageHandler
            cropImg={cropImg}
            setCropImg={setCropImg}
            defaultCrop={defaultCrop}
            setFilename={setFilename}
            maxSize={600}
            openImageHandler={openImageHandler}
            setOpenImageHandler={setOpenImageHandler}
            openImageHandlerIndex={openImageHandlerIndex}
            openModal={openModal}
            setOpenModal={setOpenModal}
          />
        </Grid>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 32,
          }}
        >
          <CombinedButtons
            outlinedBtnAction={onClose}
            solidBtnAction={onSave}
          />
        </div>
      </Drawer>
      {preview && (
        <Modal
          open={Boolean(preview)}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          disableAutoFocus
          disableEnforceFocus
          onClose={() => setPreview(false)}
        >
          <img
            src={require(`../../../images/${preview}.png`)}
            style={{ height: "85%" }}
            alt=""
          />
        </Modal>
      )}
    </Fragment>
  );
};

export default InvoiceSettings;
