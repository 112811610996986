import React from "react";

const ExportDataIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5 11.5V14.5C15.5 15.052 15.052 15.5 14.5 15.5H11.5"
        stroke="#1641DB"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 15.5H1.5C0.948 15.5 0.5 15.052 0.5 14.5V11.5"
        stroke="#1641DB"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.5 4.5V1.5C0.5 0.948 0.948 0.5 1.5 0.5H4.5"
        stroke="#1641DB"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 0.5H14.5C15.052 0.5 15.5 0.948 15.5 1.5V4.5"
        stroke="#1641DB"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 8.5L8 13.5L12.5 8.5H9.5V2.5H6.5V8.5H3.5Z"
        stroke="#1641DB"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ExportDataIcon;
