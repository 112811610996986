import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles(() => ({
  label: {
    alignItems: "flex-start",
    justifyContent: "flex-end",
    margin: "0 0 28px 0",
    width: "100%",
  },
  labelWidth: {
    width: "50%",
  },
  controlInput: {
    width: "100% !important",
    margin: "12px 0px 0px 0px !important",
  },
  breadcrumbsContainer: {
    display: "flex",
    flexFlow: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  formHead: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
    color: "#1A1A1A",
    marginBottom: "30px",
  },

  formSubHead: {
    fontSize: 14,
    fontWeight: 600,
    margin: "10px 0",
  },

  promotionTeaser: {
    padding: "7px",
    fontSize: "12px",
    lineHeight: "16px",
    whiteSpace: "noWrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#666",
    border: "1px solid #999",
    height: "32px",
    backgroundColor: "#fff",
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
    display: "flex",
    flexBasis: "90%",
  },
  helpText: {
    color: "#666666",
    fontSize: "12px",
    lineHeight: "16px",
    marginTop: "5px",
  },
  copyLabel: {
    color: "#1A1A1A",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "20px",
    marginTop: "12px",
  },
  infoText: {
    fontSize: "12px",
    lineHeight: "16px",
  },
  backDiv: {
    marginBottom: "24px",
  },
  paper: {
    padding: "28px",
    minHeight: "84vh",
    minWidth: 370,
    margin: "auto",
    borderRadius: 6,
    boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.04)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  paperCard: {
    padding: "28px",
    minWidth: 370,
    margin: "auto",
    borderRadius: 6,
    boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.04)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  paperContent: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  imgClass: {
    width: "18px",
    height: "18px",
    marginRight: "12px",
  },
  menuPaper: {
    maxHeight: "400px",
  },
  button: {
    marginTop: "10px",
    display: "flex",
    justifyContent: "flex-end",
  },

  headingButtonSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  copyButton: {
    height: "32px",
    borderRadius: "6px",
    textTransform: "none",
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: "600",
    marginLeft: "-4px",
  },
  detailsContainer: {
    backgroundColor: "#FFF2E2",
    padding: 16,
    borderRadius: 8,
  },
  detailField: {
    marginTop: 16,
    display: "flex",
    flexDirection: "row",
  },
  loading: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
export default useStyles;
