import { CLEAR_COMPLETE } from "../commom/commonTypes";
import { TRIGGER_LOGIN } from "../customer/customerTypes";
import {
  FETCH_USER_STORES,
  SET_DEFAULT_STORE,
  STORE_USER_PROGRESS,
} from "./userStoreTypes";

const initialState = {
  stores: [],
  progress: false,
  loaded: false,
  store: {},
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_STORES:
      return {
        ...state,
        stores: action.payload,
        store:
          action.payload && action.payload.length > 0 ? action.payload[0] : {},
        progress: false,
        loaded: true,
      };
    case SET_DEFAULT_STORE:
      return {
        ...state,
        store: action.payload,
      };
    case STORE_USER_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      };
    case TRIGGER_LOGIN:
      return {
        ...state,
        loaded: false,
      };
    case CLEAR_COMPLETE:
      return {
        ...state,
        stores: [],
        progress: false,
        loaded: false,
        store: {},
        error: "",
      };
    default:
      return state;
  }
};
export default reducer;
