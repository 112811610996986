import { Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import ConfirmModal from "../../../../components/common/ConfirmModal";
import { EditIcon } from "../../../../icons";
import CancelOutlinedIcon from "../../../../icons/CancelOutlinedIcon";
import {
  deleteMenuItem,
  deleteSubMenuItem,
} from "../../../../redux/menu/menuActions";
import {
  rowFlexAlignCenter,
  rowFlexAlignStart,
  rowFlexJustifyBetween,
} from "../../../../styles/common";
import useStyles from "./styles";

const MenuItemCard = ({
  menu,
  menuItem,
  handleProps,
  subMenuItems,
  setMenuAnchor,
  setSelParentMenuItem,
  setEditItemRecord,
  setToastDel,
  setToastErr,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [menuSubMenuItems, setMenuSubMenuItems] = useState([]);
  const [deletingItem, setDeletingItem] = useState(null);
  const [delSubMenuItem, setDelSubMenuItem] = useState(false);

  useEffect(() => {
    setMenuSubMenuItems(subMenuItems);
    // eslint-disable-next-line
  }, [subMenuItems]);

  const getItemStyle = (draggableStyle, index) => ({
    userSelect: "none",
    padding: 0,
    ...draggableStyle,
  });

  const deleteItemConfirmPopUp = (item) => (event) => {
    event.stopPropagation();
    setDeletingItem(item);
  };

  const deleteItemConfirm = () => {
    if (delSubMenuItem) {
      dispatch(deleteSubMenuItem(menu.id, menuItem.id, deletingItem.id)).then(
        (res) => {
          if (res) {
            setToastDel(true);
          } else {
            setToastErr(true);
          }
        }
      );
    } else {
      dispatch(deleteMenuItem(menu.id, deletingItem.id)).then((res) => {
        if (res) {
          setToastDel(true);
        } else {
          setToastErr(true);
        }
      });
    }
    setDeletingItem(null);
  };

  return (
    <>
      <div className={rowFlexAlignStart} style={{ marginBottom: 12 }}>
        <span
          style={{
            fontSize: "24px",
            marginRight: "16px",
            cursor: "grab",
            color: "#999999",
          }}
          {...handleProps}
        >
          ≡
        </span>
        <div
          style={{
            padding: 8,
            border: "1px solid #E1E1E1",
            borderRadius: 6,
            width: "100%",
          }}
        >
          <div className={rowFlexJustifyBetween}>
            <Typography style={{ fontSize: 14 }}>{menuItem.name}</Typography>
            <div>
              <span
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSelParentMenuItem(null);
                  setMenuAnchor(true);
                  setEditItemRecord({
                    record: menuItem,
                    parentMenuItem: null,
                  });
                }}
              >
                <EditIcon size={15} />
              </span>

              {!subMenuItems && (
                <span
                  style={{
                    cursor: "pointer",
                    marginLeft: 16,
                  }}
                  onClick={(event) => {
                    deleteItemConfirmPopUp(menuItem)(event);
                    setDelSubMenuItem(false);
                  }}
                >
                  <CancelOutlinedIcon />
                </span>
              )}
            </div>
          </div>
          {!subMenuItems && (
            <>
              <hr className={classes.divider} />
              <span
                className={classes.clickableHeading}
                onClick={() => {
                  setMenuAnchor(true);
                  setSelParentMenuItem(menuItem);
                  setEditItemRecord({
                    record: null,
                    parentMenuItem: null,
                  });
                }}
              >
                + Sub menu item
              </span>
            </>
          )}
        </div>
      </div>
      {subMenuItems && (
        <div style={{ width: "100%" }}>
          <Droppable
            droppableId={`${menuItem.name}-${menuItem.id}`}
            type={`${menuItem.id}`}
            style={{ margin: 0 }}
          >
            {(provided, snapshot) => {
              return (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    padding: 8,
                    border: "1px solid #E1E1E1",
                    borderRadius: 6,
                    marginLeft: 50,
                  }}
                >
                  {menuSubMenuItems?.length !== 0 &&
                    menuSubMenuItems?.map((item, i, { length }) => {
                      return (
                        <Draggable
                          key={`${item.id}${i}`}
                          draggableId={`submenuitem-${item.id}${i}`}
                          index={i}
                        >
                          {(provided, snapshot) => (
                            <>
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={getItemStyle(
                                  provided.draggableProps.style,
                                  i
                                )}
                              >
                                <div className={rowFlexAlignCenter}>
                                  <div
                                    {...provided.dragHandleProps}
                                    style={{
                                      fontSize: "20px",
                                      marginRight: "16px",
                                      cursor: "grab",
                                      color: "#999999",
                                    }}
                                  >
                                    ≡
                                  </div>
                                  <div
                                    className={rowFlexJustifyBetween}
                                    style={{
                                      width: "100%",
                                    }}
                                  >
                                    <Typography style={{ fontSize: 13 }}>
                                      {item.name}
                                    </Typography>
                                    <div>
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          marginRight: 16,
                                        }}
                                        onClick={() => {
                                          setSelParentMenuItem(null);
                                          setMenuAnchor(true);
                                          setEditItemRecord({
                                            record: item,
                                            parentMenuItem: menuItem,
                                          });
                                        }}
                                      >
                                        <EditIcon size={15} />
                                      </span>
                                      <span
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={(event) => {
                                          deleteItemConfirmPopUp(item)(event);
                                          setDelSubMenuItem(true);
                                        }}
                                      >
                                        <CancelOutlinedIcon />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {i + 1 !== length && (
                                <hr className={classes.divider} />
                              )}
                            </>
                          )}
                        </Draggable>
                      );
                    })}
                  <hr className={classes.divider} />
                  <span
                    className={classes.clickableHeading}
                    onClick={() => {
                      setMenuAnchor(true);
                      setSelParentMenuItem(menuItem);
                      setEditItemRecord({
                        record: null,
                        parentMenuItem: null,
                      });
                    }}
                  >
                    + Sub menu item
                  </span>
                </div>
              );
            }}
          </Droppable>
        </div>
      )}
      <ConfirmModal
        open={deletingItem ? true : false}
        message={
          <span>
            Are you sure you want to delete <b>{deletingItem?.name}</b> menu
            item ?
          </span>
        }
        closeBtnText="Cancel"
        confirmBtnText="Delete"
        onClose={() => {
          setDeletingItem(null);
        }}
        onConfirm={() => {
          deleteItemConfirm();
        }}
      />
    </>
  );
};
export default MenuItemCard;
