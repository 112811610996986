import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const RoundAddIcon = ({
  viewBox = "0 0 18 18",
  stroke = "#1641DB",
  fill = "#ffffff",
}) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "18px", height: "18px" }}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="9" cy="9" r="8" fill={fill} stroke={stroke} />
        <path
          d="M5 9H13"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.00195 5V13"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default RoundAddIcon;
