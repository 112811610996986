import { style } from "typestyle";

export const cardContainer = style({
  border: "1px solid #E5E5E5",
  borderRadius: "8px",
  background: "#fff",
  width: "100%",
});

export const cardBody = style({
  padding: "16px",
  cursor: "pointer",
});

export const cardControls = style({
  padding: "16px",
  borderTop: "1px solid #E5E5E5",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

export const cardImage = style({
  flexBasis: "25%",
  height: "80px",
  borderRadius: "4px",
  objectFit: "cover",
  marginRight: "16px",
  maxWidth: "80px",
});

export const cardTitle = style({
  fontSize: "14px",
  lineHeight: "24px",
  fontWeight: "600",
  color: "#1A1A1A",
  margin: "0px",
});

export const cardDescription = style({
  fontSize: "12px",
  lineHeight: "16px",
  color: "#666",
  margin: "0px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  "-webkit-box-orient": "vertical",
  "-webkit-line-clamp": 2,
});

export const numProducts = style({
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: "16px",
  margin: "0px",
  color: "#1A1A1A",
  display: "flex",
  alignItems: "center",
});

export const switchLabel = style({
  fontSize: "12px",
  fontWeight: 400,
  color: "#666666",
});

export const sheet = style({
  position: "absolute",
  top: 50,
  display: "flex",
  borderRadius: 6,
  background: "#FFFFFF",
  overflow: "hidden",
  zIndex: 1,
  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
});

export const collectionAction = style({
  fontWeight: 400,
  fontSize: 12,
  borderRadius: 6,
  padding: 9,
  cursor: "pointer",
  "&:hover": {
    background: "#F5F7FC",
  },
  display: "flex",
  alignItems: "center",
  color: "#1641DB",
});

export const nopicctn = style({
  border: "1px solid #E5E5E5",
  borderRadius: "4px",
  width: "80px",
  height: "80px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
