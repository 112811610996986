import axios from "axios";
import { getDefaultStore } from "./storeUtil";

export const getCityAndState = async (pincode) => {
  let response = axios.get(
    `${process.env.REACT_APP_API}/api/v1/locations/?pincode=${pincode}`
  );

  return response;
};

export const fetchStoreCustomerDetails = (id) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  const payload = axios
    .get(
      `${process.env.REACT_APP_API}/api/v2/org/store/${store.store_id}/customers/${id}?addresses=true`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .then((response) => {
      return response.data.payload;
    })
    .catch((error) => {
      console.log(error);
    });
  return payload;
};

export const fetchStoreCustomerMetric = (id, metric) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  const payload = axios
    .get(
      `${process.env.REACT_APP_API}/api/v1/business/store/${store.store_id}/customers/${id}/metrics/${metric}?orgStoreCustomerId=${id}`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .then((response) => {
      return response.data.payload;
    })
    .catch((error) => {
      console.log(error);
    });
  return payload;
};

export const fetchCustomerWalletTransactions = (id, size, page) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  const payload = axios
    .get(
      `${process.env.REACT_APP_API}/api/v1/business/store/${store.store_id}/customers/${id}/wallet-transactions?size=${size}&page=${page}&sort=id,desc`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .then((response) => {
      return response.data.payload;
    })
    .catch((error) => {
      console.log(error);
    });
  return payload;
};

export const fetchCustomerStoreTransactions = (id, size, page) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  const payload = axios
    .get(
      `${process.env.REACT_APP_API}/api/v1/business/store/${store.store_id}/customers/${id}/transactions?size=${size}&page=${page}&sort=id,desc`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .then((response) => {
      return response.data.payload;
    })
    .catch((error) => {
      console.log(error);
    });
  return payload;
};

export const fetchOpenOrders = (id, intent) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  const response = axios.get(
    `${process.env.REACT_APP_API}/api/v1/business/store/${store.store_id}/customers/${id}/order-summaries?intent=${intent}`,
    {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return response;
};

export const postWalletTransaction = async (id, data) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  return await axios.post(
    `${process.env.REACT_APP_API}/api/v1/business/store/${store.store_id}/customers/${id}/wallet-transactions`,
    data,
    {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const postStoreTransaction = async (id, data) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  return await axios.post(
    `${process.env.REACT_APP_API}/api/v1/business/store/${store.store_id}/customers/${id}/transactions`,
    data,
    {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const fetchCustomerAddresses = async (id) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API}/api/v1/business/store/${store.store_id}/customers/${id}/addresses`,

      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.payload;
  } catch (err) {
    console.log("Error", err);
  }
};

export const addCustomerAddress = async (payload, customerId) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_API}/api/v1/business/store/${store.store_id}/customers/${customerId}/addresses`,
      payload,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.payload;
  } catch (err) {
    console.log("Error ", err);
  }
};

export const editCustomerAddress = async (payload, customerId) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  try {
    let response = await axios.put(
      `${process.env.REACT_APP_API}/api/v1/business/store/${store.store_id}/customers/${customerId}/addresses/${payload.id}`,
      payload,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.payload;
  } catch (err) {
    console.log("Error", err);
  }
};

export const deleteCustomerAddress = async (id, customerId) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  try {
    let response = await axios.delete(
      `${process.env.REACT_APP_API}/api/v1/business/store/${store.store_id}/customers/${customerId}/addresses/${id}`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.payload;
  } catch (err) {
    console.log("Error", err);
  }
};
