import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const StoreIcon = ({
  viewBox = "0 0 20 20",
  stroke = "#999999",
  fill = "#999999",
}) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "20px", height: "20px" }}>
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.25 0.625H3.75L0.625 6.25C0.625 7.97625 2.02375 9.375 3.75 9.375C5.47625 9.375 6.875 7.97625 6.875 6.25C6.875 7.97625 8.27375 9.375 10 9.375C11.7263 9.375 13.125 7.97625 13.125 6.25C13.125 7.97625 14.5237 9.375 16.25 9.375C17.9763 9.375 19.375 7.97625 19.375 6.25L16.25 0.625Z"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.875 11.875V19.375H3.125V11.875"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.125 19.375V14.375H11.875V19.375"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default StoreIcon;
