import React from "react";
import { makeStyles, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  default: {
    marginBottom: theme.spacing(9),
    borderRadius: 6,
    background: "#FF3020",
    boxShadow: "0px 6px 10px 0px rgba(0,0,0,0.09)",
  },
  placeholder: {
    fontSize: 12,
    color: "#999999",
  },
}));

const ErrorAlert = ({
  message,
  open,
  onClose,
  autoHideDuration,
  alertClass,
}) => {
  const classes = useStyles();
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration ? autoHideDuration : 10000}
      onClose={onClose}
    >
      <Alert
        elevation={6}
        variant="filled"
        severity="error"
        className={alertClass ? alertClass : classes.default}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default ErrorAlert;
