import React, { useState } from "react";
import CheckIcon from "@material-ui/icons/Check";
import EditPen from "../../icons/EditPen";
import {
  makeStyles,
  TextField,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import HeaderBreadcrumbs from "../common/HeaderBreadcrumbs";

const useStyles = makeStyles((theme) => ({
  label: {
    color: "#1641DB",
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 600,
  },
  heading: {
    fontSize: 24,
    lineHeight: "32px",
    color: "#1A1A1A",
  },
}));

const OrderHeader = ({
  label,
  noEdit,
  prefix,
  id,
  autoId,
  onNumberChange,
  startLabel,
  displayId,
}) => {
  const classes = useStyles();
  const [editNumber, setEditNumber] = useState(false);
  const [number, setNumber] = useState("");

  const onCompletion = () => {
    onNumberChange(prefix + number);
    setEditNumber(false);
  };

  const list = noEdit
    ? [
        startLabel,
        {
          label: displayId ? displayId : id,
          link: startLabel.nextLink
            ? startLabel.nextLink
            : `${startLabel.link}/${id}`,
        },
        {
          label,
        },
      ]
    : [
        startLabel,
        {
          label,
        },
      ];

  return (
    <div>
      <h6 className={classes.heading} style={{ fontWeight: 700 }}>
        <HeaderBreadcrumbs list={list} />
      </h6>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: 8,
        }}
      >
        {!editNumber && !noEdit && (
          <Typography className={classes.label}>
            {autoId.trim() !== id.trim() ? "User Assigned " : "Auto Assigned "}
            {id}
          </Typography>
        )}
        {editNumber && !noEdit && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography className={classes.label}>{prefix}</Typography>
            <TextField
              inputProps={{
                style: {
                  color: "#1641DB",
                  fontSize: 14,
                  height: 20,
                  lineHeight: "18px",
                  backgroundColor: "#fff",
                },
                className: classes.heading,
                maxLength: 16 - prefix.length,
              }}
              InputProps={{
                disableUnderline: true,
                id: "invoiceNoInput",
                endAdornment: (
                  <InputAdornment position="end">
                    <span style={{ fontSize: "10px", color: "#808080" }}>
                      {number.length}/{16 - prefix.length}
                    </span>
                  </InputAdornment>
                ),
              }}
              value={number}
              disabled={!editNumber}
              name="invoice_id"
              autoFocus
              onChange={(e) => {
                setNumber(e.target.value);
              }}
              onBlur={onCompletion}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  onCompletion();
                }
              }}
            />
          </div>
        )}
        {!editNumber && !noEdit && (
          <span
            onClick={() => {
              setNumber(id.slice(prefix.length));
              setEditNumber(true);
            }}
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              marginLeft: 10,
            }}
          >
            <EditPen />
          </span>
        )}
        {editNumber && !noEdit && (
          <div
            onClick={onCompletion}
            style={{
              marginTop: -6,
              marginLeft: 5,
            }}
          >
            <CheckIcon color="secondary" fontSize="medium" />
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderHeader;
