export const SETAPPS = "SETAPPS";
export const TOGGLEAPPSLOADING = "TOGGLEAPPSLOADING";
export const INSTALLLOADING = "INSTALLLOADING";
export const INSTALLSUCCESS = "INSTALLSUCCESS";
export const INSTALLFAILED = "INSTALLFAILED";
export const CLEARAPPS = "CLEARAPPS";
export const LOADFAILED = "LOADFAILED";
export const SETCHECKOUTFIELDS = "SETCHECKOUTFIELDS";
export const CREATECHECKOUTFIELD = "CREATECHECKOUTFIELD";
export const DELETECHECKOUTFIELD = "DELETECHECKOUTFIELD";
export const CHECKOUTFIELDACTIONLOADING = "CHECKOUTFIELDACTIONLOADING";
export const CHECKOUTFIELDACTIONSUCCESS = "CHECKOUTFIELDACTIONSUCCESS";
export const CHECKOUTFIELDACTIONFAILED = "CHECKOUTFIELDACTIONFAILED";
export const SETPIXELVALUES = "SETPIXELVALUES";
export const RESETCHECKOUTACTION = "RESETCHECKOUTACTION";
export const SETPLUGINCONFIG = "SETPLUGINCONFIG";
export const CONFIGACTIONSTATUS = "CONFIGACTIONSTATUS";
export const AMENDCHECKOUTFIELD = "AMENDCHECKOUTFIELD";
export const CLEARAPPSTATE = "CLEARAPPSTATE";
