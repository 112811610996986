import React from "react";


const AddProductIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M10.5 7.5C12.1569 7.5 13.5 6.15685 13.5 4.5C13.5 2.84315 12.1569 1.5 10.5 1.5C8.84315 1.5 7.5 2.84315 7.5 4.5C7.5 6.15685 8.84315 7.5 10.5 7.5Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.5 9.5C9.17392 9.5 7.90215 10.0268 6.96447 10.9645C6.02678 11.9021 5.5 13.1739 5.5 14.5H15.5C15.5 13.1739 14.9732 11.9021 14.0355 10.9645C13.0979 10.0268 11.8261 9.5 10.5 9.5V9.5Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M0.5 8.5H4.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2.5 6.5V10.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};
export default AddProductIcon;