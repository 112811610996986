import { ExpandLess, ExpandMore } from "@material-ui/icons";
import {
  Collapse,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useContext } from "react";
import { ChatWeightContext } from "../../../../../../context/Context";
import { ColorPicker } from "material-ui-color";
import BubbleText from "./components/BubbleText";
import {
  InputGridContainer,
  InputGridItem,
} from "../../../Common/InputGridContainer";
import { colorPickerColors } from "../../../../../../../../../../utils/colorPickerColors";

const useStyle = makeStyles(() => ({
  colorPicker: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #E6E6E6",
    padding: 4,
    borderRadius: 4,
  },
}));

const AdvancedSettings = () => {
  const classes = useStyle();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const {
    chatBubbleStates: { chatBubbleColors, chatBubbleColorDispatch },
  } = useContext(ChatWeightContext);

  return (
    <div>
      <ListItem
        button
        onClick={handleClick}
        style={{
          background: "#f9f9f9",
        }}
      >
        <ListItemText primary="Advanced Settings" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <div
          style={{
            paddingTop: "24px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <InputGridContainer style={{ marginBottom: 20, width: "100%" }}>
              {Object.values(chatBubbleColors).map((color, index) => (
                <InputGridItem key={index}>
                  <Typography
                    style={{
                      fontSize: 14,
                      marginBottom: 12,
                    }}
                  >
                    {color.label}
                  </Typography>
                  <div className={classes.colorPicker}>
                    <ColorPicker
                      hideTextfield
                      value={color.value}
                      onChange={(value) => {
                        chatBubbleColorDispatch(color.name, {
                          color: value,
                          value: value.css.backgroundColor,
                        });
                      }}
                      palette={colorPickerColors}
                    />
                    <Typography style={{ fontSize: 14, marginLeft: 10 }}>
                      {color.value}
                    </Typography>
                  </div>
                </InputGridItem>
              ))}
            </InputGridContainer>
          </div>
          <BubbleText />
        </div>
      </Collapse>
    </div>
  );
};

export default AdvancedSettings;
