import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const PrintIcon = ({
  viewBox = "0 0 18 16",
  stroke = "#1641DB",
  size = 16,
}) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: 18, height: 16 }}>
      <svg
        width="18px"
        height="16px"
        viewBox={viewBox}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.5 4.76914V2.14414H5.49998V4.76914H4.1875V0.831665H13.8125V4.76914H12.5ZM14.0817 8.2691C14.3296 8.2691 14.5374 8.18525 14.7051 8.01754C14.8728 7.84983 14.9567 7.64202 14.9567 7.3941C14.9567 7.14618 14.8728 6.93837 14.7051 6.77066C14.5374 6.60295 14.3296 6.5191 14.0817 6.5191C13.8338 6.5191 13.626 6.60295 13.4583 6.77066C13.2905 6.93837 13.2067 7.14618 13.2067 7.3941C13.2067 7.64202 13.2905 7.84983 13.4583 8.01754C13.626 8.18525 13.8338 8.2691 14.0817 8.2691ZM12.5 14.1249V10.3893H5.49998V14.1249H12.5ZM13.8125 15.4374H4.1875V11.9374H0.939941V6.9566C0.939941 6.33682 1.15168 5.8173 1.57515 5.39803C1.99862 4.97877 2.51603 4.76914 3.1274 4.76914H14.8726C15.4923 4.76914 16.0119 4.97877 16.4311 5.39803C16.8504 5.8173 17.06 6.33682 17.06 6.9566V11.9374H13.8125V15.4374ZM15.7476 10.6249V6.9566C15.7476 6.70868 15.6637 6.50087 15.496 6.33316C15.3283 6.16545 15.1205 6.0816 14.8726 6.0816H3.1274C2.87948 6.0816 2.67167 6.16545 2.50396 6.33316C2.33625 6.50087 2.2524 6.70868 2.2524 6.9566V10.6249H4.1875V9.07681H13.8125V10.6249H15.7476Z"
          fill={stroke}
        />
      </svg>
    </SvgIcon>
  );
};

export default PrintIcon;
