import React from "react";
import Controls from "../../../../components/controls/Controls";
import { Grid, Typography } from "@material-ui/core";
import {
  getLabel,
  getPlaceholder,
  getTimePlaceholder,
  validateTimeDuration,
} from "./BaatHelper";
import useStyles from "../../components/ConfigStyles";

const parameterOptions = [
  { id: "order.store_name", title: "Store Name" },
  { id: "order.org_customer_name", title: "Customer Name" },
  { id: "order.org_customer_mobile", title: "Customer Mobile" },
  { id: "order.display_id", title: "Order ID" },
  { id: "order.current_reason", title: "Order Cancel Reason" },
  { id: "order.order_items", title: "Order Items" },
  { id: "order.delivery_estimate", title: "Order Delivery Estimate" },
  { id: "delivery_detail.partner_name", title: "Order Courier Name" },
  { id: "delivery_detail.tracking_id", title: "Order Courier Tracking Id" },
  { id: "order.final_sale_price", title: "Order Total Amount" },
  { id: "invoice.url", title: "Invoice URL" },
  { id: "otp", title: "OTP" },
];

const abandonedCartOptions = [
  { id: "cart.storeName", title: "Store Name" },
  { id: "cart.customerName", title: "Customer Name" },
  { id: "cart.customerMobile", title: "Customer Mobile" },
  { id: "cart.firstProduct", title: "First Product" },
  { id: "cart.maxValueProduct", title: "Max Value Product" },
  { id: "cart.amount", title: "Amount" },
  { id: "cart.firstProductImage", title: "First Product Image" },
];

const TimeOptions = [
  // { id: "mintues", title: "Mintues" },
  { id: "hours", title: "Hours" },
  { id: "days", title: "Days" },
];

export const TemplateForm = ({
  template,
  templates,
  templateData,
  onSwitchChange,
  onTemplateChange,
  onParamsChange,
}) => {
  const Parameter = (template) => {
    if (template.id === "abandoned_cart") {
      return abandonedCartOptions;
    } else {
      return parameterOptions;
    }
  };

  const classes = useStyles();
  return (
    <>
      <Controls.Switch
        name={template.id}
        label={template.title}
        value={templateData.show}
        onChange={onSwitchChange}
        labelFontWeight={500}
      />

      {templateData.show && (
        <>
          <Controls.Select
            name={template.id}
            label="Select Template"
            labelFontWeight={400}
            labelPlacement="top"
            value={templateData.template_name || "none"}
            placeholder={"Select Template"}
            onChange={onTemplateChange}
            options={
              templates &&
              templates.map((template) => {
                return {
                  id: template.name,
                  title: template.name,
                };
              })
            }
            error={templateData.error ? "This field is required" : ""}
            MenuProps={{
              classes: {
                paper: classes.menuPaper,
              },
            }}
          />
          <Grid container>
            {templateData.template_name &&
              Object.keys(templateData.parameters).length > 0 &&
              Object.keys(templateData.parameters).map((param, index) => {
                if (param.split("|")[1] !== "TIME") {
                  return (
                    <Grid item xs={12}>
                      <Controls.Select
                        name={param}
                        label={getLabel(param)}
                        labelFontWeight={400}
                        labelPlacement="top"
                        value={
                          templateData.parameters[param].value !== ""
                            ? templateData.parameters[param].value
                            : "none"
                        }
                        placeholder={getPlaceholder(
                          param,
                          templateData?.parameters[param]?.format
                        )}
                        onChange={onParamsChange}
                        options={Parameter(template)}
                        key={index}
                        error={
                          templateData.parameters[param].error
                            ? "This field is required"
                            : ""
                        }
                      />
                    </Grid>
                  );
                } else {
                  return (
                    <Grid item xs={6}>
                      {param.split("|")[2] === "{{1}}" && (
                        <>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              marginBottom: "10px",
                            }}
                          >
                            Send message after
                          </Typography>
                          <Controls.Input
                            name={param}
                            placeholder={getTimePlaceholder(
                              param,
                              templateData
                            )}
                            value={
                              templateData.parameters[param].value
                                ? templateData.parameters[param].value
                                : ""
                            }
                            onChange={onParamsChange}
                            error={
                              validateTimeDuration(
                                templateData.parameters[
                                  "abandoned_cart|TIME|{{1}}"
                                ],
                                templateData.parameters[
                                  "abandoned_cart|TIME|{{2}}"
                                ].value
                              )
                                ? validateTimeDuration(
                                    templateData.parameters[
                                      "abandoned_cart|TIME|{{1}}"
                                    ],
                                    templateData.parameters[
                                      "abandoned_cart|TIME|{{2}}"
                                    ].value
                                  )
                                : templateData.parameters[
                                    "abandoned_cart|TIME|{{1}}"
                                  ].error
                                ? "This field is required"
                                : ""
                            }
                            style={{ width: "98%" }}
                          />
                        </>
                      )}

                      {param.split("|")[2] === "{{2}}" && (
                        <>
                          <Controls.Select
                            name={param}
                            labelFontWeight={400}
                            labelPlacement="top"
                            value={
                              templateData.parameters[param].value !== ""
                                ? templateData.parameters[param].value
                                : "none"
                            }
                            onChange={onParamsChange}
                            options={TimeOptions}
                            error={
                              templateData.parameters[param].error
                                ? "This field is required"
                                : ""
                            }
                            style={{ marginTop: "30px" }}
                          />
                        </>
                      )}
                    </Grid>
                  );
                }
              })}
          </Grid>
        </>
      )}
      <hr
        style={{
          border: "1px solid #E0E0E0",
          marginTop: "-1px",
        }}
      />
    </>
  );
};
