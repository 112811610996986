// Core react imports
import React from "react";
// Mui imports
import { Box, Typography, Button, Grid, makeStyles } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import InstallIcon from "../../../icons/InstallIcon";
// Routing imports
import { useHistory } from "react-router-dom";
// Component imports
import { FreeChip, InstalledChip, PremiumChip } from "./Chips";
import { configApps } from "../constants";
import { PLANS } from "../../plans/constants";
// Making styles
const useStyles = makeStyles((theme) => ({
  content: {
    display: "block" /* Fallback for non-webkit */,
    lineHeight: "16px",
    color: "#666",
    fontSize: "12px",
    cursor: "pointer",
  },
  title: {
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "20px",
    cursor: "pointer",
    marginBottom: "4px",
  },
  bottomBox: {
    backgroundColor: "#fff",
    padding: "12px",
    border: "1px",
    borderRadius: "6px",
    boxShadow: "0px -1px 0px rgba(0, 0, 0, 0.06)",
    borderTopLeftRadius: "0px",
    borderTopRightRadius: "0px",
  },
}));
// Exporting component
export default function AppCard({ app }) {
  const classes = useStyles();
  const history = useHistory();
  const getStatusOrCostingChip = () => {
    if (app.installed) {
      return <InstalledChip />;
    } else if (app.plan === "FREE") {
      return <FreeChip />;
    } else {
      return (
        <PremiumChip
          planName={PLANS[app.plan] ? PLANS[app.plan].name : "Upcoming"}
        />
      );
    }
  };
  const getActionButton = () => {
    let buttonText = null;
    if (!app.installed) {
      buttonText = "Install";
    } else if (app.config_required && configApps.includes(app.slug)) {
      buttonText = "Configure";
    }
    if (buttonText) {
      return (
        <Button
          size="small"
          color="secondary"
          startIcon={
            buttonText === "Configure" ? (
              <SettingsIcon fontSize="small" />
            ) : (
              <InstallIcon />
            )
          }
          style={{
            textTransform: "none",
            fontSize: "12px",
            padding: 0,
            lineHeight: "12px",
            height: "16px",
          }}
          onClick={() => {
            if (buttonText === "Configure") {
              history.push(`./apps/${app.slug}/configure`);
            } else {
              history.push(`./apps/${app.slug}?action=${buttonText}`);
            }
          }}
        >
          {buttonText}
        </Button>
      );
    }
  };
  return (
    <Grid item xs={4}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        style={{
          border: "1px solid #E5E5E5",
          borderRadius: "6px",
          height: "100%",
          background: "#fff",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-around"
          style={{
            padding: "12px",
            cursor: "pointer",
            border: "1px",
            borderRadius: "6px",
          }}
          onClick={() => {
            history.push(`./apps/${app.slug}`);
          }}
        >
          <img
            src={app.icon_url}
            alt={`${app.slug}_image`}
            style={{ height: "72px", width: "72px", marginRight: "12px" }}
          />
          <Box>
            <Typography className={classes.title}>{app.title}</Typography>
            <Typography className={classes.content}>{app.sub_title}</Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className={classes.bottomBox}
        >
          <Box>{getStatusOrCostingChip()}</Box>
          <Box>{getActionButton()}</Box>
        </Box>
      </Box>
    </Grid>
  );
}
