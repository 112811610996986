import { Typography } from "@material-ui/core";
import { LocalMall } from "@material-ui/icons";
import React from "react";
import { useDispatch } from "react-redux";
import FormModal from "../../../components/common/FormModal";
import { updateOrders } from "../../../redux/orders/orderActions";
import useStyles from "../styles";

const ShipOrderModal = ({
  openShipOrder,
  setOpenShipOrder,
  order,
  setToast,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onShip = () => {
    dispatch(
      updateOrders(
        {
          state: "SHIPPED",
          status: "IN_TRANSIT",
        },
        [order.id]
      )
    ).then(() => setToast(true));

    setOpenShipOrder(false);
  };
  if (order)
    return (
      <FormModal
        open={openShipOrder}
        onClose={() => {
          setOpenShipOrder(false);
        }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onSave={onShip}
        startIcon={<LocalMall fontSize="small" />}
        btnTxt={"Pickup"}
        heading={"Ready For Pickup"}
      >
        <div>
          <Typography className={classes.subHeading}>
            Set Order Ready For Pickup?
          </Typography>{" "}
        </div>
      </FormModal>
    );
  return <span />;
};

export default ShipOrderModal;
