import React from "react";

export default function PaymentsSettingsIcon({
  fill = "#666666",
  strokeWidth = 0,
}) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_22814_1829"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="28"
        height="28"
      >
        <rect width="28" height="28" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_22814_1829)">
        <path
          d="M5.81744 22C5.314 22 4.88519 21.823 4.531 21.469C4.177 21.1148 4 20.686 4 20.1826V5.81744C4 5.314 4.177 4.88519 4.531 4.531C4.88519 4.177 5.314 4 5.81744 4H20.1826C20.686 4 21.1148 4.177 21.469 4.531C21.823 4.88519 22 5.314 22 5.81744V9.12747H20.875V5.81744C20.875 5.6155 20.8101 5.44956 20.6804 5.31962C20.5504 5.18987 20.3845 5.125 20.1826 5.125H5.81744C5.6155 5.125 5.44956 5.18987 5.31963 5.31962C5.18988 5.44956 5.125 5.6155 5.125 5.81744V20.1826C5.125 20.3845 5.18988 20.5504 5.31963 20.6804C5.44956 20.8101 5.6155 20.875 5.81744 20.875H20.1826C20.3845 20.875 20.5504 20.8101 20.6804 20.6804C20.8101 20.5504 20.875 20.3845 20.875 20.1826V16.8725H22V20.1826C22 20.686 21.823 21.1148 21.469 21.469C21.1148 21.823 20.686 22 20.1826 22H5.81744ZM14.8174 17.5C14.314 17.5 13.8852 17.323 13.531 16.969C13.177 16.6148 13 16.186 13 15.6826V10.3174C13 9.814 13.177 9.38519 13.531 9.031C13.8852 8.677 14.314 8.5 14.8174 8.5H21.3076C21.811 8.5 22.2398 8.677 22.594 9.031C22.948 9.38519 23.125 9.814 23.125 10.3174V15.6826C23.125 16.186 22.948 16.6148 22.594 16.969C22.2398 17.323 21.811 17.5 21.3076 17.5H14.8174ZM21.3076 16.375C21.5095 16.375 21.6754 16.3101 21.8054 16.1804C21.9351 16.0504 22 15.8845 22 15.6826V10.3174C22 10.1155 21.9351 9.94956 21.8054 9.81962C21.6754 9.68987 21.5095 9.625 21.3076 9.625H14.8174C14.6155 9.625 14.4496 9.68987 14.3196 9.81962C14.1899 9.94956 14.125 10.1155 14.125 10.3174V15.6826C14.125 15.8845 14.1899 16.0504 14.3196 16.1804C14.4496 16.3101 14.6155 16.375 14.8174 16.375H21.3076ZM17.5 14.6875C17.9688 14.6875 18.3672 14.5234 18.6953 14.1953C19.0234 13.8672 19.1875 13.4688 19.1875 13C19.1875 12.5312 19.0234 12.1328 18.6953 11.8047C18.3672 11.4766 17.9688 11.3125 17.5 11.3125C17.0312 11.3125 16.6328 11.4766 16.3047 11.8047C15.9766 12.1328 15.8125 12.5312 15.8125 13C15.8125 13.4688 15.9766 13.8672 16.3047 14.1953C16.6328 14.5234 17.0312 14.6875 17.5 14.6875Z"
          fill={fill}
          stroke={fill}
          strokeWidth={strokeWidth}
        />
      </g>
    </svg>
  );
}
