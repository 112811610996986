import axios from "axios";
import { getStoreIdFromUrl } from "../../utils/storeUtil";
import {
  ADD_MENU,
  ADD_MENU_ITEM,
  ADD_SUB_MENU_ITEM,
  CLEAR_MENU_ERROR,
  DELETE_MENU_ITEM,
  DELETE_SUB_MENU_ITEM,
  UPDATE_MENU_ITEM,
  UPDATE_SUB_MENU_ITEM,
  FETCH_MENU,
  MENU_LOADER,
  MENU_PROGRESS,
  SET_MENU_ERROR,
  UPDATE_MENU,
  UPDATE_MENU_ITEM_LIST,
} from "./menuTypes";

export const rearrangeMenuItems = (menuId, data) => (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  let storeId = getStoreIdFromUrl();
  axios
    .patch(
      `${process.env.REACT_APP_AUTH_API}/api/v2/business/store/${storeId}/menu/${menuId}/position`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .then((res) => {
      dispatch({
        type: UPDATE_MENU_ITEM_LIST,
        payload: res.data.payload,
      });
    })
    .catch((err) => console.log(err));
};

export const fetchMenu = () => async (dispatch) => {
  dispatch({
    type: MENU_PROGRESS,
    payload: true,
  });
  let storeId = getStoreIdFromUrl();
  let accessToken = localStorage.getItem("access_token");
  return await axios
    .get(`${process.env.REACT_APP_API}/api/v2/business/store/${storeId}/menu`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) =>
      dispatch({
        type: FETCH_MENU,
        payload: response.data.payload,
      })
    )
    .catch((error) => {
      console.log(error.response);
      dispatch({
        type: SET_MENU_ERROR,
        payload: { error: error.message },
      });
    });
};

export const addMenu = (menuData) => async (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  let storeId = getStoreIdFromUrl();
  let data = menuData;
  return await axios
    .post(
      `${process.env.REACT_APP_API}/api/v2/business/store/${storeId}/menu`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      dispatch({
        type: ADD_MENU,
        payload: response.data.payload,
      });
      return response.data.payload;
    })
    .catch((error) => {
      dispatch({
        type: SET_MENU_ERROR,
        payload: { error: error.message },
      });
    });
};

export const updateMenu = (menuId, menuData) => async (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  let storeId = getStoreIdFromUrl();
  let data = menuData;
  return await axios
    .patch(
      `${process.env.REACT_APP_API}/api/v2/business/store/${storeId}/menu/${menuId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      dispatch({
        type: UPDATE_MENU,
        payload: menuData.css,
      });
      return response.data.payload;
    })
    .catch((error) => {
      dispatch({
        type: SET_MENU_ERROR,
        payload: { error: error.message },
      });
    });
};

export const addMenuItem = (data, menuId) => async (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  let storeId = getStoreIdFromUrl();
  return await axios
    .post(
      `${process.env.REACT_APP_API}/api/v2/business/store/${storeId}/menu/${menuId}/items`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      dispatch({
        type: ADD_MENU_ITEM,
        payload: response.data.payload,
      });
      return response.data.payload;
    })
    .catch((error) => {
      dispatch({
        type: SET_MENU_ERROR,
        payload: { error: error.message },
      });
    });
};

export const addSubMenuItem =
  (data, menuId, parentMenuItemId) => async (dispatch) => {
    let accessToken = localStorage.getItem("access_token");
    let storeId = getStoreIdFromUrl();
    return await axios
      .post(
        `${process.env.REACT_APP_API}/api/v2/business/store/${storeId}/menu/${menuId}/items/${parentMenuItemId}/items`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        dispatch({
          type: ADD_SUB_MENU_ITEM,
          payload: {
            subMenuItem: response.data.payload,
            parentMenuItemId: parentMenuItemId,
          },
        });
        return response.data.payload;
      })
      .catch((error) => {
        dispatch({
          type: SET_MENU_ERROR,
          payload: { error: error.message },
        });
      });
  };

export const deleteMenuItem = (menuId, menuItemId) => async (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  startLoader();
  let storeId = getStoreIdFromUrl();
  return await axios
    .delete(
      `${process.env.REACT_APP_API}/api/v2/business/store/${storeId}/menu/${menuId}/items/${menuItemId}`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .then((res) => {
      dispatch({
        type: DELETE_MENU_ITEM,
        payload: { id: menuItemId },
      });
      return res.data.payload;
    })
    .catch((error) => {
      console.log(error.response);
      dispatch({
        type: SET_MENU_ERROR,
        payload: { id: menuItemId, error: error.message },
      });
    });
};

export const deleteSubMenuItem =
  (menuId, menuItemId, subMenuItemId) => async (dispatch) => {
    let accessToken = localStorage.getItem("access_token");
    startLoader();
    let storeId = getStoreIdFromUrl();
    return await axios
      .delete(
        `${process.env.REACT_APP_API}/api/v2/business/store/${storeId}/menu/${menuId}/items/${menuItemId}/items/${subMenuItemId}`,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((res) => {
        dispatch({
          type: DELETE_SUB_MENU_ITEM,
          payload: { menuItemId: menuItemId, subMenuItemId: subMenuItemId },
        });
        return res.data.payload;
      })
      .catch((error) => {
        console.log(error.response);
        dispatch({
          type: SET_MENU_ERROR,
          payload: { id: menuItemId, error: error.message },
        });
      });
  };

export const editMenuItem = (data, menuId) => async (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  let storeId = getStoreIdFromUrl();
  return await axios
    .put(
      `${process.env.REACT_APP_API}/api/v2/business/store/${storeId}/menu/${menuId}/items/${data.id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      dispatch({
        type: UPDATE_MENU_ITEM,
        payload: response.data.payload,
      });
      return response.data.payload;
    })
    .catch((error) => {
      dispatch({
        type: SET_MENU_ERROR,
        payload: { error: error.message },
      });
    });
};

export const editSubMenuItem =
  (data, menuId, parentMenuItemId) => async (dispatch) => {
    let accessToken = localStorage.getItem("access_token");
    let storeId = getStoreIdFromUrl();
    return await axios
      .put(
        `${process.env.REACT_APP_API}/api/v2/business/store/${storeId}/menu/${menuId}/items/${parentMenuItemId}/items/${data.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        dispatch({
          type: UPDATE_SUB_MENU_ITEM,
          payload: {
            subMenuItem: response.data.payload,
            parentMenuItemId: parentMenuItemId,
          },
        });
        return response.data.payload;
      })
      .catch((error) => {
        dispatch({
          type: SET_MENU_ERROR,
          payload: { error: error.message },
        });
      });
  };

export const clearMenuError = () => (dispatch) => {
  dispatch({
    type: CLEAR_MENU_ERROR,
    payload: { error: null },
  });
};

export const setMenuError = (message) => (dispatch) => {
  dispatch({
    type: SET_MENU_ERROR,
    payload: { error: message },
  });
};

export const startLoader = () => (dispatch) => {
  dispatch({
    type: MENU_LOADER,
    payload: true,
  });
};
