import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const FileDownload = () => {
  return (
    <SvgIcon viewBox="0 0 24 24" style={{ width: "24px", height: "24px" }}>
      <mask
        id="mask0_12694_7934"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_12694_7934)">
        <path
          d="M6 20C5.45 20 4.97933 19.8043 4.588 19.413C4.196 19.021 4 18.55 4 18V15H6V18H18V15H20V18C20 18.55 19.8043 19.021 19.413 19.413C19.021 19.8043 18.55 20 18 20H6ZM12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16Z"
          fill="#1641DB"
        />
      </g>
    </SvgIcon>
  );
};

export default FileDownload;
