import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  paper: {
    padding: "28px",
    minHeight: "84vh",
    minWidth: 370,
    margin: "auto",
    borderRadius: 6,
    boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.04)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  switchctn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 16,
    padding: "28px",
    width: "50%",
    borderRadius: 6,
    background: "#FFFFFF",
    boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.04)",
  },
  switchlabel: {
    fontWeight: 400,
  },
  linkIcon: {
    color: "#1A1A1A",
    fontSize: "14px",
    verticalAlign: "middle",
  },
  linkText: {
    fontSize: "14px",
    color: "#1A1A1A",
    fontWeight: 600,
    lineHeight: "20px",
  },
  button: {
    width: "47.5%",
    border: "1px solid",
    textTransform: "none",
    padding: "12px 18px",
    borderRadius: "8px",
    color: "#1641DB",
    lineHeight: "24px",
    fontSize: "16px",
    fontWeight: "600",
    "&:hover": {
      backgroundColor: "none",
    },
  },
  action: {
    width: "150px",
    border: "1px solid #1641DB",
    textTransform: "none",
    marginLeft: 16,
    padding: "12px",
    borderRadius: "8px",
    lineHeight: "24px",
    fontSize: "16px",
    fontWeight: "600",
    "&:hover": {
      backgroundColor: "none",
    },
  },
  link: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
  },
  imgctn: {
    width: "80px",
    height: "80px",
    background: "#E5E5E5",
    borderRadius: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontWeight: 600,
    fontSize: 24,
    marginBottom: 0,
  },
  subtitle: {
    fontSize: 14,
  },
  description: {
    fontSize: 14,
  },
  row: {
    display: "grid",
    gap: "28px",
    gridTemplateColumns: "1fr 1fr",
    marginBottom: "10px",
  },
  btnctn: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 32,
    marginBottom: 40,
  },
  progressbar: {
    position: "fixed",
    top: "50%",
    left: "50%",
  },
  breadcrumbsContainer: {
    display: "flex",
    flexFlow: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  backDiv: {
    marginBottom: "24px",
  },
  infoContainer: {
    display: "flex",
    flexFlow: "row",
    alignItems: "center",
    gap: "15px",
    paddingBottom: "5px",
    borderBottom: "1px solid rgba(0,0,0,.1)",
    marginBottom: "20px",
  },
}));
