import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ListCardIcon = () => {
  return (
    <SvgIcon viewBox={"0 0 36 36"} style={{ width: 36, height: 36 }}>
      <rect
        x="0.5"
        y="0.5"
        width="35"
        height="35"
        rx="1.5"
        fill="white"
        stroke="#999999"
      />
      <rect
        x="2.08301"
        y="2.76855"
        width="8.34783"
        height="8.86154"
        rx="1.24638"
        fill="#8994FF"
      />
      <rect
        x="11.9961"
        y="3.87695"
        width="13.0435"
        height="1.10769"
        rx="0.553846"
        fill="#C4C4C4"
      />
      <rect
        x="11.9961"
        y="6.64648"
        width="8.86956"
        height="1.10769"
        rx="0.553846"
        fill="#C4C4C4"
      />
      <rect
        x="27.6494"
        y="4.98438"
        width="6.26087"
        height="3.32308"
        rx="1.24638"
        fill="#C4C4C4"
      />
      <rect
        x="2.08301"
        y="13.8447"
        width="8.34783"
        height="8.86154"
        rx="1.24638"
        fill="#8994FF"
      />
      <rect
        x="11.9961"
        y="14.9521"
        width="13.0435"
        height="1.10769"
        rx="0.553846"
        fill="#C4C4C4"
      />
      <rect
        x="11.9961"
        y="17.7227"
        width="8.86956"
        height="1.10769"
        rx="0.553846"
        fill="#C4C4C4"
      />
      <rect
        x="27.6494"
        y="16.0605"
        width="6.26087"
        height="3.32308"
        rx="1.24638"
        fill="#C4C4C4"
      />
      <rect
        x="2.08301"
        y="24.9229"
        width="8.34783"
        height="8.86154"
        rx="1.24638"
        fill="#8994FF"
      />
      <rect
        x="11.9961"
        y="26.0312"
        width="13.0435"
        height="1.10769"
        rx="0.553846"
        fill="#C4C4C4"
      />
      <rect
        x="11.9961"
        y="28.8008"
        width="8.86956"
        height="1.10769"
        rx="0.553846"
        fill="#C4C4C4"
      />
      <rect
        x="27.6494"
        y="27.1387"
        width="6.26087"
        height="3.32308"
        rx="1.24638"
        fill="#C4C4C4"
      />
    </SvgIcon>
  );
};

export default ListCardIcon;
