import React, { useEffect, useState } from "react";
import useStyles from "../components/ConfigStyles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  fetchApps,
  getPluginConfig,
  postPluginConfig,
} from "../../../redux/apps/appActions";
import Toast from "../../../components/Layout/Toast";
import HeaderBreadcrumbs from "../../../components/common/HeaderBreadcrumbs";
import { Paper, Typography } from "@material-ui/core";
import Controls from "../../../components/controls/Controls";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import { centerContainer, layoutCenterContainer } from "../../../styles/common";
import PageLoader from "../../../components/Layout/PageLoader";

const list = [
  {
    label: "App Store",
    link: "/apps",
  },
  {
    label: "Facebook Conversion API",
    link: "/apps/facebook-conversion-api",
  },
  {
    label: "Configure",
  },
];

const ConversionApiConfig = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const storeData = useSelector((state) => state.store);
  const appState = useSelector((state) => state.apps);
  const apps = appState.apps;

  const initialState = {
    dataset_id: {
      value: "",
      touched: false,
      error: "",
    },
    access_token: {
      value: "",
      toched: false,
      error: "",
    },
  };

  const [formState, setFormState] = useState(initialState);
  const [loading, setLoading] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (apps.length === 0) {
      dispatch(fetchApps());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apps.length]);

  useEffect(() => {
    if (storeData.fetched) {
      dispatch(getPluginConfig("facebook-conversion-api")).then((response) => {
        if (response.length > 0) {
          let formData = { ...initialState };
          response.forEach((configItem) => {
            if (formData.hasOwnProperty(configItem.name)) {
              formData[configItem.name] = {
                ...formData[configItem.name],
                value: configItem.value,
                touched: true,
              };
            }
          });
          setFormState(formData);
        }
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeData.fetched]);

  const handleChnage = (event) => {
    let { name, value } = event.target;
    let cloneData = formState[name];
    cloneData.touched = true;
    cloneData.value = value;
    setFormState({ ...formState, [name]: cloneData });
  };

  const onBlur = (event) => {
    const { name } = event.target;
    const cloneData = formState[name];
    if (!cloneData.value)
      cloneData.error =
        name === "dataset_id"
          ? "Please enter a valid Dataset ID."
          : "Please enter a valid Access Token.";

    setFormState((prevState) => ({ ...prevState, [name]: cloneData }));
  };

  const onFocus = (event) => {
    const { name } = event.target;
    let cloneData = formState[name];
    cloneData.touched = true;
    cloneData.error = "";
    setFormState((prevState) => ({ ...prevState, [name]: cloneData }));
  };

  const validatation = () => {
    let errorFlag = false;
    Object.keys(formState).forEach((key) => {
      let cloneData = formState[key];
      if (!cloneData.value) {
        errorFlag = true;
        cloneData.error =
          key === "dataset_id"
            ? "Please enter a valid Dataset ID."
            : "Please enter a valid Access Token.";
      }
      setFormState({ ...formState, [key]: cloneData });
    });
    return errorFlag;
  };

  const navigateToUrl = () => {
    history.goBack();
  };

  const handleSave = async () => {
    let isError = validatation();
    if (isError) return;

    dispatch(
      postPluginConfig("facebook-conversion-api", {
        access_token: formState.access_token.value,
        dataset_id: formState.dataset_id.value,
      })
    ).then((cobj) => {
      if (cobj.error) {
        setShowError(true);
      } else {
        setShowSuccess(true);
      }
    });
  };

  if (loading) return <PageLoader />;

  return (
    <div className={layoutCenterContainer}>
      <div className={centerContainer}>
        <div className={classes.breadcrumbsContainer}>
          <div className={classes.backDiv}>
            <HeaderBreadcrumbs list={list} />
          </div>
        </div>
        <Paper elevation={0} className={classes.paper}>
          <div>
            <Typography className={classes.formHead}>
              Conversion API Configuration Settings
            </Typography>
            <Controls.Input
              name="dataset_id"
              label="Facebook Pixel ID / Dataset ID"
              labelPlacement="top"
              value={formState.dataset_id.value}
              onChange={handleChnage}
              onFocus={onFocus}
              onBlur={onBlur}
              error={formState.dataset_id.error}
              helperText={
                !formState.dataset_id.error &&
                "Enter your 15-digit Facebook Pixel ID (e.g., 123456789012345) for ad tracking."
              }
              extraSpace={formState.dataset_id.error && true}
            />

            <Controls.Input
              name="access_token"
              label="Access Token"
              labelPlacement="top"
              required={true}
              value={formState.access_token.value}
              onChange={handleChnage}
              onBlur={onBlur}
              onFocus={onFocus}
              error={formState.access_token.error}
              helperText={
                !formState.access_token.error &&
                "Provide Conversion API Access Token from Facebook Event Manager > Settings tab."
              }
              extraSpace={formState.dataset_id.error && true}
              multiline
            />
          </div>

          <div className={classes.button}>
            <CombinedButtons
              outlinedBtnText="Back"
              outlinedBtnAction={navigateToUrl}
              solidBtnAction={handleSave}
            />
          </div>
        </Paper>
      </div>

      <Toast
        autoClose
        message={"Dataset ID and Access Token successfully saved."}
        open={showSuccess}
        close={() => setShowSuccess(false)}
      />
      <Toast
        autoClose
        message={
          "Failed to save Dataset ID and Access Token. Please try again later."
        }
        open={showError}
        close={() => setShowError(false)}
        severity="error"
      />
    </div>
  );
};

export default ConversionApiConfig;
