import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const SettingsIcon = ({
  viewBox = "0 0 56 56",
  stroke = "#999999",
  fill = "#999999",
}) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "56px", height: "56px" }}>
      <svg
        width="56"
        height="56"
        viewBox={viewBox}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="28" cy="28" r="28" fill="#EEF1FE" />
        <circle cx="27.5" cy="27.5" r="18.5" fill="#C2D0FF" />
        <path
          d="M31.125 21H17.375C17.0105 21.0004 16.661 21.1454 16.4032 21.4032C16.1454 21.661 16.0004 22.0105 16 22.375V27.4167C16 35.1041 22.5163 37.6539 23.8225 38.087C24.0997 38.181 24.4002 38.181 24.6774 38.087C25.9836 37.6539 32.5 35.1041 32.5 27.4167V22.375C32.4996 22.0105 32.3546 21.661 32.0968 21.4032C31.839 21.1454 31.4895 21.0004 31.125 21ZM23.5625 25.8125C23.5625 25.6302 23.6349 25.4553 23.7639 25.3264C23.8928 25.1974 24.0677 25.125 24.25 25.125C24.4323 25.125 24.6072 25.1974 24.7361 25.3264C24.8651 25.4553 24.9375 25.6302 24.9375 25.8125V29.25C24.9375 29.4323 24.8651 29.6072 24.7361 29.7361C24.6072 29.8651 24.4323 29.9375 24.25 29.9375C24.0677 29.9375 23.8928 29.8651 23.7639 29.7361C23.6349 29.6072 23.5625 29.4323 23.5625 29.25V25.8125ZM24.25 33.375C24.046 33.375 23.8467 33.3145 23.6771 33.2012C23.5075 33.0879 23.3753 32.9268 23.2972 32.7384C23.2192 32.55 23.1988 32.3426 23.2386 32.1426C23.2784 31.9425 23.3766 31.7588 23.5208 31.6145C23.665 31.4703 23.8488 31.3721 24.0488 31.3323C24.2489 31.2925 24.4562 31.3129 24.6446 31.391C24.8331 31.4691 24.9941 31.6012 25.1075 31.7708C25.2208 31.9404 25.2812 32.1398 25.2812 32.3438C25.2812 32.6173 25.1726 32.8796 24.9792 33.073C24.7858 33.2663 24.5235 33.375 24.25 33.375Z"
          fill="#2238A8"
        />
        <circle cx="33" cy="24" r="6" fill="white" />
        <path
          d="M33 17.875C31.3816 17.8945 29.835 18.5461 28.6906 19.6906C27.5461 20.835 26.8945 22.3816 26.875 24C26.8945 25.6184 27.5461 27.165 28.6906 28.3094C29.835 29.4539 31.3816 30.1055 33 30.125C34.6184 30.1055 36.165 29.4539 37.3094 28.3094C38.4539 27.165 39.1055 25.6184 39.125 24C39.1055 22.3816 38.4539 20.835 37.3094 19.6906C36.165 18.5461 34.6184 17.8945 33 17.875ZM36.5 24.4375H33.4375V27.5H32.5625V24.4375H29.5V23.5625H32.5625V20.5H33.4375V23.5625H36.5V24.4375Z"
          fill="#FF4444"
        />
      </svg>
    </SvgIcon>
  );
};

export default SettingsIcon;
