import { CLEAR_DATA, CLEAR_COMPLETE } from "../commom/commonTypes";
import {
  ADD_USER,
  FETCH_USERS,
  DELETE_USER,
  USER_PROGRESS,
  SET_USER_ERROR,
  SET_USER_LOADER,
  SET_USERS_CURRENT_PAGE,
  SET_USERS_MAX_PAGE_SO_FAR,
  SET_USERS_PER_PAGE,
  CLEAR_COMPLETE_USERS,
  SET_SUCCESS_TOAST,
} from "./userTypes";
const initialState = {
  users: [],
  progress: true,
  error: null,
  loader: false,
  successToast: false,
  numberOfPages: 0,
  numberOfElements: 0,
  usersPerPage: null,
  currentPage: 0,
  maxPageSoFar: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      };
    case SET_USER_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case FETCH_USERS:
      let filterUsers = action.payload.filter(
        (user) => user.role !== "SUPER_ADMIN"
      );
      return {
        ...state,
        users: filterUsers,
        progress: false,
      };
    case ADD_USER:
      return {
        ...state,
        users: [...state.users, action.payload],
        loader: false,
        successToast: true,
      };
    case DELETE_USER:
      return {
        ...state,
        users: state.users.filter((user) => user.id !== action.payload.id),
        loader: false,
      };
    case SET_SUCCESS_TOAST:
      return {
        ...state,
        successToast: action.payload,
      };
    case SET_USER_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loader: false,
      };
    case SET_USERS_PER_PAGE:
      return {
        ...state,
        usersPerPage: action.payload,
      };
    case SET_USERS_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SET_USERS_MAX_PAGE_SO_FAR:
      return {
        ...state,
        maxPageSoFar: action.payload,
      };
    case CLEAR_COMPLETE_USERS:
      return {
        ...state,
        users: [],
        numberOfPages: 0,
        numberOfElements: 0,
        progress: false,
        loader: false,
        error: null,
        successToast: false,
      };
    case CLEAR_COMPLETE:
    case CLEAR_DATA:
      return {
        ...state,
        users: [],
        progress: true,
        error: null,
        loader: false,
        numberOfPages: 0,
        numberOfElements: 0,
        usersPerPage: null,
        currentPage: 0,
        maxPageSoFar: 0,
        successToast: false,
      };
    default:
      return state;
  }
};
export default reducer;
