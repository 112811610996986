import { Button, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import OutlinedButton from "../../../components/buttons/OutlinedButton";
import {
  rowFlexAlignCenter,
  rowFlexCenterAll,
  transparentButton,
} from "../../../styles/common";
import {
  CallMadeRounded,
  CallReceivedRounded,
  LocalPhone,
  WhatsApp,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid #E6E6E6",
    padding: "26px",
    borderRadius: "12px",
    margin: "0 36px 16px",
  },
  name: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "20px",
    marginBottom: 16,
    textAlign: "center",
  },
  icon: {
    width: 14,
  },
  iconContainer: {
    width: 24,
    height: 24,
    borderRadius: 5,
    marginRight: 10,
    backgroundColor: (props) => props.backgroundColor,
  },
  subtxt: {
    fontSize: 14,
    color: "#666666",
    fontWeight: 400,
    "& span": {
      color: "#1A1A1A",
      fontWeight: 600,
    },
    textAlign: "center",
  },
  reminder: {
    fontWeight: "400 !important",
    padding: "0 !important",
    width: "fit-content !important",
    height: "fit-content !important",
    marginTop: 20,
  },
  btnIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid",
    borderRadius: "50%",
    padding: 5,
  },
}));

const TableHeaderCard = ({
  icon,
  backgroundColor,
  value,
  name,
  mobile,
  subText,
  showReminder,
  btn1Txt,
  btn2Txt,
  btn1Action,
  btn2Action,
}) => {
  const classes = useStyles({ backgroundColor });
  return (
    <div className={classes.root}>
      <Typography className={classes.name}>{name}</Typography>
      {mobile && (
        <div className={rowFlexCenterAll} style={{ marginBottom: 18 }}>
          <div className={rowFlexAlignCenter}>
            <LocalPhone fontSize="small" color="secondary" />
            <a href={`tel:${mobile}`}>
              <Typography
                style={{
                  margin: "0 20px 0 5px",
                  fontSize: 14,
                  color: "#1A1A1A",
                }}
              >
                {mobile}
              </Typography>
            </a>
          </div>
          <div className={rowFlexAlignCenter}>
            <WhatsApp htmlColor="#16AC67" fontSize="small" />
            <a
              href={"https://wa.me/91" + mobile}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography
                color="secondary"
                style={{ margin: "0 0 0 5px", fontSize: 14 }}
              >
                WhatsApp
              </Typography>
            </a>
          </div>
        </div>
      )}
      <div className={rowFlexCenterAll}>
        <div className={`${rowFlexCenterAll} ${classes.iconContainer}`}>
          <img
            src={`https://storage.googleapis.com/shy-pub/_static/app-img/metrics/${icon}.png`}
            alt="icon"
            className={classes.icon}
          />
        </div>
        <Typography className={classes.subtxt}>
          {subText} <span>{value}</span>
        </Typography>
      </div>
      {showReminder && (
        <div className={rowFlexCenterAll}>
          <Button className={`${classes.reminder} ${transparentButton}`}>
            Set reminder
          </Button>
        </div>
      )}
      <div className={rowFlexCenterAll} style={{ marginTop: 28 }}>
        <OutlinedButton
          onClick={btn1Action}
          style={{ marginRight: 8, fontWeight: 400 }}
          startIcon={
            <div
              className={classes.btnIcon}
              style={{
                borderColor: "#F0042E",
              }}
            >
              <CallMadeRounded htmlColor="#F0042E" style={{ fontSize: 13 }} />
            </div>
          }
        >
          {btn1Txt}
        </OutlinedButton>
        <OutlinedButton
          onClick={btn2Action}
          style={{ marginLeft: 8, fontWeight: 400 }}
          startIcon={
            <div
              className={classes.btnIcon}
              style={{
                borderColor: "#16AC67",
              }}
            >
              <CallReceivedRounded
                htmlColor="#16AC67"
                style={{ fontSize: 13 }}
              />
            </div>
          }
        >
          {btn2Txt}
        </OutlinedButton>
      </div>
    </div>
  );
};

export default TableHeaderCard;
