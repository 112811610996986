import React from "react";
import {
  DragIndicatorOutlined,
  EditOutlined,
  DeleteForeverOutlined,
} from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getFieldTitle, getFieldValue } from "./ProductHelper";

const useStyles = makeStyles(() => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  flexCenterBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  dragDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  heading: {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 16,
    marginTop: 20,
  },

  productField: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    padding: "10px 10px 10px 10px",
    border: "1px solid #E1E1E1",
    borderRadius: "10px",
    background: "white",
    marginBottom: 16,
    minHeight: 60,
  },

  pointer: {
    cursor: "pointer",
  },

  name: {
    fontWeight: 600,
    fontSize: 14,
  },
  description: {
    fontWeight: 400,
    fontSize: 12,
    color: "#999999",
  },

  fieldType: {
    fontWeight: 400,
    fontSize: 12,
  },
  categoryContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
    marginTop: "10px",
    borderTop: "1px solid #E1E1E1",
    padding: "10px 0px 0px 0px",
  },
  category: {
    height: "28px",
    display: "inline-flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFEFEF",
    border: "1px solid transparent",
    borderRadius: "20px",
    padding: "4px 10px",
  },
  allCategory: {
    height: "28px",
    display: "inline-flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#E9EEFF",
    border: "1px solid #E9EEFF",
    borderRadius: "20px",
    padding: "4px 10px",
  },

  dragIndicator: {
    color: "#E1E1E1", // Normal state color
    "&:hover": {
      color: "#666666", // Hover state color
    },
  },
}));

const ProductFields = ({ fields, onEditField, onDeleteField, onDragEnd }) => {
  const classes = useStyles();

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className={classes.heading} style={{ color: "#1A1A1A" }}>
        {`PRODUCT ${fields[0].group.toUpperCase()} FIELDS`}
      </div>
      {fields[0].group === "CHECKOUT" && (
        <p style={{ marginTop: -10, color: "#666666", fontSize: 12 }}>
          Ask Additional Information from Customer for Product customization.
          Please Note that Max 5 fields will be shown to the customer for a
          given product.
        </p>
      )}
      <Droppable droppableId={fields[0].group} type={fields[0].group}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              ...provided.droppableProps.style,
            }}
          >
            {fields.map((field, index) => (
              <Draggable
                key={field.id}
                draggableId={String(field.id)}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={{
                      ...provided.draggableProps.style,
                      boxShadow: snapshot.isDragging
                        ? "0px 4px 8px rgba(0,0,0,0.2)"
                        : "none",
                    }}
                    className={classes.productField}
                  >
                    <div
                      {...provided.dragHandleProps}
                      className={(classes.dragHandle, classes.dragDiv)}
                    >
                      <DragIndicatorOutlined
                        className={classes.dragIndicator}
                      />
                    </div>

                    <div style={{ width: "100%" }}>
                      <div>
                        <div className={classes.flexCenterBetween}>
                          <div className={classes.name}>{field.name}</div>
                          <div
                            className={classes.flexCenter}
                            style={{ gap: 10 }}
                          >
                            <Tooltip title="Edit" className={classes.pointer}>
                              <span
                                name={field.group}
                                id={field.slug}
                                onClick={onEditField}
                              >
                                <EditOutlined
                                  color="secondary"
                                  fontSize="small"
                                />
                              </span>
                            </Tooltip>
                            <Tooltip title="Delete" className={classes.pointer}>
                              <span
                                name={field.group}
                                id={field.slug}
                                onClick={onDeleteField}
                              >
                                <DeleteForeverOutlined
                                  color="error"
                                  fontSize="small"
                                />
                              </span>
                            </Tooltip>
                          </div>
                        </div>
                        {field.description && (
                          <div className={classes.description}>
                            {field.description}
                          </div>
                        )}
                        {field.group === "CHECKOUT" && (
                          <div className={classes.flexCenter}>
                            <div style={{ flexBasis: "40%" }}>
                              <span className={classes.description}>
                                Field Type :
                              </span>
                              <span className={classes.fieldType}>
                                {` ${getFieldTitle(field.field_type)}`}
                              </span>
                            </div>
                            <div>
                              <span className={classes.description}>
                                {field.field_type === "TEXT"
                                  ? "Character Length"
                                  : field.field_type === "FILE"
                                  ? "File Type"
                                  : "Duration"}{" "}
                                :
                              </span>
                              <span className={classes.fieldType}>
                                {getFieldValue(field)}
                              </span>
                            </div>
                          </div>
                        )}
                        <div className={classes.categoryContainer}>
                          {field.categories?.length > 0 ? (
                            field.categories.map((category) => (
                              <div
                                className={classes.category}
                                key={category.id}
                              >
                                {category.cat_name}
                              </div>
                            ))
                          ) : (
                            <div className={classes.allCategory}>
                              All Categories
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ProductFields;
