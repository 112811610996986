import { makeStyles } from "@material-ui/styles";
export default makeStyles(() => ({
  card: {
    width: 382,
    minHeight: 128,
    borderRadius: 6,
    padding: 16,
    display: "flex",
    marginTop: 24,
    cursor: "pointer",
    textTransform: "none",
    textAlign: "left",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  subSetting: {
    cursor: "pointer",
    marginRight: 30,
    "&:hover": {
      backgroundColor: "#ffffff",
      boxShadow: "none",
    },
    "&:focus": {
      outline: "none !important",
    },
  },
  subSettingText: {
    fontWeight: 400,
    fontSize: 14,
    textAlign: "left",
  },
  subHeading: {
    fontWeight: 600,
    fontSize: 14,
    color: "#1A1A1A",
    paddingTop: 6,
  },
  lightSubHeading: {
    fontWeight: 500,
    fontSize: 14,
    color: "#1A1A1A",
  },
  productCard: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    height: 134,
    borderRadius: 6,
    border: "1px solid",
    marginBottom: 16,
    padding: 8,
    cursor: "pointer",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  radioRoot: {
    padding: 0,
  },
  dividerGrid: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "space-between",
    paddingTop: 12,
    paddingBottom: 18,
    borderBottom: "1px solid #E6E6E6",
  },
  accholder: {
    marginLeft: 4,
    width: 247,
  },
  accnumber: {
    marginLeft: 7,
    width: 250,
  },
  reaccnumber: {
    marginLeft: 8,
    width: 251,
  },
  accifsc: {
    marginLeft: 80,
    width: 266,
  },
  accemail: {
    marginLeft: 77,
    width: "260px",
  },
  accbank: {
    marginLeft: 19,
    width: 276,
  },
  accholderlabel: {
    width: "40%",
  },
  labelinput: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  helperText: {
    fontWeight: 400,
    fontSize: 12,
    color: "#999999",
    paddingTop: 10,
    marginBottom: -4,
  },
  sortBy: {
    marginLeft: 0,
    alignItems: "flex-start",
    width: "100%",
  },
  sortBySelect: {
    marginTop: 12,
    width: "60%",
  },
  bottomLine: {
    paddingTop: 20,
    paddingBottom: 3,
    borderBottom: "1px solid #E6E6E6",
  },
  divider: {
    color: "#EBEBEB",
    marginTop: "8px",
    borderWidth: "1px",
  },
  dividerBox: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "space-between",
    padding: 20,
    border: "1px solid #E6E6E6",
    marginBottom: 16,
    borderRadius: 8,
  },
  primarypoint: {
    "& span": {
      fontWeight: "bold",
    },
  },
  paymentdivider: {
    height: 5,
    backgroundColor: "#F6F6F6",
    marginTop: 20,
  },
  cardBox: {
    padding: 20,
    border: "1px solid #E6E6E6",
    marginBottom: 16,
    borderRadius: 8,
  },
  swatch: {
    width: 24,
    height: 24,
    borderRadius: 6,
    border: "3px solid #fff",
    boxShadow:
      "0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1)",
    cursor: "pointer",
    marginRight: 10,
  },
  popover: {
    position: "absolute",
    top: "calc(100% + 5px)",
    left: 0,
    zIndex: 1,
    // borderRadius: 9,
    // boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
  },
  colorPicker: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #E6E6E6",
    padding: 4,
    borderRadius: 4,
  },
  helperTxt: {
    color: "#666666",
    margin: 2,
    marginLeft: 0,
    fontSize: 12,
  },
  whiteLayover: {
    position: "absolute",
    inset: 0,
    background: "#FFFFFFCC",
  },
  paper: {
    maxHeight: "330px",
  },
  stickyCont: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "12px 0px",
    position: "sticky",
    bottom: 0,
    baxShadow: "0px -1px 4px rgba(0, 0, 0, 0.15)",
    background: "#F6F6F6",
  },
  controlBox: {
    margin: 0,
    marginLeft: 4,
  },
  label: {
    fontWeight: 400,
  },
  clickableHeading: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 500,
    marginBottom: "16px",
    color: "#1641DB",
    cursor: "pointer",
  },
  selectimg: {
    width: 22,
    height: 22,
    marginRight: 8,
    objectFit: "contain",
  },
  menuPaper: {
    maxHeight: 370,
  },
  container: {
    backgroundColor: "#ffffff",
    boxShadow: "0px 8px 20px rgba(0,0,0,0.04)",
    borderRadius: 10,
    padding: 24,
    width: "100%",
    fontSize: 18,
    fontWeight: 700,
    color: "#000000",
  },
  fixedIframe: {
    height: "100%",
    width: "100%",
    border: "none",
  },
  unassignBtn: {
    outline: "none !important",
    color: "#1641DB",
    border: "1px solid #1641DB",
    borderRadius: 24,
    height: 38,
    fontWeight: 600,
    fontSize: 12,
    textTransform: "none",
    minWidth: "7rem",
    whiteSpace: "nowrap",
    boxShadow: "none !important",
    "&:hover": {
      background: "#FFFFFF",
    },
  },
  continueBtn: {
    width: "20%",
    textAlign: "center",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 600,
    textTransform: "none",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    margin: "auto",
  },
}));
