import React, { useEffect } from "react";
import { makeStyles, TextField, Typography } from "@material-ui/core";
import { useState } from "react";
import "react-image-crop/dist/ReactCrop.css";
import { DownArrowIcon } from "../../icons";
import UpArrowIcon from "../../icons/UpArrowIcon";
import Controls from "../controls/Controls";

const useStyles = makeStyles(() => ({
  dividerGrid: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "space-between",
    paddingTop: 20,
    paddingBottom: 20,
    borderBottom: "1px solid #E6E6E6",
    alignItems: "center",
  },
  inputBox: {
    width: "100%",
    height: "40px",
    borderRadius: "4px",
    border: "1px solid #999999",
    padding: "0px 4px",
    outlineColor: "#1641DB",
  },
  flexBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  heading: {
    fontWeight: 700,
    fontSize: 20,
  },
  subHeading: {
    fontWeight: 600,
    fontSize: 14,
    color: "#1A1A1A",
    marginBottom: 10,
  },
  bottomDivider: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingBottom: 10,
    borderBottom: "1px #E1E1E1 solid",
  },
  previewBox: {
    width: "100%",
    background: "white",
    borderRadius: 8,
    border: "1px #E1E1E1 solid",
    padding: 12,
    overflowWrap: "break-word",
  },
  slug: {
    width: "100%",
    color: "#666666",
    fontSize: 12,
    fontWeight: "400",
    letterSpacing: 0.24,
    marginBottom: 5,
  },
  title: {
    color: "#1A0DAB",
    fontSize: 16,
    fontWeight: "600",
    marginBottom: 5,
  },
  description: {
    width: "100%",
    color: "#1A1A1A",
    fontSize: 14,
    marginBottom: 5,
    fontWeight: "400",
  },
  slugTextField: {
    "& .MuiOutlinedInput-input": {
      paddingLeft: "2px !important",
    },
  },
}));

const SeoForm = ({
  openSeoSettingFileld = false,
  previewSubHeading,
  previewPageUrl,
  previewSeoTitle,
  previewSeoDescription,
  seoDescriptionPlaceholder,
  formState,
  handleChange,
  onFocus,
  onBlur,
}) => {
  const classes = useStyles();

  const [openSeoFiled, setOpenSeoFiled] = useState(openSeoSettingFileld);

  useEffect(() => {
    setOpenSeoFiled(openSeoSettingFileld);
  }, [openSeoSettingFileld]);

  return (
    <>
      <div>
        <p className={classes.subHeading}> Preview On Search Engine</p>
        <p style={{ marginTop: -10 }}>{previewSubHeading}</p>
        <div className={classes.previewBox}>
          <div className={classes.slug}>{previewPageUrl}</div>
          <div className={classes.title}>{previewSeoTitle}</div>
          <div className={classes.description}>{previewSeoDescription}</div>
        </div>
        <p style={{ margin: "5px 0px 20px", fontSize: 12 }}>
          You can modify your search listing by updating the SEO settings below.
        </p>
      </div>
      <div style={{ marginTop: 16 }}>
        <div
          style={{ cursor: "pointer" }}
          className={classes.flexBox}
          onClick={() => setOpenSeoFiled(!openSeoFiled)}
        >
          <label
            className={classes.subHeading}
            style={{ cursor: "pointer", margin: 0 }}
          >
            Search Engine Optimization (SEO) Settings
          </label>
          {openSeoFiled ? (
            <UpArrowIcon stroke="#666666" />
          ) : (
            <DownArrowIcon stroke="#666666" />
          )}
        </div>
        {openSeoFiled && (
          <>
            <Controls.Input
              type="text"
              name="slug"
              label="URL Slug *"
              labelFontWeight={400}
              labelPlacement="top"
              value={formState.slug.value}
              maxLength={45}
              startAdornment={"/"}
              onChange={handleChange}
              onBlur={onBlur}
              onFocus={onFocus}
              helperText={
                !formState.slug.error &&
                "Page URLs end with a clear and concise slug. See the full URL in the Google preview."
              }
              error={formState.slug.error}
              extraSpace={formState.slug.error && true}
              className={classes.slugTextField}
            />
            <Controls.Input
              type="text"
              name="seo_title"
              label="SEO Title"
              labelFontWeight={400}
              labelPlacement="top"
              value={formState.seo_title.value}
              maxLength={256}
              onChange={handleChange}
              onFocus={onFocus}
              helperText={
                !formState.seo_title.error &&
                "Improve Search Ranking: Write a clear, powerful title"
              }
              extraSpace={formState.seo_title.error && true}
              error={formState.seo_title.error}
            />
            <div>
              <Typography style={{ marginBottom: 12, fontSize: 14 }}>
                SEO Description
              </Typography>
              <TextField
                name="seo_description"
                color="secondary"
                variant="outlined"
                fullWidth
                value={formState.seo_description.value}
                placeholder={seoDescriptionPlaceholder}
                onChange={handleChange}
                onFocus={onFocus}
                rows="3"
                multiline
                inputProps={{
                  maxLength: 512,
                }}
                helperText={
                  formState.seo_description.error
                    ? formState.seo_description.error
                    : "Write a concise, engaging description with keywords. Optimal page summary: 155-165 characters"
                }
                error={formState.seo_description.error}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SeoForm;
