import { Typography, Button, ClickAwayListener } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import useStyles from "../../../../styles";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import {
  setSuperProdSortBy,
  setSuperProdSearchKeyword,
  setSuperProdInputKeyword,
  setSuperProdMaxPageSoFar,
  setSuperProdCurrentPage,
  setSuperProdPerPage,
  clearSuperProdPlan,
} from "../../../../../../../redux/subscription/superPorduct/superProdActions";
import FormModal from "../../../../../../../components/common/FormModal";
import { DownArrowIcon } from "../../../../../../../icons";
import { rowFlexAlignCenter } from "../../../../../../../styles/common";
import Paginator from "../../../../../../../components/Paginator/Paginator";
import {
  DEFAULT_PRODUCTS_PER_PAGE,
  NEW_SUPER_PRODUCT_PER_PAGE,
  SUPER_PROD_PER_PAGE,
} from "../../../../../../../utils/utils";
import SolidButton from "../../../../../../../components/buttons/SolidButton";
import SuperProductList from "./SuperPorductList";
import { handleFetchSuperProduct } from "../../../../../../../redux/subscription/superPorduct/superProdActions";
import { SelectedItem } from "./SelectedItems";
import { getStoreIdFromUrl } from "../../../../../../../utils/storeUtil";
import OutlinedButton from "../../../../../../../components/buttons/OutlinedButton";

const SelectProduct = ({ setProducts, products }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const state = useSelector((state) => state);
  const optionList = state.superProduct.superProduct;
  const superProdState = useSelector((state) => state.superProduct);
  const numberOfPages = superProdState.numberOfPages;
  const progress = superProdState.progress;
  const subPlansPerPage = superProdState.subPlansPerPage;
  const shortBy = superProdState.shortBy;
  const currentPage = superProdState.currentPage;
  const maxPageSoFar = superProdState.maxPageSoFar;
  const searchKeyword = superProdState.searchKeyword;
  const inputKeyword = superProdState.inputKeyword;
  const [openSort, setOpenSort] = useState(false);
  const productsPerPage =
    state.superProduct.productsPerPage || DEFAULT_PRODUCTS_PER_PAGE;
  const [item, setItem] = useState({ product: [] });
  const [selectedSort, setSelectedSort] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const storeId = getStoreIdFromUrl();
  let startIndex = currentPage * subPlansPerPage;
  let endIndex =
    startIndex + subPlansPerPage < optionList.length
      ? startIndex + subPlansPerPage
      : optionList.length;

  const AddProduct = () => {
    const newProduct = products.products?.map((product) => ({
      sku: product.sku,
      ssku: product.ssku,
    }));

    setOpenModal(true);
    setSelectedProducts(products.products.length > 0 ? newProduct : []);
  };
  useEffect(() => {
    if (optionList.length === 0 || optionList.pageDirty) {
      dispatch(
        handleFetchSuperProduct(
          searchKeyword,
          shortBy,
          currentPage,
          productsPerPage
        )
      );
    }
    // eslint-disable-next-line
  }, [storeId]);
  useEffect(() => {
    if (!subPlansPerPage) {
      dispatch(setSuperProdPerPage(SUPER_PROD_PER_PAGE));
    }
    // eslint-disable-next-line
  }, [subPlansPerPage]);

  const sortCriterias = [
    { display_name: "Price: High to Low", query: "salePrice,desc" },
    { display_name: "Price: Low to High", query: "salePrice,asc" },
    { display_name: "Alphabetically", query: "displayName,asc" },
    { display_name: "Quantity: High to Low", query: "qty,desc" },
    { display_name: "Quantity: Low to High", query: "qty,asc" },
  ];

  const handleCloseModal = () => {
    setSelectedProducts([]);
    setOpenModal(false);
  };

  const onSortChange = (sortQuery) => {
    setSelectedSort(sortQuery);
    dispatch(
      handleFetchSuperProduct(searchKeyword, sortQuery, 0, productsPerPage)
    );
  };

  const handleRecordsPerPageChange = (newProductperPage) => {
    localStorage.setItem(NEW_SUPER_PRODUCT_PER_PAGE, newProductperPage);
    dispatch(clearSuperProdPlan());
    dispatch(setSuperProdCurrentPage(0));
    dispatch(setSuperProdMaxPageSoFar(0));
    dispatch(setSuperProdPerPage(newProductperPage));
    dispatch(
      handleFetchSuperProduct(searchKeyword, shortBy, 0, newProductperPage)
    );
  };

  useEffect(() => {
    handleFetchSuperProduct(
      searchKeyword,
      shortBy,
      currentPage,
      productsPerPage
    );
  }, [searchKeyword, shortBy, currentPage, productsPerPage]);

  const handleSelect = (tableitem, superSku, variants = []) => {
    const variantsArray = Array.isArray(variants) ? variants : [variants];
    setSelectedProducts((prevSelected) => {
      const newSelected = [...prevSelected];
      variantsArray.forEach((variantSku) => {
        const exists = newSelected.some(
          (product) => product.sku === variantSku && product.ssku === superSku
        );
        if (!exists) {
          newSelected.push({
            ssku: superSku,
            sku: variantSku,
            product: tableitem[0],
          });
        }
      });

      return newSelected;
    });
  };

  const handleUnselect = (tableitem, superSku, variants = []) => {
    const variantsArray = Array.isArray(variants) ? variants : [variants];
    setSelectedProducts((prevSelected) => {
      let newSelected = [...prevSelected];
      const exists = newSelected.some(
        (product) =>
          product.sku === tableitem.sku ||
          product.prod_sku === tableitem.prod_sku
      );
      if (!exists) {
        newSelected.push({
          sku: tableitem.sku,
          ssku: superSku,
          product: tableitem,
        });
      }
      const isSuperProduct = variantsArray.length > 0;
      if (isSuperProduct) {
        newSelected = newSelected.filter((product) => {
          const isVariantSku =
            variantsArray.includes(product.sku) ||
            variantsArray.includes(product.prod_sku);
          return !isVariantSku;
        });
      } else {
        newSelected = newSelected.filter(
          (product) =>
            product.sku !== tableitem.sku &&
            product.prod_sku !== tableitem.prod_sku
        );
      }
      return newSelected;
    });
  };

  const onSearch = (keyword) => {
    if (keyword) {
      dispatch(setSuperProdMaxPageSoFar(0));
      dispatch(setSuperProdCurrentPage(0));
      dispatch(handleFetchSuperProduct(keyword, shortBy, 0, 6));
    }
  };

  const onNext = () => {
    if (maxPageSoFar < currentPage + 1) {
      dispatch(
        handleFetchSuperProduct(
          searchKeyword,
          shortBy,
          currentPage + 1,
          subPlansPerPage
        )
      );
      dispatch(
        setSuperProdMaxPageSoFar(
          maxPageSoFar < currentPage + 1 ? currentPage + 1 : maxPageSoFar
        )
      );
      dispatch(setSuperProdCurrentPage(currentPage + 1));
    } else {
      dispatch(setSuperProdCurrentPage(currentPage + 1));
    }
  };

  const onPrevPage = () => {
    dispatch(setSuperProdCurrentPage(currentPage - 1));
  };

  const handleRemove = (removeProduct) => {
    const productSku = removeProduct.sku
      ? removeProduct.sku
      : removeProduct.prod_sku;

    setProducts((prev) => ({
      ...prev,
      products: prev.products.filter((product) => product.sku !== productSku),
    }));
    setItem((prev) => ({
      ...prev,
      product: prev.product.filter((product) => product.sku !== productSku),
    }));
  };

  const handleSelectProduct = () => {
    const combinedProductDtos = selectedProducts
      .filter(({ sku, ssku }) => sku && ssku)
      .map(({ sku, ssku }) => ({ sku, ssku }));
    setProducts((prevFormData) => ({
      ...prevFormData,
      products: Array.from(
        new Set(combinedProductDtos.map(JSON.stringify))
      ).map(JSON.parse),
    }));
    setItem((prevItemData) => {
      const prevProducts = prevItemData.product || [];
      const prevProductMap = new Map(
        prevProducts.map((product) => [product.sku, product])
      );
      const updatedProducts = prevProducts.filter((product) =>
        selectedProducts.some((selected) => selected.sku === product.sku)
      );
      const newProductsToAdd = combinedProductDtos
        .filter(({ sku }) => !prevProductMap.has(sku))
        .map(({ sku, ssku }) => {
          const fullProductDetails = selectedProducts.find(
            (product) => product.sku === sku && product.ssku === ssku
          );

          return { ...fullProductDetails.product, sku, ssku };
        });

      return {
        ...prevItemData,
        product: [...updatedProducts, ...newProductsToAdd],
      };
    });
    setOpenModal(false);
  };

  return (
    <div>
      <Typography className={classes.heading}>Select Products</Typography>
      <Typography className={classes.subHeading}>
        Organize and Effortlessly Add Products to subscription plans
      </Typography>
      <SelectedItem
        item={item}
        onRemove={(removeProduct) => handleRemove(removeProduct)}
        setProducts={setProducts}
        setItem={setItem}
      />

      <OutlinedButton fullWidth={true} onClick={AddProduct}>
        + Add Products
      </OutlinedButton>
      <FormModal
        onClose={handleCloseModal}
        open={openModal}
        heading="Add Products"
        hideBottomStickyButton={true}
        width={800}
        style={{ paddingBottom: "25px" }}
        marginBottom={0}
        scrollFromBelowTop={true}
        overlay={false}
        minHeight={100}
      >
        <div>
          <div className={`${rowFlexAlignCenter} ${classes.productBox}`}>
            <div className={classes.search}>
              <SearchIcon />
              <input
                type="text"
                placeholder="Search by product name, brand or catagory"
                className={classes.searchBox}
                value={inputKeyword}
                onChange={(e) => {
                  dispatch(setSuperProdInputKeyword(e.target.value));
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (inputKeyword.trim() !== "") {
                      dispatch(setSuperProdSearchKeyword(inputKeyword));
                      onSearch(inputKeyword.trim());
                    }
                  }
                  if (e.key === "Escape") {
                    dispatch(setSuperProdInputKeyword(""));
                    dispatch(setSuperProdSearchKeyword(""));
                    dispatch(setSuperProdMaxPageSoFar(0));
                    dispatch(setSuperProdCurrentPage(0));
                    dispatch(
                      handleFetchSuperProduct(
                        "",
                        shortBy,
                        0,
                        SUPER_PROD_PER_PAGE
                      )
                    );
                  }
                }}
              />
              {searchKeyword !== "" && (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    dispatch(setSuperProdSearchKeyword(""));
                    dispatch(setSuperProdInputKeyword(""));
                    dispatch(setSuperProdMaxPageSoFar(0));
                    dispatch(setSuperProdCurrentPage(0));
                    dispatch(
                      handleFetchSuperProduct(
                        "",
                        shortBy,
                        0,
                        SUPER_PROD_PER_PAGE
                      )
                    );
                  }}
                >
                  <CloseIcon fontSize="small" />
                </span>
              )}
            </div>
            <div>
              <ClickAwayListener onClickAway={() => setOpenSort(false)}>
                <div style={{ position: "relative" }}>
                  <Button
                    style={{ minWidth: 88, marginLeft: 10 }}
                    className={classes.selector}
                    onClick={() => setOpenSort(true)}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div style={{ textAlign: "left" }}>
                        <Typography
                          style={{
                            color: "#999999",
                            fontSize: 12,
                            fontWeight: 400,
                            outline: "none",
                          }}
                        >
                          Sort By
                        </Typography>
                      </div>
                      <span>
                        <DownArrowIcon stroke="#1A1A1A" />
                      </span>
                    </div>
                  </Button>
                  {openSort && (
                    <div
                      className={classes.catSheet}
                      style={{ flexDirection: "column", width: 156, left: -8 }}
                    >
                      {sortCriterias.map((filter, i) => (
                        <div
                          style={{
                            padding: "8px 10px",
                            cursor: "pointer",
                          }}
                          className={classes.hover}
                          onClick={() => {
                            setOpenSort(false);
                            onSortChange(filter.query);
                            dispatch(setSuperProdSortBy(filter.query));
                          }}
                          key={i}
                        >
                          <Typography
                            style={{
                              fontSize: 12,
                              fontWeight:
                                selectedSort === filter.query ? 600 : 400,
                            }}
                          >
                            {filter.display_name}
                          </Typography>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </ClickAwayListener>
            </div>
          </div>
          <div>
            <SuperProductList
              optionList={optionList}
              handleSelect={handleSelect}
              handleUnselect={handleUnselect}
              endIndex={endIndex}
              startIndex={startIndex}
              productsPerPage={productsPerPage}
              selectedProducts={selectedProducts}
              progress={progress}
              setSelectedProducts={setSelectedProducts}
              noProductsAvailable={optionList.length === 0}
            />
          </div>
          <div
            style={{
              paddingTop: 10,
              position: "sticky",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 2,
              background: "#ffffff",
            }}
          >
            <Paginator
              currentPage={currentPage}
              totalCount={superProdState.numberOfElements}
              recordsPerPage={productsPerPage}
              onNext={onNext}
              onPrev={onPrevPage}
              marginTop="0px"
              marginLeft="66px"
              total={numberOfPages === 0 ? 1 : numberOfPages}
              changeRecordsPerPage={handleRecordsPerPageChange}
              possibleRecordsPerPage={[
                DEFAULT_PRODUCTS_PER_PAGE,
                20,
                50,
                100,
                200,
              ]}
            />
            <div style={{ padding: "15px 0px 20px 0px", textAlign: "center " }}>
              <SolidButton onClick={handleSelectProduct}>
                Add Products
              </SolidButton>
            </div>
          </div>
        </div>
      </FormModal>
    </div>
  );
};

export default SelectProduct;
