// Importing action types
import {
  SET_COLLECTIONS,
  SET_COLLECTION_TOAST,
  TOGGLE_COLLECTION_LOADING,
} from "./collectionTypes";
import { CLEAR_COMPLETE, CLEAR_DATA } from "../commom/commonTypes";
// Initial state
const initState = {
  loading: true,
  list: [],
  isToast: false,
  message: "",
};
// Defining reducer
const collectionReducer = (state = initState, action) => {
  let clonedState = { ...state, list: [...state.list] };
  switch (action.type) {
    case SET_COLLECTIONS:
      clonedState.list = action.payload;
      clonedState.loaded = true;
      return clonedState;
    case TOGGLE_COLLECTION_LOADING:
      clonedState.loading = action.payload;
      return clonedState;
    case SET_COLLECTION_TOAST:
      clonedState.isToast = action.payload.isToast;
      clonedState.message = action.payload.message;
      return clonedState;
    case CLEAR_COMPLETE:
    case CLEAR_DATA:
      return { loading: false, list: [], isToast: false, message: "" };
    default:
      return clonedState;
  }
};
// Exporting defined reducer as default export
export default collectionReducer;
