import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
  image: {
    height: "125px",
    width: "125px",
  },
  noFound: {
    width: "100%",
    marginTop: "210px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  notFoundText: {
    marginTop: "20px",
    color: "#666666",
    fontSize: 12,
    fontWeight: "400",
  },
});

const ProductNotFound = () => {
  const classes = useStyles();
  return (
    <div className={classes.noFound}>
      <div>
        <img
          className={classes.image}
          src="https://storage.googleapis.com/shy-pub/_static/app-img/undraw-no-data.png"
          alt="NoProducts"
        />
      </div>
      <div className={classes.notFoundText}>No product found</div>
    </div>
  );
};

export default ProductNotFound;
