import React, { useEffect, useState } from "react";
import Form from "./Form/Form";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchSubPlans } from "../../../../../redux/subscription/plans/planActions";
import HeaderBreadcrumbs from "../../../../../components/common/HeaderBreadcrumbs";
import PageLoader from "../../../../../components/Layout/PageLoader";
import { getStoreIdFromUrl } from "../../../../../utils/storeUtil";

const EditSubscriptionPlan = () => {
  const { planId } = useParams();
  const subPlanState = useSelector((state) => state.subPlans);
  const subPlans = subPlanState.subPlans || [];
  const dispatch = useDispatch();
  const storeId = getStoreIdFromUrl();
  const [record, setRecord] = useState([]);
  useEffect(() => {
    if (subPlans.length === 0) {
      dispatch(fetchSubPlans());
    }
  }, [dispatch, storeId]);
  useEffect(() => {
    if (subPlans.length > 0) {
      const productToEdit = subPlans.find((product) => product.id == planId);
      setRecord(productToEdit);
    }
  }, [subPlans, planId]);

  return (
    <div style={{ width: "60%", margin: "auto" }}>
      <div style={{ padding: "20px 0px" }}>
        <HeaderBreadcrumbs
          list={[
            { label: "Plans", link: "/subscription/plans" },
            { label: "Update plan" },
          ]}
        />
      </div>

      {record ? <Form record={record} /> : <PageLoader />}
    </div>
  );
};

export default EditSubscriptionPlan;
