import axios from "axios";
import { getStoreIdFromUrl } from "./storeUtil";

export const validateBulkProduct = async (data, isUpdate) => {
  let accessToken = localStorage.getItem("access_token");
  let storeId = getStoreIdFromUrl();
  let query = "";
  if (isUpdate) {
    query = "for-ingest=false";
  }
  return await axios.post(
    `${process.env.REACT_APP_API}/api/v2/business/store/${storeId}/product/validate-file?${query}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const importBulkProduct = async (data) => {
  let accessToken = localStorage.getItem("access_token");
  let storeId = getStoreIdFromUrl();
  return await axios.post(
    `${process.env.REACT_APP_API}/api/v2/business/store/${storeId}/product/add-by-file-async`,
    data,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const updateBulkProduct = async (data) => {
  let accessToken = localStorage.getItem("access_token");
  let storeId = getStoreIdFromUrl();
  return await axios.post(
    `${process.env.REACT_APP_API}/api/v2/business/store/${storeId}/product/update-by-file-async`,
    data,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const exportBulkProduct = async (
  keyword,
  categories,
  status,
  minQty,
  maxQty
) => {
  let storeId = getStoreIdFromUrl();
  let accessToken = localStorage.getItem("access_token");
  let catParam = "";
  if (categories && categories.length > 0) {
    categories.forEach((cat) => {
      catParam = catParam + `&cat-slugs%5B%5D=${cat}`;
    });
  }
  let statusParam = "";
  if (status === "online") {
    statusParam = "&online-only=true";
  } else if (status === "offline") {
    statusParam = "&offline-only=true";
  }
  let minParam = minQty != null ? `&min-stock-qty=${minQty}` : "";
  let maxParam = maxQty != null ? `&max-stock-qty=${maxQty}` : "";
  return await axios.get(
    `${
      process.env.REACT_APP_API
    }/api/v2/business/store/${storeId}/products/exported-file?${
      keyword && keyword !== "" ? `q=${keyword}&` : ""
    }child-cat-products=true&pages=100${catParam}${statusParam}${minParam}${maxParam}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const startJobCheck = async (jobId, storeId) => {
  let accessToken = localStorage.getItem("access_token");
  let success = false;
  let res = null;
  await new Promise((resolve) => {
    let uploaded = setInterval(async () => {
      res = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/org/stores/${storeId}/jobs/${jobId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      success = res.data.payload.status === "COMPLETED";
      if (!success) {
        if (res.data.payload.status === "FAILED") {
          resolve("reject");
          clearInterval(uploaded);
        }
      } else {
        resolve("accept");
        clearInterval(uploaded);
      }
    }, 6000);
  });
  return success;
};

export const exportProductPdf = async (store, products) => {
  let accessToken = localStorage.getItem("access_token");
  let data = { record_type: "product", record_ids: products };
  return await axios.post(
    `${process.env.REACT_APP_API}/api/v1/org/store/${store.slug}/records/document`,
    data,
    {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${accessToken}`,
      },
      responseType: "blob",
    }
  );
};

export const getGmcConditions = () => [
  { id: "new", title: "New" },
  { id: "refurbished", title: "Refurbished" },
  { id: "used", title: "Used" },
];

export const getGmcGenders = () => [
  { id: "female", title: "Female" },
  { id: "male", title: "Male" },
  { id: "unisex", title: "Unisex" },
];

export const getGmcAgegroups = () => [
  { id: "adult", title: "Adult" },
  { id: "infant", title: "Infant" },
  { id: "kids", title: "Kids" },
  { id: "newborn", title: "Newborn" },
  { id: "toddler", title: "Toddler" },
  { id: "young_adult", title: "Young Adult" },
];

export const gmcFieldsConfig = [
  {
    name: "condition",
    label: "Condition",
    fieldType: "select",
    placeholder: "Optional",
    options: getGmcConditions(),
  },
  {
    name: "gender",
    label: "Gender",
    fieldType: "select",
    placeholder: "Optional",
    options: getGmcGenders(),
  },
  {
    name: "age_group",
    label: "Age Group",
    fieldType: "select",
    placeholder: "Optional",
    options: getGmcAgegroups(),
  },
  {
    name: "mpn",
    label: "MPN",
    fieldType: "input",
    placeholder: "Manufacturer Part Number (Optional)",
  },
  {
    name: "gtin",
    label: "GTIN",
    fieldType: "input",
    placeholder: "Global Trade Item Number (Optional)",
  },
  {
    name: "material",
    label: "Material",
    fieldType: "input",
    placeholder: "Optional",
  },
  {
    name: "pattern",
    label: "Pattern",
    fieldType: "input",
    placeholder: "Optional",
  },
  {
    name: "no_gmc",
    fieldType: "switch",
    label: "Sync to Google"
  },
];
export const generateProductName = (title) => {
  const trimmedTitle = title.trim();
  const lowercased = trimmedTitle.toLowerCase();
  const newName = lowercased
    .replace(/[^\w\s-]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-{2,}/g, "-")
    .substring(0, 50)
    .replace(/-+$/, "");

  return newName;
};
