export const FETCH_BANNERS = "FETCH_BANNERS";
export const BANNER_PROGRESS = "BANNER_PROGRESS";
export const UPDATE_BANNER = "UPDATE_BANNER";
export const SET_BANNER_ERROR = "SET_BANNER_ERROR";
export const SET_BANNER_LOADER = "SET_BANNER_LOADER";
export const ADD_BANNER = "ADD_BANNER";
export const CLEAR_BANNER = "CLEAR_BANNER";
export const CLEAR_COMPLETE_BANNER = "CLEAR_COMPLETE_BANNER";
export const DELETE_BANNER = "DELETE_BANNER";
export const SET_SUCCESS_TOAST = "SET_SUCCESS_TOAST";
export const POSITION_MOVE_UP = "POSITION_MOVE_UP";
export const POSITION_MOVE_TOP = "POSITION_MOVE_TOP";
export const CLEAR_BAANNER_ERROR = "CLEAR_BAANNER_ERROR";
