import React from "react";
import { rowFlexJustifyBetween } from "../../../styles/common";
import useStyles from "../styles";
import OutlinedButton from "../../../components/buttons/OutlinedButton";
import { Checkbox, Tooltip, Typography } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";

const BulkActionsHeader = ({
  orders,
  startIndex,
  endIndex,
  selectedItems,
  setselectedItems,
  btnList,
}) => {
  const classes = useStyles();
  const selectedItemsLength = Object.entries(selectedItems).length;

  const bulkActBtnStyle = {
    height: "32px",
    padding: "8px 0px",
    marginLeft: "20px",
    minWidth: "auto",
    fontSize: "12px",
    borderRadius: "4px",
  };

  return (
    <div
      style={{
        position: "sticky",
        top: 64,
        zIndex: 3,
        background: "#ffffff",
      }}
    >
      <div
        className={rowFlexJustifyBetween}
        style={{
          borderBottom: "1px solid #E6E6E6",
          padding: "10px 16px",
          minHeight: "57.91px",
          boxShadow:
            "0px 3px 4px rgba(0, 0, 0, 0.05), 0px -1px 0px rgba(0, 0, 0, 0.09)",
        }}
      >
        <div>
          <Checkbox
            style={{ padding: 0 }}
            checked={
              Object.entries(selectedItems).length !== 0 &&
              Object.entries(selectedItems).length === endIndex - startIndex
            }
            onChange={(e) => {
              let selected = {};
              orders
                .slice(startIndex, endIndex)
                .forEach((item) => (selected[item.id] = item.display_id));

              if (e.target.checked) {
                setselectedItems(selected);
              } else {
                setselectedItems({});
              }
            }}
          />
          {btnList.map((btn, idx) => {
            return (
              <OutlinedButton
                key={idx}
                style={bulkActBtnStyle}
                startIcon={btn.icon}
                onClick={(e) => btn.onClick(e)}
              >
                {btn.name}
              </OutlinedButton>
            );
          })}
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {selectedItemsLength > 20 ? (
            <div className={classes.shake} style={{ display: "flex" }}>
              <Tooltip
                title="The maximum number of orders that can be selected is 20"
                arrow
                classes={{
                  tooltip: classes.tooltip,
                  arrow: classes.arrow,
                }}
                PopperProps={{
                  disablePortal: true,
                }}
              >
                <InfoOutlined
                  style={{
                    marginRight: 8,
                    fontSize: 20,
                    color: "rgb(244, 67, 54)",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
              <Typography
                style={{
                  fontSize: 12,
                  fontWeight: 600,
                }}
              >
                Max 20 orders can be selected
              </Typography>
            </div>
          ) : (
            <Typography style={{ fontSize: 12, fontWeight: 600 }}>
              {selectedItemsLength}{" "}
              {selectedItemsLength > 1 ? "Orders" : "Order"} Selected
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export default BulkActionsHeader;
