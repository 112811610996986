import React from "react";
import { rowFlexOnlyJustifyBetween } from "../../../styles/common";
import { Typography } from "@material-ui/core";
import useStyles from "./styles";

const ChargeRow = ({ label, amount }) => {
  const classes = useStyles();
  return (
    <div
      className={rowFlexOnlyJustifyBetween}
      style={{
        marginTop: 16,
      }}
    >
      <Typography
        className={classes.secondary}
        style={{ fontSize: 14, lineHeight: "20px" }}
      >
        {label}
      </Typography>
      <Typography className={classes.charge}>
        <span
          style={{
            fontSize: 12,
            lineHeight: "16px",
            fontWeight: 600,
          }}
        >
          ₹
        </span>
        &nbsp;
        <span>{amount}</span>
      </Typography>
    </div>
  );
};

export default ChargeRow;
