// Core react imports
import React, { useEffect, useState } from "react";
// Mui Imports
import { makeStyles, Grid, Paper, Typography, Box } from "@material-ui/core";
// Redux imports
import { useDispatch, useSelector } from "react-redux";
// Custom component imports
import AppCard from "./components/AppCard";
// Action imports
import { fetchApps } from "../../redux/apps/appActions";
// Loader imports
import { layoutContainer, layoutLoaderHeader } from "../../styles/common";
import PageLoader from "../../components/Layout/PageLoader";
import HeaderBreadcrumbs from "../../components/common/HeaderBreadcrumbs";
import { getStoreIdFromUrl } from "../../utils/storeUtil";

const useStyles = makeStyles(() => ({
  searchArea: {
    padding: "24px",
    marginBottom: "24px",
    boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.04);",
    marginTop: 30,
  },
  numApps: {
    color: "#666",
    fontSize: "12px",
    lineHeight: "16px",
    marginBottom: "24px",
  },
  search: {
    display: "flex",
    border: "1px solid #E6E6E6",
    borderRadius: 6,
    padding: "12px",
    paddingTop: 9,
    height: "44px",
    width: 300,
  },
  searchIcon: {
    height: "100%",
  },
  searchBox: {
    outlineWidth: 0,
    lineHeight: "16px",
    padding: "12px",
    border: "none",
    fontSize: "12px",
    width: 390,
  },
  typeFilter: {
    color: "#666",
    fontSize: "14px",
    lineHeight: "16px",
    margin: "0px 15px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  active: {
    color: "#1641DB",
    borderBottom: "4px solid #1641DB",
  },
}));

export default function Index() {
  const classes = useStyles();
  const typeFilters = [
    { label: "All", name: "all" },
    { label: "Online Store", name: "onlinestore" },
    { label: "SEO & Marketing", name: "seo&marketing" },
  ];
  const apps = useSelector((state) => state.apps.apps);
  const dispatch = useDispatch();
  const [filter, setFilter] = useState("all");
  const [localApps, setLocalApps] = useState(apps);
  const storeId = getStoreIdFromUrl();
  useEffect(() => {
    let clonedApps = [...apps];
    clonedApps = clonedApps.filter((app) => {
      if (filter === "all") {
        return ["estimate", "purchase-order"].indexOf(app.slug) === -1;
      } else if (filter === "onlinestore") {
        return ["checkout-fields", "tawkto", "tidio"].indexOf(app.slug) !== -1;
      } else if (filter === "seo&marketing") {
        return (
          ["facebook-pixel", "google-analytics", "seo"].indexOf(app.slug) !== -1
        );
      }
      return true;
    });
    setLocalApps(clonedApps);
  }, [filter, apps]);
  useEffect(() => {
    if (!apps.length) {
      dispatch(fetchApps());
    }
    // eslint-disable-next-line
  }, [apps.length]);

  return (
    <div className={layoutContainer}>
      <div className={layoutLoaderHeader}>
        <HeaderBreadcrumbs list={[{ label: "App Store" }]} />
      </div>
      {apps.length ? (
        <>
          <Paper elevation={0} className={classes.searchArea}>
            <Box display="flex" alignItems="center">
              {typeFilters.map((type) => {
                let classList = [classes.typeFilter];
                if (type.name === filter) {
                  classList.push(classes.active);
                }
                return (
                  <Typography
                    key={type.name}
                    className={classList.join(" ")}
                    display="inline"
                    onClick={() => setFilter(type.name)}
                  >
                    {type.label}
                  </Typography>
                );
              })}
            </Box>
          </Paper>
          <Typography className={classes.numApps}>
            Showing All ({localApps.length} results)
          </Typography>
          <Grid container spacing={5}>
            {localApps.map((app) => (
              <AppCard app={app} key={app.slug} />
            ))}
          </Grid>
        </>
      ) : (
        <PageLoader />
      )}
    </div>
  );
}
