import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const AddImage = ({
  viewBox = "0 0 60 60",
  stroke = "#999999",
  fill = "#999999",
}) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "60px", height: "60px" }}>
      <svg
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.5 8C0.5 3.85786 3.85787 0.5 8 0.5H52C56.1421 0.5 59.5 3.85786 59.5 8V52C59.5 56.1421 56.1421 59.5 52 59.5H8C3.85786 59.5 0.5 56.1421 0.5 52V8Z"
          fill="white"
          stroke="#1641DB"
        />
        <path
          d="M22 30H38"
          stroke="#1641DB"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30 22V38"
          stroke="#1641DB"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default AddImage;
