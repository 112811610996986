import React from "react";

export default function DesktopThreeColumnIcon() {
  return (
    <svg
      width="64"
      height="36"
      viewBox="0 0 64 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="63"
        height="35"
        rx="1.5"
        fill="white"
        stroke="#999999"
      />
      <rect x="3" y="3" width="19" height="18" rx="1" fill="#FF7D7D" />
      <rect
        width="17"
        height="18"
        rx="1"
        transform="matrix(-1 0 0 1 41 3)"
        fill="#FF7D7D"
      />
      <rect
        width="18"
        height="18"
        rx="1"
        transform="matrix(-1 0 0 1 61 3)"
        fill="#FF7D7D"
      />
    </svg>
  );
}
