import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { Fragment } from "react";
import { documentIcons } from "../../../icons/documentIcons";
import CustomerInfoIcon from "../../../icons/CustomerInfoIcon";
import CalendarIcon from "../../../icons/CalendarIcon";
import DownloadOutlineIcon from "../../../icons/DownloadOutlineIcon";
import { MONTHS } from "../../../utils/dateUtils";

const useStyle = makeStyles(() => ({
  checkoutFieldsCtn: {
    border: "1px dashed #E1E1E1",
    borderRadius: 4,
    padding: 10,
    marginTop: 10,
  },
  CustomerInfo: {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #E1E1E1",
    padding: 10,
    margin: -10,
    marginBottom: 10,
  },
  CustomerInfoIcon: {
    marginRight: 10,
    width: 20,
    height: 20,
    color: "#1641DB",
  },
  customerInfoText: {
    display: "block",
    fontSize: 13,
    fontWeight: 600,
  },
  checkoutFieldsRow: {
    display: "flex",
    marginBottom: 6,
    "&:last-child": {
      marginBottom: 0,
    },
  },
  checkoutFieldsCol: {
    fontSize: 14,
  },
  checkoutFieldsCol1: {
    width: "40%",
    color: "#666666",
  },
  checkoutFieldsCol2: {
    width: "60%",
    display: "flex",
    alignItems: "center",
  },
  checkoutFieldImg: {
    height: 18,
    borderRadius: 4,
  },
  date: {
    display: "flex",
    alignItems: "center",
  },
  calendarIcon: {
    height: 17,
    marginLeft: 10,
    color: "#1641DB",
    cursor: "pointer",
  },
}));

export function getFormattedDateString(dateString) {
  const date = new Date(dateString);
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const month = MONTHS[monthIndex];
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedDay = day < 10 ? "0" + day : day;
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
  return `${formattedDay} ${month}, ${formattedHours}:${formattedMinutes} ${ampm}`;
}

const getFormattedOnlyDateString = (dateString) => {
  const date = new Date(dateString);  
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const month = MONTHS[monthIndex];
  const year = date.getFullYear();
  const formattedDay = day < 10 ? "0" + day : day;
  return `${formattedDay} ${month}, ${year}`;
};

const ItemCheckoutFieldsInfo = ({ item, order, getDueAmount }) => {
  const classes = useStyle();
  const checkoutInputFieldCalendarInfo = {
    title: `${order.display_id} - ${item.display_name}`,
    description: `<h3>Details:</h3><table><tr><td>Customer Name:</td><td>${
      order.org_customer_name
    }</td></tr><tr><td>Mobile:</td><td>${
      order.org_customer_mobile
    }</td></tr><tr><td>Address:</td><td><div>${
      order.order_address.customer_name
        ? `${order.order_address.customer_name}, `
        : ""
    }${
      order.order_address.mobile ? `${order.order_address.mobile}` : ""
    }</div><div>${order.order_address.street1}, ${
      order.order_address.street2
    }</div><div>${order.order_address.city}, ${order.order_address.state}, ${
      order.order_address.pincode
    }</div></td></tr></table><br><h3>Order Details:</h3><table><tr><td>OrderId:</td><td>${
      order.display_id
    }</td></tr><tr><td>Order Date:</td><td>${getFormattedDateString(
      order.created_at
    )}</td></tr><tr><td>Due Amount:</td><td>${getDueAmount(
      order
    )}</td></tr></table><br><h3>Product Details:</h3><table><tr><td>Product Name:</td><td>${
      item.display_name
    }</td></tr><tr><td>Product Quantity:</td><td>${
      item.quantity
    }</td></tr><tr><td>Product Price:</td><td>${
      item.sale_price
    }</td></tr><tr><td>Product SKU:</td><td>${
      item.sku
    }</td></tr>${item.checkout_fields
      .map(
        (field) =>
          `<tr><td>${field.name}:</td><td>${(() => {
            if (field.type === "FILE") {
              return `<a href="${field.value}" target="_blank">${field.value}</a>`;
            } else if (field.type === "DATE_TIME") {
              return getFormattedDateString(field.value);
            } else if (field.type === "DATE") {
              return getFormattedOnlyDateString(field.value);
            } else {
              return field.value;
            }
          })()}</td></tr>`
      )
      .join("\n")}</table>`,
  };
  return (
    <Box className={classes.checkoutFieldsCtn}>
      <div className={classes.CustomerInfo}>
        <CustomerInfoIcon className={classes.CustomerInfoIcon} />
        <span className={classes.customerInfoText}>
          Customer Provided Informatioin
        </span>
      </div>
      {item.checkout_fields.map((field, index) => (
        <Box className={classes.checkoutFieldsRow} key={index}>
          <Box
            className={`${classes.checkoutFieldsCol} ${classes.checkoutFieldsCol1}`}
          >
            {field.name}:
          </Box>
          <Box
            className={`${classes.checkoutFieldsCol} ${classes.checkoutFieldsCol2}`}
          >
            {field.type === "FILE" &&
              (field.value ? (
                <div className={classes.date}>
                  <Fragment>
                    <img
                      className={classes.checkoutFieldImg}
                      src={
                        field.value.match(/\.(png|jpeg|jpg)$/)
                          ? field.value
                          : documentIcons(field.value)
                      }
                      alt=""
                    />
                  </Fragment>
                  <a
                    href={field.value}
                    target="_blank"
                    download={field.value}
                    rel="noopener noreferrer"
                  >
                    <DownloadOutlineIcon className={classes.calendarIcon} />
                  </a>
                </div>
              ) : (
                "--"
              ))}
            {field.type === "TEXT" && (field.value ?? "--")}
            {field.type === "DATE_TIME" &&
              (field.value ? (
                <div className={classes.date}>
                  <span>{getFormattedDateString(field.value)}</span>
                  <CalendarIcon
                    className={classes.calendarIcon}
                    onClick={() => {
                      window.open(
                        `https://calendar.google.com/calendar/u/0/r/eventedit?dates=${field.value.replace(
                          /[-:]/g,
                          ""
                        )}/${field.value.replace(
                          /[-:]/g,
                          ""
                        )}&ctz=Asia/Calcutta&text=${encodeURIComponent(
                          checkoutInputFieldCalendarInfo.title
                        )}&location=Indian+Standard+Time&details=${encodeURIComponent(
                          checkoutInputFieldCalendarInfo.description
                        )}`
                      );
                    }}
                  />
                </div>
              ) : (
                "--"
              ))}
            {field.type === "DATE" &&
              (field.value ? (
                <div className={classes.date}>
                  <span>{getFormattedOnlyDateString(field.value)}</span>
                  <CalendarIcon
                    className={classes.calendarIcon}
                    onClick={() => {
                      window.open(
                        `https://calendar.google.com/calendar/u/0/r/eventedit?dates=${field.value.replace(
                          /[-:]/g,
                          ""
                        )}/${field.value.replace(
                          /[-:]/g,
                          ""
                        )}&ctz=Asia/Calcutta&text=${encodeURIComponent(
                          checkoutInputFieldCalendarInfo.title
                        )}&location=Indian+Standard+Time&details=${encodeURIComponent(
                          checkoutInputFieldCalendarInfo.description
                        )}`
                      );
                    }}
                  />
                </div>
              ) : (
                "--"
              ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default ItemCheckoutFieldsInfo;
