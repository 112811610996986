import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const DashboardOrdIcon = ({ viewBox = "0 0 56 56" }) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "56px", height: "56px" }}>
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 12C0 5.37258 5.37258 0 12 0H44C50.6274 0 56 5.37258 56 12V44C56 50.6274 50.6274 56 44 56H12C5.37258 56 0 50.6274 0 44V12Z"
          fill="#FFEED9"
        />
        <path
          d="M18.6934 42.0391C20.2405 42.0391 21.4947 40.7849 21.4947 39.2378C21.4947 37.6907 20.2405 36.4365 18.6934 36.4365C17.1463 36.4365 15.8921 37.6907 15.8921 39.2378C15.8921 40.7849 17.1463 42.0391 18.6934 42.0391Z"
          stroke="#F87231"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.3687 42.0391C38.9158 42.0391 40.17 40.7849 40.17 39.2378C40.17 37.6907 38.9158 36.4365 37.3687 36.4365C35.8216 36.4365 34.5674 37.6907 34.5674 39.2378C34.5674 40.7849 35.8216 42.0391 37.3687 42.0391Z"
          stroke="#F87231"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.7598 17.7607H40.1701L36.435 27.0984H17.7598"
          stroke="#F87231"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.0244 14.0254L17.7595 17.7604V27.0981L14.0244 32.7007H42.0373"
          stroke="#F87231"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default DashboardOrdIcon;
