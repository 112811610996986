import React, { useState, useEffect } from "react";
import { CircularProgress, Typography } from "@material-ui/core";
import FormModal from "../../../../../components/common/FormModal";
import {
  rowFlexCenterAll,
  rowFlexJustifyBetween,
} from "../../../../../styles/common";
import { useStyles } from "../formStyles";
import Controls from "../../../../../components/controls/Controls";
import SearchIcon from "@material-ui/icons/Search";
import OptionCard from "./OptionCard";
export default function SearchableSelect({
  heading,
  subHeading,
  isOpen,
  close,
  selected_items = {},
  ImageFallback = () => <></>,
  fetchData = () => {},
  maxSelectedItem = null,
}) {
  const [selectedItems, setSelectedItems] = useState({});
  const [optionList, setOptionList] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const classes = useStyles();
  const handleDataFetch = async (searchTerm = "") => {
    try {
      setLoading(true);
      let list = await fetchData(searchTerm);
      setOptionList(list);
      setLoading(false);
    } catch (err) {
      console.log({ err });
    }
  };
  const handleSearch = (e) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    let id = setTimeout(() => handleDataFetch(e.target.value), 1000);
    setTimeoutId(id);
    setSearchKey(e.target.value);
  };
  useEffect(() => {
    handleDataFetch("");
    setSelectedItems({ ...selected_items });
    // eslint-disable-next-line
  }, []);

  return (
    <FormModal
      heading={heading}
      open={isOpen}
      width={600}
      maxHeight={70}
      onClose={() => {
        close(Object.keys(selected_items).length > 0 ? selected_items : {});
      }}
      onSave={() => close(selectedItems)}
    >
      <div
        style={{
          position: "sticky",
          top: 57,
          background: "#ffffff",
          zIndex: 2,
        }}
      >
        <div className={rowFlexJustifyBetween}>
          <Typography
            className={classes.linkText}
            style={{ fontWeight: 400, marginBottom: "16px" }}
          >
            {subHeading}
          </Typography>
          <p
            style={{
              fontSize: "12px",
              lineHeight: "16px",
              color: "#1641DB",
              textDecoration: "underline",
              cursor: "pointer",
              marginBottom: "12px",
            }}
            onClick={() => {
              setSelectedItems({});
            }}
          >
            Clear All
          </p>
        </div>
        <Controls.Input
          placeholder="Search by name"
          value={searchKey}
          onChange={handleSearch}
          endAdornment={<SearchIcon />}
        />
      </div>
      {loading && (
        <div
          style={{
            width: "100%",
            height: "133px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      )}
      {optionList.length === 0 && !loading && (
        <div
          style={{
            width: "100%",
            height: "133px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p>No product found</p>
        </div>
      )}
      {!loading &&
        optionList.map((option) => {
          return (
            <OptionCard
              key={option.id}
              option={option}
              ImageFallback={ImageFallback}
              selected={selectedItems.hasOwnProperty(option.id)}
              onChange={(checked, option) => {
                let clonedSelectedItems = { ...selectedItems };
                if (checked) {
                  if (
                    maxSelectedItem &&
                    Object.keys(clonedSelectedItems).length >= maxSelectedItem
                  ) {
                    return;
                  }
                  clonedSelectedItems[option.id] = { ...option };
                } else {
                  delete clonedSelectedItems[option.id];
                }
                setSelectedItems(clonedSelectedItems);
              }}
            />
          );
        })}

      {searchKey === "" && !loading && (
        <div className={rowFlexCenterAll} style={{ marginTop: "10px" }}>
          <Typography
            style={{ fontWeight: 400, fontSize: "12px", color: "#1641DB" }}
          >
            Use Search box above to find more products
          </Typography>
        </div>
      )}
    </FormModal>
  );
}
