import {
  FETCH_CONFIG,
  CONFIG_PROGRESS,
  SET_CONFIG_ERROR,
  UPDATE_CONFIG,
} from "./billConfigTypes";
import axios from "axios";
import { getDefaultStore } from "../../utils/storeUtil";

export const fetchBillConfig = () => async (dispatch) => {
  dispatch({
    type: CONFIG_PROGRESS,
    payload: true,
  });
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  return await axios
    .get(
      `${process.env.REACT_APP_API}/api/v1/org/store/${store.store_id}/billing-config/INVOICE`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .then((response) => {
      dispatch({
        type: FETCH_CONFIG,
        payload: response.data.payload,
      });
      return response.data.payload;
    })
    .catch((error) => {
      dispatch({
        type: SET_CONFIG_ERROR,
        payload: { error: error.message },
      });
    });
};

export const updateBillConfig = (data) => async (dispatch) => {
  dispatch({
    type: CONFIG_PROGRESS,
    payload: true,
  });
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  return await axios
    .post(
      `${process.env.REACT_APP_API}/api/v1/org/store/${store.store_id}/billing-config`,
      data,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .then((response) => {
      dispatch({
        type: UPDATE_CONFIG,
        payload: response.data.payload,
      });
    })
    .catch((error) => {
      dispatch({
        type: SET_CONFIG_ERROR,
        payload: { error: error.message },
      });
    });
};
