import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormModal from "../../../components/common/FormModal";
import Controls from "../../../components/controls/Controls";
import { updateStore } from "../../../redux/store/storeActions";
import useStyles from "../styles";

const AddUpiModal = ({ openAddUpi, setOpenAddUpi }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const store = useSelector((state) => state.store.store);
  const [upi, setUpi] = useState({
    id: store.upi_address ? store.upi_address : "",
    error: false,
  });

  useEffect(() => {
    setUpi({ ...upi, id: store.upi_address ? store.upi_address : "" });
    // eslint-disable-next-line
  }, [store]);

  const onAdd = () => {
    if (upi.id.trim() === "" || !/^[\w.-]+@[\w.-]+$/.test(upi.id.trim())) {
      setUpi({ ...upi, error: true });
      return;
    }
    dispatch(updateStore({ upi_address: upi.id }));
    setOpenAddUpi(false);
  };

  return (
    <FormModal
      open={openAddUpi}
      onClose={() => {
        setOpenAddUpi(false);
        setUpi({ ...upi, id: store.upi_address ? store.upi_address : "" });
      }}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onSave={onAdd}
      btnTxt={store.upi_address ? "Save" : "Add"}
      heading="Add UPI"
    >
      <div
        className={classes.dividerGrid}
        style={{ display: "block", paddingBottom: 2 }}
      >
        <Controls.Input
          name="id"
          label="UPI ID"
          value={upi.id}
          onChange={(e) => {
            setUpi({ ...upi, id: e.target.value });
          }}
          onFocus={() => {
            setUpi({ ...upi, error: false });
          }}
          onBlur={(e) => {
            if (
              e.target.value.trim() === "" ||
              !/^[\w.-]+@[\w.-]+$/.test(upi.id.trim())
            )
              setUpi({ ...upi, error: true });
          }}
          error={upi.error ? "Please enter UPI ID" : ""}
          placeholder="Example: 9999999999@okupi"
          autoFocus={true}
          onEnter={onAdd}
        />
      </div>
    </FormModal>
  );
};

export default AddUpiModal;
