export const colorPickerColors = [
  "#ff0000",
  "#0000ff",
  "#00ff00",
  "#FFFF00",
  "#00FFFF",
  "#00FF00",
  "#808080",
  "#FFA500",
  "#800080",
  "#000000",
  "#FFFFFF",
  "#FFC0CB",
  "#00008B",
];
