// Core react imports
import React, { useEffect, useState } from "react";
// Mui imports
import { Typography, Button } from "@material-ui/core";
// Icon imports
import ReportSuccess from "../../icons/ReportSuccessIcon";
import ReportPending from "../../icons/ReportPending";
import ReportFail from "../../icons/ReportFailIcon";

import InstallIcon from "../../icons/InstallIcon";
// Loader imports
import Lottie from "react-lottie";
import DashedLoader from "../../components/DashedLoader.json";
// Style imports
import useStyles from "./styles";
// Util imports
import { getDefaultStore } from "../../utils/storeUtil";
// Redux imports
import { useDispatch } from "react-redux";
import { updateReport } from "../../redux/reports/reportActions";
// Custom component imports
import Toast from "../../components/Layout/Toast";
let reportTypeMap = {};
function stringifyReportType(type) {
  if (reportTypeMap[type]) {
    return reportTypeMap[type];
  } else {
    reportTypeMap[type] = type.split("_").map((word) => (
      <span style={{ textTransform: "capitalize" }} key={word}>
        {word.toLowerCase()}{" "}
      </span>
    ));
    return reportTypeMap[type];
  }
}

function formatDate(dt){
  if(dt == null) {return ""};
  let dto = new Date(dt);
  return String(dto.getDate()).padStart(2, "0") +
      "/" +
      String(dto.getMonth() + 1).padStart(2, "0") +
      "/" +
      dto.getFullYear();
}
// Exporting default component
export default function SingleReport({ report, noBorder }) {
  const classes = useStyles();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorrMessage, setErrorMessage] = useState("");
  const filterJson = JSON.parse(report.filter_json);
  const dispatch = useDispatch();

  useEffect(() => {
    if (report.status === "Pending") {
      let store = getDefaultStore();
      let accessToken = localStorage.getItem("access_token");
      var interval = setInterval(async () => {
        let apiCallCount = localStorage.getItem(`report_${report.id}`);
        if (apiCallCount) {
          if (apiCallCount >= 10) {
            localStorage.removeItem(`report_${report.id}`);
            return clearInterval(interval);
          }
          let response = await fetch(
            `${process.env.REACT_APP_API}/api/v3/stores/${store.store_id}/report-requests/${report.id}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          let data = await response.json();
          localStorage.setItem(`report_${report.id}`, Number(apiCallCount) + 1);
          if (
            data.payload.status === "Completed" ||
            data.payload.status === "Failed"
          ) {
            localStorage.removeItem(`report_${report.id}`);
            dispatch(updateReport({ ...data.payload, sessionReport: true }));
          }
        }
      }, 10000);
    } else if (report.status === "Completed" && report.sessionReport) {
      setSuccessMessage("Report Generated Successfully");
      dispatch(updateReport({ ...report, sessionReport: false }));
    } else if (report.status === "Failed" && report.sessionReport) {
      setErrorMessage("Failed to Generate Report");
      dispatch(updateReport({ ...report, sessionReport: false }));
    }
    return () => {
      if (interval) {
        window.clearInterval(interval);
      }
    };
    // eslint-disable-next-line
  }, [report]);
  // TODO: Change to using file-download package
  const downloadHandler = () => {
    let A = document.createElement("a");
    A.setAttribute("href", report.web_link);
    A.setAttribute("download", true);
    A.click();
  };
  return (
    <>
      <div
        className={classes.reportBody}
        style={{ borderBottom: noBorder ? "" : "1px solid #E5E5E5" }}
      >
        <Typography
          className={classes.reportBodyText}
          style={{ flexBasis: "25%" }}
        >
          {report.status === "Completed" ? (
            <span style={{ marginRight: "9px" }}>
              <ReportSuccess />
            </span>
          ) : report.status === "Failed" ? (
            <span style={{ marginRight: "9px" }}>
              <ReportFail />
            </span>
          ) : (
            <span style={{ marginRight: "9px" }}>
              <ReportPending />
            </span>
          )}
          {stringifyReportType(report.report_type)}
        </Typography>
        <Typography
          className={classes.reportBodyText}
          style={{ flexBasis: "25%" }}
        >
          {formatDate(filterJson.created_aoe) + " - " + formatDate(filterJson.created_boe)}
        </Typography>
        <Typography
          className={classes.reportBodyText}
          style={{ flexBasis: "30%" }}
        >
          {new Date(report.created_at).toLocaleString()}
        </Typography>
        {report.status === "Completed" ? (
          <Button
            className={classes.downloadBtn}
            startIcon={<InstallIcon />}
            onClick={downloadHandler}
          >
            Download
          </Button>
        ) : report.status === "Pending" ? (
          <Button className={classes.generatingBtn} onClick={() => {}}>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: DashedLoader,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={16}
              width={16}
              style={{
                marginRight: "8px",
                overflow: "visible",
                marginTop: "-10px",
              }}
            />
            Generating
          </Button>
        ) : (
          <Typography className={classes.reportBodyText}>
            <span style={{ color: "#FF4444", fontWeight: "600" }}>
              No Reports available
            </span>
          </Typography>
        )}
      </div>
      <Toast
        open={successMessage.length > 0}
        close={() => setSuccessMessage("")}
        autoClose
        message={successMessage}
      />
      <Toast
        open={errorrMessage.length > 0}
        severity="error"
        close={() => setErrorMessage("")}
        autoClose
        message={errorrMessage}
      />
    </>
  );
}
