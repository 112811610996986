import { getFontStylesCSS } from "../components/Right/hooks/getFontStylesCSS";
import fontFamily from "../assets/FontFamily.json";
import { useEffect, useRef } from "react";

export const useFontFamilyLoader = () => {
  const fontCSS = fontFamily.map((font) => getFontStylesCSS(font)).join("");

  const blobUrlRef = useRef(null);

  useEffect(() => {
    // Create a Blob containing the CSS code
    const blob = new Blob([fontCSS], {
      type: "text/css",
    });

    // Create a URL for the Blob
    const blobUrl = URL.createObjectURL(blob);

    // Create a <link> element and set its href attribute to the Blob URL
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = blobUrl;
    link.id = "font-css-link";

    // Set the blobUrlRef to the created blobUrl
    blobUrlRef.current = blobUrl;

    // Append the link to the document head
    document.head.appendChild(link);

    // Clean up the Blob URL when the component unmounts
    return () => {
      // eslint-disable-next-line no-unused-expressions
      document.getElementById("font-css-link")?.remove();
      if (blobUrlRef?.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
