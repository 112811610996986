import React from "react";
import { Grid } from "@material-ui/core";
import { useStyles } from "./formStyles";
import Controls from "../../../../components/controls/Controls";
export default function FormSectionOne({
  formState,
  onFormFieldChange,
  params,
}) {
  const classes = useStyles();
  return (
    <Grid container spacing={0}>
      <p className={classes.sectionHead}>COUPON DETAILS</p>
      <Grid item xs={12}>
        <Controls.Input
          placeholder="Eg- DEAL50"
          disabled={params.operation !== "create"}
          helperText={formState.code.helpText}
          name="code"
          autoFocus={true}
          label="Coupon Code"
          value={formState.code.value}
          onChange={(e) => {
            let val = e.target.value;
            if (val.length > 16) {
              val = val.slice(0, 16);
            }
            onFormFieldChange(e.target.name, val.toUpperCase());
          }}
          onFocus={() => {}}
          onBlur={() => {}}
          error={formState.code.hasError ? formState.code.error : ""}
          labelPlacement="top"
        />
      </Grid>
      <Grid item xs={6} style={{ paddingRight: "20px" }}>
        <Controls.Input
          type="date"
          helperText={formState.validity.helpText}
          name="validity"
          label="Expiry Date"
          value={formState.validity.value}
          onChange={(e) => onFormFieldChange(e.target.name, e.target.value)}
          onFocus={() => {}}
          onBlur={() => {}}
          error={formState.validity.hasError ? formState.validity.error : ""}
          labelPlacement="top"
        />
      </Grid>
      <Grid item xs={6} style={{ paddingLeft: "20px" }}>
        <Controls.Input
          type="number"
          helperText={formState.usage_per_customer.helpText}
          name="usage_per_customer"
          label="Usage per customer"
          value={formState.usage_per_customer.value}
          onChange={(e) => onFormFieldChange(e.target.name, e.target.value)}
          onFocus={() => {}}
          onBlur={() => {}}
          error={
            formState.usage_per_customer.hasError
              ? formState.usage_per_customer.error
              : ""
          }
          labelPlacement="top"
        />
      </Grid>
      <Grid item xs={12}>
        <Controls.Input
          placeholder="Eg- Get upto 20% off pn 1499 and above. Max discount 1200."
          helperText={formState.description.helpText}
          name="description"
          autoFocus={true}
          label="Description"
          value={formState.description.value}
          onChange={(e) => {
            onFormFieldChange(e.target.name, e.target.value);
          }}
          onFocus={() => {}}
          onBlur={() => {}}
          labelPlacement="top"
          multiline={true}
          maxRows={2}
          minRows={2}
        />
      </Grid>
    </Grid>
  );
}
