import React from "react";
import { Paper, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import useStyles from "../components/ConfigStyles";
import { useDispatch, useSelector } from "react-redux";
import Controls from "../../../components/controls/Controls";
import { useHistory } from "react-router-dom";
import { centerContainer, layoutCenterContainer } from "../../../styles/common";
import HeaderBreadcrumbs from "../../../components/common/HeaderBreadcrumbs";
import PageLoader from "../../../components/Layout/PageLoader";
import Toast from "../../../components/Layout/Toast";
import { fetchApps } from "../../../redux/apps/appActions";

const list = [
  {
    label: "App Store",
    link: "/apps",
  },
  {
    label: "Facebook Domain Verification",
    link: "/apps/facebook-domain-verification",
  },
  {
    label: "Configure",
  },
];

export const FacebookDomConfig = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const store = useSelector((state) => state.store.store);
  const storeData = useSelector((state) => state.store);
  const appState = useSelector((state) => state.apps);
  const apps = appState.apps;

  const initialState = {
    verificationMetaTag: {
      value: "",
      touched: false,
      error: false,
    },
  };
  const [formState, setFormState] = useState(initialState);
  const [fetching, setFetching] = useState(false);
  const [showSuccess, setShowSuccess] = useState(null);
  const [showError, setShowError] = useState(null);

  useEffect(() => {
    if (apps.length === 0) {
      dispatch(fetchApps());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (storeData.fetched) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeData]);

  const fetchData = async () => {
    setFetching(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API}/api/v1/org/stores/${store.slug}/seo-settings/metas/facebook-domain-verification`
      );

      if (!response.ok) {
        throw new Error("Error fetching data. Please try again.");
      }
      const result = await response.json();

      // Check if the value exists and is not empty
      if (result.payload.content) {
        const metaTagWithValue = `<meta name="facebook-domain-verification" content="${result.payload.content}" />`;

        setFormState((prevState) => ({
          ...prevState,
          verificationMetaTag: {
            ...prevState.verificationMetaTag,
            value: metaTagWithValue,
          },
        }));
      }

      setFetching(false);
    } catch (error) {
      setFetching(false);
      setShowError(error.message);
    }
  };

  const handleChange = (event) => {
    let { name, value } = event.target;
    let cloneData = formState[name];
    cloneData.touched = true;
    cloneData.value = value;
    setFormState({ ...formState, [name]: cloneData });
  };

  const onBlur = (event) => {
    const { name } = event.target;
    const cloneData = formState[name];
    if (!cloneData.value)
      cloneData.error =
        name === "verificationMetaTag"
          ? "Please enter a Facebook domain verification meta tag to proceed."
          : "";

    setFormState((prevState) => ({ ...prevState, [name]: cloneData }));
  };

  const onFocus = (event) => {
    const { name } = event.target;
    let cloneData = formState[name];
    cloneData.touched = true;
    cloneData.error = "";
    setFormState((prevState) => ({ ...prevState, [name]: cloneData }));
  };

  const handleOutlined = () => {
    history.goBack();
  };

  const validation = () => {
    let error = false;
    Object.keys(formState).forEach((key) => {
      let cloneData = formState[key];
      if (!cloneData.value) {
        error = true;
        cloneData.error =
          key === "verificationMetaTag"
            ? "Please enter a Facebook domain verification meta tag to proceed."
            : "";
      }
      setFormState({ ...formState, [key]: cloneData });
    });
    return error;
  };

  const handleSave = async () => {
    if (validation()) {
      return;
    }

    const match = formState.verificationMetaTag.value.match(/content="(.*)"/);
    let metaTag = match ? match[1] : formState.verificationMetaTag.value;

    let accessToken = localStorage.getItem("access_token");

    const data = {
      content: metaTag,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API}/api/v1/org/stores/${store.slug}/seo-settings/metas/facebook-domain-verification`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Faild to save meta tag.");
      } else {
        const result = await response.json();

        const metaTag = `<meta name="facebook-domain-verification" content="${result.payload.content}" />`;

        setFormState((prevState) => ({
          ...prevState,
          verificationMetaTag: {
            ...prevState.verificationMetaTag,
            value: metaTag,
          },
        }));

        setShowSuccess(
          "Facebook domian varification meta tag saved succesfully."
        );
      }
    } catch (error) {
      setShowError(error.message);
    }
  };

  if (fetching) return <PageLoader />;

  return (
    <div className={layoutCenterContainer}>
      <div className={centerContainer}>
        <div className={classes.breadcrumbsContainer}>
          <div className={classes.backDiv}>
            <HeaderBreadcrumbs list={list} />
          </div>
        </div>
        <Paper elevation={0} className={classes.paper}>
          <div>
            <Typography className={classes.formHead}>
              Facebook Domain Verification Configuration Settings
            </Typography>
            <Controls.Input
              name="verificationMetaTag"
              label="Facebook Domain Verification Meta Tag"
              labelPlacement="top"
              placeholder={
                'e.g., <meta name="facebook-domain-verification" content="123456789abcdefg" />'
              }
              value={formState.verificationMetaTag.value}
              onChange={handleChange}
              onFocus={onFocus}
              onBlur={onBlur}
              error={formState.verificationMetaTag.error}
              helperText={
                !formState.verificationMetaTag.error &&
                "Enter the complete Meta tag provided by Facebook for domain verification."
              }
            />
          </div>
          <div className={classes.button}>
            <CombinedButtons
              outlinedBtnText="Back"
              outlinedBtnAction={handleOutlined}
              solidBtnAction={handleSave}
            />
          </div>
        </Paper>
        <Toast
          message={showSuccess}
          open={!!showSuccess}
          close={() => setShowSuccess(null)}
          autoClose
        />
        <Toast
          message={showError}
          open={!!showError}
          close={() => setShowError(null)}
          severity="error"
          autoClose
        />
      </div>
    </div>
  );
};
