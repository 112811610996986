export const FETCH_SUB_PLANS = "FETCH_SUB_PLANS";
export const ADD_SUB_PLAN = "ADD_SUB_PLAN";
export const EDIT_SUB_PLAN = "EDIT_SUB_PLAN";
export const DELETE_SUB_PLAN = "DELETE_SUB_PLAN";
export const SUB_PLANS_PROGRESS = "SUB_PLANS_PROGRESS";
export const SET_SUB_PLANS_ERROR = "SET_SUB_PLANS_ERROR";
export const SET_SUB_PLANS_LOADER = "SET_SUB_PLANS_LOADER";
export const CLEAR_SUB_PLAN = "CLEAR_SUB_PLAN";
export const CLEAR_SUB_PLANS = "CLEAR_SUB_PLANS";
export const CLEAR_COMPLETE_SUB_PLANS = "CLEAR_COMPLETE_SUB_PLANS";
export const SET_SUB_PLANS_PER_PAGE = "SET_SUB_PLANS_PER_PAGE";
export const SET_SUB_PLANS_CURRENT_PAGE = "SET_SUB_PLANS_CURRENT_PAGE";
export const SET_SUB_PLANS_MAX_PAGE_SO_FAR = "SET_SUB_PLANS_MAX_PAGE_SO_FAR";
export const SET_SUB_PLANS_INPUT_KEYWORD = "SET_SUB_PLANS_INPUT_KEYWORD";
export const SET_SUB_PLANS_SEARCH_KEYWORD = "SET_SUB_PLANS_SEARCH_KEYWORD";
export const SET_SUCCESS_TOAST = "SET_SUCCESS_TOAST";
export const SET_SUB_PLANS_STATE_FILTER = "SET_SUB_PLANS_STATE_FILTER";
export const DELETE_SUB_PLANS_ERROR = "DELETE_SUB_PLANS_ERROR";
