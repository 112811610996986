import { makeStyles } from "@material-ui/styles";
export default makeStyles((theme) => ({
  tableHeader: {
    display: "flex",
    marginLeft: theme.spacing(3.25),
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(2),
    justifyContent: "space-between",
  },
  search: {
    display: "flex",
    border: "1px solid #E6E6E6",
    borderRadius: 6,
    padding: "12px",
    paddingTop: 9,
    height: "44px",
    maxWidth: 410,
    marginRight: "16px",
    flex: 1,
    minWidth: 0,
  },
  searchIcon: {
    height: "100%",
  },
  searchBox: {
    outlineWidth: 0,
    lineHeight: "16px",
    marginLeft: "12px",
    border: "none",
    fontSize: "12px",
    flex: 1,
    minWidth: 0,
  },
  tablehead: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px -1px 0px #E5E5E5, 0px 1px 0px #E6E6E6",
    borderTop: "1px solid #E6E6E6",
  },
  tableHeaderRight: {
    display: "flex",
    alignItems: "center",
  },

  // style added

  subSetting: {
    cursor: "pointer",
    marginRight: 30,
    "&:hover": {
      backgroundColor: "#ffffff",
      boxShadow: "none",
    },
    "&:focus": {
      outline: "none !important",
    },
  },
  subSettingText: {
    fontWeight: 400,
    fontSize: "14px",
    textAlign: "left",
  },
  divider: {
    color: "#EBEBEB",
    marginTop: "8px",
    borderWidth: "1px",
  },
  stepText: {
    fontSize: "14px",
    marginBottom: "6px",
  },
  stepSubText: {
    fontSize: "12px",
    color: "#666666",
  },
  radioBtnLabel: {
    fontSize: "14px",
    color: "#1A1A1A",
    fontWeight: 400,
  },
  rupee: {
    fontSize: "14px",
    lineHeight: "16px",
  },
  heading: {
    fontSize: "14px",
    fontWeight: 600,
    marginBottom: "18px",
  },
  sectionDivider: {
    color: "#E5E5E5",
    marginBottom: "35px",
    borderWidth: "4px",
  },
  colorPickerLabel: {
    fontWeight: 400,
    fontSize: 14,
    color: "#1A1A1A",
    paddingTop: 6,
  },
  colorPicker: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #0000003b",
    padding: 4,
    borderRadius: 4,
  },
  card: {
    height: "74px",
    borderRadius: "8px",
    padding: "12px",
    display: "flex",
    textAlign: "left",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  sheet: {
    position: "absolute",
    top: 50,
    display: "flex",
    borderRadius: 6,
    background: "#FFFFFF",
    overflow: "hidden",
    zIndex: 1,
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
  },
  bulkAction: {
    fontWeight: 400,
    fontSize: 12,
    borderRadius: 6,
    padding: 9,
    cursor: "pointer",
    "&:hover": {
      background: "#F5F7FC",
    },
    display: "flex",
    alignItems: "center",
  },
  dateText: {
    color: "#999",
    fontSize: "14px",
    lineHeight: "18px",
  },
  upgradeLink: {
    cursor: "pointer",
    color: "#1641DB",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));
