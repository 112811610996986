import { CLEAR_DATA, CLEAR_COMPLETE } from "../commom/commonTypes";
import {
  FETCH_SETTINGS,
  SETTINGS_PROGRESS,
  SET_SETTINGS_ERROR,
  UPDATE_SETTINGS,
} from "./billSettingsTypes";

const initialState = {
  settings: {
    id: null,
    org_id: null,
    store_id: null,
    bill_type: null,
    bill_template: null,
    page_size: null,
    color: null,
    fields_excluded: null,
    enable_discount: false,
    enable_packaging_charge: false,
    enable_round_off: false,
    enable_shipping_charge: false,
    extra_fields: null,
    notes: null,
    tos: null,
  },
  progress: true,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SETTINGS_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      };
    case FETCH_SETTINGS:
      return {
        ...state,
        settings: action.payload,
        progress: false,
      };
    case UPDATE_SETTINGS:
      return {
        ...state,
        settings: action.payload,
        progress: false,
      };
    case SET_SETTINGS_ERROR:
      return {
        ...state,
        error: action.payload.error,
        progress: false,
      };
    case CLEAR_COMPLETE:
    case CLEAR_DATA:
      return {
        ...state,
        settings: {},
        progress: false,
        error: null,
      };
    default:
      return state;
  }
};

export default reducer;
