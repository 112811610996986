import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ProfileIcon = ({ viewBox = "0 0 40 40", size = 40 }) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: size, height: size }}>
      <circle cx="19.9998" cy="20" r="13.3333" fill="#1A1A1A" />
      <path
        d="M20.0002 3.33334C10.8002 3.33334 3.3335 10.8 3.3335 20C3.3335 29.2 10.8002 36.6667 20.0002 36.6667C29.2002 36.6667 36.6668 29.2 36.6668 20C36.6668 10.8 29.2002 3.33334 20.0002 3.33334ZM20.0002 8.33334C22.7668 8.33334 25.0002 10.5667 25.0002 13.3333C25.0002 16.1 22.7668 18.3333 20.0002 18.3333C17.2335 18.3333 15.0002 16.1 15.0002 13.3333C15.0002 10.5667 17.2335 8.33334 20.0002 8.33334ZM20.0002 32C18.0199 32 16.0705 31.51 14.3256 30.5736C12.5808 29.6372 11.0948 28.2835 10.0002 26.6333C10.0502 23.3167 16.6668 21.5 20.0002 21.5C23.3168 21.5 29.9502 23.3167 30.0002 26.6333C28.9055 28.2835 27.4195 29.6372 25.6747 30.5736C23.9299 31.51 21.9804 32 20.0002 32Z"
        fill="#F6F6F6"
      />
    </SvgIcon>
  );
};

export default ProfileIcon;
