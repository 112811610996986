import React from "react";

const AddVectorIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.8 3.2C0.36 3.2 0 3.56 0 4V14.4C0 15.28 0.72 16 1.6 16H12C12.44 16 12.8 15.64 12.8 15.2C12.8 14.76 12.44 14.4 12 14.4H2.4C1.96 14.4 1.6 14.04 1.6 13.6V4C1.6 3.56 1.24 3.2 0.8 3.2ZM14.4 0H4.8C3.92 0 3.2 0.72 3.2 1.6V11.2C3.2 12.08 3.92 12.8 4.8 12.8H14.4C15.28 12.8 16 12.08 16 11.2V1.6C16 0.72 15.28 0 14.4 0ZM12.8 7.2H10.4V9.6C10.4 10.04 10.04 10.4 9.6 10.4C9.16 10.4 8.8 10.04 8.8 9.6V7.2H6.4C5.96 7.2 5.6 6.84 5.6 6.4C5.6 5.96 5.96 5.6 6.4 5.6H8.8V3.2C8.8 2.76 9.16 2.4 9.6 2.4C10.04 2.4 10.4 2.76 10.4 3.2V5.6H12.8C13.24 5.6 13.6 5.96 13.6 6.4C13.6 6.84 13.24 7.2 12.8 7.2Z"
        fill="white"
      />
    </svg>
  );
};

export default AddVectorIcon;
