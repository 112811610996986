import { Typography } from "@material-ui/core";
import { ColorPicker } from "material-ui-color";
import React from "react";

import Controls from "../../../components/controls/Controls";
import useStyles from "../styles";
import { colorPickerColors } from "../../../utils/colorPickerColors";

const ContentAndLayoutSetting = ({ attributes, setAttributes }) => {
  const classes = useStyles();
  const onFormFieldChange = (fieldName, fieldValue) => {
    const clonedState = { ...attributes };
    clonedState[fieldName] = fieldValue;
    setAttributes(clonedState);
  };

  return (
    <div>
      <Typography className={classes.heading}>CONTENT</Typography>
      <Typography className={classes.stepText} style={{ marginBottom: 20 }}>
        <b>Step 1</b>: Commission for Referee
      </Typography>
      <Controls.Input
        name="referredTitle"
        color="secondary"
        variant="outlined"
        type="text"
        label="Title"
        labelFontWeight={400}
        value={attributes.referee_title}
        onChange={(e) => onFormFieldChange("referee_title", e.target.value)}
        labelPlacement="top"
      />
      <Controls.Input
        name="refereeDesc"
        color="secondary"
        variant="outlined"
        type="text"
        label="Description"
        labelFontWeight={400}
        multiline
        rows="3"
        value={attributes.referee_desc}
        onChange={(e) => onFormFieldChange("referee_desc", e.target.value)}
        labelPlacement="top"
      />
      <hr className={classes.divider} />
      <Typography className={classes.stepText} style={{ marginBottom: 20 }}>
        <b>Step 1</b>: Commission for Referred friends
      </Typography>
      <Controls.Input
        name="referredTitle"
        color="secondary"
        variant="outlined"
        type="text"
        label="Title"
        labelFontWeight={400}
        value={attributes.referred_title}
        onChange={(e) => onFormFieldChange("referred_title", e.target.value)}
        labelPlacement="top"
      />
      <Controls.Input
        name="referredDesc"
        color="secondary"
        variant="outlined"
        type="text"
        label="Description"
        labelFontWeight={400}
        multiline
        rows="3"
        value={attributes.referred_desc}
        onChange={(e) => onFormFieldChange("referred_desc", e.target.value)}
        labelPlacement="top"
      />
      <Controls.Input
        color="secondary"
        variant="outlined"
        type="text"
        label="Button Text"
        labelFontWeight={400}
        value={attributes.referred_button_text}
        onChange={(e) =>
          onFormFieldChange("referred_button_text", e.target.value)
        }
        labelPlacement="top"
      />
      <hr className={classes.sectionDivider} />
      <Typography className={classes.heading}>STYLE</Typography>
      <Typography
        className={classes.colorPickerLabel}
        style={{ marginBottom: 10, fontWeight: 400 }}
      >
        Modal Background Color
      </Typography>
      <div className={classes.colorPicker}>
        <ColorPicker
          hideTextfield
          value={attributes.modal_background_color}
          onChange={(value) =>
            onFormFieldChange("modal_background_color", value)
          }
          palette={colorPickerColors}
        />
        <Typography style={{ fontSize: 14, marginLeft: 10 }}>
          {attributes.modal_background_color.css.backgroundColor}
        </Typography>
      </div>
      <Typography
        className={classes.colorPickerLabel}
        style={{ marginTop: 10, marginBottom: 10, fontWeight: 400 }}
      >
        Title Color
      </Typography>
      <div className={classes.colorPicker}>
        <ColorPicker
          hideTextfield
          value={attributes.title_color}
          onChange={(value) => onFormFieldChange("title_color", value)}
          palette={colorPickerColors}
        />
        <Typography style={{ fontSize: 14, marginLeft: 10 }}>
          {attributes.title_color.css.backgroundColor}
        </Typography>
      </div>
      <Typography
        className={classes.colorPickerLabel}
        style={{ marginTop: 10, marginBottom: 10, fontWeight: 400 }}
      >
        Description Color
      </Typography>
      <div className={classes.colorPicker}>
        <ColorPicker
          hideTextfield
          value={attributes.desc_color}
          onChange={(value) => onFormFieldChange("desc_color", value)}
          palette={colorPickerColors}
        />
        <Typography style={{ fontSize: 14, marginLeft: 10 }}>
          {attributes.desc_color.css.backgroundColor}
        </Typography>
      </div>
      <Typography
        className={classes.colorPickerLabel}
        style={{ marginTop: 10, marginBottom: 10, fontWeight: 400 }}
      >
        Button Color
      </Typography>
      <div className={classes.colorPicker}>
        <ColorPicker
          hideTextfield
          value={attributes.button_color}
          onChange={(value) => onFormFieldChange("button_color", value)}
          palette={colorPickerColors}
        />
        <Typography style={{ fontSize: 14, marginLeft: 10 }}>
          {attributes.button_color.css.backgroundColor}
        </Typography>
      </div>
      <Typography
        className={classes.colorPickerLabel}
        style={{ marginTop: 10, marginBottom: 10, fontWeight: 400 }}
      >
        Button Text Color
      </Typography>
      <div className={classes.colorPicker}>
        <ColorPicker
          hideTextfield
          value={attributes.button_text_color}
          onChange={(value) => onFormFieldChange("button_text_color", value)}
          palette={colorPickerColors}
        />
        <Typography style={{ fontSize: 14, marginLeft: 10 }}>
          {attributes.button_text_color.css.backgroundColor}
        </Typography>
      </div>
      <hr className={classes.sectionDivider} style={{ marginTop: 32 }} />
      <Typography className={classes.heading} style={{ marginBottom: 0 }}>
        WIDGET
      </Typography>

      <Controls.RadioGroup
        name="type"
        radioLabelClass={classes.radioBtnLabel}
        value={attributes.widget_type}
        onChange={(e) => onFormFieldChange("widget_type", e.target.value)}
        items={[
          { id: "WITH_ICON", title: "With Icon" },
          { id: "WITHOUT_ICON", title: "Without Icon" },
        ]}
      />
      <Controls.Input
        color="secondary"
        variant="outlined"
        type="text"
        label="Text"
        labelFontWeight={400}
        value={attributes.widget_text}
        onChange={(e) => onFormFieldChange("widget_text", e.target.value)}
        labelPlacement="top"
        helperText={`${attributes.widget_text?.length}/12`}
        maxLength={12}
        FormHelperTextProps={{
          style: { position: "absolute", bottom: -20, right: 0 },
        }}
      />
      <Controls.Select
        name="aligment"
        label="Desktop Position"
        value={attributes.widget_desktop_pos}
        onChange={(e) =>
          onFormFieldChange("widget_desktop_pos", e.target.value)
        }
        options={[
          { title: "Right Center", id: "RIGHT_CENTER" },
          { title: "Left Center", id: "LEFT_CENTER" },
        ]}
        color="secondary"
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        labelPlacement="top"
        labelFontWeight={400}
      />
      <Controls.Select
        name="aligment"
        label="Mobile Position"
        value={attributes.widget_mobile_pos}
        onChange={(e) => onFormFieldChange("widget_mobile_pos", e.target.value)}
        options={[
          { title: "Right Center", id: "RIGHT_CENTER" },
          { title: "Left Center", id: "LEFT_CENTER" },
        ]}
        color="secondary"
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        labelPlacement="top"
        labelFontWeight={400}
      />
    </div>
  );
};

export default ContentAndLayoutSetting;
