import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const RemoveCircleIcon = ({
  viewBox = "0 0 20 20",
  stroke = "#999999",
  fill = "#999999",
  width = "20px",
  height = "20px",
}) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width, height }}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.5 10C0.5 4.7533 4.75329 0.5 10 0.5C15.2467 0.5 19.5 4.7533 19.5 10C19.5 15.2467 15.2467 19.5 10 19.5C4.75329 19.5 0.5 15.2467 0.5 10Z"
          fill="white"
          stroke="#1641DB"
        />
        <path
          d="M7.33331 10H12.6666"
          stroke="#1641DB"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default RemoveCircleIcon;
