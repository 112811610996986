import React from 'react'

export default function facebook() {
    return (
      <svg
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle opacity="0.05" cx="30" cy="30" r="30" fill="#0B31B6" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 30.1117C10 40.055 17.2217 48.3233 26.6667 50V35.555H21.6667V30H26.6667V25.555C26.6667 20.555 29.8883 17.7783 34.445 17.7783C35.8883 17.7783 37.445 18 38.8883 18.2217V23.3333H36.3333C33.8883 23.3333 33.3333 24.555 33.3333 26.1117V30H38.6667L37.7783 35.555H33.3333V50C42.7783 48.3233 50 40.0567 50 30.1117C50 19.05 41 10 30 10C19 10 10 19.05 10 30.1117Z"
          fill="#0B31B6"
        />
      </svg>
    );
}
