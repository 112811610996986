import React from "react";
import { makeStyles } from "@material-ui/styles";
import CategoryArrow from "../../../icons/CategoryArrow";
import NoCategoriesFigma from "../../../icons/NoCategoriesFigma";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
  },
}));

const NoCategory = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div style={{ position: "absolute", right: "13%", top: 30 }}>
        <CategoryArrow />
      </div>
      <div
        style={{
          position: "absolute",
          right: "32%",
          top: 55,
          maxWidth: 372,
          textAlign: "center",
        }}
      >
        <p
          style={{
            fontWeight: 600,
            fontSize: 20,
            lineHeight: "32px",
            color: "#1A1A1A",
          }}
        >
          Start settting up your product categories
        </p>
      </div>
      <div style={{ position: "absolute", right: "31%", top: 210 }}>
        <NoCategoriesFigma />
      </div>
    </div>
  );
};

export default NoCategory;
