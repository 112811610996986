import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useStyles from "../components/ConfigStyles";
import PageLoader from "../../../components/Layout/PageLoader";
import { centerContainer, layoutCenterContainer } from "../../../styles/common";
import HeaderBreadcrumbs from "../../../components/common/HeaderBreadcrumbs";
import { Paper, Typography } from "@material-ui/core";
import Controls from "../../../components/controls/Controls";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import Toast from "../../../components/Layout/Toast";
import { fetchApps } from "../../../redux/apps/appActions";

const list = [
  {
    label: "App Store",
    link: "/apps",
  },
  {
    label: "Google Site Verification",
    link: "/apps/google-site-verification",
  },
  {
    label: "Configure",
  },
];

export const GoogleSiteConfig = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const store = useSelector((state) => state.store.store);
  const storeData = useSelector((state) => state.store);
  const appState = useSelector((state) => state.apps);
  const apps = appState.apps;

  const initialState = {
    verificationMetaTag: {
      value: "",
      touched: false,
      error: false,
    },
  };

  const [formState, setFormState] = useState(initialState);
  const [fetching, setFetching] = useState(false);
  const [showSuccess, setShowSuccess] = useState(null);
  const [showError, setShowError] = useState(null);

  useEffect(() => {
    if (apps.length === 0) {
      dispatch(fetchApps());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (storeData.fetched) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeData]);

  const fetchData = async () => {
    setFetching(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API}/api/v1/org/stores/${store.slug}/seo-settings/metas/google-site-verification`
      );

      if (!response.ok) {
        throw new Error("Error fetching data. Please try again.");
      }

      const result = await response.json();
      setFormState((prevState) => ({
        ...prevState,
        verificationMetaTag: {
          ...prevState.verificationMetaTag,
          value: result.payload.content || "",
        },
      }));

      setFetching(false);
    } catch (error) {
      setFetching(false);
      setShowError(error.message);
    }
  };

  const handleChnage = (event) => {
    let { name, value } = event.target;
    let cloneData = formState[name];
    cloneData.touched = true;
    cloneData.value = value;
    setFormState({ ...formState, [name]: cloneData });
  };

  const onBlur = (event) => {
    const { name } = event.target;
    const cloneData = formState[name];
    if (!cloneData.value)
      cloneData.error =
        name === "verificationMetaTag" &&
        "Please enter a valid Verification Meta Tag";

    setFormState((prevState) => ({ ...prevState, [name]: cloneData }));
  };

  const onFocus = (event) => {
    const { name } = event.target;
    let cloneData = formState[name];
    cloneData.touched = true;
    cloneData.error = "";
    setFormState((prevState) => ({ ...prevState, [name]: cloneData }));
  };

  const validation = () => {
    let errorFlag = false;
    Object.keys(formState).forEach((key) => {
      let cloneData = formState[key];
      if (!cloneData.value) {
        errorFlag = true;
        cloneData.error =
          key === "verificationMetaTag" &&
          "Please enter a valid Verification Meta Tag";
      }
      setFormState({ ...formState, [key]: cloneData });
    });
    return errorFlag;
  };

  const handleOutlined = () => {
    history.goBack();
  };

  const handleSave = async () => {
    if (validation()) {
      return;
    }

    const match = formState.verificationMetaTag.value.match(/content="(.*)"/);
    const metaTag = match ? match[1] : formState.verificationMetaTag.value;

    let accessToken = localStorage.getItem("access_token");

    const data = {
      content: metaTag,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API}/api/v1/org/stores/${store.slug}/seo-settings/metas/google-site-verification`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to save verification meta tag.");
      }

      const result = await response.json();

      if (result.payload.content) {
        setShowSuccess("Verification meta tag is saved successfully.");
      }
    } catch (error) {
      setShowError(error.message);
    }
  };

  if (fetching) return <PageLoader />;

  return (
    <div className={layoutCenterContainer}>
      <div className={centerContainer}>
        <div className={classes.breadcrumbsContainer}>
          <div className={classes.backDiv}>
            <HeaderBreadcrumbs list={list} />
          </div>
        </div>
        <Paper elevation={0} className={classes.paper}>
          <div>
            <Typography className={classes.formHead}>
              Google Site Verification Settings
            </Typography>
            <Controls.Input
              name="verificationMetaTag"
              label="Google Site Verification Meta Tag"
              labelPlacement="top"
              value={formState.verificationMetaTag.value}
              onChange={handleChnage}
              onFocus={onFocus}
              onBlur={onBlur}
              error={formState.verificationMetaTag.error}
            />
          </div>

          <div className={classes.button}>
            <CombinedButtons
              outlinedBtnText="Back"
              outlinedBtnAction={handleOutlined}
              solidBtnAction={handleSave}
            />
          </div>
        </Paper>
      </div>
      <Toast
        message={showSuccess}
        open={!!showSuccess}
        close={() => setShowSuccess(null)}
        autoClose
      />
      <Toast
        message={showError}
        open={!!showError}
        close={() => setShowError(null)}
        severity="error"
        autoClose
      />
    </div>
  );
};
