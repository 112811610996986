import { Typography } from "@material-ui/core";
import React from "react";
import useStyles from "../styles";

const StateElement = ({ state, stateSelector, selector, numElements }) => {
  const classes = useStyles();
  return (
    <div onClick={() => selector(state)} className={classes.stateElement}>
      <Typography
        style={{
          color: stateSelector === state ? "#1641DB" : "#666666",
          fontWeight: stateSelector === state ? 600 : 400,
          cursor: "pointer",
          fontSize: 12,
          borderBottom:
            stateSelector === state ? "4px solid #1641DB" : "0px solid #fff",
        }}
      >
        {stateSelector !== state
          ? state
          : `${state} ${numElements >= 0 ? "(" + numElements + ")" : ""}`}
      </Typography>
    </div>
  );
};

export default StateElement;
