import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const SubscriptionIcon = ({ viewBox = "0 0 20 17", stroke = "#999999" }) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "20px", height: "17px" }}>
      <svg
        width="20"
        height="17"
        viewBox={viewBox}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.64098 13.1L0.754068 2.51444L5.77114 7.16663L6.23267 7.5946L6.54515 7.04822L10 1.00716L13.4549 7.04822L13.7673 7.5946L14.2289 7.16663L19.2459 2.51444L17.359 13.1H2.64098ZM17.2844 15.8C17.2862 15.9914 17.2823 16.1665 17.2565 16.3244C17.241 16.4192 17.2217 16.473 17.209 16.5H2.791C2.77826 16.473 2.75896 16.4192 2.74346 16.3244C2.71766 16.1665 2.71384 15.9914 2.71561 15.8H17.2844Z"
          stroke={stroke}
        />
      </svg>
    </SvgIcon>
  );
};

export default SubscriptionIcon;
