import { AppBar, makeStyles, Toolbar, Typography } from "@material-ui/core";
import React from "react";
import { ShoopyIconLatest } from "../../icons";
import { isMobile } from "react-device-detect";
import { rowFlexAlignCenter, rowFlexJustifyBetween } from "../../styles/common";
import MobileSwitcher from "../Sidebar/components/MobileSwitcher";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    color: "#000000",
  },
  title: {
    flexGrow: 1,
    textAlign: "center",
    fontSize: 20,
    lineHeight: "20px",
    fontWeight: 600,
    marginLeft: 8,
    [theme.breakpoints.up("md")]: {
      fontSize: 24,
      lineHeight: "28px",
    },
  },
  header: {
    color: "#F44B4B",
    [theme.breakpoints.up("md")]: {
      backgroundColor: "#ffffff",
    },
  },
  logo: {
    maxWidth: 40,
    marginRight: "10px",
  },
  toolbar: {
    minHeight: 64,
    [theme.breakpoints.up("md")]: {
      paddingLeft: 32,
      paddingRight: 32,
    },
  },
}));

const ShoopyHeader = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        elevation={0}
        classes={{ colorPrimary: classes.header }}
      >
        <Toolbar className={`${rowFlexJustifyBetween} ${classes.toolbar}`}>
          <div className={rowFlexAlignCenter}>
            <ShoopyIconLatest
              viewBox="0 0 32 32"
              width={isMobile ? 32 : 48}
              height={isMobile ? 32 : 48}
            />
            <Typography className={classes.title}>Shoopy</Typography>
          </div>
          <MobileSwitcher />
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default ShoopyHeader;
