import React from 'react'

const BoldIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
<path d="M9.11575 5.41407C9.96018 4.80752 10.4827 3.79571 10.3562 2.66467C10.1833 1.11898 8.75035 -8.57138e-06 7.16302 0H0.75V0.857143L2.01631 1.47738C2.31275 1.62257 2.5 1.91937 2.5 2.24403V9.75597C2.5 10.0806 2.31275 10.3774 2.01631 10.5226L0.75 11.1429V12H7.59483C9.43571 12 11.0777 10.6736 11.2365 8.87703C11.3733 7.32921 10.4593 5.97247 9.11575 5.41407ZM5.125 1.71429H6C6.96649 1.71429 7.75001 2.48176 7.75001 3.42857C7.75001 4.37533 6.96649 5.14286 6 5.14286H5.125V1.71429ZM6.87501 10.2857H5.125V6.85714H6.87501C7.84149 6.85714 8.62501 7.62462 8.62501 8.57143C8.62501 9.51819 7.84149 10.2857 6.87501 10.2857Z" fill="#999999"/>
</svg>
    )
}

export default BoldIcon
