import React, { Fragment } from "react";
import { Typography } from "@material-ui/core";
import useStyles from "../styles";
import CircleOutlinedIcon from "../../../icons/CircleOutlinedIcon";
import BeautyIcon from "../../../icons/BeautyIcon";
import NoActivityIcon from "../../../icons/NoActivityIcon";
import { clickable } from "../../../styles/common";
import { Skeleton } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchActivities,
  startDashboardLoader,
} from "../../../redux/dashboard/dashboardActions";
import { getStoreIdFromUrl } from "../../../utils/storeUtil";
import { useEffect } from "react";

const link = {
  SETUP_LOGO: "/settings",
  ADD_CATEGORY: "/categories",
  ADD_COUPON: "/coupons/create",
  MIN_PRODUCT: "/products",
  MORE_PRODUCT: "/products",
};
const strokeColor = {
  SETUP_LOGO: "#1641DB",
  ADD_CATEGORY: "#126459",
  ADD_COUPON: "#F87231",
  MIN_PRODUCT: "#6D3F84",
  MORE_PRODUCT: "#B3263F",
};

const Activities = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const storeId = getStoreIdFromUrl();
  const dashboardState = useSelector((state) => state.dashboard);
  const activities = dashboardState.activities;
  const loader = dashboardState.loader;

  useEffect(() => {
    if (activities.length === 0) {
      dispatch(startDashboardLoader());
      dispatch(fetchActivities());
    }
    // eslint-disable-next-line
  }, [storeId]);

  return (
    <div>
      <Typography className={classes.heading}>ToDos</Typography>
      {!loader && activities.length === 0 ? (
        <div
          className={classes.actCard}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <NoActivityIcon />
          <Typography
            style={{
              fontSize: 12,
              fontWeight: 400,
              color: "#7D7D7D",
              marginTop: 20,
            }}
          >
            No recent ToDo for now
          </Typography>
        </div>
      ) : (
        <div className={classes.actCard}>
          {loader &&
            activities.length === 0 &&
            [...Array(3)].map((_, idx) => {
              return (
                <div
                  key={idx}
                  className={classes.actContainer}
                  style={{ justifyContent: "flex-start", marginBottom: 20 }}
                >
                  <Skeleton
                    animation="wave"
                    variant="circle"
                    width={30}
                    height={30}
                  />
                  <div style={{ padding: "0px 12px" }}>
                    <Skeleton
                      animation="wave"
                      width={130}
                      height={20}
                      style={{ marginBottom: 8 }}
                    />
                    <Skeleton animation="wave" width={220} height={15} />
                    <Skeleton animation="wave" width={250} height={15} />
                  </div>
                </div>
              );
            })}
          {!loader &&
            activities.length !== 0 &&
            activities.map((act, index) => {
              return (
                <Fragment key={index}>
                  <div className={classes.actContainer}>
                    <div
                      className={classes.actContainer}
                      style={{ justifyContent: "flex-start" }}
                    >
                      <CircleOutlinedIcon stroke={strokeColor[act.type]} />
                      <div style={{ padding: "0px 12px" }}>
                        <Typography className={classes.actHead}>
                          {act.title}
                        </Typography>
                        <Typography className={classes.actDesc}>
                          {act.description}
                        </Typography>
                      </div>
                    </div>
                    <div
                      className={clickable}
                      onClick={() => {
                        history.push(`/${storeId}${link[act.type]}`);
                      }}
                    >
                      <BeautyIcon />
                    </div>
                  </div>
                  <div className={classes.divider} />
                </Fragment>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default Activities;
