import React, { useState, useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import OrderForm from "./components/OrderForm";
import { fetchOrderById } from "../../utils/orderUtil";
import { useDispatch } from "react-redux";
import { saveOrder, clearOrder } from "../../redux/orders/orderActions";

const EditOrder = () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [record, setRecord] = useState(null);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchOrderById(params.orderid).then((rec) => {
      setRecord(rec);
      setLoading(false);
    });
  }, [params.orderid]);

  const onOrderFormClose = () => {
    history.goBack();
  };

  const onOrderSave = (ordReq) => {
    setLoader(true);
    dispatch(saveOrder(ordReq)).then(() => {
      dispatch(clearOrder());
      setLoader(false);
      history.goBack();
    });
  };
  return (
    <div>
      {loading && (
        <div
          style={{
            position: "fixed",
            left: "50%",
            top: "50%",
            zIndex: "900",
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      )}

      {record && (
        <OrderForm
          record={record}
          headerLabel="Edit Order"
          dateLabel="Invoice Date"
          type="ONLINE"
          loader={loader}
          enableDescount={record && record.order_discount > 0}
          enableCoupon={true}
          onClose={onOrderFormClose}
          onSave={onOrderSave}
          startLabel={{
            label: "Orders",
            link: "/orders",
            nextLink: record?.invoice_id
              ? `/invoices/${record?.invoice_id}`
              : "/orders",
          }}
        />
      )}
    </div>
  );
};

export default EditOrder;
