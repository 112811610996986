import { CLEAR_DATA, CLEAR_COMPLETE } from "../commom/commonTypes";
import {
  CREATE_ORDER,
  FETCH_ORDERS,
  ORDER_PROGRESS,
  UPDATE_ORDER,
  SET_ORDER_ERROR,
  SET_ORDER_LOADER,
  CLEAR_ORDER,
  CLEAR_COMPLETE_ORDERS,
  ORDER_DATE_SELECTOR,
  ORDER_STATE_SELECTOR,
  ORDER_DATE_RANGE,
  SET_ORDERS_PER_PAGE,
  SET_ORDERS_CURRENT_PAGE,
  SET_ORDERS_MAX_PAGE_SO_FAR,
  SET_ORDERS_INPUT_KEYWORD,
  SET_ORDERS_SEARCH_KEYWORD,
  SET_HIGHLIGHT_FALSE,
  SET_ORDERS_CUSTOMER_ID,
  UPDATE_ORDERS,
} from "./orderTypes";

const initialState = {
  orders: [],
  order: null,
  numberOfPages: 0,
  totalCount: -1,
  numberOfElements: 0,
  progress: true,
  loader: false,
  error: null,
  dateSelector: null,
  stateSelector: null,
  dateRange: {
    aoe: null,
    boe: null,
  },
  ordersPerPage: null,
  searchKeyword: "",
  inputKeyword: "",
  currentPage: 0,
  maxPageSoFar: 0,
  customerId: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ORDER_PROGRESS:
      return {
        ...state,
        progress: action.payload,
        totalCount: -1,
      };
    case FETCH_ORDERS:
      let maxOrdersViewed = localStorage.getItem(
        `${action.storeId}_orders_viewed`
      );
      let shouldHighlight = false;
      if (maxOrdersViewed) {
        shouldHighlight = true;
        maxOrdersViewed = Number(maxOrdersViewed);
      }
      let allInvoices = state.orders;
      let page = action.payload.pageable.pageNumber;
      if (page > 0 && state.orders.length > action.ordersPerPage * page) {
        allInvoices = state.orders.slice(0, action.ordersPerPage * page);
      }
      if (page > 0) {
        allInvoices = [...allInvoices, ...action.payload.content];
      } else {
        allInvoices = action.payload.content;
      }

      if (allInvoices.length > 0) {
        if (shouldHighlight) {
          allInvoices.forEach((invoice) => {
            if (invoice.id > maxOrdersViewed) {
              invoice.highlight = true;
            }
          });
        }
        if (maxOrdersViewed < allInvoices[0].id) {
          localStorage.setItem(
            `${action.storeId}_orders_viewed`,
            `${allInvoices[0].id}`
          );
        }
      }

      return {
        ...state,
        orders: allInvoices,
        numberOfPages: action.payload.totalPages,
        numberOfElements: action.payload.totalElements,
        totalCount: action.payload.totalElements,
        progress: false,
      };
    case CREATE_ORDER:
      let pageCnt = state.numberOfPages;
      let localPageOrders = state.orders;
      let count = state.orders.length;
      if (count + 1 > state.ordersPerPage) {
        let fullPages = Math.floor((count + 1) / state.ordersPerPage);
        localPageOrders = state.orders.slice(
          0,
          fullPages * state.ordersPerPage - 1
        );
      }
      let availablePages = Math.floor(
        state.orders.length / state.ordersPerPage
      );
      return {
        ...state,
        order: action.payload,
        loader: false,
        progress: false,
        orders: [action.payload, ...localPageOrders],
        numberOfPages:
          state.numberOfElements === pageCnt * state.ordersPerPage
            ? pageCnt + 1
            : pageCnt,
        numberOfElements: state.numberOfElements + 1,
        currentPage: 0,
        maxPageSoFar: availablePages > 1 ? availablePages - 1 : 0,
      };
    case UPDATE_ORDER:
      let modOrders = state.orders.map((ord) => {
        if (ord.id === action.payload.id) {
          return action.payload;
        }
        return ord;
      });
      return {
        ...state,
        orders: modOrders,
        order: action.payload,
        loader: false,
      };
    case UPDATE_ORDERS:
      let mOrders = action.payload;
      let orderMap = {};
      mOrders.forEach((mord) => {
        orderMap[mord.id] = mord;
      });
      let modOrderss = state.orders.map((ord) => {
        if (orderMap[ord.id]) {
          return orderMap[ord.id];
        }
        return ord;
      });
      return {
        ...state,
        orders: modOrderss,
        loader: false,
      };

    case SET_HIGHLIGHT_FALSE:
      let invoices = state.orders.map((invoice) => {
        if (invoice.id === action.payload) {
          invoice.highlight = false;
        }
        return invoice;
      });
      return {
        ...state,
        orders: invoices,
      };

    case SET_ORDER_ERROR:
      return {
        ...state,
        error: action.payload.error,
        progress: false,
      };
    case SET_ORDER_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case CLEAR_ORDER:
      return {
        ...state,
        order: action.payload,
      };
    case CLEAR_COMPLETE_ORDERS:
      return {
        ...state,
        orders: [],
        order: null,
        numberOfPages: 0,
        numberOfElements: 0,
        totalCount: -1,
        progress: false,
        loader: false,
        error: null,
      };
    case CLEAR_COMPLETE:
    case CLEAR_DATA:
      return {
        ...state,
        orders: [],
        order: null,
        numberOfPages: 0,
        numberOfElements: 0,
        totalCount: -1,
        progress: false,
        loader: false,
        error: null,
        dateSelector: null,
        stateSelector: null,
        dateRange: {
          aoe: null,
          boe: null,
        },
        ordersPerPage: null,
        searchKeyword: "",
        inputKeyword: "",
      };
    case ORDER_DATE_SELECTOR:
      return {
        ...state,
        dateSelector: action.payload,
      };
    case ORDER_STATE_SELECTOR:
      return {
        ...state,
        stateSelector: action.payload,
      };
    case ORDER_DATE_RANGE:
      return {
        ...state,
        dateRange: action.payload,
      };
    case SET_ORDERS_PER_PAGE:
      return {
        ...state,
        ordersPerPage: action.payload,
      };
    case SET_ORDERS_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SET_ORDERS_MAX_PAGE_SO_FAR:
      return {
        ...state,
        maxPageSoFar: action.payload,
      };
    case SET_ORDERS_INPUT_KEYWORD:
      return {
        ...state,
        inputKeyword: action.payload,
      };
    case SET_ORDERS_SEARCH_KEYWORD:
      return {
        ...state,
        searchKeyword: action.payload,
      };
    case SET_ORDERS_CUSTOMER_ID:
      return {
        ...state,
        customerId: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
