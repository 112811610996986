import React from "react";
import { SvgIcon } from "@material-ui/core";

const XIcon = ({ size = 24 }) => {
  return (
    <SvgIcon
      viewBox="0 0 37 36"
      style={{ width: size, height: size }}
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.81 36L22.4149 15.0185L22.4395 15.0382L35.4188 0H31.0815L20.5082 12.24L12.1118 0H0.736515L14.1757 19.589L14.1741 19.5873L0 36H4.33733L16.0923 22.3822L25.4348 36H36.81ZM10.3932 3.27272L30.5904 32.7273H27.1533L6.93977 3.27272H10.3932Z"
        fill="black"
      />
    </SvgIcon>
  );
};

export default XIcon;
