import React from "react";
import { SvgIcon } from "@material-ui/core";

const BusinessDiamondIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.999 2.2917L17.9488 1.95834L17.6141 0L17.3212 2.00002L15.3965 2.2917L17.3212 2.58334L17.6141 4.58336L17.9488 2.62502L19.999 2.2917Z"
      fill="#FF1D1D"
    />
    <path
      d="M3.43073 0L3.34707 0.791678L2.51025 0.916678L3.30521 1.04168L3.43073 1.83332L3.59811 1.04168L4.39311 0.916678L3.55626 0.791678L3.43073 0Z"
      fill="#FF1D1D"
    />
    <path
      d="M2.30159 2.54204L2.0924 1.12537L1.8832 2.54204L0.502441 2.75036L1.8832 3.00036L2.0924 4.41703L2.30159 3.00036L3.76606 2.75036L2.30159 2.54204Z"
      fill="#04113F"
    />
    <path
      d="M13.1379 8.41711L10.5856 3.58374H9.53955L6.69434 8.41711H13.1379Z"
      fill="#8715F4"
    />
    <path
      d="M16.4019 3.58374H10.5859L13.1383 8.41711L16.4019 3.58374Z"
      fill="#D7DAFF"
    />
    <path
      d="M19.9174 8.41711L16.4446 3.58374H16.4028L13.1392 8.41711H19.9174Z"
      fill="#8715F4"
    />
    <path
      d="M9.95794 19.9999L13.1379 8.41656H6.69434L9.95794 19.9999Z"
      fill="#9973EF"
    />
    <path
      d="M9.54024 3.35284H3.51514L6.69503 8.18621L9.54024 3.35284Z"
      fill="#D7DAFF"
    />
    <path
      d="M3.51465 3.58374H3.4728L0 8.41711H6.69455L3.51465 3.58374Z"
      fill="#8715F4"
    />
    <path
      d="M19.9164 8.18591L9.95816 19.7692L0 8.18591H6.69455L9.95816 19.7692L13.1381 8.18591H19.9164Z"
      fill="#D7DAFF"
    />
    <path
      d="M13.1374 7.12537L12.97 8.2087L11.8403 8.37538L12.97 8.54206L13.1374 9.70871L13.3048 8.54206L14.4345 8.37538L13.3048 8.2087L13.1374 7.12537Z"
      fill="white"
    />
    <path
      d="M4.93572 4.70868L4.76838 5.79203L3.63867 5.95867L4.72653 6.12535L4.93572 7.29202L5.1031 6.16703L6.23281 5.95867L5.1031 5.79203L4.93572 4.70868Z"
      fill="white"
    />
  </SvgIcon>
);

export default BusinessDiamondIcon;
