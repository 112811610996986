import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { fetchBillConfig } from "../../redux/billConfig/billConfigActions";
import { fetchBillSettings } from "../../redux/billSettings/billSettingsActions";
import { getStoreIdFromUrl } from "../../utils/storeUtil";
import InvoiceForm from "./components/InvoiceForm";
import { useDispatch } from "react-redux";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";

const CreateInvoice = () => {
  const history = useHistory();
  const storeId = getStoreIdFromUrl();
  const dispatch = useDispatch();

  const [invoicePrefix, setInvoicePrefix] = useState(null);

  useEffect(() => {
    dispatch(fetchBillSettings());
    dispatch(fetchBillConfig()).then((billConfig) => {
      let prefix = billConfig.prefix;
      if (billConfig.year_included) {
        prefix += String(new Date().getUTCFullYear());
      }
      if (billConfig.month_included) {
        prefix += String(new Date().getUTCMonth() + 1);
      }
      if (billConfig.day_included) {
        prefix += String(new Date().getUTCDate());
      }
      setInvoicePrefix(prefix);
    });
    // eslint-disable-next-line
  }, [storeId]);

  const onInvoiceCreate = () => {
    history.goBack();
  };

  const onInvoiceFormClose = () => {
    history.goBack();
  };

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0, transition: { duration: 0.75 } }}
        exit={{ opacity: 0, y: -100, transition: { duration: 0.75 } }}
      >
        <InvoiceForm
          record={null}
          invoicePrefix={invoicePrefix}
          onSave={onInvoiceCreate}
          onClose={onInvoiceFormClose}
        />
      </motion.div>
    </AnimatePresence>
  );
};
export default CreateInvoice;
