import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const AddImageIcon = ({ viewBox = "0 0 43 40", showSmall = false }) => {
  return (
    <SvgIcon
      viewBox={showSmall ? "0 0 32 29" : viewBox}
      style={
        showSmall
          ? { width: "32px", height: "29px" }
          : { width: "43px", height: "40px" }
      }
    >
      {showSmall ? (
        <path
          d="M29.2174 6.90476H24.807L22.2609 4.14286H13.913V6.90476H21.0365L23.5826 9.66667H29.2174V26.2381H6.95652V13.8095H4.17391V26.2381C4.17391 27.7571 5.42609 29 6.95652 29H29.2174C30.7478 29 32 27.7571 32 26.2381V9.66667C32 8.14762 30.7478 6.90476 29.2174 6.90476ZM11.1304 17.9524C11.1304 21.7638 14.247 24.8571 18.087 24.8571C21.927 24.8571 25.0435 21.7638 25.0435 17.9524C25.0435 14.141 21.927 11.0476 18.087 11.0476C14.247 11.0476 11.1304 14.141 11.1304 17.9524ZM18.087 13.8095C20.3826 13.8095 22.2609 15.6738 22.2609 17.9524C22.2609 20.231 20.3826 22.0952 18.087 22.0952C15.7913 22.0952 13.913 20.231 13.913 17.9524C13.913 15.6738 15.7913 13.8095 18.087 13.8095ZM6.95652 6.90476H11.1304V4.14286H6.95652V0H4.17391V4.14286H0V6.90476H4.17391V11.0476H6.95652V6.90476Z"
          fill="#1641DB"
        />
      ) : (
        <path
          d="M39.2609 9.52381H33.3343L29.913 5.71429H18.6957V9.52381H28.2678L31.6891 13.3333H39.2609V36.1905H9.34783V19.0476H5.6087V36.1905C5.6087 38.2857 7.2913 40 9.34783 40H39.2609C41.3174 40 43 38.2857 43 36.1905V13.3333C43 11.2381 41.3174 9.52381 39.2609 9.52381ZM14.9565 24.7619C14.9565 30.019 19.1443 34.2857 24.3043 34.2857C29.4643 34.2857 33.6522 30.019 33.6522 24.7619C33.6522 19.5048 29.4643 15.2381 24.3043 15.2381C19.1443 15.2381 14.9565 19.5048 14.9565 24.7619ZM24.3043 19.0476C27.3891 19.0476 29.913 21.619 29.913 24.7619C29.913 27.9048 27.3891 30.4762 24.3043 30.4762C21.2196 30.4762 18.6957 27.9048 18.6957 24.7619C18.6957 21.619 21.2196 19.0476 24.3043 19.0476ZM9.34783 9.52381H14.9565V5.71429H9.34783V0H5.6087V5.71429H0V9.52381H5.6087V15.2381H9.34783V9.52381Z"
          fill="#1641DB"
        />
      )}
    </SvgIcon>
  );
};
export default AddImageIcon;
