import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { rowFlexAlignCenter } from "../../styles/common";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "10px 24px",
    background: "#E7E7E7",
    borderRadius: 8,
  },
  img: {
    height: 36,
    marginRight: 16,
  },
  text: {
    fontSize: 15,
    fontWeight: 600,
  },
}));

const NoticeBar = ({ imgSrc, text }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={rowFlexAlignCenter}>
        <img src={imgSrc} className={classes.img} alt="disable info" />
        <Typography className={classes.text}>{text}</Typography>
      </div>
    </div>
  );
};

export default NoticeBar;
