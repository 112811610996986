import React, { useState, useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import InvoiceForm from "./components/InvoiceForm";
import { fetchInvoiceById } from "../../utils/invoiceUtil";
import { fetchBillSettings } from "../../redux/billSettings/billSettingsActions";
import { useDispatch } from "react-redux";
import { getStoreIdFromUrl } from "../../utils/storeUtil";

const EditInvoice = () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const storeId = getStoreIdFromUrl();

  const [record, setRecord] = useState(null);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    dispatch(fetchBillSettings());
    // eslint-disable-next-line
  }, [storeId]);

  useEffect(() => {
    fetchInvoiceById(params.invoiceid).then((rec) => {
      setRecord(rec);
      setLoader(false);
    });
  }, [params.invoiceid]);

  const onInvoiceSave = () => {
    history.goBack();
  };

  const onInvoiceFormClose = () => {
    history.goBack();
  };

  return (
    <div>
      {loader && (
        <div
          style={{
            position: "fixed",
            left: "50%",
            top: "50%",
            zIndex: "900",
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      )}

      {record && (
        <InvoiceForm
          record={record}
          onSave={onInvoiceSave}
          onClose={onInvoiceFormClose}
        />
      )}
    </div>
  );
};

export default EditInvoice;
