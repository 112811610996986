import React from "react";
import { Paper, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import useStyles from "../components/ConfigStyles";
import { useDispatch, useSelector } from "react-redux";
import Controls from "../../../components/controls/Controls";
import { useHistory } from "react-router-dom";
import { centerContainer, layoutCenterContainer } from "../../../styles/common";
import HeaderBreadcrumbs from "../../../components/common/HeaderBreadcrumbs";
import PageLoader from "../../../components/Layout/PageLoader";
import Toast from "../../../components/Layout/Toast";
import { fetchApps } from "../../../redux/apps/appActions";

const list = [
  {
    label: "App Store",
    link: "/apps",
  },
  {
    label: "Google Ads Pixel",
    link: "/apps/google-ads-pixel",
  },
  {
    label: "Configure",
  },
];

export const GoogleAdsPixelConfig = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const initialState = {
    googleTagManagerId: {
      name: "ga_ads",
      value: "",
      touched: false,
      error: false,
    },
    conversionLabel: {
      name: "ga_ads_conversion",
      value: "",
      touched: false,
      error: false,
    },
    addCartLabel: {
      name: "ga_ads_cart",
      value: "",
      touched: false,
      error: false,
    },
  };

  const store = useSelector((state) => state.store.store);
  const appState = useSelector((state) => state.apps);
  const apps = appState.apps;

  const [formState, setFormState] = useState(initialState);
  const [fetching, setFetching] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (apps.length === 0) {
      dispatch(fetchApps());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apps.length]);

  useEffect(() => {
    if (store.slug) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  const init = async () => {
    setFetching(true);
    try {
      let accessToken = localStorage.getItem("access_token");
      const res = await fetch(
        `${process.env.REACT_APP_API}/api/v1/org/store/${store.slug}/group-names/pixels/attributes`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const result = await res.json();
      if (result) {
        let cloneData = { ...formState };
        result.payload.forEach((item) => {
          if (item.name === "ga_ads") {
            cloneData.googleTagManagerId.value = item.value;
          }
          if (item.name === "ga_ads_conversion") {
            cloneData.conversionLabel.value = item.value;
          }
          if (item.name === "ga_ads_cart") {
            cloneData.addCartLabel.value = item.value;
          }
        });
        setFormState(cloneData);
        setFetching(false);
      }
    } catch (error) {
      setShowError(true);
      setFetching(false);
    }
  };

  const errorText = (key) => {
    const messages = {
      googleTagManagerId: "GTM ID is required. Please enter a valid GTM ID.",
      conversionLabel:
        "Conversion label is required. Please enter a valid conversion label.",
      addCartLabel:
        "Cart label is required. Please enter a valid add to cart label.",
    };

    return messages[key] || "This field is required.";
  };

  const handleChange = (event) => {
    let { name, value } = event.target;
    let cloneData = formState[name];
    cloneData.touched = true;
    cloneData.value = value;
    setFormState({ ...formState, [name]: cloneData });
  };

  const onFocus = (event) => {
    const { name } = event.target;
    let cloneData = formState[name];
    cloneData.touched = true;
    cloneData.error = "";
    setFormState((prevState) => ({ ...prevState, [name]: cloneData }));
  };

  const onBlur = (event) => {
    const { name } = event.target;
    const cloneData = formState[name];
    if (!cloneData.value) cloneData.error = errorText(name);
    setFormState((prevState) => ({ ...prevState, [name]: cloneData }));
  };

  const validataion = () => {
    let errorFlag = false;
    Object.keys(formState).forEach((key) => {
      let cloneData = formState[key];
      if (!cloneData.value) {
        errorFlag = true;
        cloneData.error = errorText(key);
      }
      setFormState({ ...formState, [key]: cloneData });
    });
    return errorFlag;
  };

  const makePayload = () => {
    let cloneData = { ...formState };
    const regex = /gtag\('config',\s*'([^']+)'\);/;
    if (formState.googleTagManagerId.value.match(regex)) {
      let match = formState.googleTagManagerId.value.match(regex);
      if (match && match.length >= 2) {
        cloneData.googleTagManagerId.value = match[1];
      }
    }

    const regex1 = /'send_to': '([^/]+)\/([^']+)'/;
    if (formState.conversionLabel.value.match(regex1)) {
      let match = formState.conversionLabel.value.match(regex1);
      if (match) {
        const awConversionId = match[1]; // Extracted AW-CONVERSION_ID
        const conversionLabel = match[2]; // Extracted CONVERSION_LABEL
        cloneData.conversionLabel.value = `${awConversionId}/${conversionLabel}`;
      }
    }

    // const regex2 = /'send_to': '([^/]+)\/([^']+)'/;
    if (formState.addCartLabel.value.match(regex1)) {
      let match = formState.addCartLabel.value.match(regex1);
      if (match) {
        // logic for add To cart
        const awConversionId = match[1]; // Extracted AW-CONVERSION_ID
        const conversionLabel = match[2]; // Extracted CONVERSION_LABEL
        cloneData.addCartLabel.value = `${awConversionId}/${conversionLabel}`;
      }
    }

    let data = [];
    Object.keys(cloneData).forEach((key) => {
      data.push({
        name: cloneData[key].name,
        value: cloneData[key].value,
      });
    });
    return data;
  };

  const handleOutlined = () => {
    history.goBack();
  };

  const handleSave = async () => {
    if (validataion()) {
      return;
    }
    const payload = makePayload();
    try {
      let accessToken = localStorage.getItem("access_token");
      const res = await fetch(
        `${process.env.REACT_APP_API}/api/v1/org/store/${store.slug}/group-names/pixels/attributes`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(payload),
        }
      );
      const result = await res.json();
      if (result) {
        setShowSuccess(true);
      }
    } catch (error) {
      setShowError(true);
    }
  };

  if (fetching) return <PageLoader />;

  return (
    <div className={layoutCenterContainer}>
      <div className={centerContainer}>
        <div className={classes.breadcrumbsContainer}>
          <div className={classes.backDiv}>
            <HeaderBreadcrumbs list={list} />
          </div>
        </div>
        <Paper elevation={0} className={classes.paper}>
          <div>
            <Typography className={classes.formHead}>
              Google Tag Manager Configuration Settings
            </Typography>

            <Controls.Input
              name="googleTagManagerId"
              label="Google Tag Manager ID"
              labelPlacement="top"
              placeholder="GTM-XXXXXXX"
              value={formState.googleTagManagerId.value}
              onChange={handleChange}
              onFocus={onFocus}
              onBlur={onBlur}
              error={formState.googleTagManagerId.error}
              extraSpace={formState.googleTagManagerId.error && true}
            />

            <Typography
              style={{
                marginBottom: 10,
              }}
            >
              Event Snippets
            </Typography>

            <Controls.Input
              name="conversionLabel"
              label="Conversion Label"
              labelPlacement="top"
              placeholder="AW-CONVERSION_ID/CONVERSION_LABEL"
              value={formState.conversionLabel.value}
              onChange={handleChange}
              onFocus={onFocus}
              onBlur={onBlur}
              error={formState.conversionLabel.error}
              extraSpace={formState.conversionLabel.error && true}
            />
            <Controls.Input
              name="addCartLabel"
              label="Add to Cart Label"
              labelPlacement="top"
              placeholder="AW-CONVERSION_ID/CONVERSION_LABEL"
              value={formState.addCartLabel.value}
              onChange={handleChange}
              onFocus={onFocus}
              onBlur={onBlur}
              error={formState.addCartLabel.error}
              extraSpace={formState.addCartLabel.error && true}
            />
          </div>

          <div className={classes.button}>
            <CombinedButtons
              outlinedBtnText="Back"
              outlinedBtnAction={handleOutlined}
              solidBtnAction={handleSave}
            />
          </div>
        </Paper>
      </div>
      <Toast
        message={"Successfully saved."}
        open={showSuccess}
        close={() => setShowSuccess(false)}
        autoClose
      />
      <Toast
        message={"Something went wrong."}
        open={showError}
        close={() => setShowError(false)}
        severity="error"
        autoClose
      />
    </div>
  );
};
