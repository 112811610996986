import React from "react";
import NoProductImage from "./NoProductImage";
import ArrowIcon from "../../icons/ArrowIcon";

const NoProduct = () => {
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <div style={{ position: "absolute", right: "13%", top: 30 }}>
        <ArrowIcon />
      </div>
      <div
        style={{
          position: "absolute",
          right: "32%",
          top: 55,
          maxWidth: 372,
          textAlign: "center",
        }}
      >
        <p
          style={{
            fontWeight: 600,
            fontSize: 20,
            lineHeight: "32px",
            color: "#1A1A1A",
          }}
        >
          Start adding your products for your customers
        </p>
      </div>
      <div style={{ position: "absolute", right: "31%", top: 210 }}>
        <NoProductImage />
      </div>
    </div>
  );
};

export default NoProduct;
