import React from "react";
import { cdnUrl } from "../../../../utils/cdnUtils";
import { Button } from "@material-ui/core";
import useStyles from "../../common/styles";

const RAZOPRAY_ICON_URL =
  "https://storage.googleapis.com/shy-pub/static/shoopy-app/rzp.png";

const ConfigurationDetails = ({ sendToRazorpay }) => {
  const classes = useStyles();
  return (
    <div>
      <div style={{ display: "flex" }}>
        <div className={classes.imgctn}>
          <img
            alt="Razorpay"
            width="40px"
            height="40px"
            src={cdnUrl(RAZOPRAY_ICON_URL)}
          />
        </div>
        <div style={{ marginLeft: 16, marginBottom: 16 }}>
          <p className={classes.title}>Razorpay</p>
          <p className={classes.subtitle}>
            Connect your Store with Razorpay Payment Gateway
          </p>
        </div>
      </div>
      <p className={classes.description}>
        Manage all your online store payments with Razorpay and have your own
        brand in front.
      </p>
      <div className={classes.btnctn}>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          className={classes.button}
          onClick={sendToRazorpay}
          fullWidth
        >
          I already have Razorpay
        </Button>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          className={classes.button}
          style={{ color: "#ffffff" }}
          onClick={sendToRazorpay}
          disableElevation
          fullWidth
        >
          Signup with Razorpay
        </Button>
      </div>
      <hr />
      <div>
        <p style={{ fontSize: 16, fontWeight: 600 }}>Features</p>
        <ul>
          <li>100+ Payment Methods supported</li>
          <li>Ready to use integration kits</li>
          <li>Zero setup fee and No annual maintenance fee</li>
          <li>Priority support</li>
          <li>Robust Security and PCI Compliant</li>
          <li>Industry Leading Success Rate</li>
        </ul>
      </div>
    </div>
  );
};

export default ConfigurationDetails;
