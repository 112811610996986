import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import {
  layoutBodyBox,
  layoutContainer,
  layoutSubHeader,
} from "../../styles/common";
import StoreInsights from "./components/StoreInsights";
import Activities from "./components/Activities";
import DashboardStoreLink from "./components/DashboardStoreLink";
import BannerCarousel from "./components/BannerCarousel";
import OnlineStores from "./components/OnlineStores";
import HeaderBreadcrumbs from "../../components/common/HeaderBreadcrumbs";
import { getDefaultStore, getStoreIdFromUrl } from "../../utils/storeUtil";
import { isCustomDomain } from "../../utils/utils";
import WalletBalance from "./components/WalletBalance";

const Dashboard = () => {
  const storeId = getStoreIdFromUrl();

  const [customDomain, setCustomDomain] = useState(false);
  const [showInsightsTodos, setShowInsightsTodos] = useState(true);

  useEffect(() => {
    let storeUser = getDefaultStore();
    if (storeUser && storeUser.role) {
      const showInsights =
        storeUser.role === "SUPER_ADMIN" || storeUser.role === "ADMIN";
      setShowInsightsTodos(showInsights);
    }
    const isCustom = isCustomDomain();
    setCustomDomain(isCustom);
  }, [storeId]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <div className={layoutContainer}>
          <div className={layoutSubHeader} style={{ paddingTop: 7 }}>
            <HeaderBreadcrumbs list={[{ label: "Dashboard" }]} />
          </div>
          <div style={{ position: "relative", width: "100%" }}>
            <div className={layoutBodyBox}>
              <div style={{ padding: "8px 32px 32px " }}>
                <WalletBalance />

                {showInsightsTodos && (
                  <div style={{ marginBottom: 32 }}>
                    <StoreInsights />
                  </div>
                )}
                <Grid container>
                  <Grid item xs={12}>
                    <DashboardStoreLink />
                  </Grid>
                </Grid>
                {!customDomain && (
                  <Grid container spacing={3} style={{ marginTop: 32 }}>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      <BannerCarousel />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      <Activities />
                    </Grid>
                  </Grid>
                )}
              </div>
            </div>
            {!customDomain && (
              <div style={{ marginTop: 20 }}>
                <OnlineStores />
              </div>
            )}
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
