import React from "react";
import { makeStyles, Typography} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    flexBox: {
        display: "flex",
        justifyContent: "space-between",
        alignContent: "space-between",
        width: "100%",
        alignItems: "center",
    },
    subHeading: {
        fontWeight: 600,
        fontSize: 14,
        color: "#1A1A1A",
        paddingTop: 0,
    },
}));

const OrderChargeRow = ({ chargeName, chargeAmount }) => {
  const classes = useStyles();
  return (
    <div
      className={classes.flexBox}
      style={{
        borderBottom: "1px dashed rgb(245,245,245)",
        width: "100%",
        padding: "18px 0px",
      }}
    >
      <Typography
        className={classes.subHeading}
        style={{ fontWeight: "normal", color: "#666666" }}
      >
        {chargeName}
      </Typography>
      <Typography className={classes.subHeading} style={{ color: "#666666" }}>
        ₹ {chargeAmount.toFixed(2)}
      </Typography>
    </div>
  );
};

export default OrderChargeRow;
