import { makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import FormModal from "../../../components/common/FormModal";
import Controls from "../../../components/controls/Controls";
import { postWalletTransaction } from "../../../utils/customerUtils";
import { toLocaleISOString } from "../../../utils/dateUtils";

const useStyles = makeStyles((theme) => ({
  subtxt: {
    fontSize: 14,
    color: "#666666",
    fontWeight: 400,
    "& span": {
      color: "#1A1A1A",
      fontWeight: 600,
    },
    position: "absolute",
    top: -16,
  },
  dividerGrid: {
    paddingTop: 20,
    paddingBottom: 2,
    borderBottom: "1px solid #E6E6E6",
  },
  radioLabel: {
    marginRight: 24,
  },
}));

const WalletTransactionModal = ({
  transactionModalType,
  name,
  walletPoints,
  onClose,
  customerId,
  saveRecordCallback,
}) => {
  const classes = useStyles();
  const [invDateError, setInvDateError] = useState(false);
  const [invDateFocus, setInvDateFocus] = useState(false);
  const [error, setError] = useState("");
  const [paymentData, setPaymentData] = useState({
    points: "",
    tx_date: new Date().toISOString(),
    description: "",
    type: "ISSUED",
  });
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    if (transactionModalType)
      setPaymentData({ ...paymentData, type: transactionModalType });
    // eslint-disable-next-line
  }, [transactionModalType]);

  const close = () => {
    onClose();
    setPaymentData({
      points: "",
      tx_date: new Date().toISOString(),
      description: "",
      type: "ISSUED",
    });
    setLoading(false);
    setError("");
    setInvDateError("");
  };

  const onSave = () => {
    if (!paymentData.points.trim()) {
      setError("Enter the points");
      return;
    }
    if (!paymentData.tx_date) {
      setInvDateError(true);
      return;
    }
    setLoading(true);
    postWalletTransaction(customerId, {
      ...paymentData,
      status: "COMPLETED",
    })
      .then((res) => {
        saveRecordCallback(res.data.payload);
        close();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <FormModal
      open={!!transactionModalType}
      heading={name}
      onSave={onSave}
      btnTxt={
        paymentData.type === "ISSUED" ? "Issue Points" : "Withdraw Points"
      }
      btnStyle={{ width: 160 }}
      onClose={close}
      loading={loading}
    >
      <div style={{ position: "relative", paddingBottom: 12 }}>
        <Typography className={classes.subtxt}>
          Wallet Points - <span>{walletPoints}</span>
        </Typography>
      </div>

      <Controls.RadioGroup
        name="type"
        label=""
        value={paymentData.type}
        labelClass={classes.label}
        onChange={(e) => {
          setPaymentData({
            ...paymentData,
            type: e.target.value,
          });
          if (
            e.target.value === "WITHDREW" &&
            walletPoints < parseFloat(paymentData.points)
          ) {
            setError("Withdraw points can't be more than available points");
            return;
          } else if (error) setError("");
        }}
        items={[
          { id: "ISSUED", title: "Issue" },
          { id: "WITHDREW", title: "Withdraw" },
        ]}
        radioLabelClass={classes.radioLabel}
      />
      <div className={classes.dividerGrid}>
        <Controls.Input
          name="points"
          label="Points"
          value={paymentData.points}
          onChange={(e) => {
            setPaymentData({ ...paymentData, points: e.target.value });
            if (
              paymentData.type === "WITHDREW" &&
              walletPoints < parseFloat(e.target.value)
            ) {
              setError("Withdraw points can't be more than available points");
              return;
            }
            if (error) setError("");
          }}
          onFocus={() => {
            setError("");
          }}
          onBlur={() => {
            if (paymentData.points.trim() === "") {
              setError("Enter the points");
            }
          }}
          error={error}
          type="number"
          placeholder="0"
          step="0.1"
          onEnter={() => {
            if (paymentData.points.trim() === "") {
              setError("Enter the points");
              return;
            }
            onSave();
          }}
          autoFocus
          preventDecimal
        />
      </div>
      <div className={classes.dividerGrid}>
        <Controls.Input
          name="notes"
          label="Notes"
          value={paymentData.description}
          onChange={(e) => {
            setPaymentData({
              ...paymentData,
              description: e.target.value,
            });
          }}
          type="text"
          onEnter={onSave}
          maxLength={256}
        />
      </div>
      <div className={classes.dividerGrid}>
        <Controls.Input
          InputProps={{
            defaultValue: toLocaleISOString(new Date(paymentData.tx_date)),
          }}
          inputProps={{
            style: {
              color: invDateFocus ? "#1A1A1A" : "#999999",
              fontSize: 12,
            },
          }}
          type="date"
          label={`${paymentData.type === "ISSUED" ? "Issue" : "Withdraw"} Date`}
          onFocus={() => {
            setInvDateError(false);
            setInvDateFocus(true);
          }}
          onChange={() => {
            if (invDateError) setInvDateError(false);
          }}
          onBlur={(e) => {
            if (Date.parse(e.target.value)) {
              setPaymentData({
                ...paymentData,
                tx_date: new Date(e.target.value).toISOString(),
              });
            } else {
              setInvDateError(true);
            }
            setInvDateFocus(false);
          }}
          onEnter={(e) => {
            if (Date.parse(e.target.value)) {
              setPaymentData({
                ...paymentData,
                tx_date: new Date(e.target.value).toISOString(),
              });
            } else {
              setInvDateError(true);
              return;
            }
            setInvDateFocus(false);
            onSave();
          }}
          error={invDateError ? "Enter Valid Date" : ""}
        />
      </div>
    </FormModal>
  );
};

export default WalletTransactionModal;
