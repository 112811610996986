import axios from "axios";
import { getDefaultStore, getStoreIdFromUrl } from "../../../utils/storeUtil";
import { CONTRACTS_PER_PAGE } from "../../../utils/utils";
import {
  ADD_CONTRACT,
  CLEAR_COMPLETE_CONTRACTS,
  CLEAR_CONTRACT,
  CLEAR_CONTRACTS,
  CONTRACTS_PROGRESS,
  DELETE_CONTRACT,
  EDIT_CONTRACT,
  FETCH_CONTRACTS,
  SET_CONTRACTS_CURRENT_PAGE,
  SET_CONTRACTS_ERROR,
  SET_CONTRACTS_INPUT_KEYWORD,
  SET_CONTRACTS_LOADER,
  SET_CONTRACTS_MAX_PAGE_SO_FAR,
  SET_CONTRACTS_PER_PAGE,
  SET_CONTRACTS_SEARCH_KEYWORD,
  SET_CONTRACTS_STATE_FILTER,
  SET_SUCCESS_TOAST,
} from "./contractTypes";

export const fetchContracts =
  (page = 0, keyword = null, state = "All", size = CONTRACTS_PER_PAGE) =>
  (dispatch) => {
    dispatch({
      type: CONTRACTS_PROGRESS,
      payload: true,
    });
    let accessToken = localStorage.getItem("access_token");
    let store = getDefaultStore();
    axios
      .get(
        `${process.env.REACT_APP_API}/api/v1/business/store/${
          store.store_id
        }/subscriptions/contracts?page=${page}&size=${size}&sort=createdAt,desc${
          keyword ? `&query=${keyword}` : ""
        }${state ? `&status=${state}` : ""}`,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: FETCH_CONTRACTS,
          payload: response.data.payload,
          contractsPerPage: size,
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_CONTRACTS_ERROR,
          payload: { error: error.message },
        });
      });
  };

export const clearContract = () => (dispatch) => {
  dispatch({ type: CLEAR_CONTRACT, payload: null });
};

export const addContract = (contractData) => async (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  let data = contractData;
  // data.org_id = store.org_id;
  dispatch({
    type: SET_CONTRACTS_LOADER,
    payload: true,
  });
  return await axios
    .post(
      `${process.env.REACT_APP_API}/api/v2/org/store/${store.store_id}/customers`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return dispatch({
        type: ADD_CONTRACT,
        payload: response.data.payload,
      });
    })
    .catch((error) => {
      dispatch({
        type: SET_CONTRACTS_ERROR,
        payload: { error: error.message },
      });
    });
};

export const editContract = (contractData) => async (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  let data = contractData;
  dispatch({
    type: SET_CONTRACTS_LOADER,
    payload: true,
  });

  return await axios
    .put(
      `${process.env.REACT_APP_API}/api/v2/org/store/${store.store_id}/customers/${data.id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return dispatch({
        type: EDIT_CONTRACT,
        payload: response.data.payload,
      });
    })
    .catch((error) => {
      dispatch({
        type: SET_CONTRACTS_ERROR,
        payload: { error: error.message },
      });
    });
};

export const deleteContract =
  (contractId, contractsPerPage) => async (dispatch) => {
    let accessToken = localStorage.getItem("access_token");
    let storeId = getStoreIdFromUrl();
    return await axios
      .delete(
        `${process.env.REACT_APP_API}/api/v2/org/store/${storeId}/customers/${contractId}`,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        return dispatch({
          type: DELETE_CONTRACT,
          payload: contractId,
          contractsPerPage,
        });
      });
  };

export const clearContracts = () => (dispatch) => {
  dispatch({
    type: CLEAR_COMPLETE_CONTRACTS,
  });
};

export const startContractsLoader = () => (dispatch) => {
  dispatch({
    type: SET_CONTRACTS_LOADER,
    payload: true,
  });
};

export const stopContractsLoader = () => (dispatch) => {
  dispatch({
    type: SET_CONTRACTS_LOADER,
    payload: false,
  });
};

export const setContractsPerPage = (data) => (dispatch) => {
  dispatch({
    type: SET_CONTRACTS_PER_PAGE,
    payload: data,
  });
};

export const setContractsCurrentPage = (data) => (dispatch) => {
  dispatch({
    type: SET_CONTRACTS_CURRENT_PAGE,
    payload: data,
  });
};

export const setContractsMaxPageSoFar = (data) => (dispatch) => {
  dispatch({
    type: SET_CONTRACTS_MAX_PAGE_SO_FAR,
    payload: data,
  });
};

export const setContractsInputKeyword = (data) => (dispatch) => {
  dispatch({
    type: SET_CONTRACTS_INPUT_KEYWORD,
    payload: data,
  });
};

export const setContractsSearchKeyword = (data) => (dispatch) => {
  dispatch({
    type: SET_CONTRACTS_SEARCH_KEYWORD,
    payload: data,
  });
};

export const setSuccessToast = (data) => (dispatch) => {
  dispatch({
    type: SET_SUCCESS_TOAST,
    payload: data,
  });
};

export const setContractsStateFilter = (data) => (dispatch) => {
  dispatch({
    type: SET_CONTRACTS_STATE_FILTER,
    payload: data,
  });
};
