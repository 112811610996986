import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import HeaderBreadcrumbs from "../../../../components/common/HeaderBreadcrumbs";
import SubscriptionDetails from "./components/SubscriptionDetails";
import UpcomingOrders from "./components/UpcomingOrders";
import { useParams } from "react-router-dom";
import CustomerInfo from "./components/CustomerInfo";
import { getContractById } from "../contractUtils";
import PageLoader from "../../../../components/Layout/PageLoader";

const ContractDetails = () => {
  const classes = useStyles();
  const params = useParams();

  const [contractDetails, setContractDetails] = useState(null);

  useEffect(() => {
    if (params.contractId) {
      getContractById(params.contractId).then((res) => {
        setContractDetails(res);
      });
    }
  }, [params.contractId]);
  if (!contractDetails) {
    return <PageLoader />;
  }
  return (
    <>
      <div style={{ width: "60%", margin: "auto" }}>
        <div style={{ padding: "20px 0px" }}>
          <HeaderBreadcrumbs
            list={[
              { label: "Contract", link: "/subscription/contract" },
              {
                label: `CTR#${params.contractId}`,
              },
            ]}
          />
        </div>
        <div className={classes.containersection} style={{ padding: "24px" }}>
          <CustomerInfo contractDetails={contractDetails} />
        </div>
        <div className={classes.containersection} style={{ padding: "24px" }}>
          <SubscriptionDetails contractDetails={contractDetails} />
        </div>

        <div className={classes.containersection} style={{ padding: "24px" }}>
          <UpcomingOrders />
        </div>
      </div>
    </>
  );
};

export default ContractDetails;
