import colorNames from "./colorLabel.json";

const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

const hexToHsl = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  var r = parseInt(result[1], 16);
  var g = parseInt(result[2], 16);
  var b = parseInt(result[3], 16);

  r = r / 255;
  g = g / 255;
  b = b / 255;

  var max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  var h,
    s,
    l = (max + min) / 2;
  if (max === min) {
    h = s = 0; // achromatic
  } else {
    var d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }
  var HSL = {};
  HSL["h"] = h;
  HSL["s"] = s;
  HSL["l"] = l;
  return HSL;
};

export const getColorName = (color) => {
  let gethslofcolor = {};
  let hexCode = color?.css?.backgroundColor ?? "";
  let newHexCode = "";
  if (hexCode.length < 7) {
    return "";
  }
  if (hexCode.length > 7) {
    newHexCode = hexCode.substring(0, 7);
  } else if (hexCode.length === 7) {
    newHexCode = hexCode;
  }
  if (newHexCode.length === 7) {
    gethslofcolor = hexToHsl(newHexCode);
  }

  let r = color.rgb[0];
  let g = color.rgb[1];
  let b = color.rgb[2];

  let h = parseInt(gethslofcolor.h);
  let s = parseInt(gethslofcolor.s * 100);
  let l = parseInt(gethslofcolor.l * 100);

  let ndf1;
  let ndf2;
  let ndf;
  let df = -1;
  let cl = "";
  for (let code in colorNames) {
    let rgbColor = hexToRgb(code);
    let hslColor = hexToHsl(code);

    ndf1 =
      parseInt(Math.pow(r - rgbColor.r, 2)) +
      parseInt(Math.pow(g - rgbColor.g, 2)) +
      parseInt(Math.pow(b - rgbColor.b, 2));
    ndf2 =
      parseInt(Math.pow(h - hslColor.h, 2)) +
      parseInt(Math.pow(s - hslColor.s * 100, 2)) +
      parseInt(Math.pow(l - hslColor.l * 100, 2));

    ndf = ndf1 + ndf2 * 2;
    if (df < 0 || df > ndf) {
      df = ndf;
      cl = code;
    }
  }
  return cl === "" ? "" : colorNames[cl];
};
