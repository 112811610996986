import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ErrorIcon = ({ viewBox = "0 0 19 18", stroke = "white" }) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "19px", height: "18px" }}>
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.18216 14.6788L7.96893 1.84875C8.1045 1.59253 8.30735 1.37812 8.55567 1.22857C8.80398 1.07902 9.08837 1 9.37824 1C9.66811 1 9.9525 1.07902 10.2008 1.22857C10.4491 1.37812 10.652 1.59253 10.7876 1.84875L17.5743 14.6788C17.7017 14.9209 17.7642 15.1919 17.7557 15.4654C17.7472 15.7388 17.6681 16.0054 17.5259 16.2392C17.3838 16.4729 17.1835 16.6659 16.9446 16.7992C16.7057 16.9325 16.4363 17.0017 16.1627 17H2.5907C2.31738 17.0012 2.0484 16.9316 1.80992 16.7981C1.57144 16.6645 1.37159 16.4715 1.22979 16.2378C1.088 16.0042 1.00909 15.7378 1.00074 15.4646C0.992391 15.1914 1.05489 14.9207 1.18216 14.6788V14.6788Z"
          stroke={stroke}
          strokeWidth="1.2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M9.37695 6.33496V10.9057"
          stroke={stroke}
          strokeWidth="1.2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M9.38093 13.9503C9.80165 13.9503 10.1427 13.6093 10.1427 13.1885C10.1427 12.7678 9.80165 12.4268 9.38093 12.4268C8.96021 12.4268 8.61914 12.7678 8.61914 13.1885C8.61914 13.6093 8.96021 13.9503 9.38093 13.9503Z"
          fill={stroke}
        />
      </svg>
    </SvgIcon>
  );
};

export default ErrorIcon;
