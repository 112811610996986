import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const FullNameIcon = ({ viewBox = "0 0 16 16", stroke = "#1A1A1A" }) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "16px", height: "16px" }}>
      <path
        d="M6 2C6.49445 2 6.9778 2.14662 7.38893 2.42133C7.80005 2.69603 8.12048 3.08648 8.3097 3.54329C8.49892 4.00011 8.54843 4.50277 8.45196 4.98773C8.3555 5.47268 8.1174 5.91814 7.76777 6.26777C7.41814 6.6174 6.97268 6.8555 6.48773 6.95196C6.00277 7.04843 5.50011 6.99892 5.04329 6.8097C4.58648 6.62048 4.19603 6.30005 3.92133 5.88893C3.64662 5.4778 3.5 4.99445 3.5 4.5C3.5 3.83696 3.76339 3.20107 4.23223 2.73223C4.70108 2.26339 5.33696 2 6 2ZM6 1C5.30777 1 4.63108 1.20527 4.05551 1.58986C3.47993 1.97444 3.03133 2.52107 2.76642 3.16061C2.50152 3.80015 2.4322 4.50388 2.56725 5.18282C2.7023 5.86175 3.03564 6.48539 3.52513 6.97487C4.01461 7.46436 4.63825 7.7977 5.31719 7.93275C5.99612 8.0678 6.69985 7.99848 7.33939 7.73358C7.97894 7.46867 8.52556 7.02007 8.91015 6.4445C9.29473 5.86892 9.5 5.19223 9.5 4.5C9.5 3.57174 9.13125 2.6815 8.47487 2.02513C7.8185 1.36875 6.92826 1 6 1Z"
        fill={stroke}
      />
      <path
        d="M11 15H10V12.5C10 11.837 9.73661 11.2011 9.26777 10.7322C8.79893 10.2634 8.16304 10 7.5 10H4.5C3.83696 10 3.20107 10.2634 2.73223 10.7322C2.26339 11.2011 2 11.837 2 12.5V15H1V12.5C1 11.5717 1.36875 10.6815 2.02513 10.0251C2.6815 9.36875 3.57174 9 4.5 9H7.5C8.42826 9 9.3185 9.36875 9.97487 10.0251C10.6313 10.6815 11 11.5717 11 12.5V15Z"
        fill={stroke}
      />
      <path d="M11 2H16V3H11V2Z" fill={stroke} />
      <path d="M11 4.5H16V5.5H11V4.5Z" fill={stroke} />
      <path d="M11 7H14.5V8H11V7Z" fill={stroke} />
    </SvgIcon>
  );
};

export default FullNameIcon;
