import React, { useEffect } from "react";
import useStyles from "../styles";
import "swiper/swiper-bundle.min.css";
import { Skeleton } from "@material-ui/lab";
import { Typography } from "@material-ui/core";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSelector, useDispatch } from "react-redux";
import { getStoreIdFromUrl } from "../../../utils/storeUtil";
import { Navigation, Scrollbar, Keyboard, Autoplay, Pagination } from "swiper";
import { fetchPromotionBanner } from "../../../redux/dashboard/dashboardActions";

const BannerCarousel = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const storeId = getStoreIdFromUrl();
  const dashboardState = useSelector((state) => state.dashboard);
  const banners = dashboardState.banners;

  useEffect(() => {
    if (banners.length === 0) {
      dispatch(fetchPromotionBanner());
    }
    // eslint-disable-next-line
  }, [storeId]);

  return (
    <div>
      <Typography className={classes.heading}>Promotional Offers</Typography>
      {banners.length <= 0 ? (
        <div>
          <Skeleton
            style={{ transform: "none", borderRadius: "12px" }}
            animation="wave"
            width="100%"
            height={212}
          />
        </div>
      ) : (
        <Swiper
          className="swiper"
          navigation={true}
          autoplay={{
            delay: 3000,
          }}
          loop={true}
          keyboard={true}
          pagination
          modules={[Navigation, Scrollbar, Keyboard, Autoplay, Pagination]}
        >
          {banners.filter((banner) => storeId !== "351364" || !/razorpay/i.test(banner.title)).map((banner, index) => {
            return (
              <SwiperSlide key={index}>
                <img
                  onClick={() => {
                    window.open(banner.link);
                  }}
                  src={banner.image}
                  alt={banner.title}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
      <style jsx="true">{`
        .swiper {
          width: 100%;
          height: 212px;
        }

        .swiper-slide {
          text-align: center;
          font-size: 18px;
          background: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .swiper-slide img {
          display: block;
          width: 100%;
          height: 212px;
          border-radius: 12px;
          object-fit: fill;
          cursor: pointer;
        }

        .swiper-button-next {
          background-repeat: no-repeat;
          background-size: 100% auto;
          background-position: center;
        }

        .swiper-button-prev {
          background-repeat: no-repeat;
          background-size: 100% auto;
          background-position: center;
        }

        .swiper-button-next::after {
          display: none;
        }

        .swiper-button-prev::after {
          display: none;
        }
      `}</style>
    </div>
  );
};

export default BannerCarousel;
