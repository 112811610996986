import { CheckCircleOutlineOutlined, ReportOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Controls from "../../../components/controls/Controls";
import { updateOrders } from "../../../redux/orders/orderActions";
import useStyles from "../styles";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import Toast from "../../../components/Layout/Toast";
import AlertBar from "../../../components/common/AlertBar";

const validatePartnerInput = (text) => {
  let newText = text.replace(/\n/g, ""); // Remove new line characters
  newText = newText.replace(/\t/g, ""); // Remove tab characters
  newText = newText.replace(/\\n/g, ""); // Remove literal \n
  newText = newText.replace(/\\t/g, ""); // Remove literal \t
  newText = newText.replace(/ {5,}/g, "    "); // Replace sequences of more than four spaces with exactly four spaces

  return newText;
};

const DeliveryPartnerForm = ({
  order,
  selectedItems,
  setselectedItems,
  bulkOrdDispatch,
  selectedOrders,
  setToast,
  onClose,
  scale,
}) => {
  const classes = useStyles();
  const orders = useSelector((state) => state.orders.orders);
  const dispatch = useDispatch();
  const [details, setDetails] = useState({
    id: "",
    carrier: "",
    id_error: false,
    carrier_error: false,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [existIdAndPartnername, setExistIdAndPartnername] = useState([]);
  const [existIdAndNotPartnername, setExistIdAndNotPartnername] = useState([]);
  const [existPartnernameAndNotId, setExistPartnernameAndNotId] = useState([]);
  const [notExistOrderAddress, setNotExistOrderAddress] = useState([]);
  const disabled =
    bulkOrdDispatch &&
    (existIdAndNotPartnername.length > 0 ||
      existPartnernameAndNotId.length > 0 ||
      notExistOrderAddress.length > 0);

  useEffect(() => {
    if (!bulkOrdDispatch) {
      let matchedOrder = orders.filter((ord) => ord.id === order.id)[0] ?? null;
      setDetails({
        ...details,
        id: matchedOrder?.tracking_id ?? "",
        carrier: matchedOrder?.delivery_partner ?? "",
      });
    }
  }, [orders]);

  //bulk order error handling

  useEffect(() => {
    if (bulkOrdDispatch) {
      let filterOrdExistIdandName = selectedOrders.filter(
        (ord) =>
          ord?.tracking_id !== "" &&
          ord?.tracking_id !== undefined &&
          ord?.delivery_partner !== "" &&
          ord?.delivery_partner !== undefined
      );
      setExistIdAndPartnername(filterOrdExistIdandName);
      let filterOrdPartnernameNotExist = selectedOrders.filter(
        (ord) =>
          ord?.tracking_id !== "" &&
          ord?.tracking_id !== undefined &&
          (ord?.delivery_partner === "" || ord?.delivery_partner === undefined)
      );
      setExistIdAndNotPartnername(filterOrdPartnernameNotExist);
      let filterOrdIdNotExist = selectedOrders.filter(
        (ord) =>
          (ord?.tracking_id === "" || ord?.tracking_id === undefined) &&
          ord?.delivery_partner !== "" &&
          ord?.delivery_partner !== undefined
      );
      setExistPartnernameAndNotId(filterOrdIdNotExist);
      let filterNotExistOrderAddress = selectedOrders.filter(
        (ord) => !ord?.order_address
      );
      setNotExistOrderAddress(filterNotExistOrderAddress);
    }
  }, [selectedOrders]);

  const clearData = () => {
    setDetails({
      id: "",
      carrier: "",
      id_error: false,
      carrier_error: false,
    });
  };
  const onShip = () => {
    if (details.id.trim() !== "") {
      if (details.carrier.trim() === "") {
        setDetails({ ...details, carrier_error: true });
        return;
      }
    }
    if (details.carrier.trim() !== "") {
      if (details.id.trim() === "") {
        setDetails({ ...details, id_error: true });
        return;
      }
    }

    if (
      (details.carrier.trim() === "" && details.id.trim() === "") ||
      bulkOrdDispatch
    ) {
      setLoading(true);
      dispatch(
        updateOrders(
          {
            state: "SHIPPED",
            status: "IN_TRANSIT",
          },
          bulkOrdDispatch ? Object.keys(selectedItems) : [order.id]
        )
      ).then((res) => {
        if (res) {
          setToast(true);
          setLoading(false);
          onClose();
          if (bulkOrdDispatch) {
            setselectedItems({});
          }
        } else {
          setLoading(false);
          setError(true);
        }
      });
    } else {
      setLoading(true);
      dispatch(
        updateOrders(
          {
            state: "SHIPPED",
            status: "IN_TRANSIT",
            delivery_detail: {
              partner_name: details.carrier,
              tracking_id: details.id,
              store_id: order.store_id,
              order_id: order.id,
            },
          },
          [order.id]
        )
      ).then((res) => {
        if (res) {
          setToast(true);
          setLoading(false);
          onClose();
        } else {
          setLoading(false);
          setError(true);
        }
      });
    }
    clearData();
    localStorage.setItem("default_shipping_option", "DELIVERY_PARTNER");
  };

  const getOrderIds = (ordsArray) => {
    let ordIdsStr = "";
    ordsArray.map((ord, i) => {
      ordIdsStr =
        ordIdsStr +
        ord.display_id +
        `${ordsArray.length === i + 1 ? "" : ", "}`;
    });
    return ordIdsStr;
  };

  return (
    <>
      <div style={{ minHeight: 251 }}>
        {bulkOrdDispatch ? (
          <>
            {existIdAndPartnername.length > 0 && (
              <AlertBar
                heading={`${existIdAndPartnername.length} ${
                  existIdAndPartnername.length > 1 ? "orders" : "order"
                } have delivery partner and tracking id`}
                icon={
                  <CheckCircleOutlineOutlined
                    style={{ color: "#00A55D", fonSize: 20, marginTop: -1 }}
                  />
                }
                backgroundColor="#EEFFEF"
              />
            )}
            {existIdAndNotPartnername.length > 0 && (
              <AlertBar
                heading={`${existIdAndNotPartnername.length} ${
                  existIdAndNotPartnername.length > 1 ? "orders" : "order"
                } have tracking id but missing partner name`}
                orderIds={getOrderIds(existIdAndNotPartnername)}
                icon={
                  <ReportOutlined
                    style={{ color: "#FF0000", fonSize: 20, marginTop: -1 }}
                  />
                }
                backgroundColor="#FFEEEE"
              />
            )}
            {existPartnernameAndNotId.length > 0 && (
              <AlertBar
                heading={`${existPartnernameAndNotId.length} ${
                  existPartnernameAndNotId.length > 1 ? "orders" : "order"
                } have partner name but missing tracking id`}
                orderIds={getOrderIds(existPartnernameAndNotId)}
                icon={
                  <ReportOutlined
                    style={{ color: "#FF0000", fonSize: 20, marginTop: -1 }}
                  />
                }
                backgroundColor="#FFEEEE"
              />
            )}
            {notExistOrderAddress.length > 0 && (
              <AlertBar
                heading={`${notExistOrderAddress.length} ${
                  notExistOrderAddress.length > 1 ? "orders" : "order"
                } does not have an address`}
                orderIds={getOrderIds(notExistOrderAddress)}
                icon={
                  <ReportOutlined
                    style={{ color: "#FF0000", fonSize: 20, marginTop: -1 }}
                  />
                }
                backgroundColor="#FFEEEE"
              />
            )}
          </>
        ) : (
          <>
            <div
              className={classes.dividerGrid}
              style={{
                display: "block",
                padding: "8px 0px 8px",
                border: "none",
              }}
            >
              <Controls.Input
                name="id"
                label="Partner Name"
                value={details.carrier}
                color="secondary"
                onChange={(e) => {
                  setDetails({
                    ...details,
                    carrier: validatePartnerInput(e.target.value),
                  });
                }}
                onFocus={() => {
                  setDetails({ ...details, carrier_error: false });
                }}
                error={
                  details.carrier_error ? "Please enter Shipping Partner" : ""
                }
                labelClass={classes.labelinput}
                controlClass={classes.accholder}
                onEnter={onShip}
                extLabelWidth={classes.accholderlabel}
              />
            </div>
            <div
              className={classes.dividerGrid}
              style={{ display: "block", border: "none", padding: "0px" }}
            >
              <Controls.Input
                name="id"
                label="Tracking ID"
                value={details.id}
                color="secondary"
                onChange={(e) => {
                  setDetails({
                    ...details,
                    id: validatePartnerInput(e.target.value),
                  });
                }}
                onFocus={() => {
                  setDetails({ ...details, id_error: false });
                }}
                error={details.id_error ? "Please enter Tracking ID" : ""}
                labelClass={classes.labelinput}
                controlClass={classes.namecontrol}
                onEnter={onShip}
                extLabelWidth={classes.accholderlabel}
              />
            </div>
          </>
        )}
      </div>
      <div className={classes.bottomStickyBtnRoot}>
        <div className={classes.bottomStickyBtnCont}>
          <CombinedButtons
            solidBtnText={`Dispatch ${
              bulkOrdDispatch ? " (" + selectedOrders.length + ")" : ""
            }`}
            outlinedBtnAction={onClose}
            solidBtnAction={onShip}
            loading={loading}
            disabled={disabled}
          />
        </div>
      </div>
      <Toast
        message={"Something went wrong, please try again!"}
        open={error}
        close={() => setError(false)}
        severity="error"
        autoClose
      />
    </>
  );
};

export default DeliveryPartnerForm;
