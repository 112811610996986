import { Button, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import StatusDotIcon from "../../../icons/StatusDotIcon";
import { updateOrders } from "../../../redux/orders/orderActions";
import { getShippingDetails } from "../../../utils/orderUtil";
import useStyles from "../styles";
import Input from "../../../components/controls/Input";
import FormModal from "../../../components/common/FormModal";
import moment from "moment";
import { timeConvert } from "../../../utils/utils";
import { rowFlexAlignCenter } from "../../../styles/common";

const ConfirmOrderModal = ({
  openConfirmOrder,
  setOpenConfirmOrder,
  order,
  setToast,
  selectedItems,
  setselectedItems,
  bulkOrdConfirm,
  setErrorToast,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedTime, setSelectedTime] = useState(null);
  const [promisedTime, setPromisedTime] = useState(null);
  const [isCustomDate, setIsCustomDate] = useState("");
  const [isCustomTime, setIsCustomTime] = useState("");
  const [loading, setLoading] = useState(false);

  const timeSlots = {
    delivery: {
      fast: ["10-30 mins", "30-60 mins", "1-2 hrs"],
      easy: ["2-4 hrs", "4-8 hrs", "8-24 hrs"],
      long: ["2-3 days", "4-6 days", "7-10 days", "10+ days"],
    },
    pickup: {
      fast: ["10-30 mins", "30-60 mins", "1-2 hrs"],
      easy: ["2-4 hrs", "4-8 hrs", "8-24 hrs"],
      long: ["2-3 days", "4-6 days", "7-10 days", "10+ days"],
    },
  };

  useEffect(() => {
    if (order) {
      setSelectedTime(
        localStorage.getItem(
          order.delivery_mode === "PICKUP" ? "pickupTime" : "deliveryTime"
        )
      );
      if (order.delivery_ends_at) {
        setPromisedTime(getShippingDetails(order));
      }
    } else {
      setSelectedTime(null);
    }
    setIsCustomDate("");
    setIsCustomTime("");
  }, [order]);

  const setEstSelectedTime = (time) => {
    if (time === selectedTime) {
      setSelectedTime(null);
      return;
    }
    setSelectedTime(time);
    setIsCustomDate("");
    setIsCustomTime("");
  };

  const onConfirm = () => {
    if (isCustomTime && !isCustomDate) {
      return;
    }
    setLoading(true);
    let estTime = "";
    if (isCustomDate) {
      estTime =
        selectedTime + (isCustomTime ? ", " + timeConvert(isCustomTime) : "");
    } else {
      estTime = selectedTime;
    }
    let ordersId = Object.keys(selectedItems);
    dispatch(
      updateOrders(
        {
          state: "CONFIRMED",
          status: "VERIFIED",
          delivery_estimate: estTime,
        },
        bulkOrdConfirm ? ordersId : [order.id]
      )
    ).then((res) => {
      if (res) {
        setToast(true);
        setLoading(false);
        setOpenConfirmOrder(false);
        if (bulkOrdConfirm) {
          setselectedItems({});
        }
      } else {
        setErrorToast(true);
        setLoading(false);
        setOpenConfirmOrder(false);
      }
    });
    if (!isCustomDate && !isCustomTime && !bulkOrdConfirm) {
      localStorage.setItem(
        order?.delivery_mode === "PICKUP" ? "pickupTime" : "deliveryTime",
        selectedTime
      );
    }
  };
  if (order || bulkOrdConfirm)
    return (
      <FormModal
        open={openConfirmOrder}
        heading={
          !bulkOrdConfirm && order?.delivery_mode === "PICKUP"
            ? "Choose Pickup Time"
            : "Choose Delivery Time"
        }
        onSave={onConfirm}
        btnTxt={`Confirm ${
          bulkOrdConfirm
            ? " (" + Object.entries(selectedItems).length + ") "
            : ""
        }`}
        onClose={() => setOpenConfirmOrder(false)}
        loading={loading}
        padding="0 35px"
      >
        <>
          {!bulkOrdConfirm &&
            order?.delivery_ends_at &&
            order?.delivery_mode !== "PICKUP" && (
              <div style={{ paddingBottom: 14 }}>
                <Typography
                  className={classes.subHeading}
                  style={{ paddingTop: 0 }}
                >
                  <StatusDotIcon fill="#1641DB" style={{ marginBottom: 2 }} />
                  &nbsp; Promised
                </Typography>
                <div>
                  <Button
                    className={
                      selectedTime === promisedTime
                        ? classes.selectedTimeBtns
                        : classes.timeBtns
                    }
                    style={{ outline: "none" }}
                    onClick={() => {
                      setEstSelectedTime(promisedTime);
                    }}
                  >
                    {promisedTime}
                  </Button>
                </div>
              </div>
            )}
          <div>
            <Typography
              className={classes.subHeading}
              style={{ paddingTop: 0 }}
            >
              <StatusDotIcon fill="#72C472" style={{ marginBottom: 2 }} />
              &nbsp; Fast
            </Typography>

            <div>
              {(bulkOrdConfirm || order?.delivery_mode !== "PICKUP") &&
                timeSlots.delivery.fast.map((time, i) => {
                  return (
                    <Button
                      className={
                        selectedTime === time
                          ? classes.selectedTimeBtns
                          : classes.timeBtns
                      }
                      style={{ outline: "none" }}
                      onClick={() => {
                        setEstSelectedTime(time);
                      }}
                      key={i}
                    >
                      {time}
                    </Button>
                  );
                })}
              {order?.delivery_mode === "PICKUP" &&
                timeSlots.pickup.fast.map((time, i) => {
                  return (
                    <Button
                      className={
                        selectedTime === time
                          ? classes.selectedTimeBtns
                          : classes.timeBtns
                      }
                      style={{ outline: "none" }}
                      onClick={() => {
                        setEstSelectedTime(time);
                      }}
                      key={i}
                    >
                      {time}
                    </Button>
                  );
                })}
            </div>
          </div>
          <div>
            <Typography
              className={classes.subHeading}
              style={{ paddingTop: 14 }}
            >
              <StatusDotIcon fill="#FF7222" style={{ marginBottom: 2 }} />
              &nbsp; Medium
            </Typography>

            <div>
              {(bulkOrdConfirm || order?.delivery_mode !== "PICKUP") &&
                timeSlots.delivery.easy.map((time, i) => {
                  return (
                    <Button
                      className={
                        selectedTime === time
                          ? classes.selectedTimeBtns
                          : classes.timeBtns
                      }
                      style={{ outline: "none" }}
                      onClick={() => {
                        setEstSelectedTime(time);
                      }}
                      key={i}
                    >
                      {time}
                    </Button>
                  );
                })}
              {order?.delivery_mode === "PICKUP" &&
                timeSlots.pickup.easy.map((time, i) => {
                  return (
                    <Button
                      className={
                        selectedTime === time
                          ? classes.selectedTimeBtns
                          : classes.timeBtns
                      }
                      style={{ outline: "none" }}
                      onClick={() => {
                        setEstSelectedTime(time);
                      }}
                      key={i}
                    >
                      {time}
                    </Button>
                  );
                })}
            </div>
          </div>
          <div>
            <Typography
              className={classes.subHeading}
              style={{ paddingTop: 14 }}
            >
              <StatusDotIcon fill="#FF0000" style={{ marginBottom: 2 }} />
              &nbsp; Will Take Time
            </Typography>

            <div>
              {(bulkOrdConfirm || order?.delivery_mode !== "PICKUP") &&
                timeSlots.delivery.long.map((time, i) => {
                  return (
                    <Button
                      className={
                        selectedTime === time
                          ? classes.selectedTimeBtns
                          : classes.timeBtns
                      }
                      style={{ outline: "none" }}
                      onClick={() => {
                        setEstSelectedTime(time);
                      }}
                      key={i}
                    >
                      {time}
                    </Button>
                  );
                })}
              {order?.delivery_mode === "PICKUP" &&
                timeSlots.pickup.long.map((time, i) => {
                  return (
                    <Button
                      className={
                        selectedTime === time
                          ? classes.selectedTimeBtns
                          : classes.timeBtns
                      }
                      style={{ outline: "none" }}
                      onClick={() => {
                        setEstSelectedTime(time);
                      }}
                      key={i}
                    >
                      {time}
                    </Button>
                  );
                })}
            </div>
          </div>
          <div className={rowFlexAlignCenter} style={{ marginTop: 12 }}>
            <div style={{ marginRight: 35 }}>
              <Typography className={classes.subHeading}>
                <StatusDotIcon fill="#1641DB" style={{ marginBottom: 2 }} />
                &nbsp; Select Date
              </Typography>
              <Input
                style={{ marginTop: 14 }}
                name="referredTitle"
                color="secondary"
                variant="outlined"
                type="date"
                width={170}
                value={isCustomDate}
                onChange={(e) => {
                  let date = moment(e.target.value).format("DD-MMM-YYYY");
                  setIsCustomDate(e.target.value);
                  setSelectedTime(date);
                }}
                error={isCustomTime && !isCustomDate}
                helperText={
                  isCustomTime && !isCustomDate ? "Date is required" : ""
                }
              />
            </div>
            <div>
              <div>
                <Typography className={classes.subHeading}>
                  <StatusDotIcon fill="#9C9C9C" style={{ marginBottom: 2 }} />
                  &nbsp; Select Time&nbsp;
                  <span style={{ fontWeight: 400 }}>(Optional)</span>
                </Typography>

                <Input
                  style={{ marginTop: 14 }}
                  name="referredTitle"
                  color="secondary"
                  variant="outlined"
                  type="time"
                  width={170}
                  value={isCustomTime}
                  onChange={(e) => {
                    setIsCustomTime(e.target.value);
                    setIsCustomTime(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          {isCustomDate && (
            <div className={classes.dateTimeInfo}>
              <Typography style={{ fontSize: 12 }}>
                Order will be delivered by{" "}
                <b>
                  {selectedTime}
                  {isCustomTime ? ", " + timeConvert(isCustomTime) : ""}
                </b>
              </Typography>
            </div>
          )}
        </>
      </FormModal>
    );
  return <span />;
};

export default ConfirmOrderModal;
