import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    "&:hover, &:focus": {
      backgroundColor: "#04113F",
    },
    padding: "4px 32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:not(:last-child)": {
      marginBottom: 10,
    },
  },
  linkActive: {
    paddingLeft: 25,
    borderLeft: `7px solid #FFFFFF`,
  },
  linkIcon: {
    color: "#FFFFFF",
    transition: theme.transitions.create("color"),
    display: "grid",
    placeItems: "center",
    width: 36,
    height: 24,
    minWidth: 24,
    marginRight: theme.spacing(2),
    borderRadius: "3px",
  },
  linkText: {
    padding: 0,
    color: "#FFFFFFB3",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
  },
  linkTextActive: {
    color: "#FFFFFF",
    fontWeight: 600,
  },
  linkTextHidden: {
    opacity: 0,
  },
  formControl: {
    width: "100%",
    minWidth: 120,
  },
  selectmenu: {
    display: "flex !important",
    alignItems: "center !important",
    paddingBottom: "6px !important",
    paddingTop: "6px !important",
    paddingLeft: "6px !important",
    paddingRight: "24px !important",
    borderRadius: "6px !important",
    "&:focus": {
      backgroundColor: "unset",
    },
  },
  dropdownMenuContainer: {
    top: "58px !important",
    minWidth: "120px !important",
    left: "calc(100% - 140) !important",
  },
  dropdownMenuContainerDesk: {
    top: "58px !important",
  },
  dropdownList: { paddingBottom: 0, paddingTop: 0 },
  menuitemGutter: {
    paddingLeft: "6px",
    paddingRight: "6px",
  },
  menuitem: {
    display: "flex",
    alignItems: "center",
  },
  menuimage: {
    width: "48px",
    height: "48px",
  },
  menutxt: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: "16px",
    minWidth: 58,
    maxWidth: 100,
  },
  menuText: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: "16px",
    minWidth: 58,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  collapse: {
    backgroundColor: "transparent",
  },
  subLink: {
    textDecoration: "none",
    "&:hover, &:focus": {
      backgroundColor: "transparent",
    },
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 42,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  subLinkText: {
    padding: 0,
    color: "#FFFFFFB3",
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 400,
  },
  subLinkTextActive: {
    color: "#FFFFFF",
    fontWeight: 600,
  },
  storeitem: {
    height: "28px",
    display: "flex",
    justifyContent: "center",
    placeItems: "center",
    border: "1px solid #E5E5E5",
    borderRadius: "6px",
    overflow: "hidden",
    boxSizing: "border-box",
  },
  mobilemenu: {
    paddingRight: "12px !important",
  },
}));
