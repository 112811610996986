import React from "react";

export default function LoginSettingIcon({
  fill = "#666666",
  strokeWidth = 0,
}) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.97738 19.3327V18.166H17.4484C17.6281 18.166 17.7927 18.0912 17.9422 17.9417C18.0917 17.7922 18.1665 17.6276 18.1665 17.4479V2.55077C18.1665 2.3711 18.0917 2.2065 17.9422 2.05697C17.7927 1.90745 17.6281 1.83268 17.4484 1.83268H9.97738V0.666016H17.4484C17.9855 0.666016 18.4339 0.845877 18.7936 1.2056C19.1533 1.56532 19.3332 2.01371 19.3332 2.55077V17.4479C19.3332 17.985 19.1533 18.4334 18.7936 18.7931C18.4339 19.1528 17.9855 19.3327 17.4484 19.3327H9.97738ZM9.14729 14.1276L8.32829 13.2885L11.0341 10.5827H0.666504V9.41601H11.0341L8.32829 6.71022L9.14729 5.8711L13.2755 9.99935L9.14729 14.1276Z"
        fill={fill}
        stroke={fill}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
}
