import { SvgIcon } from "@material-ui/core";
import React from "react";

export default function Diamond({
  primary = "#FF1D1D",
  secondary = "#2238A8",
  tertiary = "#CA6189",
  quaternary = "#F7BBD0",
  quinary = "#E789AD",
  style = {},
}) {
  return (
    <SvgIcon
      viewBox="0 0 55 54"
      style={{ width: "55px", height: "54px", marginBottom: "11px", ...style }}
    >
      <svg
        width="55"
        height="54"
        viewBox="0 0 55 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <ellipse
          opacity="0.2"
          cx="27.5"
          cy="27"
          rx="27.5"
          ry="27"
          fill="white"
        />
        <path
          d="M44.9987 13.7813L41.6159 13.2312L41.0635 10L40.5803 13.3L37.4045 13.7813L40.5803 14.2625L41.0635 17.5625L41.6159 14.3313L44.9987 13.7813Z"
          fill={primary}
        />
        <path
          d="M17.6606 10L17.5226 11.3063L16.1418 11.5125L17.4535 11.7188L17.6606 13.025L17.9368 11.7188L19.2485 11.5125L17.8677 11.3063L17.6606 10Z"
          fill={primary}
        />
        <path
          d="M15.7977 14.1941L15.4525 11.8566L15.1073 14.1941L12.8291 14.5378L15.1073 14.9503L15.4525 17.2878L15.7977 14.9503L18.2141 14.5378L15.7977 14.1941Z"
          fill={secondary}
        />
        <path
          d="M33.6777 23.888L29.4664 15.913H27.7405L23.0459 23.888H33.6777Z"
          fill={tertiary}
        />
        <path
          d="M39.0628 15.913H29.4666L33.6779 23.888L39.0628 15.913Z"
          fill={quaternary}
        />
        <path
          d="M44.8633 23.888L39.1332 15.913H39.0641L33.6792 23.888H44.8633Z"
          fill={tertiary}
        />
        <path
          d="M28.4308 43L33.6777 23.8876H23.0459L28.4308 43Z"
          fill={quinary}
        />
        <path
          d="M27.7412 15.5326H17.7998L23.0466 23.5076L27.7412 15.5326Z"
          fill={quaternary}
        />
        <path
          d="M17.7992 15.913H17.7301L12 23.888H23.046L17.7992 15.913Z"
          fill={tertiary}
        />
        <path
          d="M44.8619 23.5072L28.4309 42.6196L12 23.5072H23.046L28.4309 42.6196L33.6778 23.5072H44.8619Z"
          fill={quaternary}
        />
        <path
          d="M33.6773 21.7565L33.4011 23.544L31.5371 23.819L33.4011 24.094L33.6773 26.019L33.9535 24.094L35.8175 23.819L33.9535 23.544L33.6773 21.7565Z"
          fill="white"
        />
        <path
          d="M20.1443 17.7688L19.8682 19.5563L18.0042 19.8313L19.7991 20.1063L20.1443 22.0313L20.4204 20.1751L22.2845 19.8313L20.4204 19.5563L20.1443 17.7688Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
}
