import React from "react";

const MoveToUpIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g mask="url(#mask0_19059_8597)">
        <path
          d="M9.16634 17.5V9L6.99967 11.1667L5.83301 10L9.99967 5.83333L14.1663 10L12.9997 11.1667L10.833 9V17.5H9.16634ZM3.33301 4.16667V2.5H16.6663V4.16667H3.33301Z"
          fill="#1641DB"
        />
      </g>
    </svg>
  );
};

export default MoveToUpIcon;
