import React, { Fragment, useEffect, useState } from "react";
import HeaderBreadcrumbs from "../../../components/common/HeaderBreadcrumbs";
import { layoutBodyBox, layoutContainer } from "../../../styles/common";
import useStyles from "../styles";
import SwitchButton from "./SwitchButton";
import BannerImgContainer from "./BannerImgContainer";
import DisplayBannerImg from "./DisplayBannerImg";
import { useDispatch, useSelector } from "react-redux";
import Switch from "../../../components/controls/Switch";
import { Typography } from "@material-ui/core";
import Input from "../../../components/controls/Input";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import ImageHandler from "../../../components/ImageHandler";
import { getContainerSize } from "./bannerUtils";
import { uploadImage } from "../../../utils/imageUploader";
import {
  addBanner,
  startBannerLoader,
  stopBannerLoader,
  updateBanner,
} from "../../../redux/banners/bannerActions";
import { isWindows } from "react-device-detect";

const desktopImageCropVariants = [
  {
    title: "Original", // Original is important if passing variants from externally
    id: "original",
    ratio: null,
  },
  {
    title: "1:1",
    id: "1:1",
    ratio: 1 / 1,
  },
  {
    title: "3:1",
    id: "3:1",
    ratio: 3 / 1,
  },
  {
    title: "4:3",
    id: "4:3",
    ratio: 4 / 3,
  },
  {
    title: "16:9",
    id: "16:9",
    ratio: 16 / 9,
  },
  {
    title: "9:16",
    id: "9:16",
    ratio: 9 / 16,
  },
  {
    title: "Custom",
    id: "custom",
    ratio: undefined,
  },
];

const BannerForm = ({ record, onSave, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const store = useSelector((state) => state.store.store);
  const loader = useSelector((state) => state.banners.loader);
  const [isMobileActive, setIsMobileActive] = useState(true);
  const [cropMobBannerImg, setCropMobBannerImg] = useState(null);
  const [cropDeskBannerImg, setCropDeskBannerImg] = useState(null);
  const [mobImgFilename, setMobImgFilename] = useState("");
  const [deskImgFilename, setDeskImgFilename] = useState("");
  const [isOpenLink, setIsOpenLink] = useState(false);
  const [navigateLink, setNavigateLink] = useState(null);
  const [isNewWindow, setIsNewWindow] = useState(false);
  const [scale, setScale] = useState(1);
  const [openMobImageHandler, setOpenMobImageHandler] = useState(false);
  const [openMobImageHandlerIndex, setOpenMobImageHandlerIndex] = useState(0);
  const [openDeskImageHandler, setOpenDeskImageHandler] = useState(false);
  const [openDeskImageHandlerIndex, setOpenDeskImageHandlerIndex] = useState(0);
  const [openMobModal, setOpenMobModal] = useState(false);
  const [openDeskModal, setOpenDeskModal] = useState(false);

  const theme = store?.store_setting?.theme ?? "basic";
  const mobContSize = getContainerSize(theme, "Mobile");
  const deskContSize = getContainerSize(theme, "Desktop");
  const defaultCrop = {
    Mobile: {
      aspect: mobContSize.aspectRatio,
      x: 0,
      y: 0,
      width: 250,
      height: 250 * (1 / mobContSize.aspectRatio),
    },
    Desktop: {
      aspect: deskContSize.aspectRatio,
      x: 0,
      y: 0,
      width: 250,
      height: 250 * (1 / deskContSize.aspectRatio),
    },
  };
  const disabled =
    !(cropMobBannerImg || cropDeskBannerImg) ||
    (isOpenLink ? (navigateLink?.trim() ? false : true) : false);

  useEffect(() => {
    if (isWindows) {
      setScale(window.devicePixelRatio - 0.07);
    }
  }, []);

  useEffect(() => {
    if (record !== null) {
      setCropMobBannerImg(record.url);
      setCropDeskBannerImg(record.desktop_url);
      setIsOpenLink(record.link ? true : false);
      setNavigateLink(record.link);
      setIsNewWindow(record.new_window);
    }
  }, [record]);

  const handleSubmit = async () => {
    dispatch(startBannerLoader());
    let mobBannerUrl = null;
    let deskBannerUrl = null;
    if (cropMobBannerImg) {
      if (cropMobBannerImg.startsWith("https:")) {
        mobBannerUrl = cropMobBannerImg;
      } else {
        let cloudFile = await uploadImage(mobImgFilename, cropMobBannerImg);
        mobBannerUrl = cloudFile.payload.location;
      }
    }
    if (cropDeskBannerImg) {
      if (cropDeskBannerImg.startsWith("https:")) {
        deskBannerUrl = cropDeskBannerImg;
      } else {
        let cloudFile = await uploadImage(deskImgFilename, cropDeskBannerImg);
        deskBannerUrl = cloudFile.payload.location;
      }
    }

    let payload = {};
    if (record !== null) {
      payload = { ...payload, ...record };
    }

    payload = {
      ...payload,
      name: record?.name ?? null,
      url: mobBannerUrl,
      desktop_url: deskBannerUrl,
      link: navigateLink?.trim() && isOpenLink ? navigateLink : null,
      new_window: navigateLink?.trim() && isOpenLink ? isNewWindow : false,
      hide: record?.hide ?? false,
      mobile_only: record?.mobile_only ?? false,
    };
    if (record !== null) {
      dispatch(updateBanner(record.id, payload)).then(() => {
        onSave();
      });
    } else {
      dispatch(addBanner(payload)).then(() => {
        onSave();
      });
    }
  };

  return (
    <div className={layoutContainer}>
      <div style={{ marginBottom: 20 }}>
        <HeaderBreadcrumbs
          list={[
            { label: "Banners", link: "/banners" },
            {
              label: record !== null ? "Edit" : "Add",
            },
          ]}
        />
      </div>
      <div style={{ position: "relative", width: "100%" }}>
        <div className={layoutBodyBox} style={{ maxWidth: 604 }}>
          <div style={{ padding: "8px 32px 32px" }}>
            <SwitchButton
              isMobileActive={isMobileActive}
              setIsMobileActive={setIsMobileActive}
            />
            <div style={{ marginTop: 34 }}>
              {isMobileActive ? (
                <Fragment>
                  {cropMobBannerImg && (
                    <DisplayBannerImg
                      height={mobContSize.height}
                      width={mobContSize.width}
                      src={cropMobBannerImg}
                      recommendSize={mobContSize.recommendSize}
                      removeCropImg={setCropMobBannerImg}
                      removeImgFilename={setMobImgFilename}
                      onImageClick={() => {
                        setOpenMobImageHandler(true);
                        setOpenMobImageHandlerIndex(0);
                      }}
                    />
                  )}
                  <ImageHandler
                    setCropImg={setCropMobBannerImg}
                    cropImg={cropMobBannerImg}
                    defaultCrop={defaultCrop["Mobile"]}
                    setFilename={setMobImgFilename}
                    uploadContainer={
                      <BannerImgContainer
                        height={mobContSize.height}
                        width={mobContSize.width}
                        aspectRatio={mobContSize.aspectRatio}
                        recommendSize={mobContSize.recommendSize}
                      />
                    }
                    maxSize={1920}
                    openImageHandler={openMobImageHandler}
                    setOpenImageHandler={setOpenMobImageHandler}
                    openImageHandlerIndex={openMobImageHandlerIndex}
                    openModal={openMobModal}
                    setOpenModal={setOpenMobModal}
                  />
                </Fragment>
              ) : (
                <Fragment>
                  {cropDeskBannerImg && (
                    <DisplayBannerImg
                      height={deskContSize.height}
                      width={deskContSize.width}
                      src={cropDeskBannerImg}
                      recommendSize={deskContSize.recommendSize}
                      removeCropImg={setCropDeskBannerImg}
                      removeImgFilename={setDeskImgFilename}
                      onImageClick={() => {
                        setOpenDeskImageHandler(true);
                        setOpenDeskImageHandlerIndex(0);
                      }}
                    />
                  )}
                  <ImageHandler
                    cropImg={cropDeskBannerImg}
                    setCropImg={setCropDeskBannerImg}
                    defaultCrop={defaultCrop["Desktop"]}
                    setFilename={setDeskImgFilename}
                    uploadContainer={
                      <BannerImgContainer
                        height={deskContSize.height}
                        width={deskContSize.width}
                        aspectRatio={deskContSize.aspectRatio}
                        recommendSize={deskContSize.recommendSize}
                      />
                    }
                    maxSize={1920}
                    openImageHandler={openDeskImageHandler}
                    setOpenImageHandler={setOpenDeskImageHandler}
                    openImageHandlerIndex={openDeskImageHandlerIndex}
                    defaultVariants={desktopImageCropVariants}
                    openModal={openDeskModal}
                    setOpenModal={setOpenDeskModal}
                  />
                </Fragment>
              )}
            </div>
            <div style={{ minHeight: `${23 * scale}vh` }}>
              {(cropMobBannerImg || cropDeskBannerImg) && (
                <>
                  <hr className={classes.horizontalDivider} />
                  <Switch
                    label={
                      <div>
                        Open link on click
                        <Typography className={classes.switchSubTitle}>
                          Enable to make banners clickable and redirect to
                          another site
                        </Typography>
                      </div>
                    }
                    value={isOpenLink}
                    onChange={() => setIsOpenLink(!isOpenLink)}
                  />
                  {isOpenLink && (
                    <Input
                      value={navigateLink}
                      style={{ marginTop: 25 }}
                      placeholder="Navigate to link"
                      helperText="Providing link will be opened when user click on banner"
                      onChange={(e) => setNavigateLink(e.target.value)}
                    />
                  )}
                  {navigateLink?.trim() && isOpenLink && (
                    <Switch
                      style={{ marginTop: 20 }}
                      label={
                        <div>
                          Open in new tab
                          <Typography className={classes.switchSubTitle}>
                            Link will open in new tab when banner is clicked
                          </Typography>
                        </div>
                      }
                      value={isNewWindow}
                      onChange={() => setIsNewWindow(!isNewWindow)}
                    />
                  )}
                </>
              )}
            </div>
            <div
              style={{
                marginTop: "50px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <CombinedButtons
                loading={loader}
                disabled={disabled}
                solidBtnAction={handleSubmit}
                outlinedBtnAction={() => {
                  dispatch(stopBannerLoader());
                  onClose();
                }}
                solidBtnText={"Save"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerForm;
