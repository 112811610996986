import React from "react";

const CustomerInfoIcon = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 12C12.4167 12 12.7708 11.8542 13.0625 11.5625C13.3542 11.2708 13.5 10.9167 13.5 10.5C13.5 10.0833 13.3542 9.72917 13.0625 9.4375C12.7708 9.14583 12.4167 9 12 9C11.5833 9 11.2292 9.14583 10.9375 9.4375C10.6458 9.72917 10.5 10.0833 10.5 10.5C10.5 10.9167 10.6458 11.2708 10.9375 11.5625C11.2292 11.8542 11.5833 12 12 12ZM12.0048 15C12.5294 15 13.0174 14.8681 13.4688 14.6042C13.9201 14.3403 14.2847 13.9861 14.5625 13.5417C14.1875 13.2778 13.7804 13.0799 13.3413 12.9479C12.9022 12.816 12.4543 12.75 11.9975 12.75C11.5408 12.75 11.0938 12.816 10.6562 12.9479C10.2188 13.0799 9.8125 13.2778 9.4375 13.5417C9.71528 13.9861 10.0815 14.3403 10.5361 14.6042C10.9907 14.8681 11.4803 15 12.0048 15ZM1.5 14C1.0875 14 0.734375 13.8531 0.440625 13.5594C0.146875 13.2656 0 12.9125 0 12.5V1.5C0 1.0875 0.146875 0.734376 0.440625 0.440626C0.734375 0.146876 1.0875 0 1.5 0H12.5C12.9125 0 13.2656 0.146876 13.5594 0.440626C13.8531 0.734376 14 1.0875 14 1.5V6.875C13.7625 6.795 13.5188 6.72611 13.2688 6.66833C13.0188 6.61056 12.7625 6.56833 12.5 6.54167V1.5H1.5V12.5H6.52083C6.54688 12.7711 6.58811 13.0298 6.64454 13.2762C6.70096 13.5226 6.77083 13.7639 6.85417 14H1.5ZM1.5 12.5V1.5V6.54167V6.5V12.5ZM3 11H6.58333C6.63889 10.7361 6.70965 10.4794 6.79563 10.2299C6.88161 9.98035 6.97751 9.73706 7.08333 9.5H3V11ZM3 7.75H8.5C8.86111 7.47222 9.25 7.23264 9.66667 7.03125C10.0833 6.82986 10.5278 6.6875 11 6.60417V6.25H3V7.75ZM3 4.5H11V3H3V4.5ZM11.9953 16C10.8873 16 9.94444 15.6095 9.16667 14.8286C8.38889 14.0477 8 13.1033 8 11.9953C8 10.8873 8.39046 9.94444 9.17138 9.16667C9.95229 8.38889 10.8967 8 12.0047 8C13.1127 8 14.0556 8.39046 14.8333 9.17138C15.6111 9.95229 16 10.8967 16 12.0047C16 13.1127 15.6095 14.0556 14.8286 14.8333C14.0477 15.6111 13.1033 16 11.9953 16Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CustomerInfoIcon;
