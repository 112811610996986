import {
  FETCH_CUSTOMER,
  CUSTOMER_LOGIN_SUCCESS,
  CUSTOMER_LOGOUT,
} from "./customerTypes";
import axios from "axios";

const setTokenInCookie = (tokenData) => {
  const expiryDate = new Date();
  expiryDate.setDate(expiryDate.getDate() + 365);
  const cookieExpires = expiryDate.toUTCString();
  const hostname = window.location.hostname;
  const domain = /localhost/.test(hostname) ? "localhost" : ".web.shoopy.in";
  document.cookie = `token=${tokenData}; domain=${domain}; expires=${cookieExpires}; path=/`;
};

const clearTokenFromCookie = () => {
  document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
};

export const fetchCustomer = () => (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  axios
    .get(`${process.env.REACT_APP_API}/api/v1/customer/logged-in`, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then((response) =>
      dispatch({
        type: FETCH_CUSTOMER,
        payload: response.data.payload,
      })
    );
};

export const customerLoginSuccess = (tokenData) => (dispatch) => {
  let token = tokenData["access_token"];
  localStorage.setItem("access_token", token);
  setTokenInCookie(token);
  axios
    .get(`${process.env.REACT_APP_API}/api/v1/customer/logged-in`, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) =>
      dispatch({
        type: CUSTOMER_LOGIN_SUCCESS,
        payload: { customer: response.data.payload, token: token },
      })
    );
};

export const customerLogout = () => (dispatch) => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("stores");
  localStorage.removeItem("store");
  clearTokenFromCookie();
  dispatch({
    type: CUSTOMER_LOGOUT,
    payload: {},
  });
};

