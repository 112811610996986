export const getPermissions = (role) => {
  if (role === "ADMIN") {
    return {
      heading: "Admin Permissions",
      permissions: [
        "Can Perform all Operations",
        "Can not Delete Store",
        "Can not Manage Users",
      ],
    };
  }
  if (role === "CATALOG_MANAGER") {
    return {
      heading: "Catalog Manager Permissions",
      permissions: [
        "Can Manage Store Catalog",
        "Can Create and Delete Products",
        "Can Manage Stock",
        "Can not Manage Orders/Invoices",
      ],
    };
  }
  if (role === "BILLING_MANAGER") {
    return {
      heading: "Billing Manager Permissions",
      permissions: [
        "Can Manage Orders/Invoices",
        "Can not Delete Orders/Invoices",
        "Can Manage Store Catalog",
        "Can Create and Delete Products",
        "Can Manage Stock",
      ],
    };
  }
  if (role === "DELIVERY_BOY") {
    return {
      heading: "Delivery Boy Permissions",
      permissions: [
        "Can Manage Order Deliveries only",
        "Can Login to Delivery App",
        "Can See only the Assigned Orders",
        "Can Mark Assigned Orders Delivered ",
        "Can not Access Business App",
      ],
    };
  }
};
