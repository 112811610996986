import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Chip } from "@material-ui/core";

const useMultiSelectCategory = (categoryList, lastCatSlug) => {
  const { categories, categoriesMap } = useSelector(
    (state) => state.categories
  );

  const categoryMapRef = useRef(null);

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [lastSelectedCategory, setLastSelectedCategory] = useState(null);

  const isTopLevelCategory = (categoryDetails) => {
    return !categoryDetails.parent_cat_slug;
  };

  const addTopLevelCategory = (hierarchy, categoryDetails) => {
    if (!hierarchy.has(categoryDetails.slug)) {
      hierarchy.set(categoryDetails.slug, new Map());
    }
  };

  const makeCategoryObject = (categoryDetails) => ({
    id: categoryDetails.slug,
    title: categoryDetails.name,
    indent: !!categoryDetails.parent_cat_slug,
    parent_slug: categoryDetails.parent_cat_slug || null,
  });

  const addChildCategory = (hierarchy, categoryDetails) => {
    let parentHierarchy = hierarchy.get(categoryDetails.parent_cat_slug);

    if (!parentHierarchy) {
      hierarchy.set(categoryDetails.parent_cat_slug, new Map());
      parentHierarchy = hierarchy.get(categoryDetails.parent_cat_slug);
    }
    const categorySlug = categoryDetails.cat_slug || categoryDetails.slug;

    if (!parentHierarchy.has(categorySlug)) {
      parentHierarchy.set(categorySlug, makeCategoryObject(categoryDetails));
    }
  };

  const buildCategoryHierarchy = (categories) => {
    let hierarchy = new Map();

    categories.forEach((category) => {
      const categoryKey = category.id || category.cat_slug;
      const categoryDetails = categoriesMap[categoryKey];

      if (!categoryDetails) return;

      if (isTopLevelCategory(categoryDetails)) {
        addTopLevelCategory(hierarchy, categoryDetails);
      } else {
        addChildCategory(hierarchy, categoryDetails);
      }
    });

    return hierarchy;
  };

  const updateSelectedCategories = (newCategories) => {
    if (
      categoryMapRef.current === null &&
      lastCatSlug &&
      categoriesMap.hasOwnProperty(lastCatSlug)
    ) {
      const { slug, name } = categoriesMap[lastCatSlug];
      setLastSelectedCategory({ id: slug, title: name });
    } else {
      setLastSelectedCategory(newCategories[newCategories.length - 1]);
    }

    const newCategoryMap = buildCategoryHierarchy(newCategories);
    const idsInNewCategories = newCategories.map((item) => item.id);

    const keysToDelete = [];
    newCategoryMap.forEach((childrenMap, outerKey) => {
      if (
        childrenMap.size === 0 &&
        categoryMapRef.current?.get(outerKey)?.size >= 1
      ) {
        keysToDelete.push(outerKey);
      } else if (
        !idsInNewCategories.includes(outerKey) &&
        categoryMapRef.current?.get(outerKey)
      ) {
        keysToDelete.push(outerKey);
      }
    });
    keysToDelete.forEach((key) => newCategoryMap.delete(key));

    const result = [];

    newCategoryMap.forEach((childrenMap, outerKey) => {
      result.push({
        id: outerKey,
        title:
          categories.find((category) => category.slug === outerKey).name || "",
      });
      childrenMap.forEach((category) => {
        result.push({ id: category.id, title: category.title });
      });
    });

    categoryMapRef.current = newCategoryMap;

    setSelectedCategories(result);

    return result;
  };

  const renderChips = (value, getTagProps) =>
    value.map((option, index) => {
      const item = categoriesMap[option.id];

      if (!item) return null;

      // Handling for parent items without a parent slug and with no children
      if (
        !item.parent_cat_slug &&
        categoryMapRef.current.get(item.slug)?.size === 0
      ) {
        return <Chip label={item.name} {...getTagProps({ index })} />;
      }

      // Skip rendering for parent items with children
      if (
        !item.parent_cat_slug &&
        categoryMapRef.current.get(item.slug)?.size > 0
      ) {
        return null;
      }

      // Handling for items with a parent slug
      const parentItem = categoriesMap[item.parent_cat_slug];

      const chipTitle = parentItem && `${parentItem.name} → ${item.name}`;

      return <Chip label={chipTitle} {...getTagProps({ index })} />;
    });

  useEffect(() => {
    if (!categoryList || !categoryList.length) {
      categoryMapRef.current = new Map();
      setSelectedCategories([]);
      setLastSelectedCategory(null);
      return;
    }

    updateSelectedCategories(categoryList);
    //eslint-disable-next-line
  }, [categoryList]);

  return [selectedCategories, lastSelectedCategory, renderChips];
};

export default useMultiSelectCategory;
