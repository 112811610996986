import {
  RadioButtonCheckedSharp,
  RadioButtonUncheckedSharp,
  ReportOutlined,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateOrders } from "../../../redux/orders/orderActions";
import useStyles from "../styles";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import { rowFlexCenterAll, rowFlexAlignCenter } from "../../../styles/common";
import { CircularProgress, Typography } from "@material-ui/core";
import { fetchUsers } from "../../../redux/usersAndRoles/userActions";
import { CustomerIcon } from "../../../icons";
import { Link } from "react-router-dom";
import { PLANS } from "../../plans/constants";
import Toast from "../../../components/Layout/Toast";
import AlertBar from "../../../components/common/AlertBar";

const DeliveryBoyForm = ({
  order,
  bulkOrdDispatch,
  selectedItems,
  setselectedItems,
  selectedOrders,
  setSuccessToast,
  onClose,
  scale,
}) => {
  const classes = useStyles();
  const store = useSelector((state) => state.store.store);
  const users = useSelector((state) => state.users.users);
  const dispatch = useDispatch();
  const [isDeliveryBoys, setIsDeliveryBoys] = useState(true);
  const [selectedDeliveryBoy, setSelectedDeliveryBoy] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [notExistOrderAddress, setNotExistOrderAddress] = useState([]);

  const deliveryBoys =
    users.length > 0 ? users.filter((boy) => boy.role === "DELIVERY_BOY") : [];
  const isNotProfPlan =
    PLANS[store?.plan]?.weight < PLANS.BUSINESS_APP_PRO.weight;

  useEffect(() => {
    if (isNotProfPlan) {
      setIsDeliveryBoys(false);
    } else if (users.length === 0 && !isNotProfPlan) {
      dispatch(fetchUsers()).then((res) => {
        setIsDeliveryBoys(false);
      });
    } else {
      setIsDeliveryBoys(false);
    }
    // eslint-disable-next-line
  }, [store.id]);

  useEffect(() => {
    if (bulkOrdDispatch) {
      let filterNotExistOrderAddress = selectedOrders.filter(
        (ord) => !ord?.order_address
      );
      setNotExistOrderAddress(filterNotExistOrderAddress);
    }
  }, [selectedOrders]);

  const getOrderIds = (ordsArray) => {
    let ordIdsStr = "";
    ordsArray.map((ord, i) => {
      ordIdsStr =
        ordIdsStr +
        ord.display_id +
        `${ordsArray.length === i + 1 ? "" : ", "}`;
    });
    return ordIdsStr;
  };

  const onShip = async () => {
    setLoading(true);
    dispatch(
      updateOrders(
        {
          state: "SHIPMENT_READY",
          status: "PICKUP_PENDING",
          delivery_detail: {
            dboy_store_user_id: selectedDeliveryBoy.id,
          },
        },
        bulkOrdDispatch ? Object.keys(selectedItems) : [order.id]
      )
    ).then((res) => {
      if (res) {
        setLoading(false);
        setSuccessToast("Order succesfully assigned to delivery boy");
        setSelectedDeliveryBoy(null);
        localStorage.setItem("default_shipping_option", "DELIVERY_BOY");
        if (bulkOrdDispatch) {
          setselectedItems({});
        }
        onClose();
      } else {
        setLoading(false);
        setError(true);
      }
    });
  };

  if (order || bulkOrdDispatch)
    return (
      <>
        <div style={{ minHeight: 251 }}>
          {notExistOrderAddress.length > 0 && (
            <AlertBar
              heading={`${notExistOrderAddress.length} ${
                notExistOrderAddress.length > 1 ? "orders" : "order"
              } does not have an address`}
              orderIds={getOrderIds(notExistOrderAddress)}
              icon={
                <ReportOutlined
                  style={{ color: "#FF0000", fonSize: 20, marginTop: -1 }}
                />
              }
              backgroundColor="#FFEEEE"
            />
          )}
          {!isDeliveryBoys && deliveryBoys.length === 0 && (
            <>
              <div
                className={rowFlexCenterAll}
                style={{
                  flexDirection: "column",
                  paddingTop: 100,
                }}
              >
                <Typography style={{ fontSize: 14 }}>
                  {isNotProfPlan
                    ? "Please Upgrade to a Professional Plan to add Delivery Boys"
                    : "Please add a Delivery Boy for your store to proceed further"}
                </Typography>
                <Link
                  to={`${isNotProfPlan ? "./billing/plans" : "./users"}`}
                  style={{
                    color: "#1641DB",
                    textDecoration: "none",
                    fontSize: 14,
                    marginTop: 14,
                  }}
                >
                  {isNotProfPlan ? "Upgrade Plan" : "Users And Roles"}
                </Link>
              </div>
            </>
          )}
          {isDeliveryBoys && (
            <>
              <div
                className={rowFlexCenterAll}
                style={{
                  paddingTop: 100,
                }}
              >
                <CircularProgress color="secondary" />
              </div>
            </>
          )}
          {!isDeliveryBoys && deliveryBoys.length > 0 && (
            <div
              style={{
                border: "1px solid #E1E1E1",
                maxHeight: 251,
                overflowY: "auto",
              }}
            >
              {deliveryBoys.map((deliveryBoy, i) => {
                return (
                  <div
                    key={i}
                    className={classes.delBoyCont}
                    onClick={() => {
                      setSelectedDeliveryBoy(deliveryBoy);
                    }}
                  >
                    <div className={rowFlexAlignCenter}>
                      <div className={classes.delBoyIcon}>
                        <CustomerIcon size={20} />
                      </div>
                      <div>
                        <Typography
                          style={{ fontSize: 14, lineHeight: "16px" }}
                        >
                          {deliveryBoy.name}
                        </Typography>
                        <span style={{ color: "#666666", fontSize: 12 }}>
                          {deliveryBoy.mobile}
                        </span>
                      </div>
                    </div>
                    <div>
                      {selectedDeliveryBoy === deliveryBoy ? (
                        <RadioButtonCheckedSharp
                          fontSize="small"
                          style={{ cursor: "pointer", marginTop: 1 }}
                          color="secondary"
                        />
                      ) : (
                        <RadioButtonUncheckedSharp
                          fontSize="small"
                          style={{
                            cursor: "pointer",
                            marginTop: 1,
                            color: "#666666",
                          }}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className={classes.bottomStickyBtnRoot}>
          <div className={classes.bottomStickyBtnCont}>
            <CombinedButtons
              solidBtnText={`Assign ${
                bulkOrdDispatch ? " (" + selectedOrders.length + ")" : ""
              }`}
              outlinedBtnAction={onClose}
              solidBtnAction={onShip}
              disabled={!selectedDeliveryBoy || notExistOrderAddress.length > 0}
              loading={loading}
            />
          </div>
        </div>
        <Toast
          message={"Something went wrong, please try again!"}
          open={error}
          close={() => setError(false)}
          severity="error"
          autoClose
        />
      </>
    );
  return <span />;
};

export default DeliveryBoyForm;
