import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "react-image-crop/dist/ReactCrop.css";
import Moment from "react-moment";
import StatusDotIcon from "../../../icons/StatusDotIcon";
import { useDispatch, useSelector } from "react-redux";
import {
  startOrderLoader,
  stopOrderLoader,
} from "../../../redux/orders/orderActions";
import { getPdfLink } from "../../../utils/invoiceUtil";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import { Fragment } from "react";
import ProductNoPicIcon from "../../../icons/NoPicIcon";
import {
  getPaymentDetails,
  getDeliveryDetails,
  getShippingDetails,
} from "../../../utils/orderUtil";
import { downloadDocument } from "../../../utils/documentUtil";
import OrderChargeRow from "./OrderChargeRow";
import OrderAttributeRow from "./OrderAttributeRow";
import ExpressDeliveryIcon from "../../../icons/ExpressDeliveryIcon";
import { transparentButton } from "../../../styles/common";
import WalletIcon from "../../../icons/WalletIcon";
import ItemCheckoutFieldsInfo, {
  getFormattedDateString,
} from "./ItemCheckoutFieldsInfo";
import { getStoreUrl } from "../../../utils/storeUtil";
import { generateProductName } from "../../../utils/productUtils";
import OutlinkIcon from "../../../icons/OutlinkIcon";

const useStyles = makeStyles(() => ({
  dividerGrid: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 24,
    paddingBottom: 24,
    borderBottom: "1px solid #E6E6E6",
    width: "100%",
  },
  flexBox: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  subHeading: {
    fontWeight: 600,
    fontSize: 14,
    color: "#1A1A1A",
    paddingTop: 0,
  },
  nopicctn: {
    border: "1px solid #E5E5E5",
    boxSizing: "border-box",
    borderRadius: "6px",
    width: 60,
    height: 60,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  prodimage: {
    height: "60px",
    width: "60px",
    borderRadius: "6px",
  },
  earnCont: {
    borderRadius: "12px",
    background: "#FFF9F4",
    padding: "12px",
    display: "flex",
    alignItems: "center",
    marginTop: "15px",
    height: "52px",
  },
}));

const OrderSummary = ({ order, setOpenRecordPayment, reloadPayment }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const store = useSelector((state) => state.store);
  const [deliveryDetails, setDeliveryDetails] = useState(null);
  const utm = {
    id: order.utm_id,
    campaign: order.utm_campaign,
  };
  const [paymentDetails, setPaymentDetails] = useState({
    progress: true,
    payments: [],
  });

  const storeUrl = getStoreUrl(store.store, true);

  const { payments } = paymentDetails;

  const stateToText = (tableitem) => {
    let state = tableitem.state;
    if (state === "NEW") return { text: "New", fill: "#1641DB" };
    if (state === "CONFIRMED") return { text: "Confirmed", fill: "#FFB800" };
    if (state === "SHIPMENT_READY")
      return { text: "Shipment Ready", fill: "#666666" };
    if (state === "SHIPPED")
      return {
        text: tableitem.delivery_mode === "PICKUP" ? "Ready" : "In Transit",
        fill: "#FF7222",
      };
    if (state === "COMPLETE") return { text: "Completed", fill: "#72C472" };
    if (state === "RETURN")
      return {
        text: tableitem.status.split("_").join(" ").toLowerCase(),
        fill: "#FF6060",
      };
    if (state === "CANCELED") return { text: "Canceled", fill: "#FF0000" };
    return { text: "-", fill: "white" };
  };

  const viewPdf = () => {
    dispatch(startOrderLoader());
    getPdfLink(order.id).then((link) => {
      window.open(link);
      dispatch(stopOrderLoader());
    });
  };

  const downloadShippingLabel = async () => {
    let suffix = "-Shipping-Label.pdf";
    let filename = order.display_id + suffix;
    dispatch(startOrderLoader());
    await downloadDocument(store.store, "shipping-label", order.id, filename);
    dispatch(stopOrderLoader());
  };

  const getDueAmount = () => {
    if (payments.length === 0) {
      return order.due_amount;
    }
    const totalPaid = payments.reduce((total, payment) => {
      return total + payment.amount;
    }, 0);
    return order.final_sale_price - totalPaid;
  };

  const getItemTotal = () => {
    return order.order_items
      .reduce((total, item) => {
        var price = item.sp_tax_included
          ? item.sale_price
          : item.sale_price + item.tax;
        return total + price * item.qty;
      }, 0)
      .toFixed(2);
  };

  useEffect(() => {
    getPaymentDetails(order.id).then((data) => {
      setPaymentDetails({ progress: false, payments: data });
    });
    if (order.delivery_mode !== "PICKUP") {
      getDeliveryDetails(order.id).then((data) => {
        setDeliveryDetails(data);
      });
    }
  }, [reloadPayment, order]);

  let checkoutFields = order?.checkout_fields
    ? order.checkout_fields.filter((oi) => oi.value)
    : [];

  return (
    <>
      <Grid className={classes.dividerGrid}>
        <Typography className={classes.subHeading}>
          {order.type === "ONLINE" ? "Order Status" : "Invoice Status"}
        </Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            textTransform: "capitalize",
          }}
        >
          <StatusDotIcon fill={stateToText(order).fill} />
          &nbsp; &nbsp;
          {stateToText(order).text}
        </div>
      </Grid>
      <Grid className={classes.dividerGrid}>
        <Typography className={classes.subHeading}>
          {order.type === "ONLINE" ? "Order Date" : "Invoice Date"}
        </Typography>
        <Moment format="DD-MMM-YYYY, hh:mm A">{order.created_at}</Moment>
      </Grid>
      <Grid className={classes.dividerGrid} style={{ padding: "20px 0px" }}>
        <Typography className={classes.subHeading}>Mode</Typography>
        <span
          style={{
            padding: "4px 8px",
            marginLeft: 4,
            borderRadius: 15,
            height: 28,
            fontSize: 14,
            background:
              order.delivery_mode === "PICKUP"
                ? "rgba(255,114,34,0.15)"
                : "rgba(34,215,255,0.15)",
            color: "#646464",
            textTransform: "none",
            minWidth: 0,
          }}
        >
          {order.delivery_mode === "PICKUP" ? "Pick Up" : "Delivery"}
        </span>
      </Grid>
      {order.delivery_estimate && (
        <Grid className={classes.dividerGrid} style={{ padding: "20px 0px" }}>
          <Typography className={classes.subHeading}>Estimated Time</Typography>

          <span>{order.delivery_estimate}</span>
        </Grid>
      )}
      <Grid className={classes.dividerGrid} style={{ padding: "20px 0px" }}>
        <Typography className={classes.subHeading}>Payment Mode</Typography>
        <span
          style={{
            padding: "4px 8px",
            marginLeft: 4,
            borderRadius: 15,
            height: 28,
            fontSize: 14,
            background:
              order.payment_mode === "COD"
                ? "rgba(121,4,242,0.15)"
                : "rgba(11,156,49,0.15)",
            color: "#646464",
            textTransform: "none",
            minWidth: 0,
          }}
        >
          {order.payment_mode === "COD" ? "COD" : "Online"}
        </span>
      </Grid>
      {order.invoice_id && (
        <Grid className={classes.dividerGrid}>
          <Typography className={classes.subHeading}>Invoice Number</Typography>
          <span
            style={{ color: "#1641DB", cursor: "pointer" }}
            onClick={viewPdf}
          >
            {order.invoice_id}
          </span>
        </Grid>
      )}
      <Grid
        className={classes.dividerGrid}
        style={{
          borderBottom: "1px dashed rgb(240,240,240)",
          paddingBottom: 18,
        }}
      >
        <Typography className={classes.subHeading}>Customer</Typography>
      </Grid>
      <div>
        <Typography className={classes.subHeading} style={{ marginBottom: 8 }}>
          Bill To
        </Typography>
        <Typography
          className={classes.subHeading}
          style={{
            fontWeight: "normal",
            marginTop: 8,
            textTransform: "capitalize",
          }}
        >
          {order.org_customer_name && `${order.org_customer_name}, `}
          {order.org_customer_mobile && `${order.org_customer_mobile}`}
        </Typography>
        {order.delivery_mode !== "PICKUP" && order.order_address && (
          <div style={{ marginTop: 8 }}>
            <Typography
              className={classes.subHeading}
              style={{ marginBottom: 16 }}
            >
              Shipping Address
            </Typography>
            <div style={{ display: "flex" }}>
              <div style={{ marginRight: 12 }}>
                - <HomeOutlinedIcon color="secondary" />-{" "}
              </div>
              <div>
                <div>
                  <Typography
                    className={classes.subHeading}
                    style={{ fontWeight: "normal" }}
                  >
                    {order.order_address.customer_name &&
                      `${order.order_address.customer_name}, `}
                    {order.order_address.mobile &&
                      `${order.order_address.mobile}`}
                  </Typography>
                  <Typography
                    className={classes.subHeading}
                    style={{ fontWeight: "normal" }}
                  >
                    {order.order_address.street1}, {order.order_address.street2}
                  </Typography>
                  <Typography
                    className={classes.subHeading}
                    style={{ fontWeight: "normal", color: "#666666" }}
                  >
                    {order.order_address.city}, {order.order_address.state},
                    {order.order_address.pincode}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {order.delivery_mode !== "PICKUP" && order.delivery_ends_at && (
        <>
          <div
            className={classes.dividerGrid}
            style={{
              borderBottom: "1px dashed rgb(240,240,240)",
              paddingBottom: 18,
              display: "block",
            }}
          >
            <Typography className={classes.subHeading}>
              Shipping Details
            </Typography>
          </div>
          <OrderAttributeRow
            label="Shipping Method"
            value={order.express ? "Express Delivery" : "Normal Delivery"}
            highlight={true}
            icon={order.express && <ExpressDeliveryIcon />}
          />
          {order.delivery_ends_at && (
            <OrderAttributeRow
              label="Delivery Time"
              value={getShippingDetails(order)}
              highlight={true}
            />
          )}
          {order.slot && (
            <OrderAttributeRow
              label="Slot"
              value={order.slot}
              highlight={true}
            />
          )}
        </>
      )}
      <div style={{ borderBottom: "1px solid #E6E6E6", width: "100%" }}>
        &nbsp;
      </div>
      {order.delivery_mode !== "PICKUP" && (
        <>
          <div
            className={classes.dividerGrid}
            style={{
              borderBottom: "1px dashed rgb(240,240,240)",
              paddingBottom: 18,
              display: "block",
            }}
          >
            <Typography className={classes.subHeading}>
              Delivery Details
            </Typography>
          </div>
          {deliveryDetails && (
            <>
              <OrderAttributeRow
                label="Tracking Id"
                value={
                  deliveryDetails.tracking_id
                    ? deliveryDetails.tracking_id
                    : "Not Available"
                }
                highlight={deliveryDetails.tracking_id ? true : false}
              />
              <OrderAttributeRow
                label={
                  deliveryDetails.delivery_channel === "delivery_boy"
                    ? "Delivery Boy"
                    : "Partner Name"
                }
                value={
                  deliveryDetails.partner_name
                    ? deliveryDetails.partner_name
                    : "Not Available"
                }
                highlight={deliveryDetails.partner_name ? true : false}
              />
              {deliveryDetails.delivery_channel === "shiprocket" && (
                <OrderAttributeRow
                  label="Delivery Via"
                  value={"Shiprocket"}
                  highlight={deliveryDetails.tracking_id ? true : false}
                />
              )}
            </>
          )}
          <div className={classes.flexBox} style={{ marginTop: 8 }}>
            <span
              className={classes.subHeading}
              style={{
                fontWeight: 500,
                fontSize: 12,
                color: "#1641DB",
                cursor: "pointer",
                textAlign: "right",
              }}
              onClick={downloadShippingLabel}
            >
              Download Shipping Label
            </span>
          </div>
          <div
            style={{
              borderBottom: "1px solid #E6E6E6",
              width: "100%",
              height: deliveryDetails ? 16 : 10,
            }}
          >
            &nbsp;
          </div>
        </>
      )}
      {(utm.id || utm.campaign) && (
        <Fragment>
          <div
            className={classes.dividerGrid}
            style={{
              borderBottom: "1px dashed rgb(240,240,240)",
              paddingBottom: 18,
              display: "block",
            }}
          >
            <Typography className={classes.subHeading}>Reference</Typography>
          </div>
          <div
            className={classes.flexBox}
            style={{
              marginTop: 8,
            }}
          >
            <Typography
              className={classes.subHeading}
              style={{ fontWeight: 500 }}
            >
              ID
            </Typography>
            <Typography
              className={classes.subHeading}
              style={{
                fontWeight: 500,
                color: utm.id ? "#1A1A1A" : "#666666",
              }}
            >
              {utm.id ? utm.id : "Not Available"}
            </Typography>
          </div>
          <div
            className={classes.flexBox}
            style={{
              marginTop: 8,
              borderBottom: "1px solid #E6E6E6",
              paddingBottom: 16,
            }}
          >
            <Typography
              className={classes.subHeading}
              style={{ fontWeight: 500 }}
            >
              Campaign
            </Typography>
            <Typography
              className={classes.subHeading}
              style={{
                fontWeight: 500,
                color: utm.campaign ? "#1A1A1A" : "#666666",
              }}
            >
              {utm.campaign ? utm.campaign : "Not Available"}
            </Typography>
          </div>
        </Fragment>
      )}
      {order?.customer_notes && (
        <Grid style={{ display: "block" }} className={classes.dividerGrid}>
          <Typography
            className={classes.subHeading}
            style={{
              borderBottom: "1px dashed rgb(245,245,245)",
              paddingBottom: 8,
            }}
          >
            Customer Note
          </Typography>
          <Typography
            className={classes.subHeading}
            style={{ fontWeight: 500 }}
          >
            {order.customer_notes}
          </Typography>
        </Grid>
      )}
      {checkoutFields.length > 0 && (
        <Grid
          className={classes.dividerGrid}
          style={{
            borderBottom: "1px dashed rgb(240,240,240)",
            paddingBottom: 18,
            display: "block",
          }}
        >
          <Typography className={classes.subHeading}>
            Additional Info
          </Typography>
          <Divider style={{ marginTop: 24 }} />
          {checkoutFields.map((item, i) => {
            return (
              <div
                style={{
                  marginTop: 8,
                }}
                key={i}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    className={classes.subHeading}
                    style={{ fontWeight: 500 }}
                  >
                    {item.name}
                  </Typography>
                  {item.type === "IMAGE" ? (
                    <Link target="_blank" href={item.value}>
                      <img
                        src={item.value}
                        style={{
                          width: "50px",
                          height: "50px",
                          objectFit: "cover",
                          borderRadius: 8,
                          border: "1px solid black",
                        }}
                        alt={item.slug}
                      />
                    </Link>
                  ) : (
                    <Typography
                      className={classes.subHeading}
                      style={{ fontWeight: 500 }}
                    >
                      {item.value}
                    </Typography>
                  )}
                </div>
              </div>
            );
          })}
        </Grid>
      )}
      <Grid style={{ display: "block" }} className={classes.dividerGrid}>
        <Typography
          className={classes.subHeading}
          style={{
            borderBottom: "1px dashed rgb(245,245,245)",
            paddingBottom: 18,
          }}
        >
          Products
        </Typography>
        {order.order_items.map((item, i) => {
          return (
            <div
              style={{
                paddingBottom: 20,
                marginTop: 20,
                borderBottom: "1px dashed rgb(240,240,240) ",
              }}
              key={i}
            >
              <div className={classes.flexBox}>
                {item.image ? (
                  <img
                    className={classes.prodimage}
                    alt="Thumbnail"
                    src={item.image}
                  />
                ) : (
                  <div className={classes.nopicctn}>
                    <ProductNoPicIcon />
                  </div>
                )}
                <div style={{ width: "84%" }}>
                  <Typography
                    className={classes.subHeading}
                    style={{
                      fontWeight: 500,
                    }}
                  >
                    {item.display_name}
                    {item.product_id && <span>&nbsp; ({item.product_id})</span>}
                    &nbsp; (<span> {item.sku}</span>
                    <a
                      href={`${storeUrl}/products/${generateProductName(
                        item.name
                      )}-${item.sku}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        cursor: "pointer",
                        margin: "0px 2px 0px 6px",
                        textDecoration: "none",
                      }}
                    >
                      <OutlinkIcon size={14} style={{ marginTop: -3 }} />
                    </a>
                    )
                  </Typography>
                  <div className={classes.flexBox}>
                    <div>
                      <Typography
                        className={classes.subHeading}
                        style={{
                          fontWeight: "normal",
                          marginTop: 8,
                          color: "#666666",
                          paddingBottom: 5,
                        }}
                      >
                        MRP: ₹ {item.mrp}
                      </Typography>
                      {item.tax_group_id && (
                        <Typography
                          className={classes.subHeading}
                          style={{
                            fontWeight: "normal",
                            color: "#666666",
                            paddingBottom: 5,
                          }}
                        >
                          Tax: ₹ {item.tax.toFixed(2)} ({item.total_tax_in_per}
                          %)
                        </Typography>
                      )}
                    </div>
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography
                        className={classes.subHeading}
                        style={{
                          fontWeight: "normal",
                          marginTop: 8,
                          color: "#666666",
                          paddingBottom: 5,
                          borderBottom: "1px solid #EFEFEF",
                        }}
                      >
                        {item.sp_tax_included
                          ? `₹ ${item.sale_price} x ${item.qty}`
                          : `₹ ${item.sale_price + item.tax} x ${item.qty}`}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
              <Typography
                style={{
                  width: "100%",
                  textAlign: "right",
                  fontWeight: 500,
                  paddingTop: 0,
                  marginTop: item.tax_group_id ? -10 : 0,
                }}
                className={classes.subHeading}
              >
                ₹{" "}
                {(item.sp_tax_included
                  ? item.sale_price * item.qty
                  : (item.sale_price + item.tax) * item.qty
                ).toFixed(2)}
              </Typography>
              {item.checkout_fields && item.checkout_fields.length >= 1 && (
                <ItemCheckoutFieldsInfo
                  order={order}
                  item={item}
                  getDueAmount={getDueAmount}
                />
              )}
            </div>
          );
        })}
        <Grid
          className={classes.flexBox}
          style={{
            borderBottom: "1px dashed rgb(245,245,245)",
            width: "100%",
            padding: "18px 0px",
          }}
        >
          <Typography
            className={classes.subHeading}
            style={{ fontWeight: "normal", color: "#666666" }}
          >
            Item Total
          </Typography>
          <Typography
            className={classes.subHeading}
            style={{ color: "#666666" }}
          >
            ₹ {getItemTotal()}
          </Typography>
        </Grid>
        {order.coupon_discount > 0 && (
          <OrderChargeRow
            chargeName={`Coupon - ${
              order.coupon_code ? order.coupon_code : ""
            }`}
            chargeAmount={order.coupon_discount}
          />
        )}
        {order.order_discount > 0 && (
          <OrderChargeRow
            chargeName="Discount"
            chargeAmount={order.order_discount}
          />
        )}
        {order.shipping_charge > 0 && (
          <OrderChargeRow
            chargeName="Shipping Charge"
            chargeAmount={order.shipping_charge}
          />
        )}
        {order.packaging_charge > 0 && (
          <OrderChargeRow
            chargeName="Packaging Charge"
            chargeAmount={order.packaging_charge}
          />
        )}
        {order.payment_charge > 0 && (
          <OrderChargeRow
            chargeName="Payment Charge"
            chargeAmount={order.payment_charge}
          />
        )}
        {order.payment_discount > 0 && (
          <OrderChargeRow
            chargeName="Payment Discount"
            chargeAmount={order.payment_discount}
          />
        )}
        {order.round_value > 0 && (
          <OrderChargeRow
            chargeName="Round Off"
            chargeAmount={order.round_value}
          />
        )}
        <div
          style={{
            width: "100%",
            paddingTop: "18",
          }}
          className={classes.flexBox}
        >
          <Typography className={classes.subHeading} style={{ paddingTop: 18 }}>
            Total Amount
          </Typography>
          <Typography className={classes.subHeading} style={{ paddingTop: 18 }}>
            ₹ {parseFloat(order.final_sale_price).toFixed(2)}
          </Typography>
        </div>
      </Grid>
      {order.earn_points > 0 && (
        <Grid style={{ display: "block", width: "100%" }}>
          <div className={classes.earnCont}>
            <WalletIcon style={{ marginRight: 10, marginTop: -4 }} />
            <Typography variant="body2">
              Customer earned&nbsp;
              <span style={{ fontWeight: 600 }}>
                {order.earn_points} points
              </span>
              &nbsp;from this order
            </Typography>
          </div>
        </Grid>
      )}
      <Grid style={{ display: "block" }} className={classes.dividerGrid}>
        <Typography
          className={classes.subHeading}
          style={{
            borderBottom: "1px dashed rgb(245,245,245)",
            paddingBottom: 18,
          }}
        >
          Payment Details
        </Typography>
        {paymentDetails.progress && (
          <div style={{ textAlign: "center", marginTop: 20 }}>
            <CircularProgress color="secondary" />
          </div>
        )}
        {!paymentDetails.progress && (
          <div>
            {payments.length === 0 && (
              <Typography
                className={classes.subHeading}
                style={{
                  borderBottom: "1px dashed rgb(245,245,245)",
                  paddingTop: 18,
                  paddingBottom: 18,
                  textAlign: "center",
                  color: "#666666",
                }}
              >
                No Payment Recorded
              </Typography>
            )}
            {payments.length > 0 &&
              payments.map((payment, i) => {
                return (
                  <div key={i}>
                    <Accordion style={{ border: "none", boxShadow: "none" }}>
                      <AccordionSummary style={{ padding: 0 }}>
                        <div
                          className={classes.flexBox}
                          style={{
                            borderBottom: "1px dashed rgb(245,245,245)",
                            paddingTop: 0,
                            paddingBottom: 18,
                          }}
                          key={i}
                        >
                          <Moment style={{ width: "33%" }} format="DD-MMM-YYYY">
                            {payment.created_at}
                          </Moment>
                          <Typography
                            style={{
                              width: "33%",
                              fontSize: 14,
                              textAlign: "center",
                            }}
                          >
                            {payment.payment_type.replace(/_/g, " ")}
                          </Typography>
                          <Typography
                            style={{
                              width: "33%",
                              fontSize: 14,
                              textAlign: "right",
                            }}
                          >
                            ₹ {payment.amount.toFixed(2)}
                          </Typography>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails
                        style={{
                          paddingTop: 0,
                          borderBottom: "1px dashed rgb(240,240,240)",
                        }}
                      >
                        <div style={{ width: "100%" }}>
                          <div
                            style={{ width: "100%" }}
                            className={classes.flexBox}
                          >
                            <Typography
                              className={classes.subHeading}
                              style={{ fontWeight: 500 }}
                            >
                              Reference ID
                            </Typography>
                            <Typography
                              className={classes.subHeading}
                              style={{
                                fontWeight: 500,
                                color: payment.gw_txn_id
                                  ? "#1A1A1A"
                                  : "#666666",
                              }}
                            >
                              {payment.gw_txn_id
                                ? payment.gw_txn_id
                                : "Not Available"}
                            </Typography>
                          </div>
                          <div
                            style={{ width: "100%" }}
                            className={classes.flexBox}
                          >
                            <Typography
                              className={classes.subHeading}
                              style={{ fontWeight: 500 }}
                            >
                              Notes
                            </Typography>
                            <Typography
                              className={classes.subHeading}
                              style={{
                                fontWeight: 500,
                                color: payment.notes ? "#1A1A1A" : "#666666",
                              }}
                            >
                              {payment.notes ? payment.notes : "Not Available"}
                            </Typography>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                );
              })}
            <Grid
              style={{
                width: "100%",
                paddingTop: "18",
              }}
              className={classes.flexBox}
            >
              <Typography
                className={classes.subHeading}
                style={{ paddingTop: 18 }}
              >
                Due
              </Typography>
              <Typography
                className={classes.subHeading}
                style={{ paddingTop: 18 }}
              >
                ₹ {parseFloat(getDueAmount()).toFixed(2)}
              </Typography>
            </Grid>
            {getDueAmount() > 0 && (
              <Button
                className={transparentButton}
                onClick={() => setOpenRecordPayment(true)}
              >
                + RECORD PAYMENT
              </Button>
            )}
          </div>
        )}
      </Grid>
    </>
  );
};

export default OrderSummary;
