import React, { useState } from "react";
import { Button, ClickAwayListener } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { periodOptions, sortOptions } from "./WalletUtils";
import { useStyles } from "./WalletStyle";

const Filters = ({ selectedSort, periodFilter, onChangeFilters }) => {
  const classes = useStyles();
  const [openSort, setSortOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);

  return (
    <div
      className={classes.flexCenterBetween}
      style={{ padding: "20px 20px", borderBottom: "1px solid #E1E1E1" }}
    >
      <div
        style={{
          fontSize: "16px",
          fontWeight: 600,
          color: "#1A1A1A",
        }}
      >
        Transaction History
      </div>
      <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
        <ClickAwayListener onClickAway={() => setSortOpen(false)}>
          <div style={{ position: "relative" }}>
            <Button
              className={classes.filterButton}
              style={{ width: 140 }}
              endIcon={openSort ? <ExpandLess /> : <ExpandMore />}
              onClick={() => setSortOpen(!openSort)}
            >
              {selectedSort}
            </Button>
            {openSort && (
              <div
                className={classes.sheet}
                style={{ visibility: "visible", width: 130 }}
              >
                {sortOptions.map((filter) => (
                  <div
                    key={filter}
                    className={classes.bulkAction}
                    onClick={() => {
                      onChangeFilters(filter, periodFilter);
                      setSortOpen(false);
                    }}
                  >
                    <span>{filter}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </ClickAwayListener>
        <ClickAwayListener onClickAway={() => setOpenDropdown(false)}>
          <div style={{ position: "relative" }}>
            <Button
              className={classes.filterButton}
              endIcon={openDropdown ? <ExpandLess /> : <ExpandMore />}
              onClick={() => setOpenDropdown(!openDropdown)}
            >
              {periodFilter}
            </Button>
            {openDropdown && (
              <div className={classes.sheet} style={{ visibility: "visible" }}>
                {periodOptions.map((filter) => (
                  <div
                    key={filter}
                    className={classes.bulkAction}
                    onClick={() => {
                      onChangeFilters(selectedSort, filter);
                      setOpenDropdown(false);
                    }}
                  >
                    <span>{filter}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </ClickAwayListener>
      </div>
    </div>
  );
};

export default Filters;
