import { Typography } from "@material-ui/core";
import { DoneAll } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import FormModal from "../../../components/common/FormModal";
import Controls from "../../../components/controls/Controls";
import { updateOrders } from "../../../redux/orders/orderActions";
import useStyles from "../styles";

const CompleteOrderModal = ({
  openCompleteOrder,
  setOpenCompleteOrder,
  order,
  setToast,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const paymentModes = [
    { id: "CASH", title: "Cash" },
    { id: "WALLET", title: "Wallet" },
    { id: "UPI", title: "UPI" },
    { id: "CARD", title: "Card" },
    { id: "CHEQUE", title: "Cheque" },
    { id: "NETBANKING", title: "Net Banking" },
    { id: "OTHER", title: "Other" },
  ];

  const [selectedMode, setSelectedMode] = useState("none");
  const [paid, setPaid] = useState(0);
  const [error, setError] = useState("");
  const [btnError, setBtnError] = useState(false);
  const [refId, setRefId] = useState("");
  const [notes, setNotes] = useState("");
  useEffect(() => {
    setSelectedMode("none");
  }, [order]);

  React.useEffect(() => {
    setPaid(0);
  }, [order]);

  const onComplete = () => {
    if (order.due_amount > 0) {
      if (error) {
        return;
      }
      if (paid > 0) {
        if (!selectedMode || selectedMode === "none") {
          setBtnError(true);
          return;
        }
      }
    }
    if (paid > 0) {
      dispatch(
        updateOrders(
          {
            state: "COMPLETE",
            status: "DELIVERED",
            order_payment: {
              order_id: order.id,
              amount: paid,
              created_at: new Date().toISOString(),
              payment_type: selectedMode,
              reference_id: refId,
              notes: notes,
            },
          },
          [order.id]
        )
      ).then(() => setToast(true));
    } else {
      dispatch(
        updateOrders(
          {
            state: "COMPLETE",
            status: "DELIVERED",
          },
          [order.id]
        )
      ).then(() => setToast(true));
    }
    setOpenCompleteOrder(false);
  };
  if (order)
    return (
      <FormModal
        open={openCompleteOrder}
        onClose={() => {
          setOpenCompleteOrder(false);
        }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onSave={onComplete}
        startIcon={<DoneAll fontSize="small" />}
        heading="Complete Order"
      >
        <div>
          <Typography className={classes.subHeading}>
            {order.delivery_mode !== "PICKUP"
              ? "Mark Order as Delivered?"
              : "Mark Order as Picked Up?"}
          </Typography>
        </div>
        {order.due_amount > 0 && (
          <div>
            <Typography
              className={classes.subHeading}
              style={{
                fontWeight: 600,
                fontSize: 20,
                marginTop: 8,
                marginBottom: 8,
              }}
            >
              Record Payment
            </Typography>
            <div
              className={classes.dividerGrid}
              style={{ display: "block", paddingBottom: 2 }}
            >
              <Controls.Select
                label="Payment Type"
                value={selectedMode}
                placeholder="Select "
                options={paymentModes}
                color="secondary"
                onChange={(e) => {
                  setBtnError(false);
                  setSelectedMode(e.target.value);
                }}
                error={btnError ? "Select Payment Mode" : ""}
              />
            </div>
            <div
              className={classes.dividerGrid}
              style={{ display: "block", paddingBottom: 2 }}
            >
              <Controls.Input
                name="id"
                label="Payment Received"
                onChange={(e) => {
                  setPaid(parseFloat(e.target.value));
                  if (order.due_amount < parseFloat(e.target.value)) {
                    setError("Paid amount can't be more than Due amount");
                  } else {
                    setError("");
                  }
                }}
                onFocus={() => {
                  setError("");
                }}
                error={error}
                type="number"
                helperText={`Due Amount ₹ ${order.due_amount}`}
              />
            </div>
            <div
              className={classes.dividerGrid}
              style={{ display: "block", paddingBottom: 2 }}
            >
              <Controls.Input
                name="id"
                label="Reference ID"
                onChange={(e) => {
                  setRefId(e.target.value);
                }}
                type="text"
                onEnter={onComplete}
              />
            </div>
            <div
              className={classes.dividerGrid}
              style={{ display: "block", paddingBottom: 2 }}
            >
              <Controls.Input
                name="id"
                label="Notes"
                onChange={(e) => {
                  setNotes(e.target.value);
                }}
                type="text"
                onEnter={onComplete}
              />
            </div>
          </div>
        )}
      </FormModal>
    );
  return <span />;
};

export default CompleteOrderModal;
