import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const SalesIcon = ({ viewBox = "0 0 56 56" }) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "56px", height: "56px" }}>
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 12C0 5.37258 5.37258 0 12 0H44C50.6274 0 56 5.37258 56 12V44C56 50.6274 50.6274 56 44 56H12C5.37258 56 0 50.6274 0 44V12Z"
          fill="#1641DB"
          fillOpacity="0.05"
        />
        <path
          d="M19.5996 29.0772L23.333 20.4616L34.5333 29.0772L42.0002 14"
          stroke="#1641DB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 42.0005L17.2667 34.4619"
          stroke="#1641DB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 29.0791H19.6001L32.6671 39.8485L42.0007 29.0791"
          stroke="#1641DB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default SalesIcon;
