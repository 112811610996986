export const FETCH_CARTS = "FETCH_CARTS";
export const CART_PROGRESS = "CART_PROGRESS";
export const SET_CART_ERROR = "SET_CART_ERROR";
export const SET_CART_LOADER = "SET_CART_LOADER";
export const CLEAR_COMPLETE_CART = "CLEAR_COMPLETE_CART";
export const DELETE_CART = "DELETE_CART";
export const CLEAR_CART = "CLEAR_CART";
export const CART_DATE_SELECTOR = "CART_DATE_SELECTOR";
export const CART_STATE_SELECTOR = "CART_STATE_SELECTOR";
export const CART_STATUS_SELECTOR = "CART_STATUS_SELECTOR";
export const CART_DATE_RANGE = "CART_DATE_RANGE";
export const SET_CARTS_PER_PAGE = "SET_CARTS_PER_PAGE";
export const SET_CARTS_CURRENT_PAGE = "SET_CARTS_CURRENT_PAGE";
export const SET_CARTS_MAX_PAGE_SO_FAR = "SET_CARTS_MAX_PAGE_SO_FAR";
export const SET_CARTS_INPUT_KEYWORD = "SET_CARTS_INPUT_KEYWORD";
export const SET_CARTS_SEARCH_KEYWORD = "SET_CARTS_SEARCH_KEYWORD";
