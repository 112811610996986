import { makeStyles } from "@material-ui/core";
import { style } from "typestyle";
export const useStyles = makeStyles((_) => ({
  linkText: {
    fontSize: "14px",
    color: "#1A1A1A",
    fontWeight: 600,
    lineHeight: "20px",
  },
  linkIcon: { color: "#1A1A1A", fontSize: "14px", verticalAlign: "middle" },
  pageHead: {
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: 700,
    color: "#1A1A1A",
    marginTop: "5px",
  },
  paper: {
    padding: "35px",
    marginTop: "20px",
    width: "57%",
    boxShadow: "0px 8px 20px rgb(0 0 0 / 4%)",
    borderRadius:"12px"
  },
  horizontalDivider: {
    color: "#E5E5E5",
    marginBottom: "35px",
    borderWidth: "4px",
  },
  sectionHead: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#1A1A1A",
    marginBottom: "20px",
  },
  label: {
    fontSize: "14px",
    lineHeight: "20px",
    color: "#1A1A1A",
    fontWeight: 600,
    marginBottom: "12px",
    width: "100%",
  },
  rupee: {
    fontSize: 12,
    lineHeight: "16px",
    color: "#999999",
  },
  helperText: {
    textAlign: "right",
  },
}));

export const cardStyle = style({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  border: "1px solid #ebebeb",
  padding: "12px",
  borderRadius: "6px",
  margin: "4px 0px",
});

export const cardImage = style({
  width: 40,
  height: 40,
  objectFit: "scale-down",
  borderRadius: "4px",
});

export const smallCardImage = style({
  width: 20,
  height: 20,
  objectFit: "scale-down",
  borderRadius: "4px",
});

export const cardImageFallback = style({
  height: 40,
  width: 40,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const smallCardImageFallback = style({
  height: 20,
  width: 20,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
